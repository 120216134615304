import { LockOutlined } from '@ant-design/icons'
import { Col, Form } from 'antd'
import { InputPassword } from 'components'
import { IEcpForgotPasswordOnidata, IEcpSignupForm } from 'ecp/app/SignupForm/signupFormInterfaces'
import { PASSWORD_HELP_TEXT } from 'egi/app/Signup/signupConstants'
import React from 'react'
import { owaspVerify } from 'utils/owasp'

type Props = {
  errors?: Partial<IEcpForgotPasswordOnidata | IEcpSignupForm>
}

export default function ChangePasswordInputs ({ errors }: Props) {
  return (
    <>
      <Col lg={24} sm={24} xs={24} className="px-1">
        <Form.Item
          name="password"
          label={<label className="simulator-label">Senha</label>}
          help={errors?.password && errors?.password}
          validateStatus={errors?.password && 'error'}
          rules={[
            {
              required: true,
              message: 'Adicione sua confirmação de senha!'
            },
            () => ({
              validator (_, value) {
                if (owaspVerify(value)) {
                  return Promise.resolve()
                }

                return Promise.reject(new Error(PASSWORD_HELP_TEXT))
              }
            })
          ]}
        >
          <InputPassword
            className="unauth-inputs"
            placeholder="Confirmar senha"
            prefix={<LockOutlined />}
          />
        </Form.Item>
      </Col>

      <Col lg={24} sm={24} xs={24} className="px-1">
        <Form.Item
          name="confirmPassword"
          label={<label className="simulator-label">Confirmação de senha</label>}
          help={errors?.confirmPassword && errors?.confirmPassword}
          validateStatus={errors?.confirmPassword && 'error'}
          rules={[
            {
              required: true,
              message: 'Adicione sua confirmação de senha!'
            },
            ({ getFieldValue }) => ({
              validator (_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }

                if (!owaspVerify(value)) {
                  return Promise.resolve()
                }

                return Promise.reject(new Error('As senhas não coincidem!'))
              }
            })
          ]}
        >
          <InputPassword
            className="unauth-inputs"
            placeholder="Confirmar senha"
            prefix={<LockOutlined />}
          />
        </Form.Item>
      </Col>
    </>
  )
}

import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react'
import { Empty, Skeleton, Timeline } from 'antd'
import { useParams } from 'react-router-dom'
import ProposalEgiRepository from 'egi/repositories/ProposalEgiRepository'
import { ArrowRightOutlined } from '@ant-design/icons'
import { timeAsDayjs } from 'utils/time'
import { Dayjs } from 'dayjs'

function CommercialAttendantHistory () {
  const main: HTMLElement | null = document.querySelector('.ant-layout-content')
  const params: { id: string } = useParams()

  const [loading, setLoading] = useState(false)
  const [departmentsData, setDepartmentsData] = useState<Array<any>>([])
  const [error, setError] = useState<string>()

  useEffect(() => {
    const getCommercialAttendentsChangeHistory = async () => {
      setLoading(true)
      try {
        const response = await ProposalEgiRepository.commercialHistory(params.id)
        if (!response.data.data.history.length) throw new Error('Não foram encontradas alterações')
        setDepartmentsData(response?.data?.data?.history)
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }
    getCommercialAttendentsChangeHistory()
  }, [])

  useLayoutEffect(() => {
    if (main) main.scrollTo(0, main.scrollHeight)
  }, [loading])

  return (
    <Fragment>
      {loading && (
        <div className="proposal-container-card p-2 pt-3 mt-4">
          <Skeleton loading={loading} active />
          <Skeleton loading={loading} active />
        </div>
      ) } {(
        departmentsData.length > 0 && <div className="proposal-container-card p-2 pt-3  mt-4">
          <h3 className="p-2 b">Alteração de atendentes comerciais</h3>
          <Timeline mode="alternate">
            {
              departmentsData.map(item => {
                if (item.type === 'replacecommercialattendant') {
                  return (<Timeline.Item key={item.createdAt}>
                    <h4>{timeAsDayjs(item.createdAt as Dayjs).format('DD/MM/YYYY HH:MM')}</h4>
                    <h4>Modificado por: {item.modifiedBy.name}</h4>
                    <h4>{item?.last?.name ?? '-'} <ArrowRightOutlined /> {item?.new?.name ?? '-'}</h4>
                  </Timeline.Item>
                  )
                }

                if (item.type === 'reminderfinishcommercial') {
                  return (
                    <Timeline.Item key={item.createdAt}>
                      <h4>{timeAsDayjs(item.createdAt as Dayjs).format('DD/MM/YYYY HH:MM')}</h4>
                      <h4>Modificado por: {item.modifiedBy.name}</h4>
                      <h4>Atendimento finalizado</h4>
                    </Timeline.Item>
                  )
                }

                return (
                  <Timeline.Item key={item.createdAt}>
                    <h4>{timeAsDayjs(item.createdAt as Dayjs).format('DD/MM/YYYY HH:MM')}</h4>
                    <h4>Modificado por: {item.modifiedBy.name}</h4>
                  </Timeline.Item>
                )
              })
            }
          </Timeline>
          {error && <Empty description={error}/>}
        </div>
      )}

    </Fragment>
  )
}

export default CommercialAttendantHistory

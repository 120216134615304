import { Radio } from 'antd'
import { RadioGroupProps } from 'antd/lib/radio'
import React from 'react'

export default function RadioGroupSquare ({ className, ...rest }: { className?: string } & RadioGroupProps) {
  return (
    <Radio.Group
      {...rest}
      className={`radio-group-square ${className || ''}`}
    />
  )
}

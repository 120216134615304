import React from 'react'
import { Route } from 'react-router-dom'
import UnauthLayout from './UnauthLayout'

function UnauthRouter ({ ...rest }) {
  return (
    <Route
      {...rest}
      render={() => <UnauthLayout />}
    />
  )
}

export default UnauthRouter

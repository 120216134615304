import { LoadingOutlined } from '@ant-design/icons'
import { Button, Form, Image, Input, message, Radio } from 'antd'
import OpenIdSVG from 'assets/reactSvgs/OpenIdSVG/OpenIdSVG'
import axios from 'axios'
import InputPassword from 'components/Inputs/InputPassword/InputPassword'
import Modal from 'components/Modal/Modal'
import { swalError } from 'components/SwalError/SwalError'
import { AuthorizationsUser, SignInCredentials } from 'ecp/repositories/LoginRepository'
import OIDCRepository from 'ecp/repositories/OIDCRepository'
import SigninECPF from 'ecpf/app/SigninECPF/views/SigninECPF/SigninECPF'
import { InvalidErrors } from 'egi/consumers/errors'
import EmailRepository from 'egi/repositories/EmailRepository'
import GRecaptchaRepository from 'egi/repositories/GRecaptchaRepository'
import LoginRepositoryEgi from 'egi/repositories/LoginRepositoryEgi'
import { IAuth } from 'egi/types'
import { useAuth } from 'hooks'
import useAuthLayout, { ProductNotFound } from 'hooks/useAuthLayout'
import jwtDecode from 'jwt-decode'
import FullScreenLoading from 'layouts/FullScreenLoading/FullScreenLoading'
import UnauthLayout from 'layouts/UnauthLayout/UnauthLayout'
import { SigninLayout, SigninModel } from 'models/SiginModel'
import queryString from 'query-string'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { UNAUTHS_PATHS } from 'routes/unauthRoutes'
import { authActions } from 'store/modules/auth/authActions'
import format from 'utils/format'
import { isLocal } from 'utils/globals'
import imagesUrls from 'utils/imagesUrls'
import swal, { IConfirm } from 'utils/swal'
import { validateCpf, validateResponse } from 'utils/validate'
import { useFortity } from '../FortifyPassword/hooks/useFortify'
import GRecaptcha from './components/GRecaptcha/GRecaptcha'
import { ModalSelectLevel } from './components/SelectLevel/SelectLevel'
import SigninLoading from './components/SigninLoading/SigninLoading'
import { SigninErrorCodes } from './signinInterfaces'
import MaintenanceModal from 'components/MaintenanceModal/MaintanceModal'

const defaultCredentials = {
  identifier: '',
  password: '',
  isEcpf: false
}

export interface ILoginOIDC {
  authenticatedOIDC: boolean,
  userId?: string,
  access_token: string,
  identifier: string,
  iat: string
}

interface ISigninFormValues {
  identifier: string,
  password: string
}

type _oicdError = 'default' | 'userNotFound'

interface IQuery {
  verifyToken?: string
  token?: string
  method?: 'oidc'
  error?: _oicdError
}

const FullScreenLoadingPage = () => {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.location.reload()
    }, 3000)

    return () => clearTimeout(timeoutId)
  }, [])

  return (
    <FullScreenLoading.Wrapper>
      <FullScreenLoading.LogoContent logo={imagesUrls.rodobensLogoLight} />
    </FullScreenLoading.Wrapper>
  )
}

export const CookieButton = () =>
  <button
    id="ot-sdk-btn"
    type='button'
    className="ot-sdk-show-settings signin__cookies-button"
  >
    Gerenciar Cookies
  </button>

export const OIDCLink = () => {
  const [loading, setLoading] = useState(false)

  async function getOIDCLogin () {
    try {
      setLoading(true)
      const response = await OIDCRepository.getOIDCLink()
      const url = response.data.data.authorizationUrl
      setLoading(false)
      return window.open(url, '_self')
    } catch (err) {
      if (!axios.isCancel(err)) {
        message.error(err.message)
        setLoading(false)
      }
    }
  }

  const handleOIDCLogin = async () => {
    await getOIDCLogin()
  }

  return (
    <Link
      to="#"
      className="unauth-oidc"
      onClick={() => {
        if (loading === false) handleOIDCLogin()
      }}
    >
      <span className="mr-1">
        <OpenIdSVG/>
      </span>
      Login via Open Id {loading && <LoadingOutlined className='ml-1' />}
    </Link>
  )
}

interface ISigninFormProps {
  loaderTokenVerify: boolean
  onSubmit: (values: any) => void
  form: any
  errors: { identifier?: string, password?: string }
  loading: boolean
  redirectFirstAccess: () => void
  showFirstAcces?: boolean
}

function SigninForm ({ loaderTokenVerify, onSubmit, form, errors, loading, redirectFirstAccess, showFirstAcces } : ISigninFormProps) {
  return (
    <SigninLoading loaderTokenVerify={loaderTokenVerify}>
      <Form
        onFinish={onSubmit}
        layout='vertical'
        className='unauth-form'
        form={form}
      >
        <Form.Item
          name='identifier'
          label={<label className="simulator-label ml-2">Email ou CPF</label>}
          help={errors?.identifier}
          validateStatus={errors?.identifier && 'error'}
        >
          <Input
            className="unauth-inputs"
            data-cy="test-login-email"
            placeholder='Email ou CPF'
          />
        </Form.Item>

        <Form.Item
          name='password'
          label={<label className="simulator-label ml-2">Senha</label>}
          help={errors?.password}
          validateStatus={errors?.password && 'error'}
        >
          <InputPassword
            data-cy="test-login-password"
            placeholder='Senha'
            className="unauth-inputs"
          />
        </Form.Item>

        <div className='mb-4'>
          <Link
            to={UNAUTHS_PATHS.FORGOT}
            className='color-primary underline unauth-bottom-label'
          >
            Esqueceu a senha?
          </Link>
        </div>

        <Form.Item className="text-center mb-0">
          <Button
            data-cy='test-submit'
            id='submitSignin'
            loading={loading}
            htmlType='submit'
            type='primary'
            className='w-100 unauth-buttons'
          >
            Entrar
          </Button>
        </Form.Item>

        {showFirstAcces && (
          <div className="text-center mb-0">
            <Button
              onClick={redirectFirstAccess}
              type='ghost'
              className='w-100 unauth-buttons-ghost'
            >
              Primeiro Acesso
            </Button>
          </div>

        )}

      </Form>

      <div className="text-center mb-0 mt-2">
        <OIDCLink />
      </div>

      <CookieButton />
    </SigninLoading>

  )
}

function Signin () {
  const [errors, setErrors] = useState<Partial<SignInCredentials>>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [loaderTokenVerify, setLoaderTokenVerify] = useState<boolean>(false)
  const [secretCount, setSecretCount] = useState(0)

  const [signinMethod, setSigninMethod] = useState<SigninLayout>(
    SigninModel.chooseLayout()
  )

  const [visible, setVisible] = useState(false)
  const [credentials, setCredentials] = useState<SignInCredentials>(defaultCredentials)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [hasFetchError, setHasFetchError] = useState(isLocal ? true : false)

  const [maintananceModalVisible, setMaintananceModalVisible] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()
  const user = useAuth()
  const [form] = Form.useForm<ISigninFormValues>()

  const { handleSigninByAuth } = useAuthLayout()
  const { redirectToFortifyOrEcpPath } = useFortity()

  const query = queryString.parse(history.location.search)
  const { verifyToken, token, method, error }: IQuery = query || {}

  const redirectFirstAccess = () => {
    history.push(UNAUTHS_PATHS.FIRST_ACCESS_CLIENT_ECP_CHECK_LINK)
  }

  async function resendEmailPassword (err: any) {
    try {
      const response = await EmailRepository.verification({ userEmail: String(err.data.email) })
      swal.basic({ icon: 'success', title: 'Sucesso!', text: response.data?.message })
    } catch (err) {
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    }
  }

  const redirectToResendEmailView = (email: string) => {
    history.push(UNAUTHS_PATHS.RESEND_EMAIL, { email })
  }

  const resendEmail = (err: any): IConfirm => ({
    text: err.message,
    icon: 'warning',
    title: 'Atenção',
    confirmButtonText: 'Reenviar e-mail',
    force: true,
    confirm: async () => {
      await resendEmailPassword(err)
      redirectToResendEmailView(err.data.email)
    }
  })

  const redirectToForgotPasswordECPF = (err: Error): IConfirm => ({
    icon: 'warning',
    title: 'Atenção',
    html: err.message,
    confirmButtonText: 'Esqueci a senha',
    showCancelButton: false,
    confirm: () => {
      history.push(UNAUTHS_PATHS.FORGOT_ECPF)
    }
  })

  const redirectToForgotPassword = (err: Error): IConfirm => ({
    icon: 'warning',
    title: 'Atenção',
    html: err.message,
    confirmButtonText: 'Esqueci a senha',
    showCancelButton: false,
    confirm: () => {
      history.push(UNAUTHS_PATHS.FORGOT)
    }
  })

  const redirectToFirstAcess = (err: Error): IConfirm => ({
    icon: 'warning',
    title: 'Atenção',
    html: err.message,
    confirmButtonText: 'Prosseguir',
    showCancelButton: false,
    confirm: () => {
      redirectFirstAccess()
    }
  })

  const redirectUpdatePassword = (err: { message: string, data: { identifier: string } }): IConfirm => ({
    icon: 'warning',
    title: 'Atenção',
    html: err.message,
    confirmButtonText: 'Atualizar senha',
    showCancelButton: false,
    showLoaderOnConfirm: true,
    preConfirm: async () => {
      try {
        const response = await LoginRepositoryEgi.reset({ identifier: err?.data?.identifier })
        const { email } = response.data.data || {}

        history.push({
          pathname: UNAUTHS_PATHS.ECP_FORGOT_TOKEN_ONIDATA,
          state: {
            email: email
          }
        })
      } catch (err) {
        message.error(err.message)
      }
    },
    confirm: () => {}
  })

  const signinCallback = async (auth: IAuth | null, err: InvalidErrors | null) => {
    try {
      if (err || !auth) throw err

      await handleSigninByAuth(auth)
    } catch (error) {
      const message = error?.message || 'Falha ao se comunicar com o servidor'

      if (error instanceof ProductNotFound) {
        setVisible(false)
      }

      if (err?.data?.invalid) setErrors(validateResponse(err.data?.invalid))
      if (err?.data?.code === 'notVerified') {
        swal.confirmNegate(resendEmail(error))
      } else if (err?.data?.code === 'maintenance') {
        setMaintananceModalVisible(true)
      } else {
        swal.htmlBasic({
          text: message,
          icon: 'warning',
          title: 'Atenção',
          customClass: {
            content: 'signin__basic-swam-html'
          }
        })
      }
    } finally {
      setLoading(false)
    }
  }

  const credentialsFactory = (values: SignInCredentials & { userId?: string }) => {
    const identifier = (values.identifier || '').trim()
    const rawIdentifier = format.onlyDigits(identifier)
    const isValidCpf = validateCpf(rawIdentifier)

    return {
      ...values,
      identifier: isValidCpf ? rawIdentifier : identifier
    }
  }

  const onSignin = async ({ credentials, user, isOIDC }: { credentials: SignInCredentials, user: AuthorizationsUser, isOIDC?: boolean }) => {
    setLoading(true)
    const formattedLogin = isOIDC ? credentials : credentialsFactory(credentials)
    dispatch(authActions.signin({ ...formattedLogin, userId: user._id }, signinCallback))
  }

  const verifyGRecaptchaToken = async (token: string, identifier: string) => {
    const response = await GRecaptchaRepository.verifyToken({ token, identifier })

    const { recaptchaResponse } = response.data?.data || {}

    if (!recaptchaResponse) throw new Error('Não foi possível obter resposta do servidor para autenticação reCAPTCHA')
    if (!recaptchaResponse?.success) throw Object.assign({ message: 'A autenticação por reCAPTCHA falhou', data: { 'error-codes': recaptchaResponse['error-codes'] }, reCaptcha: true })
    return recaptchaResponse?.success
  }

  const onSubmit = () => setIsModalVisible(true)

  const onSubmitAlternativeFields = ({ identifier, password }: { identifier: string, password: string }) => {
    form.setFieldsValue({ identifier, password })
    setCredentials(prev => ({ ...prev, isEcpf: true }))
    setIsModalVisible(true)
  }

  const redirectUser = (users: AuthorizationsUser[], credencialsValues: SignInCredentials, isOIDC?: boolean) => {
    if (!users) throw new Error('Nenhum usuário encontrado')
    if (users.length === 1) {
      dispatch(authActions.resetLevels())
      const formattedLogin = isOIDC ? credencialsValues : credentialsFactory(credencialsValues as SignInCredentials)

      if (credencialsValues) onSignin({ credentials: formattedLogin, user: users[0], isOIDC })
    } else {
      dispatch(authActions.setLevels(users))

      if (credencialsValues) setCredentials(credencialsValues)
      setVisible(true)
    }
  }

  const authenticateUser = async ({ values, token = '' }: { values: SignInCredentials, token?: string }) => {
    setLoading(true)
    try {
      if (!hasFetchError) await verifyGRecaptchaToken(token, values.identifier)
      const response = await LoginRepositoryEgi.authorizations(credentialsFactory(values))
      const users = response.data.data?.users
      if (!users) throw new Error('Nenhum usuário encontrado')
      redirectUser(users, values)
    } catch (err) {
      if (err?.data?.redirectForgot) {
        if (credentials.isEcpf) {
          swal.confirm(redirectToForgotPasswordECPF(err))
        } else {
          swal.confirm(redirectToForgotPassword(err))
        }
      }

      switch (err?.data?.code) {
        case SigninErrorCodes.notVerified:
          swal.confirmNegate(resendEmail(err))
          break
        case SigninErrorCodes.passwordUpdate:
          swal.confirm(redirectUpdatePassword(err))
          break
        case SigninErrorCodes.firstAcess:
          swal.confirm(redirectToFirstAcess(err))
          break
      }

      if (err?.reCaptcha) {
        swalError({ err })
      } else {
        swal.confirm({
          icon: 'warning',
          title: 'Atenção',
          text: err.message,
          confirm: () => {
            redirectToFortifyOrEcpPath(err, err?.data?.email)
          }
        })
      }

      setLoading(false)
    }
  }

  const handleGRecaptcha = (token: string = '') => {
    setIsModalVisible(false)
    authenticateUser({ values: form.getFieldsValue(), token })
  }

  useEffect(() => {
    return () => {
      setLoaderTokenVerify(false)
    }
  }, [])

  useEffect(() => {
    if (verifyToken) history.push(`${UNAUTHS_PATHS.VERIFY_EMAIL}?verifyToken=${verifyToken}`)
  }, [verifyToken])

  const oidcLogin = async () => {
    setLoading(true)
    try {
      const values : ILoginOIDC = jwtDecode(String(token))
      const response = await LoginRepositoryEgi.authorizations(values)
      const users = response.data.data?.users
      if (users) redirectUser(users, values, true)
    } catch (err) {
      message.error(err.message)
    } finally {
      setLoading(false)
    }
  }

  const getOidcResponse = (error: _oicdError) => {
    if (token) return oidcLogin()
    if (error === 'userNotFound') return message.error('Ops, usuário não foi encontrado. Tente inserir outras credenciais')
    if (error === 'default') return message.error('Não foi possível entrar no sistema, tente novamente')
  }

  useEffect(() => {
    if (method === 'oidc') {
      setSigninMethod(SigninLayout.default)
      getOidcResponse(error as _oicdError)
    }
  }, [])

  if (user?.isAuth === true) {
    return (
      <FullScreenLoadingPage />
    )
  }

  return (
    <Fragment>
      <MaintenanceModal
        onCancel={() => setMaintananceModalVisible(false)}
        visible={maintananceModalVisible}
      />

      <ModalSelectLevel
        title='Selecione seu nível de usuário'
        description='Selecione abaixo qual nível de usuário você deseja entrar no sistema.'
        loading={loading}
        visible={visible}
        users={user.levels}
        onSelect={async (user) => onSignin({ credentials, user })}
        onClose={() => {
          setLoading(false)
          setVisible(false)
        }}
      />

      <Modal
        centered
        closable
        destroyOnClose
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        visible={isModalVisible}
      >
        <GRecaptcha
          callback={handleGRecaptcha}
          hasFetchError={hasFetchError}
          setHasFetchError={setHasFetchError}
        />
      </Modal>

      <UnauthLayout>
        <div className="unauth-form-border">
          <div className='text-center'>
            {SigninModel.canSwitchLayouts(secretCount) && (
              <Radio.Group
                className="w-100 simulator-radio"
                defaultValue={signinMethod}
                value={signinMethod}
                onChange={(method) => {
                  setSigninMethod(method.target.value)
                  if (method.target.value === 'default') {
                    setCredentials(prev => ({ ...prev, isEcpf: false }))
                  }
                }}
              >
                <Radio.Button value='default'>EGI</Radio.Button>
                <Radio.Button className='w-100' value='ecpf'>ECP Funçao</Radio.Button>
              </Radio.Group>
            )}

            <Image
              src={imagesUrls.rodobensLogoLight}
              preview={false}
              className='signin-image-logo'
              alt="rodobens"
              onClick={() => setSecretCount(prev => prev + 1)}
            />
          </div>

          <div style={{ display: signinMethod === 'default' ? 'block' : 'none' }}>
            <SigninForm
              loaderTokenVerify={loaderTokenVerify}
              onSubmit={onSubmit}
              form={form}
              errors={errors}
              loading={loading}
              redirectFirstAccess={redirectFirstAccess}
            />
          </div>

          <div style={{ display: signinMethod === 'ecpf' ? 'block' : 'none' }}>
            <SigninECPF
              loading={loading}
              onSubmitAlternativeFields={onSubmitAlternativeFields}
            />
          </div>
        </div>
      </UnauthLayout>
    </Fragment>
  )
}

export default Signin

import React from 'react'
import { Form, Select } from 'antd'
import { serverTableSelectProps } from 'utils/globals'

function FilterCurrentAction ({ onChange, defaultValue }: { onChange: (value: string | string[] | undefined) => void, defaultValue?: string[] }) {
  return (
    <Form.Item
      name="currentAction"
      label={<label>Ação atual</label>}
    >
      <Select
        {...serverTableSelectProps}
        allowClear
        placeholder="Escolha a ação atual"
        defaultValue={defaultValue}
        onChange={onChange}
      >
        <Select.Option value="legal">Jurídico</Select.Option>
        <Select.Option value="partner">Parceiro</Select.Option>

      </Select>
    </Form.Item>
  )
}

export default FilterCurrentAction

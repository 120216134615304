import { Empty, Spin } from 'antd'
import { IRoute } from 'components/Sidebar/types'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { getRoutes } from 'routes'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { LoadingCard } from 'components/LoadingCard/LoadingCard'
import proposals from 'egi/consumers/proposals'
import { useAuth } from 'hooks'
import { AxiosRequestConfig } from 'axios'
import useSelectedLevel from 'components/NavbarSelectLevel/hooks/useSelectedLevel'
import { resourcesSetProducts } from 'store/modules/resources/actions'
import useAuthLayout from 'hooks/useAuthLayout'
import useSelectedProducts from 'components/SelectProductsSidebar/hooks/useSelectedProducts'
import { authActions } from 'store/modules/auth/authActions'

const FollowUpProposalIndiky = () => {
  const [componentStatus, setComponentStatus] = useState<'loading' | 'failed' | undefined>('loading')

  const dispatch = useDispatch()
  const history = useHistory()
  const query = queryString.parse(history.location.search)
  const user = useAuth()
  const { getProducts } = useSelectedLevel()
  const { handleReset } = useAuthLayout()
  const { onProductSelect } = useSelectedProducts()

  const antIcon = <Loading3QuartersOutlined style={{ fontSize: 200 }} spin />

  useEffect(() => {
    async function getAcessToken (token: string) {
      setComponentStatus('loading')
      try {
        const response = await proposals.followupProposal({ indikyToken: token })
        const userData = { ...response.data, followUpProposal: true }

        const requestConfig: AxiosRequestConfig = {
          headers: { Authorization: `Bearer ${userData.token}` }
        }

        const { firstProduct, products } = await getProducts({ requestConfig, user })
        if (!firstProduct || !firstProduct?._id) {
          handleReset()
          throw new Error('Falha ao encontrar produto!')
        }

        dispatch(resourcesSetProducts(products || []))
        onProductSelect(firstProduct._id, products, userData)

        dispatch(authActions.setUserData(userData, async () => {
          const routes = getRoutes('home-equity', userData)

          const firstRoutes = routes[0] as IRoute
          if (firstRoutes) {
            history.push(`/auth${firstRoutes.path}`)
            setComponentStatus(undefined)
          }
        }))
      } catch (err) {
        setComponentStatus('failed')
        console.log(err)
      }
    }

    if (query.token) {
      getAcessToken(query.token as string)
    }
  }, [])
  return (
    <div className='unauth-proposal'>
      <>
        {componentStatus === 'loading' && (
          <Spin
            size={'large'}
            indicator={antIcon}
          />
        )}

        {componentStatus === 'failed' &&
          <LoadingCard>
            <Empty description={'Falha ao carregar proposta'}/>
          </LoadingCard>
        }
      </>
    </div>
  )
}

export default FollowUpProposalIndiky

import { notaryFees } from 'routes/departments/pricing'
import {
  precificacaoEdit,
  pricingTable,
  precificacaoCreate,
  exclusiveFees,
  chat,
  proposalCheck,
  lgpdTerms,
  bacenTerms
} from '../routes'

const pricingRouter = [
  pricingTable,
  precificacaoCreate,
  precificacaoEdit,
  proposalCheck,
  exclusiveFees,
  chat,
  lgpdTerms,
  bacenTerms,
  notaryFees
]

export default pricingRouter

import React, { Fragment, useMemo, useState } from 'react'
import { DocumentUpload, Drawer } from 'components'
import ExplainSend from 'components/ExplainSend/ExplainSend'
import { AxiosRequestConfig } from 'axios'
import swal from 'utils/swal'
import { Empty } from 'antd'
import { swalError } from 'components/SwalError/SwalError'
import IDocuments from 'egi/types/IDocuments'
import usePartnerStep from '../../../../hooks/usePartnerStep'
import usePartnerStepDrawer from '../../../../hooks/usePartnerStepDrawer'
import usePartnerDocumentation from '../../../../hooks/usePartnerDocumentation'
import useDepartmentStep from '../../../../hooks/usePartnerDepartments'
import ReviewStep from '../../../ReviewStep/ReviewStep'
import PartnerStepButton from '../../../PartnerStepButton/PartnerStepButton'
import ReviewDescription from '../../../ReviewDescription/ReviewDescription'
import PartnerModel from 'egi/models/PartnerModel'
import PartnersRepository from 'egi/repositories/PartnersRepository'

function DocumentStep () {
  const [documentLoading, setDocumentLoading] = useState<boolean>(false)
  const [path, setPath] = useState<Array<IDocuments>>([])
  const [status, setStatus] = useState<'back' | 'saving' | 'sending'>()

  const { step } = usePartnerStep()
  const { close, open, visible } = usePartnerStepDrawer()
  const { _id: documentId, docConfiguration: rootDocumentConfiguration } = usePartnerDocumentation()
  const { slug: departmentSlug } = useDepartmentStep()
  const sendDocument = PartnerModel.canSendDocument(step)
  const documentSize = step?.docConfiguration?.size || rootDocumentConfiguration.size
  const documentLimit = step?.docConfiguration?.limit || rootDocumentConfiguration.limit

  const description = useMemo(() => {
    const chooseDescriptions: { [key: string]: string } = {
      'comprovante-endereco': '(conta de água, luz ou telefone) - validade 90 dias',
      'atos-societarios': 'Contrato Social, Atualizações e Procurações se houver'
    }

    return chooseDescriptions
  }, [step.slug])

  async function onSubmit () {
    setStatus('sending')
    const data = {
      department: departmentSlug,
      step: step.slug
    }

    try {
      const response = await PartnersRepository.send(documentId, data)
      swal.basic({ icon: 'success', title: 'Sucesso!', text: response.data.message })
      close()
    } catch (err) {
      console.error(err)
      swalError({ title: 'Atenção', icon: 'warning', err })
    }
    setStatus(undefined)
  }

  async function onFileUpload (request: any) {
    const { file, onSuccess, onProgress, onError } = request
    if (!file) return

    const formData = new FormData()
    formData.append('file', file)
    formData.append('department', departmentSlug)
    formData.append('step', step.slug)

    const config: AxiosRequestConfig = {
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 90000,
      onUploadProgress: ({ total, loaded }) => {
        if (total) onProgress({ percent: Math.round((loaded / total) * 100) })
      }
    }

    try {
      const response = await PartnersRepository.sendDocument(documentId, formData, config)

      onSuccess(response)
    } catch (err) {
      onError(err)
    }
  }

  const onFileDelete = async (fileUrl: string | object, resolve: Function) => {
    setDocumentLoading(true)

    const data = {
      department: departmentSlug,
      step: step.slug,
      file: fileUrl
    }
    const config: AxiosRequestConfig = { timeout: 90000 }

    try {
      const response = await PartnersRepository.deleteDocument(documentId, data, config)
      resolve(true)
      swal.basic({ icon: 'success', title: 'Sucesso!', text: response.data.message })
    } catch (err) {
      console.error(err)
      swal.basic({ icon: 'warning', title: 'Atenção!', text: err.message })
    } finally {
      setDocumentLoading(false)
    }
  }

  return (
    <Fragment>
      <PartnerStepButton
        status={step.status}
        enabled={true}
        onClick={open}
        step={step}
      >
        {step.name}
      </PartnerStepButton>

      <Drawer
        visible={visible}
        onClose={close}
        onSubmit={() => onSubmit()}
        showSubmit={true}
        disabled={!sendDocument}
        title={step.name}
        status={status}
      >
        <Fragment>
          <div className="my-4">
            <ExplainSend
              isDocument={true}
              description={description[step.slug]}
              docSize={documentSize}
              docLimit={documentLimit}
            />

            {(!step.documents && !sendDocument) && <Empty description="Nenhum documento encontrado!" />}

            <DocumentUpload
              paths={path}
              getDocuments={() => setPath([{ name: '', path: step?.documents ?? [], stepId: '', stepName: '' }])}
              loading={documentLoading}
              hideUploadButton={!sendDocument}
              maxDocs={documentLimit}
              onFileUpload={onFileUpload}
              onFileDelete={onFileDelete}
              skeletonQuantity={2}
            />
          </div>

          <div className="my-4">
            <ReviewDescription />
          </div>

          <ReviewStep onSuccess={close} />
        </Fragment>
      </Drawer>
    </Fragment>
  )
}

export default DocumentStep

import { message } from 'antd'
import { AxiosRequestConfig } from 'axios'
import { IErrorDetails } from 'components/ErrorDetails/interfaces'
import { IErrorDetailsECPF } from 'components/ErrorDetailsECPF/ErrorDetailsECPF'
import ProposalDuesEcpfModel from 'ecpf/models/ProposalDuesEcpfModel'
import { IContractECPF, IDebitBalanceResponse, IDueCPF, IGetCOntractsECPFParams } from 'ecpf/repositories/OperationsECPFRepository'
import { IAnticipationSimulationPayload, IAnticipationSimulationResponse, IGenerateTicketPayload, IGenerateTicketResponse } from 'ecpf/repositories/ProposalECPFRepository'
import { AnticipationSimulationNotFound, IGetDebitBalanceFromDueListResponse, IGetDebitBalanceProps, OperationService } from 'ecpf/services/operationService'
import { useCallback, useState } from 'react'

export default function useDueList () {
  const [dueList, setDueList] = useState<Array<IDueCPF>>()
  const [error, setError] = useState<IErrorDetailsECPF>()
  const [loading, setLoading] = useState(false)

  const fetchDueList = useCallback(async (abortController: AbortController, operationNumber?: string) => {
    setError(undefined)
    try {
      setLoading(true)

      if (!operationNumber) throw new Error('Número da operação não encontrado')
      const operationService = new OperationService()
      const dueList = await operationService.getDueList({ numeroOperacao: operationNumber })
      setDueList(dueList || [])
    } catch (err) {
      if (!abortController.signal.aborted) {
        setDueList([])
        setError(err)
      }
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    dueList,
    setDueList,
    error,
    loading,
    fetchDueList
  }
}

export function useDebitBalance () {
  const [debitBalance, setDebitBalance] = useState<IDebitBalanceResponse>()
  const [error, setError] = useState<IErrorDetails>()
  const [loading, setLoading] = useState(true)

  const fetchDebitBalance = useCallback(async (abortController: AbortController, params: IGetDebitBalanceProps) => {
    setError(undefined)
    try {
      setLoading(true)
      if (!params?.numeroOperacao) throw new Error('Número da operação não encontrado')

      const operationService = new OperationService()
      const balance = await operationService.getDebitBalance(params)
      setDebitBalance(balance || undefined)
    } catch (err) {
      if (!abortController.signal.aborted) {
        setDebitBalance(undefined)
        setError(err)
      }
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    debitBalance,
    setDebitBalance,
    error,
    loading,
    fetchDebitBalance
  }
}

export function useDebitBalanceFromDueList () {
  const [debitBalanceFromDueList, setDebitBalanceFromDueList] = useState<IGetDebitBalanceFromDueListResponse>()
  const [error, setError] = useState<IErrorDetailsECPF>()
  const [loading, setLoading] = useState(false)

  const fetchDebitBalanceFromDueList = useCallback(async (abortController: AbortController, params: IGetDebitBalanceProps) => {
    setError(undefined)
    try {
      setLoading(true)
      const operationService = new OperationService()
      const balance = await operationService.getDebitBalanceFromDueList(params)
      setDebitBalanceFromDueList(balance || undefined)
    } catch (err) {
      if (!abortController.signal.aborted) {
        setDebitBalanceFromDueList(undefined)
        setError(err)
      }
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    debitBalanceFromDueList,
    setDebitBalanceFromDueList,
    error,
    loading,
    fetchDebitBalanceFromDueList
  }
}

export function useAnticipationSimulation () {
  const [simulationResult, setSimulationResult] = useState<IAnticipationSimulationResponse>()
  const [error, setError] = useState<IErrorDetails>()
  const [loading, setLoading] = useState(false)

  const onSimulateAnticipation = useCallback(async (abortController: AbortController, data: IAnticipationSimulationPayload, config?: AxiosRequestConfig) => {
    setError(undefined)
    try {
      setLoading(true)
      const operationService = new OperationService()
      const simulationResult = await operationService.anticipationSimulation(data, config)
      if (!simulationResult) throw new AnticipationSimulationNotFound()
      setSimulationResult(simulationResult)
    } catch (err) {
      if (!abortController.signal.aborted) {
        setSimulationResult(undefined)
        setError(err)
      }
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    simulationResult,
    setSimulationResult,
    error,
    loading,
    setError,
    onSimulateAnticipation
  }
}

export function useGenerateTicket () {
  const [ticket, setTicket] = useState<IGenerateTicketResponse>()
  const [error, setError] = useState<IErrorDetails>()
  const [loading, setLoading] = useState(false)

  const generateTicket = useCallback(async (abortController: AbortController, data: IGenerateTicketPayload) => {
    setError(undefined)
    try {
      setLoading(true)
      const operationService = new OperationService()
      const ticketResponse = await operationService.generateTicket(data)
      setTicket(ticketResponse)
      ticketResponse?.documento && ProposalDuesEcpfModel.downloadBase64File(ticketResponse?.documento, 'boleto.pdf')
    } catch (err) {
      if (!abortController.signal.aborted) {
        setTicket(undefined)
        setError(err)
        message.error(err?.message || 'Erro ao gerar boleto')
      }
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    ticket,
    setTicket,
    error,
    loading,
    generateTicket
  }
}

export function useContracts () {
  const [contracts, setContracts] = useState<IContractECPF[]>()
  const [error, setError] = useState<IErrorDetailsECPF >()
  const [loading, setLoading] = useState(false)

  const fetchContracts = useCallback(async (params: IGetCOntractsECPFParams, abortController: AbortController) => {
    setError(undefined)
    try {
      setLoading(true)
      const operationService = new OperationService()
      const contracts = await operationService.getContracts(params)
      setContracts(contracts || [])
    } catch (err) {
      if (!abortController.signal.aborted) {
        setContracts([])
        setError(err)
      }
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    contracts,
    setContracts,
    error,
    loading,
    fetchContracts
  }
}

import React from 'react'

function MasterElement () {
  const MasterDocs = `
  <h2>Função de Cancelamento de Usuario</h2>
    <a href="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFKRKfDPdE&#x2F;view?utm_content=DAFKRKfDPdE&amp;utm_campaign=designshare&amp;utm_medium=embeds&amp;utm_source=link" 
      target="_blank" 
      rel="noopener">
    MANUAL CANCELAMENTO DE USUÁRIO</a> 
    <div style="position: relative; width: 100%; height: 0; padding-top: 56.2500%;
      padding-bottom: 0; box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16); margin-top: 1.6em; margin-bottom: 0.9em; overflow: hidden;
      border-radius: 90px; will-change: transform;">
      <iframe 
        loading="lazy" 
        style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;"
        src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFKRKfDPdE&#x2F;view?embed" allowfullscreen="allowfullscreen" 
        allow="fullscreen">
      </iframe>
    </div>
`

  return (
    <div dangerouslySetInnerHTML={{ __html: MasterDocs }} />
  )
}

export default MasterElement

import { _userLevel } from 'ecp/models/UsersModel'
import { _tableAreas } from 'globals'

const StepsModel = {
  canUploadBankProof (userLevel: _userLevel, userArea: _tableAreas | undefined): boolean {
    const levels = ['client', 'master', 'promoter', 'commercial']
    const area = ['comercial']
    return levels.includes(userLevel) || (userArea !== undefined && area.includes(userArea))
  }
}

export default StepsModel

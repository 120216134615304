import React from 'react'
import { Collapse, Divider, Progress, Typography, Skeleton } from 'antd'
import DepartmentSectionSkeleton from '../../DepartmentSection/Skeleton/DepartmentSectionSkeleton'
import PartnerStepSkeleton from '../../PartnerStep/Skeleton/PartnerStepSkeleton'

function PartnerDepartmentsSkeleton () {
  return (
    <DepartmentSectionSkeleton>
      <div className="department-info department-info-skeleton">
        <Typography.Title className="department-info__title" level={3}>
          <Skeleton.Input
            active={true}
            className="department-info-skeleton__title"
          />
        </Typography.Title>

        <Divider className="department-info__divider"/>

        <div className='department-info__progress-bar-wrapper'>
          <Progress
            className='department-info__progress-bar'
            strokeColor='var(--primary-color)'
            trailColor='#CECECE'
            status='success'
            percent={0}
            showInfo={false}
          />

          <Skeleton.Input
            active={true}
            className="department-info-skeleton__progress-bar-text"
          />
        </div>

        <Collapse defaultActiveKey="1" ghost>
          <Collapse.Panel header="Ver etapas" key='1'>
            <div className='department-info__steps-wrapper'>
              {new Array(3).fill('').map((_, idx) => (
                <PartnerStepSkeleton key={idx}/>
              ))}
            </div>
          </Collapse.Panel>
        </Collapse>
      </div>
    </DepartmentSectionSkeleton>
  )
}

export default PartnerDepartmentsSkeleton

import { AxiosRequestConfig } from 'axios'
import { Repository } from 'ecp/repositories/Repository'
import { IEmployerECPFList } from 'ecpf/app/EmployerECPF/hooks/useEmployerECPFList'
import { IECPFTypistList } from 'ecpf/app/TypistECPF/hooks/useTypist'
import api from 'services/api'
import { IPromoterListResults } from './PromoterECPFRepository'

export interface IMainOfficeItem {
  codigo: string
  descricao: string
}

export interface IRegionalItem {
  codigo: string
  descricao: string
  matriz: {
    codigo: string
    descricao: string
  }
}

export interface IOrganizationItem {
  codigo: string
  descricao: string
  empregador: {
    codigo: string
    descricao: string
  }
}

export interface IOrganizationByCnpj {
  codigo: string
  documento: string
  descricao: string
  codigoEmpregador: string
}

class OriginsECPFRepository extends Repository<{}> {
  async listEmployer (config?: AxiosRequestConfig) {
    return Repository.handle<{ resultados: Array<IEmployerECPFList>, total: number | null }>(() =>
      this.api.get(`${this.path}/empregador`, config)
    )
  }

  async listMainOffice (config?: AxiosRequestConfig) {
    return Repository.handle<{ resultados: Array<IMainOfficeItem>, total: number | null }>(() =>
      this.api.get(`${this.path}/matriz`, config)
    )
  }

  async listRegional (config?: AxiosRequestConfig) {
    return Repository.handle<{ resultados: Array<IRegionalItem>, total: number | null }>(() =>
      this.api.get(`${this.path}/regional`, config)
    )
  }

  async listPromoter (config?: AxiosRequestConfig) {
    return Repository.handle<{resultados: Array<IPromoterListResults>, total: number}>(() =>
      this.api.get(`${this.path}/promotor`, config)
    )
  }

  async listTypist (config?: AxiosRequestConfig) {
    return Repository.handle<{resultados: Array<IECPFTypistList>, total: number}>(() =>
      this.api.get(`${this.path}/digitador`, config)
    )
  }

  async listOrganization (config?: AxiosRequestConfig) {
    return Repository.handle<{resultados: Array<IECPFTypistList>, total: number}>(() =>
      this.api.get(`${this.path}/orgao`, config)
    )
  }

  async listOrganizationByCNPJ (config?: AxiosRequestConfig) {
    return Repository.handle<{ orgaos: Array<IOrganizationByCnpj>, total: number }>(() =>
      this.api.get(`${this.path}/orgao-documento`, config)
    )
  }
}

export default new OriginsECPFRepository({ api, path: '/ecp-funcao/origens' })

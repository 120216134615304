import React, { MutableRefObject } from 'react'
import { FormInstance } from 'antd/lib/form'
import { Dayjs } from 'dayjs'
import { IDocuments, IDrawer, IPerson, IStep } from 'egi/types'
import { IAction } from 'components/Actions/Action'
import { ICommercial } from './views/ProposalListEgi/dataTable'
import { openTypeDrawer } from 'components/SimulationCard/types'
import { _personTypeEnum } from 'globals'
import { ISLA } from '../Settings/views/SLAConfig/slaInterfaces'

export type _proposalStatuses = 'starting' | 'approved' | 'remaking' | 'analysis' | 'reproved' | 'reservation' | 'canceled' | 'inactive' | 'effectivated' | 'inProgress'
export type _proposalStatusesAndHistory = _proposalStatuses | 'approvedManually' | 'approvedWithRestriction' | 'approvedAutomatically'
export type _crivoStatuses = 'APR' | 'HUM' | 'REC'

export interface ISignatures {
  _id?: string
  uuid: string
  contract: {
    _id?: string
    original: string
    signed: string
  }
  stepId: string
  stepName: string
  stepSlug: string
  ref: string
  sign: string
  createdAt: string
  uploadedWithLink?: boolean
}

export interface IAvaliations {
  areas: Array<string>
  _id: string,
  name: 'simulacao' | 'credito' | 'imovel' | 'liberado'
  value: number,
  termInMonth: number
  areaIds: any
}

export interface IStepsRenders {
  callbackProposal: Function
  formRef: FormInstance
  area: string
  type: string
  setStatus: React.Dispatch<React.SetStateAction<string>>
  dateFormRef: MutableRefObject<any>
  setFiles: React.Dispatch<React.SetStateAction<File[]>>
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
  fetchProposals: () => Promise<void>
  openSimulationDrawer?: ({ type }: {
    type: openTypeDrawer;
}) => void
}

interface IBackoffices {
  administratorId: string
  name: string
}

export interface IProposal {
  _id: string
  number: number
  producer: string
  consultant: string
  support: string
  cpf: string
  name: string
  cellphone: string
  email: string
  contractNumber?: string
  value?: string
  backoffices?: IBackoffices[]
  departments: IDepartment[],
  createdAt: Dayjs
  updatedAt: Dayjs
  product: string
  indicator: {
    type: string,
    _id: string
  }
  isIntegration: boolean
  promoterId?: string
}

export type ITableUser = {
  id?: string,
  name: string,
  type?: string
}

export interface IRangeDate {
  startDate: Dayjs
  endDate: Dayjs
}

export interface IDepartment {
  status: string
  name: string
  icon: _proposalStatuses
  hasSLA: boolean
  avaliations: Array<IAvaliations>
  backoffices: Array<IBackoffices>
  sla?: ISLA
  personType?: _personTypeEnum
  _id: string
  steps: Array<IStep>
  canSeeDownloadZipDocuments: boolean
  ref?: string
  areaId: string
  description?: string
}

export type IUsersLinkedProposal = {
  showModalChangeUser: ({ user, level, proposalId }: {user: ITableUser, level: string, proposalId: string}) => void
  showStatusChangeModal?: (id: string) => void
  notInternal: boolean
  canEditCommercial: boolean
  canEditTm: boolean
  canSeeReminderTelemarketing?: boolean
  canChangeProposalPromoter: boolean
  showChangePromoterModal: (proposalId?: string, proposalCode?: number, promoterId?: string) => void
}

export interface IProposalColumns extends IUsersLinkedProposal {
  canRefuseProposal: boolean
  refuseProposal?: Function
  canSeeProviderColumns?: boolean
  canSeeSLAColumns?: boolean
  canSeeActionColumn?: boolean
  canSeeClosedReminded?: boolean
  areaNameUser?: boolean
  seeOnlyProviderDate?: boolean
  canSeeReminderTelemarketing?: boolean
  canSeeAnalysisCollum: boolean
  declineProposalDrawerInfo?: IDrawer,
  canSeeCommercialAttendant: boolean
  canEditCommercialAttendant: boolean
  showModalCommercial: (commercial: ICommercial, leadId: string) => void
  canSeeCommercialReminder: boolean
}

export interface StepProps {
  id: string
  personType?: _personTypeEnum
  step: IStep
  hasNext?: boolean
  canReview?: boolean
  onClick?: (...args: any[]) => void
  form?: IPerson
  documents?: IDocuments
  avaliations?: Array<any>
  signatures?: Array<ISignatures>
  title?: string
  departmentRef?: string
  areaId: string
}

export interface IDepartmentCardProps {
  picture: React.ReactNode
  actions?: IAction[]
  onClick?: (step: StepProps) => void;
  onReview?: (...args: any[]) => void;
  status?: string
  department:IDepartment
  departmentStatus?: _proposalStatuses
}

export interface IAvaliationsProps {
  title: string
  fields: any
  areaId: string
  fetchLogs: Function
  fetchProposals: Function
}

export enum _cndSlugToFetch {
  crf = 'certificado-de-regularidade-do-fgts'
}

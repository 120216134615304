import React, { useState } from 'react'

import {
  CalculatorOutlined, FileDoneOutlined,
  FileOutlined, FileSearchOutlined,
  FundViewOutlined, GlobalOutlined,
  PercentageOutlined, ShareAltOutlined,
  ShopOutlined, TeamOutlined,
  UserOutlined
} from '@ant-design/icons'
import { AuthorizationsUser } from 'ecp/repositories/LoginRepository'
import TokenVerificationSVG from 'assets/reactSvgs/TokenVerificationSVG/TokenVerificationSVG'
import translate from 'utils/translate'
import { Modal, Spin } from 'antd'

type CommomProps = {
  onSelect: (user: AuthorizationsUser) => Promise<void>
  users: AuthorizationsUser[]
  loading: boolean
}

type SelectLevelProps = {
  users: AuthorizationsUser[]
} & CommomProps

type ModalSelectProps = {
  visible: boolean
  onClose: () => void
  title: string
  description: string
  children: React.ReactNode
}

export function LevelIcon ({ level }: { level: string }) {
  if (!level) return <UserOutlined />

  switch (level) {
    case 'master':
      return <GlobalOutlined />
    case 'backoffice':
      return <FileSearchOutlined />
    case 'client':
      return <UserOutlined />
    case 'promoter':
    case 'administrator':
      return <TeamOutlined />
    case 'provider':
      return <FileDoneOutlined />
    case 'commercial':
      return <ShopOutlined />
    case 'auditor':
      return <FundViewOutlined />
    case 'commission':
      return <PercentageOutlined />
    case 'pricing':
      return <CalculatorOutlined />
    case 'consultant':
      return <FileOutlined />
    case 'seller':
      return <ShareAltOutlined />
    default:
      return <UserOutlined />
  }
}

function SelectLevel ({ users, onSelect, loading }: SelectLevelProps) {
  const [loadingId, setLoadingId] = useState('')

  return (
    <div className='select-level'>
      {Array.isArray(users) && users.map(user => (
        <button
          className='select-level__button'
          key={user._id}
          onClick={async () => {
            setLoadingId(user._id)
            await onSelect(user)
            setLoadingId(user._id)
          }}
        >
          <div className='select-level__icon-container'>
            <LevelIcon level={user.level} />
          </div>

          <div className='select-level__info-container'>
            <p className='select-level__level'>{translate.level(user.level)}</p>
            <p className='select-level__name'>{user.name}</p>
          </div>

          {(loading && loadingId === user._id) && <Spin className='select-level__icon'/>}
        </button>
      ))}
    </div>
  )
}

interface IModalSelect {
  visible: boolean
  onClose: () => void
  title: string
  description: string
}

export function ModalSelect ({
  visible,
  onClose,

  title,
  description,
  children
}: ModalSelectProps) {
  return (
    <Modal
      centered
      className="select-level-modal"
      visible={visible}
      destroyOnClose
      onCancel={onClose}
      footer={null}
    >
      <div className='select-level-modal__icon-container'>
        <TokenVerificationSVG />
      </div>

      <div className='select-level-modal__text-container'>
        <h2 className='select-level-modal__title'>{title}</h2>
        <p className='select-level-modal__subtitle'>{description}</p>
      </div>

      {children}
    </Modal>
  )
}

export function ModalSelectLevel ({
  users,
  onSelect,
  loading,

  ...props
}: IModalSelect & CommomProps) {
  return (
    <ModalSelect
      visible={props.visible}
      onClose={props.onClose}
      title={props.title}
      description={props.description}
    >
      <SelectLevel
        loading={loading}
        onSelect={onSelect}
        users={users}
      />
    </ModalSelect>
  )
}

export default SelectLevel

import BigNumber from 'bignumber.js'
import dayjs, { Dayjs } from 'dayjs'
import mask from './masks'
import { timeAsDayjs } from './time'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)
dayjs.extend(utc)

const format = {
  /**
   * Formats a string to show a cellphone number, adding '+55' to it's start.
   * @param value - String to be formatted.
   * @returns A string containing a cellphone number.
   */
  onlyDigits: (value: string) => {
    if (!value && value !== '0') return ''
    return value
      .replace('+55', '')
      .replace(/[\D]/g, '')
  },

  /**
   * Formats a string to show only digits.
   * @param value - String to be formatted.
   * @returns A string containing only numbers.
   */
  onlyNumber: (value: string | number | undefined | null) => {
    if (!value) return ''
    return String(value).replace(/\D/g, '')
  },

  /**
   * Formats a RG to show only it's numbers(or 'X'), removing any dot or hyphen.
   * @param value - String to be formatted
   * @returns the formatted RG.
   */
  formatRg: (value: string) => {
    if (!value) return ''
    return value.replace(/[^0-9|^(xX)]/g, '').toUpperCase()
  },

  /**
   * Formats a string removing anything that isn't a number or alphabetic character.
   * @param value - String to be formatted
   * @returns the formatted string.
   */
  onlyAlphaNumeric: (value: string) => {
    if (!value) return ''
    return String(value).replace(/[^a-z0-9]/gi, '')
  },

  /**
   * Formats the value to it extent BRL currency representation.
   * @param value - Number to be formatted.
   * @returns A BRL extent currency string.
   * @example 123 must result 'R$ 123.000,00'
   */
  formatBRL: (value: number | undefined): string => {
    if (!value) return 'R$ ' + 0
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  },

  /**
   * Formats the value to it extent BRL currency representation and round down.
   * @param value - Number to be formatted.
   * @param precision - Precision from the NumberFormat: default 2
   * @returns A BRL extent currency string.
   * @example 123 must result 'R$ 123,00'
   */
  precisionFormatBRL: (value: number | undefined, precision = 2): string => {
    if (!value) return 'R$ 0,00'

    const bignumber = new BigNumber(value)

    const options = {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: precision,
      maximumFractionDigits: precision
    }

    const fixedNumber = bignumber.toFixed(2, BigNumber.ROUND_DOWN)
    const formatedNumber = new BigNumber(fixedNumber).toNumber()

    return new Intl.NumberFormat('pt-BR', options).format(
      formatedNumber
    )
  },

  /**
   * Formats the value to it extent BRL currency representation.
   * **This function also uses { minimunFractionDigits: 0 } on it's callback.**
   * @param value - Number to be formatted.
   * @returns A BRL extent currency string.
   * @example 123 must result 'R$ 123.000,00'
   */
  formatBRLMinAndMax: (value: number | undefined): string => {
    if (!value) return 'R$ ' + 0
    return (value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 0 })
  },

  /**
   * Formats a number to its compact BRL currency representation.
   * @param value - Number to be formatted.
   * @returns A BRL compact currency string.
   * @example 123 must result 'R$ 123 mil'
   */
  compressedMoney: (value: number | undefined): string => {
    if (!value) return 'R$ ' + 0

    const valueFormatted = new Intl.NumberFormat('pt-BR', { notation: 'compact', style: 'currency', currency: 'BRL' }).format(value)
    return valueFormatted.toUpperCase()
  },

  /**
   * Formats the value to its compressed currency format representation.
   * @param value - The number to be formatted.
   * @returns A BRL compressed currency string (e.g., '123 MIL').
   * @example 123 must result '123 MIL'
   */
  compressedNumber: (value: number | undefined): string => {
    if (!value) return '' + 0

    const valueFormatted = new Intl.NumberFormat('pt-br', { notation: 'compact', style: 'decimal' }).format(value)
    return valueFormatted.toUpperCase()
  },

  /**
   * Formats the first letter of a string to uppercase.
   * @param s - String to be formatted
   * @returns A capitalized string
   */
  capitalize: (s: string) => {
    if (!s) return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  },

  brasilDate: (value: Dayjs): string => {
    return timeAsDayjs(value, { applyTimezone: false }).format('DD/MM/YYYY')
  },

  /**
   * Organizes an string array to a more human-readable format.
   * @param values - Array string to organize.
   * @returns A string with all elements of the array separated by a comma and 'e' for the last two elements.
   */
  separeteValuesByComma: (values: Array<string>) => {
    if (!values) return []
    if (values.length < 1) return values
    return values.reduce(
      (text, value, i, array) =>
        text + (i < array.length - 1 ? ', ' : ' e ') + value
    )
  },

  /**
   * Formats a currency value to his number representation
   * @param value - String containing currency
   * @returns Number with two decimal cases
   */
  formatMoneySend: (value: string): number => {
    if (!value) return 0
    let convertValue = String(value)
    const hasComma = convertValue.indexOf(',') !== -1

    if (convertValue.indexOf('R$') !== -1) {
      const temp = convertValue.split(',')
      temp[0] = temp[0].replace(/\D/g, '')
      convertValue = temp.join('.')
    } else { if (hasComma) convertValue = convertValue.replace(/,/g, '.') }

    const numberValue = Number(convertValue)
    return Number(numberValue)
  },

  currencyParser: (val: string) => {
    // for when the input gets clears
    if (typeof val !== 'string' || !val.length) {
      val = '0.0'
    }

    // detecting and parsing between comma and dot
    const group = new Intl.NumberFormat('pt-BR').format(1111).replace(/1/g, '')
    const decimal = new Intl.NumberFormat('pt-BR').format(1.1).replace(/1/g, '')
    let reversedVal = val.replace(new RegExp('\\' + group, 'g'), '')
    reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.')

    //  => 1232.21 €
    // removing everything except the digits and dot
    reversedVal = reversedVal.replace(/[^0-9.]/g, '')
    //  => 1232.21
    // appending digits properly

    const digitsAfterDecimalCount = (reversedVal.split('.')[1] || []).length
    const needsDigitsAppended = digitsAfterDecimalCount > 2

    if (needsDigitsAppended) {
      reversedVal = (Number(reversedVal) * Math.pow(10, digitsAfterDecimalCount - 2)).toString()
    }

    return Number.isNaN(reversedVal) ? 0 : reversedVal
  },

  /**
   * Transforms a decimal number to its percentage representation.
   * @param value - Decimal number.
   * @returns Percentage number.
   */
  decimalToPercentage: (value: number | undefined): number => {
    if (!value) return 0
    return value * 100
  },

  /**
   * Transforms a percentage number to its decimal representation.
   * @param value - Percentage number.
   * @returns Decimal number.
   */
  percentageToDecimal: (value: number): number => {
    if (!value) return 0
    return value / 10 / 10
  },

  /**
   * Formats a string to a number with 4 decimal places.
   * @param value - A string or number to be formatted.
   * @returns A number with 4 decimal places.
   */
  precisionFloat: (value: string | number): number => {
    if (!value) return 0
    return Number(Number(value).toPrecision(4))
  },

  /**
   * Formats a percentage number to its decimal representation with four decimal places.
   * @param value - Number to be formatted.
   * @returns A decimal number with four decimal places.
   */
  precisionAndPercentage: (value: number): number => {
    return format.precisionFloat(format.percentageToDecimal(value))
  },

  /**
   * Formats a decimal number to its percentage representation with four decimal places.
   * @param value - Number to be formatted.
   * @returns A percentage number qith four decimal places.
   */
  precisionAndDecimal: (value: number): number => {
    return format.precisionFloat(format.decimalToPercentage(value))
  },

  /**
   * Formats the value to its precise percentage representation, meaning that no rounding is applied in the number.
   * The result percentage will always use the first two decimal numbers.
   * If the number has only one decimal number, the result will add a 0 to the end of the string.
   * @param value - The number to be formatted.
   * @returns A precise percentage string.
   * @example 0.01 must result '1%'.
   * @example 0.1 must result '10%'
   * @example 0.000003 must result'0%'
   * @example 0.04444 must result '4.44%'
   * @example 1.23 must result'123%'
   */
  precisionPercentageConvert: (value: number) => {
    if (!value) return 0

    let v: string = value.toString()

    if (Number(v) >= 1) v = String(Number(v) * 100)

    if (Number(v) < 1) {
      const a = v.split('.')
      const length = a[1].length
      if (Number(v) === 0) v = String(0)

      if (length < 4) {
        v = a[1]
        v = length === 2 ? String(Number(v) * 100 / 100) : length === 1 ? String(Number(v) * 1000 / 100) : String(Number(v) * 100 / 100 / 10)
      }

      if (length >= 4) {
        v = a[1]
        v = v.substr(0, 4)
        v = String(Number(v) / 100)
      }
    }

    return Number(v)
  },

  currencyInputFormat: (money: string) => {
    const currencyRegExp = new RegExp(`(\\d{1,${100}})(.)?(\\d{2})`, 'g')
    const pointsRegExp = /(?=(\d{3})+(\D))\B/g
    if (money.length === 5 && money[money.length - 1] !== '0') {
      return money
        .replace(/\D/g, '')
        .replace('0', '')
        .replace(currencyRegExp, (match, p1, p2, p3) => [p1, p3].join(','))
        .replace(pointsRegExp, '.')
    } else {
      return money
        .replace(/\D/g, '')
        .replace(currencyRegExp, (match, p1, p2, p3) => [p1, p3].join(','))
        .replace(pointsRegExp, '.')
    }
  },

  /**
   * Formats the value to its number representation.
   * @param value - Date value which can be string or number.
   * @returns Number or undefined.
   */
  onlyMoney: (value?: string | number): number | undefined => {
    if (!value) return undefined
    if (typeof value === 'number') return value
    if (value.slice(-3).includes('.')) return Number(value)

    return Number(value.replace(/[^0-9,]/g, '').replace(/,/g, '.'))
  },

  /**
   * Formats a string or number to it's percentage representation by adding a '%' to it's end.
   * @param value - String or Number to be formatted.
   * @returns String
   */
  numberToPercentage: (value: number | string): string => {
    if (!value) return '-'
    if (value === '-') return '-'
    return `${value}%`
  },

  /**
   * Formats a string to it's pix format.
   * @param value - String to be formatted.
   * @param type - String representing the Pix's key type.
   * @return String.
   */
  formatPix: (value: string, type: string) => {
    if (!value) return '-'
    switch (type) {
      case ('cpf'):
        return mask((value as string), 'cpf', true)
      case ('cnpj'):
        return mask((value as string), 'cnpj', true)
      case 'email':
      case 'randomKey':
      case 'cellphone':
      case 'cell':
        return value
      default:
        return '-'
    }
  },

  /**
   * Formats a string by replacing a hyphen to a empty space from it's content.
   * **It only removes the first found hyphen from the string.**
   * @param value - String to be formatted.
   * @returns String with a hyphen replaced by an empty space
   */
  removeHyphen: (value: string) => {
    return value.replace('-', ' ')
  },

  /**
   * Return a percentage value without applying Math.ceil
   * @param value number value to transform in percentage
   * @param options in the options is possible to pass the separator to apply after the match
   * Examples of use:
   * - 23.9912312 to 23.99
   * - 0.199999 to 0.19
   */
  toPercentage: (value: number, options?: { separator?: string, limit?: number }): string => {
    const { separator, limit } = options || {}
    const percentageLimit = limit || 100

    if (value === null || value === undefined) return '0'
    if (value > percentageLimit) return percentageLimit.toString()
    const matchs = (String(value).match(/^(\d{1,3})\.?(\d{1,2})?/g)?.[0] || '0')
    if (separator) return matchs.replace('.', separator)
    return matchs
  },

  /**
   * Formats a string to it's Boolean representation.
   * It works by using Double Negation to check the string used as param.
   * @param value - String to be formatted.
   * @returns Boolean
   */
  stringToBoolean: (value?: string): boolean | undefined => {
    if (value === undefined) return undefined
    if (value === 'false') return false
    return !!value
  },

  /**
   * Formats a string to Dayjs by using timeAsDayJs function.
   * @param value - String.
   * @returns Dayjs.
   */
  stringDateToObject: (value: string): Dayjs => {
    return timeAsDayjs(value)
  },

  /**
   * Formats a string to a name and a last name separated by a empty space.
   * @param name - String_(optional)_
   * @param lastName - String_(optional)_
   * @returns **name** and **lastName** separated by a empty space, or '-' if **name** was not provided.
   */
  nameAndLastname: (name: string | undefined, lastName: string | undefined): string => {
    if (!name) return '-'
    if (!lastName) return name
    return name + ' ' + lastName
  },

  /**
   * Formats a name to show the first name and the first letter of the last name.
   * @param value - String containing the name to be formatted.
   * @returns String with the formatted name.
   * @example 'John Doe' must result 'John D.'
   */
  formatName: (value?: string) => {
    if (!value) return 'N/A'

    const newName = value.split(' ')
    const firstName = newName[0]
    const lastName = newName[1] ? `${newName[1].charAt(0)}.` : ''
    return `${firstName} ${lastName}`.toUpperCase()
  },

  /**
   * Formats a string to it's plural form by adding a 's' to it's end.
   * @param length - Number that will determine if the string will be formatted to it's plural form or don't
   * @param value - String that will be passed to plural.
   * @returns String
   */
  plural: (length: number, value:string) => {
    if (length > 1) return value + 's'
    return value
  },

  cellphoneWithoutDDI: (value: string) => {
    type _regexDDIMap = {
      [country: string]: RegExp;
    };

    const regexDDIMap: _regexDDIMap = {
      brasil: /^\+55\s*/,
      eua: /^\+1\s*/,
      canada: /^\+1\s*/,
      franca: /^\+33\s*/,
      alemanha: /^\+49\s*/,
      argentina: /^\+54\s*/,
      chile: /^\+56\s*/,
      mexico: /^\+52\s*/,
      espanha: /^\+34\s*/
    }

    for (const country in regexDDIMap) {
      if (regexDDIMap[country].test(value)) {
        return value.replace(regexDDIMap[country], '')
      }
    }

    return value
  },
  formatList: (list: string[]) => {
    if (!list.length) return ''
    if (list.length === 1) return list[0]

    const copiedList = list.slice()
    const lastItem = copiedList.pop()
    const listaFormatada = copiedList.join(', ') + ' e ' + lastItem
    return listaFormatada
  },

  jsonToString: (value: any) => JSON.stringify(value),

  /**
   * Converts a date string in Brazilian format ("dd/MM/yyyy") or ISO 8601 format ("yyyy-MM-ddTHH:mm:ss.sssZ")
   * to ISO 8601 format ("yyyy-MM-ddTHH:mm:ss.sssZ").
   *
   * @param {string} value - The date string in the format "dd/MM/yyyy" or "yyyy-MM-ddTHH:mm:ss.sssZ".
   * @returns {string | undefined} - The date string in ISO 8601 format ("yyyy-MM-ddTHH:mm:ss.sssZ"), or undefined if the date is invalid.
   *
   * @example
   * // Convert a date from Brazilian format to ISO 8601 format
   * const isoDate = brDateToDate("30/08/2024");
   * console.log(isoDate); // Output: "2024-08-30T00:00:00.000Z"
   *
   * @example
   * // Return the same ISO 8601 date string if input is already in ISO format
   * const isoDate = brDateToDate("2024-08-29T23:00:00.000Z");
   * console.log(isoDate); // Output: "2024-08-29T23:00:00.000Z"
   *
   * @example
   * // Handling leap years in Brazilian format
   * const isoDate = brDateToDate("29/02/2020");
   * console.log(isoDate); // Output: "2020-02-29T00:00:00.000Z"
   */
  brDateToDate: (value?: string): string | undefined => {
    if (!value) return undefined

    const isoDate = dayjs(value)
    if (isoDate.isValid() && value.includes('T')) {
      return isoDate.toISOString()
    }

    const brDate = dayjs(value, 'DD/MM/YYYY', true)
    if (brDate.isValid()) {
      return brDate.toISOString()
    }

    return undefined
  },

  percentageWithMathTrunc: (value?: number) => {
    if (value === undefined) return '-'
    return `${Math.trunc((value || 0) * 1e2) / 1e2}%`
  }

}

export default format

import { AxiosRequestConfig } from 'axios'
import { Repository } from 'ecp/repositories/Repository'
import { ILead, IProposal } from 'egi/app/User/userInterfaces'
import api from 'services/api'

export interface IClientList {
  _id: string
  cpf: string
  name: string
  selfie?: string
  canceled?: boolean
}

class ClientRepository extends Repository<IClientList> {
  async proposalById (proposalId: string, config?: AxiosRequestConfig) {
    return Repository.handle<{ client: { name: string } }>(() =>
      this.api.get(`${this.path}/proposal/${proposalId}`, config)
    )
  }

  async getLeads (clientId: string, config?: AxiosRequestConfig) {
    return Repository.handle<{ leadInfo: ILead }>(() =>
      this.api.get(`${this.path}/${clientId}/lead`, config)
    )
  }

  async getProposal (clientId: string, config?: AxiosRequestConfig) {
    return Repository.handle<{ proposalInfo: IProposal }>(() =>
      this.api.get(`${this.path}/${clientId}/proposal`, config)
    )
  }
}

export default new ClientRepository({ api, path: '/clients' })

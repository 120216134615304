import { Repository } from './Repository'
import api from 'services/api'

import { IChangeEmail, IChangePhone, IChangeProfileConfirm } from 'egi/app/Profile/profileInterfaces'

class EcpUsersRepository extends Repository<{}> {
  async updateEmailOrPhone (data: Partial<IChangeEmail> | Partial<IChangePhone>) {
    return Repository.handle(() =>
      this.api.patch(`${this.path}/update`, data)
    )
  }

  async confirmToken (data: IChangeProfileConfirm) {
    return Repository.handle(() =>
      this.api.post(`${this.path}/confirm`, data)
    )
  }
}

export default new EcpUsersRepository({ api, path: '/ecp/users' })

import { AxiosRequestConfig } from 'axios'
import { PermissionsRepository, UserPermissionsRepository } from 'egi/repositories/PermissionsRepository'

export class PermissionNotFound extends Error {
  name = 'PERMISSION_NOT_FOUND';
  message = 'Permissão não encontrada!';
}

export class PermissionsService {
  getAll (config?: AxiosRequestConfig) {
    return PermissionsRepository.fetchAll(config)
  }
}

export class UserPermissionsService {
  delete (userPermissionId: string, config?: AxiosRequestConfig) {
    return UserPermissionsRepository.delete(userPermissionId, config)
  }

  getAllByUser (userId: string, config?: AxiosRequestConfig) {
    return UserPermissionsRepository.getAllByUser(userId, config)
  }

  vinculate (body: { permissionId: string, userId: string }, config?: AxiosRequestConfig) {
    return UserPermissionsRepository.vinculate(body, config)
  }
}

import format from './format'

const shareOnSocialMedia = {
  whatsapp: ({ phoneNumber, message }:{phoneNumber: string, message: string}) => {
    const formattedPhone = '+55' + format.onlyDigits(phoneNumber)
    const formattedMessage = encodeURIComponent(message)

    const a = document.createElement('a')
    a.target = '_blank'
    a.rel = 'noreferrer'
    a.href = `https://api.whatsapp.com/send?phone=${formattedPhone}&text=${formattedMessage}`

    a.click()
  },
  whatsappNumberless: (message: string) => {
    const formattedMessage = encodeURIComponent(message)
    const a = document.createElement('a')
    a.target = '_blank'
    a.rel = 'noreferrer'
    a.href = `https://wa.me/?text=${formattedMessage}`

    a.click()
  },
  email: ({ email, message }:{email: string, message: string}) => {
    const formattedMessage = encodeURIComponent(message)
    const a = document.createElement('a')
    a.target = '_blank'
    a.rel = 'noreferrer'
    a.href = `mailto:${email}?subject=&body=${formattedMessage}`

    a.click()
  }
}

export default shareOnSocialMedia

import loadable from '@loadable/component'
import Loading from 'components/Loading/Loading'
import { timeout } from 'promise-timeout'
import React from 'react'
import { TIMEOUT_LOADER } from 'utils/globals'

const LogsOnidata = loadable(
  () => timeout(import(/* webpackChunkName: "Logs" */ 'ecp/app/Logs/views/LogsOnidata/LogsOnidata'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const LogsOnidataDetails = loadable(
  () => timeout(import(/* webpackChunkName: "Logs" */ 'ecp/app/Logs/views/LogsOnidataDetails/LogsOnidataDetails'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const LogsMenu = loadable(
  () => timeout(import(/* webpackChunkName: "Logs" */ 'ecp/app/Logs/views/LogsMenu/LogsMenu'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const LogsOnidataQueues = loadable(
  () => timeout(import(/* webpackChunkName: "Logs" */ 'ecp/app/Logs/views/LogsOnidataQueues/LogsOnidataQueues'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const SecurityLogs = loadable(
  () => timeout(import(/* webpackChunkName: "Logs" */ 'ecp/app/Logs/views/SecurityLogs/SecurityLogs'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const LogErrorsList = loadable(
  () => timeout(import(/* webpackChunkName: "Logs" */ 'ecp/app/Logs/views/LogAPIList/LogAPIList'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

export { LogsOnidata, LogsOnidataDetails, LogsMenu, LogsOnidataQueues, SecurityLogs, LogErrorsList }

import socketIO from 'socket.io-client'
import { reactAppServer } from './config'
import { getToken } from 'utils/localStorage'

class SocketSingleton {
  private static instance: SocketSingleton
  public io: typeof socketIO.Socket | undefined

  constructor () {
    if (SocketSingleton.instance) {
      return SocketSingleton.instance
    }

    const token = getToken()
    const bearer = token ? { bearer: token } : {}
    const baseUrl = reactAppServer || ''

    this.io = socketIO(baseUrl, { query: bearer, autoConnect: false })

    SocketSingleton.instance = this
  }

  connect () {
    if (!this.io?.connected) {
      console.log('connected to socket')
      this.io?.connect()
    }
  }

  disconnect () {
    if (this.io?.connected) {
      console.log('disconnected from socket')

      this.io.disconnect()
    }
  }
}

export { SocketSingleton }

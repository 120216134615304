
import { FileTextOutlined, MailFilled, TagsOutlined } from '@ant-design/icons'
import { Skeleton } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import LetterAvatar from 'ecp/components/LetterAvatar/LetterAvatar'

import React from 'react'
import translate from 'utils/translate'

export interface IUserChat {
  email: string
  protocol: string
  reason: string
  level: string
  creatorId: string
}

interface IProps {
  loading: boolean
  chatUser?: IUserChat
  visible: boolean
  onCancel: () => void
  name?: string
}

const UserInfoContainer = ({ user, name }: { user?: IUserChat, name?: string }) => {
  return (
    <>
      <LetterAvatar name={name} />

      <div className="generic-chat__user-info-container">
        <div>
          <MailFilled className="generic-chat__user-info-icon" />
          <b>Email:</b>
          &nbsp;&nbsp;{user?.email ?? '-'}
        </div>

        <div>
          <TagsOutlined className="generic-chat__user-info-icon" />
          <b>Protocolo:</b>
            &nbsp;&nbsp;#{user?.protocol ?? '-'}
        </div>

        <div>
          <FileTextOutlined className="generic-chat__user-info-icon" />
          <b>Motivo:</b>
          &nbsp;&nbsp;{user?.reason ?? '-'}
        </div>

        <div>
          <FileTextOutlined className="generic-chat__user-info-icon" />
          <b>Nível:</b>
          &nbsp;&nbsp;{user?.level ? translate.level(user?.level) : '-'}
        </div>
      </div>
    </>
  )
}

const Loading = () => {
  return (
    <>
      <Skeleton.Avatar size='large' className="px-2" />
      <Skeleton />
    </>
  )
}

function GenericChatUserModal ({ visible, chatUser, onCancel, loading, name }: IProps) {
  return (
    <Modal
      closable={true}
      title={'Detalhes do Usuário'}
      visible={visible}
      footer={null}
      onCancel={() => onCancel()}
      destroyOnClose={true}
    >
      <div className="generic-chat__user-info-main">
        {loading && <Loading />}
        {!loading && <UserInfoContainer user={chatUser} name={name} />}
      </div>
    </Modal>
  )
}

export default GenericChatUserModal

import { ReactNode } from 'react'
import { _inputMask } from 'globals'
import format from 'utils/format'
import translate from 'utils/translate'
export interface IAccessManagerDetails {

}

export enum AccessManagerDetailsGroupEnum {
  personalData = 'personalData',
  configurations = 'configurations',
  lgpd = 'lgpd',
  onidata = 'onidata',
  account = 'account',

  proposal = 'proposal',
  client = 'client',
  user = 'user',

  reference= 'reference',
  document = 'document',
  address = 'address',
  payment = 'payment',
  job = 'job',

  others = 'others'
}

export interface IDetailSchema {
  key: Array<string>
  label: string
  group: AccessManagerDetailsGroupEnum
  format?: keyof typeof format
  mask?: _inputMask
  translate?: keyof typeof translate
  Component?: (item: IDetailSchema, data: Object) => ReactNode
}

import { _userLevel } from 'ecp/models/UsersModel'
import { _contactTypes } from 'egi/types/IDrawer'

const ContactModel = {

  canSeeHistory (userLevel: _userLevel): boolean {
    return userLevel !== 'client'
  },

  isClientContact (userLevel: _userLevel, type:_contactTypes): boolean {
    return userLevel === 'client' && type === 'contact'
  },

  isNotClientWithClientId (userLevel: _userLevel, type:_contactTypes, id: string | undefined): boolean {
    if (!id) return false
    return userLevel !== 'client' && type === 'contact'
  },

  isNotClientWithOutClientId (userLevel: _userLevel, type:_contactTypes, id: string | undefined): boolean {
    return !id && userLevel !== 'client' && type === 'contact'
  },

  canSendEmail (userLevel: _userLevel): boolean {
    return userLevel !== 'auditor'
  },

  canSeeApplicantField (userLevel: _userLevel): boolean {
    return userLevel !== 'client'
  },

  canSeeSelectCategoryDrawer (userLevel: _userLevel, type: _contactTypes): boolean {
    return type === 'contact' && userLevel === 'client'
  }

}

export default ContactModel

import { message, Result } from 'antd'
import { ResultStatusType } from 'antd/lib/result'
import CopyIcon from 'assets/icons/copy.svg'
import { prettyPrintJson } from 'pretty-print-json'

import React, { useEffect } from 'react'
import { copyToClipboard } from 'utils/utils'
import { IErrorDetails } from './interfaces'
import { errorCode } from './functions'
import { IErrorDetailsECPF } from 'components/ErrorDetailsECPF/ErrorDetailsECPF'

interface IProps {
  status?: ResultStatusType
  title: string
  subTitle?: string
  error: IErrorDetails
  extra?: React.ReactNode;
}

export const generateErrorDetails = (error: IErrorDetails | IErrorDetailsECPF) => {
  const html = prettyPrintJson.toHtml(error)

  const footer = `<input id='error-details-toggle' class='error-details__checkbox' type='checkbox'/>
  <label for='error-details-toggle' class='error-details__label error-details__label-on'>Ver Detalhes</label>
  <label for='error-details-toggle' class='error-details__label error-details__label-off'>Fechar Detalhes</label>
  <div class='error-details__wrapper'><pre class=json-container">${html}</pre>
  </div>`

  document.querySelector('#toggle-content')!.innerHTML = footer

  const wrapper = document.querySelector('.error-details__wrapper')
  const copyButton = document.createElement('button')

  copyButton.onclick = () => {
    copyToClipboard(JSON.stringify(error.data || error))
    message.success({ content: 'Mensagem copiada' })
  }
  copyButton.classList.add('error-details__copy')
  copyButton.innerHTML = `<img src='${CopyIcon}'/>`

  wrapper?.appendChild(copyButton)
}

export default function ErrorDetails ({ status, subTitle, title, error, extra }:IProps) {
  const errorStatus = status ?? (error.code && errorCode(error.code)) ?? 'warning'

  useEffect(() => {
    generateErrorDetails(error)
  }, [error])

  return (
    <div className="error-details">
      <Result status={errorStatus} subTitle={subTitle} title={title} extra={extra}/>
      <div className="error-details__container">
        <div className="error-details__toggle">
          <span id="toggle-content" className="error-details__toggle-content"></span>
        </div>
      </div>
    </div>
  )
}

import { Empty, Spin, Timeline } from 'antd'
import React, { useLayoutEffect, useState } from 'react'
import { useParams } from 'react-router'
import { leadsColor } from 'styles/colors'
import swal from 'utils/swal'
import translate from 'utils/translate'
import { timeAsDayjs } from 'utils/time'
import LeadsRepository, { ILeadHistory } from 'egi/repositories/LeadsRepository'
import { ArrowRightOutlined } from '@ant-design/icons'

function LeadHistory () {
  const params: { id: string } = useParams()
  const leadId = params.id

  const [loading, setLoading] = useState<boolean>(false)
  const [history, setHistory] = useState<ILeadHistory[]>()

  useLayoutEffect(() => {
    async function fetchHistory (leadId: string) {
      setLoading(true)
      try {
        const response = await LeadsRepository.history(leadId)
        const { history, name } = response.data?.data || {}

        const element = document.querySelector('#auth-page-title')
        if (element && element.innerHTML.indexOf(':') === -1) {
          element.insertAdjacentHTML('beforeend', ': ' + name)
        }

        if (history) setHistory(history)
      } catch (err) {
        swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      }
      setLoading(false)
    }

    if (leadId) fetchHistory(leadId)
  }, [])

  if (loading) {
    return (
      <div className="mt-3 flex flex-justify-center">
        <Spin size="large" />
      </div>
    )
  }

  return (
    <div className="pt-4 proposal-container-card">
      <Timeline mode="alternate" className="lead-history-item-tail">
        {!history?.length && (
          <Empty description="Nenhum histórico encontrado." />
        )}

        {history?.length && history.map(item => (
          <Timeline.Item
            key={item._id}
            color={leadsColor[item?.new?.status] || ''}
          >
            <div>
              <h2 style={{ borderBottom: '2px solid #242424', display: 'inline-block' }}>{timeAsDayjs(item?.updatedAt).format('DD/MM/YYYY HH:mm')} - {item?.issuer?.name ?? '-'}</h2>
              <h3>Status: <label className="bold" style={{ color: leadsColor[item?.new?.status as any || 'starting'] }}>{translate.leadStatus(item?.new?.status ?? '-') ?? '-'}</label></h3>
              {item?.declinedReason && <h4>Motivo: {item?.declinedReason}</h4>}
              <p>{item?.message}</p>
            </div>
            <h3>Alteração de backoffices:</h3>
            <h4>Modificado por: {item?.issuer?.name ?? '-'} - ({translate.level(item?.issuer?.level) ?? '-'} {translate.areasNames(item?.issuer?.areaName) ?? '-'})</h4>
            <h4>{item?.last?.name ?? '-'} <ArrowRightOutlined/> {item?.new?.name ?? '-'}</h4>
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
  )
}

export default LeadHistory

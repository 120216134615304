import React from 'react'
import SettingsCard from 'egi/app/Settings/components/SettignsCard/SettingsCard'
import OperationalLimit from 'egi/app/OperationalLimit/views/OperationalLimit'

function OperationalLimitSettings () {
  return (
    <>
      <SettingsCard.Header
        title="Limite operacional"
        description={
          <>
            <p>
              Configure aqui os valores para o <b>limite operacional</b> da proposta.
              Ao fazer alguma alteração os valores refletirão na proposta.
            </p>
          </>
        }
      />

      <SettingsCard>
        <SettingsCard.title>
          Limite operacional
        </SettingsCard.title>
        <SettingsCard.description className="mb-4">
        Se os valores excederem os limites estabelecidos para cada alçada, será necessária a aprovação de crédito correspondente.
        </SettingsCard.description>
        <OperationalLimit/>
      </SettingsCard>

    </>
  )
}

export default OperationalLimitSettings


import { DeleteOutlined, PaperClipOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, message, Upload } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import { UploadFile, UploadProps } from 'antd/lib/upload/interface'
import { downLoadModelRepostory } from 'egi/repositories/NotaryFeesRepository'

import React, { useState } from 'react'

interface IProps {
  visible: boolean
  onCancel: () => void

}

function UploadNotaryFeesModelModal ({ visible, onCancel }: IProps) {
  const [loading, setLoading] = useState<boolean>()
  const [loadingSend, setLoadiingSend] = useState<boolean>(false)
  const [file, setFile] = useState<UploadFile>()

  const uploadNotaryFees = async (file: UploadFile) => {
    setLoadiingSend(true)
    try {
      const formData = new FormData()
      formData.append('file', file?.originFileObj as Blob)
      await downLoadModelRepostory.uploadModel(formData)
      message.success(`o arquivo ${file.name} está sendo processado`)
      onCancel()
    } catch (err) {
      message.error(err.message || `Não foi possível enviar o arquivo ${file.name}`)
    } finally {
      setLoadiingSend(false)
    }
  }

  const props: UploadProps = {
    name: 'file',
    maxCount: 1,
    showUploadList: false,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange: (info) => {
      const { file } = info
      if (file.status === 'uploading') {
        setLoading(true)
      }
      if ((file.status === 'done' || file.status === 'error') && file.originFileObj) {
        setLoading(false)
        setFile(file)
      }
    },

    beforeUpload: (file) => {
      const isSpreadsheet = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'text/csv'

      if (!isSpreadsheet) {
        message.error(`${file.name} não é um formato válido`)
      }

      return isSpreadsheet
    }
  }

  return (
    <Modal
      title="Fazer upload de modelo de custas cartorárias"
      visible={visible}
      destroyOnClose={true}
      onCancel={onCancel}
      footer={
        <div className="text-right m-2">
          <Button type="primary"
            onClick={() => { if (file) uploadNotaryFees(file) }}
            loading={loadingSend}>
            Enviar
          </Button>
          <Button
            type="ghost"
            onClick={onCancel}>
            Cancelar
          </Button>
        </div>
      }
    >
      <Upload {...props}>
        <Button type="primary"
          icon={<UploadOutlined />}
          className="m-1"
          loading={loading}>
          Importar planilha
        </Button>
      </Upload>

      {file && <div className="mt-2">
        <b>Arquivo selecionado:</b>
        <div className="notary-fees__upload-list-item">
          <span>
            <PaperClipOutlined className="pr-1" />
            {file?.name}
          </span>
          <DeleteOutlined
            className="notary-fees__upload-list-item-delete"
            onClick={() => setFile(undefined)} />
        </div>
      </div>
      }

    </Modal>

  )
}

export default UploadNotaryFeesModelModal

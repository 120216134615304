import React, { useLayoutEffect, useState } from 'react'
import { IFilterDate } from '../../views/MlmDashboard/types'
import { RiseOutlined } from '@ant-design/icons'
import MlmBaseCard from '../MlmBaseCard/MlmBaseCard'
import format from 'utils/format'
import { Skeleton } from 'antd'
import MlmRepository from 'egi/repositories/MlmRepository'

function MlmMonthlyClicks ({ filterDate }: IFilterDate) {
  const [total, setTotal] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [failed, setFailed] = useState<boolean>(false)

  useLayoutEffect(() => {
    const abortController = new AbortController()
    async function fetchApprovedToday () {
      setLoading(true)
      try {
        const response = await MlmRepository.monthly({
          signal: abortController.signal,
          params: filterDate
        })
        const { value } = response.data.data || {}
        if (!value) throw new Error('Falha ao encontrar valores mensais')

        setTotal(value)
        setFailed(false)
        setLoading(false)
      } catch (err) {
        if (!abortController.signal.aborted) {
          setFailed(true)
          setLoading(false)
        }
      }
    }

    if (filterDate.createdDate && filterDate.productId) fetchApprovedToday()
    return () => abortController.abort()
  }, [
    filterDate.createdDate,
    filterDate.productId,
    filterDate.promoterId,
    filterDate.sellerId,
    filterDate.type
  ])

  const finalColor = 'ligth-blue-dash'

  return (
    <div className="dashboard-status-card h-100">
      {loading ? (
        <Skeleton
          active={true}
          paragraph={{ rows: 1 }}
        />
      ) : (
        <MlmBaseCard
          valueColor={finalColor}
          failed={failed}
          value={format.compressedNumber(total)}
          description='Número de cliques'
          Icon={<RiseOutlined className={'dashboard-icon-size color-' + finalColor} />}
        />
      )}
    </div>
  )
}

export default MlmMonthlyClicks

import React from 'react'
import { Drawer } from 'components'
import GenericStep from '../GenericStep/GenericStep'
import useThisStep from 'ecp/app/Proposals/hooks/useThisStep'
import useProposalDrawerWithContext from 'ecp/app/Proposals/hooks/useProposalDrawerWithContext'
import ReviewDrawer from '../ReviewDrawer/ReviewDrawer'
import useDynamicProposal from 'ecp/app/Proposals/hooks/useDynamicProposal'

function GenericStepWithDrawer () {
  const { step } = useThisStep()
  const { isOpen, open, close, sendStep } = useProposalDrawerWithContext()
  const { details } = useDynamicProposal()
  const canOpen = step?.canOpen || false

  return (
    <>
      <GenericStep
        onClick={open}
        enabled={canOpen}
      />

      <Drawer
        visible={isOpen}
        onClose={close}
        title={step?.name || ''}
        footer={null}

      >
        <ReviewDrawer
          close={close}
          sendStep={sendStep}
          step={step}
          proposalId={details?.value?._id || ''}
          clientName={details?.value?.client?.name || ''}
        />
      </Drawer>
    </>
  )
}

export default GenericStepWithDrawer

import { AxiosRequestConfig } from 'axios'
import { _userLevel } from 'ecp/models/UsersModel'
import { Repository } from 'ecp/repositories/Repository'
import { Invite } from 'egi/app/Signup/hooks/useInvite'
import api from 'services/api'

type LevelInviteResponse = {
  link: string,
  inviteId: string
}

export class InviteEgiErrors {
  static invite () {
    return new Error('Falha ao receber identificador de convite')
  }

  static link () {
    return new Error('Falha ao receber link')
  }
}

class InviteEgiRepository extends Repository {
  async levelLink <T> (level: _userLevel, body: T, config?: AxiosRequestConfig) {
    return Repository.handle<{
      link: string,
      inviteId: string
    }>(() =>
      this.api.post(`${this.path}/${level}`, body, config)
    )
  }

  async signup (config?: AxiosRequestConfig) {
    return Repository.handle<{ invite: Invite }>(() =>
      this.api.get(`${this.path}/signup`, config)
    )
  }

  async rh () {
    return Repository.handle<LevelInviteResponse>(() =>
      this.api.post(`${this.path}/RH`)
    )
  }

  async operator () {
    return Repository.handle<LevelInviteResponse>(() =>
      this.api.post(`${this.path}/operator`)
    )
  }

  async administrator <T> (body: T) {
    return Repository.handle<LevelInviteResponse>(() =>
      this.api.post(`${this.path}/administrator/users`, body)
    )
  }
}

export default new InviteEgiRepository({ api, path: '/invites' })

import { _antdStatus } from 'store/modules/notaryFees/notaryFeesActionTypes'

export const activeStyle = {
  background: 'linear-gradient(to right, #0052D4, #65C7F7, #9CECFB)'
}

export const successStyle = {
  background: 'linear-gradient(to right, #0f3443, #34e89e)'
}

export const errorStyle = {
  background: 'linear-gradient(to right, #e35d5b, #e53935)'
}

export const getBackgroundColor = (status: _antdStatus) => {
  switch (status) {
    case 'success':
      return successStyle
    case 'exception':
      return errorStyle
    case 'active':
      return activeStyle
    default: return activeStyle
  }
}

import { AxiosResponse } from 'axios'
import { ErrInternetDisconnected, ConnectionFailed } from './errors'

import api from '../../services/api'

interface IPasswords {
  password: string
  confirmPassword: string
}

interface IChangePassword extends IPasswords {
  token: string
}

const user = {

  password: {
    reset: async (data: {email?: string, cpf?: string}) => {
      try {
        const response: AxiosResponse = await api.post('password/reset', data)
        return response.data
      } catch (err) {
        if (err.name === 'ERR_INTERNET_DISCONNECTED') {
          throw new ErrInternetDisconnected()
        }
        if (!err.response) {
          throw new ConnectionFailed()
        }
        throw err.response.data
      }
    },

    code: async (code: string) => {
      try {
        const response: AxiosResponse = await api.post('/password/reset/code', { code })
        return response.data
      } catch (err) {
        if (err.name === 'ERR_INTERNET_DISCONNECTED') {
          throw new ErrInternetDisconnected()
        }
        if (!err.response) {
          throw new ConnectionFailed()
        }
        throw err.response.data
      }
    },

    change: async ({ password, confirmPassword, token }: IChangePassword) => {
      try {
        const response: AxiosResponse = await api.patch(`/password/change?token=${token}`, { password, confirmPassword })
        return response.data
      } catch (err) {
        if (err.name === 'ERR_INTERNET_DISCONNECTED') {
          throw new ErrInternetDisconnected()
        }
        if (!err.response) {
          throw new ConnectionFailed()
        }
        throw err.response.data
      }
    },

    authReset: async (data: { oldPass: string, password: string, confirmPassword: string }) => {
      try {
        const response: AxiosResponse = await api.patch('/v2/users/password/reset', data)
        return response.data
      } catch (err) {
        if (err.name === 'ERR_INTERNET_DISCONNECTED') {
          throw new ErrInternetDisconnected()
        }
        if (!err.response) {
          throw new ConnectionFailed()
        }
        throw err.response.data
      }
    }
  },

  refreshToken: async (refreshToken: string) => {
    try {
      const response: AxiosResponse = await api.post('/tokens', { refreshToken })
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  validateToken: async (verifyToken: string) => {
    try {
      const response: AxiosResponse = await api.get(`/tokens/verify?token=${verifyToken}`)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  receiveProposal: async (id: string) => {
    try {
      const response: AxiosResponse = await api.patch(`/users/${id}/availability`)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  changeUserEmail: async (data: { email: string, userId: string }) => {
    try {
      const response: AxiosResponse = await api.post('/users/email/change', data)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  }

}

export default user

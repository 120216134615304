export interface ISigninValues {
  email: string
  password: string
  level: string
}

export interface IUpdatePasswordModalError {
  message?: string,
  data?: { identifier: string }
}

export interface ISigninErrors {
  login?: string
  password?: string
  level?: string
}

export enum SigninErrorCodes {
  firstAcess = 'primeiro-acesso',
  passwordUpdate = 'atualizar-senha',
  notVerified = 'notVerified'
}


const ChatModel = {

  canCloseChat (chatStatus?: string, isHelpDesk?: boolean): boolean {
    if (isHelpDesk) return isHelpDesk && chatStatus !== 'final'
    return false
  }

}

export default ChatModel

import { _userLevel } from 'ecp/models/UsersModel'
import InviteEgiRepository from 'egi/repositories/InviteEgiRepository'
import { useEffect, useState } from 'react'
import { IAreas } from '../signupInterfaces'

export interface Invite {
  name: string,
  level: _userLevel
  inviterName: string
  inviterLevel: string
  promoterType: string
  areaName: string
  areas: IAreas[]
  blocked: boolean
  signupsOnPlataform?: {
    line: boolean
    onidata: boolean
  }
}

type Props = { token: string | string[] }
export default function useInvite ({ token }: Props) {
  const [error, setError] = useState(false)
  const [isInvited, setIsInvited] = useState(false)
  const [data, setData] = useState<Invite>({
    name: '',
    level: '',
    inviterName: '',
    inviterLevel: '',
    promoterType: '',
    areaName: '',
    areas: [],
    blocked: false
  })

  useEffect(() => {
    const getSignupInvite = async (token: string | string[]) => {
      try {
        const requestConfig = {
          params: {
            token
          }
        }
        const response = await InviteEgiRepository.signup(requestConfig)
        const { invite } = response.data.data || {}
        if (!invite) throw new Error('Erro ao receber valores do convite.')

        setData(invite)
        setIsInvited(true)
        setError(false)
      } catch (err) {
        setError(true)
        setIsInvited(false)
        console.error(err)
      }
    }

    if (token) getSignupInvite(token)
  }, [token])

  return {
    data,
    isInvited,
    error
  }
}

import React, { Fragment, useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Select, Spin } from 'antd'
import { useDispatch } from 'react-redux'
import { tablesUpdate } from 'store/modules/tables/actions'
import swal from 'utils/swal'
import { useAuth, useResources } from 'hooks'
import { resourcesGetBusinessManagers } from 'store/modules/resources/actions'
import translate from 'utils/translate'
import { _tableAreas } from 'globals'
import { IBusinessManager } from 'store/modules/resources/types'
import { IChangeBusinessManagerProps } from 'egi/app/Promoter/promoterInterfaces'
import { IAuth } from 'egi/types'
import PromotersRepository from 'egi/repositories/PromotersRepository'

const getSubtitle = (businessManager: IBusinessManager, currentUser: IAuth) => {
  if (businessManager._id === currentUser._id) return '(Você)'
  if (businessManager.areaName) return `(${translate.areasNames(businessManager.areaName)})`
  if (businessManager.level) return `(${translate.level(businessManager.level)})`
}

const sortedOptions = (options: IBusinessManager[]) => {
  return options.sort((current, next) =>
    (current.name.toLowerCase() > next.name.toLowerCase()) ? 1 : ((next.name.toLowerCase() > current.name.toLowerCase()) ? -1 : 0))
}

function ChangeBusinessManager ({ id, currentBusinessManager, visible, onClose }: IChangeBusinessManagerProps) {
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState<string>()
  const [errors, setErrors] = useState<any>({})
  const [allManagerOptions, setAllManagerOptions] = useState<IBusinessManager[]>()

  const dispatch = useDispatch()
  const resources = useResources()
  const user = useAuth()

  const managerOptions = [...resources.allBusinessManagers]

  useEffect(() => {
    if (user.level === 'master') {
      managerOptions.push({ _id: user._id, name: user.name as string, level: user.level, areaName: user.areaName as _tableAreas })
    }
    setAllManagerOptions(sortedOptions(managerOptions))
  }, [resources.allBusinessManagers])

  useEffect(() => {
    if (resources.allBusinessManagers.length < 1) dispatch(resourcesGetBusinessManagers())
    setSelected(currentBusinessManager)
    return () => setErrors({})
  }, [currentBusinessManager, visible])

  async function handleEditUsers () {
    if (!id) return
    if (!selected || selected === currentBusinessManager) {
      setErrors({ user: 'Selecione um gerente de negócios diferente.' })
      return
    }

    setLoading(true)
    try {
      const response = await PromotersRepository.changeManager({ promoterId: id, managerId: selected })
      swal.basic({ title: 'Sucesso!', text: response?.data.message, icon: 'success' })

      setErrors({})
      onClose()
      dispatch(tablesUpdate())
    } catch (err) {
      setErrors({ user: err.message })
    }
    setLoading(false)
  }

  return (
    <Fragment>
      <Modal
        title='Mudança de gerente de negócios'
        visible={visible}
        onCancel={onClose}
        footer={
          <Fragment>
            <Button type="ghost" onClick={onClose}>Cancelar</Button>
            <Button type="primary" onClick={() => handleEditUsers()}>Ok</Button>
          </Fragment>
        }
      >
        <Spin spinning={loading}>
          <Row>
            <Col className="px-1" lg={24} sm={24} xs={24}>
              <Form.Item
                label={<label>Gerente</label>}
                help={errors.user && (errors.user)}
                validateStatus={errors.user && ('error')}
              >
                <Select
                  placeholder="Escolha o novo gerente de negócios"
                  onChange={(value) => setSelected(value)}
                  loading={loading}
                  value={selected}
                  defaultValue={selected}
                >
                  {Array.isArray(allManagerOptions) && allManagerOptions.map((businessManager) => (
                    <Select.Option key={businessManager._id} value={businessManager._id}>{businessManager.name} <span className={`bold ${businessManager.name === user.name ? 'color-analysis' : 'color-tertiary'}`}>{getSubtitle(businessManager, user)}</span></Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Spin>
      </Modal>
    </Fragment>
  )
}

export default ChangeBusinessManager

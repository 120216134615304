import React, { useState } from 'react'
import { Form } from 'antd'

import DrawerFiltersLineCollapsable, { DrawerFiltersLineCollapsableItem } from 'components/DrawerFilters/DrawerFiltersLineCollapsable'
import useDebounce from 'hooks/useDebounce'
import { tablesSetFiltersValues } from 'store/modules/tables/actions'
import { useTables } from 'hooks'
import { useDispatch } from 'react-redux'
import { InputMoney } from 'components'

interface IValues {
  minValue: string | undefined,
  maxValue: string | undefined
}

function ValueFilter () {
  const tables = useTables()
  const dispatch = useDispatch()

  const [value, setValue] = useState<IValues>({ minValue: undefined, maxValue: undefined })

  useDebounce(() => {
    dispatch(tablesSetFiltersValues({ ...tables.filters, ...value }))
  }, 500, [value.minValue, value.maxValue])

  return (
    <DrawerFiltersLineCollapsable label='Valor'>
      <DrawerFiltersLineCollapsableItem>
        <Form.Item
          label="De"
          id='minValue'
          name="minValueNotDebounced"
        >
          <InputMoney
            placeholder="Digite o valor mínimo"
            onChange={(value) => setValue((prev) => {
              return { ...prev, minValue: value?.toString() }
            })}
          />
        </Form.Item>
      </DrawerFiltersLineCollapsableItem>

      <DrawerFiltersLineCollapsableItem>
        <Form.Item
          label="Até"
          id='maxValue'
          name="maxValueNotDebounced"
        >
          <InputMoney placeholder="Digite o valor máximo"
            onChange={(value) => setValue((prev) => {
              return { ...prev, maxValue: value?.toString() }
            })}
          />
        </Form.Item>
      </DrawerFiltersLineCollapsableItem>
    </DrawerFiltersLineCollapsable>
  )
}

export { ValueFilter }

import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Button, Modal, Skeleton, Tag, Typography } from 'antd'
import { CloudUploadOutlined } from '@ant-design/icons'
import Layout from 'antd/lib/layout/layout'
import { useSelector } from 'react-redux'
import { DocumentsView, DocumentUpload } from '..'
import unauthed, { IFile } from '../../egi/consumers/unauthed'
import imagesUrls from 'utils/imagesUrls'
import { RootState } from '../../store/modules/rootReducer'
import swal from '../../utils/swal'
import SignatureCanvas from 'react-signature-canvas'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { isBrowser, isMobile, withOrientationChange } from 'react-device-detect'
import { hasAnyKey } from '../../utils/object'
import { timeAsDayjs } from 'utils/time'
import UnauthedMessages from './UnauthedMessages'
import { getFieldErrorMessage } from 'utils/validate'
import fileHandler from 'egi/consumers/fileHandler'
import { AxiosRequestConfig } from 'axios'
import StringToHtml from 'components/StringToHtml/StringToHtml'
import phone from '../../assets/img/phone.png'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'
import { PdfPreview } from 'components'
import RichTextEditor from 'components/RichTextEditor/RichTextEditor'

const TurnPhone = () => {
  return (
    <div>
      <img height="150px" width="80px" className="image-animate rotate-phone" src={phone}/>
    </div>
  )
}

function UnauthedFileUpload (props: any) {
  const { isLandscape, isPortrait } = props
  const router = useSelector((state: RootState) => state.router)
  const { token } = (router as any).location.query

  const history = useHistory()
  const sigCanvasRef: any = useRef(null)

  const [expirationDate, setExperiationDate] = useState<string>('')
  const [fileList, setFileList] = useState<IFile[]>([])
  const [filesConfig, setFilesConfig] = useState<any>({})

  const [stepsContent, setStepsContent] = useState<any>({})
  const [documentContent, setDocumentContent] = useState<any>({})

  const [visibleModal, setVisibleModal] = useState('')
  const [isProvider, setIsProvider] = useState<boolean>(false)
  const [isPromoter, setIsPromoter] = useState<boolean>(false)
  const [isStatus, setIsStatus] = useState<boolean>(false)
  const [isSteps, setIsSteps] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [gettingConfig, setGettingConfig] = useState(true)

  const queryToken = queryString.parse(history.location.search)
  const isPromoterOrProvider = isProvider || isPromoter

  const isSignature = (type: string) => type === 'signature'

  const getDocumentsConfig = async (token: string) => {
    setGettingConfig(true)

    try {
      const { fileList, signaturesCount, isOnlySignatures } = await unauthed.getFileConfig({ token })
      setFileList(fileList)
      setFilesConfig({ signaturesCount, isOnlySignatures })

      setIsSteps(true)
      setGettingConfig(false)
      if (signaturesCount === 1 && isOnlySignatures) {
        setVisibleModal(fileList[0].stepId)
      }
    } catch (err) {
      if (err.data?.invalid) {
        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
        return
      }
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      setGettingConfig(false)
    }
  }

  const getProviderOrPromoter = async () => {
    const token = queryToken.token

    try {
      const { fileList, isOnlySignatures, signaturesCount } = await unauthed.getFileProviderConfig(token)
      setFileList(fileList)
      setFilesConfig({ signaturesCount, isOnlySignatures })
      setGettingConfig(false)
      if (queryToken.promoter) {
        setIsPromoter(true)
      }

      setIsProvider(true)
    } catch (err) {
      if (err.data?.invalid) {
        const errMessage = getFieldErrorMessage(err)
        swal.htmlBasic({ title: 'Atenção', text: errMessage, icon: 'warning' })
        return
      }
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      setGettingConfig(false)
    }
  }

  const getStepLink = async () => {
    const token = queryToken.token

    setGettingConfig(true)
    try {
      const response = await unauthed.stepStatusLink(token)
      let fileList: IFile[] = response.data.step
      const { history } = response.data
      let date

      if (history) date = timeAsDayjs(history)?.format('DD/MM/YYYY HH:mm')
      else date = 'Falha ao buscar data'

      setExperiationDate(date)
      if (!Array.isArray(fileList)) fileList = [fileList]

      setFileList(fileList)
      setFilesConfig({ fileList })
      setIsStatus(true)
      setGettingConfig(false)
    } catch (err) {
      if (err.data?.invalid) {
        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
        return
      }
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      setGettingConfig(false)
    }
  }

  useEffect(() => {
    if (queryToken.provider || queryToken.promoter) {
      getProviderOrPromoter()
    } else if (queryToken.status) {
      getStepLink()
    } else {
      getDocumentsConfig(token)
    }
  }, [])

  const sendFinishedFiles = async () => {
    if (isProvider || isPromoter) {
      const documentsIds: Array<string> = []

      for (const documentId in documentContent) {
        if (documentContent[documentId] > 0) {
          documentsIds.push(documentId)
        }
      }

      if (documentsIds.length < 1) {
        swal.basic({ title: 'Atenção!', text: 'Nenhum documento selecionado', icon: 'warning' })
        return
      }

      try {
        setLoading(true)
        const response = await unauthed.finishProviderDocument({ token, documentsIds })
        swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
        getProviderOrPromoter()
        setLoading(false)
      } catch (err) {
        if (err.data?.invalid) {
          const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
          invalidFieldsError({ invalidFields: invalidFields })
          setLoading(false)
          return
        }
        swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      }
      setLoading(false)
    } else {
      for (const stepId in stepsContent) {
        if (stepsContent[stepId] > 0) {
          try {
            setLoading(true)
            const response = await unauthed.finishStep({ token, stepId })
            getDocumentsConfig(token)
            swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
            setLoading(false)
          } catch (err) {
            if (err.data?.invalid) {
              const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
              invalidFieldsError({ invalidFields: invalidFields })
              return
            }
            swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
          }
        }
      }
    }
  }

  const handleContract = async ({ stepId, mode }: { stepId: string, mode?: string }) => {
    setLoading(true)

    if (sigCanvasRef.current && sigCanvasRef.current.isEmpty()) {
      swal.basic({ icon: 'warning', title: 'Atenção', text: 'Por favor assine na área informada.' })
      setLoading(false)
      return
    }

    try {
      const signature = sigCanvasRef.current && sigCanvasRef.current.toDataURL('image/png')
      const response = await unauthed.sendContract({ token, signature, stepId, activeMode: mode || undefined })

      swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
      setVisibleModal('')
      getDocumentsConfig(token)
    } catch (err) {
      if (err.data?.invalid) {
        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
        return
      }
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    }
    setLoading(false)
  }

  const closeModal = () => {
    setVisibleModal('')
    sigCanvasRef.current && sigCanvasRef.current.clear()
  }

  const onProviderUploadFile = async (request: any, file: IFile) => {
    const { file: requestFile, onSuccess, onProgress, onError } = request
    if (!requestFile) return ''

    const formData = new FormData()
    formData.append('file', requestFile)
    formData.append('documentId', file.documentId)

    const config: AxiosRequestConfig = {
      params: { token: queryToken.token },
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 90000,
      onUploadProgress: ({ total, loaded }) => {
        if (total) onProgress({ percent: Math.round((loaded / total) * 100) })
      }
    }

    try {
      const response = await fileHandler.uploadProviderDocument({ data: formData, config })
      const responseFile = { ...response, data: { file } }
      onSuccess(responseFile)
    } catch (err) {
      onError(err)
      swal.basic({ icon: 'warning', title: 'Atenção', text: err.message })
    }
  }

  const onUploadUnauthDocument = async (request: any, file: IFile) => {
    const { file: requestFile, onSuccess, onProgress, onError } = request
    if (!requestFile) return ''

    const formData = new FormData()
    formData.append('file', requestFile)
    formData.append('stepId', file.stepId)

    const config: AxiosRequestConfig = {
      params: { token: queryToken.token },
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 90000,
      onUploadProgress: ({ total, loaded }) => {
        if (total) onProgress({ percent: Math.round((loaded / total) * 100) })
      }
    }

    try {
      const response = await fileHandler.uploadUnauthProposalDocument({ data: formData, config })
      onSuccess(response, requestFile)
    } catch (err) {
      onError(err)
      swal.basic({ icon: 'warning', title: 'Atenção', text: err.message })
    }
  }

  return (
    <Layout className="unauth-upload-background">
      <header className="unauth-upload-header">
        <img className="unauth-logo-file-upload" src={imagesUrls.rodobensLogoLight} />
      </header>

      <div className="unauth-content">

        {gettingConfig && (
          <div className="messages">
            <Skeleton loading={gettingConfig} />
          </div>
        )}

        {isPromoterOrProvider && (
          <UnauthedMessages
            title="Documentos necessários para envio:"
            description="Baixe os arquivos e preencha seus dados, ao finalizar envie os documentos nas categorias abaixo e clique no botão enviar ao final da página."
            messageContent={
              <div className="messages-content-documents ml-1">
                {fileList?.map((file: IFile) => (
                  file.documents && file.documents.map((item) => (
                    <div key={file.title}>
                      <DocumentsView
                        key={file.documentId}
                        isOnlyLink={item}
                      />
                    </div>
                  ))
                ))}
              </div>
            }
          />
        )}

        {isStatus && (
          <UnauthedMessages
            title="Instruções para envio dos documentos:"
            messageContent={
              <Fragment>
                {fileList.map((file: IFile) => (
                  <div className="w-100" key={file.title}>
                    <div className="w-100">
                      <RichTextEditor
                        setContents={file.commentary}
                        disable={true}
                      />
                      <div className="flex flex-justify-center">
                        <Tag color="blue" className="text-center color-secondary mb-3 mt-3 mr-0 p-2"><h2 className="mb-0">O link irá expirar em: <b>{expirationDate}</b></h2></Tag>
                      </div>
                    </div>
                  </div>
                ))}
              </Fragment>
            }
          />
        )}

        {isSteps && (
          <UnauthedMessages
            title="Instruções para envio dos documentos:"
            description="- Ao clicar em enviar, suas informações serão encaminhadas para revisão e não poderão ser alteradas até acontecer a revisão desse passo."
          />
        )}

        <div className="document-grid">
          {fileList?.map((file: IFile) => (
            <div className="document-container" key={file.documentId} >
              <Fragment>
                <Fragment key={file.stepId || file.documentId}>
                  <div className="my-3 flex flex-justify-center flex-align-center">
                    <p className="document-title">{file.title}</p>
                  </div>

                  <div className="center documents-list-item">
                    {!file.done ? (
                      <Fragment>
                        {file.type === 'document' && (
                          <div className="flex flex-column flex-justify-center flex-align-center">
                            <div className="document-upload-dashed">
                              <CloudUploadOutlined className="mt-2" style={{ fontSize: 25, color: '#00441F' }} />
                              <label className="color-primary mt-1 mb-3">Tamanho máximo: 4MB</label>
                              <div className="mb-3">
                                <DocumentUpload
                                  onFileDelete={(url: string | object, resolve: Function) => resolve(true)}
                                  onFileUpload={(request) => {
                                    isPromoterOrProvider
                                      ? onProviderUploadFile(request, file)
                                      : onUploadUnauthDocument(request, file)
                                  }}
                                  hideUploadButton={false}
                                  unauthId={file.stepId || file.documentId}
                                  paths={[]}
                                  getDocuments={() => null}
                                  loading={loading}
                                  skeletonQuantity={1}
                                  onFilesChange={(content: object) => {
                                    file.documentId ? setDocumentContent({ ...content, ...documentContent }) : setStepsContent({ ...content, ...stepsContent })
                                  }}
                                  maxDocs={10}
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        {(isSignature(file.type) || (file.type === 'multiple' && file.modes?.includes('term'))) && (
                          <Fragment>
                            <Modal
                              closable={!loading}
                              className="modal-config"
                              title={file.title}
                              onCancel={closeModal}
                              visible={visibleModal === file.stepId}
                              footer={null}
                            >
                              {file.contract && file && file.files && file.files[0]?.file ? (
                                <PdfPreview src={file.files[0].file}/>
                              ) : <StringToHtml value={file.contract || ''} />}

                              <div className="contract-container">
                                {isSignature(file.type) && (
                                  <Fragment>
                                    <Typography.Title level={4} className="color-primary">
                                      ASSINE NA ÁREA ABAIXO
                                    </Typography.Title>

                                    {isBrowser && (
                                      <Fragment>
                                        <SignatureCanvas
                                          ref={(ref: any) => { sigCanvasRef.current = ref }}
                                          canvasProps={{ height: isBrowser ? 100 : 200, className: 'documents-sigCanvas' }}
                                        />

                                        <label style={{ fontSize: '10px', marginBottom: '22px' }}>
                                          Declaro que li e estou ciente dos termos acima.
                                        </label>
                                      </Fragment>
                                    )}

                                    {isMobile && (<>
                                      {isLandscape && (
                                        <Fragment>
                                          <SignatureCanvas
                                            ref={(ref: any) => { sigCanvasRef.current = ref }}
                                            canvasProps={{ height: 200, className: 'documents-sigCanvas' }} />
                                          <label style={{ fontSize: '10px', marginBottom: '22px' }}>
                                            Declaro que li e estou ciente dos termos acima.
                                          </label>
                                        </Fragment>
                                      )}

                                      {isPortrait && (
                                        <Fragment>
                                          <TurnPhone/>
                                          <Typography.Text>Vire seu celular para assinar</Typography.Text>
                                        </Fragment>
                                      )}
                                    </>)}

                                  </Fragment>
                                )}

                                <div className="flex">
                                  {file.type === 'signature' && (
                                    <Button key="submit" type="ghost" onClick={() => { sigCanvasRef.current && sigCanvasRef.current.clear() }} className="mr-1">
                                      Limpar
                                    </Button>
                                  )}

                                  <Button
                                    key="submit"
                                    type="primary"
                                    loading={loading}
                                    onClick={() => {
                                      handleContract({ mode: isSignature(file.type) ? undefined : 'term', stepId: file.stepId })
                                    }}>
                                    {isSignature(file.type) ? 'Enviar assinatura' : 'Li e corcordo com os termos'}
                                  </Button>
                                </div>

                              </div>
                            </Modal>

                            <Button
                              type="primary"
                              onClick={() => setVisibleModal(file.stepId)}
                            >
                              Ver documento
                            </Button>
                          </Fragment>
                        )}

                      </Fragment>
                    ) : <Typography.Text className='documents-done-indicator' type="secondary" strong>Documento já finalizado</Typography.Text>}
                  </div>
                </Fragment>
              </Fragment>
            </div>
          ))}
        </div>

        <div className="mt-6 mb-6">
          {(!filesConfig.isOnlySignatures && hasAnyKey(filesConfig)) && (
            <div className="documents-submit">
              <Button
                className="documents-submit-button"
                id="drawer-submit-button"
                loading={loading}
                onClick={sendFinishedFiles}>
                    Enviar
              </Button>
            </div>
          )}

        </div>
      </div>
    </Layout>
  )
}

export default withOrientationChange(UnauthedFileUpload)

import { _typePromoterEnum, _userLevel } from 'ecp/models/UsersModel'
import { IAuth } from 'egi/types'
import { PermissionsEnum } from 'egi/types/IAuth'

const UserModels = {
  levelsCanCancel: ['agx', 'master', 'administrator'],
  levelHierarchy: ['agx', 'master', 'commercial', 'auditor', 'administrator', 'backoffice', 'consultant', 'promoter', 'provider', 'collaborators', 'commission', 'pricing', 'seller', 'client'],

  isUserAbove ({ userLevel, levelToCompare }: { userLevel: _userLevel, levelToCompare: _userLevel }) {
    const levelHierarchy = this.levelHierarchy
    return levelHierarchy.indexOf(userLevel) < levelHierarchy.indexOf(levelToCompare)
  },

  canCancelUser ({ userLevel, levelToCompare }: { userLevel: _userLevel, levelToCompare: _userLevel }) {
    const canCancel = this.levelsCanCancel.includes(userLevel) && this.isUserAbove({ userLevel, levelToCompare })
    return canCancel
  },

  teamLink ({ level, userId, userName }: {level: _userLevel, userId?: string, userName?: string}) {
    switch (level) {
      case 'administrator':
        return `/auth/administrators/${userId}/invited/${userName}`
      case 'promoter':
        return `/auth/mlm/promoters/${userId}/${userName}`
      default:
        return ''
    }
  },

  hasTeam (level: _userLevel, type: _typePromoterEnum) {
    return (level === 'administrator') || ((level === 'promoter') && (type === 'IN'))
  },

  isExternal: (userLevel: _userLevel) => (['client', 'promoter', 'auditor'].includes(userLevel)),

  isSignature: (userLevel: _userLevel) => (['backoffice', 'client', 'master', 'promoter'].includes(userLevel)),

  isCrivo: (userLevel: _userLevel) => (['backoffice', 'master', 'promoter'].includes(userLevel)),

  hasInviteSwitch: (userLevel: _userLevel) => (['master'].includes(userLevel)),

  hasInviteActions: (inviteBackoffice?: boolean) => !!inviteBackoffice,

  hasAccessManagerSidebarItem (auth: IAuth) {
    if (auth.permissions && Array.isArray(auth.permissions)) {
      const isAccessManager = auth?.permissions.find(item => item.value === PermissionsEnum.gestordeAcesso)
      return isAccessManager
    }
    return false
  },

  canEditProducts (level: IAuth['level']) {
    return ['master', 'agx', 'administrator'].includes(level)
  },

  canGoToUserDetailsInECPFTypists (level: IAuth['level']) {
    return ['master', 'agx'].includes(level)
  }
}

export default UserModels

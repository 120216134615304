import { Checkbox, message, Spin, Typography } from 'antd'
import ErrorDetails from 'components/ErrorDetails/ErrorDetails'
import { UserPermissionsService } from 'egi/services/PermissionService'
import { usePermissionsDelete, usePermissionsList, useUserPermissionsConfigList } from 'hooks/usePermissions'
import React, { useEffect, useState } from 'react'

export const UserDetailsPermissionsList = ({ userId }: { userId: string }) => {
  const { getPermissions, loading, error, permissions } = usePermissionsList()
  const { loading: loadingDelete, deletePermission } = usePermissionsDelete()
  const [loadingVinculate, setLoadingVinculate] = useState(false)

  const {
    getUserPermissions,
    loading: loadingUserPermission,
    error: errorUserPermission,
    userPermissions,
    onRemoveItemFromUserPermissions,
    onAddItemFromUserPermissions
  } = useUserPermissionsConfigList()

  useEffect(() => {
    const abortController = new AbortController()

    getPermissions({ signal: abortController.signal })

    return () => {
      abortController.abort()
    }
  }, [userId])

  useEffect(() => {
    const abortController = new AbortController()

    getUserPermissions(userId, { signal: abortController.signal })

    return () => {
      abortController.abort()
    }
  }, [userId])

  const onAddUserPermission = async (permissionId: string) => {
    try {
      setLoadingVinculate(true)
      const checkedPermission = permissions.find(permission => permission._id === permissionId)

      const permissionService = new UserPermissionsService()
      const response = await permissionService.vinculate({ permissionId: permissionId, userId })
      const userPermission = response.data.data

      if (userPermission && checkedPermission) {
        onAddItemFromUserPermissions({ _id: permissionId, name: checkedPermission.name, userPermissionId: userPermission._id, slug: checkedPermission.slug })
      }

      message.success(response?.data?.message)
    } catch (err) {
      message.error(err?.message)
    } finally {
      setLoadingVinculate(false)
    }
  }

  const onSelectPermision = async (_id: string, checked: boolean) => {
    if (checked) {
      onAddUserPermission(_id)
    } else {
      const checkedUserPermission = userPermissions.find(userPermission => userPermission._id === _id)

      if (checkedUserPermission) {
        await deletePermission(checkedUserPermission?.userPermissionId)
        onRemoveItemFromUserPermissions(checkedUserPermission?._id)
      }
    }
  }

  const header = (
    <div className='mb-4'>
      <Typography.Title level={4} className='mb-1 color-primary'>Liberação de Permissões</Typography.Title>
      <Typography.Paragraph>Utilize a lista abaixo para conceder ou revogar permissões aos usuários.</Typography.Paragraph>
    </div>
  )

  if (loading || loadingUserPermission) {
    return (
      <div>
        {header}

        <Spin />
      </div>
    )
  }

  if (errorUserPermission) {
    return (
      <ErrorDetails
        title='Ops, parece que algo deu errado'
        error={errorUserPermission}
      />
    )
  }

  if (error) {
    return (
      <ErrorDetails
        title='Ops, parece que algo deu errado'
        error={error}
      />
    )
  }

  return (
    <div>
      {header}

      {permissions.map(permission => (
        <div key={permission._id} className='user-details-permissions-list__checkbox-list'>
          <Checkbox
            disabled={loadingDelete || loadingVinculate}
            checked={userPermissions.some(userPermission => userPermission._id === permission._id)}
            onChange={(event) => onSelectPermision(permission._id, event.target.checked)}
          >
            {permission.code ? `${permission.code} - ` : ''}{permission.name}
          </Checkbox>

          {permission.description && (
            <Typography.Text type='secondary' className='user-details-permissions-list__permission-description'>
              {permission.description}
            </Typography.Text>
          )}
        </div>
      ))}
    </div>
  )
}

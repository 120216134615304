import { DollarOutlined, FieldTimeOutlined, FileTextFilled, HomeFilled, MailFilled, StopFilled, UserOutlined } from '@ant-design/icons'
import ListConfigurationSvg from 'assets/reactSvgs/ListConfigurationSVG/ListConfigurationSVG'
import Menu from 'components/Menu/Menu'
import SettingsModel from 'egi/models/SettingsModel'
import { useAuth } from 'hooks'

import React from 'react'
import { declines, emailConfig, internReviewSettings, operationalLimit, population, simulationConfiguration, slaConfig } from 'routes/routes'

export default function SettingsMenu () {
  const user = useAuth()

  const allCards = [
    {
      icon: <MailFilled />,
      title: 'Configurações de email',
      description: 'Configuração de envio de email',
      path: `/auth${emailConfig.path}`,
      visible: SettingsModel.canSeeEmailSettings(user.level),
      buttonText: 'Configurar'
    },
    {
      icon: <DollarOutlined />,
      title: 'Limite operacional',
      description: 'Configuração da definição dos limites operacionais',
      path: `/auth${operationalLimit.path}`,
      visible: SettingsModel.canSeeOperationalLimit(user.level, user.areaName),
      buttonText: 'Configurar'
    },
    {
      icon: <UserOutlined />,
      title: 'Limite populacional',
      description: 'Configuração do limite populacional na simulação',
      path: `/auth${population.path}`,
      visible: SettingsModel.canSeePopulationLimit(user.level),
      buttonText: 'Configurar'
    },
    {
      icon: <FieldTimeOutlined />,
      title: 'Configuração de SLA',
      description: 'Configuraçõe gerais de SLA',
      path: `/auth${slaConfig.path}`,
      visible: SettingsModel.canSeeSLASettigns(user.level),
      buttonText: 'Configurar'
    },
    {
      icon: <FileTextFilled />,
      title: 'Revisão interna',
      description: 'Definição de textos padrão para revisão interna do passo',
      path: `/auth${internReviewSettings.path}`,
      visible: SettingsModel.canSeeInternalSettings(user.level, user.areaName),
      buttonText: 'Configurar'
    },
    {
      icon: <StopFilled />,
      title: 'Motivos de Recusa',
      description: 'Definição de motivos de recusa padrão para a proposta',
      path: `/auth${declines.path}`,
      visible: SettingsModel.canSeeDeclineSettigns(user.level),
      buttonText: 'Configurar'
    },
    {
      icon: <HomeFilled />,
      title: 'Configurações de simulação',
      description: 'Configuração dos valores mínimos e máximos da simulação',
      path: `/auth${simulationConfiguration.path}`,
      visible: SettingsModel.canSeeSimulationSettigns(user.level),
      buttonText: 'Configurar'
    }
  ]

  return (
    <Menu>
      <Menu.Header
        title='Configurações'
        svg={<ListConfigurationSvg/>}
      >
        <Menu.Paragraph className="pb-2">
          As páginas de configuração do sistema foram agrupadas aqui
          nesta página para facilitar o acesso.
        </Menu.Paragraph>
        <Menu.Paragraph>
          Clique no botão <b>configurar</b> para fazer alterações
          sistema ou visualizar histórico das alterações mais recentes
        </Menu.Paragraph>
      </Menu.Header>

      <Menu.CardContainer
        cardList={allCards}
        hasSearch
      />
    </Menu>
  )
}

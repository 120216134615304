import { AxiosRequestConfig } from 'axios'
import { Repository } from 'ecp/repositories/Repository'
import api from 'services/api'

type Link = {
  productId?: string
  campaign?: boolean
  campaignSlug?: string
  campaignName?: string
}

class LinkRepository extends Repository<Link> {
  clickedLinks (linkId: string, config?: AxiosRequestConfig) {
    return Repository.handle<{ total: number | undefined }>(() =>
      this.api.get(`${this.path}/clicks/${linkId}`, config)
    )
  }
}
export default new LinkRepository({ api, path: '/links' })

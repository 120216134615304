import { SearchOutlined } from '@ant-design/icons'
import { Button, Col, Empty, Input } from 'antd'
import React, { ReactNode, useState } from 'react'
import { useHistory } from 'react-router-dom'

interface IMenuCardProps {
  icon: ReactNode
  title: string
  description: string,
  path: string,
  visible?: boolean
  buttonText?: string
}

export default function Menu ({ children, className }: { children: ReactNode, className?: string }) {
  return (
    <div className={`menu ${className}`}>
      {children}
    </div>
  )
}

Menu.Header = ({ children, title, svg }: { children: ReactNode, title: string, svg: ReactNode }) => {
  return (
    <header className="menu__header">
      <div>
        {svg}
      </div>
      <div className="menu__description">
        <h2 className="menu__title">{title}</h2>
        {children}
      </div>
    </header>
  )
}

Menu.Search = ({ onChange } : {onChange: (value: string) => void}) => {
  return (
    <>
      <Col sm={24} md={12} lg={8} className="mb-2">
        <div className="menu__search">
          <Input placeholder="Pesquise a configuração aqui" onChange={(e) => onChange(e.target.value)}/>
          <SearchOutlined className="menu__search-icon"/>
        </div>
      </Col>
    </>
  )
}

Menu.Paragraph = ({ children, className } : {children: ReactNode, className?: string}) => {
  return (
    <p className={`menu__texts ${className}`}>
      {children}
    </p>
  )
}

Menu.Card = ({ icon, title, description, path, visible = true, buttonText = 'Visualizar' }: IMenuCardProps) => {
  const history = useHistory()
  const onClick = (path: string) => {
    history.push(path)
  }
  if (visible) {
    return (
      <div className="menu-card">
        <div className="menu-card__section">
          <div className="menu-card__icon">
            {icon}
          </div>
          <h2 className="menu-card__title">{title}</h2>
        </div>
        <div className="menu-card__section">
          <p className="menu-card__description">
            {description}
          </p>
          <Button type="primary" onClick={() => onClick(path)}>
            {buttonText}
          </Button>
        </div>
      </div>
    )
  }

  return <></>
}

Menu.EmptyCard = ({ filteredCards }:{ filteredCards :IMenuCardProps[] | undefined }) => {
  if (!filteredCards?.length) {
    return (
      <div className="menu__empty-card">
        <Empty
          description="Ops, não foi possível encontrar nenhum item para esse menu"
        />
      </div>
    )
  }

  return <></>
}

Menu.CardContainer = ({ cardList, hasSearch = false }: { cardList?:IMenuCardProps[], hasSearch?: boolean }) => {
  const [searchText, setSearchText] = useState('')

  const filteredCards = cardList?.filter(card =>
    card.title.toLowerCase().includes(searchText.toLowerCase())
  )

  const onChangeText = (value: string) => {
    setSearchText(value)
  }

  return <>
    {hasSearch && (
      <Menu.Search onChange={onChangeText} />
    )}

    <Menu.EmptyCard filteredCards={filteredCards} />

    <div className="menu__cards-container">

      {filteredCards && filteredCards.map((card) => {
        return (
          <Menu.Card
            key={card.title}
            icon={card.icon}
            title={card.title}
            description={card.description}
            path={card.path}
            visible={card.visible}
            buttonText={card.buttonText}
          />
        )
      })}
    </div>
  </>
}

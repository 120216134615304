import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { CloseOutlined } from '@ant-design/icons'
import { useAuth } from 'hooks'
import format from 'utils/format'
import { Link } from 'react-router-dom'
import commonColumns from 'components/UserTable/utils/commonColumns'
import { OverflowTooltip } from 'components/OverflowToolTip/OverflowTooltip'

const columns = (handleDelete: Function): ColumnsType<Object> => [
  {
    title: 'Nome',
    dataIndex: 'name',
    width: 200,
    align: 'left',
    render: (text: string, row: any) => (<>
      <OverflowTooltip
        width={200}
        title={
          <>
            {`${text}`}
            <div>{ row?.cpf && row?.cpf}</div>
          </>
        }
      >
        <Link
          className="underline color-tertiary font-500 capitalize"
          to={`/auth/witness/show/${row._id}`}
        >
          {text.toLowerCase()} &rarr;
        </Link>

        {row?.cpf ? (
          <div>
            {row?.cpf}
          </div>
        ) : '-'}
      </OverflowTooltip>
    </>
    )
  },
  {
    title: 'RG',
    dataIndex: 'rg',
    width: 100,
    render: (rg: string) => format?.formatRg(rg)
  },
  commonColumns.email,
  {
    title: 'Nacionalidade',
    dataIndex: 'nationality',
    width: 250,
    ellipsis: true,
    render: (nacionality: string) => {
      if (!nacionality) return '-'
      return format.capitalize(nacionality)
    }
  },
  {
    title: 'Profissão',
    dataIndex: 'occupation',
    width: 250,
    ellipsis: true,
    render: (occupation: string) => {
      if (!occupation) return '-'
      return format.capitalize(occupation)
    }
  },
  {
    title: 'Estado civil',
    dataIndex: 'maritalStatus',
    width: 100,
    render: (maritalStatus: string) => {
      if (!maritalStatus) return '-'
      return maritalStatus
    }
  },
  { ...commonColumns.createdAt },
  {
    title: '',
    dataIndex: '_id',
    width: 80,
    render: (id: string, row: any) => {
      const user = useAuth()
      const IS_AUDITOR = user.level === 'auditor'
      return (
        <CloseOutlined
          disabled={true}
          onClick={() => {
            if (IS_AUDITOR) return
            handleDelete(id, row.name)
          }}
        />
      )
    }
  }
]

export { columns }

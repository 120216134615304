/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { Fragment, MutableRefObject, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Table } from 'components'
import { columns, commissionColumns, pricingColumns } from '../../components/dataTable'
import { enhanceTable } from 'components/Table/tableEnhance'
import { Button, Col, Form, Modal, Row, Select } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import swal from 'utils/swal'
import { ColumnsType } from 'antd/lib/table'
import translate from 'utils/translate'
import { IPricingParams } from '../../princingInterfaces'
import { useAuth, useResources } from 'hooks'
import { IPricing } from 'egi/types'
import { resourcesGetProducts } from 'store/modules/resources/actions'
import { useDispatch } from 'react-redux'
import { IProducSub, IProduct } from 'ecp/models/ProductModel'
import GlobalsRepository from 'egi/repositories/GlobalsRepository'
import PricingModel from 'egi/models/PricingModel'

interface IHistoryPush {
  personType: 'fisica' | 'juridica'
  product: string
  subProduct: string
}

export type _pricingTypes = 'commissao' | 'precificacao'

function PricingTable ({ type }: { type: _pricingTypes }) {
  const [finalColums, setFinalColumns] = useState<ColumnsType<Object>>(columns({ deactivatePricing: () => {} }))
  const [finalData, setFinalData] = useState<IPricing[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const [showModal, setShowModal] = useState<boolean>(false)
  const [subProducts, setSubProducts] = useState<IProducSub[]>([])

  const dispatch = useDispatch()
  const formRef: MutableRefObject<any> = useRef(null)
  const history = useHistory()

  const resources = useResources()
  const params: IPricingParams = useParams()
  const user = useAuth()

  const IS_MASTER = user.level === 'master'

  useLayoutEffect(() => {
    if (resources.products && resources.products.length < 1) {
      dispatch(resourcesGetProducts())
    }
  }, [])

  useEffect(() => {
    async function fetchProduct () {
      setLoading(true)

      const data: any = {}
      let isDirectTable: boolean = false

      if (params.product) data.product = params.product
      if (params.productSlug) data.sub = params.productSlug
      if (params.personType) data.personType = params.personType

      if (Object.keys(data).length > 0) isDirectTable = false
      else isDirectTable = true

      try {
        const response = await GlobalsRepository.pricings({ params: data })

        const pricingsData = response.data.data?.pricing || []
        let paramsColumns = columns({ deactivatePricing })
        if (isDirectTable) {
          if (type === 'commissao') paramsColumns = commissionColumns
          if (type === 'precificacao') paramsColumns = pricingColumns({ deactivatePricing })
        }

        const element = document.querySelector('#auth-page-title')
        if (element && element.innerHTML.indexOf(':') === -1 && IS_MASTER) element.insertAdjacentHTML('beforeend', ': ' + translate.subProducts(params.productSlug))

        const [enhancePricing, enhanceColumns] = enhanceTable(pricingsData, paramsColumns)

        setFinalData(enhancePricing)
        setFinalColumns(enhanceColumns)
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }

    async function deactivatePricing (pricingId: string) {
      const query = {
        product: params.product,
        sub: params.productSlug
      }

      try {
        const response = await GlobalsRepository.deactivatePricing(pricingId, { params: query })
        setTimeout(() => swal.basic({
          title: 'Sucesso!',
          text: response.data.message,
          icon: 'success'
        }), 300)
        fetchProduct()
      } catch (err) {
        setTimeout(() => swal.basic({
          title: 'Atenção!',
          text: err.message,
          icon: 'warning'
        }), 300)
      }
    }

    fetchProduct()
  }, [])

  const pushPricingCreate = ({ personType, product, subProduct }: IHistoryPush) => history.push(`/auth/pricing/create/${personType}/${product}/${subProduct}`)

  const submitModal = (values: { personType: 'fisica' | 'juridica', product: string, sub: string }) => {
    const { personType, product, sub } = values

    if (personType && product && sub) {
      pushPricingCreate({ personType, product, subProduct: sub })
    }
  }

  return (
    <div>
      <Modal
        title="DADOS PARA CRIAR PRECIFICAÇÃO"
        visible={showModal}
        footer={null}
        onCancel={() => {
          setShowModal(false)
          if (formRef && formRef.current) formRef.current.resetFields()
        }}
      >
        <Form
          ref={formRef}
          onFinish={submitModal}
          layout={'vertical'}
        >
          <Row>
            <Col lg={24} sm={24} xs={24} className="px-1">
              <Form.Item
                name='personType'
                label={<label>Tipo pessoa:</label>}
                rules={[{ required: true, message: 'Escolha o tipo de pessoa.' }]}
              >
                <Select placeholder='Selecione o tipo de pessoa'>
                  <Select.Option value='fisica'>fisica</Select.Option>
                  <Select.Option value='juridica'>juridica</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col lg={12} sm={24} xs={24} className="px-1">
              <Form.Item
                name='product'
                label={<label>Produto:</label>}
                rules={[{ required: true, message: 'Escolha o produto.' }]}
              >
                <Select
                  placeholder='Selecione o produto'
                  onChange={item => {
                    const findItemActive = (value: IProduct) => value.slug === item && value.active
                    const findItem = resources.products.find(findItemActive)
                    if (findItem) setSubProducts(findItem.sub)
                  }}
                >
                  {resources.products.map(item => (
                    <Select.Option
                      key={item._id}
                      value={item.slug}
                    >
                      {item.slug}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {subProducts && subProducts.length > 0 && (
              <Col lg={12} sm={24} xs={24} className="px-1">
                <Form.Item
                  name='sub'
                  label={<label>Sub produto:</label>}
                  rules={[{ required: true, message: 'Escolha o sub produto.' }]}
                >
                  <Select placeholder='Selecione o produto'>
                    {subProducts.map(item => (
                      <Select.Option
                        key={item._id}
                        value={item.name}
                      >
                        {translate.subProducts(item.name)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>

          <Button
            type="primary"
            htmlType="submit"
            className="mx-1 w-100"
          >
            Enviar
          </Button>
        </Form>
      </Modal>

      <Table
        columns={finalColums}
        data={finalData}
        loading={loading}
        selectUf={true}
        filters={
          <Fragment>
            {PricingModel.canCreate(user.level, type) && (
              <Button
                type="primary"
                onClick={() => {
                  if (Object.keys(params).length) {
                    pushPricingCreate({ personType: params.personType, product: params.product, subProduct: params.productSlug })
                  } else {
                    setShowModal(true)
                  }
                }}
              >
                Adicionar nova precificação
              </Button>
            )}
          </Fragment>
        }
      />
    </div>
  )
}

export default PricingTable


import { AxiosRequestConfig } from 'axios'
import { Dayjs } from 'dayjs'
import { _proposalSteps } from 'ecp/app/Proposals/proposalInterfaces'
import { Repository } from 'ecp/repositories/Repository'
import { IProposalECPF } from 'ecpf/app/ProposalECPF/hooks/useProposalListECPF'
import { IProposalDepartmentsECPF, IProposalDetailsResponse, IProposalLineData } from 'ecpf/app/ProposalECPF/ProposalECPFInterfaces'
import { _proposalStatuses } from 'egi/app/ProposalEgi/proposalEgiInterfaces'

import api from 'services/api'

export enum IProposalECPFOrderFieldEnum {
  numeroProposta = '1',
  nomeCliente = '2',
  dataBase = '3',
  valorPrincipal = '4',
  dataAtividade = '5',
  alerta = '6'
}

export enum IProposalECPFOrderEnum {
  ascendente = '1',
  descendente = '2'
}

interface IOrdenacaoProposalPayload {
  campo?: IProposalECPFOrderFieldEnum
  tipo?: IProposalECPFOrderEnum
}

interface IPaginacaoProposalPayload {
  quantidade: number
  numeroPagina: number
}

export enum ECPFPropostaSituacaoEsteira {
  andamento = '1',
  liberada = '2',
  aprovada = '3',
  pendente = '4',
  reprovada = '5',
  integrada = '6',
  cancelada = '7'
}

export enum ProposalECPFTipoConsulta {
  Andamento = '1',
  CanceladasIntegradas = '2'
}

interface ISituacaoEsteira {
  propostaEsteira: ECPFPropostaSituacaoEsteira
}

interface IFiltrosSituacaoEsteira {
  situacaoEsteira: Array<ISituacaoEsteira>
}

export enum IRangeFiltersTypes {
  dataCadastro = '1',
  dataBase = '2',
  dataAtividade = '3'
}

interface FiltrosPeriodo {
  tipo: IRangeFiltersTypes
  dataInicial: Date | string
  dataFinal: Date | string
}

export enum ECPFOrigemType {
  matriz = 1,
  regional = 2,
  promotor = 3,
  empregador = 4,
  orgao = 5,
  digitador = 6,
  origem3O = 7
}

interface IOrigemProposalPayload {
  tipo: ECPFOrigemType
  codigo: string
}

interface IFiltrosOrigensProposalPayload {
  origem: Array<IOrigemProposalPayload>
}

interface IProdutoProposalPayload {
  codigo: string
}

interface IProductFilters {
  produto: Array<IProdutoProposalPayload>
}

interface IFiltrosProposalPayload {
  tipoConsulta?: ProposalECPFTipoConsulta
  situacoesEsteira?: IFiltrosSituacaoEsteira
  periodo: FiltrosPeriodo
  backOffice?: string
  origens: IFiltrosOrigensProposalPayload
  produtos: IProductFilters
}

interface IPesquisaProposalPayload {
  idProposta?: string
  documento?: string
  dataBase?: Date
  nomeCliente?: string
}

export interface ICheckProposalPayload {
  ordenacao: IOrdenacaoProposalPayload
  paginacao: IPaginacaoProposalPayload
  filtros: IFiltrosProposalPayload
  pesquisa: IPesquisaProposalPayload
}

interface IProposalListPagination {
  numero: number
  quantidade: number
  quantidadeRegistros: number
  quantidadeTotalRegistros: number
}

interface IProposalListReponse {
  proposta: Array<IProposalECPF>
}

export interface IProposalDetailsECPF {
  funcao: IProposalDetailsResponse,
  lineData: IProposalLineData
}
export interface IAnticipationSimulationPayload {
  idProtocolo?: number
  codigoProduto: string
  valorProposto: number
  dataBase: string
  taxa?: number
  quantidadeParcelas: number
  dataPrimeiroVencimento?: string
  fluxoParcelas?: Array<{
    numeroParcela: string
    vencimento: string
    valorParcela: number
    valorDespesa: number
    valorTarifa: number
  }>
  despesas: {
    valorDespesaReembolsavel: number
    valorTarifaHonorario: number
  }
  operacoesAcordadas: Array<{
    codigoBackOffice: string
    numeroOperacao: string
    parcelas: Array<{
      numeroParcela: string
    }>
  }>
  codigoCobradora: string
}

export interface IAnticipationSimulationResponse {
  dataBase: string
  dataPrimeiroVencimento: string
  dataUltimoVencimento: string
  quantidadeParcelas: number
  valorFinanciado: number
  fluxoParcelas: Array<{
    numeroParcela: number
    vencimento: string
    valorParcela: number
    valorDespesa: number
    valorTarifa: number
  }>
  valorParcela: number
  valorPrincipal: number
  valorBruto: number
  valorLiquido: number
  taxaClienteMes: number
  taxaClienteAno: number
  taxaApMes: number
  taxaApAno: number
  taxaCetMes: number
  taxaCetAno: number
  taxaNominalMes: number
  taxaNominalAno: number
}

export interface IGenerateTicketPayload {
  numeroOperacao: string
  codigoInstalacao: number
  produtoAcordo: string
  parcelas: Array<{
    numeroParcela: string
  }>
  dataVencimento: string
  quantidadeParcelas: number
  gerarBoleto: string
  codigoCobradora: string
}

export interface IGenerateTicketResponse {
  documento: string
  numeroAcordo: number
  numeroOperacao: string
  linhasDigitaveis: Array<{
    numeroParcela: string
    numeroLinha: string
  }>
}

export enum ContractLogSlugEnum {
  starting = 'starting',
  checklist = 'checklist',
  signature = 'signature'
}

export enum contractLogStatusEnum {
  pending = 'pending',
  inProgress = 'in-progress',
  success = 'success',
  failed = 'failed',
 }

export interface IContractLogs {
  slug: ContractLogSlugEnum
  status: contractLogStatusEnum
  lastAttemptAt: Dayjs

  attempts?: number
  lastError?: string
}

export interface IContractLogsResponse {
  contractStatus: IContractLogs[]
}

class ProposalECPFRepository extends Repository<any> {
  async list (data: ICheckProposalPayload, config?: AxiosRequestConfig) {
    return Repository.handle<{ propostas: IProposalListReponse, paginacao: IProposalListPagination }>(() =>
      this.api.post(`${this.path}/consulta-esteira`, data, config)
    )
  }

  async details (proposalId: string, config?: AxiosRequestConfig) {
    return Repository.handle<IProposalDetailsECPF>(() =>
      this.api.get(`${this.path}/${proposalId}`, config)
    )
  }

  async departments (proposalId: string, config?: AxiosRequestConfig) {
    return Repository.handle<{departments: IProposalDepartmentsECPF}>(() =>
      this.api.get(`${this.path}/${proposalId}/departments `, config)
    )
  }

  async anticipationSimulation (data: IAnticipationSimulationPayload, config?: AxiosRequestConfig) {
    return Repository.handle<IAnticipationSimulationResponse>(() =>
      this.api.post(`${this.path}/acordo/simulacao`, data, config)
    )
  }

  async generateTicket (data: IGenerateTicketPayload, config?: AxiosRequestConfig) {
    return Repository.handle<IGenerateTicketResponse>(() =>
      this.api.post(`${this.path}/acordo-boleto`, data, config)
    )
  }

  async getContractLogs (proposalId: string, stepSlug: string, config?: AxiosRequestConfig) {
    return Repository.handle<IContractLogsResponse>(() =>
      this.api.get(`${this.path}/${proposalId}/contract-log/${stepSlug} `, config)
    )
  }

  async getPendingStep (sensideaId: string, config?: AxiosRequestConfig) {
    return Repository.handle<{ step: { slug: _proposalSteps, status: _proposalStatuses, name: string, instructions: string } }>(() =>
      this.api.get(`${this.path}/${sensideaId}/pending-step`, config)
    )
  }
}

export default new ProposalECPFRepository({ api, path: '/ecp-funcao/propostas' })

import React, { PureComponent, Fragment } from 'react'

class TextAmortization extends PureComponent {
  render () {
    return (
      <Fragment>
        <h1 className="simulator-tooltip-h1">Como funciona ?</h1>
        <p className="simulator-tooltip-paragraph">
          A comissão do parceiro irá funcionar beaseada nas comissões da <b>precificação</b>, ou seja,
          ao colocar um valor de comissão do parceiro ele irá ser X% das comissões da precificação.
        </p>

        <h1 className="simulator-tooltip-h1">Por exemplo:</h1>
        <p className="simulator-tooltip-paragraph">
          80% de comissão do parceiro X 3,00% = <b>2,4% para o parceiro</b>,
          <br/>
          ou
          <br/>
          100% de comissão do parceiro X 3,00% = <b>3,00% para o parceiro</b>.
        </p>
      </Fragment>
    )
  }
}

export default TextAmortization

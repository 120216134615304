import { AxiosRequestConfig } from 'axios'
import { Repository } from 'ecp/repositories/Repository'
import IAuth from 'egi/types/IAuth'
import api from 'services/api'

class B2CLoginRepository extends Repository {
  async fetchRedirectUrl (config?: AxiosRequestConfig) {
    return Repository.handle<{ url: string }>(() =>
      this.api.get(this.path, config)
    )
  }

  async login (body: Partial<{ id_token: string }>) {
    return Repository.handle<IAuth>(() =>
      this.api.post(this.path, body)
    )
  }
}

export default new B2CLoginRepository({ api, path: '/b2clogin' })

import React, { Fragment } from 'react'
import { Button, Drawer } from 'antd'
import { IDrawer } from './types'
import { useDispatch } from 'react-redux'
import { stepSetOpenDrawerLoading } from 'store/modules/step/actions'
import { useStep } from 'hooks'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

function DrawerComponent ({
  visible,
  title,
  width = '60vw',
  children,
  onClose,
  onSave,
  onSubmit,
  onBack,
  showSave,
  showSubmit,
  showBack,
  status,
  canUpdate = true,
  disabled,
  maskClosable,
  footer,
  sendTextOption
}: IDrawer) {
  const dispatch = useDispatch()
  const { md: isSmall } = useBreakpoint()
  const step = useStep()
  let sendText = 'Enviar'

  switch (step.mode) {
    case 'multiple':
      if (step.modeOptions?.selected === 'term') sendText = 'Li e concordo com os termos'
      else sendText = 'Enviar'
      break
    default:
      sendText = 'Enviar'
  }

  return (
    <Drawer
      className="custom-drawer"
      title={<label>{title}</label>}
      closable
      maskClosable={maskClosable}
      destroyOnClose={true}
      visible={visible}
      width={!isSmall ? '100%' : width}
      footer={
        footer === null
          ? footer
          : (
            <Fragment>
              <div>
                <Button
                  danger
                  type="ghost"
                  style={{ backgroundColor: 'transparent' }}
                  onClick={() => onClose && onClose() }>Cancelar
                </Button>
              </div>

              {canUpdate && (
                <div>
                  {showBack && (
                    <Button
                      disabled={disabled}
                      loading={status === 'back'}
                      type="text"
                      onClick={() => onBack && onBack()}
                    >
                        Voltar
                    </Button>
                  )}

                  {showSave && (
                    <Button
                      disabled={disabled}
                      loading={status === 'saving'}
                      type="text"
                      className="mr-1"
                      id="drawer-save-button"
                      style={{ border: '1px solid #d3d6d8' }}
                      onClick={() => onSave && onSave()}
                    >
                      Salvar
                    </Button>
                  )}

                  {showSubmit && (
                    <Button
                      disabled={disabled}
                      loading={status === 'sending'}
                      type="primary"
                      htmlType='submit'
                      id="drawer-submit-button"
                      onClick={() => onSubmit && onSubmit()}>
                      {sendTextOption ?? sendText}
                    </Button>
                  )}
                </div>
              )}
            </Fragment>
          )}
      afterVisibleChange={() => dispatch(stepSetOpenDrawerLoading({ loading: false }))}
      onClose={onClose}
    >
      <div className="drawer-content">
        {children}
      </div>
    </Drawer>
  )
}

export default DrawerComponent

import { IImportFile } from 'egi/app/NotaryFees/notaryFeesInterfaces'
import { NotaryFeesTypes } from './notaryFeesActions'
import { INotaryFeesActionsTypes } from './notaryFeesActionTypes'

export interface INotaryFeesReducer {
  importItem: IImportFile | undefined
  percentage: number
  status: string | null
  antdStatus: 'success' | 'active' | 'normal' | 'exception' | undefined

}

const initialState = {
  percentage: 0,
  status: null,
  antdStatus: 'active',
  importItem: undefined
}

export const loadingReducer = (state = initialState, action: INotaryFeesActionsTypes.allActions) => {
  switch (action.type) {
    case NotaryFeesTypes.SET_PERCENTAGE:
      return {
        ...state,
        percentage: action.payload
      }
    case NotaryFeesTypes.SET_STATUS:
      return {
        ...state,
        status: action.payload
      }

    case NotaryFeesTypes.SET_ANTD_STATUS:
      return {
        ...state,
        antdStatus: action.payload
      }

    case NotaryFeesTypes.SET_IMPORT_FILE:
      return {
        ...state,
        importItem: action.payload
      }
    case NotaryFeesTypes.RESET:
      return initialState
    default:
      return state
  }
}

export default loadingReducer

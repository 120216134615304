import React, { useState } from 'react'
import { Form, Modal } from 'antd'
import ModalShare from 'components/ModalShare/ModalShare'
import swal from 'utils/swal'
import { sendWhatsapp } from 'utils/globals'
import { useClient, useStep, useProposal } from 'hooks'
import EmailRepository from 'egi/repositories/EmailRepository'

function ReviewModal ({ show, link, resetShareModal, finishReview }: { show: boolean, link: string, resetShareModal: Function, finishReview: Function }) {
  const [inviteRef] = Form.useForm()

  const client = useClient()
  const step = useStep()
  const proposal = useProposal()

  const [loading, setLoading] = useState({
    list: false,
    invite: false,
    modalInvite: false,
    email: false,
    generateLink: false
  })

  const inviteEmail = async () => {
    const { email } = inviteRef.getFieldsValue()
    setLoading({ ...loading, email: true })
    try {
      const response = await EmailRepository.stepLink({ email, link, stepName: step.name, proposalId: proposal.id })
      swal.basic({ title: 'Sucesso!', text: response.data?.message, icon: 'success' })
    } catch (err) {
      swal.basic({ title: 'Atenção!', text: err.message, icon: 'warning' })
    } finally {
      setLoading({ ...loading, email: false })
    }
  }

  function okCancelAction () {
    resetShareModal()
    finishReview()
  }

  return (
    <Modal
      visible={show}
      title='Compartilhar link'
      onOk={() => okCancelAction()}
      onCancel={() => okCancelAction()}
    >
      <ModalShare
        email={client.email}
        cellphone={client.cellphone}
        inviteEmail={inviteEmail}
        sendWhatsapp={() => sendWhatsapp(inviteRef.getFieldsValue().cellphone, `Olá, ação necessária no passo *${step.name}*: *${link}*`)}
        form={inviteRef}
        loading={loading}
        invite={link || ''}
      />
    </Modal>
  )
}

export default ReviewModal

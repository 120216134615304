import React, { Fragment, useState } from 'react'
import { Checkbox, Form, Input } from 'antd'
import SelectTypeInvite from '../SelectTypeInvite'
import InviteEgiRepository, { InviteEgiErrors } from 'egi/repositories/InviteEgiRepository'
import { _userLevel } from 'ecp/models/UsersModel'

interface IInviteAuditor {
  level: _userLevel
  onSuccess: (token: string, inviteId?: string, name?: string) => void
  onFailed: (messsage: string) => void
}

interface IGenerateInvite {
  helpdesk: boolean
  name: string
}

function InviteAuditor ({ level, onSuccess, onFailed }: IInviteAuditor) {
  const [loading, setLoading] = useState<boolean>(false)
  const [form] = Form.useForm()

  const generateInvite = async (values: IGenerateInvite) => {
    setLoading(true)
    try {
      const response = await InviteEgiRepository.levelLink<IGenerateInvite>(level, values)
      const { link, inviteId } = response.data.data || {}

      if (!link) throw InviteEgiErrors.link()

      setLoading(false)
      onSuccess(link, inviteId)
    } catch (err) {
      setLoading(false)
      onFailed(err.message)
    }
  }

  return (
    <SelectTypeInvite
      level={level}
      onFinish={generateInvite}
      label=''
      loading={loading}
      form={form}
      layout='flex-column w-75'

      formContent={
        <Fragment>
          <Form.Item
            name="name"
            label={<label className="px-2 bold">Nome do auditor</label>}
          >
            <Input required placeholder={'Digite o nome do auditor'}/>
          </Form.Item>

          <Form.Item
            name='helpdesk'
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox className="px-1">
              Auditor Helpdesk
            </Checkbox>
          </Form.Item>
        </Fragment>
      }
    />
  )
}

export default InviteAuditor

import React from 'react'
import { ArrowDownOutlined } from '@ant-design/icons'
import IframePDF from 'ecp/components/IframePDF/IframePDF'
import { IContractSignatureResource, ILocalizationContract } from '../../proposalInterfaces'
import { Dayjs } from 'dayjs'
import { timeAsDayjs } from 'utils/time'

interface IProps {
  loading: boolean
  contract: IContractSignatureResource | null
}

interface ILocalizationProps {
  localization: ILocalizationContract | undefined
  data: string | Dayjs | undefined
  ip: string | undefined
}

interface ILocalizationItem {
  label: string | undefined
  value: string | undefined | number
}

function LocalizationItem ({ value, label }: ILocalizationItem) {
  return (
    <div className="proposal-contract-pdf__localization-item">
      <label className="proposal-contract-pdf__localization-title">{label}</label>
      <p>{value}</p>
    </div>
  )
}

function LocalizationContainer ({ localization, data, ip }: ILocalizationProps) {
  return (
    <div className="proposal-contract-pdf__localization">
      <h3 className="proposal-contract-pdf__localization-title">Informações de Localização</h3>
      <LocalizationItem label="Latitude" value={localization?.coords?.latitude || '-'} />
      <LocalizationItem label="Longitude" value={localization?.coords?.longitude || '-'} />
      <LocalizationItem label="Altitude" value={localization?.coords?.altitude || '-'} />
      <LocalizationItem label="Precisão" value={localization?.coords?.accuracy || '-'} />
      <LocalizationItem label="Precisão de Altitude" value={localization?.coords?.altitudeAccuracy || '-'} />
      <LocalizationItem label="Velocidade" value={localization?.coords?.speed || '-'} />
      <LocalizationItem label="Heading" value={localization?.coords?.heading || '-'} />
      <LocalizationItem label="Data" value={timeAsDayjs(data).format('DD/MM/YYYY') || '-'} />
      <LocalizationItem label="IP de assinatura" value={ip || '-'} />
    </div>
  )
}

function ProposalContractPDF ({ loading, contract }: IProps) {
  return (
    <div className='proposal-contract-pdf'>
      <h2 className='proposal-contract-pdf__title'>Contrato</h2>
      <div className='proposal-contract-pdf__contract'>
        <IframePDF
          className='proposal-contract-pdf__pdf'
          url={contract?.html ? contract?.html : null}
          loading={loading}
          onLoad={() => { }}
        />
      </div>

      <div className='proposal-contract-pdf__download-button-container'>
        <a
          className='proposal-contract-pdf__download-button'
          href={contract?.pdf}
          key={contract?.pdf}
          target='_blank'
          rel='noreferrer'
          download
        >
          <ArrowDownOutlined /> Download do contrato

        </a>
        <LocalizationContainer
          localization={contract?.localizacao}
          data={contract?.data}
          ip={contract?.ip}
        />

      </div>
    </div>
  )
}

export default ProposalContractPDF

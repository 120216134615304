import React, { useState, Fragment } from 'react'
import { Button, Col, Divider, Form, Select, Row, Empty } from 'antd'
import { DocumentsView, Drawer } from 'components'
import { IProvider } from 'egi/types/IProvider.'
import { useDispatch } from 'react-redux'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'
import translate from 'utils/translate'
import { useAuth, useTables } from 'hooks'
import { tablesUpdate } from 'store/modules/tables/actions'
import InvitedsRepository from 'egi/repositories/InvitedsRepository'
import RichTextEditor from 'components/RichTextEditor/RichTextEditor'

interface IDrawerDocuments {
  visible: boolean
  onClose(): void
}

const DrawerApproveDocuments = ({ visible, onClose }: IDrawerDocuments) => {
  const [errors, setErrors] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [richText, setRichText] = useState<string>('')
  const [form, setForm] = useState({
    status: ''
  })

  const [formRef] = Form.useForm()
  const dispatch = useDispatch()
  const table = useTables()
  const user = useAuth()
  const IS_AUDITOR = user.level === 'auditor'
  const canReview = IS_AUDITOR || table.row?.status === 'approved'

  const closeDrawer = () => {
    if (formRef) {
      formRef.resetFields()
      setRichText('')
      onClose()
      return
    }

    setRichText('')
    onClose()
  }

  const handleVerify = async (values: IProvider) => {
    const formData = values
    let invitedId

    if (table.row && table.row._id) invitedId = table.row._id

    const props = {
      invitedId: invitedId,
      message: richText,
      status: formData.status
    }

    setLoading(true)
    try {
      const response = await InvitedsRepository.verifyProvider(props)
      const message = response?.data?.message
      swal.basic({ title: 'Sucesso', text: message, icon: 'success' })
      setErrors({})
      dispatch(tablesUpdate())
      closeDrawer()
    } catch (err) {
      if (err.data?.invalid) {
        let errors: any = {}
        errors = validateResponse(err.data?.invalid)
        setErrors(errors)
        setLoading(false)
        swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      }

      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Drawer
      title={`Documentos do ${translate.level(table.row.level)} - ${table.row.name}`}
      visible={visible}
      onClose={closeDrawer}
      closable={true}
    >
      <Fragment>
        {table.row?.documents?.map((item: any) => {
          return (
            <Fragment key={item.slug}>
              <h2 className="color-primary">{item.name}</h2>
              <Row>
                {item.paths && item.paths.length > 0 ? (
                  item.paths.map((paths: any) => {
                    return (
                      <div key={paths} className="ml-2">
                        <DocumentsView
                          isOnlyLink={paths}
                        />
                      </div>
                    )
                  })) : (
                  <Row className="w-100 flex-justify-center">
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Nenhum documento enviado'}/>
                  </Row>
                )}
              </Row>

              <Divider/>
            </Fragment>
          )
        })}

        {canReview ? (
          <></>
        ) : (
          <div className="text-center">
            <div className="w-50 w-md-100 m-auto p-3 card-form-commission">

              <Form onFinish={handleVerify} layout="vertical" form={formRef}>
                <Form.Item
                  label={<label>Você deseja aprovar esse {translate.level(table.row?.level)}?</label>}
                  name="status"
                  help={errors.status && errors.status}
                  validateStatus={errors.status && 'error'}
                  rules={[{ required: true, message: 'Escolha o status.' }]}
                >
                  <Select
                    className="w-75 mb-3"
                    placeholder="Selecione novo status"
                    onChange={(value: string) => setForm({ ...form, status: value })}
                  >
                    <Select.Option value="approved">Aprovar</Select.Option>
                    <Select.Option value="remaking">Refazer</Select.Option>
                    <Select.Option value="reproved">Reprovar</Select.Option>
                  </Select>
                </Form.Item>

                {(form.status === 'remaking' || form.status === 'reproved') && (
                  <Row>
                    <Col lg={24} sm={24} xs={24} className="mb-3">
                      <label>Revisão:</label>

                      <Form.Item
                        help={errors.commentary && errors.commentary}
                        validateStatus={errors.commentary && 'error'}
                      >
                        <RichTextEditor
                          setContents={richText}
                          onChange={(value: string) => setRichText(value)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                <Button
                  className="mr-0 mb-3 w-75 w-md-75"
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                >
                    Enviar
                </Button>
              </Form>

            </div>
          </div>
        )}
      </Fragment>
    </Drawer>
  )
}

export default DrawerApproveDocuments

import React, { Fragment, useEffect, useState } from 'react'
import { Col, Row, Form, Divider, Typography, Radio, Button, Spin } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import { Input } from 'components'
import { IOnidataContractErrors } from '../contract'
import { proposals, resources } from 'egi/consumers'
import { validateResponse } from 'utils/validate'
import swal from 'utils/swal'
import { useProposal, useStep } from 'hooks'
import { stepForm } from 'store/modules/step/actions'
import { useDispatch } from 'react-redux'
import { swalError } from 'components/SwalError/SwalError'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'

interface IOnidataContractSelectStep {
  geral_numero_contrato: string
  geral_quantidade_vias: string
  modelo_contrato: string
}

function ContractSelectStep ({ readOnly, onSuccess }: { readOnly?: boolean, onSuccess: (value: boolean | undefined) => void }) {
  const [errors, setErrors] = useState<IOnidataContractErrors>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [contractType, setContractType] = useState<string>('')
  const [minutaVersions, setMinutaVersions] = useState<Array<string>>([])
  const [resourceVersionsLoading, setResourceVersionsLoading] = useState<boolean>(false)

  const step = useStep()
  const proposal = useProposal()
  const dispatch = useDispatch()
  const [formRef] = Form.useForm()

  function onError (err: any) {
    swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    setLoading(false)
  }

  const onSubmit = async (values: IOnidataContractSelectStep) => {
    setLoading(true)
    try {
      if (!proposal.id) throw new Error('Falha ao encontrar id da proposta')
      if (!step.form) throw new Error('Falha ao encontrar form')
      if (!step.id) throw new Error('Falha ao encontrar step id')
      if (!step.areaId) throw new Error('Falha ao encontrar area Id')

      const props: any = {
        form: { ...values, finished: false, currentStep: 1 },
        proposalId: proposal.id,
        areaId: step.areaId,
        stepId: step._id
      }

      const response = await proposals.send(props)
      dispatch(stepForm({ data: values }))
      onSuccess(true)
      setErrors({})
      swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
      setLoading(false)
    } catch (err) {
      if ([
        'Falha ao encontrar id da proposta',
        'Falha ao encontrar form',
        'Falha ao encontrar step id',
        'Falha ao encontrar area Id'
      ].includes(err.message)) return onError(err)

      if (err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
        setLoading(false)

        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
      } else {
        swalError({ title: 'Atenção', icon: 'warning', err })
        setErrors({})
      }

      setLoading(false)
    }
  }

  useEffect(() => {
    async function onFill () {
      try {
        const tempForm = JSON.parse(JSON.stringify(step.form))

        const geral_modelo_contrato = 'unified'
        setContractType(geral_modelo_contrato)

        if (formRef) formRef.setFieldsValue({ ...tempForm, geral_modelo_contrato })
      } catch (err) {
        swal.basic({ title: 'Atenção!', text: err.message, icon: 'warning' })
      }
    }

    async function getMinutaVersions () {
      setResourceVersionsLoading(true)
      try {
        const response = await resources.minutaVersions()
        if (response?.versions) setMinutaVersions(response.versions)
      } catch (err) {
        swal.basic({ title: 'Atenção!', text: err.message, icon: 'warning' })
      }
      setResourceVersionsLoading(false)
    }

    getMinutaVersions()
    onFill()
  }, [])

  useEffect(() => {
    const tempForm = JSON.parse(JSON.stringify(step.form))
    if (formRef && tempForm?.geral_versao_contrato) {
      formRef.setFieldsValue({ geral_versao_contrato: [...minutaVersions].pop() })
    } else {
      formRef.setFieldsValue({ geral_versao_contrato: minutaVersions?.[0] })
    }
  }, [minutaVersions])

  return (
    <Fragment>
      <Form
        layout="vertical"
        form={formRef}
        className="form-contract mt-5"
        onFinish={onSubmit}
        initialValues={{
          geral_modelo_contrato: 'unified'
        }}
      >
        <Col span={24}>
          <Row gutter={[15, 15]} align="top">
            <Col lg={8} md={24} sm={24} xs={24}>
              <Form.Item
                name='geral_numero_contrato'
                label={<label>Número contrato</label>}
                help={errors.geral_numero_contrato && (errors.geral_numero_contrato)}
                validateStatus={errors.geral_numero_contrato && ('error')}
              >
                <Input
                  readOnly
                  placeholder='Digite o número do contrato'
                  type="text"
                  id="test-form-item-geral_numero_contrato"
                  error={errors.geral_numero_contrato}
                />
              </Form.Item>
            </Col>

            <Col lg={8} md={24} sm={24} xs={24}>
              <Form.Item
                name='geral_quantidade_vias'
                label={<label>Quantidade vias</label>}
                help={errors.geral_quantidade_vias && (errors.geral_quantidade_vias)}
                validateStatus={errors.geral_quantidade_vias && ('error')}
              >
                <Input
                  min={0}
                  readOnly={readOnly}
                  placeholder='Digite a quantidade de vias'
                  id="test-form-item-geral_quantidade_vias"
                  type="number"
                  error={errors.geral_quantidade_vias}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Form.Item
            name='geral_modelo_contrato'
            help={errors.geral_modelo_contrato && errors.geral_modelo_contrato}
            validateStatus={errors.geral_modelo_contrato && 'error'}
            label={<label className='form-section-title form-section-title--primary bold'>Tipo de contrato</label>}
          >
            <Radio.Group
              disabled={readOnly}
              onChange={(event) => setContractType(event.target.value)}
            >
              <Radio value="unified" className='mt-2'>Minuta unificada</Radio>
            </Radio.Group>
          </Form.Item>

          {contractType && contractType.includes('unified') && (
            <Spin spinning={resourceVersionsLoading}>
              <Col span={24}>
                <Typography.Title
                  className="form-section-title"
                  style={{ color: 'var(--primary-color)' }}
                  level={5}
                >
                  Selecione a versão do contrato
                </Typography.Title>
              </Col>
              <Form.Item
                name='geral_versao_contrato'
                help={errors.geral_versao_contrato && errors.geral_versao_contrato}
                validateStatus={errors.geral_versao_contrato && 'error'}
              >
                <Radio.Group disabled={readOnly}>
                  {minutaVersions.map((version) => (
                    <Radio
                      value={version}
                      key={version}
                      style={{ color: 'var(--primary-color)' }}
                      className='mt-2'
                    >
                      {version}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Spin>
          )}

          <Row justify="end" align="middle">
            <Col>
              <Button
                loading={loading}
                className="mt-4 text-center w-100 color-white uppercase"
                type="primary"
                htmlType="submit"
                disabled={readOnly}
              >
                Avançar
                <ArrowRightOutlined />
              </Button>
            </Col>
          </Row>
        </Col>
      </Form>
    </Fragment>
  )
}

export default ContractSelectStep

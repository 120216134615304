import React, { useLayoutEffect, useState } from 'react'
import { Form, Select } from 'antd'
import { resources } from 'egi/consumers'
import { ILabelValue } from 'store/modules/resources/types'

function FilterPromoterType ({ callback, hasTM }: { callback: Function, hasTM?: boolean }) {
  const [promoterType, setPromoterType] = useState([])

  useLayoutEffect(() => {
    const getPromoterType = async () => {
      try {
        const response = await resources.promoterType({ params: { all: hasTM } })
        const types = response.data.types
        setPromoterType(types)
      } catch (err) {
        console.error(err)
      }
    }

    getPromoterType()
  }, [])

  return (
    <Form.Item
      name="promoterType"
      label={<label>Tipo de parceiro</label>}
    >
      <Select
        placeholder="Escolha o tipo do parceiro"
        onChange={(value: string) => {
          if (value) callback(value)
          else callback(undefined)
        }}
        allowClear
      >
        <Select.Option value="">Todos</Select.Option>
        {promoterType.map((item: ILabelValue) => (
          <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default FilterPromoterType

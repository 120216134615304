import { AxiosRequestConfig } from 'axios'
import { IErrorDetails } from 'components/ErrorDetails/interfaces'
import ProposalRepository from 'ecp/repositories/ProposalRepository'
import { useDynamicProposalStore } from 'hooks'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { dynamicProposalActions } from 'store/modules/dynamicProposal/dynamicProposalActions'
import usePendentProposal from './usePendentProposals'

function useDynamicProposal () {
  const { departments, details, steps, loadingSteps } = useDynamicProposalStore()
  const { getPendencies } = usePendentProposal()

  const dispatch = useDispatch()
  const history = useHistory<{ history: boolean } | undefined>()
  const hasFocusHistory = !!history.location.state?.history

  const [shouldFocusHistory, setShouldFocusHistory] = useState(false)
  const [error, setError] = useState<IErrorDetails>()

  const getProposal = useCallback(async (id: string) => {
    try {
      const response = await ProposalRepository.find(id)
      const data = response.data.data?.proposal
      if (data) dispatch(dynamicProposalActions.setDetails({ loading: false, value: data }))
    } catch (err) {
      setError(err)
      dispatch(dynamicProposalActions.setDetailsError(err))
      throw err
    }
  }, [dispatch])

  const getInitialProposal = useCallback(async (id: string) => {
    dispatch(dynamicProposalActions.setDetails({ loading: true }))
    try {
      if (!id) throw new Error('Id de proposta não encontrado')
      await getProposal(id)
    } catch (err) {
      setError(err)
    } finally {
      dispatch(dynamicProposalActions.setDetails({ loading: false }))
    }
  }, [
    getProposal
  ])

  const getDepartments = useCallback(async (id: string) => {
    const abortController = new AbortController()
    const requestConfig = {
      signal: abortController.signal
    }
    try {
      const response = await ProposalRepository.getDepartments(id, requestConfig)
      const departments = response.data.data?.department

      if (hasFocusHistory) setShouldFocusHistory(true)
      if (departments) dispatch(dynamicProposalActions.setDeparments(departments))
    } catch (err) {
      console.error(err)
      setError(err)
    }
  }, [dispatch, dynamicProposalActions.setDeparments, setShouldFocusHistory])

  const getSteps = useCallback(async (ids: { departmentId: string, proposalId: string}, config?: AxiosRequestConfig) => {
    const response = await ProposalRepository.getSteps(ids, config)
    const steps = response.data.data?.steps
    const { departmentId } = ids

    if (steps) dispatch(dynamicProposalActions.setSteps({ steps: { value: steps }, departmentId }))
  }, [])

  const getInitialSteps = async (ids: { departmentId: string; proposalId: string }) => {
    const abortController = new AbortController()
    const requestConfig = {
      signal: abortController.signal
    }
    const { departmentId } = ids
    dispatch(dynamicProposalActions.setSteps({ steps: { loading: true }, departmentId }))

    try {
      await getSteps(ids, requestConfig)
    } catch (err) {
      setError(err)
    } finally {
      dispatch(dynamicProposalActions.setSteps({ steps: { loading: false }, departmentId }))
    }
  }

  const reloadProposal = useCallback(async ({ proposalId, stepId }: { proposalId: string, stepId: string }) => {
    dispatch(dynamicProposalActions.stepLoadingOn({ stepId }))
    try {
      if (!proposalId) throw new Error('Id de proposta não encontrado')
      await getProposal(proposalId)
    } catch (err) {
      console.error(err)
      setError(err)
    } finally {
      dispatch(dynamicProposalActions.stepLoadingOff({ stepId }))
    }
  }, [
    getProposal
  ])

  const reloadProposalAndSteps = useCallback(async ({ proposalId, stepId, departmentId }:{ proposalId: string, stepId: string, departmentId: string }) => {
    dispatch(dynamicProposalActions.stepLoadingOn({ stepId }))
    try {
      if (!proposalId) throw new Error('Id de proposta não encontrado')
      await getProposal(proposalId)
      await getSteps({ proposalId, departmentId })
      await getPendencies(proposalId)
    } catch (err) {
      setError(err.message)
    } finally {
      dispatch(dynamicProposalActions.stepLoadingOff({ stepId }))
    }
  }, [
    getProposal
  ])

  const cancelFocusHistory = () => {
    setShouldFocusHistory(false)
  }

  const reset = () => {
    dispatch(dynamicProposalActions.reset())
  }

  return {
    departments,
    details,
    steps,
    loadingSteps,
    getProposal,
    getInitialProposal,
    reloadProposal,
    shouldFocusHistory,
    cancelFocusHistory,
    getDepartments,
    reloadProposalAndSteps,
    getInitialSteps,
    error,
    reset
  }
}

export default useDynamicProposal

import { _userLevel } from 'ecp/models/UsersModel'

const DivulgationModel = {
  canUploadDivulgation: (userLevel: _userLevel) => (['master', 'promoter'].includes(userLevel)),

  canEditDivulgation: (userLevel: _userLevel, creatorLevel: _userLevel) => {
    if (userLevel === 'master' && creatorLevel === 'master') {
      return true
    }

    if (userLevel === 'promoter' && creatorLevel === 'promoter') {
      return true
    }

    return false
  }
}

export default DivulgationModel

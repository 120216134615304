import React from 'react'
import { Form, Select } from 'antd'
import { SelectValue } from 'antd/lib/select'

function FilterCurrentStep ({ onChange }: { onChange: (value: SelectValue) => void }) {
  return (
    <Form.Item
      name='currentStep'
      label={<label>Passo atual</label>}
    >
      <Select
        showSearch
        allowClear
        optionFilterProp="children"
        className="w-100"
        onChange={onChange}
        placeholder='Selecione o parceiro'
      >
        <Select.Option value="">Todos</Select.Option>
        <Select.Option value="1">1º passo</Select.Option>
        <Select.Option value="2">2º passo</Select.Option>
        <Select.Option value="3">3º passo</Select.Option>
        <Select.Option value="4">4º passo</Select.Option>
        <Select.Option value="5">5º passo</Select.Option>
      </Select>
    </Form.Item>
  )
}

export default FilterCurrentStep

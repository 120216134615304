import React, { useEffect, useState } from 'react'
import { Form } from 'antd'
import { DatepickerCustom } from 'components'
import { useDispatch } from 'react-redux'
import { useResources, useTables } from 'hooks'
import locale from 'antd/es/date-picker/locale/pt_BR'
import { resourcesGetAreas } from 'store/modules/resources/actions'
import { tablesSetFiltersValues } from 'store/modules/tables/actions'
import dayjs, { Dayjs } from 'dayjs'

export type ICreatedAt = {
  onChange: Function
  fixedDate?: boolean
}

function FilterCreatedAt ({ onChange, fixedDate = false }: ICreatedAt) {
  const dispatch = useDispatch()
  const resources = useResources()
  const tables = useTables()

  const [pickerCreatedAt, setPickerCreatedAt] = useState<Dayjs | undefined>(undefined)
  const [currentDate, setCurrentDate] = useState<Dayjs | undefined>(dayjs())
  const disabledCreatedDate = tables.ignoreAll || (!tables.filters.createdDate && tables.filters.trimester)

  useEffect(() => {
    if (resources.areas.length < 1) dispatch(resourcesGetAreas())
    if (!fixedDate) dispatch(tablesSetFiltersValues({ ...tables.filters, createdDate: undefined }))
  }, [])

  return (
    <Form.Item
      name="createdDate"
      label={<label>Data criação</label>}
    >

      <DatepickerCustom.MonthPicker
        disabled={disabledCreatedDate ? true : false}
        locale={locale}
        format="MM/YYYY"
        disabledDate={(current) => current.isAfter(new Date())}
        allowClear={fixedDate ? false : true}
        value={!fixedDate ? undefined : pickerCreatedAt}
        defaultValue={!fixedDate ? undefined : currentDate}
        onChange={(value) => {
          if (value) {
            setPickerCreatedAt(value)
            setCurrentDate(value)
            onChange(value)
          } else {
            setPickerCreatedAt(undefined)
            setCurrentDate(undefined)
            onChange(undefined)
          }
        }}
      />
    </Form.Item>
  )
}

export default FilterCreatedAt

import TokenVerificationSVG from 'assets/reactSvgs/TokenVerificationSVG/TokenVerificationSVG'
import React, { ReactNode } from 'react'

type props = {
  title?: string | ReactNode
  infoText?: string | ReactNode
  className?: string
}

export default function TokenHeader ({ title, infoText, className } : props) {
  return (
    <div className={`ecp-token-header ${className ? className : ''}`} >
      {typeof title === 'string' ? <h1 className='ecp-token-header__title'>{title}</h1> : title}
      <div className='ecp-token-header__icon-container'>
        <TokenVerificationSVG />
      </div>

      {infoText &&
        <>
          {typeof infoText === 'string' ? <p className='ecp-token-header__info-text'>{infoText}</p> : infoText}
        </>
      }
    </div>
  )
}

import LGPD from 'ecp/app/LGPD/LGPD'
import React from 'react'

interface Props {
  termUrl: string | null | undefined
}

function FullScreenLGPD ({ termUrl = '' }: Props) {
  return (
    <div className='full-screen-lgpd'>
      <div className='full-screen-lgpd__container'>
        <h2 className='full-screen-lgpd__header'>TERMOS LGPD</h2>
        <p>O aceite do termo LGPD é necessário para a utilização do sistema. Seu acesso será permitido após enviar sua concordância com os termos.</p>
        <LGPD layoutPage={true} docUrl={termUrl} />
      </div>
    </div>
  )
}

export default FullScreenLGPD

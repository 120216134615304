import { Row, Typography, Form, Checkbox, Col } from 'antd'
import { IFormDataErrors } from 'components/FormUserData/types'
import { useStep } from 'hooks'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { bacenTerms, lgpdTerms } from 'routes/routes'
import { timeAsDayjs } from 'utils/time'

interface ICheckTermsFields {
  errors?: IFormDataErrors
  readOnlyInput: boolean
}

export const CheckTermsFields = ({ errors, readOnlyInput }: ICheckTermsFields) => {
  const step = useStep()
  return <>
    <Typography.Paragraph className="bold">
      Termos
    </Typography.Paragraph>
    <Row>
      <Col>
        <Form.Item
          name='lgpdBacenTerms'
          className="form-user-checkbox"
          valuePropName="checked"
          help={errors?.lgpdBacenTerms && (errors?.lgpdBacenTerms)}
          validateStatus={errors?.lgpdBacenTerms && ('error')}
          rules={[{
            required: true,
            message: 'É necessário concordar com os termos'
          }]}
        >
          <Checkbox
            id="checkbox-lgpd-bacen-terms"
            disabled={readOnlyInput}
          >
            <span className='color-refused'>*</span>
          &nbsp;De acordo com os termos do&nbsp;
            <Link
              to={`/auth${bacenTerms.path}`}
              target='_blank'
              className='link-style color-tertiary'>
              BACEN
            </Link> e do
            &nbsp;<Link
              to={`/auth${lgpdTerms.path}`}
              target='_blank'
              className='link-style color-tertiary'>
              LGPD
            </Link>
          </Checkbox>
        </Form.Item>
      </Col>
    </Row>
    { step?.form?.lgpdBacenTermsAcceptanceDate && step?.form?.lgpdBacenTermsSignature &&
      <Col className="check-terms-fields__signature">
        <p>
          <b>Assinado em:&nbsp;
          </b>{timeAsDayjs(step?.form?.lgpdBacenTermsAcceptanceDate).format('DD/MM/YYYY HH:mm')}</p>
        <p>
          <b>Código de assinatura:&nbsp;</b>{step?.form?.lgpdBacenTermsSignature}</p>
      </Col>
    }
  </>
}


import { InfoCircleOutlined } from '@ant-design/icons'
import { Form, Switch, Input, message, Col, Row, Button } from 'antd'

import { InputPassword } from 'components'
import { EmailLogs } from 'egi/app/Settings/components/Logs/EmailLogs'
import SettingsCard from 'egi/app/Settings/components/SettignsCard/SettingsCard'
import SettingsHistory from 'egi/app/Settings/components/SettignsHistory/SettingsHistory'

import EmailRepository from 'egi/repositories/EmailRepository'
import React, { ReactNode, useEffect, useState } from 'react'

import translate from 'utils/translate'
import { validateResponse } from 'utils/validate'

export interface IConfig {
  from: string
  host: string
  pass: string
  port: number
  secure: boolean
  tls: boolean
  useAltMail: boolean
  user: boolean
  isConfigured: boolean
}

const EmailConfigHeader = () => {
  return <Row className="pb-5 email-config__mobile-hide">
    <Col lg={5} >
      <label className="email-config__input-label" >Propriedade</label>
    </Col>
    <Col lg={12} >
      <label className="email-config__input-label" > Valor novo</label>
    </Col>

    <Col lg={7} className="pl-5">
      <label className="email-config__input-label">
    Valor atual</label>
    </Col>
  </Row>
}

const EmailConfigValue = ({ label, children, oldValue } :{ label: string, children: ReactNode, oldValue: string}) => {
  return <Row gutter={[15, 15]}>
    <Col lg={5} sm={24} >
      <label className="email-config__input-label">
        {label}
      </label>
    </Col>
    {children}
    <Col lg={7} sm={24} className="email-config__mobile-hide">
      <p className="email-config__logs-table-property email-config__logs-table-item">{oldValue}</p>
    </Col>
  </Row>
}

export function EmailConfig () {
  const [config, setConfig] = useState<IConfig>()
  const [useAltMail, setUseAltMail] = useState<boolean>(false)
  const [secure, setSecure] = useState<boolean>(false)
  const [tls, setTls] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<any>()
  const [form] = Form.useForm()
  const [visible, setVisible] = useState<boolean>(false)

  const getEmailConfig = async () => {
    try {
      const response = await EmailRepository.config()
      setConfig(response?.data?.data?.mailerConfiguration)
      form.setFieldsValue(response?.data?.data?.mailerConfiguration)

      setUseAltMail(response?.data?.data?.mailerConfiguration.useAltMail ?? false)
      setSecure(response?.data?.data?.mailerConfiguration.secure ?? false)
      setTls(response?.data?.data?.mailerConfiguration.tls ?? false)
    } catch (err) {
      message.error(err.message)
    }
  }

  const onSubmit = async (data: IConfig) => {
    setLoading(true)
    try {
      await EmailRepository.editConfig(data)
      message.success('Informações salvas com sucesso')
    } catch (err) {
      message.error(err.message)
      if (err.data && err.data.invalid) setErrors(validateResponse(err.data.invalid))
      message.error(err.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getEmailConfig()
  }, [])

  const onChangeVisible = () => {
    setVisible(prev => !prev)
  }

  return <>
    <SettingsHistory
      visible={visible}
      onChangeVisible={onChangeVisible}
      title='Histórico de configuração de email'
      description='Confira o histórico de alteração da configuraçõe de e-mail'
    >
      <EmailLogs/>
    </SettingsHistory>
    <SettingsCard.Header
      title="Editar Configurações de email"
      description={
        <>
          <p>
          Edite de maneira fácil as configurações de email para atender suas necessidades. Nesta tela, você pode configurar as informações de e-mail, como servidor, porta, e autenticação. Ao finalizar, salvar as mudanças.
          </p>
        </>
      }
    />

    <SettingsCard>
      <div className="email-config__title">
        <SettingsCard.title>Editar configurações de email</SettingsCard.title>
        <Button type="primary" onClick={onChangeVisible}>Ver histórico</Button>
      </div>
      <SettingsCard.description className="mb-2">Preencha o formulário e salve para fazer as alterações</SettingsCard.description>
      <div className="email-config__form-container">
        <Form
          className="email-config__form"
          onFinish={onSubmit}
          layout="vertical"
          form={form}
        >
          <EmailConfigHeader/>

          <EmailConfigValue label="Usar smtp Rodobens" oldValue={translate.yesNo(config?.useAltMail ?? false)}>
            <Col lg={12} sm={24} >
              <Form.Item
                name='useAltMail'
                help={errors?.useAltMail && (errors?.useAltMail)}
                validateStatus={errors?.useAltMail && ('error')}
              >
                <Switch onChange={(value) => setUseAltMail(value)} checked={useAltMail} />

              </Form.Item>

              <div className="email-config__warning-smtp">
                <InfoCircleOutlined className="email-config__warning-smtp-icon" />
                <p>Caso não ativada esta opção, emails partirão de no.reply@agxsoftware.com.br </p>
              </div>
            </Col>
          </EmailConfigValue>
          <EmailConfigValue label="Nome remetente" oldValue={config?.from ?? '-'}>
            <Col lg={12} sm={24} xs={24}>
              <Form.Item
                name='from'
                help={errors?.from && (errors?.from)}
                validateStatus={errors?.from && ('error')}
              >
                <Input placeholder="exemplo@email.com" disabled={!useAltMail} />
              </Form.Item>
            </Col>
          </EmailConfigValue>
          <EmailConfigValue label="Host" oldValue={config?.host ?? '-'}>
            <Col lg={12} sm={24} xs={24}>
              <Form.Item
                name='host'
                help={errors?.host && (errors?.host)}
                validateStatus={errors?.host && ('error')}
              >
                <Input placeholder="mail.agxsoftware.com" disabled={!useAltMail} />
              </Form.Item>
            </Col>
          </EmailConfigValue>
          <EmailConfigValue label="Porta" oldValue={config?.port.toString() ?? '-'}>
            <Col lg={12} sm={24} xs={24}>
              <Form.Item
                name='port'
                help={errors?.port && (errors?.port)}
                validateStatus={errors?.port && ('error')}
              >
                <Input placeholder="304" disabled={!useAltMail} />
              </Form.Item>
            </Col>
          </EmailConfigValue>
          <EmailConfigValue label="Usuário" oldValue={config?.user.toString() ?? '-'}>
            <Col lg={12} xs={24}>
              <Form.Item
                name='user'
                help={errors?.user && (errors?.user)}
                validateStatus={errors?.user && ('error')}
              >
                <Input placeholder="exemplo@email.com" disabled={!useAltMail} />
              </Form.Item>
            </Col>
          </EmailConfigValue>
          <EmailConfigValue label="Senha" oldValue={config?.pass ?? '******'}>
            <Col lg={12} xs={24}>
              <Form.Item
                name='pass'
                help={errors?.pass && (errors?.pass)}
                validateStatus={errors?.pass && ('error')}
              >
                <InputPassword disabled={!useAltMail} />
              </Form.Item>
            </Col>
          </EmailConfigValue>

          <Row>
            <Col lg={8} sm={12} xs={12}>
              <Form.Item
                name='tls'
                label={<label className="email-config__input-label">TLS</label>}
                help={errors?.tls && (errors?.tls)}
                validateStatus={errors?.tls && ('error')}
              >
                <Switch disabled={!useAltMail} checked={tls} onChange={(value) => setTls(value)} />
              </Form.Item>
            </Col>
            <Col lg={9} sm={12} xs={12} >
              <Form.Item
                help={errors?.secure && (errors?.secure)}
                validateStatus={errors?.secure && ('error')}
                name='secure'
                label={<label className="email-config__input-label">Secure</label>}
              >
                <Switch disabled={!useAltMail} checked={secure} onChange={(value) => setSecure(value)} />
              </Form.Item></Col>
            <Col lg={3} sm={12} xs={12} className="email-config__old-config-column">
              <p className="email-config__logs-table-property email-config__logs-table-item">
                {translate.yesNo(config?.tls ?? false)}
              </p>
            </Col>
            <Col lg={3} sm={12} xs={12} className="email-config__old-config-column">
              <p className="email-config__logs-table-property email-config__logs-table-item">
                {translate.yesNo(config?.secure ?? false)}
              </p>
            </Col>
          </Row>
          <SettingsCard.ButtonsContainer>
            <SettingsCard.submitButton loading={loading}>
              Salvar
            </SettingsCard.submitButton>
          </SettingsCard.ButtonsContainer>
        </Form>
      </div>
    </SettingsCard>
  </>
}

import React, { Fragment, useState } from 'react'
import swal from 'utils/swal'

import usePartnerStep from '../../../../hooks/usePartnerStep'
import usePartnerDepartments from '../../../../hooks/usePartnerDepartments'
import usePartnerDocumentation from '../../../../hooks/usePartnerDocumentation'
import EditBox from 'components/EditBox/EditBox'
import PartnersRepository from 'egi/repositories/PartnersRepository'

function PartnerContractEditBox ({ value, slug, onSuccess, readOnly }: { value: string, slug: string, onSuccess: Function, readOnly?: boolean }) {
  const [visible, setVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const { step } = usePartnerStep()
  const { slug: departmentSlug } = usePartnerDepartments()
  const { _id: documentId } = usePartnerDocumentation()

  const onSubmit = async () => {
    const div = document.getElementById(`editable-content-${slug}`)
    const edit = div?.innerHTML
    setLoading(true)
    try {
      const response = await PartnersRepository.contractEdit(documentId, departmentSlug, step.slug, { slug: slug, value: edit })
      onSuccess(response.data.data.quadros)
      setLoading(false)
      setVisible(false)
      swal.basic({ title: 'Sucesso!', text: response.data.message, icon: 'success' })
    } catch (err) {
      setLoading(false)
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    }
  }

  return (
    <Fragment>
      <EditBox
        loading={loading}
        onSubmit={onSubmit}
        readOnly={!!readOnly}
        slug={slug}
        value={value}
        visible={visible}
        setVisible={setVisible}
      />
    </Fragment>
  )
}

export default PartnerContractEditBox

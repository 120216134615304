import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import dayjs, { Dayjs } from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { ConfigProvider } from 'antd'
import setEvironment from './utils/environment'
import ptBr from 'antd/lib/locale/pt_BR'
import { Provider } from 'react-redux'
import store from 'store'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'

const quarterFormatFn = (date: Dayjs): string => {
  const q = date.format('Q') + ''
  const format: { [key: string]: string } = {
    1: '1˚ Tri',
    2: '2˚ Tri',
    3: '3˚ Tri',
    4: '4˚ Tri'
  }
  return format[q]
}

if (ptBr.DatePicker) {
  (ptBr.DatePicker.lang.quarterFormat as any) = (date: Dayjs): string => {
    return quarterFormatFn(date)
  }
}

dayjs.extend(quarterOfYear)
dayjs.locale('pt-br')
dayjs.extend(duration)
setEvironment()

ReactDOM.render(
  <ConfigProvider locale={ptBr}>
    <Provider store={store}>
      <App />
    </Provider>
  </ConfigProvider>,
  document.getElementById('root')
)

import { CloseOutlined, EyeOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import { IRequestInfo } from 'ecp/app/Logs/logInterfaces'
import { IZipDocumentLog } from 'egi/app/ProposalEgi/components/ZipDocumentsLogModal/ZipDocumentsLogModal'

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import translate from 'utils/translate'

interface Props {
  requestInfo: IRequestInfo
  row: IZipDocumentLog
}

function DownloadsLogsModal ({ requestInfo, row }: Props) {
  const [visible, setVisible] = useState<boolean>(false)
  const { browser, browserVersion, device, ip, os } = requestInfo
  const { processedDocuments, user } = row

  function openOrClose () {
    setVisible(prev => !prev)
  }

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        title={null}
        onOk={openOrClose}
        destroyOnClose
        closeIcon={<CloseOutlined onClick={openOrClose} />}
        footer={null}
      >
        <h2 className='download-modal__title'>Informações de download</h2>
        <div className="download-modal__container-list">
          <div className="download-modal__list">
            <li className="download-modal__list-item">
              <p><b>Navegador:</b></p>
              <p>{browser ?? '-'} Versão {browserVersion ?? '-'}</p>
            </li>
            <li className="download-modal__list-item">
              <p><b> Aparelho:</b></p>
              <p>{device ?? '-'}</p>
            </li>
            <li className="download-modal__list-item">
              <p><b>IP:</b></p>
              <p> {ip ?? '-'}</p>
            </li>

            <li className="download-modal__list-item">
              <p><b>Sistema operacional:</b></p>
              <p>  {os ?? '-'}</p>
            </li>

            <li className="download-modal__list-item">
              <p><b>Total Documentos Processados:</b></p>
              <p>  {processedDocuments}</p>
            </li>

            <li className="download-modal__list-item">
              <p><b>Usuário:</b></p>
              <p>{user.name} ({translate.level(user.level)} {translate.areasNames(user.areaName)}) </p>
            </li>

          </div>
        </div>

      </Modal>

      <Link
        className="link-style color-tertiary font-500 overflow-ellipsis"
        onClick={openOrClose}
        to={'#'}>
        Ver Informações <EyeOutlined className="ml-1" />
      </Link>

    </>
  )
}

export { DownloadsLogsModal }

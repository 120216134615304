import VerifyEmailSuccesSVG from 'assets/reactSvgs/VerifyEmailSuccesSVG/VerifyEmailSuccesSVG'
import { UnauthLayout } from 'layouts'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { UNAUTHS_PATHS } from 'routes/unauthRoutes'
import swal from 'utils/swal'

export interface IForgotPasswordSentEmail {
    email: string,
    time:number
  }

function ResetPasswordSendEmail () {
  const { state } = useLocation<IForgotPasswordSentEmail>() || {}
  const [time, setTime] = useState<string>('')
  const history = useHistory()

  useEffect(() => {
    if (state?.time) {
      let time = state?.time || 0
      const interval = setInterval(() => {
        time--
        if (time < 1) {
          swal.basic({ title: 'Atenção!', text: 'O link expirou, digite eu email novamente para reenviar o link.', icon: 'warning' })
          history.push({
            pathname: UNAUTHS_PATHS.FORGOT
          })
          clearInterval(interval)
        }

        const minutes = Math.floor(time / 60)
        const seconds = (time - minutes * 60).toString().padStart(2, '0')

        setTime(minutes + ':' + seconds)
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [state?.time])

  return (
    <UnauthLayout>
      <UnauthLayout.Container>
        <UnauthLayout.IconContainer icon={<VerifyEmailSuccesSVG/>}/>
        <p className="text-center font-500" style={{ fontSize: '20px' }}>Enviamos um link para o e-mail: <b>{state?.email}</b></p>
        <p className="text-center font-500">Tempo para expiração do link: {time}</p>
        <br/>
        <UnauthLayout.ButtonContainer>
          <UnauthLayout.BackToLogin text=""/>
        </UnauthLayout.ButtonContainer>
      </UnauthLayout.Container>
    </UnauthLayout>
  )
}

export default ResetPasswordSendEmail

import { AxiosRequestConfig } from 'axios'
import { ListECPFModel } from 'ecpf/models/ListECPFModel'
import { IGenericOrigemResource } from 'ecpf/repositories/ResourcesOrigensECPFRepository'
import { ResourceOrigensService } from 'ecpf/services/resourceOrigensService'
import { useCallback, useState } from 'react'

const formatGenericOptions = (results: IGenericOrigemResource[]) => {
  const formatedGenericOptions = results.map((item) => {
    return {
      label: item.descricao,
      value: item.codigo
    }
  })

  return formatedGenericOptions
}

export const useGenericOrigenOptions = (path: string) => {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [options, setOptions] = useState<{ value: string, label: string }[]>([])
  const [page, setPage] = useState<number>(1)
  const [descricao, setDescricao] = useState<string>('')

  const fetchGenericOrigenOptions = useCallback(async (abortController: AbortController, config?: AxiosRequestConfig) => {
    setLoading(true)
    try {
      const resourceOrigensService = new ResourceOrigensService()
      const response = await resourceOrigensService.list(path, config)
      const results = response.data.data?.resultados || []

      const formatedGenericOptions = formatGenericOptions(results)
      setOptions(formatedGenericOptions)

      setErrorMessage('')
      setLoading(false)
    } catch (err) {
      if (!abortController.signal.aborted) {
        if (err.code === 404) {
          setOptions([])
        } else {
          setErrorMessage(err?.message)
          setOptions([])
        }

        setLoading(false)
      }
    }
  }, [])

  const updateDescricao = (value: string) => {
    setDescricao(value)
  }

  return {
    loading,
    options,
    errorMessage,
    fetchGenericOrigenOptions,

    updateDescricao,
    descricao,

    // DEFAULT LIMIT IN THE FUNCAO API
    limit: ListECPFModel.LIMIT,

    page,
    setPage
  }
}

import { _userLevel } from 'ecp/models/UsersModel'
import translate from 'utils/translate'

const UserTableModel = {
  levelsCanSeeUserDetails: ['agx', 'master', 'administrator'],

  canBlockUser: (userLevel: _userLevel) => (userLevel !== 'auditor'),

  showUsersDetails (userLevel: _userLevel) {
    return this.levelsCanSeeUserDetails.includes(userLevel)
  },

  changeUserTranslateLEvel (level: string) {
    if (level === 'commercial' || level === 'consultant') {
      return translate.level(level)
    }

    return translate.areasNames(level)
  },

  canInviteBackofficeOrSupport (inviteBackoffice?: boolean, area?: string) {
    return inviteBackoffice && ['comercial', 'credito', 'engenharia', 'juridico', 'telemarketing'].includes(area || '')
  }
}

export default UserTableModel

import dayjs, { Dayjs } from 'dayjs'
import { timeAsDayjs } from './time'

const defaultSort = (a: any, b: any) => {
  if (!a) a = 0
  if (!b) b = 0

  if (a < b) return -1
  if (b < a) return 1
  return 0
}

const dateSort = (dateA: Dayjs, dateB: Dayjs) => {
  if (dayjs(dateA).isValid() && dayjs(dateB).isValid()) {
    return timeAsDayjs(dateA, { applyTimezone: false }).diff(timeAsDayjs(dateB, { applyTimezone: false }))
  }

  return 0
}

const stringSort = (a: any, b: any) => {
  if (!a) a = 'z'
  if (!b) b = 'z'

  return a.toString().localeCompare(b)
}

const Sorter = {
  DEFAULT: defaultSort,
  STRING: stringSort,
  DATE: dateSort
}

export default Sorter

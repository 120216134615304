import { message } from 'antd'
import { IErrorDetailsECPF, IErrorItemECPF, verifyIsECPFError } from 'components/ErrorDetailsECPF/ErrorDetailsECPF'
import { prettyPrintJson } from 'pretty-print-json'
import swal, { IBasic } from 'utils/swal'
import { copyToClipboard } from 'utils/utils'
import CopyIcon from 'assets/icons/copy.svg'

export type ISwalErrorECPF = Omit<Omit<IBasic, 'text'>, 'title'> & {
  error: IErrorDetailsECPF
  title?: string
}

// Constantes para strings literais
const ERROR_TEXT = '<span class=\'swal-error-text\'>Ops, parece que algo deu errado</span>'
const SWAL_ERROR_TOGGLE = `
  <input id='swal-error-toggle' class='swal-error-checkbox' type='checkbox'/>
  <label for='swal-error-toggle' class='swal-error-label swal-error-label-on'>Ver Detalhes</label>
  <label for='swal-error-toggle' class='swal-error-label swal-error-label-off'>Fechar Detalhes</label>
  <div class='swal-error-wrapper'><pre class="swal-error json-container">{html}</pre></div>
`

const generateSwalErrorItem = ({ index, mensagem, codigo }: { index: number, mensagem: string, codigo: string | null }) => {
  return `
    <div key=${index} class="error-details-ecpf__item">
      <div class='w-100 flex flex-column flex-align-start'>
        <p class='error-details-ecpf__message mb-0'><strong>Mensagem</strong></p>
        <p class='error-details-ecpf__message'>${mensagem || '-'}</p>
      </div>

      <div class='w-100 flex flex-column flex-align-start'>
        <p class='error-details-ecpf__code mb-0'><strong>Código</strong></p>
        <p class='error-details-ecpf__code'>${codigo !== null && codigo !== undefined ? codigo : '-'}</p>
      </div>
    </div>
  `
}

const generateSwalErrorList = (errorsList: string) => {
  return `
    <div class="error-details-ecpf__container">
      ${errorsList}
    </div>
  `
}

const generateErrorsList = (errors: IErrorItemECPF[]) => {
  const errorHTMLList = errors.map((error, index) => {
    const codigo = typeof error.codigo === 'number' ? error.codigo.toString() : error.codigo
    return generateSwalErrorItem({ index, mensagem: error.mensagem, codigo })
  })
  return errorHTMLList.join('')
}

const getText = ({ errorsList, error, areErrorsValid }: { errorsList: string, error: IErrorDetailsECPF, areErrorsValid: boolean }) => {
  if (!error?.data?.erros || error.data.erros.length < 1 || !areErrorsValid) {
    return ERROR_TEXT
  }

  return generateSwalErrorList(errorsList)
}

const swalErrorFooterECPF = (error: IErrorDetailsECPF, areErrorsValid: boolean) => {
  const html = prettyPrintJson.toHtml(error.data || error)

  if (!areErrorsValid) {
    return SWAL_ERROR_TOGGLE.replace('{html}', html)
  }

  return ''
}

const addCopyButton = (error: IErrorDetailsECPF) => {
  const swalEl = document.querySelector('.swal-error-wrapper')
  const copyButton = document.createElement('button')

  copyButton.onclick = () => {
    copyToClipboard(JSON.stringify(error.data || error))
    message.success({ content: 'Mensagem copiada' })
  }
  copyButton.classList.add('swal-error-copy')
  copyButton.innerHTML = `<img src='${CopyIcon}'/>`

  swalEl?.appendChild(copyButton)
}

export function swalErrorECPF ({ title = 'Ocorreu um problema', error, icon = 'warning', ...rest }: ISwalErrorECPF) {
  const areErrorsValid = verifyIsECPFError(error)
  const errorsList = generateErrorsList(error?.data?.erros || [])
  const footer = swalErrorFooterECPF(error, areErrorsValid)

  swal.htmlBasic({
    text: getText({ errorsList, error, areErrorsValid }),
    customClass: {
      footer: 'swal-error-footer'
    },
    icon,
    title,
    footer,
    ...rest
  })

  if (!areErrorsValid) addCopyButton(error)
}

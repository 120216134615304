import React, { Fragment, useLayoutEffect, useState } from 'react'
import { Row, Col, Button, Collapse, Empty, Skeleton, Modal, Tooltip, Select } from 'antd'
import { qualification as qualificationConsumer } from 'egi/consumers'
import swal from 'utils/swal'
import { CheckCircleOutlined, QuestionCircleOutlined, StopOutlined } from '@ant-design/icons'
import { colors } from 'styles/colors'
import { IMinutaMold } from 'egi/types'
import { useSelectedProduct } from 'hooks'
import { useDispatch } from 'react-redux'
import { setResourcesQualifications } from 'store/modules/resources/actions'
import FrameModelForm from './components/FrameModalForm/FrameModelForm'
import PreviewModel from './components/PreviewModal/PreviewModel'
const { Panel } = Collapse

type LoadingProps = {
  loading: boolean,
  failed: boolean,
  children: React.ReactElement
}
function LoadingControl ({ loading, failed, children }: LoadingProps) {
  if (loading) {
    return (
      <div className="proposal-container-card p-3 pt-4">
        <Skeleton/>
      </div>
    )
  }

  if (failed) {
    return <Empty className="proposal-container-card p-3" description="Nenhuma informação encontrada" />
  }

  return <>{children}</>
}

function QualificationStatus ({ item, onClick }: {item: IMinutaMold, onClick: (slug: string, status: boolean) => void}) {
  const isBlocked = item?.blocked

  const handleClick = (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
    event.stopPropagation()
    onClick(item.slug, item.blocked)
  }

  return (
    <Tooltip
      title={`Qualificação ${isBlocked ? 'bloqueada' : 'ativa'}`} placement="left" destroyTooltipOnHide={{ keepParent: false }}>
      <p
        style={{ backgroundColor: isBlocked ? colors.reproved : colors.approved }}
        className="open-areas-card p-1 mb-0 flex flex-align-center flex-justify-center"
        onClick={handleClick}
      >
        {isBlocked ? <StopOutlined /> : <CheckCircleOutlined />}
      </p>
    </Tooltip>
  )
}

export enum _frameTypes {
  comum = 'comum',
  qualificacao = 'qualificacao',
  cnd = 'cnd'
}

const translateFrameType = (type: _frameTypes) => {
  switch (type) {
    case _frameTypes.comum: return 'Comum'
    case _frameTypes.qualificacao: return 'Qualificação'
    case _frameTypes.cnd: return 'Texto de Certidões'
    default: return 'n/a'
  }
}

function MinutaTypeTag ({ type }: { type: _frameTypes }) {
  let background = '#CE3B44'

  if (type === _frameTypes.comum) background = '#3B7FCE'
  else if (type === _frameTypes.qualificacao) background = '#ED731B'

  return (
    <p
      style={{ backgroundColor: background, fontSize: 12 }}
      className="open-areas-card pl-2 pr-2 mr-3 mb-0 flex flex-align-center flex-justify-center "
    >
      {translateFrameType(type)}
    </p>
  )
}

function QuantityPerStatus ({ children, data }: { children: React.ReactNode, data: IMinutaMold[] }) {
  const quantityOfBlocked = data.filter(item => item.blocked).length
  const quantityOfActive = data.filter(item => !item.blocked).length

  return (
    <Tooltip placement='bottomRight' title={(
      <ul className='pl-3'>
        <li>Quantidade de Ativos: {quantityOfActive}</li>
        <li>Quantidade de Bloqueados: {quantityOfBlocked}</li>
      </ul>
    )}>
      {children}
    </Tooltip>
  )
}

function FrameModel () {
  const [minutaMoldInfo, setMinutaMoldInfo] = useState<IMinutaMold[]>([])
  const [status, setStatus] = useState<string | undefined>(undefined)
  const [visible, setVisible] = useState<boolean>(false)
  const [types, setTypes] = useState<{label: string, value: string}[]>([])
  const dispatch = useDispatch()
  const { _id: productId } = useSelectedProduct()
  const TOOLTIP_TEXT = 'Filtre por um tipo de modelo específico'

  async function getMinutaMold (type?: string) {
    setStatus('getMinutaMold')

    try {
      const params = {
        productId,
        type
      }
      const response = await qualificationConsumer.minutaMold(params)
      const qualificacao = response.data.values

      if (qualificacao.length < 1) return setStatus('failed')

      setMinutaMoldInfo(qualificacao)
      dispatch(setResourcesQualifications({ minutaMold: qualificacao }))
      setStatus(undefined)
    } catch (err) {
      setStatus('failed')
      swal.basic({ title: 'Atenção', icon: 'warning', text: err.message })
    }
  }

  async function getMinutaMoldTypes () {
    try {
      const response = await qualificationConsumer.minutaMoldTypes()
      const { minutaMoldTypes } = response?.data || {}
      if (minutaMoldTypes) setTypes([{ label: 'Todos', value: '' }, ...minutaMoldTypes])
    } catch (err) {
      swal.basic({ title: 'Atenção', icon: 'warning', text: err.message })
    }
  }

  const blockQualificacao = async (slug: string, blocked: boolean) => {
    swal.confirmNegate({
      icon: 'question',
      title: 'Atenção',
      text: `Você realmente deseja ${blocked ? 'desbloquear' : 'bloquear'} essa qualificação?`,
      confirm: async () => {
        try {
          const response = await qualificationConsumer.blockQualificacao({ slug: slug, productId: productId })
          getMinutaMold()
          swal.basic({ title: 'Sucesso!', icon: 'success', text: response.message })
        } catch (err) {
          swal.basic({ title: 'Atenção', icon: 'warning', text: err.message })
        }
      }
    })
  }

  const onSaveForm = () => {
    setVisible(false)
    getMinutaMold()
  }

  const onChangeFilterType = (value: string) => {
    getMinutaMold(value)
  }

  useLayoutEffect(() => {
    if (productId) getMinutaMold()
    getMinutaMoldTypes()
  }, [productId])

  return (
    <Fragment>
      <Modal
        destroyOnClose={true}
        footer={null}
        maskClosable={false}
        visible={visible}
        onCancel={() => setVisible(false)}
        width='50vw'
        className="modal-config modal-config-radius"
      >
        <FrameModelForm
          types={types}
          productId={productId}
          onSuccess={onSaveForm}
          onCancel={() => setVisible(false)}
        />
      </Modal>

      <h1 className='auth-layout__header-title mb-0'>
        <QuantityPerStatus data={minutaMoldInfo}>Modelos de Quadros ({minutaMoldInfo.length})</QuantityPerStatus>
      </h1>

      <Row align='bottom' justify='space-between' className='mb-3'>
        <Col className='p-0'>
          <Button type="primary" onClick={() => setVisible(true)}>Criar novo modelo</Button>
        </Col>

        <Col className='p-0'>
          <section className='flex flex-column p-0'>
            <label>
              Tipo do Modelo <Tooltip title={TOOLTIP_TEXT}><QuestionCircleOutlined /></Tooltip>
            </label>

            <Select
              options={types}
              style={{ width: 300 }}
              placeholder='Selecione um tipo'
              onChange={onChangeFilterType}
            />
          </section>
        </Col>
      </Row>

      <LoadingControl
        loading={status === 'getMinutaMold'}
        failed={status === 'failed'}
      >
        <div className="qualification-container">
          <Collapse destroyInactivePanel accordion={true}>
            {minutaMoldInfo && minutaMoldInfo.map((item, idx) => (
              <Panel
                header={item.description}
                key={idx}
                extra={
                  <aside className='flex'>
                    <MinutaTypeTag type={item.type as _frameTypes}/>
                    <QualificationStatus item={item} onClick={blockQualificacao}/>
                  </aside>
                }
              >
                <PreviewModel
                  item={item}
                  productId={productId}
                  idx={idx}
                />
              </Panel>
            ))}
          </Collapse>
        </div>
      </LoadingControl>
    </Fragment>
  )
}

export default FrameModel

import { Invite } from 'egi/app/Signup/hooks/useInvite'

import queryString from 'query-string'

const SignupModel = {
  canSeeOnidataVinculationButton (invite: Invite): boolean {
    const { signupsOnPlataform, level } = invite || {}
    return signupsOnPlataform?.onidata === true && level !== 'administrator'
  },

  canReturnToSignup (invite: Invite): boolean {
    const { signupsOnPlataform } = invite || {}
    return signupsOnPlataform?.line === true
  },

  canChooseCnpjCpfToogle (invite: Invite): boolean {
    const { level, promoterType, inviterLevel } = invite || {}
    return level === 'provider' ||
      (inviterLevel === 'promoter' && promoterType === 'CB') ||
      (level === 'promoter' && promoterType !== 'CB') ||
      (level !== 'administrator' && promoterType === 'TM')
  },

  canSeeSlugCampaignField (invite: Invite): boolean {
    const { level, promoterType } = invite || {}
    return level === 'promoter' && promoterType === 'IN'
  },

  canShowFoundationDateSignup (invite: Invite) {
    const { inviterLevel, promoterType } = invite || {}
    return inviterLevel === 'promoter' && promoterType === 'CB'
  },

  isFuncaoVinculateUser (query: queryString.ParsedQuery<string>) {
    if (query.isEcpf === 'false') return false
    return Boolean(query?.isEcpf)
  }
}

export default SignupModel

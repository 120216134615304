import SignupLayout from 'layouts/SignupLayout/SignupLayout'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { UNAUTHS_PATHS } from 'routes/unauthRoutes'

import SignupButton from 'egi/app/Signup/components/SignupButton/SignupButton'

import { SignupTitle } from 'egi/app/Signup/components/SignupTitle/SignupTitle'

import SuccessSignUpSVG from 'assets/reactSvgs/SuccessSignUpSVG/SuccessSignUpSVG'

function LGPDAccessConfirmSignUp () {
  const history = useHistory()

  return (
    <SignupLayout>

      <SignupTitle className='mb-1'>Falta pouco!</SignupTitle>

      <p className='lgpd-access__info-text'>Enviamos um e-mail com a solicitação de cadastramento para o RH da sua empresa. </p>
      <p className='lgpd-access__info-text'>Aguarde a confirmação de seus dados para que seja liberado seu acesso.</p>

      <div className="lgpd-access__svg">
        <SuccessSignUpSVG />
      </div>

      <SignupButton
        onClick={() => history.push(UNAUTHS_PATHS.MAIN)}
        type='primary'
        className='w-100 unauth-buttons-ghost'
      >
        Voltar a página incial
      </SignupButton>

    </SignupLayout>

  )
}

export default LGPDAccessConfirmSignUp

import dayjs, { Dayjs } from 'dayjs'
import { IMasterFilters } from './MasterInterfaces'

export function formatProductsForRequest (values: string[]): string {
  if (!values) return ''
  return values.toString()
}

function formatDates (filters: IMasterFilters) {
  const dateKeys: (keyof typeof filters)[] = [
    'createdAtStart',
    'createdAtEnd'
  ]

  return dateKeys.reduce((acc, key) => {
    if (filters[key]) {
      acc[key] = dayjs(filters[key] as Dayjs).format('YYYY-MM-DD')
    }
    return acc
  }, {} as { [key: string]: string })
}

export function formatMasterFilters (filters: IMasterFilters) {
  const formattedFilters: filtersMasterRequest = {
    ...filters,
    ...formatDates(filters),
    products: formatProductsForRequest(filters.products)
  }

  return formattedFilters
}

export declare type filtersMasterRequest = Omit<
    IMasterFilters,
    'products'
> & { products: string }

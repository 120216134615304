import { Form, Input, Tag } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import SearchSVGProposal from 'assets/img/SearchSvgProposal/SearchSvgProposal'
import ServerTableFilters from 'components/ServerTable/ServerTableFilters'
import { useTables } from 'hooks'
import React, { ReactNode, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { tablesSetFiltersValues } from 'store/modules/tables/actions'
import format from 'utils/format'
import { validateCpf } from 'utils/validate'
import ProposalsListEgi, { ProposaListEgiProps } from '../ProposalListEgi/ProposalListEgi'

const description = 'Busque todas as suas propostas utilizando o campo de pesquisa '

export const ProposalListSearchWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <div className='proposal-list-search'>
      <div className='proposal-list-search__container'>
        <h3 className='proposal-list-search__title'>Busca geral de propostas</h3>
        <p className='proposal-list-search__description'>{description}</p>
      </div>

      {children}
    </div>
  )
}

export const ProposalListSearchHelpText = () => {
  return (
    <div className="w-100 proposal-list-search__svg-wrapper">
      <div className='proposal-list-search__search-svg-container'>
        <SearchSVGProposal />
      </div>
      <p className='proposal-list-search__description'>
        É possível buscar por <Tag color='blue'>CPF</Tag><Tag color='success'>Nome</Tag><Tag color='orange'>Número</Tag>e muito mais!
      </p>
    </div>
  )
}

export const ProposalListSearchEGI = (props: ProposaListEgiProps) => {
  const dispatch = useDispatch()
  const tables = useTables()
  const [form] = useForm()

  useEffect(() => {
    form.setFieldsValue(tables.filters)
  }, [])

  return (
    <ProposalListSearchWrapper>
      <ServerTableFilters className='proposal-list-search__filters'>
        <Form form={form}>
          <Form.Item name="search">
            <Input.Search
              className="proposal-list-search__input"
              placeholder='Pesquisar'
              onSearch={(value) => {
                const isCpf = validateCpf(value)
                const formatted = isCpf ? format.onlyDigits(value) : value
                dispatch(tablesSetFiltersValues({ ...tables.filters, search: formatted?.trim(), searchUseFilters: false, page: 1 }))
              }}
            />
          </Form.Item>
        </Form>
      </ServerTableFilters>

      {tables.filters.search ? (
        <ProposalsListEgi {...props} hasFilters={false} />
      ) : (
        <ProposalListSearchHelpText />
      )}
    </ProposalListSearchWrapper>
  )
}

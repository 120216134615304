import React, { Fragment, useMemo, useState } from 'react'
import { ChangeUser } from 'components'

import { useAuth, useResources, useTables } from 'hooks'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { IDrawer, ISelect } from 'egi/types'
import { useDispatch } from 'react-redux'
import format from 'utils/format'
import { tablesSetFiltersValues, tablesSetRowValues, tablesUpdate } from 'store/modules/tables/actions'

import { BookOutlined, EditFilled, SolutionOutlined } from '@ant-design/icons'
import { timeAsDayjs } from 'utils/time'
import swal from 'utils/swal'

import { Button, Form, message, Select, Space, Tag, Tooltip } from 'antd'
import { IProposal, ITableUser } from '../../proposalEgiInterfaces'

import ProposalEgiModel from 'egi/models/ProposalEgiModel'
import { LimiterTable } from 'components/LimiterTable/LimiterTable'
import ServerTable from 'components/ServerTable/ServerTable'
import { handleServerTableChangeEgi } from 'components/ServerTable/function'

import ProposalFilters from '../../components/ProposalFilters/ProposalFilter'
import { Link } from 'react-router-dom'
import { setNeedVinculation } from 'store/modules/proposal/actions'
import { _proposalStatuses } from 'egi/app/ProposalEgi/proposalEgiInterfaces'
import translate from 'utils/translate'
import { analysisColors, varColors } from 'styles/colors'
import { ISLA } from 'egi/app/Settings/views/SLAConfig/slaInterfaces'
import dayjs, { Dayjs } from 'dayjs'
import Swal from 'sweetalert2'
import { slaColors } from 'utils/globals'
import { ICommercial, IntegrationTooltip } from 'egi/app/ProposalEgi/views/ProposalListEgi/dataTable'
import { proposals } from 'egi/consumers'
import { modalFillValues } from 'store/modules/modal/actions'
import ContactToClient from 'components/ContactToClient/ContactToClient'
import { IReduxModal } from 'store/modules/modal/types'
import { drawerFillForms } from 'store/modules/drawer/actions'
import Modal from 'antd/lib/modal/Modal'
import { OverflowTooltip } from 'components/OverflowToolTip/OverflowTooltip'
import commonColumns from 'components/UserTable/utils/commonColumns'
import PromotersRepository from 'egi/repositories/PromotersRepository'
import ProposalEgiRepository, { IProposalPromoterForm } from 'egi/repositories/ProposalEgiRepository'
import { ReativateProposalModal } from '../../components/ReativateProposalModal/ReativateProposalModal'
import { IBackoffice, ShowAllVinculatedBackOffices } from '../../components/ShowAllVinculatedBackoffices/ShowAllVinculatedBackOffices'

export type ProposaListEgiProps = {
  mlm?: boolean,
  reminded?: boolean,
  needVinculation?: boolean
  hasAction?: boolean | string
  hasFilters?: boolean
}

function ProposalsListEgi ({ mlm, reminded, needVinculation, hasAction, hasFilters = true }: ProposaListEgiProps) {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false)
  const [proposalId, setProposalId] = useState<string>()
  const [tableUser, setTableUser] = useState<ITableUser>()
  const [userlevel, setUserLevel] = useState<string>('')
  const [visibleModalCommercial, setVisibleModalCommercial] = useState<boolean>(false)
  const [commercialInfo, setCommercialInfo] = useState<ICommercial>()
  const [visibleModalPromoter, setVisibleModalPromoter] = useState<boolean>(false)
  const [proposalPromoterInfo, setProposalPromoterInfo] = useState<{ selectedUser?: string, proposalCode?: number }>({})
  const [proposalPromoterLoading, setProposalPromoterLoading] = useState(false)
  const [subPromotersList, setSubPromotersList] = useState<Array<ISelect>>([])
  const [proposalPromoterSubmitLoading, setProposalPromoterSubmitLoading] = useState(false)

  const user = useAuth()
  const abortController = new AbortController()
  const dispatch = useDispatch()
  const tables = useTables()
  const canShowDepartmentFilter = ProposalEgiModel.canSeeDepartmentFilter(user.level, user.areaName)

  const notInternal = user.level === 'seller' || !!(mlm)

  type ICurrentStep = {
    areaId: string
    steps: Array<string>
  }

  function showModalChangeUser ({ user, level, proposalId }: { user: ITableUser, level: string, proposalId: string }) {
    setShowModal(true)
    setTableUser(user)
    setUserLevel(level)
    setProposalId(proposalId)
  }

  function showStatusChangeModal (proposalId: string) {
    setShowStatusModal(true)
    setProposalId(proposalId)
  }

  function showModalCommercial (commercial: ICommercial, leadId: string) {
    setVisibleModalCommercial(true)
    setCommercialInfo(commercial)
    setProposalId(leadId)
  }

  const handleClose = () => {
    setShowStatusModal(false)
  }

  const declineProposalDrawerInfo: IDrawer = {
    type: 'refuseProposal',
    visible: true,
    submitButton: true,
    width: '50vw',
    title: 'Cancelar proposta'
  }

  async function fetchAvailablePromoters (userId: string) {
    setProposalPromoterLoading(true)
    try {
      const reqParams = {
        signal: abortController.signal,
        params: { proposalId }
      }

      const response = await PromotersRepository.fetchSubPromoters(userId, reqParams)
      const { promoters } = response.data?.data || {}
      if (!promoters) throw new Error('Não foi possível obter a lista de sub-corbans')

      if (Array.isArray(promoters)) {
        const list: typeof subPromotersList = []
        promoters.map(item => {
          const type = item?.type ? `${item.type} - ` : ''
          const label = type + item.name
          return list.push({ label, value: item.id })
        })

        setSubPromotersList(list)
      }

      return setProposalPromoterLoading(false)
    } catch (err) {
      if (!abortController.signal.aborted) {
        if (err?.message) message.error(err.message)
      }
      setProposalPromoterLoading(false)
    }
  }

  const handleChangePromoterModal = (proposalId?: string, proposalCode?: number, selectedUser?: string) => {
    setProposalId(proposalId ?? '')
    fetchAvailablePromoters(user._id)
    setProposalPromoterInfo({ selectedUser, proposalCode })
    setVisibleModalPromoter(true)
  }

  function handlePromoterModalCancel () {
    return setVisibleModalPromoter(false)
  }

  async function handlePromoterChange (values: IProposalPromoterForm) {
    setProposalPromoterSubmitLoading(true)
    try {
      const response = await ProposalEgiRepository.changeProposalPromoter(proposalId || '', values, { signal: abortController.signal })

      message.success(response.data.message)
      handlePromoterModalCancel()
      setProposalPromoterSubmitLoading(false)
      return dispatch(tablesUpdate())
    } catch (err) {
      if (!abortController.signal.aborted) {
        if (err?.message) message.error(err.message)
      }
      setProposalPromoterSubmitLoading(false)
    }
  }

  const columns: ColumnsType<IProposal> = useMemo(() => [
    {
      title: 'ID',
      dataIndex: 'number',
      key: 'number',
      sorter: true,
      width: 75,
      render: function renderItem (text, row) {
        if (notInternal) return text
        const tables = useTables()
        const dispatch = useDispatch()

        function checkNeedVinculation () {
          if (tables.filters.needVinculation) {
            return dispatch(setNeedVinculation(true))
          }

          dispatch(setNeedVinculation(Boolean(needVinculation)))
        }

        return (
          <Link
            className="link-style color-tertiary font-500"
            onClick={() => checkNeedVinculation()}
            to={`/auth/proposals/show/${(row)._id}`}
          >
            {text}
          </Link>
        )
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      width: 150,
      align: 'center',
      ellipsis: true,
      render: (text: string, row: Object) => {
        const dispatch = useDispatch()
        const textProposal = text as _proposalStatuses
        const translatedColor = translate.reverseProposalStatus(text)
        const typedRow = row as { status: _proposalStatuses, _id: string }

        const validStatusCanceled = ProposalEgiModel.canCancel(typedRow.status)
        const canSetAsActiveStatus = ProposalEgiModel.canRefuse(user.level) && typedRow.status === 'inactive'
        const canEditStatus = ProposalEgiModel.canRefuse(user.level) && validStatusCanceled

        let finalClass = 'text-center w-100 capitalize'
        if (ProposalEgiModel.canRefuse(user.level)) finalClass += 'pointer'

        return (
          <Tag
            className={finalClass}
            color={varColors[translatedColor]}
            onClick={() => {
              if (canEditStatus && !canSetAsActiveStatus) {
                dispatch(tablesSetRowValues({ row }))
                if (declineProposalDrawerInfo) dispatch(drawerFillForms(declineProposalDrawerInfo))
              }

              if (canSetAsActiveStatus) {
                if (showStatusChangeModal) showStatusChangeModal(typedRow._id)
              }
            }}
          >
            {canEditStatus && <EditFilled className="mx-1" />}
            {translate.proposalStatus(textProposal)}
          </Tag>
        )
      }
    },
    {
      title: 'Expiração',
      dataIndex: 'proposalExpiration',
      key: 'proposalExpiration',
      width: 100,
      sorter: true,
      render: (proposalExpiration) => {
        if (!proposalExpiration.date) return '-'
        return <span className="table-name">{timeAsDayjs(proposalExpiration.date).format('DD/MM/YYYY')}</span>
      }
    },
    {
      title: 'Pessoa',
      dataIndex: 'personType',
      sorter: true,
      width: 80,
      ellipsis: true,
      render: (personType: string) => {
        return translate.personType(personType)
      }
    },
    ...(ProposalEgiModel.canSeeSLAColumns(user.level) ? [{
      title: 'SLA',
      dataIndex: 'SLA',
      key: 'SLA',
      width: 50,
      ellipsis: true,
      align: 'center',
      render: (SLA: { SLA?: ISLA, areaId: string }) => {
        if (!SLA || !SLA.SLA) return <h4 className="text-center">-</h4>
        const resources = useResources()
        const areas = [SLA.areaId]
        const finalAreas: any = []
        const diffTime = (timeAsDayjs(dayjs(SLA.SLA?.max)).diff(timeAsDayjs(dayjs()), 'days'))
        const diffTimeEnd = (timeAsDayjs(dayjs(SLA.SLA?.max)).diff(timeAsDayjs(dayjs(SLA.SLA?.end)), 'days'))

        if (resources.areas && SLA.areaId) {
          areas.forEach((item) => {
            const areas = resources.areas.filter(element => element.value === item)
            finalAreas.push(...areas)
          })
          const onlyText = (finalAreas.map((item: any) => [format.capitalize(item.label)]))

          if (SLA.SLA?.end) {
            return (
              <Button
                type="link"
                onClick={() => {
                  Swal.fire({
                    title: 'SLA - Finalizado',
                    html: `
                    <b>Área de atuação</b>: ${(format.separeteValuesByComma(onlyText) as string)}
                    <br/><br/>
                    <b>Data de fim</b>: ${timeAsDayjs(SLA.SLA?.end).format('DD-MM-YYYY')}
                    <br/><br/>
                    <b>Data máxima</b>: ${timeAsDayjs(SLA.SLA?.max).format('DD-MM-YYYY')}
                    `
                  })
                }}
              >
                <div className="crivo-status-circle mr-1" style={{ backgroundColor: slaColors(diffTimeEnd) }} />
              </Button>
            )
          }

          return (
            <Button
              type="link"
              onClick={() => {
                Swal.fire({
                  title: 'SLA - Em andamento',
                  html: `
                  <b>Área de atuação</b>: ${(format.separeteValuesByComma(onlyText) as string)}
                  <br/><br/>
                  <b>Data de início</b>: ${timeAsDayjs(SLA.SLA?.actionAt).format('DD-MM-YYYY')}
                  <br/><br/>
                  <b>Data máxima</b>: ${timeAsDayjs(SLA.SLA?.max).format('DD-MM-YYYY')}
                  <br/><br/>
                  <b>Dias restantes</b>: ${diffTime}
                  `
                })
              }}
            >
              <div className="crivo-status-circle" style={{ backgroundColor: slaColors(diffTime) }} />
            </Button>
          )
        }
      }
    }] : []),
    {
      title: 'Venc. SLA',
      dataIndex: ['SLA', 'SLA', 'max'],
      key: 'sla-max',
      sorter: true,
      width: 125,
      ellipsis: true,
      align: 'center',
      render: (SLA: string) => {
        if (!SLA) return '-'
        return <span className="table-name">{timeAsDayjs(SLA).format('DD/MM/YYYY')}</span>
      }
    },
    {
      title: 'Cliente',
      dataIndex: 'name',
      key: 'name',
      width: 125,
      ellipsis: true,
      render: (name: string, row) => {
        if (!name) return '-'
        const { cpf } = (row as IProposal) || {}
        return (
          <>
            <p>
              <OverflowTooltip
                width={115}
                title={name}
              >
                {format.formatName(name)}
              </OverflowTooltip>
            </p>
            <p>
              <OverflowTooltip
                width={115}
                title={cpf}
              >
                {cpf}
              </OverflowTooltip>
            </p>
          </>
        )
      }
    }, {
      title: 'Indicador/API',
      dataIndex: 'indicator',
      width: 150,
      render: (item, row: object) => {
        const proposal = row as IProposal
        const type = proposal?.indicator?.type ? `${proposal.indicator?.type} - ` : ''
        if (!item?.name) return '-'

        return (
          <span className="table-name">
            <Space className='flex flex-justify-between'>
              <Tooltip
                title={type + item?.name}
                destroyTooltipOnHide={{ keepParent: false }}
              >
                <span style={{ maxWidth: 115 }} className="overflow-ellipsis">{type + item?.name}</span>
              </Tooltip>

              {proposal?.isIntegration && <IntegrationTooltip title="Proposta gerada por integração" />}
            </Space>
          </span>
        )
      }
    },
    ...(ProposalEgiModel.canSeeUsersLinkedProposal(user.level, mlm ?? false) ? [
      {
        title: 'Parceiro',
        dataIndex: 'promoter',
        key: 'producer',
        ellipsis: false,
        width: 145,
        render: (promoter: { name: string, type: string, code: number, _id: string }, row: any) => {
          if (!promoter?.name) return '-'
          const proposal = row as IProposal
          const proposalId = proposal._id

          const promoterName = `${promoter?.type || '-'} - ${promoter?.name || '-'}`

          if (promoter.type === 'TM' && ProposalEgiModel.canEditTM(user.level, user.areaName)) {
            <Tooltip title={promoterName} destroyTooltipOnHide={{ keepParent: false }}>
              <p
                className="link-style color-tertiary font-500 mb-0 table-name"
                onClick={() => showModalChangeUser({ level: 'telemarketing', user: promoter, proposalId })}
              >
                {promoter.type} - {promoter.name}
              </p>
            </Tooltip>
            return
          }

          return (
            <p className='flex'>
              <Tooltip title={promoterName} destroyTooltipOnHide={{ keepParent: false }}>
                <span className="table-name overflow-ellipsis">{promoterName}</span>
              </Tooltip>
              {ProposalEgiModel.canChangeProposalPromoter(user) && (
                <EditFilled className='ml-1' onClick={() => handleChangePromoterModal(proposalId, proposal.number, proposal.promoterId)} />
              )}
            </p>
          )
        }
      },
      ...(ProposalEgiModel.canSeeReminderTelemarketing(user.level, user.areaName, reminded ?? false, user.type) ? [
        {
          title: 'Tombada',
          dataIndex: 'reminderTelemarketingName',
          sorter: false,
          width: 150,
          ellipsis: true,
          render: (tm: string) => {
            if (!tm) return '-'
            return (
              <OverflowTooltip width={130}>
                {tm}
              </OverflowTooltip>
            )
          }
        }
      ] : []),
      {
        title: 'Ger. de negócios',
        dataIndex: 'promoterManager',
        key: 'promoterManager',
        sorter: true,
        width: 150,
        ellipsis: true,
        render: (promoterManager: { name: string }) => {
          if (!promoterManager?.name) return '-'
          return (
            <OverflowTooltip title={promoterManager?.name} width={150}>
              <p>
                {promoterManager?.name ?? '-'}
              </p>
            </OverflowTooltip>
          )
        }
      },
      {
        title: 'Suporte',
        dataIndex: 'support',
        key: 'support',
        sorter: true,
        width: 125,
        ellipsis: true,
        render: (support: { id: string, name: string }, row: IProposal) => {
          if (!support?.name) return '-'
          const proposalId = row._id

          if (ProposalEgiModel.canEditCommercial(user.level, user.areaName)) {
            return (
              <OverflowTooltip width={100}>
                <span
                  className="link-style color-tertiary font-500 mb-0 table-name"
                  onClick={() => showModalChangeUser({ level: 'commercial', user: support, proposalId })}
                >
                  {support.name}
                </span>
              </OverflowTooltip>
            )
          }

          return (
            <OverflowTooltip width={100}>
              <span className="table-name">{support?.name}</span>
            </OverflowTooltip>
          )
        }
      }
    ] : []),

    {
      title: 'Valor',
      dataIndex: 'value',
      key: 'value',
      width: 150,
      ellipsis: true,
      sorter: true
    },
    ...(ProposalEgiModel.canSeeProviderColumns(user.level, user.areaName) ? [

      ...(!ProposalEgiModel.canSeeOnlyProviderDate(user.level, user.areaName) ? [
        {
          title: 'Prestador',
          dataIndex: 'provider',
          key: 'provider',
          sorter: true,
          width: 125,
          ellipsis: true,
          render: (provider: { id: string, name: string }) => {
            if (!provider) return '-'
            return <span className="table-name">{provider?.name || '-'}</span>
          }
        }
      ] : []),
      {
        title: 'Vistoria',
        dataIndex: 'vistoria',
        key: 'vistoria',
        width: 100,
        ellipsis: true,
        sorter: true,
        render: (vistoria: Dayjs) => {
          if (!vistoria) return '-'
          return dayjs(vistoria).isValid() ? timeAsDayjs(vistoria).format('DD/MM/YYYY') : '-'
        }
      }

    ] : []),
    { ...commonColumns.createdAt },
    {
      title: 'Atualização',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 150,
      align: 'center',
      ellipsis: true,
      sorter: true,
      render: (updatedAt) => {
        return <span className="table-name">
          {updatedAt}
        </span>
      }
    },
    ...(ProposalEgiModel.canSeeAnalisys(user.areaName) ? [
      {
        title: 'Análise',
        dataIndex: 'reviewAnalysis',
        key: 'reviewAnalysis',
        width: 150,
        align: 'center',
        ellipsis: true,
        sorter: true,
        render: (reviewAnalysis: string) => {
          if (!reviewAnalysis || reviewAnalysis === '-') return '-'
          return (
            <Tag color={analysisColors[reviewAnalysis]}>
              {translate.proposalAnalysis(reviewAnalysis)}
            </Tag>
          )
        }
      }
    ] : []),
    ...(ProposalEgiModel.canSeeCommercialAttendant(user.level, user.areaName) ? [
      {
        title: 'Atendente comercial',
        width: '200px',
        dataIndex: 'reminderCommercial',
        render: (item: ICommercial | string, row: IProposal) => {
          if (!item || item === '-') return '-'

          if (ProposalEgiModel.canEditCommercial(user.level, user.areaName)) {
            return <p className="link-style color-tertiary font-500 capitalize"
              onClick={() => showModalCommercial &&
                showModalCommercial(item as ICommercial, (row)._id)}>
              {row?.reminderCommercial?.name} &rarr;
            </p>
          }

          return row?.commercial?.name
        }
      }] : []),
    ...(ProposalEgiModel.canSeeActionColumn(user.level) ? [
      {
        title: 'Ações atuais',
        dataIndex: 'currentAction',
        key: 'currentAction',
        width: 150,
        ellipsis: true,
        render: (currentStep: string) => {
          const moreValues = currentStep.split(',')
          const formattedValues: string[] = []

          if (!currentStep) return 'Nenhuma Ação'
          if (moreValues.length === 1) return translate.areasNames(currentStep)

          moreValues.forEach((item) => {
            return formattedValues.push(translate.areasNames(item.trim()))
          })

          return (
            <p
              className="link-style color-tertiary font-500 mb-0 capitalize"
              onClick={() => {
                swal.basic({
                  title: 'Ações atuais',
                  text: `${formattedValues.toString().replace(/,/g, ', ')}`
                })
              }}
            >
              Ver Mais
            </p>
          )
        }
      }
    ] : []),
    {
      title: 'Último depart.',
      dataIndex: 'lastArea',
      key: 'lastArea',
      width: 150,
      ellipsis: true,
      render: (lastArea) => {
        if (!lastArea) return '-'
        return translate.lastArea(lastArea)
      }
    },
    ...(ProposalEgiModel.canSeeClosedReminded(user.areaName, reminded ?? false, user.type) ? [
      {
        title: 'Atendimento',
        dataIndex: 'reminderTelemarketingClosed',
        key: 'reminderTelemarketingClosed',
        sorter: true,
        width: 200,
        ellipsis: true,
        render: (reminderTelemarketingClosed: boolean, row: IProposal) => {
          const { neededReminderTelemarketing, _id } = row
          const dispatch = useDispatch()

          if (neededReminderTelemarketing && reminderTelemarketingClosed) return <Tag color="green">Atendimento finalizado</Tag>

          return (
            <Tag
              className="pointer"
              color="blue"
              onClick={() => {
                swal.confirmNegate({
                  icon: 'question',
                  title: 'Atendimento',
                  text: 'Você deseja finalizar o atendimento dessa proposta ?',
                  confirm: async () => {
                    try {
                      const response = await proposals.closeReminded({ proposalId: _id })
                      dispatch(tablesUpdate())
                      swal.basic({
                        icon: 'success',
                        title: 'Sucesso!',
                        text: response.message
                      })
                    } catch (err) {
                      swal.basic({
                        title: 'Atenção',
                        text: err.message,
                        icon: 'warning'
                      })
                    }
                  }
                })
              }}
            >
              Finalizar atendimento ?
            </Tag>
          )
        }
      }
    ] : []),
    ...(ProposalEgiModel.canSeeCurrencystep(user.areaName) ? [
      {
        title: 'Pendência',
        dataIndex: 'currentStep',
        width: 100,
        ellipsis: true,
        render: (areas: Array<ICurrentStep> | string) => {
          if (!areas) return '-'
          if ((areas && areas.length < 1) || areas === '-') return '-'

          const finalSteps = (areas as Array<ICurrentStep>).map(item => item.steps).flat()
          if (finalSteps.length < 1) return '-'
          if (finalSteps.length === 1) return finalSteps

          return (
            <p
              className="link-style color-tertiary font-500 mb-0"
              onClick={() => {
                swal.basic({
                  title: 'Pendência',
                  text: (format.separeteValuesByComma(finalSteps) as string)
                })
              }}
            >
              VER MAIS
            </p>
          )
        }
      }
    ] : []),
    ...(ProposalEgiModel.canSeeLinkedBackofficeColumn(user.level, user.type) ? [{
      title: 'Backoffices Vinculados',
      key: 'backoffices',
      width: 200,
      dataIndex: 'allBackoffices',
      ellipsis: true,
      render: (backoffices: IBackoffice[]) => {
        if (!backoffices) return <>-</>
        return (
          <ShowAllVinculatedBackOffices backoffices={backoffices}/>
        )
      }
    }
    ] : []),

    ...(ProposalEgiModel.canSeeActionsAndShare(user.level, mlm ?? false) ? [
      {
        title: '',
        key: 'actions',
        width: 200,
        ellipsis: true,
        render: (_: any, row: IProposal) => {
          const user = useAuth()
          const dispatch = useDispatch()
          const { email, cellphone, product, name, cpf, _id, backoffices, number } = (row as IProposal)
          const BOTH_ITENS = ['auditor', 'administrator', 'commercial', 'master'].includes(user.level)
          const canChangeBackoffices = BOTH_ITENS && backoffices
          const canViewProposalHistory = BOTH_ITENS || (user.level === 'backoffice' && user.areaName === 'credito')

          const data: IReduxModal = {
            visible: true,
            title: 'Vincular backoffices',
            type: 'linkedBackoffices',
            hasFooter: false,
            width: '50',
            proposalId: _id
          }

          return (
            <Fragment>
              <div className="flex">
                {canChangeBackoffices && (
                  <Tooltip title="Vincular backoffices" destroyTooltipOnHide={{ keepParent: false }}>
                    <a className="color-black" onClick={() => dispatch(modalFillValues(data))}>
                      <SolutionOutlined className="mr-2" style={{ fontSize: '20px' }} />
                    </a>
                  </Tooltip>
                )}

                <Fragment>
                  {canViewProposalHistory && (
                    <Tooltip title="Histórico" destroyTooltipOnHide={{ keepParent: false }}>
                      <Link to={`/auth/proposals/history/${_id}`} className="color-black">
                        <BookOutlined className="mr-2" style={{ fontSize: 20 }} />
                      </Link>
                    </Tooltip>
                  )}

                  <ContactToClient
                    contactType="contact"
                    cpf={cpf}
                    canCall={true}
                    emailClient={{ destination: email, type: 'proposta' }}
                    numberClient={cellphone}
                    productNameClient={product}
                    nameClient={name}
                    idProposal={_id}
                    proposalNumber={String(number)}
                  />
                </Fragment>
              </div>
            </Fragment>
          )
        }
      }
    ] : [])
  ] as ColumnsType<IProposal>, [])

  return (
    <Fragment>
      <ChangeUser
        level={userlevel}
        setShow={setShowModal}
        show={showModal}
        id={proposalId}
        user={tableUser?.name}
      />

      <ChangeUser
        show={visibleModalCommercial}
        setShow={setVisibleModalCommercial}
        level="commercial"
        showUseSelf={true}
        isCommercialAttendant={true}
        id={proposalId}
        reminded={reminded}
        user={commercialInfo?._id}
      />

      <>
        <LimiterTable>
          <ReativateProposalModal
            showStatusModal={showStatusModal}
            handleClose={handleClose}
            proposalId={proposalId ?? ''}
          />

          <Modal
            visible={visibleModalPromoter}
            destroyOnClose
            onCancel={handlePromoterModalCancel}
            footer={null}
          >
            <h4 className='mb-2'>Selecione o novo colaborador responsável pela proposta <strong>{proposalPromoterInfo.proposalCode}</strong></h4>
            <Form layout='vertical' onFinish={handlePromoterChange}>
              <Form.Item label='Parceiro' name='userId' initialValue={proposalPromoterInfo.selectedUser}>
                <Select options={subPromotersList} loading={proposalPromoterLoading} value={proposalPromoterInfo.selectedUser} />
              </Form.Item>

              <div className='flex flex-row flex-justify-end'>
                <Button onClick={handlePromoterModalCancel} className='mr-2' type='ghost'>
                  Cancelar
                </Button>
                <Button type='primary' htmlType='submit' loading={proposalPromoterSubmitLoading}>
                  Salvar
                </Button>
              </div>
            </Form>
          </Modal>

          {hasFilters &&
            <ProposalFilters
              reminded={reminded}
              hasAction={hasAction ?? ''}
              needVinculation={needVinculation ?? ''}
            />
          }

          <div className="proposal-egi">
            <ServerTable
              url='/proposals'
              columns={columns}
              fixed={false}
              customParams={{ mlm, reminded, needVinculation }}
              rowKey='_id'
              customClass={canShowDepartmentFilter ? 'server-table--deparment-row' : ''}
              onChange={(
                pagination: TablePaginationConfig,
                filters: Record<string, (string | number | boolean)[] | null>,
                sorter
              ) => {
                const serverTableFiltersData = handleServerTableChangeEgi(
                  columns,
                  tables.filters
                )(pagination, filters, sorter)

                dispatch(
                  tablesSetFiltersValues({
                    ...serverTableFiltersData
                  })
                )
              }}
            />
          </div>
        </LimiterTable>
      </>
    </Fragment>
  )
}

export default ProposalsListEgi

import { AxiosRequestConfig } from 'axios'
import { Repository } from 'ecp/repositories/Repository'
import { IPricing } from 'egi/types'
import api from 'services/api'

class GlobalsRepository extends Repository<{}> {
  pricings (config: AxiosRequestConfig) {
    return Repository.handle<{ pricing: IPricing[] }>(() =>
      this.api.get(`${this.path}/pricing`, config)
    )
  }

  createPricing (body: IPricing, config?: AxiosRequestConfig) {
    return Repository.handle<{ pricing: Object[] }>(() =>
      this.api.post(`${this.path}/pricing`, body, config)
    )
  }

  fetchOnePricing (pricingId: string, config?: AxiosRequestConfig) {
    return Repository.handle<{ pricing: IPricing }>(() =>
      this.api.get(`${this.path}/pricing/${pricingId}`, config)
    )
  }

  deactivatePricing (pricingId: string, config?: AxiosRequestConfig) {
    return Repository.handle<{ pricing: Object[] }>(() =>
      this.api.patch(`${this.path}/pricing/${pricingId}`, null, config)
    )
  }

  updatePricingCommission (pricingId: string, body: IPricing, config?: AxiosRequestConfig) {
    return Repository.handle<{ pricing: Object[] }>(() =>
      this.api.patch(`${this.path}/pricing/${pricingId}/commission`, body, config)
    )
  }

  getProduct (pricingId: string, config?: AxiosRequestConfig) {
    return Repository.handle<{ pricing: IPricing }>(() =>
      this.api.get(`${this.path}/product/${pricingId}`, config)
    )
  }
}
export default new GlobalsRepository({ api, path: '/globals' })

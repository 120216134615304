import { AxiosRequestConfig } from 'axios'
import SigninECPFRepository, { IECPFForgotPasswordBody } from 'ecpf/repositories/SigninECPFRepository'

export class SigninService {
  async typist (typistIdentifier: string, config?: AxiosRequestConfig) {
    const response = await SigninECPFRepository.typist(typistIdentifier, config)
    const digitador = response.data.data?.digitador

    if (!digitador) throw new Error('Corban/Operador não encontrado')

    return digitador
  }

  async checkTypist (body: { identifier: string }, config?: AxiosRequestConfig) {
    return SigninECPFRepository.checkTypist(body, config)
  }

  async forgotPassword (body: IECPFForgotPasswordBody, config?: AxiosRequestConfig) {
    return SigninECPFRepository.forgotPassword(body, config)
  }
}

import { TeamOutlined, LoadingOutlined, UserOutlined } from '@ant-design/icons'
import { ModalProps } from 'antd/lib/modal'
import { ModalSelect } from 'egi/app/Signin/components/SelectLevel/SelectLevel'
import React, { ReactNode } from 'react'

export interface ISigninB2CModalECPF {
  onSelect: (flowOption: SinginB2CFlowOptionsEnum) => void
  onClose: () => void
  loadingClient: boolean
}

export enum SinginB2CFlowOptionsEnum {
  client = 'client',
  corban = 'corban'
}

const SigninB2CLevelSelectCard = ({
  onClick,
  loading,
  title,
  description,
  icon
}: {
  onClick: () => void
  loading: boolean
  title: string
  description: string
  icon: ReactNode
}) => {
  return (
    <div
      className='signin-b2c-modal-ecpf-card'
      role='button'
      onClick={onClick}
    >
      <div className='signin-b2c-modal-ecpf-card__icon'>
        {loading ? <LoadingOutlined /> : icon}
      </div>

      <h3 className='signin-b2c-modal-ecpf-card__title'>{title}</h3>
      <p className='signin-b2c-modal-ecpf-card__description'>{description}</p>
    </div>
  )
}

const SigninB2CModalECPF = ({ onSelect, onClose, loadingClient, ...rest }: ISigninB2CModalECPF & Omit<ModalProps, 'title'>) => {
  return (
    <>
      <ModalSelect
        title='Selecione o tipo de acesso'
        description='Selecione com qual perfil de usuário você se identifica.'
        onClose={onClose}
        visible={rest.visible ?? false}
        {...rest}
      >
        <div className='signin-b2c-modal-ecpf__content'>
          <SigninB2CLevelSelectCard
            loading={loadingClient}
            title='Sou cliente'
            description='Vou acompanhar ou cadastrar minhas propostas'
            onClick={() => onSelect(SinginB2CFlowOptionsEnum.client)}
            icon={<UserOutlined />}
          />

          <SigninB2CLevelSelectCard
            loading={false}
            title='Sou parceiro'
            description='Vou cadastrar e acompanhar as propostas dos meus clientes'
            onClick={() => onSelect(SinginB2CFlowOptionsEnum.corban)}
            icon={<TeamOutlined />}
          />
        </div>
      </ModalSelect>
    </>
  )
}

export default SigninB2CModalECPF

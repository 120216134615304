import React from 'react'

export default function WhiteListSvg () {
  return (
    <svg width="91" height="81" viewBox="0 0 91 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M86.2271 54.3788C86.5526 56.6989 85.0149 58.8325 82.7924 59.1443C80.5699 59.4562 78.5043 57.8283 78.1788 55.5082C78.1714 55.4556 78.1649 55.4033 78.1597 55.3508C77.875 53.0255 79.451 50.9227 81.6792 50.6542C83.9074 50.3857 85.9438 52.0532 86.2277 54.3787L86.2271 54.3788Z" fill="#00CF7B"/>
      <path d="M18.5791 71.3301C18.3257 71.3661 18.097 71.5012 17.9431 71.7057C17.7892 71.9102 17.7227 72.1674 17.7583 72.4209C17.7939 72.6743 17.9285 72.9033 18.1328 73.0576C18.337 73.2119 18.5941 73.2788 18.8476 73.2437L88.7054 63.4406C88.9588 63.4045 89.1876 63.2694 89.3415 63.0649C89.4953 62.8604 89.5618 62.6032 89.5262 62.3497C89.4907 62.0963 89.356 61.8673 89.1517 61.713C88.9475 61.5588 88.6904 61.4918 88.4369 61.5269L18.5791 71.3301Z" fill="#CCCCCC"/>
      <path d="M19.3846 77.0708C19.1311 77.1062 18.9019 77.2408 18.7475 77.445C18.5931 77.6492 18.5261 77.9064 18.5611 78.16C18.5962 78.4136 18.7304 78.6429 18.9344 78.7976C19.1384 78.9523 19.3955 79.0197 19.6491 78.985L63.8139 72.7873C64.0677 72.7517 64.2969 72.6168 64.4511 72.4122C64.6054 72.2075 64.6721 71.95 64.6365 71.6963C64.6008 71.4425 64.4659 71.2133 64.2613 71.059C64.0566 70.9048 63.7991 70.8381 63.5454 70.8737L19.3846 77.0708Z" fill="#CCCCCC"/>
      <path d="M81.0475 57.008L79.6716 55.6252L79.9171 55.3807L81.0381 56.5072L84.3289 52.9443L84.5835 53.1794L81.0475 57.008Z" fill="white"/>
      <path d="M28.9166 67.2236C28.8897 67.29 28.8458 67.3482 28.7893 67.3923C28.7329 67.4364 28.6658 67.4648 28.5948 67.4748L18.8948 68.836C18.8239 68.8459 18.7517 68.8369 18.6853 68.81C18.6189 68.7831 18.5608 68.7392 18.5168 68.6828C18.4727 68.6263 18.4443 68.5593 18.4344 68.4884C18.4244 68.4175 18.4333 68.3452 18.4602 68.2788C19.0429 66.8406 20.0948 65.7204 21.4151 65.074C20.6735 64.7626 20.0545 64.2164 19.6534 63.5191C19.2523 62.8219 19.0911 62.0123 19.1946 61.2146C19.2981 60.4169 19.6606 59.6753 20.2265 59.1036C20.7923 58.5318 21.5302 58.1617 22.3267 58.0499C23.1233 57.9381 23.9345 58.0909 24.6359 58.4848C25.3372 58.8787 25.8899 59.4919 26.2089 60.2303C26.528 60.9687 26.5959 61.7914 26.4022 62.5721C26.2084 63.3528 25.7637 64.0483 25.1365 64.5518C26.5837 64.8098 27.9032 65.5972 28.8593 66.8195C28.9035 66.8759 28.932 66.9429 28.9421 67.0139C28.9521 67.0848 28.9433 67.1571 28.9166 67.2236Z" fill="#00441F"/>
      <path d="M11.7594 22.7323C11.506 22.7684 11.2773 22.9035 11.1234 23.108C10.9695 23.3125 10.903 23.5697 10.9386 23.8232C10.9742 24.0766 11.1088 24.3056 11.3131 24.4599C11.5173 24.6141 11.7744 24.6811 12.0279 24.6459L81.8857 14.8428C82.1391 14.8068 82.3679 14.6717 82.5217 14.4672C82.6756 14.2627 82.7421 14.0055 82.7065 13.752C82.671 13.4985 82.5363 13.2696 82.332 13.1153C82.1278 12.961 81.8707 12.8941 81.6172 12.9292L11.7594 22.7323Z" fill="#CCCCCC"/>
      <path d="M12.5649 28.4732C12.3113 28.5086 12.0822 28.6432 11.9278 28.8474C11.7734 29.0516 11.7064 29.3087 11.7414 29.5623C11.7765 29.8159 11.9107 30.0453 12.1147 30.1999C12.3187 30.3546 12.5758 30.422 12.8294 30.3873L56.9942 24.1897C57.1199 24.1721 57.2408 24.1299 57.3501 24.0655C57.4595 24.0011 57.5551 23.9159 57.6314 23.8145C57.7078 23.7132 57.7635 23.5979 57.7953 23.475C57.8271 23.3522 57.8344 23.2243 57.8167 23.0987C57.7991 22.973 57.7569 22.8521 57.6925 22.7427C57.6282 22.6334 57.5429 22.5378 57.4416 22.4614C57.3402 22.385 57.2249 22.3294 57.102 22.2976C56.9792 22.2658 56.8513 22.2585 56.7257 22.2761L12.5649 28.4732Z" fill="#CCCCCC"/>
      <path d="M79.7152 5.73792C80.0408 8.05798 78.5031 10.1916 76.2806 10.5035C74.058 10.8154 71.9925 9.1874 71.6669 6.86733C71.6595 6.81474 71.653 6.7624 71.6479 6.70995C71.3631 4.38461 72.9392 2.28185 75.1674 2.01335C77.3955 1.74485 79.4319 3.41237 79.7159 5.73782L79.7152 5.73792Z" fill="#00CF7B"/>
      <path d="M74.5358 8.36713L73.1599 6.98435L73.4054 6.73982L74.5264 7.86634L77.8172 4.30335L78.0718 4.53848L74.5358 8.36713Z" fill="white"/>
      <path d="M22.2264 19.5497C22.1995 19.6161 22.1556 19.6743 22.0992 19.7184C22.0427 19.7625 21.9756 19.791 21.9046 19.8009L12.2047 21.1621C12.1337 21.172 12.0615 21.163 11.9951 21.1362C11.9287 21.1093 11.8706 21.0654 11.8266 21.0089C11.7825 20.9525 11.7541 20.8854 11.7442 20.8145C11.7342 20.7436 11.7431 20.6713 11.77 20.6049C12.3527 19.1668 13.4046 18.0465 14.7249 17.4002C13.9833 17.0888 13.3644 16.5425 12.9632 15.8453C12.5621 15.1481 12.4009 14.3385 12.5044 13.5408C12.6079 12.7431 12.9705 12.0014 13.5363 11.4297C14.1021 10.858 14.84 10.4878 15.6365 10.376C16.4331 10.2642 17.2444 10.417 17.9457 10.8109C18.647 11.2048 19.1997 11.8181 19.5188 12.5564C19.8378 13.2948 19.9057 14.1175 19.712 14.8982C19.5182 15.6789 19.0736 16.3745 18.4463 16.878C19.8935 17.136 21.2131 17.9234 22.1691 19.1456C22.2133 19.202 22.2419 19.2691 22.2519 19.34C22.2619 19.4109 22.2531 19.4833 22.2264 19.5497Z" fill="#00441F"/>
      <path d="M15.1476 46.8772C14.8942 46.9132 14.6654 47.0483 14.5115 47.2528C14.3577 47.4574 14.2912 47.7146 14.3268 47.968C14.3623 48.2215 14.497 48.4505 14.7013 48.6047C14.9055 48.759 15.1626 48.8259 15.4161 48.7908L85.2739 38.9877C85.5273 38.9517 85.756 38.8166 85.9099 38.6121C86.0638 38.4076 86.1303 38.1503 86.0947 37.8969C86.0591 37.6434 85.9245 37.4144 85.7202 37.2602C85.516 37.1059 85.2589 37.039 85.0054 37.0741L15.1476 46.8772Z" fill="#CCCCCC"/>
      <path d="M15.9532 52.618C15.6997 52.6534 15.4705 52.788 15.3161 52.9922C15.1617 53.1964 15.0947 53.4536 15.1297 53.7072C15.1648 53.9608 15.299 54.1901 15.503 54.3448C15.707 54.4995 15.9641 54.5669 16.2177 54.5322L60.3825 48.3346C60.6363 48.2989 60.8655 48.164 61.0197 47.9594C61.174 47.7548 61.2407 47.4973 61.2051 47.2435C61.1694 46.9897 61.0345 46.7605 60.8299 46.6063C60.6253 46.452 60.3677 46.3853 60.114 46.421L15.9532 52.618Z" fill="#CCCCCC"/>
      <path d="M82.7953 29.926C83.1209 32.246 81.5832 34.3796 79.3606 34.6915C77.1381 35.0034 75.0726 33.3755 74.747 31.0554C74.7396 31.0028 74.7331 30.9504 74.7279 30.898C74.4432 28.5727 76.0193 26.4699 78.2474 26.2014C80.4756 25.9329 82.512 27.6004 82.796 29.9259L82.7953 29.926Z" fill="#00CF7B"/>
      <path d="M77.6161 32.5552L76.2402 31.1724L76.4857 30.9279L77.6067 32.0544L80.8975 28.4915L81.1521 28.7266L77.6161 32.5552Z" fill="white"/>
      <g clipPath="url(#clip0_57_2)">
        <path d="M25.5282 43.0787C25.5014 43.1452 25.4575 43.2034 25.401 43.2475C25.3446 43.2916 25.2775 43.32 25.2065 43.33L15.5065 44.6911C15.4356 44.701 15.3633 44.6921 15.297 44.6652C15.2306 44.6383 15.1725 44.5944 15.1285 44.538C15.0844 44.4815 15.056 44.4144 15.0461 44.3435C15.0361 44.2726 15.045 44.2003 15.0719 44.134C15.6546 42.6958 16.7065 41.5756 18.0268 40.9292C17.2852 40.6178 16.6662 40.0715 16.2651 39.3743C15.8639 38.6771 15.7028 37.8675 15.8063 37.0698C15.9098 36.2721 16.2723 35.5304 16.8382 34.9587C17.404 34.387 18.1418 34.0168 18.9384 33.9051C19.735 33.7933 20.5462 33.9461 21.2476 34.34C21.9489 34.7339 22.5016 35.3471 22.8206 36.0855C23.1397 36.8239 23.2076 37.6466 23.0139 38.4273C22.8201 39.208 22.3754 39.9035 21.7482 40.407C23.1954 40.665 24.5149 41.4524 25.471 42.6746C25.5152 42.7311 25.5437 42.7981 25.5538 42.869C25.5638 42.94 25.555 43.0123 25.5282 43.0787Z" fill="#00441F"/>
      </g>
      <path d="M5.01696 10.6485C2.36185 18.6294 -1.1193 36.3729 6.197 43.5C12.0688 49.2199 12.8875 69.3488 9.83908 79.0001" stroke="#CCCCCC" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
      <defs>
        <clipPath id="clip0_57_2">
          <rect width="13.06" height="13.06" fill="white" transform="translate(12.3019 33.6014) rotate(-7.98813)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export function BanListSvg () {
  return (
    <svg width="91" height="81" viewBox="0 0 91 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.579 71.3301C18.3256 71.3661 18.0969 71.5012 17.943 71.7057C17.7891 71.9102 17.7226 72.1674 17.7582 72.4209C17.7938 72.6744 17.9284 72.9034 18.1327 73.0576C18.3369 73.2119 18.594 73.2788 18.8475 73.2437L88.7053 63.4406C88.9587 63.4046 89.1875 63.2695 89.3414 63.065C89.4952 62.8604 89.5617 62.6032 89.5261 62.3498C89.4906 62.0963 89.3559 61.8673 89.1516 61.713C88.9474 61.5588 88.6903 61.4919 88.4368 61.527L18.579 71.3301Z" fill="#CCCCCC"/>
      <path d="M19.3846 77.0708C19.1311 77.1062 18.902 77.2408 18.7475 77.445C18.5931 77.6492 18.5261 77.9064 18.5611 78.16C18.5962 78.4136 18.7304 78.6429 18.9344 78.7976C19.1384 78.9523 19.3955 79.0197 19.6491 78.985L63.8139 72.7874C64.0677 72.7518 64.2969 72.6168 64.4512 72.4122C64.6054 72.2076 64.6721 71.9501 64.6365 71.6963C64.6009 71.4425 64.4659 71.2133 64.2613 71.0591C64.0567 70.9048 63.7992 70.8382 63.5454 70.8738L19.3846 77.0708Z" fill="#CCCCCC"/>
      <path d="M28.9166 67.2235C28.8897 67.29 28.8459 67.3482 28.7894 67.3923C28.7329 67.4364 28.6658 67.4648 28.5948 67.4748L18.8949 68.8359C18.824 68.8458 18.7517 68.8369 18.6853 68.81C18.619 68.7831 18.5608 68.7392 18.5168 68.6828C18.4727 68.6263 18.4443 68.5593 18.4344 68.4883C18.4245 68.4174 18.4334 68.3451 18.4602 68.2788C19.043 66.8406 20.0948 65.7204 21.4151 65.074C20.6735 64.7626 20.0546 64.2163 19.6534 63.5191C19.2523 62.8219 19.0911 62.0123 19.1946 61.2146C19.2982 60.4169 19.6607 59.6753 20.2265 59.1035C20.7923 58.5318 21.5302 58.1616 22.3268 58.0499C23.1233 57.9381 23.9346 58.0909 24.6359 58.4848C25.3372 58.8787 25.8899 59.4919 26.209 60.2303C26.5281 60.9687 26.5959 61.7914 26.4022 62.5721C26.2084 63.3528 25.7638 64.0483 25.1365 64.5518C26.5837 64.8098 27.9033 65.5972 28.8594 66.8195C28.9035 66.8759 28.9321 66.9429 28.9421 67.0138C28.9522 67.0848 28.9434 67.1571 28.9166 67.2235Z" fill="#00441F"/>
      <path d="M11.7594 22.7324C11.506 22.7685 11.2773 22.9036 11.1234 23.1081C10.9695 23.3126 10.903 23.5698 10.9386 23.8233C10.9742 24.0767 11.1088 24.3057 11.3131 24.46C11.5173 24.6142 11.7744 24.6812 12.0279 24.646L81.8857 14.8429C82.1391 14.8069 82.3679 14.6718 82.5217 14.4673C82.6756 14.2628 82.7421 14.0056 82.7065 13.7521C82.671 13.4986 82.5363 13.2696 82.332 13.1154C82.1278 12.9611 81.8707 12.8942 81.6172 12.9293L11.7594 22.7324Z" fill="#CCCCCC"/>
      <path d="M12.5649 28.4732C12.3113 28.5086 12.0822 28.6432 11.9278 28.8474C11.7734 29.0516 11.7064 29.3087 11.7414 29.5623C11.7765 29.8159 11.9107 30.0453 12.1147 30.1999C12.3187 30.3546 12.5758 30.422 12.8294 30.3873L56.9942 24.1897C57.1199 24.1721 57.2408 24.1299 57.3501 24.0655C57.4595 24.0011 57.5551 23.9159 57.6314 23.8145C57.7078 23.7132 57.7635 23.5979 57.7953 23.475C57.8271 23.3522 57.8344 23.2243 57.8167 23.0987C57.7991 22.973 57.7569 22.8521 57.6925 22.7427C57.6282 22.6334 57.5429 22.5378 57.4416 22.4614C57.3402 22.385 57.2249 22.3294 57.102 22.2976C56.9792 22.2658 56.8513 22.2585 56.7257 22.2761L12.5649 28.4732Z" fill="#CCCCCC"/>
      <path d="M79.7152 5.73798C80.0407 8.05804 78.503 10.1917 76.2805 10.5035C74.058 10.8154 71.9924 9.18746 71.6669 6.86739C71.6595 6.8148 71.653 6.76247 71.6478 6.71002C71.3631 4.38467 72.9391 2.28191 75.1673 2.01341C77.3955 1.74491 79.4312 3.41253 79.7152 5.73798Z" fill="#DD4E4E"/>
      <path d="M77 4L74 8" stroke="white" strokeWidth="0.5" strokeLinecap="round"/>
      <path d="M74 4L78 7" stroke="white" strokeWidth="0.5" strokeLinecap="round"/>
      <path d="M22.2264 19.5497C22.1996 19.6161 22.1557 19.6743 22.0992 19.7184C22.0427 19.7625 21.9756 19.791 21.9047 19.8009L12.2047 21.1621C12.1338 21.172 12.0615 21.163 11.9952 21.1362C11.9288 21.1093 11.8707 21.0654 11.8266 21.0089C11.7826 20.9525 11.7542 20.8854 11.7442 20.8145C11.7343 20.7436 11.7432 20.6713 11.77 20.6049C12.3528 19.1668 13.4047 18.0465 14.725 17.4002C13.9833 17.0888 13.3644 16.5425 12.9633 15.8453C12.5621 15.1481 12.4009 14.3385 12.5045 13.5408C12.608 12.7431 12.9705 12.0014 13.5363 11.4297C14.1022 10.858 14.84 10.4878 15.6366 10.376C16.4332 10.2642 17.2444 10.417 17.9458 10.8109C18.6471 11.2048 19.1997 11.8181 19.5188 12.5564C19.8379 13.2948 19.9058 14.1175 19.712 14.8982C19.5183 15.6789 19.0736 16.3745 18.4463 16.878C19.8935 17.136 21.2131 17.9234 22.1692 19.1456C22.2134 19.202 22.2419 19.2691 22.252 19.34C22.262 19.4109 22.2532 19.4833 22.2264 19.5497Z" fill="#00441F"/>
      <path d="M15.1477 46.8773C14.8943 46.9133 14.6655 47.0484 14.5116 47.253C14.3577 47.4575 14.2913 47.7147 14.3269 47.9682C14.3624 48.2216 14.4971 48.4506 14.7014 48.6049C14.9056 48.7591 15.1627 48.826 15.4162 48.7909L85.274 38.9878C85.5274 38.9518 85.7561 38.8167 85.91 38.6122C86.0639 38.4077 86.1304 38.1505 86.0948 37.897C86.0592 37.6435 85.9245 37.4145 85.7203 37.2603C85.5161 37.106 85.259 37.0391 85.0055 37.0742L15.1477 46.8773Z" fill="#CCCCCC"/>
      <path d="M15.9533 52.6181C15.6997 52.6535 15.4706 52.7881 15.3162 52.9923C15.1618 53.1965 15.0947 53.4536 15.1298 53.7072C15.1648 53.9608 15.2991 54.1902 15.5031 54.3448C15.7071 54.4995 15.9641 54.5669 16.2178 54.5322L60.3826 48.3346C60.6363 48.299 60.8656 48.1641 61.0198 47.9594C61.1741 47.7548 61.2407 47.4973 61.2051 47.2436C61.1695 46.9898 61.0345 46.7606 60.8299 46.6063C60.6253 46.4521 60.3678 46.3854 60.114 46.421L15.9533 52.6181Z" fill="#CCCCCC"/>
      <path d="M82.7954 29.9261C83.1209 32.2462 81.5832 34.3798 79.3607 34.6916C77.1382 35.0035 75.0726 33.3756 74.7471 31.0555C74.7397 31.0029 74.7332 30.9506 74.728 30.8981C74.4433 28.5728 76.0193 26.47 78.2475 26.2015C80.4757 25.933 82.5114 27.6006 82.7954 29.9261Z" fill="#DD4E4E"/>
      <path d="M80 29L77 33" stroke="white" strokeWidth="0.5" strokeLinecap="round"/>
      <path d="M77 29L81 32" stroke="white" strokeWidth="0.5" strokeLinecap="round"/>
      <g clipPath="url(#clip0_69_3)">
        <path d="M25.5283 43.0788C25.5015 43.1452 25.4576 43.2034 25.4011 43.2475C25.3446 43.2916 25.2776 43.32 25.2066 43.33L15.5066 44.6912C15.4357 44.7011 15.3634 44.6921 15.2971 44.6652C15.2307 44.6383 15.1726 44.5944 15.1285 44.538C15.0845 44.4815 15.0561 44.4145 15.0462 44.3436C15.0362 44.2726 15.0451 44.2004 15.072 44.134C15.6547 42.6958 16.7066 41.5756 18.0269 40.9292C17.2852 40.6178 16.6663 40.0716 16.2652 39.3743C15.864 38.6771 15.7029 37.8675 15.8064 37.0698C15.9099 36.2721 16.2724 35.5305 16.8383 34.9588C17.4041 34.387 18.1419 34.0169 18.9385 33.9051C19.7351 33.7933 20.5463 33.9461 21.2477 34.34C21.949 34.7339 22.5016 35.3471 22.8207 36.0855C23.1398 36.8239 23.2077 37.6466 23.0139 38.4273C22.8202 39.208 22.3755 39.9035 21.7482 40.407C23.1955 40.665 24.515 41.4524 25.4711 42.6747C25.5153 42.7311 25.5438 42.7981 25.5539 42.8691C25.5639 42.94 25.5551 43.0123 25.5283 43.0788Z" fill="#00441F"/>
      </g>
      <path d="M5.01699 10.6484C2.36188 18.6293 -1.11927 36.3729 6.19704 43.5C12.0688 49.2199 12.8876 69.3488 9.83911 79" stroke="#CCCCCC" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M86.227 54.3787C86.5526 56.6988 85.0149 58.8324 82.7923 59.1443C80.5698 59.4562 78.5043 57.8282 78.1787 55.5081C78.1713 55.4555 78.1648 55.4032 78.1596 55.3508C77.8749 53.0254 79.451 50.9227 81.6791 50.6542C83.9073 50.3857 85.9437 52.0532 86.2277 54.3786L86.227 54.3787Z" fill="#DD4E4E"/>
      <path d="M84 53L81 57" stroke="white" strokeWidth="0.5" strokeLinecap="round"/>
      <path d="M81 53L85 56" stroke="white" strokeWidth="0.5" strokeLinecap="round"/>
      <defs>
        <clipPath id="clip0_69_3">
          <rect width="13.06" height="13.06" fill="white" transform="translate(12.3019 33.6014) rotate(-7.98813)"/>
        </clipPath>
      </defs>
    </svg>

  )
}

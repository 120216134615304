import React, { Fragment, useEffect, useState } from 'react'
import { Form, Col, Row, Typography, Divider, Button, Checkbox } from 'antd'
import { seguradoFieldsString, seguroFieldsNumber, valuesFormatPercentage, valuesFormatMoney } from '../contractFields'
import ChooseContractComponent from '../ChooseContractComponent'
import { Input, PercentageInput } from 'components'
import { IOnidataContractErrors } from '../contract'
import { ArrowRightOutlined } from '@ant-design/icons'
import format from 'utils/format'
import { useProposal, useStep } from 'hooks'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'
import { proposals } from 'egi/consumers'
import { useDispatch } from 'react-redux'
import { stepForm } from 'store/modules/step/actions'
import { swalError } from 'components/SwalError/SwalError'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'

interface ISeguradoValues {
  [key: string]: any
  seguro_primeiro_segurado_cpf: string
  seguro_primeiro_segurado_nome: string
  seguro_primeiro_segurado_porcentagem: number
  seguro_existe_segundo_segurado: boolean
  seguro_segundo_segurado_cpf: string
  seguro_segundo_segurado_nome: string
  seguro_segundo_segurado_porcentagem: number
  seguro_existe_terceiro_segurado: boolean
  seguro_terceiro_segurado_cpf: string
  seguro_terceiro_segurado_nome: string
  seguro_terceiro_segurado_porcentagem: number
  seguro_danos_fisicos_cesh: number
  seguro_danos_fisicos_valor_mensal: number
  seguro_morte_invalidez_cesh: number
  seguro_morte_invalidez_valor_mensal: number
}

function SeguradoStep ({ readOnly, onSuccess }: { readOnly?: boolean, onSuccess: (value: boolean) => void }) {
  const [errors, setErrors] = useState<IOnidataContractErrors>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [hasSecondInsured, setHasSecondInsured] = useState<boolean>(false)
  const [hasThirdInsured, setHasThirdInsured] = useState<boolean>(false)

  const showThirdInsuredOption = hasSecondInsured

  const [formRef] = Form.useForm()
  const proposal = useProposal()
  const step = useStep()
  const dispatch = useDispatch()

  function onError (err: any) {
    swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    setLoading(false)
  }

  const onSubmit = async (values: ISeguradoValues) => {
    setLoading(true)
    setErrors({})

    try {
      if (!proposal.id) throw new Error('Falha ao encontrar id da proposta')
      if (!step.form) throw new Error('Falha ao encontrar form')
      if (!step.id) throw new Error('Falha ao encontrar step id')
      if (!step.areaId) throw new Error('Falha ao encontrar area Id')

      values.seguro_existe_segundo_segurado = hasSecondInsured
      values.seguro_existe_terceiro_segurado = hasThirdInsured
      valuesFormatMoney.forEach(item => {
        if (values[item]) values[item] = format.formatMoneySend(values[item])
      })

      valuesFormatPercentage.forEach(item => {
        if (values[item]) values[item] = format.precisionAndPercentage(values[item])
      })

      const props: any = {
        form: { ...step.form, ...values, finished: false, currentStep: 6 },
        proposalId: proposal.id,
        stepId: step.id,
        areaId: step.areaId
      }

      const response = await proposals.send(props)
      dispatch(stepForm({ data: values }))
      onSuccess(true)
      swal.basic({ title: 'Sucesso!', text: response.message, icon: 'success' })
    } catch (err) {
      if ([
        'Falha ao encontrar id da proposta',
        'Falha ao encontrar form',
        'Falha ao encontrar step id',
        'Falha ao encontrar area Id'
      ].includes(err.message)) return onError(err)

      if (err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
      } else {
        swalError({ title: 'Atenção', icon: 'warning', err })
      }

      setLoading(false)
    }
  }

  useEffect(() => {
    async function onFill () {
      try {
        const tempForm = JSON.parse(JSON.stringify(step.form))

        if (tempForm.seguro_existe_segundo_segurado === true) setHasSecondInsured(true)
        if (tempForm.seguro_existe_terceiro_segurado === true) setHasThirdInsured(true)

        valuesFormatMoney.forEach(item => {
          if (tempForm[item]) tempForm[item] = parseFloat(tempForm[item]).toFixed(2)
        })

        valuesFormatPercentage.forEach(item => {
          if (tempForm[item]) tempForm[item] = format.decimalToPercentage(tempForm[item]).toFixed(4)
        })

        if (formRef) formRef.setFieldsValue({ ...tempForm })
      } catch (err) {
        swal.basic({ title: 'Atenção!', text: err.message, icon: 'warning' })
      }
    }

    onFill()
  }, [])

  return (
    <Fragment>
      <Form
        form={formRef}
        layout='vertical'
        onFinish={onSubmit}
        className="form-contract mt-5"
        initialValues={{
          seguro_primeiro_segurado_porcentagem: 0,
          seguro_segundo_segurado_porcentagem: 0,
          seguro_terceiro_segurado_porcentagem: 0,
          seguro_morte_invalidez_cesh: 0,
          seguro_danos_fisicos_cesh: 0,
          seguro_danos_fisicos_valor_mensal: 0,
          seguro_morte_invalidez_valor_mensal: 0
        }}
      >
        <Col span={24}>
          <Row gutter={[15, 15]} align="top">
            <Col span={24}>
              <Typography.Title className="form-section-title form-section-title--primary" level={4}>Primeiro segurado</Typography.Title>
            </Col>

            {seguradoFieldsString('seguro_primeiro').map(item => (
              <Col lg={8} md={24} sm={24} xs={24} key={item.id}>
                <ChooseContractComponent
                  errors={errors}
                  item={item}
                  type={item.type || ''}
                  readOnlyInput={readOnly}
                  component={(
                    <Form.Item
                      name={item.field}
                      label={<label>{item.label}</label>}
                      help={errors[item.field] && (errors[item.field])}
                      validateStatus={errors[item.field] && ('error')}
                    >
                      <Input
                        id={'test-form-item-' + item.field}
                        readOnly={readOnly}
                        placeholder={'Digite o(a) ' + item.label}
                        type="text"
                        error={errors[item.field]}
                        mask={item.mask || ''}
                      />
                    </Form.Item>
                  )}
                />
              </Col>
            ))}

            <Col lg={8} md={24} sm={24} xs={24}>
              <Form.Item
                name='seguro_primeiro_segurado_porcentagem'
                label={<label>Porcentagem segurado</label>}
                help={errors.seguro_primeiro_segurado_porcentagem && (errors.seguro_primeiro_segurado_porcentagem)}
                validateStatus={errors.seguro_primeiro_segurado_porcentagem && ('error')}
              >
                <PercentageInput
                  disabled={readOnly}
                  placeholder="Digite a porcentagem do segurado"
                />
              </Form.Item>
            </Col>

            <Divider />

            <Col span={24} className="flex flex-">
              <Typography.Title className="form-section-title form-section-title--primary mr-2" level={4}>Segundo segurado</Typography.Title>
              <Form.Item
                name="seguro_existe_segundo_segurado"
                className="mb-0"
                valuePropName="checked"
                help={errors.seguro_existe_segundo_segurado && (errors.seguro_existe_segundo_segurado)}
                validateStatus={errors.seguro_existe_segundo_segurado && ('error')}
              >
                <Checkbox disabled={readOnly} onChange={value => setHasSecondInsured(value.target.checked)}>
                  Possui segundo segurado ?
                </Checkbox>
              </Form.Item>
            </Col>

            {hasSecondInsured && (
              <Fragment>
                {seguradoFieldsString('seguro_segundo').map(item => (
                  <Col lg={8} md={24} sm={24} xs={24} key={item.id}>
                    <ChooseContractComponent
                      errors={errors}
                      item={item}
                      type={item.type || ''}
                      readOnlyInput={readOnly}
                      component={(
                        <Form.Item
                          name={item.field}
                          label={<label>{item.label}</label>}
                          help={errors[item.field] && (errors[item.field])}
                          validateStatus={errors[item.field] && ('error')}
                        >
                          <Input
                            id={'test-form-item-' + item.field}
                            readOnly={readOnly}
                            placeholder={'Digite o(a) ' + item.label}
                            type="text"
                            error={errors[item.field]}
                            mask={item.mask || ''}
                          />
                        </Form.Item>
                      )}
                    />
                  </Col>
                ))}

                <Col lg={8} md={24} sm={24} xs={24}>
                  <Form.Item
                    name='seguro_segundo_segurado_porcentagem'
                    label={<label>Porcentagem segurado</label>}
                    help={errors.seguro_segundo_segurado_porcentagem && (errors.seguro_segundo_segurado_porcentagem)}
                    validateStatus={errors.seguro_segundo_segurado_porcentagem && ('error')}
                  >
                    <PercentageInput
                      disabled={readOnly}
                      placeholder="Digite a porcentagem do segurado"
                    />
                  </Form.Item>
                </Col>
              </Fragment>
            )}

            {showThirdInsuredOption && <Col span={24} className="flex flex-">
              <Typography.Title className="form-section-title form-section-title--primary mr-2" level={4}>Terceiro segurado</Typography.Title>
              <Form.Item
                name="seguro_existe_terceiro_segurado"
                className="mb-0"
                valuePropName="checked"
                help={errors.seguro_existe_terceiro_segurado && (errors.seguro_existe_terceiro_segurado)}
                validateStatus={errors.seguro_existe_terceiro_segurado && ('error')}
              >
                <Checkbox disabled={readOnly} onChange={value => setHasThirdInsured(value.target.checked)}>
                  Possui terceiro segurado ?
                </Checkbox>
              </Form.Item>
            </Col>}

            {hasThirdInsured && (
              <Fragment>
                {seguradoFieldsString('seguro_terceiro').map(item => (
                  <Col lg={8} md={24} sm={24} xs={24} key={item.id}>
                    <ChooseContractComponent
                      errors={errors}
                      item={item}
                      type={item.type || ''}
                      readOnlyInput={readOnly}
                      component={(
                        <Form.Item
                          name={item.field}
                          label={<label>{item.label}</label>}
                          help={errors[item.field] && (errors[item.field])}
                          validateStatus={errors[item.field] && ('error')}
                        >
                          <Input
                            id={'test-form-item-' + item.field}
                            readOnly={readOnly}
                            placeholder={'Digite o(a) ' + item.label}
                            type="text"
                            error={errors[item.field]}
                            mask={item.mask || ''}
                          />
                        </Form.Item>
                      )}
                    />
                  </Col>
                ))}

                <Col lg={8} md={24} sm={24} xs={24}>
                  <Form.Item
                    name='seguro_terceiro_segurado_porcentagem'
                    label={<label>Porcentagem segurado</label>}
                    help={errors.seguro_terceiro_segurado_porcentagem && (errors.seguro_terceiro_segurado_porcentagem)}
                    validateStatus={errors.seguro_terceiro_segurado_porcentagem && ('error')}
                  >
                    <PercentageInput
                      disabled={readOnly}
                      placeholder="Digite a porcentagem do segurado"
                    />
                  </Form.Item>
                </Col>
              </Fragment>
            )}

            <Divider />

            <Col span={24}>
              <Typography.Title
                className="form-section-title form-section-title--primary "
                level={4}
              >
                Dados do seguro
              </Typography.Title>
            </Col>

            {seguroFieldsNumber.map(item => (
              <Col lg={8} md={24} sm={24} xs={24} key={item.id}>
                <ChooseContractComponent
                  errors={errors}
                  item={item}
                  type={item.type || ''}
                  readOnlyInput={readOnly}
                  component={(
                    <Form.Item
                      name={item.field}
                      label={<label>{item.label}</label>}
                      help={errors[item.field] && (errors[item.field])}
                      validateStatus={errors[item.field] && ('error')}
                    >
                      <Input
                        readOnly={readOnly}
                        placeholder={'Digite o(a) ' + item.label}
                        type="number"
                        error={errors[item.field]}
                      />
                    </Form.Item>
                  )}
                />
              </Col>
            ))}
          </Row>
        </Col>

        <Row justify="end">
          <Col>
            <Button
              loading={loading}
              className="mt-4 text-center w-100 color-white uppercase"
              type="primary"
              htmlType="submit"
              disabled={readOnly}
            >
              Avançar
              <ArrowRightOutlined />
            </Button>
          </Col>
        </Row>
      </Form>
    </Fragment>
  )
}

export default SeguradoStep

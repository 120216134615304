import { AxiosRequestConfig } from 'axios'
import { IClientPending } from 'ecp/app/Clients/clientInterfaces'
import { IResponseBase, Repository } from 'ecp/repositories/Repository'
import api from 'services/api'

export type ILGPDEndorse = 'approve' | 'reprove'

class ClientPendingRepository extends Repository {
  async findPending (id: string, config?: AxiosRequestConfig): Promise<IResponseBase<IClientPending>> {
    return Repository.handle<IClientPending>(() =>
      this.api.get(`${this.path}/${id}`, config)
    )
  }

  async findPendingOnidata (id: string, config?: AxiosRequestConfig) {
    return Repository.handle<{ client: IClientPending }>(() =>
      this.api.get(`${this.path}/onidata/${id}`, config)
    )
  }

  async createClientInvite (data: FormData, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.post(`${this.path}/`, data, config)
    )
  }

  async updateClientInvite (clientId: string, data: FormData, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.put(`${this.path}/${clientId}`, data, config)
    )
  }

  async updateClientOnidata (clientId: string, data: IClientPending & { userFuncaoId: string }, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.put(`${this.path}/onidata/${clientId}`, data, config)
    )
  }

  async endorseLGDPCLient (data: {
    clientId: string,
    endorse: ILGPDEndorse
  }, config?: AxiosRequestConfig): Promise<IResponseBase> {
    const { clientId, endorse } = data
    return Repository.handle(() =>
      this.api.post(`${this.path}/${clientId}`, { endorse }, config)
    )
  }

  async endorseLGDPCLientOnidata (data: {
    clientId: string,
    endorse: ILGPDEndorse,
    userFuncaoId?: string
  }, config?: AxiosRequestConfig): Promise<IResponseBase> {
    const { clientId, endorse, userFuncaoId } = data

    return Repository.handle(() =>
      this.api.post(`${this.path}/onidata/${clientId}`, { endorse, userFuncaoId }, config)
    )
  }

  async evaluateMultipleLGDPCLient (data: {pendingUserIds: string[], approval: boolean}, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.post(`${this.path}/evaluate/bulk`, data, config)
    )
  }
}

export default new ClientPendingRepository({ api, path: 'ecp/pendingUsers' })

import React from 'react'

export default function CelebrateSVG ({ className = '' } : {className?: string}) {
  return (
    <svg className={className} width="1090" height="1086" viewBox="0 0 1090 1086" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M610 1044L547.092 1044H547.09C536.458 1044 526.261 1048.29 518.743 1055.91C511.225 1063.54 507.001 1073.89 507 1084.67V1086L609.998 1086L610 1044Z" fill="#00441F"/>
      <path d="M330.263 356.551C428.715 356.551 508.525 276.734 508.525 178.275C508.525 79.8166 428.715 0 330.263 0C231.811 0 152 79.8166 152 178.275C152 276.734 231.811 356.551 330.263 356.551Z" fill="#00CF7B"/>
      <path d="M312.598 246.282C308.588 246.289 304.684 244.991 301.475 242.585L301.276 242.436L259.382 210.386C257.442 208.899 255.813 207.043 254.59 204.926C253.367 202.809 252.572 200.472 252.252 198.048C251.932 195.624 252.092 193.161 252.724 190.799C253.356 188.437 254.447 186.223 255.934 184.282C257.422 182.342 259.277 180.713 261.394 179.49C263.51 178.266 265.847 177.472 268.271 177.152C270.695 176.832 273.158 176.992 275.52 177.624C277.882 178.256 280.096 179.346 282.036 180.834L309.172 201.644L373.295 117.983C374.782 116.043 376.636 114.416 378.752 113.193C380.868 111.97 383.204 111.176 385.627 110.856C388.05 110.536 390.512 110.696 392.873 111.327C395.234 111.959 397.447 113.049 399.387 114.536L399.392 114.54L398.994 115.093L399.403 114.54C403.316 117.548 405.876 121.985 406.521 126.878C407.166 131.771 405.844 136.72 402.845 140.639L327.423 238.999C325.678 241.266 323.434 243.1 320.866 244.359C318.298 245.618 315.474 246.269 312.614 246.26L312.598 246.282Z" fill="white"/>
      <path d="M35.5001 133C55.1062 133 71 117.106 71 97.5C71 77.8939 55.1062 62 35.5001 62C15.8939 62 0 77.8939 0 97.5C0 117.106 15.8939 133 35.5001 133Z" fill="#00CF7B"/>
      <path d="M357.5 499C369.374 499 379 489.374 379 477.5C379 465.626 369.374 456 357.5 456C345.626 456 336 465.626 336 477.5C336 489.374 345.626 499 357.5 499Z" fill="#00CF7B"/>
      <path d="M93 401C105.15 401 115 391.15 115 379C115 366.85 105.15 357 93 357C80.8497 357 71 366.85 71 379C71 391.15 80.8497 401 93 401Z" fill="#00CF7B"/>
      <path d="M1089.72 53.8905C1090.71 46.8932 1089.08 39.7776 1085.16 33.8918C1081.24 28.006 1075.29 23.7591 1068.43 21.9558C1061.58 20.1525 1054.3 20.9181 1047.97 24.1075C1041.64 27.2969 1036.71 32.6886 1034.11 39.2608L973 109.086L990.93 159L1052.38 77.4116C1056.39 78.692 1060.64 79.0804 1064.81 78.5495C1068.99 78.0185 1073 76.5812 1076.56 74.3387C1080.12 72.0962 1083.14 69.103 1085.41 65.5701C1087.69 62.0371 1089.16 58.0503 1089.72 53.8905Z" fill="#FFB7B7"/>
      <path d="M836.997 326L1040 110.334L1012.62 67L758 281.356L836.997 326Z" fill="#00441F"/>
      <path d="M792 195C831.212 195 863 163.212 863 124C863 84.7878 831.212 53 792 53C752.788 53 721 84.7878 721 124C721 163.212 752.788 195 792 195Z" fill="#2F2E41"/>
      <path d="M798 84.0095C813.291 68.5765 831.795 56.6671 852.209 49.1198C865.313 44.2881 879.576 41.3028 893.276 44.0468C906.976 46.7907 919.931 56.2235 923.753 69.6018C926.878 80.5414 923.651 92.32 918.801 102.618C913.951 112.916 907.476 122.465 903.554 133.148C899.552 144.047 898.348 155.769 900.052 167.249C901.756 178.729 906.314 189.604 913.312 198.886C920.31 208.168 929.526 215.564 940.124 220.401C950.723 225.239 962.367 227.366 974 226.588C960.539 228.385 948.131 234.681 934.974 238.032C921.817 241.383 906.288 241.156 896.511 231.774C886.166 221.848 885.959 205.709 886.459 191.416C887.203 170.169 887.946 148.922 888.69 127.675C889.069 116.839 889.393 105.657 885.466 95.543C881.54 85.4295 872.21 76.5735 861.317 76.6656C853.061 76.7352 845.753 81.7131 839.133 86.6221C832.512 91.5312 825.504 96.7688 817.281 97.4989C809.057 98.2292 799.668 92.172 800.283 83.9777L798 84.0095Z" fill="#2F2E41"/>
      <path d="M522.61 600.943C515.798 602.841 508.528 602.167 502.179 599.049C495.829 595.932 490.841 590.587 488.159 584.028C485.477 577.469 485.287 570.151 487.627 563.461C489.966 556.772 494.672 551.175 500.852 547.731L562.152 478L614 489.232L541.077 560.849C542.869 564.657 543.808 568.812 543.825 573.023C543.843 577.233 542.94 581.397 541.179 585.219C539.418 589.042 536.843 592.431 533.635 595.148C530.427 597.864 526.663 599.843 522.61 600.943Z" fill="#FFB7B7"/>
      <path d="M724 351.558L571.488 544L525 522.545L704.26 242L724 351.558Z" fill="#00441F"/>
      <path d="M602 931.571L566 931L570.33 1055H601.994L602 931.571Z" fill="#FFB7B7"/>
      <path d="M843.584 898L804 923.693L883.179 1020L910 1002.59L843.584 898Z" fill="#FFB7B7"/>
      <path d="M911.573 989L858.484 1023.18L858.482 1023.18C849.51 1028.96 843.194 1038.08 840.923 1048.53C838.652 1058.97 840.612 1069.9 846.372 1078.9L847.078 1080L934 1024.03L911.573 989Z" fill="#00441F"/>
      <path d="M857.376 518.935C857.376 518.935 769.021 225.115 740.626 221.144C712.232 217.174 666.482 296.535 666.482 296.535C607.165 372.311 546 617.766 546 617.766C546 617.766 647.965 624.154 722.501 663.86C797.037 703.565 885 661.85 885 661.85L857.376 518.935Z" fill="#00441F"/>
      <path opacity="0.1" d="M857.376 518.934C857.376 518.934 769.021 225.113 740.626 221.143C712.232 217.172 678.102 297.339 678.102 297.339C648.545 419.66 546 617.765 546 617.765C546 617.765 647.965 624.154 722.501 663.859C797.037 703.565 885 661.849 885 661.849L857.376 518.934Z" fill="black"/>
      <path d="M784.939 431.485V579.143L802.294 793.037L889 954.833L847.618 984.43L729.382 830.527L682.618 677.711L609.177 1016L554 1012.05L592.32 543.021L665.037 411L784.939 431.485Z" fill="#00441F"/>
      <path d="M695.594 288.121C667.928 315.759 693.617 345.371 693.617 345.371L662 416.44L786.494 450L830 255.788L800.359 224.202C741.076 222.227 741.044 221 741.044 221C730.657 231.155 695.594 288.121 695.594 288.121Z" fill="#00CF7B"/>
      <path d="M834.854 235.388C829.229 231.212 822.836 228.201 816.045 226.527C809.254 224.854 802.201 224.552 795.293 225.639C788.386 226.727 781.761 229.181 775.804 232.861C769.846 236.541 764.674 241.373 760.586 247.077C729.45 290.673 700.66 361.069 740.328 448.866C804.022 589.84 859.45 662.007 881.984 662C882.968 662.008 883.946 661.853 884.88 661.542C898.75 656.81 918.804 633.209 922.434 612.41C923.734 604.966 923.425 594.597 914.572 587.477C889.141 567.023 843.488 503.336 829.246 419.509C821.376 373.19 836.639 330.998 850.828 303.761C856.822 292.49 858.575 279.425 855.767 266.961C852.959 254.496 845.777 243.467 835.539 235.894L834.854 235.388Z" fill="#00441F"/>
      <path d="M795.585 187.946C820.92 174.095 830.232 142.327 816.382 116.99C802.532 91.653 770.767 82.3412 745.431 96.1918C720.096 110.042 710.784 141.81 724.634 167.148C738.484 192.485 770.25 201.797 795.585 187.946Z" fill="#FFB7B7"/>
      <path d="M697 138H772.211L772.983 127.251L776.839 138H788.42L789.948 116.695L797.591 138H820V136.944C819.983 122.112 814.057 107.892 803.522 97.4043C792.986 86.9163 778.702 81.0168 763.802 81H753.198C738.299 81.0167 724.014 86.9162 713.479 97.4042C702.943 107.892 697.017 122.112 697 136.944V138Z" fill="#2F2E41"/>
      <path d="M808.705 177.392L841 166.856V88H780L781.51 89.5161C802.522 110.616 825.891 185.476 808.705 177.392Z" fill="#2F2E41"/>
    </svg>
  )
}

import React, { useState } from 'react'
import { Collapse } from 'antd'
import SpreadsheetDebt from './SpreadsheetDebt'
import Calculator from './Calculator'

const { Panel } = Collapse

function SpreadsheetCR ({ canSave = true }: { canSave?: boolean }) {
  const [open, setOpen] = useState(false)

  return (
    <Collapse onChange={() => { setOpen(!open) }} className='mb-3'>
      <Panel
        header="Calculadora"
        key="1"
      >
        <Calculator type='income' setBACEN={'BACEN'} canSave={canSave} />
        <Calculator type='income-conjuge' setBACEN={'BACEN_CONJUGE'} canSave={canSave} />
        <Calculator type='income-pj' setBACEN={'BACEN_PJ'} canSave={canSave} />
        <SpreadsheetDebt canSave={canSave} />
      </Panel>
    </Collapse>
  )
}

export default SpreadsheetCR

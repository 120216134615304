import React, { Fragment, useEffect, useState } from 'react'
import { Col, Collapse, Row, Typography, Skeleton, Empty, Button } from 'antd'
import { proposals } from 'egi/consumers'
import swal from 'utils/swal'
import { useProposal, useStep } from 'hooks'
import translate from 'utils/translate'
import CompositorStepForm from './CompositorStepForm'
import { ArrowRightOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { stepForm } from 'store/modules/step/actions'
import ContractsEgiRepository from 'egi/repositories/ContractsEgiRepository'

interface IPeople {
  title: string
  slug: string
  personRef: string
}

function CompositorStep ({ readOnly, onSuccess }: {readOnly?: boolean, onSuccess: (value: boolean) => void}) {
  const [openned, setOpenned] = useState<IPeople[]>()

  const [status, setStatus] = useState< 'loading' | 'failed' | undefined>(undefined)

  const proposal = useProposal()
  const step = useStep()
  const isUnified = step?.form?.geral_modelo_contrato === 'unified'
  const dispatch = useDispatch()
  const { Panel } = Collapse

  function handleControlAcitve (personRef: string, slug: string, active?: boolean) {
    return swal.confirmNegate({
      icon: 'question',
      title: 'Atenção',
      text: `Você tem certeza que deseja ${active ? 'desativar' : 'ativar'} esse garantidor ?`,
      confirm: async () => {
        try {
          const response = await ContractsEgiRepository.controlActive({ proposalId: proposal.id, personRef, ativo: !active })
          const message = response.data.message
          dispatch(stepForm({ data: { [`${slug}_ativo`]: !active } }))
          swal.basic({ title: 'Sucesso!', text: message, icon: 'success' })
        } catch (err) {
          swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
        }
      }
    })
  }

  useEffect(() => {
    const getOpennedPeople = async () => {
      setStatus('loading')
      try {
        const response = await proposals.opennedPeople({ proposalId: proposal.id })
        const people: string[] = response.data.people

        if (people.length < 1) {
          setStatus('failed')
          return
        }

        const formatPeople: IPeople[] = people.map((item: string) => ({
          title: translate.translatePerson(item),
          slug: translate.slugifyPerson(item),
          personRef: item
        }))

        setOpenned(formatPeople)

        setStatus(undefined)
      } catch (err) {
        setStatus('failed')
        swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      }
    }

    getOpennedPeople()
  }, [])

  return (
    <div>
      <Row gutter={[15, 15]} className="mt-5">
        <Col>
          <Typography.Title className="form-section-title form-section-title--primary " level={4}>
            {`${isUnified ? 'FIDUCIANTE' : 'TERCEIRO GARANTIDOR'}/DEVEDOR SOLIDÁRIO/AVALISTA`}
          </Typography.Title>
        </Col>
      </Row>

      {status === 'loading' && (
        <Fragment>
          {new Array(3).fill('').map((_, idx) => (
            <Skeleton.Button
              key={idx}
              active={status === 'loading'}
              size='default'
              shape='square'
              className="form-contract__skeleton"
            />
          ))}
        </Fragment>
      )}

      {status === 'failed' && (
        <div>
          <Empty description="Nenhum garantidor encontrado." />
        </div>
      )}

      {!status && (
        <Fragment>
          <Collapse destroyInactivePanel accordion={true}>
            {Array.isArray(openned) && openned.length > 0 && openned.map((item) => {
              return (
                <Panel
                  header={item.title}
                  key={item.slug}
                  extra={
                    <Fragment>
                      <div
                        className={step.form && step.form[`${item.slug}_ativo`] ? 'form-contract__collapse-active' : 'form-contract__collapse-desactive'}
                        onClick={(e) => {
                          e.stopPropagation()
                          if (step.form) {
                            handleControlAcitve(item.personRef, item.slug, step.form[`${item.slug}_ativo`] ? true : false)
                          }
                        }}
                      >
                        {step.form && step.form[`${item.slug}_ativo`] ? 'Ativo' : 'Desativado'}
                      </div>
                    </Fragment>
                  }
                >
                  <CompositorStepForm
                    readOnly={readOnly}
                    slug={item.slug}
                  />
                </Panel>
              )
            })}
          </Collapse>
        </Fragment>
      )}

      <Row justify="end">
        <Col>
          <Button
            type="primary"
            className="mt-4 text-center w-100 color-white uppercase"
            disabled={readOnly}
            onClick={() => onSuccess(true)}
          >
            Avançar
            <ArrowRightOutlined/>
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default CompositorStep

import { Repository } from './Repository'
import api from 'services/api'
import { AxiosRequestConfig } from 'axios'
import { ITemplateReasonModalForm } from 'ecp/app/Proposals/components/TemplateReasonModal/TemplateReasonModal'
import { IReasons } from 'ecp/app/Proposals/proposalInterfaces'
class StepConfigurationRepository extends Repository {
  async submitReason (stepId: string, data: ITemplateReasonModalForm, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.post(`${this.path}/reasons/${stepId}`, data, config)
    )
  }

  async updateReason (data: {reasonId: string, stepId: string, body: ITemplateReasonModalForm}, config?: AxiosRequestConfig) {
    const { reasonId, stepId, body } = data
    return Repository.handle(() =>
      this.api.put(`${this.path}/reasons/${stepId}/reason/${reasonId}`, body, config)
    )
  }

  async deleteReason (data: {reasonId: string, stepId: string}, config?: AxiosRequestConfig) {
    const { reasonId, stepId } = data
    return Repository.handle(() =>
      this.api.delete(`${this.path}/reasons/${stepId}/reason/${reasonId}`, config)
    )
  }

  async getReasons (config: AxiosRequestConfig) {
    return Repository.handle<{reasons: IReasons[]}>(() =>
      this.api.get(`${this.path}/reasons`, config)
    )
  }
}

export default new StepConfigurationRepository({ api, path: '/ecp/stepConfiguration' })

import { message } from 'antd'
import { AxiosRequestConfig } from 'axios'
import { _productSlugs } from 'ecp/models/ProductModel'
import LevelsRepository from 'ecp/repositories/LevelsRepository'
import { useAuth } from 'hooks'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { resources } from 'egi/consumers'
import { getFirstRouter } from 'utils/utils'
import { resourcesSetProducts } from 'store/modules/resources/actions'
import useSelectedProducts from 'components/SelectProductsSidebar/hooks/useSelectedProducts'
import { IAuth } from 'egi/types'
import { useState } from 'react'
import { authActions } from 'store/modules/auth/authActions'

type IGetProductsProps = {
  requestConfig: AxiosRequestConfig,
  user: IAuth,
}

function userFactory (user: IAuth) {
  return {
    ...user,
    bank: {
      account: user?.bank?.account || '',
      accountHolder: user?.bank?.accountHolder || '',
      agency: user?.bank?.agency || '',
      code: user?.bank?.code || '',
      type: user?.bank?.type || ''
    },
    status: user?.status,
    proposal: user?.proposalId || '',
    followUpProposal: user?.followUpProposal || false,
    areas: user?.areas || [],
    areaName: user?.areaName || undefined,
    administratorId: user?.administratorId || undefined,
    promoterId: user?.promoterId || undefined,
    type: user?.type || undefined
  }
}

export default function useSelectedLevel () {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useAuth()
  const { onProductSelect } = useSelectedProducts()
  const [isLoadingLevelChange, setIsLoadingLevelChange] = useState<boolean>(false)
  const currentId = user._id

  const goTofirstRoute = (productSlug: _productSlugs, user: IAuth) => {
    const route = getFirstRouter(productSlug, user)
    history.push(route)
  }

  const getProducts = async ({ requestConfig, user }: IGetProductsProps) => {
    const productsRequest = await resources.products(requestConfig)
    const products = productsRequest.data?.products
    const hasSelectedProduct = Boolean(user?.selectedProduct && products.find(product => product?.slug === user?.selectedProduct?.slug))
    const firstProduct = hasSelectedProduct ? user?.selectedProduct : products?.[0]
    return { firstProduct, products }
  }

  const onLevelSelect = async (levelId: string) => {
    if (levelId === currentId) return

    setIsLoadingLevelChange(true)
    try {
      const response = await LevelsRepository.selectLevel(levelId)
      const token = response.data.data.token
      const newUser = response.data.data.user
      const formatedNewUser = userFactory(newUser)

      localStorage.setItem('@ux-line/token', token?.token)

      const requestConfig: AxiosRequestConfig = {
        headers: { Authorization: `Bearer ${token.token}` }
      }

      const { firstProduct, products } = await getProducts({ requestConfig, user: formatedNewUser })
      if (!firstProduct || !firstProduct?._id) throw new Error('Falha ao encontrar produto!')

      dispatch(resourcesSetProducts(products || []))
      onProductSelect(firstProduct?._id, products, formatedNewUser)
      goTofirstRoute(firstProduct.slug as _productSlugs, formatedNewUser)

      dispatch(authActions.changeUserLevel(formatedNewUser))
    } catch (err) {
      message.warning(err?.message)
    } finally {
      setIsLoadingLevelChange(false)
    }
  }

  return {
    onLevelSelect,
    isLoadingLevelChange,
    getProducts,
    goTofirstRoute
  }
}

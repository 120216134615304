import React, { Fragment, MutableRefObject, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Row, Col, Form, Button, Select, Divider, Typography, InputNumber, Checkbox, Skeleton } from 'antd'
import locale from 'antd/es/date-picker/locale/pt_BR'
import { PercentageInput, InputMoney, Input, DatepickerCustom } from 'components'
import { useAuth, useResources } from 'hooks'
import { useParams } from 'react-router-dom'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'
import { useDispatch } from 'react-redux'
import { resourcesGetProducts, resourcesGetStatesIBGE } from 'store/modules/resources/actions'
import format from 'utils/format'
import { IPercentageFields, IPricingParams, IReturn, IReturnItem } from '../../princingInterfaces'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { IPartnerReturn, IPricing } from 'egi/types'
import { timeAsDayjs } from 'utils/time'
import { states } from 'utils/globals'
import GlobalsRepository from 'egi/repositories/GlobalsRepository'
import { Dayjs } from 'dayjs'
import PricingModel from 'egi/models/PricingModel'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'

const { Paragraph } = Typography

const generateR1R9 = (id: number) => {
  const baseArray = []

  for (let i = 1; i < 10; i++) {
    baseArray.push(
      {
        name: ['partnerReturn', `returns_${id}`, `returnR${i}_${id}`],
        label: `R${i} - Taxa`
      },
      {
        name: ['partnerReturn', `returns_${id}`, `returnR${i}_commission_${id}`],
        label: `R${i} - Comissão`
      },
      {
        name: ['partnerReturn', `returns_${id}`, `returnR${i}_consultor_${id}`],
        label: `R${i} - Com. consultor`
      },
      {
        name: ['partnerReturn', `returns_${id}`, `returnR${i}_corban_${id}`],
        label: `R${i} - Com. corban`
      },
      {
        name: ['partnerReturn', `returns_${id}`, `returnR${i}_indicador_${id}`],
        label: `R${i} - Com. indicador`
      },
      {
        name: ['partnerReturn', `returns_${id}`, `returnR${i}_marketPlace_${id}`],
        label: `R${i} - Com. marktplace`
      }
    )
  }

  return baseArray
}

const generateReturn = (id: number) => ({
  id,
  deadlines: [{
    label: 'Prazo inicial.',
    name: ['partnerReturn', `returns_${id}`, `returnInitialDeadline_${id}`]
  },
  {
    label: 'Prazo final.',
    name: ['partnerReturn', `returns_${id}`, `returnFinalDeadline_${id}`]
  }],

  values: generateR1R9(id)
})

function PricingCreate () {
  const [errors, setErrors] = useState<IPricing>({})
  const formRef: MutableRefObject<any> = useRef(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [fieldsLoading, setFieldsLoading] = useState<boolean>(false)
  const [isPromotional, setIsPromotional] = useState<boolean>(false)

  const [returns, setReturns] = useState<IReturn[]>([])
  const [maxDeadline, setMaxDeadline] = useState<number>(240)

  const dispatch = useDispatch()
  const resources = useResources()
  const user = useAuth()
  const params: IPricingParams = useParams()

  const IS_AUDITOR = user.level === 'auditor'

  function convertDecimalPercentage (values: IPercentageFields) {
    const keys = Object.keys(values)
    const formated = keys.map((item: string): Object => ({
      [item]: format.precisionAndDecimal(values[item])
    }))

    return formated
  }

  function convertPercentageDecimal (values: IPercentageFields) {
    const keys = Object.keys(values)
    const formated = keys.map((item: string) => ({
      [item]: format.precisionAndPercentage(values[item])
    }))

    return formated
  }

  async function partnerReturnToForm (partnerReturn: IPartnerReturn[]) {
    if (partnerReturn && partnerReturn.length > 0) {
      const tempPartnersReturn: any = []

      partnerReturn.forEach((_, index: number) => {
        tempPartnersReturn.push(generateReturn(index))
      })

      setReturns(tempPartnersReturn)

      const fixPartnerReturn: any = {}

      partnerReturn.forEach((item: any, index: number) => {
        fixPartnerReturn[`returns_${index}`] = {
          [`returnInitialDeadline_${index}`]: item.deadlineMin,
          [`returnFinalDeadline_${index}`]: item.deadlineMax
        }

        item.returnValues.forEach((value: any) => {
          fixPartnerReturn[`returns_${index}`][`return${value.name}_${index}`] = format.precisionAndDecimal(value.value)
          fixPartnerReturn[`returns_${index}`][`return${value.name}_commission_${index}`] = format.precisionAndDecimal(value.commission)
          fixPartnerReturn[`returns_${index}`][`return${value.name}_consultor_${index}`] = format.precisionAndDecimal(value.consultor)
          fixPartnerReturn[`returns_${index}`][`return${value.name}_corban_${index}`] = format.precisionAndDecimal(value.corban)
          fixPartnerReturn[`returns_${index}`][`return${value.name}_marketPlace_${index}`] = format.precisionAndDecimal(value.marketPlace)
          fixPartnerReturn[`returns_${index}`][`return${value.name}_indicador_${index}`] = format.precisionAndDecimal(value.indicador)
        })
      })

      if (formRef && formRef.current) {
        formRef.current.setFieldsValue({
          partnerReturn: fixPartnerReturn
        })
      }
    }
  }

  useLayoutEffect(() => {
    if (resources && resources.products && resources.products.length < 1) {
      dispatch(resourcesGetProducts())
    }
  }, [resources.products])

  useLayoutEffect(() => {
    if (resources && resources.ibgeStates && resources.ibgeStates.length < 1) {
      dispatch(resourcesGetStatesIBGE())
    }
  }, [resources.states])

  useEffect(() => {
    const firstR: HTMLElement | null = document.querySelector('#pricing-inputs-0')
    const secondR: HTMLElement | null = document.querySelector('#pricing-inputs-1')
    const thirdR: HTMLElement | null = document.querySelector('#pricing-inputs-2')
    const fourthR: HTMLElement | null = document.querySelector('#pricing-inputs-3')

    if (maxDeadline === 60 && secondR && thirdR && fourthR) {
      secondR.style.display = 'none'
      thirdR.style.display = 'none'
      fourthR.style.display = 'none'
    }

    if (maxDeadline === 120 && thirdR && secondR && fourthR) {
      secondR.style.display = 'block'
      thirdR.style.display = 'none'
      fourthR.style.display = 'none'
    }

    if (maxDeadline === 180 && thirdR && firstR && secondR && fourthR) {
      firstR.style.display = 'block'
      secondR.style.display = 'block'
      thirdR.style.display = 'block'
      fourthR.style.display = 'none'
    }

    if (maxDeadline === 240 && thirdR && firstR && secondR && fourthR) {
      firstR.style.display = 'block'
      secondR.style.display = 'block'
      thirdR.style.display = 'block'
      fourthR.style.display = 'block'
    }
  }, [maxDeadline])

  useLayoutEffect(() => {
    async function fetchPricing () {
      const precificacaoId = params.precificacaoId
      const { productSlug, product, personType } = params

      const query = {
        sub: productSlug,
        product,
        personType
      }

      const findProduct = resources.products.find(element => element.slug === product)

      if (productSlug) {
        try {
          let response

          setFieldsLoading(true)
          if (precificacaoId) response = await GlobalsRepository.fetchOnePricing(precificacaoId, { params: query })
          else if (findProduct) response = await GlobalsRepository.getProduct(findProduct._id)
          setFieldsLoading(false)

          let pricing = response?.data?.data?.pricing || {}

          const { aliquota, aliquotaFisica, commission, dfi, ltv, prestamista, registerPercentage, readjustmentIndex } = pricing || {}
          const pricingValues = {
            aliquota: aliquota || 0,
            aliquotaFisica: aliquotaFisica || 0,
            commission: commission || 0,
            dfi: dfi || 0,
            ltv: ltv || 0,
            prestamista: prestamista || 0,
            registerPercentage: registerPercentage || 0,
            readjustmentIndex: readjustmentIndex || 0
          }

          const percentageFields = convertDecimalPercentage(pricingValues)
          pricing = Object.assign(pricing || {}, ...percentageFields)

          const partnerReturn = response?.data?.data?.pricing.partnerReturn
          if (partnerReturn) partnerReturnToForm(partnerReturn)

          if (pricing.isPromotional) setIsPromotional(pricing.isPromotional)
          if (pricing.deadlineMax) setMaxDeadline(pricing.deadlineMax)

          if (formRef && formRef.current) {
            if (pricing.vigency) {
              (pricing.vigency as Dayjs[]) = [
                timeAsDayjs(pricing.vigency.start),
                timeAsDayjs(pricing.vigency.end)
              ]
            }

            if (pricing.partnerReturn) delete pricing.partnerReturn

            if (pricing.uf && pricing.uf.length > 0) {
              if (pricing.uf[0] === '') delete pricing.uf
            }

            formRef?.current.setFieldsValue({ ...pricing })
          }
        } catch (err) {
          console.log(err)
        } finally {
          setFieldsLoading(false)
        }
      }
    }

    fetchPricing()
  }, [resources.products])

  function activeInput (item: any) {
    if (PricingModel.canChange(user.level)) {
      return (item.label.endsWith('consultor') || item.label.endsWith('corban') || item.label.endsWith('indicador') || item.label.endsWith('marktplace'))
    } else {
      return (item.label.endsWith('Taxa') || item.label.endsWith('Comissão'))
    }
  }

  function partnerReturnSubmitFields (values: IPricing) {
    if (values.partnerReturn && Object.keys(values.partnerReturn).length > 0) {
      const lengthObject: any = Object.keys(values.partnerReturn)
      const returnsValues = []

      for (let i: number = 0; i < lengthObject.length; i++) {
        returnsValues.push(values.partnerReturn[lengthObject[i]])
      }

      const formatpartnerReturn: any = returnsValues.map((item: any) => {
        const keys = Object.keys(item)
        const splitIndex = keys[0].split('_')
        const actualIndex = splitIndex[splitIndex.length - 1]

        const data: any = {
          deadlineMin: item[`returnInitialDeadline_${actualIndex}`],
          deadlineMax: item[`returnFinalDeadline_${actualIndex}`]
        }

        const tempArray = []

        for (let i = 1; i < 10; i++) {
          if (item[`returnR${i}_${actualIndex}`]) {
            tempArray.push({
              name: `R${i}`,
              value: format.precisionAndPercentage(item[`returnR${i}_${actualIndex}`]),
              commission: format.precisionAndPercentage(item[`returnR${i}_commission_${actualIndex}`]),
              consultor: format.precisionAndPercentage(item[`returnR${i}_consultor_${actualIndex}`]),
              corban: format.precisionAndPercentage(item[`returnR${i}_corban_${actualIndex}`]),
              indicador: format.precisionAndPercentage(item[`returnR${i}_indicador_${actualIndex}`]),
              marketPlace: format.precisionAndPercentage(item[`returnR${i}_marketPlace_${actualIndex}`])
            })
          }

          data.returnValues = tempArray
        }
        return data
      })

      return formatpartnerReturn
    } else {
      delete values.partnerReturn
    }
  }

  const onUpdateCommission = async (values: IPricing) => {
    values.partnerReturn = partnerReturnSubmitFields(values)

    const { precificacaoId } = params

    const queryParams: any = {}
    if (precificacaoId) queryParams.code = precificacaoId

    const data: IPricing = {
      ...values
    }

    setLoading(true)
    try {
      const response = await GlobalsRepository.updatePricingCommission(queryParams.code, data)
      swal.basic({ title: 'Sucesso!', text: response.data?.message, icon: 'success' })
      setErrors({})
      window.history.back()
    } catch (err) {
      if (err.data?.invalid) {
        let errors: IPricing = {}
        errors = validateResponse(err.data?.invalid)
        setErrors(errors)
        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
        return
      }
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = async (values: IPricing) => {
    values.partnerReturn = partnerReturnSubmitFields(values)

    const { dfiLimit, prestamistaLimit } = values
    const { aliquota, aliquotaFisica, commission, dfi, ltv, prestamista, registerPercentage, avaliationFare, readjustmentIndex } = values

    const percentageFields = convertPercentageDecimal({
      aliquota: aliquota || 0,
      aliquotaFisica: aliquotaFisica || 0,
      commission: commission || 0,
      dfi: dfi || 0,
      ltv: ltv || 0,
      prestamista: prestamista || 0,
      registerPercentage: registerPercentage || 0,
      readjustmentIndex: readjustmentIndex || 0
    })

    values = Object.assign(values, ...percentageFields)

    const fixDfiLimit = format.formatMoneySend(String(dfiLimit))
    const fixPrestamistaLimit = format.formatMoneySend(String(prestamistaLimit))
    const fixAvaliationFare = format.formatMoneySend(String(avaliationFare))

    const tempVigency = (values.vigency as any)

    if (tempVigency && tempVigency[0] && tempVigency[1]) {
      values.vigency = {
        start: tempVigency[0],
        end: tempVigency[1]
      }
    }

    const data: IPricing = {
      ...values,
      vigency: values.vigency,
      dfiLimit: Number(fixDfiLimit),
      avaliationFare: Number(fixAvaliationFare),
      prestamistaLimit: Number(fixPrestamistaLimit),
      personType: params.personType
    }

    const { product, productSlug, precificacaoId } = params
    const queryParams: any = {}
    if (precificacaoId) queryParams.id = precificacaoId
    if (product) queryParams.product = product
    if (productSlug) queryParams.sub = productSlug

    setLoading(true)
    try {
      const response = await GlobalsRepository.createPricing(data, { params: queryParams })
      swal.basic({ title: 'Sucesso!', text: response.data?.message, icon: 'success' })
      setErrors({})
      window.history.back()
    } catch (err) {
      if (err.data?.invalid) {
        let errors: IPricing = {}
        errors = validateResponse(err.data?.invalid)
        setErrors(errors)
        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
        return
      }
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Fragment>
      {fieldsLoading ? (
        <Skeleton active={true} className="card-style p-3" paragraph={{ rows: 5 }} />
      ) : (
        <div className="mb-3 p-3 card-style">
          <Form
            layout="vertical"
            ref={formRef}
            onFinish={PricingModel.canChange(user.level) ? onSubmit : onUpdateCommission}
          >
            {(PricingModel.canChange(user.level) || IS_AUDITOR) && (
              <Fragment>
                <Row>
                  <Col lg={4} sm={24} xs={24} className="px-1">
                    <Form.Item
                      name="vigency"
                      label={<label>Vigência</label>}
                      help={errors.vigency && errors.vigency}
                      validateStatus={errors.vigency && 'error'}
                      rules={[{ required: true, message: 'Escolha a vigência' }]}
                    >
                      <DatepickerCustom.RangePicker
                        disabled={!PricingModel.canChange(user.level)}
                        locale={locale}
                        format="DD/MM/YYYY"
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={4} sm={24} xs={24} className="px-1">
                    <Form.Item
                      name="ltv"
                      label={<label>LTV</label>}
                      help={errors.ltv && errors.ltv}
                      validateStatus={(errors.ltv as any) && 'error'}
                      rules={[{ required: true, message: 'Digite a porcentagem do financiamento' }]}
                    >
                      <PercentageInput disabled={!PricingModel.canChange(user.level)} placeholder="Digite a porcentagem do financiamento" />
                    </Form.Item>
                  </Col>

                  <Col lg={4} sm={24} xs={24} className="px-1">
                    <Form.Item
                      name="dfi"
                      label={<label>Taxa DFI</label>}
                      help={errors.dfi && errors.dfi}
                      validateStatus={(errors.dfi as any) && 'error'}
                      rules={[{ required: true, message: 'Digite a taxa DFI' }]}
                    >
                      <PercentageInput disabled={!PricingModel.canChange(user.level)} placeholder="Digite a taxa DFI" />
                    </Form.Item>
                  </Col>

                  <Col lg={4} sm={24} xs={24} className="px-1">
                    <Form.Item
                      name="dfiLimit"
                      label={<label>Limite DFI</label>}
                      help={errors.dfiLimit && errors.dfiLimit}
                      validateStatus={(errors.dfiLimit as any) && 'error'}
                      rules={[{ required: true, message: 'Digite o limite do DFI' }]}
                    >
                      <InputMoney
                        disabled={!PricingModel.canChange(user.level)}
                        placeholder="Digite o limite do DFI"
                        defaultValue={0}
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={4} sm={24} xs={24} className="px-1">
                    <Form.Item
                      name="prestamista"
                      label={<label>Porcentagem prestamista</label>}
                      help={errors.prestamista && errors.prestamista}
                      validateStatus={(errors.prestamista as any) && 'error'}
                      rules={[{ required: true, message: 'Digite a porcentagem prestamista' }]}
                    >
                      <PercentageInput disabled={!PricingModel.canChange(user.level)} placeholder="Digite a porcentagem prestamista" />
                    </Form.Item>
                  </Col>

                  <Col lg={4} sm={24} xs={24} className="px-1">
                    <Form.Item
                      name="prestamistaLimit"
                      label={<label>Limite da prestamista</label>}
                      help={errors.prestamista && errors.prestamista}
                      validateStatus={(errors.prestamista as any) && 'error'}
                      rules={[{ required: true, message: 'Digite o limite da prestamista' }]}
                    >
                      <InputMoney
                        disabled={!PricingModel.canChange(user.level)}
                        placeholder="Digite o limite da prestamista"
                        defaultValue={0}
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={4} sm={24} xs={24} className="px-1">
                    <Form.Item
                      name="commission"
                      label={<label>Comissão</label>}
                      help={errors.commission && errors.commission}
                      validateStatus={(errors.commission as any) && 'error'}
                      rules={[{ required: true, message: 'Digite a comissão' }]}
                    >
                      <PercentageInput disabled={!PricingModel.canChange(user.level)} placeholder="Digite a comissão" />
                    </Form.Item>
                  </Col>

                  <Col lg={4} sm={24} xs={24} className="px-1">
                    <Form.Item
                      name="deadlineMin"
                      label={<label>Prazo mínimo</label>}
                      help={errors.deadlineMin && errors.deadlineMin}
                      validateStatus={(errors.deadlineMin as any) && 'error'}
                      rules={[{ required: true, message: 'Digite o prazo mínimo.' }]}
                    >
                      <InputNumber
                        disabled={true}
                        min={0}
                        className="w-100"
                        placeholder="Digite o Prazo mínimo"
                        defaultValue={0}
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={4} sm={24} xs={24} className="px-1">
                    <Form.Item
                      name="deadlineMax"
                      label={<label>Prazo máximo</label>}
                      help={errors.deadlineMax && errors.deadlineMax}
                      validateStatus={(errors.deadlineMax as any) && 'error'}
                      rules={[{ required: true, message: 'Digite o prazo máximo' }]}
                    >
                      <Select
                        onChange={(value: number) => setMaxDeadline(value)}
                        showSearch
                        placeholder="Digite o prazo máximo"
                        optionFilterProp="children"
                      >
                        <Select.Option value={60}>60</Select.Option>
                        <Select.Option value={120}>120</Select.Option>
                        <Select.Option value={180}>180</Select.Option>
                        <Select.Option value={240}>240</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col lg={4} sm={24} xs={24} className="px-1">
                    <Form.Item
                      name='proposalExpiration'
                      label={<label>{'Validade (dias)'}</label>}
                      help={errors.proposalExpiration && errors.proposalExpiration}
                      validateStatus={(errors.proposalExpiration as any) && 'error'}
                      rules={[{ required: true, message: 'Digite a validade em dias.' }]}
                    >
                      <InputNumber
                        disabled={!PricingModel.canChange(user.level)}
                        min={0}
                        className="w-100"
                        placeholder="Digite a validade em dias"
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={4} sm={24} xs={24} className="px-1">
                    <Form.Item
                      name='readjustmentIndex'
                      label={<label>Porcentagem de reajuste</label>}
                      help={errors.readjustmentIndex && errors.readjustmentIndex}
                      validateStatus={(errors.readjustmentIndex as any) && 'error'}
                      rules={[{ required: true, message: 'Digite a porcentagem de reajuste.' }]}
                    >
                      <PercentageInput disabled={!PricingModel.canChange(user.level)} placeholder="Digite a porcentagem de reajuste" />
                    </Form.Item>
                  </Col>

                  <Col lg={4} sm={24} xs={24} className="px-1">
                    <Form.Item
                      name="modality"
                      label={<label>Modalidade</label>}
                      help={errors.modality && errors.modality}
                      validateStatus={(errors.modality as any) && 'error'}
                      rules={[{ required: true, message: 'Escolha sua modalidade' }]}
                    >
                      <Select placeholder="Escolha sua modalidade." disabled={!PricingModel.canChange(user.level)}>
                        <Select.Option value="price">PRICE</Select.Option>
                        <Select.Option value="sac">SAC</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col lg={4} sm={24} xs={24} className="px-1">
                    <Form.Item
                      name="aliquota"
                      label={<label>Alíquota IOF</label>}
                      help={errors.aliquota && errors.aliquota}
                      validateStatus={(errors.aliquota as any) && 'error'}
                      rules={[{ required: true, message: 'Digite a alíquota IOF' }]}
                    >
                      <PercentageInput disabled={!PricingModel.canChange(user.level)} placeholder="Digite a alíquota IOF" />
                    </Form.Item>
                  </Col>

                  <Col lg={4} sm={24} xs={24} className="px-1">
                    <Form.Item
                      name="avaliationFare"
                      label={<label>Tarifa de avaliação</label>}
                      help={errors.prestamista && errors.prestamista}
                      validateStatus={(errors.prestamista as any) && 'error'}
                      rules={[{ required: true, message: 'Digite a tarifa de avaliação' }]}
                    >
                      <InputMoney
                        disabled={!PricingModel.canChange(user.level)}
                        placeholder="Digite a tarifa de avaliação"
                        defaultValue={0}
                      />
                    </Form.Item>
                  </Col>

                  {params.personType === 'fisica' && (
                    <Col lg={4} sm={24} xs={24} className="px-1">
                      <Form.Item
                        name="aliquotaFisica"
                        label={<label>Alíquota IOF PF</label>}
                        help={errors.aliquotaFisica && errors.aliquotaFisica}
                        validateStatus={(errors.aliquotaFisica as any) && 'error'}
                        rules={[{ required: true, message: 'Digite a alíquota IOF PF' }]}
                      >
                        <PercentageInput disabled={!PricingModel.canChange(user.level)} placeholder="Digite a alíquota IOF PF" />
                      </Form.Item>
                    </Col>
                  )}

                  {params.personType === 'juridica' && (
                    <Col lg={4} sm={24} xs={24} className="px-1">
                      <Form.Item
                        name="aliquotaJuridica"
                        label={<label>Alíquota IOF PJ</label>}
                        help={errors.aliquotaJuridica && errors.aliquotaJuridica}
                        validateStatus={(errors.aliquotaJuridica as any) && 'error'}
                        rules={[{ required: true, message: 'Digite a alíquota IOF PJ' }]}
                      >
                        <PercentageInput disabled={!PricingModel.canChange(user.level)} placeholder="Digite a alíquota IOF PJ" />
                      </Form.Item>
                    </Col>
                  )}

                  <Col className="px-1" lg={4} sm={24} xs={24}>
                    <Form.Item
                      name='index'
                      label={<label>Indexador:</label>}
                      help={errors.index && (errors.index)}
                      validateStatus={errors.index && ('error')}
                      rules={[{ required: true, message: 'Selecione um indexador' }]}
                    >
                      <Select
                        placeholder={'Escolha o indexador'}
                      >
                        <Select.Option value="IPCA">IPCA</Select.Option>
                        <Select.Option value="IGPM">IGPM</Select.Option>
                        <Select.Option value="ICC">ICC</Select.Option>
                        <Select.Option value="FLATRATE">PRÉ-FIXADA</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col className="px-1" lg={4} sm={12} xs={24}>
                    <Form.Item
                      name='isPromotional'
                      className="form-user-checkbox"
                      valuePropName="checked"
                      label={<label></label>}
                      help={errors.isPromotional && (errors.isPromotional)}
                      validateStatus={(errors.isPromotional as any) && ('error')}
                    >
                      <Checkbox
                        disabled={!PricingModel.canChange(user.level)}
                        onChange={(value: CheckboxChangeEvent) => setIsPromotional(value.target.checked)}
                      >
                        Promoção?
                      </Checkbox>
                    </Form.Item>
                  </Col>

                  {isPromotional && (
                    <Col className="px-1" lg={8} sm={24} xs={24}>
                      <Form.Item
                        name='promotionalDescription'
                        label={<label>Descrição da promoção:</label>}
                        help={errors.promotionalDescription && (errors.promotionalDescription)}
                        validateStatus={errors.promotionalDescription && ('error')}
                      >
                        <Input
                          disabled={!PricingModel.canChange(user.level)}
                          placeholder={'Digite a descrição da promoção'} error={errors.promotionalDescription}
                        />
                      </Form.Item>
                    </Col>
                  )}

                  <Col className="px-1" lg={4} sm={12} xs={24}>
                    <Form.Item
                      name='duplicate'
                      className="form-user-checkbox"
                      valuePropName="checked"
                      label={<label></label>}
                      help={errors.duplicate && (errors.duplicate)}
                      validateStatus={(errors.duplicate as any) && ('error')}
                    >
                      <Checkbox>
                        Copiar precificação
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
              </Fragment>
            )}

            <Paragraph className="bold pl-2 pb-2 text-center">Taxas de retornos</Paragraph>

            {returns.map((item: IReturn, index: number) => {
              return (
                <Fragment key={index}>
                  <div className="pricing-inputs py-6" id={`pricing-inputs-${item.id}`} style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.06)' }}>
                    <Row className="mb-3 flex-justify-center">
                      {item.deadlines.map((item: IReturnItem) => (
                        <Col lg={3} sm={24} xs={24} className="px-1" key={item.label}>
                          <Form.Item
                            name={item.name}
                            label={<label style={{ fontSize: '.9em' }}>{item.label}</label>}
                            rules={[{ required: true, message: item.label }]}
                          >
                            <InputNumber
                              min={0}
                              defaultValue={0}
                              readOnly={true}
                              className="w-100"
                              placeholder={item.label}
                            />
                          </Form.Item>
                        </Col>
                      ))}
                    </Row>

                    <Row className="flex-justify-center">
                      {item.values.map((item: IReturnItem) => (
                        <Fragment key={item.label}>
                          {item.label.endsWith('Taxa') && (
                            <Col
                              className="flex flex-align-center flex-justify-center pricing-table-headers"
                              style={item.label.startsWith('R1') ? { marginTop: 22 } : {}}
                            >
                              {item.label.split('-')[0]}
                            </Col>
                          )}

                          <Col lg={3} md={12} sm={12} className="px-1">
                            {item.label.startsWith('R1') ? <label className="pricing-table-table-header" style={{ fontSize: '.9em' }}>{item.label.split('-')[1]}</label> : <></>}
                            <Form.Item
                              key={item.label}
                              name={item.name}
                            >
                              <PercentageInput
                                disabled={activeInput(item)}
                                placeholder={item.label}
                              />
                            </Form.Item>
                          </Col>

                          {item.label.endsWith('marktplace') ? <Col className="w-100" /> : <></>}
                        </Fragment>
                      ))}
                    </Row>
                  </div>
                </Fragment>
              )
            })}

            {(PricingModel.canSeeStatesSelect(user.level)) && (
              <Fragment>
                <Row>
                  <Col lg={24} md={24} sm={24} xs={24} className="px-1 mt-3">
                    <Form.Item
                      name="uf"
                      label={<label>UF</label>}
                      help={errors.uf && errors.uf}
                      validateStatus={errors.uf && 'error'}
                      rules={[{ required: true, message: 'Escolha o UF' }]}
                    >
                      <Select
                        allowClear
                        placeholder="Escolha o estado."
                        mode="multiple"
                        disabled={!PricingModel.canChange(user.level)}
                        optionFilterProp="children"
                        onChange={(items: Array<string>) => {
                          if (formRef && formRef?.current) {
                            formRef.current.setFieldsValue({ uf: items })
                          }

                          if (items.includes('all')) {
                            const allValues = states.map(item => item.value)
                            formRef.current.setFieldsValue({ uf: allValues })
                          }
                        }}
                      >
                        <Select.Option key="all" value="all">Selecionar todos</Select.Option>
                        {states.map(item => (
                          <Fragment key={item.value}>
                            <Select.Option value={item.value}>{item.label}</Select.Option>
                          </Fragment>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Fragment>
            )}

            <Button
              type="primary"
              htmlType="submit"
              className="ml-1 mr-1 mt-3"
              style={{ minWidth: 150 }}
              loading={loading}
              disabled={PricingModel.cantSubmit(user.level)}
            >
              {PricingModel.canChange(user.level) ? 'Criar' : 'Atualizar'}
            </Button>
          </Form>
        </div>
      )}
    </Fragment>
  )
}

export default PricingCreate

import React from 'react'
import Icons from 'components/Icons/Icons'
import { colors } from 'styles/colors'
import { _proposalStatuses, _proposalStatusesAndHistory } from 'egi/app/ProposalEgi/proposalEgiInterfaces'

interface ICaption {
  name: _proposalStatuses
  size?: number
  text: string
}

export const CaptionIcon = ({ name, size = 20 }: Omit<ICaption, 'text'>) => (
  <Icons
    name={name}
    color={colors[name]}
    size={size}
  />
)

const Caption = ({ text, ...rest }: ICaption) => (
  <div className="caption__container">
    <CaptionIcon {...rest} />
    <h3 className="caption__text">{text}</h3>
  </div>
)

type _captionsProducts = 'ecp' | 'egi' | 'ecpf'

export const CAPTIONS_TRANSLATED: {
  [key in _captionsProducts]: {
    [key in _proposalStatuses]?: string
  }
} = {
  ecp: {
    starting: 'Pendente',
    inProgress: 'Em andamento',
    approved: 'Aprovado',
    remaking: 'Pendências',
    reproved: 'Recusado'
  },
  egi: {
    starting: 'Iniciando',
    analysis: 'Em Análise',
    approved: 'Aprovada',
    remaking: 'Refazer',
    reproved: 'Reprovada'
  },
  ecpf: {
    starting: 'Iniciando',
    analysis: 'Em Análise',
    approved: 'Aprovada',
    remaking: 'Refazer',
    reproved: 'Reprovada'
  }
}

export const CAPTIONS_TRANSLATED_HISTORY: {
  [key in _captionsProducts]: {
    [key in _proposalStatusesAndHistory]?: string
  }
} = {
  ...CAPTIONS_TRANSLATED,
  ecp: {
    ...CAPTIONS_TRANSLATED.ecp,
    approvedManually: 'Aprovado man.',
    approvedWithRestriction: 'Aprovado res.',
    approvedAutomatically: 'Aprovado aut.'
  }
}

const CAPTIONS_RESOURCE = (product: _captionsProducts) => Object.entries(CAPTIONS_TRANSLATED[product]).map(([key, value]) => ({
  name: key as _proposalStatuses,
  text: value as string
}))

const Captions = ({ product }: { product: _captionsProducts }) => {
  return (
    <div className="caption">
      {CAPTIONS_RESOURCE(product)
        .map(caption => <Caption key={caption.text} {...caption} />)}
    </div>
  )
}

export default Captions

import React, { Fragment, useState, useLayoutEffect, useEffect } from 'react'
import { CustomSteps } from 'components'
import { IFormContractProps } from './formContractInterfaces'
import { useResources } from 'hooks'

import ContractSelectStep from './FormContractSteps/ContractSelectStep'
import TitularStep from './FormContractSteps/TitularStep'
import CompositorStep from './FormContractSteps/CompositorStep/CompositorStep'
import IQStep from './FormContractSteps/IQStep'
import OperacaoStep from './FormContractSteps/OperacaoStep'
import SeguradoStep from './FormContractSteps/SeguradoStep'
import CNDStep from './FormContractSteps/CNDStep'
import MinutaPreviewStep from './FormContractSteps/MinutaStep/MinutaPreviewStep'
import { useDispatch } from 'react-redux'
import { resourcesGetQualifications } from 'store/modules/resources/actions'

const FormContract = ({ callbackProposal, readOnlyInput }: IFormContractProps) => {
  const [counterStep, setCounterStep] = useState<number>(0)

  const resources = useResources()
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    if (resources.minutaMold.length < 1) dispatch(resourcesGetQualifications({ productId: resources.products[0]._id }))
  }, [])

  useEffect(() => {
    function scrollToTop () {
      const antDrawerBody = document.querySelector('.ant-drawer-body')
      if (antDrawerBody) antDrawerBody.scrollTop = 0
    }

    scrollToTop()
  }, [counterStep])

  return (
    <Fragment>
      <div>
        <CustomSteps
          counterStep={counterStep}
          setCounterStep={setCounterStep}
          quantity={8}
          enableAfter={true}
          type="navigation"
        />
      </div>

      {counterStep === 0 && (
        <ContractSelectStep
          readOnly={readOnlyInput}
          onSuccess={(value) => {
            if (value) setCounterStep(1)
          }}
        />
      )}

      {counterStep === 1 && (
        <TitularStep
          readOnly={readOnlyInput}
          onSuccess={(value) => {
            if (value) setCounterStep(2)
          }}
        />
      )}

      {counterStep === 2 && (
        <CompositorStep
          readOnly={readOnlyInput}
          onSuccess={(value) => {
            if (value) setCounterStep(3)
          }}
        />
      )}

      {counterStep === 3 && (
        <IQStep
          readOnly={readOnlyInput}
          onSuccess={(value) => {
            if (value) setCounterStep(4)
          }}
        />
      )}

      {counterStep === 4 && (
        <OperacaoStep
          readOnly={readOnlyInput}
          onSuccess={(value) => {
            if (value) setCounterStep(5)
          }}
        />
      )}

      {counterStep === 5 && (
        <SeguradoStep
          readOnly={readOnlyInput}
          onSuccess={(value) => {
            if (value) setCounterStep(6)
          }}
        />
      )}

      {counterStep === 6 && (
        <CNDStep
          readOnly={readOnlyInput}
          onSuccess={(value) => {
            if (value) setCounterStep(7)
          }}
        />
      )}

      {counterStep === 7 && (
        <MinutaPreviewStep
          readOnly={readOnlyInput}
          callbackProposal={callbackProposal}
        />
      )}
    </Fragment>
  )
}

export default FormContract

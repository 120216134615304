import { Actions, SystemState } from './types'

const initialState: SystemState = {
  corban: {
    id: '',
    slug: '',
    name: '',
    image: '',
    description: '',
    clientUrl: ''
  },
  route: {
    path: '',
    name: '',
    meta: {
      hidden: false,
      canGoBack: false,
      centered: false,
      disabled: false,
      hiddenTitle: false
    }
  },
  historyRoutes: [],
  followUpProposal: false
}

const system = (state = initialState, action: Actions) => {
  switch (action.type) {
    case 'SET_SYSTEM_CONFIG':
      return {
        ...state,
        corban: action.payload.corban
      }

    case 'SET_ROUTE_CONFIG': {
      return {
        ...state,
        route: (action.payload as any).lastRoute,
        historyRoutes: (action.payload as any).historyRoutes
      }
    }

    case 'RESET_ROUTE_CONFIG' : {
      return {
        ...state,
        route: {},
        historyRoutes: []
      }
    }

    case 'SET_ROUTE_NAME_CONFIG': {
      return {
        ...state,
        route: {
          ...state.route,
          name: action.payload
        }
      }
    }

    case 'SET_IS_TABLE_ROUTE_CONFIG': {
      const lenght = (state.historyRoutes.length)
      state.historyRoutes[lenght - 1].isTable = action.payload
      state.route.isTable = action.payload
      return {
        ...state
      }
    }

    case 'FOLLOW_UP_PROPOSAL' : {
      return {
        ...state,
        followUpProposal: action.payload
      }
    }

    default:
      return state
  }
}

export default system

import React, { useEffect } from 'react'
import { Spin } from 'antd'
import { ProductsCardsList } from 'components/SelectProductsSidebar/components/ProductDropdown/ProductDropdown'
import { useAuth, useResources } from 'hooks'
import useSelectedProducts from 'components/SelectProductsSidebar/hooks/useSelectedProducts'
import { useDispatch } from 'react-redux'
import { resourcesGetProducts } from 'store/modules/resources/actions'

import { PoweroffOutlined } from '@ant-design/icons'
import DynamicColorButton from 'ecp/components/DynamicColorButton/DynamicColorButton'

type Props = {
  logo: string
  onExit: () => void
  onTimeout: () => void
}

export function FullScreenPageLogo ({ logo }: { logo: string }) {
  return (
    <img
      src={logo}
      className='full-screen-loading__img'
      alt='Logo da aplicação line'
    />
  )
}

export function FullScreenProducts () {
  const resources = useResources()
  const user = useAuth()
  const dispatch = useDispatch()

  const { loading, onProductSelect } = useSelectedProducts()
  const { _id: productId } = user.selectedProduct

  useEffect(() => {
    if (resources.products.length < 1) dispatch(resourcesGetProducts())
  }, [])

  if (loading || !resources.products || !productId) return <Spin />

  return (
    <ProductsCardsList
      theme='primary'
      list={resources.products}
      onItemSelect={(id, products) => onProductSelect(id, products, user)}
      selectedId={productId}
    />
  )
}

function FullScreenLoadingProducts () {
  return (
    <div className='full-screen-loading__card mb-6'>
      <h1 className='full-screen-loading__sub-title'>Seus produtos</h1>
      <FullScreenProducts />
    </div>
  )
}

function FullScreenLoadingWrapper ({ children }: { children: React.ReactNode }) {
  return (
    <div className='full-screen-loading'>
      {children}
    </div>
  )
}

function FullScreenLogoContent ({ logo }: { logo: Props['logo'] }) {
  return (
    <div className='full-screen-loading__logo-content'>
      <FullScreenPageLogo logo={logo} />

      <div>
        <Spin />
      </div>

      <p className='full-screen-loading__description'>Estamos preparando tudo para você...</p>
    </div>
  )
}

function FullScreenLoading ({ logo, onTimeout, onExit }: Props) {
  useEffect(() => {
    const timeout = setTimeout(() => {
      onTimeout()
    }, 35000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <FullScreenLoadingWrapper>
      <FullScreenLogoContent logo={logo} />

      <FullScreenLoadingProducts />

      <DynamicColorButton
        colorClass='button-red'
        type='ghost'
        icon={<PoweroffOutlined />}
        onClick={onExit}
      >
        Sair do sistema
      </DynamicColorButton>
    </FullScreenLoadingWrapper>
  )
}

FullScreenLoading.Wrapper = FullScreenLoadingWrapper
FullScreenLoading.LogoContent = FullScreenLogoContent
FullScreenLoading.Products = FullScreenLoadingProducts

export default FullScreenLoading

import { message } from 'antd'
import { IErrorDetails } from 'components/ErrorDetails/interfaces'
import { swalError } from 'components/SwalError/SwalError'
import ClientPendingRepository, { ILGPDEndorse } from 'ecp/repositories/ClientPendingRepository'
import { useLayoutEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import swal from 'utils/swal'
import { IClientPending } from '../clientInterfaces'

export function getCLientLGPD (clientId : string) {
  const [loadingGetClient, setLoadingGetClient] = useState<boolean>(false)
  const [errorGetClient, setErrorGetClient] = useState<IErrorDetails>()
  const [clientLGPD, setClientLGPD] = useState<IClientPending>()

  useLayoutEffect(() => {
    async function getClientData (clientId: string) {
      setLoadingGetClient(true)
      try {
        const response = await ClientPendingRepository.findPending(clientId)
        const client = response?.data?.data
        if (!client) throw new Error('Não foi possível obter os dados do cliente.')
        setClientLGPD(client)
      } catch (err) {
        setErrorGetClient(err)
      } finally {
        setLoadingGetClient(false)
      }
    }

    if (clientId) getClientData(clientId)
  }, [clientId])

  return ({
    errorGetClient,
    loadingGetClient,
    clientLGPD
  })
}

export const useSubmitEndorsement = () => {
  const history = useHistory()

  const [approveLoading, setApproveLoading] = useState(false)
  const [reproveLoading, setReproveLoading] = useState(false)

  const modalText = (endorse: ILGPDEndorse) => `Tem certeza que deseja ${endorse === 'approve' ? 'Aprovar funcionário' : 'Remover funcionário'}?`

  const onChangeLoadingState = (loading: boolean, endorse: ILGPDEndorse) => {
    if (endorse === 'approve') {
      setApproveLoading(loading)
    } else {
      setReproveLoading(loading)
    }
  }

  async function onEndorseClient (clientId: string, endorse: ILGPDEndorse) {
    swal.confirmNegate({
      title: 'Atenção',
      text: modalText(endorse),
      icon: 'warning',
      confirm: async () => {
        onChangeLoadingState(true, endorse)
        try {
          const response = await ClientPendingRepository.endorseLGDPCLient({ clientId, endorse })
          if (response.data.message) message.success(response.data.message)
          history.goBack()
        } catch (err) {
          swalError({
            title: 'Atenção',
            err,
            icon: 'warning'
          })
        } finally {
          onChangeLoadingState(false, endorse)
        }
      }
    })
  }

  async function onEndorseOnidataClient (clientId: string, endorse: ILGPDEndorse, userFuncaoId: string) {
    swal.confirmNegate({
      title: 'Atenção',
      text: modalText(endorse),
      icon: 'warning',
      confirm: async () => {
        onChangeLoadingState(true, endorse)
        try {
          const response = await ClientPendingRepository.endorseLGDPCLientOnidata({ clientId, endorse, userFuncaoId })
          if (response.data.message) message.success(response.data.message)
          history.goBack()
        } catch (err) {
          swalError({
            title: 'Atenção',
            err,
            icon: 'warning'
          })
        } finally {
          onChangeLoadingState(false, endorse)
        }
      }
    })
  }

  return {
    onEndorseClient,
    approveLoading,
    reproveLoading,
    onEndorseOnidataClient
  }
}

import { _amortizationTypeEnum, _cndAnalysisStatus, _cndRequestStatus, _preApprovalStatus } from 'globals'
import { PixTypes } from 'egi/types/IBank'
import format from './format'
import { _proposalStatuses } from 'egi/app/ProposalEgi/proposalEgiInterfaces'
import { _productSlugs } from 'ecp/models/ProductModel'
import { _reviewStatusOption } from 'components/ReviewBackoffice/reviewBackofficeInterfaces'
import { ProposalType, ProposalECPFModality, EscolaridadeEnum, SexoDadoCadastralECPF, duePaymentStatusEnum } from 'ecpf/app/ProposalECPF/ProposalECPFInterfaces'

export type _proposalStatusTranslate = _proposalStatuses | 'withoutProposal' | 'ongoing' | 'canceled' | 'waiting' | 'awaiting' | _reviewStatusOption

export interface ITranslate {
  proposalStatus (status: _proposalStatusTranslate): string
  preApproval (value: _preApprovalStatus): string
  reverseProposalStatus (value: string): string
  proposalAnalysis (value?: string): string
  gender (value: string): string
  level (value: string): string
  pocValuesDeadlines (value: string): string
  subProducts (value: string): string
  promoterType (value: string): string
  creditApprovalStatus (value: string): string
  leadStatus (value: string): string
  userStatus (value: string): string
  partnersStatus (value: string): string
  pixTypes (value: PixTypes): string
  areasNames (value?: string): string
  goals (value: string): string
  maritalStatus (value?: string): string
  maritalRegime (value?: string): string
  employmentType (value: string): string
  residenceType (value?: string): string
  ownership (value?: string): string
  cndTagStatus (value: _cndAnalysisStatus): string
  cndRequestStatus (value: _cndRequestStatus): string
  cndMoreInfoStatus (value: string): string
  personType (value: string): string
  minutaMold (value: string): string
  translatePerson (value: string): string
  slugifyPerson (value: string): string
  correcaoMonetaria (value?: string): string
  accountType (type: string): string
  stepProviderTexts (value?: 'engenharia' | 'juridico' | ''): string
  modes (value: 'term' | 'document'): string
  ecpProposalStatus (value: string): string
  ecpProposalsOrigen (value: string): string
  CovenantPaymentDay (day: number): string
  lgpdType (type: string): string
  amortizationType (type: _amortizationTypeEnum): string
  productSlug (type: _productSlugs): string
  productSlugToUsualName (type: _productSlugs): string
  reportsType (type: string): string
  reportsStatus (status: string): string
  DownloadStatus (status: string): string
  lotsDiscountsReasons (value: string): string
  logConfig (label: string): string
  yesNo (value: boolean): string
  clientConfigurationSlug (value: string): string
  onidataQueuesType (value: string): string
  device (value: string): string
  simulatorFields (value: string): string
  documentalReportFields (value: string): string
  departmentStatus (status: _proposalStatuses): string
  lastArea (lastArea: string): string
  sortConfigurationProperty (key: string): string
  sortConfigurationOrder (key: string): string
  ecpfProduct (proposalType: ProposalType): string
  ecpfProposalModality (modality?: ProposalECPFModality): string
  escolaridade (value?: EscolaridadeEnum): string
  sexoDadoCadastral (value?: SexoDadoCadastralECPF): string
  duePaymentStatus (value?: duePaymentStatusEnum): string
  productSlugToSigla (type: _productSlugs): string
}

export type ITranslateKeys = keyof ITranslate

const translate: ITranslate = {
  proposalStatus: (status: _proposalStatusTranslate) => {
    if (!status) return ''
    switch (status) {
      case 'analysis':
        return 'Analisando'
      case 'approved':
        return 'Aprovado'
      case 'reservation':
      case 'approvedWithRestriction':
        return 'Aprovado com ressalvas'
      case 'remaking':
        return 'Refazer'
      case 'reproved':
        return 'Reprovado'
      case 'starting':
        return 'Iniciando'
      case 'withoutProposal':
        return 'Sem proposta'
      case 'ongoing':
        return 'Em andamento'
      case 'waiting':
      case 'awaiting':
        return 'Aguardando'
      case 'canceled':
        return 'Cancelado'
      case 'inactive':
        return 'Inativo'
      case 'effectivated':
        return 'Efetivada'
      case 'approvedManually':
        return 'Aprovadas Manualmente'
      case 'approvedAutomatically':
        return 'Aprovada Automaticamente'
      default:
        return status
    }
  },
  preApproval: (value: _preApprovalStatus) => {
    if (!value) return '-'

    switch (value) {
      case 'analysis':
        return 'Mesa/humano'
      case 'approved':
        return 'Aprovado'
      case 'ongoing':
        return 'Processando'
      case 'reproved':
        return 'Reprovado'
      default:
        return '-'
    }
  },

  reverseProposalStatus: (value: string) => {
    if (!value) return ''
    switch (value.toLowerCase()) {
      case 'analisando':
        return 'analysis'
      case 'aprovado':
      case 'ativo':
        return 'approved'
      case 'refazer':
        return 'remaking'
      case 'reprovado':
      case 'desligado':
        return 'reproved'
      case 'iniciando':
        return 'starting'
      case 'sem proposta':
        return 'withoutProposal'
      case 'em andamento':
        return 'ongoing'
      default:
        return value
    }
  },

  proposalAnalysis: (value: string) => {
    if (!value) return '-'

    switch (value) {
      case 'starting':
        return 'Iniciando'
      case 'analysis':
        return 'Análise'
      case 'review':
        return 'Reanálise'
      default:
        return '-'
    }
  },

  gender: (value: string): string => {
    if (!value) return ''
    switch (value) {
      case 'male':
        return 'Masculino'
      case 'female':
        return 'Feminino'
      default:
        return value
    }
  },

  level: (value: string): string => {
    if (!value) return ''

    switch (value) {
      case 'administrator':
        return 'Administrador'
      case 'operator':
        return 'Operador'
      case 'backoffice':
        return 'Backoffice'
      case 'client':
        return 'Cliente'
      case 'master':
        return 'Master'
      case 'promoter':
        return 'Parceiro'
      case 'commission':
        return 'Comissão'
      case 'pricing':
        return 'Precificação'
      case 'commercial':
        return 'Suporte comercial'
      case 'counsel':
        return 'Assessoria comercial'
      case 'consultant':
        return 'Consultor comercial'
      case 'provider':
        return 'Prestador'
      case 'seller':
        return 'Vendedor'
      case 'collaborators':
        return 'Colaborador'
      case 'auditor':
        return 'Auditor'
      case 'agx':
        return 'AGX'
      case 'creditAnalyst':
        return 'Analista de crédito'
      case 'operatorFuncao':
        return 'Operador'
      case 'corbanFuncao':
        return 'Parceiro'
      default:
        return value
    }
  },

  pocValuesDeadlines: (value: string) => {
    if (!value) return ''

    switch (value) {
      case 'value':
        return 'Valor'
      case 'deadline':
        return 'Prazo'
      default:
        return value
    }
  },

  subProducts: (value: string) => {
    if (!value) return ''

    switch (value) {
      case 'home':
        return 'Casa'
      case 'apartment':
        return 'Apartamento'
      case 'land':
        return 'Terreno'
      case 'countryhouse':
        return 'Chácara'
      case 'commercial':
        return 'Comércio'
      default:
        return value
    }
  },

  promoterType: (value: string) => {
    if (!value) return ''

    switch (value) {
      case 'TM':
        return 'Telemarketing'
      case 'CB':
        return 'Corban'
      case 'IN':
        return 'Indicação'
      case 'MP':
        return 'Marketplace'
      default:
        return value
    }
  },

  creditApprovalStatus: (value: string) => {
    if (!value) return ''

    switch (value) {
      case 'approved':
        return 'Aceito'
      case 'reproved':
        return 'Não aceito'
      case 'waiting':
        return 'Aguardando'
      case 'remaking':
        return 'refazer'
      default:
        return ''
    }
  },

  leadStatus: (value: string): string => {
    if (!value) return ''

    switch (value) {
      case 'failed':
        return 'Não atendeu'
      case 'awaiting':
        return 'Aguardando atendimento'
      case 'ongoing':
        return 'Em andamento'
      case 'refused':
        return 'Declinou processo'
      case 'approved':
        return 'Seguiu para proposta'
      default:
        return value
    }
  },

  userStatus: (value: string): string => {
    if (!value) return ''

    switch (value) {
      case 'starting':
        return 'Pendente'
      case 'remaking':
        return 'Refazer'
      case 'refused':
      case 'reproved':
        return 'Reprovado'
      case 'approved':
        return 'Aprovado'
      case 'ongoing':
        return 'Em andamento'
      case 'analysis':
        return 'Analisando'
      case 'track':
        return 'Esteira'
      default:
        return value
    }
  },

  partnersStatus: (value: string): string => {
    if (!value) return ''

    switch (value) {
      case 'starting':
        return 'Iniciando'
      case 'analysis':
        return 'Em análise'
      case 'pendencies':
        return 'Com pendências'
      case 'signature':
        return 'Processo de Assinatura'
      case 'approved':
        return 'Aprovado'
      case 'reproved':
        return 'Reprovado'
      case 'blocked':
        return 'Bloqueado'
      case 'canceled':
        return 'Cancelado'
      case 'remaking':
        return 'Refazer'
      case 'ongoing':
        return 'Em andamento'
      case 'track':
        return 'Esteira'
      default:
        return value
    }
  },

  pixTypes: (value: PixTypes): string => {
    if (!value) return ''

    switch (value) {
      case 'cpf':
        return 'CPF'
      case 'cnpj':
        return 'CNPJ'
      case 'randomKey':
        return 'Chave aleatória'
      case 'email':
        return 'E-mail'
      case 'cell':
        return 'Celular'
      default:
        return value
    }
  },

  areasNames (value?: string): string {
    if (!value) return ''

    switch (value) {
      case 'credito':
        return 'Crédito'
      case 'juridico':
        return 'Jurídico'
      case 'engenharia':
        return 'Engenharia'
      case 'telemarketing':
        return 'Telemarketing'
      case 'avaliacao':
        return 'Avaliação'
      case 'comercial':
      case 'commercial':
        return 'Suporte comercial'
      case 'documentacao':
        return 'Documentação'
      default:
        return value
    }
  },

  goals: (value: string) => {
    if (!value) return ''

    switch (value) {
      case 'quitardividas':
        return 'Quitar dívidas'
      case 'portabilidade':
        return 'Portabilidade'
      case 'lazer':
        return 'Viagem/lazer'
      case 'construcao':
        return 'Construção ou reforma'
      case 'investimento':
        return 'Investimento'
      case 'aberturadeempresa':
        return 'Abrir empresa/franquia'
      case 'capitaldegiro':
        return 'Capital de giro para minha empresa'
      case 'aquisicaodebens':
        return 'Aquisição de bens'
      case 'outro':
        return 'Outro motivo'
      case 'financiamento':
        return 'Financiar meus estudos'
      case 'imprevisto':
        return 'Imprevisto com coronavírus'
      default:
        return '-'
    }
  },

  maritalStatus: (value?: string) => {
    if (!value) return ''

    switch (value) {
      case 'SOLTEIRO':
        return 'Solteiro(a)'
      case 'CASADO':
        return 'Casado(a)'
      case 'DIVORCIADO':
        return 'Divorciado(a)'
      case 'SEPARADO':
        return 'Separado(a)'
      case 'VIUVO':
        return 'Viúvo(a)'
      case 'DESQUITADO':
        return 'Desquitado(a)'
      default:
        return 'n/a'
    }
  },

  maritalRegime: (value?: string) => {
    if (!value) return ''

    switch (value) {
      case 'COMUNHAOPARCIAL':
        return 'Comunhão parcial'
      case 'COMUNHAOUNIVERSAL':
        return 'Comunhão universal'
      case 'SEPARACAOTOTALDEBENS':
        return 'Separação total de bens'
      case 'PARTICIPACAOFINALDOSAQUESTOS':
        return 'Separação final dos aquestos'
      case 'SEPARACAOOBRIGATORIADEBENS':
        return 'Separação obrigatória de bens'
      default:
        return 'n/a'
    }
  },

  employmentType: (value: string) => {
    if (!value) return ''

    switch (value) {
      case 'assalariado':
        return 'Assalariado(a)'
      case 'funcionarioPublico':
        return 'Funcionário(a) Público(a)'
      case 'aposentado':
        return 'Aposentado(a)'
      case 'liberal':
        return 'Profissional Liberal'
      case 'autonomo':
        return 'Autônomo(a)'
      case 'empresario':
        return 'Empresário(a)'
      case 'rural':
        return 'Produtor(a) Rural'
      default:
        return 'n/a'
    }
  },

  residenceType: (value?: string) => {
    switch (value) {
      case 'owner':
        return 'Própria'
      case 'rented':
        return 'Alugada'
      default:
        return 'n/a'
    }
  },

  ownership: (value?: string) => {
    switch (value) {
      case 'PROPRIA':
        return 'Própria'
      case 'DETERCEIROS':
        return 'De terceiro'
      default:
        return 'n/a'
    }
  },

  cndTagStatus: (value: _cndAnalysisStatus) => {
    if (!value) return ''

    switch (value) {
      case 'APPROVED':
        return 'Aprovado'
      case 'IN_ATTENTION':
        return 'Atenção'
      case 'REPROVED':
        return 'Reprovado'
      case 'CERTIFICATE_GENERATION_INFO':
        return 'Informações de geração de certificado'
      case 'NO_DATA_FOUND_INFO':
        return 'Nenhuma informação encontrada'
      default:
        return value
    }
  },

  cndRequestStatus: (value: _cndRequestStatus) => {
    if (!value) return ''

    switch (value) {
      case 'ERROR':
        return 'Erro'
      case 'PENDING':
        return 'Pendente'
      case 'PROCESSED':
        return 'Processado'
      case 'PROCESSING':
        return 'Processando'
      default:
        return value
    }
  },

  cndMoreInfoStatus: (value: string) => {
    if (!value) return ''

    switch (value) {
      case 'CERTIFICATE_GENERATION_INFO':
        return 'Informações de geração de certificado'
      case 'NO_DATA_FOUND_INFO':
        return 'Nenhuma informação encontrada'
      default:
        return value
    }
  },

  personType: (value: string) => {
    if (!value) return '-'

    switch (value) {
      case 'fisica':
        return 'Física'
      case 'juridica':
        return 'Jurídica'
      default:
        return value
    }
  },

  minutaMold: (value: string) => {
    if (!value) return '-'

    switch (value) {
      case 'nome':
      case 'email':
      case 'nacionalidade':
      case 'identidade':
      case 'logradouro':
      case 'bairro':
        return format.capitalize(value)
      case 'numero_residencial':
        return 'Numero_Residencial'
      case 'complemento_residencial':
        return 'Complemento_Residencial'
      case 'estado_civil':
        return 'Estado_Civil'
      case 'data_do_casamento':
        return 'Data_Casamento'
      case 'data_de_nascimento':
        return 'Data_Nascimento'
      case 'municipio':
        return 'Municipio'
      case 'filiacao':
        return 'Mãe'
      case 'profissao':
        return 'Profissão'
      case 'pais':
        return 'País'
      case 'filiacao_pai':
        return 'Pai'
      case 'nome_conjuge':
        return 'Nome_Conjuge'
      case 'email_conjuge':
        return 'Email_Conjuge'
      case 'data_de_nascimento_conjuge':
        return 'Data_Nascimento_Conjuge'
      case 'nacionalidade_conjuge':
        return 'Nacionalidade_Conjuge'
      case 'filiacao_conjuge':
        return 'Mãe_Conjuge'
      case 'filiacao_pai_conjuge':
        return 'Pai_Conjuge'
      case 'profissao_conjuge':
        return 'Profissao_Conjuge'
      case 'identidade_conjuge':
        return 'Identidade_Conjuge'
      case 'CPF_conjuge':
        return 'CPF_Conjuge'
      case 'estado_civil_conjuge':
        return 'Estado_Civil_Conjuge'
      case 'expedidor':
        return 'Orgao_Expedidor'
      case 'expedidor_conjuge':
        return 'Orgao_Expedidor_Conjuge'
      case 'local_expedicao':
        return 'Orgao_Expedidor_UF'
      case 'local_expedicao_conjuge':
        return 'Orgao_Expedidor_UF_Conjuge'
      default:
        return value
    }
  },

  translatePerson: (value: string) => {
    if (!value) return '-'

    switch (value) {
      case 'titular': return 'Titular'
      case 'conjuge': return 'Cônjuge do(a) Titular'
      case 'a': return 'Pessoa 1'
      case 'conjuge-a': return 'Cônjuge da Pessoa 1'
      case 'b': return 'Pessoa 2'
      case 'conjuge-b': return 'Cônjuge da Pessoa 2'
      case 'socio-a': return 'Sócio 1'
      case 'conjuge-socio-a': return 'Cônjuge do Sócio 1'
      case 'socio-b': return 'Sócio 2'
      case 'conjuge-socio-b': return 'Cônjuge do Sócio 2'
      case 'socio-c': return 'Sócio 3'
      case 'conjuge-socio-c': return 'Cônjuge do Sócio 3'
      case 'socio-d': return 'Sócio 4'
      case 'conjuge-socio-d': return 'Cônjuge do Sócio 4'
      case 'terceiro': return 'Terceiro'
      case 'terceiro-b': return 'Terceiro 2'
      case 'terceiro-c': return 'Terceiro 3'
      case 'terceiro-d': return 'Terceiro 4'
      case 'conjuge-terceiro': return 'Cônjuge do Terceiro'
      case 'conjuge-terceiro-b': return 'Cônjuge do Terceiro 2'
      case 'conjuge-terceiro-c': return 'Cônjuge do Terceiro 3'
      case 'conjuge-terceiro-d': return 'Cônjuge do Terceiro 4'
      case 'proprietario-a': return 'Proprietário 1'
      case 'conjuge-proprietario-a': return 'Cônjuge do Proprietário 1'
      case 'proprietario-b': return 'Proprietário 2'
      case 'conjuge-proprietario-b': return 'Cônjuge do Proprietário 2'
      case 'proprietario-c': return 'Proprietário 3'
      case 'conjuge-proprietario-c': return 'Cônjuge do Proprietário 3'
      case 'proprietario-d': return 'Proprietário 4'
      case 'conjuge-proprietario-d': return 'Cônjuge do Proprietário 4'
      case 'garantidor': return 'Garantidor'
      case 'conjuge-garantidor': return 'Cônjuge do Garantidor'
      default: return value
    }
  },

  slugifyPerson: (value: string) => {
    if (!value) return '-'

    switch (value) {
      case 'a': return 'compositor_pessoa_1'
      case 'b': return 'compositor_pessoa_2'
      case 'socio-a': return 'compositor_socio_1'
      case 'socio-b': return 'compositor_socio_2'
      case 'socio-c': return 'compositor_socio_3'
      case 'socio-d': return 'compositor_socio_4'
      case 'terceiro': return 'compositor_terceiro'
      case 'terceiro-b': return 'compositor_terceiro_2'
      case 'terceiro-c': return 'compositor_terceiro_3'
      case 'terceiro-d': return 'compositor_terceiro_4'
      case 'proprietario-a': return 'compositor_proprietario_1'
      case 'proprietario-b': return 'compositor_proprietario_2'
      case 'proprietario-c': return 'compositor_proprietario_3'
      case 'proprietario-d': return 'compositor_proprietario_4'
      default: return value
    }
  },

  correcaoMonetaria: (value?: string) => {
    if (!value) return ''

    switch (value) {
      case 'FLATRATE':
        return 'PRÉ-FIXADO'
      default:
        return value
    }
  },

  accountType: (type: string) => {
    switch (type) {
      case 'poupanca':
        return 'Poupança'
      case 'corrente':
        return 'Corrente'
      default:
        return '-'
    }
  },

  stepProviderTexts (value?: 'engenharia' | 'juridico' | ''): string {
    if (!value) return ''

    switch (value) {
      case 'engenharia':
        return 'Empresa de avaliação'
      case 'juridico':
        return 'Prestador jurídico'
      default:
        return value
    }
  },

  modes (value: 'term' | 'document'): string {
    return {
      term: 'Assinatura',
      document: 'Documento'
    }[value] || value
  },

  ecpProposalStatus: (value: string) => {
    if (!value) return '-'

    switch (value) {
      case 'aguardando-requisitos':
      case 'aguardando':
        return 'analysis'
      case 'averbado': return 'approved'
      case 'negada': return 'reproved'

      default: return value
    }
  },

  ecpProposalsOrigen: (value: string) => {
    if (!value) return '-'

    switch (value) {
      case 'novo-contrato': return 'Novo Contrato'
      case 'portabilidade': return 'Portabilidade'
      case 'renegociacao': return 'Renegociação'
      default: return value
    }
  },

  CovenantPaymentDay: (day: number) => {
    if (!day) return '-'

    switch (day) {
      case 1: return '1º dia Útil'
      case 2: return '2º dia Útil'
      case 3: return '3º dia Útil'
      case 4: return '4º dia Útil'
      case 5: return '5º dia Útil'
      case 30: return 'Penúltimo dia Útil'
      case 31: return 'Último dia Útil'
      default: return String(day)
    }
  },

  lgpdType: (type: string) => {
    switch (type) {
      case 'funcionario': return 'Funcionário'
      default: return type
    }
  },

  amortizationType: (type: _amortizationTypeEnum) => {
    switch (type) {
      case 'PRICE': return 'Price'
      case 'SAC': return 'Tabela SAC'
    }
  },

  productSlug: (type: _productSlugs) => {
    switch (type) {
      case 'ecp':
        return 'Empréstimo Consignado Privado'
      case 'ecp-funcao':
        return 'Empréstimo Consignado Privado (Função)'
      case 'home-equity':
      case 'egi':
        return 'Empréstimo com garantia de imóvel'
      default: return '-'
    }
  },

  productSlugToSigla: (type: _productSlugs) => {
    switch (type) {
      case 'ecp':
        return 'ECP'
      case 'ecp-funcao':
        return 'ECP (Função)'
      case 'home-equity':
      case 'egi':
        return 'EGI'
      default: return '-'
    }
  },

  productSlugToUsualName: (type: _productSlugs) => {
    switch (type) {
      case 'ecp': return 'consignado'
      case 'home-equity':
      case 'egi':
        return 'EGI'
      default: return '-'
    }
  },

  reportsType: (type: string) => {
    switch (type) {
      case 'client': return 'Cliente'
      case 'proposal': return 'Proposta'
      case 'lot': return 'Lote'
      case 'covenant': return 'Convênio'
      default: return type
    }
  },

  reportsStatus: (status: string) => {
    switch (status) {
      case 'finish': return 'Finalizado'
      case 'awaiting': return 'Aguardando'
      case 'processing': return 'Em andamento'
      case 'error': return 'Erro'
      default: return status
    }
  },

  DownloadStatus: (status: string) => {
    switch (status) {
      case 'finished': return 'Finalizado'
      case 'awaiting': return 'Aguardando'
      case 'processing': return 'Em andamento'
      case 'error': return 'Erro'
      default: return status
    }
  },

  lotsDiscountsReasons: (value: string) => {
    switch (value) {
      case 'sem-divergencia': return 'Sem divergência'
      case 'afastado': return 'Afastado'
      case 'demissao': return 'Demissão'
      case 'ferias': return 'Férias'
      case 'invalidez': return 'Invalidez'
      case 'fraude': return 'Fraude'
      case 'obito': return 'Óbito'
      case 'outros': return 'Outros'
      case 'pensao-alimenticia': return 'Pensão Alimentícia'
      default: return value
    }
  },

  logConfig: (label: string) => {
    switch (label) {
      case 'port': return 'Porta'
      case 'host': return 'Host'
      case 'from': return 'Nome Remetente'
      case 'user': return 'Usuário'
      case 'useAltMail': return 'Usar SMTP Rodobens'
      case 'tls': return 'TLS'
      case 'secure': return 'Secure'
      case 'pass': return 'Password'
      default: return label
    }
  },

  yesNo: (value: boolean) => {
    if (value) return 'Sim'
    return 'Não'
  },

  clientConfigurationSlug: (value: string) => {
    switch (value) {
      case 'dismissal-reasons': return 'dismissalReasons'
      case 'blocked-reasons': return 'blockedReasons'
      default: return value
    }
  },

  onidataQueuesType: (value: string) => {
    switch (value) {
      case 'proposal': return 'Proposta'
      case 'client': return 'Cliente'
      case 'covenant': return 'Convênio'
      default: return value
    }
  },

  device: (value: string) => {
    switch (value) {
      case 'desktop': return 'Desktop'
      case 'mobile': return 'Mobile'
      case 'smartphone': return 'Smartphone'
      default: return value
    }
  },

  simulatorFields: (value: string) => {
    switch (value) {
      case 'name': return 'Nome'
      case 'email': return 'Email'
      case 'cellphone': return 'Celular ou Telefone'
      case 'zipcode': return 'CEP do Imóvel da garantia'
      case 'terms': return 'Termos de política e privacidade'

      case 'sub': return 'Tipo de imóvel'
      case 'propertyValue': return 'Valor do imóvel'
      case 'outstandingBalance': return 'Valor da dívida'

      case 'birthdate': return 'Data de nascimento'
      case 'foundationDate': return 'Data de fundação'
      case 'income': return 'Renda familiar'
      case 'cnpj': return 'CNPJ'
      case 'cpf': return 'CPF'
      case 'employmentType': return 'Ocupação'
      case 'bacen': return 'Termo de consulta ao SCR'

      case 'resourceGoal': return 'Finalidade do empréstimo'
      case 'creditValue': return 'Valor do empréstimo'

      default: return value
    }
  },

  documentalReportFields: (value: string) => {
    switch (value) {
      case 'name': return 'Nome:'
      case 'email': return 'E-mail:'
      case 'cpf': return 'CPF:'
      case 'cellphone': return 'Celular:'
      case 'address': return 'Endereço:'
      case 'product': return 'Produto:'
      case 'releasedAmount': return 'Valor liberado:'
      case 'financedAmount': return 'Valor financiado:'
      case 'solution': return 'Solução:'
      case 'type': return 'Tipo:'
      case 'status': return 'Status:'
      case 'validated': return 'Validado:'
      default: return '-'
    }
  },

  departmentStatus: (status: _proposalStatuses) => {
    switch (status) {
      case 'starting': return 'Não iniciado'
      case 'approved': return 'Aprovado'
      case 'reproved': return 'Reprovado'
      case 'analysis': return 'Possui pendências'
      default: return 'N/A'
    }
  },

  lastArea: (lastArea: string) => {
    switch (lastArea) {
      case 'documentacao': return 'Documentação'
      case 'credito': return 'Crédito'
      case 'avaliacao': return 'Avaliação'
      case 'juridico': return 'Jurídico'
      default: return '-'
    }
  },

  sortConfigurationProperty: (key: string) => {
    switch (key) {
      case 'createdAt': return 'Data Criação'
      case 'proposalExpiration': return ' Data Expiração'
      case 'updatedAt': return 'Última atualização'
      default: return '-'
    }
  },

  sortConfigurationOrder: (key: string) => {
    switch (key) {
      case '1': return 'Ascendente'
      case '-1': return 'Descendente'
      default: return '-'
    }
  },

  ecpfProduct: (proposalType: ProposalType) => {
    switch (proposalType) {
      case ProposalType.new: return 'Novo Contrato'
      case ProposalType.refinancing: return 'Refinanciamento'
      case ProposalType.renegotiation: return 'Renegociação'
      case ProposalType.portability: return 'Portabilidade'
      default: return ''
    }
  },

  ecpfProposalModality: (modality?: ProposalECPFModality) => {
    switch (modality) {
      case ProposalECPFModality.novoContrato: return 'Novo Contrato'
      case ProposalECPFModality.refinanciamento: return 'Refinanciamento'
      case ProposalECPFModality.portabilidade: return 'Portabilidade'
      case ProposalECPFModality.renegociacao: return 'Renegociação'
      default: return ''
    }
  },

  escolaridade: (value?: EscolaridadeEnum) => {
    if (!value) return ''

    switch (value) {
      case EscolaridadeEnum.NaoDefinido:
        return 'Não definido'
      case EscolaridadeEnum.PrimeiroGrau:
        return 'Primeiro Grau'
      case EscolaridadeEnum.SegundoGrau:
        return 'Segundo Grau'
      case EscolaridadeEnum.Superior:
        return 'Superior'
      case EscolaridadeEnum.SemEscolaridade:
        return 'Sem Escolaridade'
      case EscolaridadeEnum.PosGraduacao:
        return 'Pós-Graduação'
      case EscolaridadeEnum.MestradoDoutorado:
        return 'Mestrado/Doutorado'
      default:
        return value
    }
  },

  sexoDadoCadastral: (value?: SexoDadoCadastralECPF): string => {
    if (!value) return ''

    switch (value) {
      case SexoDadoCadastralECPF.feminino:
        return 'F'
      case SexoDadoCadastralECPF.masculino:
        return 'M'
      default:
        return value
    }
  },

  duePaymentStatus: (value?: duePaymentStatusEnum): string => {
    if (!value) return ''

    switch (value) {
      case duePaymentStatusEnum.naoDefinido:
        return 'Não definido'
      case duePaymentStatusEnum.pago:
        return 'Pago'
      case duePaymentStatusEnum.atrasado:
        return 'Atrasado'
      case duePaymentStatusEnum.pendente:
        return 'Pendente'
      default:
        return value
    }
  }
}

export default translate

export const translateInvalidFields = {
  simulator: (fields: { field: string, message: string }[]) => {
    const translatedFields = fields.map(field => {
      field.field = translate.simulatorFields(field.field)
      return field
    })
    return translatedFields
  }
}

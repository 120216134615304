import { SolutionOutlined } from '@ant-design/icons'
import {
  dashboard,
  proposalsListTwo,
  generateLink,
  profile,
  divulgationPage,
  chat,
  lgpdTerms,
  bacenTerms,
  proposalsListSearch
} from '../routes'

const proposals = {
  name: 'Propostas',
  icon: SolutionOutlined,
  routes: [
    proposalsListSearch,
    proposalsListTwo
  ]
}

const sellerRouter = [
  dashboard,
  proposals,
  generateLink,
  divulgationPage,
  profile,
  chat,
  lgpdTerms,
  bacenTerms
]

export default sellerRouter

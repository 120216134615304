import { Repository } from './Repository'
import api from 'services/api'
import { IUpdateOnidataCredentials } from 'egi/app/Profile/components/UpdateOnidataCredentials/UpdateOnidataCredentials'
import { AxiosRequestConfig } from 'axios'
import { _credentialStatus } from 'store/modules/layout/layoutActionTypes'

class UsersRepository extends Repository<{}> {
  async validatingOnidataCredentials (data: IUpdateOnidataCredentials) {
    return Repository.handle(() =>
      this.api.patch(`${this.path}/credential`, data)
    )
  }

  async isOnidataCredentialWorking (config?: AxiosRequestConfig) {
    return Repository.handle<{ status: _credentialStatus }>(() =>
      this.api.get(`${this.path}/credential`, config)
    )
  }
}

export default new UsersRepository({ api, path: 'ecp/users' })

import React from 'react'
import { Input } from 'antd'
import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons'
import { InputProps } from 'antd/lib/input'

function InputPassword ({ ...rest }: InputProps) {
  return (
    <Input.Password
      {...rest}
      type='password'
      iconRender={visible => (visible ? <EyeFilled /> : <EyeInvisibleFilled />)}
    />
  )
}

export default InputPassword

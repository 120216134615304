import { _reviewStatusOption } from 'components/ReviewBackoffice/reviewBackofficeInterfaces'
import { _userLevel } from 'ecp/models/UsersModel'
import { _tableAreas } from 'globals'

const BackofficeModel = {

  isApproved (status: _reviewStatusOption):boolean {
    return status === 'approved'
  },

  isReproved (status: _reviewStatusOption):boolean {
    return status === 'reproved'
  },

  isReservation (status: _reviewStatusOption):boolean {
    return status === 'reservation'
  },

  isRemaking (status: _reviewStatusOption):boolean {
    return status === 'remaking'
  },

  isAnalysis (status: _reviewStatusOption):boolean {
    return status === 'analysis'
  },

  canInsertAreaReservation (slug: string | undefined, status: _reviewStatusOption): boolean {
    return slug === 'analise-de-credito' && this.isReservation(status)
  },

  canReturnContionalClient (slug: string | undefined, status: _reviewStatusOption): boolean {
    return slug === 'analise-de-credito' && this.isReservation(status)
  },

  remakingAndClientAction (internal: boolean, status: _reviewStatusOption, areaRef: string): boolean {
    return !internal && this.isRemaking(status) && !areaRef
  },

  canInsertProviderJuridico (hasProvider: '' | 'juridico' | 'engenharia' |undefined, areaName: _tableAreas |undefined, userLevel: _userLevel): boolean {
    return hasProvider === 'juridico' && ((areaName === 'juridico' && userLevel !== 'provider') || userLevel === 'master')
  },

  canSeeProviderSelect (hasProvider: '' | 'juridico' | 'engenharia', areaName: _tableAreas |undefined, userLevel: _userLevel, status: _reviewStatusOption) {
    return (this.canInsertProviderJuridico(hasProvider, areaName, userLevel) && this.isAnalysis(status)) || (hasProvider === 'engenharia' && this.isApproved(status))
  },

  canVinculateBackoffice (userLevel: _userLevel) {
    return userLevel !== 'auditor'
  },

  canFetchReproval (userLevel: _userLevel) {
    const levels = ['backoffice', 'provider', 'commercial', 'administrator', 'master']
    return levels.includes(userLevel)
  },

  canFetchAreaNames (userLevel: _userLevel) {
    const levels = ['backoffice', 'master', 'administrator', 'commercial']
    return levels.includes(userLevel)
  },

  isMasterMakingInvite (userLevel: _userLevel) {
    return ['master'].includes(userLevel)
  }
}

export default BackofficeModel

import loadable from '@loadable/component'
import Loading from 'components/Loading/Loading'
import { timeout } from 'promise-timeout'
import React from 'react'
import { TIMEOUT_LOADER } from 'utils/globals'

const OperatorsList = loadable(
  () => timeout(import(
    /* webpackChunkName: "Operators" */
    'ecp/app/Operators/views/OperatorsList/OperatorsList'
  ), TIMEOUT_LOADER),
  {
    fallback: <Loading/>
  }
)

const OperatorForm = loadable(
  () => timeout(import(
    /* webpackChunkName: "Operators" */
    'ecp/app/Operators/views/OperatorForm/OperatorForm'
  ), TIMEOUT_LOADER),
  {
    fallback: <Loading/>
  }
)

const OperatorDetails = loadable(
  () => timeout(import(
    /* webpackChunkName: "Operators" */
    'ecp/app/Operators/views/OperatorDetails/OperatorDetails'
  ), TIMEOUT_LOADER),
  {
    fallback: <Loading/>
  }
)

const TypistListECPF = loadable(
  () => timeout(import(
    /* webpackChunkName: "TypistECPF" */
    'ecpf/app/TypistECPF/views/TypistListECPF/TypistListECPF'
  ), TIMEOUT_LOADER),
  {
    fallback: <Loading/>
  }
)

export { OperatorsList, OperatorForm, OperatorDetails, TypistListECPF }


import { ReloadOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import React from 'react'

function ReRequestButton ({ onClick, fullWidth, ...rest }: { onClick: () => void, fullWidth?: boolean } & ButtonProps) {
  const cssProps = {
    fullWidth: fullWidth ? 'rerequest-button--full-width' : ''
  }
  return (
    <Button {...rest} type="primary" onClick={onClick} className={`rerequest-button ${cssProps.fullWidth} ${rest.className}`}>
      {fullWidth ? 'Atualizar' : null}<ReloadOutlined />
    </Button>
  )
}

export default ReRequestButton

import React, { Fragment, useState, useMemo, useEffect } from 'react'
import { Datepicker, Input, Address, InputMoney } from 'components'
import { Row, Col, Form, Select, Divider, Checkbox } from 'antd'
import locale from 'antd/es/date-picker/locale/pt_BR'
import MaritalFields from './MaritalFields'
import PEPTooltip from '../../../components/PEPTooltip/PEPTooltip'
import CertificateFields from '../../../components/CertificateFields/CertificateFields'
import IAddress from 'egi/types/IAddress'
import { AssociateFormProps, IAssociate } from '../types'

type _documentType = 'rg' | 'rne' | 'cnh' | 'passport' | 'professionalId'

function AssociateForm ({ form, field, readonly, errors }: AssociateFormProps) {
  const [documentType, setDocumentType] = useState<_documentType>()

  const formatDocument = useMemo(() => {
    const formatter: { [key in _documentType]: { placeholder: string, label: string } } = {
      rg: { placeholder: 'Digite o rg', label: 'RG' },
      rne: { placeholder: 'Digite o rne', label: 'RNE' },
      passport: { placeholder: 'Digite o passaporte', label: 'Passaporte' },
      cnh: { placeholder: 'Digite a cnh', label: 'CNH' },
      professionalId: { placeholder: 'Digite a identificação profissional', label: 'Identificiação profissional' }
    }

    return formatter
  }, [documentType])

  function fillFields (address?: IAddress, remainingAddress?: IAddress) {
    const associates: IAssociate[] = form.getFieldValue('associates')
    const chooseAssociate = associates[field.key]
    if (chooseAssociate) chooseAssociate.address = { ...remainingAddress, ...address }

    if (Array.isArray(associates) && associates.length > 0) {
      const mappedAssociations = associates.map((associate: IAssociate, idx: number) => {
        if (idx === field.key) return chooseAssociate

        return associate
      })

      form.setFieldsValue({ associates: mappedAssociations })
    }
  }

  function getAssociateMaritalInformation () {
    const associates: IAssociate[] = form.getFieldValue('associates')
    const chooseAssociate = associates[field.key]
    if (chooseAssociate) return { regime: chooseAssociate.maritalRegime, status: chooseAssociate.maritalStatus }
    return {}
  }

  function getAssociateDocumentType () {
    const associates: IAssociate[] = form.getFieldValue('associates')
    const chooseAssociate = associates[field.key]
    if (chooseAssociate) return chooseAssociate.documentType
    return undefined
  }

  useEffect(() => {
    const associateDocumentType = getAssociateDocumentType()
    if (associateDocumentType) setDocumentType(associateDocumentType as _documentType)
  }, [form, field])

  return (
    <Fragment>
      <Row>
        <Col className="px-1" lg={12} sm={24} xs={24}>
          <Form.Item
            name={[field.name, 'name']}
            label="Nome completo"
            help={errors?.name && (errors.name)}
            validateStatus={errors?.name && ('error')}
          >
            <Input placeholder="Digite o nome completo" readOnly={readonly} />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={12} sm={24} xs={24}>
          <Form.Item
            name={[field.name, 'cpf']}
            label="CPF"
            help={errors?.cpf && (errors.cpf)}
            validateStatus={errors?.cpf && ('error')}
          >
            <Input placeholder="Digite o cpf" mask="cpf" readOnly={readonly} />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={8} sm={24} xs={24}>
          <Form.Item
            name={[field.name, 'documentType']}
            label="Documento de Identificação"
            help={errors?.documentType && (errors.documentType)}
            validateStatus={errors?.documentType && ('error')}
          >
            <Select
              placeholder="Escolha o tipo de documento"
              onChange={(value: _documentType) => setDocumentType(value)}
              disabled={readonly}
              options={[
                { label: 'RG', value: 'rg' },
                { label: 'RNE', value: 'rne' },
                { label: 'CNH', value: 'cnh' },
                { label: 'Passaporte', value: 'passport' },
                { label: 'Identificação profissional (CREA, OAB, CRM, outros)', value: 'professionalId' }
              ]}
            />
          </Form.Item>
        </Col>

        {documentType && (
          <Col className="px-1" lg={8} sm={24} xs={24}>
            <Form.Item
              name={[field.name, documentType]}
              label={formatDocument[documentType].label}
              help={errors?.documentType && (errors.documentType)}
              validateStatus={errors?.documentType && ('error')}
            >
              <Input placeholder={formatDocument[documentType].placeholder} mask={documentType || ''} readOnly={readonly} />
            </Form.Item>
          </Col>
        )}

        <Col className="px-1" lg={8} sm={24} xs={24}>
          <Form.Item
            name={[field.name, 'expeditionIssuer']}
            label="Orgão emissor"
            help={errors?.expeditionIssuer && (errors.expeditionIssuer)}
            validateStatus={errors?.expeditionIssuer && ('error')}
          >
            <Input placeholder="Digite o orgão emissor" readOnly={readonly} />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={8} sm={24} xs={24}>
          <Form.Item
            name={[field.name, 'expeditionDate']}
            label="Data de emissão"
            help={errors?.expeditionDate && (errors.expeditionDate)}
            validateStatus={errors?.expeditionDate && ('error')}
          >
            <Datepicker
              id="expeditionDate"
              locale={locale}
              disabled={readonly}
            />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={8} sm={24} xs={24}>
          <Form.Item
            name={[field.name, 'birthdate']}
            label="Data de nascimento"
            help={errors?.birthdate && (errors.birthdate)}
            validateStatus={errors?.birthdate && ('error')}
          >
            <Datepicker
              id="birthdate"
              locale={locale}
              disabled={readonly}
            />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={8} sm={24} xs={24}>
          <Form.Item
            name={[field.name, 'motherName']}
            label="Nome da mãe"
            help={errors?.motherName && (errors.motherName)}
            validateStatus={errors?.motherName && ('error')}
          >
            <Input placeholder="Digite o nome da mãe" readOnly={readonly} />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={8} sm={24} xs={24}>
          <Form.Item
            name={[field.name, 'mailbox']}
            label={<label>Caixa postal:</label>}
            help={errors?.mailbox && (errors.mailbox)}
            validateStatus={errors?.mailbox && ('error')}
          >
            <Input placeholder="Digite a caixa postal" readOnly={readonly} />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={8} sm={24} xs={24}>
          <Form.Item
            name={[field.name, 'phone']}
            label={<label>Telefone:</label>}
            help={errors?.phone && (errors.phone)}
            validateStatus={errors?.phone && ('error')}
          >
            <Input placeholder="Digite o telefone" mask="cell" readOnly={readonly} />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={8} sm={24} xs={24}>
          <Form.Item
            name={[field.name, 'email']}
            label={<label>E-mail:</label>}
            help={errors?.email && (errors.email)}
            validateStatus={errors?.email && ('error')}
          >
            <Input placeholder="Digite o e-mail" readOnly={readonly} />
          </Form.Item>
        </Col>
      </Row>

      <Divider />
      <MaritalFields
        errors={errors}
        field={field}
        readonly={readonly}
        maritalInformation={getAssociateMaritalInformation()}
      />
      <Divider />

      <Address
        errors={errors}
        readOnlyInput={readonly}
        formRef={form}
        field={field}
        onCepBlur={fillFields}
        title="Endereço"
      />

      <Divider />

      <Row>
        <Col className="px-1" lg={6} sm={12} xs={24}>
          <Form.Item
            name={[field.name, 'employmentType']}
            label={<label>Tipo profissional:</label>}
            help={errors?.employmentType && (errors.employmentType)}
            validateStatus={errors?.employmentType && ('error')}
          >
            <Select
              id="test-form-select-employmentType"
              placeholder='Escolha seu tipo profissional'
              disabled={readonly}
              options={[
                { value: 'assalariado', label: 'Assalariado(a)' },
                { value: 'funcionarioPublico', label: 'Funcionário(a) público(a)' },
                { value: 'aposentado', label: 'Aposentado(a)' },
                { value: 'liberal', label: 'Profissional liberal' },
                { value: 'autonomo', label: 'Autônomo(a)' },
                { value: 'empresario', label: 'Empresario(a)' },
                { value: 'rural', label: 'Produtor(a) rural' }
              ]}
            />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={6} sm={12} xs={24}>
          <Form.Item
            name={[field.name, 'occupation']}
            label={<label>Profissão:</label>}
            help={errors?.occupation && (errors.occupation)}
            validateStatus={errors?.occupation && ('error')}
          >
            <Input placeholder="Digite sua profissão" readOnly={readonly} />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={6} sm={12} xs={24}>
          <Form.Item
            name={[field.name, 'income']}
            label={<label>Renda:</label>}
            help={errors?.income && (errors.income)}
            validateStatus={errors?.income ? ('error') : undefined}
          >
            <InputMoney placeholder="Digite sua renda" readOnly={readonly} />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={6} sm={12} xs={24}>
          <Form.Item
            name={[field.name, 'residenceType']}
            label={<label>Tipo de residência:</label>}
            help={errors?.residenceType && (errors.residenceType)}
            validateStatus={errors?.residenceType && ('error')}
          >
            <Select
              id="test-form-select-residenceType"
              placeholder='Digite o tipo de residência'
              disabled={readonly}
              options={[
                { value: 'owner', label: 'Própria' },
                { value: 'rented', label: 'Alugada' }
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row align="bottom" >
        <Col className="px-1" lg={12} sm={12} xs={24}>
          <Form.Item
            name={[field.name, 'isRural']}
            className="form-user-checkbox"
            valuePropName="checked"
            help={errors?.isRural && (errors.isRural)}
            validateStatus={errors?.isRural ? ('error') : undefined}
          >
            <Checkbox disabled={readonly}>
              É produtor rural?
            </Checkbox>
          </Form.Item>
        </Col>

        <Col className="px-1" lg={12} sm={12} xs={24}>
          <Form.Item
            name={[field.name, 'politicallyExposed']}
            className="form-user-checkbox"
            label={<PEPTooltip />}
            valuePropName="checked"
            help={errors?.politicallyExposed && (errors.politicallyExposed)}
            validateStatus={errors?.politicallyExposed ? ('error') : undefined}
          >
            <Checkbox disabled={readonly}>
              É Pessoa Exposta Politicamente?
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <CertificateFields errors={errors} fields={field} formRef={form} readonly={readonly} />
    </Fragment>
  )
}

export default AssociateForm

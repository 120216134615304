import React, { Fragment, useState } from 'react'
import { CustomSteps } from 'components'
import { IModalContract } from './types'
import EditMinuta from './EditMinuta'
import Minuta from './Minuta'

interface IFinalContract extends IModalContract{
  readOnlyInput: boolean
}

const FinalContract = ({ formRef, hide, readOnlyInput }: IFinalContract) => {
  const [counterStep, setCounterStep] = useState<number>(0)

  return (
    <Fragment>
      {!hide && (
        <div className="mb-2">
          <CustomSteps
            counterStep={counterStep}
            setCounterStep={setCounterStep}
            quantity={2}
            enableAfter={true}
          />
        </div>
      )}

      {counterStep === 0 && !hide && (
        <EditMinuta readonly={readOnlyInput} onClick={() => setCounterStep(1)} />
      )}

      {(counterStep === 1 || hide) && (
        <Minuta formRef={formRef} />
      )}
    </Fragment>
  )
}

export default FinalContract

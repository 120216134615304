import { ColumnsType } from 'antd/lib/table'
import commomColumns, { areasBackOffice, columnBlockUser, columnReceiveProposal } from './commomDataTable'
import { IColumns } from 'egi/types'

const columns = ({ blockUser, openModalDrawer, canReceiveProposal }: IColumns & { openModalDrawer: Function }): ColumnsType<Object> => [
  ...commomColumns,
  areasBackOffice(openModalDrawer),
  columnBlockUser(blockUser),
  columnReceiveProposal(canReceiveProposal)
]

export default columns

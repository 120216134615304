import { call, put, all, takeLatest } from 'redux-saga/effects'
import { proposals } from 'egi/consumers'
import swal from 'utils/swal'
import Axios from 'axios'
import { authActions } from '../auth/authActions'

const CancelToken = Axios.CancelToken
let cancelRequest: any

interface IAction {
  type: 'CREATE_PROPOSAL',
  payload: {
    productId: string
    clientId?: string
    callback?: string
    simulationId: string
  }
}

function * createProposal (action: IAction): any {
  const productId = yield action.payload.productId
  const clientId = yield action.payload.clientId
  const simulationId = yield action.payload.simulationId
  const callback = yield action.payload.callback

  let proposalId = ''
  let error

  type IData = {
    productId: string
    clientId?: string
    simulationId?: string
  }

  const data: IData = {
    productId
  }

  if (simulationId) data.simulationId = simulationId
  if (clientId) data.clientId = clientId

  try {
    const proposalCreate = yield call(proposals.create, data)
    proposalId = proposalCreate._id

    const proposal = yield call(proposals.state, proposalId, {})

    yield put({
      type: 'PROPOSAL_STATE_FETCH_SUCCEEDED',
      payload: { id: proposal._id, departments: proposal.departments }
    })

    yield put(yield call(authActions.setProposal, proposalId))
  } catch (err) {
    error = err
  }

  if (callback) callback(proposalId, error)
}

function * getProposalConfig (): any {
  try {
    const config = yield call(proposals.config)

    yield put({
      type: 'PROPOSAL_CONFIG_FETCH_SUCCEEDED',
      payload: config
    })
  } catch (err) {
    swal.basic({ text: err, icon: 'warning', title: 'Atenção' })
  }
}

function * setProposalState (action: any): any {
  const { id } = action.payload

  try {
    const state = yield call(
      proposals.state,
      id,
      {}
    )
    yield put({
      type: 'PROPOSAL_STATE_FETCH_SUCCEEDED',
      payload: state
    })
  } catch (err) {
    swal.basic({ text: err.message, icon: 'warning', title: 'Atenção' })
  }
}

function * getProposalState (action: any): any {
  const id = yield action.payload.id
  const callback = action.payload.callback

  try {
    if (cancelRequest) {
      cancelRequest()
    }

    const state = yield call(proposals.state, id, { cancelToken: new CancelToken((c) => (cancelRequest = c)) })
    const client = state.client
    const clientApproval = state.clientApproval

    yield put({
      type: 'PROPOSAL_STATE_FETCH_SUCCEEDED',
      payload: {
        ...state,
        id
      }
    })

    if (client) {
      yield put({
        type: 'SET_CLIENT_DATA',
        payload: {
          client: {
            ...client,
            proposal: { id: id },
            clientApproval: clientApproval || undefined
          }
        }
      })
    }
  } catch (err) {
    swal.basic({ text: err.message, icon: 'warning', title: 'Atenção' })
  }

  yield put({
    type: 'PROPOSAL_LOADING_STEP',
    payload: {
      step: {
        id: undefined,
        loading: false
      }
    }
  })

  if (callback) callback(id)
}

function * setNewProposal (action: any): any {
  const id = yield action.payload.id
  const callback = yield action.payload.callback

  try {
    const state = yield call(
      proposals.get,
      id
    )

    yield put({
      type: 'PROPOSAL_STATE_FETCH_SUCCEEDED',
      payload: {
        id: state._id,
        ...state
      }
    })

    yield put(yield call(authActions.setProposal, state._id))
  } catch (err) {
    swal.basic({
      text: err.message,
      icon: 'warning',
      title: 'Atenção'
    })
  }

  if (callback) callback()
}

export default all([
  takeLatest('GET_PROPOSAL_STATE', getProposalState),
  takeLatest('REQUEST_PROPOSAL_AUTH', getProposalState),
  takeLatest('GET_PROPOSAL_CONFIG', getProposalConfig),
  takeLatest('CREATE_PROPOSAL', createProposal),
  takeLatest('SET_PROPOSAL_STATE', setProposalState),
  takeLatest('SET_NEW_PROPOSAL', setNewProposal)
])

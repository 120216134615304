import { Button } from 'antd'

import SignupLayout from 'layouts/SignupLayout/SignupLayout'
import React from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { UNAUTHS_PATHS } from 'routes/unauthRoutes'

import SignupButton from 'egi/app/Signup/components/SignupButton/SignupButton'

import { SignupTitle } from 'egi/app/Signup/components/SignupTitle/SignupTitle'

import NewEmailSVG from 'assets/reactSvgs/NewEmailSVG/VerifyEmailSuccesSVG'

function LGPDAccessConfirmEmail () {
  const history = useHistory()
  const location = useLocation()

  const email = location.state as string ?? '-'

  return (
    <SignupLayout>

      <div className='mb-3'>
        <Link
          className='signup__link'
          to={UNAUTHS_PATHS.LGPD_ACCESS_CHECK_CNPJ}
        >
          &larr; Voltar
        </Link>
      </div>

      <SignupTitle className='mb-1'>Validação de email</SignupTitle>
      <div className="lgpd-access__svg">
        <NewEmailSVG />
      </div>
      <p className='lgpd-access__info-text'>Enviamos um e-mail de autenticação para o email <b>{email}</b></p>
      <p className='lgpd-access__info-text'>Siga as instruções do email para continuar seu cadastro.</p>

      <div className="lgpd-access__warning">
        <p className="mb-1"><b>Caso não tenha recebido nenhum e-mail:</b></p>
        <p>
          Entre em contato com seu RH informando: Nome completo, CPF, Matrícula e CNPJ informado</p>
      </div>

      <SignupButton
        onClick={() => history.push(UNAUTHS_PATHS.FIRST_ACCESS_CLIENT_ECP_CHECK_LINK)}
        className='w-100 mb-3'

      >
        Refazer cadastro
      </SignupButton>

      <Button
        onClick={() => history.push(UNAUTHS_PATHS.MAIN)}
        type='ghost'
        className='w-100 unauth-buttons-ghost'
      >
        Voltar a página incial
      </Button>

    </SignupLayout>

  )
}

export default LGPDAccessConfirmEmail

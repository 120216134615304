import React, { Fragment } from 'react'
import dayjs from 'dayjs'
import { ISLAProps } from '../../views/SLAConfig/slaInterfaces'
import useSLA from '../../hooks/useSLA/useSLA'

function SLA ({ sla }: ISLAProps) {
  const { slaColor, slaText } = useSLA({ sla })
  const slaWorkHours = dayjs().hour() >= 9 && dayjs().hour() <= 18
  const slaWorkDays = dayjs().day() >= 1 && dayjs().day() <= 5

  return (
    <Fragment>
      {slaWorkHours && slaWorkDays ? (
        <div className="sla-info">
          <div className="SLA-status-circle mr-2" style={{ backgroundColor: slaColor }} />
          <p className="color-gray bold mb-0">{slaText}</p>
        </div>
      ) : (
        <div className="flex flex-align-center flex-justify-end h-100 w-100">
          <p className="color-gray bold mb-0">Fora do expediente de trabalho</p>
        </div>
      )}
    </Fragment>
  )
}

export default SLA

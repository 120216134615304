import { _inputMask } from 'globals'
import format from './format'

const DO_NOT_UPPERCASE = ['de', 'das', 'dos', 'do', 'da']

/**
 * Applies a CPF (Cadastro de Pessoas Físicas) mask to the given value.
 *
 * @param {string} value - The value to apply the CPF mask.
 * @returns {string} The value with the CPF mask applied.
 *
 * @example
 * const cpfWithoutMask = '12345678909'
 * const cpfWithMask = cpfMask(cpfWithoutMask)
 * // Result: '123.456.789-09'
 *
 * @example
 * const maskedCpf = '987.654.321-00'
 * const cpfSameMask = cpfMask(maskedCpf)
 * // Result: '987.654.321-00', as the function maintains the mask if already present.
 */
const cpfMask = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

/**
 * Applies a CNPJ (Cadastro Nacional da Pessoa Jurídica) mask to the given value.
 *
 * @param {string} value - The value to apply the CNPJ mask.
 * @returns {string} The value with the CNPJ mask applied.
 *
 * @example
 * const cnpjWithoutMask = '12345678901234'
 * const cnpjWithMask = cnpjMask(cnpjWithoutMask)
 * // Result: '12.345.678/9012-34'
 *
 * @example
 * const maskedCnpj = '98.765.432/1000-11'
 * const cnpjSameMask = cnpjMask(maskedCnpj)
 * // Result: '98.765.432/1000-11', as the function maintains the mask if already present.
 */
const cnpjMask = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

/**
 * Applies a CPF (Cadastro de Pessoas Físicas) or CNPJ (Cadastro Nacional da Pessoa Jurídica) mask to the given value.
 *
 * @param {string} value - The value to apply the CPF/CNPJ mask.
 * @returns {string} The value with the CPF/CNPJ mask applied.
 *
 * @example
 * const cpfWithoutMask = '12345678909'
 * const cpfWithMask = cpfcnpjMask(cpfWithoutMask)
 * // Result: '123.456.789-09'
 *
 * @example
 * const cnpjWithoutMask = '12345678901234'
 * const cnpjWithMask = cpfcnpjMask(cnpjWithoutMask)
 * // Result: '12.345.678/9012-34'
 *
 * @example
 * const maskedCpfCnpj = '98.765.432/1000-11'
 * const cpfCnpjSameMask = cpfcnpjMask(maskedCpfCnpj)
 * // Result: '98.765.432/1000-11', as the function maintains the mask if already present.
 */
const cpfcnpjMask = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(\d{2})(\d)(\.)(\d{2})(\d)(\.)(\d{2})(\d)(-)(\d{3})/, '$1.$2$4.$5$7/$8$10')
    .replace(/(\/\d{4})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

/**
 * Applies a mask to a Brazilian phone number, assuming the number already starts with the area code.
 * If the number starts with '5555', it will adjust to start with '(55)'.
 *
 * @param {string} value - The phone number to be formatted.
 * @returns {string} - The formatted phone number with the Brazilian mask.
 *
 * @example
 * const phoneNumber1 = '5555 991622234'
 * const formattedNumber1 = cellMask(phoneNumber1)
 * // Expected result: (55) 99162-2234
 *
 *  * @example
 * const phoneNumber2 = '(55) 123456789'
 * const formattedNumber2 = cellMask(phoneNumber2)
 * // Expected result: (55) 12345-6789
 *
 * @example
 * const phoneNumber1 = '(15) 991622234'
 * const formattedNumber3 = cellMask(phoneNumber1)
 * // Expected result: (15) 99162-2234
 */
const cellMask = (value: string): string => {
  // Remove all non-numeric characters
  value = value.replace(/\D/g, '')

  // Check if the number starts with '5555'
  if (value.startsWith('5555')) {
    // Remove the first two '55' and format with (55)
    value = '55' + value.slice(4)
  }

  // Check if the phone number is bigger than 11
  if (value.replace(' ', '').length > 11) value = value.slice(-11)

  return value
    .replace(/(\d{2})(\d)/, '($1) $2') // Format the area code (DDD)
    .replace(/(\d{5})(\d)/, '$1-$2') // Format the phone number with a hyphen
    .replace(/(-\d{4})\d+?$/, '$1') // Limit to 4 digits after the hyphen
}

/**
 * Applies a phone number mask to the given value.
 *
 * @param {string} value - The value to apply the phone number mask.
 * @param {boolean} [raw=false] - If true, assumes the input value is the raw phone number without a country code.
 * @returns {string} The value with the phone number mask applied.
 *
 * @example
 * const rawPhoneNumber = '551234567890'
 * const formattedPhone = phoneMask(rawPhoneNumber, true)
 * // Result: '(12) 3456-7890'
 *
 * @example
 * const phoneWithMask = '+551234567890'
 * const formattedPhoneSameMask = phoneMask(phoneWithMask)
 * // Result: '(12) 3456-7890', as the function maintains the mask if already present.
 *
 * @example
 * const rawCellNumber = '5511987654321'
 * const formattedCell = phoneMask(rawCellNumber, true)
 * // Result: '1198765-4321', as the function switches to the cell number mask for longer numbers.
 */
const phoneMask = (value: string, raw = false) => {
  if (value.length > (raw ? 10 : 14)) return cellMask(value)
  return value
    .replace('+55', '')
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1')
}

/**
 * Formats a Brazilian cellphone number by removing the country code and non-numeric characters,
 * and then adding a hyphen to separate the last four digits.
 *
 * @param value - The cellphone number as a string, which may include the country code and non-numeric characters.
 * @returns The formatted cellphone number as a string, without the country code and with a hyphen separating the last four digits.
 *
 * @example
 * ```typescript
 * const formattedNumber = cellphoneWithoutDDDMask('+55 (11) 91234-5678');
 * console.log(formattedNumber); // Output: '91234-5678'
 * ```
 */
const cellphoneWithoutDDDMask = (value: string): string => {
  return value
    .replace('+55', '')
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2') // Format the phone number with a hyphen
    .replace(/(-\d{4})\d+?$/, '$1') // Limit to 4 digits after the hyphen
}

/**
 * Formats a Brazilian phone number by removing the country code and non-numeric characters,
 * and then adding a hyphen to separate the last four digits. If the phone number length exceeds
 * a certain limit, it delegates the formatting to `cellphoneWithoutDDDMask`.
 *
 * @param value - The phone number as a string, which may include the country code and non-numeric characters.
 * @param raw - A boolean indicating whether to use the raw format (default is false).
 * @returns The formatted phone number as a string, without the country code and with a hyphen separating the last four digits.
 *
 * @example
 * ```typescript
 * const formattedNumber = phoneWithoutDDDMask('+55 (11) 1234-5678');
 * console.log(formattedNumber); // Output: '1234-5678'
 *
 * const formattedCellNumber = phoneWithoutDDDMask('+55 (11) 91234-5678');
 * console.log(formattedCellNumber); // Output: '91234-5678'
 * ```
 */
const phoneWithoutDDDMask = (value: string) => {
  if (format.onlyDigits(value).length > 8) return cellphoneWithoutDDDMask(value)

  return value
    .replace('+55', '')
    .replace(/\D/g, '')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1')
}

/**
 * Applies a percentage mask to the given value.
 *
 * @param {string} value - The value to apply the percentage mask.
 * @returns {string} The value with the percentage mask applied.
 *
 * @example
 * const percentageValue = '12345'
 * const formattedPercentage = percentageMask(percentageValue)
 * // Result: '123,45'
 *
 * @example
 * const maskedPercentage = '98,76'
 * const percentageSameMask = percentageMask(maskedPercentage)
 * // Result: '98,76', as the function maintains the mask if already present.
 */
const percentageMask = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/([0-9]{1,3})([0-9]{2})/, '$1,$2')
    .replace(/(,\d{2})\d+?$/, '$1')
}

/**
 * Applies an RG (Registro Geral) mask to the given value.
 *
 * @param {string} value - The value to apply the RG mask.
 * @returns {string} The value with the RG mask applied.
 *
 * @example
 * const rgWithoutMask = 'ab12345678'
 * const rgWithMask = rgMask(rgWithoutMask)
 * // Result: 'AB.12.345.678', as the function applies the RG mask.
 *
 * @example
 * const maskedRg = 'CD.34.567.890'
 * const rgSameMask = rgMask(maskedRg)
 * // Result: 'CD.34.567.890', as the function maintains the mask if already present.
 */
const rgMask = (value: string) => {
  return value
    .replace(/[^a-z\d]/gi, '')
    .replace(/([a-z\d]{2})([a-z\d])/i, '$1.$2')
    .replace(/([a-z\d]{3})([a-z\d])/i, '$1.$2')
    .replace(/([a-z\d]{3})([a-z\d])/i, '$1-$2')
    .replace(/(-[a-z\d]{8})[a-z\d]+?$/i, '$8')
    .toUpperCase()
}

/**
 * Applies a mask to format a name string, capitalizing the first letter of each word.
 *
 * @param {string} value - The name value to apply the mask.
 * @returns {string} The formatted name with the first letter of each word capitalized.
 *
 * @example
 * const unformattedName = 'john doe'
 * const formattedName = nameMask(unformattedName)
 * // Result: 'John Doe', as the function capitalizes the first letter of each word.
 *
 * @example
 * const mixedCaseName = 'maRy jAnE'
 * const mixedCaseFormattedName = nameMask(mixedCaseName)
 * // Result: 'Mary Jane', as the function capitalizes the first letter of each word and handles mixed case input.
 *
 * @example
 * const emptyName = ''
 * const emptyFormattedName = nameMask(emptyName)
 * // Result: '', as the function returns an empty string for empty input.
 */
const nameMask = (value: string) => {
  if (!value || !value.trim()) return ''
  const onlyText = value.replace(/[^a-záàâãéèêíïóôõöúüçñ ']/gi, '')
  return onlyText
    .split(' ')
    .map((w) => {
      if (w.length >= 1) {
        if (!DO_NOT_UPPERCASE.includes(w.toLowerCase())) {
          return w[0].toUpperCase() + w.toLowerCase().substr(1)
        }
        return w.toLowerCase()
      }
      return ' '
    })
    .join(' ')
}

/**
 * Formats a string representing a monetary value into a currency format (BRL - Brazilian Real).
 *
 * @param {string | undefined} input - The string to be formatted.
 * @returns {string} - The formatted currency string.
 *
 * @example
 * const formattedValue = moneyMask('1234567.89')
 * // formattedValue is 'R$ 12,345,678.9'
 */
function moneyMask (input: string | undefined): string {
  if (!input) return 'R$ 0,00'
  const matches = input.match(/(\d+)?\.?(\d+)/g)
  if (!matches) return 'R$ '
  const numericValue = matches.reduce((acc, item) => {
    return acc + item
  }, '')
  return `R$ ${parseFloat(numericValue).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
}

/**
 * Converts a date to the Brazilian format (DD/MM/YYYY).
 *
 * @param {Date | string} date - The date to be formatted.
 * @returns {string} The date in the Brazilian format (DD/MM/YYYY).
 *
 * @example
 * const currentDate = new Date()
 * const formattedDate = brazilianDateFormat(currentDate)
 * // Result: '25/01/2024', as the function formats the current date.
 *
 * @example
 * const dateString = '2024-01-25'
 * const formattedStringDate = brazilianDateFormat(dateString)
 * // Result: '25/01/2024', as the function formats the date string.
 */
const dateMask = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\/\d{4})\d+?$/, '$1')
}

/**
 * Applies a CEP (Código de Endereçamento Postal) mask to the given value.
 *
 * @param {string} value - The value to apply the CEP mask.
 * @returns {string} The value with the CEP mask applied.
 *
 * @example
 * const unformattedCep = '12345678'
 * const formattedCep = cepMask(unformattedCep)
 * // Result: '12345-678', as the function applies the CEP mask.
 *
 * @example
 * const cepWithMask = '98765-432'
 * const cepSameMask = cepMask(cepWithMask)
 * // Result: '98765-432', as the function maintains the mask if already present.
 *
 * @example
 * const invalidCep = 'abcde'
 * const invalidCepFormatted = cepMask(invalidCep)
 * // Result: '', as the function returns an empty string for invalid input.
 */
const cepMask = (value: string) => {
  if (!value) return ''

  return String(value)
    .replace(/\D/g, '')
    .replace(/^(\d{5})(\d)/i, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1')
}

/**
 * Applies a mask to format a bank agency number.
 *
 * @param {string} value - The value to apply the bank agency mask.
 * @returns {string} The value with the bank agency mask applied.
 *
 * @example
 * const unformattedAgency = '12345'
 * const formattedAgency = agencyMask(unformattedAgency)
 * // Result: '12.345-6', as the function applies the bank agency mask.
 *
 * @example
 * const agencyWithMask = '54321-0'
 * const agencySameMask = agencyMask(agencyWithMask)
 * // Result: '54.321-0', as the function maintains the mask if already present.
 *
 * @example
 * const invalidAgency = 'abcde'
 * const invalidAgencyFormatted = agencyMask(invalidAgency)
 * // Result: '', as the function returns an empty string for invalid input.
 */
const agencyMask = (value: string) => {
  return String(value)
    .replace(/\D/g, '')
    .replace(/^(\d{3,5})(\d)/i, '$1-$2')
    .replace(/(-\d{1})\d+?$/, '$1')
}

/**
 * Applies a mask to format a bank account number.
 *
 * @param {string} value - The value to apply the bank account mask.
 * @returns {string} The value with the bank account mask applied.
 *
 * @example
 * const unformattedAccount = '123456789'
 * const formattedAccount = accountMask(unformattedAccount)
 * // Result: '12345-6789', as the function applies the bank account mask.
 *
 * @example
 * const accountWithMask = '98765-4321'
 * const accountSameMask = accountMask(accountWithMask)
 * // Result: '98765-4321', as the function maintains the mask if already present.
 *
 * @example
 * const invalidAccount = 'abcde'
 * const invalidAccountFormatted = accountMask(invalidAccount)
 * // Result: '', as the function returns an empty string for invalid input.
 */
const accountMask = (value: string) => {
  return String(value)
    .replace(/[^0-9x]/gi, '')
    .replace(/^([0-9]{5,13})([0-9x])/gi, '$1-$2')
    .replace(/(-[0-9x]{1})[0-9x]+?$/gi, '$1')
}

/**
 * Applies a mask to format a passport number.
 *
 * @param {string} value - The value to apply the passport mask.
 * @returns {string} The value with the passport mask applied.
 *
 * @example
 * const unformattedPassport = 'aB1234567'
 * const formattedPassport = passportMask(unformattedPassport)
 * // Result: 'AB1234567', as the function applies the passport mask.
 *
 * @example
 * const passportWithMask = 'XY9876543'
 * const passportSameMask = passportMask(passportWithMask)
 * // Result: 'XY9876543', as the function maintains the mask if already present.
 *
 * @example
 * const invalidPassport = 'abcde'
 * const invalidPassportFormatted = passportMask(invalidPassport)
 * // Result: '', as the function returns an empty string for invalid input.
 */
const passportMask = (value: string) => {
  return String(value)
    .replace(/[^a-z\d]/gi, '')
    .replace(/([a-z\d]{9})([a-z\d])/i, '$1')
    .toUpperCase()
}

/**
 * Applies a mask to format a professional identification number.
 *
 * @param {string} value - The value to apply the professional identification mask.
 * @returns {string} The value with the professional identification mask applied.
 *
 * @example
 * const unformattedId = 'aB1234567890123456789'
 * const formattedId = professionalIdMask(unformattedId)
 * // Result: 'AB1234567890123456789', as the function applies the professional identification mask.
 *
 * @example
 * const idWithMask = 'XY987654321098765432'
 * const idSameMask = professionalIdMask(idWithMask)
 * // Result: 'XY987654321098765432', as the function maintains the mask if already present.
 *
 * @example
 * const invalidId = 'abcde'
 * const invalidIdFormatted = professionalIdMask(invalidId)
 * // Result: '', as the function returns an empty string for invalid input.
 */
const professionalIdMask = (value: string) => {
  return String(value)
    .replace(/[^a-z\d]/gi, '')
    .replace(/([a-z\d]{20})([a-z\d])/i, '$1')
}

/**
 * Applies an RNE (Registro Nacional de Estrangeiros) mask to the given value.
 *
 * @param {string} value - The value to apply the RNE mask.
 * @returns {string} The value with the RNE mask applied.
 *
 * @example
 * const rneWithoutMask = '123456789A'
 * const rneWithMask = rneMask(rneWithoutMask)
 * // Result: '123.456.789-A'
 *
 * @example
 * const maskedRne = '987.654.321-Z'
 * const rneSameMask = rneMask(maskedRne)
 * // Result: '987.654.321-Z', as the function maintains the mask if already present.
 */
const rneMask = (value: string) => {
  return value
    .replace(/\W/g, '') // Remove non-alphanumeric characters
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})([\dA-Z])/, '$1-$2')
    .toUpperCase()
}

/**
 * Applies a CTPS (Carteira de Trabalho e Previdência Social) mask to the given value.
 *
 * @param {string} value - The value to apply the CTPS mask.
 * @returns {string} The value with the CTPS mask applied.
 *
 * @example
 * const ctpsWithoutMask = '12345678901'
 * const ctpsWithMask = ctpsMask(ctpsWithoutMask)
 * // Result: '123456 789-01'
 *
 * @example
 * const maskedCtps = '654321 987-65'
 * const ctpsSameMask = ctpsMask(maskedCtps)
 * // Result: '654321 987-65', as the function maintains the mask if already present.
 */
const ctpsMask = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{6})(\d)/, '$1 $2')
    .replace(/(\d{3})(\d{2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

/**
 * Applies a CNH (Carteira Nacional de Habilitação) mask to the given value.
 *
 * @param {string} value - The value to apply the CNH mask.
 * @returns {string} The value with the CNH mask applied.
 *
 * @example
 * const cnhWithoutMask = '12345678901'
 * const cnhWithMask = cnhMask(cnhWithoutMask)
 * // Result: '123 456 789-01'
 *
 * @example
 * const maskedCnh = '987 654 321-00'
 * const cnhSameMask = cnhMask(maskedCnh)
 * // Result: '987 654 321-00', as the function maintains the mask if already present.
 */
const cnhMask = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1 $2')
    .replace(/(\d{3})(\d)/, '$1 $2')
    .replace(/(\d{3})(\d{2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

/**
 * Applies a CR (Certificado de Reservista) mask to the given value.
 *
 * @param {string} value - The value to apply the CR mask.
 * @returns {string} The value with the CR mask applied.
 *
 * @example
 * const crWithoutMask = '123456789012'
 * const crWithMask = crMask(crWithoutMask)
 * // Result: '1234567/8901'
 *
 * @example
 * const maskedCr = '9876543/2100'
 * const crSameMask = crMask(maskedCr)
 * // Result: '9876543/2100', as the function maintains the mask if already present.
 */
const crMask = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{7})(\d{4})/, '$1/$2')
    .replace(/(\/\d{4})\d+?$/, '$1')
}

/**
 * Applies various masks based on the specified mask type.
 *
 * @param {string} value - The value to apply the mask.
 * @param {_inputMask} mask - The type of mask to apply.
 * @param {boolean} [raw=false] - Optional parameter for raw formatting (if applicable).
 * @returns {string} The value with the specified mask applied.
 *
 * @example
 * const unformattedValue = '123456789'
 * const formattedCPF = mask(unformattedValue, 'cpf')
 * // Result: '123.456.789-01', as the function applies the CPF mask.
 *
 * @example
 * const unformattedDate = '20240125'
 * const formattedDate = mask(unformattedDate, 'date')
 * // Result: '25/01/2024', as the function applies the date mask.
 *
 * @example
 * const rawPhoneNumber = '+55123456789'
 * const formattedPhone = mask(rawPhoneNumber, 'phone', true)
 * // Result: '(12) 3456-789', as the function applies the phone mask with raw formatting.
 *
 * @example
 * const unformattedValue = 'ABC1234567890123456789'
 * const formattedProfessionalId = mask(unformattedValue, 'professionalId')
 * // Result: 'ABC1234567890123456789', as the function applies the professionalId mask.
 */
const mask = (value: string, mask?: _inputMask, raw?: boolean) => {
  if (!mask || !value) return value
  if (value === null) return value

  const result = String(value)

  switch (mask.toLowerCase()) {
    case 'cpf':
      return cpfMask(result)
    case 'cnpj':
      return cnpjMask(result)
    case 'cpfcnpj':
      return cpfcnpjMask(result)
    case 'phone':
      return phoneMask(result, raw)
    case 'phonewithoutddd':
      return phoneWithoutDDDMask(result)
    case 'cell':
      return cellMask(result)
    case 'rg':
      return rgMask(result)
    case 'name':
      return nameMask(result)
    case 'money':
      return moneyMask(result)
    case 'date':
      return dateMask(result)
    case 'cep':
      return cepMask(result)
    case 'agency':
      return agencyMask(result)
    case 'account':
      return accountMask(result)
    case 'percentage':
      return percentageMask(result)
    case 'professionalId':
      return professionalIdMask(result)
    case 'passport':
      return passportMask(result)
    case 'rne':
      return rneMask(result)
    case 'ctps':
      return ctpsMask(result)
    case 'cnh':
      return cnhMask(result)
    case 'cr':
      return crMask(result)
    default:
      return result
  }
}

export default mask

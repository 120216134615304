import { AxiosRequestConfig } from 'axios'
import { _userLevel } from 'ecp/models/UsersModel'
import { IPaginate, Repository } from 'ecp/repositories/Repository'
import { IConfigLogs } from 'egi/app/Settings/components/Logs/EmailLogs'
import { IConfig } from 'egi/app/Settings/views/EmailConfig/EmailConfig'
import { IAuth } from 'egi/types'

import api from 'services/api'

export type EmailInviteBody = {
  email: string
  link: string
  level: _userLevel
  inviteId: string
}

type StepLinkBody = {
  email: string
  link: string
  stepName: string
  proposalId?: string
}
class EmailRepository extends Repository<{}> {
  async verify (data: {}, config?: AxiosRequestConfig) {
    return Repository.handle<IAuth>(() =>
      this.api.patch(`${this.path}/verify`, data, config)
    )
  }

  async verification (data: { userEmail?: string, token?: string }, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.post(`${this.path}/verification`, data, config)
    )
  }

  async levels (config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.get(`${this.path}/levels`, config)
    )
  }

  async changeValidate (config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.get(`${this.path}/reset/confirm`, config)
    )
  }

  async createLink (link: { link: string, email: string }, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.post(`${this.path}/link`, link, config)
    )
  }

  async invite (body: EmailInviteBody, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.post(`${this.path}/invite`, body, config)
    )
  }

  async stepLink (body: StepLinkBody, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.post(`${this.path}/step`, body, config)
    )
  }

  async config (config?: AxiosRequestConfig) {
    return Repository.handle<{mailerConfiguration: IConfig }>(() =>
      this.api.get(`${this.path}/configure`, config)
    )
  }

  async logs (config?: AxiosRequestConfig) {
    return Repository.handle<IPaginate<IConfigLogs>>(() =>
      this.api.get(`${this.path}/configure/logs`, config)
    )
  }

  async editConfig (data: IConfig, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.patch(`${this.path}/configure`, data, config)
    )
  }
}

export default new EmailRepository({ api, path: '/email' })

import { _maritalStatusEnum, _tableAreas } from 'globals'
import { IAuth } from 'egi/types'
import { Dayjs } from 'dayjs'
import { ICovenantProposal, IDissmissInfo, _clientEmployeeStatus, _ecpClientStatus, _formActions } from 'ecp/app/Clients/clientInterfaces'
import { _typeOfDischarge } from 'ecp/app/Clients/views/ClientDismiss/ClientDismiss'
import { timeAsDayjs } from 'utils/time'
import { IOnidataAddress, IOnidataDocument, IOnidataLimits, IOnidataPayment, IProposalDismiss } from 'ecp/app/Proposals/proposalInterfaces'
import { IOnidataCompany } from './CovenantModel'
import { _userLevel } from './UsersModel'

export type PreSignupOnidataClient = {
  cargo: string
  cpf: string
  data_admissao: string | Dayjs
  data_nascimento: string | Dayjs
  descontos: number
  matricula: string
  email: string
  email_gestor_imediato: string
  inss: number
  irrf: number
  salario: number
  outros_vencimentos: number
  nome: string
  nome_mae: string
  sexo: string
  sobrenome: string
}

export type IOnidataClientLGPD = {
  signatureCode: string
  signatureDate: string | Dayjs
  signed: boolean
  type: 'signup' | 'proposal'
  _id: string
}

export interface IOnidataClient extends PreSignupOnidataClient {
  onidataId: string
  id?: string
  demissao: IProposalDismiss
  endereco?: IOnidataAddress
  documento?: IOnidataDocument
  estado_civil: string
  telefone_celular: string
  valor_emprestado: number
  telefone_celular_verificado: boolean
  email_verificado: boolean
  comprometimento_outros?: number
  dependentes: number
  pagamento: IOnidataPayment
  email_secundario: string
  referencia_nome: string
  referencia_parentesco: string
  referencia_telefone?: string
  _id: string
  hasActiveToken: boolean
  lgpd:IOnidataClientLGPD
  identifier: string
  data: {
    estado_civil: _maritalStatusEnum
    cadastro_ativo: string
    id: string
    ip: string
    sexo: string
    dependentes: number
    data_nascimento: string | Dayjs
    matricula: string
    data_admissao: string | Dayjs
    cargo: string
    salario: number
    descontos: number
    email_secundario: string
    referencia_nome: string
    referencia_parentesco: string
    referencia_telefone: string
    endereco: IOnidataAddress
    pagamento?: IOnidataPayment
    documento: IOnidataDocument
    limites: IOnidataLimits
    inss: number
    irrf: number
    comprometimento_outros?: number
    criado_em: string
    empresa: IOnidataCompany
    status: 'ativo' | 'desligado'
    saldo_devedor: number
    saldo_devedor_no_proximo_vencimento: number
    email_gestor_imediato: string
    contracheque: string
  } & IOnidataClient
  onidataid: string
  scores: []
  corban?: {
    fantasyName: string,
    socialName: string,
    _id: string
  }
  convenios?: ICovenantProposal[]
}

export interface IOnidataClientInContract extends IOnidataClient {
  resourcetype: string
}

const ClientModel = {

  chooseCorrectName: (level: _userLevel | 'RH') => level === 'RH' ? 'Funcionário' : 'Cliente',

  canGenerateReport: (user: IAuth): boolean => user.type !== 'CB',

  canDismiss: (level: _userLevel | 'RH', onidataStatus?: _clientEmployeeStatus) => level === 'RH' && onidataStatus === 'ativo',

  canBlock: (level: _userLevel | 'RH') => level === 'RH',

  showEmail: (level: _userLevel | 'RH') => level !== 'RH',

  showCellphone: (level: _userLevel | 'RH') => level !== 'RH',

  showFirstAcces: (level: _userLevel | 'RH') => level === 'RH' || level === 'promoter',

  showDismiss: (level: _userLevel | 'RH') => level === 'RH',

  masterViewDetails: (level: _userLevel) => level === 'master',

  rhViewDetails: (level: _userLevel) => level === 'RH',

  showCovenantField: (level: _userLevel, action: _formActions | undefined) => level === 'RH' && action === 'create',

  dismissClientFormatedValues: (client: IDissmissInfo, typeOfDischarge: _typeOfDischarge, selectedDate: string | Dayjs) => {
    const { saldo_devedor_no_proximo_vencimento, saldo_devedor, proximo_corte } = client || {}
    const isLote = typeOfDischarge === 'lote'
    const saldoDevedorFnal = isLote ? saldo_devedor_no_proximo_vencimento : saldo_devedor
    const data_emissao =
      isLote
        ? timeAsDayjs(proximo_corte?.data_vencimento, { applyTimezone: false }).format('DD/MM/YYYY')
        : timeAsDayjs(selectedDate, { applyTimezone: false }).format('DD/MM/YYYY')

    return { ...client, saldo_devedor: saldoDevedorFnal, data_emissao, forma_pagamento: typeOfDischarge }
  },

  showLGPD: (areaName?: _tableAreas, userLevel?: _userLevel) => {
    return areaName === 'juridico' || userLevel === 'master'
  },

  canShowBornDateField (userLevel : _userLevel): boolean {
    const cantShow = ['promoter', 'operator', 'client']
    return !cantShow.includes(userLevel)
  },

  canShowMotherNameField (userLevel : _userLevel): boolean {
    const cantShow = ['promoter', 'operator', 'client']
    return !cantShow.includes(userLevel)
  },

  canShowDependentsField (userLevel : _userLevel): boolean {
    const cantShow = ['promoter', 'operator', 'client']
    return !cantShow.includes(userLevel)
  },

  canShowSexField (userLevel : _userLevel): boolean {
    const cantShow = ['promoter', 'operator', 'client']
    return !cantShow.includes(userLevel)
  },

  canShowFormCellphone (userLevel: _userLevel): boolean {
    const levels = ['promoter', 'operator', 'client']
    return !levels.includes(userLevel)
  },

  disabledFieldsForm (userLevel: _userLevel, action?: _formActions): boolean {
    const levels = ['client', 'RH']
    const hasValidLevel = levels.includes(userLevel)
    const hasValidAction = action === 'update'
    return hasValidLevel && hasValidAction
  },

  canCreate (userLevel: _userLevel): boolean {
    return userLevel === 'RH'
  },

  approveClientLGPDButtonText: (selectedRowsLenght: number, approval: boolean) => {
    if (approval) {
      if (selectedRowsLenght > 1) return `Aprovar cadastro${selectedRowsLenght > 1 ? 's' : ''} (${selectedRowsLenght})`
      return 'Aprovar Cadastro'
    } else {
      if (selectedRowsLenght > 1) return `Remover Cliente${selectedRowsLenght > 1 ? 's' : ''} (${selectedRowsLenght})`
      return 'Remover Cliente'
    }
  },

  canShowReason: (userLevel: _userLevel, status: _ecpClientStatus) => {
    return ['master', 'RH'].includes(userLevel) && status === 'desligado'
  }
}

export default ClientModel

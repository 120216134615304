import React, { useEffect, useState } from 'react'
import Input from 'components/Inputs/Input/Input'
import DatepickerCustom from '../DatepickerCustom/DatepickerCustom'
import dayjs, { Dayjs } from 'dayjs'
import masks from 'utils/masks'
import { IInputProps } from 'components/Inputs/Input/types'
import { PickerLocale } from 'antd/lib/date-picker/generatePicker'

interface IDatepickerProps extends Omit<IInputProps, 'onChange'> {
  locale?: PickerLocale
  inputReadOnly?: boolean
  disabledDate?: ((date: Dayjs) => boolean) | undefined
  onChange?: Function
}

function Detapicker ({ mask = 'date', value, locale, disabled, inputReadOnly, disabledDate, onChange, ...rest }: IDatepickerProps) {
  const [open, setOpen] = useState<boolean>(false)
  const [date, setDate] = useState<{ calendar: dayjs.Dayjs | null, input: string }>({
    calendar: null,
    input: ''
  })

  const handleValue = (value: string) => {
    if (!value || value?.length < 10) return null
    const dateDayJS = dayjs(value, 'DD/MM/YYYY')

    if (dateDayJS && dayjs(dateDayJS).isValid()) return dateDayJS

    return null
  }

  useEffect(() => {
    if (value) {
      setDate({
        input: value.toString(),
        calendar: handleValue(value.toString())
      })
    }
  }, [value])

  return (
    <div className="date-input">
      <Input
        className="date-input__datepicker"
        onChange={(e) => {
          if (onChange) onChange(masks(e.target.value, mask))
          setDate({
            input: masks(e.target.value, mask),
            calendar: handleValue(e.target.value)
          })
        }}
        onClick={() => setOpen(true)}
        onKeyPress={() => setOpen(true)}
        onFocus={() => setOpen(true)}
        onBlur={() => setOpen(false)}
        value={date.input}
        maxLength={10}
        placeholder='DD/MM/AAAA'
        lang="PT-BR"
        disabled={disabled}
        {...rest}
      />

      <DatepickerCustom
        open={open}
        className='date-input__calendar'
        locale={locale}
        disabled={disabled}
        inputReadOnly={inputReadOnly}
        format="DD/MM/YYYY"
        value={date.calendar}
        disabledDate={disabledDate}
        onChange={(_, stringDate) => {
          setOpen(false)
          if (onChange) onChange(stringDate)
          setDate({
            input: stringDate,
            calendar: handleValue(stringDate)
          })
        }}
      />
    </div>
  )
}

export default Detapicker

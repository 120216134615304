import React from 'react'
import { Skeleton } from 'antd'

function SkeletonsProposal () {
  return (
    <div className="proposal-container-card p-3 pt-4">
      <div className="pb-6">
        <Skeleton
          active
          avatar={{ shape: 'square', size: 100 }}
          paragraph={{ rows: 5 }}
        />
      </div>

      <div className="pb-6">
        <Skeleton
          active
          avatar={{ shape: 'square', size: 100 }}
          paragraph={{ rows: 5 }}
        />
      </div>
    </div>
  )
}

export default SkeletonsProposal

import React, { DetailedHTMLProps, ReactNode, useState } from 'react'
import { Button, Col, Form, Row } from 'antd'
import { EditFilled } from '@ant-design/icons'
import { Input } from 'components'
import { useStep } from 'hooks'
import locale from 'antd/es/date-picker/locale/pt_BR'
import Datepicker from 'components/Datepicker/Datepicker'
import CndForm from '../CndForm'
import ProposalEgiModel from 'egi/models/ProposalEgiModel'
import { useDispatch } from 'react-redux'
import { stepUpdateStepExpirationDate } from 'store/modules/step/actions'
import { IStep } from 'egi/types'

type CndExpirationDateIconProps = {
  children: ReactNode
  fontSize: string
  color?: 'green' | 'primary'
} & DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
const CndExpirationDateIcon = ({
  children,
  color = 'primary',
  fontSize,
  className,
  ...rest
}: CndExpirationDateIconProps) => {
  const colors = {
    green: 'cnd-review__expiration-icon--green',
    primary: 'cnd-review__expiration-icon--primary'
  }

  const styles = {
    fontSize
  }

  return (
    <div
      className={`cnd-review__expiration-icon ${colors[color] || ''} ${className || ''}`}
      style={styles}
      {...rest}
    >
      {children}
    </div>
  )
}

type CndAutomaticallyExpirationDateProps = {
  expirationDate: string | null
  dateFormItem: ReactNode
  onSave: () => void
  onCancel: () => void
  errors: any
}
const CndAutomaticallyExpirationDate = ({
  expirationDate,
  dateFormItem,
  onSave,
  onCancel,
  errors
}: CndAutomaticallyExpirationDateProps) => {
  // TODO - Until we decide the best way to handle CND with expiration date and steps
  const showDateInput = true

  const [isEditingInput, setIsEditingInput] = useState<boolean>(false)

  const onToogleExpirationDateInput = () => {
    setIsEditingInput(prev => !prev)
  }

  const errorMessage = (errors: IStep['form']) => {
    return ProposalEgiModel.getCndExpirationDateFromObject(errors)
  }

  if (showDateInput) {
    return <>
      {dateFormItem}
    </>
  }

  return (
    <Col lg={24} xs={24} sm={24}>
      <div className='cnd-review__expiration'>
        <h3 className='cnd-review__expiration-title'>
          Data de validade via integração API
        </h3>

        <p className='cnd-review__expiration-description'>
          O campo foi autopreenchido automaticamente com a data de validade pela API. Caso deseje alterar a data, clique no ícone <CndExpirationDateIcon
            className='cnd-review__expiration-icon--text mr-1'
            fontSize='1.1rem'
          >
            <EditFilled />
          </CndExpirationDateIcon> e, em seguida, em salvar.
        </p>

        <>
          <div
            className='cnd-review__expiration-edit-date-container'
            style={{
              display: isEditingInput ? 'block' : 'none'
            }}
          >
            {dateFormItem}

            <Col lg={24}>
              <div className='cnd-review__expiration-buttons-container'>
                <div className='cnd-review__expiration-save-button'>
                  <Button
                    onClick={() => {
                      onToogleExpirationDateInput()
                      onCancel()
                    }}
                    type='ghost'
                  >
                    Cancelar
                  </Button>
                </div>

                <div className='cnd-review__expiration-save-button'>
                  <Button
                    type='primary'
                    onClick={() => {
                      onToogleExpirationDateInput()
                      onSave()
                    }}
                  >
                    Salvar
                  </Button>
                </div>
              </div>
            </Col>
          </div>

          <div
            className='cnd-review__expiration-date-container'
            style={{
              display: isEditingInput ? 'none' : 'flex'
            }}
          >
            <p>Data de validade: {expirationDate}</p>
            <CndExpirationDateIcon
              fontSize='1.1rem'
              onClick={onToogleExpirationDateInput}
            >
              <EditFilled />
            </CndExpirationDateIcon>
          </div>

          {(errorMessage(errors) && !isEditingInput) && (
            <p className='cnd-review__expiration-error-message'>{errorMessage(errors)}</p>
          )}
        </>
      </div>

    </Col>
  )
}

const CdnReview = ({
  errors,
  updateExpirationsDate
}: {
  errors: any,
  updateExpirationsDate: (oldDate: string) => void
}) => {
  const step = useStep()

  const dispatch = useDispatch()
  const [dueDate, setDueDate] = useState<string>()

  const onUpdateStepExpirationDate = (newDate: string | undefined) => {
    if (!newDate) return null
    dispatch(stepUpdateStepExpirationDate(newDate))
  }

  const onUpdateDueDate = (newDate: string) => {
    setDueDate(newDate)
  }

  const onCancelExpirationDate = (oldDate: string | null) => {
    if (oldDate) updateExpirationsDate(oldDate)
  }

  const expirationDate = ProposalEgiModel.getCurrentCndExpirationDate(step.cnd)

  switch (step.slug) {
    case 'cnd-tributos-federais':
      return (
        <Row gutter={30}>
          <Col lg={12} xs={24}>
            <Form.Item
              name="cdtfEmissorForIssuer"
              help={errors.cdtfEmissorForIssuer && errors.cdtfEmissorForIssuer}
              validateStatus={errors.cdtfEmissorForIssuer && 'error'}
              label={<label>Data da emissão</label>}
            >
              <Datepicker locale={locale}/>
            </Form.Item>
          </Col>

          <Col lg={12} xs={24}>
            <Form.Item
              name="cdtfVerificationCodeForIssuer"
              help={errors.cdtfVerificationCodeForIssuer && errors.cdtfVerificationCodeForIssuer}
              validateStatus={errors.cdtfVerificationCodeForIssuer && 'error'}
              label={<label>Código de Verificação da Certidão</label>}
            >
              <Input
                placeholder={'Digite o Código de Verificação da Certidão'}
                error={errors.cdtfVerificationCodeForIssuer}
              />
            </Form.Item>
          </Col>

          <CndAutomaticallyExpirationDate
            onSave={() => onUpdateStepExpirationDate(dueDate)}
            onCancel={() => onCancelExpirationDate(expirationDate)}
            expirationDate={expirationDate}
            errors={errors}
            dateFormItem={
              <Col lg={12} xs={24}>
                <Form.Item
                  name="cdtfDueDateForIssuer"
                  help={errors.cdtfDueDateForIssuer && errors.cdtfDueDateForIssuer}
                  validateStatus={errors.cdtfDueDateForIssuer && 'error'}
                  label={<label>Validade da Certidão</label>}
                >
                  <Datepicker
                    locale={locale}
                    onChange={(date: string) => onUpdateDueDate(date)}
                  />
                </Form.Item>
              </Col>
            }
          />
        </Row>
      )
    case 'cnd-tributos-municipais':
      return <CndForm cndType="cndtm" errors={errors} />
    case 'cnd-tributos-imobiliarios':
      return (
        <Row gutter={30}>
          <Col lg={12} sm={24} xs={24}>
            <Form.Item
              name='cdtiNumberForIssuer'
              label={<label>Número da Certidão</label>}
              help={errors.cdtiNumberForIssuer && errors.cdtiNumberForIssuer}
              validateStatus={errors.cdtiNumberForIssuer && 'error'}
            >
              <Input placeholder='Digite o Número da Certidão' error={errors.cdtiNumberForIssuer} type={'text'}/>
            </Form.Item>
          </Col>

          <Col lg={12} sm={24} xs={24}>
            <Form.Item
              name="cdtiDateForIssuer"
              help={errors.cdtiDateForIssuer && errors.cdtiDateForIssuer}
              validateStatus={errors.cdtiDateForIssuer && 'error'}
              label={<label>Data de Emissão da Certidão</label>}
            >
              <Datepicker locale={locale} />
            </Form.Item>
          </Col>

          <CndAutomaticallyExpirationDate
            onSave={() => onUpdateStepExpirationDate(dueDate)}
            onCancel={() => onCancelExpirationDate(expirationDate)}
            expirationDate={expirationDate}
            errors={errors}
            dateFormItem={
              <Col lg={12} sm={24} xs={24}>
                <Form.Item
                  name="cdtiDueDateForIssuer"
                  help={errors.cdtiDueDateForIssuer && errors.cdtiDueDateForIssuer}
                  validateStatus={errors.cdtiDueDateForIssuer && 'error'}
                  label={<label>Data de Validade da Certidão</label>}
                >
                  <Datepicker locale={locale} />
                </Form.Item>
              </Col>
            }
          />
        </Row>
      )
    case 'matricula-do-imovel':
    case 'matricula-do-imovel-locado':
      return (
        <Row gutter={30}>
          <CndAutomaticallyExpirationDate
            onSave={() => onUpdateStepExpirationDate(dueDate)}
            onCancel={() => onCancelExpirationDate(expirationDate)}
            expirationDate={expirationDate}
            errors={errors}
            dateFormItem={
              <Col lg={12} sm={24} xs={24}>
                <Form.Item
                  name="dueDate"
                  help={errors.dueDate && errors.dueDate}
                  validateStatus={errors.dueDate && 'error'}
                  label={<label>Data de validade da matrícula</label>}
                >
                  <Datepicker locale={locale} />
                </Form.Item>
              </Col>
            }
          />
        </Row>
      )
  }

  return <></>
}

export default CdnReview

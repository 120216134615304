import { AxiosRequestConfig } from 'axios'
import { IResponseBase, Repository } from 'ecp/repositories/Repository'
import api from 'services/api'

class AdministratorsRepository extends Repository {
  async changeTeam (data: any, config?: AxiosRequestConfig) {
    return Repository.handle<IResponseBase>(() =>
      this.api.patch(`${this.path}/change-team/telemarketing`, data, config)
    )
  }
}

export default new AdministratorsRepository({ api, path: '/administrators' })

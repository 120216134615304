import React, { ReactElement, ReactNode, useState } from 'react'
import { WhatsAppOutlined, MailOutlined } from '@ant-design/icons'
import { Button, Input, message, Popover, Spin, Tooltip, Typography } from 'antd'
import copy from 'copy-to-clipboard'
import shareOnSocialMedia from 'utils/shareOnSocialMedia'
import { ButtonProps } from 'antd/lib/button'

interface Props {
  generateLink: ([...args]?: any) => Promise<any>
  phone?: string
  shareButtonText?: string
  icon?: React.ReactElement | React.ReactNode
  directSend?: () => Promise<void>
  inSteps?: boolean
  messagePrefix?: string
  email?: string
  small?: boolean
}

export function SmallButton ({ children, ...rest }: { children: ReactNode } & ButtonProps) {
  return (
    <Button
      className='share-button share-button--small'
      {...rest}
    >
      {children}
    </Button>
  )
}

function ShareButtons ({
  generateLink,
  phone,
  shareButtonText,
  icon,
  directSend,
  inSteps,
  email,
  messagePrefix = 'Olá, segue o link para enviar o(s) documento(s): ',
  small
}: Props): ReactElement {
  const [linkResponse, setLinkResponse] = useState('')
  const [hasError, setHasError] = useState(false)

  async function copyLink () {
    copy(linkResponse)
    message.success('Copiado')
  }

  async function getLink () {
    setLinkResponse('')
    setHasError(false)
    try {
      const link = await generateLink()
      if (!link) throw new Error('Link não encontrado')
      setLinkResponse(link as string)
      return link
    } catch (err) {
      setHasError(true)
      setLinkResponse(err.message)
    }
  }

  function sendMessageWhatsapp () {
    const message = messagePrefix + linkResponse

    if (phone) shareOnSocialMedia.whatsapp({ message, phoneNumber: phone })
    else {
      shareOnSocialMedia.whatsappNumberless(message)
    }
  }

  function sendMessageEmail () {
    const message = messagePrefix + linkResponse

    if (email) shareOnSocialMedia.email({ message, email })
  }

  const smallButtonClassName = small ? 'share-button--small' : ''

  return (
    <div>
      <Popover
        style={{ position: 'absolute', left: 0 }}
        content={
          <>
            {linkResponse || hasError ? (
              <>
                <Input
                  type='text'
                  readOnly
                  value={linkResponse}
                  id='clipboard-input'
                  style={{ width: '250px', marginRight: '5px' }}
                />
                {!hasError && (
                  <>
                    <Button type='primary' onClick={copyLink}>
                      Copiar link
                    </Button>

                    <Tooltip
                      title='Enviar link'
                      destroyTooltipOnHide={{ keepParent: false }}
                    >
                      {phone && (
                        <Button
                          type='ghost'
                          shape='circle'
                          className='share-button ml-2'
                          style={{ backgroundColor: 'rgb(0, 150, 136)' }}
                          icon={<WhatsAppOutlined style={{ color: '#ffff' }} />}
                          onClick={sendMessageWhatsapp}
                        />
                      )}

                      {!!email && (
                        <Button
                          type='ghost'
                          shape='circle'
                          className='share-button ml-2'
                          style={{ backgroundColor: 'rgb(185, 41, 41)' }}
                          icon={<MailOutlined style={{ color: '#ffff' }} />}
                          onClick={sendMessageEmail}
                        />
                      )}
                    </Tooltip>
                  </>
                )}
              </>
            ) : (
              <>
                <Spin className='mr-2' />
                <Typography.Text type='secondary'>Gerando Link</Typography.Text>
              </>
            )}
          </>
        }
        trigger='click'
      >
        <Button
          className={!inSteps ? `share-button ${smallButtonClassName ?? ''}` : `share-steps-button ${smallButtonClassName ?? ''}`}
          type='primary'
          style={{ borderRadius: '40px' }}
          onClick={directSend || getLink}
          icon={icon}
        >
          {shareButtonText || 'Gerar link'}
        </Button>
      </Popover>
    </div>
  )
}

export default ShareButtons

import React, { useState, Fragment, useEffect } from 'react'
import { Form, Button, Row, Col, Tooltip, Radio, Typography, Divider, Space, message, Spin } from 'antd'
import { Link, useHistory } from 'react-router-dom'
import { InfoCircleFilled, LockOutlined } from '@ant-design/icons'
import { RadioChangeEvent } from 'antd/lib/radio'
import { useHostConfig } from 'hooks'
import queryString from 'query-string'
import { validateResponse } from 'utils/validate'
import format from 'utils/format'
import swal, { IBasic, IConfirm } from 'utils/swal'
import SlugInput from 'components/Inputs/SlugInput/SlugInput'
import InputPassword from 'components/Inputs/InputPassword/InputPassword'
import { correctTimezone } from 'utils/time'
import { owaspVerify } from 'utils/owasp'
import useInvite from './hooks/useInvite'
import SignupLayout from 'layouts/SignupLayout/SignupLayout'
import SignupCheckbox from 'layouts/SignupLayout/components/SignupCheckbox/SignupCheckbox'
import { UNAUTHS_PATHS } from 'routes/unauthRoutes'
import { PASSWORD_HELP_TEXT } from './signupConstants'
import { useForm } from 'antd/lib/form/Form'
import SignupButton from './components/SignupButton/SignupButton'
import JuridicalForm from './components/JuridicalForm/JuridicalForm'
import TooltipPassword from './components/TooltipPassword/TooltipPassword'
import locale from 'antd/es/date-picker/locale/pt_BR'
import SignupInviteMessages, { SignupInviteAlert } from './components/SignupInviteMessages/SignupInviteMessages'
import { SignupSubTitle, SignupTitle } from './components/SignupTitle/SignupTitle'
import { ISignupValues } from './signupInterfaces'
import SignupRepository from 'egi/repositories/SignupRepository'
import { ConfirmationEmailModal } from './components/ConfirmationEmailModal/ConfirmationEmailModal'
import { PreSignupModal } from './components/PreSignupModal/PreSignupModal'
import SignupModel from 'egi/models/SignupModel'
import Modal from 'components/Modal/Modal'
import LgpdText from 'components/LgpdText/LgpdText'
import WarningText from 'components/WarningText/WarningText'
import { IDigitador, useTypist } from './hooks/useTypist'
import SignupECPFRepository from 'ecpf/repositories/SignupECPFRepository'
import imagesUrls from 'utils/imagesUrls'
import { FullScreenPageLogo } from 'layouts/FullScreenLoading/FullScreenLoading'
import { Input } from 'components'
import Datepicker from 'components/Datepicker/Datepicker'
import { isLocal } from 'utils/globals'
import useAuthLayout from 'hooks/useAuthLayout'

const BASE_VINCULATE = { value: '', pastBlurValue: '' }

function Signup () {
  const [email, setEmail] = useState(BASE_VINCULATE)
  const [cpf, setCpf] = useState(BASE_VINCULATE)

  const [isVinculationEmail, setIsVinculationEmail] = useState(false)
  const [isVinculationCpf, setIsVinculationCpf] = useState(false)

  const [loading, setLoading] = useState<boolean>(false)
  const [confirmationModalVisible, setConfirmationModalVisible] = useState<boolean>(false)
  const [errors, setErrors] = useState<{ [key in keyof ISignupValues]?: string }>({})
  const [check, setCheck] = useState<boolean>(false)
  const [term, setTerm] = useState<boolean>(false)
  const [personType, setPersonType] = useState<'fisica' | 'juridica'>('fisica')
  const [passwordError, setPasswordError] = useState<string | undefined>(undefined)

  const [formRef] = useForm()
  const hostConfig = useHostConfig()
  const history = useHistory()

  const query = queryString.parse(history.location.search)
  const { simulationId, inviteToken } = query || {}

  const juridicoTypes = ['CB', 'IN', 'MP']
  const isVinculationFlux = isVinculationCpf || isVinculationEmail
  const {
    data: invite,
    error: inviteError,
    isInvited
  } = useInvite({ token: inviteToken || '' })

  const { handleSigninByAuth } = useAuthLayout()

  const {
    getTypist,
    errorMessage: typistErrorMessage,
    loading: loadingTypist,
    typist
  } = useTypist()

  const generateFormData = (values: ISignupValues, typist?: IDigitador) => {
    const query = queryString.parse(history.location.search)
    const formData = new FormData()

    const data: ISignupValues = {
      ...values,
      personType: personType,
      term: check
    }

    if (data.cpf) data.cpf = format.onlyDigits(data.cpf)
    if (data.cnpj) data.cnpj = format.onlyDigits(data.cnpj)
    if (data.cellphone) data.cellphone = format.onlyDigits(data.cellphone)
    if (data.birthdate) data.birthdate = correctTimezone(data.birthdate)
    if (data.foundationDate) data.foundationDate = correctTimezone(data.foundationDate)

    if (typist?.nomeResumido) formData.append('nomeResumido', typist.nomeResumido)
    if (typist?.codigo) formData.append('codigo', typist.codigo)

    for (const item in data) {
      if (data[item]) formData.append(item as string, data[item] as string)
    }

    for (const item in query) {
      formData.append(item as string, String(query[item]))
    }

    formData.append('corbanId', hostConfig.id)
    return formData
  }

  const redirectToMainPage = () => {
    history.push(UNAUTHS_PATHS.MAIN)
  }

  const vinculateSwalTimer = (message: string) => {
    const timerResponseContent: IConfirm = {
      title: 'Sucesso!',
      text: message,
      icon: 'success',
      confirm: () => {
        redirectToMainPage()
      }
    }

    swal.timerSwal(timerResponseContent)

    setTimeout(() => {
      redirectToMainPage()
    }, 4000)
  }

  const onVinculate = async (values: ISignupValues) => {
    setLoading(true)
    try {
      const formData = generateFormData(values)

      const requestConfig = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }

      const response = await SignupRepository.association(formData, requestConfig)
      if (response.data?.message) vinculateSwalTimer(response.data?.message)

      setErrors({})
    } catch (err) {
      if (err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
        swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      } else {
        setErrors({})
        swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      }
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = async (values: ISignupValues) => {
    setLoading(true)
    try {
      const formData = generateFormData(values)

      const requestConfig = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }

      await SignupRepository.create(formData, requestConfig)
      setConfirmationModalVisible(true)
      setErrors({})
    } catch (err) {
      const errorSwal: IBasic = { title: 'Atenção', text: err?.message, icon: 'warning' }
      if (err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
        swal.basic(errorSwal)
      } else {
        setErrors({})
        swal.basic(errorSwal)
      }
    } finally {
      setLoading(false)
    }
  }

  async function onSubmitFuncao (values: ISignupValues, typist: IDigitador) {
    try {
      setLoading(true)
      setErrors({})

      const formData = generateFormData(values, typist)

      const requestConfig = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }

      const response = await SignupECPFRepository.signup(formData, requestConfig)

      const auth = response.data.data
      if (auth?.token) await handleSigninByAuth(auth)
      setConfirmationModalVisible(true)
    } catch (err) {
      if (err?.data?.invalid) setErrors(validateResponse(err.data.invalid))
      swal.basic({ title: 'Atenção', text: err?.message, icon: 'warning' })
    } finally {
      setLoading(false)
    }
  }

  async function onAssociateFuncao (values: ISignupValues, typist: IDigitador) {
    try {
      setLoading(true)
      setErrors({})

      const formData = generateFormData(values, typist)

      const requestConfig = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }

      const response = await SignupECPFRepository.associate(formData, requestConfig)
      const auth = response.data.data

      if (auth?.token) await handleSigninByAuth(auth)
    } catch (err) {
      if (err?.data?.invalid) setErrors(validateResponse(err.data.invalid))
      swal.basic({ title: 'Atenção', text: err?.message, icon: 'warning' })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (inviteError === false) {
      if ((invite.promoterType === 'CB' && invite.inviterLevel !== 'promoter') || juridicoTypes.includes(invite.promoterType)) {
        formRef.setFieldsValue({ personType: 'juridica' })
        setPersonType('juridica')
      }
    }
  }, [
    invite.promoterType,
    invite.inviterLevel,
    inviteError
  ])

  async function verifyUserExist ({ email, cpf, isVinculationFlux }: { email?: string, cpf?: string, isVinculationFlux: boolean }) {
    try {
      let response
      if (email) response = await SignupRepository.verifyAssociation({ params: { identifier: email.trim() } })
      if (cpf) response = await SignupRepository.verifyAssociation({ params: { identifier: cpf } })

      const exists = response?.data?.data?.exists || false

      if (email) setIsVinculationEmail(exists)
      if (cpf) setIsVinculationCpf(exists)

      if (exists === true && !isVinculationFlux) swal.basic({ title: 'Atenção', text: response?.data?.message || '', icon: 'success' })
    } catch (err) {
      console.error(err)
    }
  }

  const fetchTypist = async (identifier: string) => {
    try {
      const typist = await getTypist(String(identifier))

      formRef.setFieldsValue({
        name: typist?.nomeCompleto,
        email: typist?.email,
        cpf: typist?.documento
      })

      if (typist?.email) setEmail(prev => ({ ...prev, value: typist.email }))

      await verifyUserExist({ email: typist?.email, isVinculationFlux: false })
      await verifyUserExist({ cpf: format.onlyDigits(typist?.documento || ''), isVinculationFlux: false })
    } catch (err) {
      message.error(err?.message)
    }
  }

  useEffect(() => {
    const { identifier } = query || {}
    if (SignupModel.isFuncaoVinculateUser(query) && identifier) {
      fetchTypist(String(identifier))
    }
  }, [])

  function handleChangePersonType () {
    setIsVinculationCpf(false)
    setIsVinculationEmail(false)

    setEmail(prev => ({ ...prev, pastBlurValue: '', value: '' }))
    formRef.resetFields()
  }

  const handleCpfChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCpf(prev => ({ ...prev, value: format.onlyDigits(event.target.value) }))
  }

  const handleCpfBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (cpf.value !== cpf.pastBlurValue) verifyUserExist({ cpf: format.onlyDigits(event.target.value), isVinculationFlux })
    if (event.target.value === '') setIsVinculationCpf(false)
    setCpf(prev => ({ ...prev, pastBlurValue: format.onlyDigits(event.target.value) }))
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(prev => ({ ...prev, value: event.target.value }))
  }

  const handleEmailBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (email.value !== email.pastBlurValue) verifyUserExist({ email: event.target.value, isVinculationFlux })
    if (event.target.value === '') setIsVinculationEmail(false)
    setEmail(prev => ({ ...prev, pastBlurValue: event.target.value }))
  }

  if (loadingTypist) {
    return (
      <SignupLayout>
        <div className='text-center'>
          <div>
            <FullScreenPageLogo logo={imagesUrls.rodobensLogoLight} />
          </div>
          <p className='mb-4'>Aguarde um momento enquanto validamos suas informações...</p>
          <Spin />
        </div>
      </SignupLayout>
    )
  }

  if (typistErrorMessage !== '') {
    return (
      <SignupLayout>
        <div className='text-center'>
          <div>
            <FullScreenPageLogo logo={imagesUrls.rodobensLogoLight} />
          </div>

          <div>
            <SignupTitle>Ops, parece que algo deu errado</SignupTitle>
            <p className='mb-4'>{typistErrorMessage}</p>
          </div>

          <div>
            <Button
              onClick={() => {
                const { identifier } = query || {}
                if (identifier) {
                  fetchTypist(String(identifier))
                }
              }}
              type='primary'
            >
              Tentar novamente
            </Button>
          </div>
        </div>
      </SignupLayout>
    )
  }

  return (
    <Fragment>
      <Modal
        title="TERMO DE ACEITE PARA TRATAMENTO DE DADOS PESSOAIS"
        closable
        visible={term}
        onOk={() => setTerm(false)}
        onCancel={() => setTerm(false)}
        destroyOnClose={true}
        footer={[
          <Button
            type="ghost"
            key="back"
            onClick={() => setTerm(false)}
          >
            Voltar
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => {
              setTerm(false)
              setCheck(true)
            }}
          >
            Aceitar
          </Button>
        ]}
      >
        <LgpdText />
      </Modal>

      <ConfirmationEmailModal
        onCancel={() => setConfirmationModalVisible(false)}
        visible={confirmationModalVisible}
        email={email.value}
      />

      {simulationId && (
        <PreSignupModal
          simulationId={simulationId as string}
          onFetchLead={lead => {
            if (lead.email) setEmail({ ...email, value: lead.email })
            if (formRef) formRef.setFieldsValue(lead)
          }}
        />
      )}

      <SignupLayout>
        {isInvited && (
          <div className='mb-6'>
            <SignupInviteAlert inviterName={invite.name}>
              <SignupInviteMessages
                level={invite.level}
                inviterName={invite.inviterName}
                areaName={invite.areaName}
                promoterType={invite.promoterType}
                inviterLevel={invite.inviterLevel}
              />
            </SignupInviteAlert>
          </div>
        )}

        <SignupTitle>Preencha o formulário abaixo para realizar seu cadastro:</SignupTitle>

        {SignupModel.isFuncaoVinculateUser(query) && (
          <div className='mb-3 review-backoffice-expired-proposal'>
            <WarningText
              type='info'
              title='Primeiro acesso'
            >
              Identificamos que é seu primeiro acesso de corban/operador em nosso sistema. Preencha os campos para realizar a vinculação da sua conta.
            </WarningText>
          </div>
        )}

        <Form
          form={formRef}
          layout="vertical"
          onFinish={values => {
            if (isVinculationFlux && SignupModel.isFuncaoVinculateUser(query) && typist) {
              return onAssociateFuncao(values, typist)
            }

            if (SignupModel.isFuncaoVinculateUser(query) && typist) {
              return onSubmitFuncao(values, typist)
            }

            if (isVinculationFlux) {
              return onVinculate(values)
            }

            onSubmit(values)
          }}
        >
          {SignupModel.canChooseCnpjCpfToogle(invite) && (
            <Row className="w-100" align="middle" justify="center">
              <Form.Item
                name='personType'
                label={<label className="simulator-label color-primary">Tipo de pessoa:</label>}
                className="w-100"
              >
                <Radio.Group
                  onChange={(event: RadioChangeEvent) => setPersonType(event.target.value)}
                  className="w-100 simulator-radio"
                  defaultValue="fisica"
                  size="large"
                  buttonStyle="outline"
                >
                  <Radio.Button
                    id="simulator-radio-itens-fisica"
                    className="simulator-radio-itens-values"
                    value="fisica"
                    onClick={handleChangePersonType}
                  >
                    Física
                  </Radio.Button>

                  <Radio.Button
                    id="simulator-radio-itens-juridica"
                    className="simulator-radio-itens-values"
                    value="juridica"
                    onClick={handleChangePersonType}
                  >
                    Jurídica
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Row>
          )}

          {SignupModel.canSeeOnidataVinculationButton(invite) && (
            <Fragment>
              <Space className='signup__onidata-help'>
                <InfoCircleFilled className='signup__onidata-icon' />

                <Typography.Paragraph className='signup__onidata-paragraph'>
                  Já possui uma conta no <b>canal rodobens</b> do produto consignado privado ? <Link
                    className='signup__link'
                    to={{
                      pathname: UNAUTHS_PATHS.ECP_FORGOT,
                      search: history.location.search
                    }}
                  >
                    Vincular minha conta do canal rodobens &rarr;
                  </Link>
                </Typography.Paragraph>
              </Space>

              <Divider>OU</Divider>
            </Fragment>
          )}

          <Row gutter={[15, 0]}>
            {personType === 'fisica' && (
              <>
                <Col lg={24} sm={24} xs={24}>
                  <Form.Item
                    name="name"
                    label={<label className="simulator-label">Nome</label>}
                    help={errors.name && errors.name}
                    validateStatus={errors.name && 'error'}
                  >
                    <Input
                      disabled={Boolean(SignupModel.isFuncaoVinculateUser(query) && typist?.nomeCompleto)}
                      className="unauth-inputs"
                      placeholder="Digite seu nome"
                    />
                  </Form.Item>
                </Col>

                <Col lg={24} sm={24} xs={24}>
                  <Form.Item
                    name="email"
                    help={errors.email && errors.email}
                    validateStatus={errors.email && 'error'}
                    label={<label className="simulator-label">Email</label>}
                  >
                    <Input
                      disabled={Boolean(SignupModel.isFuncaoVinculateUser(query) && typist?.email) && !isLocal}
                      className="unauth-inputs"
                      placeholder="Digite seu email"
                      error={errors.email}
                      onChange={handleEmailChange}
                      onBlur={handleEmailBlur}
                    />
                  </Form.Item>
                </Col>

                <Col lg={12} sm={24} xs={24}>
                  <Form.Item
                    name="cpf"
                    label={<label className="simulator-label">CPF</label>}
                    help={errors.cpf && errors.cpf}
                    validateStatus={errors.cpf && 'error'}
                  >
                    <Input
                      className="unauth-inputs"
                      placeholder="Digite seu cpf"
                      mask="cpf"
                      disabled={Boolean(SignupModel.isFuncaoVinculateUser(query) && typist?.documento)}
                      error={errors.cpf}
                      onChange={handleCpfChange}
                      onBlur={handleCpfBlur}
                    />
                  </Form.Item>
                </Col>

                <Col lg={12} sm={24} xs={24}>
                  <Form.Item
                    name="cellphone"
                    label={<label className="simulator-label">Celular</label>}
                    help={errors.cellphone && errors.cellphone}
                    validateStatus={errors.cellphone && 'error'}
                  >
                    <Input
                      className="unauth-inputs"
                      placeholder="Digite seu celular"
                      mask="phone"
                      error={errors.cellphone}
                    />
                  </Form.Item>
                </Col>

                {!simulationId && (
                  <Col lg={12} sm={24} xs={24}>
                    <Form.Item
                      name="birthdate"
                      label={<label className="simulator-label">Data de nascimento</label>}
                      help={errors.birthdate && errors.birthdate}
                      validateStatus={errors.birthdate && 'error'}
                      rules={[{ required: true, message: 'Escolha sua data de nascimento!' }]}
                    >
                      <Datepicker
                        className="unauth-inputs"
                        locale={locale}
                      />
                    </Form.Item>
                  </Col>
                )}
              </>
            )}

            {SignupModel.isFuncaoVinculateUser(query) && (
              <>
                <SignupSubTitle>Informações da empresa</SignupSubTitle>

                <JuridicalForm
                  companyName='companyName'
                  errors={errors}
                  simulationId={simulationId}
                />
              </>
            )}

            {personType === 'juridica' && (
              <>
                <JuridicalForm
                  companyName='name'
                  errors={errors}
                  simulationId={simulationId}
                />

                <Col lg={24} sm={24} xs={24}>
                  <Form.Item
                    name="email"
                    help={errors.email && errors.email}
                    validateStatus={errors.email && 'error'}
                    label={<label className="simulator-label">Email</label>}
                  >
                    <Input
                      className="unauth-inputs"
                      placeholder="Digite o email"
                      error={errors?.email}
                      onChange={handleEmailChange}
                      onBlur={handleEmailBlur}
                    />
                  </Form.Item>
                </Col>

                <Col lg={24} sm={24} xs={24}>
                  <Form.Item
                    name="cellphone"
                    label={<label className="simulator-label">Celular</label>}
                    help={errors.cellphone && errors.cellphone}
                    validateStatus={errors.cellphone && 'error'}
                  >
                    <Input
                      className="unauth-inputs"
                      placeholder="Digite o celular"
                      mask="phone"
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>

          {SignupModel.canSeeSlugCampaignField(invite) && (
            <Row className="px-1">
              <Col lg={24}>
                <Form.Item
                  name="slug"
                  help={errors.slug && (errors.slug)}
                  validateStatus={errors.slug && ('error')}
                  label={
                    <Fragment>
                      <label className="simulator-label">Slug da campanha (Usado para geração de links de campanha)</label>
                      <Tooltip
                        destroyTooltipOnHide={{ keepParent: false }}
                        title={<label>Permitido apenas <b>letras minúsculas</b>, <b>números</b> e <b>-</b></label>}
                      >
                        <InfoCircleFilled className="mx-1 color-primary" />
                      </Tooltip>
                    </Fragment>
                  }
                >
                  <SlugInput
                    formRef={formRef}
                    field='slug'
                    className="unauth-inputs"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          {!isVinculationFlux && (
            <Row>
              <Col lg={24} sm={24} xs={24} className="px-1">
                <Form.Item
                  name="password"
                  label={<TooltipPassword />}
                  help={errors.password && errors.password}
                  validateStatus={errors.password && 'error'}
                  rules={[
                    () => ({
                      validator (_, value) {
                        if (!owaspVerify(value)) {
                          setPasswordError(PASSWORD_HELP_TEXT)
                          return Promise.resolve()
                        }

                        setPasswordError(undefined)
                        return Promise.resolve()
                      }
                    })
                  ]}
                >
                  <InputPassword
                    className="unauth-inputs"
                    placeholder="Senha"
                    prefix={<LockOutlined />}
                  />
                </Form.Item>
              </Col>

              {passwordError && (
                <Col lg={24} sm={24} xs={24} className="px-1">
                  <p className="color-primary font-500">
                    {passwordError}
                  </p>
                </Col>
              )}

              <Col lg={24} sm={24} xs={24} className="px-1">
                <Form.Item
                  name="confirmPassword"
                  label={<label className="simulator-label">Confirmação de senha</label>}
                  help={errors.confirmPassword && errors.confirmPassword}
                  validateStatus={errors.confirmPassword && 'error'}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator (_, value) {
                        if (!value) {
                          setPasswordError(PASSWORD_HELP_TEXT)
                          return Promise.resolve()
                        }

                        if (getFieldValue('password') !== value) {
                          return Promise.resolve()
                        }

                        setPasswordError(undefined)
                        return Promise.resolve()
                      }
                    })
                  ]}
                >
                  <InputPassword
                    className="unauth-inputs"
                    placeholder="Confirmar senha"
                    prefix={<LockOutlined />}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Form.Item
            name="term"
            help={errors.term && errors.term}
            validateStatus={errors.term && 'error'}
          >
            <SignupCheckbox
              checked={check}
              onClick={() => setCheck(prev => !prev)}
            >
              Aceito os <a className='signup__link' onClick={() => setTerm(true)}>termos de política e privacidade</a>
            </SignupCheckbox>
          </Form.Item>

          <Form.Item>
            <SignupButton
              loading={loading}
              htmlType="submit"
              className='w-100'
            >
              {isVinculationFlux ? 'Vincular' : 'Cadastrar'}
            </SignupButton>
          </Form.Item>
        </Form>

        <div className='text-center'>
          <Link
            to={'/' + history.location.search}
            className="signup__already-have-account"
          >
            Já tem um cadastro? <b className="underline">Entrar</b>
          </Link>
        </div>
      </SignupLayout>
    </Fragment>
  )
}

export default Signup

import React, { Fragment, MutableRefObject, useLayoutEffect, useRef, useState } from 'react'
import { Button, Form, Select } from 'antd'
import { useProposal } from 'hooks'
import { simulation as simulationConsumer } from 'egi/consumers'
import swal from 'utils/swal'
import { openTypeDrawer } from '../../types'
import { Table } from 'components'
import { IPartnerReturValues } from 'egi/types/IPartnerReturn'
import { columns } from './dataTable'
import format from 'utils/format'
import SimulationsRepository from 'egi/repositories/SimulationsRepository'

function CommissionChange ({ type, callbackFunction }: { type?: openTypeDrawer, callbackFunction: Function }) {
  const proposal = useProposal()

  const [loading, setLoading] = useState<boolean>(false)
  const [updateloading, setUpdateLoading] = useState<boolean>(false)

  const [values, setValues] = useState<IPartnerReturValues[]>()
  const [rValues, setRValues] = useState<Array<string>>()

  const formRef: MutableRefObject<any> = useRef(null)

  useLayoutEffect(() => {
    async function fetchCommissionPartners () {
      setLoading(true)
      if (!proposal.id) return
      try {
        const response = await simulationConsumer.partnerReturns({ proposalId: proposal.id })

        const { returnValues } = response.data.partnerReturn

        const formatValue = (item: number) => `${format.decimalToPercentage(Number(item)).toFixed(2)} %`

        const onlyRs = returnValues.map((item: IPartnerReturValues) => item.name)

        const formatedValues = returnValues.map((item: IPartnerReturValues) => ({
          ...item,
          value: formatValue(item.value),
          commission: formatValue(item.commission),
          consultor: formatValue(item.consultor),
          marketPlace: formatValue(item.marketPlace),
          indicador: formatValue(item.indicador),
          corban: formatValue(item.corban)
        }))

        setRValues(onlyRs)
        setValues(formatedValues)
      } catch (err) {
        swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      }
      setLoading(false)
    }

    if (type === 'commission') {
      fetchCommissionPartners()

      if (formRef && formRef.current && proposal.simulation) {
        formRef.current.setFieldsValue({ returnName: proposal.simulation.returnName })
      }
    }
  }, [type])

  async function updateCommissionR (values: { returnName: string }) {
    if (!proposal.id) return
    setUpdateLoading(true)
    try {
      const response = await SimulationsRepository.returnsUpdate({ returnName: values.returnName, proposalId: proposal.id })
      callbackFunction()
      swal.basic({ title: 'Atenção', text: response.data.message, icon: 'success' })
    } catch (err) {
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    }
    setUpdateLoading(false)
  }

  return (
    <Fragment>
      <h2 className="color-secondary mb-0">Valores de comissão disponiveis</h2>

      <Table
        canSearch={false}
        data={values || []}
        loading={loading}
        columns={columns}
      />

      <div className="text-center">
        <div className="w-50 w-md-100 m-auto p-3 card-form-commission">
          <Form
            ref={formRef}
            layout="vertical"
            onFinish={updateCommissionR}
          >
            <Form.Item
              className="text-center"
              name='returnName'
              label={<label className="text-center">Selecione o R</label>}
            >
              <Select
                id="test-select-maritalStatus"
                placeholder='Escolha o R'
              >
                {rValues && rValues.length > 0 && rValues.map(item => (
                  <Select.Option
                    key={item}
                    value={item}
                  >
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item className="text-center">
              <Button
                loading={updateloading}
                disabled={loading}
                type="primary"
                htmlType="submit"
                className="w-100"
              >
                Atualizar
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Fragment>

  )
}

export default CommissionChange

import React, { Fragment, MutableRefObject, useLayoutEffect, useRef, useState } from 'react'
import { Col, Form, Row, Select } from 'antd'
import MlmProposalsMinorDashCards from '../../components/MlmProposalsMinorDashCards/MlmProposalsMinorDashCards'
import { DatepickerCustom } from 'components'
import locale from 'antd/es/date-picker/locale/pt_BR'
import { timeAsDayjs } from 'utils/time'
import dayjs, { Dayjs } from 'dayjs'
import MlmWeekly from '../../components/MlmWeekly/MlmWeekly'
import MlmDashboardTop from '../../components/MlmDashboardTop/MlmDahsboardTop'
import { useAuth, useSelectedProduct } from 'hooks'
import { resources } from 'egi/consumers'
import { TMlmObjectFilters } from './types'
import { pendingChat } from 'store/modules/chat/actions'
import { useDispatch } from 'react-redux'
import DashGeneratedLinks from '../../components/MlmDashGeneratedLinks/MlmDashGeneratedLinks'
import MlmMonthlyClicks from '../../components/MlmMonthlyClicks/MlmMonthlyClicks'
import MlmDashboardAreasProposals from '../../components/MlmDashboardAreasProposals/MlmDashboardAreasProposals'
import TotalLeads from '../../components/MlmTotalLeads/MlmTotalLeads'
import FirstStepLeads from '../../components/MlmFirstStepLeads/MlmFirstStepLeads'
import SecondStepLeads from '../../components/MlmSecondStepsLeads/MlmSecondStepLeads'
import DashboardHistory from '../../components/DashboardHistory/DashboardHistory'
import MlmLeadsFunnel from '../../components/MlmLeadsChartPie/MlmLeadsChartPie'
import { _userLevel } from 'ecp/models/UsersModel'
import MlmDashboardModel from 'egi/models/MlmDashboardModel'

function MlmDashboard () {
  const user = useAuth()
  const dispatch = useDispatch()

  const [promoterType, setPromoterType] = useState([])
  const [type, setType] = useState<string | undefined>(undefined)
  const [createdDate, setCreatedDate] = useState<Dayjs>(dayjs())
  const [levelFilter, setLevelFilter] = useState<{promoterId?: string, sellerId?: string}>({})
  const [selectValue, setSelect] = useState([])
  const { _id: productId } = useSelectedProduct()

  const formattedDate = timeAsDayjs(createdDate).format('MM-YYYY')
  const objectFilters: TMlmObjectFilters = Object.assign({ createdDate: formattedDate, productId, type }, levelFilter)

  const PROMOTER_IN = (user.level === 'promoter' && user.type === 'IN')

  const canSeeSelect = MlmDashboardModel.canSeeSelect({ userLevel: user.level, userType: user.type, areaName: user.areaName })
  const canSeeProviderTypes = MlmDashboardModel.canSeeProviderTypes({ userLevel: user.level, userType: user.type, areaName: user.areaName })

  const formRef: MutableRefObject<any> = useRef(null)

  const getPromoters = async ({ type }: {type: string| undefined}) => {
    try {
      const response = await resources.promoters({ types: type })
      const promoters = response.data.promoters
      setSelect(promoters)
    } catch (err) {
      console.error(err)
    }
  }

  useLayoutEffect(() => {
    const getSeller = async () => {
      try {
        const response = await resources.seller()
        const sellers = response.data.sellers
        setSelect(sellers)
      } catch (err) {
        console.error(err)
      }
    }
    if (PROMOTER_IN) getSeller()
  }, [])

  useLayoutEffect(() => {
    const getPromoterType = async () => {
      try {
        const response = await resources.promoterType({ params: { all: true } })
        const types = response.data.types
        setPromoterType(types)
      } catch (err) {
        console.error(err)
      }
    }

    if (canSeeProviderTypes) {
      getPromoters({ type: undefined })
      getPromoterType()
    }

    dispatch(pendingChat())
  }, [])

  useLayoutEffect(() => {
    if (!type && !PROMOTER_IN) getPromoters({ type: undefined })
    if (type && formRef && formRef.current) {
      formRef.current.resetFields()
      getPromoters({ type: type })
      setLevelFilter({})
    }
  }, [type])

  function selectText (level: _userLevel | undefined) {
    if (!level) return ''

    switch (level) {
      case 'master':
      case 'administrator':
      case 'commercial':
      case 'auditor':
        return 'Selecione o parceiro'
      case 'promoter':
        return 'Selecione o vendedor'
      default:
        return ''
    }
  }

  return (
    <Fragment>
      <Row gutter={[15, 15]}>
        <Col lg={4} md={12} sm={24} xs={24}>
          <DatepickerCustom.MonthPicker
            onChange={(value: any) => setCreatedDate(value)}
            value={createdDate}
            locale={locale}
            disabledDate={(current) => current.isAfter(new Date())}
            format='MM/YYYY'
            allowClear={false}
          />
        </Col>

        {canSeeProviderTypes && (
          <Col lg={4} md={24} sm={24} xs={24}>
            <Select
              optionFilterProp="children"
              onChange={(value: any) => {
                if (!value) setType(undefined)
                if (value) setType(value)
              }}
              className="w-100"
              placeholder="Selecione o tipo do parceiro"
              showSearch
              allowClear
            >
              <Select.Option value="">Todos</Select.Option>
              {promoterType.map((item: any) => {
                return <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
              })}
            </Select>
          </Col>
        )}

        {canSeeSelect && (
          <Col lg={4} md={24} sm={24} xs={24} className="searchble-focus-select">
            <Form ref={formRef}>
              <Form.Item name="promoterName" className="m-0">
                <Select
                  optionFilterProp="children"
                  onChange={(value: any) => {
                    if (!value) setLevelFilter({})
                    if (value) {
                      if (canSeeSelect) setLevelFilter({ promoterId: value })
                      if (PROMOTER_IN) setLevelFilter({ sellerId: value })
                    }
                  }}
                  className="w-100"
                  placeholder={selectText(user.level)}
                  showSearch
                  allowClear
                >
                  <Select.Option value="">Todos</Select.Option>
                  {selectValue.map((item: any) => {
                    return (
                      <Select.Option
                        key={item._id}
                        value={item._id}
                      >
                        {type ? `${item.name}` : item.type ? `${item.type} - ${item.name}` : `${item.name}`}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Form>
          </Col>
        )}
      </Row>

      {MlmDashboardModel.canSeeLinks({ userLevel: user.level, userType: user.type, type }) && (
        <Row gutter={[15, 15]}>
          <Col lg={6} md={24} sm={24} xs={24}>
            <Row className="h-100">
              <Col lg={24} md={24} sm={24} xs={24} className="mb-3">
                <DashGeneratedLinks filterDate={objectFilters} />
              </Col>

              <Col lg={24} md={24} sm={24} xs={24}>
                <MlmMonthlyClicks filterDate={objectFilters} />
              </Col>
            </Row>
          </Col>

          <Col lg={9} md={24} sm={24} xs={24}>
            <MlmWeekly filterDate={objectFilters} />
          </Col>

          {MlmDashboardModel.canSeeTopChart(user.level, levelFilter.sellerId) && (
            <Col lg={9} md={24} sm={24} xs={24}>
              <MlmDashboardTop filterDate={objectFilters} />
            </Col>
          )}
        </Row>
      )}

      {MlmDashboardModel.canSeeLeads({ userLevel: user.level, userType: user.type, type }) && (
        <Row gutter={[15, 15]}>
          <Col lg={5} md={24} sm={24} xs={24}>
            <div className="dashboard-status-card h-100">
              <FirstStepLeads filterDate={objectFilters} />
            </div>
          </Col>

          <Col lg={5} md={24} sm={24} xs={24}>
            <div className="dashboard-status-card h-100">
              <SecondStepLeads filterDate={objectFilters} />
            </div>
          </Col>

          <Col lg={5} md={24} sm={24} xs={24}>
            <div className="dashboard-status-card h-100">
              <TotalLeads filterDate={objectFilters} />
            </div>
          </Col>

          <Col lg={9} md={24} sm={24} xs={24}>
            <MlmLeadsFunnel filterDate={objectFilters} />
          </Col>
        </Row>
      )}

      <MlmProposalsMinorDashCards filterDate={objectFilters} />

      <Row gutter={[15, { lg: 0, xl: 15 }]}>
        <DashboardHistory filterDate={objectFilters} />
        <MlmDashboardAreasProposals filterDate={objectFilters} />
      </Row>
    </Fragment>
  )
}

export default MlmDashboard

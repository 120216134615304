import React from 'react'
import { Form, Row, Col } from 'antd'
import { Input, DatepickerCustom } from 'components'
import locale from 'antd/es/date-picker/locale/pt_BR'
import { FormInstance } from 'antd/lib/form'
import { IAddNewClientErros } from 'egi/app/Leads/leadInterfaces'
import SimulationModel from 'egi/models/SimulationModel'

interface IClientForm {
  form: FormInstance<any>
  onFinish: (values: any) => void
  errors?: IAddNewClientErros
  personType: string | undefined

  onCpfBlur: (event: React.ChangeEvent<HTMLInputElement>) => void
  onEmailBlur: (event: React.ChangeEvent<HTMLInputElement>) => void
}

function ClientForm ({ form, onFinish, personType, errors, onCpfBlur, onEmailBlur }: IClientForm) {
  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
    >
      <Row>
        <Col lg={12} sm={24} xs={24} className="px-1">
          <Form.Item
            name="email"
            help={errors?.email && errors.email}
            validateStatus={errors?.email && 'error'}
            rules={[{ required: true, message: 'Digite o email do cliente.' }]}
            label={<label>Email</label>}
          >
            <Input
              placeholder="Digite o email do cliente"
              error={errors?.email}
              onBlur={onEmailBlur}
            />
          </Form.Item>
        </Col>

        <Col lg={12} sm={24} xs={24} className="px-1">
          <Form.Item
            name="name"
            label={<label>Nome</label>}
            help={errors?.name && errors.name}
            validateStatus={errors?.name && 'error'}
            rules={[{ required: true, message: 'Digite o nome do cliente.' }]}
          >
            <Input placeholder="Digite o nome do cliente" />
          </Form.Item>
        </Col>

        <Col lg={8} sm={24} xs={24} className="px-1">
          <Form.Item
            name="cpf"
            label={<label>CPF</label>}
            help={errors?.cpf && errors?.cpf}
            validateStatus={errors?.cpf && 'error'}
            rules={[{ required: true, message: 'Digite o cpf do cliente.' }]}
          >
            <Input
              placeholder="Digite o cpf do cliente"
              mask="cpf"
              error={errors?.cpf}
              onBlur={onCpfBlur}
            />
          </Form.Item>
        </Col>

        <Col lg={8} sm={24} xs={24} className="px-1">
          <Form.Item
            name="cellphone"
            label={<label>Celular</label>}
            help={errors?.cellphone && errors.cellphone}
            validateStatus={errors?.cellphone && 'error'}
            rules={[{ required: true, message: 'Digite o celular do cliente.' }]}
          >
            <Input
              placeholder="Digite o celular do cliente"
              mask="phone"
              error={errors?.cellphone}
            />
          </Form.Item>
        </Col>

        {SimulationModel.canSeeBirthdateField(personType) &&
          <Col lg={8} sm={24} xs={24} className="px-1">
            <Form.Item
              name="birthdate"
              label={<label>Data de nascimento</label>}
              help={errors?.birthdate && errors.birthdate}
              validateStatus={errors?.birthdate && 'error'}
              rules={[{ required: true, message: 'Escolha a data de nascimento do cliente!' }]}
            >
              <DatepickerCustom locale={locale} format="DD/MM/YYYY" />
            </Form.Item>
          </Col>
        }
      </Row>
    </Form>
  )
}

export default ClientForm

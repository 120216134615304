import React, { ReactNode, useState } from 'react'
import { Form, Button, message } from 'antd'
import TokenVerification from 'components/TokenVerification/TokenVerification'

export interface ITokenValidationConfirmProps {
  onSubmit: (token: string) => Promise<any>
  onResendToken: () => Promise<any>

  description?: ReactNode | string
  onGoBack: () => void
  backButtonClassName?: string
  submitButtonClassName?: string
};

const TokenValidationConfirm = ({
  onSubmit, onResendToken, onGoBack,
  backButtonClassName, submitButtonClassName, description = ''
}: ITokenValidationConfirmProps) => {
  const [loading, setLoading] = useState(false)
  const [resendLoading, setResendLoading] = useState(false)

  const handleTokenSubmit = async (values: { token: string }) => {
    try {
      setLoading(true)
      await onSubmit(values.token)
    } catch (err) {
      message.error(err?.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="token-validation__input-container">
      {description && description}

      <Form
        layout="horizontal"
        className="w-100 flex flex-column"
        onFinish={handleTokenSubmit}
      >
        <Form.Item
          name="token"
          className="w-100 m-0"
        >
          <TokenVerification
            lenght={6}
            placeholder=' '
          />
        </Form.Item>

        <Button
          type="link"
          loading={resendLoading}
          className='my-3'
          onClick={() => {
            setResendLoading(true)
            onResendToken()
              .then(() => setResendLoading(false))
              .finally(() => setResendLoading(false))
          }}
        >
          Reenviar Token
        </Button>

        <div className="token-validation__buttons-container">
          <Button
            type="ghost"
            onClick={onGoBack}
            className={`token-validation__back-button ${backButtonClassName || ''}`}
          >
            Voltar
          </Button>

          <Button
            type="primary"
            loading={loading}
            htmlType="submit"
            className={`token-validation__submit-button ${submitButtonClassName}`}
          >
            Validar
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default TokenValidationConfirm

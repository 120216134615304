import SignupContainer from './components/SignupContainer'
import SignupHeader from './components/SignupHeader'
import React, { ReactNode } from 'react'
import imageUrls from 'utils/imagesUrls'

type Props = {
  children: ReactNode
  className?: string
}

export default function SignupLayout ({ children, className }: Props) {
  return (
    <section className='signup'>
      <SignupHeader imgUrl={imageUrls.rodobensLogoLight} />

      <SignupContainer className={className}>
        {children}
      </SignupContainer>
    </section>
  )
}

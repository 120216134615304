import { Form, message, Typography } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import SignupLayout from 'layouts/SignupLayout/SignupLayout'
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { handleOnidataErrors } from 'utils/validate'
import { UNAUTHS_PATHS } from 'routes/unauthRoutes'
import { IError } from 'ecp/app/Proposals/proposalInterfaces'
import PasswordInputs from 'ecp/app/SignUpClient/components/PasswordInputs/PasswordInputs'
import { IFirstAccesClientEmailCpFPassword } from '../../firstAccesInterfaces'
import { Input } from 'components'
import LoginRepository from 'ecp/repositories/LoginRepository'
import SignupButton from 'egi/app/Signup/components/SignupButton/SignupButton'
import { PASSWORD_HELP_TEXT } from 'egi/app/Signup/signupConstants'
import { SignupTitle } from 'egi/app/Signup/components/SignupTitle/SignupTitle'
import { swalError } from 'components/SwalError/SwalError'

function FirstAccessClientEmailCpfPassword () {
  const [form] = useForm<IFirstAccesClientEmailCpFPassword>()
  const [errors, setErrors] = useState<IError<IFirstAccesClientEmailCpFPassword>>({})
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const history = useHistory()

  function redirectToNextStep (tokenJwt: string, email: string) {
    history.push({
      pathname: `${UNAUTHS_PATHS.FIRST_ACCESS_CLIENT_EMAIL_TOKEN.replace(':tokenJwt', String(tokenJwt))}`,
      state: { email }
    })
  }

  async function handleSubmit (values: IFirstAccesClientEmailCpFPassword) {
    setErrors({})
    setLoadingSubmit(true)

    try {
      const response = await LoginRepository.firstAcces(values)
      const { token_jwt: jwtToken } = response.data?.data || {}

      message.success(response.data.message)
      redirectToNextStep(jwtToken as string, values.email)
    } catch (err) {
      swalError({ err })
      if (err.data?.error) setErrors(handleOnidataErrors(err.data?.error))
    } finally {
      setLoadingSubmit(false)
    }
  }

  return (
    <SignupLayout>

      <div className='mb-3'>
        <Link
          className='signup__link'
          to={UNAUTHS_PATHS.FIRST_ACCESS_CLIENT_ECP_CHECK_LINK}
        >
          &larr; Voltar
        </Link>
      </div>

      <SignupTitle className='mb-1'>Cadastro para solicitação via email</SignupTitle>
      <p className='first-acces__info-text'>
        Para continuar seu cadastro preencha as informações abaixo
      </p>

      <Form
        layout="vertical"
        className='unauth-form'
        onFinish={handleSubmit}
        form={form}
      >
        <Form.Item
          name='email'
          label='Email corporativo'
          help={errors?.email}
          validateStatus={errors?.email && ('error')}
        >
          <Input
            className='unauth-inputs'
            placeholder='Digite seu email corporativo'
          />
        </Form.Item>

        <Form.Item
          name='cpf'
          label='CPF'
          help={errors?.cpf}
          validateStatus={errors?.cpf && ('error')}
        >
          <Input
            className='unauth-inputs'
            placeholder='Digite seu CPF'
            mask='cpf'
          />
        </Form.Item>

        <div className='ecp-signup-form__password-help-container'>
          <Typography.Paragraph className='ecp-signup-form__password-help-text'>
            {PASSWORD_HELP_TEXT}
          </Typography.Paragraph>
        </div>

        <PasswordInputs/>
        <Form.Item>
          <SignupButton
            htmlType='submit'
            className='w-100'
            loading={loadingSubmit}
          >
            Enviar
          </SignupButton>
        </Form.Item>
      </Form>

    </SignupLayout>
  )
}

export default FirstAccessClientEmailCpfPassword

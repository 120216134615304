
import { SettingFilled } from '@ant-design/icons'
import { Button } from 'antd'

import React, { ReactNode } from 'react'

export default function SettingsCard ({ children, className }: { children: ReactNode, className?: string }) {
  return (
    <div className={`settings-card ${className}`}>
      {children}
    </div>
  )
}

SettingsCard.title = ({ children, className }: { children: ReactNode, className?: string }) => {
  return <h3 className={`settings-card__title ${className || ''}`}>
    {children}
  </h3>
}

SettingsCard.description = ({ children, className }: { children: ReactNode, className?: string }) => {
  return <p className={`settings-card__description ${className || ''}`}>
    {children}
  </p>
}

SettingsCard.submitButton = ({ children, className, loading, disabled }: { loading: boolean, children: ReactNode, className?: string, disabled?: boolean }) => {
  return <Button type="primary"
    className={`settings-card__button ${className || ''}`}
    htmlType="submit"
    disabled={disabled}
    loading={loading}
  >
    {children}
  </Button>
}

SettingsCard.cancelButton = ({ children, className, loading, onClick }: { loading?: boolean, children: ReactNode, className?: string, onClick: Function }) => {
  return <Button type="ghost"
    className={`settings-card__button ${className || ''}`}
    loading={loading}
    onClick={() => onClick()}
  >
    {children}
  </Button>
}

SettingsCard.ButtonsContainer = ({ children }: {children: ReactNode}) => {
  return <div className="settings-card__button-container">
    {children}
  </div>
}

SettingsCard.Header = ({ title, description, className }: { title: string, description: string | ReactNode, className?: string }) => {
  return (
    <SettingsCard className={`mb-2 ${className}`} >
      <div className="settings-card__header-content">
        <SettingFilled className="settings-card__header-icon"/>
        <div>
          <SettingsCard.title>
            {title}
          </SettingsCard.title>
          <p>{description}</p>
        </div>
      </div>
    </SettingsCard>
  )
}

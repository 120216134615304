import React, { Fragment, useState } from 'react'
import swal from 'utils/swal'
import { proposals } from 'egi/consumers'
import { useProposal } from 'hooks'
import EditBox from 'components/EditBox/EditBox'

function ContractEditBox ({ value, slug, onSuccess, readOnly }: { value: string, slug: string, onSuccess: Function, readOnly?: boolean }) {
  const [visible, setVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const proposal = useProposal()

  const onSubmit = async () => {
    const div = document.getElementById(`editable-content-${slug}`)
    const edit = div?.innerHTML
    setLoading(true)
    try {
      const response = await proposals.finalMinutaEdit({ proposalId: proposal.id, data: { slug: slug, value: edit } })
      setLoading(false)
      onSuccess()
      swal.basic({ title: 'Sucesso!', text: response.message, icon: 'success' })
    } catch (err) {
      setLoading(false)
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    }
  }

  return (
    <Fragment>
      <EditBox
        loading={loading}
        onSubmit={onSubmit}
        readOnly={!!readOnly}
        slug={slug}
        value={value}
        visible={visible}
        setVisible={setVisible}
      />
    </Fragment>
  )
}

export default ContractEditBox

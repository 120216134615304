import loadable from '@loadable/component'
import Loading from 'components/Loading/Loading'
import { timeout } from 'promise-timeout'
import React from 'react'
import { TIMEOUT_LOADER } from 'utils/globals'

const MainOfficeListECPF = loadable(
  () => timeout(import(
    /* webpackChunkName: "MainOfficeListECPF" */
    'ecpf/app/MainOfficeECPF/views/MainOfficeListECPF/MainOfficeListECPF'
  ), TIMEOUT_LOADER),
  {
    fallback: <Loading/>
  }
)

export { MainOfficeListECPF }

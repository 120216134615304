import React, { Fragment } from 'react'
import { Col, Row, Form, Select, Divider, Typography } from 'antd'
import { Address, Datepicker, Input, InputMoney } from 'components'
import locale from 'antd/es/date-picker/locale/pt_BR'
import { useResources } from 'hooks'
import AssociateFields from './AssociateFields'
import { PartnerFormProps } from '../types'
import CertificateFields from '../../../components/CertificateFields/CertificateFields'
import PlusButton from 'components/PlusButton/PlusButton'

function FormPartner ({ formRef, errors, readonly, onChange, onSubmit }: PartnerFormProps) {
  const resources = useResources()

  return (
    <Fragment>
      <Form
        form={formRef}
        layout="vertical"
        onFinish={onSubmit}
        initialValues={{
          certificate: false
        }}
      >
        <Row>
          <Col className="px-1" lg={14} sm={24} xs={24}>
            <Form.Item
              name='socialName'
              label={<label>Razão social:</label>}
              help={errors.socialName && (errors.socialName)}
              validateStatus={errors.socialName && ('error')}
            >
              <Input placeholder='Digite a razão social' readOnly={readonly} />
            </Form.Item>
          </Col>

          <Col className="px-1" lg={10} sm={24} xs={24}>
            <Form.Item
              name='cnpj'
              label={<label>CNPJ:</label>}
              help={errors.cnpj && (errors.cnpj)}
              validateStatus={errors.cnpj && ('error')}
            >
              <Input placeholder='Digite o CNPJ' mask="cnpj" readOnly={readonly} />
            </Form.Item>
          </Col>

          <Col className="px-1" lg={14} sm={24} xs={24}>
            <Form.Item
              name='fantasyName'
              label={<label>Nome fantasia:</label>}
              help={errors.fantasyName && (errors.fantasyName)}
              validateStatus={errors.fantasyName && ('error')}
            >
              <Input placeholder='Digite o nome fantasia' readOnly={readonly} />
            </Form.Item>
          </Col>

          <Col className="px-1" lg={10} sm={24} xs={24}>
            <Form.Item
              name='foundationDate'
              label={<label>Data de fundação:</label>}
              help={errors.foundationDate && (errors.foundationDate)}
              validateStatus={errors.foundationDate && ('error')}
            >
              <Datepicker
                id="foundationDate"
                locale={locale}
                disabled={readonly}
              />
            </Form.Item>
          </Col>

          <Col className="px-1" lg={8} sm={24} xs={24}>
            <Form.Item
              name="legalNature"
              label={<label>Natureza jurídica:</label>}
              help={errors.legalNature && (errors.legalNature)}
              validateStatus={errors.legalNature && ('error')}
            >
              <Select
                id="test-select-legalNature"
                placeholder="Selecione a natureza jurídica"
                disabled={readonly}
                options={
                  resources.legalNature.map((item) => ({
                    value: item,
                    label: item,
                    key: item
                  }))
                }
              />
            </Form.Item>
          </Col>

          <Col className="px-1" lg={8} sm={24} xs={24}>
            <Form.Item
              name="economicActivity"
              label={<label>Atividade econômica:</label>}
              help={errors.economicActivity && (errors.economicActivity)}
              validateStatus={errors.economicActivity && ('error')}
            >
              <Select
                id="test-select-economicActivity"
                placeholder="Selecione a atividade econômica"
                disabled={readonly}
                options={
                  resources.economicActivity.map((item) => ({
                    value: item,
                    label: item,
                    key: item
                  }))
                }
              />
            </Form.Item>
          </Col>

          <Col className="px-1" lg={8} sm={24} xs={24}>
            <Form.Item
              name="activityBranch"
              label={<label>Ramo de atividade:</label>}
              help={errors.activityBranch && (errors.activityBranch)}
              validateStatus={errors.activityBranch && ('error')}
            >
              <Input placeholder="Digite o ramo de atividade" readOnly={readonly} />
            </Form.Item>
          </Col>

          <Col className="px-1" lg={8} sm={24} xs={24}>
            <Form.Item
              name="mailbox"
              label={<label>Caixa postal:</label>}
              help={errors.mailbox && (errors.mailbox)}
              validateStatus={errors.mailbox && ('error')}
            >
              <Input placeholder="Digite a caixa postal" readOnly={readonly} />
            </Form.Item>
          </Col>

          <Col className="px-1" lg={8} sm={24} xs={24}>
            <Form.Item
              name="phone"
              label={<label>Telefone:</label>}
              help={errors.phone && (errors.phone)}
              validateStatus={errors.phone && ('error')}
            >
              <Input placeholder="Digite o telefone" mask="cell" readOnly={readonly} />
            </Form.Item>
          </Col>

          <Col className="px-1" lg={8} sm={24} xs={24}>
            <Form.Item
              name="email"
              label={<label>E-mail:</label>}
              help={errors.email && (errors.email)}
              validateStatus={errors.email && ('error')}
            >
              <Input placeholder="Digite o e-mail" readOnly={readonly} />
            </Form.Item>
          </Col>
        </Row>

        <Address
          onCepBlur={(address, remainingAddress) => formRef.setFieldsValue({ address: { ...address, ...remainingAddress } })}
          formRef={formRef}
          errors={errors}
          person='Parceiro'
          title="Endereço"
          readOnlyInput={readonly}
        />

        <Divider />

        <Typography.Paragraph className="bold">Renda</Typography.Paragraph>
        <Row>
          <Col className="px-1" lg={8} sm={24} xs={24}>
            <Form.Item
              name="monthlyBilling"
              label={<label>Faturamento médio mensal:</label>}
              help={errors.monthlyBilling && (errors.monthlyBilling)}
              validateStatus={errors.monthlyBilling && ('error')}
            >
              <InputMoney placeholder='Digite o faturamento médio mensal' readOnly={readonly} />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Form.List name="associates">
          {(fields, { add, remove }) => (
            <Fragment>
              {fields.map((field, idx) => (
                <AssociateFields
                  form={formRef}
                  readOnly={readonly}
                  field={field}
                  key={field.fieldKey}
                  index={idx}
                  onRemove={() => remove(field.name)}
                  onChange={onChange}
                />
              ))}

              {!readonly && (
                <Col className="px-1" lg={8} sm={12} xs={24} style={{ position: 'relative' }}>
                  <PlusButton
                    onClick={() => add()}
                    label="Adicionar sócio"
                  />
                </Col>
              )}
            </Fragment>
          )}
        </Form.List>

        <Divider />

        <Typography.Paragraph className="bold">Informações adicionais</Typography.Paragraph>
        <CertificateFields errors={errors} formRef={formRef} readonly={readonly} />

      </Form>

    </Fragment>
  )
}

export default FormPartner

import { AxiosRequestConfig } from 'axios'
import { Repository } from 'ecp/repositories/Repository'
import api from 'services/api'

export type MlmDashboardWeekly = { day: string, value: number }
export type MlmDashboardTopResponse = { name: string, value: string }
export type MlmMaterialResponse = {
  _id: string
  name: string
  description: string
  materialId: string
  image: string
}

class MlmRepository extends Repository {
  dashboardPath = this.path + '/dashboard'
  materialsPath = this.path + '/materials'

  // DASHBOARD
  links (config: AxiosRequestConfig) {
    return Repository.handle<{ total: number }>(() =>
      this.api.get(`${this.dashboardPath}/links`, config)
    )
  }

  weekly (config: AxiosRequestConfig) {
    return Repository.handle<{ values: MlmDashboardWeekly[] }>(() =>
      this.api.get(`${this.dashboardPath}/weekly`, config)
    )
  }

  monthly (config: AxiosRequestConfig) {
    return Repository.handle<{ value: number }>(() =>
      this.api.get(`${this.dashboardPath}/monthly`, config)
    )
  }

  top (config: AxiosRequestConfig) {
    return Repository.handle<{ values: MlmDashboardTopResponse[] | number }>(() =>
      this.api.get(`${this.dashboardPath}/top`, config)
    )
  }

  leads (config: AxiosRequestConfig) {
    return Repository.handle<{ value: number, count: number }>(() =>
      this.api.get(`${this.dashboardPath}/leads`, config)
    )
  }

  // MATERIALS
  sendMaterials (body: FormData, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.post(`${this.materialsPath}`, body, config)
    )
  }

  deleteMaterial (materialId: string, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.delete(`${this.materialsPath}/${materialId}`, config)
    )
  }

  updateMaterials (body: FormData, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.patch(`${this.materialsPath}`, body, config)
    )
  }

  listMaterials (config?: AxiosRequestConfig) {
    return Repository.handle<{ materials: MlmMaterialResponse[] }>(() =>
      this.api.get(`${this.materialsPath}`, config)
    )
  }
}

export default new MlmRepository({ api, path: '/mlm' })

import React, { Fragment, useLayoutEffect, useRef, useState } from 'react'
import { Form, Select } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useDrawer, useResources } from 'hooks'
import mask from 'utils/masks'
import imageUrls from 'utils/imagesUrls'
import { validateResponse } from 'utils/validate'
import swal from 'utils/swal'
import { drawerButtonIsLoading, drawerFormRefSubmit, drawerUpdateStatus } from 'store/modules/drawer/actions'
import { useDispatch } from 'react-redux'
import { resourcesGetRefusedReasons } from 'store/modules/resources/actions'
import { _leadReason } from 'globals'
import { IErrorsLeads } from './types'
import LeadsRepository from 'egi/repositories/LeadsRepository'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'

type enumStatus = 'ongoing' | 'refused' | ''

type onSubmitLeadUpdate = {
  status: enumStatus, message: string, declinedReason?: _leadReason
}

function LeadsDrawer () {
  const formRef = useRef<any>(null)
  const drawer = useDrawer()
  const dispatch = useDispatch()
  const resources = useResources()

  const [errors, setErrors] = useState<IErrorsLeads>({})
  const [status, setStatus] = useState<enumStatus>('')

  const onSubmit = async (values: onSubmitLeadUpdate) => {
    const leadId = drawer.lead?.id

    if (!leadId) return
    const { status, message, declinedReason } = values

    const data: onSubmitLeadUpdate = {
      status,
      message
    }

    if (declinedReason) data.declinedReason = declinedReason

    dispatch(drawerButtonIsLoading(true))
    try {
      const response = await LeadsRepository.updateStatus(leadId, data)
      dispatch(drawerUpdateStatus({ newStatus: status, isSubmit: true }))
      setErrors({})
      swal.basic({ title: 'Sucesso', text: response.data?.message, icon: 'success' })
    } catch (err) {
      if (err.data?.invalid) {
        let errors: IErrorsLeads = {}
        errors = validateResponse(err.data?.invalid)
        setErrors(errors)
        dispatch(drawerButtonIsLoading(false))

        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
        return
      }

      setErrors({})
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    } finally {
      dispatch(drawerUpdateStatus({ newStatus: status, isSubmit: false }))
      dispatch(drawerButtonIsLoading(false))
    }
  }

  useLayoutEffect(() => {
    dispatch(drawerFormRefSubmit(formRef))

    if (drawer.lead?.status === 'awaiting') {
      onSubmit({
        status: 'ongoing',
        message: 'Iniciando tentativas de contato.'
      })
    }
  }, [])

  useLayoutEffect(() => {
    if (resources.refusedReasons.length < 1) dispatch(resourcesGetRefusedReasons())
  }, [resources.refusedReasons])

  return (
    <Fragment>
      {(drawer && drawer.contact && drawer.contact.client) && (
        <div className="text-center">
          <h2>Telefone / Celular</h2>
          <img src={imageUrls.phoneRinging} width="86" className="py-2 shake-lr" />
          <h1>{mask((drawer.contact.client.number as string), 'phone', true)}</h1>
        </div>
      )}

      <Form
        ref={formRef}
        onFinish={onSubmit}
        layout="vertical"
      >
        <Form.Item
          name='status'
          label={<label>Status do lead:</label>}
          help={errors.status && (errors.status)}
          validateStatus={errors.status && ('error')}
        >
          <Select
            onChange={(item: enumStatus) => setStatus(item)}
            placeholder='Escolha o novo status do lead'
          >
            <Select.Option value="ongoing">Em andamento</Select.Option>
            <Select.Option value="refused">Perdeu</Select.Option>
          </Select>
        </Form.Item>

        {(status === 'refused') && (
          <Form.Item
            name='declinedReason'
            label={<label>Razão da perda:</label>}
            help={errors.status && (errors.status)}
            validateStatus={errors.status && ('error')}
          >
            <Select
              className="leads-drawer-search"
              showSearch
              placeholder='Razão da perda'
              filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {resources.refusedReasons.length > 0 && resources.refusedReasons.map((item, index) => (
                <Select.Option key={index} value={item.value}>{item.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          name="message"
          label={<label>Mensagem: </label>}
          help={errors.message && (errors.message)}
          validateStatus={errors.message && ('error')}
        >
          <TextArea
            style={{ borderRadius: 5 }}
            rows={5}
            placeholder="Sua mensagem"
          />
        </Form.Item>
      </Form>
    </Fragment>
  )
}

export default LeadsDrawer

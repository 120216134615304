import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Selfie, Input } from 'components'
import { Button, Col, Form, Row, Select, Modal, Skeleton } from 'antd'
import { resourcesGetBanks } from 'store/modules/resources/actions'
import { useDispatch } from 'react-redux'
import { useAuth, useResources, useSelectedProduct } from 'hooks'
import { timeAsDayjs } from 'utils/time'
import { IBankInfoUser, PixTypes } from 'egi/types/IBank'
import format from 'utils/format'
import swal from 'utils/swal'
import DetailsItem from 'ecp/components/DetailsItem/DetailsItem'
import { ClientProfile } from '../../components/ClientProfile/ClientProfile'

import { IProfileErros, IProfileInformation } from '../../profileInterfaces'

import UpdateOnidataCredentials from '../../components/UpdateOnidataCredentials/UpdateOnidataCredentials'
import { EcpCard } from 'ecp/components/EcpCard/EcpCard'
import { EditOutlined, SettingOutlined } from '@ant-design/icons'
import translate from 'utils/translate'
import { IError } from 'ecp/app/Proposals/proposalInterfaces'
import mask from 'utils/masks'
import { validateResponse } from 'utils/validate'
import ChangePasswordModal from 'egi/app/Profile/components/ChangePasswordModal/ChangePasswordModal'
import ChangeEmailModal from '../../components/ChangeEmailModal/ChangeEmailModal'
import ChangePhoneModal from '../../components/ChangePhoneModal/ChangePhoneModal'
import { UsersModel } from 'ecp/models/UsersModel'
import useAuthLayout from 'hooks/useAuthLayout'
import UsersEgiRepository from 'egi/repositories/UsersEgiRepository'

function Profile () {
  const [newEmail, setNewEmail] = useState<string>('')
  const [newPhone, setNewPhone] = useState<string>('')
  const [emailStep, setEmailStep] = useState<'changeEmail' | 'verifyEmail'>('changeEmail')
  const [phoneStep, setPhoneStep] = useState<'changePhone' | 'verifyPhone'>('changePhone')

  const [formRef] = Form.useForm()
  const dispatch = useDispatch()
  const { _id: productId } = useSelectedProduct()
  const resources = useResources()
  const user = useAuth()
  const { products } = resources
  const { layout } = useAuthLayout()
  const selectedProduct = products.find(product => productId === product._id)

  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [errors, setErrors] = useState<IError<IProfileErros>>()

  const [loadingSubmit, setLoadingSubmit] = useState<boolean>()
  const [loadingForm, setLoadingForm] = useState<boolean>()
  const [loading, setLoading] = useState({
    button: false,
    list: false
  })
  const [information, setInformation] = useState<IProfileInformation | null>(
    null
  )
  const [visiblePassword, setVisiblePassword] = useState<boolean>(false)
  const [visibleEmail, setVisibleEmail] = useState<boolean>(false)
  const [visiblePhone, setVisiblePhone] = useState<boolean>(false)
  const [imageBlob, setImageBlob] = useState<Blob>()
  const [image, setImage] = useState<string>()
  const [defaultPix, setDefaultPix] = useState<PixTypes>(
    user.personType === 'fisico' ? 'cpf' : 'cnpj'
  )

  const filterBanks = (bankCode: string) => {
    const filtred = resources.banks.filter(element => {
      return element.code === bankCode
    })
    return filtred?.[0]?.name
  }

  async function onSubmit (values: IBankInfoUser) {
    setLoadingSubmit(true)
    if (!user._id) return

    if (values.cellphone) values.cellphone = format.onlyDigits(values.cellphone)

    if (values.bank) {
      if (values.bank.agency) { values.bank.agency = format.onlyDigits(values.bank.agency) }
      if (values.bank.account) { values.bank.account = format.onlyDigits(values.bank.account) }
    }

    if (values.pix) {
      if (values.pix.cpf) values.pix.cpf = format.onlyDigits(values.pix.cpf)
      if (values.pix.cnpj) values.pix.cnpj = format.onlyDigits(values.pix.cnpj)
      if (values.pix.cellphone) { values.pix.cellphone = format.onlyDigits(values.pix.cellphone) }
    }

    try {
      const response = await UsersEgiRepository.updateProfile(
        user._id,
        values
      )
      setErrors({})
      swal.basic({ title: 'Sucesso', icon: 'success', text: response.data.message })

      setInformation(prev => prev && ({
        ...prev,
        ...values
      }))
    } catch (err) {
      if (err.data?.invalid) {
        const errors = validateResponse(err.data?.invalid)
        console.error(errors)
        setErrors(errors)

        setLoading({ ...loading, button: false })
        swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
        return
      }

      setErrors({})
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    } finally {
      setLoadingSubmit(false)
    }

    setIsEdit(false)
  }

  useEffect(() => {
    async function updateSelfie () {
      if (!user._id || !imageBlob) return

      const formData = new FormData()
      formData.append('selfie', imageBlob)

      try {
        const response = await UsersEgiRepository.updateProfile(
          user._id,
          formData
        )
        swal.basic({
          title: 'Sucesso',
          text: response.data.message,
          icon: 'success'
        })
      } catch (err) {
        swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      }
    }

    updateSelfie()
  }, [imageBlob])

  useLayoutEffect(() => {
    async function getProfile () {
      if (isEdit === false) {
        setLoading({ ...loading, list: true })
      } else {
        setLoadingForm(true)
      }
      try {
        const response = await UsersEgiRepository.profile()
        const info = response.data.data

        info.birthdate = info.birthdate
          ? timeAsDayjs(info.birthdate, { applyTimezone: false }).format('DD/MM/YYYY')
          : undefined
        info.foundationDate = info.foundationDate
          ? timeAsDayjs(info.foundationDate, { applyTimezone: false })
          : undefined

        if (info.selfie) setImage(info.selfie)
        if (info.pix && info.pix.type) setDefaultPix(info.pix.type)

        setLoading({ ...loading, list: false })

        setInformation(info)
      } catch (err) {
        console.log(err)
      }
      setLoading({ ...loading, list: false })
      setLoadingForm(false)
    }

    getProfile()

    if (isEdit) { formRef?.setFieldsValue?.(information) }
  }, [isEdit, newEmail, newPhone])

  useLayoutEffect(() => {
    if (resources.banks.length < 1) dispatch(resourcesGetBanks())
  }, [resources.banks])

  return (
    <div>
      <Modal
        className='modal-config'
        visible={visiblePassword}
        onCancel={() => setVisiblePassword(false)}
        title={'Trocar senha'}
        footer={null}
        width='30vw'
        centered
      >
        <ChangePasswordModal />
      </Modal>

      <Modal
        className='modal-config'
        visible={visibleEmail}
        onCancel={() => setVisibleEmail(false)}
        title='Alterar email'
        footer={null}
        width='30vw'
        centered
      >
        <ChangeEmailModal
          setStep={setEmailStep}
          step={emailStep}
          currentEmail={information?.email ?? '-'}
          setVisibleEmail={setVisibleEmail}
          setNewEmail={setNewEmail}
        />
      </Modal>

      <Modal
        className='modal-config'
        visible={visiblePhone}
        onCancel={() => setVisiblePhone(false)}
        title={'Alterar telefone'}
        footer={null}
        width='30vw'
        centered
      >
        <ChangePhoneModal
          step={phoneStep}
          setStep={setPhoneStep}
          setNewPhone={setNewPhone}
          currentPhone={information?.cellphone ?? '-'}
          setVisiblePhone={setVisiblePhone}
        />
      </Modal>

      {layout.status === 'notWorking' && (
        <Row gutter={[15, 15]}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <EcpCard>
              <UpdateOnidataCredentials />
            </EcpCard>
          </Col>
        </Row>
      )}

      <EcpCard>
        <Row className='profile'>

          <Col lg={8} sm={24} xs={24} >
            <div className='profile__selfie'>
              <div className='pb-2 '>
                {loading.list
                  ? <Skeleton.Avatar style={{ width: '10rem', height: '10rem' }} active={true}/>
                  : <Selfie defaultImage={image} getFile={setImageBlob} profile={true} />
                }
              </div>

              <h2 className='profile__section-title'>Selfie</h2>

              <Row className="profile__buttons">
                <Button type='primary' onClick={() => setVisiblePassword(true)}>
                  <SettingOutlined />
                  Alterar senha
                </Button>

                {UsersModel.canUpdateEmailAndPhone(user.level, selectedProduct?.slug) &&
                  <>
                    <Button
                      type='primary'
                      className="profile__change-email-button"
                      onClick={() => setVisibleEmail(true)}
                    >
                      <SettingOutlined />
                      Alterar Email
                    </Button>

                    <Button
                      type='primary'
                      className="profile__change-telephone-button"
                      onClick={() => setVisiblePhone(true)}
                    >
                      <SettingOutlined />
                      Alterar Telefone
                    </Button>
                  </>
                }
              </Row>
            </div>
          </Col>
          {loading.list
            ? <Col lg={16} sm={24} xs={24} className='profile__info'><div>
              <Skeleton className="m-3" active={true}/>
              <Skeleton className="m-3" active={true}/>
              <Skeleton className="m-3" active={true}/>
            </div></Col> : <Col lg={16} sm={24} xs={24} className={'profile__info'}>

              <h2 className='profile__section-title'>Dados pessoais</h2>

              <Row gutter={[12, 12]}>
                <Col lg={12} sm={24} xs={24}>
                  <DetailsItem
                    label='Nome'
                    value={information?.name ?? '-'}
                  />
                </Col>
                <Col lg={12} sm={24} xs={24}>
                  <DetailsItem
                    label='Email'
                    value={information?.email ?? '-'}
                  />

                </Col>
              </Row>

              <Row gutter={[12, 12]}>
                <Col lg={12} sm={24} xs={24}>
                  <DetailsItem label='Data Nascimento' value={information?.birthdate ?? '-'} />
                </Col>

                <Col lg={12} sm={24} xs={24}>
                  <DetailsItem
                    label='Celular'
                    value={(information && information.cellphone) ? mask(String(information.cellphone), 'cell') : '-'}
                  />
                </Col>
              </Row>

              {UsersModel.canSeeBankInformation(user.level) && (

                <h2 className='profile__section-title'>
                  Dados bancários
                  <Button
                    type={!isEdit ? 'primary' : 'ghost'}
                    onClick={() => setIsEdit(!isEdit)}
                  >
                    {!isEdit ? <><EditOutlined /> Editar</> : <>Cancelar</>}
                  </Button>
                </h2>

              )}
              {UsersModel.canSeeBankInformation(user.level) &&
                (!isEdit ? (
                  <>
                    <Row gutter={[12, 12]}>
                      <Col lg={12} sm={24} xs={24}>
                        <DetailsItem
                          label='Instituição'
                          value={
                            (information &&
                              information.bank &&
                              filterBanks(information.bank.code)) ??
                            '-'
                          }
                        />
                      </Col>
                      <Col lg={12} sm={24} xs={24}>
                        <DetailsItem
                          label='Tipo de conta'
                          value={
                            (information &&
                              information.bank &&
                              translate.accountType(information.bank.type)) ?? '-'
                          }
                        />
                      </Col>
                    </Row>
                    <Row gutter={[12, 12]}>
                      <Col lg={12} sm={24} xs={24}>
                        <DetailsItem
                          label='Agência'
                          value={information?.bank?.agency ?? '-'}
                        />
                      </Col>
                      <Col lg={12} sm={24} xs={24}>
                        <DetailsItem
                          label='Conta'
                          value={information?.bank?.account ?? '-'}
                        />
                      </Col>
                    </Row>
                    <Row gutter={[12, 12]}>
                      <Col lg={12} sm={24} xs={24}>
                        <DetailsItem
                          label='Titular'
                          value={information?.bank?.accountHolder ?? '-'}
                        />
                      </Col>
                    </Row>
                    <h2 className='profile__section-title'>Chaves pix</h2>
                    <Row gutter={[12, 12]}>
                      <Col lg={12} sm={24} xs={24}>
                        <DetailsItem
                          label='Tipo chave'
                          value={translate.pixTypes(defaultPix) ?? '-'}
                        />
                      </Col>
                      <Col lg={12} sm={24} xs={24}>

                        <DetailsItem
                          label={translate.pixTypes(defaultPix)}
                          value={(information && information.pix &&
                            format.formatPix(information.pix[defaultPix] ?? '', defaultPix)) ?? '-'}
                        />
                      </Col>
                    </Row>{' '}
                  </>
                ) : (
                  <>
                    <Form
                      className='client-form'
                      onFinish={onSubmit}
                      layout='vertical'
                      form={formRef}
                    >
                      <Row gutter={[12, 12]}>
                        <Col lg={12} sm={24} xs={24}>
                          <Form.Item name={['bank', 'code']}
                            help={errors?.code}
                            validateStatus={errors?.code && ('error')}
                            label='Instituição'>
                            <Select placeholder='Selecione o banco' showSearch disabled={loadingForm}>
                              {resources.banks.map(bank => (
                                <Select.Option

                                  id={`test-form-select-bank-${bank.name}`}
                                  key={bank.code}
                                  value={bank.code}
                                >
                                  {bank.code} - {bank.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col lg={12} sm={24} xs={24}>
                          <Form.Item
                            help={errors?.type}
                            name={['bank', 'type']}
                            label='Tipo de conta' validateStatus={errors?.type && ('error')}
                          >
                            <Select disabled={loadingForm}
                              placeholder='Selecione o tipo de conta'
                              showSearch
                            >
                              <Select.Option value='corrente'>
                                  Corrente
                              </Select.Option>
                              <Select.Option value='poupanca'>
                                  Poupança
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[12, 12]}>
                        <Col lg={12} sm={24} xs={24}>
                          <Form.Item
                            name={['bank', 'agency']}
                            label='Agência'
                            help={errors?.agency}>
                            <Input placeholder='Insira a agência' disabled={loadingForm} />
                          </Form.Item>
                        </Col>
                        <Col lg={12} sm={24} xs={24}>
                          <Form.Item name={['bank', 'account']} label='Conta' validateStatus={errors?.account && ('error')} help={errors?.account}>
                            <Input placeholder='Insira o número da conta' disabled={loadingForm} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[12, 12]}>
                        <Col lg={12} sm={24} xs={24}>
                          <Form.Item
                            name={['bank', 'accountHolder']}
                            label='Titular conta'
                            validateStatus={errors?.accountHolder && ('error')}
                            help={errors?.accountHolder}
                          >
                            <Input placeholder='Insira o titular da conta' disabled={loadingForm} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <h2 className='profile__section-title'>Chaves pix</h2>
                      <Row gutter={[12, 12]}>
                        <Col lg={12} sm={24} xs={24}>
                          <Form.Item name={['pix', 'type']}
                            label='Tipo chave'
                            help={errors?.type}
                            validateStatus={errors?.type && ('error')}>
                            <Select
                              disabled={loadingForm}
                              placeholder='Escolha o tipo de chave pix'
                              onChange={(value: PixTypes) => setDefaultPix(value)}
                            >
                              <Select.Option value='cpf'>CPF</Select.Option>
                              <Select.Option value='cnpj'>CNPJ</Select.Option>
                              <Select.Option value='cellphone'>Celular</Select.Option>
                              <Select.Option value='email'>Email</Select.Option>
                              <Select.Option value='randomKey'>Chave aleatória</Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col lg={12} sm={24} xs={24}>
                          <Form.Item
                            help={errors?.[defaultPix]} validateStatus={errors?.[defaultPix] && ('error')}
                            name={['pix', defaultPix]}
                            label={<label>{translate.pixTypes(defaultPix)}</label>}
                          >
                            <Input placeholder={translate.pixTypes(defaultPix)} disabled={loadingForm} mask={(defaultPix === 'cpf' || defaultPix === 'cnpj') ? defaultPix : ''} />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Button type='primary' className='w-100' htmlType='submit' loading={loadingSubmit}>
                          Salvar
                      </Button>
                    </Form>
                  </>
                ))}
            </Col>}
        </Row>

        {(UsersModel.isClientECP(selectedProduct?.slug, user.level) && information?.onidata?.data) &&
          (
            <ClientProfile
              data={information?.onidata?.data}
            />
          )
        }
      </EcpCard>
    </div>
  )
}

export default Profile

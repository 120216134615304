import { IAuth } from 'egi/types'
import {
  dashboard,
  proposalsHistory,
  proposalCheck,
  comercialTeam,
  pricingCommissionTable,
  precificacaoEdit,
  chat,
  allInvites,
  teamPromoterIn,
  commercialClients,
  leadDetails,
  leadsHistory,
  lgpdTerms,
  bacenTerms,
  leadsMenu,
  proposalsCommercialTwo
} from '../routes'
import { ShopOutlined } from '@ant-design/icons'

// AREAS
const comercial = {
  name: 'Comercial',
  icon: ShopOutlined,
  routes: [
    comercialTeam.collaborators
  ]
}

const commercialRouter = (auth: IAuth) => {
  const routes = [
    dashboard,
    proposalsCommercialTwo,
    leadsMenu,
    leadDetails,
    leadsHistory,
    commercialClients,
    proposalsHistory,
    proposalCheck,
    pricingCommissionTable,
    precificacaoEdit,
    allInvites,
    comercialTeam.promoters,
    comercialTeam.partnersLine,
    chat,
    teamPromoterIn,
    lgpdTerms,
    bacenTerms
  ]

  if (auth && !auth.administratorId && !auth.promoterId) {
    return [
      ...routes,
      comercial
    ]
  }

  return routes
}

export default commercialRouter

import React, { Fragment } from 'react'
import { Typography, Row, Col, Form, Select } from 'antd'
import { Input } from 'components'
import { useResources } from 'hooks'

const { Paragraph } = Typography
interface IBankProps {
  errors: any
  readOnlyInput?: boolean
}

function Bank ({ errors, readOnlyInput }: IBankProps) {
  const resources = useResources()

  return (
    <Fragment>
      <Paragraph className="bold">Dados bancários</Paragraph>

      <Row>
        <Col className="px-1" lg={16} sm={16} xs={24} >
          <Form.Item
            name={['bank', 'code']}
            label={<label>Instituição</label>}
            help={errors.code && (errors.code)}
            validateStatus={errors.code && ('error')}
          >
            <Select
              id="test-form-select-bank"
              showSearch
              placeholder='Escolha sua instituição'
              filterOption={(input, option) => option?.children.join().toLowerCase().indexOf(input.toLowerCase()) >= 0}
              aria-readonly={readOnlyInput}
              disabled={readOnlyInput}
            >
              {(resources.banks).map(bank => (
                <Select.Option id={`test-form-select-bank-${bank.code}`} key={bank.code} value={bank.code}>{bank.code} - {bank.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col className="px-1" lg={8} sm={8} xs={24} >
          <Form.Item
            name={['bank', 'type']}
            label={<label>Tipo de Conta</label>}
            help={errors.type && (errors.type)}
            validateStatus={errors.type && ('error')}
          >
            <Select
              id="test-form-select-bank_type"
              placeholder='Escolha seu tipo de conta'
              aria-readonly={readOnlyInput}
              disabled={readOnlyInput}
            >
              <Select.Option id="test-form-select-bank_type-corrente" value='corrente'>Corrente</Select.Option>
              <Select.Option id="test-form-select-bank_type-poupanca" value='poupanca'>Poupança</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col className="px-1" lg={8} sm={12} xs={24}>
          <Form.Item
            name={['bank', 'agency']}
            label={<label >Agência</label>}
            help={errors.agency && (errors.agency)}
            validateStatus={errors.agency && ('error')}
          >
            <Input
              readOnly={readOnlyInput}
              placeholder='Digite sua agência'
              error={errors.agency}
              mask="agency"
            />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={8} sm={12} xs={24} >
          <Form.Item
            name={['bank', 'account']}
            label={<label>Conta</label>}
            help={errors.account && (errors.account)}
            validateStatus={errors.account && ('error')}
          >
            <Input
              readOnly={readOnlyInput}
              placeholder='Digite sua conta'
              error={errors.account}
              mask='account'
            />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={8} sm={12} xs={24}>
          <Form.Item
            name={['bank', 'accountHolder']}
            label={<label>Titular da conta</label>}
            help={errors.accountHolder && (errors.accountHolder)}
            validateStatus={errors.accountHolder && ('error')}
          >
            <Input
              readOnly={readOnlyInput}
              placeholder="Digite o titular da conta"
              error={errors.accountHolder}
            />
          </Form.Item>
        </Col>
      </Row>
    </Fragment>
  )
}

export default Bank

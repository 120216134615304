import dayjs from 'dayjs'
import { timeAsDayjs } from 'utils/time'
import { MultiDatepickerType } from './MultDatePicker'

export function getMultiDatepickerFormat ({ type, mode }: {type: MultiDatepickerType, mode: 'display' | 'store'}) {
  const modes = {
    display: (() => {
      switch (type) {
        case 'month':
          return 'MMMM'
        case 'date':
        case 'range':
          return 'DD/MM/YY'
        case 'year':
          return 'YYYY'
      }
    })(),
    store: (() => {
      switch (type) {
        case 'month':
          return 'YYYY-MM'
        case 'date':
        case 'range':
          return 'YYYY-MM-DD'
        case 'year':
          return 'YYYY'
      }
    })()
  }
  return modes[mode]
}

const currentDate = dayjs()
const startOfMonth = currentDate.startOf('month')
const dynamicDate = startOfMonth.format('YYYY-MM-DD')
export const initialDate = timeAsDayjs(dynamicDate, { applyTimezone: false })

import React, { Fragment } from 'react'
import { Form, Radio } from 'antd'

import DrawerFiltersLineCollapsable, { DrawerFiltersLineCollapsableItem } from 'components/DrawerFilters/DrawerFiltersLineCollapsable'
import ProposalEgiModel from 'egi/models/ProposalEgiModel'
import { useAuth } from 'hooks'

function OpenContracts ({ reminded } : {reminded:boolean}) {
  const user = useAuth()

  if (ProposalEgiModel.canSeeOpenProposalsFilter(user.areaName, user.type, reminded)) {
    return (
      <div className='proposal-radio-filter'>
        <DrawerFiltersLineCollapsable label='Atendimentos não finalizados'>
          <Form.Item
            name='openContacts'
            initialValue={undefined}
          >
            <Radio.Group>

              <DrawerFiltersLineCollapsableItem key={''}>
                <Radio value={''}>{'Todos'}</Radio>
              </DrawerFiltersLineCollapsableItem>

              <DrawerFiltersLineCollapsableItem key={'vinculadas'}>
                <Radio value={'true'}>{'Sim'}</Radio>
              </DrawerFiltersLineCollapsableItem>

              <DrawerFiltersLineCollapsableItem key={'naoVinculadas'}>
                <Radio value={'false'}>{'Não'}</Radio>
              </DrawerFiltersLineCollapsableItem>

            </Radio.Group>
          </Form.Item>
        </DrawerFiltersLineCollapsable>
      </div>
    )
  }

  return <Fragment/>
}

export { OpenContracts }

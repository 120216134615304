import { LayoutTypes } from './layoutActions'
import { ILayoutActionsTypes, _credentialStatus } from './layoutActionTypes'

export interface ILayoutReducer {
  loading: boolean,
  status: _credentialStatus,
  error: string
}

const initialState: ILayoutReducer = {
  loading: true,
  status: 'doesntHaveCredential',
  error: ''
}

const layoutReducer = (state = initialState, action: ILayoutActionsTypes.allActions) => {
  switch (action.type) {
    case LayoutTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.payload.loading
      }
    }

    case LayoutTypes.SET_CREDENTIAL: {
      return {
        ...state,
        status: action.payload.status
      }
    }

    case LayoutTypes.SET_ERROR: {
      return {
        ...state,
        error: action.payload.error
      }
    }

    case LayoutTypes.RESET: {
      return initialState
    }

    default:
      return state
  }
}

export default layoutReducer

import React, { useState, Fragment, useLayoutEffect, useEffect, useRef, MutableRefObject, useMemo, ReactNode, useCallback, ReactElement } from 'react'
import { Col, Row, Table as AntTable, Pagination, Form, Spin, Badge, Typography, Result } from 'antd'
import { ISearch, IServerTable, TCustomsFilters } from './types'
import { enhanceServerTable } from './serverTableEnhance'
import { useSystem, useTables } from '../../hooks'
import { useDispatch } from 'react-redux'
import { timeAsDayjs } from 'utils/time'
import { tablesResetFiltersValues, tablesSetFiltersValues, tablesSetTotalDocs } from 'store/modules/tables/actions'
import { ColumnType, TablePaginationConfig } from 'antd/lib/table/interface'
import { ColumnGroupType } from 'antd/lib/table'
import api from 'services/api'
import { InfoCircleOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { MoreOptions } from 'components'
import { IReduxRoute } from 'store/modules/system/types'
import { downloadCSVFile } from './serverTableFunctions'
import { Gutter } from 'antd/lib/grid/row'

function ServerTable ({
  columns, url, hasProposalStatus,
  filters, hasLeadStatus, customsParams = {},
  fixedDate = false,
  csvFilename, buttons, primaryFilter,
  secondaryFilter, hasAction, trimester,
  filterRow, customClass = '',
  timeout = 30000
}: IServerTable) {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any[]>([])
  const [totalDocs, setTotalDocs] = useState<number>(0)

  const [countFilters, setCountFilters] = useState<number | undefined>()
  const [errorMessage, setErrorMessage] = useState('')

  const [expandMoreFilters, setExpandMoreFilters] = useState(false)

  const [hasFilledSearchs, setHasFilledSearchs] = useState<boolean>(false)

  const dispatch = useDispatch()
  const system = useSystem()
  const tables = useTables()
  const LIMIT = 15
  const hideFilters = 885
  const showFilters = 886
  const abortController = new AbortController()

  const formRef: MutableRefObject<any> = useRef(null)

  const fetchData = async (params: ISearch, abortSignal: AbortSignal): Promise<void> => {
    const finalParams = { ...params } as ISearch

    if (params.proposalStatus && !hasProposalStatus) delete finalParams.proposalStatus
    if (params.leadStatus && !hasLeadStatus) delete finalParams.leadStatus

    setLoading(true)
    try {
      const response = await api.get(url, {
        params: finalParams,
        timeout: params.download ? 600000 : timeout,
        signal: abortSignal
      })

      const data = response.data.data
      const CSV = data.csv
      const firstKey = Object.keys(data)[0]
      const item = data[firstKey]
      const finalData = enhanceServerTable(item.docs)
      const formatFinalData = finalData.map((item: any) => ({ ...item }))

      if (CSV) {
        downloadCSVFile(CSV, csvFilename, { hasDownload: tables.filters.download })
        dispatch(tablesSetFiltersValues({ ...tables.filters, download: undefined, downloadSignatures: undefined }))
      }
      if (item.totalDocs) dispatch(tablesSetTotalDocs(item.totalDocs))

      setData([])
      setData(formatFinalData)
      setTotalDocs(item.totalDocs)
      setLoading(false)
    } catch (err) {
      if (!abortSignal.aborted) {
        setLoading(false)
        setErrorMessage(err.message || 'Falha ao buscar valores')
        setData([])
      }
    }
  }

  const prevPageRef: MutableRefObject<any> = useRef()
  useEffect(() => {
    prevPageRef.current = tables.filters.page
  })
  const prevPage = prevPageRef?.current

  const returnSameTable = (historyRoutes: IReduxRoute[]): boolean => historyRoutes && historyRoutes.length === 3 && (historyRoutes[0].path === historyRoutes[2].path && historyRoutes[1].meta.canGoBack === true)
  const isReturnSameTable = useMemo(() => returnSameTable(system.historyRoutes), [system])

  function resetAllvalues () {
    if (formRef.current) formRef.current.resetFields()
    if (tables.promoterSetFn) tables.promoterSetFn(undefined)
    dispatch(tablesResetFiltersValues(fixedDate, hasProposalStatus, hasLeadStatus, hasAction, trimester, customsParams.needVinculation))
  }

  useEffect(() => {
    if (customsParams) dispatch(tablesSetFiltersValues({ ...tables.filters, ...customsParams }))
  }, [])

  useEffect(() => {
    const tablesFiltersArray: [string, string | number | undefined | Array<any>][] = Object.entries(tables.filters)
    const keysIgnore = [
      String(secondaryFilter?.key),
      String(primaryFilter?.key),
      'page',
      'limit',
      'download',
      'downloadSignatures',
      'searchUseFilters']
    const amountActived = tablesFiltersArray.map(filter => {
      const [key, value] = filter
      if (value === 1 || value === -1) return null
      return !keysIgnore.includes(key) && value !== undefined && value?.toString()?.length !== 0 ? filter : null
    }).filter(element => element ? element : null)
    setCountFilters(amountActived?.length)
  }, [tables.filters, url])

  useLayoutEffect(() => {
    const abortSignal = abortController.signal

    const filteredCustoms = JSON.parse(JSON.stringify(customsParams))
    const data: ISearch = tables.filters

    if (system.historyRoutes.length > 0) fetchData({ ...data, ...filteredCustoms }, abortSignal)

    return () => {
      abortController.abort()
    }
  }, [tables.update, url])

  useEffect(() => {
    const abortSignal = abortController.signal

    function handleSearch (data: ISearch, customs: TCustomsFilters) {
      const finalData: { [key: string]: string | undefined | number } = { ...data, ...customs }
      let canReturnFirstPage = true
      if (isReturnSameTable && hasFilledSearchs === false) {
        setHasFilledSearchs(true)
        const formFillData = Object.assign({}, data)
        if (data.createdDate) {
          const reverseDate = (data.createdDate as string).indexOf('-') !== -1 ? (formFillData.createdDate as string).split('-').reverse().join('-') : undefined
          formFillData.createdDate = timeAsDayjs(reverseDate)
        }
        if (data.updatedDate) formFillData.updatedDate = timeAsDayjs(data.updatedDate)

        if (data.leadStatus && typeof data.leadStatus === 'string') formFillData.leadStatus = data.leadStatus.split(',')
        if (data.proposalStatus && typeof data.proposalStatus === 'string') formFillData.proposalStatus = data.proposalStatus.split(',')
        if (formRef.current) formRef.current.setFieldsValue(formFillData)

        canReturnFirstPage = false
      }

      const customsKeys = Object.keys(customs)
      for (const searched of customsKeys) {
        if (finalData[searched] && !customs[searched]) delete finalData[searched]
      }

      if (tables.filters.createdDate) finalData.createdDate = tables.filters.createdDate
      if (tables.filters.updatedDate) finalData.updatedDate = tables.filters.updatedDate
      if (canReturnFirstPage && prevPage && prevPage !== 1 && prevPage === tables.filters.page) dispatch(tablesSetFiltersValues({ ...tables.filters, page: 1 }))

      fetchData(finalData as ISearch, abortSignal)
    }

    const filteredCustoms = JSON.parse(JSON.stringify(customsParams))
    const data: ISearch = tables.filters

    if (system.historyRoutes.length > 0) handleSearch(data, filteredCustoms)

    return () => {
      abortController.abort()
    }
  }, [tables.filters.createdDate, tables.filters.updatedDate, tables.filters, system, url])

  useEffect(() => {
    const { historyRoutes } = system
    if (historyRoutes && historyRoutes.length > 0) {
      let isTableSequence = false
      let routeCanBack = false

      const finalElements = historyRoutes.slice(Math.max(historyRoutes.length - 2, 0))
      finalElements.forEach((item, index) => {
        const last = finalElements[index - 1]
        if (last && last.isTable && item.isTable) isTableSequence = true
        if (last && !last.meta.canGoBack && !item.meta.canGoBack) routeCanBack = true
      })

      const notEqualTables = historyRoutes && historyRoutes.length === 3 && (historyRoutes[0].path !== historyRoutes[2].path && historyRoutes[1].meta.canGoBack === true)
      if (isTableSequence || notEqualTables || routeCanBack) resetAllvalues()
    }

    if (historyRoutes && historyRoutes[0] && !historyRoutes[1] && !historyRoutes[2]) {
      resetAllvalues()
    }
  }, [system, url])

  const limitsPagination = () => {
    if ((tables.filters.page * LIMIT) > totalDocs) return totalDocs + '/' + totalDocs
    return (tables.filters.page * LIMIT) + '/' + totalDocs
  }

  const [screenHeight, setScreenHeight] = useState<number | undefined | string>(0)
  const [screenWidth, setScreenWidth] = useState<number | undefined | string>(0)
  const cellphoneWidth = 768
  const isMobile = Number(screenWidth) < cellphoneWidth
  const gutter: [Gutter, Gutter] = isMobile ? [0, 15] : [15, 15]

  useEffect(() => {
    function handleResize () {
      setScreenWidth(document.documentElement.clientWidth)
      setScreenHeight(document.documentElement.clientHeight)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (!screenHeight) setScreenHeight(document.documentElement.clientHeight)
    if (!screenWidth) setScreenWidth(document.documentElement.clientWidth)

    if (window.matchMedia('(orientation: portrait)').matches) {
      if (Number(screenWidth) < showFilters) setExpandMoreFilters(true)
    }
  }, [screenHeight, screenWidth, expandMoreFilters])

  const handleTableChange = async (
    pagination: TablePaginationConfig,
    filters: Record<string, (string | number | boolean)[] | null>,
    sorter: any
  ) => {
    let sortOrder

    if (sorter.order === 'ascend') sortOrder = -1
    else if (sorter.order === 'descend') sortOrder = 1

    const sorterField = sorter.field
    const paramsObject = { [sorterField]: sortOrder }

    const sorterKeys = Object.keys(paramsObject)
    if (sorterKeys.length > 0) {
      type TColumnsType = ColumnGroupType<any> | ColumnType<any>

      const finalData: {[key: string]: any} = { ...tables.filters, ...paramsObject }
      const BySorter = (item: TColumnsType) => item.sorter
      const columnsWithSorter = columns.filter(BySorter)
      const onlyDataIndex = (item: TColumnsType) => (item as any).dataIndex
      const possiblesIndexes = columnsWithSorter.map(onlyDataIndex)
      const sorterQuantity = possiblesIndexes.filter(item => finalData[item] && (finalData[item] === 1 || finalData[item] === -1))

      if (sorterQuantity.length > 1) {
        const notActualKey = sorterQuantity.filter(item => item !== sorterKeys[0])
        if (notActualKey.length > 0) delete finalData[notActualKey[0]]
      }
      dispatch(tablesSetFiltersValues({ ...finalData }))
    }
  }

  const TableIcon = useCallback((errorMessage: string, loading: boolean): ReactElement => {
    if (loading) return <Result status='404' subTitle='Carregando informações.' />
    if (errorMessage) return <Result status="500" subTitle={errorMessage} />
    return <Result status='404' subTitle='Sem resultados' />
  }, [
    errorMessage,
    loading
  ])

  return (
    <Fragment>
      <Form
        className="server-table-filters"
        ref={formRef}
        layout="vertical"
      >
        <Row align="bottom" justify="space-between" >
          <Col className="server-table-filters__layout-primary">
            {primaryFilter}
          </Col>

          {expandMoreFilters && filters && filters.length > 0 && (
            <Fragment>
              <div className="server-table-filters__overlay" onClick={() => setExpandMoreFilters(prev => !prev)} >
                <div className="server-table-filters__overlay--text-container">
                  <Typography.Title level={3} className="server-table-filters__overlay--text">
                    <InfoCircleOutlined className="server-table-filters__overlay--icon"/>
                    Clique fora para fechar
                  </Typography.Title>
                </div>
              </div>

              <Row className={'server-table-filters__grid-expanded'} align="bottom" gutter={gutter}>
                {Number(screenWidth) < showFilters && (
                  <Col className="server-table-filters__layout-grid-expanded">
                    {secondaryFilter}
                  </Col>
                )}

                {filters.map((element: ReactNode, index: number) => {
                  return (
                    <Col className="server-table-filters__layout-grid-expanded" key={index}>
                      <>
                        {element}
                      </>
                    </Col>
                  )
                })}
              </Row>
            </Fragment>
          )}

          <Col className="server-table-filters__mobile-full">
            <Row align="bottom" className="server-table-filters__justify-group-second-col" gutter={ [15, 0]}>

              {Number(screenWidth) > hideFilters && <Col > {secondaryFilter}</Col>}

              <Col className="server-table-filters__width-button-sm">
                {buttons && (
                  <MoreOptions
                    filtersClicked={expandMoreFilters}
                    buttonTitle="Ações"
                    disabled={tables.ignoreAll}
                    contents={
                      <>
                        {buttons}
                      </>
                    }
                  />
                )}
              </Col>

              {filters && filters.length > 0 && (
                <Fragment>
                  <Col className={!expandMoreFilters ? 'server-table-filters__dropdown' : 'server-table-filters__dropdown server-table-filters__button-actived'}>
                    <button
                      type="button"
                      className={`server-table-filters__button-more ${tables.ignoreAll ? 'server-table-filters__button-more__disabled' : ''}`}
                      onClick={() => setExpandMoreFilters(prev => !prev)}
                      disabled={tables.ignoreAll}
                    >
                      <section className="server-table-filters__section-layout">
                        {expandMoreFilters ? (
                          <MinusOutlined className="server-table-filters__icon-plus-less" />
                        ) : (
                          <PlusOutlined
                            className={`server-table-filters__icon-plus-less ${tables.ignoreAll ? 'server-table-filters__icon-plus-less__disabled' : ''}`}
                          />
                        )}
                        <section className="server-table-filters__section-relative">
                          <div
                            className={`server-table-filters__text-more-filters ${tables.ignoreAll ? 'server-table-filters__text-more-filters__disabled' : ''}`}
                          >
                            Filtros
                          </div>
                          {!tables.ignoreAll && (
                            <Badge overflowCount={99} count={countFilters} className='server-table-filters__badge-count' />
                          )}
                        </section>
                      </section>
                    </button>
                  </Col>
                </Fragment>
              )}
            </Row>
          </Col>
        </Row>
      </Form>

      {filterRow}

      <Row className={'server-table '} >
        <Col flex="auto" >
          <Spin spinning={loading}>
            <Row className="">
              <AntTable
                pagination={false}
                dataSource={data || []}
                columns={(columns as any)}
                rowKey={'_id'}
                id='server-table'
                locale={{ emptyText: TableIcon(errorMessage, loading) }}
                className={`antd-base-table ${customClass || ''}`}
                onChange={handleTableChange}
              />
            </Row>
          </Spin>

          <Row
            align='middle'
            justify='space-between'
            className="server-table__pagination"
          >
            <Row align="bottom" gutter={[15, 0]}>
              <Col>
                <Pagination
                  responsive
                  pageSize={LIMIT}
                  defaultCurrent={tables.filters.page}
                  current={tables.filters.page}
                  total={totalDocs}
                  onChange={(page) => dispatch(tablesSetFiltersValues({ ...tables.filters, page: page }))}
                />
              </Col>

              <div className="server-table__tag-totals">
                <label>
              Total: <strong>{loading ? <Spin size="small" spinning={loading}/> : limitsPagination()}</strong>
                </label>
              </div>
            </Row>
          </Row>

        </Col>
      </Row>

    </Fragment>
  )
}

export default ServerTable

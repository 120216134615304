import React, { Fragment, useLayoutEffect, useState } from 'react'
import { Button, Row, Divider, Empty, Skeleton, Form, Col } from 'antd'
import { useModal } from 'hooks'
import swal from 'utils/swal'
import { timeAsDayjs } from 'utils/time'
import { DownloadOutlined } from '@ant-design/icons'
import { IPrognum } from './types'
import ProposalEgiRepository from 'egi/repositories/ProposalEgiRepository'

type Props = {
  items: IPrognum
  downloadDocument: Function
  fileName: string
}
function PanelContent ({ items, downloadDocument, fileName }: Props) {
  return (
    <Fragment>
      {items && (
        <div key={items._id} className="flex flex-column flex-align-start">
          <li>Criação do documento: <b>{timeAsDayjs(items.createdAt).format('DD/MM/YYYY HH:mm')}</b></li>
          {items.path?.map((link) => (
            <div key={link} className="flex flex-justify-center w-100">
              <Button
                className="share-button mt-3"
                type="primary"
                icon={<DownloadOutlined />}
                onClick={() => downloadDocument({ baseUrl: link, name: fileName })}
              >
                Baixar documento
              </Button>
            </div>
          ))}
        </div>
      )}
    </Fragment>
  )
}

function PrognumDocs () {
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const [prognumData, setProgumData] = useState<IPrognum[]>()

  const modal = useModal()

  const getPrognum = async () => {
    setLoadingData(true)
    try {
      const response = await ProposalEgiRepository.getPrognum({
        params: {
          proposalId: String(modal.proposalId)
        }
      })

      const prognumDocuments = response.data.data?.prognum.documents || []
      setProgumData(prognumDocuments)
    } catch (err) {
      swal.basic({ title: 'Atenção!', icon: 'warning', text: err.message })
    }
    setLoadingData(false)
  }

  async function downloadDocument ({ baseUrl, name }:{ baseUrl: string, name?: string }) {
    return fetch(baseUrl).then(response => response.blob()).then(blob => {
      const blobUrl = URL.createObjectURL(blob)
      const a = document.createElement('a')

      a.href = blobUrl
      a.download = name + '.csv'
      a.target = '_blank'

      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    })
  }
  useLayoutEffect(() => {
    getPrognum()
  }, [])

  return (
    <Fragment>
      <Row align="middle" justify="center">
        <Form
          layout="vertical"
        >
          <Row align="middle">
            <Col>
              <Button
                type="primary"
                htmlType="submit"
              >
                Gerar novo documento
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>

      <Divider />

      {loadingData ? (
        <Row align="middle" justify="center">
          <Skeleton.Button active className="infobanc-skeleton" />
        </Row>
      ) : (
        <Fragment>
          <Row align="middle" justify="center">
            <div className="infobanc">
              {prognumData && Array.isArray(prognumData) && prognumData.length < 1 ? (
                <Empty description="Nenhum documento encontrado" />
              ) : (
                <Row>
                  {Array.isArray(prognumData) && prognumData.length > 0 && prognumData?.map((items) => (
                    <PanelContent
                      items={items}
                      downloadDocument={downloadDocument}
                      key={items._id}
                      fileName='relatorio_prognum'
                    />
                  ))}
                </Row>
              )}
            </div>
          </Row>
        </Fragment>
      )}

    </Fragment>
  )
}

export default PrognumDocs

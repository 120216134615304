import React, { Fragment, useMemo, useState } from 'react'
import { ServerTable } from 'components'
import ButtonModel from 'components/SeverTable/ButtonModel'
import filters from 'components/SeverTable/IndexFilters'
import campaignColumns from '../../dataTable/campaignColumns'
import CampaignModal from '../CampaignModal/CampaignModal'
import { LimiterTable } from 'components/LimiterTable/LimiterTable'

function CampaignLink () {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [defaultLink, setDefaultLink] = useState<string>('')

  function addDefaultLink (url: string) {
    setShowModal(true)
    setDefaultLink(url)
  }

  function allButtons (): React.ReactElement[] {
    const addButtons: React.ReactElement[] = [
      <ButtonModel
        onClick={() => setShowModal(true)}
        text={'Novo link de campanha'}
        key={0}
      />
    ]

    return addButtons
  }

  const finalColumns = useMemo(() => campaignColumns({ setShareLink: addDefaultLink }), [])

  return (
    <Fragment>
      <CampaignModal
        defaultInvite={defaultLink}
        setVisible={setShowModal}
        visible={showModal}
      />
      <LimiterTable>
        <ServerTable
          url="/links?campaign=true"
          columns={finalColumns}
          buttons={allButtons()}
          primaryFilter={
            filters().search
          }
        />
      </LimiterTable>
    </Fragment>
  )
}

export default CampaignLink

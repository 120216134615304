import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import commomColumns, { columnBlockUser, columnProviderDocuments } from './commomDataTable'
import { IColumns } from 'egi/types'
import { Tag } from 'antd'
import { varColors } from 'styles/colors'
import translate from 'utils/translate'
import format from 'utils/format'
import { useAuth } from 'hooks'
import NameDropdownController from 'components/NameDropdownController/NameDropdownController'
import UserTableModel from 'egi/models/UserTableModels'
import commonColumns, { commomNameOverflowWidth } from 'components/UserTable/utils/commonColumns'
import { OverflowTooltip } from 'components/OverflowToolTip/OverflowTooltip'

const tempColumns = commomColumns.slice(3)

const finalColumns = (): ColumnsType<Object> => [
  {
    ...commonColumns.name,
    render: (name: string, row: { _id: string } | any) => {
      const user = useAuth()
      return (
        <>
          <OverflowTooltip width={commomNameOverflowWidth} title={<>
            {name}
            { row.cpfOrCnpj && <div>{row.cpfOrCnpj}</div>}
          </>}>
            <NameDropdownController
              showUserDetails={UserTableModel.showUsersDetails(user.level)}
              dropDownLabel={name}
              userName={name}
              userId={row._id}
              tableLevel={'Prestador'}
            />
            { row.cpfOrCnpj && row.cpfOrCnpj}
          </OverflowTooltip>
        </>
      )
    }
  },
  commonColumns.email,
  {
    title: 'Razão Social',
    dataIndex: 'fantasyName',
    sorter: true,
    width: 150,
    render: (fantasyName: string) => {
      if (!fantasyName) return '-'
      return (
        <OverflowTooltip width={150}>
          <p className='capitalize'>{fantasyName.toLowerCase()}</p>
        </OverflowTooltip>
      )
    }
  },
  ...tempColumns
]

const columns = ({ blockUser, providerDocuments }: IColumns): ColumnsType<Object> => {
  const finalColumnProvider = [
    ...finalColumns()
  ]

  if (providerDocuments) {
    finalColumnProvider.push({
      title: 'Tipo',
      dataIndex: 'areaName',
      width: 100,
      sorter: true,
      render: (department: string) => {
        if (!department) return '-'
        return format.capitalize(department)
      }
    } as any)

    finalColumnProvider.push(columnProviderDocuments(providerDocuments))
  } else {
    finalColumnProvider.push({
      title: 'Liberação',
      dataIndex: 'status',
      width: 100,
      align: 'center',
      sorter: true,
      render: (value: string) => {
        return <Tag color={varColors[value]}>{translate.userStatus(value)}</Tag>
      }
    })
  }

  finalColumnProvider.push(columnBlockUser(blockUser))

  return finalColumnProvider
}

export default columns

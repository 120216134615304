import loadable from '@loadable/component'
import Loading from 'components/Loading/Loading'
import { timeout } from 'promise-timeout'
import React from 'react'
import { TIMEOUT_LOADER } from 'utils/globals'

const PortabilityECPF = loadable(
  () => timeout(import(
    /* webpackChunkName: "PortabilityECPF" */
    'ecpf/app/PortabilityECPF/views/PortabilityECPF/PortabilityECPF'
  ), TIMEOUT_LOADER),
  {
    fallback: <Loading/>
  }
)

export { PortabilityECPF }

import { ColumnGroupType, ColumnsType, ColumnType, TablePaginationConfig } from 'antd/lib/table/interface'
import { ISearch } from 'components/SeverTable/types'

export function hasFilterCounter (keysToIgnore: string[], entriesToIgnore: {}[]) {
  return function ([key, value]: [string, string]) {
    return !keysToIgnore.includes(key) &&
      !['', undefined].includes(value) &&
      !JSON.stringify(entriesToIgnore).includes(JSON.stringify({ [key]: value })) &&
      !(value?.toString() === '1' || value?.toString() === '-1')
  }
}

export const handleServerTableChange = (columns: ColumnsType<any>, table: ISearch) => (
  pagination: TablePaginationConfig,
  filters: Record<string, (string | number | boolean)[] | null>,
  sorter: any
) => {
  let sortOrder

  if (sorter.order === 'ascend') {
    sortOrder = '-' + sorter.field
  } else if (sorter.order === 'descend') {
    sortOrder = sorter.field
  }

  const sorterField = 'sort'
  const paramsObject = { [sorterField]: sortOrder }

  const sorterKeys = Object.keys(paramsObject)
  if (sorterKeys.length > 0 && sorterKeys.length > 0) {
    type TColumnsType = ColumnGroupType<any> | ColumnType<any>

    const finalData: { [key: string]: string } = Object.assign(table, paramsObject || {})
    const BySorter = (item: TColumnsType) => item.sorter
    const columnsWithSorter = columns.filter(BySorter)
    const onlyDataIndex = (item: ColumnType<any>) => item.dataIndex
    const possiblesIndexes = columnsWithSorter.map(onlyDataIndex)
    const sorterQuantity = possiblesIndexes.filter(item => finalData.sort === item || finalData.sort === '-' + item)

    if (sorterQuantity.length > 1) {
      const notActualKey = sorterQuantity.filter(item => item !== sorterKeys[0])
      if (notActualKey.length > 0 && notActualKey[0]) delete finalData[notActualKey[0] as string]
    }

    return finalData
  }
}

const removeUnwantedFields = (filters: [string, any][], sorter: string | string[]) => {
  const filteredData: Record<string, any> = {}

  filters.forEach(([key, value]) => {
    const isSorter = (value === 1 || value === -1) && key !== 'page'

    if (isSorter && key === sorter) {
      filteredData[key] = value
    }

    if (!isSorter) {
      filteredData[key] = value
    }
  })

  return filteredData
}
export const handleServerTableChangeEgi = (columns: ColumnsType<any>, table: ISearch) => (
  pagination: TablePaginationConfig,
  filters: Record<string, (string | number | boolean)[] | null>,
  sorter: any
) => {
  let sortOrder

  if (sorter && sorter.field) {
    if (sorter.order === 'ascend') {
      sortOrder = -1
    } else if (sorter.order === 'descend') {
      sortOrder = 1
    }
  }

  const sorterField = sorter.field
  const paramsObject = { [sorterField]: sortOrder }

  const sorterKeys = Object.keys(paramsObject)
  if (sorterKeys.length > 0 && sorterKeys.length > 0) {
      type TColumnsType = ColumnGroupType<any> | ColumnType<any>;

      const finalData: { [key: string]: any } = { ...table, ...paramsObject }
      const BySorter = (item: TColumnsType) => item.sorter
      const columnsWithSorter = columns.filter(BySorter)
      const onlyDataIndex = (item: ColumnType<any>) => item.dataIndex
      const possiblesIndexes = columnsWithSorter.map(onlyDataIndex)

      const currentSort = finalData.sort ? String(finalData.sort) : undefined

      const sorterQuantity = possiblesIndexes.filter(
        item => currentSort === item || currentSort === '-' + item
      )

      if (sorterQuantity.length > 1) {
        const notActualKey = sorterQuantity.filter(item => item !== sorterKeys[0])
        if (notActualKey.length > 0 && notActualKey[0]) {
          delete finalData[notActualKey[0] as string]
        }
      }

      const hasSort = sorter?.column?.sorter

      return removeUnwantedFields(Object.entries(finalData), hasSort
        ? sorter.column.dataIndex.toString()
        : undefined)
  }
}

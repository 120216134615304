import { useResources } from 'hooks'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { resourcesGetBanks } from 'store/modules/resources/actions'
import Select, { ISelectProps } from 'components/Select/Select'

export default function BankSelect ({ onChange, ...rest }: ISelectProps) {
  const { banks } = useResources()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!banks.length) dispatch(resourcesGetBanks())
  }, [])

  const banksOption = useMemo(() => {
    const options = banks.map((element) => {
      return ({
        label: `${element.code} - ${element.name}`,
        value: element.code
      })
    })

    return options
  }, [banks])

  return (
    <Select
      {...rest}
      onChange={onChange}
      placeholder='Selecione o banco'
      options={banksOption}
      showSearch
      optionFilterProp="label"
      notFoundContent='Nenhum dado encontrado'
    />
  )
}

import React, { useEffect, useState } from 'react'
import { Alert, Button, Form, Input, Row, Select } from 'antd'
import { MentionInput } from 'components'
import { validateResponse } from 'utils/validate'
import { qualification as qualificationConsumer } from 'egi/consumers'
import swal from 'utils/swal'
import translate from 'utils/translate'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'
import VariablesAvailable from 'components/VariablesAvailable/VariablesAvailable'
import FrameModelRules from 'egi/models/FrameModel'

export interface IMinutaMoldVariables {
  id: string
  display: string
}

interface IFrameModelForm {
  onCancel: () => void,
  onSuccess: () => void,
  productId: string,
  types: Array<{ label: string, value: string }>
}

function FrameModelForm ({ onCancel, onSuccess, productId, types }: IFrameModelForm) {
  const [qualificationText, setQualificationText] = useState<string>('')
  const [errors, setErrors] = useState<{description?: string, text?: string}>({})

  const [type, setType] = useState<string>('')
  const [variables, setVariables] = useState<string | IMinutaMoldVariables[]>('Nenhuma variável disponível')
  const [status, setStatus] = useState<string | undefined>(undefined)

  function formatText (text: string) {
    return text.replaceAll('[', '').replaceAll(']', '')
  }

  const onSubmit = async (values: {description: string, type: string}) => {
    if (!qualificationText) return setErrors({ text: 'Campo obrigatório.' })

    const data = {
      ...values,
      productId,
      text: formatText(qualificationText)
    }

    setStatus('createMinutalMold')
    try {
      const response = await qualificationConsumer.sendMinutaMold(data)
      setStatus(undefined)
      onSuccess()
      swal.basic({ title: 'Sucesso!', icon: 'success', text: response.message })
    } catch (err) {
      if (err.data?.invalid) {
        let errors: {description?: string, text?: string} = {}
        errors = validateResponse(err.data?.invalid)
        setErrors(errors)
        setStatus(undefined)
        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
        return
      }

      setErrors({})
      setStatus(undefined)
      swal.basic({ title: 'Atenção', icon: 'warning', text: err.message })
    }
  }

  useEffect(() => {
    async function getMinutaMoldVariables () {
      setStatus('getVariables')
      try {
        const response = await qualificationConsumer.minutaMoldVariables({ params: { type: type } })
        const variables = response.data.variables

        if (variables.length < 1) setVariables('Nenhuma variável disponível')
        else {
          const formatVariables = variables.map((item: string) => ({ id: item, display: translate.minutaMold(item) }))
          setVariables(formatVariables)
        }
        setStatus(undefined)
      } catch (err) {
        setStatus(undefined)
        swal.basic({ title: 'Atenção', icon: 'warning', text: err.message })
      }
    }

    if (type) getMinutaMoldVariables()
  }, [type])

  return (
    <Form layout="vertical" onFinish={onSubmit}>
      <Form.Item
        name="description"
        label={<label>Nome:</label>}
        help={errors.description && errors.description}
        validateStatus={errors.description && ('error')}
      >
        <Input placeholder="Digite o nome do modelo de quadro"/>
      </Form.Item>

      <Form.Item
        name="type"
        label={<label>Tipo:</label>}
        help={errors.description && errors.description}
        validateStatus={errors.description && ('error')}
      >
        <Select
          placeholder="Escolha o tipo do modelo de quadro"
          onChange={(value: string) => setType(value)}
          options={types}
        />
      </Form.Item>

      {FrameModelRules.canShowAlertAboutCertificateText(type) && (
        <Alert
          showIcon
          type='info'
          className='mb-3'
          message={<strong>Importante saber</strong>}
          description={<span>O texto inserido <strong>será replicado</strong> em a), b), c), etc., de acordo com a quantidade de ocorrências do passo <strong>&apos;Tributos Federais&apos;</strong>. Portanto, adicione o texto base <strong>apenas uma única vez</strong>.</span>}
        />
      )}

      <VariablesAvailable variables={variables} />

      <Form.Item
        name="text"
        label={<label>Texto do modelo de quadro:</label>}
        help={errors.text && errors.text}
        validateStatus={errors.text && ('error')}
        className='mb-6'
      >
        <MentionInput
          mentions={variables}
          placeholder="Utilize '@' para mencionar uma varíavel"
          onChange={(value) => setQualificationText(value)}
          isCreate
        />
      </Form.Item>

      <Row justify="end" align="bottom">
        <Button
          type="ghost"
          className="mr-2"
          onClick={() => onCancel()}
        >
          Cancelar
        </Button>

        <Button
          type="primary"
          htmlType="submit"
          loading={status === 'createMinutalMold'}
        >
          Enviar
        </Button>
      </Row>
    </Form>
  )
}

export default FrameModelForm

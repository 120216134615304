import { PAGE_OFFSET_ECPF } from 'hooks/useQuery'
import EntityRoute from 'routes/ecp/EntityRoute'

abstract class EntityRouteECPF extends EntityRoute {
  PREFIX = '/ecp-funcao'

  getListSearch () {
    return `?${PAGE_OFFSET_ECPF}=1`
  }
}

class OperatorRoutes extends EntityRouteECPF {
  name = 'Operador'
  path = '/operator'
  preposition = ''

  public list () {
    return {
      name: this.name + 'es',
      path: this.PREFIX + this.path + 's',
      search: this.getListSearch()
    }
  }
}

class MainOfficeRoutes extends EntityRouteECPF {
  name = 'Matriz'
  path = '/main-office'
  preposition = ''

  public list () {
    return {
      name: this.name + 'es',
      path: this.PREFIX + this.path + 's',
      search: this.getListSearch()
    }
  }
}

class RegionalRoutes extends EntityRouteECPF {
  name = 'Gerente de negócios'
  path = '/regional'
  preposition = ''

  public list () {
    return {
      name: this.name,
      path: this.PREFIX + this.path + 's',
      search: this.getListSearch()
    }
  }
}

class PromoterRoutes extends EntityRouteECPF {
  name = 'Corban'
  path = '/promoter'
  preposition = ''

  public list () {
    return {
      name: this.name + 's',
      path: this.PREFIX + this.path + 's',
      search: this.getListSearch()
    }
  }
}

class EmployerRoutes extends EntityRouteECPF {
  name = 'Empresas Matriz'
  path = '/employer'
  preposition = ''

  public list () {
    return {
      name: this.name,
      path: this.PREFIX + this.path + 's',
      search: this.getListSearch()
    }
  }
}

class OrganizationRoutes extends EntityRouteECPF {
  name = 'Convênio'
  path = '/organization'
  preposition = ''

  public list () {
    return {
      name: this.name + 's',
      path: this.PREFIX + this.path + 's',
      search: this.getListSearch()

    }
  }
}

class ProposalRoutes extends EntityRouteECPF {
  name = 'Proposta'
  path = '/proposal'
  preposition = 'da'

  getListSearch () {
    return `?${PAGE_OFFSET_ECPF}=1&tipoConsulta=1&campoOrdenacao=1&tipoOrdenacao=1`
  }

  public list () {
    return {
      name: this.name + 's',
      path: this.PREFIX + this.path + 's',
      search: this.getListSearch()
    }
  }

  public flow () {
    return {
      name: 'Cadastrar proposta',
      path: this.path + '/flow/:proposalType/:cpf?'
    }
  }
}

class PortabilityRoutes extends EntityRouteECPF {
  name = 'Portabilidade'
  path = '/portability'
  preposition = 'da'

  public home () {
    return {
      name: 'Portabilidade',
      path: this.path
    }
  }
}

class ClientRoutes extends EntityRouteECPF {
  name = 'Cliente'
  path = '/client'
  preposition = 'do'

  getListSearch () {
    return `?${PAGE_OFFSET_ECPF}=1&tipoConsulta=1`
  }

  public details () {
    return {
      name: `Detalhes ${this.preposition} ${this.name.toLocaleLowerCase()}`,
      path: `${this.PREFIX}${this.DETAILS}${this.path}/:cpf`
    }
  }

  public form () {
    return {
      name: `Formulário ${this.name.toLocaleLowerCase()}`,
      path: `${this.PREFIX}${this.FORM}${this.path}/:cpf?`,
      paramLessPath: this.PREFIX + this.FORM + this.path
    }
  }

  public initial () {
    return {
      name: 'Página inicial',
      path: `${this.PREFIX}${this.path}/initial-page`,
      search: this.getListSearch()
    }
  }
}

class ConfigurationRoutes extends EntityRouteECPF {
  name = 'Configuração'
  path = '/configuration'
  preposition = 'da'

  public main () {
    return {
      path: `${this.PREFIX}${this.path}`,
      name: 'Configurações'
    }
  }

  public insurance () {
    return {
      path: `${this.PREFIX}${this.path}/insurance`,
      name: 'Configurações de seguro e whatsapp'
    }
  }

  public maximumRequestedValue () {
    return {
      path: `${this.PREFIX}${this.path}/maximum-requested-value`,
      name: 'Configuração de valor máximo solicitado'
    }
  }
}

class FindUserRoutes extends EntityRouteECPF {
  name = 'Buscar usuário'
  path = '/find-user'
  preposition = ''

  public newContract () {
    return {
      name: 'Contratar Crédito',
      path: this.path
    }
  }

  public newPortability () {
    return {
      name: 'Portabilidade',
      path: `${this.path}/portability`
    }
  }

  public newRefinancing () {
    return {
      name: 'Refinanciamento',
      path: `${this.path}/refinancing`
    }
  }

  public newRenegotiation () {
    return {
      name: 'Renegociação',
      path: `${this.path}/renegotiation`
    }
  }
}

class SelectContractsRoutes extends EntityRouteECPF {
  name = 'Selecionar contratos'
  path = '/select-contracts'
  preposition = ''

  public refinancing () {
    return {
      name: 'Refinanciamento',
      path: `${this.path}/refinancing`
    }
  }

  public renegotiation () {
    return {
      name: 'Renegociação',
      path: `${this.path}/renegotiation`
    }
  }
}

const operationRoutes = new OperatorRoutes()
const mainOfficeRoutes = new MainOfficeRoutes()
const proposalRoutes = new ProposalRoutes()
const regionalRoutes = new RegionalRoutes()
const promoterRoutes = new PromoterRoutes()
const employerRoutes = new EmployerRoutes()
const organizationRoutes = new OrganizationRoutes()
const clientRoutes = new ClientRoutes()
const portabilityRoutes = new PortabilityRoutes()
const findUserRoutes = new FindUserRoutes()
const selectContracts = new SelectContractsRoutes()
const configurationRoutes = new ConfigurationRoutes()

export const ECPF = {

  FIND_USER: findUserRoutes.newContract(),
  FIND_USER_PORTABILITY: findUserRoutes.newPortability(),
  FIND_USER_REFINANCING: findUserRoutes.newRefinancing(),
  FIND_USER_RENEGOTIATION: findUserRoutes.newRenegotiation(),

  // Propostas

  PROPOSAL_LIST: proposalRoutes.list(),
  PROPOSAL_DETAILS: proposalRoutes.details(),
  PROPOSAL_FLOW: proposalRoutes.flow(),

  // Matriz (Origem 1)

  MAIN_OFFICE_LIST: mainOfficeRoutes.list(),

  // Gerente de negócios (Origem 2)

  REGIONAL_LIST: regionalRoutes.list(),

  // Gerente de Promoteres (corbans) (Origem 3)

  PROMOTER_LIST: promoterRoutes.list(),

  // Empregadores (Origem 4)

  EMPLOYER_LIST: employerRoutes.list(),

  // Orgões (Origem 5)

  ORGANIZATION_LIST: organizationRoutes.list(),

  // Operador (Origem 6)

  OPERATOR_LIST: operationRoutes.list(),

  // Clientes

  CLIENT_DETAILS: clientRoutes.details(),
  CLIENT_FORM: clientRoutes.form(),
  CLIENT_INITIAL_PAGE: clientRoutes.initial(),

  // Portabilidade

  PORTABILITY_HOME: portabilityRoutes.home(),

  // Seleção contratos

  SELECT_CONTRACTS_REFINANCING: selectContracts.refinancing(),
  SELECT_CONTRACTS_RENEGOTIATION: selectContracts.renegotiation(),

  // Configurações

  CONFIGURATION_MAIN: configurationRoutes.main(),
  CONFIGURATION_INSURANCE: configurationRoutes.insurance(),
  CONFIGURATION_MAXIMUM_REQUESTED_VALUE: configurationRoutes.maximumRequestedValue()
}

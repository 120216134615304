import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import api from 'services/api'
import { ILeadInfo } from 'egi/types'
import { ConnectionFailed, ErrInternetDisconnected } from './errors'

const simulation = {
  entries: async (data: object, config?: AxiosRequestConfig) => {
    try {
      const response: AxiosResponse = await api.get('/simulation/pricing', { params: data, ...config })
      return response.data
    } catch (err) {
      if (axios.isCancel(err)) throw err
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  simulationLead: async (data: ILeadInfo) => {
    try {
      const response: AxiosResponse = await api.post('/simulation', data)
      return response.data.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  simulation: async (data: object, config?: AxiosRequestConfig) => {
    try {
      const response: AxiosResponse = await api.patch('/simulation', data, config)
      return response.data
    } catch (err) {
      if (axios.isCancel(err)) throw err
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  leads: async (simulationId: string) => {
    try {
      const response: AxiosResponse = await api.get(`/simulation/${simulationId}/leads`)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  partnerReturns: async ({ proposalId }: { proposalId: string }) => {
    try {
      const response: AxiosResponse = await api.get(`/globals/pricing/return/${proposalId}`)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  }

}

export default simulation

import { IPaginate, Repository } from './Repository'
import api from 'services/api'
import { AxiosRequestConfig } from 'axios'
import { IPendingUserCovenants, IRHForm } from 'ecp/app/RH/rhInterfaces'
import { _userLevel } from 'ecp/models/UsersModel'

export interface IResendEmail {
  userId: string,
  rhId: string,
  level: _userLevel
}

class RHRepository extends Repository<IRHForm> {
  async create (data: IRHForm, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.post(`${this.path}`, data, config)
    )
  }

  async edit (id: string, data: IRHForm, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.put(`${this.path}/${id}`, data, config)
    )
  }

  async reSendEmail (data: IResendEmail, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.post(`${this.path}/email/send`, data, config)
    )
  }

  async getPendingUserCovenants (config?: AxiosRequestConfig) {
    return Repository.handle <IPaginate<IPendingUserCovenants>>(() =>
      this.api.get(`${this.path}/covenants/clients`, config)
    )
  }
}
export default new RHRepository({ api, path: '/ecp/rh' })

import { EditOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { AccessManagementProducts } from 'egi/repositories/UsersEgiRepository'
import React, { useState } from 'react'
import BackofficeSelectProductsModal from '../BackofficeSelectProductsModal/BackofficeSelectProductsModal'

type Props = {
  userId?: string
  products?: Array<AccessManagementProducts>,
  onFinish?: () => void
}

export function BackofficeIconProductButton ({
  onFinish, userId, products
}: Props) {
  const [visible, setVisible] = useState(false)

  const onCancel = () => {
    setVisible(false)
  }

  return (
    <>
      <BackofficeSelectProductsModal
        userId={userId}
        onCancel={onCancel}
        visible={visible}
        products={products}
        onFinish={onFinish}
      />

      <button
        className='backoffice-products-button__icon'
        onClick={() => {
          setVisible(true)
        }}
      ><EditOutlined /></button>
    </>
  )
}

export default function BackofficeProductsButton ({
  onFinish, userId, products
}: Props) {
  const [visible, setVisible] = useState(false)

  const onCancel = () => {
    setVisible(false)
  }

  return (
    <>
      <BackofficeSelectProductsModal
        userId={userId}
        onCancel={onCancel}
        visible={visible}
        products={products}
        onFinish={onFinish}
      />

      <Button
        type='primary'
        className='back-office-details__button'
        onClick={() => {
          setVisible(true)
        }}
      >
        Alterar produtos
      </Button>
    </>
  )
}

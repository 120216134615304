import React, { DetailedHTMLProps, ReactNode, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSystem, useTables } from 'hooks'
import dayjs from 'dayjs'
import { timeAsDayjs } from 'utils/time'
import { tablesResetFiltersValues } from 'store/modules/tables/actions'

export function useFillFilters () {
  const system = useSystem()
  const table = useTables()
  const dispatch = useDispatch()

  function resetAllvalues () {
    dispatch(tablesResetFiltersValues(true))
  }

  useEffect(() => {
    const { historyRoutes } = system
    if (historyRoutes && historyRoutes.length > 0) {
      let isTableSequence = false

      const finalElements = historyRoutes.slice(Math.max(historyRoutes.length - 2, 0))
      finalElements.forEach((item, index) => {
        const last = finalElements[index - 1]
        if (last && last.isTable && item.isTable) isTableSequence = true
      })

      const notEqualTables = historyRoutes && historyRoutes.length === 3 && (historyRoutes[0].path !== historyRoutes[2].path && historyRoutes[1].meta.canGoBack === true)
      if (isTableSequence || notEqualTables) {
        resetAllvalues()
      } else {
        const copyItem: any = JSON.parse(JSON.stringify(table.filters))
        Object.keys(copyItem).forEach((item) => {
          const actualItem = copyItem[item]
          if (Array.isArray(copyItem[item]) && copyItem[item].length > 0) copyItem[item] = copyItem[item].map((value: string) => timeAsDayjs(value, { applyTimezone: false }))
          if (typeof copyItem[item] === 'string' && dayjs(actualItem).isValid() && !['search'].includes(item)) copyItem[item] = timeAsDayjs(copyItem[item], { applyTimezone: false })
        })
      }
    }
  }, [
    system.historyRoutes
  ])
}

type Props = DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  children: ReactNode
}

export default function ServerTableFilters ({ children, className }: Props) {
  return (
    <div className={`server-table-filter ${className || ''}`}>
      {children}
    </div>
  )
}

import { deadlineInDays } from './functions'
import { ProposalState, Actions } from './types'

const initialState: ProposalState = {
  departments: [],
  id: undefined,
  isPj: false,
  needVinculation: false,
  maxDocs: 10,
  number: undefined,
  maxSize: 10000,
  maxDocsPerDepartment: 20,
  maxPersonalizedDocs: 20,
  preApproval: undefined,
  maxSizeDocument: 4000000,
  pastCredit: false,
  callback: undefined,
  step: {
    loading: false,
    id: undefined
  },
  status: undefined,
  deadlineInDays: undefined
}

const proposal = (state = initialState, action: Actions) => {
  switch (action.type) {
    case 'PROPOSAL_CONFIG_FETCH_SUCCEEDED':
      return {
        ...state,
        ...action.payload.documents
      }

    case 'PROPOSAL_LOADING_STEP':
      return {
        ...state,
        step: {
          id: action.payload.step.id,
          loading: action.payload.step.loading
        }
      }

    case 'PROPOSAL_SET_CALLBACK':
      return {
        ...state,
        callback: action.payload.fn
      }

    case 'PROPOSAL_STATE_FETCH_SUCCEEDED':
      return {
        ...state,
        priceRevision: action.payload.pricingRevision,
        id: action.payload.id,
        departments: action.payload.departments,
        simulation: action.payload.simulation,
        creditDefense: action.payload.creditDefense,
        isPj: action.payload.isPj,
        number: action.payload.number,
        pastCredit: action.payload.pastCredit,
        preApproval: action.payload.preApproval,
        exceptionDocuments: action.payload.exceptionDocuments,
        status: action.payload.status,
        proposalExpiration: {
          ...action.payload.proposalExpiration,
          isDateExpired: action.payload.isDateExpired,
          canShowExpiredButton: action.payload.canShowExpiredButton,
          canSeeSolicitationModal: action.payload.canSeeSolicitationModal,
          canShowExpiredDate: action.payload.canShowExpiredDate
        },
        deadlineInDays: deadlineInDays(action.payload.proposalExpiration?.date)
      }

    case 'SET_NEED_VINCULATION':
      return {
        ...state,
        needVinculation: action.payload.needVinculation
      }

    case 'RESET_PROPOSAL':
      return {
        ...initialState
      }

    default:
      return state
  }
}

export default proposal

import React from 'react'
import { InputNumber } from 'antd'
import { IPercentage } from './types'
import format from 'utils/format'

function PercentageInput ({ placeholder, onChange, value, disabled, id, defaultValue = 0, typeNumber = false }: IPercentage) {
  return (
    <InputNumber
      id={id}
      decimalSeparator=","
      disabled={disabled}
      defaultValue={defaultValue}
      className='w-100'
      min={0}
      max={100}
      value={value}
      onChange={onChange}
      formatter={ typeNumber ? (value?: string | number) => `${format.onlyNumber(value)}%` : (value?: string | number) => `${value}%` }
      parser={(value: string | undefined) => value ? value.replace('%', '') : ''}
      placeholder={placeholder}
    />
  )
}

export default PercentageInput

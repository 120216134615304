import React, { useState, Fragment, useLayoutEffect, useEffect } from 'react'
import { Col, Row, Table as AntTable, Input, Select, Pagination, Spin } from 'antd'
import { states } from 'utils/globals'
import { ITable } from './types'
import { TableProps } from 'antd/lib/table'

function Table ({ data, columns, loading, filters, status, selectUf, canSearch = true, limit, className = '', pagination, showPagination = true, rowKey = '_id', ...rest }: ITable & TableProps<any>) {
  const [filterData, setFilterData] = useState<Object[] | undefined>(undefined)
  const [screenHeight, setScreenHeight] = useState<number | undefined | string>(0)
  const [heightSize, setHeightSize] = useState<number | undefined | string>(0)
  const [screenWidth, setScreenWidth] = useState<number | undefined | string>(0)
  const [dataTable, setDataTable] = useState<Array<Object>>()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const limitItems = limit ?? 15

  const [search, setSearch] = useState({
    status: '',
    text: '',
    uf: ''
  })

  const dataFilter = (array: Array<any>, value: string) => {
    return array.filter((item: { [key: string]:string }) =>
      Object.keys(item).some((text: string) =>
        String(item[text])
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    )
  }

  const ufFilter = (array: Array<any>, value: string) => {
    return array.filter((item: { [key: string]:string }) =>

      item.uf.includes(value)
    )
  }

  const paginationPage = (page: number) => {
    setCurrentPage(page)
    const result:Array<Object> = []
    let setItems: Object[]
    let totalPages: number = 0
    !filterData ? setItems = data : setItems = filterData
    if (setItems) totalPages = Math.ceil(setItems.length / limitItems)

    let count = (page * limitItems) - limitItems
    const delimiter = count + limitItems
    if (page <= totalPages) {
      for (let i = count; i < delimiter; i++) {
        if (setItems[i] !== undefined) {
          result.push(setItems[i])
        }
        count++
      }
    }

    setDataTable(result)
  }

  const limitsPagination = () => {
    const total = () => {
      if (filterData) return filterData.length
      if (data) return data.length
      return 0
    }

    const current = () => {
      if ((currentPage * limitItems) > Number(total())) return total()
      return Number(currentPage * limitItems)
    }

    return current() + '/' + total()
  }

  const handleSelectStatus = (value: 'Aprovado' | 'Reprovado' | 'Refazer' | '') => {
    setSearch({ ...search, status: value })
  }

  const mainSearch = () => {
    const { status, text, uf } = search

    let filterTable = data

    if (text && status && uf) {
      filterTable = dataFilter(filterTable, text)
      filterTable = dataFilter(filterTable, status)
      filterTable = ufFilter(filterTable, uf)
    }

    if (text && status && !uf) {
      filterTable = dataFilter(filterTable, text)
      filterTable = dataFilter(filterTable, status)
    }

    if (!text && status && uf) {
      filterTable = dataFilter(filterTable, status)
      filterTable = ufFilter(filterTable, uf)
    }

    if (text && !status && uf) {
      filterTable = dataFilter(filterTable, text)
      filterTable = ufFilter(filterTable, uf)
    }

    if (text && !status && !uf) {
      filterTable = dataFilter(filterTable, text)
    }

    if (!text && status && !uf) {
      filterTable = dataFilter(filterTable, status)
    }

    if (!text && !status && uf) {
      filterTable = ufFilter(filterTable, uf)
    }

    if (!text && !status && !uf) {
      filterTable = undefined
    }

    setFilterData(filterTable)
  }

  useLayoutEffect(() => {
    mainSearch()
  }, [search])

  useLayoutEffect(() => {
    paginationPage(1)
  }, [data, filterData])

  useEffect(() => {
    setScreenHeight(document.documentElement.clientHeight)
    setScreenWidth(document.documentElement.clientWidth)

    if (window.matchMedia('(orientation: portrait)').matches) {
      setHeightSize('100%')
    } else if (window.matchMedia('(orientation: landscape)').matches) {
      const formHeight = document.getElementById('col-form-pricing-table')?.offsetHeight
      setHeightSize(Number(screenHeight) - 270 - Number(formHeight))
    }
  }, [screenHeight, screenWidth])

  const filtersElements = filters?.props?.children

  return (
    <Fragment>
      <Row className="mb-3" id="col-form-pricing-table">
        {canSearch && (
          <Col lg={6} md={12} sm={24} xs={24} className="pr-3 pr-md-0 mb-md-3">
            <Input.Search
              className="table-filter"
              placeholder='Pesquisar'
              onSearch={(text: string) => setSearch({ ...search, text })}
            />
          </Col>
        )}

        {status && (
          <Col lg={4} md={12} sm={24} xs={24} className="pr-3 pr-md-0 mb-md-3">
            <Select
              className="w-100"
              placeholder="Escolha o status"
              showSearch
              onChange={handleSelectStatus}
              filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              <Select.Option value="">Todos</Select.Option>
              <Select.Option value="Sem proposta">Sem proposta</Select.Option>
              <Select.Option value="Iniciando">Iniciando</Select.Option>
              <Select.Option value="Analisando">Analisando</Select.Option>
              <Select.Option value="Refazer">Refazer</Select.Option>
              <Select.Option value="Aprovado">Aprovado</Select.Option>
              <Select.Option value="Reprovado">Reprovado</Select.Option>
            </Select>
          </Col>
        )}

        {selectUf && (
          <Col lg={4} md={12} sm={24} xs={24} className="pr-3 pr-md-0 mb-md-3">
            <Select
              className="w-100"
              showSearch
              placeholder="Escolha a UF"
              onSelect={(uf: string) => setSearch({ ...search, uf })}
              filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              <Select.Option value=''>Todos</Select.Option>
              {states?.map((item: any) => (
                <Fragment key={item.value}>
                  <Select.Option value={item.value}>{item.label}</Select.Option>
                </Fragment>
              ))
              }
            </Select>
          </Col>
        )}

        {filtersElements?.length > 0 ? (
          <Fragment>
            {filtersElements.map((item: React.ReactElement) => (
              <Col lg={6} md={12} sm={24} xs={24} className="pr-3 pr-md-0 mb-md-3 flex flex-align-center" key={item.key}>
                {item}
              </Col>
            ))}
          </Fragment>
        ) : (
          <Col lg={6} md={12} sm={24} xs={24} className="pr-3 pr-md-0 mb-md-4 flex flex-align-center">
            {filters}
          </Col>
        )}
      </Row>

      <Row className={`server-table ${className}`}>
        <Col flex="auto">
          <Row className="server-table__scroll-style">
            <AntTable
              {...rest}
              style={{ overflow: 'auto', maxHeight: heightSize, minHeight: '100%' }}
              pagination={false}
              className="antd-base-table"
              dataSource={dataTable || []}
              columns={(columns as any)}
              loading={loading}
              rowKey={rowKey}
            />
          </Row>

          { showPagination && <Row>
            <Pagination
              responsive
              pageSize={limitItems}
              className="my-3 server-table__pagination"
              defaultCurrent={currentPage}
              current={currentPage}
              total={filterData ? filterData.length : data ? data.length : 0}
              onChange={(page) => paginationPage(page)}
              size={pagination?.size || 'default'}
            />

            {pagination?.showTagTotals !== false &&
              <Row align="middle" className="server-table__tag-totals ml-3">
                <label className="server-table__color-black">
                  Total: <strong>{loading ? <Spin size="small" className="server-table__limits-pagination" /> : limitsPagination()}</strong>
                </label>
              </Row>
            }
          </Row>}
        </Col>
      </Row>
    </Fragment>
  )
}

export default Table

import React, { useLayoutEffect, useState } from 'react'
import { Row, Col, Skeleton } from 'antd'
import { ReactSVG } from 'react-svg'
import { IFilterDate } from '../../views/MlmDashboard/types'
import MlmBaseCard from '../MlmBaseCard/MlmBaseCard'
import format from 'utils/format'
import DashboardEgiRepository, { IDepartmentEgiTotals } from 'egi/repositories/DashboardEgiRepository'

function MlmDashboardAreasProposals ({ filterDate }: IFilterDate) {
  const [loading, setLoading] = useState<boolean>(true)
  const [failed, setFailed] = useState<boolean>(false)
  const [departmentsCards, setDepartamentCards] = useState<IDepartmentEgiTotals[]>([])

  useLayoutEffect(() => {
    const abortController = new AbortController()
    const getDepartments = async () => {
      setLoading(true)
      try {
        const response = await DashboardEgiRepository.departments({
          signal: abortController.signal,
          params: filterDate
        })
        const { proposals } = response.data.data || {}
        if (!proposals) throw new Error('Falha ao encontrar valores das propostas')

        const sortedList = ['documentacao', 'credito', 'avaliacao', 'juridico']
        const sortedDepartments = sortedList
          .map(item => proposals.find(value => value._id === item) as IDepartmentEgiTotals)

        setDepartamentCards(sortedDepartments)
        setFailed(false)
        setLoading(false)
      } catch (err) {
        if (!abortController.signal.aborted) {
          setFailed(true)
          setLoading(false)
        }
      }
    }
    if (filterDate.createdDate && filterDate.productId) getDepartments()
    return () => abortController.abort()
  }, [filterDate.createdDate, filterDate.productId, filterDate.promoterId, filterDate.sellerId, filterDate.type])

  return (
    <Col xl={10} lg={24} md={24} sm={24} xs={24}>
      <Row gutter={[15, 15]} className="h-100">
        {loading ? (
          new Array(4).fill('').map((_, index) => (
            <Col xl={12} lg={12} md={24} sm={24} xs={24} key={index}>
              <div className="dashboard-status-card h-100">
                <Skeleton
                  active={true}
                  paragraph={{ rows: 1 }}
                />
              </div>
            </Col>
          ))
        ) : (
          departmentsCards && departmentsCards.length > 0 && departmentsCards.map(item => (
            <Col xl={12} lg={12} md={24} sm={24} xs={24} key={item?._id}>
              <div className="dashboard-status-card h-100">
                <MlmBaseCard
                  titleClass="dashboard-card-title-areas"
                  description={<label className="mb-1 display-block">{item?.name}</label>}
                  value={format.compressedNumber(item?.value)}
                  rawValue={item?.value}
                  failed={failed}
                  valueColor="primary"
                  Icon={
                    <div>
                      <label className="display-block text-center color-primary bold mb-1">{item?.count || '0'}</label>
                      <ReactSVG
                        beforeInjection={(svg) => {
                          svg.setAttribute('style', 'width: 45px')
                          svg.setAttribute('style', 'height: 45px')
                        }}
                        src={item?.image}
                        alt='Icone sobre cada passo do sistema uxline'
                      />
                    </div>
                  }
                />
              </div>
            </Col>
          ))
        )}
      </Row>
    </Col>
  )
}

export default MlmDashboardAreasProposals

import React from 'react'
import Intl from 'react-intl-currency-input'
import format from 'utils/format'

type Props = {
  classNameInput?: string
  classNameOnDiv?: string
  onChange?: (value: number) => void
  footer?: boolean
} & Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'onChange'>

function IntlInput ({ onChange, ...rest }: Props) {
  const currencyConfig = {
    locale: 'pt-BR',
    formats: {
      number: {
        BRL: {
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
      }
    }
  }

  function handleChange (event: React.ChangeEvent<HTMLInputElement>, normalizedValue: number) {
    event.preventDefault()
    if (onChange) onChange(normalizedValue)
  }

  return (
    <Intl
      {...rest}
      currency="BRL"
      config={currencyConfig}
      onChange={handleChange}
    />
  )
}

const InputMoney = ({ min, max, footer, classNameInput, classNameOnDiv, ...rest }: Props) => {
  return (
    <>
      <div className='intl-input-group'>
        <div className={`intl-input-group__suffix intl-input-group__preffix-money ${classNameOnDiv ?? ''} `}>R$</div>
        <IntlInput
          {...rest}
          min={min}
          max={max}
          className={`ant-input intl-input-group__money ${classNameInput ?? ''}`}
        />
      </div>

      {footer && <div className={`intl-input-group__footer ${!min && 'intl-input-group__footer--only-max'}`}>
        {!!min && <span>{format.formatBRL(Number(min))}</span>}
        {!!max && <span>{format.formatBRL(Number(max))}</span>}
      </div>}
    </>
  )
}

const InputPercentage = ({ ...rest }: Props) => {
  return (
    <div className='intl-input-group'>
      <IntlInput {...rest} max={100.00} className="ant-input intl-input-group__percentage"/>
      <div className='intl-input-group__suffix intl-input-group__suffix-percentage'>%</div>
    </div>
  )
}

export {
  InputMoney,
  InputPercentage
}
export default IntlInput

import React, { useState, useEffect } from 'react'
import { Button, Form, Modal, Divider } from 'antd'
import { PercentageInput } from 'components'
import swal from 'utils/swal'
import format from 'utils/format'
import { validateResponse } from 'utils/validate'
import ExampleCommissionTable from 'components/ExampleCommissionTable/ExampleCommissionTable'
import { useDispatch } from 'react-redux'
import { tablesUpdate } from 'store/modules/tables/actions'
import { IChangeCommissionProps } from 'egi/app/Promoter/promoterInterfaces'
import PromotersRepository from 'egi/repositories/PromotersRepository'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'

interface IValues {
  commissionPercentage: number
}

function ModalChangeCommission ({ promoterCommission, promoterId, promoterType, onClose, visible }: IChangeCommissionProps) {
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<{commissionPercentage?: string}>({})
  const [currentValue, setCurrentValue] = useState<string | number | null | undefined>()

  const dispatch = useDispatch()
  const [formRef] = Form.useForm()

  const onSubmit = async (values: IValues) => {
    if (values.commissionPercentage) values.commissionPercentage = format.percentageToDecimal(values.commissionPercentage)
    setLoading(true)
    setErrors({})

    try {
      if (!promoterId) throw new Error('Identificador do parceiro não encontrado!')
      const response = await PromotersRepository.changeCommission(promoterId, { commissionPercentage: values.commissionPercentage })
      swal.basic({ title: 'Sucesso!', text: response?.data.message, icon: 'success' })
      dispatch(tablesUpdate())
      setLoading(false)
    } catch (err) {
      if (err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
        setLoading(false)
        return
      }

      setLoading(false)
      setErrors({})
      swal.basic({ title: 'Atenção!', text: err.message, icon: 'warning' })
    }
  }

  const handleClose = () => {
    if (formRef) formRef.setFieldsValue({ commissionPercentage: undefined })
    onClose()
  }

  useEffect(() => {
    const currentCommission = format.decimalToPercentage(promoterCommission).toFixed(2)
    setCurrentValue(currentCommission)
    if (formRef) formRef.setFieldsValue({ commissionPercentage: currentCommission })
  }, [visible])

  return (
    <Modal
      title="Editar comissão"
      visible={visible}
      footer={null}
      onCancel={handleClose}
    >
      <Form
        onFinish={onSubmit}
        form={formRef}
        initialValues={{
          commissionPercentage: 0
        }}
      >
        <Form.Item
          name="commissionPercentage"
          label={<label>Digite a porcentagem da comissão do parceiro</label>}
          help={errors.commissionPercentage && (errors.commissionPercentage)}
          validateStatus={errors.commissionPercentage && ('error')}
        >
          <PercentageInput
            placeholder="Digite a comissão do parceiro"
            onChange={(value) => { setCurrentValue(format.onlyNumber(value)) }}
            max={100}
            typeNumber={true}
          />
        </Form.Item>

        <ExampleCommissionTable
          currentValue={currentValue}
          promoterType={String(promoterType)}
        />

        <Divider/>

        <div className="flex flex-justify-end">
          <Button type="ghost" className="mr-1" onClick={() => onClose()}>Cancelar</Button>
          <Button type="primary" htmlType="submit" loading={loading}>Enviar</Button>
        </div>
      </Form>
    </Modal>
  )
}

export default ModalChangeCommission

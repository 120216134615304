import { swalError } from 'components/SwalError/SwalError'
import { useFortity } from 'egi/app/FortifyPassword/hooks/useFortify'
import LoginRepositoryEgi, { IResetPasswordResponse } from 'egi/repositories/LoginRepositoryEgi'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { UNAUTHS_PATHS } from 'routes/unauthRoutes'
import { timestampToSeconds } from 'utils/date'
import format from 'utils/format'
import swal from 'utils/swal'

export const useResetPassword = () => {
  const history = useHistory()

  const [loading, setLoading] = useState<boolean>(false)
  const { redirectToFortifyOrEcpPath } = useFortity()

  const redirectToEgiResetPasswordSendEmail = (email:string, time: number) => {
    history.push({
      pathname: UNAUTHS_PATHS.FORGOT_SENT_EMAIL,
      state: {
        email: email,
        time: time
      }
    })
  }

  const redirectToOnidataTokenView = (email: string) => {
    history.push({
      pathname: UNAUTHS_PATHS.ECP_FORGOT_TOKEN_ONIDATA,
      state: {
        email: email
      },
      search: history.location.search
    })
  }

  const onSubmit = async (values: { email: string, cpf: string, withMFA?: boolean }) => {
    const formattedCpf = format.onlyDigits(values.cpf || '')
    const identifier = formattedCpf || values.email

    setLoading(true)
    try {
      const response = await LoginRepositoryEgi.reset({ identifier, withMFA: values?.withMFA })
      const { expiresIn, isVinculateWithOnidata, email } = (response.data.data || {}) as IResetPasswordResponse
      const time = timestampToSeconds(expiresIn || 0)

      swal.basic({ title: 'Sucesso!', text: response.data?.message, icon: 'success' })

      if (isVinculateWithOnidata && email) redirectToOnidataTokenView(email)
      if (!isVinculateWithOnidata && email && !values?.withMFA) redirectToEgiResetPasswordSendEmail(email, time)

      return response
    } catch (err) {
      redirectToFortifyOrEcpPath(err, identifier)
      swalError({ err })
      throw err
    } finally {
      setLoading(false)
    }
  }

  return {
    redirectToEgiResetPasswordSendEmail,
    loading,
    onSubmit
  }
}

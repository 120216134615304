import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import commomColumns, { columnBlockUser } from './commomDataTable'
import { useAuth } from 'hooks'
import NameDropdownController from 'components/NameDropdownController/NameDropdownController'
import IColumns from 'egi/types/IColumns'
import UserTableModel from 'egi/models/UserTableModels'
import { OverflowTooltip } from 'components/OverflowToolTip/OverflowTooltip'
import commonColumns, { commomNameOverflowWidth } from 'components/UserTable/utils/commonColumns'

const tempColumns = commomColumns.slice(1)

const finalColumn = (area?: string) : ColumnsType<Object> => ([
  {
    ...commonColumns.name,
    render: (name: string, row: { _id: string } | any) => {
      const user = useAuth()

      return (
        <OverflowTooltip width={commomNameOverflowWidth} title={name}>
          <NameDropdownController
            showUserDetails={UserTableModel.showUsersDetails(user.level)}
            dropDownLabel={name}
            userName={name}
            userId={row._id}
            tableLevel='Administrador'
            extraAction={{
              name: 'Time',
              url: `/auth/administrators/${row._id}/invited/${name}?areaName=${area}`,
              hasExtraAction: true,
              area
            }}
          />

          {row?.cpf ? row?.cpf : '-'}
        </OverflowTooltip>
      )
    }
  }]
)

const columns = ({ blockUser, area }: IColumns): ColumnsType<Object> => [
  ...finalColumn(area),
  ...tempColumns,
  columnBlockUser(blockUser)
]

export default columns

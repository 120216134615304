import React, { useEffect, useState } from 'react'
import { Form } from 'antd'
import { useDispatch } from 'react-redux'
import { useResources, useTables } from 'hooks'
import { resourcesGetAreas } from 'store/modules/resources/actions'
import { tablesSetFiltersValues } from 'store/modules/tables/actions'
import dayjs from 'dayjs'
import MultiDatepicker, { MultiDatepickerType } from 'components/MultiDatepicker/MultDatePicker'
import { formatDateByDateType, timeAsDayjs } from 'utils/time'
import { initialDate } from 'components/MultiDatepicker/constants'

function FilterCreatedProposal () {
  const dispatch = useDispatch()
  const resources = useResources()
  const tables = useTables()

  const [filterDateType, setFilterDateType] = useState<MultiDatepickerType>('month')

  useEffect(() => {
    if (resources.areas.length < 1) dispatch(resourcesGetAreas())
    if (tables.filters.createdDateAfter && tables.filters.createdDateBefore) {
      setFilterDateType('range')
    }
  }, [])

  const value = () => {
    if (!tables.filters.createdDate) return undefined

    const [month, year] = tables.filters.createdDate.split('-')
    const newDate = dayjs(`${year}-${month}`)

    return newDate
  }

  return (
    <Form.Item
      label={<label>Data criação</label>}
    >
      <MultiDatepicker
        type={filterDateType}
        options={['month', 'range']}
        onDateTypeChange={dateType => setFilterDateType(dateType)}
        value={value}
        disabled={tables.ignoreAll}
        rangeValue={
          tables.filters.createdDateBefore && tables.filters.createdDateAfter && !tables.filters.createdDate
            ? [timeAsDayjs(tables.filters.createdDateAfter), timeAsDayjs(tables.filters.createdDateBefore)] : null
        }
        onRangeChange={(range) => {
          dispatch(tablesSetFiltersValues({
            ...tables.filters,
            createdDateBefore: range?.[1] ? timeAsDayjs(range[1], { applyTimezone: false }).format('YYYY-MM-DD') : initialDate,
            createdDateAfter: range?.[0] ? timeAsDayjs(range[0], { applyTimezone: false }).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
            createdDate: undefined,
            page: 1
          }))
        }}
        onDateChange={(value: dayjs.Dayjs | null) => {
          if (!value) {
            return
          }
          dispatch(tablesSetFiltersValues({
            ...tables.filters,
            createdDate: formatDateByDateType(value, filterDateType),
            createdDateAfter: undefined,
            createdDateBefore: undefined,
            page: 1
          }))
        }}
      />
    </Form.Item>
  )
}

export default FilterCreatedProposal

import React from 'react'
import { Form } from 'antd'

import DrawerFiltersLineCollapsable, { DrawerFiltersLineCollapsableItem } from 'components/DrawerFilters/DrawerFiltersLineCollapsable'
import { DatepickerCustom } from 'components'
import locale from 'antd/lib/date-picker/locale/en_US'
import dayjs, { Dayjs } from 'dayjs'
import { useTables } from 'hooks'

function UpdatedAtFilter () {
  const tables = useTables()

  const disabledDateOf = (date: Dayjs, value: string) => {
    return date.isAfter(dayjs()) || date.isAfter(dayjs(value).endOf('d'))
  }

  const disabledDateTo = (date: Dayjs, value: string) => {
    if (!value) return date.isAfter(dayjs())
    return date.isAfter(dayjs()) || date.isBefore(dayjs(value))
  }

  return (
    <DrawerFiltersLineCollapsable label='Data atualização'>
      <DrawerFiltersLineCollapsableItem>
        <Form.Item
          label="De"
          id='updatedDateAfter'
          name='updatedDateAfter'
        >
          <DatepickerCustom
            locale={locale}
            format='DD/MM/YYYY'
            disabledDate={date => disabledDateOf(date, tables.filters.updatedDateBefore)}
          />
        </Form.Item>
      </DrawerFiltersLineCollapsableItem>

      <DrawerFiltersLineCollapsableItem>
        <Form.Item
          label="Até"
          id='updatedDateBefore'
          name='updatedDateBefore'
        >
          <DatepickerCustom
            locale={locale}
            format='DD/MM/YYYY'
            disabledDate={date => disabledDateTo(date, tables.filters.updatedDateAfter)}
          />
        </Form.Item>
      </DrawerFiltersLineCollapsableItem>
    </DrawerFiltersLineCollapsable>
  )
}

export { UpdatedAtFilter }

import { WarningFilled } from '@ant-design/icons'
import { Button, message, Space, Tag } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import { DatepickerCustom } from 'components'
import { downloadCSVFile } from 'components/SeverTable/serverTableFunctions'
import { ISearch } from 'components/SeverTable/types'
import dayjs, { Dayjs } from 'dayjs'
import { _proposalStatuses } from 'egi/app/ProposalEgi/proposalEgiInterfaces'
import { useTables } from 'hooks'
import React, { ReactNode, useMemo, useState } from 'react'
import api from 'services/api'
import { monthCommaYear } from 'utils/date'
import translate from 'utils/translate'

type Props = {
  hasProposalStatus: boolean
  visible: boolean
  url: string
  onVisibleChange: (show: boolean) => void
}

const reverseDate = (date: string) => date ? date.split('-').reverse().join('-') : ''

export const proposalStatusesToArray = (proposalStatus: string): _proposalStatuses[] => {
  if (!proposalStatus) return []
  return String(proposalStatus).split(',') as _proposalStatuses[]
}

export const hasSlowStatusIncludedInReport = (status: _proposalStatuses): boolean => {
  return ['canceled', 'inactive', 'reproved'].includes(status)
}

export const findProposalReport = (statuses: _proposalStatuses[]): boolean => !!statuses.find(hasSlowStatusIncludedInReport)

const ProposalsReportWarning = ({ description }: { description: string | ReactNode }) => {
  return (
    <div className='proposal-report-modal__warning'>
      <div className='proposal-report-modal__icon-content'>
        <WarningFilled style={{ color: 'var(--remaking-status-color)' }} />
      </div>

      <div className='proposal-report-modal__text-content'>
        <h2 className='proposal-report-modal__subtitle'>Atenção</h2>
        <p className='proposal-report-modal__text'>{description}</p>
      </div>
    </div>
  )
}

export default function ProposalEgiReportModal ({ hasProposalStatus, visible, onVisibleChange, url }: Props) {
  const tables = useTables()

  const [reportDate, setReportDate] = useState<Dayjs>(
    tables.filters.createdDate && dayjs(reverseDate(tables.filters.createdDate)).isValid() ? dayjs(reverseDate(tables.filters.createdDate)) : dayjs()
  )
  const [loading, setLoading] = useState(false)

  const onGenerateProposalReport = async (url: string, params: ISearch, options?: { csvFilename?: string }): Promise<void> => {
    const finalParams = { ...params } as ISearch
    const { csvFilename } = options || {}

    if (params.proposalStatus && !hasProposalStatus) delete finalParams.proposalStatus

    setLoading(true)
    try {
      const response = await api.get(url, {
        params: finalParams,
        timeout: 600000
      })

      const data = response.data.data
      const csv = data.csv

      if (csv) {
        downloadCSVFile(csv, csvFilename)
        onVisibleChange(false)
      }
    } catch (err) {
      if (err.message) message.error(err.message || 'Falha ao gerar relatório')
    } finally {
      setLoading(false)
    }
  }

  const proposalStatuses: _proposalStatuses[] = useMemo(() => {
    return proposalStatusesToArray(String(tables.filters.proposalStatus))
  }, [
    tables.filters.proposalStatus
  ])

  return (
    <Modal
      centered
      footer={null}
      destroyOnClose
      onCancel={() => onVisibleChange(false)}
      visible={visible}
      className='proposal-report-modal'
    >
      <h1 className='proposal-report-modal__title'>Por favor, selecione o mês referente ao seu relatório</h1>

      <ProposalsReportWarning
        description={
          <>Quando nenhum status é adicionado no filtro <b>status da propostas</b>, o sistema trata como se todos os status estivessem selecionados</>
        }
      />

      <div className='proposal-report-modal__tip-body'>
        <p className='proposal-report-modal__text'>Você selecionou no filtro <b>status da propostas</b> os seguintes <b>status</b>:</p>
        <p className='mb-4'>
          {!proposalStatuses.length ? <>
            <Tag><b>Todos</b> os status estão selecionados</Tag>
          </> : (
            <>
              {proposalStatuses.map((status, idx) => (
                <Tag key={idx} color={hasSlowStatusIncludedInReport(status) ? 'var(--refused-status-color)' : ''}>
                  {translate.proposalStatus(status as _proposalStatuses)}
                </Tag>
              ))}
            </>
          )}
        </p>

        <p className='proposal-report-modal__text'>Filtro mensal deve ser aplicado para relatórios com os status de proposta abaixo:</p>
        <ul className='proposal-report-modal__ul-list'>
          <li>Todos</li>
          <li>Reprovado</li>
          <li>Inativo</li>
          <li>Cancelado</li>
        </ul>
      </div>

      <div className='proposal-report-modal__month-filter'>
        <span>Filtro mensal: </span>
        <DatepickerCustom.MonthPicker
          value={reportDate}
          disabledDate={(current) => current.isAfter(new Date())}
          allowClear={false}
          onChange={(date: Dayjs | null) => date && setReportDate(date)}
          format={monthCommaYear}
        />
      </div>

      <ProposalsReportWarning
        description={
          <>As propostas do relatório serão referentes ao período de <b>{reportDate.format('MMMM, YYYY')}</b></>
        }
      />

      <div className='proposal-report-modal__button-content'>
        <Space>
          <Button
            type='ghost'
            onClick={() => onVisibleChange(false)}
          >
            Cancelar
          </Button>

          <Button
            loading={loading}
            type='primary'
            onClick={() => {
              onGenerateProposalReport(url, {
                ...tables.filters,
                download: true,
                createdDate: reportDate.format('MM-YYYY')
              })
            }}
          >
            Gerar relatório
          </Button>
        </Space>
      </div>
    </Modal>
  )
}

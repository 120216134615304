import React, { useState, useLayoutEffect, Fragment, ReactNode } from 'react'
import format from 'utils/format'
import { Form, Col, Row, Tooltip, Empty, Skeleton } from 'antd'
import { InputMoney } from 'components'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'
import { useAuth, useSelectedProduct } from 'hooks'

import ConfigurationsRepository from 'egi/repositories/ConfigurationsRepository'
import { IOperationalLimitValues, ISendOperationalLimitErrors } from '../operationalLimitInterfaces'
import DetailsItem from 'ecp/components/DetailsItem/DetailsItem'
import mask from 'utils/masks'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'
import SettingsCard from 'egi/app/Settings/components/SettignsCard/SettingsCard'

type LoadingEmptyProps = {
  loading: boolean
  failRequest: boolean
  children: ReactNode
}

function LoadingOrEmpty ({ loading, failRequest, children }: LoadingEmptyProps) {
  if (loading) {
    return (
      <>
        {new Array(2).fill('').map((_, idx) => (
          <Row gutter={[15, 15]} key={idx}>
            <Col lg={12}>
              <Skeleton.Button active className="operation-limit__skeleton"/>
            </Col>
            <Col lg={12}>
              <Skeleton.Button active className="operation-limit__skeleton"/>
            </Col>
          </Row>
        ))}
      </>
    )
  }

  if (failRequest) return <Empty description="Falha ao buscar dados, tente novamente mais tarde" className='card-style p-3' />
  return <>{children}</>
}

function OperationalUserLabel ({ title, maxValue }:{title: string, maxValue?: number }) {
  const maxValueAvailable = maxValue ? format.compressedMoney(maxValue) : 'Ilimitado'
  const tooltipValue = maxValue ? format.formatBRL(maxValue) : undefined

  return (
    <div className="flex flex-column">
      <label className="operation-limit__value-title">{title}</label>

      <Tooltip title={tooltipValue} placement="right" destroyTooltipOnHide={{ keepParent: false }}>
        <label className="operation-limit__max-value-label">
          {maxValueAvailable}
        </label>
      </Tooltip>
    </div>
  )
}

interface IOperationalFormItemProps {
  name: string,
  label: string,
  value: number,
  maxValue?: number,
  errors: ISendOperationalLimitErrors,
  readonly: boolean
}

function OperationalUserFormItem ({ name, label, value, maxValue, errors, readonly }: IOperationalFormItemProps) {
  const tooltip = maxValue ? format.formatBRL(maxValue) : 'Ilimitado'

  if (readonly) {
    return <DetailsItem
      label={label}
      tooltip={`Valor máximo: ${tooltip}`}
      value={<span>A partir de {mask(String(value ?? 0), 'money')}</span>}
    />
  }

  return (
    <Form.Item
      name={name}
      label={<OperationalUserLabel title={label} maxValue={maxValue} />}
      help={errors && errors[name]}
      validateStatus={errors && errors[name] && 'error'}
      className='mb-0'
    >
      <InputMoney
        readOnly={readonly}
        placeholder="Digite o limite"
        defaultValue={0}
      />
    </Form.Item>
  )
}

const initialValues = {
  analistValue: 0,
  managerValue: 0,
  supervisorValue: 0,
  directorValue: 0
}

function OperationalLimit ({ canUpdate = true, className = '' }: { canUpdate?: boolean, className?: string }) {
  const { _id: productId } = useSelectedProduct()
  const user = useAuth()
  const [formRef] = Form.useForm()

  const [errors, setErrors] = useState<ISendOperationalLimitErrors>({})
  const [failRequest, setFailRequest] = useState<boolean>(false)
  const [loading, setLoading] = useState({ list: true, button: false })
  const [operationalLimit, setOperationalLimit] = useState<IOperationalLimitValues>(initialValues)

  const readonly = user.level === 'auditor' || !canUpdate

  const formItems = [
    { name: 'analistValue', label: 'Analista', value: operationalLimit?.analistValue, maxValue: (operationalLimit.managerValue - 1) },
    { name: 'managerValue', label: 'Gerente', value: operationalLimit?.managerValue, maxValue: (operationalLimit.supervisorValue - 1) },
    { name: 'supervisorValue', label: 'Superintendente', value: operationalLimit?.supervisorValue, maxValue: (operationalLimit.directorValue - 1) },
    { name: 'directorValue', label: 'Diretor', value: operationalLimit?.directorValue }
  ]

  useLayoutEffect(() => {
    const getLimitValues = async () => {
      setLoading({
        ...loading,
        list: true
      })

      try {
        const response = await ConfigurationsRepository.getCreditOperationalLimit(productId)
        const operationLimit = response.data.data

        if (!operationLimit) throw new Error('Não foi possível obter os dados do limite operacional.')

        setOperationalLimit(operationLimit)
        formRef.setFieldsValue({ ...operationLimit })
      } catch (err) {
        setFailRequest(true)
      } finally {
        setLoading({ ...loading, list: false })
      }
    }

    if (productId) getLimitValues()
  }, [productId])

  const onSubmit = async (values: IOperationalLimitValues) => {
    if (values.analistValue) values.analistValue = format.formatMoneySend(String(values.analistValue))
    if (values.supervisorValue) values.supervisorValue = format.formatMoneySend(String(values.supervisorValue))
    if (values.managerValue) values.managerValue = format.formatMoneySend(String(values.managerValue))
    if (values.directorValue) values.directorValue = format.formatMoneySend(String(values.directorValue))

    setLoading({ ...loading, button: true })

    try {
      const response = await ConfigurationsRepository.updateCreditOperationalLimit(productId, values)
      swal.basic({ title: 'Sucesso!', text: response.data.message, icon: 'success' })
    } catch (err) {
      if (err.data?.invalid) {
        let errors: ISendOperationalLimitErrors = {}
        errors = validateResponse(err.data?.invalid)

        setErrors(errors)

        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
        return
      }

      setErrors({})
      swal.basic({ title: 'Atenção!', text: err.message, icon: 'warning' })
    } finally {
      setLoading({ ...loading, button: false })
    }
  }

  return (
    <>
      <div className={`${className}`}>
        <Fragment>
          <LoadingOrEmpty
            loading={loading.list}
            failRequest={failRequest}
          >
            <Form
              form={formRef}
              layout="vertical"
              onFinish={onSubmit}
              initialValues={initialValues}
              className='w-100'
            >
              <Row gutter={[30, 15]} className='w-100 mb-4'>
                {formItems.map((item) => (
                  <Col lg={12} md={24} sm={24} xs={24} key={item.name}>
                    <OperationalUserFormItem
                      name={item.name}
                      label={item.label}
                      value={item.value}
                      maxValue={item.maxValue}
                      errors={errors}
                      readonly={readonly}
                    />
                  </Col>
                ))}

              </Row>

              {!readonly && (
                <SettingsCard.ButtonsContainer>
                  <SettingsCard.submitButton loading={loading.button}>
                        Salvar
                  </SettingsCard.submitButton>
                </SettingsCard.ButtonsContainer>
              )}
            </Form>
          </LoadingOrEmpty>
        </Fragment>
      </div>

    </>
  )
}

export default OperationalLimit

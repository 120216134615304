import { ArrowLeftOutlined } from '@ant-design/icons'
import React from 'react'

interface IFindUserHeaderEcpf {
  title: string
  description?: string
  icon?: React.ReactNode
  onBack?: () => void
}

function FindUserHeaderECPF ({ title, description, onBack, icon }: IFindUserHeaderEcpf) {
  return (
    <header className='text-center'>
      <h2 className='find-user__title'>
        {onBack && (
          <ArrowLeftOutlined
            className='mr-6'
            onClick={onBack}
            style={{ fontSize: 16 }}
          />
        )}
        {title}
      </h2>

      {description && <p className='find-user__description'>{description}</p>}

      {icon && <div className='find-user__image'>{icon}</div>}
    </header>
  )
}

export default FindUserHeaderECPF

import React, { Fragment, MutableRefObject, useRef, useState, useEffect } from 'react'
import { Col, Typography, Form, Row, Button, Checkbox, Select } from 'antd'
import { Address, Input } from 'components'
import locale from 'antd/es/date-picker/locale/pt_BR'
import { IOnidataContractErrors } from '../contract'
import { conjugeFieldsString, valuesFormatToDate, formContractFields } from '../contractFields'
import ChooseContractComponent from '../ChooseContractComponent'
import MinutaMoldSelect from '../MinutaMoldSelect'
import { useProposal, useResources, useStep } from 'hooks'
import { ArrowRightOutlined } from '@ant-design/icons'
import time, { correctTimezone, timeAsDayjs } from 'utils/time'
import format from 'utils/format'
import { proposals } from 'egi/consumers'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'
import { useDispatch } from 'react-redux'
import { stepForm } from 'store/modules/step/actions'
import Datepicker from 'components/Datepicker/Datepicker'
import { swalError } from 'components/SwalError/SwalError'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'

interface ITitularStepValue {
  [key: string]: any
  titular_incluir_conjuge: boolean
  titular_data_de_nascimento: Date
  titular_nome: string
  titular_email: string
  titular_nacionalidade: string
  titular_filiacao: string
  titular_profissao: string
  titular_estado_civil: string
  titular_identidade: string
  titular_CPF: string
  titular_CEP: string
  titular_pais: string
  titular_logradouro: string
  titular_numero_residencial: string
  titular_complemento_residencial: string
  titular_bairro: string
  titular_UF: string
  titular_municipio: string
  titular_qualificacao: string
  titular_qualificacao_slug: string

  titular_razao_social: string
  titular_CNPJ: string
  titular_data_de_fundacao: string
  titular_natureza_juridica: string
  titular_atividade_economica: string

  titular_nome_conjuge?: string
  titular_email_conjuge?: string
  titular_data_de_nascimento_conjuge?: Date
  titular_nacionalidade_conjuge?: string
  titular_filiacao_conjuge?: string
  titular_filiacao_pai_conjuge?: string
  titular_profissao_conjuge?: string
  titular_identidade_conjuge?: string
  titular_CPF_conjuge?: string
}

function TitularStep ({ readOnly, onSuccess }: { readOnly?: boolean, onSuccess: (value: boolean) => void }) {
  const [errors, setErrors] = useState<IOnidataContractErrors>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [hasConjuge, setHasConjuge] = useState<boolean>(false)
  const [minutaMoldText, setMinutaMoldText] = useState<string | undefined>(undefined)

  const resources = useResources()
  const step = useStep()
  const dispatch = useDispatch()
  const proposal = useProposal()

  const isPj = proposal.isPj

  const [formRef] = Form.useForm()
  const ref: MutableRefObject<{ minutaText: string }> = useRef({
    minutaText: ''
  })

  function onError (err: any) {
    swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    setLoading(false)
  }

  const onSubmit = async (values: ITitularStepValue) => {
    setLoading(true)
    setErrors({})
    try {
      if (!proposal.id) throw new Error('Falha ao encontrar id da proposta')
      if (!step.form) throw new Error('Falha ao encontrar form')
      if (!step.id) throw new Error('Falha ao encontrar step id')
      if (!step.areaId) throw new Error('Falha ao encontrar area Id')
      if (!ref.current.minutaText) throw new Error('Texto de qualificação do titular não informado!')

      valuesFormatToDate.forEach(item => {
        if (values[item]) values[item] = time(correctTimezone(values[item]))
      })

      if (ref.current.minutaText) values.titular_qualificacao = ref.current.minutaText
      if (values.titular_CPF) values.titular_CPF = format.onlyDigits(values.titular_CPF)
      if (values.titular_CNPJ) values.titular_CNPJ = format.onlyDigits(values.titular_CNPJ)

      const data: any = {
        form: { ...step.form, ...values, finished: false, currentStep: 2 },
        proposalId: proposal.id,
        stepId: step.id,
        areaId: step.areaId
      }

      const response = await proposals.send(data)
      dispatch(stepForm({ data: values }))
      onSuccess(true)
      swal.basic({ title: 'Sucesso!', text: response.message, icon: 'success' })
      setLoading(false)
    } catch (err) {
      if ([
        'Falha ao encontrar id da proposta',
        'Falha ao encontrar form',
        'Falha ao encontrar step id',
        'Falha ao encontrar area Id',
        'Texto de qualificação do titular não informado!'
      ].includes(err.message)) return onError(err)

      if (err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        if (invalidFields?.length > 0) {
          invalidFieldsError({ invalidFields: invalidFields })
        } else {
          swalError({ title: 'Atenção', icon: 'warning', err })
        }
      } else {
        swalError({ title: 'Atenção', icon: 'warning', err })
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    async function onFill () {
      try {
        const tempForm = JSON.parse(JSON.stringify(step.form))

        valuesFormatToDate.forEach(item => {
          if (tempForm[item]) tempForm[item] = timeAsDayjs(tempForm[item], { applyTimezone: false }).format('DD/MM/YYYY')
        })

        if (tempForm.titular_incluir_conjuge === true) setHasConjuge(true)
        if (tempForm.titular_qualificacao) {
          setMinutaMoldText(tempForm.titular_qualificacao)
          ref.current.minutaText = tempForm.titular_qualificacao
        }

        if (formRef) formRef.setFieldsValue({ ...tempForm })
      } catch (err) {
        swal.basic({ title: 'Atenção!', text: err.message, icon: 'warning' })
      }
    }

    onFill()
  }, [])

  return (
    <Fragment>
      <Col span={24} className="mt-5">
        <Typography.Title
          className="form-section-title form-section-title--primary"
          level={4}
        >
          Titular
        </Typography.Title>
      </Col>

      <Form
        form={formRef}
        layout='vertical'
        onFinish={onSubmit}
        className="form-contract"
      >
        <Row gutter={[15, 15]}>
          {isPj
            ? <>
              <Col lg={8} md={24} sm={24} xs={24}>
                <Form.Item
                  name='titular_data_de_fundacao'
                  label={<label>Data de Fundação</label>}
                  help={errors.titular_data_de_fundacao && (errors.titular_data_de_fundacao)}
                  validateStatus={errors.titular_data_de_fundacao && ('error')}
                >
                  <Datepicker disabled={readOnly} locale={locale} />
                </Form.Item>
              </Col>

              <Col lg={8} md={24} sm={24} xs={24}>
                <Form.Item
                  name='titular_data_da_ultima_alteracao'
                  label={<label>Data da última alteração</label>}
                  help={errors.titular_data_da_ultima_alteracao && (errors.titular_data_da_ultima_alteracao)}
                  validateStatus={errors.titular_data_da_ultima_alteracao && ('error')}
                >
                  <Datepicker disabled={readOnly} locale={locale} />
                </Form.Item>
              </Col>
              <Col lg={8} md={24} sm={24} xs={24}>
                <Form.Item
                  name='titular_natureza_juridica'
                  label={<label>Natureza Jurídica:</label>}
                  help={errors.titular_natureza_juridica && (errors.titular_natureza_juridica)}
                  validateStatus={errors.titular_natureza_juridica && ('error')}
                >
                  <Select
                    placeholder="Selecione a Natureza Jurídica"
                    aria-readonly={readOnly}
                    disabled={readOnly}
                  >
                    {resources.legalNature.map((item) => {
                      return (
                        <Select.Option
                          id={`teste-form-${item?.toLowerCase().replace(/[ /()]+/g, '')}`}
                          key={item}
                          value={item}>
                          {item}
                        </Select.Option>)
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col lg={8} md={24} sm={24} xs={24}>
                <Form.Item
                  name='titular_atividade_economica'
                  label={<label>Atividade econômica:</label>}
                  help={errors.titular_atividade_economica && (errors.titular_atividade_economica)}
                  validateStatus={errors.titular_atividade_economica && ('error')}
                >
                  <Select
                    placeholder="Selecione a Atividade econômica"
                    aria-readonly={readOnly}
                    disabled={readOnly}
                  >
                    {resources.economicActivity.map((item) => {
                      return (
                        <Select.Option
                          id={`teste-form-${item?.toLowerCase().replace(/ ()/g, '-').replace(' ', '-')}`}
                          key={item}
                          value={item}>
                          {item}
                        </Select.Option>)
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </>
            : <Col lg={8} md={24} sm={24} xs={24}>
              <Form.Item
                name='titular_data_de_nascimento'
                label={<label>Data de Nascimento</label>}
                help={errors.titular_data_de_nascimento && (errors.titular_data_de_nascimento)}
                validateStatus={errors.titular_data_de_nascimento && ('error')}
              >
                <Datepicker disabled={readOnly} locale={locale} />
              </Form.Item>
            </Col>}

          {formContractFields('titular_', isPj).map(item => (
            <Col lg={8} md={24} sm={24} xs={24} key={item.id}>
              <ChooseContractComponent
                errors={errors}
                item={item}
                type={item.type || ''}
                readOnlyInput={readOnly}
                component={(
                  <Form.Item
                    name={item.field}
                    label={<label>{item.label}</label>}
                    help={errors[item.field] && (errors[item.field])}
                    validateStatus={errors[item.field] && ('error')}
                  >
                    <Input
                      id={`test-form-item-${item.field}`}
                      readOnly={readOnly}
                      placeholder={'Digite o(a) ' + item.label}
                      type="text"
                      error={errors[item.field]}
                      mask={item.mask || ''}
                    />
                  </Form.Item>
                )}
              />
            </Col>
          ))}

          {!isPj &&
            <>
              <Col lg={8} md={24} sm={24} xs={24}>
                <Form.Item
                  name='titular_data_do_casamento'
                  label={<label>Data de casamento</label>}
                  help={errors.titular_data_casamento && errors.titular_data_casamento}
                  validateStatus={errors.titular_data_casamento && ('error')}
                >
                  <Datepicker disabled={readOnly} locale={locale} />
                </Form.Item>
              </Col>

              <Col lg={8} md={24} sm={24} xs={24}>
                <Form.Item
                  name='titular_incluir_conjuge'
                  valuePropName="checked"
                  label={<label></label>}
                  help={errors.titular_incluir_conjuge && errors.titular_incluir_conjuge}
                  validateStatus={errors.titular_incluir_conjuge && ('error')}
                >
                  <Checkbox
                    disabled={readOnly}
                    onChange={value => setHasConjuge(value.target.checked)}
                    id="test-form-item-hasCompositor"
                  >
                    Incluir cônjuge ?
                  </Checkbox>
                </Form.Item>
              </Col>
            </>
          }
          <Row align="bottom" className="w-100" gutter={[15, 15]}>
            <Col lg={24} md={24} sm={24} xs={24} className="pr-0">
              <MinutaMoldSelect
                selectSlug={{ name: 'titular_qualificacao_slug', label: 'Qualificação do Emitente' }}
                text={minutaMoldText}
                placeholder="Selecione a qualificação"
                readOnlySelect={readOnly}
                qualification={resources.minutaMold}
                type="qualificacao"
                setText={(value) => { ref.current.minutaText = value }}
                errors={errors.titular_qualificacao_slug}
                formRef={formRef}
              />
            </Col>
          </Row>

          {hasConjuge && (
            <Col span={24}>
              <Typography.Paragraph className="bold">
                CÔNJUGE
              </Typography.Paragraph>

              <Row gutter={[15, 15]}>
                <Col lg={8} md={24} sm={24} xs={24}>
                  <Form.Item
                    name='titular_data_de_nascimento_conjuge'
                    label={<label>Data nascimento do cônjuge</label>}
                    help={errors.titular_data_de_nascimento_conjuge && (errors.titular_data_de_nascimento_conjuge)}
                    validateStatus={errors.titular_data_de_nascimento_conjuge && ('error')}
                  >
                    <Datepicker disabled={readOnly} locale={locale} />
                  </Form.Item>
                </Col>

                {conjugeFieldsString('titular_').map(item => (
                  <Col lg={8} md={24} sm={24} xs={24} key={item.id}>
                    <ChooseContractComponent
                      errors={errors}
                      item={item}
                      type={item.type || ''}
                      readOnlyInput={readOnly}
                      component={(
                        <Form.Item
                          name={item.field}
                          label={<label>{item.label}</label>}
                          help={errors[item.field] && (errors[item.field])}
                          validateStatus={errors[item.field] && ('error')}
                        >
                          <Input
                            id={`test-form-item-${item.field}`}
                            readOnly={readOnly}
                            placeholder={'Digite o(a) ' + item.label + ' do cônjuge'}
                            type="text"
                            error={errors[item.field]}
                            mask={item.mask || ''}
                          />
                        </Form.Item>
                      )}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          )}

          <Col span={24}>
            <Address
              title="ENDEREÇO"
              name='titular_'
              onCepBlur={(address, remainingAddress) => formRef.setFieldsValue({ titular_Address: { ...address, ...remainingAddress } })}
              readOnlyInput={readOnly}
              formRef={formRef}
              errors={errors}
            />
          </Col>
        </Row>

        <Row justify="end" align="middle">
          <Col>
            <Button
              loading={loading}
              className="mt-4 text-center w-100 color-white uppercase"
              type="primary"
              htmlType="submit"
              disabled={readOnly}
            >
              Avançar
              <ArrowRightOutlined />
            </Button>
          </Col>
        </Row>
      </Form>
    </Fragment>
  )
}

export default TitularStep

import { put, all, takeLatest } from 'redux-saga/effects'

function * simulationValues (action: any): any {
  const data = yield action.payload.data
  const step = yield action.payload.step

  yield put({
    type: 'SIMULATION_FILL_VALUES_SUCCEED',
    payload: {
      ...data
    }
  })

  if (step) {
    yield put({
      type: 'SIMULATION_STEPS_VALUES',
      payload: step
    })
  }
}

function * simulationLastStepValues (action: any): any {
  const data = yield action.payload.data
  const step = yield action.payload.step

  yield put({
    type: 'SIMULATION_LAST_STEP_SUCCEED',
    payload: {
      ...data
    }
  })

  if (step) {
    yield put({
      type: 'SIMULATION_STEPS_VALUES',
      payload: step
    })
  }
}

export default all([
  takeLatest('SIMULATION_FILL_VALUES_REQUESTED', simulationValues),
  takeLatest('SIMULATION_LAST_STEP_REQUESTED', simulationLastStepValues)
])

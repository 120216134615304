import { Progress } from 'antd'
import { ProgressProps } from 'antd/lib/progress'
import React from 'react'
import { _antdStatus } from 'store/modules/notaryFees/notaryFeesActionTypes'
import { getBackgroundColor } from './LoadingBarFunctions'

export interface ILoadingProps extends ProgressProps {
  percent: number
  status: _antdStatus
}

function LoadingBar ({ percent = 0, status, ...rest }: ILoadingProps) {
  return (
    <div className="loading-bar">
      <h2><b>{percent.toFixed(0)}%</b></h2>
      <Progress
        size='small'
        percent={percent}
        className="mb-2"
        strokeColor={getBackgroundColor(status).background}
        {...rest}
      />
    </div>
  )
}

export default LoadingBar

import { DeleteOutlined, PaperClipOutlined, UploadOutlined } from '@ant-design/icons'
import React, { Suspense, useState } from 'react'
import { Button, message, Modal, Spin, Tooltip, Upload } from 'antd'
import { UploadProps } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import { getBase64 } from 'utils/download'
import swal from 'utils/swal'
import { PdfPreview } from 'components'

interface IPreviewModalInterfaces {
  file: UploadFile
  visible: boolean
  preview: string
  onCancel: Function
}

function PreviewModal ({ file, visible, preview, onCancel }: IPreviewModalInterfaces) {
  return (
    <Modal
      className="modal-config"
      visible={visible}
      title={file?.name}
      footer={null}
      width='60vw'
      onCancel={() => onCancel()}
      centered
    >
      <>
        {file?.type === 'application/pdf' ? (
          <Suspense fallback={<Spin />}>
            <PdfPreview src={preview} />
          </Suspense>
        ) : (
          <img
            className="upload-img-size"
            alt='Documento inserido na plataforma'
            src={preview}
          />
        )}
      </>
    </Modal>
  )
}

export const ClientUploadContracheque = ({ onRemoveFile, onUploadFile, file }: {
  onRemoveFile: () => void,
  onUploadFile: (file: UploadFile) => void,
  file?: UploadFile
}) => {
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [preview, setPreview] = useState<string>()

  const onCancel = () => {
    setVisible(false)
  }

  const props: UploadProps = {
    name: 'file',
    maxCount: 1,
    showUploadList: false,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange: async (info) => {
      if (info.file.status === 'uploading') {
        setLoadingUpload(true)
      }
      if ((info.file.status === 'done' || info.file.status === 'error') && info.file.originFileObj) {
        setLoadingUpload(false)
        message.success(`${info.file.name} arquivo enviado com sucesso`)
        onUploadFile(info.file)
        const previewFile = await getBase64(info.file.originFileObj)
        setPreview(previewFile)
      }
    },
    onRemove () {
      onRemoveFile()
    },
    beforeUpload (file) {
      const isPdfOrPng = file.type === 'application/pdf' || file.type === 'image/png'
      if (!isPdfOrPng) {
        swal.basic({
          title: 'Atenção',
          text: 'Escolha uma imagem de extensão PNG ou um arquivo PDF',
          icon: 'warning'
        })
      }

      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        swal.basic({
          title: 'Atenção',
          text: 'Tamanho máximo do arquivo 2mb',
          icon: 'warning'
        })
      }

      return isPdfOrPng && isLt2M
    }
  }

  return (
    <>
      {(file && preview) && (
        <PreviewModal
          visible={visible}
          onCancel={onCancel}
          file={file}
          preview={preview}
        />
      )}

      <Upload {...props} >
        <Button
          icon={<UploadOutlined />}
          loading={loadingUpload}
          type='primary'
        >
          Anexar contracheque
        </Button>
      </Upload>

      {file && (
        <div className="mt-2">
          <b>Arquivo selecionado:</b>
          <div className="lgpd-access__upload-list-item">
            <Tooltip title="Clique para visualizar" placement="right">
              <span onClick={() => setVisible(true)}
                className="lgpd-access__upload-list-preview">
                <PaperClipOutlined className="pr-1" />
                {file?.name}
              </span>
            </Tooltip>

            <DeleteOutlined
              className="lgpd-access__upload-list-preview"
              onClick={onRemoveFile}
            />
          </div>
        </div>
      )}
    </>
  )
}

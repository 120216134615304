import { Tooltip } from 'antd'
import LetterAvatar from 'ecp/components/LetterAvatar/LetterAvatar'
import ClientModel from 'ecp/models/ClientModel'
import { useAuth } from 'hooks'
import React from 'react'
import mask from 'utils/masks'
import { renderBadge } from 'utils/renderBadge'
import { _ecpClientStatus } from '../../clientInterfaces'

type Props = {
  client: {
    name?: string
    status?: string
    cpf?: string
    dismissalReason?: string
  }
  className?: string
  showStatus?: boolean
}

export default function ClientHeaderInfo ({ client, className = '', showStatus = true } : Props) {
  const user = useAuth()
  return (
    <div className={`flex client-details__header-info-container ${className}`}>
      <span className='client-details__initial-container'>
        <LetterAvatar name={client?.name} />
      </span>

      <div className='client-details__header-info'>
        <div className='client-details__header-name-container'>
          <Tooltip title={`${client?.name}`} destroyTooltipOnHide={{ keepParent: false }}>
            <h2 className='client-details__header-name mb-0'>{client?.name}</h2>
          </Tooltip>

          {showStatus && (
            <>
              <div className='client-details__header-tags'>
                {client?.status && renderBadge(client?.status, 'client')}
                {ClientModel.canShowReason(user.level, client?.status as _ecpClientStatus) && client?.dismissalReason && <p>Motivo: {client?.dismissalReason ?? '-'}</p>}
              </div>

            </>
          )}
        </div>

        {client?.cpf &&
          <p className='client-details__header-id'>
            CPF: {mask((client?.cpf as string), 'cpf', true)}
          </p>
        }

      </div>
    </div>
  )
}

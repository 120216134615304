import { Skeleton } from 'antd'
import { SkeletonInputProps } from 'antd/lib/skeleton/Input'
import React from 'react'

function SkeletonTextLine ({ className, ...rest }: SkeletonInputProps) {
  return (
    <div className='skeleton-text-skeleton'>
      <Skeleton.Input
        active={true}
        className={`skeleton-text-skeleton__content ${className || ''}`}
        {...rest}
      />
    </div>
  )
}

export default SkeletonTextLine

import { IActionDynamicProposal } from 'store/modules/dynamicProposal/dynamicProposalActionsTypes'

export interface IAction<T, P> {
  type: T,
  payload: P
}

export enum DynamicProposalTypes {
  SET_DEPARTMENTS = 'DYNAMIC_PROPOSAL_SET_DEPARTMENTS',
  SET_DETAILS = 'DYNAMIC_PROPOSAL_SET_DETAILS',
  SET_DETAILS_ERROR = 'DYNAMIC_PROPOSAL_SET_DETAILS',

  SET_STEPS = 'DYNAMIC_PROPOSAL_SET_STEPS',
  STEP_LOADING_ON = 'DYNAMIC_PROPOSAL_STEP_LOADING_ON',
  STEP_LOADING_OFF = 'DYNAMIC_PROPOSAL_STEP_LOADING_OFF',
  RESET = 'DYNAMIC_PROPOSAL_RESET'
}

export const dynamicProposalActions = {
  // DETAILS
  setDetails: (payload: IActionDynamicProposal.setDetails['payload']): IActionDynamicProposal.setDetails => ({
    type: DynamicProposalTypes.SET_DETAILS,
    payload: payload
  }),

  setDetailsError: (payload: IActionDynamicProposal.setDetails['payload']): IActionDynamicProposal.setDetails => ({
    type: DynamicProposalTypes.SET_DETAILS_ERROR,
    payload
  }),

  setDeparments: (payload: IActionDynamicProposal.setDeparments['payload']): IActionDynamicProposal.setDeparments => ({
    type: DynamicProposalTypes.SET_DEPARTMENTS,
    payload
  }),

  // STEPS
  stepLoadingOn: (payload: IActionDynamicProposal.setLoadingStepOn['payload']): IActionDynamicProposal.setLoadingStepOn => ({
    type: DynamicProposalTypes.STEP_LOADING_ON,
    payload
  }),

  stepLoadingOff: (payload: IActionDynamicProposal.setLoadingStepOff['payload']): IActionDynamicProposal.setLoadingStepOff => ({
    type: DynamicProposalTypes.STEP_LOADING_OFF,
    payload
  }),

  setSteps: (payload: IActionDynamicProposal.setSteps['payload']): IActionDynamicProposal.setSteps => ({
    type: DynamicProposalTypes.SET_STEPS,
    payload
  }),

  reset: () => ({
    type: DynamicProposalTypes.RESET
  })
}

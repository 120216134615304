import React, { useLayoutEffect } from 'react'
import { reactAppLine } from 'utils/config'
import Simulator from 'egi/app/Simulator/Simulator'
import { useAuth } from 'hooks'
import { SocketSingleton } from 'utils/socket'

function SideText ({ internal }: { internal: boolean }) {
  return (
    <div className='simulator-left-side-block-text'>
      {internal && (<a href={reactAppLine} target="_blank" rel="noreferrer" className="simulator-layout-link-client">JA É CLIENTE? ACESSE AQUI</a>)}
      <h1 className='simulator-main-text'>Conheça o empréstimo com garantia de imóvel.</h1>
      <p className="simulator-description-text">Utilize seu imóvel como garantia para ter o empréstimo com as menores taxas do Brasil.</p>
    </div>
  )
}

function SimulatorLayout ({ internal = false, currentStep, needCEP }: { internal: boolean, currentStep?: number, needCEP?: boolean }) {
  const user = useAuth()
  const isAuth = user.isAuth

  useLayoutEffect(() => {
    if (!isAuth) {
      const socket = new SocketSingleton()
      socket.disconnect()
    }
  }, [])

  return (
    <div className='simulator-full-page'>
      <div
        className='simulator-wrapper text-center'
        style={{ justifyContent: 'space-between' }}
      >
        <SideText internal={!internal} />
        <Simulator step={currentStep} onlyCEP={needCEP} hasTerms={true} isAuth={isAuth}/>
      </div>
    </div>
  )
}

export default SimulatorLayout

import React, { Fragment, useState, useLayoutEffect, useEffect } from 'react'
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { Button, Divider, Modal, Spin, Tooltip } from 'antd'
import { useAuth, useClient, useProposal, useSimulation } from 'hooks'
import format from 'utils/format'
import translate from 'utils/translate'
import { resetAllSimulation, simulationLastStep, simulationSteps } from 'store/modules/simulation/actions'
import { simulation as consumerSimulation } from 'egi/consumers'
import swal from 'utils/swal'
import { useDispatch } from 'react-redux'
import { createProposal } from 'store/modules/proposal/actions'
import SimulatorResume from '../SimulatorResume/SimulatorResume'
import SimulatorLine from '../SimulatorLine/SimulatorLine'
import { ISimulatorResultStep } from '../../simulatorInterfaces'
import { useHistory } from 'react-router-dom'
import SimulationsRepository from 'egi/repositories/SimulationsRepository'
import { tablesUpdate } from 'store/modules/tables/actions'
import LeadsRepository from 'egi/repositories/LeadsRepository'

interface IHandleFinishSimulation {
  simulationId: string
  productId: string
  clientId?: string
  callback?: (error?: any) => void
}

function SimulatorResultStep ({ callbackClient, callbackFunction, internalSimulator, isAuth }: ISimulatorResultStep) {
  const [modalTitle, setModalTitle] = useState<string>('INFORMAÇÕES SOBRE SUA SIMULAÇÃO')
  const [visible, setVisible] = useState<boolean>(false)
  const [indexes, setIndexes] = useState<boolean>(false)
  const [flatRate, setFlatRate] = useState<boolean>(false)
  const [status, setStatus] = useState< 'FLATRATE' | 'IPCA' | 'finish' |undefined>(undefined)

  const history = useHistory()
  const simulation = useSimulation()
  const dispatch = useDispatch()
  const user = useAuth()
  const client = useClient()
  const proposal = useProposal()

  useLayoutEffect(() => {
    if (simulation.lastStep?.possibleIndexes) {
      const getFlateRate = simulation.lastStep?.possibleIndexes.find((item: string) => item === 'FLATRATE')
      if (getFlateRate) {
        setIndexes(true)
      }
    }
  }, [simulation])

  function handleFinishSimulation ({ simulationId, productId, clientId, callback }: IHandleFinishSimulation) {
    setStatus('finish')
    dispatch(createProposal({ productId, simulationId, clientId }, (id: string, error) => {
      if (callback) callback(error)
      if (callbackFunction) {
        callbackFunction(id)
        dispatch(simulationSteps(1))
        dispatch(resetAllSimulation())
      }
    }))
  }

  const onSubmit = async () => {
    const data = {
      ...simulation.values,
      currentStep: 6
    }

    setStatus('finish')
    try {
      const response = await consumerSimulation.simulation(data)
      const simulationId = response.data.simulation.simulationId
      dispatch(simulationLastStep(response.data.simulation))
      swal.basic({ title: 'Sucesso!', text: response.message, icon: 'success' })
      setStatus(undefined)

      if (isAuth) {
        if (user.level === 'promoter') {
          handleFinishSimulation({
            productId: String(simulation.values?.productId),
            simulationId: simulationId || simulation.id,
            clientId: client._id,
            callback: (error) => {
              if (error) {
                setStatus(undefined)
                swal.basic({ title: 'Atenção', text: error.message, icon: 'warning' })
                return
              }
              setStatus('finish')
            }
          })

          return
        }

        handleFinishSimulation({
          productId: String(simulation.values?.productId),
          simulationId: simulationId,
          clientId: user._id,
          callback: () => setStatus(undefined)
        })

        return
      }
      history.push(`/signup?simulationId=${simulation.id}`)
    } catch (err) {
      setStatus(undefined)
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      if (err?.data?.needsVinculation) {
        swal.confirmNegate({
          icon: 'question',
          title: 'Atenção',
          text: 'Você gostaria de se vincular a essa proposta?',
          confirm: async () => {
            try {
              if (!simulation?.values?._id) throw new Error('Esta proposta não está válida para alteração')
              const response = await LeadsRepository.changeCommercial(simulation?.values?._id, { commercialId: user._id })
              const message = response.data.message

              swal.basic({ title: 'Sucesso!', text: message, icon: 'success' })
              dispatch(tablesUpdate())
            } catch (err) {
              swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
            }
          }
        })
      }
    }
  }

  const changeIndex = async (index: 'FLATRATE' | 'IPCA', showSwal?: boolean) => {
    const data = {
      ...simulation.values,
      currentStep: 5,
      index: index
    }

    setStatus(index)
    try {
      const response = await consumerSimulation.simulation(data)
      dispatch(simulationLastStep(response.data.simulation))
      if (showSwal) {
        swal.confirmNegate({
          title: 'Atenção!',
          text: 'Ao mudar a taxa, é necessário retornar ao passo anterior para ajustes do valor de crédito líquido',
          icon: 'warning',
          confirm: () => { dispatch(simulationSteps(3)) },
          negate: () => {
            changeIndex(index === 'IPCA' ? 'FLATRATE' : 'IPCA', false)
            setFlatRate(index === 'IPCA' ? true : false)
          }
        })
      }
      setStatus(undefined)
    } catch (err) {
      const specificError = 'Valor de crédito não pode ser maior que o valor máximo'
      if (err.data && err.data.invalid && err.data.invalid[0].message.includes(specificError)) {
        swal.confirmNegate({
          title: 'Atenção!',
          text: 'Ao mudar a taxa, é necessário retornar ao passo anterior para ajustes do valor de crédito líquido',
          icon: 'warning',
          confirm: () => {
            setFlatRate(index === 'IPCA' ? true : false)
            if (simulation.lastStep) {
              dispatch(simulationLastStep({
                ...simulation.lastStep,
                index: index
              }, 3))
            }
          }
        })
      }
      setStatus(undefined)
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    }
  }

  const changeSimulation = async () => {
    if (!proposal.id) {
      swal.basic({ title: 'Atenção', text: 'Proposta não encontrada!', icon: 'warning' })
      return
    }

    setStatus('finish')

    const data = {
      simulationId: simulation.lastStep?.simulationId,
      proposalId: proposal.id
    }

    try {
      const response = await SimulationsRepository.change(data)
      swal.basic({ title: 'Sucesso', text: response.data.message, icon: 'success' })
      dispatch(resetAllSimulation())
      dispatch(simulationSteps(1))
      if (callbackFunction) callbackFunction()
    } catch (err) {
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    } finally {
      setStatus(undefined)
    }
  }

  const handleChangeSimulation = () => {
    if (proposal.simulation?.financing && simulation.lastStep?.financing) {
      if (simulation.lastStep?.financing <= proposal.simulation?.financing) {
        return swal.confirmNegate({
          icon: 'warning',
          title: 'Atenção',
          text: 'Os valores da proposta serão alterados. Tem certeza que deseja prosseguir?',
          confirm: () => { changeSimulation() }
        })
      }
    }

    if (proposal.pastCredit) {
      return swal.confirmNegate({
        icon: 'warning',
        title: 'Atenção',
        text: 'Sua proposta retornará a etapa de Análise de Crédito. Tem certeza que deseja sair?',
        confirm: () => { changeSimulation() }
      })
    }

    return changeSimulation()
  }

  useEffect(() => {
    function onFill () {
      const data = Object.assign({}, simulation.lastStep) || {}

      if (data.index === 'FLATRATE') setFlatRate(true)
    }

    onFill()
  }, [])

  return (
    <Fragment>
      <Modal
        className="simulator-modal"
        width='80vw'
        title={<h1 className="simulator-title text-center my-3" style={{ lineHeight: 1.3 }}>{modalTitle}</h1>}
        centered
        visible={visible}
        footer={null}
        onOk={() => {
          setVisible(false)
          setModalTitle('INFORMAÇÕES SOBRE SUA SIMULAÇÃO')
        }}
        onCancel={() => {
          setVisible(false)
          setModalTitle('INFORMAÇÕES SOBRE SUA SIMULAÇÃO')
        }}
      >
        <SimulatorResume setModalTitle={setModalTitle}/>
      </Modal>

      <div className="mb-3">
        <SimulatorLine
          label="Valor do imóvel"
          value={format.formatBRL(simulation.values?.propertyValue)}
        />
      </div>

      <div className="mb-3">
        <SimulatorLine
          label="Valor do empréstimo"
          value={format.formatBRL(simulation.lastStep?.financing)}
        />
      </div>

      <div className="mb-3">
        <SimulatorLine
          label="Valor do crédito líquido"
          value={format.formatBRL(simulation.lastStep?.liquid)}
        />
      </div>

      <div className="mb-3">
        <SimulatorLine
          label="Prazo"
          value={simulation.values?.deadline + ' meses'}
        />
      </div>

      <div className="mb-3">
        <SimulatorLine
          label="Tipo do imóvel"
          value={translate.subProducts(String(simulation.values?.sub))}
        />
      </div>

      <div className="mb-3">
        <SimulatorLine
          label="Sistema de amortização"
          value={
            <label style={{ textTransform: 'capitalize' }}>
              {simulation.lastStep?.amortizationType}
            </label>
          }
        />
      </div>

      <Divider />

      <div className="mb-3 text-center">
        <Button
          style={{ minWidth: 140 }}
          className={`mr-2  mb-md-2 mr-md-0 ${!flatRate ? 'simulator-calculation-radio-button' : 'simulator-specialist-radio-button'}`}
          loading={status === 'IPCA'}
          type={flatRate ? 'ghost' : 'default'}
          onClick={() => {
            setFlatRate(false)
            changeIndex('IPCA', true)
          }}
        >
          IPCA
        </Button>

        <Button
          style={{ minWidth: 140 }}
          className={`ml-md-3 ${flatRate ? 'simulator-calculation-radio-button' : 'simulator-specialist-radio-button'}`}
          loading={status === 'FLATRATE'}
          type={flatRate ? 'default' : 'ghost'}
          disabled={(Number(simulation.lastStep?.deadline) <= 60 && indexes === true) ? false : true }
          onClick={() => {
            setFlatRate(true)
            changeIndex('FLATRATE', true)
          }}
        >
          PRÉ-FIXADA
        </Button>

        <label className="pl-1">
          <Tooltip
            color="white"
            placement="right"
            title={<label className="color-black">A taxa pré-fixada é válida apenas para simulações de até 60 meses.</label>}
            destroyTooltipOnHide={{ keepParent: false }}
          >
            <InfoCircleOutlined className="simulation-info" style={{ color: '#00441F' }} />
          </Tooltip>
        </label>
      </div>

      <div className="mb-3">
        <SimulatorLine
          label="Valor da primeira parcela"
          value={simulation.lastStep?.parcels && simulation.lastStep?.parcels?.length > 0
            ? <label className="simulator-title" style={{ fontSize: 18 }}>{format.formatBRL(simulation.lastStep?.parcels[0].totalParcel)}</label>
            : (<LoadingOutlined />)}
        />
      </div>

      <div className="mb-3">
        <SimulatorLine
          label="Taxa de juros"
          value={
            <Fragment>
              {(status === 'FLATRATE' || status === 'IPCA') ? (<Spin spinning={status === 'FLATRATE' || status === 'IPCA'} />) : (
                <Fragment>
                  <label>{format.decimalToPercentage(Number(simulation.lastStep?.taxes?.monthlyTax)).toFixed(2)}%</label>
                  <label>{flatRate ? ' ao mês + PRÉ-FIXADA' : ' ao mês + IPCA'}</label>
                </Fragment>
              )}
            </Fragment>
          }
          tooltip={
            <label className="pl-1">
              <Tooltip
                color="white"
                placement="right"
                destroyTooltipOnHide={{ keepParent: false }}
                title={
                  <p className="simulator-tooltip-paragraph mb-0">
                    <b>O IPCA (Índice Nacional de Preços ao Consumidor Amplo)</b> é um índice mensal que indica a variação dos preços e serviços no comércio.
                    É considerado, pelo Banco Central, o índice brasileiro oficial da inflação.
                  </p>
                }
              >
                <InfoCircleOutlined className="simulation-info" style={{ color: '#00441F' }} />
              </Tooltip>
            </label>
          }
        />
      </div>

      <button
        className="link-style simulator-informations"
        onClick={() => { setVisible(true) }}
      >
        VER MAIS INFORMAÇÕES
      </button>

      {(internalSimulator) ? (
        <Button
          className="w-100 mb-3 simulator-calculation-button"
          onClick={() => {
            if (callbackClient) callbackClient()
          }}
        >
          CADASTRAR CLIENTE
        </Button>
      ) : (
        <Button
          className="w-100 mb-3 simulator-calculation-button"
          id="simulator-calculation-button"
          onClick={() => (proposal.id) ? handleChangeSimulation() : onSubmit()}
          loading={status === 'finish'}
        >
          CONTRATAR AGORA
        </Button>
      )}

      <Button
        className="w-100 mt-1 color-primary simulator-specialist-button"
        type="ghost"
        onClick={() => { dispatch(simulationSteps(callbackFunction || user.level === 'client' ? 1 : 0)) }}
      >
        REFAZER SIMULAÇÃO
      </Button>
    </Fragment>
  )
}

export default SimulatorResultStep

import { IReduxModal } from './types'

export const modalFillValues = (data: IReduxModal) => ({
  type: 'MODAL_FILL_VALUES',
  payload: { ...data }
})

export const modalSetVisible = ({ visible }: { visible: boolean }) => ({
  type: 'MODAL_MANAGE_VISIBLE',
  payload: {
    visible
  }
})

export const modalReset = () => ({
  type: 'MODAL_RESET_ALL'
})

import React, { Fragment } from 'react'
import { Col, Row, Tooltip, Typography } from 'antd'
import { SelectOutlined } from '@ant-design/icons'
import { INotification } from 'components/Notification/types'
import mask from 'utils/masks'
import { Link } from 'react-router-dom'

const { Paragraph } = Typography

function NotificationInfo ({ info, setVisible }: { info: INotification, setVisible: React.Dispatch<React.SetStateAction<boolean>> }) {
  const regex = /(?=\/auth)(.*)/g
  const notificationLink = info.redirectTo.match(regex)

  function tooltipInfo ({ email, cellphone }: { email: string, cellphone: string }) {
    return (
      <div className="flex flex-column p-1">
        <span className="color-primary mb-2">Celular: <b>{mask(String(cellphone), 'cell', true)}</b></span>
        <span className="color-primary">Email: <b>{email}</b></span>
      </div>
    )
  }

  return (
    <Fragment>
      <Row align="middle">
        <Col lg={10} md={10} sm={10} xs={24}>
          <Link
            to={`${notificationLink}`}
            target='_blank'
            type="link"
            className="notification__redirect-proposal-button"
            onClick={() => {
              setVisible(false)
            }}
          >
            <SelectOutlined /> <span className="underline">Acessar proposta <b>{info.proposal?.number}</b></span>
          </Link>
        </Col>

        <Col lg={14} md={14} sm={14} xs={24}>
          <Paragraph className="mb-0 mb-md-2 color-primary">Passo: <b>{info.step?.name}</b></Paragraph>
        </Col>
      </Row>

      <Row align="middle">
        <Col lg={10} md={10} sm={10} xs={24}>
          <Paragraph className="mb-0 mb-md-2 color-primary">
            Enviado por: <Tooltip title={tooltipInfo({ email: info.sender?.email, cellphone: info.sender?.cellphone })} placement="bottom" color="#fff" destroyTooltipOnHide={{ keepParent: false }}>
              <b className="underline pointer">{info.sender?.name}</b>
            </Tooltip>
          </Paragraph>
        </Col>

        <Col lg={14} md={14} sm={14} xs={24}>
          <Paragraph className="mb-0 color-primary">
            Cliente: <Tooltip title={tooltipInfo({ email: info.client?.email, cellphone: info.client?.cellphone })} placement="bottom" color="#fff" destroyTooltipOnHide={{ keepParent: false }}>
              <b className="underline pointer">{info.client?.name}</b>
            </Tooltip>
          </Paragraph>
        </Col>
      </Row>
    </Fragment>
  )
}

export default NotificationInfo

import React, { Fragment, useState } from 'react'
import UserTable from 'components/UserTable/UserTable'
import TelemarketingAdminColumns from 'components/UserTable/usersColumns/TelemarketingAdminColumns'
import { useDispatch } from 'react-redux'
import { tablesSetFiltersValues, tablesUpdate } from 'store/modules/tables/actions'
import swal from 'utils/swal'
import translate from 'utils/translate'
import ButtonModel from 'components/SeverTable/ButtonModel'
import FilterSwitch from 'components/SeverTable/FilterSwitch'
import { useTables } from 'hooks'
import ModalInvite from 'components/ModalInvite/ModalInvite'
import UsersEgiRepository from 'egi/repositories/UsersEgiRepository'

function TelemarketingAdminList () {
  const [visible, setVisible] = useState<boolean>(false)

  const dispatch = useDispatch()
  const tables = useTables()

  async function blockUser (id: string) {
    try {
      const response = await UsersEgiRepository.block(id)
      dispatch(tablesUpdate())
      swal.basic({ title: 'Sucesso!', text: response.data.message, icon: 'success' })
    } catch (err) {
      swal.basic({ title: 'Atenção!', text: err.message, icon: 'warning' })
    }
  }

  const buttons = [
    <ButtonModel
      onClick={() => setVisible(true)}
      key={0}
      text={`Convidar usuário ${translate.level('administrator')}`}
    />
  ]

  const filters = [
    <FilterSwitch
      placeholder="Email verificado:"
      name="hasEmailVerified"
      key='hasEmailVerified'
      onChange={(value) => {
        if (value) dispatch(tablesSetFiltersValues({ ...tables.filters, hasEmailVerified: true }))
        else dispatch(tablesSetFiltersValues({ ...tables.filters, hasEmailVerified: undefined }))
      }}
    />
  ]

  return (
    <Fragment>
      <ModalInvite
        visible={visible}
        level="administrator"
        invitedLevel={translate.level('administrator')}
        area="telemarketing"
        onClose={() => setVisible(false)}
      />

      <UserTable
        buttons={buttons}
        filters={filters}
        columns={TelemarketingAdminColumns({
          blockUser,
          areaName: 'telemarketing'
        })}
        url="/administrators?areaName=telemarketing"
      />
    </Fragment>
  )
}

export default TelemarketingAdminList

import { IPartnerStep } from 'egi/app/PartnersLine/types'

const PartnerModel = {
  allowedStatus: ['approved', 'analysis'],
  documentsWithExpirationDate: ['certificado-correspondente-bancario'],

  canReview (step: IPartnerStep): boolean {
    const permissions = step.permissionConfiguration as { [key: string]: Array<string> }
    return permissions[step.status] && permissions[step.status].length > 0
  },

  canSendForm (step: IPartnerStep): boolean {
    const permissions = step.permissionConfiguration as { [key: string]: Array<string> }
    return permissions.form && permissions.form.includes('sendForm') && !this.allowedStatus.includes(step.status)
  },

  canSendDocument (step: IPartnerStep): boolean {
    const permissions = step.permissionConfiguration as { [key: string]: Array<string> }
    return permissions.document && permissions.document.includes('send') && !this.allowedStatus.includes(step.status)
  },

  canEditContract (step: IPartnerStep): boolean {
    const permissions = step.permissionConfiguration as { [key: string]: Array<string> }
    return permissions.drafts && permissions.drafts.includes('edit') && !['approved'].includes(step.status)
  },

  hasDocumentExpirationDate (step: IPartnerStep): boolean {
    return this.documentsWithExpirationDate.includes(step.slug)
  },

  canChooseMinuta (step: IPartnerStep): boolean {
    return step.status !== 'approved'
  }

}

export default PartnerModel

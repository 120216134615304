type IFieldInvalid = {
  field: string
  message: string
}

/**
 * Validates a Brazilian CPF (Cadastro de Pessoas Físicas) number.
 *
 * @param {string} cpf - The CPF number to validate.
 * @returns {boolean} True if the CPF is valid, false otherwise.
 *
 * @example
 * const validCpf = '123.456.789-09';
 * const isValid = validateCpf(validCpf);
 * // Result: true, as '123.456.789-09' is a valid CPF.
 *
 * @example
 * const invalidCpf = '111.222.333-44';
 * const isValid = validateCpf(invalidCpf);
 * // Result: false, as '111.222.333-44' is not a valid CPF.
 */
export const validateCpf = (cpf: string): boolean => {
  cpf = String(cpf).replace(/\D/g, '')
  let sum = 0; let remainder
  if (cpf === '00000000000') return false
  if (cpf === '11111111111') return false
  if (cpf === '22222222222') return false
  if (cpf === '33333333333') return false
  if (cpf === '44444444444') return false
  if (cpf === '55555555555') return false
  if (cpf === '66666666666') return false
  if (cpf === '77777777777') return false
  if (cpf === '88888888888') return false
  if (cpf === '99999999999') return false
  for (let i = 1; i <= 9; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i)
  remainder = (sum * 10) % 11
  if ((remainder === 10) || (remainder === 11)) remainder = 0
  if (remainder !== parseInt(cpf.substring(9, 10))) return false
  sum = 0
  for (let i = 1; i <= 10; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i)
  remainder = (sum * 10) % 11
  if ((remainder === 10) || (remainder === 11)) remainder = 0
  if (remainder !== parseInt(cpf.substring(10, 11))) return false
  return true
}

/**
 * Validates whether a CNPJ is valid.
 *
 * The CNPJ (Cadastro Nacional de Pessoa Jurídica) consists of 14 digits, including two check digits.
 * This function removes special characters (dots, slashes, hyphens), checks the length,
 * and performs calculations to validate the check digits.
 *
 * @param {string} cnpj - The CNPJ to be validated. It can contain special characters like dots, slashes, and hyphens.
 * @returns {boolean} - Returns `true` if the CNPJ is valid, `false` otherwise.
 *
 * @example
 * const validCNPJ = validarCNPJ("11.222.333/0001-81");
 * console.log(validCNPJ); // true or false depending on the validity of the CNPJ
 */
export function validateCNPJ (cnpj: string): boolean {
  cnpj = cnpj.replace(/[^\d]+/g, '')

  if (cnpj.length !== 14) return false
  if (/^(\d)\1{13}$/.test(cnpj)) return false

  const validateDigit = (cnpj: string, pos: number): number => {
    let sum = 0
    const pesos = pos === 12
      ? [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
      : [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]

    for (let i = 0; i < pesos.length; i++) {
      sum += parseInt(cnpj.charAt(i)) * pesos[i]
    }

    const resto = sum % 11
    return resto < 2 ? 0 : 11 - resto
  }

  const digit1 = validateDigit(cnpj, 12)
  const digit2 = validateDigit(cnpj, 13)

  // Verifica se os dígitos calculados são iguais aos dígitos do CNPJ
  return digit1 === parseInt(cnpj.charAt(12)) && digit2 === parseInt(cnpj.charAt(13))
}

/**
 * Validates and extracts error messages from a response data array.
 *
 * @param {any} data - The response data array containing error information.
 * @returns {Object} An object with fields as keys and corresponding error messages.
 *
 * @example
 * const responseData = [
 *   { field: 'username', message: 'Username is required.' },
 *   { field: 'password', message: 'Password must be at least 8 characters.' },
 * ];
 * const errors = validateResponse(responseData);
 * // Result: { username: 'Username is required.', password: 'Password must be at least 8 characters.' }
 *
 * @example
 * const emptyData = null;
 * const errorsEmpty = validateResponse(emptyData);
 * // Result: {}
 */
export function validateResponse (data: any) {
  if (!data) return {}
  const tempErrors: any = {}
  data.forEach((element: any) => {
    tempErrors[element.field] = element.message
  })

  return tempErrors
}

/**
 * Retrieves a formatted error message for display, especially for 422 status codes.
 *
 * @param {any} err - The error object containing information about the error.
 * @returns {string} A formatted error message.
 *
 * @example
 * const genericError = { code: 500, message: 'Internal Server Error' };
 * const errorMessage = getFieldErrorMessage(genericError);
 * // Result: 'Internal Server Error', as it's not a 422 status code.
 *
 * @example
 * const validationError = { code: 422, message: 'Validation Failed', data: { invalid: [{ field: 'username' }] } };
 * const errorMessageValidation = getFieldErrorMessage(validationError);
 * // Result: '<p>Validation Failed<ul><li class='swal-fields-error'><b>username</b></li></ul></p>'
 */
export function getFieldErrorMessage (err: any) {
  if (err.code !== 422) return err.message
  const fields = Array.isArray(err.data?.invalid) ? err.data?.invalid : []
  return (
    `<p>${err.message}<ul>${fields.map((invalidField: IFieldInvalid) => `<li class='swal-fields-error'><b>${invalidField.field}</b></li>`).join('')}</ul></p>`
  )
}

/**
 * Checks if a value is an array and has at least one element.
 *
 * @template T
 * @param {T[]} value - The array to check.
 * @returns {boolean} True if the value is an array and has at least one element, false otherwise.
 *
 * @example
 * const validArray = [1, 2, 3];
 * const isValid = isArrayAndHaveValues(validArray);
 * // Result: true, as [1, 2, 3] is an array with elements.
 *
 * @example
 * const emptyArray = [];
 * const isValidEmptyArray = isArrayAndHaveValues(emptyArray);
 * // Result: false, as [] is an array with no elements.
 *
 * @example
 * const nonArrayValue = 'notAnArray';
 * const isValidNonArray = isArrayAndHaveValues(nonArrayValue);
 * // Result: false, as 'notAnArray' is not an array.
 */
export const isArrayAndHaveValues = <T, >(value: T[]): boolean => Array.isArray(value) && value.length > 0

type UnidataCommomError = { [key: string]: [string] | any }
type IUnidataErrors = UnidataCommomError

/**
 * Handles and formats Unidata errors for display.
 *
 * @typedef {Object} UnidataCommomError - Common structure for Unidata errors.
 * @property {string[]} | {string} [key: string] - The error message(s) associated with a specific key.
 * @property {any} [key: string] - Nested Unidata errors.
 *
 * @typedef {Object} IUnidataErrors - Type alias for Unidata errors.
 *
 * @param {IUnidataErrors} [error] - The Unidata errors to handle.
 * @returns {UnidataCommomError} Formatted Unidata errors.
 *
 * @example
 * const unidataError = { field1: ['Error 1'], field2: ['Error 2'] };
 * const formattedErrors = handleOnidataErrors(unidataError);
 * // Result: { field1: 'Error 1', field2: 'Error 2' }
 *
 * @example
 * const nestedUnidataError = { field1: [{ nestedField: ['Nested Error'] }] };
 * const formattedNestedErrors = handleOnidataErrors(nestedUnidataError);
 * // Result: { field1: { nestedField: 'Nested Error' } }
 *
 * @example
 * const emptyError = null;
 * const formattedEmptyError = handleOnidataErrors(emptyError);
 * // Result: {}
 */
export const handleOnidataErrors = (error?: IUnidataErrors) => {
  if (!error) return {}
  if (Object.entries(error).length < 1) return {}

  return Object.entries(error).reduce((acc: UnidataCommomError, [key, values]) => {
    if (Array.isArray(values) && typeof values[0] === 'string') {
      acc[key] = values[0]
      return acc
    }

    if (Array.isArray(values) && typeof values[0] === 'object') {
      if (!acc[key]) acc[key] = []

      for (const item of values) {
        acc[key].push(handleOnidataErrors(item))
      }

      return acc
    }

    return acc
  }, {})
}

import React, { Fragment, useState } from 'react'
import { ServerTable } from 'components'
import { useTables } from 'hooks'
import { useDispatch } from 'react-redux'
import { tablesSetFiltersValues } from 'store/modules/tables/actions'
import filters from 'components/SeverTable/IndexFilters'
import FilterCreditArea from 'components/SeverTable/FilterCreditArea'
import { Drawer, Tabs } from 'antd'
import FeeReviewForm, { IFeeReviewFormProps } from '../components/FeeReviewForm/FeeReviewForm'
import SimulationHistory from 'components/SimulationCard/components/SimulationHistory/SimulationHistory'
import { personalData, propertyData } from 'components/SimulationCard/constants'
import { loanDataPricing } from '../exclusiveFeesConstants'
import { ExclusiveFeesColumns } from '../components/ExclusiveFeesColumns/ExclusiveFeesColumns'
import { LimiterTable } from 'components/LimiterTable/LimiterTable'

export interface IProposalDrawer {
  proposalId: string
  row: {
    isRevised: boolean,
    monthlyTax: number,
    commission: number,
    sub: string,
    amortizationType: string,
    promoterId?: string,
    pricingRevision: {
      needsRevision: boolean
      reason: string
      requestedRevisionAt: string
      _id: string
    }
  }
}

function ExclusiveFees () {
  const [showDrawer, setShowDrawer] = useState<boolean>(false)
  const [isRevised, setIsRevised] = useState<boolean>(false)
  const [proposalId, setProposalId] = useState<string>()
  const [proposalFields, setProposalFields] = useState<IFeeReviewFormProps>({ amortizationType: '', commission: 0, monthlyTax: 0, sub: '', pricingRevision: { needsRevision: false, reason: '', requestedRevisionAt: '', _id: '' } })

  const dispatch = useDispatch()
  const tables = useTables()

  function showProposalDrawer ({ proposalId, row }: IProposalDrawer) {
    setShowDrawer(true)
    setProposalId(proposalId)
    setIsRevised(row.isRevised)
    setProposalFields({ ...row })
  }

  function canSeeFilters (): React.ReactElement[] {
    const addFilters: React.ReactElement[] = [
      filters().proposalStatus,
      filters().person,
      filters().lastTrimester,
      filters().areasAction,
      filters().lastArea
    ]

    if (tables.filters.lastArea && tables.filters.lastArea === 'credito') {
      addFilters.push(
        <FilterCreditArea
          onChange={(value) => [
            dispatch(tablesSetFiltersValues({ ...tables.filters, clientApprovalStatus: value }))
          ]}
          key='clientApprovalStatus'
        />
      )
    }

    return addFilters
  }

  return (
    <Fragment>
      <Drawer
        placement="right"
        onClose={() => setShowDrawer(false)}
        visible={showDrawer}
        className="fee-drawer"
        destroyOnClose={true}
      >
        <Tabs>
          {isRevised === false && proposalId && (
            <Tabs.TabPane tab="Alterar" key="change">
              <FeeReviewForm
                id={proposalId}
                fields={proposalFields}
                onCancel={() => setShowDrawer(false)}
              />
            </Tabs.TabPane>
          )}

          {isRevised === true && proposalId && (
            <Tabs.TabPane tab="Histórico" key="history">
              <SimulationHistory
                proposalId={proposalId}
                content={{
                  personal: personalData,
                  property: propertyData,
                  loan: loanDataPricing
                }}
              />
            </Tabs.TabPane>
          )}
        </Tabs>
      </Drawer>
      <LimiterTable>
        <ServerTable
          url='/proposals?pricing=true'
          hasProposalStatus={true}
          primaryFilter={filters().typeSearch}
          secondaryFilter={filters().createdAt}
          filters={canSeeFilters()}
          columns={ExclusiveFeesColumns({ showProposalDrawer })}
          fixedDate={true}
        />
      </LimiterTable>
    </Fragment>
  )
}

export default ExclusiveFees

import React from 'react'
import { Space, Typography } from 'antd'
import Button from 'antd/es/button'
import { preSignupNumber } from 'utils/globals'

type Props = {
  userName: string
  onClose: () => void
}
function PreSignup ({ userName, onClose }: Props) {
  const { Paragraph } = Typography
  const whatsAppText = `https://api.whatsapp.com/send?1=pt_br&phone=${preSignupNumber}&text=Olá%20sou%20%2A${userName}%2A%2C%20e%20gostaria%20de%20ser%20acompanhado(a)%20por%20um%20consultor%20%2ARodobens%2A.`

  return (
    <div className='pre-signup'>
      <div>
        <h1 className="pre-signup__title">
          Bem vindo(a), <b>{userName}</b>
        </h1>
      </div>

      <div className='pre-signup__text-container'>
        <Paragraph className="pre-signup__text">
        Você vai iniciar o processo de autocontratação do Empréstimo com Garantia de Imóvel Rodobens!
          <br/><br/>
        Esta modalidade é 100% online. Você envia os seus documentos e acompanha toda a evolução da sua proposta por meio da nossa plataforma.
          <br/><br/>
        Para seguir com sua contratação, você deve se cadastrar e criar seu login e senha de acesso. Ao entrar na plataforma, complemente seu cadastro e realize o envio dos documentos solicitados para seguir com sua contratação.
          <br/><br/>
        Caso prefira ser acompanhado por um de nossos consultores, <a className="pre-signup__link" href={whatsAppText} target="_blank" rel="noreferrer"> clique aqui.</a>
        </Paragraph>
      </div>

      <Space>
        <Button
          className='pre-signup__button'
          type='primary'
          onClick={() => onClose()}
        >
          Continuar
        </Button>

        <Button
          className='pre-signup__button'
          type="ghost"
          onClick={() => onClose()}
        >
          Cancelar
        </Button>
      </Space>
    </div>
  )
}

export default PreSignup

import React from 'react'

export default function TokenVerificationSVG () {
  return (
    <svg viewBox="0 0 156 124" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M150.463 123.902H4.88142C3.58679 123.902 2.34518 123.388 1.42973 122.472C0.51429 121.557 0 120.315 0 119.021C52.7732 112.887 104.555 112.887 155.344 119.021C155.344 120.315 154.83 121.557 153.915 122.472C152.999 123.388 151.757 123.902 150.463 123.902Z" fill="#2F2E41"/>
      <path d="M155.344 119.308L0 119.021L18.0039 88.7271L18.09 88.5836V21.82C18.0899 21.0096 18.2495 20.2072 18.5596 19.4585C18.8697 18.7098 19.3242 18.0295 19.8972 17.4565C20.4702 16.8835 21.1505 16.429 21.8992 16.1189C22.6479 15.8088 23.4503 15.6492 24.2607 15.6493H130.222C131.033 15.6492 131.835 15.8088 132.584 16.1189C133.332 16.429 134.013 16.8835 134.586 17.4565C135.159 18.0295 135.613 18.7098 135.923 19.4585C136.233 20.2072 136.393 21.0096 136.393 21.82V89.043L155.344 119.308Z" fill="#3F3D56"/>
      <path d="M24.1199 19.9564C23.587 19.957 23.0761 20.169 22.6992 20.5458C22.3224 20.9226 22.1105 21.4335 22.1099 21.9664V82.8407C22.1105 83.3736 22.3224 83.8845 22.6993 84.2613C23.0761 84.6381 23.587 84.8501 24.1199 84.8507H131.224C131.757 84.85 132.268 84.6381 132.645 84.2613C133.022 83.8845 133.233 83.3736 133.234 82.8407V21.9664C133.234 21.4335 133.022 20.9226 132.645 20.5458C132.268 20.169 131.757 19.957 131.224 19.9564H24.1199Z" fill="white"/>
      <path d="M24.5687 92.8907C24.4041 92.891 24.2429 92.9383 24.1042 93.0272C23.9656 93.116 23.8552 93.2426 23.7861 93.3921L18.2385 105.452C18.1782 105.583 18.1517 105.728 18.1617 105.872C18.1717 106.016 18.2178 106.155 18.2957 106.277C18.3737 106.398 18.4809 106.499 18.6077 106.568C18.7344 106.637 18.8766 106.674 19.021 106.674H135.683C135.83 106.674 135.974 106.636 136.103 106.564C136.231 106.493 136.339 106.39 136.416 106.265C136.493 106.14 136.537 105.997 136.543 105.851C136.55 105.704 136.519 105.558 136.453 105.427L130.423 93.3668C130.352 93.2235 130.242 93.1029 130.106 93.0187C129.97 92.9346 129.813 92.8902 129.653 92.8907H24.5687Z" fill="#2F2E41"/>
      <path d="M77.2414 18.9514C77.955 18.9514 78.5335 18.3729 78.5335 17.6593C78.5335 16.9456 77.955 16.3671 77.2414 16.3671C76.5277 16.3671 75.9492 16.9456 75.9492 17.6593C75.9492 18.3729 76.5277 18.9514 77.2414 18.9514Z" fill="white"/>
      <path d="M65.7744 108.971C65.5851 108.971 65.401 109.033 65.2507 109.149C65.1004 109.264 64.9922 109.425 64.9427 109.608L63.5512 114.777C63.5168 114.904 63.5122 115.038 63.5379 115.168C63.5635 115.298 63.6187 115.42 63.6991 115.525C63.7795 115.63 63.883 115.715 64.0016 115.773C64.1202 115.832 64.2506 115.862 64.3829 115.862H90.847C90.9843 115.862 91.1196 115.829 91.2417 115.766C91.3638 115.703 91.469 115.612 91.5486 115.5C91.6283 115.388 91.68 115.259 91.6995 115.123C91.719 114.987 91.7057 114.849 91.6607 114.719L89.8717 109.55C89.8131 109.381 89.7031 109.234 89.5573 109.13C89.4114 109.027 89.2368 108.971 89.0577 108.971H65.7744Z" fill="#2F2E41"/>
      <path d="M136.393 87.5786V88.727H18.0039L18.093 88.5836V87.5786H136.393Z" fill="#2F2E41"/>
      <path d="M133.091 40.2C144.192 40.2 153.191 31.2009 153.191 20.1C153.191 8.99907 144.192 0 133.091 0C121.99 0 112.991 8.99907 112.991 20.1C112.991 31.2009 121.99 40.2 133.091 40.2Z" fill="#C4D294"/>
      <path d="M141.418 28.8579H124.763C124.459 28.8576 124.167 28.7365 123.951 28.5211C123.736 28.3058 123.615 28.0138 123.615 27.7093V17.6593C123.615 17.3548 123.736 17.0628 123.952 16.8475C124.167 16.6322 124.459 16.5111 124.763 16.5107H141.418C141.722 16.5111 142.014 16.6322 142.229 16.8475C142.445 17.0628 142.566 17.3548 142.566 17.6593V27.7093C142.566 28.0138 142.445 28.3058 142.229 28.5211C142.014 28.7365 141.722 28.8576 141.418 28.8579ZM124.763 17.6593V27.7093H141.418L141.418 17.6593L124.763 17.6593Z" fill="white"/>
      <path d="M138.259 17.6593H127.922V13.065C127.922 9.73735 130.096 7.32214 133.09 7.32214C136.085 7.32214 138.259 9.73735 138.259 13.065L138.259 17.6593ZM129.07 16.5107H137.11V13.065C137.11 10.36 135.457 8.47072 133.09 8.47072C130.724 8.47072 129.07 10.36 129.07 13.065L129.07 16.5107Z" fill="white"/>
      <path d="M133.09 23.5457C133.725 23.5457 134.239 23.0314 134.239 22.3971C134.239 21.7628 133.725 21.2485 133.09 21.2485C132.456 21.2485 131.942 21.7628 131.942 22.3971C131.942 23.0314 132.456 23.5457 133.09 23.5457Z" fill="white"/>
      <path d="M119.059 64.2028H36.2853C35.8789 64.2023 35.4893 64.0407 35.2019 63.7533C34.9146 63.4659 34.7529 63.0763 34.7524 62.6699V42.1372C34.7529 41.7308 34.9146 41.3412 35.2019 41.0539C35.4893 40.7665 35.8789 40.6048 36.2853 40.6044H119.059C119.466 40.6048 119.855 40.7665 120.143 41.0539C120.43 41.3412 120.592 41.7308 120.592 42.1372V62.6699C120.592 63.0763 120.43 63.4659 120.143 63.7533C119.855 64.0407 119.466 64.2023 119.059 64.2028ZM36.2853 41.2175C36.0414 41.2178 35.8077 41.3148 35.6352 41.4872C35.4628 41.6596 35.3658 41.8934 35.3656 42.1372V62.6699C35.3658 62.9138 35.4628 63.1476 35.6352 63.32C35.8077 63.4924 36.0414 63.5894 36.2853 63.5896H119.059C119.303 63.5894 119.537 63.4924 119.709 63.32C119.882 63.1476 119.979 62.9138 119.979 62.6699V42.1372C119.979 41.8934 119.882 41.6596 119.709 41.4872C119.537 41.3148 119.303 41.2178 119.059 41.2175L36.2853 41.2175Z" fill="#E6E6E6"/>
      <path d="M49.1464 57.8597C52.5948 57.8597 55.3904 55.0642 55.3904 51.6157C55.3904 48.1672 52.5948 45.3717 49.1464 45.3717C45.6979 45.3717 42.9023 48.1672 42.9023 51.6157C42.9023 55.0642 45.6979 57.8597 49.1464 57.8597Z" fill="#E6E6E6"/>
      <path d="M63.5672 47.4531C63.4304 47.4528 63.2949 47.4796 63.1685 47.5318C63.042 47.584 62.9271 47.6606 62.8303 47.7573C62.7335 47.8539 62.6567 47.9687 62.6043 48.0951C62.5519 48.2215 62.5249 48.3569 62.5249 48.4937C62.5249 48.6305 62.5519 48.766 62.6043 48.8924C62.6567 49.0187 62.7335 49.1335 62.8303 49.2302C62.9271 49.3268 63.042 49.4035 63.1685 49.4557C63.2949 49.5079 63.4304 49.5346 63.5672 49.5344H112.627C112.903 49.5344 113.168 49.4248 113.363 49.2296C113.558 49.0344 113.668 48.7697 113.668 48.4937C113.668 48.2177 113.558 47.953 113.363 47.7579C113.168 47.5627 112.903 47.4531 112.627 47.4531H63.5672Z" fill="#E6E6E6"/>
      <path d="M63.567 53.6971C63.291 53.6971 63.0263 53.8067 62.8312 54.0019C62.636 54.197 62.5264 54.4617 62.5264 54.7378C62.5264 55.0138 62.636 55.2785 62.8312 55.4736C63.0263 55.6688 63.291 55.7784 63.567 55.7784H84.6777C84.9537 55.7784 85.2184 55.6688 85.4136 55.4736C85.6088 55.2785 85.7184 55.0138 85.7184 54.7378C85.7184 54.4617 85.6088 54.197 85.4136 54.0019C85.2184 53.8067 84.9537 53.6971 84.6777 53.6971H63.567Z" fill="#E6E6E6"/>
    </svg>
  )
}

import { AxiosRequestConfig } from 'axios'
import { IResponseBase, Repository } from 'ecp/repositories/Repository'
import api from 'services/api'

class WhiteListRepository extends Repository {
  async getWhiteList (config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/list`, config)
    )
  }

  async addUserToWhiteList (body: { identifier: string, value: string}, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.post(`${this.path}`, body, config)
    )
  }

  async deleteWhiteList (id: string, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.delete(`${this.path}/${id}`, config)
    )
  }
}

export default new WhiteListRepository({ api, path: '/identifier-white-list' })

import { LoadingOutlined, DownOutlined, CloseOutlined } from '@ant-design/icons'
import { Spin, Progress } from 'antd'
import { EcpCard } from 'ecp/components/EcpCard/EcpCard'
import ProgressQueueModels from 'ecp/models/ProgressQueueModels'
import React, { ReactNode, useState } from 'react'
import { IProgressQueueItem, IStatusProgressQueue } from 'store/modules/progressQueue/progressQueueReducer'
import { IProgressQueueInfo, IProgressQueueListType } from '../../progressQueueInterfaces'

type Props = {
  onClose: Function
  percentage: number
  status?: IStatusProgressQueue
  listLenght: number
  progressQueueInfoList?: IProgressQueueInfo[]
  progressText: string
  itemIndex: number
  queueList: IProgressQueueItem[]
  body: ReactNode
  type?: IProgressQueueListType
}

type ProgressQueueCardViewHeaderProps = {
  progressText: string
  setShowBody: (showBOdy: boolean) => void
  showBody: boolean
  onClose: Function
  itemIndex: number
  queueList: IProgressQueueItem[]
}

function ProgressQueueCardViewHeader ({ progressText, setShowBody, showBody, onClose, queueList, itemIndex }: ProgressQueueCardViewHeaderProps) {
  return (
    <div className='progress-queue-card__header p-3 flex flex-justify-between '>
      <span>
        {status === 'active' &&
          <Spin
            indicator={<LoadingOutlined spin className='mr-3'/>}
            style={{ color: '#FFFFFF' }}
          />
        }

        <b>{progressText}</b>
      </span>

      <div>
        <button
          className='progress-queue-card__header-button mr-4'
          onClick={() => setShowBody(!showBody)}
        >
          <DownOutlined
            className={'progress-queue-card__show-button'}
            rotate={showBody ? 0 : 180}
          />
        </button>

        <button
          className='progress-queue-card__header-button'
          onClick={() => onClose(queueList, itemIndex)}
        >
          <CloseOutlined />
        </button>
      </div>
    </div>
  )
}

export default function ProgressQueueCardView ({ onClose, percentage, status, progressText, itemIndex, queueList, body, progressQueueInfoList, type }: Props) {
  const [showBody, setShowBody] = useState<boolean>(true)

  const handleStrokeColor = (status?: IStatusProgressQueue, hasErrors?: boolean) => {
    if (status === 'active') return 'normal'
    if (status === 'error') return 'var(--red-status)'
    if (ProgressQueueModels.hasPendingUserWarnings(status, type, hasErrors)) return 'var(--warning-status)'
    return 'var(--green-status)'
  }

  const handleStatus = (status?: IStatusProgressQueue, hasErrors?: boolean) => {
    if (ProgressQueueModels.hasPendingUserWarnings(status, type, hasErrors)) return 'normal'
    if (status === 'error') return 'exception'
    return status
  }

  return (
    <EcpCard className='progress-queue-card p-0'>
      <ProgressQueueCardViewHeader
        progressText={progressText}
        setShowBody={setShowBody}
        showBody={showBody}
        onClose={onClose}
        itemIndex={itemIndex}
        queueList={queueList}
      />

      <div className='progress-queue-card__progress-container p-3'>
        <Progress
          strokeColor={handleStrokeColor(status, ProgressQueueModels.hasErrorsOnOperations(progressQueueInfoList))}
          percent={Number(percentage.toFixed(2))}
          status={handleStatus(status, ProgressQueueModels.hasErrorsOnOperations(progressQueueInfoList))}
        />
      </div>

      {showBody &&
        <div className='progress-queue-card__body'>
          {body}
        </div>
      }
    </EcpCard>
  )
}

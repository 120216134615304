import React, { useState, useEffect, Fragment } from 'react'
import { Col, Divider, Row, Typography, Button, Tooltip, Skeleton, Tabs, Image } from 'antd'
import { EditFilled, SyncOutlined, RetweetOutlined, ExclamationCircleOutlined, CheckOutlined, CalendarOutlined, FolderOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { Drawer } from 'components'
import { ReactSVG } from 'react-svg'
import imageUrls from 'utils/imagesUrls'
import format from 'utils/format'
import translate from 'utils/translate'
import printContent from 'utils/print'
import { getProposalState } from 'store/modules/proposal/actions'
import { useAuth, useProposal } from 'hooks'
import { ISimulationCardProps, openTypeDrawer } from './types'
import StatisticsSimulation from './StatisticsSimulation'
import SimulationHistory from './components/SimulationHistory/SimulationHistory'
import SimulationPrintTemplate from './components/SimulationPrintTemplate/SimulationPrintTemplate'
import CommissionChange from './components/CommissionChange/CommissionChange'
import SimulationCET from './components/SimulationCET/SimulationCET'
import RequestFeeModal from './components/RequestFeeModal/RequestFeeModal'
import { resetAllSimulation, simulationSteps } from 'store/modules/simulation/actions'
import { loanDataCard, personalData, propertyData } from './constants'
import { ISimulation } from 'egi/types'
import Simulator from 'egi/app/Simulator/Simulator'
import { timeAsDayjs } from 'utils/time'
import { expirationDateColors } from './functions'
import StorageFiles from './components/StorageFiles/StorageFiles'
import RevalidateHistory from './components/RevalidateHistory/RevalidateHistory'

import ProposalEgiModel from 'egi/models/ProposalEgiModel'
const { Title } = Typography

function DepartmentButton ({ tooltipText, icon, clickButton }: { tooltipText: string, icon: React.ReactNode, clickButton: () => void }) {
  return (
    <Col className="ml-2 mb-1">
      <Tooltip title={tooltipText} destroyTooltipOnHide={{ keepParent: false }}>
        <Button
          className="share-button"
          shape="circle"
          icon={icon}
          type="primary"
          onClick={clickButton}
        />
      </Tooltip>
    </Col>
  )
}

const SimulationCard = ({ values, canRemakeSimulation, canChangeCommission, canRequestPricing, exceptionStorage, showValidateProposal, simulationDrawerOpen = false, openType, openSimulationDrawer, closeSimulationDrawer }: ISimulationCardProps) => {
  const [simulation, setSimulation] = useState<ISimulation>()
  const [tabType, setTabType] = useState('simulation')

  const [loading, setLoading] = useState<boolean>(false)

  const [requestFeeModalVisible, setRequestFeeModalVisible] = useState<boolean>(false)

  const proposal = useProposal()
  const proposalDeadline = proposal.deadlineInDays
  const dispatch = useDispatch()
  const user = useAuth()

  const canRequestDateReview = proposal?.proposalExpiration?.canShowExpiredButton

  const validateRevision = ProposalEgiModel.validateRevision(proposal.priceRevision?.needsRevision, proposal.priceRevision?.finishedRevision)

  const drawerTitle: { [key in openTypeDrawer]: string } = {
    commission: 'Editar comissão',
    simulator: 'Refazer simulação',
    storage: 'Armazenamento de Exceções',
    revalidateHistory: 'Histórico da validade da proposta'
  }

  useEffect(() => {
    setSimulation(proposal.simulation)
    if (values) setSimulation(values)
  }, [proposal])

  const closeDrawer = () => {
    setTabType('simulation')
    closeSimulationDrawer()
    setLoading(true)
    dispatch(resetAllSimulation())
    dispatch(simulationSteps(0))
    if (proposal.id) {
      dispatch(getProposalState(proposal.id, () => {
        setLoading(false)
      }))
    }
  }

  const firstCardSimulation = [
    { title: 'Valor do imóvel', value: format.formatBRL(simulation?.propertyValue) },
    { title: 'Valor Financiado', value: format.formatBRL(simulation?.financing) },
    { title: 'Prazo (mês)', value: simulation?.deadline && `${simulation?.deadline}` },
    { title: 'Forma de amortização', value: simulation?.amortizationType }
  ]

  if (ProposalEgiModel.preApproval(proposal.preApproval, user.level)) {
    firstCardSimulation.push({ title: 'Pré-aprovação', value: proposal.preApproval && `${translate.preApproval(proposal.preApproval).toUpperCase()}` })
  }

  const secondCardSimulation = [
    { title: 'Primeira Parcela', value: format.formatBRL(simulation?.parcels[0]?.totalParcel) },
    { title: 'Taxa de juros mensal', value: simulation?.taxes?.monthlyTax && `${format.decimalToPercentage(simulation?.taxes?.monthlyTax).toFixed(2)} %` },
    { title: 'Taxa de juros anual', value: simulation?.taxes?.anualTax && `${format.decimalToPercentage(simulation?.taxes?.anualTax).toFixed(2)} %` },
    { title: 'Valor líquido a liberar', value: simulation?.liquid && `${format.formatBRL(simulation?.liquid)}` }
  ]

  if (!ProposalEgiModel.isExternal(user.level)) {
    const secondCardSliced = secondCardSimulation.splice(3)
    secondCardSliced.unshift({ title: 'Total de juros', value: simulation?.totalInterest && `${format.formatBRL(simulation?.totalInterest)}` })
    secondCardSliced.push({ title: 'Valor total a pagar', value: simulation?.totalValue && `${format.formatBRL(simulation?.totalValue)}` })
    secondCardSimulation.push(...secondCardSliced)

    firstCardSimulation.push({ title: 'LTV', value: `${format.decimalToPercentage(simulation?.ltv || 0).toFixed(2)} %` })
  }

  return (
    <Fragment>
      <RequestFeeModal
        visible={requestFeeModalVisible}
        onCancel={() => setRequestFeeModalVisible(false)}
      />

      <Drawer
        title={drawerTitle[openType as openTypeDrawer]}
        visible={simulationDrawerOpen}
        onClose={closeDrawer}
      >
        {openType === 'simulator' && (
          <div>
            {ProposalEgiModel.canSeeSimulatorAndHistory(user.level) ? (
              <Fragment>
                <Tabs
                  defaultActiveKey={tabType}
                  activeKey={tabType}
                  type="card"
                  onChange={key => setTabType(key)}
                >
                  <Tabs.TabPane tab="Simulador" key="simulation" />
                  <Tabs.TabPane tab="Histórico" key="history" />
                </Tabs>

                {tabType === 'simulation' && (
                  <Simulator
                    remaking={true}
                    simulatorData={proposal.simulation}
                    callbackFunction={closeDrawer}
                    step={1}
                    hasTerms={false}
                  />
                )}

                {tabType === 'history' && proposal.id && (
                  <SimulationHistory
                    proposalId={proposal.id}
                    content={{
                      loan: loanDataCard,
                      personal: personalData,
                      property: propertyData
                    }}
                  />
                )}
              </Fragment>
            ) : (
              <Simulator
                remaking={true}
                simulatorData={proposal.simulation}
                callbackFunction={closeDrawer}
                step={1}
                hasTerms={false}
              />
            )}
          </div>
        )}

        {openType === 'commission' && (
          <CommissionChange
            type={openType}
            callbackFunction={closeDrawer}
          />
        )}

        {openType === 'storage' && exceptionStorage && (
          <StorageFiles
            exceptionStorage={exceptionStorage}
            proposal={proposal}
            callbackFunction={closeDrawer}
          />
        )}

        {openType === 'revalidateHistory' && (
          <RevalidateHistory />
        )}

      </Drawer>

      <Row className="pb-2">
        <Col xs={0} sm={0} md={3} className="pr-3">
          <div className="flex flex-justify-center flex-align-center pt-2">
            <ReactSVG
              beforeInjection={(svg) => {
                svg.classList.add('department-image-documents')
                svg.classList.add('h-100')
              }}
              src={imageUrls.simulation}
            />
          </div>
        </Col>

        <Col xs={24} sm={24} md={21} className="p-1">
          <Row className='simulation-card-header' align='middle'>
            <div className='simulation-card-header-title'>
              <Col>
                <Title className="department-card-title mb-0 pb-0" level={2}>
                  Simulação
                </Title>
              </Col>

              <div className='flex flex-row'>
                <Image
                  src={imageUrls.printer}
                  preview={false}
                  onClick={() => printContent('print-template')}
                  className="simulation-card-icons"
                />

                {canRemakeSimulation && (
                  <DepartmentButton
                    tooltipText="Refazer simulação"
                    icon={<SyncOutlined />}
                    clickButton={() => openSimulationDrawer({ type: 'simulator' })}
                  />
                )}

                {canChangeCommission && (
                  <DepartmentButton
                    tooltipText="Editar comissão"
                    icon={<EditFilled />}
                    clickButton={() => openSimulationDrawer({ type: 'commission' })}
                  />
                )}

                {canRequestPricing && validateRevision && (
                  <DepartmentButton
                    tooltipText="Revisão de pricing"
                    icon={<RetweetOutlined />}
                    clickButton={() => setRequestFeeModalVisible(true)}
                  />
                )}

                {exceptionStorage && (
                  <DepartmentButton
                    tooltipText="Armazenamento de Exceções"
                    icon={<FolderOutlined />}
                    clickButton={() => openSimulationDrawer({ type: 'storage' })}
                  />
                )}

                {canRequestDateReview && (
                  <DepartmentButton
                    tooltipText="Revisão de validade"
                    icon={<CalendarOutlined />}
                    clickButton={() => showValidateProposal(true)}
                  />
                )}

                {!validateRevision && (
                  <p className="proposal-flag proposal-flag--exclusive-tax">
                    <ExclamationCircleOutlined className='mr-2' />
                    Taxas em Revisão
                  </p>
                )}

                {proposal.priceRevision?.needsRevision && proposal.priceRevision?.finishedRevision && (
                  <p className="proposal-flag proposal-flag--exclusive-revised-tax">
                    <CheckOutlined className='mr-1' />
                    Taxa Revisada
                  </p>
                )}

                {proposal.proposalExpiration && proposal.proposalExpiration.revalidated && (
                  <p className="proposal-flag proposal-flag--exclusive-revised-tax cursor-pointer" onClick={() => openSimulationDrawer({ type: 'revalidateHistory' })}>
                    <CheckOutlined className='mr-1' />
                    Validade Revisada
                  </p>
                )}
              </div>
            </div>
            {proposal.proposalExpiration && !proposal.proposalExpiration.canShowExpiredDate && proposal.proposalExpiration.date && <div className='simulation-card-header-expiration-date'>
              <div className="crivo-status-circle ml-1 mr-2" style={{ backgroundColor: expirationDateColors(proposalDeadline) }} />
              <p><span className='bold'>Validade da proposta: </span> <span className='font-500'>{timeAsDayjs(proposal.proposalExpiration.date, { applyTimezone: false }).format('DD/MM/YYYY')}</span></p>
            </div>
            }
          </Row>

          <Divider className="divider-style" />

          {loading ? (
            <div className='mt-3'>
              <Skeleton active={true} className="loading-simulation-card" />
              <Skeleton active={true} className="loading-simulation-card" />
            </div>
          ) : (
            (simulation) && (
              <Fragment>
                <Row className="mt-6 mt-md-0">
                  <div className="card-simulation">
                    <StatisticsSimulation data={firstCardSimulation} />
                  </div>
                  <div className="card-simulation">
                    <StatisticsSimulation data={secondCardSimulation} />
                  </div>
                </Row>
              </Fragment>
            )
          )}

          <SimulationPrintTemplate values={proposal.simulation} expirationDate={proposal.proposalExpiration?.date} />
          <SimulationCET />
        </Col>
      </Row>
    </Fragment >
  )
}

export default SimulationCard

import { ListECPFModel } from 'ecpf/models/ListECPFModel'
import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { isNullish } from 'utils/utils'

function transformObjToQuery (obj: Record<string, string>) {
  return new URLSearchParams(obj).toString()
}

export function urlSearchParamsToObj (urlParams: URLSearchParams) {
  return Array.from((urlParams.entries() || [])).reduce((acc, [key, value]) => {
    if (!value) return acc
    acc[key] = value
    return acc
  }, {} as Record<string, string>)
}

const filterValidObjEntriesToUrl = (obj: Record<string, any>) => {
  const result: Record<string, any> = {}

  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'string' && value !== '') {
      result[key] = value
    }

    if (typeof value === 'number') {
      result[key] = value
    }
  })

  return result
}

const filterInvalidObjEntriesToUrl = (obj: Record<string, any>): string[] => {
  return Object.keys(obj).filter(key => {
    const value = obj[key]
    return value === undefined || value === null || value === ''
  })
}

export const PAGE_OFFSET_ECPF = 'offset'

export function useQueryFilters () {
  const history = useHistory()
  const location = useLocation()

  const urlSearchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search])

  const onUpdateSearchUrl = useCallback((values: Record<string, string>) => {
    const invalidEntries = filterInvalidObjEntriesToUrl({ ...values })
    const validEntries = filterValidObjEntriesToUrl({ ...values })

    const newValues = {
      ...urlSearchParamsToObj(urlSearchParams),
      ...validEntries
    }

    if (invalidEntries.length > 0) {
      invalidEntries.forEach(field => {
        delete newValues[field]
      })
    }

    history.push({
      pathname: location.pathname,
      search: encodeURI(transformObjToQuery(newValues))
    })
  }, [urlSearchParams.toString()])

  const onResetSearch = useCallback(() => {
    history.push({
      pathname: location.pathname,
      search: ''
    })
  }, [])

  const onUpdateSearchUrlResetingOffset = useCallback((values: Record<string, string>) => {
    onUpdateSearchUrl({ ...values, offset: '1' })
  }, [urlSearchParams.toString()])

  const getOffset = useCallback(() => {
    const page = urlSearchParams.get(PAGE_OFFSET_ECPF)
    if (isNullish(page)) return 1

    return Number(page)
  }, [urlSearchParams.toString()])

  const getLimit = useCallback(() => {
    const limit = urlSearchParams.get('limit')
    if (isNullish(limit)) return ListECPFModel.LIMIT

    return Number(limit)
  }, [urlSearchParams.toString()])

  const calculateCountByForm = (formItems: Record<string, any>) => {
    return Object.keys(formItems || {}).filter((key: string) => {
      return urlSearchParams.has(key) && urlSearchParams.get(key) !== ''
    }).length
  }

  function calculateCountBySearchParams<T extends object> (obj: T, ignoredKeys: string[] = []): number {
    return Object.entries(obj)
      .filter(([key, value]) =>
        !ignoredKeys.includes(key) &&
        value !== '' &&
        value !== undefined &&
        value !== null
      )
      .length
  }

  return {
    urlSearchParams,
    onUpdateSearchUrl,
    onResetSearch,
    getOffset,
    getLimit,
    calculateCountByForm,
    calculateCountBySearchParams,
    onUpdateSearchUrlResetingOffset
  }
}

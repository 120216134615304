import loadable from '@loadable/component'
import Loading from 'components/Loading/Loading'
import { timeout } from 'promise-timeout'
import React from 'react'
import { TIMEOUT_LOADER } from 'utils/globals'

const ClientInitialECPF = loadable(
  () => timeout(import(
    /* webpackChunkName: "ClientECPF" */
    'ecpf/app/ClientECPF/views/ClientInitialECPF/ClientInitialECPF'
  ), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const ClientFormECPF = loadable(
  () => timeout(import(
    /* webpackChunkName: "ClientECPF" */
    'ecpf/app/ClientECPF/views/ClientFormECPF/ClientFormECPF'
  ), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const ClientDetailsECPF = loadable(
  () => timeout(import(
    /* webpackChunkName: "ClientECPF" */
    'ecpf/app/ClientECPF/views/ClientDetailsECPF/ClientDetailsECPF'
  ), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

export { ClientInitialECPF, ClientFormECPF, ClientDetailsECPF }

import { Modal } from 'antd'
import { ILead } from 'egi/app/User/userInterfaces'
import { simulation } from 'egi/consumers'
import React, { useEffect, useState } from 'react'
import PreSignup from '../PreSignup/PreSignup'

type Props = {
  simulationId: string
  onFetchLead: (leads: ILead) => void
}
export const PreSignupModal = ({ simulationId, onFetchLead }: Props) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [userName, setUserName] = useState<string>('')

  useEffect(() => {
    async function fetchLead (simulationId: string) {
      try {
        const response = await simulation.leads(simulationId)
        const leads = response.data.leads
        setUserName(leads.name)
        setVisible(true)

        onFetchLead(leads)
      } catch (err) {
        console.log(err)
      }
    }

    if (simulationId) fetchLead(simulationId)
  }, [])

  const handleClose = () => {
    setVisible(false)
  }

  return (
    <Modal
      maskClosable={false}
      visible={visible}
      footer={null}
      closable={false}
      centered={true}
      destroyOnClose={true}
      width='clamp(700px, 700px, 100%)'
      className="confirmation-email-modal"
    >
      <PreSignup
        onClose={handleClose}
        userName={userName}
      />
    </Modal>
  )
}

import React from 'react'
import { Row, Table, Image } from 'antd'
import { ISimulation } from 'egi/types'
import format from 'utils/format'
import { timeAsDayjs } from 'utils/time'
import columns from 'egi/app/Simulator/components/dataTable'
import imageUrls from 'utils/imagesUrls'
import translate from 'utils/translate'

function SimulationPrintTemplate ({ values, expirationDate }: { values?: ISimulation, expirationDate?: string }) {
  return (
    <div id="print-template">
      <div className="flex flex-align-center flex-justify-between">
        <Image className="unauth-logo-file-upload" src={imageUrls.rodobensLogoLight} />
        <h3 className="mb-0 bold" />
        <h4 className="mb-0 bold">Simulação de Financiamento</h4>
      </div>
      <div style={{ width: '100%', height: 20, backgroundColor: '#E0E5CF' }} />
      <Row align="bottom" justify="space-between" >
        <label className="color-primary bold" >Nome do cliente</label>
        <label className="color-primary font-500" >{values?.name}</label>
      </Row>
      <Row align="bottom" justify="space-between" >
        <label className="color-primary bold" >Valor de avaliação do bem</label>
        <label className="color-primary font-500" >{format.formatBRL(values?.propertyValue)}</label>
      </Row>
      <Row align="middle" justify="space-between" >
        <label className="color-primary bold">Valor financiado</label>
        <label className="color-primary font-500">{format.formatBRL(values?.financing)}</label>
      </Row>
      <Row align="middle" justify="space-between" >
        <label className="color-primary bold" >Custas cartorárias*</label>
        <label className="color-primary font-500" >{format.formatBRL(values?.registerValue)}</label>
      </Row>
      <Row align="middle" justify="space-between" >
        <label className="color-primary bold" >IOF</label>
        <label className="color-primary font-500">{format.formatBRL(values?.iof)}</label>
      </Row>
      <Row align="middle" justify="space-between" >
        <label className="color-primary bold" >Tarifa de avaliação</label>
        <label className="color-primary font-500">{format.formatBRL(values?.avaliationFare)}</label>
      </Row>
      <Row align="middle" justify="space-between" >
        <label className="color-primary bold" >Valor líquido</label>
        <label className="color-primary font-500">{format.formatBRL(values?.liquid)}</label>
      </Row>
      <Row align="middle" justify="space-between" >
        <label className="color-primary bold" >Taxa efetiva a.m.</label>
        <label className="color-primary font-500">{format.decimalToPercentage(Number(values?.taxes.monthlyTax)).toFixed(2)} %</label>
      </Row>
      <Row align="middle" justify="space-between" >
        <label className="color-primary bold" >Taxa efetiva a.a.</label>
        <label className="color-primary font-500">{format.decimalToPercentage(Number(values?.taxes.anualTax)).toFixed(2)} %</label>
      </Row>
      <Row align="middle" justify="space-between" >
        <label className="color-primary bold" >Correção monetária**</label>
        <label className="color-primary font-500">{translate.correcaoMonetaria(values?.index)}</label>
      </Row>
      <Row align="middle" justify="space-between" >
        <label className="color-primary bold" >Prazo</label>
        <label className="color-primary font-500">{values?.deadline} meses</label>
      </Row>
      <Row align="middle" justify="space-between" >
        <label className="color-primary bold" >Data do 1º vencimento</label>
        <label className="color-primary font-500">{timeAsDayjs(values?.parcels[0].dueDate).format('DD/MM/YYYY')}</label>
      </Row>
      <Row align="middle" justify="space-between" >
        <label className="color-primary bold" >Data da simulação</label>
        <label className="color-primary font-500">{timeAsDayjs(values?.createdAt).format('DD/MM/YYYY')}</label>
      </Row>
      {expirationDate && <Row align="middle" justify="space-between" >
        <label className="color-primary bold" >Validade final da simulação</label>
        <label className="color-primary font-500">{timeAsDayjs(expirationDate).format('DD/MM/YYYY')}</label>
      </Row>}
      <Row align="middle" justify="space-between" >
        <label className="color-primary bold" >Tipo de amortização</label>
        <label className="color-primary font-500">{values?.amortizationType}</label>
      </Row>
      <Row align="top">
        <label className="color-primary bold mr-1">Observações</label>
        <div className="flex flex-column mt-1 mb-2">
          <label className="color-primary font-500">*Esta é uma estimativa das custas cartorárias e o valor não utilizado será devolvido.</label>
          {values?.index === 'IPCA' && <label className="color-primary font-500" >**A correção monetária (IPCA) não está projetada no valor da parcela total.</label>}
          <label className="color-primary font-500">
            As informações aqui constantes tratam-se de mera simulação, estando sujeitas a alterações a qualquer tempo,
            sem prévio aviso. A concessão do financiamento estará sujeita à análise e aprovação do Banco Rodobens, que observará sua política de crédito e a legislação vigente.
          </label>
        </div>
      </Row>

      <Table
        pagination={false}
        scroll={{ x: 450 }}
        className="simulator-table simulator-table__modified-padding"
        dataSource={values?.parcels}
        columns={columns}
      />
    </div>
  )
}

export default SimulationPrintTemplate

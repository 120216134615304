import React, { Fragment, useLayoutEffect } from 'react'
import { Button, Col, Divider, Form, Row } from 'antd'
import { Input } from 'components'
import QRCode from 'qrcode.react'
import { MailOutlined, WhatsAppOutlined } from '@ant-design/icons'
import { FormInstance } from 'antd/lib/form'

interface IModalShareProps {
  loading: {
    list: boolean
    invite: boolean
    modalInvite: boolean
    email: boolean
    generateLink: boolean
  }
  sendWhatsapp: () => void
  inviteEmail: () => void
  invite: string
  email?: string
  cellphone?: string
  form: FormInstance<any>
}

function ModalShare ({ loading, sendWhatsapp, inviteEmail, form, invite, email, cellphone }: IModalShareProps) {
  useLayoutEffect(() => {
    if (email && form) form.setFieldsValue({ email })
    if (cellphone && form) form.setFieldsValue({ cellphone })
  }, [])

  return (
    <Fragment>
      <Divider>
        <label className="bold">Compartilhar</label>
      </Divider>

      <Form
        className="pb-3 text-center"
        layout="vertical"
        form={form}
      >
        <Row gutter={15}>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="cellphone"
              rules={[{ required: true, message: 'Digite um celular.' }]}
            >
              <Input placeholder="Digite um celular" mask="phone"/>
            </Form.Item>
          </Col>

          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item>
              <Button
                type="primary"
                className="w-100"
                icon={<WhatsAppOutlined />}
                onClick={sendWhatsapp}

              >
                Enviar por whatsapp
              </Button>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={15}>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Digite um email.' }]}
            >
              <Input placeholder="Digite um email" />
            </Form.Item>
          </Col>

          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item>
              <Button
                loading={loading.email}
                type="primary"
                className="w-100"
                icon={<MailOutlined />}
                onClick={inviteEmail}
              >
                Enviar por email
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <div className="text-center pt-3">
        <p className="bold">QR CODE</p>
        <QRCode value={invite} />
      </div>
    </Fragment>
  )
}

export default ModalShare

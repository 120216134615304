import React, { Fragment, useState, useEffect, useRef, MutableRefObject } from 'react'
import { Button, Checkbox, Col, Form, Row, Typography } from 'antd'
import { Input, Address } from 'components'
import ChooseContractComponent from '../../ChooseContractComponent'
import locale from 'antd/es/date-picker/locale/pt_BR'
import { IOnidataContractErrors } from '../../contract'
import { fieldsString, conjugeFieldsString, valuesFormatToDate } from '../../contractFields'
import MinutaMoldSelect from '../../MinutaMoldSelect'
import { useProposal, useResources, useStep } from 'hooks'
import { proposals } from 'egi/consumers'
import { validateResponse } from 'utils/validate'
import swal from 'utils/swal'
import time, { correctTimezone, timeAsDayjs } from 'utils/time'
import format from 'utils/format'
import { useDispatch } from 'react-redux'
import { stepForm } from 'store/modules/step/actions'
import Datepicker from 'components/Datepicker/Datepicker'
import { openTypeDrawer } from 'components/SimulationCard/types'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'

interface ICompositorStepValues {
  [key: string]: any
  hasCompositor?: boolean
  incluir_conjuge: boolean
  data_de_nascimento: Date
  nome: string
  email: string
  nacionalidade: string
  filiacao: string
  profissao: string
  estado_civil: string
  identidade: string
  CPF: string
  CEP: string
  pais: string
  logradouro: string
  numero_residencial: string
  complemento_residencial: string
  bairro: string
  UF: string
  municipio: string
  qualificacao: string
  qualificacao_slug: string
  nome_conjuge?: string
  email_conjuge?: string
  data_de_nascimento_conjuge?: Date
  nacionalidade_conjuge?: string
  filiacao_conjuge?: string
  filiacao_pai_conjuge?: string
  profissao_conjuge?: string
  identidade_conjuge?: string
  CPF_conjuge?: string
}

type Props = {
  readOnly?: boolean
  slug: string
  openSimulationDrawer?: ({ type }: {
    type: openTypeDrawer;
  }) => void
}

function CompositorStep ({ readOnly, slug, openSimulationDrawer }: Props) {
  const [errors, setErrors] = useState<IOnidataContractErrors>({})
  const [hasConjuge, setHasConjuge] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [minutaMoldText, setMinutaMoldText] = useState<string | undefined>(undefined)

  const resources = useResources()
  const step = useStep()
  const dispatch = useDispatch()
  const proposal = useProposal()

  const [formRef] = Form.useForm()
  const minutaRef: MutableRefObject<{minutaText: string}> = useRef({ minutaText: '' })

  const onSubmit = async (values: ICompositorStepValues) => {
    setLoading(true)

    try {
      if (!proposal.id) throw new Error('Falha ao encontrar id da proposta')
      if (!step.form) throw new Error('Falha ao encontrar form')
      if (!step.id) throw new Error('Falha ao encontrar step id')
      if (!step.areaId) throw new Error('Falha ao encontrar area Id')
      if (!minutaRef.current.minutaText && !readOnly) throw new Error('Texto de qualificação do compositor não informado!')

      valuesFormatToDate.forEach(item => {
        if (values[item]) values[item] = time(correctTimezone(values[item]))
      })

      if (values.CPF) values.CPF = format.onlyDigits(values.CPF)
      if (minutaRef.current.minutaText) values.qualificacao = minutaRef.current.minutaText

      const props: any = {
        form: {
          ...step.form,
          [slug]: { ...step.form[slug], ...values },
          finished: false,
          currentStep: 3
        },
        proposalId: proposal.id,
        areaId: step.areaId,
        stepId: step._id
      }

      const response = await proposals.send(props)
      dispatch(stepForm({ data: { [slug]: { ...values } } }))
      setErrors({})
      swal.basic({ title: 'Sucesso!', text: response.message, icon: 'success' })
    } catch (err) {
      if (err.data && err.data.invalid) {
        setErrors(validateResponse(err.data?.invalid))
        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
        setLoading(false)
        return
      }

      if (err?.data?.maxDeadline !== undefined && err?.data?.maxDeadline !== null) {
        swal.confirm({
          icon: 'warning',
          title: 'Atenção',
          html: err.message,
          confirmButtonText: 'Refazer simulação',
          showCancelButton: false,
          confirm: () => {
            if (openSimulationDrawer) openSimulationDrawer({ type: 'simulator' })
          }
        })
      }

      setErrors({})
      setLoading(false)
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    }
    setLoading(false)
  }

  useEffect(() => {
    async function onFill () {
      try {
        const tempForm = step.form && step.form[slug] ? JSON.parse(JSON.stringify(step.form[slug])) : {}

        valuesFormatToDate.forEach(item => {
          if (tempForm[item]) tempForm[item] = timeAsDayjs(tempForm[item], { applyTimezone: false }).format('DD/MM/YYYY')
        })

        if (tempForm.incluir_conjuge === true) setHasConjuge(true)

        if (tempForm.qualificacao) {
          setMinutaMoldText(tempForm.qualificacao)
          minutaRef.current.minutaText = tempForm.qualificacao
        }

        if (formRef) formRef.setFieldsValue({ ...tempForm })
      } catch (err) {
        swal.basic({ title: 'Atenção!', text: err.message, icon: 'warning' })
      }
    }

    if (step.form && step.form[slug]) onFill()
  }, [])

  return (
    <Fragment>
      <Form
        form={formRef}
        layout='vertical'
        onFinish={onSubmit}
        className="form-contract"
      >
        <Col span={24}>
          <Row gutter={[15, 15]} align="top">
            <Col lg={8} md={24} sm={24} xs={24}>
              <Form.Item
                name='data_de_nascimento'
                label={<label>Data nascimento</label>}
                help={errors.compositor_data_de_nascimento && (errors.compositor_data_de_nascimento)}
                validateStatus={errors.compositor_data_de_nascimento && ('error')}
              >
                <Datepicker
                  id={'test-form-item-compositor_data_de_nascimento'}
                  disabled={readOnly}
                  locale={locale}
                />
              </Form.Item>
            </Col>

            {fieldsString('').map(item => (
              <Col lg={8} md={24} sm={24} xs={24} key={item.id}>
                <ChooseContractComponent
                  errors={errors}
                  item={item}
                  type={item.type || ''}
                  readOnlyInput={readOnly}
                  component={(
                    <Form.Item
                      name={item.field}
                      label={<label>{item.label}</label>}
                      help={errors[item.field] && (errors[item.field])}
                      validateStatus={errors[item.field] && ('error')}
                    >
                      <Input
                        readOnly={readOnly}
                        id={'test-form-item-' + item.field}
                        placeholder={'Digite o(a) ' + item.label}
                        type="text"
                        error={errors[item.field]}
                        mask={item.mask || ''}
                      />
                    </Form.Item>
                  )}
                />
              </Col>
            ))}

            <Col lg={8} md={24} sm={24} xs={24}>
              <Form.Item
                name='incluir_conjuge'
                valuePropName="checked"
                label={<label></label>}
                help={errors.compositor_conjuge && errors.compositor_conjuge}
                validateStatus={errors.compositor_conjuge && ('error')}
              >
                <Checkbox
                  disabled={readOnly}
                  onChange={value => setHasConjuge(value.target.checked) }
                  id="test-form-item-hasCompositor"
                >
                  Incluir cônjuge ?
                </Checkbox>
              </Form.Item>
            </Col>

            <Row align="bottom" className="w-100" gutter={[15, 15]}>
              <Col lg={24} md={24} sm={24} xs={24} className="pr-0">
                <MinutaMoldSelect
                  selectSlug={{ name: 'qualificacao_slug', label: 'Qualificação' }}
                  text={minutaMoldText}
                  placeholder="Selecione a qualificação"
                  readOnlySelect={readOnly}
                  qualification={resources.minutaMold}
                  type="qualificacao"
                  setText={(value: string) => { minutaRef.current.minutaText = value }}
                  errors={errors.qualificacao_slug}
                  formRef={formRef}
                />
              </Col>
            </Row>

            {hasConjuge && (
              <Col span={24}>
                <Typography.Paragraph className="bold">
                  CÔNJUGE
                </Typography.Paragraph>

                <Row gutter={[15, 15]}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <Form.Item
                      name='data_de_nascimento_conjuge'
                      label={<label>Data nascimento do cônjuge</label>}
                      help={errors.compositor_data_de_nascimento_conjuge && (errors.compositor_data_de_nascimento_conjuge)}
                      validateStatus={errors.compositor_data_de_nascimento_conjuge && ('error')}
                    >
                      <Datepicker disabled={readOnly} locale={locale} />
                    </Form.Item>
                  </Col>

                  {conjugeFieldsString('').map(item => (
                    <Col lg={8} md={24} sm={24} xs={24} key={item.id}>
                      <ChooseContractComponent
                        errors={errors}
                        item={item}
                        type={item.type || ''}
                        readOnlyInput={readOnly}
                        component={(
                          <Form.Item
                            name={item.field}
                            label={<label>{item.label}</label>}
                            help={errors[item.field] && (errors[item.field])}
                            validateStatus={errors[item.field] && ('error')}
                          >
                            <Input
                              id={`test-form-item-${item.field}`}
                              readOnly={readOnly}
                              placeholder={'Digite o(a) ' + item.label + ' do cônjuge'}
                              type="text"
                              error={errors[item.field]}
                              mask={item.mask || ''}
                            />
                          </Form.Item>
                        )}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            )}

            <Col span={24}>
              <Address
                title="ENDEREÇO"
                name=''
                readOnlyInput={readOnly}
                onCepBlur={(address, remainingAddress) => formRef.setFieldsValue({ address: { ...address, ...remainingAddress } })}
                formRef={formRef}
                errors={errors}
              />
            </Col>
          </Row>

          <Row justify="end">
            <Col>
              <Button
                loading={loading}
                className="mt-4 text-center w-100 color-white uppercase"
                type="primary"
                htmlType="submit"
                disabled={readOnly}
              >
                Salvar formulário
              </Button>
            </Col>
          </Row>
        </Col>
      </Form>
    </Fragment>
  )
}

export default CompositorStep

import React, { Fragment, lazy, Suspense, useEffect, useReducer, useState, useLayoutEffect, useMemo } from 'react'
import { Upload, Modal, Button, Spin } from 'antd'
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface'
import pop from 'utils/notification'
import axios from 'axios'
import { useStep } from 'hooks'
import swal from 'utils/swal'
import { Props, ImagePreview, State } from './types'
import { downloadFile, getBase64 } from 'utils/download'
import { IDocuments } from 'egi/types'
import { _preview } from 'globals'
import { UploadRequestOption } from 'rc-upload/lib/interface'
import UploadButton from './components/UploadButton/UploadButton'
import UploadSkeleton from './components/UploadSkeleton/UploadSkeleton'

export const previewFileTypes = [
  'application/pdf',
  'image/png',
  'image/jpeg',
  'image/jpg',
  'image/jfif'
]

const initialState: State = {
  previewVisible: false,
  previewImage: '',
  previewTitle: '',
  previewType: '',
  fileList: [],
  documents: [],
  paths: [],
  uidUrl: {}
}

type Action =
  | { type: 'SET_PREVIEW'; newPreview: ImagePreview }
  | { type: 'SET_FILE_LIST'; newList: DocumentUploadProps[] }
  | { type: 'SET_UID_AND_URL_LIST'; newState: any }
  | { type: 'SET_DOCUMENTS'; documents: Array<IDocuments> }
  | { type: 'SET_PATHS_DOCUMENTS'; paths: Array<string> };

function reducer (state: State, action: Action): State {
  switch (action.type) {
    case 'SET_PREVIEW':
      return { ...state, ...action.newPreview }
    case 'SET_FILE_LIST':
      return { ...state, fileList: action.newList }
    case 'SET_UID_AND_URL_LIST':
      return { ...state, uidUrl: action.newState }
    case 'SET_DOCUMENTS':
      return { ...state, documents: action.documents }
    case 'SET_PATHS_DOCUMENTS':
      return { ...state, paths: action.paths }
    default:
      throw new Error()
  }
}

export interface DocumentUploadProps extends UploadFile<any> {
  alreadySent?: boolean;
}

export const getFileType = (value: string) => {
  const cndAuth = '?X-Amz-Algorithm'
  if (value.includes(cndAuth)) value = value.substring(0, value.indexOf(cndAuth))

  const ext = value
    .match(/\.[0-9a-z]+$/i)
    ?.toString()
    .replace('.', '')
  if (!ext || ext == null) return 'none'

  return previewFileTypes.filter((i) => i.endsWith(ext))[0] || `application/${ext}`
}

function DocumentUpload ({
  onFileDelete,
  onFilesChange,
  onFileUpload,
  hideUploadButton,
  unauthId,
  maxDocs,
  paths,
  getDocuments,
  loading,
  skeletonQuantity = 3,
  rebuild,
  isStep = true,
  otherFiles
}: Props) {
  // Redux
  const step = useStep()

  // States
  const [state, dispatch] = useReducer(reducer, initialState)
  const [documentPath, setDocumentPath] = useState<IDocuments[]>([])

  const { previewVisible, previewImage, fileList, previewTitle, previewType } = state
  const isPdf = useMemo(() => previewType === 'application/pdf', [previewType])
  const PdfPreview = lazy(() => import('../PdfPreview/PdfPreview'))

  useLayoutEffect(() => {
    if (!unauthId) setDocumentPath(paths)
  }, [paths])

  const handleCancel = () => dispatch({ type: 'SET_PREVIEW', newPreview: { previewVisible: false } })

  const handlePreview = async (file: UploadFile<FileReader>) => {
    if (!file) return

    const fileType = file.type
    const fileUrl = String(file.url)

    if (!previewFileTypes.includes(fileType)) {
      if (fileUrl) downloadFile({ fileUrl: fileUrl, title: file.name || fileUrl.substring(fileUrl.lastIndexOf('/') + 1) })
      return pop({
        title: 'Preview indisponível',
        text: 'A preview não é compatível com este arquivo.'
      })
    }

    if (!file.originFileObj) throw new Error('no file')
    file.preview = await getBase64(file.originFileObj)

    dispatch({
      type: 'SET_PREVIEW',
      newPreview: {
        previewImage: file.preview,
        previewVisible: true,
        previewTitle: file.name || fileUrl.substring(fileUrl.lastIndexOf('/') + 1),
        previewType: (file.type as _preview)
      }
    })
  }

  const handleChange = ({ fileList }: UploadChangeParam<UploadFile>) => {
    if (hideUploadButton) {
      return swal.basic({
        title: 'Atenção',
        text: 'Não é possivel deletar um arquivo de um cliente',
        icon: 'warning'
      })
    }

    dispatch({ type: 'SET_FILE_LIST', newList: fileList })

    if (onFilesChange && unauthId) {
      const allContentFn: any = {}
      allContentFn[unauthId] = fileList.length
      onFilesChange(allContentFn)
    }
  }

  const handleFileUpload = async (request: UploadRequestOption) => {
    if (state.fileList.length > maxDocs) {
      return swal.basic({
        title: 'Atenção',
        text: 'Número máximo de arquivos atingido!',
        icon: 'warning'
      })
    }

    onFileUpload(request)
    const newState = { ...state.uidUrl, [request.file.uid]: request.file }
    dispatch({ type: 'SET_UID_AND_URL_LIST', newState })
  }

  const handleDelete = (file: UploadFile<any>) => {
    const swalText = `Não é possível deletar um arquivo de ${isStep ? 'um cliente!' : 'outra área!'}`
    if (hideUploadButton) {
      return swal.basic({
        title: 'Atenção',
        text: swalText,
        icon: 'warning'
      })
    }

    return new Promise<boolean>((resolve) => {
      swal.confirmNegate({
        icon: 'question',
        text: 'O arquivo será excluido desse passo.',
        title: 'Deseja excluir esse arquivo ?',
        confirm: async () => {
          if (!file.error && (file.url || file?.response?.data?.file || file?.response?.data?.data?.file)) {
            const url = file.url ?? file.response?.data?.file ?? file.response?.data?.data?.file
            onFileDelete(url, resolve)
          }
        }
      })
    })
  }

  useEffect(() => {
    if (step?.documents) {
      const tempPaths: Array<string> = []

      const documentsAux = step?.documents
      if (documentsAux && Array.isArray(documentsAux)) {
        documentsAux?.forEach((item: IDocuments) => {
          item.path.forEach((item: string) => {
            tempPaths.push(item)
          })
        })
      }

      dispatch({
        type: 'SET_PATHS_DOCUMENTS',
        paths: tempPaths
      })
    }
  }, [step, step._id])

  const getFileType = (value: string) => {
    const cndAuth = '?X-Amz-Algorithm'
    if (value.includes(cndAuth)) value = value.substring(0, value.indexOf(cndAuth))

    const ext = value
      .match(/\.[0-9a-z]+$/i)
      ?.toString()
      .replace('.', '')
    if (!ext || ext == null) return 'none'

    return previewFileTypes.filter((i) => i.endsWith(ext))[0] || `application/${ext}`
  }

  const getFileName = (value: string) => {
    const parts = value.split('-')
    const size = parts.length

    return `${parts[size - 2]}-${parts[size - 1]}`
  }

  const getImages = async () => {
    const files: Array<DocumentUploadProps> = []
    if (!documentPath) return
    const paths = (documentPath) ? documentPath[0].path : documentPath[0] ? documentPath[0] : []
    if (!paths.length) return

    for (let i = 0; i < paths.length; i++) {
      try {
        const res = await axios({ url: paths[i], responseType: 'blob' })
        const fileUrlPaths = paths[i].split('/')
        const filename = fileUrlPaths[fileUrlPaths.length - 1]
        const file: DocumentUploadProps = {
          uid: `old-${i}`,
          name: getFileName(filename),
          status: 'done',
          size: res.data.size,
          type: getFileType(filename),
          url: paths[i],
          alreadySent: true,
          originFileObj: new File([res.data], filename, {
            type: getFileType(filename)
          })
        }
        files.push(file)
      } catch (err) {
        console.log(err)
      }
    }

    dispatch({
      type: 'SET_FILE_LIST',
      newList: files
    })
  }

  useEffect(() => {
    setDocumentPath([])
    getDocuments()
  }, [rebuild])

  useEffect(() => {
    if (documentPath?.length) {
      const paths = documentPath ? documentPath[0].path : []
      if (paths && paths.length) getImages()
    }

    dispatch({ type: 'SET_FILE_LIST', newList: [] })
  }, [documentPath])

  function sendRules () {
    const hideButtonRules = (fileList.length >= maxDocs) || hideUploadButton
    if (hideButtonRules) return null
    return <UploadButton/>
  }

  return (
    <Fragment>
      <Modal
        className="modal-config"
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        width='60vw'
        onCancel={handleCancel}
        centered
      >
        <Fragment>
          <div className="flex flex-align-center flex-justify-center">
            <Button
              className="text-center mb-3"
              type="primary"
              onClick={() => downloadFile({ fileUrl: state.previewImage, title: state.previewTitle })}
            >
              Baixar arquivo
            </Button>
          </div>

          {isPdf ? (
            <Suspense fallback={<Spin />}>
              <PdfPreview src={previewImage} />
            </Suspense>
          ) : (<img className="upload-img-size" alt='Documento inserido na plataforma' src={previewImage} />)}
        </Fragment>
      </Modal>

      {loading ? (
        <UploadSkeleton quantity={skeletonQuantity} />
      ) : (
        <Upload
          id="test-form-upload"
          listType="picture"
          accept={otherFiles ? [...previewFileTypes, ...otherFiles].toString() : previewFileTypes.toString()}
          customRequest={handleFileUpload}
          onChange={handleChange}
          onRemove={handleDelete}
          fileList={fileList}
          onPreview={handlePreview}
          className="upload-list-inline"
        >
          {sendRules()}
        </Upload>
      )}
    </Fragment>
  )
}

export default DocumentUpload

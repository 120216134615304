import { IReduxModal, ReduxModalActions } from './types'

const INITIALSTATE: IReduxModal = {
  visible: false,
  title: '',
  type: '',
  className: '',
  hasFooter: true,
  creditReservation: undefined,
  width: '50',
  departmentName: undefined,
  outsideClick: true
}

const modal = (state = INITIALSTATE, action: ReduxModalActions) => {
  switch (action.type) {
    case 'MODAL_FILL_VALUES': {
      return {
        ...state,
        ...action.payload
      }
    }

    case 'MODAL_MANAGE_VISIBLE': {
      return {
        ...state,
        ...action.payload
      }
    }

    case 'MODAL_RESET_ALL': {
      return INITIALSTATE
    }

    default:
      return state
  }
}

export default modal


import { AxiosRequestConfig } from 'axios'
import dayjs from 'dayjs'
import OperationsECPFRepository, { IDebitBalanceResponse, IDueCPF, IGetCOntractsECPFParams, IMergedDueAndDebitBalanceECPF, IParcelDebitBalanceECPF } from 'ecpf/repositories/OperationsECPFRepository'
import ProposalECPFRepository, { IAnticipationSimulationPayload, IAnticipationSimulationResponse, IGenerateTicketPayload, IGenerateTicketResponse } from 'ecpf/repositories/ProposalECPFRepository'

export interface IGetDebitBalanceFromDueListResponse {
  dueList: IDueCPF[] | null
  debitBalance: IDebitBalanceResponse | undefined
  mergedDueAndDebitBalance: IMergedDueAndDebitBalanceECPF[] | undefined
}

export interface IGetDebitBalanceProps {
  numeroOperacao?: string
  dataReferencia?: string
  parcelaInicial?: number
  parcelaFinal?: number
}
export class DueListNotFound extends Error {
  name = 'DUE_LIST_NOT_FOUND'
  message = 'Parcelas não encontradas.'
  code = 404
}

export class DebitBalanceNotFound extends Error {
  name = 'DEBIT_BALANCE_NOT_FOUND'
  message = 'Saldo devedor não encontrado.'
  code = 404
}

export class DebitBalanceAndDueAreNotEqual extends Error {
  name = 'DEBIT_BALANCE_AND_DUE_ARE_NOT_EQUAL'
  message = 'O saldo devedor e as parcelas não são equivalentes.'
  code = 404
}

export class AnticipationSimulationNotFound extends Error {
  name = 'ANTICIPATION_SIMULATION_NOT_FOUND'
  message = 'Simulação de antecipação não encontrada.'
  code = 404
}

export class GenerateTicketFailed extends Error {
  name = 'GENERATE_TICKET_FAILED'
  message = 'Falha ao gerar boleto.'
  code = 404
}

class ContractsNotFound extends Error {
  name = 'CONTRACTS_NOT_FOUND'
  message = 'Contratos não encontrados.'
  data = { erros: [{ codigo: null, mensagem: 'Contratos não encontrados.' }] }
}

function mergeDueAndDebitBalance (
  dueList: IDueCPF[],
  debitBalanceList: IParcelDebitBalanceECPF[]
): IMergedDueAndDebitBalanceECPF[] {
  if (dueList.length !== debitBalanceList.length) {
    throw new DebitBalanceAndDueAreNotEqual()
  }

  return dueList.map(dueItem => {
    const dueDate = dayjs(dueItem.dataVencimentoParcela).format('YYYY-MM-DD')
    const debitBalanceItem = debitBalanceList.find(
      item => item.dataVencimento === dueDate
    )

    if (!debitBalanceItem) {
      throw new DebitBalanceAndDueAreNotEqual()
    }

    return {
      ...dueItem,
      ...debitBalanceItem,
      numero: dueItem.numero,
      numeroSaldo: debitBalanceItem.numero
    }
  })
}

export const mockAnticipationSimulationResponse: IAnticipationSimulationResponse = {
  dataBase: '2024-08-21T00:00:00',
  dataPrimeiroVencimento: '2024-08-21T17:05:23',
  dataUltimoVencimento: '2024-08-21T17:05:23',
  quantidadeParcelas: 1,
  valorFinanciado: 87.44,
  fluxoParcelas: [
    {
      numeroParcela: 1,
      vencimento: '2024-08-21T17:05:23',
      valorParcela: 87.44,
      valorDespesa: 0,
      valorTarifa: 0
    }
  ],
  valorParcela: 87.44,
  valorPrincipal: 87.44,
  valorBruto: 87.44,
  valorLiquido: 87.44,
  taxaClienteMes: 0,
  taxaClienteAno: 0,
  taxaApMes: 0,
  taxaApAno: 0,
  taxaCetMes: 0,
  taxaCetAno: 0,
  taxaNominalMes: 0,
  taxaNominalAno: 0
}

export class OperationService {
  async getDueList (props: {numeroOperacao: string}): Promise<Array<IDueCPF> | null> {
    const { numeroOperacao } = props
    const queryParams = {
      numeroOperacao
    }

    const response = await OperationsECPFRepository.getDueList({ params: queryParams })
    const operacoes = response?.data?.data?.operacoes || []

    const hasDueList = operacoes && Array.isArray(operacoes) && operacoes.length > 0
    if (!hasDueList) {
      throw new DueListNotFound()
    }

    return operacoes
  }

  async getDebitBalance (props: IGetDebitBalanceProps): Promise<IDebitBalanceResponse | undefined> {
    const { numeroOperacao, dataReferencia, parcelaFinal, parcelaInicial } = props

    const queryParams = {
      numeroOperacao,
      dataReferencia,
      parcelaInicial,
      parcelaFinal
    }

    const response = await OperationsECPFRepository.getDebitBalance({ params: queryParams })
    const data = response?.data?.data

    if (data === undefined || data === null) {
      throw new DebitBalanceNotFound()
    }

    return data
  }

  async getDebitBalanceFromDueList (props: IGetDebitBalanceProps): Promise<IGetDebitBalanceFromDueListResponse> {
    if (!props.numeroOperacao) {
      throw new Error('Número da operação não encontrado')
    }

    const dueList = await this.getDueList({ numeroOperacao: props.numeroOperacao })
    if (!dueList || dueList.length === 0) {
      throw new DueListNotFound()
    }

    const firstDueDateOrSystemDate = dayjs(dueList[0]?.dataVencimentoParcela).isAfter(dayjs())
      ? dayjs()
      : dayjs(dueList[0]?.dataVencimentoParcela)

    const debitBalance = await this.getDebitBalance({
      numeroOperacao: props.numeroOperacao,
      dataReferencia: firstDueDateOrSystemDate.format('DD-MM-YYYY')
    })

    const mergedDueAndDebitBalance = mergeDueAndDebitBalance(dueList, debitBalance?.parcelas || [])

    return {
      dueList,
      debitBalance,
      mergedDueAndDebitBalance
    }
  }

  async anticipationSimulation (
    data: IAnticipationSimulationPayload,
    config?: AxiosRequestConfig
  ): Promise<IAnticipationSimulationResponse> {
    const response = await ProposalECPFRepository.anticipationSimulation(data, config)
    if (!response.data.data) throw new AnticipationSimulationNotFound()
    return response?.data?.data
  }

  async generateTicket (
    data: IGenerateTicketPayload,
    config?: AxiosRequestConfig
  ): Promise<IGenerateTicketResponse> {
    const response = await ProposalECPFRepository.generateTicket(data, config)
    if (!response.data.data) throw new GenerateTicketFailed()
    return response.data.data
  }

  async getContracts (params: IGetCOntractsECPFParams) {
    const response = await OperationsECPFRepository.getContracts({ params })
    const contracts = response?.data?.data?.operacoes
    if (!contracts) {
      throw new ContractsNotFound()
    }

    return contracts
  }
}

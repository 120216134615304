import { IAuth } from 'egi/types'
import { ILayoutReducer } from 'store/modules/layout/layoutReducer'
import { isLocal } from 'utils/globals'
import { _userLevel } from './UsersModel'

export type _productSlugs = 'home-equity' | 'ecp' | 'egi' | 'ecp-funcao'

export interface IProduct {
  _id: string
  corbanId: string
  name: string
  shortenName: string
  icon: string
  slug: _productSlugs
  description: string
  active: boolean
  sub: IProducSub[]
}

export interface IProducSub {
  _id: string
  name: string
  active: boolean
}

const ProductModel = {
  isEcpProduct (item: IProduct): boolean {
    return item?.slug === 'ecp'
  },

  isEcpFuncaoProduct (item: Partial<IProduct>): boolean {
    return item?.slug === 'ecp-funcao'
  },

  canAccessClientEcpfProfile (item: Partial<IProduct>, level: IAuth['level']): boolean {
    return this.isEcpFuncaoProduct(item) && level === 'client'
  },

  canShowSkipFullScreenError (): boolean {
    return isLocal
  },

  canShowAuthFullLayout (products: IProduct[], selectedProduct: IProduct): boolean {
    if (!this.hasEcpInProducts(products)) return false
    if (!this.isEcpProduct(selectedProduct)) return false
    return true
  },

  canShowCredentialsMessage (products: IProduct[], selectedProduct: IProduct, layoutReducer: ILayoutReducer): boolean {
    return this.canShowAuthFullLayout(products, selectedProduct) && layoutReducer.status === 'notWorking'
  },

  hasEcpInProducts (products: IProduct[]): boolean {
    return Boolean(products.find(this.isEcpProduct))
  },

  canSeeUnicoCommunication (product: IProduct): boolean {
    return this.isEcpProduct(product)
  },

  mustSignLGPDTerm (userLevel: _userLevel, selectedProduct: Partial<IProduct>, status: string = '') {
    return userLevel === 'client' && selectedProduct.slug === 'ecp' && status !== 'assinado'
  },

  canSeeProductsSelect (products: IProduct[]) {
    return products.length > 1
  }
}

export default ProductModel

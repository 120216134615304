import React from 'react'

type Props = {
  onClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void
  label: string
  backgroundLess?: boolean
}

function PlusButton ({ label, onClick, backgroundLess = false }: Props) {
  return (
    <div className="plus-button" onClick={onClick}>
      <label>{label}</label>
      <div
        className={`plus-button__button-container ${backgroundLess ? 'plus-button__button-container--background-less' : ''}`}
      >
        <div
          className={`plus-button__button-circle ${backgroundLess ? 'plus-button__button-circle--background-less' : ''}`}
        >
          <h1 className="plus-button__plus-icon">+</h1>
        </div>
      </div>
    </div>
  )
}

export default PlusButton

import { Button, Result } from 'antd'
import { EcpCard } from 'ecp/components/EcpCard/EcpCard'
import React from 'react'

const ErrorFallback = () => {
  const handleReload = () => {
    window.location.reload()
  }
  return (
    <EcpCard className='flex w-100 flex-column flex-justify-center flex-align-center pb-5'>
      <Result
        title='Ops, que algo deu errado.'
        status='warning'
        subTitle='Ocorreu um problema ao carregar a página, verifique a sua conexão com a internet e tente novamente.'
      />

      <div>
        <Button type='primary' onClick={handleReload}>
          Recarregar
        </Button>
      </div>
    </EcpCard>
  )
}

export default ErrorFallback

import loadable from '@loadable/component'
import Loading from 'components/Loading/Loading'
import { timeout } from 'promise-timeout'
import React from 'react'
import { TIMEOUT_LOADER } from 'utils/globals'

export const PdfPreviewLoader = loadable(
  () => timeout(import(/* webpackChunkName: "PdfPreview" */ './PdfPreview'), TIMEOUT_LOADER),
  { fallback: <Loading /> }
)

import { InfoCircleOutlined } from '@ant-design/icons'
import { Skeleton, Tooltip } from 'antd'
import React, { ReactNode } from 'react'

function TooltipInfo ({ message }: { message?: string }) {
  if (!message) return <></>

  return (
    <Tooltip title={message}>
      <InfoCircleOutlined/>
    </Tooltip>
  )
}

export interface IDetailsItem {
  label: React.ReactNode
  value: string | ReactNode
  tooltip?: string
  detailsItemClassName?: string
  loading?: boolean
}

function DetailsItem ({ label, value, tooltip, detailsItemClassName, loading = false } : IDetailsItem) {
  const className = detailsItemClassName || 'details-item'

  return (
    <div className={className}>
      <Tooltip title={label}>
        <p className={`${className}__label`}>{label} <TooltipInfo message={tooltip}/></p>
      </Tooltip>

      {loading
        ? <Skeleton paragraph={{ rows: 0 }} active className='details-item__skeleton' />
        : (
          <Tooltip title={value}>
            {typeof value === 'string' ? <p className={`${className}__value`}>{value}</p> : value}
          </Tooltip>
        )
      }
    </div>
  )
}

export interface IDetailsItemTitle {
  className?: string
}

function DetailsItemSectionTitle ({ children, ...rest }: { children: React.ReactNode }) {
  return <h3 className='details-item-section-title' {...rest}>{children}</h3>
}

DetailsItem.SectionTitle = DetailsItemSectionTitle

export default DetailsItem

import React, { useEffect, useState } from 'react'
import { Empty, Select } from 'antd'
import { ALL_RESOURCE } from '../../proposalConstants'
import { AxiosRequestConfig } from 'axios'
import ResourcesRepository, { IResourceWithFantasyName } from 'ecp/repositories/ResourcesRepository'
import { ISelectProps } from 'components/Select/Select'

export const formatToSelect = (resourceValue : ResourceValue) => (item: IResourceWithFantasyName) => ({
  value: item[resourceValue],
  label: item.fantasyName
})

type ResourceValue = '_id' | 'cnpj'

interface Props extends ISelectProps {
  corbanId?: string
  resourceValue?: ResourceValue
  hasAllResource?: boolean
}

export default function SubCorbanSelect ({ hasAllResource = true, corbanId, resourceValue = '_id', disabled, ...rest }: Props) {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [subcorbans, setSubcorbans] = useState<{ value: string, label: string }[]>([])
  const [options, setOptions] = useState<{ value: string, label: string }[]>([])

  useEffect(() => {
    const abortController = new AbortController()
    async function fetchSubCorbans (corbanId?: string) {
      try {
        setLoading(true)

        const params = { corbanId }

        const requestConfig: AxiosRequestConfig = {
          signal: abortController.signal,
          params
        }

        const response = await ResourcesRepository.subcorbans(requestConfig)
        const subcorbans = response.data.data?.subcorbans

        if (!subcorbans?.length) {
          setSubcorbans([])
          throw new Error('Nenhum Sub-corban encontrado')
        }

        const formattedValues = (subcorbans || []).map(formatToSelect(resourceValue))

        setSubcorbans(formattedValues)
        setLoading(false)
      } catch (err) {
        if (!abortController.signal.aborted) {
          setErrorMessage(err?.message)
          setLoading(false)
        }
      }
    }

    fetchSubCorbans(corbanId)
    return () => abortController.abort()
  }, [corbanId])

  useEffect(() => {
    if (hasAllResource) setOptions([ALL_RESOURCE, ...subcorbans])
    else setOptions(subcorbans)
  }, [subcorbans])

  return (
    <Select
      {...rest}
      allowClear
      disabled={loading || disabled}
      loading={loading}
      getPopupContainer={trigger => trigger.parentNode}
      showSearch
      optionFilterProp="children"
      notFoundContent={
        errorMessage && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={errorMessage} />
      }
    >
      {options.map((item, idx) => (
        <Select.Option
          value={item.value}
          key={item.value + (idx)}
        >
          {item.label}
        </Select.Option>
      ))}
    </Select>
  )
}

import PricingCreate from './PricingCreate/PricingCreate'
import PricingProducts from './PricingProducts/PricingProducts'
import PricingTable from './PricingTable/PricingTable'
import ExclusiveFees from '../../ExclusiveFees/views/ExclusiveFees'

export default {
  PricingCreate,
  PricingProducts,
  PricingTable,
  ExclusiveFees
}

import { IReduxProposalFlow, ProposalFlowTypes } from './actions'

const initial_state: IReduxProposalFlow = {
  customerFlow: undefined,
  extraCustomerFlow: undefined,
  clientCovenant: undefined,
  chosenCovenant: undefined,
  simulationFlow: undefined,
  client: undefined,
  tokenValidationFlowEcpf: undefined
}

const proposalFlow = (state = initial_state, action: any) => {
  switch (action.type) {
    case ProposalFlowTypes.SET_CLIENT_INFO: {
      return {
        ...state,
        client: action.payload.client
      }
    }

    case ProposalFlowTypes.SET_CUSTOMER_FLOW: {
      return {
        ...state,
        customerFlow: action.payload.customerFlow
      }
    }

    case ProposalFlowTypes.SET_CLIENT_COVENANT: {
      return {
        ...state,
        clientCovenant: action.payload.clientCovenant
      }
    }

    case ProposalFlowTypes.SET_SIMULATION_FLOW: {
      return {
        ...state,
        simulationFlow: action.payload.simulationFlow
      }
    }

    case ProposalFlowTypes.SET_CHOSEN_COVENANT: {
      return {
        ...state,
        choosenCovenant: action.payload.choosenCovenant
      }
    }

    case ProposalFlowTypes.SET_EXTRA_CUSTOMER_DATA: {
      return {
        ...state,
        extraCustomerFlow: action.payload.extraCustomerData
      }
    }

    case ProposalFlowTypes.SET_TOKEN_VALIDATION_FLOW_ECPF: {
      return {
        ...state,
        tokenValidationFlowEcpf: action.payload.tokenValidationFlowEcpf
      }
    }

    case ProposalFlowTypes.SET_SIMULATION_FLOW_MIN_MAX: {
      return {
        ...state,
        simulationFlowMinMax: action.payload.simulationFlowMinMax
      }
    }

    case ProposalFlowTypes.SET_PORTABILITY_SIMULATION_FLOW: {
      return {
        ...state,
        portabilitySimulationFlow: action.payload.portabilitySimulationFlow
      }
    }

    case ProposalFlowTypes.RESET_SIMULATION_FLOW: {
      return {
        ...state,
        simulationFlow: undefined
      }
    }

    case ProposalFlowTypes.RESET_CLIENT_COVENANT: {
      return {
        ...state,
        clientCovenant: undefined
      }
    }

    case ProposalFlowTypes.RESET_SIMULATION_FLOW_MIN_MAX: {
      return {
        ...state,
        simulationFlowMinMax: undefined
      }
    }

    case ProposalFlowTypes.SET_SELECT_CONTRACTS_FLOW: {
      return {
        ...state,
        selectContractsFlow: action.payload.selectContractsFlow
      }
    }

    case ProposalFlowTypes.RESET_PROPOSAL_FLOW: {
      return initial_state
    }

    default:
      return state
  }
}

export default proposalFlow


import { DeleteOutlined } from '@ant-design/icons'
import { Drawer, Empty, Skeleton, Timeline } from 'antd'
import { _userLevel } from 'ecp/models/UsersModel'
import React from 'react'
import { timeAsDayjs } from 'utils/time'
import translate from 'utils/translate'
import SettingsCard from '../SettignsCard/SettingsCard'

export interface ISettignsHistory {
  createdAt: string
  modifiedBy: { _id: string, name: string, level: _userLevel }
  new: { newText: string, deleted?: boolean }
  _id: string
}

interface IProps {
  history?: ISettignsHistory[]
  visible: boolean,
  onChangeVisible: () => void
  historyLoading: boolean
}

export const HistoryLastModified = ({ updatedAt, modifiedName, modifiedLevel }:{ updatedAt: string, modifiedName: string, modifiedLevel: _userLevel}) => {
  return <span>
    Alterado <b>{timeAsDayjs(updatedAt).format('DD/MM/YYYY [às] HH:mm')}</b>&nbsp;
  por&nbsp;<b>{modifiedName}</b>&nbsp;<b>({translate.level(modifiedLevel)})</b>
  </span>
}

const ShowHistory = ({ history, historyLoading }: {history: ISettignsHistory[] | undefined, historyLoading: boolean}) => {
  if (historyLoading) {
    return <Timeline>
      <Timeline.Item >
        <Skeleton/>
      </Timeline.Item>
    </Timeline>
  }
  if (history?.length) {
    return (
      <Timeline>
        {history?.map(item => {
          return (
            <Timeline.Item key={item._id}>
              <div className="mb-2">
                <HistoryLastModified updatedAt={item.createdAt}
                  modifiedLevel={item.modifiedBy.level}
                  modifiedName={item.modifiedBy.name}
                />
              </div>
              {item.new.deleted
                ? <div className='internal-review-settings__display-text internal-review-settings__deleted'>
                  <DeleteOutlined /> Texto deletado
                </div>
                : <div
                  dangerouslySetInnerHTML={{ __html: item.new.newText }}
                  className="internal-review-settings__display-text">
                </div>
              }
            </Timeline.Item>
          )
        })}
      </Timeline>
    )
  }

  return <div className="text-center">
    <Empty
      description="Não foi possível encontrar nenhum histórico de revisão para esse passo"
    />
  </div>
}

export default function SettingHistory ({ history, visible, onChangeVisible, historyLoading }: IProps) {
  return (
    <Drawer
      title="Histórico de alterações"
      className="settings-history"
      width={1000}
      destroyOnClose={true}
      onClose={onChangeVisible}
      visible={visible}>

      <SettingsCard.title>
        Histórico de alterações da revisão interna
      </SettingsCard.title>

      <SettingsCard.description className="mb-3">
        Confira aqui as ultimas alterações para as configurações do passo
      </SettingsCard.description>

      <ShowHistory
        history={history}
        historyLoading={historyLoading}
      />
    </Drawer>
  )
}

export const avaliationTooltip = 'Ao habilitar este campo, você poderá indicar um valor para substituir o valor de mercado (no empréstimo concedido e na minuta).'

export const PROPOSAL_EGI_INITIAL_FILTERS = {
  page: 1,
  limit: 15
}

export const proposalEgiFiltersKeysIgnore = [
  'search',
  'page',
  'limit',
  'download',
  'downloadSignatures',
  'searchUseFilters',
  'createdDate',
  'identifier',
  'sort'
]

export const proposalEgiFiltersEntriesToIgnore: {}[] = [

]

export const PROPOSAL_STATUS_RESOURCE = [
  { value: 'ongoing', label: 'Em andamento' },
  { value: 'approved', label: 'Aprovado' },
  { value: 'effectivated', label: 'Efetivado' },
  { value: 'reproved', label: 'Reprovado' },
  { value: 'canceled', label: 'Cancelado' },
  { value: 'inactive', label: 'Inativo' }
]

import React from 'react'
import { Col, Row } from 'antd'

import DetailsItem from 'ecp/components/DetailsItem/DetailsItem'
import { IOnidataClient } from 'ecp/models/ClientModel'

export function AddressFieldsDisplay ({ addressInfos }: { addressInfos: IOnidataClient }) {
  return (
    <Row gutter={[25, 25]}>
      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='CEP'
          value={addressInfos?.endereco?.cep ?? '-'}
        />
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='Logradouro'
          value={addressInfos?.endereco?.logradouro ?? '-'}
        />
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='UF'
          value={addressInfos?.endereco?.uf ?? '-'}
        />
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='Cidade'
          value={addressInfos?.endereco?.cidade ?? '-'}
        />
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='Bairro'
          value={addressInfos?.endereco?.bairro ?? '-'}
        />
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='Número'
          value={addressInfos?.endereco?.numero ?? '-'}
        />
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='Complemento (opcional)'
          value={addressInfos?.endereco?.complemento ?? '-'}
        />
      </Col>
    </Row>
  )
}

export default AddressFieldsDisplay

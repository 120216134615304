import { Pagination, Skeleton } from 'antd'
import ErrorDetails from 'components/ErrorDetails/ErrorDetails'
import { IErrorDetails } from 'components/ErrorDetails/interfaces'
import dayjs from 'dayjs'
import { daysLeftText, R$ } from 'ecp/app/Proposals/proposalFunctions'
import { EcpCard } from 'ecp/components/EcpCard/EcpCard'
import React, { ReactNode } from 'react'
import { timeAsDayjs } from 'utils/time'
import { usePendingUserCovenants } from '../../hooks/usePendingUserCovenants'
import { useTransferCovenants } from '../../hooks/useTransferCovenants'
import { IPendingUserCovenants, ITransferCovenant } from '../../rhInterfaces'

export type RHCovenantSelectProps = {
  textInfo: string
  onSelect: (id: string, name: string) => void
}

type PropsRHCovenantSelectView<T> = {
  loading: boolean
  error?: IErrorDetails
  onSelect: (id: string, name: string) => void
  covenants: T[]
}

const COVENANTS_SELECT_TRANSFER_COLUMNS = [
  'Empresas', 'Data para envio',
  'Valor Original', 'Valor Atual'
]

const COVENANTS_SELECT_PENDING_USER_COLUMNS = [
  'Empresa',
  'Total de Funcionários',
  'LGPD - Pendente de Verificação',
  'Com acesso'
]

function RHCovenantSelectTableHeader ({
  columns
}: { columns: Array<String> }) {
  return (
    <tr className='rh-covenant-select__table-header'>
      {(columns || []).map((column, idx) => (
        <th
          key={idx}
          className={`rh-covenant-select__table-title-item ${idx === 0 ? 'pl-4' : ''} `}
        >
          {column}
        </th>
      ))}
    </tr>
  )
}

const RHCovenantSelectViewLoading = ({ columns }: {
  columns: Array<String>
}) => {
  return (
    <div className='rh-covenant-select__table-container w-100 mt-2'>
      <table className='w-100 rh-covenant-select__table'>
        <RHCovenantSelectTableHeader columns={columns} />

        <>
          {new Array(3).fill('').map((_, idx) => (
            <tr className='rh-covenant-select__covenant-row' key={idx}>
              <td className='rh-covenant-select__row-item pl-4'>
                <Skeleton.Input className='w-50 rh-covenant-select__row-item-skeleton' active={true} />
              </td>
              <td className='rh-covenant-select__row-item'>
                <Skeleton.Input className='w-50 rh-covenant-select__row-item-skeleton' active={true} />
              </td>
              <td className='rh-covenant-select__row-item'>
                <Skeleton.Input className='w-50 rh-covenant-select__row-item-skeleton' active={true} />
              </td>
              <td className='rh-covenant-select__row-item'>
                <Skeleton.Input className='w-50 rh-covenant-select__row-item-skeleton' active={true} />
              </td>
            </tr>
          ))}
        </>
      </table>
    </div>
  )
}

const RHCovenantSelectViewError = ({ error, columns }: {
  error: PropsRHCovenantSelectView<ITransferCovenant>['error'],
  columns: Array<String>
}) => {
  if (!error) return null

  return (
    <div className='rh-covenant-select__table-container w-100 mt-2'>
      <table className='w-100 rh-covenant-select__table'>
        <RHCovenantSelectTableHeader columns={columns} />
      </table>

      <EcpCard className='rh-covenant-select__error-container'>
        <ErrorDetails
          error={error}
          status='404'
          title='Ops, parece que algo deu errado'
          subTitle='Canais não encontrados'
        />
      </EcpCard>
    </div>
  )
}

const RhCovenantSelectViewTableContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div className='rh-covenant-select__table-container w-100 mt-2'>
      <table className='w-100 rh-covenant-select__table'>
        {children}
      </table>
    </div>
  )
}

const RhCovenantSelectViewTr = ({ children, ...rest }: {
  children: ReactNode
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>) => {
  return (
    <tr
      className='rh-covenant-select__covenant-row'
      {...rest}
    >
      {children}
    </tr>
  )
}

const RhCovenantSelectViewTd = ({ children, className, ...rest }: {
  children: ReactNode,
  className?: string
}) => {
  return (
    <td className={`rh-covenant-select__row-item ${className ?? ''}`} {...rest}>
      {children}
    </td>
  )
}

function RHCovenantSelectTransferView ({ loading, error, onSelect, covenants }: PropsRHCovenantSelectView<ITransferCovenant>) {
  if (loading) {
    return <RHCovenantSelectViewLoading columns={COVENANTS_SELECT_TRANSFER_COLUMNS} />
  }

  if (!loading && error) {
    return <RHCovenantSelectViewError columns={COVENANTS_SELECT_TRANSFER_COLUMNS} error={error} />
  }

  return (
    <RhCovenantSelectViewTableContainer>
      <RHCovenantSelectTableHeader columns={COVENANTS_SELECT_TRANSFER_COLUMNS} />

      {Array.isArray(covenants) && covenants.map((covenant: ITransferCovenant) => {
        const { user_funcao, valor_original, valor_atual, data_vencimento, _id } = covenant
        const currentDate = new Date()
        const daysLeft = dayjs(data_vencimento).diff(currentDate, 'day')
        const dayleftLabel = daysLeftText(daysLeft)

        return (
          <RhCovenantSelectViewTr
            key={user_funcao.id}
            id={user_funcao.id}
            onClick={() => onSelect(_id, user_funcao.entidade.nome)}
          >
            <RhCovenantSelectViewTd className='pl-4'>
              {user_funcao.entidade.nome || '-'}
            </RhCovenantSelectViewTd>

            <RhCovenantSelectViewTd>
              <p className='text-ellipsis'><b>{dayleftLabel}</b></p>
              {dayjs(data_vencimento).isValid() ? timeAsDayjs(data_vencimento).format('DD/MM/YYYY') : '-'}
            </RhCovenantSelectViewTd>

            <RhCovenantSelectViewTd>
              {R$(valor_original)}
            </RhCovenantSelectViewTd>

            <RhCovenantSelectViewTd>
              {R$(valor_atual)}
            </RhCovenantSelectViewTd>
          </RhCovenantSelectViewTr>
        )
      })}
    </RhCovenantSelectViewTableContainer>
  )
}

export default function RHTransferCovenantSelect ({ textInfo, onSelect }: RHCovenantSelectProps) {
  const {
    covenants,
    error,
    loading,
    params,
    totalDocs,
    onUpdateParams
  } = useTransferCovenants({
    onSelect
  })

  return (
    <>
      <h2 className='bold color-primary mt-3 mb-0'>Selecionar Convênio</h2>
      <p className='color-primary'>{textInfo}</p>

      <RHCovenantSelectTransferView
        covenants={covenants}
        error={error}
        loading={loading}
        onSelect={onSelect}
      />

      <div className='mt-5 flex flex-justify-center'>
        <Pagination
          pageSize={Number(params.limit)}
          showSizeChanger={false}
          total={totalDocs}
          current={params.page}
          onChange={page => onUpdateParams({ page })}
          size="small"
        />
      </div>
    </>
  )
}

function RHCovenantSelectPendingView ({ loading, error, onSelect, covenants } : PropsRHCovenantSelectView<IPendingUserCovenants>) {
  if (loading) {
    return <RHCovenantSelectViewLoading columns={COVENANTS_SELECT_PENDING_USER_COLUMNS} />
  }

  if (!loading && error) {
    return <RHCovenantSelectViewError columns={COVENANTS_SELECT_PENDING_USER_COLUMNS} error={error} />
  }

  return (
    <RhCovenantSelectViewTableContainer>
      <RHCovenantSelectTableHeader columns={COVENANTS_SELECT_PENDING_USER_COLUMNS} />

      {Array.isArray(covenants) && covenants.map((covenant: IPendingUserCovenants) => {
        const { user_funcao, funcionarios, funcionarios_com_acesso, lgpd_pendente_verificacao } = covenant

        return (
          <RhCovenantSelectViewTr
            key={user_funcao.id}
            id={user_funcao.id}
            onClick={() => onSelect(user_funcao.id, user_funcao.entidade.nome)}
          >
            <RhCovenantSelectViewTd className='pl-4'>
              {user_funcao.entidade.nome || '-'}
            </RhCovenantSelectViewTd>

            <RhCovenantSelectViewTd>
              {funcionarios || 0}
            </RhCovenantSelectViewTd>

            <RhCovenantSelectViewTd>
              {lgpd_pendente_verificacao}
            </RhCovenantSelectViewTd>

            <RhCovenantSelectViewTd>
              {funcionarios_com_acesso || 0}
            </RhCovenantSelectViewTd>
          </RhCovenantSelectViewTr>
        )
      })}
    </RhCovenantSelectViewTableContainer>
  )
}

export function RHCovenantSelectPendingUser ({ textInfo, onSelect }: RHCovenantSelectProps) {
  const {
    covenants,
    error,
    loading,
    params,
    totalDocs,
    onUpdateParams
  } = usePendingUserCovenants({
    onSelect
  })

  return (
    <>
      <h2 className='bold color-primary mt-3 mb-0'>Selecionar Convênio</h2>
      <p className='color-primary'>{textInfo}</p>

      <RHCovenantSelectPendingView
        covenants={covenants}
        error={error}
        loading={loading}
        onSelect={onSelect}
      />

      <div className='mt-5 flex flex-justify-center'>
        <Pagination
          pageSize={Number(params.limit)}
          showSizeChanger={false}
          total={totalDocs}
          current={params.page}
          onChange={page => onUpdateParams({ page })}
          size="small"
        />
      </div>
    </>
  )
}

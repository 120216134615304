import React from 'react'
import { Select } from 'antd'
import { SelectProps, SelectValue } from 'antd/lib/select'
import { ISelect } from 'egi/types'
import { FormInstance } from 'antd/lib/form'

export interface IAntdSelect extends SelectProps<SelectValue> {
  rest?: Array<any>
  form: FormInstance<any>
  baseArray: Array<ISelect>
  options: Array<ISelect>
  name: string
}

function AntdMultipleSelect ({ form, baseArray, name, options, ...rest }: IAntdSelect) {
  return (
    <Select
      {...rest}
      allowClear
      mode="multiple"
      onChange={(items: Array<string> | any) => {
        if (form) {
          form.setFieldsValue({ [name]: items })

          if (items.includes('all')) {
            const allValues = baseArray.map((item: ISelect) => item.value)
            form.setFieldsValue({ [name]: allValues })
          }
        }
      }}
      options={[
        { label: 'Selecionar todos', value: 'all' },
        ...options
      ]}
    />
  )
}

export default AntdMultipleSelect

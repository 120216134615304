import { DownOutlined } from '@ant-design/icons'
import DrawerFiltersLine from 'components/DrawerFilters/DrawerFiltersLine'
import React, { DetailedHTMLProps, ReactElement, ReactNode, useState } from 'react'

type IDrawerFiltersRadioGroupWrapper = DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
}

export function DrawerFiltersRadioGroupWrapper ({ children, ...rest }: IDrawerFiltersRadioGroupWrapper) {
  return <div className='proposal-radio-filter' {...rest}>{children}</div>
}

type IDrawerFiltersLineCollapsable = DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  label: ReactNode
  children: ReactNode | ReactElement
  startClosed?: boolean
}

function DrawerFiltersLineCollapsable ({ children, label, startClosed = false, ...rest }: IDrawerFiltersLineCollapsable) {
  const [collapsed, setCollapsed] = useState(startClosed ? true : false)

  return (
    <div className='drawer-filters__line-collapsable'>
      <DrawerFiltersLine
        className='drawer-filters__line-collapsable-header'
        tabIndex={0}
        label={label}
        onClick={() => setCollapsed(prev => !prev)}
        onKeyDown={e => ['Enter', ' '].includes(e.key) && setCollapsed(prev => !prev)}
      >
        <DownOutlined className={`drawer-filters__line-collapsable-icon ${collapsed ? 'drawer-filters__line-collapsable-icon--collapsed' : ''}`} />
      </DrawerFiltersLine>

      <div
        className={`drawer-filters__line-collapsable-content ${collapsed ? 'drawer-filters__line-collapsable-content--collapsed' : ''}`}
        {...rest}
      >
        {collapsed ? null : children}
      </div>
    </div>
  )
}

type IDrawerFiltersLineCollapsableItem = DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
}

export function DrawerFiltersLineCollapsableItem ({ children, className, ...rest }: IDrawerFiltersLineCollapsableItem) {
  return (
    <div
      className={`drawer-filters__line-collapsable-item ${className || ''}`}
      {...rest}
    >
      {children}
    </div>
  )
}

export function DrawerFiltersLineCollapsableItemLabelful ({ children, className, label, ...rest }: IDrawerFiltersLineCollapsableItem & { label: string }) {
  const isReactNode = React.Children.count(children) > 1
  const memoizedChildren = React.useMemo(() => (
    isReactNode
      ? children
      : React.cloneElement(children as ReactElement, rest)
  ), [isReactNode])

  return (
    <div
      className={`drawer-filters__line-collapsable-item ${className || ''}`}
      {...(isReactNode ? {} : rest)}
    >
      {label}
      {memoizedChildren}
    </div>
  )
}

export default DrawerFiltersLineCollapsable

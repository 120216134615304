import { Button, Form, Skeleton, Typography } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import LoginRepository from 'ecp/repositories/LoginRepository'
import SignupLayout from 'layouts/SignupLayout/SignupLayout'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { handleOnidataErrors } from 'utils/validate'
import { UNAUTHS_PATHS } from 'routes/unauthRoutes'
import { IError } from 'ecp/app/Proposals/proposalInterfaces'
import PasswordInputs from '../../components/PasswordInputs/PasswordInputs'
import InviteRepository from 'ecp/repositories/InviteRepository'
import { ISignupClientPasswordRequest, ISignupTokenInfo, ISingupClientPasswordFields } from '../../signUpClientInterfaces'
import SignupButton from 'egi/app/Signup/components/SignupButton/SignupButton'
import { PASSWORD_HELP_TEXT } from 'egi/app/Signup/signupConstants'
import { SignupTitle } from 'egi/app/Signup/components/SignupTitle/SignupTitle'
import { SignupInviteAlert } from 'egi/app/Signup/components/SignupInviteMessages/SignupInviteMessages'
import { swalError } from 'components/SwalError/SwalError'

function SignupClientPasswords () {
  const { preSignupToken } = useParams<{preSignupToken: string}>()
  const [token, setToken] = useState<ISignupTokenInfo | null>(null)
  const [loadingTokenInfo, setLoadingTokenInfo] = useState(false)
  const [hasTokenFailed, setHasTokenFailed] = useState(true)

  const [form] = useForm<ISingupClientPasswordFields>()
  const [errors, setErrors] = useState<IError<ISingupClientPasswordFields>>({})
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const history = useHistory()

  async function getTokenInfo (preSignupToken: string) {
    setHasTokenFailed(false)
    setLoadingTokenInfo(true)
    setErrorMessage('')
    try {
      const response = await InviteRepository.getTokenInfo(preSignupToken)
      const data = response.data.data

      if (data) {
        const tokenInfo: ISignupTokenInfo = {
          email: data.user.onidata.email,
          name: data.user.onidata.nome,
          cpf: data.user.onidata.cpf,
          preSignupToken
        }

        if (tokenInfo) setToken(tokenInfo)
      }
    } catch (err) {
      console.error(err)
      setHasTokenFailed(true)
      if (err.message) setErrorMessage(err.message)
    } finally {
      setLoadingTokenInfo(false)
    }
  }

  useEffect(() => {
    if (preSignupToken) getTokenInfo(preSignupToken)
  }, [preSignupToken])

  function redirectToNextStep (tokenInfo: ISignupTokenInfo) {
    history.push({
      pathname: `${UNAUTHS_PATHS.SIGNUP_CLIENT_PRIVACY_POLICY}`,
      state: tokenInfo
    })
  }

  async function handleSubmit (values: ISingupClientPasswordFields) {
    setErrors({})
    setLoadingSubmit(true)
    if (!token) return

    try {
      const data: ISignupClientPasswordRequest = {
        ...values,
        cpf: token?.cpf,
        email: token?.email,
        preSignupToken: token?.preSignupToken
      }

      const response = await LoginRepository.clientSignup(data)
      const { token_jwt: jwtToken } = response.data?.data || {}

      const updatedTokenInfo: ISignupTokenInfo = {
        ...token,
        jwtToken
      }

      setToken(updatedTokenInfo)
      redirectToNextStep(updatedTokenInfo)
    } catch (err) {
      console.error(err)
      if (err.data?.error) {
        setErrors(handleOnidataErrors(err.data?.error))
      } else {
        swalError({ err })
      }
    } finally {
      setLoadingSubmit(false)
    }
  }

  return (
    <SignupLayout>
      {loadingTokenInfo
        ? (
          <div>
            <SignupTitle>Buscando convite</SignupTitle>
            <Skeleton active={true}/>
          </div>
        ) : hasTokenFailed
          ? (
            <div className='flex flex-column'>
              <SignupTitle>Ocorreu um problema</SignupTitle>
              <Typography.Paragraph className='mb-6 text-center'>
                {errorMessage || 'Verifique o endereço de pré-cadastro ou a sua conexão e tente novamente.'}
              </Typography.Paragraph>
              <Button
                className='m-auto'
                type='primary'
                onClick={() => preSignupToken && getTokenInfo(preSignupToken)}
              >
                Tentar novamente
              </Button>
            </div>
          )
          : (
            <>
              <SignupTitle>Conclua seu cadastro:</SignupTitle>
              <div className='mb-3'>
                <SignupInviteAlert
                  inviterName={token?.name || ''}
                >
                  Seu pré-cadastro foi identificado. Preencha o formulário abaixo para continuar.
                </SignupInviteAlert>
              </div>

              <Form
                onFinish={handleSubmit}
                form={form}
                layout="vertical"
                className='unauth-form'
              >
                <div className='ecp-signup-form__password-help-container'>
                  <Typography.Paragraph className='ecp-signup-form__password-help-text'>{PASSWORD_HELP_TEXT}</Typography.Paragraph>
                </div>
                <PasswordInputs
                  errors={errors}
                />
                <Form.Item>
                  <SignupButton
                    loading={loadingSubmit}
                    htmlType='submit'
                    className='w-100'
                  >
                  Enviar
                  </SignupButton>
                </Form.Item>
              </Form>
            </>
          )
      }
    </SignupLayout>
  )
}

export default SignupClientPasswords

import { AxiosRequestConfig } from 'axios'
import { IResponseBase, Repository } from 'ecp/repositories/Repository'
import api from 'services/api'

class BackofficeRepository extends Repository {
  async updateAreas (backofficeId: string, body: { areas: Array<string> }, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.patch(`${this.path}/${backofficeId}/areas`, body, config)
    )
  }

  async fetchBackOffices (config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/list/proposal`, config)
    )
  }
}

export default new BackofficeRepository({ api, path: '/backoffices' })

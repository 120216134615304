import React, { Fragment, useEffect, useState } from 'react'
import { Form, Button, Row, Col, Checkbox, Typography } from 'antd'
import { Input } from 'components'
import { simulation as simulationConsumer } from 'egi/consumers'
import { ILeadInfoErrors } from 'egi/types/ILeadInfo'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'
import format from 'utils/format'
import { useDispatch } from 'react-redux'
import { simulationSetId, simulationValues } from 'store/modules/simulation/actions'
import queryString from 'query-string'
import { useHistory } from 'react-router'
import { useAuth, useSimulation } from 'hooks'
import { ISimulatorLeadInfo } from '../../simulatorInterfaces'
import LeadsRepository from 'egi/repositories/LeadsRepository'
import { tablesUpdate } from 'store/modules/tables/actions'
import { invalidSimulationFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
const { Paragraph } = Typography

function SimulatorLeadInfo ({ product, simulatorData, onlyCEP, hasTerms, isAuth }: ISimulatorLeadInfo) {
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<ILeadInfoErrors>({})

  const [formRef] = Form.useForm()
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useAuth()
  const simulation = useSimulation()

  const onSubmit = async (values: any) => {
    const query = queryString.parse(history.location.search)

    const cellphone = values.cellphone
    if (cellphone) values.cellphone = format.onlyDigits(cellphone)
    if (product) values.product = product

    if (values.zipcode) values.zipcode = format.onlyDigits(values.zipcode)

    if (query.token) values.token = query.token
    if (query.transaction_id) values.transaction_id = query.transaction_id
    if (query.utm_source) values.utm_source = query.utm_source

    let clientId
    if (user.level !== 'client') clientId = simulatorData?._id || simulatorData?.clientId

    const reduxData = {
      ...simulation.values,
      name: values.name,
      cellphone: values.cellphone,
      email: values.email,
      zipcode: values.zipcode
    }

    const data = {
      ...values,
      clientId: clientId || undefined
    }

    setLoading(true)

    try {
      const response = await simulationConsumer.simulationLead(data)
      const simulationId = response.simulationId

      dispatch(simulationSetId(simulationId))
      dispatch(simulationValues(reduxData, 1))
      setErrors({})
    } catch (err) {
      if (err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
        invalidSimulationFieldsError({ invalidFields: err.data?.invalid })
        setLoading(false)
      } else {
        setErrors({})
        swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      }
      if (err?.data?.needsVinculation) {
        swal.confirmNegate({
          icon: 'question',
          title: 'Atenção',
          text: 'Você gostaria de se vincular a essa proposta?',
          confirm: async () => {
            try {
              if (!simulation?.values?._id) throw new Error('Esta proposta não está válida para alteração')
              const response = await LeadsRepository.changeCommercial(simulation?.values?._id, { commercialId: user._id })
              const message = response.data.message

              swal.basic({ title: 'Sucesso!', text: message, icon: 'success' })
              dispatch(tablesUpdate())
            } catch (err) {
              swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
            }
          }
        })
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    function onFill () {
      const data = Object.assign({}, simulation.values) || {}

      if (isAuth && user.level === 'client') {
        if (user.name) data.name = user.name
        if (user.email) data.email = user.email
        if (user.cellphone) data.cellphone = user.cellphone
      }

      if (formRef) formRef.setFieldsValue({ ...data })
    }

    onFill()
  }, [simulation.values])

  return (
    <Form
      onFinish={onSubmit}
      layout="vertical"
      form={formRef}
    >
      <Col className="px-1" lg={24} sm={24} xs={24}>
        <Form.Item
          name='name'
          label={<label className="simulator-label">Nome:</label>}
          help={errors.name && (errors.name)}
          validateStatus={errors.name && ('error')}
        >
          <Input
            disabled={onlyCEP}
            className="simulator-input ant-input"
            placeholder='Digite o nome'
            error={errors.name}
          />
        </Form.Item>
      </Col>

      <Col className="px-1" lg={24} sm={24} xs={24}>
        <Form.Item
          name='email'
          label={<label className="simulator-label">Email:</label>}
          help={errors.email && (errors.email)}
          validateStatus={errors.email && ('error')}
        >
          <Input
            disabled={onlyCEP}
            className="simulator-input ant-input"
            placeholder='Digite o email'
            error={errors.email}
          />
        </Form.Item>
      </Col>

      <Col className="px-1" lg={24} sm={24} xs={24}>
        <Form.Item
          name='cellphone'
          label={<label className="simulator-label">Celular ou Telefone:</label>}
          help={errors.cellphone && (errors.cellphone)}
          validateStatus={errors.cellphone && ('error')}
        >
          <Input
            disabled={onlyCEP}
            className="simulator-input ant-input"
            placeholder="(__) _____-____"
            mask="phone"
          />
        </Form.Item>
      </Col>

      <Row gutter={[15, 15]}>
        <Col lg={24} sm={24} xs={24}>
          <Form.Item
            name='zipcode'
            label={<label className="simulator-label">CEP do Imóvel da garantia:</label>}
            help={errors.zipcode && errors.zipcode}
            validateStatus={errors.zipcode && ('error')}
          >
            <Input
              placeholder='Digite o cep'
              mask='cep'
              className="simulator-input ant-input"
            />
          </Form.Item>
        </Col>
      </Row>

      {(isAuth === false || hasTerms) && (
        <Fragment>
          <Row>
            <Col lg={25} sm={24} xs={24}>
              <Form.Item
                name="terms"
                valuePropName="checked"
                help={errors.terms && errors.terms}
                validateStatus={errors.terms && 'error'}
              >
                <Paragraph className="unauth-terms-label">
                  <Checkbox className="px-1 checkbox-lgpd" />
                  <a target="_blank" rel="noreferrer" href="https://rodobens.com.br/pdf/politica-de-privacidade-das-empresas-rodobens.pdf" className="color-primary">Aceito os termos de política e privacidade.</a>
                </Paragraph>
              </Form.Item>
            </Col>
          </Row>
        </Fragment>
      )}

      <Form.Item>
        <Button
          htmlType="submit"
          className="simulator-calculation-button w-100"
          loading={loading}
        >
          INICIAR SIMULAÇÃO
        </Button>
      </Form.Item>
    </Form>
  )
}

export default SimulatorLeadInfo

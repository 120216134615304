import React, { ReactNode } from 'react'
import Result, { ResultStatusType } from 'antd/lib/result'
import ErrorDetails from 'components/ErrorDetails/ErrorDetails'
import { errorCode } from 'components/ErrorDetails/functions'
import { IErrorDetails } from 'components/ErrorDetails/interfaces'
import UserInformationSVG from 'assets/reactSvgs/UserSVG/UserInformationSVG'

export interface IErrorItemECPF {
  mensagem: string
  codigo: number | null
}

export interface IDataErrorECPF {
  erros: IErrorItemECPF[]
}

export interface IErrorDetailsECPF {
  code?: ResultStatusType
  data?: IDataErrorECPF
  message?: string
  status?: string
  success?: boolean
}

export const verifyIsECPFError = (error: IErrorDetailsECPF): boolean => {
  if (!error?.data?.erros || !Array.isArray(error.data.erros)) {
    return false
  }

  return error.data.erros.every((err) => {
    return typeof err.mensagem === 'string' &&
           (typeof err.codigo === 'string' ||
            typeof err.codigo === 'number' ||
            err.codigo === null)
  })
}

function ErrorListECPF ({ erros, containerClassName = 'error-details-ecpf__container' }: { erros?: IErrorItemECPF[], containerClassName?: string }) {
  if (!erros || erros?.length < 1) return null
  return (
    <div className={containerClassName}>
      {erros.map((err, index) => (
        <div key={index} className="error-details-ecpf__item">
          <div className='w-100 flex flex-column flex-align-start'>
            <p className='error-details-ecpf__message flex flex-space-between mb-0'><strong>Mensagem </strong><i>{err?.codigo || ''}</i></p>
            <p className='error-details-ecpf__message'>{err.mensagem}</p>
          </div>
        </div>
      ))}
    </div>
  )
}

interface IErrorDetailsECPFProps {
  status?: ResultStatusType
  title: string
  error: IErrorDetailsECPF
  fullHeightContainer?: boolean
  extra?: React.ReactNode
  containerClassName?: string
}

export function ErrorDetailsSimulationECPF ({ whatsAppLink }: { whatsAppLink: ReactNode }) {
  return (
    <div className='error-details-ecpf__simulation'>
      <div className='w-100 text-center'>
        <UserInformationSVG
          className='error-details-ecpf__warning-svg'
        />
      </div>

      <h2 className='error-details-ecpf__simulation-title'>Caro cliente!</h2>
      <p className='mb-2'>Não foi possível simular o valor máximo do seu empréstimo, mas não se preocupe! Temos uma oferta especial esperando por você!</p>
      <p className='error-details-ecpf__simulation-whatsapp'>
        Entre em contato com o nosso atendimento através do nosso WhatsApp: {whatsAppLink}
      </p>
    </div>
  )
}

export function ErrorListContainerECPF ({ fullHeightContainer, containerClassName, error }: {
  fullHeightContainer: IErrorDetailsECPFProps['fullHeightContainer'],
  error: IErrorDetailsECPFProps['error'],
  containerClassName?: IErrorDetailsECPFProps['containerClassName']
}) {
  const fullHeightContainerClassName = fullHeightContainer ? 'error-details-ecpf__container--full-height' : ''

  const containerFullClassName = `error-details-ecpf__container ${containerClassName ?? ''} ${fullHeightContainerClassName}`

  return (
    <div className={containerFullClassName}>
      <ErrorListECPF
        containerClassName={containerClassName}
        erros={error.data?.erros}
      />
    </div>
  )
}

export default function ErrorDetailsECPF ({
  status,
  title,
  error,
  extra,
  containerClassName,
  fullHeightContainer
}: IErrorDetailsECPFProps) {
  const areErrorsValid = verifyIsECPFError(error)
  const errorStatus = status ?? (error.code && errorCode(error.code)) ?? 'warning'

  if (!areErrorsValid) {
    return (
      <ErrorDetails
        status={errorStatus}
        title={title}
        error={error as unknown as IErrorDetails}
        extra={extra}
        subTitle={error?.message || ''}
      />
    )
  }

  return (
    <div className="error-details-ecpf">
      <Result
        className='error-details-ecpf__result'
        status={errorStatus}
        title={title}
        extra={extra}
      />

      <ErrorListContainerECPF
        fullHeightContainer={fullHeightContainer}
        containerClassName={containerClassName}
        error={error}
      />
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { Empty, Select } from 'antd'
import { ALL_RESOURCE } from '../../proposalConstants'
import { AxiosRequestConfig } from 'axios'
import ResourcesRepository, { IResourceCorban } from 'ecp/repositories/ResourcesRepository'
import { ISelectProps } from 'components/Select/Select'

interface Props extends ISelectProps {
  hasAllResource?: boolean
}

const formatToSelectSubCorban = (item: IResourceCorban) => ({
  value: item._id,
  label: item.nome_fantasia
})

export default function CorbanSelect ({ hasAllResource = true, disabled, onChange, ...rest }: Props) {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [corbans, setCorbans] = useState<{ value: string, label: string }[]>([])
  const [options, setOptions] = useState<{ value: string, label: string }[]>([])

  useEffect(() => {
    const abortController = new AbortController()
    async function fetchSubCorbans () {
      try {
        setLoading(true)

        const requestConfig: AxiosRequestConfig = {
          signal: abortController.signal
        }

        const response = await ResourcesRepository.corbans(requestConfig)
        const corbans = response.data.data?.corbans

        if (!corbans?.length) {
          setCorbans([])
          throw new Error('Nenhum corban encontrado')
        }

        const formatedValues = (corbans || []).map(formatToSelectSubCorban)
        setCorbans(formatedValues)
        setLoading(false)
      } catch (err) {
        if (!abortController.signal.aborted) {
          setErrorMessage(err?.message)
          setLoading(false)
        }
      }
    }

    fetchSubCorbans()
    return () => abortController.abort()
  }, [])

  useEffect(() => {
    if (hasAllResource) setOptions([ALL_RESOURCE, ...corbans])
    else setOptions(corbans)
  }, [corbans])

  return (
    <Select
      {...rest}
      onChange={onChange}
      showSearch
      optionFilterProp="children"
      disabled={loading || disabled}
      loading={loading}
      getPopupContainer={trigger => trigger.parentNode}
      notFoundContent={
        errorMessage && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={errorMessage} />
      }
    >
      {options.map((item, idx) => (
        <Select.Option
          value={item.value}
          key={item.value + (idx)}
        >
          {item.label}
        </Select.Option>
      ))}
    </Select>
  )
}

import { Button, Col, Form } from 'antd'
import { Drawer } from 'components'
import Datepicker from 'components/Datepicker/Datepicker'
import { swalError } from 'components/SwalError/SwalError'
import ProposalStepButton from 'ecp/app/Proposals/components/ProposalStepButton/ProposalStepButton'
import ProposalStepButtonWithHistory from 'ecp/app/Proposals/components/ProposalStepButtonWithHistory/ProposalStepButtonWithHistory'
import useDynamicProposal from 'ecp/app/Proposals/hooks/useDynamicProposal'
import useProposalDrawerWithContext from 'ecp/app/Proposals/hooks/useProposalDrawerWithContext'
import useThisStep from 'ecp/app/Proposals/hooks/useThisStep'
import { IError, ISendStepBody } from 'ecp/app/Proposals/proposalInterfaces'
import ProposalModel from 'ecp/models/ProposalModel'
import React, { useState } from 'react'
import { correctTimezone } from 'utils/time'
import { handleOnidataErrors } from 'utils/validate'

type StepForm = { paymentDate: string }
const PagamentoStep = () => {
  const { step } = useThisStep()

  const { isOpen, open, close, sendStep } = useProposalDrawerWithContext()
  const { details } = useDynamicProposal()

  const [loading, setLoading] = useState(false)
  const canOpen = step.canOpen
  const [errors, setErrors] = useState<IError<{data_pagamento?: string}>>({})

  async function onSubmit ({ paymentDate }: StepForm) {
    setErrors({})

    const body: ISendStepBody<{}> = {
      parameters: {
        paymentDate: paymentDate ? correctTimezone(paymentDate) : undefined
      },
      status: 'approved'
    }

    setLoading(true)
    try {
      const proposalId = details?.value?._id
      if (!proposalId) throw new Error('ProposalId não encontrado')

      await sendStep(body, proposalId)

      close()
    } catch (err) {
      if (err?.data && err?.data?.error) setErrors(handleOnidataErrors(err?.data?.error))
      swalError({ title: 'Atenção', err, icon: 'warning' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <ProposalStepButtonWithHistory>
      <ProposalStepButton
        onClick={open}
        enabled={canOpen}
      >
        {step.name}
      </ProposalStepButton>
      <Drawer
        visible={isOpen}
        onClose={close}
        title={step.name}
        footer={null}
        width='min(700px, 60vw)'
      >
        <Form
          layout='vertical'
          onFinish={onSubmit}
        >
          <Col style={{ maxWidth: 300 }}>
            <Form.Item
              name='paymentDate'
              label='Data de pagamento'
              help={errors.data_pagamento}
              validateStatus={errors.data_pagamento && 'error'}
            >
              <Datepicker
                disabledDate={
                  (current) => ProposalModel.isAverbationDateDisabled(current, details!.value!.createdAt)
                }
              />
            </Form.Item>
          </Col>

          {canOpen && (
            <div className='flex flex-justify-end'>
              <Button
                type='primary'
                htmlType='submit'
                loading={loading}
              >
                Enviar
              </Button>
            </div>
          )}
        </Form>
      </Drawer>
    </ProposalStepButtonWithHistory>
  )
}

export default PagamentoStep

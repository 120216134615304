import React from 'react'
import { Col, Form, Select, Typography } from 'antd'
import { Input } from 'components'
import { useResources } from 'hooks'

interface IBankProps {
  errors: any
  readOnlyInput?: boolean
}

function BankContract ({ errors, readOnlyInput }: IBankProps) {
  const resources = useResources()

  return (
    <>
      <Col span={24}>
        <Typography.Title className="form-section-title form-section-title--primary" level={4}>Dados bancários</Typography.Title>
      </Col>

      <Col lg={12} md={24} sm={24} xs={24}>
        <Form.Item
          name='dados_bancarios_banco'
          label={<label>Banco</label>}
          help={errors.dados_bancarios_banco && (errors.dados_bancarios_banco)}
          validateStatus={errors.dados_bancarios_banco && ('error')}
        >
          <Select
            id="test-form-item-dados_bancarios_banco"
            showSearch
            placeholder={'Escolha o banco'}
            optionFilterProp="children"
            aria-readonly={readOnlyInput}
            disabled={readOnlyInput}
          >
            {(resources.banks).map(bank => (
              <Select.Option id={`test-form-select-bank-${bank.name}`} key={bank.code} value={bank.code}>{bank.code} - {bank.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      <Col lg={12} md={24} sm={24} xs={24}>
        <Form.Item
          name='dados_bancarios_agencia'
          label={<label>Agência</label>}
          help={errors.dados_bancarios_agencia && (errors.dados_bancarios_agencia)}
          validateStatus={errors.dados_bancarios_agencia && ('error')}
        >
          <Input
            id="test-form-item-dados_bancarios_agencia"
            placeholder={'Digite a agência'}
            error={errors.dados_bancarios_agencia}
            mask="agency"
            readOnly={readOnlyInput}
          />
        </Form.Item>
      </Col>

      <Col lg={12} md={24} sm={24} xs={24}>
        <Form.Item
          name='dados_bancarios_conta_corrente'
          label={<label>Conta</label>}
          help={errors.dados_bancarios_conta_corrente && (errors.dados_bancarios_conta_corrente)}
          validateStatus={errors.dados_bancarios_conta_corrente && ('error')}
        >
          <Input
            id="test-form-item-dados_bancarios_conta_corrente"
            readOnly={readOnlyInput}
            placeholder='Digite a conta'
            error={errors.dados_bancarios_conta_corrente}
            mask='account'
          />
        </Form.Item>
      </Col>

      <Col lg={12} md={24} sm={24} xs={24}>
        <Form.Item
          name='dados_bancarios_titular'
          label={<label>Titular da conta</label>}
          help={errors.dados_bancarios_titular && (errors.dados_bancarios_titular)}
          validateStatus={errors.dados_bancarios_titular && ('error')}
        >
          <Input
            id="test-form-item-dados_bancarios_titular"
            readOnly={readOnlyInput}
            placeholder="Digite o titular da conta"
            error={errors.dados_bancarios_titular}
          />
        </Form.Item>
      </Col>
    </>
  )
}

export default BankContract

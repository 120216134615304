import React, { Fragment, useState, useEffect, useLayoutEffect } from 'react'
import { Row, Typography, Col, Button, Input, Skeleton, Empty } from 'antd'
import { DownloadOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { DocumentsView } from 'components'
import { useAuth } from 'hooks'
import swal from 'utils/swal'
import CreateDivulgation from '../../components/CreateDivulgation/CreateDivulgation'
import MlmRepository, { MlmMaterialResponse } from 'egi/repositories/MlmRepository'
import DivulgationModel from 'egi/models/DivulgationModel'

const { Title, Paragraph } = Typography

function LoadingEmptyControl ({ loading, component, failed }: { loading: boolean, component: React.ReactElement, failed: boolean }) {
  if (loading) {
    return (
      <div className="divulgation-card">
        {new Array(3).fill('').map((_, index) => (
          <div key={index} className="dashboard-status-card flex flex-column flex-align-center p-3">
            <Skeleton loading={loading} active />
          </div>
        ))}
      </div>
    )
  }

  if (failed) {
    return (
      <Empty
        description="Não há dados"
        className="dashboard-status-card p-3 w-100 m-0"
      />
    )
  }

  return component
}

function Divulgation () {
  const [materials, setMaterials] = useState<MlmMaterialResponse[]>([])
  const [search, setSearch] = useState<string | undefined>(undefined)
  const [visible, setVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [resetImage, setResetImage] = useState<boolean | undefined>(false)
  const [fieldsValue, setFieldsValue] = useState({
    name: '',
    description: '',
    materialId: '',
    image: ''
  })

  const user = useAuth()
  if (search === '') setSearch(undefined)

  const getMaterials = async (search?: string) => {
    setLoading(true)
    try {
      const response = await MlmRepository.listMaterials({ params: { search: search } })
      const materials = response.data.data?.materials || []
      setMaterials(materials)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  const onDelete = async (id: string) => {
    setLoading(true)
    try {
      const response = await MlmRepository.deleteMaterial(id)
      swal.basic({ icon: 'success', text: response.data?.message, title: 'Sucesso!' })
      setLoading(false)
      getMaterials()
    } catch (err) {
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      setLoading(false)
    }
  }

  function fetch () {
    getMaterials()
  }

  function resetFieldValue () {
    setFieldsValue({
      description: '',
      materialId: '',
      name: '',
      image: ''
    })

    setResetImage(undefined)
    setResetImage(!resetImage)
  }

  function downloadImage (link: string) {
    const a = document.createElement('a')
    a.href = link
    a.download = link

    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  useLayoutEffect(() => {
    getMaterials(search)
  }, [search])

  useEffect(() => {
    getMaterials()
  }, [])

  return (
    <Fragment>
      <CreateDivulgation
        fetch={fetch}
        visible={visible}
        setVisible={setVisible}
        resetFieldValue={resetFieldValue}
        resetImage={resetImage}
        fieldsValue={fieldsValue}
      />

      <Row gutter={15} className="mb-4">
        <Col lg={5}>
          <label>Pesquisar</label>
          <Input.Search
            onSearch={(text: string) => setSearch(text)}
            className="table-filter"
            placeholder="Pesquisar"
          />
        </Col>

        {DivulgationModel.canUploadDivulgation(user.level) && (
          <Button type="primary" className="mt-4" onClick={() => {
            setVisible(true)
          }}
          >Criar nova divulgaçao</Button>
        )}
      </Row>

      <LoadingEmptyControl
        loading={loading}
        failed={materials.length < 1}
        component={
          <div className=" divulgation-card">
            {materials.map((item: any) => {
              return (
                <Col
                  className="dashboard-status-card flex flex-column flex-align-center p-3"
                  key={item._id}
                >
                  <Fragment>
                    <Title level={4} className="color-primary my-2">{item.name}</Title>

                    <div className="mt-3">
                      <DocumentsView
                        isOnlyLink={item.filePath}
                      />
                    </div>

                    <Paragraph className="mt-3 bold color-gray">{item.description}</Paragraph>

                    <div className="w-100 flex">
                      <button onClick={() => downloadImage(item.filePath)} className="flex flex-justify-center flex-1 divulgation-download pointer flex-align-center py-2">
                        <DownloadOutlined className="divulgation-icon" />
                      </button>

                      {DivulgationModel.canEditDivulgation(user.level, item.creatorLevel) && (
                        <Fragment>
                          <button className="flex flex-justify-center flex-1 divulgation-edit pointer flex-align-center" onClick={() => {
                            setVisible(true)
                            setFieldsValue({
                              name: item.name,
                              description: item.description,
                              materialId: item._id,
                              image: item.filePath
                            })
                          }}>
                            <EditOutlined className="divulgation-icon" />
                          </button>

                          <button
                            className="flex flex-justify-center flex-1 divulgation-delete pointer flex-align-center py-2"
                            onClick={() => swal.confirmNegate({
                              icon: 'warning',
                              title: 'Atenção',
                              text: 'Deseja excluir essa divulgação',
                              confirm: () => {
                                onDelete(item._id)
                              }
                            })}>
                            <DeleteOutlined className="divulgation-icon" />
                          </button>
                        </Fragment>
                      )}
                    </div>
                  </Fragment>
                </Col>
              )
            })}
          </div>
        }
      />
    </Fragment>
  )
}

export default Divulgation

import { AxiosRequestConfig } from 'axios'
import { IErrorDetailsECPF } from 'components/ErrorDetailsECPF/ErrorDetailsECPF'
import { OrigemUserSituation } from 'ecpf/models/OrigemModel'
import { OriginsService } from 'ecpf/services/originsService'
import { useCallback, useState } from 'react'

export interface IEmployerECPFList {
  codigo: string
  descricao: string
  situacao: OrigemUserSituation
  grupoConvenio: string
  promotor: {
    codigo: string
    descricao: string
  }
}

export const useEmployerListECPF = () => {
  const [loading, setLoading] = useState(false)
  const [employers, setEmployers] = useState<Array<IEmployerECPFList>>([])
  const [errorDetails, setErrorDetails] = useState<IErrorDetailsECPF>()

  const fetchEmployers = useCallback(async (abortController: AbortController, config?: AxiosRequestConfig) => {
    try {
      setLoading(true)

      const originsService = new OriginsService()
      const response = await originsService.listEmployer(config)
      const results = response.data.data?.resultados || []

      setEmployers(results)
      setErrorDetails(undefined)
      setLoading(false)
    } catch (err) {
      if (!abortController.signal.aborted) {
        if (err.code === 404) {
          setEmployers([])
        } else {
          setEmployers([])
          setErrorDetails(err)
        }

        setLoading(false)
      }
    }
  }, [])

  return {
    loading,
    fetchEmployers,
    employers,
    errorDetails
  }
}

import React, { useState } from 'react'
import { Form, Input } from 'antd'

function FilterSearch ({ onChange }: { onChange: (value: string | undefined) => void }) {
  const [value, setValue] = useState<string | undefined>(undefined)

  return (
    <Form.Item
      name="search"
      label={<label className="ml-1">Pesquisar</label>}
    >
      <Input.Search
        className="table-filter"
        placeholder='Pesquisar'
        onSearch={(e) => {
          onChange(e)
          setValue(e.trim())
        }}
        onBlur={(e) => {
          setValue(e.currentTarget.value.trim())
          if (e.currentTarget.value.trim() !== value) onChange(e.currentTarget.value.trim())
        }}
      />
    </Form.Item>
  )
}

export default FilterSearch

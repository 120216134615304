import dayjs, { Dayjs } from 'dayjs'
import format from 'utils/format'
import { ICepInfo } from 'hooks/useCep'
import { IClientDetails, IClientPending, IClientsFilters } from 'ecp/app/Clients/clientInterfaces'
import { CLIENTS_FILTERS_ENTRIES_TO_IGNORE, CLIENTS_FILTERS_KEYS_IGNORE } from 'ecp/app/Clients/clientConstants'
import { formatStatusForRequest, removeEmptyKeys } from 'utils/utils'
import { correctTimezone, timeAsDayjs } from 'utils/time'
import { IFiltersForRequest } from '../Covenant/covenantInterfaces'
import { IOnidataClient } from 'ecp/models/ClientModel'

export function formatCepInfo (cepInfo: ICepInfo) {
  const { localidade, ...cepInfoCheryPicked } = cepInfo

  const clientFormCepInfo = {
    ...cepInfoCheryPicked,
    cidade: localidade
  }

  return clientFormCepInfo
}

export function formatSendClientForm (values: IOnidataClient) {
  if (values?.documento?.numero && values.documento.tipo === 'RG') values.documento.numero = format.formatRg(String(values?.documento.numero))
  if (values?.documento?.numero && (values.documento.tipo === 'CNH' || values.documento.tipo === 'RNE' || values.documento.tipo === 'PASSAPORTE')) values.documento.numero = format.onlyDigits(String(values?.documento.numero))
  if (values?.telefone_celular) values.telefone_celular = format.onlyDigits(String(values.telefone_celular))
  if (values?.referencia_telefone) values.referencia_telefone = format.onlyDigits(String(values.referencia_telefone))
  if (values?.data_nascimento) values.data_nascimento = dayjs(correctTimezone(String(values.data_nascimento))).isValid() ? correctTimezone(String(values.data_nascimento)) : values.data_nascimento
  if (values?.data_admissao) values.data_admissao = dayjs(correctTimezone(String(values.data_admissao))).isValid() ? correctTimezone(String(values.data_admissao)) : values.data_admissao
  if (values.irrf && typeof values.irrf === 'string') values.irrf = format.onlyMoney(values.irrf) || 0
  if (values.outros_vencimentos && typeof values.outros_vencimentos === 'string') values.outros_vencimentos = format.onlyMoney(values.outros_vencimentos) || 0
  if (values.salario && typeof values.salario === 'string') values.salario = format.onlyMoney(values.salario) || 0
  if (values.descontos && typeof values.descontos === 'string') values.descontos = format.formatMoneySend(values.descontos)
  if (values?.endereco?.cep) values.endereco.cep = format.onlyDigits(values.endereco.cep)

  return removeEmptyKeys(values) as IOnidataClient
}

export function formatFillClientForm (values: IClientDetails) {
  const formattedValues = values.onidata.data
  if (formattedValues?.data_nascimento) (formattedValues.data_nascimento as Dayjs | string) = dayjs(formattedValues.data_nascimento).isValid() ? dayjs(formattedValues.data_nascimento).format('DD/MM/YYYY') : formattedValues.data_nascimento
  if (formattedValues?.data_admissao) (formattedValues.data_admissao as Dayjs | string) = dayjs(formattedValues.data_admissao).isValid() ? dayjs(formattedValues.data_admissao).format('DD/MM/YYYY') : formattedValues.data_admissao

  return formattedValues
}

export function hasFilterCounterClients ([key, value]: [string, string]) {
  return !CLIENTS_FILTERS_KEYS_IGNORE.includes(key) &&
    !['', undefined].includes(value) &&
    !JSON.stringify(CLIENTS_FILTERS_ENTRIES_TO_IGNORE).includes(JSON.stringify({ [key]: value }))
}

export function formatFiltersClients (filters: IClientsFilters) {
  const formattedFilters: IFiltersForRequest<IClientsFilters> = {
    ...filters,
    status: formatStatusForRequest(filters?.status)
  }

  return formattedFilters
}

export function formatFinalBodyRequest (values: IClientPending) {
  return {
    cargo: values.cargo,
    email: values.email,
    email_gestor_imediato: values.email_gestor_imediato,
    matricula: values.matricula,
    nome: values.nome,
    nome_mae: values.nome_mae,
    sexo: values.sexo,
    sobrenome: values.sobrenome,
    covenantId: values.covenantId,
    cpf: values.cpf && format.onlyDigits(values.cpf),
    data_admissao: values.data_admissao && correctTimezone(values.data_admissao.toString()),
    data_nascimento: values.data_nascimento && correctTimezone(values.data_nascimento.toString()),
    inss: values.inss && Number(values.inss),
    irrf: values.irrf && Number(values.irrf),
    salario: values.salario && Number(values.salario),
    outros_vencimentos: values.outros_vencimentos && Number(values.outros_vencimentos),
    descontos: values.descontos && Number(values.descontos),
    corbanId: values.corbanId
  }
}

function returnZero (value: number | undefined | null): number {
  if (!value) return 0
  return value
}

export function formatFieldsValue (values: IClientPending) {
  return {
    ...values,
    data_nascimento: values?.data_nascimento ? timeAsDayjs(values.data_nascimento).format('DD/MM/YYYY') : '',
    data_admissao: values?.data_admissao ? timeAsDayjs(values.data_admissao).format('DD/MM/YYYY') : '',
    comprometimento_outros: returnZero(values?.comprometimento_outros),
    salario: returnZero(values?.salario),
    inss: returnZero(values?.inss),
    irrf: returnZero(values?.irrf),
    outros_vencimentos: returnZero(values?.outros_vencimentos),
    descontos: returnZero(values?.descontos),
    cpf: format.onlyDigits(values?.cpf),
    covenantId: values?.covenantId
  }
}

import { Typography } from 'antd'
import LgpdText from 'components/LgpdText/LgpdText'
import React from 'react'

function LGPDText () {
  return (
    <article role="article">
      <div >
        <Typography.Paragraph className="lgpd-page">
          <LgpdText />
        </Typography.Paragraph>
        <div className="text-center">
          Esta política de cookies foi criada e atualizada por <a href="https://agxsoftware.com/" target="_blank" rel="noreferrer">UX Vision Tech</a>.
        </div>
      </div>
    </article>

  )
}

export default LGPDText

import React, { useState, ReactElement, useEffect } from 'react'
import { Col, Pagination, Result, Row, Spin, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table/interface'
import { useTables } from 'hooks'
import useServerTable, { ResponseObject } from './hooks/useServerTable'
import { useDispatch } from 'react-redux'
import { tablesSetFiltersValues } from 'store/modules/tables/actions'
import { TableProps } from 'antd/lib/table'
import { SimplePagination } from 'ecpf/components/SimplePagination/SimplePagination'
import ErrorDetailsECPF, { IErrorDetailsECPF } from 'components/ErrorDetailsECPF/ErrorDetailsECPF'
import CustomTable from 'components/Table/Table'
import { ITable } from 'components/Table/types'

interface ITables {
  updatedTime?: string
  customClass?: string
}

interface IServerTable extends TableProps<any>, ITables {
  columns: ColumnsType<any>
  url: string
  customParams?: object
  fixed?: boolean
  resizeTable?: boolean
}

export const ServerTableIcon = ({
  error,
  errorText,
  loading,
  errorDetails
}: {
  error: boolean,
  errorText: string,
  loading: boolean,
  errorDetails?: IErrorDetailsECPF
}): ReactElement => {
  if (loading) return <Result status='404' subTitle="Carregando informações." />
  if (error) {
    if (errorDetails) {
      return (
        <ErrorDetailsECPF error={errorDetails} status="404" title='Ops, parece que algo deu errado'/>
      )
    }

    return (<Result status="500" title='Desculpe, algo deu errado.' subTitle={errorText} />)
  }
  return <Result status='404' subTitle='Sem resultados' />
}
export const ServerTableTotal = (
  {
    loading,
    page,
    limit,
    totalDocs
  }: {
    loading: boolean,
    page: number,
    limit: number,
    totalDocs: number
  }
) => {
  const [total, setTotal] = useState('')

  useEffect(() => {
    const limitsPagination = (page: number, limit: number, totalDocs: number) => {
      const actualLimit = page * limit
      const greaterDocs = actualLimit > totalDocs

      let text = actualLimit + '/' + totalDocs
      if (greaterDocs) text = totalDocs + '/' + totalDocs

      return text
    }

    setTotal(limitsPagination(page, limit, totalDocs))
  }, [
    page,
    limit,
    totalDocs
  ])

  return (
    <div className="server-table__tag-totals">
      <label>
        Total: <strong>{loading ? <Spin size="small" spinning={loading}/> : total}</strong>
      </label>
    </div>
  )
}

export const TableECPF = ({
  loading,
  errorDetails,
  errorText,
  ...rest
}: ITable & TableProps<any> & {
  errorText?: string
  errorDetails?: IErrorDetailsECPF
  loading: boolean
}) => {
  return (
    <>
      <CustomTable
        loading={loading}
        locale={{
          emptyText:
            <ServerTableIcon
              error={Boolean(errorText)}
              errorText={errorText ?? ''}
              loading={Boolean(loading)}
              errorDetails={errorDetails}
            />
        }}
        {...rest}
      />
    </>
  )
}

export const ServerTableAndSimplePagination = ({
  loading,
  customClass,
  errorText,
  columns,
  data,
  rowSelection,
  limit,
  page,
  rowKey = '_id',
  onPaginationChange,
  errorDetails,
  ...rest
}: ITables & TableProps<any> & {
  onPaginationChange: (page: number) => void
  errorText?: string
  columns: ColumnsType<any>
  data: any[]
  page: number
  errorDetails?: IErrorDetailsECPF
  limit: number
}) => {
  return (
    <>
      <Spin spinning={Boolean(loading)} className=''>
        <Table
          id="server-table"
          pagination={false}
          dataSource={data}
          columns={columns}
          className={`antd-base-table ${customClass || ''}`}
          locale={{
            emptyText:
              <ServerTableIcon
                error={Boolean(errorText)}
                errorText={errorText ?? ''}
                loading={Boolean(loading)}
                errorDetails={errorDetails}
              />
          }}
          rowSelection={rowSelection}
          rowKey={rowSelection ? rowKey : '_id'}
          {...rest}
        />
      </Spin>

      <Row
        align='middle'
        justify='space-between'
        className="server-table__pagination"
      >
        <Row align="bottom" gutter={[15, 0]}>
          <Col>
            <SimplePagination
              limit={limit}
              page={page}
              hasNextPage={(data ?? []).length >= limit && loading === false}
              onNextPage={onPaginationChange}
              onPreviousPage={onPaginationChange}
            />
          </Col>
        </Row>
      </Row>
    </>
  )
}

export const ServerTableAndPagination = ({
  loading,
  customClass,
  updatedTime,
  errorText,
  columns,
  data,
  rowSelection,
  limit,
  totalDocs,
  page,
  rowKey = '_id',
  onPaginationChange,
  errorDetails,
  ...rest
}: ResponseObject & ITables & TableProps<any> & {
  onPaginationChange: (page: number) => void
  errorText?: string
  columns: ColumnsType<any>
  data: any[]
  page: number
  errorDetails?: IErrorDetailsECPF
}) => {
  return (
    <>
      <Spin spinning={Boolean(loading)} className=''>
        <Table
          id="server-table"
          pagination={false}
          dataSource={data}
          columns={columns}
          className={`antd-base-table ${customClass || ''}`}
          locale={{
            emptyText:
              <ServerTableIcon
                error={Boolean(errorText)}
                errorText={errorText ?? ''}
                loading={Boolean(loading)}
                errorDetails={errorDetails}
              />
          }}
          rowSelection={rowSelection}
          rowKey={rowSelection ? rowKey : '_id'}
          {...rest}
        />
      </Spin>

      <Row
        align='middle'
        justify='space-between'
        className="server-table__pagination"
      >
        <Row align="bottom" gutter={[15, 0]}>
          <Col>
            <Pagination
              responsive
              pageSize={limit}
              defaultCurrent={page}
              current={page}
              total={totalDocs}
              onChange={onPaginationChange}
            />
          </Col>

          <ServerTableTotal
            loading={Boolean(loading)}
            limit={limit}
            totalDocs={totalDocs}
            page={page}
          />
        </Row>

        {updatedTime && (
          <Row align="middle" justify="end">
            <label className="server-table__updateTime">
              Atualizado em: {updatedTime}
            </label>
          </Row>
        )}
      </Row>
    </>
  )
}

function ServerTable ({ columns, url, customParams, updatedTime, rowSelection, rowKey = 'id', customClass = '', errorDetails, ...rest }: IServerTable & {
  errorDetails?: IErrorDetailsECPF
}) {
  const table = useTables()
  const dispatch = useDispatch()

  const { data, loading, errorText, columns: finalColumns, totalDocs, limit } = useServerTable({
    url,
    params: { ...customParams, ...table.filters },
    columns
  }, [
    table.update,
    JSON.stringify(table.filters),
    url
  ])

  return (
    <ServerTableAndPagination
      rowKey={rowSelection ? rowKey : '_id'}
      loading={loading}
      updatedTime={updatedTime}
      rowSelection={rowSelection}
      customClass={customClass}
      page={table.filters.page}
      limit={limit}
      totalDocs={totalDocs}
      columns={finalColumns}
      errorText={errorText}
      errorDetails={errorDetails}
      data={data}
      onPaginationChange={(page) => dispatch(tablesSetFiltersValues({ ...table.filters, page: page }))}
      {...rest}
    />
  )
}

export default ServerTable

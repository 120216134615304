import React, { useLayoutEffect, useState } from 'react'
import { resources } from 'egi/consumers'
import { Form, Select } from 'antd'
import { useTables } from 'hooks'
import { SelectValue } from 'antd/lib/select'

export type FilterPromoterProps = {
  setSeller: React.Dispatch<React.SetStateAction<string | undefined>>
}

export type ISeller = {
  onChange: (value: SelectValue | string | undefined) => void
  onClear: () => void
}

function FilterSeller ({ onChange, onClear }: ISeller) {
  const tables = useTables()
  const [selectValues, setSelectValues] = useState([])

  const getSeller = async () => {
    try {
      const response = await resources.seller()
      const sellers = response.data.sellers
      setSelectValues(sellers)
    } catch (err) {
      console.warn(err)
    }
  }

  useLayoutEffect(() => {
    getSeller()
    if (tables.filters.sellerId) onChange(tables.filters.sellerId)
  }, [])

  return (
    <Form.Item
      name="sellerId"
      label={<label>Vendedor</label>}
    >
      <Select
        showSearch
        optionFilterProp="children"
        className="w-100"
        onChange={onChange}
        onClear={onClear}
        placeholder='Selecione o vendedor'
        allowClear
      >
        <Select.Option value="">Todos</Select.Option>
        {selectValues.map((item: any) => {
          return <Select.Option key={item._id} value={item._id}>{item.name}</Select.Option>
        })}
      </Select>
    </Form.Item>
  )
}

export default FilterSeller

import { LoadingOutlined } from '@ant-design/icons'
import React from 'react'

type IProps = {
  url: string | null
  loading: boolean
  onLoad(): void
  className?: string
}

function IframePDF ({ url, loading, onLoad, className }: IProps) {
  return (
    <div className={`iframe-pdf ${className || ''}`}>
      {loading && (
        <span className='iframe-pdf__spinner'>
          <LoadingOutlined />
        </span>
      )}
      {url && (
        <iframe
          className={`iframe-pdf__iframe ${className} ${loading ? 'iframe-pdf__iframe--loading' : ''}`}
          onLoad={onLoad}
          onError={onLoad}
          src={url}
        />
      )}
      {!url && !loading && (
        <p className='text-center'>Ocorreu um problema</p>
      )}
    </div>
  )
}

export default IframePDF

import React, { useEffect, useMemo } from 'react'
import { accessManagerDetailsGroups, detailsSchema } from 'egi/app/AccessManager/accessManagerConstants'
import AccessManagerDetails from 'egi/app/AccessManager/components/AccessManagerDetails/AccessManagerDetails'
import { useAccessManagerBackoffice } from '../../hooks/useAccessManager'
import { useParams } from 'react-router-dom'
import LetterAvatar from 'ecp/components/LetterAvatar/LetterAvatar'
import { Result, Skeleton, Tag, Tooltip } from 'antd'
import mask from 'utils/masks'
import { EcpCard } from 'ecp/components/EcpCard/EcpCard'
import BackofficeCancelButton from '../../components/BackofficeCancelButton/BackofficeCancelButton.tsx'
import BackofficeBlockSwitch from '../../components/BackofficeBlockSwitch/BackofficeBlockSwitch'
import BackofficeProductsButton from '../../components/BackofficeProductsButton/BackofficeProductsButton'
import translate from 'utils/translate'
import { varColors } from 'styles/colors'
import { accessManagerBuildSchema } from '../../accessManagerFunctions'
import { IDetailSchema } from '../../accessManagerInterfaces'

function AccessManagerBackOfficeDetails () {
  const { isLoading, backoffice, errorMessage, getbackoffices } = useAccessManagerBackoffice()
  const { backofficeId } = useParams<{ backofficeId?: string }>()

  const schema = useMemo<Array<IDetailSchema>>(() => {
    return accessManagerBuildSchema(detailsSchema, backoffice || {})
  }, [detailsSchema, backoffice])

  useEffect(() => {
    getbackoffices(backofficeId)
  }, [backofficeId])

  const onFinish = () => {
    getbackoffices(backofficeId)
  }

  if (isLoading) {
    return (
      <EcpCard>
        <Skeleton active />
        <Skeleton active />
      </EcpCard>
    )
  }

  if (!backoffice || errorMessage) {
    return (
      <EcpCard>
        <Result status={404} subTitle={errorMessage} title={'Ops, parece que algo deu errado'}/>
      </EcpCard>
    )
  }

  return (
    <div>
      <div className='mb-5 mt5 back-office-details__header'>
        <div className={'flex back-office-details__header-info-container'}>
          <div className='flex back-office-details__initial-container'>
            <LetterAvatar name={backoffice?.name} />
          </div>

          <div className='back-office-details__header-info'>
            <div className='back-office-details__header-name-container'>
              <Tooltip title={`${backoffice?.name}`} destroyTooltipOnHide={{ keepParent: false }}>
                <h2 className='back-office-details__header-name mb-0'>{backoffice?.name}</h2>
              </Tooltip>

              <div className='back-office-details__header-tags'>
                {backoffice?.canceled &&
                  <Tag color='var(--refused-status-color)'>Cancelado</Tag>
                }

                {backoffice?.status &&
                   <Tag color={varColors[backoffice?.status]}>{translate.userStatus(backoffice?.status)}</Tag>
                }
              </div>
            </div>

            {backoffice?.cpf &&
              <p className='back-office-details__header-id'>
                CPF: {mask((backoffice?.cpf as string), 'cpf', true)}
              </p>
            }

            <div className='back-office-details__header-tags '>
              {backoffice?.products && Array.isArray(backoffice?.products) &&
                <>
                  {backoffice?.products.map((product, idx) => {
                    return (
                      <Tag className='mr-0' key={idx}>
                        {translate.productSlug(product)}
                      </Tag>
                    )
                  })}
                </>
              }
            </div>
          </div>
        </div>

        <div className='back-office-details__header-buttons flex w-100'>
          <BackofficeBlockSwitch
            userId={backoffice._id}
            onFinish={onFinish}
            userIsBlocked={backoffice?.blocked || false }
          />

          {!backoffice?.canceled &&
            <BackofficeCancelButton
              userId={backoffice._id}
              onFinish={onFinish}
              className='back-office-details__button'
            />
          }

          <BackofficeProductsButton
            userId={backoffice._id}
            onFinish={onFinish}
            products={backoffice.products || []}
          />
        </div>
      </div>
      <AccessManagerDetails
        groups={accessManagerDetailsGroups}
        schema={schema || []}
        data={backoffice}
      />
    </div>
  )
}

export default AccessManagerBackOfficeDetails

import Checkbox, { CheckboxProps } from 'antd/lib/checkbox/Checkbox'
import React from 'react'

export default function SignupCheckbox ({ children, ...rest }: CheckboxProps) {
  return (
    <Checkbox
      className="signup-checkbox"
      {...rest}
    >
      {children}
    </Checkbox>
  )
}

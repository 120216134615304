import { AxiosRequestConfig } from 'axios'
import { _reviewStatusOption } from 'components/ReviewBackoffice/reviewBackofficeInterfaces'
import { IResponseBase, Repository } from 'ecp/repositories/Repository'
import api from 'services/api'

interface IResponseData {
  [key: string]: any
  department?: string
  step: string
}

interface IDeleteDocument extends IResponseData {
  file: string | object
}

interface IReview extends IResponseData {
  status: _reviewStatusOption
  description: string
}

class PartnersRepository extends Repository<{}> {
  documentation (documentId: string, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/documents/${documentId}`, config)
    )
  }

  review (documentId: string, data: IReview, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.patch(`${this.path}/document/${documentId}`, data, config)
    )
  }

  sendDocument (documentId: string, data: FormData, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.post(`${this.path}/document/upload/${documentId}`, data, config)
    )
  }

  deleteDocument (documentId: string, data: IDeleteDocument, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.post(`${this.path}/document/delete/${documentId}`, data, config)
    )
  }

  send (documentId: string, data: IResponseData, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.patch(`${this.path}/form/${documentId}`, data, config)
    )
  }

  contractEdit (documentId: string, departmentSlug: string, stepSlug: string, data: { slug: string, value: string | undefined }, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.patch(`${this.path}/${documentId}/department/${departmentSlug}/step/${stepSlug}/contract/edit`, data, config)
    )
  }

  finalContract (documentId: string, departmentSlug: string, stepSlug: string, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.get(`${this.path}/${documentId}/department/${departmentSlug}/step/${stepSlug}/contract/final`, config)
    )
  }

  minutaOptions (departmentSlug: string, stepSlug: string, config?: AxiosRequestConfig):Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/minutas/department/${departmentSlug}/step/${stepSlug}`, config)
    )
  }
}
export default new PartnersRepository({ api, path: '/partners' })

import React, { useState } from 'react'

import { Form, Input, message, Select } from 'antd'
import { CloseOutlined, SearchOutlined } from '@ant-design/icons'

import useDebounce from 'hooks/useDebounce'

import format from 'utils/format'

interface ISearchSelectProps {
  onChange: (search?: string, item?: string) => void
  onReset?: () => void
  options: { label: string, value: string }[]
  placeholder: string
  identifier?: string
  label?: string
  large?: boolean
  borderless?: boolean
  autoFocus?: boolean
  value?: string
}

const { Option } = Select

function SearchFilterBySelect (props: ISearchSelectProps) {
  const { options, identifier, placeholder, label, large, borderless, autoFocus, onChange, onReset } = props

  const [item, setItem] = useState<string | undefined>(identifier ?? options[0].value)
  const [errors, setErrors] = useState<{ search?: string }>({})
  const [value, setValue] = useState<string | undefined>(props?.value)

  function handleClear () {
    setValue(undefined)
    onChange('', '')
    if (onReset) onReset()
  }

  function handleSearch ({ value }: { value: string }) {
    if (!item) {
      message.error('Escolha o que deseja pesquisar primeiro!')
      if (onReset) onReset()
      return
    }
    if (item === 'cpf') value = format.onlyDigits(value)
    return setValue(value)
  }

  useDebounce(() => {
    if (value) onChange(value, item)
    if (!value) onChange('', item)
  }, 500, [value, item])

  const inputClass = 'search-filter-by-select__input'
  const classModifiers = {
    large: large ? `${inputClass}__large` : '',
    borderless: borderless ? `${inputClass}__borderless` : ''
  }

  return (
    <div className='search-filter-by-select'>
      {label ? <span>{label ? label : ''}</span> : null}
      <div className='search-filter-by-select__group'>
        <div className='search-filter-by-select__identifier'>
          <Form.Item
            className='w-100'
            name='identifier'
            initialValue={options[0].value}
          >
            <Select
              className='w-100'
              defaultValue={item}
              onSelect={(value) => {
                setItem(value)
                setErrors({})
              }}
            >
              {Array.isArray(options) && options.map((identifier) => (
                <Option
                  key={identifier.value}
                  value={identifier.value}
                >
                  {identifier.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div className='search-filter-by-select__search'>
          <Form.Item
            name="search"
            help={errors?.search && errors?.search}
            validateStatus={errors?.search && 'error'}
          >
            <Input
              autoComplete="off"
              className={`${inputClass} ${classModifiers.large} ${classModifiers.borderless}`}
              placeholder={placeholder ?? 'Pesquisar...'}
              onChange={(e) => handleSearch({ value: e.target.value })}
              onPressEnter={(e) => handleSearch({ value: e.currentTarget.value })}
              suffix={
                <>
                  {value
                    ? <CloseOutlined onClick={() => handleClear()} />
                    : <SearchOutlined className="ml-2" />
                  }
                </>
              }
              autoFocus={autoFocus}
            />
          </Form.Item>
        </div>
      </div>
    </div>
  )
}

export default SearchFilterBySelect

import React, { Fragment } from 'react'
import { Form, Radio } from 'antd'

import DrawerFiltersLineCollapsable, { DrawerFiltersLineCollapsableItem } from 'components/DrawerFilters/DrawerFiltersLineCollapsable'
import ProposalEgiModel from 'egi/models/ProposalEgiModel'
import { useAuth } from 'hooks'

function RemindedFilter () {
  const user = useAuth()
  if (ProposalEgiModel.canSeeRemindedFilter(user.areaName, user.type)) {
    return (
      <div className='proposal-radio-filter'>
        <DrawerFiltersLineCollapsable label='Propostas tombadas'>
          <Form.Item
            name='reminded'
            initialValue={''}
          >
            <Radio.Group>

              <DrawerFiltersLineCollapsableItem key={''}>
                <Radio value={''}>{'Todos'}</Radio>
              </DrawerFiltersLineCollapsableItem>

              <DrawerFiltersLineCollapsableItem key={''}>
                <Radio value={'true'}>{'Tombadas'}</Radio>
              </DrawerFiltersLineCollapsableItem>

              <DrawerFiltersLineCollapsableItem key={''}>
                <Radio value={'false'}>{'Não tombadas'}</Radio>
              </DrawerFiltersLineCollapsableItem>

            </Radio.Group>
          </Form.Item>
        </DrawerFiltersLineCollapsable>
      </div>
    )
  }

  return <Fragment/>
}

export { RemindedFilter }

import { AxiosRequestConfig } from 'axios'
import { IPrognum } from 'components/ReduxModal/types'
import { IStepHistory, IStepHistoryLastNew } from 'components/ReviewBackoffice/reviewBackofficeInterfaces'
import { IPaginate, IResponseBase, Repository } from 'ecp/repositories/Repository'
import { IZipDocumentLog } from 'egi/app/ProposalEgi/components/ZipDocumentsLogModal/ZipDocumentsLogModal'
import { IDocuments } from 'egi/types'
import api from 'services/api'

interface IStepProps {
  proposalId: string
  stepId: string
  limit?: number
}

interface IUpload {
  data: FormData
  proposalId: string
}

interface IDeleteData {
  path: string | object
  stepId: string
}

interface IDeleteDocument extends Omit<IUpload, 'data'> {
  areaId: string
  data: IDeleteData
}

interface IUploadDocument extends IUpload {
  areaId: string
}

export interface IProposalPromoterForm {
  userId: string
}

interface IContact {
  message: string
  type: string
}

class ProposalEgiRepository extends Repository {
  async paths<T = IDocuments> (data: {proposalId: string, stepId: string}, config?: AxiosRequestConfig) {
    return Repository.handle< { document: T[] } >(() =>
      this.api.get(`${this.path}/${data.proposalId}/documents/${data.stepId}/paths`, config)
    )
  }

  async sendRepresentative (data: { proposalId: string, stepId: string, representativesId: string[] }, config?: AxiosRequestConfig) {
    const { proposalId, stepId, representativesId } = data
    return Repository.handle(() =>
      this.api.post(`${this.path}/${proposalId}/step/${stepId}/representative`, { representativesId }, config)
    )
  }

  async history ({ proposalId, stepId, limit }: IStepProps) {
    return Repository.handle<{histories: IStepHistory[]}>(() =>
      this.api.get(`${this.path}/${proposalId}/step/${stepId}/histories`, {
        params: {
          limit: limit && limit
        }
      })
    )
  }

  async uploadDocument (data: IUploadDocument, config?: AxiosRequestConfig) {
    return Repository.handle<{ file: { url: string, name: string } }>(() =>
      this.api.patch(`${this.path}/${data.proposalId}/documents/${data.areaId}`, data.data, config)
    )
  }

  async deleteDocument (data: IDeleteDocument, config?: AxiosRequestConfig) {
    return Repository.handle<IResponseBase>(() =>
      this.api.post(`${this.path}/${data.proposalId}/documents/${data.areaId}/delete`, data.data, config)
    )
  }

  async uploadDefense (data: IUpload, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.patch(`${this.path}/${data.proposalId}/defense`, data.data, config)
    )
  }

  async deleteDefense (data: Partial<IDeleteDocument>, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.post(`${this.path}/${data.proposalId}/defense/remove`, data.data, config)
    )
  }

  async list (config?: AxiosRequestConfig):Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/`, config)
    )
  }

  async review (proposalId: string, areaId: string, data: FormData, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.post(`${this.path}/${proposalId}/departments/${areaId}/review`, data, config)

    )
  }

  async proposalHistory (proposalId: string, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle<{proposal: Array<any>}>(() =>
      this.api.get(`${this.path}/${proposalId}/history`, config)
    )
  }

  // Contact

  async chatContact (proposalId: string, data: IContact, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle<{proposal: Array<any>}>(() =>
      this.api.post(`${this.path}/${proposalId}/contact`, data, config)
    )
  }

  async chatHistory (proposalId: string, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle<{proposal: Array<any>}>(() =>
      this.api.get(`${this.path}/${proposalId}/history`, config)
    )
  }

  // PROGNUM
  async createPrognum (body: { startDate?: string, endDate?: string, onlyApproved: boolean }, config?: AxiosRequestConfig) {
    return Repository.handle<{
      docIds: string[]
    }>(() =>
      this.api.post(`${this.path}/prognum`, body, config)
    )
  }

  async getPrognum (config?: AxiosRequestConfig) {
    return Repository.handle<{
      prognum: {
        documents: IPrognum[]
      }
    }>(() =>
      this.api.get(`${this.path}/prognum`, config)
    )
  }

  async revalidate (id: string, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.post(`${this.path}/${id}/revalidate`, config)
    )
  }

  async redirect (id: string, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.post(`${this.path}/${id}/revalidate/redirect`, config)
    )
  }

  async changeCommercial (proposalId: string, data: { commercialId: string, reminded?: boolean }, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.put(`${this.path}/${proposalId}/replace/commercial/attendant`, data, config)
    )
  }

  async commercialCloseAttendant (proposalId: string, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.patch(`${this.path}/${proposalId}/commercial/close`, config)
    )
  }

  async commercialHistory (proposalId: string, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/${proposalId}/commercial-attendant/history`, config)
    )
  }

  async downloadZipDocuments (proposalId: string, data: {departmentId: string, receiveMethod: 'email'| 'download' }, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.post(`${this.path}/zip/${proposalId}`, data, config)
    )
  }

  async seeProcessZipDocuments (proposalId: string, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/zip/${proposalId}`, config)
    )
  }

  async zipLogs (config?: AxiosRequestConfig) {
    return Repository.handle<IPaginate<IZipDocumentLog>>(() =>
      this.api.get(`${this.path}/zip/logs`, config)
    )
  }

  async changeProposalPromoter (proposalId: string, body: IProposalPromoterForm, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.post(`${this.path}/change-promoter/${proposalId}`, body, config)
    )
  }

  async findHistoryChangeForm (historyId?: string, config?: AxiosRequestConfig) {
    return Repository.handle<{ propertyChanges: {new: IStepHistoryLastNew, last: IStepHistoryLastNew}}>(() =>
      this.api.get(`${this.path}/history/${historyId}`, config)
    )
  }

  async accessClientDetails (config?: AxiosRequestConfig) {
    return Repository.handle<{proposal: Array<any>}>(() =>
      this.api.get(`${this.path}/access-manager/clients/details`, config)
    )
  }
}

export default new ProposalEgiRepository({ api, path: '/proposals' })

import { AxiosRequestConfig } from 'axios'
import { Repository } from 'ecp/repositories/Repository'
import api from 'services/api'

interface IBasePermission {
  _id: string
  name: string
  slug: string
}

export interface IUserPermission extends IBasePermission {
  userPermissionId: string
}

export interface IPermission extends IBasePermission {
  _id: string
  name: string
  code: number
  description: string
}

interface IVinculatePermissionResponse {
  _id: string
  userId: string
  permissionId: string
}

class PermissionsRepositoryDef extends Repository<IPermission> {
  fetchAll (config?: AxiosRequestConfig) {
    return Repository.handle<{ permissions: Array<IPermission> }>(() =>
      this.api.get(this.path, config)
    )
  }
}

class UserPermissionsRepositoryDef extends Repository<IUserPermission> {
  getAllByUser (userId: string, config?: AxiosRequestConfig) {
    return Repository.handle<{ permissions: Array<IUserPermission> }>(() =>
      this.api.get(`${this.path}/user/${userId}`, config)
    )
  }

  vinculate (body: { permissionId: string, userId: string }, config?: AxiosRequestConfig) {
    return Repository.handle<IVinculatePermissionResponse>(() =>
      this.api.post(`${this.path}`, body, config)
    )
  }

  delete (userPermissionId: string, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.delete(`${this.path}/${userPermissionId}`, config)
    )
  }
}

const PermissionsRepository = new PermissionsRepositoryDef({ api, path: '/permissions' })
const UserPermissionsRepository = new UserPermissionsRepositoryDef({ api, path: '/user-permissions' })

export {
  PermissionsRepository,
  UserPermissionsRepository
}

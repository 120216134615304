import React, { useEffect, useState } from 'react'
import { useStep } from 'hooks'
import { useSelector } from 'react-redux'
import { RootState } from 'store/modules/rootReducer'

import { Row, Col, Input, Button, Collapse } from 'antd'

import { spreadsheet } from '../../../egi/consumers'
import swal from 'utils/swal'
import InputMoney from 'components/inputMoney/inputMoneySpreeds'

import format from 'utils/format'

const { Panel } = Collapse

interface IColumn {
  label: string
  value: number[]
}

function SpreadsheetIRPF ({ type }: { type: string }) {
  const client = useSelector((state: RootState) => state.client)
  const proposal = useSelector((state: RootState) => state.proposal)
  const step = useStep()

  const [column1, setColumn1] = useState<IColumn>({ label: 'RECEITA', value: [...(new Array(5))] })
  const [column2, setColumn2] = useState<IColumn>({ label: 'BENS E DIREITOS', value: [...(new Array(4))] })
  const [column3, setColumn3] = useState<IColumn>({ label: 'OBRIGAÇÕES', value: [...(new Array(4))] })

  const [loading, setLoading] = useState(false)
  const [spreadsheetId, setSpreadsheetId] = useState<string>()
  const [open, setOpen] = useState(false)

  function getTotal (column: IColumn) {
    return column.value.reduce(function (total, value) {
      if (value) return total + value
      return total
    }, 0)
  }

  async function sendSpreadsheet () {
    if (!proposal.id) { return }

    const data = {
      columns: [column1, column2, column3],
      type: type,
      work: ''
    }

    setLoading(true)
    try {
      const response = await spreadsheet.save(proposal.id, data)
      if (response) {
        setSpreadsheetId(response.calculationId)
      }
      setLoading(false)
      swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
    } catch (err) {
      if (err) { swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' }) }
      console.log(err)
      setLoading(false)
    }
  }

  async function updateSpreadsheet () {
    if (!proposal.id) { return }
    if (!spreadsheetId) { return }
    const data = {
      columns: [column1, column2, column3],
      type: type,
      work: ''
    }

    setLoading(true)
    try {
      const response = await spreadsheet.update(proposal.id, spreadsheetId, data)
      setLoading(false)
      swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
    } catch (err) {
      if (err) { swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' }) }
      console.log(err)
      setLoading(false)
    }
  }

  async function getSpreadsheet () {
    if (!proposal.id) { return }
    setLoading(true)
    try {
      const response = await spreadsheet.get(proposal.id, type)
      const columns = response.columns
      if (response) { setSpreadsheetId(response._id) }
      if (columns) {
        setColumn1(columns[0])
        setColumn2(columns[1])
        setColumn3(columns[2])
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  function cleantable () {
    setColumn1({ label: 'RECEITA', value: [...(new Array(5))] })
    setColumn2({ label: 'BENS E DIREITOS', value: [...(new Array(4))] })
    setColumn3({ label: 'OBRIGAÇÕES', value: [...(new Array(4))] })
  }

  function getFinalTotal () {
    return getTotal(column2) - getTotal(column3)
  }

  useEffect(() => {
    cleantable()
    getSpreadsheet()
  }, [step.id])

  return (
    <Collapse onChange={() => { setOpen(!open) }} className='mb-3'>
      <Panel
        header="Calculadora"
        key="1"
        extra={
          open && <Button loading={loading} type='primary' onClick={(e) => { !loading && (spreadsheetId ? updateSpreadsheet() : sendSpreadsheet()); e.stopPropagation() }}>Salvar</Button>
        }
      >
        <Row className='flex-1'>
          <Col span={24} className='cell-primary'>
            DECLARAÇÃO IRPF
          </Col>
        </Row>
        <Row className='flex-1 spreadsheet-ir'>
          <Col>

            <Row className='flex-1 '>
              <Col span={24} className='cell-secundary'>
                NOME CLIENTE: {client?.name}
              </Col>
            </Row>
            <Row className='flex-1' justify="center" align='middle'>
              <Row className='flex-1'>
                <Col span={6}>
                  <Col className="cell cell-title" span={24}>
                    <p>RECEITA </p>
                  </Col>
                  <Col className="cell cell-title" span={24}>
                    <p>R TRIBUTÁVEIS  </p>
                  </Col>
                  <Col className="cell cell-title" span={24}></Col>
                  <Col className="cell cell-title" span={24}>
                    <p>R ISENTOS </p>
                  </Col>
                  <Col className="cell cell-title" span={24}></Col>
                  <Col className="cell cell-title" span={24}></Col>
                  <Col className="cell cell-secundary" span={24}></Col>

                </Col>
                <Col span={6}>
                  <Col className="cell cell-value-final" span={24}>
                    <Input
                      disabled={loading}
                      value={format.formatBRL(getTotal(column1)) || ' '} />
                  </Col>
                  {column1.value.map((cell, row) => (
                    <Col key={row} className="cell" span={24}>
                      <InputMoney disabled={loading} value={column1?.value[row] || undefined} onChange={(e) => { column1.value[row] = Number(format.currencyParser(e.target.value)); setColumn1({ label: column1.label, value: column1?.value }) }} />
                    </Col>
                  ))}
                  <Col className="cell cell-secundary" span={24}></Col>

                </Col>

                <Col span={6}>
                  <Col className="cell cell-title" span={24}><p>PATRIMÔNIO</p></Col>
                  <Col className="cell cell-title" span={24}></Col>
                  <Col className="cell cell-title" span={24}></Col>
                  <Col className="cell cell-title" span={24}></Col>
                  <Col className="cell cell-title" span={24}></Col>
                  <Col className="cell cell-title" span={24}></Col>
                  <Col className="cell cell-secundary" span={24}></Col>

                </Col>
                <Col span={6}>
                  <Col className="cell cell-value-final" span={24}>
                    <Input disabled={loading} value={format.formatBRL(getFinalTotal()) || ' '} />
                  </Col>
                  {column1.value.map((cell, row) => (
                    <Col key={row} className="cell" span={24}>
                      <Input disabled={loading} type="number" value={undefined} />
                    </Col>
                  ))}
                  <Col className="cell cell-secundary" span={24}></Col>

                </Col>

                <Col span={6}>
                  <Col className="cell cell-title" span={24}><p>BENS E DIREITOS </p></Col>
                  <Col className="cell cell-title" span={24}> </Col>
                  <Col className="cell  cell-normal-text" span={24}><p>Veículo </p></Col>
                  <Col className="cell  cell-normal-text" span={24}><p>Imóveis </p></Col>
                  <Col className="cell  cell-normal-text" span={24}><p>Saldo em Conta  </p></Col>
                  <Col className="cell  cell-normal-text" span={24}><p>Outros </p></Col>
                </Col>
                <Col span={6}>
                  <Col className="cell cell-value-final" span={24}>
                    <Input disabled={loading} value={format.formatBRL(getTotal(column2)) || ' '} />
                  </Col>
                  <Col className="cell" span={24}> </Col>

                  {column2.value.map((cell, row) => (
                    <Col key={row} className="cell" span={24}>
                      <InputMoney
                        disabled={loading}
                        value={column2?.value[row] || undefined}
                        onChange={(e) => {
                          column2.value[row] = Number(format.currencyParser(e.target.value))
                          setColumn2({ label: column2.label, value: column2?.value })
                        }}
                      />
                    </Col>
                  ))}
                </Col>

                <Col span={6}>
                  <Col className="cell cell-title" span={24}><p>we </p></Col>
                  <Col className="cell cell-title" span={24}></Col>
                  <Col className="cell cell-normal-text" span={24}><p>Empréstimos</p> </Col>
                  <Col className="cell cell-normal-text" span={24}><p>Empréstimos</p> </Col>
                  <Col className="cell cell-normal-text" span={24}><p>Empréstimos</p> </Col>
                  <Col className="cell cell-normal-text" span={24}><p>Empréstimos</p> </Col>
                </Col>
                <Col span={6}>
                  <Col className="cell cell-value-final" span={24}>
                    <Input disabled={loading} value={format.formatBRL(getTotal(column3)) || ' '} />
                  </Col>
                  <Col className="cell" span={24}> </Col>

                  {column3.value.map((cell, row) => (
                    <Col key={row} className="cell" span={24}>
                      <InputMoney
                        disabled={loading}
                        value={column3?.value[row] || undefined}
                        onChange={(e) => {
                          column3.value[row] = Number(format.currencyParser(e.target.value)); setColumn3({ label: column3.label, value: column3?.value })
                        }}
                      />
                    </Col>
                  ))}
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>

      </Panel>
    </Collapse >
  )
}

export default SpreadsheetIRPF

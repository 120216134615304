import React, { useEffect, useState } from 'react'
import { Button, message, Radio, Form, Skeleton } from 'antd'
import CustomSortRepository, { ICustomSort } from 'egi/repositories/CustomSortRepository'
import { useAuth } from 'hooks'
import { useForm } from 'antd/lib/form/Form'
import translate from 'utils/translate'
import { useDispatch } from 'react-redux'
import { tablesUpdate } from 'store/modules/tables/actions'
import { validateResponse } from 'utils/validate'

const CurrentSortConfiguration = ({ customSort, loading }: { customSort: ICustomSort | undefined, loading: boolean}) => {
  if (loading) {
    return <>
      <Skeleton.Input active size="small"/>
      <Skeleton.Button active size='large' className="sort-configuration__loading-input w-100" />
    </>
  }
  if (!customSort) {
    return (
      <h3 className='sort-configuration__primary-focus'>
        Adicionar ordenação padrão
      </h3>
    )
  }

  const { key, value } = customSort

  return (
    <div className="sort-configuration__value-container">
      <label
        className="sort-configuration__value-label"
      >
        Ordenação Atual:
      </label>

      <div className="sort-configuration__value-show sort-configuration__primary-focus">
        {translate.sortConfigurationProperty(key)} {translate.sortConfigurationOrder(value)}
      </div>
    </div>
  )
}

export function SortConfiguration () {
  const user = useAuth()
  const [form] = useForm<ICustomSort>()
  const dispatch = useDispatch()

  const [customSort, setCustomSort] = useState<ICustomSort>()
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>()
  const [errors, setErrors] = useState<Partial<ICustomSort>>({})

  const handleFillValues = (customSort: {
    [key: string]: string
  }) => {
    const sort = Object.entries(customSort)
    const formattedSort = sort.map(([key, value]) => {
      return {
        key,
        value: value?.toString()
      }
    })

    form?.setFieldsValue?.(formattedSort[0] as ICustomSort)
    setCustomSort(formattedSort[0] as ICustomSort)

    return formattedSort
  }

  const getCustomSort = async () => {
    setLoading(true)
    try {
      const response = await CustomSortRepository.getCustomSort(user._id)
      handleFillValues(response?.data?.data.document)
    } catch (err) {
      if (err) setCustomSort(undefined)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getCustomSort()
  }, [])

  const addCustomSort = async (values: ICustomSort) => {
    setLoadingSubmit(true)
    try {
      await CustomSortRepository.addCustomSort(user._id, values)
      dispatch(tablesUpdate())
      getCustomSort()
      setErrors({})
    } catch (err) {
      if (err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
      }

      message.error(err.message)
    } finally {
      setLoadingSubmit(false)
    }
  }

  const updateCustomSort = async (values: ICustomSort) => {
    setLoadingSubmit(true)
    try {
      await CustomSortRepository.changeCustomSort(user._id, values)
      getCustomSort()
      dispatch(tablesUpdate())
      setErrors({})
    } catch (err) {
      if (err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
      }

      message.error(err.message)
    } finally {
      setLoadingSubmit(false)
    }
  }

  return (
    <>
      <CurrentSortConfiguration
        loading={loading}
        customSort={customSort}
      />

      <p className="mb-5">
        É posível configurar sua <b>ordenação padrão</b>. Faça sua escolha e salve,
        em próximas consultas sua ordenação será selecionada assim que você entrar na página.
      </p>

      <Form
        form={form}
        onFinish={customSort ? updateCustomSort : addCustomSort}
      >
        <h3 className='sort-configuration__primary-focus'>
          Propriedade de ordenação:
        </h3>

        <Form.Item
          name="key"
          help={errors.key}
          validateStatus={errors.key && ('error')}
        >
          <Radio.Group
            className="sort-configuration__radio">
            <Radio
              value="createdAt"
              className='sort-configuration__radio-item'>
              Data Criação
            </Radio>
            <Radio
              value="proposalExpiration"
              className='sort-configuration__radio-item'>
              Data Expiração
            </Radio>
            <Radio
              value="updatedAt"
              className='sort-configuration__radio-item'>
              Última atualização
            </Radio>
          </Radio.Group>
        </Form.Item>
        <h3 className='sort-configuration__primary-focus mt-5'>
          Modo de ordenção:
        </h3>
        <Form.Item
          name="value"
          help={errors.value}
          validateStatus={errors.value && ('error')}
        >
          <Radio.Group
            className="sort-configuration__radio">
            <Radio
              value="1"
              className='sort-configuration__radio-item'>
              Ascendente
            </Radio>
            <Radio
              value="-1"
              className='sort-configuration__radio-item'>
              Descendente
            </Radio>
          </Radio.Group>
        </Form.Item>

        <div className="text-right mt-3" >
          <Button
            loading={loadingSubmit}
            htmlType="submit"
            type="primary">
            Salvar
          </Button>
        </div>
      </Form>
    </>
  )
}

import React, { useState } from 'react'
import { Checkbox, Form, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { IError } from 'ecp/app/Proposals/proposalInterfaces'
import SignupLayout from 'layouts/SignupLayout/SignupLayout'
import { UNAUTHS_PATHS } from 'routes/unauthRoutes'
import { Input } from 'components'
import { useHistory, useParams } from 'react-router-dom'
import { ISignupClientRequestPhoneToken, ISignupTokenInfo } from 'ecp/app/SignUpClient/signUpClientInterfaces'
import format from 'utils/format'
import useFirstAccessTokenInfo from '../../hooks/useFirstAccessTokenInfo'
import { handleOnidataErrors } from 'utils/validate'
import { IFirstAccessSendPhone, IFirstAccessTokenInfo } from '../../firstAccesInterfaces'
import LoginRepository from 'ecp/repositories/LoginRepository'
import SignupButton from 'egi/app/Signup/components/SignupButton/SignupButton'
import { SignupTitle } from 'egi/app/Signup/components/SignupTitle/SignupTitle'
import { swalError } from 'components/SwalError/SwalError'

export default function FirstAccesClientPhone () {
  const [form] = useForm<ISignupClientRequestPhoneToken>()
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [errors, setErrors] = useState<IError<ISignupClientRequestPhoneToken>>({})

  const { tokenJwt } = useParams<{ tokenJwt?: string }>()
  const { tokenInfo } = useFirstAccessTokenInfo()
  const history = useHistory()

  function redirectToNextStep (token: string, tokenInfo: IFirstAccessTokenInfo) {
    history.push({
      pathname: `${UNAUTHS_PATHS.FIRST_ACCESS_CLIENT_PHONE_TOKEN.replace(':tokenJwt', String(token))}`,
      state: tokenInfo
    })
  }

  function handleSubmit (token: string) {
    return async function (values: IFirstAccessSendPhone) {
      setLoadingSubmit(true)

      const requestPhoneData: IFirstAccessSendPhone = {
        ...values,
        onidataToken: token
      }

      if (requestPhoneData.telefone_celular) format.onlyDigits(requestPhoneData.telefone_celular)

      try {
        const response = await LoginRepository.sendPhoneToken(requestPhoneData)

        const updatedTokenInfo: ISignupTokenInfo = {
          ...tokenInfo,
          cellphone: requestPhoneData.telefone_celular,
          celular_eh_whatsapp: requestPhoneData.celular_eh_whatsapp
        }

        if (response.data.message) message.success(response.data.message)
        redirectToNextStep((token), updatedTokenInfo)
      } catch (err) {
        if (err?.data && err?.data?.error) setErrors(handleOnidataErrors(err.data.error))
        swalError({ title: 'Atenção', err, icon: 'warning' })
      } finally {
        setLoadingSubmit(false)
      }
    }
  }

  return (
    <SignupLayout>

      <SignupTitle className='mb-1'>
        Informe seu celular
      </SignupTitle>

      <Form
        onFinish={handleSubmit(String(tokenJwt))}
        form={form}
        layout="vertical"
        className='unauth-form'
      >
        <p className='signup-check-link__paragraph mb-4'>Vamos confirmar seu celular para finalizar seu cadastro</p>

        <Form.Item
          name='telefone_celular'
          label='Celular'
          help={errors?.telefone_celular}
          validateStatus={errors?.telefone_celular && ('error')}
        >
          <Input
            className='unauth-inputs'
            placeholder='Insira o celular'
            mask='cell'
          />
        </Form.Item>
        <Form.Item
          name='celular_eh_whatsapp'
          help={errors?.celular_eh_whatsapp}
          validateStatus={errors?.celular_eh_whatsapp && ('error')}
          valuePropName='checked'
          initialValue={false}
        >
          <Checkbox>Possui whatsapp?</Checkbox>
        </Form.Item>
        <Form.Item>
          <SignupButton
            loading={loadingSubmit}
            htmlType='submit'
            className='w-100'
          >
            Enviar
          </SignupButton>
        </Form.Item>
      </Form>
    </SignupLayout>
  )
}

import { IDataInfos, ISimulationHistory } from 'components/SimulationCard/types'
import format from 'utils/format'
import { timeAsDayjs } from 'utils/time'

export const loanDataPricing = (item: ISimulationHistory): IDataInfos[] => [
  ...(item.last.proposalExpirationDate && item.new.proposalExpirationDate ? [{ title: 'Validade da proposta', lastItem: timeAsDayjs(item.last.proposalExpirationDate, { applyTimezone: false }).format('DD/MM/YYYY'), newItem: timeAsDayjs(item.new.proposalExpirationDate, { applyTimezone: false }).format('DD/MM/YYYY') }] : []),
  { title: 'Sistema de amortização', lastItem: item.last.amortizationType, newItem: item.new.amortizationType },
  { title: 'Custos de registro', lastItem: item.last.hasRegister ? 'Incluído' : 'Não incluído', newItem: item.new.hasRegister ? 'Incluído' : 'Não incluído' },
  { title: 'Valor do empréstimo', lastItem: format.formatBRL(item.last.creditValue), newItem: format.formatBRL(item.new.creditValue) },
  { title: 'Prazo (meses)', lastItem: item.last.deadline, newItem: item.new.deadline },
  { title: 'Indexador', lastItem: item.last.index, newItem: item.new.index },
  { title: 'Valor financiado', lastItem: format.formatBRL(item.last.financing), newItem: format.formatBRL(item.new.financing) },
  { title: 'Valor total de juros', lastItem: format.formatBRL(item.last.totalInterest), newItem: format.formatBRL(item.new.totalInterest) },
  { title: 'Taxa de juros mensal', lastItem: format.decimalToPercentage(item.last.taxes.monthlyTax).toFixed(2) + '%', newItem: format.decimalToPercentage(item.new.taxes.monthlyTax).toFixed(2) + '%' },
  { title: 'Taxa de juros anual', lastItem: format.decimalToPercentage(item.last.taxes.anualTax).toFixed(2) + '%', newItem: format.decimalToPercentage(item.new.taxes.anualTax).toFixed(2) + '%' },
  { title: 'Valor de crédito líquido', lastItem: format.formatBRL(item.last.liquid), newItem: format.formatBRL(item.new.liquid) },
  { title: 'Valor total a pagar', lastItem: format.formatBRL(item.last.totalValue), newItem: format.formatBRL(item.new.totalValue) }
]

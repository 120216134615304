import { Empty, Input, Radio, Spin } from 'antd'
import { RadioGroupProps } from 'antd/lib/radio'
import { AxiosRequestConfig } from 'axios'
import { DrawerFiltersLineCollapsableItem } from 'components/DrawerFilters/DrawerFiltersLineCollapsable'
import { SimplePagination } from 'ecpf/components/SimplePagination/SimplePagination'
import React, { useEffect } from 'react'
import { useGenericOrigenOptions } from './hooks/useGenericOrigenOptions'

export function PreviewRadioErrorNotFound ({ isError, errorMessage }: { isError: boolean, errorMessage?: string }) {
  if (isError) <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={errorMessage || 'Falha ao buscar valores.'} />
  return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Nenhuma opção foi encontrada.' />
}

interface IGenericOrignRadioECPFProps extends RadioGroupProps {
  path: string
}

export default function GenericOriginRadioECPF ({ path, ...rest }: IGenericOrignRadioECPFProps) {
  const { fetchGenericOrigenOptions, options, loading, page, limit, setPage, errorMessage, descricao, updateDescricao } = useGenericOrigenOptions(path)
  const defaultOption = { value: '', label: 'Todos' }

  const onFecthOptions = (page: number, descricao?: string) => {
    const abortController = new AbortController()
    const requestConfig: AxiosRequestConfig = {
      signal: abortController.signal,
      params: { offset: page, descricao }
    }

    fetchGenericOrigenOptions(abortController, requestConfig)
  }

  const onNextPage = () => {
    const newPage = page + 1
    setPage(newPage)
    onFecthOptions(newPage, descricao)
  }

  const onPreviousPage = () => {
    const newPage = page - 1
    setPage(newPage)
    onFecthOptions(newPage)
  }

  useEffect(() => {
    const abortController = new AbortController()
    onFecthOptions(page, descricao)
    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <>
      <Input.Search
        className="table-filter generic-origin-radio-ecpf__input-search"
        placeholder='Pesquise por nome'
        onSearch={(value) => {
          updateDescricao(value)
          onFecthOptions(page, value)
        }}
      />

      <Spin spinning={loading}>
        {errorMessage ? (
          <PreviewRadioErrorNotFound
            isError
            errorMessage={errorMessage}
          />
        ) : (
          <>
            <Radio.Group
              {...rest}
            >
              {[defaultOption, ...options].map((option, key) => {
                return (
                  <DrawerFiltersLineCollapsableItem key={key}>
                    <Radio
                      style={{ width: '100%' }}
                      value={option.value}
                    >
                      {option.value ? `${option.value} - ` : ''} {option.label}
                    </Radio>
                  </DrawerFiltersLineCollapsableItem>
                )
              })}
            </Radio.Group>

            <SimplePagination
              className="generic-origin-radio-ecpf__pagination"
              limit={limit}
              page={page}
              hasNextPage={(options ?? []).length >= limit}
              onNextPage={onNextPage}
              onPreviousPage={onPreviousPage}
            />
          </>
        )}
      </Spin>
    </>
  )
}

import React, { Fragment } from 'react'
import { ColumnsType } from 'antd/lib/table'
import { Button, Space, Tag, Tooltip } from 'antd'
import { BookOutlined, EditFilled, InfoCircleFilled, SolutionOutlined } from '@ant-design/icons'
import Contact from 'components/ContactToClient/ContactToClient'
import { Link } from 'react-router-dom'
import translate from 'utils/translate'
import { useAuth, useResources, useTables } from 'hooks'
import swal from 'utils/swal'
import format from 'utils/format'
import { analysisColors, varColors } from 'styles/colors'
import dayjs, { Dayjs } from 'dayjs'
import Swal from 'sweetalert2'
import { timeAsDayjs } from 'utils/time'
import { proposals } from 'egi/consumers'
import { useDispatch } from 'react-redux'
import { drawerFillForms } from 'store/modules/drawer/actions'
import { tablesSetRowValues, tablesUpdate } from 'store/modules/tables/actions'
import { modalFillValues } from 'store/modules/modal/actions'
import { IReduxModal } from 'store/modules/modal/types'
import { setNeedVinculation } from 'store/modules/proposal/actions'
import { slaColors } from 'utils/globals'
import { IProposal, IProposalColumns, IUsersLinkedProposal, _proposalStatuses } from '../../proposalEgiInterfaces'
import { ISLA } from 'egi/app/Settings/views/SLAConfig/slaInterfaces'
import ProposalEgiModel from 'egi/models/ProposalEgiModel'
import ProposalEgiRepository from 'egi/repositories/ProposalEgiRepository'
import { OverflowTooltip } from 'components/OverflowToolTip/OverflowTooltip'

export const IntegrationTooltip = ({ title }: { title: string }) => (
  <Tooltip title={title}>
    <InfoCircleFilled style={{ color: 'var(--analysis-status-color)' }} />
  </Tooltip>
)

export interface ICommercial {
  email: string
  name: string
  _id: string
}

const commercial = (canSeeCommercial: boolean, canEditCommercial: boolean, showModalCommercial?: (commercial: ICommercial, leadId: string) => void): Array<object> => {
  if (!canSeeCommercial) return []
  return [{
    title: 'Atendente comercial',
    width: '200px',
    dataIndex: 'reminderCommercial',
    render: (item: ICommercial, row: any) => {
      if (!item) return 'N/A'

      if (canEditCommercial) {
        return <p className="link-style color-tertiary font-500 capitalize" onClick={() => showModalCommercial && showModalCommercial(item, (row as any)._id)}>{row?.reminderCommercial?.name} &rarr;</p> || 'N/A'
      }

      return row?.commercial?.name
    }
  }]
}

function reminderTelemarketing ({ canSeeReminderTelemarketing }: { canSeeReminderTelemarketing: boolean }) {
  if (!canSeeReminderTelemarketing) return []

  return [{
    title: 'Tombada',
    dataIndex: 'reminderTelemarketingName',
    sorter: false,
    width: 100,
    ellipsis: true,
    render: (tm: string) => {
      if (!tm) return '-'
      return (
        <OverflowTooltip width={75}>
          {tm}
        </OverflowTooltip>
      )
    }
  }]
}

const usersLinkedProposal = ({
  showModalChangeUser,
  notInternal = false,
  canEditCommercial = false,
  canEditTm = false,
  canSeeReminderTelemarketing = false,
  canChangeProposalPromoter = false,
  showChangePromoterModal
}: IUsersLinkedProposal) => {
  if (notInternal) return []

  return [
    {
      title: 'Parceiro',
      dataIndex: 'promoter',
      key: 'producer',
      ellipsis: false,
      width: 145,
      render: (promoter: { name: string, type: string, code: number, _id: string }, row: any) => {
        if (!promoter?.name) return '-'
        const proposal = row as IProposal
        const proposalId = proposal._id

        const promoterName = `${promoter?.type || '-'} - ${promoter?.name || '-'}`

        if (promoter.type === 'TM' && canEditTm) {
          <Tooltip title={promoterName} destroyTooltipOnHide={{ keepParent: false }}>
            <p
              className="link-style color-tertiary font-500 mb-0 table-name"
              onClick={() => showModalChangeUser({ level: 'telemarketing', user: promoter, proposalId })}
            >
              {promoter.type} - {promoter.name}
            </p>
          </Tooltip>
          return
        }

        return (
          <p className='flex'>
            <Tooltip title={promoterName} destroyTooltipOnHide={{ keepParent: false }}>
              <span className="table-name overflow-ellipsis">{promoterName}</span>
            </Tooltip>
            {canChangeProposalPromoter && (
              <EditFilled className='ml-1' onClick={() => showChangePromoterModal(proposalId, proposal.number, proposal.promoterId)}/>
            )}
          </p>
        )
      }
    },
    ...reminderTelemarketing({ canSeeReminderTelemarketing }),
    {
      title: 'Consultor',
      dataIndex: 'consultant',
      key: 'consultant',
      sorter: true,
      width: 100,
      ellipsis: true,
      render: (consultant: { id: string, name: string }, row: any) => {
        if (!consultant.name) return '-'

        const proposalId = row._id
        if (canEditCommercial) {
          return (
            <OverflowTooltip width={80}>
              <span
                onClick={() => showModalChangeUser({ level: 'consultant', user: consultant, proposalId })}
                className='link-style color-tertiary font-500 mb-0 table-name'
              >
                {consultant.name}
              </span>
            </OverflowTooltip>
          )
        }

        return (
          <OverflowTooltip width={80}>
            <span className="table-name">{consultant?.name}</span>
          </OverflowTooltip>
        )
      }
    },
    {
      title: 'Suporte',
      dataIndex: 'support',
      key: 'support',
      sorter: true,
      width: 125,
      ellipsis: true,
      render: (support: { id: string, name: string }, row: any) => {
        if (!support?.name) return '-'
        const proposalId = row._id

        if (canEditCommercial) {
          return (
            <OverflowTooltip width={100}>
              <span
                className="link-style color-tertiary font-500 mb-0 table-name"
                onClick={() => showModalChangeUser({ level: 'commercial', user: support, proposalId })}
              >
                {support.name}
              </span>
            </OverflowTooltip>
          )
        }

        return (
          <OverflowTooltip width={100}>
            <span className="table-name">{support?.name}</span>
          </OverflowTooltip>
        )
      }
    }
  ]
}

function slaColumn ({ canSeeSLAColumns }: { canSeeSLAColumns: boolean }) {
  if (!canSeeSLAColumns) return []

  return [{
    title: 'SLA',
    dataIndex: 'SLA',
    key: 'SLA',
    width: 50,
    ellipsis: true,
    render: (SLA: { SLA?: ISLA, areaId: string }) => {
      if (!SLA || !SLA.SLA) return <h4 className="text-center">-</h4>

      const resources = useResources()
      const areas = [SLA.areaId]
      const finalAreas: any = []
      const diffTime = (timeAsDayjs(dayjs(SLA.SLA?.max)).diff(timeAsDayjs(dayjs()), 'days'))
      const diffTimeEnd = (timeAsDayjs(dayjs(SLA.SLA?.max)).diff(timeAsDayjs(dayjs(SLA.SLA?.end)), 'days'))

      if (resources.areas && SLA.areaId) {
        areas.forEach((item) => {
          const areas = resources.areas.filter(element => element.value === item)
          finalAreas.push(...areas)
        })
        const onlyText = (finalAreas.map((item: any) => [format.capitalize(item.label)]))

        if (SLA.SLA?.end) {
          return (
            <Button
              type="link"
              onClick={() => {
                Swal.fire({
                  title: 'SLA - Finalizado',
                  html: `
                  <b>Área de atuação</b>: ${(format.separeteValuesByComma(onlyText) as string)}
                  <br/><br/>
                  <b>Data de fim</b>: ${timeAsDayjs(SLA.SLA?.end).format('DD-MM-YYYY')}
                  <br/><br/>
                  <b>Data máxima</b>: ${timeAsDayjs(SLA.SLA?.max).format('DD-MM-YYYY')}
                  `
                })
              }}
            >
              <div className="crivo-status-circle mr-1" style={{ backgroundColor: slaColors(diffTimeEnd) }} />
            </Button>
          )
        }

        return (
          <Button
            type="link"
            onClick={() => {
              Swal.fire({
                title: 'SLA - Em andamento',
                html: `
                <b>Área de atuação</b>: ${(format.separeteValuesByComma(onlyText) as string)}
                <br/><br/>
                <b>Data de início</b>: ${timeAsDayjs(SLA.SLA?.actionAt).format('DD-MM-YYYY')}
                <br/><br/>
                <b>Data máxima</b>: ${timeAsDayjs(SLA.SLA?.max).format('DD-MM-YYYY')}
                <br/><br/>
                <b>Dias restantes</b>: ${diffTime}
                `
              })
            }}
          >
            <div className="crivo-status-circle" style={{ backgroundColor: slaColors(diffTime) }} />
          </Button>
        )
      }
    }
  }]
}

function canSeeCurrencyStep ({ areaNameUser }: { areaNameUser: boolean }) {
  if (!areaNameUser) return []

  type ICurrentStep = {
    areaId: string
    steps: Array<string>
  }

  return [{
    title: 'Pendência',
    dataIndex: 'currentStep',
    width: 100,
    ellipsis: true,
    render: (areas: Array<ICurrentStep>) => {
      if (!areas) return '-'
      if (areas && areas.length < 1) return '-'

      const finalSteps = areas.map(item => item.steps).flat()
      if (finalSteps.length < 1) return '-'
      if (finalSteps.length === 1) return finalSteps

      return (
        <p
          className="link-style color-tertiary font-500 mb-0"
          onClick={() => {
            swal.basic({
              title: 'Pendência',
              text: (format.separeteValuesByComma(finalSteps) as string)
            })
          }}
        >
          VER MAIS
        </p>
      )
    }
  }]
}

function engenhariaOnlyStuffs ({ canSeeProviderColumns, seeOnlyProviderDate }: { canSeeProviderColumns: boolean, seeOnlyProviderDate: boolean }) {
  if (!canSeeProviderColumns && !seeOnlyProviderDate) return []

  const finalValues = [
    {
      title: 'Vistoria',
      dataIndex: 'vistoria',
      key: 'vistoria',
      width: 100,
      ellipsis: true,
      sorter: true,
      render: (vistoria: Dayjs) => {
        if (!vistoria) return '-'
        return format.brasilDate(vistoria) || '-'
      }
    }
  ]

  if (seeOnlyProviderDate) return finalValues

  return [
    {
      title: 'Prestador',
      dataIndex: 'provider',
      key: 'provider',
      sorter: true,
      width: 125,
      ellipsis: true,
      render: (provider: { id: string, name: string }) => {
        if (!provider) return '-'
        return <span className="table-name">{provider?.name || '-'}</span>
      }
    },
    ...finalValues
  ]
}

function analysis (canSeeAnalysisCollum: boolean) {
  if (!canSeeAnalysisCollum) return []
  return [{
    title: 'Análise',
    dataIndex: 'reviewAnalysis',
    key: 'reviewAnalysis',
    width: 150,
    align: 'center',
    ellipsis: true,
    sorter: true,
    render: (reviewAnalysis: string) => {
      if (!reviewAnalysis) return '-'
      return <Tag color={analysisColors[reviewAnalysis]}>{translate.proposalAnalysis(reviewAnalysis)}</Tag>
    }
  }]
}

function areasAction ({ canSeeActionColumn }: { canSeeActionColumn: boolean }) {
  if (!canSeeActionColumn) return []

  return [{
    title: 'Ações atuais',
    dataIndex: 'currentAction',
    key: 'currentAction',
    width: 150,
    ellipsis: true,
    render: (currentStep: string) => {
      const moreValues = currentStep.split(',')
      const formattedValues: string[] = []

      if (!currentStep) return <Tag>Nenhuma Ação</Tag>
      if (moreValues.length === 1) return <Tag>{translate.areasNames(currentStep)}</Tag>

      moreValues.forEach((item) => {
        return formattedValues.push(translate.areasNames(item.trim()))
      })

      return (
        <p
          className="link-style color-tertiary font-500 mb-0 capitalize"
          onClick={() => {
            swal.basic({
              title: 'Ações atuais',
              text: `${formattedValues.toString().replace(/,/g, ', ')}`
            })
          }}
        >
          Ver Mais
        </p>
      )
    }
  }]
}

const actions = (notInterval: boolean = false) => {
  if (notInterval) return []

  return [{
    title: '',
    key: 'actions',
    width: 200,
    ellipsis: true,
    render: (_: any, row: any) => {
      const user = useAuth()
      const dispatch = useDispatch()
      const { email, cellphone, product, name, cpf, _id, backoffices, number } = (row as IProposal)
      const BOTH_ITENS = ['auditor', 'administrator', 'commercial', 'master'].includes(user.level)
      const canChangeBackoffices = BOTH_ITENS && backoffices
      const canViewProposalHistory = ProposalEgiModel.canSeeProposalHistorical(user.level, user.areaName)

      const data: IReduxModal = {
        visible: true,
        title: 'Backoffices Vinculados',
        type: 'linkedBackoffices',
        hasFooter: false,
        width: '50',
        proposalId: _id
      }

      return (
        <Fragment>
          <div className="flex">
            {canChangeBackoffices && (
              <Tooltip title="Backoffices vinculados" destroyTooltipOnHide={{ keepParent: false }}>
                <a className="color-black" onClick={() => dispatch(modalFillValues(data))}>
                  <SolutionOutlined className="mr-2" style={{ fontSize: '20px' }} />
                </a>
              </Tooltip>
            )}

            <Fragment>
              {canViewProposalHistory && (
                <Tooltip title="Histórico" destroyTooltipOnHide={{ keepParent: false }}>
                  <Link to={`/auth/proposals/history/${_id}`} className="color-black">
                    <BookOutlined className="mr-2" style={{ fontSize: 20 }} />
                  </Link>
                </Tooltip>
              )}

              <Contact
                contactType="contact"
                cpf={cpf}
                canCall={true}
                emailClient={{ destination: email, type: 'proposta' }}
                numberClient={cellphone}
                productNameClient={product}
                nameClient={name}
                idProposal={_id}
                proposalNumber={String(number)}
              />
            </Fragment>
          </div>
        </Fragment>
      )
    }
  }]
}

function RemindedTag ({ confirm }: { confirm: Function }) {
  return (
    <Tag
      className="pointer"
      color="blue"
      onClick={() => {
        swal.confirmNegate({
          icon: 'question',
          title: 'Atendimento',
          text: 'Você deseja finalizar o atendimento dessa proposta ?',
          confirm: confirm
        })
      }}
    >
      Finalizar atendimento
    </Tag>
  )
}

function closeReminded ({ canSeeClosedReminded }: { canSeeClosedReminded: boolean }) {
  if (!canSeeClosedReminded) return []

  return [{
    title: 'Atendimento Telemarketing',
    dataIndex: 'reminderTelemarketingClosed',
    key: 'reminderTelemarketingClosed',
    sorter: true,
    width: 200,
    ellipsis: true,
    render: (reminderTelemarketingClosed: boolean, row: any) => {
      const { neededReminderTelemarketing, _id } = row
      const dispatch = useDispatch()
      const confirm = async () => {
        try {
          const response = await proposals.closeReminded({ proposalId: _id })
          dispatch(tablesUpdate())

          swal.basic({
            icon: 'success',
            title: 'Sucesso!',
            text: response.data.message
          })
        } catch (err) {
          swal.basic({
            title: 'Atenção',
            text: err.message,
            icon: 'warning'
          })
        }
      }
      if (neededReminderTelemarketing && reminderTelemarketingClosed) return <Tag color="green">Atendimento finalizado</Tag>

      return (
        <RemindedTag confirm={confirm} />
      )
    }
  }]
}

function closeRemindedCommercial (canSeeRemindedCommercial: boolean) {
  if (!canSeeRemindedCommercial) return []
  return [{
    title: 'Atendimento comercial',
    dataIndex: 'reminderTelemarketingClosed',
    key: 'reminderCommercialClosed',
    sorter: true,
    width: 200,
    ellipsis: true,
    render: (reminderCommercialIsClosed: boolean, row: any) => {
      const { neededReminderTelemarketing, _id } = row
      const dispatch = useDispatch()

      if (neededReminderTelemarketing && reminderCommercialIsClosed) return <Tag color="green">Atendimento finalizado</Tag>

      const confirm = async () => {
        try {
          const response = await ProposalEgiRepository.commercialCloseAttendant(_id)

          swal.basic({
            icon: 'success',
            title: 'Sucesso!',
            text: response.data.message
          })

          dispatch(tablesUpdate())
        } catch (err) {
          swal.basic({
            title: 'Atenção',
            text: err?.message,
            icon: 'warning'
          })
        }
      }

      return (
        <RemindedTag confirm={confirm} />
      )
    }
  }]
}

const columns = ({
  showStatusChangeModal, showModalChangeUser,
  notInternal = false, canEditCommercial = false,
  canSeeProviderColumns = false, seeOnlyProviderDate = false,
  areaNameUser = false, canRefuseProposal = false,
  canSeeSLAColumns = false, declineProposalDrawerInfo,
  canEditTm = false, canSeeActionColumn = false,
  canSeeClosedReminded = false, canSeeReminderTelemarketing = false,
  canSeeAnalysisCollum = false,
  canSeeCommercialAttendant = false, canEditCommercialAttendant, showModalCommercial,
  canSeeCommercialReminder = false, canChangeProposalPromoter = false, showChangePromoterModal
}: IProposalColumns): ColumnsType<Object> => [
  {
    title: 'ID',
    dataIndex: 'number',
    key: 'number',
    sorter: true,
    width: 75,
    render: function renderItem (text, row) {
      if (notInternal) return text
      const tables = useTables()
      const dispatch = useDispatch()

      function checkNeedVinculation () {
        if (tables.filters.needVinculation) return dispatch(setNeedVinculation(true))
        dispatch(setNeedVinculation(false))
      }

      return (
        <Link
          className="link-style color-tertiary font-500"
          onClick={() => checkNeedVinculation()}
          to={`/auth/proposals/show/${(row as any)._id}`}
        >
          {text}
        </Link>
      )
    }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    sorter: true,
    width: 150,
    ellipsis: true,
    render: (text: string, row: Object) => {
      const dispatch = useDispatch()
      const textProposal = text as _proposalStatuses
      const translatedColor = translate.reverseProposalStatus(text)
      const typedRow = row as { status: _proposalStatuses, _id: string }

      const validStatusCanceled =
        typedRow.status !== 'approved' && typedRow.status !== 'reproved' && typedRow.status !== 'canceled' && typedRow.status !== 'effectivated'

      const canSetAsActiveStatus = canRefuseProposal && typedRow.status === 'inactive'
      const canEditStatus = canRefuseProposal && validStatusCanceled

      let finalClass = 'text-center w-100 capitalize'
      if (canRefuseProposal) finalClass += 'pointer'

      return (
        <Tag
          className={finalClass}
          color={varColors[translatedColor]}
          onClick={() => {
            if (canEditStatus && !canSetAsActiveStatus) {
              dispatch(tablesSetRowValues({ row }))
              if (declineProposalDrawerInfo) dispatch(drawerFillForms(declineProposalDrawerInfo))
            }
            if (canSetAsActiveStatus) {
              if (showStatusChangeModal) showStatusChangeModal(typedRow._id)
            }
          }}
        >
          {canEditStatus && <EditFilled className="mx-1" />}
          {translate.proposalStatus(textProposal)}
        </Tag>
      )
    }
  },
  {
    title: 'Expiração',
    dataIndex: 'proposalExpiration',
    key: 'proposalExpiration',
    width: 100,
    sorter: true,
    render: (proposalExpiration) => {
      if (!proposalExpiration) return 'N/A'
      return <span className="table-name">{timeAsDayjs(proposalExpiration.date).format('DD/MM/YYYY')}</span>
    }
  },
  {
    title: 'Tipo Pessoa',
    dataIndex: 'personType',
    sorter: true,
    width: 100,
    ellipsis: true,
    render: (personType: string) => {
      return translate.personType(personType)
    }
  },
  ...slaColumn({ canSeeSLAColumns }),
  {
    title: 'Venc. SLA',
    dataIndex: ['SLA', 'SLA', 'max'],
    key: 'sla-max',
    sorter: true,
    width: 100,
    ellipsis: true,
    render: (SLA: string) => {
      if (!SLA) return '-'
      return <span className="table-name">{timeAsDayjs(SLA).format('DD/MM/YYYY')}</span>
    }
  },
  {
    title: 'Cliente',
    dataIndex: 'name',
    key: 'name',
    width: 125,
    ellipsis: true,
    render: (name: string, row) => {
      if (!name) return '-'
      const { cpf } = (row as IProposal) || {}

      return (
        <>
          <p>
            <OverflowTooltip
              width={115}
              title={name}
            >
              {format.formatName(name)}
            </OverflowTooltip>
          </p>

          <p>
            <OverflowTooltip
              width={115}
              title={cpf}
            >
              {cpf}
            </OverflowTooltip>
          </p>
        </>
      )
    }
  },
  {
    title: 'Indicador/API',
    dataIndex: 'indicator',
    width: 150,
    render: (item, row: object) => {
      const proposal = row as IProposal
      const type = proposal?.indicator?.type ? `${proposal.indicator?.type} - ` : ''
      if (!item?.name) return '-'

      return (
        <span className="table-name">
          <Space className='flex flex-justify-between'>
            <Tooltip
              title={type + item?.name}
              destroyTooltipOnHide={{ keepParent: false }}
            >
              <span style={{ maxWidth: 115 }} className="overflow-ellipsis">{type + item?.name}</span>
            </Tooltip>

            {proposal?.isIntegration && <IntegrationTooltip title="Proposta gerada por integração" />}
          </Space>
        </span>
      )
    }
  },
  ...usersLinkedProposal({ showModalChangeUser, notInternal, canEditCommercial, canEditTm, canSeeReminderTelemarketing, canChangeProposalPromoter, showChangePromoterModal }),
  {
    title: 'Valor',
    dataIndex: 'value',
    key: 'value',
    width: 150,
    ellipsis: true,
    sorter: true
  },

  ...engenhariaOnlyStuffs({ canSeeProviderColumns, seeOnlyProviderDate }),
  {
    title: 'Criação',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 100,
    ellipsis: true,
    sorter: true,
    render: (createdAt) => {
      return <span className="table-name">{createdAt}</span>
    }
  },
  ...commercial(canSeeCommercialAttendant, canEditCommercialAttendant, showModalCommercial),
  {
    title: 'Atualização',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    width: 100,
    ellipsis: true,
    sorter: true,
    render: (updatedAt) => {
      return <span className="table-name">{updatedAt}</span>
    }
  },
  ...analysis(canSeeAnalysisCollum),
  ...areasAction({ canSeeActionColumn }),
  {
    title: 'Último departamento',
    dataIndex: 'lastArea',
    key: 'lastArea',
    width: 120,
    ellipsis: true,
    render: (item) => <Tag>{item}</Tag>
  },
  ...closeReminded({ canSeeClosedReminded }),
  ...closeRemindedCommercial(canSeeCommercialReminder),
  ...canSeeCurrencyStep({ areaNameUser }),
  ...actions(notInternal)
]

export { columns }

import { DeleteOutlined, EditFilled } from '@ant-design/icons'
import { Button, Empty, Form, Skeleton } from 'antd'
import RichTextEditor from 'components/RichTextEditor/RichTextEditor'

import DynamicColorButton from 'ecp/components/DynamicColorButton/DynamicColorButton'
import { ISelect } from 'egi/types'
import { IStepIdentification } from 'hooks/useResources'
import React, { useState } from 'react'
import swal from 'utils/swal'
import { IReview } from '../../types'
import { _mode } from '../InternalReviewForm/InternalReviewForm'
import SettingHistory, { HistoryLastModified, ISettignsHistory } from '../InternalReviewHistory/InternalReviewHistory'
import SettingsCard from '../SettignsCard/SettingsCard'

const TextAreaHeader = ({ step, headerDescription }: { step: IStepIdentification | undefined, headerDescription?: React.ReactNode }) => {
  return <>
    <SettingsCard.title className="pt-1 internal-review-settings__header-title">
      Revisão interna do passo {step?.name ?? ''}
    </SettingsCard.title>
    {headerDescription}
  </>
}

const EmptySettings = ({ description, step }: { description: string | React.ReactNode, step: IStepIdentification | undefined }) => {
  return <div>
    <TextAreaHeader step={step} />
    <div className="internal-review-settings__empty">
      <Empty description={description} />
    </div>
  </div>
}

export const LoadingTextArea = () => {
  return <div>
    <Skeleton.Button
      className='internal-review-settings__skeleton'
      active />
  </div>
}

const DisplayTextAreaFooter = ({ review, getHistory, history, historyLoading }: {
  review: IReview,
  getHistory: (id: string) => void,
  history: ISettignsHistory[] | undefined
  historyLoading: boolean
}) => {
  const [visible, setVisible] = useState<boolean>(false)

  function onChangeVisible () {
    setVisible(prev => !prev)
  }
  return (
    <div className="internal-review-settings__footer">

      <div className="internal-review-settings__last-modification">
        <HistoryLastModified
          updatedAt={review?.updatedAt ?? review?.createdAt}
          modifiedLevel={review?.modifiedLevel}
          modifiedName={review?.modifiedName}
        />

        <Button
          type="primary"
          size="small"
          onClick={() => {
            getHistory(review?._id)
            onChangeVisible()
          }}
          className="internal-review-settings__history-button">
          Ver histórico completo
        </Button>

        <SettingHistory
          historyLoading={historyLoading}
          history={history}
          visible={visible}
          onChangeVisible={onChangeVisible}
        />
      </div>
    </div>
  )
}

const DisplayTextArea = ({ step, changeMode, onDelete, text, review, getHistory, history, historyLoading }: {
  step: IStepIdentification,
  changeMode: Function,
  onDelete: (id: string) => void,
  text: string,
  review: IReview | undefined,
  getHistory: (id: string) => void,
  history: ISettignsHistory[] | undefined
  historyLoading: boolean
}) => {
  return (<>
    <div
      className="internal-review-settings__text-header">
      <TextAreaHeader
        step={step}
      />
      <div>
        <Button
          type="primary"
          icon={<EditFilled />}
          onClick={() => changeMode('edit')}
          className="mr-2"
        >
        Editar
        </Button>
        <DynamicColorButton
          colorClass="button-red"
          onClick={() =>
            swal.confirm({
              icon: 'warning',
              title: 'Atenção',
              text: 'Deseja mesmo deletar o texto desse passo?',
              showCancelButton: true,
              cancelButtonText: 'Cancelar',
              confirmButtonText: 'Deletar',
              confirm: () => {
                review?._id && onDelete(review?._id)
              }
            })
          }>
          <DeleteOutlined /> Deletar
        </DynamicColorButton>
      </div>
    </div>

    <div
      dangerouslySetInnerHTML={{ __html: text }}
      className="internal-review-settings__display-text">
    </div>

    {review &&
      <DisplayTextAreaFooter
        historyLoading={historyLoading}
        history={history}
        review={review}
        getHistory={getHistory}
      />
    }
  </>)
}

export const InternalReviewText = ({ onEditorChange, step, department, text, loading, changeMode, mode, review, onDelete, getHistory, history, historyLoading }: {
  onEditorChange: ((value: string) => void),
  step: IStepIdentification | undefined,
  department: ISelect | undefined,
  text: string | undefined,
  review: IReview | undefined
  loading: boolean
  changeMode: Function,
  mode: _mode
  onDelete: (id: string) => void
  getHistory: (id: string) => void,
  history: ISettignsHistory[] | undefined,
  historyLoading: boolean
}) => {
  if (!step) {
    return (
      <EmptySettings
        step={step}
        description={
          <>
            <p>Ops, parece que você não selecionou que passo deseja visualizar</p>
            <p>Certifique-se de selecionar um <b>departamento</b> e um&nbsp;
              <b>passo</b> para ver, adicionar ou editar uma revisão interna padrão</p>
          </>
        } />
    )
  }

  if (loading) {
    return (
      <>
        <TextAreaHeader step={step} />
        <LoadingTextArea />
      </>
    )
  }

  if (!text && mode === 'display') {
    return <EmptySettings
      step={step}
      description={
        <>
          <p>Ops, parece que você não adicionou nenhum texto para o passo <b>{step.name}</b></p>
          <p>Clique no botão abaixo  para adicionar</p>
          <Button
            onClick={() => changeMode('add')}
            type="primary"
            className="mt-1">
            Adicionar
          </Button>
        </>
      } />
  }

  if (text && mode === 'display') {
    return (
      <DisplayTextArea
        changeMode={changeMode}
        onDelete={onDelete}
        historyLoading={historyLoading}
        getHistory={getHistory}
        review={review}
        step={step}
        text={text}
        history={history}
      />
    )
  }

  return (
    <>
      <TextAreaHeader step={step}
        headerDescription={
          <SettingsCard.description className="pb-2">
            Digite o texto, depois clique em salvar a revisão interna padrão do passo <b>
              {step?.name ?? ''}</b> no departamento <b>{department?.label}</b>
          </SettingsCard.description>
        }
      />

      <Form.Item
        name='text'
      >
        <RichTextEditor
          setContents={text}
          onChange={onEditorChange}
        />
      </Form.Item>

      <SettingsCard.ButtonsContainer>
        <SettingsCard.cancelButton onClick={() => changeMode('display')} className="mr-1">
          Cancelar
        </SettingsCard.cancelButton>

        <SettingsCard.submitButton loading={false}>
          Salvar
        </SettingsCard.submitButton>
      </SettingsCard.ButtonsContainer>
    </>
  )
}

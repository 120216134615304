import React, { Fragment } from 'react'
import { Drawer } from '..'
import { useDispatch } from 'react-redux'
import { drawerReset } from '../../store/modules/drawer/actions'
import { useDrawer, useProposal, useAuth } from 'hooks'
import ContactDrawer from './ContactDrawer'
import LeadsDrawer from './LeadsDrawer'
import UploadDocumentDrawer from './UploadDocumentDrawer'
import DeclineProposal from './DeclineProposal'
import proposals from 'egi/consumers/proposals'
import RefuseProposalInList from './RefuseProposalInList'
import ActionClientInstructions from './ActionClientInstructions'

function ReduxDrawer () {
  const dispatch = useDispatch()
  const proposal = useProposal()
  const drawer = useDrawer()
  const user = useAuth()
  const IS_AUDITOR = user.level === 'auditor'

  return (
    <Drawer
      setVisible={() => dispatch(drawerReset())}
      onClose={() => dispatch(drawerReset())}
      title={drawer.title}
      status={drawer.status}
      visible={drawer.visible}
      width={drawer.width}
      maskClosable={drawer.outsideClick}
      showSubmit={!IS_AUDITOR && drawer.submitButton}
      onSubmit={() => drawer.formRef?.current.submit()}
      closable={true}
    >
      <Fragment>
        {drawer.type === 'contact' && (<ContactDrawer />)}
        {drawer.type === 'leads' && (<LeadsDrawer />)}
        {drawer.type === 'upload' && (<UploadDocumentDrawer />)}
        {drawer.type === 'declineProposal' && (<DeclineProposal submitFn={proposals.providerRefuse} proposalId={proposal.id} />)}
        {drawer.type === 'refuseProposal' && (<RefuseProposalInList />)}
        {drawer.type === 'clientInstructions' && (<ActionClientInstructions />)}
      </Fragment>
    </Drawer>
  )
}

export default ReduxDrawer

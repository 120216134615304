import { Button, Drawer, Result, Spin, Timeline } from 'antd'
import { DownloadOutlined, HistoryOutlined, InfoCircleFilled } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import ProposalEgiRepository from 'egi/repositories/ProposalEgiRepository'
import { useProposal } from 'hooks'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { timeAsDayjs } from 'utils/time'
import { colors } from 'styles/colors'
import { DepartmentCardButtonZip } from '../DepartmentCard/DepartmentCard'
import translate from 'utils/translate'

export interface IZipDocumentLog {
  _id: string
  processedDocuments: number
  proposalId: string
  createdAt: string
  url: string
  error?: string

  status: 'awaiting' | 'processing' | 'finished' | 'error'
  user: {
    name: string
    level: string
    areaName: string
  }
}

const STATUS_BY_COLOR = {
  awaiting: colors.analysis,
  processing: colors.analysis,
  finished: colors.approved,
  error: colors.reproved
}

export const ErrorZip = ({ message = 'Ocorreu um erro no processamento do arquivo.' }: {message?: string}) => {
  return <div className='mb-3 zip-documents-log-modal__error'>
    <InfoCircleFilled/> {message}
  </div>
}

const ZipDocumentsLogsTimeline = (props: {
  departmentId: string
}) => {
  const {
    departmentId
  } = props

  const [logs, setLogs] = useState<Array<IZipDocumentLog>>([])
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [page, setPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false)
  const [seeMoreLoading, setSeeMoreLoading] = useState(false)

  const proposal = useProposal()

  const onDownload = (url: string) => {
    window.open(url, '_blank')
  }

  const onShowMoreLogs = () => {
    setPage(prev => prev + 1)
  }

  const fetchLogs = async (
    proposalId: string,
    departmentId: string,
    page: number
  ) => {
    try {
      if (page === 1) {
        setLoading(true)
      } else {
        setSeeMoreLoading(true)
      }

      const axiosRequestConfig = {
        params: {
          proposalId,
          departmentId,
          page
        }
      }

      const response = await ProposalEgiRepository.zipLogs(axiosRequestConfig)
      const logs = response.data.data?.logs
      const docs = logs?.docs

      if (!docs?.length) throw new Error('Nenhum log encontrado')
      if (!logs) throw new Error('Falha ao encontrar logs')

      setHasNextPage(logs?.hasNextPage)

      if (page === 1) {
        setLogs(docs || [])
      } else {
        setLogs(prev => [...prev, ...docs])
      }

      setErrorMessage('')
    } catch (error) {
      setErrorMessage(error.message)
    } finally {
      setLoading(false)
      setSeeMoreLoading(false)
    }
  }

  const onFetchLogs = () => {
    if (proposal.id && departmentId) {
      fetchLogs(proposal.id, departmentId, page)
    }
  }

  useEffect(() => {
    onFetchLogs()
  }, [
    proposal.id,
    departmentId,
    page
  ])

  const mountUserName = (user: IZipDocumentLog['user']) => {
    const { level, name, areaName } = user || {}

    const translatedLevel = translate.level(level)
    const translatedArea = translate.areasNames(areaName)

    if (!name && !level && !areaName) return ''

    if (name && level && areaName) return `${name} (${translatedLevel} ${translatedArea})`
    if (name && level) return `${name} (${translatedLevel})`

    return name
  }

  if (loading) {
    return (
      <div>
        <Spin />
      </div>
    )
  }

  if (errorMessage) {
    return (
      <div className='text-center'>
        <Result
          status='warning'
          title='Parece que algo deu errado'
          subTitle={errorMessage}
        />

        <Button type='primary' onClick={onFetchLogs}>Tentar Novamente</Button>
      </div>
    )
  }

  return (
    <Timeline>
      {(logs || []).map(log => (
        <Timeline.Item
          key={log._id}
          color="#00CCFF"
          dot={
            <div
              className='zip-documents-log-modal__icon-container'
              style={{
                backgroundColor: STATUS_BY_COLOR[log.status]
              }}
            >
              <DownloadOutlined />
            </div>
          }
        >
          <h4 className='zip-documents-log-modal__title'>
            Download realizado por {mountUserName(log.user)}
          </h4>

          <div>
            <p className='zip-documents-log-modal__description'>
              {timeAsDayjs(log.createdAt).format('DD/MM/YYYY HH:mm:ss')}
            </p>
          </div>

          <p className='zip-documents-log-modal__description'>
            Documentos compilados: {log.processedDocuments}
          </p>

          {log.error && (
            <div className='mb-3'>
              <ErrorZip/>
            </div>
          )}

          <a
            href='#'
            className='zip-documents-log-modal__link'
            onClick={() => onDownload(log.url)}
          >
            Baixar ZIP
          </a>
        </Timeline.Item>
      ))}

      {hasNextPage && (
        <div>
          <Button
            disabled={seeMoreLoading}
            type='link'
            onClick={onShowMoreLogs}
          >
            {seeMoreLoading
              ? <Spin />
              : <span>Ver mais &darr;</span>
            }
          </Button>
        </div>
      )}
    </Timeline>
  )
}

export const ZipDocumentsLogModal = ({
  department
}: {
  department: {
    name: string,
    _id: string
  }
}) => {
  const [visible, setVisible] = useState<boolean>(false)
  const { sm: isSmall } = useBreakpoint()

  const onOpenDrawer = () => {
    setVisible(true)
  }

  const onCloseDrawer = () => {
    setVisible(false)
  }

  return (
    <>
      <Drawer
        placement='right'
        visible={visible}
        width={!isSmall ? '100%' : '50vw'}
        onClose={onCloseDrawer}
        destroyOnClose
      >
        <div className='zip-documents-log-modal__text-container'>
          <h3 className='zip-documents-log-modal__help-title'>Histórico de downloads ZIP</h3>
          <p className='zip-documents-log-modal__help-text'>
            Confira abaixo o histórico de downloads realizados no departamento <b>{department.name}</b>
          </p>
        </div>

        <div>
          <ZipDocumentsLogsTimeline
            departmentId={department._id}
          />
        </div>
      </Drawer>

      <div>
        <DepartmentCardButtonZip
          className="department-card__zip-button-right"
          onClick={onOpenDrawer}
          icon={<HistoryOutlined />}
        >
          Logs
        </DepartmentCardButtonZip>
      </div>
    </>
  )
}

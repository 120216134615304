import format from 'utils/format'
import { newContractSteps, portabilitySteps, refinancingSteps, renegotiationSteps } from './ProposalECPFConstants'
import { CodigoDespesa, IProposalDespesasItemECPF, IProposalOrigemItemECPF, ProposalType } from './ProposalECPFInterfaces'

export const returnProposalStep = (type: ProposalType) => {
  switch (type) {
    case ProposalType.new: return newContractSteps
    case ProposalType.refinancing: return refinancingSteps
    case ProposalType.portability: return portabilitySteps
    case ProposalType.renegotiation: return renegotiationSteps
    default: return []
  }
}

export const returnProposalType = (proposalType: ProposalType) => {
  switch (proposalType) {
    case ProposalType.new: return 'Nova'
    case ProposalType.portability: return 'Portabilidade'
    case ProposalType.refinancing: return 'Refinanciamento'
    case ProposalType.renegotiation: return 'Renegociacao'
  }
}

export const findOriginItemECPF = (tipo: string, itens: IProposalOrigemItemECPF[]): IProposalOrigemItemECPF => {
  return itens?.find(item => item?.tipo === tipo) || {} as IProposalOrigemItemECPF
}

export const findByExpenseByCode = (codigo: CodigoDespesa, itens: IProposalDespesasItemECPF[]): IProposalDespesasItemECPF => {
  return itens?.find(item => item?.codigoTipo === codigo) || {} as IProposalDespesasItemECPF
}

export const formatTaxRates = (monthlyRate?: number, annualRate?: number) => {
  const formattedMonthlyRate = monthlyRate !== undefined ? `${format.percentageWithMathTrunc(monthlyRate || 0)} (a.m.)` : '-'
  const formattedAnnualRate = annualRate !== undefined ? `${format.percentageWithMathTrunc(annualRate || 0)} (a.a.)` : '-'
  return `${formattedMonthlyRate} / ${formattedAnnualRate}`
}

import step1 from '../assets/icons/step_1.svg'
import rodobensLogoLight from '../assets/icons/ic-logo.svg'
import rodobensLogoDark from '../assets/icons/ic-logonegative.svg'
import simulation from '../assets/icons/simulation.svg'
import phoneRinging from '../assets/icons/phone-ringing.svg'
import ornament from '../assets/icons/ornament.svg'
import printer from '../assets/icons/icon-printer.svg'
import dots from '../assets/icons/dots.svg'
import favicon from '../assets/icons/ic-favicon.svg'
import fallback from '../assets/img/fallback.png'

const imagesUrls = {
  step1,
  rodobensLogoLight,
  rodobensLogoDark,
  simulation,
  phoneRinging,
  fallback,
  ornament,
  dots,
  favicon,
  printer
}

export default imagesUrls

import { CheckCircleFilled } from '@ant-design/icons'
import { Spin } from 'antd'
import React, { ButtonHTMLAttributes, ReactNode } from 'react'

export type _productButtonTheme = 'white' | 'primary'

export type ProductItemButtonTheme = {
  theme?: _productButtonTheme
}

type Props = {
  children: ReactNode | string
  active?: boolean
  loading?: boolean
} & ButtonHTMLAttributes<HTMLButtonElement> & ProductItemButtonTheme

const ProductItemButton = ({ children, active = false, loading = false, theme = 'white', ...rest }: Props) => {
  const cssProps = {
    active: active ? 'product-item-button--active' : '',
    theme: theme === 'primary' ? 'product-item-button--secondary' : ''
  }

  return (
    <button
      {...rest}
      className={`product-item-button ${cssProps.active} ${cssProps.theme}`}
    >
      {children}
      {loading && <Spin className='product-item-button__icon' />}
      {active && <CheckCircleFilled className='product-item-button__icon' />}
    </button>
  )
}

export default ProductItemButton

import React from 'react'
import { Layout, Row, Image, Col } from 'antd'
import { IconsMenu } from './IconsMenu'
import { IconNotification } from './IconNotification'
import imageUrls from 'utils/imagesUrls'
import ProductsDropdown from 'components/SelectProductsSidebar/SelectProductsSidebar'
import { useAuth, useSelectedProduct } from 'hooks'
import ProductModel from 'ecp/models/ProductModel'
import { useDispatch } from 'react-redux'
import { authActions } from 'store/modules/auth/authActions'
import { PermissionModel } from 'models/PermissionModel'
import { useUserPermissions } from 'hooks/usePermissions'

const { Header } = Layout

export function UserTranslatedLevel () {
  const user = useAuth()
  const { userPermissions } = useUserPermissions()

  return <span>{PermissionModel.translateLevel(user.level, userPermissions)}</span>
}

function UserInfo () {
  const user = useAuth()

  if (user.level && user.level !== 'client') {
    return <h4
      className="mt-1 color-white font-500"
    >
      {user.name} <span className="label-user-level">- {<UserTranslatedLevel />}</span>
    </h4>
  }

  return <h4 className="mt-1 color-white font-500">{user.name}</h4>
}

function Navbar () {
  const product = useSelectedProduct()
  const dispatch = useDispatch()

  return (
    <Header className="navbar-header navbar">
      <Row align="middle" justify="space-between" className="row-navbar">
        <Col>
          <Row justify="space-between" className="logo-alignment">
            <Col>
              <Image
                className='navbar-image'
                src={imageUrls.rodobensLogoDark}
                preview={false}
              />
            </Col>

            <Col className="flex-1">
              <UserInfo />
            </Col>
          </Row>
        </Col>

        <Col>
          <Row gutter={15} align="middle" justify="space-between">
            <Col className="navbar-product-dropdown">
              <ProductsDropdown />
            </Col>

            {ProductModel.canSeeUnicoCommunication(product) && (
              <Col>
                <h3
                  className='navbar-header__communication'
                  onClick={() => dispatch(authActions.updateCommunicationVisible(true))}
                >
                  📢
                </h3>
              </Col>
            )}

            <Col>
              <IconNotification />
            </Col>

            <Col>
              <IconsMenu />
            </Col>
          </Row>
        </Col>
      </Row>
    </Header>
  )
}

export default Navbar

import React, { Fragment } from 'react'
import { Form, Button } from 'antd'
import { FormInstance } from 'antd/lib/form'

interface SelectTypeInvites {
  level: string
  label: string
  loading: boolean
  form: FormInstance<any>
  onFinish: ((values: any) => void) | undefined
  buttonText?: string
  formContent: React.ReactElement | React.ReactNode
  layout?: 'flex' | 'grid w-100' | 'flex-column w-75'
}

function SelectTypeInvite ({ level, label, loading, form, onFinish, formContent, layout = 'flex', buttonText = 'Gerar link' }: SelectTypeInvites) {
  return (
    <Fragment>
      <div className="flex flex-justify-center">
        <label className={level === 'promoter' ? 'bold' : 'bold mr-1'}>{label}</label>
      </div>

      <div className="flex flex-justify-center mt-3">
        <Form
          form={form}
          className={layout}
          onFinish={onFinish}
          layout='vertical'
          initialValues={{
            commissionPercentage: 100,
            docFree: false
          }}
        >
          {formContent}

          <Button
            type="primary"
            className={level === 'promoter' || level === 'auditor' ? 'w-100' : 'ml-2'}
            htmlType="submit"
            loading={loading}
          >
            {buttonText}
          </Button>

        </Form>
      </div>
    </Fragment>
  )
}

export default SelectTypeInvite

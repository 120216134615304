
import { DownloadOutlined, FileDoneOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Col, message, Row, Tag } from 'antd'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import axios from 'axios'
import { handleServerTableChange } from 'components/ServerTable/function'
import ServerTable from 'components/ServerTable/ServerTable'

import PricingModel from 'egi/models/PricingModel'
import { downLoadModelRepostory, pricingRepository } from 'egi/repositories/NotaryFeesRepository'

import { useAuth, useTables } from 'hooks'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NotaryFeestActions } from 'store/modules/notaryFees/notaryFeesActions'
import { RootState } from 'store/modules/rootReducer'
import { tablesSetFiltersValues } from 'store/modules/tables/actions'
import { reactAppServer } from 'utils/config'

import { downloadFile } from 'utils/download'
import { isLocal } from 'utils/globals'
import DisplayErrorsModal from '../components/DisplayErrorsModal/DisplayErrorsModal'
import LoadingNotaryFeesBar from '../components/LoadingNotaryFeesBar/LoadingNotaryFeesBar'
import NotaryFeesHintModal from '../components/NotaryFeelHintModal/NotaryFeesHintModal'
import NotaryFeesLastModal from '../components/NotaryFeesLastModal/NotaryFeesLastModal'
import UploadNotaryFeesModal from '../components/UploadNotaryFeesModal/UploadNotaryFees'
import UploadNotaryFeesModelModal from '../components/UploadNotaryFeesModelModal/UploadNotaryFeesModelModal'
import { IFees, IImportFile, ILineErrors, INotaryFees } from '../notaryFeesInterfaces'

function NotaryFees () {
  const user = useAuth()
  const dispatch = useDispatch()
  const table = useTables()

  const [loadingDownload, setLoadingDownload] = useState<boolean>()
  const [visibleUploadModel, setVisibleUploadModel] = useState<boolean>(false)
  const [visibleUpload, setVisibleUpload] = useState<boolean>(false)
  const [fees, setFees] = useState<IFees>()
  const [visibleHint, setVisibleHint] = useState<boolean>(false)
  const [visibleLast, setVisibleLast] = useState<boolean>(false)
  const [lastFeesError, setLastFeesError] = useState<string>()
  const [lastFeesLoading, setlastFeesLoading] = useState<boolean>(false)

  const loadingState = useSelector((state: RootState) => state.loadingReducer)

  const tables = useTables()

  const setFileImport = (payload: IImportFile | undefined) => dispatch(NotaryFeestActions.setImportItem(payload))

  const onCancel = () => {
    setVisibleUpload(false)
    setVisibleUploadModel(false)
    setVisibleHint(false)
    setVisibleLast(false)
  }

  const onImport = (data: IImportFile | undefined) => {
    setFileImport(data)
  }

  const onDownloadModelLink = (id: string) => {
    if (isLocal) return `${reactAppServer}/download/models/${id}`
    return `${reactAppServer}download/models/${id}`
  }

  const columns: ColumnsType<INotaryFees> = useMemo(() => [
    {
      align: 'center',
      title: 'ID',
      dataIndex: 'notaryFeeCode',
      width: '5rem'
    },
    {
      align: 'center',
      title: 'Vigente',
      dataIndex: 'isCurrentTable',
      render: (isCurrentTable: boolean) => {
        if (isCurrentTable === true) {
          return <Tag
            color='var(--green-status)'
            onClick={() => setVisibleLast(true)}>
            Vigente
          </Tag>
        }
        return '-'
      }
    },
    {
      title: 'Data de Importação',
      dataIndex: 'createdAt'
    },
    {
      title: 'Nome de usuário',
      dataIndex: ['user', 'name']
    },
    {
      title: 'Nome Arquivo',
      dataIndex: 'fileName'
    },
    {
      align: 'center',
      title: 'Erro',
      dataIndex: 'lineErrors',
      render: (value: ILineErrors[], row: INotaryFees) => {
        if (!value.length && !row?.fileError?.length) return 'Não'
        return <DisplayErrorsModal row={row} />
      }
    },
    {
      title: 'Download',
      dataIndex: 'url',
      width: '5rem',
      align: 'center',
      render: (url: string) => {
        return <a href={url}> <DownloadOutlined className="notary-fees__download-icon" /></a>
      }
    }
  ], [])

  const showModel = async () => {
    setLoadingDownload(true)
    try {
      const response = await downLoadModelRepostory.showModel()

      const fileUrl = onDownloadModelLink(response?.data?.data?.downloadModel._id)
      const title = 'custas_cartorarias_V2023'

      await axios.head(fileUrl)
      downloadFile({ fileUrl, title })
    } catch (err) {
      message.error(err.message)
    } finally {
      setLoadingDownload(false)
    }
  }

  const getLast = async () => {
    setlastFeesLoading(true)
    try {
      const response = await pricingRepository.getLast()

      setFees(response.data.data.notaryFee.fees)

      if (!response.data.data.notaryFee.fees) throw new Error('Não há nenhuma tabela vigente no momento')
    } catch (err) {
      setLastFeesError(err.message)
    } finally {
      setlastFeesLoading(false)
    }
  }

  useEffect(() => {
    getLast()
  }, [tables])

  return (
    <div className="notary-fees">
      <UploadNotaryFeesModal
        visible={visibleUpload}
        onCancel={onCancel}
        onImportData={onImport}
      />

      <NotaryFeesHintModal
        visible={visibleHint}
        onCancel={onCancel}
      />

      <NotaryFeesLastModal
        visible={visibleLast}
        onCancel={onCancel}
        fees={fees}
        loading={lastFeesLoading}
        error={lastFeesError}
      />

      {PricingModel.canEditNotaryFeesModel(user.level) && (
        <UploadNotaryFeesModelModal
          visible={visibleUploadModel}
          onCancel={onCancel}
        />
      )}

      <Row gutter={10} className="mb-3">
        <Col lg={12} sm={24} >
          <div className="notary-fees__card" >
            <div className="flex">
              <div>
                <div className="notary-fees__title">
                  <h2 className="notary-fees__card-title">
                    Importação de Custas Cartorárias
                  </h2>
                  <p>Importe sua planilha sua planilha de custas cartorárias</p>
                </div>
                <div className="notary-fees__buttons">
                  <Row className="notary-fees__buttons-top">
                    <Button
                      type="primary"
                      icon={<UploadOutlined />}
                      className="m-1"
                      onClick={() => setVisibleUpload(true)}>
                      Importar planilha
                    </Button>
                    <Button
                      type="primary"
                      icon={<DownloadOutlined />}
                      className="m-1"
                      onClick={() => showModel()}
                      loading={loadingDownload} >
                      Baixar modelo
                    </Button>
                  </Row>
                  <Row className="notary-fees__buttons-bottom">
                    <Button
                      type="primary"
                      className="m-1"
                      onClick={() => setVisibleLast(true)}
                    >
                      Ver tabela de vigente
                    </Button>

                    {PricingModel.canEditNotaryFeesModel(user.level) && (
                      <Button
                        className="m-1"
                        icon={<UploadOutlined />}
                        onClick={() => setVisibleUploadModel(true)}
                        type='primary'>
                        Anexar novo modelo
                      </Button>
                    )}
                  </Row>
                </div>
              </div>
              <div className="notary-fees__icon">
                <FileDoneOutlined className='white notary-fees__icon-bg' />
              </div>
            </div>
          </div>

        </Col>
        <Col lg={12} sm={24} >
          <div className="notary-fees__card notary-fees__card-mobile">
            <div className="text-center">
              <h2 className="notary-fees__card-title" >Siga as instruções para realizar a importação</h2>
              <p className=" mb-2">Baixe o modelo e siga as instruções, clicando no botão abaixo, para preencher corretamente a tabela de custas cartorárias</p>
              <Button type="primary" onClick={() => setVisibleHint(true)}>Instruções</Button></div>
          </div>
        </Col>
      </Row>

      <LoadingNotaryFeesBar importFile={loadingState?.importItem} onImport={onImport} />

      <h2 className="notary-fees__card-title mt-3">Lista de importações</h2>
      <ServerTable
        onChange={
          (pagination: TablePaginationConfig, filters: Record<string, (string | number | boolean)[] | null>, sorter) => {
            const serverTableFiltersData = handleServerTableChange(columns, table.filters)(pagination, filters, sorter)
            dispatch(tablesSetFiltersValues({ ...table.filters, ...serverTableFiltersData }))
          }
        }
        url='/pricings/notary-fees/list'
        columns={columns}
      />
    </div>
  )
}

export default NotaryFees

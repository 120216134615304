import dayjs from 'dayjs'

import { timeAsDayjs } from 'utils/time'

export function deadlineInDays (deadline: string | undefined) {
  if (!deadline) return 0
  const convertedDeadline = timeAsDayjs(deadline, { applyTimezone: false }).format('YYYY-MM-DD')
  return dayjs(`${convertedDeadline}`).diff(timeAsDayjs(), 'days')
}

export function expirationDateColors (deadline: number | undefined) {
  if (!deadline) return
  if (deadline >= 14) return '#00CF7B'
  if (deadline > 0) return '#FFC107'
  return '#DD4E4E'
}

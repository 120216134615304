import React, { useState } from 'react'
import { Select, Form } from 'antd'
import SelectTypeInvite from '../SelectTypeInvite'
import translate from 'utils/translate'
import format from 'utils/format'
import { _tableAreas } from 'globals'
import { _userLevel } from 'ecp/models/UsersModel'
import InviteEgiRepository, { InviteEgiErrors } from 'egi/repositories/InviteEgiRepository'

interface IInviteProvider {
  level: _userLevel
  area?: _tableAreas
  onSuccess: (token: string, inviteId?: string) => void
  onFailed: (messsage: string) => void
}

function InviteProvider ({ area, level, onSuccess, onFailed } :IInviteProvider) {
  const [loading, setLoading] = useState<boolean>(false)

  const [form] = Form.useForm()

  const generateInvite = async (values: {department: string}) => {
    setLoading(true)
    try {
      const response = await InviteEgiRepository.levelLink<{ department: string }>(level, values)
      const { link, inviteId } = response.data.data || {}
      if (!link) throw InviteEgiErrors.link()

      setLoading(false)
      onSuccess(link, inviteId)
    } catch (err) {
      setLoading(false)
      onFailed(err.message)
    }
  }

  return (
    <SelectTypeInvite
      level={level}
      onFinish={generateInvite}
      label={`Selecione o departamento de atuação do ${translate.level('provider')}:`}
      form={form}
      loading={loading}
      layout='flex'

      formContent={
        <Form.Item
          name='department'
          rules={[{ required: true, message: 'Selecione um tipo.' }]}
        >
          <Select
            placeholder='Departamento'
            options={[{ label: format.capitalize(String(area)), value: String(area) }]}
          />
        </Form.Item>
      }
    />
  )
}

export default InviteProvider

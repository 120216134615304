import React from 'react'
import { Skeleton } from 'antd'
import SkeletonAvatar from 'antd/lib/skeleton/Avatar'

function PartnerStepSkeleton () {
  return (
    <div className="partner-step partner-step-skeleton">
      <SkeletonAvatar active/>
      <Skeleton.Button active/>
    </div>
  )
}

export default PartnerStepSkeleton

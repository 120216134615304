import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Badge, Button, Drawer } from 'antd'
import { DrawerProps } from 'antd/lib/drawer'
import React, { ReactNode, useState } from 'react'

interface IDrawerFilters extends DrawerProps {
  children: ReactNode
  onClear: () => void
  counter: number
  disabled?:boolean
  onSearch?: () => void
  showSearch?:boolean
  closeOnClear?: boolean
}

function DrawerFilters ({ children, onClear, onSearch, counter, disabled, showSearch = false, closeOnClear = false, ...rest }: IDrawerFilters) {
  const [visible, setVisible] = useState(false)

  const onHandleSearch = () => {
    setVisible(false)
    onSearch?.()
  }

  return (
    <div className='drawer-filters'>
      <Button
        className={`drawer-filters__button ${disabled ? 'drawer-filters__button--disabled' : ''}`}
        type='ghost'
        disabled={disabled}
        onClick={() => setVisible(true)}
      >
        {visible ? <MinusOutlined /> : <PlusOutlined />} Filtros

        <Badge
          overflowCount={99}
          count={disabled ? 0 : counter}
          className='drawer-filters__badge-count'
        />
      </Button>

      <Drawer
        title='Filtros'
        width='max(30vw, 250px)'
        className='drawer-filters__drawer'
        destroyOnClose={false}
        visible={visible}
        onClose={() => {
          setVisible(false)
          onSearch?.()
        }}
        {...rest}
      >
        <div className='drawer-filters__selected-filters'>
          <p>{counter ? `Filtros ativos (${counter})` : 'Nenhum filtro selecionado'}</p>

          {!!counter && (
            <Button
              className='drawer-filters__button-clear'
              type='link'
              onClick={() => {
                onClear()
                if (closeOnClear) setVisible(false)
              }}
            >
              Limpar
            </Button>
          )}

          {showSearch && (
            <Button
              type='primary'
              className='drawer-filters__button-search'
              onKeyPress={(e) => {
                if (['Enter', ' '].includes(e.key)) onHandleSearch()
              }}
              onClick={onHandleSearch}
            >
              Buscar
            </Button>
          )}
        </div>

        {children}
      </Drawer>
    </div>
  )
}

export default DrawerFilters

import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Empty, Menu, Space } from 'antd'
import { IProduct } from 'ecp/models/ProductModel'
import React, { useState } from 'react'
import ProductItem from '../ProductItem/ProductItem'
import ProductItemButton, { ProductItemButtonTheme } from '../ProductItemButton/ProductItemButton'

type Props = {
  products: IProduct[]
  selectedProductId: string
  onProductSelect: (id: string, products: IProduct[]) => Promise<void>
}

interface ICardListProps extends ProductItemButtonTheme {
  list: IProduct[]
  onItemSelect: (value: string, list: IProduct[]) => Promise<void>
  selectedId: string
}

export function ProductsCardsList ({ list, onItemSelect, selectedId, theme }: ICardListProps) {
  const [loading, setLoading] = useState(false)
  const [loadingSelectedId, setLoadingSelectedId] = useState('')

  if (!list.length) {
    return (
      <Empty
        className='products-modal__empty'
        description='Nenhum produto encontrado'
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    )
  }

  return (
    <div className='products-dropdown__body'>
      {list.map((item) => (
        <div key={item._id}>
          <ProductItemButton
            theme={theme}
            loading={loading && loadingSelectedId === item._id}
            active={selectedId === item._id}
            onClick={async () => {
              setLoading(true)
              setLoadingSelectedId(String(item._id))
              await onItemSelect(String(item._id), list)
              setLoading(false)
            }}
          >
            <ProductItem
              name={item.shortenName}
              description={item.description}
              icon={item.icon}
            />
          </ProductItemButton>
        </div>
      ))}
    </div>
  )
}

function ProductDropdown ({ products, selectedProductId, onProductSelect }: Props) {
  const selectedProduct = products.find(product => product._id === selectedProductId)

  return (
    <>
      <Dropdown
        placement="bottomRight"
        className='products-dropdown'
        trigger={['click']}
        overlay={
          <Menu
            className='products-dropdown__menu'
          >
            <p className='products-dropdown__item-subtitle mb-2'>Seus produtos</p>
            <ProductsCardsList
              list={products}
              onItemSelect={onProductSelect}
              selectedId={selectedProductId}
            />
          </Menu>
        }
      >
        <a
          onClick={event => { event.preventDefault() }}
        >
          <Space>
            <span className='color-white color-white products-dropdown__current-product'>
            Produto {selectedProduct?.shortenName && `- ${selectedProduct?.shortenName}`} <DownOutlined />
            </span>
          </Space>
        </a>
      </Dropdown>
    </>
  )
}

export default ProductDropdown

import React, { useState } from 'react'
import { Form, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import TokenVerification from 'components/TokenVerification/TokenVerification'
import { IError } from 'ecp/app/Proposals/proposalInterfaces'
import SignupLayout from 'layouts/SignupLayout/SignupLayout'
import { handleOnidataErrors } from 'utils/validate'
import TokenHeader from 'ecp/components/TokenHeader/TokenHeader'
import { useHistory, useParams } from 'react-router-dom'
import { ISignupClienttoken_confirmacaoForm } from 'ecp/app/SignUpClient/signUpClientInterfaces'
import { UNAUTHS_PATHS } from 'routes/unauthRoutes'
import useFirstAccessTokenInfo from '../../hooks/useFirstAccessTokenInfo'
import { IFirstAccessConfirm, IFirstAccessTokenInfo } from '../../firstAccesInterfaces'
import FirstAccesResendToken from '../../components/FirstAccesResendToken/FirstAccesResendToken'
import LoginRepository from 'ecp/repositories/LoginRepository'
import SignupButton from 'egi/app/Signup/components/SignupButton/SignupButton'
import { swalError } from 'components/SwalError/SwalError'

function FirstAccessClientPhoneToken () {
  const [form] = useForm<ISignupClienttoken_confirmacaoForm>()
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [errors, setErrors] = useState<IError<ISignupClienttoken_confirmacaoForm>>({})

  const { tokenJwt } = useParams<{ tokenJwt?: string }>()
  const { tokenInfo } = useFirstAccessTokenInfo()

  const history = useHistory()

  function redirectToNextStep (token: string, tokenInfo: IFirstAccessTokenInfo) {
    history.push({
      pathname: `${UNAUTHS_PATHS.FIRST_ACCESS_CLIENT_LGPD.replace(':tokenJwt', String(token))}`,
      state: tokenInfo
    })
  }

  function handleSubmit (token: string) {
    return async function (values: ISignupClienttoken_confirmacaoForm) {
      setLoadingSubmit(true)

      const data: IFirstAccessConfirm = {
        ...values,
        onidataToken: token
      }

      try {
        const response = await LoginRepository.confirmSms(data)

        message.success(response.data.message)
        redirectToNextStep(String(token), tokenInfo)
      } catch (err) {
        if (err?.data && err?.data?.error) {
          message.error(err?.message)
          setErrors(handleOnidataErrors(err.data.error))
        }
        swalError({ title: 'Atenção', err, icon: 'warning' })
      } finally {
        setLoadingSubmit(false)
      }
    }
  }

  return (
    <SignupLayout>
      <TokenHeader
        title='Token enviado para o seu celular'
        infoText={<p className='ecp-token-header__info-text'>Adicione o token que você recebeu no número <b>{tokenInfo?.cellphone}</b></p>}
        className='mb-5'
      />
      <Form
        onFinish={handleSubmit(String(tokenJwt))}
        form={form}
        layout='vertical'
        className='unauth-form'
      >
        <Form.Item
          name='token_confirmacao'
          label={<span className='simulator-label'>Digite o token</span>}
          help={errors?.token_confirmacao}
          validateStatus={errors?.token_confirmacao && ('error')}
          rules={[
            {
              required: true,
              message: 'Adicione o token'
            }
          ]}
        >
          <TokenVerification lenght={6}/>
        </Form.Item>
        <Form.Item>
          <SignupButton
            loading={loadingSubmit}
            htmlType='submit'
            className='w-100'
          >
            Enviar
          </SignupButton>
        </Form.Item>
      </Form>

      {tokenInfo.email &&
        <div className='flex flex-justify-center'>
          <FirstAccesResendToken
            value={{
              telefone_celular: tokenInfo.cellphone,
              onidataToken: tokenJwt,
              celular_eh_whatsapp: tokenInfo.celular_eh_whatsapp
            }}
            sendMethod={'cellphone'}
          />
        </div>
      }
    </SignupLayout>
  )
}

export default FirstAccessClientPhoneToken

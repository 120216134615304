import { LockOutlined } from '@ant-design/icons'
import { Col, Form } from 'antd'
import { InputPassword } from 'components'
import { IError } from 'ecp/app/Proposals/proposalInterfaces'
import { PASSWORD_HELP_TEXT } from 'egi/app/Signup/signupConstants'
import React from 'react'
import { owaspVerify } from 'utils/owasp'
import { ISingupClientPasswordFields } from '../../signUpClientInterfaces'

type Props = {
  errors?: IError<ISingupClientPasswordFields>
}

export default function PasswordInputs ({ errors }: Props) {
  return (
    <>
      <Col lg={24} sm={24} xs={24} className='px-1'>
        <Form.Item
          name='senha'
          label={<label className='simulator-label'>Senha</label>}
          help={errors?.senha && errors?.senha}
          validateStatus={errors?.senha && 'error'}
          rules={[
            {
              required: true,
              message: 'Adicione sua confirmação de senha!'
            },
            () => ({
              validator (_, value) {
                if (owaspVerify(value)) {
                  return Promise.resolve()
                }

                return Promise.reject(new Error(PASSWORD_HELP_TEXT))
              }
            })
          ]}
        >
          <InputPassword
            className='unauth-inputs'
            placeholder='Confirmar senha'
            prefix={<LockOutlined />}
          />
        </Form.Item>
      </Col>

      <Col lg={24} sm={24} xs={24} className='px-1'>
        <Form.Item
          name='confirmar_senha'
          label={<label className='simulator-label'>Confirmação de senha</label>}
          help={errors?.confirmar_senha && errors?.confirmar_senha}
          validateStatus={errors?.confirmar_senha && 'error'}
          rules={[
            {
              required: true,
              message: 'Adicione sua confirmação de senha!'
            },
            ({ getFieldValue }) => ({
              validator (_, value) {
                if (!value || getFieldValue('senha') === value) {
                  return Promise.resolve()
                }

                if (!owaspVerify(value)) {
                  return Promise.resolve()
                }

                return Promise.reject(new Error('As senhas não coincidem!'))
              }
            })
          ]}
        >
          <InputPassword
            className='unauth-inputs'
            placeholder='Confirmar senha'
            prefix={<LockOutlined />}
          />
        </Form.Item>
      </Col>
    </>
  )
}

import { ECPFPropostaSituacaoEsteira, IRangeFiltersTypes, ProposalECPFTipoConsulta } from 'ecpf/repositories/ProposalECPFRepository'
import { ProposalFlowStep } from './ProposalECPFInterfaces'

export type StepData = {
  title: string
  key: ProposalFlowStep
}

type ProposalStepsData = {
  [key: string]: StepData
}

const proposalStepsDefault: ProposalStepsData = {
  customerData: {
    title: 'Dados do cliente',
    key: ProposalFlowStep.customerData
  },
  simulation: {
    title: 'Simulação',
    key: ProposalFlowStep.simulation
  },
  simulationResume: {
    title: 'Resumo da simulação',
    key: ProposalFlowStep.simulationResume
  },
  extraCustomerData: {
    title: 'Informações extra',
    key: ProposalFlowStep.extraCustomerData
  },
  tokenValidate: {
    title: 'Validação de token',
    key: ProposalFlowStep.tokenValidation
  },
  portabilitySimulation: {
    title: 'Simulação',
    key: ProposalFlowStep.portabilitySimulation
  },
  portabilitySimulationResume: {
    title: 'Resumo da simulação',
    key: ProposalFlowStep.portabilitySimulationResume
  },
  refinancingSimulation: {
    title: 'Simulação',
    key: ProposalFlowStep.refinancingSimulation
  },
  renegotiationSimulation: {
    title: 'Simulação',
    key: ProposalFlowStep.renegotiationSimulation
  }
}

export const newContractSteps = [
  proposalStepsDefault.customerData,
  proposalStepsDefault.simulation,
  proposalStepsDefault.simulationResume,
  proposalStepsDefault.extraCustomerData,
  proposalStepsDefault.tokenValidate
]

export const portabilitySteps = [
  proposalStepsDefault.customerData,
  proposalStepsDefault.portabilitySimulation,
  proposalStepsDefault.portabilitySimulationResume,
  proposalStepsDefault.extraCustomerData,
  proposalStepsDefault.tokenValidate
]

export const refinancingSteps = [
  proposalStepsDefault.customerData,
  proposalStepsDefault.refinancingSimulation,
  proposalStepsDefault.simulationResume,
  proposalStepsDefault.extraCustomerData,
  proposalStepsDefault.tokenValidate
]

export const renegotiationSteps = [
  proposalStepsDefault.customerData,
  proposalStepsDefault.renegotiationSimulation,
  proposalStepsDefault.simulationResume,
  proposalStepsDefault.extraCustomerData,
  proposalStepsDefault.tokenValidate
]

export enum PhoneValuesEnum {
  NaoDefinido = 'NaoDefinido',
  FoneFisico = 'FoneFisico',
  FoneComercial1 = 'FoneComercial1',
  FoneComercial2 = 'FoneComercial2',
  Celular = 'Celular',
  FoneRecado = 'FoneRecado',
  EmpregoAnterior = 'EmpregoAnterior',
  Fax = 'Fax'
}

export enum ReferenciaValuesEnum {
  NaoDefinido = 'NaoDefinido',
  Comercial = 'Comercial',
  Pessoal = 'Pessoal'
}

const PHONE_TYPE_OPTIONS = [
  { label: 'Não definido', value: PhoneValuesEnum.NaoDefinido, key: '0' },
  { label: 'Físico', value: PhoneValuesEnum.FoneFisico, key: '1' },
  { label: 'Comercial 1', value: PhoneValuesEnum.FoneComercial1, key: '2' },
  { label: 'Comercial 2', value: PhoneValuesEnum.FoneComercial2, key: '3' },
  { label: 'Celular', value: PhoneValuesEnum.Celular, key: '4' },
  { label: 'Recado', value: PhoneValuesEnum.FoneRecado, key: '5' },
  { label: 'Emprego anterior', value: PhoneValuesEnum.EmpregoAnterior, key: '6' }
]

const PHONE_TYPE_PROPOSAL_OPTIONS = [
  ...PHONE_TYPE_OPTIONS,
  { label: 'Fax', value: PhoneValuesEnum.Fax, key: '7' }
]

const REFERENCIA_TYPE_OPTIONS = [
  { label: 'Não definido', value: ReferenciaValuesEnum.NaoDefinido, key: '0' },
  { label: 'Comercial', value: ReferenciaValuesEnum.Comercial, key: '1' },
  { label: 'Pessoal', value: ReferenciaValuesEnum.Pessoal, key: '2' }
]

export enum PhoneReferenciaValuesEnum {
  NaoDefinido = 'NaoDefinido',
  Recado = 'Recado',
  Residencial = 'Residencial',
  Celular = 'Celular'
}

const REFERENCIA_PHONE_TYPE_OPTIONS = [
  { label: 'Não definido', value: PhoneReferenciaValuesEnum.NaoDefinido, key: '0' },
  { label: 'Recado', value: PhoneReferenciaValuesEnum.Recado, key: '1' },
  { label: 'Residencial', value: PhoneReferenciaValuesEnum.Residencial, key: '2' },
  { label: 'Celular', value: PhoneReferenciaValuesEnum.Celular, key: '3' }
]

const SEXO_OPTIONS = [
  { label: 'Masculino', value: 'Masculino', key: '1' },
  { label: 'Feminino', value: 'Feminino', key: '2' }
]

const NACIONALIDADE_OPTIONS = [
  { label: 'Brasileira', value: 'Brasileira', key: '1' },
  { label: 'Estrangeira', value: 'Estrangeira', key: '2' }
]

const ESCOLARIDADE_OPTIONS = [
  { label: 'Primeiro grau', value: 'PrimeiroGrau', key: '1' },
  { label: 'Segundo grau', value: 'SegundoGrau', key: '2' },
  { label: 'Superior', value: 'Superior', key: '3' },
  { label: 'Sem escolaridade', value: 'SemEscolaridade', key: '4' },
  { label: 'Pós graduacao', value: 'PosGraduacao', key: '5' },
  { label: 'Mestrado/Doutorado', value: 'MestradoDoutorado', key: '6' }
]

const TIPO_CONTA_OPTIONS = [
  { label: 'Não informado', value: 'NaoInformado', key: '1' },
  { label: 'Conta corrente individual', value: 'ContaCorrenteIndividual', key: '2' },
  { label: 'Conta poupança individual', value: 'ContaPoupancaIndividual', key: '3' },
  { label: 'Conta salário', value: 'ContaSalario', key: '4' },
  { label: 'Conta corrente conjunta', value: 'ContaCorrenteConjunta', key: '5' },
  { label: 'Conta poupança conjunta', value: 'ContaPoupancaConjunta', key: '6' },
  { label: 'Conta investimento', value: 'ContaInvestimento', key: '7' }
]

const TIPO_PESSOA_OPTIONS = [
  { label: 'Física', value: 'Fisica', key: '0' }
]

const TIPO_DOCUMENTO_OPTIONS = [

  { label: 'RG', value: 'RG', key: '1' },
  { label: 'RNE', value: 'RNE', key: '2' },
  { label: 'CTPS', value: 'CTPS', key: '3' },
  { label: 'CNH', value: 'CNH', key: '4' },
  { label: 'CR', value: 'CR', key: '5' }
]

const ESTADO_CIVIL_OPTIONS = [
  { label: 'Solteiro', value: 'Solteiro', key: '1' },
  { label: 'Casado', value: 'Casado', key: '2' },
  { label: 'Desquitado', value: 'Desquitado', key: '3' },
  { label: 'Divorciado', value: 'Divorciado', key: '4' },
  { label: 'Viuvo', value: 'Viuvo', key: '5' },
  { label: 'Outros', value: 'Outros', key: '9' }
]

const CARTAO_OPTIONS = [
  { label: 'Visa', value: 'Visa', key: '1' },
  { label: 'Mastercard', value: 'Mastercard', key: '2' },
  { label: 'Dinners', value: 'Dinners', key: '3' },
  { label: 'Amex', value: 'Amex', key: '4' },
  { label: 'Hipercard', value: 'Hipercard', key: '5' },
  { label: 'Goodcard', value: 'Goodcard', key: '6' },
  { label: 'Credicard', value: 'Credicard', key: '7' },
  { label: 'Senff', value: 'Senff', key: '8' },
  { label: 'Outros', value: 'Outros', key: '9' }
]

const TIPO_ENDERECO_OPTIONS = [
  { label: 'Residencial', value: 'Residencial', key: '1' },
  { label: 'Comercial', value: 'Comercial', key: '2' },
  { label: 'Referencia', value: 'Referencia', key: '3' },
  { label: 'Correspondencia', value: 'Correspondencia', key: '4' }
]

const TIPO_CORRESPONDENCIA_OPTIONS = [
  { label: 'Residencial', value: 'Residencial', key: '1' },
  { label: 'Comercial', value: 'Comercial', key: '2' },
  { label: 'Referencia', value: 'Referencia', key: '3' },
  { label: 'Correspondencia', value: 'Correspondencia', key: '4' }
]

const TIPO_CONSULTA_OPTIONS = [
  { label: 'Andamento', value: ProposalECPFTipoConsulta.Andamento },
  { label: 'Canceladas/Integradas', value: ProposalECPFTipoConsulta.CanceladasIntegradas }
]

export const ECPF_OPTIONS = {
  PHONE_TYPE_OPTIONS,
  PHONE_TYPE_PROPOSAL_OPTIONS,
  SEXO_OPTIONS,
  NACIONALIDADE_OPTIONS,
  ESCOLARIDADE_OPTIONS,
  TIPO_ENDERECO_OPTIONS,
  TIPO_CONTA_OPTIONS,
  TIPO_PESSOA_OPTIONS,
  TIPO_DOCUMENTO_OPTIONS,
  ESTADO_CIVIL_OPTIONS,
  TIPO_CONSULTA_OPTIONS,
  CARTAO_OPTIONS,
  TIPO_CORRESPONDENCIA_OPTIONS,
  REFERENCIA_TYPE_OPTIONS,
  REFERENCIA_PHONE_TYPE_OPTIONS
}

export const SITUATION_OPTIONS = [
  {
    value: '',
    label: 'Todos'
  },
  {
    value: ECPFPropostaSituacaoEsteira.andamento,
    label: 'Em andamento'
  },
  {
    value: ECPFPropostaSituacaoEsteira.liberada,
    label: 'Liberada'
  },
  {
    value: ECPFPropostaSituacaoEsteira.aprovada,
    label: 'Aprovada'
  },
  {
    value: ECPFPropostaSituacaoEsteira.pendente,
    label: 'Pendente'
  },
  {
    value: ECPFPropostaSituacaoEsteira.reprovada,
    label: 'Reprovada'
  },
  {
    value: ECPFPropostaSituacaoEsteira.integrada,
    label: 'Integrada'
  },
  {
    value: ECPFPropostaSituacaoEsteira.cancelada,
    label: 'Cancelada'
  }
]

export const RANGE_DATE_TYPE_OPTIONS = [
  {
    label: 'Data base',
    value: IRangeFiltersTypes.dataBase
  },
  {
    label: 'Data de cadastro',
    value: IRangeFiltersTypes.dataCadastro
  },
  {
    label: 'Data de atividade',
    value: IRangeFiltersTypes.dataAtividade
  }
]

import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import React from 'react'

interface IProps extends ButtonProps {
  colorClass: 'button-red' | 'button-green'
}

function DynamicColorButton ({ colorClass, className = '', ...rest }: IProps) {
  return (
    <Button
      className={`${className} dynamic-color-button ${colorClass}`}
      {...rest}
    />
  )
}

export default DynamicColorButton

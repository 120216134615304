import { Button, Checkbox, Empty, Modal, Result, Select, Tooltip } from 'antd'
import React, { ReactNode, useMemo } from 'react'
import { SearchOutlined, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import useMultiSelect from 'hooks/useMultiSelect'
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll'
import { IOption } from 'components/MultiSelect/MultiSelect'
import useDebounce from 'hooks/useDebounce'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { SelectProps } from 'antd/lib/select'
import { AxiosRequestConfig } from 'axios'
import { Pressable } from 'components/Pressable/Pressable'

export type IParams = AxiosRequestConfig & {
  page: number
  limit: number
  name?: string
  isSupervisor?: boolean
}

interface IMultiSelectModal<T extends IOption = any> extends Omit<SelectProps<T>, 'mode'| 'value' | 'options'> {
  options: T[]
  value?: Array<CheckboxValueType | string>
  modalTitle?: string
  open: boolean
  onCancel: () => void
  onConfirm: (values?: Array<CheckboxValueType>) => void
  initialValues?: Array<CheckboxValueType>
  renderOption?: (option: T) => ReactNode
}

interface IMultiSelectInfiniteModal<T extends IOption = any> extends IMultiSelectModal<T> {
  hasNextPage: boolean
  isLoading: boolean
  setParams: React.Dispatch<React.SetStateAction<IParams>>
  error: string | undefined
  searchedOptions: T[]
}

const handleChangeSearch = (e: CheckboxChangeEvent | React.MouseEvent<HTMLElement, MouseEvent>) => {
  e.preventDefault()
  e.stopPropagation()
}

type IOptionsWrapperProps = {
  children: React.ReactNode
  quantity?: number
  hasNextPage: boolean | undefined
  isLoading: boolean | undefined
  setParams: React.Dispatch<React.SetStateAction<IParams>> | undefined
  error: string | undefined
}

function OptionsWrapper ({ children, quantity, error, hasNextPage, isLoading, setParams }:IOptionsWrapperProps) {
  if (error) {
    return (
      <aside className='multi-select-modal__empty'>
        <Result status="error" extra={error}/>
      </aside>
    )
  }

  if (quantity === 0) {
    return (
      <aside className='multi-select-modal__empty'>
        <Empty description='Nenhuma opção foi selecionada...'/>
      </aside>
    )
  }

  if (typeof hasNextPage === 'boolean') {
    return (
      <InfiniteScroll
        root={document.querySelector('.multi-select-modal__info')}
        more={!!hasNextPage}
        loading={!!isLoading}
        loadingCover={<p>Loading </p>}
        fetch={() => setParams?.(prev => ({ ...prev, page: prev.page + 1 }))}
      >
        {children}
      </InfiniteScroll>
    )
  }

  return <>{children}</>
}

function MultiSelectModal<T extends IOption> (props: IMultiSelectModal<T> | IMultiSelectInfiniteModal<T>) {
  const { modalTitle = 'Múltiplas seleções', open, onCancel, onConfirm, options, initialValues, renderOption } = props

  const {
    onCheckAll,
    onSelectSearchValue,
    onOpenDropdown,
    onClearSearch,
    handleCheckedList,
    handleShowOnlyChecked,
    onCloseDropdown,

    checkAll,
    filterText,
    haveSearch,
    checkedList,
    filteredList,
    searchValue
  } = useMultiSelect<T>({ options, initialValues })

  const searchedOptions = 'searchedOptions' in props ? props.searchedOptions : null
  const setParams = 'setParams' in props ? props.setParams : null
  const isLoading = 'isLoading' in props ? props.isLoading : undefined

  useDebounce(() => {
    if (setParams) setParams(prev => ({ ...prev, name: searchValue || undefined }))
  }, 500, [searchValue])

  const optionsFiltered = useMemo(() => {
    if (searchedOptions?.length) return searchedOptions
    if (options?.length) return options
    return []
  }, [options, searchedOptions])

  return (
    <Modal
      centered
      width={800}
      visible={open}
      footer={false}
      title={modalTitle}
      onCancel={onCancel}
      maskClosable={false}
    >
      <section>
        <label>Pesquisar</label>

        <Select
          showSearch
          className='w-100'
          open={ haveSearch}
          filterOption={true}
          value={searchValue}
          searchValue={searchValue}
          optionFilterProp='label'
          onBlur={onCloseDropdown}
          onSelect={(e) => {
            onSelectSearchValue(e)
          }}
          onSearch={onOpenDropdown}
          suffixIcon={searchValue ? <CloseCircleOutlined onClick={onClearSearch}/> : <SearchOutlined />}
          placeholder='Encontre uma opção específica'
          loading={isLoading}
          notFoundContent={
            isLoading
              ? (
                <section className='multi-select-modal__empty'>
                  <LoadingOutlined style={{ fontSize: '2rem' }}/>
                </section>
              )
              : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Nenhuma opção encontrada."/>

          }
        >
          {optionsFiltered?.map((opt) => (
            <Select.Option key={String(opt.value)} value={String(opt.value)} label={opt.label}>
              <Checkbox
                checked={checkedList?.includes(opt.value)}
                onChange={(e) => {
                  handleChangeSearch(e)

                  onSelectSearchValue(String(opt.value))
                }}
                onClick={handleChangeSearch}
                className='multi-select-modal__search-options multi-select-modal__search-options--checkbox'
              >
                <div onClick={(e) => {
                  handleChangeSearch(e)

                  onSelectSearchValue(String(opt.value))
                }}>
                  {renderOption ? renderOption(opt) : opt.label}
                </div>
              </Checkbox>
            </Select.Option>
          ))}
        </Select>
      </section>

      <section className='multi-select-modal__info'>
        <label>Selecione abaixo:</label>

        <Tooltip title={filterText.tooltipText} destroyTooltipOnHide>
          <Pressable
            onClick={handleShowOnlyChecked}
          >
            <label className='multi-select-modal__quantity'>
              {filterText.text}
            </label>
          </Pressable>
        </Tooltip>
      </section>

      <section className='multi-select-modal__options-container'>
        <Checkbox
          className='multi-select-modal__first-option'
          checked={checkAll}
          onChange={onCheckAll}
        >
          Selecionar tudo
        </Checkbox>

        <Checkbox.Group
          value={checkedList}
          onChange={handleCheckedList}
          className='multi-select-modal__checkbox-group multi-select-modal__checkbox-group--option'
        >
          <OptionsWrapper
            hasNextPage={'hasNextPage' in props ? props.hasNextPage : undefined}
            isLoading={'isLoading' in props ? props.isLoading : undefined}
            setParams={'setParams' in props ? props.setParams : undefined}
            error={'error' in props ? props.error : undefined}
            quantity={filteredList?.length}
          >
            {filteredList?.map(option => (
              <Checkbox
                key={String(option.value)}
                value={option.value}
                checked={checkedList?.includes(option.value)}
              >
                {renderOption ? renderOption(option) : option.label}
              </Checkbox>)
            )}
          </OptionsWrapper>

        </Checkbox.Group>
      </section>

      <aside className='multi-select-modal__buttons'>
        <Button
          type='ghost'
          onClick={onCancel}
        >
          Cancelar
        </Button>

        <Button
          type='primary'
          onClick={() => onConfirm(checkedList)}
        >
          Confirmar
        </Button>
      </aside>
    </Modal>
  )
}

export default MultiSelectModal

import React, { useEffect } from 'react'
import SimulatorLayout from 'layouts/Simulator/SimulatorLayout'
import UnauthedFileUpload from 'components/UnauthedFileUpload/UnauthedFileUpload'
import { Layout } from 'antd'
import Signin from 'egi/app/Signin/Signin'
import Signup from 'egi/app/Signup/Signup'
import LGPD from 'egi/app/LGPD/views/LGPD/LGPD'
import CreditApprove from 'egi/app/CreditApprove/views/CreditApprove'
import EcpResetPassword from 'ecp/app/SignupForm/SignupForm'
import EcpSignupFormToken from 'ecp/app/SignupForm/components/SignupFormToken/SignupFormToken'
import ForgotPassword from 'ecp/app/ForgotPassword/ForgotPassword'

import EcpSignupClientEmailToken from 'ecp/app/SignUpClient/views/SignupClientEmailToken/SignupClientEmailToken'
import EcpSignupClientLGPD from 'ecp/app/SignUpClient/views/SignupClientLGPD/SignupClientLGPD'
import EcpSignupClientPhone from 'ecp/app/SignUpClient/views/SignupClientPhone/SignupClientPhone'
import EcpSignupClientPhoneToken from 'ecp/app/SignUpClient/views/SignupClientPhoneToken/SignupClientPhoneToken'
import EcpSignupClientPasswords from 'ecp/app/SignUpClient/views/SignupClientPasswords/SignupClientPasswords'
import FirstAccessClientCheckLink from 'ecp/app/FirstAccess/views/FirstAccessClientCheckLink/FirstAccessClientCheckLink'
import FirstAccessClientEmailCpfPassword from 'ecp/app/FirstAccess/views/FirstAccessClientEmailCpfPassword/FirstAccessClientEmailCpfPassword'
import FirstAccessClientEmailToken from 'ecp/app/FirstAccess/views/FirstAccessClientEmailToken/FirstAccessClientEmailToken'
import FirstAccesClientPhone from 'ecp/app/FirstAccess/views/FirstAccesClientPhone/FirstAccesClientPhone'
import FirstAccessClientLGPD from 'ecp/app/FirstAccess/views/FirstAccessClientLGPD/FirstAccessClientLGPD'
import FirstAccessClientPhoneToken from 'ecp/app/FirstAccess/views/FirstAccessClientPhoneToken/FirstAccessClientPhoneToken'
import ProposalUnauthContract from 'ecp/app/Proposals/views/ProposalUnauthContract/ProposalUnauthContract'
import FollowUpProposalIndiky from 'egi/app/ProposalEgi/views/FollowUpProposalIndiky/FollowUpProposalIndiky'
import { InternalChangeEmailValidate, InternalChangeEmailVerify } from 'egi/app/InternalChangeEmail/InternalChangeEmail'
import ResetPassword from 'egi/app/ResetPassword/views/ResetPassword/ResetPassword'
import { FortifyPassword } from 'egi/app/FortifyPassword/FortifyPassword'
import VerifyEmail from 'egi/app/VerifyEmail/VerifyEmail'
import { ResendEmail } from 'egi/app/ResendEmail/ResendEmail'
import ResetPasswordSendEmail from 'egi/app/ResetPassword/views/ResetPasswordSendEmail/ResetPasswordSendEmail'

import FirstAccessPassword from 'ecp/app/FirstAccess/views/FirstAccessPassword/FirstAccessPassword'
import FirstAccessPhone from 'ecp/app/FirstAccess/views/FirstAccesCorbanPhone/FirstAccessPhone'
import FirstAccessPhoneToken from 'ecp/app/FirstAccess/views/FirstAccesCorbanPhoneToken/FirstAccessPhoneToken'
import LGPDAccessCheckCnpj from 'ecp/app/LGPDAcess/views/LGPDAccessCheckCnpj/LGPDAccessCheckCnpj'
import LGPDAccessSendEmail from 'ecp/app/LGPDAcess/views/LGPDAccessSendEmail/LGPDAccessSendEmail'
import LGPDAccessConfirmEmail from 'ecp/app/LGPDAcess/views/LGPDAccessConfirmEmail/LGPDAccessConfirmEmail'
import LGPDAccessSignUp from 'ecp/app/LGPDAcess/views/LGPDAccessSignup/LGPDAccessSignup'
import LGPDAccessConfirmSignUp from 'ecp/app/LGPDAcess/views/LGPDAccessConfirmSignUp/LGPDAccessConfirmSignUp'
import { isProduction } from 'utils/globals'
import UnauthUploadFile from 'ecp/app/UnauthUploadFile/Views/UnauthUploadFile/UnauthUploadFile'
import { ForgotPasswordECPF } from 'ecpf/app/ForgotPasswordECPF/ForgotPasswordECPFLoader'
import SigninB2CCheckUserECPF from 'ecpf/app/SigninB2CECPF/views/SigninB2CCheckUserECPF/SigninB2CCheckUserECPF'

const chooseCorrectUrl = () => {
  if (isProduction) return 'https://d3vorm0bpoy42w.cloudfront.net'
  return 'https://canal.rodobens.hmg.tec.br'
}

const RedirectToOnidata = () => {
  useEffect(() => {
    const host = window.location.href
    const url = new URL(host)
    const onibase = chooseCorrectUrl()
    const finalUrl = onibase + url.pathname + url.search

    window.location.href = finalUrl
  }, [])

  return null
}

export const UNAUTHS_PATHS = {

  MAIN: '/',

  SIMULATOR: '/simulator',

  PRIVACY_POLICY: '/politicas-de-privacidade',

  SIGNUP: '/signup',

  FORGOT: '/forgot',

  FORGOT_SENT_EMAIL: '/forgot-sent-email',

  SIGNUP_CLIENT_PASSWORDS: '/signup/client/:preSignupToken',

  SIGNUP_CLIENT_PRIVACY_POLICY: '/signup/client/privacy-policy',

  SIGNUP_CLIENT_EMAIL: '/signup/client/email',

  SIGNUP_CLIENT_PHONE: '/signup/client/phone',

  SIGNUP_CLIENT_PHONE_TOKEN: '/signup/client/phone/token',

  ECP_PRIVACY_POLICY: '/ecp/privacy-policy',

  ECP_FORGOT: '/ecp-forgot',

  ECP_FORGOT_TOKEN: '/ecp-forgot/token',

  ECP_FORGOT_TOKEN_ONIDATA: '/ecp-forgot/token-onidata',

  NEW: '/new',

  DOCUMENTS: '/documentos',

  ECP_UPLOAD: '/ecp-upload',

  CREDIT_APPROVE: '/creditapprove',

  CHANGE_EMAIL: '/changeemail',

  PROMOTER_CLIENT_EMAIL: '/promoterclientemail',

  LGPD_ACCESS_CHECK_CNPJ: '/lgpd-access/cnpj',

  LGPD_ACCESS_SEND_EMAIL: '/lgpd-access/email-send',

  LGPD_ACCESS_CONFIRM_EMAIL: '/lgpd-access/email-confirm',

  LGPD_ACCESS_SIGNUP: '/lgpd-access/validate',

  LGPD_ACCESS_CONFIRM_SIGN_UP: '/lgpd-access/signup-confirm',

  FIRST_ACCESS_CLIENT_ECP_CHECK_LINK: '/first-access/check-link',

  FIRST_ACCESS_CLIENT_PASSWORD: '/first-access/password',

  FIRST_ACCESS_CLIENT_EMAIL_TOKEN: '/first-access/email-token/:tokenJwt',

  FIRST_ACCESS_CLIENT_PHONE: '/first-access/client-phone/:tokenJwt',

  FIRST_ACCESS_CLIENT_PHONE_TOKEN: '/first-access/phone/token/:tokenJwt',

  FIRST_ACCESS_CLIENT_LGPD: '/first-access/LGPD/:tokenJwt',

  FIRST_ACCESS_PHONE: '/first-access/manager/phone/',

  FIRST_ACCESS_PHONE_TOKEN: '/first-access/manager/phone-token/',

  FIRST_ACCESS_PASSWORD: '/first-access/manager/password/',

  ACCEPT_CONTRACT: '/accept-contract/:token',

  UNAUTH_PROPOSAL: '/unauth-proposal',

  FORTIFY_PASSWORD: '/fortify/password',

  VERIFY_EMAIL: '/verify-email',

  RESEND_EMAIL: '/resend-email',

  SIGNIN_CONTRACT: '/sign-contract/cep/',

  SIGNIN_PORTABILITY: '/sign-portability/cep/',

  VALIDATE_INVITE: '/validate-invite/',

  OIDC: '/signin/oidc',

  // ECP Função

  FORGOT_ECPF: '/ecpf/forgot-password',

  SIGNIN_PASSWORD_ECPF: '/ecpf/signin/password',

  B2C_LOGIN: '/b2c/cliente'

}

export const UNAUTHS_ROUTES = [
  { path: UNAUTHS_PATHS.MAIN, component: Signin, withToken: false },

  { path: UNAUTHS_PATHS.OIDC, component: Signin, withToken: false },

  { path: UNAUTHS_PATHS.SIMULATOR, component: SimulatorLayout, withToken: false },

  { path: UNAUTHS_PATHS.SIGNUP, component: Signup, withToken: true },

  { path: UNAUTHS_PATHS.PRIVACY_POLICY, component: LGPD, withToken: true },

  { path: UNAUTHS_PATHS.FORGOT, component: ResetPassword, withToken: true },

  { path: UNAUTHS_PATHS.FORGOT_SENT_EMAIL, component: ResetPasswordSendEmail, withToken: true },

  { path: UNAUTHS_PATHS.SIGNUP_CLIENT_EMAIL, component: EcpSignupClientEmailToken, withToken: true },

  { path: UNAUTHS_PATHS.SIGNUP_CLIENT_PRIVACY_POLICY, component: EcpSignupClientLGPD, withToken: true },

  { path: UNAUTHS_PATHS.SIGNUP_CLIENT_PHONE, component: EcpSignupClientPhone, withToken: true },

  { path: UNAUTHS_PATHS.SIGNUP_CLIENT_PHONE_TOKEN, component: EcpSignupClientPhoneToken, withToken: true },

  { path: UNAUTHS_PATHS.SIGNUP_CLIENT_PASSWORDS, component: EcpSignupClientPasswords, withToken: true },

  { path: UNAUTHS_PATHS.ECP_FORGOT, component: EcpResetPassword, withToken: true },

  { path: UNAUTHS_PATHS.ECP_FORGOT_TOKEN, component: EcpSignupFormToken, withToken: true },

  { path: UNAUTHS_PATHS.ECP_FORGOT_TOKEN_ONIDATA, component: ForgotPassword, withToken: true },

  { path: UNAUTHS_PATHS.NEW, component: ResetPassword, withToken: true },

  { path: UNAUTHS_PATHS.DOCUMENTS, component: UnauthedFileUpload, withToken: true },

  { path: UNAUTHS_PATHS.ECP_UPLOAD, component: UnauthUploadFile, withToken: true },

  { path: UNAUTHS_PATHS.CREDIT_APPROVE, component: () => <Layout className="unauth-upload-background"><CreditApprove /></Layout>, withToken: true },

  { path: UNAUTHS_PATHS.CHANGE_EMAIL, component: InternalChangeEmailValidate, withToken: true },

  { path: UNAUTHS_PATHS.PROMOTER_CLIENT_EMAIL, component: InternalChangeEmailVerify, withToken: true },

  { path: UNAUTHS_PATHS.LGPD_ACCESS_CHECK_CNPJ, component: LGPDAccessCheckCnpj, withToken: true },

  { path: UNAUTHS_PATHS.LGPD_ACCESS_SEND_EMAIL, component: LGPDAccessSendEmail, withToken: true },

  { path: UNAUTHS_PATHS.LGPD_ACCESS_CONFIRM_EMAIL, component: LGPDAccessConfirmEmail, withToken: true },

  { path: UNAUTHS_PATHS.LGPD_ACCESS_SIGNUP, component: LGPDAccessSignUp, withToken: true },

  { path: UNAUTHS_PATHS.LGPD_ACCESS_CONFIRM_SIGN_UP, component: LGPDAccessConfirmSignUp, withToken: true },

  { path: UNAUTHS_PATHS.FIRST_ACCESS_CLIENT_ECP_CHECK_LINK, component: FirstAccessClientCheckLink, withToken: true },

  { path: UNAUTHS_PATHS.FIRST_ACCESS_CLIENT_PASSWORD, component: FirstAccessClientEmailCpfPassword, withToken: true },

  { path: UNAUTHS_PATHS.FIRST_ACCESS_CLIENT_EMAIL_TOKEN, component: FirstAccessClientEmailToken, withToken: true },

  { path: UNAUTHS_PATHS.FIRST_ACCESS_CLIENT_PHONE, component: FirstAccesClientPhone, withToken: true },

  { path: UNAUTHS_PATHS.FIRST_ACCESS_CLIENT_PHONE_TOKEN, component: FirstAccessClientPhoneToken, withToken: true },

  { path: UNAUTHS_PATHS.FIRST_ACCESS_CLIENT_LGPD, component: FirstAccessClientLGPD, withToken: true },

  { path: UNAUTHS_PATHS.FIRST_ACCESS_PHONE, component: FirstAccessPhone, withToken: true },

  { path: UNAUTHS_PATHS.FIRST_ACCESS_PHONE_TOKEN, component: FirstAccessPhoneToken, withToken: true },

  { path: UNAUTHS_PATHS.FIRST_ACCESS_PASSWORD, component: FirstAccessPassword, withToken: true },

  { path: UNAUTHS_PATHS.ACCEPT_CONTRACT, component: ProposalUnauthContract, withToken: true },

  { path: UNAUTHS_PATHS.UNAUTH_PROPOSAL, component: FollowUpProposalIndiky, withToken: true },

  { path: UNAUTHS_PATHS.FORTIFY_PASSWORD, component: FortifyPassword, withToken: true },

  { path: UNAUTHS_PATHS.VERIFY_EMAIL, component: VerifyEmail, withToken: true },

  { path: UNAUTHS_PATHS.RESEND_EMAIL, component: ResendEmail, withToken: true },

  { path: UNAUTHS_PATHS.B2C_LOGIN, component: SigninB2CCheckUserECPF, withToken: true },

  {
    path: UNAUTHS_PATHS.SIGNIN_CONTRACT,
    component: RedirectToOnidata,
    withToken: true
  },

  {
    path: UNAUTHS_PATHS.SIGNIN_PORTABILITY,
    component: RedirectToOnidata,
    withToken: true
  },

  {
    path: UNAUTHS_PATHS.VALIDATE_INVITE,
    component: RedirectToOnidata,
    withToken: true
  },

  // ECP Função

  { path: UNAUTHS_PATHS.FORGOT_ECPF, component: ForgotPasswordECPF, withToken: true }
]

import React, { ReactNode, useMemo } from 'react'
import { EcpCard } from 'ecp/components/EcpCard/EcpCard'
import { Skeleton } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'

type props = {
  title?: string
  value?: string | number
  icon?: ReactNode
  isLoading?: boolean
}

export default function InfoCard ({ title, value, icon, isLoading } : props) {
  const hasNoValues = useMemo(() => typeof value !== 'number' && typeof value !== 'string', [value])

  if (isLoading) {
    return (
      <EcpCard>
        <Skeleton
          active={true}
          paragraph={{ rows: 1 }}
        />
      </EcpCard>
    )
  }

  return (
    <EcpCard>
      <div className='info-card__total-card'>
        <div className='info-card__total-content'>
          <p className='info-card__title'>{title}</p>
          <p className='info-card__value'>{hasNoValues ? '-' : value}</p>
        </div>

        {hasNoValues ? <ExclamationCircleFilled className='info-card__icon'/> : icon}
      </div>
    </EcpCard>
  )
}

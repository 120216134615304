import { Form, message, Typography } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { swalError } from 'components/SwalError/SwalError'
import { IError } from 'ecp/app/Proposals/proposalInterfaces'
import PasswordInputs from 'ecp/app/SignUpClient/components/PasswordInputs/PasswordInputs'
import LoginRepository from 'ecp/repositories/LoginRepository'
import SignupButton from 'egi/app/Signup/components/SignupButton/SignupButton'
import { SignupTitle } from 'egi/app/Signup/components/SignupTitle/SignupTitle'
import { PASSWORD_HELP_TEXT } from 'egi/app/Signup/signupConstants'
import SignupLayout from 'layouts/SignupLayout/SignupLayout'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { handleOnidataErrors } from 'utils/validate'
import { IFirstAccessCorbanRHTokenInfo } from '../../firstAccesInterfaces'
import useFirstAccesCorbanTokenInfo from '../../hooks/useFirstAccesCorbanTokenInfo'

interface IFirstAccesCorbanPasswordForm {
  confirmar_senha: string
  senha: string
}

export default function FirstAccessPassword () {
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [form] = useForm()
  const [errors, setErrors] = useState<IError<IFirstAccesCorbanPasswordForm>>({})
  const history = useHistory()
  const { tokenInfo } = useFirstAccesCorbanTokenInfo()

  function redirectToNextStep () {
    history.push('/')
  }

  async function handleSubmit (values: IFirstAccesCorbanPasswordForm, tokenInfo: IFirstAccessCorbanRHTokenInfo) {
    setLoadingSubmit(true)
    setErrors({})

    try {
      const data = {
        ...values,
        cpf: tokenInfo.cpf ?? '',
        email: tokenInfo.email ?? '',
        onidataToken: tokenInfo.onidataToken ?? ''
      }

      const response = await LoginRepository.firstAccessPassword(data)

      message.success(response.data.message)
      redirectToNextStep()
    } catch (err) {
      swalError({ title: 'Atenção', icon: 'warning', err })
      setErrors(handleOnidataErrors(err.data?.error))
    } finally {
      setLoadingSubmit(false)
    }
  }

  return (
    <SignupLayout>
      <SignupTitle className='mb-1'>Cadastro de senha do usuário</SignupTitle>

      <p className='first-acces__info-text'>Para continuar seu cadastro digite sua senha a ser cadastrada.</p>

      <Form
        layout="vertical"
        className='unauth-form'
        onFinish={(values) => handleSubmit(values, tokenInfo)}
        form={form}
      >

        <div className='ecp-signup-form__password-help-container'>
          <Typography.Paragraph className='ecp-signup-form__password-help-text'>{PASSWORD_HELP_TEXT}</Typography.Paragraph>
        </div>

        <PasswordInputs errors={errors}/>
        <Form.Item>
          <SignupButton
            htmlType='submit'
            className='w-100'
            loading={loadingSubmit}
          >
          Enviar
          </SignupButton>
        </Form.Item>
      </Form>

    </SignupLayout>
  )
}

import React from 'react'
import { Form } from 'antd'
import { DatepickerCustom } from 'components'
import locale from 'antd/es/date-picker/locale/pt_BR'
import { Dayjs } from 'dayjs'

function FilterUpdatedAt ({ onChange }: { onChange: Dayjs | undefined | any }) {
  return (
    <Form.Item
      name="updatedDate"
      label={<label>Data atualização</label>}
    >
      <DatepickerCustom
        locale={locale}
        format="DD/MM/YYYY"
        onChange={onChange}
      />
    </Form.Item>
  )
}

export default FilterUpdatedAt

import { Table } from 'components'
import { enhanceServerTable } from 'components/SeverTable/serverTableEnhance'
import React, { useEffect, useState } from 'react'
import UsersEgiRepository from 'egi/repositories/UsersEgiRepository'

interface IData {
  createdAt: string
  message : string
  sent?: boolean
  failCounter: number
}

const columns = [
  {
    title: 'Data/hora envio',
    dataIndex: 'createdAt'
  },
  {
    title: 'Mensagem',
    dataIndex: 'message'
  },
  {
    title: 'Enviado s/n',
    dataIndex: 'sent',
    render: (value: boolean) => value ? 'Sim' : 'Não'
  },
  {
    title: 'Tentativas',
    dataIndex: 'failCounter'
  }
]

function UserDetailsSMSTable ({ id } : { id: string}) {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IData[]>([])

  useEffect(() => {
    const abortController = new AbortController()
    async function fetchSmss (id: string) {
      setLoading(true)
      try {
        const response = await UsersEgiRepository.getSmss(id, {
          signal: abortController.signal
        })
        if (response.data.data.smsList) {
          const formatedValues = enhanceServerTable(response.data.data.smsList)
          setData(formatedValues)
        }
      } catch (err) {
        console.log(err)
      }
      setLoading(false)
    }

    if (id) fetchSmss(id)

    return () => abortController.abort()
  }, [id])

  return (
    <>
      <Table
        data={data}
        columns={columns}
        loading={loading}
      />
    </>
  )
}

export default UserDetailsSMSTable

import React from 'react'

export default function SuccessSignUpSVG () {
  return (
    <svg width="250" height="198" viewBox="0 0 715 567" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5654_26147)">
        <path d="M449.593 516.77L438.785 516.769L433.643 474.943L449.596 474.944L449.593 516.77Z" fill="#9E616A" />
        <path d="M452.349 527.281L417.498 527.28V526.838C417.498 523.228 418.928 519.767 421.471 517.215C424.015 514.663 427.466 513.229 431.063 513.229H431.064L452.35 513.229L452.349 527.281Z" fill="#2F2E41" />
        <path d="M410.802 516.77L399.994 516.769L394.852 474.943L410.804 474.944L410.802 516.77Z" fill="#9E616A" />
        <path d="M413.558 527.281L378.707 527.28V526.838C378.707 523.228 380.137 519.767 382.68 517.215C385.224 514.663 388.674 513.229 392.272 513.229H392.273L413.559 513.229L413.558 527.281Z" fill="#2F2E41" />
        <path d="M284.918 223.689C285.781 224.881 286.895 225.868 288.18 226.581C289.466 227.294 290.891 227.716 292.357 227.817C293.822 227.918 295.292 227.695 296.662 227.164C298.032 226.634 299.27 225.808 300.288 224.745L321.675 233.124L329.92 221.327L299.552 209.92C297.659 208.323 295.249 207.478 292.777 207.545C290.304 207.612 287.943 208.586 286.139 210.283C284.335 211.98 283.214 214.282 282.989 216.753C282.764 219.224 283.45 221.692 284.918 223.689Z" fill="#9E616A" />
        <path d="M410.664 295.166L382.506 304.381L391.912 504.45L413.645 504.989L424.766 363.898L427.292 504.432L454.163 505.918L464.069 295.166H410.664Z" fill="#00441F" />
        <path d="M391.665 177.228L463.936 161.475L471.973 223.907L465.281 253.737L467.961 299.071C467.961 299.071 410.816 327.606 382.506 304.381C382.506 304.381 405.26 244.421 392.071 216.353L391.665 177.228Z" fill="#A5DC86" />
        <path d="M404.181 186.059L391.665 177.228C391.665 177.228 390.926 176.201 380.496 188.434C370.065 200.667 348.906 220.126 348.906 220.126L313.642 206.859L307.217 230.674L348.202 249.835L406.227 210.957L404.181 186.059Z" fill="#A5DC86" />
        <path d="M467.974 322.472C469.302 321.843 470.476 320.929 471.414 319.795C472.352 318.661 473.031 317.334 473.402 315.908C473.774 314.483 473.83 312.993 473.565 311.543C473.301 310.094 472.723 308.72 471.872 307.519L484.062 287.999L474.045 277.674L457.216 305.482C455.3 307.05 454.023 309.269 453.628 311.718C453.233 314.167 453.747 316.677 455.073 318.771C456.399 320.865 458.445 322.399 460.822 323.081C463.2 323.763 465.744 323.546 467.974 322.472Z" fill="#9E616A" />
        <path d="M452.664 173.773L463.936 161.474C463.936 161.474 476.496 157.086 486.758 179.761C497.019 202.436 508.481 248.43 508.481 248.43L481.684 300.215L460.873 290.886L481.684 248.064L459.661 216.353L452.664 173.773Z" fill="#A5DC86" />
        <path d="M433.55 156.354C444.919 150.119 449.097 135.819 442.882 124.413C436.668 113.008 422.414 108.816 411.045 115.051C399.677 121.286 395.499 135.586 401.713 146.991C407.928 158.397 422.182 162.588 433.55 156.354Z" fill="#9E616A" />
        <path d="M442.079 114.656C440.969 109.982 437.086 106.293 432.618 104.564C428.151 102.834 423.176 102.851 418.455 103.655C410.618 104.991 403.301 108.382 396.09 111.738L401.648 114.405L393.979 114.847L399.905 117.922C398.322 119.13 397.118 120.768 396.436 122.642C395.754 124.516 395.621 126.547 396.055 128.494C396.36 129.769 397.175 131.173 398.481 131.217C400.059 131.27 400.883 129.425 401.987 128.293C403.457 126.787 405.778 126.453 407.86 126.737C409.941 127.021 411.915 127.824 413.969 128.268C416.565 128.811 419.248 128.781 421.832 128.181C424.416 127.58 426.839 126.423 428.933 124.79L436.323 142.643C436.253 141.969 436.339 141.289 436.575 140.655C436.811 140.02 437.189 139.449 437.681 138.986C438.173 138.523 438.765 138.18 439.411 137.985C440.057 137.789 440.739 137.746 441.405 137.858C442.07 137.971 442.7 138.236 443.247 138.633C443.793 139.031 444.24 139.549 444.553 140.149C444.867 140.748 445.038 141.412 445.053 142.089C445.069 142.766 444.928 143.437 444.642 144.05C447.841 139.209 450.415 133.709 450.503 127.902C450.591 122.094 447.72 115.976 442.465 113.539" fill="#2F2E41" />
        <path d="M156.985 492.621L157.263 493.668C160.431 505.859 160.906 515.904 158.665 523.665C158.634 523.779 158.597 523.889 158.566 524.002L158.044 523.848L157.543 523.693C160.081 515.28 158.949 504.65 156.188 494.016C156.102 493.673 156.015 493.324 155.918 492.978C154.709 488.487 153.229 484.013 151.617 479.713C151.495 479.379 151.367 479.041 151.239 478.703C149.082 473.038 146.726 467.704 144.543 463.107C144.39 462.781 144.232 462.457 144.076 462.138C140.349 454.372 137.19 448.869 136.48 447.654C136.396 447.504 136.347 447.423 136.336 447.404L136.79 447.133L136.794 447.126L137.253 446.854C137.263 446.873 137.429 447.145 137.72 447.656C138.818 449.561 141.734 454.751 145.096 461.727C145.246 462.042 145.404 462.366 145.556 462.692C147.332 466.421 149.214 470.615 151.004 475.068C151.456 476.185 151.888 477.292 152.3 478.386C152.434 478.723 152.562 479.06 152.684 479.394C154.426 484.043 155.86 488.451 156.985 492.621Z" fill="white" />
        <path d="M145.097 461.727C144.763 461.866 144.424 462.007 144.077 462.138C143.135 462.497 142.175 462.807 141.202 463.067C137.054 464.181 132.711 464.347 128.491 463.553C128.347 463.886 128.203 464.218 128.055 464.558C132.505 465.434 137.096 465.279 141.477 464.104C142.516 463.826 143.54 463.494 144.544 463.107C144.886 462.977 145.225 462.837 145.557 462.692C148.306 461.518 150.862 459.931 153.136 457.986C152.838 457.761 152.547 457.539 152.262 457.32C150.103 459.135 147.688 460.62 145.097 461.727Z" fill="white" />
        <path d="M152.3 478.388C151.949 478.504 151.595 478.609 151.239 478.705C151.132 478.739 151.018 478.769 150.909 478.798C146.414 479.999 141.695 480.091 137.157 479.067C132.618 478.044 128.393 475.934 124.843 472.918C124.717 473.279 124.592 473.64 124.473 474.005C128.143 477.036 132.481 479.145 137.126 480.159C141.771 481.173 146.591 481.062 151.185 479.835C151.329 479.797 151.474 479.758 151.617 479.714C151.978 479.612 152.331 479.507 152.684 479.396C158.127 477.638 162.95 474.346 166.576 469.911C166.33 469.649 166.083 469.387 165.832 469.127C162.315 473.469 157.613 476.688 152.3 478.388Z" fill="white" />
        <path d="M156.984 492.622C156.631 492.75 156.274 492.867 155.917 492.98C155.533 493.099 155.149 493.213 154.761 493.316C148.718 494.923 142.319 494.522 136.523 492.175C130.726 489.827 125.843 485.66 122.604 480.295C122.483 480.777 122.361 481.254 122.25 481.733C125.699 487.027 130.724 491.096 136.609 493.361C142.495 495.626 148.943 495.974 155.036 494.353C155.424 494.249 155.81 494.141 156.187 494.018C156.551 493.91 156.907 493.792 157.262 493.67C164.417 491.144 170.348 485.986 173.854 479.238C173.644 478.883 173.42 478.538 173.194 478.188C169.867 484.931 164.053 490.108 156.984 492.622Z" fill="white" />
        <path d="M546.962 494.012L547.236 495.044C550.359 507.058 550.826 516.957 548.618 524.606C548.587 524.718 548.551 524.826 548.521 524.938L548.006 524.786L547.512 524.633C550.014 516.343 548.897 505.867 546.177 495.388C546.092 495.049 546.006 494.705 545.91 494.364C544.72 489.939 543.261 485.529 541.672 481.292C541.552 480.963 541.426 480.63 541.299 480.297C539.174 474.714 536.852 469.458 534.701 464.927C534.551 464.607 534.395 464.287 534.241 463.973C530.568 456.319 527.455 450.897 526.755 449.699C526.672 449.552 526.624 449.471 526.613 449.452L527.06 449.185L527.064 449.179L527.517 448.91C527.527 448.929 527.691 449.197 527.977 449.701C529.059 451.579 531.933 456.693 535.246 463.567C535.394 463.878 535.549 464.198 535.7 464.518C537.449 468.193 539.304 472.327 541.069 476.715C541.514 477.816 541.939 478.906 542.346 479.985C542.477 480.316 542.604 480.649 542.724 480.978C544.441 485.559 545.854 489.904 546.962 494.012Z" fill="white" />
        <path d="M535.245 463.567C534.916 463.705 534.582 463.843 534.24 463.973C533.312 464.326 532.367 464.632 531.407 464.888C527.32 465.986 523.04 466.149 518.881 465.367C518.739 465.694 518.597 466.022 518.451 466.357C522.837 467.221 527.361 467.068 531.678 465.91C532.703 465.636 533.711 465.308 534.701 464.928C535.038 464.799 535.372 464.661 535.7 464.519C538.409 463.361 540.927 461.797 543.168 459.881C542.875 459.658 542.587 459.44 542.307 459.225C540.179 461.013 537.8 462.477 535.245 463.567Z" fill="white" />
        <path d="M542.345 479.986C541.999 480.1 541.651 480.204 541.299 480.298C541.194 480.332 541.081 480.362 540.974 480.39C536.544 481.573 531.895 481.665 527.422 480.655C522.949 479.646 518.785 477.567 515.287 474.595C515.163 474.951 515.039 475.307 514.922 475.666C518.539 478.653 522.814 480.732 527.392 481.731C531.969 482.73 536.718 482.621 541.246 481.412C541.388 481.374 541.531 481.336 541.672 481.293C542.027 481.192 542.376 481.088 542.723 480.979C548.087 479.247 552.84 476.002 556.414 471.632C556.171 471.374 555.928 471.116 555.68 470.859C552.215 475.138 547.581 478.31 542.345 479.986Z" fill="white" />
        <path d="M546.964 494.012C546.616 494.138 546.265 494.254 545.912 494.364C545.534 494.482 545.155 494.594 544.773 494.696C538.818 496.279 532.512 495.884 526.8 493.571C521.087 491.258 516.275 487.151 513.084 481.864C512.964 482.339 512.844 482.809 512.734 483.281C516.134 488.498 521.085 492.508 526.885 494.74C532.685 496.973 539.039 497.315 545.044 495.718C545.426 495.616 545.807 495.509 546.178 495.388C546.537 495.281 546.888 495.165 547.238 495.044C554.289 492.556 560.134 487.472 563.589 480.822C563.382 480.473 563.161 480.133 562.938 479.787C559.66 486.432 553.93 491.535 546.964 494.012Z" fill="white" />
        <path d="M356.182 91.0408C356.182 53.8868 330.364 23.7676 298.515 23.7676C266.666 23.7676 240.848 53.8868 240.848 91.0408C240.848 124.738 262.086 152.647 289.798 157.549V163.889C289.798 165.087 290.272 166.235 291.116 167.082C291.96 167.929 293.105 168.405 294.299 168.405H302.731C303.925 168.405 305.07 167.929 305.914 167.082C306.758 166.235 307.232 165.087 307.232 163.889V157.549C334.944 152.647 356.182 124.738 356.182 91.0408Z" fill="#00441F" />
        <path d="M288.411 162.973C290.215 161.273 292.365 159.984 294.712 159.197C297.06 158.409 299.549 158.14 302.01 158.409C304.49 158.697 306.869 159.559 308.96 160.928C311.052 162.296 312.798 164.132 314.061 166.293C315.324 168.389 316.118 170.736 316.386 173.171C316.655 175.606 316.393 178.07 315.618 180.393C314.102 185.026 310.88 188.881 307.577 192.356C304.111 196.001 300.342 199.363 297.085 203.205C291.712 209.578 287.961 217.163 286.152 225.31C284.343 233.456 284.531 241.922 286.7 249.979C289.558 260.193 295.461 269.285 303.615 276.038C304.65 276.887 305.717 277.695 306.814 278.464C308.084 279.355 309.286 277.266 308.027 276.382C299.174 270.099 292.631 261.063 289.41 250.674C286.968 242.578 286.656 233.986 288.503 225.733C290.351 217.48 294.295 209.847 299.953 203.576C303.39 199.769 307.238 196.362 310.673 192.554C313.964 188.906 316.965 184.79 318.25 179.971C318.901 177.521 319.062 174.966 318.725 172.453C318.387 169.94 317.558 167.519 316.284 165.328C315.016 163.089 313.3 161.137 311.245 159.595C309.19 158.052 306.839 156.952 304.341 156.363C299.314 155.267 294.059 156.179 289.692 158.907C288.622 159.592 287.624 160.383 286.712 161.268C285.596 162.346 287.296 164.05 288.411 162.973Z" fill="#3F3D56" />
        <path d="M133.962 62.868C116.317 22.1732 73.734 1.52493 38.8499 16.7489C3.96586 31.9729 -10.0092 77.304 7.63564 117.999C23.6387 154.907 60.1552 175.325 92.8365 167.447L95.8473 174.391C96.4161 175.703 97.4809 176.734 98.8077 177.259C100.134 177.783 101.614 177.757 102.922 177.186L112.158 173.155C113.465 172.585 114.493 171.516 115.016 170.185C115.538 168.854 115.512 167.369 114.943 166.058L111.932 159.114C139.958 140.498 149.965 99.7765 133.962 62.868Z" fill="#A5DC86" />
        <path d="M93.717 173.844C94.8635 171.195 96.552 168.816 98.6732 166.863C100.794 164.91 103.301 163.425 106.03 162.506C108.711 161.6 111.556 161.293 114.367 161.607C117.178 161.92 119.886 162.846 122.303 164.32C124.793 165.799 126.94 167.795 128.6 170.172C130.261 172.55 131.397 175.256 131.933 178.109C133.165 184.721 131.166 191.456 128.896 197.612C126.515 204.065 123.549 210.332 121.894 217.033C119.591 226.452 119.544 236.284 121.758 245.724C123.972 255.164 128.384 263.944 134.632 271.343C142.5 280.492 152.864 287.132 164.446 290.446C165.867 290.848 167.303 291.191 168.749 291.485C170.259 291.791 170.905 289.468 169.388 289.16C157.337 286.671 146.355 280.487 137.96 271.463C129.061 261.81 123.685 249.417 122.709 236.305C122.187 229.662 122.794 222.978 124.504 216.539C126.33 209.744 129.42 203.383 131.75 196.762C133.973 190.445 135.549 183.661 134.152 176.976C132.868 171.09 129.308 165.955 124.253 162.7C121.831 161.098 119.115 159.994 116.264 159.454C113.413 158.914 110.483 158.947 107.645 159.553C101.849 160.87 96.7705 164.353 93.4436 169.294C92.6343 170.525 91.9497 171.835 91.4 173.204C90.8093 174.636 93.1331 175.26 93.717 173.844Z" fill="#3F3D56" />
        <path d="M626.852 554.405L639.804 554.404L645.965 504.285L626.85 504.286L626.852 554.405Z" fill="#FFB6B6" />
        <path d="M623.549 550.163L649.055 550.162H649.056C653.367 550.162 657.501 551.88 660.55 554.939C663.598 557.997 665.311 562.144 665.311 566.469V566.999L623.55 567.001L623.549 550.163Z" fill="#2F2E41" />
        <path d="M550.193 543.356L562.542 547.274L583.484 501.354L565.259 495.57L550.193 543.356Z" fill="#FFB6B6" />
        <path d="M548.319 538.311L572.638 546.028L572.639 546.028C576.749 547.333 580.174 550.222 582.161 554.06C584.148 557.898 584.534 562.371 583.234 566.495L583.075 567L543.258 554.364L548.319 538.311Z" fill="#2F2E41" />
        <path d="M579.355 325.506L572.168 345.842L571.964 455.631L551.582 530.8L576.602 537.805L604.239 466.928L613.104 404.673L624.786 467.29L623.178 541.714H649.429L651.41 427.025C651.967 394.816 646.349 362.799 634.863 332.716L579.355 325.506Z" fill="#00441F" />
        <path d="M570.796 243.127H613.227L630.956 260.914L636.253 334.438L572.168 344.785L551.582 274.632L562.793 244.818L570.796 243.127Z" fill="#A5DC86" />
        <path d="M555.537 275.333L490.208 250.673L488.508 240.48L529.708 205.504L543.421 215.823L518.566 243.127L562.794 244.817L555.537 275.333Z" fill="#A5DC86" />
        <path d="M537.631 219.811C543.465 219.811 548.195 215.065 548.195 209.212C548.195 203.358 543.465 198.613 537.631 198.613C531.796 198.613 527.066 203.358 527.066 209.212C527.066 215.065 531.796 219.811 537.631 219.811Z" fill="#FFB6B6" />
        <path d="M562.132 222.565L566.906 200.969C567.743 197.165 569.319 193.565 571.544 190.373C573.768 187.181 576.598 184.46 579.872 182.366C583.145 180.271 586.798 178.845 590.621 178.168C594.445 177.491 598.363 177.577 602.154 178.42C605.944 179.263 609.531 180.848 612.711 183.083C615.89 185.318 618.6 188.16 620.684 191.446C622.768 194.732 624.186 198.398 624.858 202.234C625.529 206.071 625.44 210.002 624.595 213.804L619.822 235.4C619.593 236.43 618.966 237.327 618.078 237.895C617.191 238.462 616.115 238.653 615.088 238.425L565.148 227.314C564.121 227.084 563.226 226.455 562.661 225.565C562.096 224.675 561.906 223.596 562.132 222.565Z" fill="#2F2E41" />
        <path d="M595.019 230.705C606.51 227.364 613.126 215.311 609.797 203.783C606.467 192.255 594.453 185.617 582.962 188.958C571.471 192.298 564.855 204.351 568.185 215.879C571.514 227.408 583.529 234.045 595.019 230.705Z" fill="#FFB6B6" />
        <path d="M559.699 201.177C561.047 195.109 564.74 189.826 569.966 186.486C575.192 183.146 581.526 182.023 587.577 183.362L591.882 184.32C597.93 185.672 603.196 189.377 606.525 194.62C609.854 199.863 610.974 206.217 609.639 212.288L609.543 212.72L600.445 210.696L599.269 201.287L596.722 209.867L592.019 208.821L591.426 204.074L590.141 208.403L559.604 201.609L559.699 201.177Z" fill="#2F2E41" />
        <path d="M581.571 233.516C581.272 232.841 581.169 232.095 581.275 231.363C581.38 230.632 581.689 229.945 582.166 229.383C588.653 221.692 598.596 207.027 592.311 195.22L591.859 194.371L616.474 199.848L608.278 236.923L585.043 235.907C584.807 235.896 584.573 235.866 584.343 235.815C583.733 235.679 583.163 235.4 582.681 235C582.199 234.601 581.819 234.092 581.571 233.516Z" fill="#2F2E41" />
        <path d="M611.54 243.572L680.644 253.43L684.509 263.012L651.85 306.119L636.232 299.03L654.593 266.968L611.053 274.942L611.54 243.572Z" fill="#A5DC86" />
        <path d="M715.001 103.815C715.001 59.4367 684.162 23.4609 646.121 23.4609C608.079 23.4609 577.24 59.4367 577.24 103.815C577.24 144.064 602.608 177.401 635.708 183.256V190.828C635.708 192.259 636.275 193.631 637.283 194.642C638.291 195.654 639.659 196.222 641.085 196.222H651.157C652.582 196.222 653.95 195.654 654.958 194.642C655.966 193.631 656.533 192.259 656.533 190.828V183.256C689.633 177.401 715.001 144.064 715.001 103.815Z" fill="#00441F" />
        <path d="M633.887 189.568C636.073 187.505 638.681 185.944 641.528 184.993C644.376 184.041 647.395 183.722 650.378 184.057C653.308 184.403 656.121 185.414 658.604 187.013C661.088 188.611 663.175 190.756 664.71 193.284C666.272 195.779 667.276 198.584 667.655 201.506C668.033 204.427 667.775 207.396 666.9 210.209C665.201 215.73 661.471 220.352 657.583 224.495C653.435 228.915 648.891 232.956 644.925 237.549C638.46 245.086 633.917 254.086 631.686 263.775C629.455 273.463 629.603 283.55 632.116 293.169C635.479 305.531 642.585 316.541 652.452 324.679C653.651 325.657 654.884 326.591 656.152 327.479C657.422 328.369 658.624 326.28 657.364 325.397C646.797 317.904 638.971 307.141 635.085 294.758C632.133 285.093 631.715 274.828 633.87 264.953C636.025 255.078 640.681 245.927 647.389 238.383C651.465 233.818 656.056 229.751 660.179 225.23C664.128 220.9 667.752 216.039 669.374 210.318C670.191 207.428 670.428 204.405 670.072 201.422C669.717 198.44 668.775 195.558 667.302 192.943C665.835 190.269 663.833 187.928 661.422 186.067C659.011 184.206 656.243 182.864 653.291 182.127C647.291 180.743 640.99 181.792 635.757 185.044C634.474 185.858 633.278 186.803 632.188 187.863C631.073 188.943 632.774 190.646 633.887 189.568Z" fill="#3F3D56" />
        <path d="M642.217 318.379C648.051 318.379 652.781 313.634 652.781 307.78C652.781 301.927 648.051 297.182 642.217 297.182C636.382 297.182 631.652 301.927 631.652 307.78C631.652 313.634 636.382 318.379 642.217 318.379Z" fill="#FFB6B6" />
        <path d="M532.131 212.188C531.577 213.067 530.234 212.939 529.48 212.39C528.433 211.627 528.772 210.158 529.493 209.289C531.784 206.53 535.573 209.443 536.085 212.184C536.275 213.52 536.07 214.883 535.495 216.103C534.92 217.323 534.001 218.347 532.851 219.048C530.237 220.603 526.842 219.823 524.594 217.963C519.906 214.083 521.182 206.314 526.39 203.507C529.713 201.716 533.915 202.502 537.189 204.003C540.874 205.692 544.043 208.281 547.452 210.439C554.93 215.136 563.389 218.034 572.168 218.907C573.705 219.064 573.694 216.652 572.168 216.496C567.303 215.992 562.527 214.838 557.966 213.065C553.454 211.23 549.171 208.872 545.204 206.038C541.192 203.279 536.999 200.598 532.033 200.142C530.029 199.907 527.999 200.209 526.149 201.016C524.299 201.824 522.695 203.109 521.5 204.74C520.437 206.2 519.726 207.886 519.421 209.668C519.117 211.45 519.228 213.278 519.746 215.009C520.37 216.82 521.461 218.432 522.908 219.682C524.355 220.932 526.106 221.774 527.984 222.125C529.791 222.467 531.66 222.235 533.329 221.462C534.999 220.688 536.386 219.41 537.297 217.808C539.125 214.636 539.066 210.376 536.305 207.746C535.513 206.939 534.523 206.357 533.434 206.06C532.345 205.763 531.197 205.761 530.107 206.055C529.103 206.395 528.22 207.022 527.565 207.858C526.91 208.694 526.512 209.703 526.42 210.763C526.154 214.88 531.979 216.938 534.206 213.405C535.034 212.09 532.956 210.879 532.131 212.188Z" fill="#3F3D56" />
        <path d="M51.4171 341.738L48.2438 345.733L37.0195 339.071L40.1928 335.076L51.4171 341.738Z" fill="#E0E5CF" />
        <path d="M63.1292 358.902L65.9325 363.099L54.1412 368.984L51.3379 364.787L63.1292 358.902Z" fill="#E0E5CF" />
        <path d="M75.3348 340.656L80.9365 340.855L80.3791 352.614L74.7773 352.415L75.3348 340.656Z" fill="#E0E5CF" />
        <path d="M183.571 23.5372L180.398 27.5316L169.174 20.8702L172.347 16.875L183.571 23.5372Z" fill="#E0E5CF" />
        <path d="M195.284 40.6992L198.087 44.8961L186.295 50.7804L183.492 46.5835L195.284 40.6992Z" fill="#E0E5CF" />
        <path d="M207.489 22.4551L213.091 22.6536L212.533 34.4126L206.932 34.2141L207.489 22.4551Z" fill="#E0E5CF" />
        <path d="M495.134 65.3204L491.961 69.3148L480.736 62.6534L483.91 58.6582L495.134 65.3204Z" fill="#E0E5CF" />
        <path d="M506.846 82.4844L509.649 86.6813L497.858 92.5656L495.055 88.3687L506.846 82.4844Z" fill="#E0E5CF" />
        <path d="M519.052 64.2383L524.653 64.4368L524.096 76.1958L518.494 75.9973L519.052 64.2383Z" fill="#E0E5CF" />
        <path d="M399.823 6.66216L396.65 10.6566L385.426 3.99521L388.599 0L399.823 6.66216Z" fill="#E0E5CF" />
        <path d="M411.535 23.8262L414.339 28.0231L402.547 33.9074L399.744 29.7105L411.535 23.8262Z" fill="#E0E5CF" />
        <path d="M423.741 5.58008L429.343 5.77855L428.785 17.5376L423.184 17.3391L423.741 5.58008Z" fill="#E0E5CF" />
        <path d="M496.724 306.953L493.551 311.59L504.775 319.322L507.949 314.685L496.724 306.953Z" fill="#E0E5CF" />
        <path d="M507.869 341.437L510.672 346.309L522.464 339.478L519.66 334.607L507.869 341.437Z" fill="#E0E5CF" />
        <path d="M531.31 327.078L536.912 327.309L537.47 313.66L531.868 313.429L531.31 327.078Z" fill="#E0E5CF" />
        <path d="M258.621 517.376L268.325 515.871L261.672 438.088L240.047 442.106L258.621 517.376Z" fill="#FFB6B6" />
        <path d="M255.654 514.583L274.765 511.618C277.995 511.117 281.292 511.924 283.929 513.861C286.566 515.798 288.328 518.707 288.827 521.947L288.889 522.344L257.599 527.198L255.654 514.583Z" fill="#2F2E41" />
        <path d="M156.633 516.413L166.443 516.832L178.375 447.73L156.75 447.731L156.633 516.413Z" fill="#FFB6B6" />
        <path d="M154.266 513.092L173.586 513.917H173.587C176.852 514.057 179.929 515.493 182.139 517.908C184.35 520.323 185.514 523.52 185.375 526.796L185.357 527.198L153.725 525.846L154.266 513.092Z" fill="#2F2E41" />
        <path d="M175.947 291.275C175.484 291.145 175.01 291.056 174.531 291.011L180.647 240.789L201.398 196.7C202.704 193.927 202.912 190.76 201.982 187.838C201.052 184.916 199.051 182.457 196.385 180.957C193.719 179.457 190.584 179.027 187.614 179.753C184.644 180.48 182.059 182.31 180.381 184.874C174.942 193.182 170.828 202.29 168.187 211.87L159.469 243.494L165.844 297.728C165.599 299.256 165.8 300.823 166.423 302.24C167.045 303.656 168.062 304.863 169.352 305.713C170.641 306.563 172.148 307.022 173.691 307.033C175.234 307.044 176.748 306.608 178.049 305.777C179.351 304.945 180.386 303.754 181.029 302.347C181.672 300.94 181.895 299.376 181.673 297.844C181.451 296.312 180.792 294.877 179.776 293.711C178.761 292.546 177.431 291.7 175.947 291.275Z" fill="#FFB6B6" />
        <path d="M188.546 245.041L162.328 214.951L175.316 184.302C177.017 180.289 180.214 177.101 184.224 175.42C188.235 173.738 192.742 173.696 196.784 175.301C200.825 176.907 204.081 180.034 205.856 184.015C207.632 187.995 207.786 192.515 206.287 196.608L188.546 245.041Z" fill="#00441F" />
        <path d="M232.129 149.116C238.436 137.539 234.196 123.025 222.657 116.697C211.118 110.369 196.651 114.623 190.343 126.199C184.035 137.776 188.276 152.29 199.815 158.618C211.353 164.947 225.821 160.692 232.129 149.116Z" fill="#FFB6B6" />
        <path d="M215.217 108.637H199.836C189.752 108.637 181.578 117.863 181.578 129.244L182.29 156.849C193.185 158.209 203.842 161.069 213.96 165.347L215.395 159.197L218.227 167.297C220.746 168.521 223.243 169.86 225.719 171.313C222.829 157.692 221.64 144.103 223.624 133.547H228.833V127.827L230.602 133.547H240.046C240.046 130.275 239.404 127.036 238.156 124.014C236.909 120.992 235.08 118.246 232.774 115.933C230.469 113.619 227.731 111.785 224.719 110.533C221.707 109.281 218.478 108.637 215.217 108.637Z" fill="#2F2E41" />
        <path d="M265.37 442.313L263.142 442.852L261.272 407.954L247.656 303.494L232.038 263.719L239.647 235.997C239.647 235.997 248.457 216.712 226.431 201.846L208.811 172.115C196.639 164.131 186.625 169.352 181.286 182.923C176.635 194.748 173.024 209.708 175.171 225.149C179.982 259.738 183.982 263.719 183.982 263.719C183.982 263.719 153.947 297.066 160.354 339.653L156.783 449.919L153.516 449.784L153.158 458.543L181.7 459.717L182.058 450.958L179.691 450.861L189.989 407.151L205.206 349.296L231.637 420.008L240.527 448.316L237.599 449.023L239.644 457.545L267.416 450.835L265.37 442.313Z" fill="#00441F" />
        <path d="M284.983 244.31C284.66 244.667 284.369 245.053 284.114 245.462L242.063 217.533L212.019 179.209C210.13 176.798 207.399 175.196 204.378 174.725C201.357 174.255 198.27 174.951 195.741 176.674C193.212 178.397 191.428 181.018 190.749 184.009C190.07 186.999 190.547 190.137 192.083 192.789C197.061 201.382 203.345 209.144 210.708 215.795L235.013 237.75L286.222 256.26C287.476 257.163 288.963 257.682 290.505 257.757C292.046 257.831 293.576 257.457 294.91 256.68C296.245 255.903 297.327 254.755 298.027 253.375C298.726 251.995 299.013 250.442 298.854 248.902C298.694 247.362 298.094 245.901 297.126 244.695C296.159 243.489 294.864 242.59 293.399 242.105C291.933 241.619 290.359 241.57 288.866 241.961C287.373 242.352 286.025 243.168 284.983 244.31Z" fill="#FFB6B6" />
        <path d="M249.384 212.343L210.837 222.43L189.311 197.078C186.492 193.759 185.077 189.465 185.37 185.114C185.662 180.763 187.638 176.698 190.876 173.789C194.114 170.879 198.357 169.353 202.7 169.539C207.043 169.724 211.141 171.604 214.122 174.779L249.384 212.343Z" fill="#00441F" />
      </g>
      <defs>
        <clipPath id="clip0_5654_26147">
          <rect width="715" height="567" fill="white" />
        </clipPath>
      </defs>
    </svg>

  )
}

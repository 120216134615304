import { Repository } from 'ecp/repositories/Repository'
import api from 'services/api'
import { AxiosRequestConfig } from 'axios'

export interface IGRecaptcha {
  token: string
  identifier: string
}

export interface IGRecaptchaResponse {
  success: boolean
  'error-codes': Array<string>
}

class GRecaptchaRepository extends Repository<{}> {
  async verifyToken (credentials: IGRecaptcha, config?: AxiosRequestConfig) {
    return Repository.handle<{ recaptchaResponse: IGRecaptchaResponse }>(() =>
      this.api.post(`${this.path}/verify`, credentials, config)
    )
  }
}

export default new GRecaptchaRepository({ api, path: '/recaptcha' })

import React, { Fragment, MutableRefObject, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { Button, Modal, Form } from 'antd'
import { useAuth, useCorban } from 'hooks'
import { isProduction, sendWhatsapp } from 'utils/globals'
import swal from 'utils/swal'
import translate from 'utils/translate'
import api from 'services/api'
import { IModalInvite } from './types'
import ModalShare from 'components/ModalShare/ModalShare'
import ModalLink from './ModalLink'
import InvitePromoter from './components/InvitePromoter'
import InviteProvider from './components/InviteProvider'
import InviteBackoffice from './components/InviteBackoffice'
import InviteAuditor from './components/InviteAuditor'
import EmailRepository from 'egi/repositories/EmailRepository'
import { swalError } from 'components/SwalError/SwalError'
import InviteTeamAsMaster from './components/InviteTeamAsMaster'
import { _productSlugs } from 'ecp/models/ProductModel'

function ModalInvite ({ level, visible, onClose, area, canChooseType, invitedLevel, type, specificType }: IModalInvite) {
  const host = document.location.host
  const protocol = window.location.protocol
  const corban = useCorban()
  const user = useAuth()
  let hostLink = ''

  const translateLevel = useMemo(() => translate.level(level), [level])
  const [showInviteLink, setShowInviteLink] = useState<boolean>(false)
  const [invite, setInvite] = useState<string>('')
  const [inviteId, setInviteId] = useState<string>('')
  const [namePromoter, setNamePromoter] = useState<string>('')
  const [loading, setLoading] = useState({
    list: false,
    invite: false,
    modalInvite: false,
    email: false,
    generateLink: false
  })

  const [inviteRef] = Form.useForm()
  const generateLinkFormRef: MutableRefObject<any> = useRef(null)

  const auth = useAuth()

  const generateInvite = async () => {
    let baseUrl: string = level

    type IQuery = { level: string, areaName?: string, type?: string, products?: _productSlugs[] }
    const query: IQuery = { level }

    switch (level) {
      case 'master': {
        query.products = ['home-equity']
        break
      }
      case 'backoffice':
      case 'administrator':
        if (area) {
          query.areaName = area
          query.products = [auth.selectedProduct.slug as _productSlugs]
          baseUrl = level
        }
        break
      case 'commercial':
      case 'consultant':
        baseUrl = level
        break
      case 'promoter':
        if (user.areaName === 'telemarketing') baseUrl = user.areaName
        if (user.areaName === 'comercial') baseUrl = 'telemarketing'
        if (type) query.type = type
        break
      default:
        break
    }

    if (canChooseType) {
      setLoading({
        ...loading,
        generateLink: true
      })
    }

    try {
      const response = await api.post(`/invites/${baseUrl}`, query)
      const link: string = response.data.data.link

      setInviteId(response.data.data.inviteId)

      let customHost: string = ''
      if (level === 'client' && corban.clientUrl) customHost = corban.clientUrl
      customHost = host

      if (isProduction) hostLink = `https://${customHost}/signup${link}`
      else hostLink = `${protocol}//${customHost}/signup${link}`

      if (canChooseType) setShowInviteLink(true)
      setInvite(hostLink)
    } catch (err) {
      if (err.response && err.response.data.data?.invalid) {
        setLoading({
          ...loading,
          generateLink: false
        })
        return
      }

      setInvite(err.message)
    }

    if (canChooseType) {
      setLoading({
        ...loading,
        generateLink: false
      })
    }
  }

  function createInviteLink (token: string, inviteId?: string) {
    setInviteId(inviteId || '')

    if (isProduction) hostLink = `https://${host}/signup${token}`
    else hostLink = `${protocol}//${host}/signup${token}`

    setInvite(hostLink)
    if (canChooseType) setShowInviteLink(true)
  }

  const handleInvite = async (type: 'invite' | 'modalInvite') => {
    setLoading({ ...loading, [type]: true })
    await generateInvite()
    setLoading({ ...loading, [type]: false })
  }

  const inviteEmail = async () => {
    const { email } = inviteRef.getFieldsValue()

    setLoading({ ...loading, email: true })
    try {
      const response = await EmailRepository.invite({ email, link: invite, level, inviteId })
      swal.basic({ title: 'Sucesso!', text: response.data?.message, icon: 'success' })
    } catch (err) {
      swal.basic({ title: 'Atenção!', text: err.message, icon: 'warning' })
    } finally {
      setLoading({ ...loading, email: false })
    }
  }

  function resetAllForms () {
    if (generateLinkFormRef && generateLinkFormRef?.current) generateLinkFormRef?.current.resetFields()
    if (inviteRef) inviteRef.resetFields()
  }

  useLayoutEffect(() => {
    if (visible && !canChooseType) handleInvite('invite')
  }, [visible])

  const cancelModal = () => {
    resetAllForms()
    if (canChooseType) setShowInviteLink(false)
    onClose()
  }

  const handleOnSuccessToken = (token: string) => {
    if (isProduction) hostLink = `https://${host}/signup${token}`
    else hostLink = `${protocol}//${host}/signup${token}`
    setInvite(hostLink)
    setShowInviteLink(true)
  }

  return (
    <Fragment>
      <Modal
        title={`Convite ${invitedLevel}`}
        visible={visible}
        onCancel={cancelModal}
        destroyOnClose={true}
        footer={[
          <Button
            type="ghost"
            key="cancel"
            onClick={cancelModal}
          >
            Cancelar
          </Button>
        ]}
      >
        {(!showInviteLink && canChooseType) ? (
          <Fragment>
            {(level === 'promoter') && (
              <InvitePromoter
                level={level}
                invitedLevel={invitedLevel}
                specificType={specificType}
                onFailed={(message: string) => setInvite(message)}
                onSuccess={(id, token, name) => {
                  const inviteName = name ? '*' + name + '*' + '.' : ''
                  setNamePromoter(inviteName)
                  createInviteLink(id, token)
                }}
              />
            )}

            {level === 'provider' && (
              <InviteProvider
                area={area}
                level={level}
                onFailed={(message: string) => setInvite(message)}
                onSuccess={createInviteLink}
              />
            )}

            {level === 'backoffice' && (
              <InviteBackoffice
                level={level}
                area={area}
                onSuccess={handleOnSuccessToken}
              />
            )}

            {level === 'master' && (
              <InviteTeamAsMaster level={'backoffice'} onSuccess={handleOnSuccessToken}/>
            )}

            {level === 'auditor' && (
              <InviteAuditor
                level={level}
                onFailed={(message: string) => {
                  swalError({ err: { message } })
                  setInvite(message)
                }}
                onSuccess={(id, token) => {
                  createInviteLink(id, token)
                }}
              />
            )}
          </Fragment>
        ) : (
          <Fragment>
            <ModalLink
              handleInvite={() => handleInvite('modalInvite')}
              generateLinkFormRef={generateLinkFormRef}
              loading={loading}
              invite={invite}
              canChooseType={canChooseType}
              texts={{
                goInviteText: 'Ir para o cadastro',
                generateAnotherLink: 'Clique para gerar um novo link de convite',
                inviteLinkText: 'Link de convite:'
              }}
            />

            <ModalShare
              form={inviteRef}
              invite={invite}
              loading={loading}
              sendWhatsapp={() => {
                const whatsappMessage = `Olá%2C%20${namePromoter}%20Você%20foi%20convidado%20por%20*${user.name}*%20para%20se%20tornar%20um%20usuário%20*${translateLevel}*%20na%20plataforma%20UXLine%2C%20clique%20no%20link%20abaixo%20para%20completar%20seu%20cadastro%3A%20${invite}`
                sendWhatsapp(inviteRef.getFieldsValue().cellphone, whatsappMessage)
              }}
              inviteEmail={inviteEmail}
            />
          </Fragment>
        )}
      </Modal>
    </Fragment>
  )
}

export default ModalInvite

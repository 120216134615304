import { IInitialTables } from './tables/types'
import proposal from './proposal/reducer'
import system from './system/reducer'
import client from './client/reducer'
import resources from './resources/reducer'
import drawer from './drawer/reducer'
import step from './step/reducer'
import simulation from './simulation/reducer'
import pmt from './pmt/reducer'
import tables from './tables/reducer'
import modal from './modal/reducer'
import chat from './chat/reducer'
import proposalFlow from './proposalFlow/reducer'
import { ProposalState } from './proposal/types'
import { Reducer } from 'react'
import { combineReducers } from 'redux'
import { connectRouter, LocationChangeAction, RouterState } from 'connected-react-router'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { SystemState } from './system/types'
import { IInitialResources } from './resources/types'
import { IChat } from './chat/types'
import { IInitialSimulation } from './simulation/types'
import { IAuth, IClient, IDrawer, IPMT, IStep } from 'egi/types'
import { IReduxModal } from './modal/types'
import progressQueueReducer, { IProgressQueueReducer } from 'store/modules/progressQueue/progressQueueReducer'
import loadingReducer, { INotaryFeesReducer } from 'store/modules/notaryFees/notaryFeesReducer'

import dynamicProposalReducer, { IDynamicProposalStore } from 'store/modules/dynamicProposal/dynamicProposalReducer'
import layoutReducer, { ILayoutReducer } from 'store/modules/layout/layoutReducer'
import auth from './auth/authReducer'
import { IProduct } from 'ecp/models/ProductModel'
import dynamicProposalPendingReducer, { IDynamicProposalPendingState } from './dynamicProposalPending/dynamicProposalReducer'
import { IReduxProposalFlow } from './proposalFlow/actions'

const authPersistConfig = {
  key: '@ux-line/auth',
  storage: storage
}

const layoutPersistConfig = {
  key: '@ux-line/layout',
  storage: storage
}

const progressQueuePersistConfig = {
  key: '@ux-line/progressQueue',
  storage: storage
}

const createRootReducer = (history: any) => combineReducers({
  router: connectRouter(history),
  auth: persistReducer(authPersistConfig, auth),
  layout: persistReducer(layoutPersistConfig, layoutReducer),
  progressQueue: persistReducer(progressQueuePersistConfig, progressQueueReducer),
  proposal,
  dynamicProposal: dynamicProposalReducer,
  dynamicProposalPending: dynamicProposalPendingReducer,
  system,
  client,
  resources,
  drawer,
  step,
  simulation,
  pmt,
  tables,
  modal,
  chat,
  loadingReducer,
  proposalFlow
})

export interface RootState {
  router: Reducer<RouterState<any>, LocationChangeAction<any>>
  auth: IAuth
  proposal: ProposalState
  dynamicProposal: IDynamicProposalStore
  dynamicProposalPending: IDynamicProposalPendingState
  layout: ILayoutReducer
  system: SystemState
  client: IClient
  resources: IInitialResources
  drawer: IDrawer
  step: IStep
  simulation: IInitialSimulation
  pmt: IPMT
  tables: IInitialTables
  modal: IReduxModal
  chat: IChat
  progressQueue:IProgressQueueReducer
  loadingReducer: INotaryFeesReducer
  products: IProduct[]
  proposalFlow: IReduxProposalFlow | null
}

export default createRootReducer

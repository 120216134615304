import { ClockCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { useEffect, useState } from 'react'

interface IProps {
  onResend(): Promise<void>
}

function TokenResend ({ onResend }: IProps) {
  const [hasSent, setHasSent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [time, setTime] = useState<string>('')
  const [secondsTime, setSecondsTime] = useState<number | undefined>(0)

  useEffect(() => {
    if (secondsTime) {
      let time = secondsTime || 0
      const interval = setInterval(() => {
        time--
        if (time < 1) {
          setHasSent(false)
          clearInterval(interval)
        }
        setTime(time.toString())
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [secondsTime])

  if (hasSent) {
    return (
      <Button
        type='link'
        disabled={true}
      >
        <ClockCircleOutlined /> Reenviar token em { time }s
      </Button>
    )
  }

  async function onClick () {
    setLoading(true)
    try {
      await onResend()
      setHasSent(true)
      setSecondsTime(undefined)
      setSecondsTime(15)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button
      loading={loading}
      type='link'
      onClick={onClick}
    >
      Reenviar token
    </Button>
  )
}

export default TokenResend

import dayjs, { Dayjs } from 'dayjs'

export function formatDates (filters: IDownloadFilters) {
  const dateKeys: (keyof typeof filters)[] = [
    'startDate',
    'endDate'
  ]

  return dateKeys.reduce((acc, key) => {
    if (filters[key]) {
      acc[key] = dayjs(filters[key] as Dayjs).format('YYYY-MM-DD')
    }
    return acc
  }, {} as { [key: string]: string })
}

interface IDownloadFilters {
  startDate: string | Dayjs
  endDate: string | Dayjs
  status: string[]
}

export function formatStatusForRequest (values: string[]): string {
  if (!values) return ''
  return values.toString()
}

export function formatDownloadFilters (filters: IDownloadFilters) {
  const formattedFilters = {
    ...filters,
    ...formatDates(filters),
    status: formatStatusForRequest(filters.status)
  }

  return formattedFilters
}

import React, { useState, useLayoutEffect, Fragment } from 'react'
import { Form, Col, Row, InputNumber } from 'antd'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'
import { useAuth, useSelectedProduct } from 'hooks'
import { LoadingOrEmpty } from 'egi/app/Settings/views/PopulationSettings/Population'
import ConfigurationsRepository from 'egi/repositories/ConfigurationsRepository'
import { ISLAList } from './slaInterfaces'
import SLAModel from 'egi/models/SLAModel'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'
import SettingsCard from 'egi/app/Settings/components/SettignsCard/SettingsCard'

function SLAConfig () {
  const [errors, setErrors] = useState<any>({})
  const [failRequest, setFailRequest] = useState<boolean>(false)
  const [loading, setLoading] = useState({
    list: true,
    button: false
  })

  const { _id: productId } = useSelectedProduct()
  const [formRef] = Form.useForm()
  const user = useAuth()

  useLayoutEffect(() => {
    const getSLAValues = async () => {
      setLoading({
        ...loading,
        list: true
      })

      try {
        const response = await ConfigurationsRepository.getSLAValues(productId)
        const areas = response.data.data?.configurations

        setLoading({
          ...loading,
          list: false
        })

        if (formRef) formRef.setFieldsValue(areas)
      } catch (err) {
        setFailRequest(true)
      } finally {
        setLoading({
          ...loading,
          list: false
        })
      }
    }

    if (productId) getSLAValues()
  }, [productId])

  const onSubmit = async (values: ISLAList) => {
    setLoading({
      ...loading,
      button: true
    })

    try {
      const response = await ConfigurationsRepository.updateSLAValues(productId, values)
      swal.basic({ icon: 'success', title: 'Sucesso!', text: response.data.message })
      setLoading({
        ...loading,
        button: false
      })
    } catch (err) {
      if (err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
        setLoading({
          ...loading,
          button: false
        })

        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
        return
      }
      setErrors({})
      swal.basic({ title: 'Atenção!', text: err.message, icon: 'warning' })
    } finally {
      setLoading({
        ...loading,
        button: false
      })
    }
  }

  return (<>

    <SettingsCard.Header
      title="Configurações de SLA"
      description={
        <p>
            Configure aqui os valore de SLA. A alteração dos valores refletirá no prazo em em <b>dias</b> que a respectiva área alterada terá para atuar na proposta
        </p>
      }
    />

    <SettingsCard> <LoadingOrEmpty
      loading={loading.list}
      failRequest={failRequest}
    >
      <SettingsCard.title>
        Alterar valores de SLA
      </SettingsCard.title>
      <SettingsCard.description >
        Altere os valores e clique em salvar para gravar suas alterações
      </SettingsCard.description>

      <Form
        form={formRef}
        className="p-4 w-100"
        layout="vertical"
        onFinish={onSubmit}
      >
        <Row gutter={[15, 15]}>
          <Col lg={12} sm={24} xs={24} className="pb-2">
            <Form.Item
              name="analista"
              label={<label style={{ minWidth: 260, textAlign: 'left' }}>Análise de Crédito - Analista</label>}
              help={errors.analista && errors.analista}
              validateStatus={errors.analista && 'error'}
            >
              <InputNumber
                className="w-100"
                placeholder="Digite o tempo"
                defaultValue={0}
                disabled={SLAModel.isInputDisabled(user.level)}
              />
            </Form.Item>
          </Col>

          <Col lg={12} sm={24} xs={24} className="pb-2">
            <Form.Item
              name="gerente"
              label={<label style={{ minWidth: 260, textAlign: 'left' }}>Análise de Crédito - Gerente</label>}
              help={errors.gerente && errors.gerente}
              validateStatus={errors.gerente && 'error'}
            >
              <InputNumber
                className="w-100"
                placeholder="Digite o tempo"
                defaultValue={0}
                disabled={SLAModel.isInputDisabled(user.level)}
              />
            </Form.Item>
          </Col>

          <Col lg={12} sm={24} xs={24} className="pb-2">
            <Form.Item
              name="superintendente"
              label={<label>Análise de Crédito - Superintendente</label>}
              help={errors.superintendente && errors.superintendente}
              validateStatus={errors.superintendente && 'error'}
            >
              <InputNumber
                className="w-100"
                placeholder="Digite o tempo"
                defaultValue={0}
                disabled={SLAModel.isInputDisabled(user.level)}
              />
            </Form.Item>
          </Col>

          <Col lg={12} sm={24} xs={24} className="pb-2">
            <Form.Item
              name="diretor"
              label={<label style={{ minWidth: 260, textAlign: 'left' }}>Análise de Crédito - Diretor</label>}
              help={errors.diretor && errors.diretor}
              validateStatus={errors.diretor && 'error'}
            >
              <InputNumber
                className="w-100"
                placeholder="Digite o tempo"
                defaultValue={0}
                disabled={SLAModel.isInputDisabled(user.level)}
              />
            </Form.Item>
          </Col>

          <Col lg={12} sm={24} xs={24} className="pb-2">
            <Form.Item
              name="engenharia"
              label={<label style={{ minWidth: 260, textAlign: 'left' }}>Pré-Análise de Engenharia</label>}
              help={errors.engenharia && errors.engenharia}
              validateStatus={errors.engenharia && 'error'}
            >
              <InputNumber
                className="w-100"
                placeholder="Digite o tempo"
                defaultValue={0}
                disabled={SLAModel.isInputDisabled(user.level)}
              />
            </Form.Item>
          </Col>

          <Col lg={12} sm={24} xs={24} className="pb-2">
            <Form.Item
              name="vistoria"
              label={<label style={{ minWidth: 260, textAlign: 'left' }}>Avaliação do imóvel</label>}
              help={errors.vistoria && errors.vistoria}
              validateStatus={errors.vistoria && 'error'}
            >
              <InputNumber
                className="w-100"
                placeholder="Digite o tempo"
                defaultValue={0}
                disabled={SLAModel.isInputDisabled(user.level)}
              />
            </Form.Item>
          </Col>

          <Col lg={12} sm={24} xs={24} className="pb-2">
            <Form.Item
              name="laudo"
              label={<label style={{ minWidth: 260, textAlign: 'left' }}>Parecer Técnico da Engenharia</label>}
              help={errors.laudo && errors.laudo}
              validateStatus={errors.laudo && 'error'}
            >
              <InputNumber
                className="w-100"
                placeholder="Digite o tempo"
                defaultValue={0}
                disabled={SLAModel.isInputDisabled(user.level)}
              />
            </Form.Item>
          </Col>

          <Col lg={12} sm={24} xs={24} className="pb-2">
            <Form.Item
              name="suporte-comercial"
              label={<label style={{ minWidth: 260, textAlign: 'left' }}>Suporte Comercial</label>}
              help={errors.suporte && errors.suporte}
              validateStatus={errors.suporte && 'error'}
            >
              <InputNumber
                className="w-100"
                placeholder="Digite o tempo"
                defaultValue={0}
                disabled={SLAModel.isInputDisabled(user.level)}
              />
            </Form.Item>
          </Col>

          <Col lg={12} sm={24} xs={24} className="pb-2">
            <Form.Item
              name="interveniente-quitante"
              label={<label style={{ minWidth: 260, textAlign: 'left' }}>Interveniente Quitante</label>}
              help={errors.interveniente && errors.interveniente}
              validateStatus={errors.interveniente && 'error'}
            >
              <InputNumber
                className="w-100"
                placeholder="Digite o tempo"
                defaultValue={0}
                disabled={SLAModel.isInputDisabled(user.level)}
              />
            </Form.Item>
          </Col>

          <Col lg={12} sm={24} xs={24} className="pb-2">
            <Form.Item
              name="juridico"
              label={<label style={{ minWidth: 260, textAlign: 'left' }}>Formalização</label>}
              help={errors.juridico && errors.juridico}
              validateStatus={errors.juridico && 'error'}
            >
              <InputNumber
                className="w-100"
                placeholder="Digite o tempo"
                defaultValue={0}
                disabled={SLAModel.isInputDisabled(user.level)}
              />
            </Form.Item>
          </Col>

          <Col lg={12} sm={24} xs={24} className="pb-2">
            <Form.Item
              name="contrato"
              label={<label style={{ minWidth: 260, textAlign: 'left' }}>Emissão do contrato</label>}
              help={errors.contrato && errors.contrato}
              validateStatus={errors.contrato && 'error'}
            >
              <InputNumber
                className="w-100"
                placeholder="Digite o tempo"
                defaultValue={0}
                disabled={SLAModel.isInputDisabled(user.level)}
              />
            </Form.Item>
          </Col>

          <Col lg={12} sm={24} xs={24} className="pb-2">
            <Form.Item
              name="cartorio"
              label={<label style={{ minWidth: 260, textAlign: 'left' }}>Cartório</label>}
              help={errors.cartorio && errors.cartorio}
              validateStatus={errors.cartorio && 'error'}
            >
              <InputNumber
                className="w-100"
                placeholder="Digite o tempo"
                defaultValue={0}
                disabled={SLAModel.isInputDisabled(user.level)}
              />
            </Form.Item>
          </Col>
        </Row>
        <SettingsCard.ButtonsContainer>
          <SettingsCard.submitButton
            loading={loading.button}
            disabled={SLAModel.isInputDisabled(user.level)}>
              Salvar
          </SettingsCard.submitButton>
        </SettingsCard.ButtonsContainer>
      </Form>
    </LoadingOrEmpty>
    </SettingsCard>

  </>
  )
}

export default SLAConfig

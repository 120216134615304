import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { IColumns } from 'egi/types'
import translate from 'utils/translate'
import commomColumns, { columnBlockUser } from './commomDataTable'
import TransferModal from 'components/TransferModal/TransferModal'

export interface ITeamColumns {
  level: string,
  administratorId: string,
  telemarketingId: string,
  _id: string
  type: string

}

const teamColumns = ({ blockUser, canSeeTransferColumn }: IColumns): ColumnsType<Partial<ITeamColumns>> => [
  ...commomColumns,
  {
    title: 'Nível',
    dataIndex: 'level',
    width: 150,
    render: (value: string) => translate.level(value),
    sorter: true
  },

  ...(canSeeTransferColumn ? [{
    title: 'Transferir',
    dataIndex: '',
    width: 150,
    render: (value: any, row: any) => {
      return row?.type === 'TM'
        ? <TransferModal row={row} />
        : '-'
    }
  }] : []),

  columnBlockUser(blockUser)
]

export default teamColumns

import imageUrls from './imagesUrls'

interface CorbanConfigs {
  [key: string]: {
    id: string,
    slug: string,
    description: string,
    name: string,
    image: string,
    loginType: 'system' | 'client',
    clientUrl?: string
    server?: string
    acServer?: string
    favicon?: string
    line?: string
  }
}

const rodobens = {
  id: '5fe2417e2031d9019c4fde37',
  slug: 'rodobens',
  description: 'Rodobens Placeholder',
  name: 'Rodobens',
  favicon: imageUrls.favicon,
  server: process.env.REACT_APP_RODOBENS_URL,
  line: process.env.REACT_APP_LINE_RODOBENS
}

const corbans: CorbanConfigs = {
  localhost: {
    id: '5fe2417e2031d9019c4fde37',
    slug: 'agxtest',
    description: 'Agx Software Intensive Test',
    name: 'Agx Software',
    loginType: 'system',
    image: 'https://rodobens.agxsoftware.com/wp-content/uploads/2020/12/logo.png',
    clientUrl: '127.0.0.1:3000'
  },
  '127.0.0.1': {
    id: '5fe2417e2031d9019c4fde37',
    slug: 'agxtest',
    description: 'Agx Software Intensive Test',
    name: 'Agx Software',
    loginType: 'client',
    image: 'https://rodobens.agxsoftware.com/wp-content/uploads/2020/12/logo.png'
  },
  'internal.rodobens.line.agxsoftware.com': {
    ...rodobens,
    loginType: 'system',
    image: 'https://rodobens.agxsoftware.com/wp-content/uploads/2020/12/logo.png',
    clientUrl: 'internal.rodobens.line.agxsoftware.com'
  },
  'rodobens.line.agxsoftware.com': {
    ...rodobens,
    image: 'https://rodobens.agxsoftware.com/wp-content/uploads/2020/12/logo.png',
    loginType: 'client',
    clientUrl: 'rodobens.line.agxsoftware.com'
  },
  'line-ecp-web.herokuapp.com': {
    ...rodobens,
    image: 'https://rodobens.agxsoftware.com/wp-content/uploads/2020/12/logo.png',
    loginType: 'client'
  },
  'hml.ecp.rodobens.agxsoftware.com': {
    ...rodobens,
    image: 'https://rodobens.agxsoftware.com/wp-content/uploads/2020/12/logo.png',
    loginType: 'client'
  },
  'www.clienteegi.rodobens.com.br': {
    ...rodobens,
    image: 'https://rodobens.agxsoftware.com/wp-content/uploads/2020/12/logo.png',
    clientUrl: 'www.clienteegi.rodobens.com.br',
    loginType: 'client'
  },
  'dev.internal.rodobens.line.agxsoftware.com': {
    ...rodobens,
    loginType: 'system',
    image: 'https://renegociacao.rodobens.com.br/Content/img/ic-logo.png',
    clientUrl: 'dev.rodobens.line.agxsoftware.com'
  },
  'dev.rodobens.line.agxsoftware.com': {
    ...rodobens,
    loginType: 'client',
    image: 'https://renegociacao.rodobens.com.br/Content/img/ic-logo.png'
  },
  'consignado.rodobens.com.br': {
    ...rodobens,
    loginType: 'client',
    image: 'https://renegociacao.rodobens.com.br/Content/img/ic-logo.png'
  }
}

export default corbans

import React from 'react'
import { Col, Form, Typography } from 'antd'
import { Input } from 'components'
import { IEcpSignupForm } from '../../signupFormInterfaces'
import ChangePasswordInputs from 'ecp/components/ChangePasswordInputs/ChangePasswordInputs'
import { PASSWORD_HELP_TEXT } from 'egi/app/Signup/signupConstants'

type Props = {
  errors: Partial<IEcpSignupForm>
}

function SignupFormJuridical ({ errors }: Props) {
  return (
    <>
      <Col span={24}>
        <Form.Item
          name="email_ou_cpf"
          help={errors.email_ou_cpf && errors.email_ou_cpf}
          validateStatus={errors.email_ou_cpf && 'error'}
          label={<label className="simulator-label">Email</label>}
          rules={[
            {
              required: true,
              message: 'Adicione seu email!'
            }
          ]}
        >
          <Input
            className="unauth-inputs"
            placeholder="Digite o email"
            error={errors.email_ou_cpf}
          />
        </Form.Item>
      </Col>

      <div className='ecp-signup-form__password-help-container'>
        <Typography.Paragraph className='ecp-signup-form__password-help-text'>{PASSWORD_HELP_TEXT}</Typography.Paragraph>
      </div>

      <ChangePasswordInputs
        errors={errors}
      />
    </>
  )
}

export default SignupFormJuridical

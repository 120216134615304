import { AxiosRequestConfig } from 'axios'
import { IResponseBase, Repository } from 'ecp/repositories/Repository'
import api from 'services/api'

class BanRepository extends Repository {
  async getBanList (config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/list`, config)
    )
  }

  async addUserToBanList (body: { identifier: string, value: string, why?: string}, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.post(`${this.path}`, body, config)
    )
  }

  async deleteWhiteList (id: string, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.delete(`${this.path}/delete/${id}`, config)
    )
  }
}

export default new BanRepository({ api, path: '/banned-identifiers' })

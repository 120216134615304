import { Button, Col, Form, message, Typography } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import TokenVerification from 'components/TokenVerification/TokenVerification'
import SignupLayout from 'layouts/SignupLayout/SignupLayout'
import React, { useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { UNAUTHS_PATHS } from 'routes/unauthRoutes'
import { handleOnidataErrors } from 'utils/validate'
import { IEcpForgotPasswordOnidata } from '../SignupForm/signupFormInterfaces'
import ChangePasswordInputs from '../../components/ChangePasswordInputs/ChangePasswordInputs'
import SignupFormResendToken from '../SignupForm/components/SignupFormResendToken/SignupFormResendToken'
import TokenHeader from '../../components/TokenHeader/TokenHeader'
import LoginRepository from 'ecp/repositories/LoginRepository'
import { PASSWORD_HELP_TEXT } from 'egi/app/Signup/signupConstants'
import { UnauthLayout } from 'layouts'
import { swalError } from 'components/SwalError/SwalError'

export default function EcpForgotPasswordOnitada () {
  const [form] = useForm<IEcpForgotPasswordOnidata>()
  const location = useLocation<IEcpForgotPasswordOnidata>()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Partial<IEcpForgotPasswordOnidata>>({})

  const handleSubmit = async (values: IEcpForgotPasswordOnidata) => {
    setLoading(true)

    try {
      if (!location.state.email) throw new Error('Falha ao buscar email')
      values.email = location.state.email

      const response = await LoginRepository.resetWithTokenOnidata(values)
      history.push(UNAUTHS_PATHS.MAIN)
      message.success(response?.data.message)
    } catch (err) {
      if (err?.data && err?.data?.error) {
        message.error(err?.message)
        setErrors(handleOnidataErrors(err.data.error))
      }
      swalError({ title: 'Atenção', err, icon: 'warning' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <SignupLayout>
      <div className='forgot-password-onidata'>
        <div className='forgot-password-onidata__container'>
          <div className='mb-3'>
            <Link
              className='signup__link'
              to={UNAUTHS_PATHS.FORGOT}
            >
              &larr; Voltar
            </Link>
          </div>

          <TokenHeader
            title='Token enviado com sucesso'
            infoText={
              <>
                {(location.state?.fortifyPassword === true && location.state?.email) ? (
                  <p className='forgot-password-onidata__info-text'>
                    <UnauthLayout.Text email={location.state.email} />

                    <br />
                    Insira a token de validação recebida no email abaixo
                  </p>
                ) : (
                  <p className='forgot-password-onidata__info-text'>
                    Foi enviado no email <b>{location.state?.email}</b> o token de validação, insira-a abaixo
                  </p>
                )}
              </>
            }
          />

          <Form
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            className='forgot-password-onidata__form'
          >
            <Col span={24}>
              <Form.Item
                name="resetToken"
                help={errors?.resetToken && (errors?.resetToken)}
                validateStatus={errors?.resetToken && ('error')}
                rules={[
                  {
                    required: true,
                    message: 'Adicione o token.'
                  }
                ]}
              >
                <div className='forgot-password-onidata__verification-input-container'>
                  <TokenVerification lenght={6}/>
                </div>
              </Form.Item>
            </Col>

            <div className='ecp-signup-form__password-help-container'>
              <Typography.Paragraph className='forgot-password-onidata__info-text forgot-password-onidata__info-text--password mb-0'>{PASSWORD_HELP_TEXT}</Typography.Paragraph>
            </div>

            <ChangePasswordInputs
              errors={errors}
            />

            <Form.Item className='mb-3'>
              <Button
                loading={loading}
                type='primary'
                htmlType='submit'
                className='w-100'
              >
                Enviar
              </Button>
            </Form.Item>

            {location?.state?.email &&
              <SignupFormResendToken
                email={location?.state?.email}
              />
            }
          </Form>
        </div>
      </div>
    </SignupLayout>
  )
}

import {
  pricingCommissionTable,
  precificacaoEdit,
  chat,
  lgpdTerms,
  bacenTerms
} from '../routes'

const commissionRouter = [
  pricingCommissionTable,
  precificacaoEdit,
  chat,
  lgpdTerms,
  bacenTerms
]

export default commissionRouter

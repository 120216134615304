import { IChat } from './types'

const INITIALSTATE: IChat = {
  page: 1,
  chat: undefined,
  messages: [],
  tabType: 'active',
  mobileType: 'list',
  otherPersonDetails: undefined,
  showMoreDetails: false,
  updateMessages: false,
  updateChats: false,
  chatPending: 0,
  chatFile: undefined
}

const chat = (state = INITIALSTATE, action: any) => {
  switch (action.type) {
    case 'SET_USERS_PAGE': {
      return {
        ...state,
        page: action.payload.page ? action.payload.page : state.page + 1
      }
    }

    case 'SET_CHAT_TYPE': {
      return {
        ...state,
        tabType: action.payload.tabType
      }
    }

    case 'SET_CHAT_INFO': {
      return {
        ...state,
        chat: action.payload.chatInfo
      }
    }

    case 'SET_CHAT_OTHER_PERSON': {
      return {
        ...state,
        otherPersonDetails: action.payload.person
      }
    }

    case 'SET_CHAT_FILE': {
      return {
        ...state,
        chatFile: action.payload.file
      }
    }

    case 'SET_SHOW_CHAT_MORE_DETAILS': {
      return {
        ...state,
        showMoreDetails: action.payload.mode
      }
    }

    case 'UPDATE_MESSAGES': {
      return {
        ...state,
        updateMessages: !state.updateMessages
      }
    }

    case 'UPDATE_CHATS': {
      return {
        ...state,
        updateChats: !state.updateChats
      }
    }

    case 'CHAT_PENDING_SUCCESS': {
      return {
        ...state,
        chatPending: action.payload.chatPending
      }
    }

    case 'SET_CHAT_MOBILE_TYPE': {
      return {
        ...state,
        mobileType: action.payload.type
      }
    }

    case 'SET_CHAT_MESSAGES': {
      return {
        ...state,
        messages: action.payload.messages
      }
    }

    case 'RESET_CHAT_FILE': {
      return {
        ...state,
        chatFile: undefined
      }
    }

    case 'RESET_CHAT_INFO': {
      return {
        ...state,
        chat: undefined,
        messages: []
      }
    }

    case 'RESET_ALL': {
      return {
        ...state,
        page: 1,
        chat: undefined,
        messages: [],
        updateMessages: false,
        showMoreDetails: false,
        updateChats: false,
        tabType: 'active'
      }
    }

    default:
      return state
  }
}

export default chat

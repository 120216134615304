import { AxiosRequestConfig } from 'axios'
import { IProgressQueueInfo } from 'ecp/components/ProgressQueue/progressQueueInterfaces'
import { IResponseBase, Repository } from 'ecp/repositories/Repository'
import api from 'services/api'

export interface IProgressQueueResponse {
  list?: IProgressQueueInfo[]
  percentage: number
}

class EvaluateRepository extends Repository {
  async progressQueueProgress (id: string, config: AxiosRequestConfig): Promise<IResponseBase<IProgressQueueResponse>> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/${id}`, config)
    )
  }
}

export default new EvaluateRepository({ api, path: 'ecp/evaluate' })

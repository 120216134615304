import React, { useEffect, useState, Fragment } from 'react'
import { Col, Row, Skeleton, Collapse, Tooltip, Select, Form, Button, Empty } from 'antd'
import { proposals } from 'egi/consumers'
import { IAvailableUsers, ILinkedBackoffices } from './types'
import swal from 'utils/swal'
import format from 'utils/format'
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import translate from 'utils/translate'
import { useAuth, useModal } from 'hooks'
import UsersEgiRepository from 'egi/repositories/UsersEgiRepository'
import BackofficeModel from 'egi/models/BackofficeModel'

const { Panel } = Collapse

interface IOpenAreas {
  name: string
  areaId: string
  active: boolean
  backoffices: IBackoffices[]
  backofficeCount: number
}

interface IBackoffices {
  email: string
  level: string
  name: string
  id: string
  areaName: string
}

function LinkedBackofices ({ proposalId }: ILinkedBackoffices) {
  const [loadingSkeleton, setLoadingSkeleton] = useState<boolean>(false)
  const [loadingBackoffices, setLoadingBackoffcies] = useState<boolean>(false)
  const [errors, setErrors] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [openAreasBase, setOpenAreasBase] = useState<IOpenAreas[]>([])
  const [availableUsers, setAvailableUsers] = useState<IAvailableUsers[]>([])
  const [isLinkBackoffice, setIsLinkBackoffice] = useState<boolean>(false)
  const [areaIdLinkedBackoffice, setAreaIdLinkedBackoffice] = useState<string>('')
  const [areaName, setAreaName] = useState<string>('')
  const [selected, setSelected] = useState<string>('')

  const modal = useModal()
  const user = useAuth()

  const getOpenAreas = async () => {
    setLoadingSkeleton(true)
    try {
      const response = await proposals.openAreas({ proposalId: String(proposalId) })
      const departments = response.data.departments
      setOpenAreasBase(departments)
      setLoadingSkeleton(false)
    } catch (err) {
      setLoadingSkeleton(false)
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    }
  }

  useEffect(() => {
    if (proposalId) getOpenAreas()
  }, [proposalId])

  useEffect(() => {
    if (!modal.visible) setIsLinkBackoffice(false)
  }, [modal.visible])

  const getAvailableBackoffice = async ({ event, departmentId, areaName }: {event: React.MouseEvent<HTMLSpanElement, MouseEvent>, departmentId: string, areaName: string}) => {
    event.stopPropagation()
    setLoadingSkeleton(true)
    try {
      const params = { departmentId }
      const response = await UsersEgiRepository.availableBackoffices({ params })
      const users = response?.data?.data?.users || []
      setAvailableUsers(users)
      setIsLinkBackoffice(true)
      setLoadingSkeleton(false)
      setAreaName(areaName)
      setAreaIdLinkedBackoffice(departmentId)
      setLoading(false)
      return response.data
    } catch (err) {
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      setLoading(false)
      setLoadingSkeleton(false)
    }
  }

  const sendNewLinkedBackoffice = async () => {
    setLoading(true)

    try {
      const response = await proposals.linkBackoffice({ proposalId: String(proposalId), areaId: areaIdLinkedBackoffice, data: { backofficeId: selected } })
      swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
      getOpenAreas()
      setIsLinkBackoffice(false)
      setLoading(false)
      setSelected('')
    } catch (err) {
      setErrors({ user: err.message })
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      setLoading(false)
    }
  }

  function handleCancel () {
    setIsLinkBackoffice(false)
    setSelected('')
  }

  async function deleteLinkedBackoffice ({ departmentId, backofficeId }: { departmentId: string, backofficeId: string}) {
    swal.confirmNegate({
      title: 'Atenção',
      text: 'Você deseja desvincular esse backoffice?',
      icon: 'question',
      confirm: async () => {
        setLoadingBackoffcies(true)
        try {
          const response = await proposals.removeBackoffice({ proposalId: String(proposalId), areaId: departmentId, data: { backofficeId: backofficeId } })
          getOpenAreas()
          setLoadingBackoffcies(false)
          swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
        } catch (err) {
          swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
          setLoadingBackoffcies(false)
        }
      }
    })
  }

  return (
    <Fragment>
      {loadingSkeleton ? (
        <Skeleton loading={loadingSkeleton} active={true} />
      ) : (
        <Fragment>
          {!isLinkBackoffice ? (
            <Fragment>
              {openAreasBase.length < 1 ? (
                <Empty
                  description="Nenhum backoffice vinculado"
                />
              ) : (
                <Fragment>
                  {openAreasBase.map((areas, index) => (
                    <Collapse key={areas.areaId}>
                      <Panel
                        header={format.capitalize(areas.name)}
                        key={index}
                        extra={
                          <Fragment>
                            {BackofficeModel.canVinculateBackoffice(user.level) && (
                              <Tooltip title="Vincular novo backoffice" destroyTooltipOnHide={{ keepParent: false }}>
                                <p className="open-areas-card p-1 mb-0 flex flex-align-center flex-justify-center" onClick={(e) => getAvailableBackoffice({ event: e, departmentId: areas.areaId, areaName: areas.name })}>
                                  <PlusCircleOutlined/>
                                </p>
                              </Tooltip>
                            )}
                          </Fragment>
                        }
                      >
                        <Row className="flex" gutter={[10, 10]}>
                          <Fragment>
                            {loadingBackoffices ? (
                              <Skeleton.Button
                                active
                                size='large'
                                className="skeleton-backoffices mb-2" />
                            ) : (
                              <Fragment>
                                {areas.backoffices.length < 1 ? (
                                  <h4 className="color-gray">Nenhum backoffice vinculado</h4>
                                ) : (
                                  <Fragment>
                                    {areas.backoffices.map((backoffices) => (
                                      <Col key={backoffices.id}>
                                        <div className="open-areas-card flex flex-justify-center flex-align-center px-1" key={backoffices.id}>
                                          <h4 className={`mb-0 ${BackofficeModel.canVinculateBackoffice(user.level) && 'mr-3'} color-white`}>{backoffices.name}</h4>
                                          {BackofficeModel.canVinculateBackoffice(user.level) && (
                                            <Tooltip title="Desvincular backoffice" destroyTooltipOnHide={{ keepParent: false }}>
                                              <CloseCircleOutlined className="pointer" onClick={() => deleteLinkedBackoffice({ departmentId: areas.areaId, backofficeId: backoffices.id })} />
                                            </Tooltip>
                                          )}
                                        </div>
                                      </Col>
                                    ))}
                                  </Fragment>
                                )}
                              </Fragment>
                            )}
                          </Fragment>
                        </Row>
                      </Panel>
                    </Collapse>
                  ))}
                </Fragment>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <Row>
                <Col className="px-1" lg={24} sm={24} xs={24}>
                  <Form.Item
                    label={<label>Vincular backoffice em <b>{format.capitalize(areaName)}</b></label>}
                    help={errors.user && (errors.user)}
                    validateStatus={errors.user && ('error')}
                    rules={[{ required: true, message: 'Escolha um Backoffice.' }]}
                  >
                    <Select
                      onChange={(value: string) => setSelected(value)}
                      id="test-select-form-resourcesPurpose"
                      placeholder="Selecione um backoffice"
                      showSearch
                      optionFilterProp="children"
                    >
                      {availableUsers.map(users =>
                        <Select.Option key={users._id} value={users._id}>{users.name} - {translate.areasNames(users.areaName)} </Select.Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <div className="flex flex-justify-end w-100">
                  <Button type="ghost" onClick={() => handleCancel()} className="mr-2">Cancelar</Button>
                  <Button type="primary" onClick={() => sendNewLinkedBackoffice()} loading={loading}>Vincular</Button>
                </div>
              </Row>
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

export default LinkedBackofices

import React from 'react'
import { ServerTable } from 'components'
import FilterSearch from 'components/SeverTable/FilterSearch'
import FilterCreatedAt from 'components/SeverTable/FilterCreatedAt'
import { useDispatch } from 'react-redux'
import { useTables } from 'hooks'
import { tablesSetFiltersValues } from 'store/modules/tables/actions'
import { Dayjs } from 'dayjs'
import { timeAsDayjs } from 'utils/time'
import { UserTableProps } from './userTableInterfaces'
import { LimiterTable } from 'components/LimiterTable/LimiterTable'

function UserTable ({ buttons, columns, filters, fileName, url, customParams }: UserTableProps) {
  const dispatch = useDispatch()
  const tables = useTables()

  return (
    <div className="user-table">
      <LimiterTable>
        <ServerTable
          url={url}
          filters={filters}
          columns={columns}
          buttons={buttons}
          csvFilename={fileName}
          customsParams={customParams}
          primaryFilter={
            <FilterSearch
              key='search'
              onChange={(value: string | undefined) => {
                dispatch(tablesSetFiltersValues({ ...tables.filters, search: value?.trim(), page: 1 }))
              }}
            />
          }
          secondaryFilter={
            <FilterCreatedAt
              key='createdDate'
              onChange={(value: Dayjs) => {
                if (!value) return dispatch(tablesSetFiltersValues({ ...tables.filters, createdDate: undefined, page: 1 }))
                dispatch(tablesSetFiltersValues({ ...tables.filters, createdDate: timeAsDayjs(value).format('MM-YYYY'), page: 1 }))
              }}
            />
          }
        />
      </LimiterTable>
    </div>
  )
}

export default UserTable

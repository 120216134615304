import { _userLevel } from 'ecp/models/UsersModel'
import { _tableAreas } from 'globals'

const SettingsModel = {
  canSeeEmailSettings (userLevel: _userLevel): boolean {
    return userLevel === 'master'
  },

  canSeeOperationalLimit (userLevel: _userLevel, userArea: _tableAreas | undefined) {
    const levels: _userLevel[] = ['master', 'auditor', 'agx']
    const isCreditAdm = userLevel === 'administrator' && userArea === 'credito'
    return levels.includes(userLevel) || isCreditAdm
  },

  canSeePopulationLimit (userLevel: _userLevel) {
    const levels: _userLevel[] = ['master', 'auditor', 'agx']
    return levels.includes(userLevel)
  },

  canSeeSLASettigns (userLevel: _userLevel) {
    const levels: _userLevel[] = ['master', 'auditor', 'agx']
    return levels.includes(userLevel)
  },

  canSeeDeclineSettigns (userLevel: _userLevel) {
    const levels: _userLevel[] = ['master', 'auditor', 'agx']
    return levels.includes(userLevel)
  },

  canSeeSimulationSettigns (userLevel: _userLevel) {
    const levels: _userLevel[] = ['master', 'auditor', 'agx']
    return levels.includes(userLevel)
  },

  canSeeInternalSettings (userLevel: _userLevel, userArea: _tableAreas | undefined) {
    const levels: _userLevel[] = ['master']
    const areas: _tableAreas[] = ['juridico']

    return levels.includes(userLevel) || (userArea && areas.includes(userArea))
  }

}

export default SettingsModel

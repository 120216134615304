import React, { useEffect } from 'react'
import { Divider, message } from 'antd'
import { Drawer } from 'components'
import useProposalDrawerWithContext from 'ecp/app/Proposals/hooks/useProposalDrawerWithContext'
import useThisStep from 'ecp/app/Proposals/hooks/useThisStep'
import ProposalStepButton from '../../ProposalStepButton/ProposalStepButton'
import ProposalStepButtonWithHistory from '../../ProposalStepButtonWithHistory/ProposalStepButtonWithHistory'
import { ProposalStepContent } from '../ProposalSteps'

import ReviewDrawer from '../ReviewDrawer/ReviewDrawer'

import { useAuth } from 'hooks'
import ProposalModel from 'ecp/models/ProposalModel'
import { UploadStepSection } from '../UploadStepSection/UploadStepSection'
import GenerateSendDocumentsLink from '../../GenerateSendDocumentsLink/GenerateSendDocumentsLink'
import { DocumentalProcess } from '../../DocumentalProcess/DocumentalProcess'
import { DocumentalLogsHistory } from '../../DocumentalStepsLogsHistory/DocumentalStepsLogsHistory'
import { IEmailAgeRequest, IUnicoRequest, _openStepWithAction } from 'ecp/app/Proposals/proposalInterfaces'
import { useLocation, useParams } from 'react-router-dom'
import { ClientInstructions } from '../../ClientInstructions/ClientInstructions'
import usePendentProposal from 'ecp/app/Proposals/hooks/usePendentProposals'
import useDynamicProposal from 'ecp/app/Proposals/hooks/useDynamicProposal'
import format from 'utils/format'
import { concatClientAddress } from 'ecp/app/Proposals/proposalFunctions'

interface IParams {
  proposalId: string
}

const DocumentalContent = ({ canOnlyUploadDocument } : { canOnlyUploadDocument: boolean}) => {
  const user = useAuth()
  const { details, reloadProposalAndSteps } = useDynamicProposal()
  const { step, department } = useThisStep()
  const { proposalId } = useParams<IParams>()
  const {
    cpf,
    data_nascimento,
    sexo,
    nome,
    sobrenome,
    email: emailFromFuncionario,
    telefone_celular: telefone_celularFromFuncionario,
    endereco
  } = details?.value?.onidata?.funcionario || {}

  const {
    email: emailFromClient,
    cellphone: cellphoneFromClient
  } = details?.value?.client || {}

  const {
    email: emailFromContract,
    telefone_celular: cellphoneFromContract
  } = details?.value.onidata.contrato || {}

  const email = emailFromFuncionario || emailFromClient || emailFromContract || ''
  const cellphone = telefone_celularFromFuncionario || cellphoneFromClient || cellphoneFromContract

  const resendUnicoAcertPixInfo: IUnicoRequest = {
    email,
    cpf: cpf || '',
    birthDate: data_nascimento ? data_nascimento.toString() : '',
    gender: sexo || '',
    firstName: nome || '',
    lastName: sobrenome || '',
    cellphone: (cellphone ?? '').replace('+', '')
  }

  const resendEmailAgeInfo: IEmailAgeRequest = {
    email,
    firstName: nome || '',
    lastName: sobrenome || '',
    billAddress: endereco?.logradouro || '',
    billCity: endereco?.cidade || '',
    billRegion: endereco?.bairro || '',
    billCountry: endereco?.pais || '',
    billPostal: endereco?.cep || '',
    cellphone: format.cellphoneWithoutDDI(cellphone ?? '')
  }

  const generateReportInfo = {
    client: {
      ...details?.value?.client,
      name: details?.value?.client?.name || '',
      email: details?.value?.client?.email || '',
      cpf: details?.value?.client?.cpf || '',
      cellphone: details?.value?.client?.cellphone || '',
      address: details?.value.onidata.cliente_info_no_contrato.endereco
        ? concatClientAddress(details?.value.onidata.cliente_info_no_contrato.endereco)
        : ''
    },
    operation: {
      product: details?.value?.productSlug || '',
      financedAmount: details?.value?.financedAmount || 0,
      releasedAmount: details?.value?.releasedAmount || 0
    }
  }

  const updateProposal = async () => {
    try {
      await reloadProposalAndSteps({
        proposalId: proposalId ?? details?.value?._id,
        stepId: step._id,
        departmentId: department._id
      })
    } catch (err) {
      message.error('Não foi possível atualizar a proposta')
    }
  }

  if (canOnlyUploadDocument) {
    return <>
      <ClientInstructions
        proposalId={proposalId ?? details?.value?._id}
        step={step}
      />

      <UploadStepSection
        step={step}
        proposalId={proposalId ?? details?.value?._id}
        departmentId={department?._id || ''}
        updateProposal={updateProposal}
      />
    </>
  }

  return (<>
    <DocumentalProcess
      resendUnicoAcertPixInfo={resendUnicoAcertPixInfo}
      resendEmailAgeInfo={resendEmailAgeInfo}
      generateReportInfo={generateReportInfo}
      step={step}
      proposalId={details?.value?._id}
    />

    {ProposalModel.canSeeDocumentalHistoryLogs(user.level) && (
      <div className='documental-step__logs-container'>
        <ProposalStepContent.Subtitle>
        Visualização de logs
        </ProposalStepContent.Subtitle>

        <ProposalStepContent.Description className='mb-0'>
          Logs dos retornos de disparos, webhooks, erros e sucessos das APIS do <b>Unico, Acertpix, Core e EmailAge</b>
        </ProposalStepContent.Description>

        <DocumentalLogsHistory proposalId={details?.value?._id} />
      </div>
    )}

    <Divider />

    <div className='documental-step__container'>
      <ProposalStepContent.Title>
        Validação manual
      </ProposalStepContent.Title>

      <ProposalStepContent.Description>
        Valide os processos de análise documental manualmente. Insira todos os documentos e adicione o status e revisão
      </ProposalStepContent.Description>

      <UploadStepSection
        step={step}
        proposalId={proposalId ?? details?.value?._id}
        departmentId={department?._id || ''}
        updateProposal={updateProposal}
      />
    </div>

  </>
  )
}

export function DocumentalStep () {
  const { isOpen, open, close } = useProposalDrawerWithContext()
  const { sendStep } = useProposalDrawerWithContext()
  const { details } = useDynamicProposal()

  const { setCurrentStepToOpen } = usePendentProposal()

  const {
    step
  } = useThisStep()

  const { canOnlyUploadDocument, canApprove, canOpen } = step

  const { state } = useLocation<_openStepWithAction | null>()

  useEffect(() => {
    if (state === 'openDocumental') open()
  }, [])

  useEffect(() => {
    setCurrentStepToOpen(step.slug, open)
  }, [])

  return (
    <ProposalStepButtonWithHistory>
      <ProposalStepButton
        onClick={open}
        enabled={canOpen || canOnlyUploadDocument}
      >
        {step.name}

      </ProposalStepButton>

      <Drawer
        visible={isOpen}
        onClose={close}
        title={step.name}
        footer={null}
      >
        <ProposalStepContent>
          <DocumentalContent
            canOnlyUploadDocument={canOnlyUploadDocument}
          />
          {canApprove &&
            <ReviewDrawer
              close={close}
              sendStep={sendStep}
              step={step}
              proposalId={details?.value?._id || ''}
              clientName={details?.value?.client?.name || ''}
            />
          }
        </ProposalStepContent>
      </Drawer>

      <GenerateSendDocumentsLink messagePrefix={`Envio de documento do passo ${step.name}`} />
    </ProposalStepButtonWithHistory>
  )
}

import dayjs from 'dayjs'
import { timeAsDayjs } from 'utils/time'

function downloadCSVFile (csv: string, csvFilename?: string, options?: { callback?: () => void, hasDownload?: boolean }) {
  const { callback, hasDownload } = options || {}

  const csvFile = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv)
  let filename: string

  if (hasDownload) filename = `relatorio_geral${'_' + csvFilename || ''}_${timeAsDayjs(dayjs()).format('DD-MM-YYYY')}`
  else filename = `relatorio_de_propostas_EGI_${timeAsDayjs(dayjs()).format('DD-MM-YYYY')}`

  const a = document.createElement('a')

  a.href = 'data:' + csvFile
  a.download = filename + '.csv'

  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  callback?.()
}

export {
  downloadCSVFile
}

import loadable from '@loadable/component'
import { Skeleton } from 'antd'
import { timeout } from 'promise-timeout'
import React from 'react'
import { TIMEOUT_LOADER } from 'utils/globals'
import { IRichTextEditorInnerProps } from './types'

function RichTextSuspeseSekeleton () {
  return (
    <Skeleton.Button
      active={true}
      className='w-100 rich-text-editor-inner__skeleton'
    />
  )
}

const RichTextEditorInnerLazy = loadable(
  () => timeout(import(/* webpackChunkName: "RichTextEditorInner" */ './RichTextEditorInner'), TIMEOUT_LOADER),
  { fallback: <RichTextSuspeseSekeleton /> }
)

const RichTextEditorInnerLazyWrapper: React.FC<IRichTextEditorInnerProps> = (props) => {
  return (
    <RichTextEditorInnerLazy {...props} />
  )
}

export default function RichTextEditor (props: IRichTextEditorInnerProps) {
  return (
    <RichTextEditorInnerLazyWrapper {...props} />
  )
}

import React, { useEffect, useState } from 'react'
import { Button, Col, Collapse, Input, Row, Select } from 'antd'
import { useClient, useProposal, useStep } from 'hooks'
import InputMoneySpreed from 'components/inputMoney/inputMoneySpreeds'
import format from 'utils/format'
import { spreadsheet } from 'egi/consumers'
import swal from 'utils/swal'
import { useDispatch } from 'react-redux'
import { setPMT } from 'store/modules/pmt/action'

interface IColumn {
  label: string
  value: any[]
}

const { Panel } = Collapse

function Calculator ({ type, setBACEN, canSave = true }: { type: string, setBACEN: string, canSave?: boolean }) {
  const client = useClient()
  const proposal = useProposal()
  const step = useStep()
  const dispatch = useDispatch()

  const [spreadsheetId, setSpreadsheetId] = useState<string>()
  const [loading, setLoading] = useState(false)

  // SERASA
  const [column1SERASA, setColumn1SERASA] = useState<IColumn>({ label: 'MODALIDADE', value: [...(new Array(6))] })
  const [column2SERASA, setColumn2SERASA] = useState<IColumn>({ label: 'QTD ', value: [...(new Array(6))] })
  const [column3SERASA, setColumn3SERASA] = useState<IColumn>({ label: 'VALOR ', value: [...(new Array(6))] })
  const [column4SERASA, setColumn4SERASA] = useState<IColumn>({ label: 'SERÁ PAGO?', value: [...(new Array(6))] })

  // BACEN
  const [column1BACEN, setColumn1BACEN] = useState<IColumn>({ label: 'MODALIDADE', value: [...(new Array(6))] })
  const [column2BACEN, setColumn2BACEN] = useState<IColumn>({ label: 'À VENCER ', value: [...(new Array(6))] })
  const [column3BACEN, setColumn3BACEN] = useState<IColumn>({ label: 'VENCIDO ', value: [...(new Array(6))] })
  const [column4BACEN, setColumn4BACEN] = useState<IColumn>({ label: 'SERÁ PAGO?', value: [...(new Array(6))] })
  const [column5BACEN, setColumn5BACEN] = useState<IColumn>({ label: 'VALOR PMT', value: [...(new Array(6))] })

  // DÍVIDAS ECAC
  const [column1ECAC, setColumn1ECAC] = useState<IColumn>({ label: 'MODALIDADE', value: [...(new Array(6))] })
  const [column2ECAC, setColumn2ECAC] = useState<IColumn>({ label: 'QTD ', value: [...(new Array(6))] })
  const [column3ECAC, setColumn3ECAC] = useState<IColumn>({ label: 'VALOR ', value: [...(new Array(6))] })
  const [column4ECAC, setColumn4ECAC] = useState<IColumn>({ label: 'SERÁ PAGO?', value: [...(new Array(6))] })

  const [open, setOpen] = useState(false)

  const sendSpreadsheet = async () => {
    if (!proposal.id) return

    const data = {
      columns: [
        column1SERASA, column2SERASA, column3SERASA, column4SERASA,
        column1BACEN, column2BACEN, column3BACEN, column4BACEN, column5BACEN,
        column1ECAC, column2ECAC, column3ECAC, column4ECAC],
      type: type
    }

    setLoading(true)
    try {
      const response = await spreadsheet.save(proposal.id, data)

      if (response) {
        setSpreadsheetId(response.calculationId)
      }

      setLoading(false)
      swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
    } catch (err) {
      if (err) { swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' }) }
      console.log(err)
      setLoading(false)
    }
  }

  const updateSpreadsheet = async () => {
    if (!proposal.id) return
    if (!spreadsheetId) { return }

    const data = {
      columns: [
        column1SERASA, column2SERASA, column3SERASA, column4SERASA,
        column1BACEN, column2BACEN, column3BACEN, column4BACEN, column5BACEN,
        column1ECAC, column2ECAC, column3ECAC, column4ECAC],
      type: type
    }

    setLoading(true)
    try {
      const response = await spreadsheet.update(proposal.id, spreadsheetId, data)
      setLoading(false)
      swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
    } catch (err) {
      if (err) { swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' }) }
      console.log(err)
      setLoading(false)
    }
  }

  async function getSpreadsheet () {
    if (!proposal.id) { return }
    setLoading(true)
    try {
      const response = await spreadsheet.get(proposal.id, type)
      const columns = response.columns
      if (response) { setSpreadsheetId(response._id) }
      if (columns) {
        setColumn1SERASA(columns[0]); setColumn2SERASA(columns[1]); setColumn3SERASA(columns[2]); setColumn4SERASA(columns[3])
        setColumn1BACEN(columns[4]); setColumn2BACEN(columns[5]); setColumn3BACEN(columns[6]); setColumn4BACEN(columns[7]); setColumn5BACEN(columns[8])
        setColumn1ECAC(columns[9]); setColumn2ECAC(columns[10]); setColumn3ECAC(columns[11]); setColumn4ECAC(columns[12])
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  function getTotal (column: IColumn) {
    return column?.value.reduce(function (total, value) {
      if (value) { return total + value }
      return total
    }, 0)
  }

  function getBACENTotal () {
    return Number(getTotal(column2BACEN) || 0) + Number(getTotal(column3BACEN) || 0)
  }

  function getTotalPMT () {
    const column = column5BACEN
    const condition = column4BACEN
    const result = column?.value.reduce(function (total, value, index) {
      if (value && condition.value[index] === 'no') { return total + value }
      return total
    }, 0)

    dispatch(setPMT({ [setBACEN]: result }))
  }

  function cleantable () {
    setColumn1SERASA({ label: 'MODALIDADE', value: [...(new Array(6))] })
    setColumn2SERASA({ label: 'QTD', value: [...(new Array(6))] })
    setColumn3SERASA({ label: 'VALOR', value: [...(new Array(6))] })
    setColumn4SERASA({ label: 'SERÁ PAGO?', value: [...(new Array(6))] })

    setColumn1BACEN({ label: 'MODALIDADE', value: [...(new Array(6))] })
    setColumn2BACEN({ label: 'À VENCER', value: [...(new Array(6))] })
    setColumn3BACEN({ label: 'VENCIDO', value: [...(new Array(6))] })
    setColumn4BACEN({ label: 'SERÁ PAGO?', value: [...(new Array(6))] })
    setColumn5BACEN({ label: 'VALOR PMT', value: [...(new Array(6))] })

    setColumn1ECAC({ label: 'MODALIDADE', value: [...(new Array(6))] })
    setColumn2ECAC({ label: 'QTD', value: [...(new Array(6))] })
    setColumn3ECAC({ label: 'VALOR', value: [...(new Array(6))] })
    setColumn4ECAC({ label: 'SERÁ PAGO?', value: [...(new Array(6))] })
  }

  useEffect(() => {
    cleantable()
    getSpreadsheet()
  }, [step.id, step.situation])

  useEffect(() => {
    getTotalPMT()
  }, [column5BACEN, column4BACEN])

  return (
    <Collapse onChange={() => { setOpen(!open) }} className='mb-2'>
      <Panel
        header="Calculadora"
        key="1"
        extra={(open && canSave) && <Button type='primary' loading={loading} onClick={(e) => { !loading && (spreadsheetId ? updateSpreadsheet() : sendSpreadsheet()); e.stopPropagation() }}>Salvar</Button>}
      >
        <Row className='flex-1 spreadsheet' justify='center'>
          <Col span={23}>
            <Row className='flex-1 spreadsheet-cr-model'>
              <Col span={24} className='cell-primary'>
                {type.includes('pj') ? 'PESSOA JURÍDICA' : 'PESSOA FÍSICA'} - {client?.name}
              </Col>
            </Row>
            <Row className='flex-1 spreadsheet-cr-model'>
              <Col span={24}>
                <Row className='flex-1'>
                  <Col span={21} className='cell-secundary'>
                    SERASA
                  </Col>
                </Row>
                <Row className='flex-1' justify="center" align='middle'>
                  <Row className='flex-1'>
                    <Col span={3}>
                      <Col className="cell cell-secundary" span={24}> <p>ÓRGÃO </p></Col>
                      {column1SERASA.value.map((cell, row) => <Col key={row} className="cell" span={24}><p>SERASA </p></Col>)}
                    </Col>
                    <Col span={9}>
                      <Col className="cell cell-secundary" span={24}>
                        < Input disabled={loading} value={column1SERASA?.label} />
                      </Col>
                      {column1SERASA.value.map((cell, row) => (
                        <Col key={row} className="cell" span={24}>
                          <Input disabled={loading} value={column1SERASA?.value[row]} onChange={(e) => { column1SERASA.value[row] = e.target.value; setColumn1SERASA({ label: column1SERASA.label, value: column1SERASA?.value }) }} />
                        </Col>
                      ))}
                    </Col>
                    <Col span={3}>
                      <Col className="cell cell-secundary" span={24}>
                        < Input disabled={loading} value={column2SERASA?.label} />
                      </Col>
                      {column2SERASA.value.map((cell, row) => (
                        <Col key={row} className="cell" span={24}>
                          <Input disabled={loading} type="number" min={0} value={column2SERASA?.value[row]} onChange={(e) => { column2SERASA.value[row] = Number(e.target.value); setColumn2SERASA({ label: column2SERASA.label, value: column2SERASA?.value }) }} />
                        </Col>
                      ))}
                    </Col>
                    <Col span={3}>
                      <Col className="cell cell-secundary" span={24}>
                        < Input disabled={loading} value={column3SERASA?.label} />
                      </Col>
                      {column3SERASA.value.map((cell, row) => (
                        <Col key={row} className="cell" span={24}>
                          <InputMoneySpreed
                            disabled={loading}
                            value={column3SERASA?.value[row] || undefined}
                            onChange={(e) => {
                              column3SERASA.value[row] = Number(format.currencyParser(e.target.value))
                              setColumn3SERASA({ label: column3SERASA.label, value: column3SERASA?.value })
                            }}
                          />
                        </Col>
                      ))}
                    </Col>
                    <Col span={3}>
                      <Col className="cell cell-secundary" span={24}>
                        < Input disabled={loading} value={column4SERASA?.label} />
                      </Col>
                      {column4SERASA.value.map((cell, row) => (
                        <Col key={row} className="cell" span={24}>
                          <Select disabled={loading} value={column4SERASA?.value[row]} onChange={(e) => { column4SERASA.value[row] = (e); setColumn4SERASA({ label: column4SERASA.label, value: column4SERASA?.value }) }} >
                            <Select.Option value={'yes'}>SIM</Select.Option>
                            <Select.Option value={'no'}>NÃO</Select.Option>
                          </Select>
                        </Col>
                      ))}
                    </Col>
                  </Row>
                </Row>
                <Row className='flex-1'>
                  <Col span={3}><Col span={24}></Col></Col>
                  <Col span={9}><Col span={24}></Col></Col>
                  <Col span={3}><Col className="cell cell-value" span={24}><p>TOTAL</p></Col></Col>
                  <Col span={3}>
                    <Col className="cell cell-secundary" span={24}>
                      < Input disabled={loading} value={format.formatBRL(getTotal(column3SERASA)) || '-'} />
                    </Col>
                  </Col>
                  <Col span={3}><Col span={24}></Col></Col>
                </Row>
              </Col>
            </Row>
            <Row className='flex-1 spreadsheet-cr-model'>
              <Col span={24}>
                <Row className='flex-1' justify="center">
                  <Col span={24} className='cell-secundary'>
                    BACEN
                  </Col>
                </Row>
                <Row className='flex-1' justify="center" align='middle'>
                  <Row className='flex-1'>
                    <Col span={3}>
                      <Col className="cell cell-secundary" span={24}> <p>ÓRGÃO </p></Col>
                      {column1BACEN.value.map((cell, row) => <Col key={row} className="cell" span={24}><p>BACEN </p></Col>)}
                    </Col>
                    <Col span={9}>
                      <Col className="cell cell-secundary" span={24}>
                        < Input disabled={loading} value={column1BACEN?.label} />
                      </Col>
                      {column1BACEN.value.map((cell, row) => (
                        <Col key={row} className="cell" span={24}>
                          <Input disabled={loading} value={column1BACEN?.value[row]} onChange={(e) => { column1BACEN.value[row] = e.target.value; setColumn1BACEN({ label: column1BACEN.label, value: column1BACEN?.value }) }} />
                        </Col>
                      ))}
                    </Col>
                    <Col span={3}>
                      <Col className="cell cell-secundary" span={24}>
                        <Input disabled={loading} value={column2BACEN?.label} />
                      </Col>
                      {column2BACEN.value.map((cell, row) => (
                        <Col key={row} className="cell" span={24}>
                          <InputMoneySpreed disabled={loading} value={column2BACEN?.value[row] || undefined} onChange={(e) => { column2BACEN.value[row] = Number(format.currencyParser(e.target.value)); setColumn2BACEN({ label: column2BACEN.label, value: column2BACEN?.value }) }} />
                        </Col>
                      ))}
                    </Col>
                    <Col span={3}>
                      <Col className="cell cell-secundary" span={24}>
                        < Input disabled={loading} value={column3BACEN?.label} />
                      </Col>
                      {column3BACEN.value.map((cell, row) => (
                        <Col key={row} className="cell" span={24}>
                          <InputMoneySpreed disabled={loading} value={column3BACEN?.value[row] || undefined } onChange={(e) => { column3BACEN.value[row] = Number(format.currencyParser(e.target.value)); setColumn3BACEN({ label: column3BACEN.label, value: column3BACEN?.value }) }} />
                        </Col>
                      ))}
                    </Col>
                    <Col span={3}>
                      <Col className="cell cell-secundary" span={24}>
                        < Input disabled={loading} value={column4BACEN?.label} />
                      </Col>
                      {column4BACEN.value.map((cell, row) => (
                        <Col key={row} className="cell" span={24}>
                          <Select disabled={loading} value={column4BACEN?.value[row]} onChange={(e) => { column4BACEN.value[row] = (e); setColumn4BACEN({ label: column4BACEN.label, value: column4BACEN?.value }) }} >
                            <Select.Option value={'yes'}>SIM</Select.Option>
                            <Select.Option value={'no'}>NÃO</Select.Option>
                          </Select>
                        </Col>
                      ))}
                    </Col>
                    <Col span={3}>
                      <Col className="cell cell-secundary" span={24}>
                        < Input disabled={loading} value={column5BACEN?.label} />
                      </Col>
                      {column5BACEN.value.map((cell, row) => (
                        <Col key={row} className="cell" span={24}>
                          <InputMoneySpreed disabled={loading} value={column5BACEN?.value[row] || undefined} onChange={(e) => { column5BACEN.value[row] = Number(format.currencyParser(e.target.value)); setColumn5BACEN({ label: column5BACEN.label, value: column5BACEN?.value }) }} />
                        </Col>
                      ))}
                    </Col>
                  </Row>
                </Row>
                <Row className='flex-1'>
                  <Col span={3}><Col span={24}></Col></Col>
                  <Col span={9}><Col span={24}></Col></Col>
                  <Col span={3}><Col className="cell cell-value" span={24}><p>TOTAL</p></Col></Col>
                  <Col span={3}>
                    <Col className="cell cell-secundary" span={24}>
                      < Input disabled={loading} value={format.formatBRL(getBACENTotal()) || '-'} />
                    </Col>
                  </Col>
                  <Col span={3}><Col span={24}></Col></Col>
                  <Col span={3}>
                    <Col className="cell cell-secundary" span={24}>
                      < Input disabled={loading} value={format.formatBRL(getTotal(column5BACEN)) || '-'} />
                    </Col>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className='flex-1 spreadsheet-cr-model'>
              <Col span={24}>
                <Row className='flex-1'>
                  <Col span={21} className='cell-secundary'>
                    DÍVIDAS ECAC
                  </Col>
                </Row>
                <Row className='flex-1' justify="center" align='middle'>
                  <Row className='flex-1'>
                    <Col span={3}>
                      <Col className="cell cell-secundary" span={24}> <p>ÓRGÃO </p></Col>
                      {column1ECAC.value.map((item, row) => <Col key={row} className="cell" span={24}><p>ECAC </p></Col>)}
                    </Col>
                    <Col span={9}>
                      <Col className="cell cell-secundary" span={24}>
                        < Input disabled={loading} value={column1ECAC?.label} />
                      </Col>
                      {column1ECAC.value.map((cell, row) => (
                        <Col key={row} className="cell" span={24}>
                          <Input disabled={loading} value={column1ECAC?.value[row]} onChange={(e) => { column1ECAC.value[row] = e.target.value; setColumn1ECAC({ label: column1ECAC.label, value: column1ECAC?.value }) }} />
                        </Col>
                      ))}
                    </Col>
                    <Col span={3}>
                      <Col className="cell cell-secundary" span={24}>
                        < Input disabled={loading} value={column2ECAC?.label} />
                      </Col>
                      {column2ECAC.value.map((cell, row) => (
                        <Col key={row} className="cell" span={24}>
                          <Input disabled={loading} type="number" min={0} value={column2ECAC?.value[row]} onChange={(e) => { column2ECAC.value[row] = Number(e.target.value); setColumn2ECAC({ label: column2ECAC.label, value: column2ECAC?.value }) }} />
                        </Col>
                      ))}
                    </Col>
                    <Col span={3}>
                      <Col className="cell cell-secundary" span={24}>
                        < Input disabled={loading} value={column3ECAC?.label} />
                      </Col>
                      {column3ECAC.value.map((cell, row) => (
                        <Col key={row} className="cell" span={24}>
                          <InputMoneySpreed disabled={loading} value={column3ECAC?.value[row] || undefined} onChange={(e) => { column3ECAC.value[row] = Number(format.currencyParser(e.target.value)); setColumn3ECAC({ label: column3ECAC.label, value: column3ECAC?.value }) }} />
                        </Col>
                      ))}
                    </Col>
                    <Col span={3}>
                      <Col className="cell cell-secundary" span={24}>
                        < Input disabled={loading} value={column4ECAC?.label} />
                      </Col>
                      {column4ECAC.value.map((cell, row) => (
                        <Col key={row} className="cell" span={24}>
                          <Select disabled={loading} value={column4ECAC?.value[row]} onChange={(e) => { column4ECAC.value[row] = (e); setColumn4ECAC({ label: column4ECAC.label, value: column4ECAC?.value }) }} >
                            <Select.Option value={'yes'}>SIM</Select.Option>
                            <Select.Option value={'no'}>NÃO</Select.Option>
                          </Select>
                        </Col>
                      ))}
                    </Col>
                  </Row>
                </Row>
                <Row className='flex-1'>
                  <Col span={3}><Col span={24}></Col></Col>
                  <Col span={9}><Col span={24}></Col></Col>
                  <Col span={3}><Col className="cell cell-value" span={24}><p>TOTAL</p></Col></Col>
                  <Col span={3}>
                    <Col className="cell cell-secundary" span={24}>
                      < Input disabled={loading} value={format.formatBRL(getTotal(column3ECAC)) || '-'} />
                    </Col>
                  </Col>
                  <Col span={3}><Col span={24}></Col></Col>
                </Row>
                <Row className='flex-1'>
                  <Col span={0}><Col span={24}></Col></Col>
                  <Col span={1}><Col span={24}></Col></Col>
                  <Col span={14}><Col className="cell cell-value" span={24}><p>TOTAL BACEN + SERASA+ E-CAC:</p></Col></Col>
                  <Col span={3}>
                    <Col className="cell cell-secundary" span={24}>
                      < Input disabled={loading} value={format.formatBRL(getTotal(column3ECAC)) || '-'} />
                    </Col>
                  </Col>
                  <Col span={3}><Col span={24}></Col></Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  )
}

export default Calculator

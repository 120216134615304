import React, { useRef } from 'react'
import { Form, Input } from 'antd'

import { InputProps } from 'antd/lib/input'
import { validateCpf } from 'utils/validate'
import format from 'utils/format'

interface IFilterTypeSearch extends Omit<InputProps, 'onChange'> {
  onChange: (value: string | undefined, ignore: boolean | undefined) => void
  value?: string
}

function FilterTypeSearchDropdown ({ onChange }: IFilterTypeSearch) {
  const inputRef = useRef<Input | null>(null)

  return (
    <Form.Item
      name="search"
    >
      <Input.Search
        ref={inputRef}
        className="filter-type-search__input"
        placeholder='Pesquisar'
        onSearch={(value, event) => {
          event?.stopPropagation()
          const isCpf = validateCpf(value)
          const formatted = isCpf ? format.onlyDigits(value) : value

          onChange(formatted, true)
        }}
      />
    </Form.Item>
  )
}

export default FilterTypeSearchDropdown

import { Empty, Select } from 'antd'
import axios, { AxiosRequestConfig } from 'axios'
import { ISelectProps } from 'components/Select/Select'
import ResourcesRepository from 'ecp/repositories/ResourcesRepository'
import React, { useEffect, useState } from 'react'
import translate from 'utils/translate'

const formatToSelectUser = (item: string) => ({
  value: item,
  label: translate.level(item)
})

interface Props extends ISelectProps{
  product?: string
}

export default function UsersSelect ({ product, disabled, mode, onChange, placeholder, ...rest }: Props) {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [users, setUsers] = useState<{ value: string, label: string }[]>([])

  useEffect(() => {
    const abortController = new AbortController()
    async function fetchLevels () {
      try {
        setLoading(true)

        const requestConfig: AxiosRequestConfig = {
          signal: abortController.signal,
          params: { product }
        }

        const response = await ResourcesRepository.getUsersLevels(requestConfig)
        const users = response.data.data?.levels

        if (!users?.length) {
          setUsers([])
          throw new Error('Nenhum nivel encontrado encontrado')
        }

        const formatedValues = (users || []).map(formatToSelectUser)
        setUsers(formatedValues)
        setLoading(false)
      } catch (err) {
        if (!axios.isAxiosError(err)) {
          setErrorMessage(err?.message)
          setLoading(false)
        }
      }
    }

    fetchLevels()
    return () => abortController.abort()
  }, [])

  return (
    <Select
      {...rest}
      mode={mode}
      onChange={onChange}
      showSearch
      optionFilterProp="children"
      disabled={loading || disabled}
      loading={loading}
      placeholder={placeholder}
      getPopupContainer={trigger => trigger.parentNode}
      notFoundContent={
        errorMessage && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={errorMessage} />
      }
    >
      {users.map((item, idx) => (
        <Select.Option
          value={item.value}
          key={item.value + (idx)}
        >
          {item.label}
        </Select.Option>
      ))}
    </Select>
  )
}

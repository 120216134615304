import axios from 'axios'
import dayjs, { Dayjs } from 'dayjs'

const holidays: {
  [key: number]: Array<{
    date: string
    name: string
    type: string
  }>
} = {
  2024: [
    {
      date: '2024-01-01',
      name: 'Confraternização mundial',
      type: 'national'
    },
    {
      date: '2024-02-13',
      name: 'Carnaval',
      type: 'national'
    },
    {
      date: '2024-03-29',
      name: 'Sexta-feira Santa',
      type: 'national'
    },
    {
      date: '2024-03-31',
      name: 'Páscoa',
      type: 'national'
    },
    {
      date: '2024-04-21',
      name: 'Tiradentes',
      type: 'national'
    },
    {
      date: '2024-05-01',
      name: 'Dia do trabalho',
      type: 'national'
    },
    {
      date: '2024-05-30',
      name: 'Corpus Christi',
      type: 'national'
    },
    {
      date: '2024-09-07',
      name: 'Independência do Brasil',
      type: 'national'
    },
    {
      date: '2024-10-12',
      name: 'Nossa Senhora Aparecida',
      type: 'national'
    },
    {
      date: '2024-11-02',
      name: 'Finados',
      type: 'national'
    },
    {
      date: '2024-11-15',
      name: 'Proclamação da República',
      type: 'national'
    },
    {
      date: '2024-11-20',
      name: 'Dia da consciência negra',
      type: 'national'
    },
    {
      date: '2024-12-25',
      name: 'Natal',
      type: 'national'
    }
  ],
  2025: [
    {
      date: '2025-01-01',
      name: 'Confraternização mundial',
      type: 'national'
    },
    {
      date: '2025-03-04',
      name: 'Carnaval',
      type: 'national'
    },
    {
      date: '2025-04-18',
      name: 'Sexta-feira Santa',
      type: 'national'
    },
    {
      date: '2025-04-20',
      name: 'Páscoa',
      type: 'national'
    },
    {
      date: '2025-04-21',
      name: 'Tiradentes',
      type: 'national'
    },
    {
      date: '2025-05-01',
      name: 'Dia do trabalho',
      type: 'national'
    },
    {
      date: '2025-06-19',
      name: 'Corpus Christi',
      type: 'national'
    },
    {
      date: '2025-09-07',
      name: 'Independência do Brasil',
      type: 'national'
    },
    {
      date: '2025-10-12',
      name: 'Nossa Senhora Aparecida',
      type: 'national'
    },
    {
      date: '2025-11-02',
      name: 'Finados',
      type: 'national'
    },
    {
      date: '2025-11-15',
      name: 'Proclamação da República',
      type: 'national'
    },
    {
      date: '2025-11-20',
      name: 'Dia da consciência negra',
      type: 'national'
    },
    {
      date: '2025-12-25',
      name: 'Natal',
      type: 'national'
    }
  ]
}

type IHoliday = {
  date: string
  name: string
  type: string
}

export class Holiday {
  private year: number

  constructor (year: number) {
    this.year = year
  }

  getHoliday = async () => {
    try {
      const holidays = await axios.get<Array<IHoliday>>('https://brasilapi.com.br/api/feriados/v1/' + dayjs().year())

      return holidays.data
    } catch (err) {
      return holidays[this.year] || []
    }
  }

  nextWorkingDay = (date: Dayjs, allHolidays: Array<IHoliday>): Dayjs => {
    const nextDay = date.add(1, 'day')

    const isHoliday = (allHolidays ?? []).some((holiday: IHoliday) => dayjs(holiday.date).isSame(nextDay, 'day'))

    if (isHoliday) return this.nextWorkingDay(nextDay, allHolidays)
    return nextDay.day() === 0 || nextDay.day() === 6 ? this.nextWorkingDay(nextDay, allHolidays) : nextDay
  }
}

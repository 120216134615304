import DatepickerCustom from 'components/DatepickerCustom/DatepickerCustom'
import React, { useRef } from 'react'
import { Dropdown, Menu, Tooltip } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

import { SettingOutlined } from '@ant-design/icons'
import locale from 'antd/lib/date-picker/locale/pt_BR'
import RangePickerCustom from 'ecp/app/Logs/components/RangePickerCustom/RangePickerCustom'
import { getMultiDatepickerFormat } from './constants'

import dayjs, { Dayjs } from 'dayjs'

export type MultiDatepickerType = 'month' | 'date' | 'year' | 'range'

interface IMultiDatepicker {
  type?: MultiDatepickerType;
  onDateTypeChange: (type: MultiDatepickerType) => void
  onDateChange: (value: dayjs.Dayjs | null, stringDate: string) => void
  onRangeChange: (range: typeof RangePickerCustom.prototype.onChange) => void
  value: () => Dayjs | undefined
  rangeValue: [Dayjs | null, Dayjs | null] | null | undefined
  defaultValue?: Dayjs | undefined
  options?: MultiDatepickerType[]
  className?: string
  disabled: boolean
}

function MultiDatepicker ({
  type = 'date',
  onDateTypeChange,
  onDateChange,
  onRangeChange,
  value,
  rangeValue,
  disabled,
  defaultValue,
  options = ['date', 'month'],
  className
}: IMultiDatepicker) {
  const pickerRef = useRef(null)
  const { md: isScreenMediumOrBigger } = useBreakpoint()

  return (
    <Tooltip
      title='Selecione o ícone de calendário e escolha o mês que deseja ver.'
      placement='left'
    >
      <div className={`multi-datepicker ${className || ''}`} >
        <Dropdown
          disabled={disabled}
          className={disabled ? 'multi-datepicker__disabled' : ''}
          getPopupContainer={() => document.querySelector('.multi-datepicker') || document.body}
          trigger={['click']}
          overlay={(
            <Menu
              selectable={true}
              defaultSelectedKeys={[type]}
              className='multi-datepicker__menu'
            >
              {options.includes('date') && (
                <Menu.Item key='date' onClick={() => onDateTypeChange('date')}>
                  Diário
                </Menu.Item>
              )}
              {options.includes('range') && (
                <Menu.Item key='range' onClick={() => onDateTypeChange('range')}>
                  Intervalo
                </Menu.Item>
              )}
              {options.includes('month') && (
                <Menu.Item key='month' onClick={() => onDateTypeChange('month')}>
                  Mensal
                </Menu.Item>
              )}
              {options.includes('year') && (
                <Menu.Item key='year' onClick={() => onDateTypeChange('year')}>
                  Anual
                </Menu.Item>
              )}
            </Menu>
          )}
        >
          <div className='multi-datepicker__button'>
            <SettingOutlined />
          </div>
        </Dropdown>

        {type === 'range' ? (
          <RangePickerCustom
            onChange={onRangeChange}
            value={rangeValue}
            disabled={disabled}
            locale={locale}
            clearIcon={false}
            format={'DD/MM/YYYY'}
            disabledDate={(current: Dayjs) => current > dayjs()}
          />
        ) : (
          <DatepickerCustom
            locale={locale}
            inputReadOnly={!isScreenMediumOrBigger}
            picker={type}
            ref={pickerRef}
            disabled={disabled}
            className='w-100'
            getPopupContainer={() => document.querySelector('.dashboard__filters--date') || document.body}
            format={getMultiDatepickerFormat({ type, mode: 'display' })}
            disabledDate={(current) => current.isAfter(new Date()) || current.isBefore('2021-01-01')}
            allowClear={false}
            showToday={false}
            value={value()}
            onChange={onDateChange}
            defaultValue={defaultValue}
          />
        )}
      </div>
    </Tooltip>
  )
}

export default MultiDatepicker

import { IProduct } from 'ecp/models/ProductModel'
import { useSelector } from 'react-redux'
import { RootState } from '../store/modules/rootReducer'
import corbans from '../utils/corbans'

const useCorban = () => {
  const corban = useSelector((state: RootState) => state.system.corban)
  return corban
}

const useProposal = () => {
  const proposal = useSelector((state: RootState) => state.proposal)
  return proposal
}

const useSimulation = () => {
  const simulation = useSelector((state: RootState) => state.simulation)
  return simulation
}

const useAuth = () => {
  const auth = useSelector((state: RootState) => state.auth)
  return auth
}

const useStep = () => {
  const step = useSelector((state: RootState) => state.step)
  return step
}

const useResources = () => {
  const resources = useSelector((state: RootState) => state.resources)
  return resources
}

const useHostConfig = () => {
  const host = document.location.hostname
  const config = corbans[host] || corbans.localhost
  return config
}

const useSystem = () => {
  const system = useSelector((state: RootState) => state.system)
  return system
}

const useClient = () => {
  const client = useSelector((state: RootState) => state.client)
  return client
}

const usePMT = () => {
  const pmt = useSelector((state:RootState) => state.pmt)
  return pmt
}

const useDrawer = () => {
  const drawer = useSelector((state:RootState) => state.drawer)
  return drawer
}

const useModal = () => {
  const modal = useSelector((state:RootState) => state.modal)
  return modal
}

const useTables = () => {
  const tables = useSelector((state:RootState) => state.tables)
  return tables
}

const useChat = () => {
  const chat = useSelector((state: RootState) => state.chat)
  return chat
}

const useSelectedProduct = () => {
  return useSelector((state: RootState) => state.auth.selectedProduct as IProduct)
}

const useDynamicProposalStore = () => {
  return useSelector((state: RootState) => state.dynamicProposal)
}

const useDynamicProposalPendingStore = () => {
  return useSelector((state: RootState) => state.dynamicProposalPending)
}

export {
  useCorban,
  useProposal,
  useHostConfig,
  useAuth,
  useStep,
  useResources,
  useClient,
  usePMT,
  useSimulation,
  useDrawer,
  useTables,
  useModal,
  useSystem,
  useChat,
  useSelectedProduct,
  useDynamicProposalStore,
  useDynamicProposalPendingStore
}

import React from 'react'

const trigger: React.CSSProperties = {
  top: -70,
  background: 'none',
  right: 'initial',
  color: 'white'
}

const sider = (isSmall: boolean) => {
  return {
    position: isSmall ? 'absolute' : 'relative',
    height: '100%'

  } as React.CSSProperties
}

const opacity: React.CSSProperties = {
  position: 'absolute',
  background: 'rgba(0, 0, 0, 0.2)',
  height: '100%',
  width: '100%',
  marginBottom: '50',
  zIndex: 10
}

export {
  trigger,
  sider,
  opacity
}

import React, { Fragment, useState } from 'react'
import PromoterCollaboratorsColumns from 'components/UserTable/usersColumns/PromoterCollaboratorsColumns'
import UserTable from 'components/UserTable/UserTable'
import swal from 'utils/swal'
import { useDispatch } from 'react-redux'
import { tablesSetFiltersValues, tablesUpdate } from 'store/modules/tables/actions'
import ButtonModel from 'components/SeverTable/ButtonModel'
import translate from 'utils/translate'
import ModalInvite from 'components/ModalInvite/ModalInvite'
import FilterSwitch from 'components/SeverTable/FilterSwitch'
import { useTables } from 'hooks'
import UsersEgiRepository from 'egi/repositories/UsersEgiRepository'

function PromoterCollaboratorsList () {
  const [visible, setVisible] = useState<boolean>(false)

  const dispatch = useDispatch()
  const tables = useTables()

  async function blockUser (id: string) {
    try {
      const response = await UsersEgiRepository.block(id)
      dispatch(tablesUpdate())
      swal.basic({ title: 'Sucesso!', text: response.data.message, icon: 'success' })
    } catch (err) {
      swal.basic({ title: 'Atenção!', text: err.message, icon: 'warning' })
    }
  }

  const filters = [
    <FilterSwitch
      placeholder="Email verificado: "
      name="hasEmailVerified"
      onChange={(value) => {
        if (value) dispatch(tablesSetFiltersValues({ ...tables.filters, hasEmailVerified: true }))
        else dispatch(tablesSetFiltersValues({ ...tables.filters, hasEmailVerified: undefined }))
      }}
      key='hasEmailVerified'
    />
  ]

  return (
    <Fragment>
      <ModalInvite
        level="promoter"
        visible={visible}
        canChooseType={true}
        invitedLevel="Colaborador"
        onClose={() => setVisible(false)}
      />

      <UserTable
        url="/promoters"
        columns={PromoterCollaboratorsColumns({ blockUser })}
        fileName="parceiros"
        filters={filters}
        buttons={[
          <ButtonModel
            onClick={() => setVisible(true)}
            key={0}
            text={`Convidar usuário ${translate.level('collaborators')}`}
          />
        ]}
      />
    </Fragment>
  )
}

export default PromoterCollaboratorsList

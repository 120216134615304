import { _userLevel } from 'ecp/models/UsersModel'
import IAuth from 'egi/types/IAuth'

const PromoterListModel = {
  canAccessLink: (userLevel: _userLevel) => (['master', 'commercial', 'agx', 'administrator', 'backoffice'].includes(userLevel)),

  canSeePromoterIndicator: (userLevel: _userLevel) => (['master', 'agx'].includes(userLevel)),

  canChangeCommission: (user: IAuth) => (['master', 'commercial'].includes(user.level) || (user.level === 'administrator' && user.areaName === 'comercial')),

  canChangeChanel: (userLevel: _userLevel) => (userLevel === 'master'),

  canChangeManager: (userLevel: _userLevel) => (['master'].includes(userLevel)),

  showCollaboratorsApprove: (row: {status: string, code?: string}, viewerLevel: string) => (!row.code && row.code !== 'approved' && viewerLevel === 'juridico'),

  showCollaboratorsApprovedTag: (row: {status: string, code?: string}) => (row.status === 'approved' && !row.code),

  showCommissionColumn: (viewerLevel: string) => (!['juridico', 'commercial'].includes(viewerLevel)),

  partnersLineRoute: (user: IAuth, document: string): string => {
    if (user.areaName === 'juridico') return `/auth/juridico/partner/${document}/documentation`
    if (user.areaName === 'comercial') return `/auth/commercial/partner/${document}/documentation`
    switch (user.level) {
      case 'commercial':
        return `/auth/commercial/partner/${document}/documentation`
      case 'master' :
        return `/auth/partner/${document}/documentation`
      default: return ''
    }
  }
}

export default PromoterListModel

import React, { Fragment, useEffect, useState } from 'react'
import { FormListFieldData } from 'antd/lib/form/FormList'
import { Checkbox, Col, Form, Input, Row, Typography } from 'antd'
import { MinusCircleOutlined } from '@ant-design/icons'
import { FormInstance } from 'antd/lib/form'

type _signatureLabels = 'assinaturas_emitente' | 'assinaturas_compositor' | 'assinaturas_IQ' | 'assinaturas_rotulo_customizado'

type Props = {
  field: FormListFieldData,
  name: _signatureLabels
  remove(index: number | number[]): void,
  readOnly?: boolean,
  form: FormInstance
  index: number
  parentField?: FormListFieldData
}

function SignaturesFields ({ field, form, name, remove, readOnly, index, parentField }: Props) {
  const fields = form.getFieldValue(name)[field.fieldKey]

  const [hasConjuge, setHasConjuge] = useState<boolean>(false)

  const labels: {[key in _signatureLabels]: string} = {
    assinaturas_IQ: 'Interveniente Quitante',
    assinaturas_compositor: 'Compositor',
    assinaturas_emitente: 'Emitente',
    assinaturas_rotulo_customizado: 'Rótulo customizado'
  }

  useEffect(() => {
    if (parentField) {
      const signerField = form.getFieldsValue()[name]?.[parentField.fieldKey]?.assinaturas?.[field.fieldKey]
      setHasConjuge(signerField?.existe_conjuge)
    }

    if (fields && fields.existe_conjuge) setHasConjuge(true)
  }, [fields])

  return (
    <Fragment>
      <Typography.Paragraph className="bold">
        Assinatura {index + 1} {' '}
        {!readOnly && (
          <MinusCircleOutlined
            onClick={() => remove(field.name)}
            style={{ color: 'red' }}
          />
        )}
      </Typography.Paragraph>

      <Row>
        <Col className="px-1" lg={8} sm={12} xs={24}>
          <Form.Item
            {...field}
            name={[field.name, 'nome']}
            label={<label>Nome do {labels[name]} {index + 1}:</label>}
            fieldKey={[field.fieldKey, 'nome']}
            rules={[
              {
                required: true,
                message: 'O campo nome é obrigatório'
              }
            ]}
            required
          >
            <Input
              name='nome'
              readOnly={readOnly}
              placeholder='Digite o nome do assinante'
            />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={6} sm={12} xs={24}>
          <Form.Item
            {...field}
            name={[field.name, 'existe_conjuge']}
            label={<label></label>}
            fieldKey={[field.fieldKey, 'existe_conjuge']}
            valuePropName="checked"
            className="form-user-checkbox"
          >
            <Checkbox
              id="test-checkbox-property"
              disabled={readOnly}
              onChange={(e) => { setHasConjuge(e.target.checked) }}
            >
              Possui cônjuge?
            </Checkbox>
          </Form.Item>
        </Col>

        {hasConjuge && (
          <Col className="px-1" lg={8} sm={12} xs={24}>
            <Form.Item
              {...field}
              name={[field.name, 'nome_conjuge']}
              label={<label>Nome do cônjuge:</label>}
              fieldKey={[field.fieldKey, 'nome_conjuge']}
              rules={[
                {
                  required: true,
                  message: 'O campo nome do conjugê é obrigatório'
                }
              ]}
            >
              <Input
                name='nome'
                readOnly={readOnly}
                placeholder='Digite o nome do cônjuge'
              />
            </Form.Item>
          </Col>
        )}
      </Row>
    </Fragment>
  )
}

export default SignaturesFields

export const RH_INITIAL_FILTERS = {
  hasEmailVerified: undefined,
  page: 1,
  limit: 15
}

export const RH_FILTERS_ENTRIES_TO_IGNORE: {}[] = [
  { possui_acesso: undefined },
  { status: undefined }
]

export const RH_FILTERS_KEYS_IGNORE = [
  'search',
  'page',
  'limit'
]

export const TRANSLATED_MONTH_NAMES = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

export const REASONS_SELECT_OPTIONS = [
  { value: 'sem-divergencia', label: 'Sem divergência' },
  { value: 'afastado', label: 'Afastado' },
  { value: 'ferias', label: 'Férias' },
  { value: 'fraude', label: 'Fraude' },
  { value: 'invalidez', label: 'Invalidez' },
  { value: 'obito', label: 'Óbito' },
  { value: 'outros', label: 'Outros' },
  { value: 'pensao-alimenticia', label: 'Pensão Alimentícia' }
]

export const RH_CURRENT_ALLOTMENT_TEXT = 'Clique em um de seus convênios abaixo para prosseguir com a visualização do lote atual'

export const RH_PENDING_USER_TEXT = 'Clique em um de seus convênios para prosseguir com a visualização dos funcionários pendentes'

export const RH_PREV_ALLOTMENT_TEXT = 'Clique em um de seus convênios abaixo para prosseguir com a visualização dos lotes anteriores'

export const RH_PERMISSIONS_RESOURCE = [
  { value: 'CEP_CONTRATOS_AVERBAR', label: 'Averbar Contratos de Funcionários' },
  { value: 'CEP_BLOQUEAR_FUNCIONARIO', label: 'Pode realizer bloqueio e desbloqueio de um funcionário' },
  { value: 'CRED_MODULOS_GET', label: 'Pode visualizar todos os módulos da financeira' },
  { value: 'CEP_FUNCIONARIOS_TRANSFERIR', label: 'Pode transferir funcionários' },
  { value: 'CEP_CONTRATOS_GET', label: 'Pode visualizar contratos da empresa' },
  { value: 'CEP_RENEGOCIACOES_GET', label: 'Pode visualizar renegociações da empresas' },
  { value: 'CEP_FUNCIONARIOS_GET', label: 'Visualizar Funcionários' },
  { value: 'ALERTAS_LOTE_FOI_ENVIADO', label: 'Receber alertas de lote enviado' },
  { value: 'CEP_FUNCIONARIO_DEMITIR', label: 'Pode demitir funcionário' },
  { value: 'ALERTAS_FUNCIONARIO_BLOQUEADO_SOLICITANDO_CREDITO', label: 'Receber alertas de solicitação de crédito por usuário bloqueado' },
  { value: 'CEP_RENEGOCIACAO_GET', label: 'Pode visualizar as renegociações da empresa' },
  { value: 'CRED_USUARIOS_EDIT', label: 'Pode gerenciar usuários da financeira' },
  { value: 'CRED_CONTRATOS_METADADOS_EDIT', label: 'Pode editar metadados de um contrato após a criação' },
  { value: 'CEP_FUNCIONARIO_REVERTER_DEMISSAO', label: 'Pode reverter demissão do funcionário' },
  { value: 'CEP_CONVENIO_GET', label: 'Pode visualizar convênios da empresa' },
  { value: 'CEP_LOTES_SUBMIT', label: 'Pode submeter lotes da empresa' },
  { value: 'ALERTAS_LOTE_FOI_FECHADO', label: 'Receber alertas de lote fechado' },
  { value: 'ALERTAS_LOTE_PAGAMENTO_DIVERGENTE', label: 'Receber alertas de pagamento divergente em lote' },
  { value: 'CRED_USUARIOS_LIST', label: 'Pode visualizar usuários' },
  { value: 'CEP_RECEBIVEIS_GET', label: 'Visualizar recebíveis' },
  { value: 'LGPD_CADASTRO_EDIT', label: 'Pode editar cadastros originados via LGPD' },
  { value: 'CRED_USUARIOS_CREATE', label: 'Pode criar usuários' },
  { value: 'CEP_LOTES_GET', label: 'Pode visualizar lotes da empresa' },
  { value: 'CRED_USUARIOS_DELETE', label: 'Pode desabilitar usuários da financeira' },
  { value: 'CEP_EMPRESA_CONFIG', label: 'Visualizar e editar informações de configuração de empresa' },
  { value: 'LGPD_CADASTRO_GET', label: 'Pode visualizar cadastros originados via LGPD' },
  { value: 'CRED_CONTRATOS_CANCELAR_SEGURO', label: 'Pode cancelar o seguro dos recebíveis de um contrato' },
  { value: 'CRED_USUARIOS_ENVIAR_CONVITE', label: 'Pode reenviar convite para gestor da financeira' },
  { value: 'LGPD_CADASTRO_APROVAR', label: 'Pode aprovar cadastros originados via LGPD' },
  { value: 'CRED_PESSOAS_GET', label: 'Visualizar pessoas' },
  { value: 'CEP_FUNCIONARIOS_CREATE', label: 'Criar Funcionários' },
  { value: 'CEP_FUNCIONARIO_CARTA_DE_ACESSO_GEN', label: 'Gerar Carta de Acesso para funcionario' },
  { value: 'CEP_FUNCIONARIOS_EDIT', label: 'Editar Funcionários' },
  { value: 'ALERTAS_CONTRATOS_A_AVERBAR', label: 'Receber alertas de contratos aguardando averbação' },
  { value: 'CEP_LOTES_EDIT', label: 'Pode editar lotes da empresa' },
  { value: 'CEP_CONTRATOS_NEGAR_AVERBACAO', label: 'Negar avebação de Contratos de Funcionários' },
  { value: 'CEP_PORTABILIDADES_AVERBAR', label: 'Pode averbar portabilidades da empresa' },
  { value: 'CEP_CONVENIOS_GET', label: 'Pode visualizar convênios da empresa' },
  { value: 'LGPD_CADASTRO_REJEITAR', label: 'Pode rejeitar cadastros originados via LGPD' },
  { value: 'CRED_PROPOSTAS_METADADOS_EDIT', label: 'Pode editar metadados de uma proposta após a criação' },
  { value: 'CEP_PORTABILIDADES_GET', label: 'Pode visualizar portabilidades da empresa' }
]

export const RH_PERMISSIONS_VALUE = RH_PERMISSIONS_RESOURCE.map(item => (item.value))

import React, { useState } from 'react'
import SignupLayout from 'layouts/SignupLayout/SignupLayout'
import { Col, Form, message } from 'antd'
import { Link, useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { useForm } from 'antd/lib/form/Form'
import { handleOnidataErrors } from 'utils/validate'
import LoginRepository from 'ecp/repositories/LoginRepository'
import { IEcpFormToken, IEcpSignupForm, IResetWithToken } from '../../signupFormInterfaces'
import dayjs from 'dayjs'
import { correctTimezone } from 'utils/time'
import { UNAUTHS_PATHS } from 'routes/unauthRoutes'
import TokenVerification from 'components/TokenVerification/TokenVerification'
import SignupFormResendToken from '../SignupFormResendToken/SignupFormResendToken'
import TokenHeader from '../../../../components/TokenHeader/TokenHeader'
import SignupButton from 'egi/app/Signup/components/SignupButton/SignupButton'

export default function EcpSignupFormToken () {
  const location = useLocation<IEcpSignupForm>()
  const history = useHistory()
  const [form] = useForm<IEcpFormToken>()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Partial<IEcpFormToken>>()

  const queryToken = queryString.parse(history.location.search)

  const handleSubmit = async (values: IEcpFormToken) => {
    setLoading(true)
    try {
      if (!location.state.email_ou_cpf) throw new Error('Falha ao buscar email')

      const {
        email_ou_cpf,
        confirmPassword,
        password,
        foundationDate
      } = location.state

      if (!dayjs(foundationDate).isValid()) throw new Error('Data de fundação inválida')

      const requestBody: IResetWithToken = {
        email_ou_cpf: email_ou_cpf,
        confirmPassword: confirmPassword,
        password: password,
        foundationDate: foundationDate ? correctTimezone(foundationDate) : undefined,
        inviteId: queryToken.inviteToken as string,
        resetToken: values.resetToken
      }

      const response = await LoginRepository.resetWithToken(requestBody)
      history.push(UNAUTHS_PATHS.MAIN)
      message.success(response?.data.message)
    } catch (err) {
      if (err.data?.error) setErrors(handleOnidataErrors(err.data?.error))
      message.error(err?.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <SignupLayout>
      <div className='mb-3'>
        <Link
          className='signup__link'
          to={{
            pathname: UNAUTHS_PATHS.ECP_FORGOT,
            search: history.location.search
          }}
        >
          &larr; Voltar
        </Link>
      </div>

      <TokenHeader
        title='Token enviado com sucesso'
        infoText={
          <p className='ecp-token-header__info-text'>Adicione o token que você recebeu no email <b>{location.state?.email_ou_cpf}</b></p>
        }
        className='mb-5'
      />

      <Form
        onFinish={handleSubmit}
        form={form}
        layout="vertical"
        className='unauth-form'
      >
        <Col span={24}>
          <Form.Item
            name="resetToken"
            label={<label className="simulator-label">Digite o token</label>}
            help={errors?.resetToken && (errors?.resetToken)}
            validateStatus={errors?.resetToken && ('error')}
            rules={[
              {
                required: true,
                message: 'Adicione o token.'
              }
            ]}
          >
            <TokenVerification lenght={6}/>
          </Form.Item>
        </Col>

        <Form.Item>
          <SignupButton
            loading={loading}
            htmlType='submit'
            className='w-100'
          >
            Vincular minha conta
          </SignupButton>
        </Form.Item>
      </Form>

      {location?.state?.email_ou_cpf &&
        <div className='flex flex-justify-center'>
          <SignupFormResendToken
            email={location?.state?.email_ou_cpf}
          />
        </div>
      }
    </SignupLayout>
  )
}

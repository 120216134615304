import { message } from 'antd'
import { IProduct, _productSlugs } from 'ecp/models/ProductModel'
import ProductsRepository from 'ecp/repositories/ProductsRepository'
import { IAuth } from 'egi/types'
import { useAuth, useResources } from 'hooks'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { authActions } from 'store/modules/auth/authActions'
import { getFirstRouter } from 'utils/utils'

export default function useSelectedProducts () {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useAuth()
  const resources = useResources()

  const { _id: productId } = user.selectedProduct || {}

  const goTofirstRoute = (productSlug: _productSlugs, user: IAuth) => {
    const route = getFirstRouter(productSlug, user)
    history.push(route)
  }

  const changeProduct = async (id: string, products: IProduct[]) => {
    try {
      await ProductsRepository.selectProduct(id)
      const product = products.find(product => product._id === id)
      if (!product) throw new Error('Produto não encontrado')
      dispatch(authActions.updateSelectedProduct(product))
      return product
    } catch (err) {
      message.warning(err?.message)
    }
  }

  const onProductSelect = async (id: string, products: IProduct[], user: IAuth) => {
    try {
      const product = await changeProduct(id, products)
      if (product?.slug) goTofirstRoute(product.slug, user)
    } catch (err) {
      message.warning(err?.message)
    }
  }

  const goToPathAndChangeProduct = async (id: string, products: IProduct[], path: string) => {
    try {
      await changeProduct(id, products)
      history.push(path)
    } catch (err) {
      message.warning(err?.message)
    }
  }

  const loading = Boolean(!resources.products?.length || !productId)

  return {
    onProductSelect,
    goToPathAndChangeProduct,
    loading
  }
}

import React, { useLayoutEffect, useState, Fragment, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { ModalInvite, ServerTable } from 'components'
import { ColumnsType } from 'antd/lib/table'
import { user as userConsumer } from 'egi/consumers'
import { resourcesGetAreas } from 'store/modules/resources/actions'
import { tablesSetFiltersValues, tablesUpdate } from 'store/modules/tables/actions'
import columns from './DataTables'
import { _tableAreas } from 'globals'
import { addNameAfterTitle } from 'utils/globals'
import swal from 'utils/swal'
import { useLocation, useParams } from 'react-router-dom'
import { useAuth, useTables } from 'hooks'
import ModalBackofficeAreas from './ModalBackofficeAreas'
import DrawerApproveDocuments from './DrawerApproveDocuments'
import ButtonModel from 'components/SeverTable/ButtonModel'
import filters from 'components/SeverTable/IndexFilters'
import { _typePromoterEnum, _userLevel } from 'ecp/models/UsersModel'
import UsersEgiRepository from 'egi/repositories/UsersEgiRepository'
import AdminModel from 'egi/models/AdminModel'
import InviteTeamAsMaster from 'components/ModalInvite/components/InviteTeamAsMaster'
import { Button, Modal } from 'antd'
import UserTableModel from 'egi/models/UserTableModels'
import { LimiterTable } from 'components/LimiterTable/LimiterTable'

interface IMainList {
  level?: _userLevel
  columns: Function | ColumnsType<Object>
  url: string
  type?: _typePromoterEnum
  area?: _tableAreas
  inviteButtonText?: string
}

function getFinalUrl (url: string, area?: _tableAreas) {
  switch (area) {
    case 'juridico':
      if (url.includes('administrators')) return `${url}?areaName=${area}`
      return url
    case 'telemarketing':
    case 'engenharia':
    case 'credito':
    case 'comercial':
      return `${url}?areaName=${area}`
    default:
      return url
  }
}

function UsersTable ({ level, url, area, type, inviteButtonText }: IMainList) {
  const [finalColumns, setFinalColumns] = useState<Object[]>([])
  const [visible, setVisible] = useState<boolean>(false)
  const [showModalAreas, setShowModalAreas] = useState<boolean>(false)
  const [showDrawerDocuments, setShowDrawerDocuments] = useState<boolean>(false)
  const [isMasterInviteModalOpen, setIsMasterInviteModalOpen] = useState(false)

  const user = useAuth()
  const tables = useTables()
  const dispatch = useDispatch()
  const params: { id: string, name: string } = useParams()
  const { state: locationState } = useLocation<{ area?: string }>()
  const finalUrl = useMemo(() => getFinalUrl(url, area), [url, area])

  const canApproveDocuments = area === 'juridico' || area === 'comercial'
  const IS_PROMOTER_TABLE = inviteButtonText === 'Parceiro'
  const IS_PROVIDER_TABLE = inviteButtonText === 'Prestador'
  const IS_AUDITOR = user.level === 'auditor'
  const IS_COMERCIAL_ADMIN = user.level === 'administrator' && user.areaName === 'comercial'
  const canSeePromoterTypeAndProviderFilter = ['master', 'administrator', 'backoffice'].includes(user.level)
  const masterInviteText = (area?: string) => {
    switch (area) {
      case 'comercial': return 'suporte comercial'
      case 'telemarketing': return 'telemarketing'
      default: return 'backoffice'
    }
  }

  async function blockUser (id: string) {
    try {
      const response = await UsersEgiRepository.block(id)
      dispatch(tablesUpdate())
      setTimeout(() => swal.basic({ title: 'Sucesso!', text: response.data.message, icon: 'success' }), 250)
    } catch (err) {
      setTimeout(() => swal.basic({ title: 'Atenção!', text: err.message, icon: 'warning' }), 250)
    }
  }

  async function canReceiveProposal (id: string) {
    try {
      const response = await userConsumer.receiveProposal(id)
      dispatch(tablesUpdate())
      setTimeout(() => swal.basic({ title: 'Sucesso!', text: response.message, icon: 'success' }), 250)
    } catch (err) {
      setTimeout(() => swal.basic({ title: 'Atenção!', text: err.message, icon: 'warning' }), 250)
    }
  }

  function providerDocuments () {
    setShowDrawerDocuments(true)
  }

  function openModalDrawer () {
    setShowModalAreas(true)
  }

  useLayoutEffect(() => {
    const fnParams: {
      blockUser: Function,
      providerDocuments?: Function,
    } = { blockUser }

    if (canApproveDocuments) {
      fnParams.providerDocuments = providerDocuments
    }

    const data: { [key: string]: ColumnsType<Object> } = {
      backoffice: columns.backoffice({ blockUser, openModalDrawer, canReceiveProposal }),
      client: columns.clients({ blockUser }),
      commission: columns.commission({ blockUser }),
      pricing: columns.pricing({ blockUser }),
      commercial: columns.commercial({ blockUser }),
      administrator: columns.administrator({ blockUser, area }),
      consultant: columns.consultant({ blockUser }),
      telemarketing: columns.telemarketing({ blockUser }),
      provider: columns.provider(fnParams),
      commom: columns.teamColumns({ blockUser, canSeeTransferColumn: AdminModel.canSeeTransferColumn(user.level) }),
      seller: columns.seller({ blockUser }),
      auditor: columns.auditor({ blockUser })
    }

    const column = data[level || area as _tableAreas]

    setFinalColumns(column)
    dispatch(resourcesGetAreas())

    if (params.name) addNameAfterTitle(params.name)
  }, [])

  const promoterExceptTelemarketing = level === 'promoter' && !url.startsWith('/telemarketing')
  const canChooseType = level === 'backoffice' || level === 'provider' || promoterExceptTelemarketing || level === 'auditor'
  const cantInvite = IS_AUDITOR || (IS_COMERCIAL_ADMIN && type === 'TM') || (user.level !== 'promoter' && level === 'seller') || params.id
  const promoterReport = (level === 'promoter' && type !== 'IN') && user.level !== 'promoter'

  function canSeeFilters (): React.ReactElement[] {
    const addFilters: React.ReactElement[] = [
      filters().verifyEmail
    ]

    if (IS_PROMOTER_TABLE && canSeePromoterTypeAndProviderFilter) {
      addFilters.push(
        filters().promoterType,
        filters().promoterStatus,
        filters().promoterChannel,
        filters().currentAction,
        filters().businessManager
      )
    }

    if (IS_PROVIDER_TABLE && canSeePromoterTypeAndProviderFilter) {
      addFilters.push(
        filters().providerDepartment
      )
    }

    return addFilters
  }

  function allButtons (): React.ReactElement[] {
    const addButtons: React.ReactElement[] = []

    if (!cantInvite) {
      addButtons.push(
        <ButtonModel
          onClick={() => setVisible(true)}
          text={`Convidar usuário ${inviteButtonText}`}
          key={0}
        />
      )
    }

    if (UserTableModel.canInviteBackofficeOrSupport(user?.inviteAdmin, locationState?.area)) {
      addButtons.push(
        <ButtonModel
          onClick={() => setIsMasterInviteModalOpen(true)}
          text={`Convidar usuário ${masterInviteText(locationState?.area)}`}
          key={0}
        />
      )
    }

    if (promoterReport) {
      addButtons.push(
        <ButtonModel
          onClick={() => dispatch(tablesSetFiltersValues({ ...tables.filters, download: true }))}
          text={`Relatório de ${inviteButtonText}`}
          key={0}
        />
      )
    }

    return addButtons
  }

  return (
    <Fragment>
      <ModalInvite
        area={area}
        level={level as _userLevel}
        type={type}
        visible={visible}
        onClose={() => setVisible(false)}
        canChooseType={canChooseType}
        invitedLevel={inviteButtonText}
      />

      <Modal
        visible={isMasterInviteModalOpen}
        closable
        footer={[
          <Button
            type="ghost"
            key="cancel"
            onClick={() => setIsMasterInviteModalOpen(false)}
          >
            Cancelar
          </Button>
        ]}
        onCancel={() => setIsMasterInviteModalOpen(false)}
        destroyOnClose
      >
        <InviteTeamAsMaster level={user.level} areaName={locationState?.area}/>
      </Modal>

      <ModalBackofficeAreas
        visible={showModalAreas}
        setVisible={setShowModalAreas}
      />

      <DrawerApproveDocuments
        visible={showDrawerDocuments}
        onClose={() => setShowDrawerDocuments(false)}
      />
      <LimiterTable>
        <ServerTable
          filters={canSeeFilters()}
          csvFilename="parceiros"
          primaryFilter={filters().search}
          secondaryFilter={filters().createdAt}
          columns={finalColumns}
          url={finalUrl}
          buttons={allButtons()}
        />
      </LimiterTable>
    </Fragment>
  )
}

export default UsersTable

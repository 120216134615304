import React from 'react'

import { useTables } from 'hooks'

import { useDispatch } from 'react-redux'
import { tablesSetFiltersValues } from 'store/modules/tables/actions'
import SearchIdentifierInput from './SearchIdentifierInput'

interface IFilterSearchIdentifierInput {
  onChange: (search: string | undefined, identifier: string | undefined) => void
  identifiers: Array<{value: string, label: string}>
  placeholder?: string
  large?: boolean
  label?: string
  borderless?: boolean
  autoFocus?: boolean
  onReset(): void
  onClickSearch?: (search?: string, item?: string) => void
  disabled?: boolean
}

export default function FilterSearchIdentifierInput (props : IFilterSearchIdentifierInput) {
  const { onChange, identifiers, placeholder, large, borderless, label, autoFocus, onReset, disabled, ...rest } = props

  const tables = useTables()
  const dispatch = useDispatch()

  return (
    <SearchIdentifierInput
      identifier={tables.filters.identifier || undefined}
      options={identifiers}
      placeholder={placeholder ?? 'Pesquisar'}
      onChange={(search, item) => onChange(search, item)}
      onClickSearch={(search, item) => onChange(search, item)}
      large={large}
      disabled={disabled}
      borderless={borderless}
      label={label}
      autoFocus={autoFocus}
      onReset={() => {
        dispatch(tablesSetFiltersValues({ ...tables.filters, search: '' }))
        onReset()
      }}
      value={tables.filters?.search}
      {...rest}
    />
  )
}

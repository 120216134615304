
import { DeleteOutlined, PaperClipOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, message, Upload } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import { UploadFile, UploadProps } from 'antd/lib/upload/interface'
import ErrorDetailsECPF, { IErrorDetailsECPF } from 'components/ErrorDetailsECPF/ErrorDetailsECPF'
import { pricingRepository } from 'egi/repositories/NotaryFeesRepository'

import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { tablesUpdate } from 'store/modules/tables/actions'
import { IImportFile } from '../../notaryFeesInterfaces'

interface IProps {
  visible: boolean
  onCancel: () => void
  onImportData: (data: IImportFile | undefined) => void

}

function UploadNotaryFeesModal ({ visible, onCancel, onImportData }: IProps) {
  const [loading, setLoading] = useState<boolean>()
  const [loadingSend, setLoadingSend] = useState<boolean>(false)
  const [file, setFile] = useState<UploadFile>()
  const [error, setError] = useState<IErrorDetailsECPF>()

  const dispatch = useDispatch()

  const uploadNotaryFees = async (file: UploadFile) => {
    setLoadingSend(true)
    setError(undefined)
    try {
      const formData = new FormData()
      formData.append('file', file?.originFileObj as Blob)

      const requestConfig = {
        headers: { 'Content-Type': 'multipart/form-data' }
      }

      const response = await pricingRepository.uploadNotaryFees(formData, requestConfig)

      onImportData(response?.data?.data?.notaryFee)
      message.success(`${file.name} arquivo enviado com sucesso`)

      onCancel()
    } catch (err) {
      const formatedInvalid = err?.data?.invalid?.map((item: {message?: string}) => {
        return {
          mensagem: item?.message || '',
          codigo: ''
        }
      })

      const error = {
        ...err,
        data: {
          erros: formatedInvalid || []
        }
      }

      setError(error)
      message.error(err.message || `Não foi possível enviar o arquivo${file.name}`)
    } finally {
      dispatch(tablesUpdate())
      setLoadingSend(false)
      setFile(undefined)
    }
  }

  const props: UploadProps = {
    name: 'file',
    maxCount: 1,
    showUploadList: false,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange: (info) => {
      const { file } = info
      if (file.status === 'uploading') {
        setLoading(true)
      }
      if ((file.status === 'done' || file.status === 'error') && file.originFileObj) {
        setError(undefined)
        setLoading(false)
        setFile(file)
      }
    },

    beforeUpload: (file) => {
      const isSpreadsheet = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'text/csv'

      if (!isSpreadsheet) {
        message.error(`${file.name} não é um formato válido`)
      }

      return isSpreadsheet
    }
  }

  return (
    <Modal
      title="Fazer upload de custas cartorárias"
      visible={visible}
      destroyOnClose={true}
      onCancel={onCancel}
      footer={
        <div className="text-right m-2">
          <Button type="primary"
            onClick={() => { if (file) uploadNotaryFees(file) }}
            loading={loadingSend}>
            Enviar
          </Button>
          <Button
            type="ghost"
            onClick={onCancel}>
            Cancelar
          </Button>
        </div>
      }
    >
      <Upload {...props}>
        <Button type="primary"
          icon={<UploadOutlined />}
          className="m-1"
          loading={loading}>
          Importar planilha
        </Button>
      </Upload>

      {file && <div className="mt-2">
        <b>Arquivo selecionado:</b>
        <div className="notary-fees__upload-list-item">
          <span>
            <PaperClipOutlined className="pr-1" />
            {file?.name}
          </span>
          <DeleteOutlined
            className="notary-fees__upload-list-item-delete"
            onClick={() => setFile(undefined)} />
        </div>
      </div>
      }

      {error &&
        <ErrorDetailsECPF error={error} title='Ops, parece que algo deu errado'/>
      }
    </Modal>

  )
}

export default UploadNotaryFeesModal

import { AxiosRequestConfig } from 'axios'
import { Repository } from 'ecp/repositories/Repository'
import { ILeadDetails } from 'egi/app/Leads/leadInterfaces'
import { leadTypes } from 'egi/types/IDrawer'
import { _leadReason, _levelNameEnum, _tableAreas } from 'globals'
import api from 'services/api'

type UpdateStatus = {
  status: string
  message: string
}

interface IContact {
  message: string
  type: string
}

export interface ILeadHistory {
  _id: string
  issuer: {
    _id: string
    level: _levelNameEnum
    name: string
    areaName: _tableAreas
  }
  new: {
    status: leadTypes,
    name: string }
  last: { status: leadTypes,
    name: string
  }
  leadId: string
  message: string
  createdAt: string | Date
  updatedAt: string | Date
  declinedReason: _leadReason
}

export interface ITotalLead {
  status: string
  count: number
}

class LeadsRepository extends Repository {
  async updateStatus (leadId: string, body: UpdateStatus, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.patch(`${this.path}/status/${leadId}`, body, config)
    )
  }

  async history (leadId: string, config?: AxiosRequestConfig) {
    return Repository.handle<{ history: ILeadHistory[], name: string }>(() =>
      this.api.get(`${this.path}/history/${leadId}`, config)
    )
  }

  async totals (config?: AxiosRequestConfig) {
    return Repository.handle<{ leads: ITotalLead[] }>(() =>
      this.api.get(`${this.path}/totals`, config)
    )
  }

  async chatContact (leadId: string, data: IContact, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.post(`${this.path}/${leadId}/contact`, data, config)
    )
  }

  async chatHistory (leadId: string, config?: AxiosRequestConfig) {
    return Repository.handle<{ contacts: ILeadHistory[] }>(() =>
      this.api.get(`${this.path}/${leadId}/contact/history`, config)
    )
  }

  async changeTelemarketing (leadId: string, data: { telemarketingId: string }, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.put(`${this.path}/${leadId}/replace/telemarketing`, data, config)
    )
  }

  async changeCommercial (leadId: string, data: { commercialId: string }, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.put(`${this.path}/${leadId}/replace/commercial`, data, config)
    )
  }

  async details (leadId: string, config?: AxiosRequestConfig) {
    return Repository.handle<{ lead: ILeadDetails }>(() =>
      this.api.get(`${this.path}/${leadId}`, config)
    )
  }
}

export default new LeadsRepository({ api, path: '/leads' })

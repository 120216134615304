import SignupRepository from 'egi/repositories/SignupRepository'
import { useCallback, useMemo, useState } from 'react'

export const useSignupCheckVinculatedAccount = () => {
  const [isEmailVinculated, setIsEmailVinculated] = useState(false)

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isCpfVinculated, setIsCpfVinculated] = useState(false)

  const isVinculatedAccount = useMemo(() => {
    return isEmailVinculated === true || isCpfVinculated === true
  }, [isEmailVinculated, isCpfVinculated])

  const onCheckEmail = useCallback(async (email: string) => {
    try {
      setLoading(true)
      const response = await SignupRepository.verifyAssociation({ params: { identifier: (email ?? '').trim() } })
      setIsEmailVinculated(response?.data?.data?.exists || false)
      setErrorMessage('')
    } catch (error) {
      setErrorMessage(error?.message)
    } finally {
      setLoading(false)
    }
  }, [])

  const onCheckCpf = useCallback(async (cpf: string) => {
    try {
      setLoading(true)
      const response = await SignupRepository.verifyAssociation({ params: { identifier: cpf } })
      setIsCpfVinculated(response?.data?.data?.exists || false)
      setErrorMessage('')
    } catch (error) {
      setErrorMessage(error?.message)
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    onCheckCpf,
    onCheckEmail,
    isVinculatedAccount,
    errorMessage
  }
}

import { Skeleton, Tooltip } from 'antd'
import React, { ReactNode } from 'react'

type Props = {
  label: string,
  value: string | ReactNode
  valueColor?: string
  className?: string
  backGroundColor?: string
  alignment?: 'start' | 'center' | 'end'
  loading?: boolean
}

export default function ValueCard ({ label, value, valueColor, className, backGroundColor, alignment, loading }: Props) {
  const style = {
    backgroundColor: backGroundColor,
    textAlign: alignment
  }

  const skaletonClassname = `value-card__skeleton--${alignment || 'center'} w-100`

  if (loading) {
    return (
      <div style={style} className={`value-card__info-card ${className || ''}`}>
        <Tooltip title={label} destroyTooltipOnHide={{ keepParent: false }}>
          <p className='value-card__info-label text-ellipsis'>{label}</p>
        </Tooltip>
        <Skeleton className={skaletonClassname} active paragraph={{ rows: 0 }}/>
      </div>
    )
  }

  return (
    <div style={style} className={`value-card__info-card ${className || ''}`}>
      <Tooltip title={label} destroyTooltipOnHide={{ keepParent: false }}>
        <p className='value-card__info-label text-ellipsis'>{label}</p>
      </Tooltip>

      {typeof value === 'string' ? <p style={{ color: valueColor }} className='value-card__info-value'>{value}</p> : value}
    </div>
  )
}


import { Button, message } from 'antd'

import { swalError } from 'components/SwalError/SwalError'

import { IEmailAgeRequest, IUnicoRequest } from 'ecp/app/Proposals/proposalInterfaces'

import RodobensKYCRepository from 'ecpf/repositories/RodobensKYCRepository'
import React, { useState } from 'react'

import { ProposalStepContent } from '../ProposalSteps/ProposalSteps'

interface IProps {
  fetchDocumentalProcess: () => void
  resendUnicoAcertPixInfo?: IUnicoRequest
  resendEmailAgeInfo?: IEmailAgeRequest
  proposalId?: string
}

export function ResendDocumental ({ fetchDocumentalProcess, resendUnicoAcertPixInfo, resendEmailAgeInfo, proposalId }: IProps) {
  const initialLoading = {
    emailAge: false,
    unicoAcertPix: false
  }

  const [loading, setLoading] = useState<{ [key in 'emailAge' | 'unicoAcertPix']: boolean }>(initialLoading)

  const resendUnicoAcertPix = async (body: IUnicoRequest) => {
    setLoading((prev) => ({ ...prev, unicoAcertPix: true }))
    try {
      if (!proposalId) throw new Error('Falha ao buscar proposta.')
      await RodobensKYCRepository.unico(proposalId, body)
      message.success('Enviado com sucesso')
    } catch (err) {
      swalError({ err })
    } finally {
      fetchDocumentalProcess()
      setLoading(initialLoading)
    }
  }

  const resendEmailAge = async (data: IEmailAgeRequest) => {
    setLoading((prev) => ({ ...prev, emailAge: true }))
    try {
      if (!proposalId) throw new Error('Falha ao buscar proposta.')
      await RodobensKYCRepository.emailAge(proposalId, data)
      message.success('Enviado com sucesso')
    } catch (err) {
      swalError({ err })
    } finally {
      fetchDocumentalProcess()
      setLoading(initialLoading)
    }
  }

  return (
    <div className="resend-documental">
      <ProposalStepContent.Subtitle>
        Reenvio dos processos
      </ProposalStepContent.Subtitle>

      <ProposalStepContent.Description className='mb-0'>
        Clique nos botões de reenviar, e faça o reenvio do processo para o cliente.
      </ProposalStepContent.Description>

      <ProposalStepContent.Description>
        Obs: fazer o reenvio do <b>Único</b> e do <b>AcertPix</b>, causará o reenvio de <b>ambos</b> os processos
      </ProposalStepContent.Description>

      <div>
        <Button type="link"
          onClick={() => resendUnicoAcertPixInfo && resendUnicoAcertPix(resendUnicoAcertPixInfo)}
          loading={loading.unicoAcertPix}
          className="link-style color-tertiary font-500 resend-documental__link"
        >
          Reenviar Único e AcertPix
        </Button>
      </div>
      <div>
        <Button
          type="link"
          className="link-style color-tertiary font-500 resend-documental__link"
          onClick={() => resendEmailAgeInfo && resendEmailAge(resendEmailAgeInfo)}
          loading={loading.emailAge}>
          Reenviar EmailAge
        </Button>
      </div>
    </div>
  )
}

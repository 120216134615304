import { message } from 'antd'
import TokensValidationRepository from 'ecp/repositories/TokensValidationRepository'
import { TokenValidationMessageTemplateType } from 'ecpf/models/TokenValidationModel'
import format from 'utils/format'
import { TokenMethodEnum } from '../components/TokenValidator/TokenValidator'

export class TokenCPFNotFound extends Error {
  name = 'CPF_NOT_FOUND'
  message = 'CPF não encontrado'
}

export class TokenMethodNotSent extends Error {
  name = 'TOKEN_METHOD_NOT_SENT'
  message = 'Método de reenvio do token não enviado'
}

export class TokenService {
  async submit (token: string, contactInfo: string, method: TokenMethodEnum | null) {
    const body = {
      token: token,
      email: method === TokenMethodEnum.email ? contactInfo : undefined,
      cellphone: method === TokenMethodEnum.sms ? format.onlyDigits(contactInfo) : undefined
    }

    return TokensValidationRepository.validate(body)
  }

  async resend (contactInfo: string, method: TokenMethodEnum | null, messageTemplate: TokenValidationMessageTemplateType) {
    if (!method || !contactInfo) throw new TokenMethodNotSent()

    const body = {
      methodType: method,
      email: method === TokenMethodEnum.email ? contactInfo : undefined,
      cellphone: method === TokenMethodEnum.sms ? format.onlyDigits(contactInfo) : undefined,
      messageTemplateType: messageTemplate
    }

    const response = await TokensValidationRepository.resend(body)
    message.success(response.data?.message)

    return response
  }
}

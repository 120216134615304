import React from 'react'
import { Tabs } from 'antd'

import PersonalityFieldsDisplay from 'ecp/app/Clients/components/ClientFieldsDisplay/PersonalityFieldsDisplay'
import DocumentsFieldsDisplay from 'ecp/app/Clients/components/ClientFieldsDisplay/DocumentsFieldsDisplay'
import AddressFieldsDisplay from 'ecp/app/Clients/components/ClientFieldsDisplay/AddressFieldsDisplay'
import ContactFieldsDisplay from 'ecp/app/Clients/components/ClientFieldsDisplay/ContactFieldsDisplay'
import ContactReferenceFieldsDisplay from 'ecp/app/Clients/components/ClientFieldsDisplay/ContactReferenceFieldsDisplay'
import PaymentFieldsDisplay from 'ecp/app/Clients/components/ClientFieldsDisplay/PaymentFieldsDisplay'
import { IOnidataClient } from 'ecp/models/ClientModel'

function ClientProfile ({ data }: { data: IOnidataClient }) {
  return (
    <div className='client-form__fields'>
      <Tabs>
        <Tabs.TabPane
          key='1'
          tab={<h3 className='color-primary'>Dados Pessoais</h3>}
        >
          <PersonalityFieldsDisplay
            personalityInfos={data}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          key='2'
          tab={<h3 className='color-primary'>Documento</h3>}
        >
          <DocumentsFieldsDisplay
            documentsInfos={data}
          />
        </Tabs.TabPane>

        <Tabs.TabPane
          key='3'
          tab={<h3 className='color-primary'>Endereço</h3>}
        >
          <AddressFieldsDisplay
            addressInfos={data}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          key='4'
          tab={<h3 className='color-primary'>Pagamento</h3>}
        >
          <PaymentFieldsDisplay
            paymentInfos={data}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={<h3 className='color-primary'>Contato</h3>}
          key='5'
        >
          <ContactFieldsDisplay
            contactInfos={data}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={<h3 className='color-primary'>Contato para referência</h3>}
          key='6'
        >
          <ContactReferenceFieldsDisplay
            contactReferenceInfos={data}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

export { ClientProfile }

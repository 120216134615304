import { compose, createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import { persistStore } from 'redux-persist'

import 'regenerator-runtime/runtime'

import rootSaga from './modules/rootSaga'
import createRootReducer from './modules/rootReducer'

import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = (
  typeof window !== 'undefined' &&
  (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' as any] as any) &&
  (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' as any] as any)({ trace: true, traceLimit: 25 })
) || compose

const store = createStore(createRootReducer(history), composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware)))
export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)
export default store

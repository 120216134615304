import { BulbOutlined, SolutionOutlined } from '@ant-design/icons'
import { RouteGroup } from 'components/Sidebar/types'
import { _tableAreas } from 'globals'
import { ecpRoutes } from 'routes/ecp/ecpRoutes'

import {
  internReviewSettings,
  operationalLimit,
  attorneys,
  witnessess,
  leads,
  promoterClients,
  createAttorney,
  getOneAttorney,
  getOneWitness,
  createWitness,
  clientList,

  analiseCreditoTeam,
  juridicoTeam,
  engenhariaTeam,
  comercialTeam,
  telemarketingTeam,

  chat,
  consultants,
  dashboard,
  proposalsTelemarketing,

  proposalsHistory,
  proposalCheck,
  leadsHistory,
  pricingCommissionTable,
  precificacaoEdit,
  allInvites,
  qualification,
  userDetails,
  teamPromoterIn,
  leadDetails,
  proposalsListTwo,
  proposalsTelemarketingTwo,
  commercialClients,
  proposalsAdminCommercial,
  lgpdTerms,
  bacenTerms,
  leadsMenu,
  proposalsAdminCommercialTwo,
  proposalsListSearch,
  settingsMenu
} from '../routes'

const telemarketing: RouteGroup = {
  name: 'Telemarketing',
  icon: BulbOutlined,
  routes: [
    telemarketingTeam.leaders,
    telemarketingTeam.collaborators
  ]
}

const proposals = {
  name: 'Propostas',
  icon: SolutionOutlined,
  routes: [
    proposalsListSearch,
    proposalsListTwo
  ]
}

const adminRoutes = (name?: _tableAreas) => {
  const routes = [
    dashboard,
    proposalsHistory,
    proposalCheck
  ]

  if (name === 'telemarketing') {
    return [
      ...routes,
      proposalsTelemarketing,
      proposalsTelemarketingTwo,
      leads,
      leadsHistory,
      leadDetails,
      telemarketingTeam.collaborators,
      promoterClients,
      chat,
      userDetails,
      clientList,
      lgpdTerms,
      bacenTerms
    ]
  }

  switch (name) {
    case 'juridico':
      return [
        ...routes,
        proposals,
        qualification,
        juridicoTeam.collaborators,
        attorneys,
        witnessess,
        createAttorney,
        createWitness,
        internReviewSettings,
        getOneAttorney,
        getOneWitness,
        juridicoTeam.providers,
        juridicoTeam.logsZip,
        juridicoTeam.promoters,
        juridicoTeam.telemarketing,
        juridicoTeam.partnersLine,
        ecpRoutes.ecpLogsMenu,
        allInvites,
        chat,
        settingsMenu,
        userDetails,
        clientList,
        teamPromoterIn
      ]
    case 'credito':
      return [
        ...routes,
        proposals,
        operationalLimit,
        analiseCreditoTeam.collaborators,
        chat,
        settingsMenu,
        userDetails,
        clientList
      ]
    case 'engenharia':
      return [
        ...routes,
        proposals,
        engenhariaTeam.collaborators,
        engenhariaTeam.providers,
        chat,
        userDetails,
        clientList
      ]
    case 'comercial': {
      return [
        ...routes,
        proposalsAdminCommercial,
        proposalsAdminCommercialTwo,
        leadsMenu,
        leadDetails,
        leadsHistory,
        commercialClients,
        comercialTeam.collaborators,
        consultants,
        comercialTeam.promoters,
        comercialTeam.partnersLine,
        telemarketing,
        pricingCommissionTable,
        precificacaoEdit,
        allInvites,
        chat,
        userDetails,
        clientList
      ]
    }
    default:
      return routes
  }
}

export default adminRoutes

import tinycolor from 'tinycolor2'
import { IColors } from 'egi/types'
import { _ecpParcelAntecipationStatus } from 'ecp/app/ParcelsAntecipation/parcelsAntecipationInterfaces'
import { _rodobensKYCAPI } from 'ecp/app/Proposals/proposalInterfaces'
import { duePaymentStatusEnum } from 'ecpf/app/ProposalECPF/ProposalECPFInterfaces'
import { TRANSLATE_REPROVED_APPROVED_STATUS } from 'ecp/app/Proposals/proposalConstants'

export declare type _listType =
  'client' |
  'proposal' |
  'covenant' |
  'contract' |
  'portability' |
  'payment' |
  'logErrorStatus' |
  'logErrorMethods' |
  'endorsement' |
  'reportStatus' |
  'onidataLogs' |
  'rodobensKYC' |
  'proposalStepLabel' |
  'fileStatus' |
  'dueStatus'

const createVar = (variable: string): string => `var(${variable})`
const defaultStatusColor = createVar('--status-default')

export const colors: IColors = {
  starting: '#CACACA',
  remaking: '#FF7A40',
  primary: '#00441F',
  canceled: '#949292',
  approved: '#00CF7B',
  reservation: '#00CF7B',
  analysis: '#00A8E2',
  inProgress: '#00A8E2',
  ongoing: '#00A8E2',
  waiting: '#00A8E2',
  reproved: '#DD4E4E',
  updated: '#FFBF00',
  inactive: '#949292',
  closed: '#5BC851',
  effectivated: '#000'
}

export const RGBleadsColors: { [key: string]: string } = {
  ongoing: '#FF7A40',
  approved: '#00CF7B',
  awaiting: '#00A8E2',
  refused: '#DD4E4E',
  failed: '#cecece'
}

export const parcelsAntecipationStatusColor: { [key in _ecpParcelAntecipationStatus]: string} = {
  Pago: '#00CF7B',
  Pendente: '#FFB26A'
}

export function getTextColor (color?: string): '#000' | '#fff' {
  if (!color) return '#000'
  const colorSpecs = tinycolor(color)
  return colorSpecs.isLight() ? '#000' : '#fff'
}

export const varColors: {[key: string]: string} = {
  primary: 'var(--primary-status-color)',
  ongoing: 'var(--analysis-status-color)',
  approved: 'var(--approved-status-color)',
  reservation: 'var(--approved-status-color)',
  reproved: 'var(--refused-status-color)',
  analysis: 'var(--analysis-status-color)',
  remaking: 'var(--remaking-status-color)',
  canceled: 'var(--canceled-status-color)',
  inactive: 'var(--canceled-status-color)',
  waiting: 'var(--analysis-status-color)',
  track: colors.canceled,
  starting: colors.canceled,
  effectivated: colors.effectivated
}

export const partnersStatusColor: {[key: string]: string} = {
  starting: colors.canceled,
  analysis: 'var(--analysis-status-color)',
  pendencies: 'var(--remaking-status-color)',
  signature: 'var(--primary-color)',
  reproved: 'var(--refused-status-color)',
  approved: 'var(--approved-status-color)',
  blocked: 'var(--refused-status-color)',
  canceled: 'var(--refused-status-color)',
  primary: 'var(--primary-status-color)',
  ongoing: 'var(--analysis-status-color)',
  track: colors.canceled,
  remaking: 'var(--remaking-status-color)'
}

export const leadsColorsRgb: {
  [key: string]: string
  ongoing: string,
  failed: string,
  awaiting: string,
  refused: string,
  approved: string
} = {
  ongoing: colors.remaking,
  failed: colors.canceled,
  awaiting: colors.ongoing,
  refused: colors.reproved,
  approved: colors.approved
}

export const leadsColor: { [key: string]: string } = {
  ongoing: 'var(--remaking-status-color)',
  approved: 'var(--approved-status-color)',
  awaiting: 'var(--analysis-status-color)',
  refused: 'var(--refused-status-color)',
  failed: colors.canceled
}

export const proposalStatusColor: {[key: string]: string} = {
  approved: '#2CD701',
  ongoing: '#CACACA',
  reproved: '#D70101'
}

export const avaliationsColors: {[key: string]: string} = {
  'valor-declarado': '#DBEFFE',
  'valor-de-liquidacao-forcada': '#A3D4FA',
  'valor-de-mercado': '#77BEF5',
  'valor-de-credito-aprovado': '#51A9EC',
  'valor-de-credito': '#ABABFB',
  'valor-avaliacao': '#8383F7',
  'valor-area-averbada': '#22A4FF'
}

export const analysisColors: {[key: string]: string} = {
  review: 'var(--remaking-status-color)',
  analysis: 'var(--analysis-status-color)',
  starting: colors.canceled
}

export interface ICovenantColors {
  aprovado: string
  negado: string
  'aguardando-analise': string
  inativo: string
}

function clientColors (value:string): string {
  const colorName: {[key: string]: string} = {
    aprovado: createVar('--status-client-ativo'),
    desligado: '#D70101',
    invalidez: defaultStatusColor,
    obito: defaultStatusColor,
    afastado: defaultStatusColor,
    default: defaultStatusColor,
    ativo: createVar('--status-client-ativo'),
    bloqueado: createVar('--refused-status-color'),
    'primeiro-acesso': defaultStatusColor
  }

  return colorName[value] || colorName.default
}

function covenantColors (value:string): string {
  const colorName: {[key: string]: string} = {
    'aguardando-analise': createVar('--analysis-status-color'),
    negado: createVar('--refused-status-color'),
    aprovado: createVar('--approved-status-color'),
    inativo: colors.canceled,
    ted: createVar('--payment-type-ted'),
    boleto: createVar('--payment-type-boleto'),
    default: defaultStatusColor
  }

  return colorName[value] || colorName.default
}

function proposalColors (value:string): string {
  const colorName: { [key: string]: string } = {
    Aprovado: colors.approved,
    Cancelado: colors.canceled,
    'Em Análise': colors.analysis,
    Reprovada: colors.reproved,
    Refazer: colors.remaking,
    'Em Andamento': colors.analysis,
    Efetivado: colors.approved,
    Expirado: colors.canceled,
    Encerrado: colors.closed,
    Finalizado: colors.closed,
    Recusado: colors.reproved,
    Estornado: colors.canceled,
    'Erro Crivo': colors.reproved,
    'Pré-Formalização': '#6C63FF',
    Pendências: colors.remaking,
    'Biometria Não Enviada': '#E0643D',
    Andamento: colors.analysis,
    Pendente: colors.remaking,
    Liberada: colors.approved,
    Aprovada: colors.approved
  }

  return colorName[value] || colorName.default
}

export function stepProposalLabelColor (value: string): string {
  const colorName: { [key: string]: string } = {
    Aprovado: colors.approved,
    'Em Andamento': colors.inProgress,
    'Em Análise': colors.inProgress,
    Refazer: colors.remaking,
    Cancelada: colors.canceled,
    Reprovada: colors.reproved,
    'Aprovado manualmente': colors.approved,
    'Aprovado automaticamente': colors.approved,
    'Aprovado com ressalvas': colors.approved
  }

  return colorName[value] || colorName.default
}

function proposalLabelColors (value: string): string {
  const colors: {[key: string]: string} = {
    [TRANSLATE_REPROVED_APPROVED_STATUS.approvedManually]: '#7F57B2',
    [TRANSLATE_REPROVED_APPROVED_STATUS.approvedAutomatically]: '#6178CC',
    [TRANSLATE_REPROVED_APPROVED_STATUS.approvedWithRestriction]: '#FFB26A',
    [TRANSLATE_REPROVED_APPROVED_STATUS.reprovedAutomatically]: 'var(--red-status)'
  }
  return colors[value] || colors.default
}

function contractColors (value: string): string {
  const colorName: {[key: string]: string} = {
    efetivado: createVar('--approved-status-color'),
    'mesa-credito': defaultStatusColor,
    negada: createVar('--refused-status-color'),
    rascunho: defaultStatusColor,
    'analise-credito': defaultStatusColor,
    cancelado: colors.canceled,
    erro: defaultStatusColor,
    assinatura: colors.analysis,
    default: defaultStatusColor
  }

  return colorName[value] || colorName.default
}

function portabilityStatusColors (value: string): string {
  const colors: {[key: string]: string} = {
    assinada: createVar('--approved-status-color'),
    cancelada: createVar('--refused-status-color'),
    default: defaultStatusColor
  }

  return colors[value] || colors.default
}

function paymentStatusColors (value: string): string {
  const colors: {[key: string]: string} = {
    Pago: createVar('--approved-status-color'),
    Pendente: createVar('--analysis-status-color'),
    default: defaultStatusColor
  }

  return colors[value] || colors.default
}

function reportsStatus (value: string): string {
  const colors: {[key: string]: string} = {
    Finalizado: createVar('--approved-status-color'),
    Erro: createVar('--refused-status-color'),
    'Em andamento': createVar('--analysis-status-color'),
    Aguardando: createVar('--orange-status'),

    default: defaultStatusColor
  }

  return colors[value] || colors.default
}

function logErrorStatus (value: string): string {
  const colorsName: {[key: string]: string} = {
    200: createVar('--approved-status-color'),
    400: createVar('--refused-status-color'),
    401: createVar('--refused-status-color'),
    403: createVar('--refused-status-color'),
    404: createVar('--refused-status-color'),
    405: createVar('--refused-status-color'),
    500: colors.canceled,
    502: colors.canceled,
    503: colors.canceled,

    default: defaultStatusColor
  }

  return colorsName[value] || colorsName.default
}

function logErrorMethods (value: string): string {
  const colors: {[key: string]: string} = {
    GET: createVar('--approved-status-color'),
    PUT: '#949292',
    POST: '#00A8E2',
    DELETE: '#FF7A40',
    PATCH: defaultStatusColor,

    default: defaultStatusColor
  }

  return colors[value] || colors.default
}
function endorsementStatusColors (value: string): string {
  const endorsementColors: {[key: string]: string} = {
    'Aguardando Requisitos': createVar('--analysis-status-color'),
    Averbado: createVar('--approved-status-color'),
    Negada: createVar('--refused-status-color'),
    Aguardando: 'var(--orange-status)',
    Expirado: colors.canceled,
    Cancelado: colors.canceled,
    default: defaultStatusColor
  }

  return endorsementColors[value] || endorsementColors.default
}

export function hasInsuranceColors (hasInsurance: boolean): string {
  if (hasInsurance) return createVar('--analysis-status-color')
  return colors.canceled
}

export function onidataLogsColors (value: string) {
  const endorsementColors: {[key: string]: string} = {
    success: colors.approved,
    error: colors.reproved,
    failed: colors.reproved,
    default: colors.updated
  }

  return endorsementColors[value] || endorsementColors.default
}

export function rodobensKYC (value: _rodobensKYCAPI) {
  const colors: {[key in _rodobensKYCAPI]: string} = {
    'Email Age': '#7F57B2',
    Único: createVar('--orange-status'),
    'AcertPix GED': createVar('--analysis-status-color')
  }

  return colors[value]
}

function dueStatusColors (value: duePaymentStatusEnum): string {
  const dueStatusColors: {[key in duePaymentStatusEnum]: string} = {
    Pago: colors.primary,
    Pendente: colors.remaking,
    Atrasado: colors.reproved,
    NaoDefinido: colors.canceled
  }

  return dueStatusColors[value]
}

export function getColor (value: string, list: _listType): string {
  switch (list) {
    case 'client': return clientColors(value)
    case 'covenant': return covenantColors(value)
    case 'proposal': return proposalColors(value)
    case 'contract': return contractColors(value)
    case 'portability': return portabilityStatusColors(value)
    case 'payment': return paymentStatusColors(value)
    case 'reportStatus': return reportsStatus(value)
    case 'logErrorStatus': return logErrorStatus(value)
    case 'logErrorMethods': return logErrorMethods(value)
    case 'endorsement': return endorsementStatusColors(value)
    case 'onidataLogs': return onidataLogsColors(value)
    case 'rodobensKYC': return rodobensKYC(value as _rodobensKYCAPI)
    case 'proposalStepLabel': return proposalLabelColors(value as _rodobensKYCAPI)
    case 'dueStatus': return dueStatusColors(value as duePaymentStatusEnum)
    default:
      return defaultStatusColor
  }
}

import { MailOutlined, WhatsAppOutlined } from '@ant-design/icons'
import { Button, Divider, Empty, Row, Skeleton, Tag, Timeline, Typography } from 'antd'
import InvitedsRepository, { ILinksHistoriesResponse } from 'egi/repositories/InvitedsRepository'
import { useAuth, useModal } from 'hooks'
import React, { Fragment, useLayoutEffect, useState } from 'react'
import format from 'utils/format'
import swal from 'utils/swal'
import { timeAsDayjs } from 'utils/time'
import translate from 'utils/translate'

function ResendLink () {
  const [histories, setHistories] = useState<ILinksHistoriesResponse[]>([])
  const [failed, setFailed] = useState<boolean>(false)
  const [loadingHistory, setLoadingHistory] = useState<boolean>(false)
  const modal = useModal()
  const user = useAuth()
  const IS_AUDITOR = user.level === 'auditor'

  async function resendHistory () {
    setLoadingHistory(true)
    try {
      const response = await InvitedsRepository.resendLinkHistory(String(modal.resendLinkUser?.id))
      const links = response.data.data?.links || []

      if (links.length < 1) setFailed(true)
      else setHistories(links)
      setLoadingHistory(false)
    } catch (err) {
      setFailed(true)
      setLoadingHistory(false)
      console.error(err)
    }
  }

  async function generateLink (email: boolean) {
    if (!modal.resendLinkUser?.id) return

    try {
      const body = {
        id: String(modal.resendLinkUser?.id),
        environment: email ? 'email' : 'whatsapp'
      }

      const response = await InvitedsRepository.generatedLink(body)
      const { link } = response.data.data || {}
      setFailed(false)
      resendHistory()

      const whatsMessage = `https://api.whatsapp.com/send?1=pt_br&phone=55${modal.resendLinkUser?.phone}&text=Segue o link para enviar seus documentos de *${translate.level(String(modal.resendLinkUser?.level))}* - ${encodeURIComponent(link || '')}`

      if (email) swal.basic({ icon: 'success', title: 'Sucesso!', text: response.data?.message })
      else window.open(whatsMessage)

      return response
    } catch (err) {
      swal.basic({ icon: 'warning', title: 'Atenção', text: err.message })
    }
  }

  useLayoutEffect(() => {
    resendHistory()
  }, [])

  return (
    <Fragment>
      <Row align="middle" justify="center" className="mb-5">
        <Typography.Paragraph className="mb-0 uppercase color-primary resendLink-title font-500">Reenviar link por:</Typography.Paragraph>
        <Button
          type="primary"
          className="mx-2"
          icon={<MailOutlined/>}
          onClick={() => generateLink(true)}
          disabled={IS_AUDITOR}
        >
          Email
        </Button>

        <Button
          type="primary"
          icon={<WhatsAppOutlined/>}
          onClick={() => generateLink(false)}
          disabled={IS_AUDITOR}
        >
          WhatsApp
        </Button>
      </Row>

      <Row align="middle" justify="center" className="mb-3">
        <Typography.Paragraph className="mb-0 uppercase color-primary font-500">Histórico de reenvio</Typography.Paragraph>
        <Divider className="mt-0"/>
        {failed ? (
          <Empty description="Nenhum histórico encontrado." />
        ) : (
          <Fragment>
            {loadingHistory ? (
              <Skeleton active/>
            ) : (
              <Timeline
                mode="alternate"
                className="w-100"
              >
                {histories.map((items) => (
                  <Timeline.Item key={items._id}>
                    <h4 className="uppercase color-primary">{items.modifiedBy.name} - {translate.level(items.modifiedBy.level)}</h4>
                    <h4 className="uppercase color-primary mb-0">Enviado em: {timeAsDayjs(items.createdAt).format('DD/MM/YYYY - HH:mm')}</h4>
                    {items.environment && (
                      <h4 className="uppercase color-primary">Enviado por: <b>{format.capitalize(items.environment)}</b></h4>
                    )}

                    {items.link && (
                      <Fragment>
                        <Tag color="blue" className="text-center color-primary mb-3 mt-3 p-2 unset-white-space">
                          <h3 className="mb-0">
                            <Typography.Link
                              copyable={true}
                              target="_blank"
                              className="link-style"
                              rel="noreferrer"
                              href={items.link}
                            >
                              {items.link}
                            </Typography.Link>
                          </h3>
                        </Tag>
                      </Fragment>
                    )}
                  </Timeline.Item>
                ))}
              </Timeline>
            )}
          </Fragment>
        )}
      </Row>
    </Fragment>
  )
}

export default ResendLink

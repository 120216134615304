import React from 'react'
import { Typography } from 'antd'
import UnauthLayout from 'layouts/UnauthLayout/UnauthLayout'
import LGPDText from '../../components/LGPDModalText/LGPDModalText'

function LGPD () {
  return (
    <UnauthLayout>
      <div className="unauth-form-border">
        <Typography.Paragraph className="lgpd-page">
          <LGPDText/>
        </Typography.Paragraph>
      </div>
    </UnauthLayout>
  )
}
export default LGPD

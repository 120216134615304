import Modal from 'components/Modal/Modal'
import ProductModel from 'ecp/models/ProductModel'
import { useAuth, useSelectedProduct } from 'hooks'
import React from 'react'
import { useDispatch } from 'react-redux'
import { authActions } from 'store/modules/auth/authActions'
import UnicoCommunication from '../../assets/img/unico-communication.png'

export const CommunicationUnicoModal = () => {
  const auth = useAuth()
  const product = useSelectedProduct()
  const dispatch = useDispatch()

  const isVisible = auth.communicationVisible

  if (!ProductModel.canSeeUnicoCommunication(product)) {
    return null
  }

  return (
    <Modal
      className='communication-unico-modal'
      title={<h4 className='communication-unico-modal__title'>Banco Comunica 📢</h4>}
      visible={isVisible}
      onCancel={() => dispatch(authActions.updateCommunicationVisible(false))}
      footer={null}
    >
      <img
        className='communication-unico-modal__img'
        src={UnicoCommunication}
      />
    </Modal>
  )
}

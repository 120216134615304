import React, { useState } from 'react'
import { Checkbox, Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { IError } from 'ecp/app/Proposals/proposalInterfaces'
import SignupLayout from 'layouts/SignupLayout/SignupLayout'
import LoginRepository from 'ecp/repositories/LoginRepository'
import { handleOnidataErrors } from 'utils/validate'
import { UNAUTHS_PATHS } from 'routes/unauthRoutes'
import { Input } from 'components'
import { useHistory } from 'react-router-dom'
import format from 'utils/format'
import useTokenInfo from '../../hooks/useTokenInfo'
import { ISignupClientRequestPhoneToken, ISignupTokenInfo } from '../../signUpClientInterfaces'
import SignupButton from 'egi/app/Signup/components/SignupButton/SignupButton'
import { SignupTitle } from 'egi/app/Signup/components/SignupTitle/SignupTitle'
import { swalError } from 'components/SwalError/SwalError'

function SignupClientPhone () {
  const [form] = useForm<ISignupClientRequestPhoneToken>()
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [errors, setErrors] = useState<IError<ISignupClientRequestPhoneToken>>({})

  const { tokenInfo } = useTokenInfo()
  const history = useHistory()

  function redirectToNextStep (tokenInfo: ISignupTokenInfo) {
    history.push({
      pathname: `${UNAUTHS_PATHS.SIGNUP_CLIENT_PHONE_TOKEN}`,
      state: tokenInfo
    })
  }

  async function handleSubmit (values: ISignupClientRequestPhoneToken) {
    setLoadingSubmit(true)
    const requestPhoneData: ISignupClientRequestPhoneToken = {
      ...values,
      onidataToken: tokenInfo.jwtToken as string
    }
    if (requestPhoneData.telefone_celular) format.onlyDigits(requestPhoneData.telefone_celular)

    try {
      await LoginRepository.requestPhoneToken(requestPhoneData)

      const updatedTokenInfo: ISignupTokenInfo = {
        ...tokenInfo,
        cellphone: requestPhoneData.telefone_celular
      }

      redirectToNextStep(updatedTokenInfo)
    } catch (err) {
      if (err.data?.error) {
        console.error(err)
        setErrors(handleOnidataErrors(err.data?.error))
      }

      swalError({ err })
    } finally {
      setLoadingSubmit(false)
    }
  }

  return (
    <SignupLayout>
      <SignupTitle>
        Confirmar celular
      </SignupTitle>

      <Form
        onFinish={handleSubmit}
        form={form}
        layout="vertical"
        className='unauth-form'
      >
        <Form.Item
          name='telefone_celular'
          label='Celular'
          help={errors?.telefone_celular}
          validateStatus={errors?.telefone_celular && ('error')}
        >
          <Input
            placeholder='Insira o celular'
            mask='cell'
          />
        </Form.Item>
        <Form.Item
          name='celular_eh_whatsapp'
          help={errors?.celular_eh_whatsapp}
          validateStatus={errors?.celular_eh_whatsapp && ('error')}
          valuePropName='checked'
          initialValue={false}
        >
          <Checkbox>Possui whatsapp?</Checkbox>
        </Form.Item>
        <Form.Item>
          <SignupButton
            loading={loadingSubmit}
            htmlType='submit'
            className='w-100'
          >
            Enviar
          </SignupButton>
        </Form.Item>
      </Form>
    </SignupLayout>
  )
}

export default SignupClientPhone

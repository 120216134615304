
import React, { Fragment, useEffect, useState } from 'react'
import { useProposal, useStep } from 'hooks'
import { proposals } from 'egi/consumers'
import { Button, Checkbox, Col, Divider, Empty, Form, Row, Spin, Typography } from 'antd'
import MinutaQuadros from './MinutaQuadros'
import swal from 'utils/swal'
import SignatureFields from './SignaturesFields'
import { useDispatch } from 'react-redux'
import { proposalLoadingStep } from 'store/modules/proposal/actions'
import PlusButton from 'components/PlusButton/PlusButton'
import { swalError } from 'components/SwalError/SwalError'
import Input from 'components/Inputs/Input/Input'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

interface IMinutaData {
  slug: string
  name: string
  value: string
  editable: boolean
  addedClausulas?: string[]
}

interface IMinutaSignature {
  nome: string
  existe_conjuge?: boolean
  nome_conjuge?: string
}

interface ISignatureData {
  assinaturas_existe_emitente: boolean
  assinaturas_emitente: IMinutaSignature[]
  assinaturas_existe_compositor: boolean
  assinaturas_compositor: IMinutaSignature[]
  assinaturas_existe_IQ: boolean
  assinaturas_IQ: IMinutaSignature[]
}

function MinutaPreviewStep ({ readOnly, callbackProposal }: {readOnly?: boolean, callbackProposal: Function}) {
  const [status, setStatus] = useState<'loading' | 'sending' | undefined>(undefined)
  const [textAreaVisible, setTextAreaVisible] = useState<boolean>(false)
  const [hasSignatureEmitente, setHasSignatureEmitente] = useState<boolean>(false)
  const [hasSignatureCompositor, setHasSignatureCompositor] = useState<boolean>(false)
  const [hasSignatureIQ, setHasSignatureIQ] = useState<boolean>(false)
  const [hasCustomLabel, setHasCustomLabel] = useState<boolean>(false)
  const [minuta, setMinuta] = useState<IMinutaData[]>([])

  const [formRef] = Form.useForm<ISignatureData>()
  const proposal = useProposal()
  const dispatch = useDispatch()
  const step = useStep()
  const isUnified = step?.form?.geral_modelo_contrato === 'unified'

  function onError (err: any) {
    swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    setStatus(undefined)
  }

  const onSubmit = async (values: ISignatureData) => {
    setStatus('sending')
    try {
      if (!proposal.id) throw new Error('Falha ao encontrar id da proposta')
      if (!step.form) throw new Error('Falha ao encontrar form')
      if (!step.id) throw new Error('Falha ao encontrar step id')
      if (!step.areaId) throw new Error('Falha ao encontrar area Id')

      const dataObj = JSON.parse(JSON.stringify(values))
      const sendObj = {
        ...step.form,
        ccb_particulares_existe_testemunhas: step.form.ccb_particulares_existe_testemunhas ?? false,
        assinaturas_IQ: dataObj.assinaturas_IQ,
        assinaturas_emitente: dataObj.assinaturas_emitente,
        assinaturas_compositor: dataObj.assinaturas_compositor,
        assinaturas_existe_emitente: hasSignatureEmitente,
        assinaturas_existe_compositor: hasSignatureCompositor,
        assinaturas_existe_rotulo_customizado: hasCustomLabel,
        assinaturas_rotulo_customizado: dataObj.assinaturas_rotulo_customizado,
        assinaturas_existe_IQ: hasSignatureIQ,
        finished: true,
        currentStep: 8
      }

      const props: any = {
        form: sendObj,
        proposalId: proposal.id,
        stepId: step._id,
        areaId: step.areaId
      }

      const response = await proposals.send(props)
      if (step.id) dispatch(proposalLoadingStep({ stepId: step.id, loading: true }))
      callbackProposal()
      swal.basic({ title: 'Sucesso!', text: response.message, icon: 'success' })
    } catch (err) {
      if ([
        'Falha ao encontrar id da proposta',
        'Falha ao encontrar form',
        'Falha ao encontrar step id',
        'Falha ao encontrar area Id'
      ].includes(err.message)) return onError(err)

      swalError({ title: 'Atenção', icon: 'warning', err })
      setStatus(undefined)
    }
  }

  async function getMinuta () {
    if (!proposal.id) return
    setStatus('loading')
    try {
      const response = await proposals.minutaPreview({ proposalId: proposal.id })
      setMinuta(response.data.quadros)
      setStatus(undefined)
    } catch (err) {
      console.error(err)
      setStatus(undefined)
    }
  }

  useEffect(() => {
    function onFill () {
      try {
        const tempForm = JSON.parse(JSON.stringify(step.form))

        if (tempForm.assinaturas_emitente.length > 0) setHasSignatureEmitente(true)
        if (tempForm.assinaturas_compositor.length > 0) setHasSignatureCompositor(true)
        if (tempForm.assinaturas_IQ.length > 0) setHasSignatureIQ(true)
        if (tempForm.assinaturas_rotulo_customizado.length > 0) setHasCustomLabel(true)

        if (formRef) formRef.setFieldsValue(tempForm)
      } catch (err) {
        swal.basic({ title: 'Sucesso!', text: err.message, icon: 'warning' })
      }
    }

    getMinuta()
    onFill()
  }, [])

  useEffect(() => {
    const addIcon = document.querySelectorAll('.form-contract-add')

    if (textAreaVisible && addIcon.length > 0) {
      addIcon.forEach(item => {
        item.classList.add('form-contract-none')
      })
    } else {
      addIcon.forEach(item => {
        item.classList.remove('form-contract-none')
      })
    }
  }, [textAreaVisible])

  return (
    <div className="final-contract mt-5">
      <Spin spinning={status === 'loading'}>
        {minuta.length < 1 && (
          <Empty description="Minuta não se encontra disponível." />
        )}

        {minuta.map((value, idx) => (
          <MinutaQuadros
            key={idx}
            name={value.name}
            editable={value.editable}
            value={value.value}
            slug={value.slug}
            addedClausula={value.addedClausulas}
            proposalId={proposal.id}
            onSuccess={() => getMinuta()}
            onVisible={(value) => setTextAreaVisible(value)}
          />
        ))}

        <Divider/>

        <Row>
          <Form
            form={formRef}
            onFinish={onSubmit}
            layout="vertical"
            className="w-100"
          >
            <Row gutter={[15, 15]}>
              <Col>
                <Typography.Title
                  className="form-section-title form-section-title--primary"
                  level={4}
                >
                  Assinaturas do Emitente
                </Typography.Title>
              </Col>

              <Col>
                <Form.Item
                  name="assinaturas_existe_emitente"
                  className="mb-0"
                  valuePropName="checked"
                >
                  <Checkbox
                    disabled={readOnly}
                    onChange={value => setHasSignatureEmitente(value.target.checked)}
                    id="test-form-item-hasCompositor"
                  >
                    Possui assinatura do emitente?
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>

            {hasSignatureEmitente && (
              <Form.List name="assinaturas_emitente">
                {(fields, { add, remove }) => {
                  return (
                    <Fragment>
                      {fields.map((field, index) => {
                        return (
                          <Fragment key={field.fieldKey}>
                            <SignatureFields
                              name="assinaturas_emitente"
                              form={formRef}
                              field={field}
                              index={index}
                              readOnly={readOnly}
                              remove={remove}
                            />
                          </Fragment>
                        )
                      })}

                      {!readOnly && (
                        <Col className="px-1" lg={8} sm={12} xs={24} style={{ position: 'relative' }}>
                          <Form.Item>
                            <PlusButton
                              onClick={() => add()}
                              label="Adicionar assinatura do emitente"
                            />
                          </Form.Item>
                        </Col>
                      )}
                    </Fragment>
                  )
                }}
              </Form.List>
            )}

            <Row gutter={[15, 15]}>
              <Col>
                <Typography.Title
                  className="form-section-title form-section-title--primary"
                  level={4}
                >
                  {`Assinaturas do ${isUnified ? 'Fiduciante' : 'Terceiro Garantidor'}/Devedor Solidário/Avalista`}
                </Typography.Title>
              </Col>

              <Col>
                <Form.Item
                  name="assinaturas_existe_compositor"
                  className="mb-0"
                  valuePropName="checked"
                >
                  <Checkbox
                    disabled={readOnly}
                    onChange={value => setHasSignatureCompositor(value.target.checked)}
                    id="test-form-item-hasCompositor"
                  >
                    Possui assinatura do compositor?
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>

            {hasSignatureCompositor && (
              <Form.List name="assinaturas_compositor" >
                {(fields, { add, remove }) => {
                  return (
                    <Fragment>
                      {fields.map((field, index) => (
                        <Fragment key={field.fieldKey}>
                          <SignatureFields
                            name="assinaturas_compositor"
                            field={field}
                            index={index}
                            readOnly={readOnly}
                            form={formRef}
                            remove={remove}
                          />
                        </Fragment>
                      ))}

                      {!readOnly && (
                        <Col className="px-1" lg={8} sm={12} xs={24} style={{ position: 'relative' }}>
                          <Form.Item>
                            <PlusButton
                              onClick={() => add()}
                              label="Adicionar assinatura do Terceiro"
                            />
                          </Form.Item>
                        </Col>
                      )}
                    </Fragment>
                  )
                }}
              </Form.List>
            )}

            <Row gutter={[15, 15]}>
              <Col>
                <Typography.Title
                  className="form-section-title form-section-title--primary"
                  level={4}
                >
                  Assinaturas do Interveniente Quitante
                </Typography.Title>
              </Col>

              <Col>
                <Form.Item
                  name="assinaturas_existe_IQ"
                  className="mb-0"
                  valuePropName="checked"
                >
                  <Checkbox
                    disabled={readOnly}
                    onChange={value => setHasSignatureIQ(value.target.checked)}
                    id="test-form-item-hasCompositor"
                  >
                    Possui assinatura do Interveniente Quitante?
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>

            {hasSignatureIQ && (
              <Form.List name="assinaturas_IQ">
                {(fields, { add, remove }) => {
                  return (
                    <Fragment>
                      {fields.map((field, index) => (
                        <Fragment key={field.fieldKey}>
                          <SignatureFields
                            name="assinaturas_IQ"
                            form={formRef}
                            field={field}
                            index={index}
                            readOnly={readOnly}
                            remove={remove}
                          />
                        </Fragment>
                      ))}

                      {!readOnly && (
                        <Col className="px-1" lg={8} sm={12} xs={24} style={{ position: 'relative' }}>
                          <Form.Item>
                            <PlusButton
                              onClick={() => add()}
                              label="Adicionar assinatura do Interveniente Quitante"
                            />
                          </Form.Item>
                        </Col>
                      )}
                    </Fragment>
                  )
                }}
              </Form.List>
            )}

            <Row gutter={[15, 15]}>
              <Col>
                <Typography.Title
                  className="form-section-title form-section-title--primary"
                  level={4}
                >
                  SEÇÕES DE ASSINATURA COM RÓTULO PERSONALIZADO
                </Typography.Title>
              </Col>

              <Col>
                <Form.Item
                  name="assinaturas_existe_rotulo_customizado"
                  className="mb-0"
                  valuePropName="checked"
                >
                  <Checkbox
                    disabled={readOnly}
                    onChange={value => setHasCustomLabel(value.target.checked)}
                    id="test-form-item-hasCompositor"
                  >
                    Ativar seções com rótulo personalizado
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>

            {hasCustomLabel && (
              <Form.List name='assinaturas_rotulo_customizado'>
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {(fields || []).map((field, index) => (
                      <Fragment key={field.key}>
                        <Typography.Paragraph className="bold minuta-preview-step__section-title">
                          Seção {index + 1} {' '}
                          {!readOnly && (
                            <MinusCircleOutlined
                              onClick={() => remove(field.name)}
                              style={{ color: 'red' }}
                            />
                          )}
                        </Typography.Paragraph>

                        <Form.Item
                          required={false}
                          key={field.key}
                        >
                          <Row>
                            <Col sm={24} lg={12}>
                              <Form.Item
                                {...field}
                                label='Nome do rótulo'
                                name={[field.name, 'nome_rotulo']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'O campo nome do rótulo é obrigatório'
                                  }
                                ]}
                              >
                                <Input placeholder="Nome do rótulo" />
                              </Form.Item>
                            </Col>

                            <Col span={24} className='minuta-preview-step__signer-section'>
                              <Form.List name={[field.name, 'assinaturas']}>
                                {(fields, { add, remove }) => {
                                  return (
                                    <Fragment>
                                      {fields.map((nestedField, index) => (
                                        <Fragment key={field.fieldKey}>
                                          <SignatureFields
                                            name="assinaturas_rotulo_customizado"
                                            form={formRef}
                                            field={nestedField}
                                            parentField={field}
                                            index={index}
                                            readOnly={readOnly}
                                            remove={remove}
                                          />
                                        </Fragment>
                                      ))}

                                      {!readOnly && (
                                        <Col className="px-1" lg={8} sm={12} xs={24} style={{ position: 'relative' }}>
                                          <Form.Item>
                                            <PlusButton
                                              onClick={() => add()}
                                              label="Adicionar assinatura"
                                              backgroundLess
                                            />
                                          </Form.Item>
                                        </Col>
                                      )}
                                    </Fragment>
                                  )
                                }}
                              </Form.List>
                            </Col>
                          </Row>
                        </Form.Item>
                      </Fragment>
                    ))}

                    {!readOnly && (
                      <Form.Item>
                        <Button
                          type="ghost"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          Adicionar nova seção
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    )}
                  </>
                )}
              </Form.List>

            )}

            <Row justify="end">
              <Col>
                <Button
                  loading={status === 'sending'}
                  className="mt-4 text-center w-100 color-white uppercase"
                  type="primary"
                  htmlType="submit"
                  disabled={readOnly}
                >
                  Emitir contrato
                </Button>
              </Col>
            </Row>
          </Form>
        </Row>
      </Spin>
    </div>
  )
}

export default MinutaPreviewStep

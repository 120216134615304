import { AuthorizationsUser } from 'ecp/repositories/LoginRepository'
import { IAuth } from 'egi/types'
import { _productSlugs } from './ProductModel'

// TM  - Telemarketing
// IN - Indicação
// MP - Marketplace
// CB -  Corban
export type _typePromoterEnum = 'TM' | 'CB' | 'IN' | 'MP'

export type _userLevel = 'backoffice' | 'master' | 'client' | 'promoter' | 'corban' | 'commercial' | 'commission' | 'pricing' | 'administrator' | 'consultant' | 'provider' | 'seller' | 'collaborators' | 'auditor' | 'agx' | 'RH' | 'operator' | 'operatorFuncao' | 'corbanFuncao' | ''

export const UsersModel = {
  showSidebar (user: IAuth, productSlug: _productSlugs): boolean {
    if (productSlug === 'home-equity') {
      const status = ['starting', 'track']
      if (!user.status) return true
      return !status.includes(user.status) && user.level !== 'client'
    }

    return true
  },

  canUpdateEmailAndPhone: (userLevel: _userLevel, product?: string) => {
    const levels: _userLevel[] = ['RH', 'promoter', 'operator', 'client']
    return levels.includes(userLevel) && product === 'ecp'
  },

  canSeeProfile: (userLevel: _userLevel) => {
    const levels: _userLevel[] = ['client', 'promoter', 'seller', 'RH', 'operator']
    return levels.includes(userLevel)
  },

  canSeeSubCorbanSelect: (userLevel: _userLevel) => {
    const levels = ['promoter', 'operator', 'master']
    return levels.includes(userLevel)
  },

  canSeeCovenantSelect: (userLevel: _userLevel) => {
    const levels = ['backoffice', 'administrator']
    return !levels.includes(userLevel)
  },

  chooseDashboardMonerayText: (userLevel: _userLevel) => {
    if (['operator', 'promoter', 'RH'].includes(userLevel)) return 'released'
    return 'financed'
  },

  chooseDashboardMonetary ({ financed, released, level }: { financed: number, released: number, level: _userLevel }) {
    if (this.chooseDashboardMonerayText(level) === 'released') return released
    return financed
  },

  canSeeLevelSelect: (levels: AuthorizationsUser[]) => {
    if (!levels) return false
    return levels.length > 0
  },

  covenantAndCorbanFIlterName: (userLevel: _userLevel) => {
    if (userLevel === 'RH') return 'Convênios'
    return 'Corbans e Convênios'
  },

  isClientECP (selectedProduct: _productSlugs | undefined, level: _userLevel) {
    return (selectedProduct === 'ecp') && (level === 'client')
  },

  canSeeBankInformation (level: _userLevel) {
    return (level !== 'client')
  }

}

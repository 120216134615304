import React, { Fragment, useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Select, Spin } from 'antd'
import { useDispatch } from 'react-redux'
import { tablesUpdate } from 'store/modules/tables/actions'
import swal from 'utils/swal'
import { channelOptions } from 'components/ModalInvite/components/constants'
import { IChangeChannelProps } from 'egi/app/Promoter/promoterInterfaces'
import PromotersRepository from 'egi/repositories/PromotersRepository'

function ChangeChannel ({ id, currentChannel, visible, onClose }: IChangeChannelProps) {
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState<string>()
  const [errors, setErrors] = useState<any>({})

  const dispatch = useDispatch()

  async function handleEditUsers () {
    if (!id) return
    if (!selected || selected === currentChannel) {
      setErrors({ user: 'Selecione um canal diferente.' })
      return
    }

    setLoading(true)
    try {
      const response = await PromotersRepository.changeChannel({ promoterId: id, channel: selected })
      swal.basic({ title: 'Sucesso!', text: response?.data.message, icon: 'success' })

      setErrors({})
      onClose()
      dispatch(tablesUpdate())
    } catch (err) {
      setErrors({ user: err.message })
    }
    setLoading(false)
  }

  useEffect(() => {
    setSelected(currentChannel)
    return () => setErrors({})
  }, [currentChannel, visible])

  return (
    <Modal
      title='Mudança de canal'
      visible={visible}
      onCancel={onClose}
      footer={
        <Fragment>
          <Button type="ghost" onClick={onClose}>Cancelar</Button>
          <Button type="primary" onClick={() => handleEditUsers()}>Ok</Button>
        </Fragment>
      }
    >
      <Spin spinning={loading}>
        <Row>
          <Col className="px-1" lg={24} sm={24} xs={24}>
            <Form.Item
              label={<label>Canal</label>}
              help={errors.user && (errors.user)}
              validateStatus={errors.user && ('error')}
            >
              <Select
                placeholder="Escolha o novo canal"
                onChange={(value) => { setSelected(value as string) }}
                loading={loading}
                value={selected}
                defaultValue={selected}
              >
                {channelOptions.map((channel) => (
                  <Select.Option key={channel.value} value={channel.value}>{channel.label}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Spin>
    </Modal>
  )
}

export default ChangeChannel

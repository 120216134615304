import React, { Fragment, useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import { Button, Tooltip } from 'antd'
import { ShareAltOutlined } from '@ant-design/icons'
import swal from 'utils/swal'
import format from 'utils/format'
import LinkRepository from 'egi/repositories/LinkRepository'

interface ICampaignColumns {
  setShareLink: (url: string) => void
}

const campaignColumns = ({ setShareLink }: ICampaignColumns): ColumnsType<Object> => [
  {
    title: 'URL',
    dataIndex: 'url',
    sorter: false,
    render: (url: string) => (
      <Fragment>
        <a href={url} target="_blank" rel="noreferrer" className="link-style mr-1">Link de campanha</a>

        <Tooltip title="Compartilhar link" destroyTooltipOnHide={{ keepParent: false }}>
          <Button
            className="share-button"
            shape="circle"
            icon={<ShareAltOutlined />}
            type="primary"
            onClick={() => setShareLink(url)}
          />
        </Tooltip>
      </Fragment>
    )
  },
  {
    title: 'Nome da campanha',
    dataIndex: 'campaign',
    sorter: true,
    render: (campaign: { name: string }) => `${campaign?.name || '-'}`
  },
  {
    title: 'Slug da campanha',
    dataIndex: 'campaign',
    sorter: true,
    render: (campaign: { slug: string }) => `${campaign?.slug || '-'}`
  },
  {
    title: 'Propostas criadas',
    dataIndex: 'proposals',
    sorter: true,
    render: (proposals: number) => {
      if (!proposals) return 'Nenhuma proposta criada'
      return `${proposals}`
    }
  },
  {
    title: 'Leads gerados',
    dataIndex: 'leads',
    sorter: true,
    render: (leads: number) => {
      if (!leads) return 'Nenhum lead gerado'
      return `${leads}`
    }
  },
  {
    title: 'Cliques da campanha',
    dataIndex: 'clicks',
    sorter: false,
    render: (_, row: any) => {
      const { _id } = row
      const [total, setTotal] = useState<number | undefined>(undefined)
      const [loading, setLoading] = useState<boolean>(false)

      async function clickedLinks (id: string) {
        setLoading(true)
        try {
          const response = await LinkRepository.clickedLinks(id)
          setTotal(response.data.data?.total)
          setLoading(false)
        } catch (err) {
          swal.basic({ icon: 'warning', text: err.message, title: 'Atenção' })
          setLoading(false)
        } finally {
          setLoading(false)
        }
      }

      return (
        <Fragment>
          {total === 0 ? (<h1>Nenhum clique</h1>) : (
            <Fragment>
              {total ? (
                <h1>{format.compressedNumber(total)}</h1>
              ) : (
                <Button type="link" className="p-0" loading={loading} onClick={() => clickedLinks(_id)}>Ver total de cliques</Button>
              )}
            </Fragment>
          )}
        </Fragment>
      )
    }
  },
  {
    title: 'Data de criação',
    dataIndex: 'createdAt',
    sorter: true
  }
]

export default campaignColumns

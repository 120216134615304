import React from 'react'

export default function OpenIdSVG () {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.6204 3.54211L13.8714 5.36976V24.107C9.5933 23.5706 6.37337 21.2389 6.37337 18.4366C6.37337 15.7802 9.26995 13.5429 13.2153 12.86V10.4778C7.18058 11.2072 2.62436 14.4936 2.62436 18.4366C2.62436 22.5217 7.51271 25.8957 13.8714 26.4579L17.6204 24.6928V3.54211ZM18.2765 10.4778V12.86C19.744 13.1139 21.0687 13.5775 22.1504 14.2034L20.1197 15.3515L27.3756 16.9292L26.8523 11.5556L24.9232 12.6413C23.131 11.5571 20.8357 10.7871 18.2765 10.4778Z" fill="#f7931e"/>
    </svg>

  )
}

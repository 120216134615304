import React, { Fragment, useRef, MutableRefObject, useState, useEffect } from 'react'
import { Form, Col, Row, Select, Typography, Divider, Button } from 'antd'
import { Input, InputMoney } from 'components'
import locale from 'antd/es/date-picker/locale/pt_BR'
import { operacaoDateFields, operationsRequiredFields, operationsOptionalFields, valuesFormatMoney, valuesFormatToDate, valuesFormatPercentage, valuesFormatRemakeSimulation } from '../contractFields'
import ChooseContractComponent from '../ChooseContractComponent'
import { IOnidataContractErrors } from '../contract'
import BankContract from '../BankContract'
import { ArrowRightOutlined } from '@ant-design/icons'
import { useProposal, useResources, useStep } from 'hooks'
import time, { timeAsDayjs, correctTimezone } from 'utils/time'
import format from 'utils/format'
import { proposals } from 'egi/consumers'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'
import MinutaMoldSelect from '../MinutaMoldSelect'
import { useDispatch } from 'react-redux'
import { stepForm } from 'store/modules/step/actions'
import Datepicker from 'components/Datepicker/Datepicker'
import { swalError } from 'components/SwalError/SwalError'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'
import RichTextEditor from 'components/RichTextEditor/RichTextEditor'

interface IOperacaoValues {
  [key: string]: any
  operacao_natureza_emprestimo: string,
  operacao_vencimento_primeira_parcela: string,
  operacao_vencimento_ultima_parcela: string
  operacao_data_emprestimo: string
  operacao_emprestimo_concedido: number,
  operacao_tarifa_avaliacao_bens: number,
  operacao_IOF: number,
  operacao_emprestimo_liberado: number,
  operacao_prazo: number,
  operacao_quantidade_parcelas: number,
  operacao_taxa_juros_mensal: number,
  operacao_taxa_juros_anual: number,
  operacao_valor_parcela_mensal: number,
  operacao_CET_anual: number,
  operacao_amortizacao: string,
  reajuste_indice: string
  operacao_divida_junto_terceiro: number
  operacao_pagamento_quitante: number
  operacao_recursos_livres: number
  operacao_custas_cartorarias: number
  operacao_plataforma_eletronica: number
  operacao_emissao_de_certificado: number
  operacao_despesas_de_registro: number
  garantia_modelo_slug: string
  garantia: string
  dividas: string
  onus: string
  dados_bancarios_agencia: string
  dados_bancarios_banco: string
  dados_bancarios_conta_corrente: string
  dados_bancarios_titular: string
}

function OperacaoStep ({ readOnly, onSuccess }: {readOnly?: boolean, onSuccess: (value: boolean) => void}) {
  const [errors, setErrors] = useState<IOnidataContractErrors>({})
  const [status, setStatus] = useState<'sending' | 'remaking' | undefined>(undefined)

  const [garantiaRichText, setgarantiaRichText] = useState<string | undefined>(undefined)
  const [deptsRichText, setDeptsRichText] = useState<string | undefined>(undefined)
  const [onusRichText, setOnusRichText] = useState<string | undefined>(undefined)

  const [formRef] = Form.useForm()
  const ref: MutableRefObject<{garantiaText: string}> = useRef({
    garantiaText: ''
  })

  const proposal = useProposal()
  const resources = useResources()
  const dispatch = useDispatch()
  const step = useStep()

  const isDeptsContract = step.form?.geral_modelo_contrato === 'owner-debits' || step.form?.geral_modelo_contrato === 'third-part-debits' || step.form?.geral_modelo_contrato === 'unified'

  function onError (err: any) {
    swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    setStatus(undefined)
  }

  const onSubmit = async (values: IOperacaoValues) => {
    setStatus('sending')
    setErrors({})

    try {
      if (!proposal.id) throw new Error('Falha ao encontrar id da proposta')
      if (!step.form) throw new Error('Falha ao encontrar form')
      if (!step.id) throw new Error('Falha ao encontrar step id')
      if (!step.areaId) throw new Error('Falha ao encontrar area Id')
      if (!ref.current.garantiaText) throw new Error('Quadro garantia não informado!')

      valuesFormatToDate.forEach(item => {
        if (values[item]) values[item] = time(correctTimezone(values[item]))
      })

      valuesFormatMoney.forEach(item => {
        if (values[item]) values[item] = format.formatMoneySend(String(values[item]))
      })

      valuesFormatPercentage.forEach(item => {
        if (values[item]) values[item] = format.percentageToDecimal(values[item]).toPrecision(6)
      })

      if (deptsRichText) values.dividas = deptsRichText
      if (ref.current.garantiaText) values.garantia_quadro = ref.current.garantiaText
      if (onusRichText) values.onus = onusRichText

      const props: any = {
        form: { ...step.form, ...values, finished: false, currentStep: 5 },
        proposalId: proposal.id,
        stepId: step._id,
        areaId: step.areaId
      }

      const response = await proposals.send(props)
      dispatch(stepForm({ data: values }))
      onSuccess(true)
      swal.basic({ title: 'Sucesso!', text: response.message, icon: 'success' })
    } catch (err) {
      if ([
        'Falha ao encontrar id da proposta',
        'Falha ao encontrar form',
        'Falha ao encontrar step id',
        'Falha ao encontrar area Id',
        'Quadro garantia não informado!'
      ].includes(err.message)) return onError(err)

      if (err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))

        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
      } else {
        swalError({ title: 'Atenção', icon: 'warning', err })
      }

      setStatus(undefined)
    }
  }

  async function getRemakingSimulation () {
    setStatus('remaking')
    try {
      const config = {
        timeout: 90000
      }
      const response = await proposals.remakingSimulation({ proposalId: proposal.id }, config)
      if (response.data && response.data.data) {
        const values = JSON.parse(JSON.stringify(response.data.data))
        dispatch(stepForm({ data: values }));

        ['operacao_vencimento_primeira_parcela', 'operacao_vencimento_ultima_parcela', 'operacao_data_emprestimo'].forEach(item => {
          if (values[item]) values[item] = timeAsDayjs(values[item]).format('DD/MM/YYYY')
        })

        valuesFormatRemakeSimulation.forEach(item => {
          if (values[item]) values[item] = values[item].toFixed(2)
        })

        valuesFormatPercentage.forEach(item => {
          if (values[item]) values[item] = format.decimalToPercentage(values[item]).toFixed(4)
        })

        if (formRef && values) {
          formRef.setFieldsValue({ ...values })
        }
      }
      setStatus(undefined)
      swal.basic({ title: 'Sucesso!', text: response.message, icon: 'success' })
    } catch (err) {
      if (err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
      } else {
        swalError({ title: 'Atenção', icon: 'warning', err })
      }
      setStatus(undefined)
    }
  }

  useEffect(() => {
    function onFill () {
      try {
        const tempForm = JSON.parse(JSON.stringify(step.form))

        valuesFormatToDate.forEach(item => {
          if (tempForm[item]) tempForm[item] = timeAsDayjs(tempForm[item], { applyTimezone: false }).format('DD/MM/YYYY')
        })

        valuesFormatMoney.forEach(item => {
          if (tempForm[item]) tempForm[item] = parseFloat(tempForm[item]).toFixed(2)
        })

        valuesFormatPercentage.forEach(item => {
          if (tempForm[item]) tempForm[item] = format.decimalToPercentage(tempForm[item]).toFixed(4)
        })

        if (tempForm.garantia_quadro) {
          setgarantiaRichText(tempForm.garantia_quadro)
          ref.current.garantiaText = tempForm.garantia_quadro
        }

        if (formRef) formRef.setFieldsValue({ ...tempForm })
      } catch (err) {
        swal.basic({ title: 'Atenção!', text: err.message, icon: 'warning' })
      }
    }

    onFill()
  }, [step])

  return (
    <Fragment>
      <Form
        form={formRef}
        layout='vertical'
        onFinish={onSubmit}
        className="form-contract mt-5"
        initialValues={{
          operacao_emprestimo_concedido: 0,
          operacao_tarifa_avaliacao_bens: 0,
          operacao_IOF: 0,
          operacao_emprestimo_liberado: 0,
          operacao_valor_parcela_mensal: 0,
          operacao_CET_anual: 0,
          operacao_taxa_juros_mensal: 0,
          operacao_taxa_juros_anual: 0,
          operacao_divida_junto_terceiro: 0,
          operacao_recursos_livres: 0,
          operacao_pagamento_quitante: 0,
          operacao_custas_cartorarias: 0,
          operacao_despesas_de_registro: 0,
          operacao_emissao_de_certificado: 0,
          operacao_plataforma_eletronica: 0
        }}
      >
        <Col span={24}>
          <Row gutter={[15, 15]} align="middle">
            {operacaoDateFields.map(item => (
              <Col lg={8} md={24} sm={24} xs={24} key={item.id}>
                <ChooseContractComponent
                  errors={errors}
                  item={item}
                  type={item.type || ''}
                  readOnlyInput={true}
                  component={(
                    <Form.Item
                      name={item.field}
                      label={<label>{item.label}</label>}
                      help={errors.geral_data_atual && (errors.geral_data_atual)}
                      validateStatus={errors.geral_data_atual && ('error')}
                    >
                      <Datepicker
                        disabled={true}
                        locale={locale}
                      />
                    </Form.Item>
                  )}
                />
              </Col>
            ))}

            {operationsRequiredFields.map(item => (
              <Col lg={8} md={24} sm={24} xs={24} key={item.id}>
                <ChooseContractComponent
                  errors={errors}
                  item={item}
                  type={item.type || ''}
                  readOnlyInput={true}
                  component={(
                    <Form.Item
                      name={item.field}
                      label={<label>{item.label}</label>}
                      help={errors[item.field] && (errors[item.field])}
                      validateStatus={errors[item.field] && ('error')}
                    >
                      <Input
                        readOnly={true}
                        placeholder={'Digite o(a) ' + item.label}
                        type="number"
                        error={errors[item.field]}
                      />
                    </Form.Item>
                  )}
                />
              </Col>
            ))}

            <Col lg={8} md={24} sm={24} xs={24}>
              <Form.Item
                name='operacao_amortizacao'
                label={<label>Operacao amortização</label>}
                help={errors.operacao_amortizacao && (errors.operacao_amortizacao)}
                validateStatus={errors.operacao_amortizacao && ('error')}
              >
                <Select
                  disabled={true}
                  placeholder="Escolha a operacao amortização"
                >
                  <Select.Option value="PRICE">PRICE</Select.Option>
                  <Select.Option value="SAC">SAC</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col lg={8} md={24} sm={24} xs={24}>
              <Form.Item
                name='reajuste_indice'
                label={<label>Indíce de reajuste</label>}
                help={errors.reajuste_indice && (errors.reajuste_indice)}
                validateStatus={errors.reajuste_indice && ('error')}
              >
                <Select
                  disabled={true}
                  placeholder="Escolha a operacao amortização"
                >
                  <Select.Option value="IPCA">IPCA</Select.Option>
                  <Select.Option value="FLATRATE">Taxa Fixa</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            {operationsOptionalFields.map(item => (
              <Col lg={8} md={24} sm={24} xs={24} key={item.id}>
                <Form.Item
                  name={item.field}
                  label={<label>{item.label}</label>}
                  help={errors[item.field] && (errors[item.field])}
                  validateStatus={errors[item.field] && ('error')}
                >
                  <InputMoney
                    id={`test-form-item-${item.field}`}
                    disabled={readOnly}
                    placeholder={'Digite o(a) ' + item.label}
                    defaultValue={0}
                    decimalsLimit={4}
                  />
                </Form.Item>
              </Col>
            ))}

            <Row justify="end" className="w-100">
              <Col className="px-2">
                <Button
                  disabled={readOnly}
                  type="primary"
                  onClick={getRemakingSimulation}
                  loading={status === 'remaking'}
                >
                Refazer simulação
                </Button>
              </Col>
            </Row>

            <Row align="bottom" className="w-100" gutter={[15, 15]}>
              <Col lg={24} md={24} sm={24} xs={24} className="pr-0">
                <MinutaMoldSelect
                  selectSlug={{ name: 'garantia_modelo_slug', label: 'Quadro garantia' }}
                  placeholder="Selecione um modelo de quadro para a garantia"
                  readOnlySelect={readOnly}
                  qualification={resources.minutaMold}
                  type="comum"
                  text={garantiaRichText}
                  setText={(value) => { ref.current.garantiaText = value }}
                  errors={errors.garantia_modelo_slug}
                  formRef={formRef}
                />
              </Col>
            </Row>

            <Divider/>

            {isDeptsContract && (
              <Fragment>
                <Col span={24}>
                  <Row gutter={[15, 0]} align="top">
                    <Col>
                      <Typography.Title
                        className="form-section-title form-section-title--primary"
                        level={4}
                      >
                        Dívidas
                      </Typography.Title>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={<label>Dívidas</label>}
                    name='dividas'
                    help={errors.dividas && errors.dividas}
                    validateStatus={errors.dividas && 'error'}
                  >
                    <RichTextEditor
                      onChange={setDeptsRichText}
                      disable={readOnly}
                    />
                  </Form.Item>
                </Col>

                <Divider/>
              </Fragment>
            )}

            <Col span={24}>
              <Form.Item
                name="onus"
                help={errors.onus && errors.onus}
                validateStatus={errors.onus && 'error'}
                label={<label>Ônus</label>}
              >
                <RichTextEditor
                  onChange={setOnusRichText}
                  disable={readOnly}
                />
              </Form.Item>
            </Col>

            <Divider />

            <BankContract readOnlyInput={readOnly} errors={errors} />
          </Row>

          <Row justify="end">
            <Col>
              <Button
                loading={status === 'sending'}
                className="mt-4 text-center w-100 color-white uppercase"
                type="primary"
                htmlType="submit"
                disabled={readOnly}
              >
                Avançar
                <ArrowRightOutlined/>
              </Button>
            </Col>
          </Row>
        </Col>
      </Form>

    </Fragment>
  )
}

export default OperacaoStep

import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React from 'react'

function PEPTooltip () {
  const link = 'https://www.in.gov.br/en/web/dou/-/circular-n-3.978-de-23-de-janeiro-de-2020-239631175'
  return (
    <label>
      Ajuda: {' '}
      <Tooltip
        color="white"
        placement="right"
        destroyTooltipOnHide={{ keepParent: false }}
        title={
          <p className="color-black">
            Confira o conceito de Pessoa Exposta Politicamente (PEP) nos artigos 19 e 27 da Circular nº 3978/2020 do Banco Central do Brasil,
            acessando o link: <a href={link} target="_blank" rel="noreferrer">{link}</a>
          </p>
        }
      >
        <InfoCircleOutlined className="simulation-info" style={{ color: '#00441F' }}/>
      </Tooltip>
    </label>
  )
}

export default PEPTooltip

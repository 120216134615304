import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Checkbox, InputNumber } from 'antd'
import { IFormAlienatingProps, IFormAlienatingErrors } from './types'
import { useProposal, useStep } from 'hooks'
import { proposals } from 'egi/consumers'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'
import { proposalLoadingStep } from 'store/modules/proposal/actions'
import { useDispatch } from 'react-redux'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'

function FormAlienating ({ formRef, onLoading, callbackProposal, readOnlyInput }: IFormAlienatingProps) {
  const step = useStep()
  const proposal = useProposal()

  const [errors, setErrors] = useState<IFormAlienatingErrors>({})
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const onSubmit = async (values: any) => {
    if (loading) return
    if (!proposal.id) return

    const props = {
      proposalId: proposal.id,
      stepId: step._id,
      areaId: step.areaId,
      form: values
    }

    setLoading(true)
    try {
      onLoading('sending')
      if (step.id) dispatch(proposalLoadingStep({ stepId: step.id, loading: true }))
      const response = await proposals.sendAlienating(props)
      setErrors({})
      callbackProposal()
      swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
    } catch (err) {
      if (err.data?.invalid) {
        let errors: IFormAlienatingErrors = {}
        errors = validateResponse(err.data?.invalid)
        setErrors(errors)

        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
        return
      }
      setErrors({})
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    } finally {
      setLoading(false)
      onLoading('')
    }
  }

  function resetFields () {
    if (formRef) formRef.resetFields()
  }

  function onFill () {
    const formData = (step as any).form || {}

    if (Object.keys(formData).length < 1) {
      resetFields()
      return
    }

    formRef.setFieldsValue({ ...formData })
  }

  useEffect(() => {
    resetFields()
    onFill()
  }, [step.form, formRef, step._id, step.situation])

  return (
    <div className="mb-4">
      <Form
        form={formRef}
        onFinish={onSubmit}
        layout="vertical"
      >

        <Row gutter={[30, 30]}>
          <Col md={12} xs={24}>
            <Form.Item
              name='alienante'
              label={<label>Possui quantos alienantes: (de 0 a 4)</label>}
              initialValue={0}
              help={errors.alienante && (errors.alienante)}
              validateStatus={errors.alienante && ('error')}
            >
              <InputNumber
                min={0}
                max={4}
                disabled={readOnlyInput}
                className='w-100'
                placeholder='Digite o número de alienantes'
              />
            </Form.Item>
          </Col>

          <Col md={12} xs={24}>
            <Form.Item
              name='hasGuarantor'
              valuePropName="checked"
              initialValue={false}
              help={errors.hasGuarantor && (errors.hasGuarantor)}
              validateStatus={errors.hasGuarantor && ('error')}
              label={<label></label>}
            >
              <Checkbox disabled={readOnlyInput}>Possui garantidor</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default FormAlienating

import { IRoute } from 'components/Sidebar/types'
import { IAuth } from 'egi/types'
import { getRoutes } from 'routes'
import { ISearch } from 'components/SeverTable/types'
import { _productSlugs } from 'ecp/models/ProductModel'

/**
 * Retrieves the first available route for a specific product.
 *
 * @param {string} productSlug - The product slug to obtain routes for.
 * @param {IAuth} auth - Authentication information required.
 * @returns {string} The path of the first available route for the product within the authentication context.
 */
export const getFirstRouter = (productSlug: _productSlugs = 'home-equity', auth: IAuth): string => {
  const routes = getRoutes(productSlug, auth)
  const firstRoute = routes[0] as IRoute
  const search = firstRoute?.meta?.location?.search

  if (!firstRoute) return '/auth'
  return `/auth${firstRoute.path}` + (search ?? '')
}

/**
 * Calculates the total height of an HTML element, including margins.
 *
 * @param {HTMLElement} element - The HTML element for which to calculate the height.
 * @returns {number} The total height of the element, including top and bottom margins.
 */
export function elementDimension (element: HTMLElement): number {
  if (!element) return 0
  let offsetHeight = element.offsetHeight
  offsetHeight += parseInt(window.getComputedStyle(element).getPropertyValue('margin-top'))
  offsetHeight += parseInt(window.getComputedStyle(element).getPropertyValue('margin-bottom'))
  return offsetHeight
}

/**
 * Removes empty keys (undefined, null, empty string) from an object, including nested objects.
 *
 * @param {Record<string, any>} obj - The object from which to remove empty keys.
 * @returns {Record<string, any>} A new object with empty keys removed.
 *
 * @example
 * const inputObject = {
 *   name: 'John',
 *   age: null,
 *   address: {
 *     street: '123 Main St',
 *     city: '',
 *     zip: undefined,
 *   },
 *   hobbies: ['reading', '', 'coding'],
 * };
 *
 * const resultObject = removeEmptyKeys(inputObject);
 * // Result: { name: 'John', address: { street: '123 Main St' }, hobbies: ['reading', 'coding'] }
 */
export const removeEmptyKeys = (obj: Record<string, any>): Record<string, any> => {
  const newObj: Record<string, any> = {}

  for (const key in obj) {
    const value = obj[key]

    if (typeof value === 'object' && !Array.isArray(value)) {
      const newNestedObj = removeEmptyKeys(value)
      if (Object.keys(newNestedObj).length !== 0) {
        newObj[key] = newNestedObj
      }
    } else if (value !== '' && value !== null && value !== undefined) {
      newObj[key] = value
    }
  }

  return newObj
}

/**
 * Formats a value or array of values for filtering, joining array elements into a comma-separated string.
 *
 * @param {string | string[] | undefined} value - The value or array of values to format.
 * @returns {string[]} A comma-separated string array, or an empty array if the input is undefined or empty.
 *
 * @example
 * const inputValue = 'apple';
 * const formattedValue = filterValueFormatter(inputValue);
 * // Result: ['a', 'p', 'p', 'l', 'e']
 *
 * @example
 * const inputArray = ['red', 'green', 'blue'];
 * const formattedArray = filterValueFormatter(inputArray);
 * // Result: ['red,green,blue']
 *
 * @example
 * const undefinedValue = undefined;
 * const formattedUndefined = filterValueFormatter(undefinedValue);
 * // Result: []
 */
export function filterValueFormatter (value: string | string[] | undefined) {
  if (!value || !value.length) return []
  return Array(value).join(',')
}

/**
 * Formats an array of status values for request, converting it into a comma-separated string.
 *
 * @param {string[]} values - The array of status values to format.
 * @returns {string} A comma-separated string representation of the status values, or an empty string if the input is falsy.
 *
 * @example
 * const inputValues = ['pending', 'approved', 'rejected'];
 * const formattedString = formatStatusForRequest(inputValues);
 * // Result: 'pending,approved,rejected'
 *
 * @example
 * const emptyArray = [];
 * const formattedEmptyArray = formatStatusForRequest(emptyArray);
 * // Result: ''
 *
 * @example
 * const falsyValues = null;
 * const formattedFalsyValues = formatStatusForRequest(falsyValues);
 * // Result: ''
 */
export function formatStatusForRequest (values: string[]): string {
  if (!values) return ''
  return values.toString()
}

/**
 * Formats fields from a form object, specifically converting the 'status' field from a comma-separated string to an array.
 *
 * @param {ISearch} value - The form object containing fields to be formatted.
 * @returns {ISearch} The formatted form object with the 'status' field as an array, or the original object if 'status' is undefined.
 *
 * @example
 * const inputValue = {
 *   filters: {
 *     status: 'pending,approved,rejected',
 *     otherField: 'someValue',
 *   },
 *   // ...other fields in ISearch
 * };
 * const formattedValue = formatFieldsForm(inputValue);
 * // Result: { filters: { status: ['pending', 'approved', 'rejected'], otherField: 'someValue' }, ...other fields in ISearch }
 *
 * @example
 * const undefinedStatus = {
 *   filters: {
 *     otherField: 'someValue',
 *   },
 *   // ...other fields in ISearch
 * };
 * const formattedUndefinedStatus = formatFieldsForm(undefinedStatus);
 * // Result: { filters: { otherField: 'someValue' }, ...other fields in ISearch }
 */
export function formatFieldsForm (value: ISearch) {
  if (!value.filters?.status) return value.filters
  return { ...value.filters, status: value.filters?.status.split(',') }
}

/**
 * Copies the provided data to the clipboard.
 *
 * @param {string | undefined} data - The data to be copied to the clipboard.
 *
 * @example
 * const textToCopy = 'Hello, Clipboard!';
 * copyToClipboard(textToCopy);
 * // Result: 'Hello, Clipboard!' is copied to the clipboard.
 *
 * @example
 * const undefinedData = undefined;
 * copyToClipboard(undefinedData);
 * // Result: No action is taken if data is undefined.
 */
export function copyToClipboard (data: string | undefined) {
  if (!data) return

  if (typeof (navigator.clipboard) === 'undefined') {
    const textArea = document.createElement('textarea')
    textArea.value = data
    textArea.style.position = 'fixed'
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    document.execCommand('copy')
    document.body.removeChild(textArea)
    return
  }

  navigator.clipboard.writeText(data)
}

/**
 * Returns a set of keys conditionally based on the provided condition.
 *
 * @param {T | undefined} conditional - The condition to check.
 * @param {Object} trueReturn - The set of keys to return if the condition is truthy.
 * @returns {Object} An object containing the specified keys if the condition is truthy, or an empty object otherwise.
 *
 * @template T
 *
 * @example
 * const conditionTrue = true;
 * const keysToReturn = { key1: 'value1', key2: 'value2' };
 * const resultTrue = conditionalKeys(conditionTrue, keysToReturn);
 * // Result: { key1: 'value1', key2: 'value2' }
 *
 * @example
 * const conditionFalse = false;
 * const keysToReturn = { key1: 'value1', key2: 'value2' };
 * const resultFalse = conditionalKeys(conditionFalse, keysToReturn);
 * // Result: {}
 *
 * @example
 * const undefinedCondition = undefined;
 * const keysToReturn = { key1: 'value1', key2: 'value2' };
 * const resultUndefined = conditionalKeys(undefinedCondition, keysToReturn);
 * // Result: {}
 */
export function conditionalKeys <T = string> (conditional: T | undefined, trueReturn: {}) {
  if (!conditional) return {}
  return trueReturn
}

/**
 * Removes properties with null or empty string values from an object using a deep clone.
 *
 * @param {Object} object - The object from which to remove empty properties.
 * @returns {Object} A new object with empty properties removed.
 *
 * @example
 * const inputObject = {
 *   name: 'John',
 *   age: null,
 *   address: {
 *     street: '',
 *     city: 'New York',
 *   },
 * };
 * const resultObject = removeEmptyProps(inputObject);
 * // Result: { name: 'John', address: { city: 'New York' } }
 *
 * @example
 * const inputArray = ['red', 'green', ''];
 * const resultArray = removeEmptyProps(inputArray);
 * // Result: ['red', 'green']
 */
export const removeEmptyProps = (object: Object) => {
  return JSON.parse(
    JSON.stringify(object),
    (_, value) => value === null || value === '' ? undefined : value
  )
}

/**
 * Scrolls the specified parent element (default: '.ant-layout-content') to the top.
 *
 * @param {string} [parent='.ant-layout-content'] - The selector for the parent element to scroll.
 * @returns {void}
 *
 * @example
 * focusTop();
 * // Result: Scrolls the '.ant-layout-content' element to the top.
 *
 * @example
 * focusTop('.custom-container');
 * // Result: Scrolls the '.custom-container' element to the top.
 */
export const focusTop = (parent: string = '.ant-layout-content') => {
  const layoutContent = document.querySelector<HTMLElement>(parent)
  if (layoutContent) {
    layoutContent.scrollTo({
      top: 0,
      behavior: 'auto'
    })
  }
}

/**
 * Checks if a given step is disabled based on its position in a sequence.
 *
 * @template T
 * @param {T} reference - The step to use as a reference for comparison.
 * @param {T} actual - The current step to check for disablement.
 * @param {T[]} sequence - The sequence of steps to determine the order.
 * @returns {boolean} True if the current step is disabled, false otherwise.
 *
 * @example
 * const referenceStep = 'step2';
 * const currentStep = 'step4';
 * const stepSequence = ['step1', 'step2', 'step3', 'step4'];
 * const isDisabled = isStepDisable(referenceStep, currentStep, stepSequence);
 * // Result: true, as 'step4' comes after 'step2' in the sequence.
 *
 * @example
 * const referenceStep = 'step3';
 * const currentStep = 'step1';
 * const stepSequence = ['step1', 'step2', 'step3', 'step4'];
 * const isDisabled = isStepDisable(referenceStep, currentStep, stepSequence);
 * // Result: false, as 'step1' comes before 'step3' in the sequence.
 */
export const isStepDisable = <T>(reference: T, actual: T, sequence: T[]): boolean => {
  return sequence.indexOf(actual) < sequence.indexOf(reference)
}

/**
 * Returns the original value if it is a number or a non-empty string.
 * Otherwise, returns 'n/a'.
 *
 * @template T - The type of the input value.
 * @param {T} value - The value to be checked and returned.
 * @returns {T | 'n/a'} - The original value or 'n/a'.
 *
 * @example
 * const result1 = valueOrNa(42) // result1 is 42
 * const result2 = valueOrNa('Hello') // result2 is 'Hello'
 * const result3 = valueOrNa('') // result3 is 'n/a'
 * const result4 = valueOrNa(null) // result4 is 'n/a'
 */
export const valueOrNa = <T, >(value: T) => {
  if (typeof value === 'number') return value
  return value || 'n/a'
}

export const isNullish = (value: any) => {
  return value === undefined || value === null
}

export interface ILogChanges {
  [property: string]: {
    old?: any;
    new?: any;
  };
}

/**
 * Represents changes in an object, indicating the altered properties
 * and their old and new versions.
 *
 * @typedef {Object} Changes
 * @property {Object} propertyName - Name of the altered property.
 * @property {any} old - Old value of the property.
 * @property {any} new - New value of the property.
 */

/**
 * Extracts changes from an object based on the json-diff result.
 *
 * @param {any} diffResult - Result of json-diff containing differences between two objects.
 * @returns {ILogChanges} - Object representing changes in the format {propertyName: {old: any, new: any}}.
 */
export const extractJsonDiff = (diffResult: any): ILogChanges => {
  /**
   * Object to store changes.
   * @type {ILogChanges}
   */
  const changes: ILogChanges = {}

  /**
   * Handles boolean values, converting them to "Verdadeiro" or "Falso".
   *
   * @param {any} value - The value to handle.
   * @returns {string} - The converted value.
   */
  const handleBooleanValue = (value: any): string => {
    return typeof value === 'boolean' ? (value ? 'Sim' : 'Não') : value
  }

  /**
   * Traverses the json-diff result object to extract changes.
   *
   * @param {any} obj - The current object to traverse.
   * @param {string} path - The current path in the object hierarchy.
   */
  const traverseDiff = (obj: any, path = ''): void => {
    for (const key in obj) {
      if (key === '__old' || key === '__new') {
        const propertyName = path.split('.').pop()
        if (propertyName) {
          changes[propertyName] = changes[propertyName] || {}
          changes[propertyName][key.slice(2) as 'old' | 'new'] = handleBooleanValue(obj[key])
        }
      } else if (key.endsWith('__deleted')) {
        const propertyName = key.slice(0, -9)
        changes[propertyName] = changes[propertyName] || { new: 'N/A' }
        changes[propertyName].old = handleBooleanValue(obj[key])
      } else if (key.endsWith('__added')) {
        const propertyName = key.slice(0, -7)
        if (typeof obj[key] === 'object') traverseDiff(obj[key])
        changes[propertyName] = changes[propertyName] || { old: 'N/A' }
        changes[propertyName].new = handleBooleanValue(obj[key])
      } else if (Array.isArray(obj[key])) {
        // Handle array of objects
        obj[key].forEach((item: any, index: number) => {
          traverseDiff(item, `${path ? `${path}.${key}` : key}[${index}]`)
        })
      } else if (typeof obj[key] === 'object') {
        traverseDiff(obj[key], path ? `${path}.${key}` : key)
      } else {
        console.log(key)
        changes[key] = { new: obj[key], old: 'N/A' }
      }
    }
  }

  traverseDiff(diffResult)

  return changes
}

import React, { useLayoutEffect, useState } from 'react'
import { IFilterDate } from '../../views/MlmDashboard/types'
import { Empty, Skeleton } from 'antd'
import LeadsPie from 'egi/app/MLM/components/LeadPie/LeadsPie'
import LeadsRepository, { ITotalLead } from 'egi/repositories/LeadsRepository'

function MlmLeadsFunnel ({ filterDate }: IFilterDate) {
  const [leadsTotals, setLeadsTotals] = useState<ITotalLead[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)

  useLayoutEffect(() => {
    const abortController = new AbortController()
    async function fetchLeads () {
      setLoading(true)
      try {
        const response = await LeadsRepository.totals({
          signal: abortController.signal,
          params: filterDate
        })

        const { leads } = response.data.data || {}
        if (!leads) throw new Error('Falha ao encontrar totais')

        setLeadsTotals(leads)
        setFailed(false)
        setLoading(false)
      } catch (err) {
        if (!abortController.signal.aborted) {
          setFailed(true)
          setLoading(false)
        }
      }
    }

    if (filterDate.createdDate && filterDate.productId) fetchLeads()
    return () => abortController.abort()
  }, [filterDate.createdDate, filterDate.productId, filterDate.promoterId, filterDate.sellerId, filterDate.type])

  return (
    <div className="dashboard-status-card h-100">
      {loading ? (
        <Skeleton
          active={true}
          avatar
          paragraph={{ rows: 2 }}
        />
      ) : (
        failed ? <Empty description="Falha ao carregar dados." className="h-100 flex flex-align-center flex-column flex-justify-center" /> : <LeadsPie data={leadsTotals} />
      )}
    </div>
  )
}

export default MlmLeadsFunnel

import { ArrowRightOutlined } from '@ant-design/icons'
import { Empty, Skeleton, Timeline } from 'antd'
import { _userLevel } from 'ecp/models/UsersModel'
import { ILogs } from 'egi/repositories/ConfigurationsRepository'
import React from 'react'
import { timeAsDayjs } from 'utils/time'
export interface ISettignsHistory {
  createdAt: string
  modifiedBy: { _id: string, name: string, level: _userLevel }
  new: { newText: string }
  _id: string
}

export const HistoryLastModified = ({ updatedAt, modifiedName }: { updatedAt: string, modifiedName: string }) => {
  return <span>
    Alterado <b>{timeAsDayjs(updatedAt).format('DD/MM/YYYY [às] HH:mm')}</b>&nbsp;
  por&nbsp;<b>{modifiedName}</b>
  </span>
}

export const ShowHistory = ({ history, historyLoading }: { history: ILogs[] | undefined, historyLoading: boolean }) => {
  if (historyLoading) {
    return <Timeline>
      <Timeline.Item >
        <Skeleton />
      </Timeline.Item>
    </Timeline>
  }
  if (history?.length) {
    return (
      <Timeline>
        {history?.map(item => {
          return (
            <Timeline.Item key={item._id}>
              <div className="mb-2">
                <HistoryLastModified
                  updatedAt={item.createdAt}
                  modifiedName={item.modifiedBy.name}
                />
              </div>
              <b>População antiga: </b><span>{item.last.populationLimit}</span> <ArrowRightOutlined/> <b>População nova: </b> <span>{item.new.populationLimit}</span>

            </Timeline.Item>
          )
        })}

      </Timeline>
    )
  }

  return <div className="text-center">
    <Empty
      description="Não foi possível encontrar nenhum histórico de revisão para esse passo"
    />
  </div>
}

import { AxiosResponse } from 'axios'
import api from '../../services/api'
import { ConnectionFailed, ErrInternetDisconnected } from './errors'

const promoter = {

  getAllManagers: async () => {
    try {
      const response: AxiosResponse = await api.get('/resources/managers')
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  }

}

export default promoter

import React from 'react'

interface IBaseLineProps {
  label: string
  value: string | React.ReactElement
  tooltip?: React.ReactElement
}

function SimulatorLine ({ label, value, tooltip }: IBaseLineProps) {
  return (
    <div className="flex flex-row flex-space-between flex-align-baseline">
      <label className="simulator-result-label">
        {label}
        {tooltip}
      </label>

      <label className="simulator-title-baseline" style={{ fontSize: 18 }}>
        {value}
      </label>
    </div>
  )
}

export default SimulatorLine

import React, { Fragment, useLayoutEffect, useState } from 'react'
import Avaliation from '../Avaliation/Avaliation'
import { useSelector } from 'react-redux'
import { RootState } from 'store/modules/rootReducer'
import { useClient, useStep } from 'hooks'
import { Row, Col, Spin, Tag, Typography, Timeline, Button } from 'antd'
import { proposals, resources } from 'egi/consumers'
import format from 'utils/format'
import { ArrowLeftOutlined, FileDoneOutlined, LoadingOutlined, RightOutlined } from '@ant-design/icons'
import { timeAsDayjs } from 'utils/time'
import { avaliationsColors, varColors } from 'styles/colors'
import { IAvaliations } from 'egi/types'
import OperationalLimit from 'egi/app/OperationalLimit/views/OperationalLimit'
import WarningText from 'components/WarningText/WarningText'
import { StepSectionTitle } from '../StepRenders/StepRenders'

const { Title } = Typography

function ListAvaliations ({ areaId, fetchProposals }: { areaId: string, fetchProposals: Function }) {
  const proposal = useSelector((state: RootState) => state.proposal)
  const step = useStep()
  const client = useClient()

  const [logs, setLogs] = useState([])
  const [loading, setLoading] = useState<boolean>(false)
  const [population, setPopulation] = useState<string>('')
  const [isLogsVisible, setIsLogsVisible] = useState<boolean>(false)

  const canSeePopulation = (step.title === 'parecer técnico da engenharia' || step.title === 'pré-análise de engenharia') && false

  async function fetchLogs () {
    if (proposal.id) {
      try {
        const response = await proposals.logs({ proposalId: proposal.id })
        const logs = response.data.logs
        setLoading(true)
        setLogs(logs)
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }
  }

  useLayoutEffect(() => {
    fetchLogs()
  }, [step._id])

  useLayoutEffect(() => {
    async function estimatedPopulation (city: string) {
      try {
        const population = await resources.populationIBGE(city)
        const keys = Object.keys(population)

        const finalValue = population[keys[keys.length - 1]]
        const cityName = await resources.cityNameIBGE(city)

        setPopulation(`População estimada do munícipio ${cityName} - ${finalValue} (${keys[keys.length - 1]})`)
      } catch (err) {
        setPopulation('Erro ao buscar dados populacionais')
        console.log(err)
      }
    }

    if (canSeePopulation) {
      if (client.city) estimatedPopulation(String(client.city))
      else setPopulation('Cliente ainda não cadastrou a cidade do imóvel.')
    }
  }, [])

  return (
    <div>
      {loading ? (
        <LoadingOutlined size={26} className="p-2" />
      ) : (
        <Fragment>
          {isLogsVisible ? (
            <Fragment>
              <Row className="mb-3">
                <Col className="flex flex-align-center">
                  <>
                    <Button
                      className="share-button"
                      shape="circle"
                      icon={<ArrowLeftOutlined />}
                      type="primary"
                      onClick={() => setIsLogsVisible(!isLogsVisible)}
                    />
                  </>
                  <Title level={2} className="review-backoffice-card-title ml-3 mb-0">Logs de alteração</Title>
                </Col>
              </Row>
              {(logs.length < 1 && !loading) && (<p className="bold">Sem logs</p>)}

              <Timeline className="mb-3">
                {(logs.length > 0 && !loading) && (
                  <Fragment>
                    {logs?.map((item: any) => (
                      <Timeline.Item
                        key={item._id}
                      >
                        <Row>
                          <Col>
                            <strong>{item.modifiedBy.name} - {timeAsDayjs(item.createdAt, { applyTimezone: false }).format('DD/MM/YYYY HH:mm')}</strong>
                          </Col>
                        </Row>

                        {item?.name && <Tag className="my-2" color={avaliationsColors[item.name]}>{item?.name.replace(/-/g, ' ')}</Tag>}
                        <div className="flex flex-align-center">
                          <label>{format.formatBRL(item?.last?.value || 0)}</label>
                          <RightOutlined className="mx-2" />
                          <label>{format.formatBRL(item?.new?.value || 0)}</label>
                        </div>
                      </Timeline.Item>
                    ))}
                  </Fragment>
                )}

              </Timeline>
            </Fragment>
          ) : (
            <div className="flex flex-align-center">
              <Button
                className="share-button"
                shape="circle"
                icon={<FileDoneOutlined />}
                type="primary"
                onClick={() => setIsLogsVisible(!isLogsVisible)}
              />
              <p className="review-label ml-2 mb-0">Log de Alterações</p>
            </div>
          )}
        </Fragment>
      )}

      <div className='step-warning mt-3 mb-3'>
        <WarningText type='info'>
          <h3>Atenção:</h3>

          <p>É necessário revisar a proposta após enviar os valores de avaliação para prosseguir para o proxímo departamento.</p>
        </WarningText>
      </div>

      {canSeePopulation && (
        <Tag color={varColors.ongoing} className="mb-3 p-1 color-secondary">
          <label style={{ fontSize: '1.3rem', color: 'black' }}>{population || <Spin style={{ color: 'white' }} />}</label>
        </Tag>
      )}

      <Row gutter={[30, 30]} className='mb-4'>
        {step.avaliations?.map((item: IAvaliations) => (
          <Fragment key={item?._id}>
            {item?.areaIds.includes(areaId) && (
              <Col
                lg={12} md={24} sm={24} xs={24}
                key={item.name}
                className='pb-3'
              >
                <Avaliation
                  fetchProposals={fetchProposals}
                  fetchLogs={fetchLogs}
                  areaId={areaId}
                  title={item.name}
                  fields={item}
                />
              </Col>
            )}
          </Fragment>
        ))}
      </Row>

      {step.title?.startsWith('análise de crédito') && step.avaliations?.map((item: IAvaliations) => (
        <Fragment key={item._id}>

          {item.areaIds.includes(areaId) && (
            <>
              <StepSectionTitle title='Limite Operacional'/>
              <OperationalLimit
                canUpdate={false}
                className='operation-limit__container--fill'
              />
            </>
          )}
        </Fragment>
      ))}

    </div >

  )
}

export default ListAvaliations

import { Drawer } from 'components'
import ProposalStepButton from 'ecp/app/Proposals/components/ProposalStepButton/ProposalStepButton'
import ProposalStepButtonWithHistory from 'ecp/app/Proposals/components/ProposalStepButtonWithHistory/ProposalStepButtonWithHistory'
import React from 'react'
import useProposalDrawerWithContext from 'ecp/app/Proposals/hooks/useProposalDrawerWithContext'
import useThisStep from 'ecp/app/Proposals/hooks/useThisStep'
import ReviewDrawer from '../ReviewDrawer/ReviewDrawer'
import useDynamicProposal from 'ecp/app/Proposals/hooks/useDynamicProposal'

function CrivoStep () {
  const { isOpen, open, close, sendStep } = useProposalDrawerWithContext()
  const { step } = useThisStep()
  const { details } = useDynamicProposal()
  const canOpen = step.canOpen

  return (
    <ProposalStepButtonWithHistory>
      <ProposalStepButton
        onClick={open}
        enabled={canOpen}
      >
        {step.name}
      </ProposalStepButton>
      <Drawer
        visible={isOpen}
        onClose={close}
        title={step.name}
        footer={null}
      >
        <ReviewDrawer
          close={close}
          sendStep={sendStep}
          step={step}
          proposalId={details?.value?._id || ''}
          clientName={details?.value?.client?.name || ''}
        />
      </Drawer>
    </ProposalStepButtonWithHistory>
  )
}

export default CrivoStep

import React, { useMemo, useState } from 'react'
import { Form } from 'antd'
import SelectTypeInvite from '../SelectTypeInvite'
import format from 'utils/format'
import { useAuth, useResources } from 'hooks'
import { _tableAreas } from 'globals'
import swal from 'utils/swal'
import { _userLevel } from 'ecp/models/UsersModel'
import InviteEgiRepository, { InviteEgiErrors } from 'egi/repositories/InviteEgiRepository'
import { areasBackofficeInvite } from './functions'
import { IInviteRequestBody } from '../types'
import MultiSelect from 'components/MultiSelect/MultiSelect'

interface IInviteBackoffice {
  level: _userLevel
  area?: _tableAreas
  onSuccess: (token: string) => void
}

type GeneretaLevelLink = {
  level: _userLevel
  type?: string
  areas?: string
  levelName?: string
  areaName?: string
}

function InviteBackoffice ({ level, area, onSuccess }: IInviteBackoffice) {
  const [loading, setLoading] = useState<boolean>(false)
  const [form] = Form.useForm()

  const user = useAuth()
  const resources = useResources()
  const finalAreas = useMemo(() => areasBackofficeInvite(user, resources), [user, resources])

  const generateLinkBackofficeInvite = async (values: IInviteRequestBody) => {
    const { areas, levelName, adminId, areaName } = values

    const data: IInviteRequestBody = {
      areas,
      level,
      levelName,
      adminId,
      areaName
    }

    if (area) data.areaName = area

    setLoading(true)

    try {
      const response = await InviteEgiRepository.levelLink<GeneretaLevelLink>('backoffice', data)
      const { link } = response.data.data || {}
      if (!link) throw InviteEgiErrors.link()

      onSuccess(link)
      setLoading(false)
    } catch (err) {
      swal.basic({ title: 'Atenção!', text: err.message, icon: 'warning' })
      setLoading(false)
    }
  }

  return (
    <SelectTypeInvite
      level={level}
      onFinish={generateLinkBackofficeInvite}
      label='Selecione as areas do backoffice:'
      form={form}
      loading={loading}
      layout='grid w-100'
      formContent={
        <Form.Item
          name='areas'
          rules={[{ required: true, message: 'Escolha as áreas do Backoffice' }]}
        >
          <MultiSelect
            showSearch
            options={finalAreas.map(area => ({ label: format.capitalize(area.label), value: area.value }))}
            placeholder='Selecione uma opção'
          />
        </Form.Item>
      }
    />
  )
}

export default InviteBackoffice

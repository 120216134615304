import api from '../../services/api'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ErrInternetDisconnected, ConnectionFailed } from './errors'
import { states } from 'utils/globals'
import { IProduct } from 'ecp/models/ProductModel'

const resources = {
  banks: async () => {
    try {
      const response: AxiosResponse = await api.get('/resources/banks')
      return response.data.data.banks
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  countries: async () => {
    try {
      const response: AxiosResponse = await api.get('/resources/countries')
      return response.data.data.countries
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  states: async () => {
    try {
      const response: AxiosResponse = await api.get('/resources/states')
      return response.data.data.states
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  statesIBGE: async () => {
    try {
      const response: AxiosResponse = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/municipios?orderBy=nome')

      const ibgeCities = response.data

      const nowState = states.map(state => {
        const cities = ibgeCities
          .filter((city: any) => city['regiao-imediata']['regiao-intermediaria'].UF.id === state.value)
          .map((city: any) => ({
            value: city.id,
            label: city.nome
          }))

        return ({
          ...state,
          cities
        })
      })

      return nowState
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  populationIBGE: async (city: string) => {
    try {
      const response: AxiosResponse = await axios.get(`https://servicodados.ibge.gov.br/api/v1/pesquisas/indicadores/29171/resultados/${city}`)
      return response.data[0]?.res[0]?.res
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  cityNameIBGE: async (city: string) => {
    try {
      const response: AxiosResponse = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/municipios/${city}`)
      return response.data.nome
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  address: async (cep: string) => {
    try {
      return axios.request({
        method: 'GET',
        timeout: 5000,
        url: `https://viacep.com.br/ws/${cep}/json/`
      })
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  addressFallback: async (cep: string): Promise<
    {
      data: {
        'cep': string
        'address_type': string
        'address_name': string
        'address': string
        'state': string
        'district': string
        'lat': string
        'lng': string
        'city': string
        'city_ibge': string
        'ddd': string
      }
    }
  > => {
    try {
      return axios.request({
        method: 'GET',
        url: `https://cep.awesomeapi.com.br/json/${cep}`,
        timeout: 5000
      })
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  products: async (config?: AxiosRequestConfig): Promise<{
    code: number
    message: string
    data: {
      products: IProduct[]
    }
  }> => {
    try {
      const response: AxiosResponse = await api.get('/resources/products', config)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  providers: async ({ areaName }: { areaName?: 'juridico' | 'engenharia' | '' }) => {
    const query = areaName ? '?areaName=' + areaName : ''

    try {
      const response: AxiosResponse = await api.get(`/resources/providers${query}`)
      return response.data.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  areas: async () => {
    try {
      const response: AxiosResponse = await api.get('/resources/areas')
      return response.data.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  witness: async () => {
    try {
      const response: AxiosResponse = await api.get('/resources/witness')
      return response.data.data.representatives
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  attorney: async () => {
    try {
      const response: AxiosResponse = await api.get('/resources/attorney')
      return response.data.data.representatives
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  promoterType: async (config?: AxiosRequestConfig) => {
    try {
      const response: AxiosResponse = await api.get('/resources/promoter/types', config)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  promoters: async (query: {types?: string}) => {
    try {
      const response: AxiosResponse = await api.get('/resources/promoters', { params: query })
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  seller: async () => {
    try {
      const response: AxiosResponse = await api.get('/resources/sellers')
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  consultants: async () => {
    try {
      const response: AxiosResponse = await api.get('/resources/consultant')
      return response.data.data.consultants
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  support: async () => {
    try {
      const response: AxiosResponse = await api.get('/commercials/all')
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  telemarketing: async () => {
    try {
      const response: AxiosResponse = await api.get('/telemarketing/all')
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  legalNature: async () => {
    try {
      const response: AxiosResponse = await api.get('/resources/legalnature')
      return response.data.data.socialNature
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  economicActivity: async () => {
    try {
      const response: AxiosResponse = await api.get('/resources/economicactivity')
      return response.data.data.economicActivity
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  partners: async ({ proposalId }: { proposalId: string }) => {
    try {
      const response: AxiosResponse = await api.get(`resources/proposals/${proposalId}/partners`)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  areaNames: async () => {
    try {
      const response: AxiosResponse = await api.get('/resources/areasnames')
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()

      throw err.response.data
    }
  },

  goals: async () => {
    try {
      const response: AxiosResponse = await api.get('/resources/goals')
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  refusedReasons: async () => {
    try {
      const response: AxiosResponse = await api.get('/resources/lead/reasons')
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  declines: async (query: { productId: string }) => {
    try {
      const response: AxiosResponse = await api.get('/resources/reproval', { params: query })
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  minutaMold: async (query: { productId: string }) => {
    try {
      const response: AxiosResponse = await api.get('/resources/minuta/mold', { params: query })
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  minutaVersions: async () => {
    try {
      const response: AxiosResponse = await api.get('/resources/minuta/versions')
      return response.data.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  }
}

export default resources

import { AxiosRequestConfig } from 'axios'
import { IRequestLGPDResponse } from 'ecp/app/LGPD/LGPDInterfaces'
import { IResponseBase, Repository } from 'ecp/repositories/Repository'
import api from 'services/api'

class TermsRepository extends Repository<{}> {
  async getTerms (config?: AxiosRequestConfig): Promise<IResponseBase<IRequestLGPDResponse>> {
    return Repository.handle(() =>
      this.api.get(this.path, config)
    )
  }

  async accept (body: { type: 'funcionario' }, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.post(this.path, body, config)
    )
  }
}

export default new TermsRepository({ api, path: '/ecp/terms' })

import React, { ReactNode } from 'react'
import { Spin } from 'antd'

type ControlLoadersProps = { loaderTokenVerify: boolean, children: ReactNode }

function SigninLoading ({ loaderTokenVerify, children }: ControlLoadersProps) {
  if (loaderTokenVerify) {
    return (
      <div className="flex flex-justify-center flex-align-center">
        <Spin
          spinning={loaderTokenVerify}
          tip="Estamos verificando seu email."
          size="large"
        />
      </div>
    )
  }

  return <>{children}</>
}

export default SigninLoading

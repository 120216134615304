import axios, { InternalAxiosRequestConfig } from 'axios'
import { BroadcastChannelRouter } from 'models/BroadcastRouterModel'
import { getToken, clearStorage } from '../utils/localStorage'
import swal from '../utils/swal'
import { reactAppServer } from './../utils/config'

const api = axios.create({
  baseURL: reactAppServer,
  timeout: 30000
})

api.defaults.headers.post['Content-Type'] = 'application/json'
api.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
  const token = getToken()

  const hasAuth = document.location.href.includes('/auth')
  if (token && hasAuth) config.headers.Authorization = 'Bearer ' + token

  return config
}, error => Promise.reject(error))

api.interceptors.response.use(
  (response) => {
    return response
  }, (error) => {
    if (error.response && error.response.status === 401 && error.response.data.data?.authentication === false) {
      swal.onClose({
        icon: 'info',
        title: 'Sua sessão expirou!',
        text: 'Faça o login novamente.',
        close: () => {
          document.location.href = '/'

          const channel = new BroadcastChannelRouter()
          channel.postLogout()

          clearStorage()
        }
      })
    }

    return Promise.reject(error)
  }
)

export default api

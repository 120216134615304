import { IInvalidFieldsSwalError } from 'components/InvalidFieldsError/InvalidFieldsError'

export type FormatedFormItem = {
  labelFor: string | undefined
  label: string
}

const translateAntForm = {

  formatLabelFor (labelFor?: string) {
    if (!labelFor) return ''
    const regex = /"([^"]*)"/g
    return labelFor?.match(regex)?.[0]?.replace(/"/g, '')
  },

  containsHtml (text: string): boolean {
    const matchs = text.match(/<.+>.*?<\/.+>/g) || []
    return Boolean((matchs || [])?.length > 0)
  },

  formItemCallback (item: Element) {
    const extractLabelForRegex = /label for="\w+" /
    const firstLabel = item.getElementsByTagName('label')?.[0]

    const firstLabelContent = firstLabel?.children?.[0]?.innerHTML ?? firstLabel?.innerHTML
    const labelFor = item?.innerHTML.match(extractLabelForRegex)?.[0]

    if (!firstLabelContent || firstLabelContent.match(/ajuda:/gi)?.[0]) {
      const spans = Array.from(item.getElementsByTagName('span'))
      let lastElement: { labelFor: string | undefined, label: string } | null = null

      ;(spans || []).forEach(span => {
        if (!this.containsHtml(span.innerHTML) && span.innerHTML) {
          lastElement = {
            label: span.innerText,
            labelFor: this.formatLabelFor(labelFor)
          }
        }
      })

      if (lastElement) return lastElement
    }

    return {
      label: firstLabelContent,
      labelFor: this.formatLabelFor(labelFor)
    }
  },

  removeUnmatchFormItems (formItem: FormatedFormItem) {
    return formItem.labelFor !== undefined &&
      formItem.labelFor !== null &&
      formItem.labelFor !== '' &&
      formItem.label !== null &&
      formItem.label !== undefined
  },

  transformUnderlineMatchs (field: string) {
    return (field?.split('_') || []).pop()
  },

  capitalizeFirstLetter (label: string) {
    return label.charAt(0).toUpperCase() + label.slice(1)
  },

  translateFormItems (): FormatedFormItem[] {
    const allFormItems = document.querySelectorAll('.ant-form-item')

    const formatedFormItems = Array.from(allFormItems || [])
      .map((item) => this.formItemCallback(item))
      .filter((formItem) => this.removeUnmatchFormItems(formItem))

    const duplicateObjectFields: FormatedFormItem[] = []
    for (const formItem of formatedFormItems) {
      if (formItem.labelFor && formItem.labelFor?.indexOf('_') !== -1) {
        const secondHalfLabel = this.transformUnderlineMatchs(formItem.labelFor || '')

        if (formItem.labelFor.includes('propertyAddress')) {
          duplicateObjectFields.push({
            labelFor: 'property' + this.capitalizeFirstLetter(secondHalfLabel || ''),
            label: formItem.label
          })
        } else {
          duplicateObjectFields.push({
            labelFor: secondHalfLabel,
            label: formItem.label
          })
        }
      }

      duplicateObjectFields.push(formItem)
    }

    return duplicateObjectFields
  },

  formatToSwalError (invalidFields: IInvalidFieldsSwalError['invalidFields'] = []): IInvalidFieldsSwalError['invalidFields'] {
    const formItems = this.translateFormItems() || []

    const items = formItems.map(formItem => {
      const matchInvalidField = invalidFields.find(
        invalidField => invalidField.field === formItem.labelFor
      )

      if (!matchInvalidField) {
        return null
      }

      return {
        field: formItem.label,
        message: matchInvalidField?.message
      }
    }).filter(item => !!item)

    return items as IInvalidFieldsSwalError['invalidFields']
  }
}

export default translateAntForm

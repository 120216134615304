import React from 'react'
import { Input as AntInput } from 'antd'
import masks from 'utils/masks'

import { _inputMask } from 'globals'
import { SearchProps } from 'antd/lib/input'
import { getMaxLength } from 'components/Inputs/functions'

function InputSearch ({ mask, value = '', min, ...rest }: SearchProps & { mask: string }) {
  return (
    <AntInput.Search
      maxLength={getMaxLength(mask)}
      onChange={(e) => masks(e.target.value, mask as _inputMask)}
      value={masks(value as string, mask as _inputMask, false)}
      min={min}
      {...rest}
    />
  )
}

export default InputSearch

import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { ReloadOutlined } from '@ant-design/icons'
import { Button, Collapse, Result, Spin, Timeline } from 'antd'
import { Icons } from 'components'
import dayjs from 'dayjs'
import { _proposalStatuses } from 'egi/app/ProposalEgi/proposalEgiInterfaces'
import { colors } from 'styles/colors'
import { timeAsDayjs } from 'utils/time'
import { ProposalStepContent } from '../ProposalSteps/ProposalSteps'
import RodobensKYCRepository from 'ecpf/repositories/RodobensKYCRepository'

const CollapseLogsHistory = ({ children, onUpdate }: {
  children: ReactNode
  onUpdate: () => void
}) => {
  return (
    <Collapse
      ghost
    >
      <Collapse.Panel
        key="1"
        header='Clique para visualizar os logs de integração das APIs'
        extra={
          <div
            onClick={(event) => {
              onUpdate()
              event.stopPropagation()
            }}
          >
            <ReloadOutlined />
          </div>
        }
      >
        {children}
      </Collapse.Panel>
    </Collapse>
  )
}

type _logsDocumentalStatusHistory = 'Falha' | 'Sucesso' | 'Disparo'
const IconByStatusHistory = ({
  status
}: {
  status:_logsDocumentalStatusHistory
}) => {
  const convertToProposalStatus: {
    [key in _logsDocumentalStatusHistory]: _proposalStatuses
  } = {
    Falha: 'reproved',
    Sucesso: 'approved',
    Disparo: 'analysis'
  }

  const translateColor: {
    [key in _logsDocumentalStatusHistory]: string
  } = {
    Falha: colors.reproved,
    Sucesso: colors.approved,
    Disparo: colors.analysis
  }

  return <Icons
    name={convertToProposalStatus[status] || 'error'}
    color={translateColor[status] || colors.redproved}
    size={30}
  />
}

const TimelineItemByStatus = ({ log }: {
  log: IDocumentalHistoryLog
}) => {
  return (
    <Timeline.Item
      dot={
        <IconByStatusHistory
          status={log.status as _logsDocumentalStatusHistory}
        />
      }
    >
      <h3 className='mb-0'>{log.type} ({log.status})</h3>
      {log.message && (<p className='mb-3'>{log.message || ''}</p>)}
      {dayjs(log.createdAt).isValid()
        ? <small>{timeAsDayjs(log.createdAt, { applyTimezone: false }).format('DD/MM/YYYY HH:mm:ss')}</small>
        : 'Data inválida'
      }
    </Timeline.Item>
  )
}

type _kycTypes = 'EmailAge' | 'Unico' | 'AcertPix'
export interface IDocumentalHistoryLog {
  _id: string,
  createdAt: string,
  status: _logsDocumentalStatusHistory,
  type: _kycTypes
  message: string
}

interface IDocumentalLogsHistoryProps {
  proposalId?: string
}
export function DocumentalLogsHistory ({ proposalId }: IDocumentalLogsHistoryProps) {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [logs, setLogs] = useState<Array<IDocumentalHistoryLog>>([])

  const fetchDocumentalStepsHistory = useCallback(async (proposalId: string) => {
    setLoading(true)
    try {
      const response = await RodobensKYCRepository.getDocumentalHistoryLogs(proposalId)
      const { logs } = response.data.data || {}

      if (!logs) throw new Error('Falha ao encontrar logs.')
      if (!logs.length) throw new Error('Não existe nenhum log para essa proposta.')

      setLogs(logs)
      setErrorMessage('')
    } catch (error) {
      setErrorMessage(error?.message)
    } finally {
      setLoading(false)
    }
  }, [
    proposalId
  ])

  const onRetryFetchLogs = () => {
    if (proposalId) {
      fetchDocumentalStepsHistory(
        proposalId
      )
    }
  }

  useEffect(() => {
    if (proposalId) {
      fetchDocumentalStepsHistory(
        proposalId
      )
    }
  }, [
    proposalId
  ])

  if (loading) {
    return (
      <CollapseLogsHistory onUpdate={onRetryFetchLogs}>
        <ProposalStepContent>
          <Spin />
        </ProposalStepContent>
      </CollapseLogsHistory>
    )
  }

  if (errorMessage) {
    return (
      <CollapseLogsHistory onUpdate={onRetryFetchLogs}>
        <ProposalStepContent>
          <div className='text-center'>
            <Result
              title='Ops, que algo deu errado.'
              status='warning'
              subTitle={errorMessage}
            />

            <Button
              onClick={onRetryFetchLogs}
              type='primary'
            >
              <ReloadOutlined /> Tentar novamente
            </Button>
          </div>
        </ProposalStepContent>
      </CollapseLogsHistory>
    )
  }

  return (
    <CollapseLogsHistory onUpdate={onRetryFetchLogs}>
      <ProposalStepContent>
        <Timeline>
          {(logs || []).map(log => (
            <TimelineItemByStatus
              key={log._id}
              log={log}
            />
          ))}
        </Timeline>
      </ProposalStepContent>
    </CollapseLogsHistory>
  )
}

import { InfoCircleFilled } from '@ant-design/icons'
import { Button, Progress, Skeleton } from 'antd'
import { EcpCard } from 'ecp/components/EcpCard/EcpCard'
import React from 'react'

type ProgressCardWithoutParcelsProps = {
  canInformDebits?: boolean
  handleCLick: () => void
  isLoading ?: boolean
}

type ProgressCardProps = {
  numberOfParcels ?: number
  parcelsPaid ?: number
  percentagePaid ?: number
} & ProgressCardWithoutParcelsProps

export function ProgressCard ({ numberOfParcels, parcelsPaid, percentagePaid, isLoading, canInformDebits, handleCLick } : ProgressCardProps) {
  if (isLoading) {
    return (
      <EcpCard>
        <Skeleton
          active={true}
          paragraph={{ rows: 1 }}
        />
      </EcpCard>
    )
  }

  return (
    <EcpCard>
      {numberOfParcels ? (
        <>
          <h3 className='progress-card__title mb-3'> {canInformDebits ? 'Melhorar Score' : 'Score'}</h3>

          <div className='progress-card__content mb-5'>
            <div className='progress-card__data'>
              <div className='progress-card__text-container'>
                <p className='progress-card__sub-title'>N.º de parcelas</p>
                <p className='progress-card__value'>{numberOfParcels}</p>
              </div>

              <div className='progress-card__text-container'>
                <p className='progress-card__sub-title'>N.º de parcelas pagas</p>
                <p className='progress-card__value'>{parcelsPaid}</p>
              </div>
            </div>
            <Progress type="circle" percent={percentagePaid} className='progress-card__progress'/>
          </div>
        </>
      ) : (
        <>
          <div className='progress-card__no-parcels-container mb-4'>
            <InfoCircleFilled className='progress-card__no-parcels-svg mt-4 mb-4'/>
            <p className='progress-card__no-Parcels-text'>Você não possui nenhuma dívida informada, clique no botão para informar</p>
          </div>
        </>
      )}
      {canInformDebits &&
        <Button
          type='primary'
          className='w-100'
          onClick={handleCLick}
        >
          Informar Dívidas
        </Button>
      }
    </EcpCard>
  )
}

import api from 'services/api'
import { AxiosRequestConfig } from 'axios'
import { IResponseBase, Repository } from 'ecp/repositories/Repository'
import { IAttorney } from 'egi/app/Attorney/attorneyInterfaces'

type _representatives = 'attorney' | 'witness'
class RepresentativeRepository extends Repository<IAttorney> {
  async attorneysList (config?: AxiosRequestConfig) {
    return Repository.handle<{ representatives: IAttorney[] }>(() =>
      this.api.get(`${this.path}/attorney`, config)
    )
  }

  async witnessList (config?: AxiosRequestConfig) {
    return Repository.handle<{ representatives: IAttorney[] }>(() =>
      this.api.get(`${this.path}/witness`, config)
    )
  }

  async create<T> (representative: T & { type: _representatives }, config?: AxiosRequestConfig) {
    return Repository.handle<IResponseBase>(() =>
      this.api.post(this.path, representative, config)
    )
  }

  async update<T> (attorneyId: string, representative: T & { type: _representatives }, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.patch(`${this.path}/${attorneyId}`, representative, config)
    )
  }

  async delete (representative: string, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.delete(`${this.path}/${representative}`, config)
    )
  }
}

export default new RepresentativeRepository({ api, path: '/representative' })

import React, { ReactNode } from 'react'
import { Layout } from 'antd'
import imagesUrl from 'utils/imagesUrls'
import { Link } from 'react-router-dom'
import { UNAUTHS_PATHS } from 'routes/unauthRoutes'

function UnauthLayout ({ children }: { children?: React.ReactNode }) {
  return (
    <Layout className="full-height-unauth flex flex-column">
      <article className='container-unauth flex-1'>
        <img className="unauth-dots" src={imagesUrl.dots} />

        <aside className='form-place'>
          {children}
        </aside>

        <img className="unauth-dots" src={imagesUrl.dots} />
      </article>
    </Layout>
  )
}

UnauthLayout.TextContainer = ({ children }: {children: ReactNode}) =>
  <div className='unauth-layout__text-container'>{children}</div>

UnauthLayout.Text = ({ email, text, firstLineText = 'Olá' }: { email: string, firstLineText?: string, text?: ReactNode | string }) => {
  const generateDefaultText = (email: string) => <>para sua maior segurança, estamos atualizando a senha de todos os nossos usuários. Enviamos um e-mail para <b>{email}</b> para você prosseguir com a troca.</>

  const hydratedText = text || generateDefaultText(email)

  return (
    <>
      <p className='unauth-layout__text'>{firstLineText},</p>
      <p className='unauth-layout__text'>{hydratedText}</p>
    </>
  )
}

UnauthLayout.BackToLogin = ({ text }: { text: string }) => {
  return (
    <>
      <p className='unauth-layout__text unauth-layout__text--2'>{text}</p>
      <Link to={UNAUTHS_PATHS.MAIN} className='unauth-layout__link'>Voltar para o Login</Link>
    </>
  )
}

UnauthLayout.IconContainer = ({ icon }: { icon: React.ReactNode }) => {
  return (
    <div className='unauth-layout__icon-container'>
      {icon}
    </div>
  )
}

UnauthLayout.Title = ({ title }: { title: string }) => {
  return (
    <h1 className='unauth-layout__title'>{title}</h1>
  )
}

UnauthLayout.ButtonContainer = ({ children }: {children: ReactNode}) =>
  <div className='unauth-layout__button-container'>{children}</div>

UnauthLayout.Container = ({ children }: {children: ReactNode}) =>
  <div className='unauth-layout unauth-form-border'>{children}</div>

export default UnauthLayout

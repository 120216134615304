import React, { Fragment } from 'react'
import { Button, Col, message, Row } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import Icons from 'components/Icons/Icons'
import { useDispatch } from 'react-redux'
import { stepFill, stepSetOpenDrawerLoading } from 'store/modules/step/actions'
import unauthed from 'egi/consumers/unauthed'
import ShareButtons from 'components/ShareButtons'
import { LoadingOutlined, InfoCircleFilled } from '@ant-design/icons'
import { useAuth, useClient, useProposal } from 'hooks'
import { StepProps, _crivoStatuses, _proposalStatuses } from 'egi/app/ProposalEgi/proposalEgiInterfaces'
import { varColors } from 'styles/colors'
import swal from 'utils/swal'
import CndTagStatus, { CndTagExpirationDate } from '../CndStatus/CndStatus'
import { IAvaliations, IStep } from 'egi/types'
import SignatureFlagMessage from '../SignatureFlagMessage/SignatureFlagMessage'
import ProposalEgiModel from 'egi/models/ProposalEgiModel'
import { ExpirationDateTag } from '../ExpirationDateTag/ExpirationDateTag'

const crivoInfo: {
  [key in _crivoStatuses]: { color: string, text: string }
} = {
  APR: { color: '#2CD701', text: 'Aprovado' },
  HUM: { color: '#FFC107', text: 'Mesa (humano)' },
  REC: { color: '#D70101', text: 'Recusado' }
}

export function RegistrationTagExpirationDate ({
  step
}: { step: IStep }) {
  const expirationDate = step.dueDate || null

  return (
    <ExpirationDateTag
      expirationDate={expirationDate}
    />
  )
}

const Step = ({ id, step, hasNext = false, onClick, avaliations, title, departmentRef, areaId, personType }: StepProps) => {
  const { status: situation, mode, name, internal, commentary, feedback, review } = step

  const dispatch = useDispatch()
  const user = useAuth()
  const { id: proposalId } = useProposal()
  const proposal = useProposal()
  const client = useClient()

  const status = situation || 'starting'
  const finalStatus = status

  const crivoColors = step.crivoStatus ? crivoInfo[step.crivoStatus].color : 'white'
  const crivoMessage = step.crivoStatus ? crivoInfo[step.crivoStatus].text : ''
  const disabledButton = ProposalEgiModel.disabledButtonsDetails(user, step.view)

  const replaceNewId = (step.name).replaceAll(' ', '').replaceAll('/', '').replaceAll('+', '').replaceAll('(', '').replaceAll(')', '')

  async function generateLink () {
    try {
      const response = await unauthed.createStepLink({ stepId: id, proposalId: String(proposalId) })
      const link = response
      if (!link) throw new Error('Failed to create Step Link')
      return link
    } catch (err) {
      message.error(err.message || 'Falha ao gerar link')
    }
  }

  async function sendEmail () {
    try {
      const response = await unauthed.sendStepLink({ stepId: id, proposalId: String(proposalId) })
      swal.basic({ title: 'Sucesso', icon: 'success', text: response.message })
    } catch (err) {
      swal.basic({ title: 'Atenção', icon: 'warning', text: err.message })
    }
  }

  function sortAvaliations (avaliations?: IAvaliations[]) {
    if (!avaliations) return undefined

    const avaliationsAreas = [
      'valor-declarado',
      'valor-de-credito',
      'valor-de-credito-aprovado',
      'valor-de-mercado',
      'valor-de-liquidacao-forcada',
      'valor-area-averbada'
    ]

    const sortedList: any[] = avaliationsAreas
      .map(item => [...avaliations].find((value: IAvaliations) => value.name === item))

    const sortedFiltered = sortedList.filter(item => item)
    return sortedFiltered
  }

  async function onStepClick () {
    onClick && onClick()

    const finalStep: IStep = {
      ...step,
      personType,
      description: step.description || undefined,
      activeMode: step.activeMode || undefined,
      ref: departmentRef || undefined,
      areaId: step.areaId || areaId,
      avaliations: sortAvaliations(avaliations),
      id,
      internal,
      mode,
      name,
      situation,
      commentary,
      title
    }

    if (finalStep.modeOptions && finalStep.modeOptions.modes && finalStep.modeOptions.modes[0]) finalStep.modeOptions.selected = finalStep.modeOptions.modes[0]

    dispatch(stepFill(finalStep))
    dispatch(stepSetOpenDrawerLoading({ loading: true }))
  }

  const loadingStep = proposal.step.loading && id === proposal.step.id

  if (step.display === false) return (<></>)

  return (
    <Fragment>
      <Row style={{ position: 'relative', minHeight: 55 }} wrap={false}>
        <Col>
          {loadingStep ? (<LoadingOutlined style={{ fontSize: 25 }} />) : (
            <div
              className="pointer"
              onClick={() => onStepClick()}
            >
              {finalStatus === 'reservation' && (
                <div className="step-reservation">
                  <InfoCircleFilled />
                </div>
              )}
              <Icons name={finalStatus} color={varColors[finalStatus]} />
            </div>
          )}

          {hasNext && <hr className="appendice-size" />}
        </Col>

        <Col className="responsive-cols-step">
          <div className='fixed-hover'>
            <Button
              id={`test-button-step-${replaceNewId}`}
              style={{ height: '32px !important' }}
              className='no-hover pr-2 pt-0 pl-1 mb-3'
              type="text"
              disabled={disabledButton || loadingStep}
              onClick={() => onStepClick()}
            >
              <Paragraph
                className="bold department-card-step-font"
                style={{ color: (disabledButton) ? '#65737A' : 'var(--primary-color)' }}
              >
                {name}
              </Paragraph>
            </Button>
          </div>

          <Row className="mb-3">
            {review && (
              <Col>
                <div className='fixed-hover mb-2'>
                  <Button
                    type="ghost"
                    className="deliberar-button no-hover"
                    onClick={() => onStepClick()}
                    disabled={disabledButton || loadingStep}
                  >
                    Deliberar
                  </Button>
                </div>
              </Col>
            )}

            {ProposalEgiModel.canShareLink({ userLevel: user.level, step, proposal }) && (
              <div className="mb-1">
                <ShareButtons
                  directSend={ProposalEgiModel.canDirectSend(step.mode, step.modeOptions) ? sendEmail : undefined}
                  generateLink={generateLink}
                  phone={client.cellphone}
                  inSteps={true}
                />
              </div>
            )}

            {ProposalEgiModel.canRegistrateDueDate(step.slug || '', situation as _proposalStatuses) && (
              <Col>
                <RegistrationTagExpirationDate step={step} />
              </Col>
            )}

            {ProposalEgiModel.showSignatureFlagMessage({
              userLevel: user.level,
              situation: situation as _proposalStatuses,
              stepMode: step.mode,
              stepModeOptions: step.modeOptions
            }) && (
              <Col>
                <SignatureFlagMessage
                  signatures={step.signatures}
                />
              </Col>
            )}

            {ProposalEgiModel.canSeeCrivoMessage(user.level, step.mode) && (
              <Col>
                <p
                  className="proposal-flag proposal-flag--crivo"
                  style={{ backgroundColor: `${crivoColors}`, borderColor: `${crivoColors}` }}
                >
                  {crivoMessage}
                </p>
              </Col>
            )}

            {(feedback && feedback[feedback.length - 1] && ProposalEgiModel.canShowFeedBackMessage(user.level, situation as _proposalStatuses)) && (
              <p className="paragraph-feedback">
                {feedback[feedback.length - 1].message}
              </p>
            )}

            <Col>
              <CndTagStatus step={step} />
            </Col>

            <Col>
              <CndTagExpirationDate step={step} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  )
}

export default Step

import { IInitialResources } from './types'

const INITIALSTATE: IInitialResources = {
  products: [],
  providers: [],
  areas: [],
  areaNames: [],
  states: [],
  countries: [],
  ibgeStates: [],
  banks: [],
  educations: [],
  consultants: [],
  economicActivity: [],
  legalNature: [],
  goals: [],
  refusedReasons: [],
  commercialSupport: [],
  allTelemarketing: [],
  declines: [],
  minutaMold: [],
  allBusinessManagers: [],
  userPermissions: null
}

const resources = (state = INITIALSTATE, action: any) => {
  switch (action.type) {
    case 'RESOURCES_PRODUCTS_SUCCEED': {
      return {
        ...state,
        products: action.payload.products
      }
    }

    case 'RESOURCES_PROVIDERS_SUCCEED': {
      return {
        ...state,
        providers: action.payload.providers
      }
    }

    case 'RESOURCES_DECLINES_SUCCEED': {
      return {
        ...state,
        declines: action.payload.declines
      }
    }

    case 'RESOURCES_PROVIDERS_RESET': {
      return {
        ...state,
        providers: []
      }
    }

    case 'RESOURCES_AREAS_SUCCEED': {
      return {
        ...state,
        areas: action.payload.areas
      }
    }

    case 'RESOURCES_AREA_NAMES_SUCCEED': {
      return {
        ...state,
        areaNames: action.payload.areaNames
      }
    }

    case 'RESOURCES_STATES_SUCCEED': {
      return {
        ...state,
        states: action.payload.states
      }
    }

    case 'RESOURCES_STATES_IBGE_SUCCEED': {
      return {
        ...state,
        ibgeStates: action.payload.states
      }
    }

    case 'RESOURCES_BANKS_SUCCEED': {
      return {
        ...state,
        banks: action.payload.banks
      }
    }

    case 'RESOURCES_EDUCATIONS_SUCCEED': {
      return {
        ...state,
        educations: action.payload.educations
      }
    }

    case 'RESOURCES_CONSULTANTS_SUCCEED': {
      return {
        ...state,
        consultants: action.payload.consultants
      }
    }

    case 'RESOURCES_BUSINESS_MANAGERS_SUCCEED': {
      return {
        ...state,
        allBusinessManagers: action.payload.allBusinessManagers
      }
    }

    case 'RESOURCES_SUPPORT_SUCCEED': {
      return {
        ...state,
        commercialSupport: action.payload.commercialSupport
      }
    }

    case 'RESOURCES_TELEMARKETING_SUCCEED': {
      return {
        ...state,
        allTelemarketing: action.payload.allTelemarketing
      }
    }

    case 'RESOURCES_COUNTRIES_SUCCEED': {
      return {
        ...state,
        countries: action.payload.countries
      }
    }

    case 'RESOURCES_LEGAL_NATURE_SUCCEED': {
      return {
        ...state,
        legalNature: action.payload.legalNature
      }
    }

    case 'RESOURCES_ECONOMIC_ACTIVITY_SUCCEED': {
      return {
        ...state,
        economicActivity: action.payload.economicActivity
      }
    }

    case 'RESOURCES_REFUSED_REASONS_SUCCEED': {
      return {
        ...state,
        refusedReasons: action.payload.refusedReasons
      }
    }

    case 'RESOURCES_GOALS_SUCCEED': {
      return {
        ...state,
        goals: action.payload.goals
      }
    }

    case 'RESOURCES_USER_PERMISSIONS_SUCCEED': {
      return {
        ...state,
        userPermissions: action.payload.userPermissions
      }
    }

    case 'RESOURCES_MINUTA_MOLD_SUCCEED': {
      return {
        ...state,
        minutaMold: action.payload.minutaMold
      }
    }

    case 'SET_RESOURCES_MINUTA_MOLD_REQUESTED': {
      return {
        ...state,
        minutaMold: action.payload
      }
    }

    case 'RESOURCES_RESET': {
      return INITIALSTATE
    }

    default:
      return state
  }
}

export default resources

import React, { useCallback, useEffect, useState } from 'react'
import { UploadOutlined, ReloadOutlined } from '@ant-design/icons'
import { ProposalStepContent } from '../ProposalSteps'
import { Button, message, Result, Tag, Upload } from 'antd'
import { UploadFile, UploadFileStatus } from 'antd/es/upload/interface'
import { UploadRequestOption } from 'rc-upload/lib/interface'
import axios, { AxiosRequestConfig } from 'axios'
import { getFileType, previewFileTypes } from 'components/DocumentUpload/DocumentUpload'
import { swalError } from 'components/SwalError/SwalError'
import filePdf from 'assets/icons/FilePdfFilled.svg'
import SkeletonInput from 'ecp/components/SkeletonInput/SkeletonInput'
import format from 'utils/format'
import WarningText from 'components/WarningText/WarningText'
import { downloadFile } from 'utils/download'
import { IDynamicProposalStep } from 'ecp/app/Proposals/proposalInterfaces'
import { CAPTIONS_TRANSLATED_HISTORY } from 'components/Captions/Captions'
import { colors } from 'styles/colors'
import DynamicProposalECPFRepository from 'ecpf/repositories/DynamicProposalECPFRepository'

interface IInfoContainerProps {
  step: IDynamicProposalStep
}

const InfoContainer = ({ step }: IInfoContainerProps) => {
  const { maxSize, type } = step?.file ?? {}

  if (!step?.canUploadFile) {
    return (
      <div className="p-2">
        <WarningText
          type="warning"
          iconColor='var(--warning-status)'
        >
          <div>
            <ProposalStepContent.Subtitle className="upload-step-section__subtitle">
              Não é possivel adicionar arquivos ao passo {step.name} no momento ou seu usuário não tem permissão.
            </ProposalStepContent.Subtitle>

            <ProposalStepContent.Description className='mb-0'>
              Só é possivel fazer o upload de arquivos quando o status do passo estiver em
              <span className='ml-1'>
                {(Array.isArray(step.canUploadFileStatuses) && step.canUploadFileStatuses.length > 0)
                  ? step.canUploadFileStatuses.map((status, idx) => <Tag key={idx} color={colors[status]}>{CAPTIONS_TRANSLATED_HISTORY.ecp[status]}</Tag>)
                  : ' -'
                }
              </span>
            </ProposalStepContent.Description>

            <ProposalStepContent.Description>
              Aguarde próximas atualizações para prosseguir com sua proposta.
            </ProposalStepContent.Description>
          </div>
        </WarningText>
      </div>
    )
  }

  return (
    <div className='p-2'>
      <WarningText
        type="info"
        iconColor='var(--blue-status)'
      >
        <ProposalStepContent.Subtitle className="upload-step-section__subtitle">
          Adicionar arquivos ao passo {step.name}
        </ProposalStepContent.Subtitle>

        <ProposalStepContent.Description>
          Adicione abaixo os arquivos que serão vinculados ao passo {step.name}. <b>Fique atento</b> aos critérios para subir o arquivo:
        </ProposalStepContent.Description>

        <ol className='upload-step-section__list ml-2'>
          {maxSize && <li>O tamanho máximo do arquivo é <b>{maxSize}</b> mb</li>}
          {type && <li>As extenções permitidas são {format.formatList(type ?? [])}</li>}
        </ol>
      </WarningText>
    </div>
  )
}

export const useUploadStepFiles = () => {
  const [fileList, setFileList] = useState<Array<UploadFile<any> & { _id: string }>>([])

  const fileFactory = (file: UploadFile, options: {
    url: string,
    thumbUrl: string,
    _id: string
  }) => {
    const { url, thumbUrl, _id } = options || {}

    return {
      uid: file.uid,
      name: file.name,

      status: file.status,
      size: file.size,
      type: file.type,
      percent: file.percent,

      url,
      thumbUrl,
      _id
    }
  }

  const choosePreviewThumb = (file: UploadFile, url: string) => {
    if (file.type === 'application/pdf') return filePdf
    return url
  }

  const addFile = (file: UploadFile) => {
    const fileURL = URL.createObjectURL(
      file.originFileObj || file
    )

    const newFile = fileFactory(file, {
      url: fileURL,
      thumbUrl: choosePreviewThumb(file, fileURL),
      _id: file.uid
    })

    setFileList(prev => ([
      ...prev,
      newFile
    ]))
  }

  const deleteFile = (fileId: string) => {
    setFileList(prev => {
      return (prev || []).filter(file => file.uid !== fileId)
    })
  }

  const changeFileStatus = (fileId: string, newStatus: UploadFileStatus) => {
    setFileList(prev => {
      return (prev || []).map(
        file => {
          if (file.uid === fileId && newStatus) {
            return ({
              ...file,
              status: newStatus
            })
          }

          return file
        }
      )
    })
  }

  const updateFileId = (fileId: string, newFileId?: string) => {
    setFileList(prev => {
      return (prev || []).map(
        file => {
          if (file.uid === fileId && newFileId) {
            return ({
              ...file,
              _id: newFileId
            })
          }

          return file
        }
      )
    })
  }

  const downloadAndIncrementFiles = async (paths: Array<{ _id: string, location: string }>) => {
    for (const path of paths) {
      const { location, _id } = path || {}

      const res = await axios({ url: location, responseType: 'blob' })
      const fileUrlPaths = location.split('/')
      const filename = fileUrlPaths[fileUrlPaths.length - 1]

      const file: UploadFile & { _id: string } = {
        uid: _id,
        name: filename,
        status: 'done',
        size: res.data.size,
        type: getFileType(filename),
        url: location,
        originFileObj: new File([
          res.data
        ], filename, {
          type: getFileType(filename)
        }),
        _id
      }

      addFile(file)
    }
  }

  const updateFilePercentage = (
    fileId: string,
    loaded: number,
    total: number
  ) => {
    const percent = Math.round((loaded / total) * 100)

    setFileList(
      prevFileList => {
        return prevFileList.map(
          file => {
            if (file.uid === fileId) {
              return ({
                ...file,
                status: 'uploading',
                percent
              })
            }

            return file
          }
        )
      }
    )
  }

  const resetFiles = () => {
    setFileList([])
  }

  const findFileByUid = (fileId: string) => {
    return fileList.find(file => file.uid === fileId)
  }

  return {
    fileList,
    addFile,
    updateFileId,
    updateFilePercentage,
    downloadAndIncrementFiles,
    deleteFile,
    resetFiles,
    findFileByUid,
    changeFileStatus
  }
}

interface IUploadStepFilesProps {
  proposalId?: string
  step: IDynamicProposalStep
  departmentId?: string
  updateProposal: (stepId: string) => void
}

function UploadStepFiles ({ updateProposal, step, departmentId, proposalId }: IUploadStepFilesProps) {
  const {
    fileList,
    addFile,
    updateFilePercentage,
    deleteFile,
    updateFileId,
    downloadAndIncrementFiles,
    resetFiles,
    changeFileStatus,
    findFileByUid
  } = useUploadStepFiles()

  const canRemoveFile = step?.canRemoveFile

  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const onUploadFile = async (
    request: UploadRequestOption,
    ids: { stepId: string, proposalId: string, departmentId: string }
  ) => {
    const { file, onSuccess, onError } = request

    try {
      addFile(file)

      const formData = new FormData()

      formData.append('file', file)

      const config: AxiosRequestConfig = {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 90000,
        onUploadProgress: ({ total, loaded }) => {
          if (total) {
            updateFilePercentage(
              file.uid,
              loaded,
              total
            )
          }
        }
      }

      const body = {
        file
      }

      const response = await DynamicProposalECPFRepository.uploadFile(ids, body, config)
      const { _id } = response.data.data || {}
      if (!_id) throw new Error('Falha ao obter identificador da proposta')

      onSuccess?.(file, response.request)
      updateFileId(file.uid, _id)
      message.success('Arquivo enviado com sucesso')
      updateProposal(ids.stepId)
    } catch (err) {
      swalError({ title: 'Atenção', err, icon: 'warning' })
      onError?.(err)
    }
  }

  const onUploadCustomRequest = async (
    request: UploadRequestOption
  ) => {
    if (step?._id && departmentId && proposalId) {
      onUploadFile(request, {
        stepId: step._id,
        departmentId: departmentId,
        proposalId: proposalId
      })
    }
  }

  const onDeleteFile = async (
    file: UploadFile<any>,
    ids: { stepId: string, departmentId: string, proposalId: string }
  ) => {
    try {
      const currentFile = findFileByUid(file.uid)
      if (!currentFile?._id) throw new Error('Falha ao identificar id do documento.')

      changeFileStatus(file.uid, 'uploading')
      const response = await DynamicProposalECPFRepository.deleteFile({ ...ids, fileId: currentFile._id })
      deleteFile(file.uid)
      message.success(response.data?.message)
    } catch (err) {
      changeFileStatus(file.uid, 'success')
      swalError({ title: 'Atenção', err, icon: 'warning' })
    }
  }

  const onRemove = async (
    file: UploadFile<any>
  ) => {
    if (!canRemoveFile) return message.error('Você não tem permissão para deletar esse arquivo')
    if (file.status === 'error') {
      deleteFile(file.uid)
    }

    if (step?._id && departmentId && proposalId && file.status !== 'error') {
      onDeleteFile(
        file, {
          stepId: step._id,
          departmentId: departmentId,
          proposalId: proposalId
        }
      )
    }
  }

  const fetchFiles = useCallback(async (
    proposalId: string,
    stepId: string,
    departmentId: string
  ) => {
    try {
      setLoading(true)
      const response = await DynamicProposalECPFRepository.listFiles({
        proposalId,
        stepId,
        departmentId
      })

      await downloadAndIncrementFiles(response.data.data?.files || [])

      setLoading(false)
      setErrorMessage('')
    } catch (err) {
      setLoading(false)
      setErrorMessage(err?.message)
    }
  }, [])

  const onRetryFetchFiles = async () => {
    if (proposalId && step._id && departmentId) {
      fetchFiles(
        proposalId,
        step._id,
        departmentId
      )
    }
  }

  useEffect(() => {
    if (proposalId && step._id && departmentId) {
      fetchFiles(proposalId, step._id, departmentId)
    }

    return () => {
      resetFiles()
    }
  }, [
    step._id,
    departmentId,
    proposalId
  ])

  if (loading) {
    return (
      <div>
        <div className='upload-step-section__skeleton-button-container'>
          <SkeletonInput className='upload-step-section__skeleton-button' />
        </div>

        <div className='upload-step-section__skeleton-container'>
          {new Array(3).fill('').map((_, idx) =>
            <SkeletonInput
              className='upload-step-section__skeleton-document'
              key={idx}
            />
          )}
        </div>
      </div>
    )
  }

  if (errorMessage) {
    return (
      <div className='upload-step-section__error-container'>
        <Result
          title='Ops, que algo deu errado.'
          status='error'
          subTitle={errorMessage}
        />

        <Button
          onClick={onRetryFetchFiles}
          type='primary'
        >
          <ReloadOutlined /> Tentar novamente
        </Button>
      </div>
    )
  }

  const onDownload = async (file: UploadFile | undefined) => {
    if (!file?.url) throw new Error('Arquivo não encontrado para download')
    setLoading(true)
    try {
      await downloadFile({
        fileUrl: file?.url,
        title: `${step.name}_documento${file.name}`
      })
    } catch (err) {
      message.error(err.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='upload-step-section__files-container'>
      <Upload
        action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
        listType="picture"
        fileList={step.canListFile ? fileList : undefined}
        onRemove={onRemove}
        accept={previewFileTypes.toString()}
        customRequest={onUploadCustomRequest}
        onDownload={onDownload}
        showUploadList={{
          showDownloadIcon: true,
          showRemoveIcon: canRemoveFile
        }}
      >
        {step.canUploadFile && <Button
          type='ghost'
          icon={<UploadOutlined />}
        >
          Adicionar Arquivo
        </Button>
        }
      </Upload>
    </div>
  )
}

interface IUploadStepSectionProps {
  proposalId?: string
  step: IDynamicProposalStep
  departmentId?: string
  updateProposal: (stepId: string) => void
}

export function UploadStepSection ({ proposalId, step, departmentId, updateProposal }: IUploadStepSectionProps) {
  return (
    <div className='upload-step-section'>
      <InfoContainer step={step}/>

      <UploadStepFiles
        updateProposal={updateProposal}
        step={step}
        proposalId={proposalId}
        departmentId={departmentId}
      />
    </div>
  )
}

import React from 'react'
import { Col, Row } from 'antd'
import DetailsItem from 'ecp/components/DetailsItem/DetailsItem'
import dayjs from 'dayjs'
import { IOnidataClient } from 'ecp/models/ClientModel'

function PersonalityFieldsDisplay ({ personalityInfos }: { personalityInfos: IOnidataClient }) {
  return (
    <Row gutter={[25, 25]}>
      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='Nome'
          value={personalityInfos?.nome ?? '-'}
        />
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='Sobrenome'
          value={personalityInfos?.sobrenome ?? '-'}
        />
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='Data de nascimento'
          value={
            (personalityInfos.data_nascimento && dayjs(personalityInfos.data_nascimento).isValid())
              ? dayjs(personalityInfos.data_nascimento).format('DD/MM/YYYY')
              : '-'}
        />
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='Sexo'
          value={personalityInfos?.sexo ?? '-'}
        />
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='Estado civil'
          value={personalityInfos?.estado_civil ?? '-'}
        />
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='Número de dependentes'
          value={personalityInfos?.dependentes?.toString() ?? '-'}
        />
      </Col>
    </Row>
  )
}

export default PersonalityFieldsDisplay

import React, { useEffect, useState } from 'react'
import { useAuth } from 'hooks'
import NotificationComponent from './NotificationComponent'
import { INotification, INotificationAll } from 'components/Notification/types'
import NotificationRepository from 'egi/repositories/NotificationRepository'

export function IconNotification () {
  const user = useAuth()

  const [loading, setLoading] = useState(false)
  const [notificationsAll, setNotificationsAll] = useState<INotificationAll>()
  const [notifications, setNotifications] = useState<INotification[]>([])
  const [filter, setFilter] = useState<string>('read')
  const [notificationsUnread, setNotificationsUnread] = useState<number>()
  const [page, setPage] = useState<number>(1)

  const getRightFilters = (filter: string) => {
    if (filter === 'received') return { read: true, sent: false }
    if (filter === 'sent') return { read: false, sent: true }

    return { read: false, sent: false }
  }

  const getCount = async () => {
    try {
      const response = await NotificationRepository.count()
      setNotificationsUnread(response?.data?.data?.notifications?.totalUnread || 0)
    } catch (err) {
      console.error(err)
    }
  }

  const getNotification = async (page: number, filter: string) => {
    setLoading(true)
    if (user._id) {
      const rightfilters = getRightFilters(filter)
      const params = {
        page,
        ...rightfilters
      }

      try {
        const response = await NotificationRepository.clientList(user._id, { params })

        setNotificationsAll(response?.data?.data)

        setLoading(false)
      } catch (err) {
        setLoading(false)
        console.error(err)
      }
    }
  }

  const handleFilterChange = (value: string) => {
    setFilter(value)
    setPage(1)
  }

  const handlePageChange = (page: number) => {
    setPage(page)
  }

  useEffect(() => {
    getCount()
  }, [])

  useEffect(() => {
    getNotification(page, filter)
  }, [page, filter])

  return (
    <div className="navbar-notification">
      <NotificationComponent
        filter={filter}
        getNotification={getNotification}
        handleFilterChange={handleFilterChange}
        notificationsLength={notificationsUnread}
        getCount={getCount}
        notifications={notifications}
        setNotifications={setNotifications}
        notificationsAll={notificationsAll}
        handlePageChange={handlePageChange}
        loading={loading}
        setLoading={setLoading}
      />
    </div>
  )
}

import React, { Fragment } from 'react'
import { Col, Statistic } from 'antd'
import { IStatisticsSimulator } from './types'

function StatisticsSimulation ({ data }: { data: IStatisticsSimulator[] }) {
  return (
    <Fragment>
      {data.map((item) => (
        <Col key={item.title}>
          <Statistic
            key={item.title}
            className='statistic-simulation-card-primary'
            title={item.title}
            value={item.value}
          />
        </Col>
      ))}
    </Fragment>
  )
}

export default StatisticsSimulation

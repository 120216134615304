import loadable from '@loadable/component'
import Loading from 'components/Loading/Loading'
import { timeout } from 'promise-timeout'
import React from 'react'
import { TIMEOUT_LOADER } from 'utils/globals'

const CovenantList = loadable(
  () => timeout(import(/* webpackChunkName: "Covenant" */ 'ecp/app/Covenant/views/CovenantList/CovenantList'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const CovenantForm = loadable(
  () => timeout(import(/* webpackChunkName: "Covenant" */ 'ecp/app/Covenant/views/CovenantForm/CovenantForm'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const CovenantDetails = loadable(
  () => timeout(import(/* webpackChunkName: "Covenant" */ 'ecp/app/Covenant/views/CovenantDetails/CovenantDetails'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

export { CovenantList, CovenantForm, CovenantDetails }

import React, { Fragment } from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { timeAsDayjs } from 'utils/time'
import { ISignatures } from 'egi/app/ProposalEgi/proposalEgiInterfaces'

type Props = {
  signatures?: ISignatures[]
}

function SignatureFlagMessage ({ signatures }: Props) {
  if (signatures && signatures[0] && signatures[0].createdAt) {
    return (
      <div className="flex flex-align-center">
        <p className="proposal-flag proposal-flag--signature">
          Assinado em - {timeAsDayjs(signatures[0].createdAt, { applyTimezone: false }).format('DD/MM/YYYY HH:mm')}
        </p>

        {signatures[0].uuid && (
          <Tooltip
            destroyTooltipOnHide={{ keepParent: false }}
            trigger={['click', 'hover']}
            color="white"
            title={
              <Fragment>
                <h1 className="simulator-tooltip-h1">Código identificador:</h1>
                <p className="simulator-tooltip-paragraph">{signatures[0].uuid}</p>
              </Fragment>
            }
          >
            <InfoCircleOutlined />
          </Tooltip>
        )}
      </div>
    )
  }

  return <></>
}

export default SignatureFlagMessage

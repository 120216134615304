
import { AxiosRequestConfig } from 'axios'
import { Repository } from 'ecp/repositories/Repository'
import api from 'services/api'

class PromotersRepository extends Repository {
  async changeCommission (promoterId: string, body: { commissionPercentage: number}, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.patch(`${this.path}/${promoterId}/commission`, body, config)
    )
  }

  async fetchSubPromoters (userId: string, config?: AxiosRequestConfig) {
    return Repository.handle<{ promoters: Array<{ id: string, name: string, type?: string }> }>(() =>
      this.api.get(`${this.path}/${userId}/select`, config)
    )
  }

  async changeManager (data :{ promoterId: string, managerId: string }, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.patch(`${this.path}/manager`, data, config)
    )
  }

  async changeChannel (data :{ promoterId: string, channel: string }, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.patch(`${this.path}/manager`, data, config)
    )
  }
}
export default new PromotersRepository({ api, path: '/promoters' })

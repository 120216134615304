import React from 'react'

export type IPressableProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

export function Pressable ({ className = '', children, ...props }: IPressableProps) {
  return (
    <button
      className={`pressable ${className}`}
      {...props}
    >
      {children}
    </button>
  )
}

import { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { UNAUTHS_PATHS } from 'routes/unauthRoutes'
import { IFirstAccessTokenInfo } from '../firstAccesInterfaces'

function useFirstAccessTokenInfo () {
  const { state: tokenInfo } = useLocation<IFirstAccessTokenInfo | null>()
  const { tokenJwt } = useParams<{ tokenJwt?: string }>()
  const history = useHistory()

  useEffect(() => {
    if (!tokenInfo || !tokenJwt) history.push(UNAUTHS_PATHS.FIRST_ACCESS_CLIENT_ECP_CHECK_LINK)
  }, [tokenInfo, tokenJwt])

  return { tokenInfo: tokenInfo || {} as IFirstAccessTokenInfo }
}

export default useFirstAccessTokenInfo

import { serverTableDefaultFilters } from 'utils/globals'
import { Actions, IInitialTables } from './types'

const defaultStatus = {
  page: 1,
  limit: 15
}

const initialState: IInitialTables = {
  backofficeAreas: [],
  row: {},
  filters: defaultStatus,
  promoterSetFn: undefined,
  update: false,
  ignoreAll: false,
  previousTable: undefined,
  sort: undefined,
  totalDocs: undefined
}

const tables = (state = initialState, action: Actions) => {
  switch (action.type) {
    case 'TABLE_SET_BACKOFFICE_AREAS':
      return {
        ...state,
        backofficeAreas: action.payload.areas
      }

    case 'TABLE_SET_ROW_VALUES': {
      return {
        ...state,
        row: action.payload.row
      }
    }

    case 'TABLE_UPDATE': {
      return {
        ...state,
        update: !state.update
      }
    }

    case 'TABLE_SET_FILTER_VALUES': {
      return {
        ...state,
        filters: action.payload.filters
      }
    }

    case 'SET_PREVIOUS_TABLE': {
      return {
        ...state,
        previousTable: action.payload
      }
    }

    case 'TABLE_SET_PROMOTER_FN': {
      return {
        ...state,
        promoterSetFn: action.payload.promoterSetFn
      }
    }

    case 'TABLE_DISABLE_ALL': {
      return {
        ...state,
        ignoreAll: action.payload.ignoreAll

      }
    }

    case 'TABLE_RESET_FILTER_VALUES': {
      return {
        ...state,
        filters: {
          ...defaultStatus,
          leadStatus: action.payload.hasLeadStatus === true ? serverTableDefaultFilters.leads.join(',') : undefined,
          proposalStatus: action.payload.hasProposalStatus === true ? serverTableDefaultFilters.proposals.join(',') : undefined,
          hasAction: action.payload.hasAction === true ? true : undefined,
          trimester: action.payload.trimester === true ? true : undefined,
          needVinculation: action.payload.needVinculation === true ? true : undefined
        },
        ignoreAll: false
      }
    }

    case 'TABLE_SET_TOTAL_DOCS': {
      return {
        ...state,
        totalDocs: action.payload.totalDocs
      }
    }

    case 'TABLES_RESET_ALL':
      return initialState

    default:
      return state
  }
}

export default tables

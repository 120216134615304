import React, { Fragment, MutableRefObject, useRef, useState, useEffect } from 'react'
import { Button, Form, Radio } from 'antd'
import { InputMoney } from 'components'
import { RadioChangeEvent } from 'antd/lib/radio'
import { simulation as consumerSimulation } from 'egi/consumers'
import format from 'utils/format'
import { validateResponse } from 'utils/validate'
import swal from 'utils/swal'
import { useAuth, useClient, useSimulation } from 'hooks'
import { simulationPricngValues, simulationSetId, simulationSteps, simulationValues } from 'store/modules/simulation/actions'
import { useDispatch } from 'react-redux'
import { ISimulatorCommonStep } from '../../simulatorInterfaces'
import { ISimulationValues } from 'store/modules/simulation/types'
import LeadsRepository from 'egi/repositories/LeadsRepository'
import { tablesUpdate } from 'store/modules/tables/actions'
import { invalidSimulationFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'

interface ISimulatorPropertyStepValues {
  sub: string
  propertyValue: number
  settled: string | boolean | undefined
  outstandingBalance?: number
  currentStep: number
  simulationId?: string
  clientId?: string
  userId?: string
  remaking?: boolean
  product: 'home-equity',
}

function SimulatorPropertyStep ({ simulatorData, remaking, onlyCEP }: ISimulatorCommonStep) {
  const [settled, setSettled] = useState<boolean>(true)
  const [errors, setErrors] = useState<any>({})
  const [subType, setSubType] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [propertyValue, setPropertyValue] = useState<number | undefined>(undefined)

  const [formRef] = Form.useForm()
  const inputRef: MutableRefObject<any> = useRef(null)
  const dispatch = useDispatch()
  const simulation = useSimulation()
  const user = useAuth()
  const client = useClient()
  const cannotReturnToFirst = (user.isAuth && (user.level === 'client' || user.level === 'promoter')) || remaking

  const onSubmit = async (values: ISimulatorPropertyStepValues) => {
    const allSimulationValues = Object.assign({}, simulation.values)

    setLoading(true)
    if (propertyValue) values.propertyValue = propertyValue
    if (user.level === 'client') values.clientId = user._id

    if (values.outstandingBalance) values.outstandingBalance = Number(values.outstandingBalance)
    else values.outstandingBalance = undefined

    const clientId = simulatorData?.clientId || values.clientId || client._id
    const remakingSimulation = remaking ? true : false

    const data: ISimulatorPropertyStepValues & ISimulationValues = {
      ...allSimulationValues,
      clientId: clientId || undefined,
      sub: subType,
      currentStep: 2,
      outstandingBalance: values.outstandingBalance,
      propertyValue: Number(values.propertyValue),
      settled: settled,
      remaking: remakingSimulation,
      simulationId: simulation.id || simulatorData?.simulationId,
      product: 'home-equity'
    }

    try {
      const response = await consumerSimulation.simulation({ ...data, deadline: undefined, creditValue: undefined })
      const simulationId = response.data.simulation.simulationId
      dispatch(simulationValues(data, 2))
      setLoading(false)
      if (!simulation.id || !simulatorData?.simulationId) dispatch(simulationSetId(simulationId))
      swal.basic({ title: 'Sucesso', text: response.message, icon: 'success' })
    } catch (err) {
      if (err.data?.invalid) {
        const errors: any = {}
        setErrors(validateResponse(err.data?.invalid))
        if (errors.propertyValue && errors.propertyValue.includes('valor máximo.')) setErrors({ propertyValue: errors.propertyValue + ` ${format.formatBRL(simulation.pricing?.propertyMax)}` })

        invalidSimulationFieldsError({ invalidFields: err.data?.invalid })

        return
      }

      if (err?.code === 412) swal.htmlBasic({ icon: 'warning', title: 'Atenção', text: err.message })

      if (err?.data?.needsVinculation) {
        swal.confirmNegate({
          icon: 'question',
          title: 'Atenção',
          text: 'Você gostaria de se vincular a essa proposta?',
          confirm: async () => {
            try {
              if (!simulation?.values?._id) throw new Error('Esta proposta não está válida para alteração')
              const response = await LeadsRepository.changeCommercial(simulation?.values?._id, { commercialId: user._id })
              const message = response.data.message

              swal.basic({ title: 'Sucesso!', text: message, icon: 'success' })
              dispatch(tablesUpdate())
            } catch (err) {
              swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
            }
          }
        })
      }

      setErrors({})
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    } finally {
      setLoading(false)
    }
  }

  const simulationEntry = async (subType: string) => {
    const data: any = {
      product: 'home-equity',
      sub: subType
    }

    try {
      const response = await consumerSimulation.entries(data)
      const ranges = response.data.pricing
      dispatch(simulationPricngValues(ranges))
    } catch (err) {
      if (err?.code === 412) swal.htmlBasic({ icon: 'warning', title: 'Atenção', text: err.message })
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      console.warn(err)
    }
  }

  useEffect(() => {
    function onFill () {
      const data = Object.assign({}, simulation.values) || {}

      if (data.settled === true) setSettled(true)
      else setSettled(false)

      if (data.propertyValue) setPropertyValue(data.propertyValue)

      if (data.sub) {
        setSubType(data.sub)
        simulationEntry(data.sub)
      } else simulationEntry('home')

      data.settled = data.settled ? 'yes' : 'no'

      if (formRef) formRef.setFieldsValue({ ...data })
    }

    onFill()
  }, [simulation.values])

  useEffect(() => {
    if (propertyValue === undefined && simulation.values?.propertyValue === undefined) setPropertyValue(simulation?.pricing?.propertyMin)
  }, [simulation?.pricing?.propertyMin])

  return (
    <Form
      className="simulator-form"
      layout="vertical"
      form={formRef}
      onFinish={onSubmit}
    >
      <Fragment>
        <Form.Item
          name='sub'
          label={<label className="simulator-label text-left">Tipo de imóvel que será dado como garantia:</label>}
          help={errors.sub && errors.sub}
          validateStatus={errors.sub && 'error'}
        >
          <Radio.Group
            className="w-100 simulator-radio"
            size="large"
            onChange={(event: RadioChangeEvent) => setSubType(event.target.value)}
          >
            <Radio.Button id="subProduct-home" onClick={() => simulationEntry('home')} value="home">Casa</Radio.Button>
            <Radio.Button id="subProduct-apartment" onClick={() => simulationEntry('apartment')} value="apartment">Apartamento</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="propertyValue"
          label={<label className="simulator-label">Valor do imóvel</label>}
          help={errors.propertyValue && errors.propertyValue}
          validateStatus={errors.propertyValue && 'error'}
        >
          <div className="position-relative mb-0">
            <InputMoney
              inputRef={inputRef}
              id="propertyValue"
              defaultValue={propertyValue}
              value={propertyValue}
              onChange={(value: any) => { setPropertyValue(format.formatMoneySend(value)) }}
              className="simulator-input ant-input"
              placeholder="R$"
            />

            <span className="simulation-tag-input mb-0 user-select" onClick={() => inputRef.current.focus()}>
              Valor mínimo: <label className="simulation-values-text">
                {format.formatBRLMinAndMax(simulation.pricing?.propertyMin)}
              </label>
            </span>
          </div>
        </Form.Item>

        <Form.Item
          name='settled'
          className="simulation-settled"
          label={<label className="simulator-label">O imóvel está quitado?</label>}
          help={errors.settled && errors.settled}
          validateStatus={errors.settled && 'error'}
        >
          <Radio.Group
            className="w-100 simulator-radio"
            defaultValue="yes"
            size="large"
          >
            <Radio.Button value="yes" id="simulation-settled" onClick={() => setSettled(true)}>Já está quitado</Radio.Button>
            <Radio.Button value="no" id="simulation-not-settled" onClick={() => setSettled(false)}>Ainda não</Radio.Button>
          </Radio.Group>
        </Form.Item>

        {!settled && (
          <Form.Item
            name="outstandingBalance"
            label={<label className="simulator-label">Qual o valor da dívida?</label>}
            help={errors.outstandingBalance && errors.outstandingBalance}
            validateStatus={errors.outstandingBalance && 'error'}
          >
            <InputMoney
              id="outstandingBalance"
              className="simulator-input ant-input"
              placeholder="R$"
            />
          </Form.Item>
        )}

        <Form.Item>
          <Button
            id="finishStep"
            className="simulator-calculation-button w-100 mt-4 mb-3"
            htmlType="submit"
            loading={loading}
          >
            PRÓXIMA ETAPA
          </Button>

          {(!cannotReturnToFirst || onlyCEP) && (
            <Button
              className="w-100 mt-1 color-primary simulator-specialist-button"
              type="ghost"
              onClick={() => { dispatch(simulationSteps(0)) }}
            >
              VOLTAR
            </Button>
          )}
        </Form.Item>
      </Fragment>
    </Form>
  )
}

export default SimulatorPropertyStep

import React from 'react'

import WarningText from 'components/WarningText/WarningText'

export function StepWarning ({ isDocument, docSize = 4000000, docLimit, description }: { isDocument?: boolean, docSize?: number, docLimit?: number, description?: string }) {
  return (
    <div className="step-warning">
      <WarningText type={'info'}>
        <h3>Instruções:</h3>

        <p>Ao clicar <b>enviar</b> suas informações serão encaminhadas para a revisão e <b>não</b> poderão ser alteradas antes da finalização desse processo para este passo.</p>

        {!isDocument && <p>Confira todos os seus dados e caso não tenha certeza das informações preencidas, clique em <b>salvar</b>, para enviar <b>mais tarde</b></p>}

        <div className='ml-3'>
          {isDocument && <li>Tamanho máximo do arquivo: <b>{docSize}MB</b></li>}
          {(description && isDocument) && (<li>{description}</li>)}
          {docLimit && <li>Limite de <b>{docLimit} {docLimit > 1 ? 'arquivos' : 'arquivo'}</b></li>}
        </div>
      </WarningText>
    </div>
  )
}

import { AxiosRequestConfig } from 'axios'
import { Repository } from 'ecp/repositories/Repository'
import api from 'services/api'

export interface IGenericOrigemResource {
  codigo: string
  descricao: string
}

class ResourcesOrigensECPFRepository extends Repository<{}> {
  async list (path: string, config?: AxiosRequestConfig) {
    return Repository.handle<{resultados: IGenericOrigemResource[], total: number}>(() =>
      this.api.get(`${this.path}/origens/${path}`, config)
    )
  }
}

export default new ResourcesOrigensECPFRepository({ api, path: '/ecp-funcao' })

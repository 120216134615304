import React, { useState } from 'react'

import { Button, Form, Input, message, Select } from 'antd'
import { CloseOutlined, SearchOutlined } from '@ant-design/icons'

import format from 'utils/format'

interface ISearchSelectProps {
  onChange: (search?: string, item?: string) => void
  onReset?: () => void
  options: { label: string, value: string }[]
  placeholder: string
  identifier?: string
  label?: string
  large?: boolean
  borderless?: boolean
  autoFocus?: boolean
  value?: string
  disabled?: boolean
  onClickSearch?: (search?: string, item?: string) => void
}

const { Option } = Select

export default function SearchIdentifierInput (props: ISearchSelectProps) {
  const { options, identifier, placeholder, label, large, borderless, autoFocus, onChange, onReset, onClickSearch, disabled } = props
  const [item, setItem] = useState<string | undefined>(identifier ?? options[0].value)
  const [errors, setErrors] = useState<{ search?: string }>({})
  const [value, setValue] = useState<string | undefined>(props?.value)

  const inputClass = 'search-identifier-input__input'
  const classModifiers = {
    large: large ? `${inputClass}__large` : '',
    borderless: borderless ? `${inputClass}__borderless` : ''
  }

  function handleClear () {
    setValue(undefined)
    onChange('', '')
    if (onReset) onReset()
  }

  function handleChange ({ value }: { value: string }) {
    if (item === 'cpf') value = format.onlyDigits(value)
    onChange(value || '', item)
    return setValue(value)
  }

  const handleClickSearch = () => {
    if (!item) {
      message.error('Escolha o que deseja pesquisar primeiro!')
      if (onReset) onReset()
      return
    }
    onClickSearch && onClickSearch(value, item)
  }

  return (
    <div className='search-identifier-input'>
      {label ? <span>{label ? label : ''}</span> : null}
      <div className='search-identifier-input__group'>
        <div className='search-identifier-input__identifier'>
          <Form.Item
            className='w-100'
            name='identifier'
            initialValue={options[0].value}
          >
            <Select
              className='w-100'
              defaultValue={item}
              disabled={disabled}
              onChange={() => {}}
              onSelect={(value) => {
                setItem(value)
                setErrors({})
              }}
            >
              {Array.isArray(options) && options.map((identifier) => (
                <Option
                  key={identifier.value}
                  value={identifier.value}
                >
                  {identifier.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div className='search-identifier-input__search'>
          <Form.Item
            name="search"
            help={errors?.search && errors?.search}
            validateStatus={errors?.search && 'error'}
          >
            <Input
              autoComplete="off"
              className={`${inputClass} ${classModifiers.large} ${classModifiers.borderless}`}
              placeholder={placeholder ?? 'Pesquisar...'}
              onChange={(e) => handleChange({ value: e.target.value })}
              onPressEnter={handleClickSearch}
              disabled={disabled}
              suffix={
                <>
                  {value && <CloseOutlined onClick={() => handleClear()} /> }
                </>
              }
              autoFocus={autoFocus}
              addonAfter={
                <Button
                  className='w-100 search-identifier-input__search-button'
                  type='link'
                  block
                  disabled={disabled}
                  icon={<SearchOutlined className='primary-color'/>}
                  onClick={handleClickSearch}
                  size={'small'}
                />
              }
            />
          </Form.Item>
        </div>
      </div>
    </div>
  )
}

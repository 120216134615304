import { IResponseBase, Repository } from './Repository'
import api from 'services/api'
import { AxiosRequestConfig } from 'axios'
import { IOnidataCorban } from 'ecp/models/CorbanModel'
import { IConfigurationSteps } from 'ecp/app/Proposals/proposalInterfaces'
import { ISelect } from 'egi/types'

export interface IResource {
  _id: string
  name: string
  slug: string
}

export interface IResourceCorban extends IOnidataCorban {
  _id: string
}

export interface IResourceWithFantasyName {
  _id: string
  fantasyName: string
  socialName: string
  cnpj: string
}

class ResourcesRepository extends Repository {
  async steps (departmentId: string, config: AxiosRequestConfig): Promise<IResponseBase<{ steps: IResource[] }>> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/products/${departmentId}/dynamicSteps`, config)
    )
  }

  async products (config?: AxiosRequestConfig): Promise<IResponseBase<{ products: IResource[] }>> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/ecp/products`, config)
    )
  }

  async corbans (config?: AxiosRequestConfig): Promise<IResponseBase<{ corbans: IResourceCorban[] }>> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/ecp/corbans`, config)
    )
  }

  async subcorbans (config?: AxiosRequestConfig): Promise<IResponseBase<{ subcorbans: IResourceWithFantasyName[] }>> {
    return Repository.handle(() =>
      this.api.get(`ecp/${this.path}/subcorban`, config)
    )
  }

  async covenants (config?: AxiosRequestConfig): Promise<IResponseBase<{ covenants: IResourceWithFantasyName[] }>> {
    return Repository.handle(() =>
      this.api.get(`ecp/${this.path}/covenant`, config)
    )
  }

  async getSteps (config?: AxiosRequestConfig) {
    return Repository.handle<{steps: IConfigurationSteps[]}>(() =>
      this.api.get(`ecp/${this.path}/step`, config)
    )
  }

  async getUsersLevels (config?: AxiosRequestConfig): Promise<IResponseBase<{ levels: string[] }>> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/levels`, config)
    )
  }

  async getOnidataProcessSteps (config?: AxiosRequestConfig): Promise<IResponseBase<{ processSteps: ISelect[] }>> {
    return Repository.handle(() =>
      this.api.get(`/ecp/${this.path}/log-diff-process-step`, config)
    )
  }

  async getOnidataLogsTypes (config?: AxiosRequestConfig): Promise<IResponseBase<{ types: ISelect[] }>> {
    return Repository.handle(() =>
      this.api.get(`/ecp/${this.path}/log-diff-type`, config)
    )
  }

  async getOnidataLogsStatus (config?: AxiosRequestConfig): Promise<IResponseBase<{ status: ISelect[] }>> {
    return Repository.handle(() =>
      this.api.get(`/ecp/${this.path}/log-diff-status`, config)
    )
  }

  async getProposalStatusLabel (config?: AxiosRequestConfig): Promise<IResponseBase<{ avaliableStatusLabel: ISelect[] }>> {
    return Repository.handle(() =>
      this.api.get(`/ecp/${this.path}/proposal/avaliable/status-label`, config)
    )
  }

  async getBannedIdentifiers (config?: AxiosRequestConfig): Promise<IResponseBase<{ resource: ISelect[] }>> {
    return Repository.handle(() =>
      this.api.get(`/${this.path}/banned-identifiers`, config)
    )
  }
}

export default new ResourcesRepository({ api, path: 'resources' })

import React, { useState } from 'react'
import { Modal } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import queryString from 'query-string'
import CreditMainComponent from '../components/CreditMainComponent/CreditMainComponent'

export const CreditSuccessIcon = <div className="display-block"><CheckCircleOutlined style={{ fontSize: 56, color: '#9FC519' }} /></div>
export const CreditErrorIcon = <div className="display-block"><CloseCircleOutlined style={{ fontSize: 56, color: '#D70101' }} /></div>

function CreditApprove () {
  const [visible, setVisible] = useState<boolean>(true)

  const token = queryString.parse(location.search).token
  const canCloseModal = !token

  return (
    <Modal
      className="credit-modal-layout"
      title="Basic Modal"
      visible={visible}
      footer={null}
      maskClosable={false}
      centered
      onCancel={() => {
        if (canCloseModal) setVisible(false)
      }}
    >
      <CreditMainComponent />
    </Modal>
  )
}

export default CreditApprove

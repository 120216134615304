import React, { Fragment, ReactNode } from 'react'
import { Icons } from 'components'
import { colors } from 'styles/colors'
import { _proposalStatuses } from 'egi/app/ProposalEgi/proposalEgiInterfaces'
import { LoadingOutlined } from '@ant-design/icons'
import { IPartnerStep } from '../../types'
import { IBacenAgreementInformation } from '../PartnerStep/steps/BacenStep/types'
import { timeAsDayjs } from 'utils/time'

interface IProps {
  status: _proposalStatuses
  children: ReactNode
  onClick(): void
  enabled: boolean
  step: IPartnerStep & {
    slug: any
  }
}

function PartnerStepButton ({ children, enabled, onClick, status, step }: IProps) {
  const stepToolTip = step.permissionConfiguration.toolTip as string
  const isStepRequired = step.isRequired
  const agreementInformation = step.form as IBacenAgreementInformation || undefined

  return (
    <Fragment>

      <button
        className={`partner-step-button ${enabled ? 'partner-step-button--enabled' : ''}`}
        onClick={onClick}
      >
        <figure className="partner-step-button__icon">
          {!!(status) && (
            step.slug === false ? (
              <LoadingOutlined style={{ fontSize: 25 }} />
            ) : (
              <Icons name={status} color={colors[status]} />
            )
          )}
        </figure>

        <p className="partner-step-button__name">
          {children}
          {isStepRequired && <span className="partners-flag partners-flag--required-step">Obrigatório</span>}
          {stepToolTip && <span className="partners-flag partners-flag--required-step">{stepToolTip}</span>}
          {agreementInformation && agreementInformation.agreement && (
            <span className="partners-flag partners-flag--signature">
              Assinado em - {timeAsDayjs(agreementInformation.assignedAt, { applyTimezone: false }).format('DD/MM/YYYY HH:mm')}
            </span>
          )}
        </p>
      </button>

    </Fragment>
  )
}

export default PartnerStepButton

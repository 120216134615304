import { _typePromoterEnum, _userLevel } from 'ecp/models/UsersModel'
import { _cndSlugToFetch, _proposalStatuses } from 'egi/app/ProposalEgi/proposalEgiInterfaces'
import { CreditStatus } from 'egi/consumers/unauthed'
import { IAuth, IStep } from 'egi/types'
import { IStepModeOptions } from 'egi/types/IStep'
import { _personTypeEnum, _preApprovalStatus, _tableAreas } from 'globals'
import { isFrank, isLoft } from 'utils/globals'
import UserModels from './UserModels'
import { ProposalState } from 'store/modules/proposal/types'
import { _maritalRegime, _maritalStatus } from 'components/FormUserData/types'

type IShowSignatureFlagMessageProps = {
  userLevel: _userLevel,
  situation: _proposalStatuses,
  stepMode: string,
  stepModeOptions?: IStepModeOptions
}

const ProposalEgiModel = {
  canSeeSLA (userLevel: _userLevel): boolean {
    return userLevel !== 'client' && userLevel !== 'promoter'
  },

  canSeeLinkedBackoffice (userLevel: _userLevel): boolean {
    return userLevel === 'master' || userLevel === 'administrator' || userLevel === 'commercial' || userLevel === 'auditor'
  },
  canfindSteps (userLevel: _userLevel): boolean {
    return userLevel === 'client'
  },

  // Proposal list

  canSeeAnalisys (userArea: _tableAreas | undefined):boolean {
    if (!userArea) return false
    const userAreas = ['juridico', 'credito', 'engenharia', 'comercial', 'telemarketing', 'cartorio']
    return userAreas.includes(userArea)
  },

  canFilterBySeller (userLevel: _userLevel, userType: _typePromoterEnum | undefined): boolean {
    return userLevel === 'promoter' && userType === 'IN'
  },

  canSeeSLAColumns (userLevel: _userLevel): boolean {
    return ['provider', 'administrator', 'backoffice', 'master', 'auditor'].includes(userLevel)
  },

  canSeeProviderColumns (userLevel: _userLevel, userArea: _tableAreas | undefined): boolean {
    return ['provider', 'administrator', 'backoffice'].includes(userLevel) && userArea === 'engenharia'
  },

  canSeeOnlyProviderDate (userLevel: _userLevel, userArea: _tableAreas | undefined): boolean {
    return (userLevel === 'provider' && userArea === 'engenharia')
  },

  canEditCommercial (userLevel: _userLevel, userArea: _tableAreas | undefined): boolean {
    return userLevel === 'master' || (userLevel === 'administrator' && userArea === 'comercial')
  },

  canFilterByPromoter (userLevel: _userLevel, userArea: _tableAreas | undefined): boolean {
    const levels = ['auditor', 'master', 'commercial'].includes(userLevel)
    return levels || (userLevel === 'administrator' && userArea === 'comercial')
  },
  canFilterByProvider (userLevel: _userLevel, userArea: _tableAreas | undefined): boolean {
    return userArea === 'engenharia' && (userLevel === 'administrator' || userLevel === 'backoffice')
  },

  canRefuse (userLevel: _userLevel): boolean {
    return ['commercial', 'administrator', 'master'].includes(userLevel)
  },

  canSeeNeedVinculationFilter (userLevel: _userLevel): boolean {
    return ['commercial', 'backoffice'].includes(userLevel)
  },

  canDownloadPrognumReport (userLevel: _userLevel, userArea: _tableAreas | undefined): boolean {
    return userLevel === 'master' ||
      (userLevel === 'administrator' && userArea === 'juridico') ||
      (userLevel === 'backoffice' && userArea === 'juridico')
  },

  canChooseReportType (userLevel: _userLevel, userArea: _tableAreas | undefined): boolean {
    return ['commercial', 'auditor', 'master'].includes(userLevel) || userArea === 'juridico'
  },

  canEditTM (userLevel: _userLevel, userArea: _tableAreas | undefined): boolean {
    return userLevel === 'master' || (userLevel === 'administrator' && userArea === 'telemarketing')
  },

  canSeeRemindedFilter (userArea: _tableAreas | undefined, userType: _typePromoterEnum | undefined): boolean {
    return !(userArea === 'telemarketing' || userType === 'TM')
  },

  canSeeActionFilter (userLevel: _userLevel, userArea: _tableAreas | undefined): boolean {
    if (!userArea) return false
    return (userArea !== 'telemarketing') && userLevel !== 'provider'
  },

  canSeeAreasActionFilter (userLevel: _userLevel): boolean {
    return userLevel === 'master'
  },

  canSeeOpenProposalsFilter (userArea: _tableAreas | undefined, userType: _typePromoterEnum | undefined, reminded: boolean): boolean {
    return (userArea === 'telemarketing' || userType === 'TM') && reminded
  },

  vinculateUnlinkedAction (userLevel: _userLevel) {
    const isCommercial = userLevel === 'commercial'
    if (isCommercial) return ''
    return true
  },

  canSeeReminderTelemarketing (userLevel: _userLevel, userArea: _tableAreas | undefined, reminded: boolean, userType: _typePromoterEnum | undefined): boolean {
    const isPromoterTm = userLevel === 'promoter' && userArea === 'telemarketing'
    const isTm = userArea === 'telemarketing' || userType === 'TM'
    return (!isPromoterTm && reminded) || (!isTm && !reminded)
  },

  canSeeActionColumn (userLevel: _userLevel): boolean {
    return userLevel === 'master'
  },

  canSeeClosedReminded (userArea: _tableAreas | undefined, reminded: boolean, userType: _typePromoterEnum | undefined):boolean {
    const isTm = userArea === 'telemarketing' || userType === 'TM'
    return isTm && reminded
  },
  canSeeCurrencystep (userArea: _tableAreas| undefined): boolean {
    return userArea !== 'telemarketing' && userArea !== undefined
  },

  canSeeActionsAndShare (userLevel: _userLevel, mlm: boolean): boolean {
    return !(userLevel === 'seller' || !!(mlm))
  },

  canSeeLinkedBackofficeColumn (userLevel: _userLevel, userType: _typePromoterEnum | undefined): boolean {
    const userTypes = ['CB', 'IN', 'MP']
    return userLevel !== 'promoter' && !userTypes.includes(userType ?? '')
  },

  canSendFixedAreaName (user: IAuth) {
    if (user.areaName && user.level === 'backoffice') return user.areaName
    return undefined
  },

  canSeeUsersLinkedProposal (userLevel: _userLevel, mlm: boolean):boolean {
    return !(userLevel === 'seller' || !!(mlm))
  },

  isExternal (userLevel: _userLevel): boolean {
    return (userLevel === 'client' || userLevel === 'promoter')
  },

  validateRevision (needsRevision?: boolean, finishedRevision?: boolean) {
    return (!needsRevision && !finishedRevision) || finishedRevision
  },

  preApproval (preApproval: _preApprovalStatus | undefined, userLevel: _userLevel) {
    const validPreApproval = preApproval && preApproval !== 'ongoing'
    const validLevel = userLevel !== 'client'
    return validPreApproval && validLevel
  },

  canSeeCommercialRemindedFilters (userLevel: _userLevel, userArea?: _tableAreas, reminded?: boolean) {
    return ((userLevel === 'administrator' && userArea === 'comercial') || userLevel === 'commercial') && reminded
  },

  canSeeTelemarketingRemindedFilters (userArea?: _tableAreas, userType?: _typePromoterEnum, reminded?: boolean) {
    return (userArea === 'telemarketing' || userType === 'TM') && reminded
  },

  canSeeProposalRemindedFilters (user: IAuth, reminded?: boolean) {
    return (this.canSeeCommercialRemindedFilters(user.level, user.areaName, reminded) || this.canSeeTelemarketingRemindedFilters(user.areaName, user.type, reminded))
  },

  canChangeSimulation (userLevel: _userLevel, userArea: _tableAreas |undefined): boolean {
    if (userLevel === 'administrator' && userArea === 'comercial') return true
    const levels = ['client', 'commercial', 'promoter', 'master']

    const hasValidLevel = levels.includes(userLevel)
    const hasValidArea = userArea === 'credito'

    return hasValidLevel || hasValidArea
  },

  canChangeComission (userLevel: _userLevel, userArea: _tableAreas |undefined): boolean {
    if (userLevel === 'administrator' && userArea === 'comercial') return true
    if (userLevel === 'promoter' && userArea !== 'telemarketing') return true

    const levels = ['commercial', 'master']
    const hasValidLevel = levels.includes(userLevel)

    return hasValidLevel
  },

  canRequestPricing (userLevel: _userLevel, userArea: _tableAreas |undefined): boolean {
    const levels = ['master', 'commercial']

    const hasValidLevel = levels.includes(userLevel)
    const hasValidArea = userArea === 'juridico'

    return hasValidLevel || hasValidArea
  },

  canSeeCommercialAttendant: (userLevel:_userLevel, userArea?: _tableAreas) => {
    return ['commercial', 'master'].includes(userLevel) || (userArea && ['comercial'].includes(userArea))
  },

  canEditCommercialAttendant: (userLevel:_userLevel, userArea?: _tableAreas) => {
    return ['commercial', 'master'].includes(userLevel) || (userArea && ['comercial'].includes(userArea))
  },

  canSeeCommercialReminded: (userLevel:_userLevel, userArea?: _tableAreas, reminded?: boolean) => {
    return (['commercial', 'master'].includes(userLevel) || (userArea && ['comercial'].includes(userArea))) && reminded
  },

  // Proposal Details
  formDateInspectionDateDisabled ({ userlevel, stepInternal, stepMode }: {userlevel: _userLevel, stepInternal: boolean, stepMode: string}) {
    const validStepMode = stepMode === 'date'
    const isExternal = UserModels.isExternal(userlevel)

    return stepInternal && validStepMode && isExternal
  },

  canShareLink ({ userLevel, step, proposal } : {userLevel: _userLevel, step: IStep, proposal: ProposalState}) {
    const hasValidLevel = userLevel !== 'client'
    const hasValidStatus = ['remaking', 'starting'].includes(step.status || '')

    const hasValidMode = !['form'].includes(step.mode)
    const validLevelAndStatus = hasValidLevel && hasValidStatus
    const isExpired = proposal?.proposalExpiration?.isDateExpired

    return validLevelAndStatus && hasValidMode && step.linkEditable && !isExpired && !step.internal
  },

  canDirectSend: (stepMode: string, stepModeOptions?: IStepModeOptions) => {
    if (stepMode === 'multiple' && stepModeOptions) {
      return stepModeOptions.modes.includes('term')
    }

    return stepMode === 'signature'
  },

  canSeeShareButtons (userLevel: _userLevel) {
    return userLevel !== 'client'
  },

  showSignatureFlagMessage: ({ userLevel, situation, stepMode, stepModeOptions } : IShowSignatureFlagMessageProps) => {
    const isSignature = UserModels.isSignature(userLevel)
    const canDirectSend = ProposalEgiModel.canDirectSend(stepMode, stepModeOptions)
    const validSituation = situation === 'approved'

    return isSignature && canDirectSend && validSituation
  },

  canRegistrateDueDate: (stepSlug: string, situation: _proposalStatuses) => {
    const stepSlugsValids = ['matricula-do-imovel', 'matricula-do-imovel-locado']
    const stepSlugValid = stepSlugsValids.includes(stepSlug)
    const situationValid = ['approved'].includes(situation)
    return stepSlugValid && situationValid
  },

  canSeeCrivoMessage: (userLevel: _userLevel, stepMode: string) => {
    const isCrivo = UserModels.isCrivo(userLevel)
    const validStepMode = ['crivo'].includes(stepMode)
    return isCrivo && validStepMode
  },

  disabledButtonsDetails: (user: IAuth, stepView?: boolean) => {
    const { level, _id } = user
    const isExternal = UserModels.isExternal(level)
    const notExceptionId = _id !== isFrank && _id !== isLoft
    if (isExternal && notExceptionId) return !stepView
    return false
  },

  canShowFeedBackMessage: (userLevel: _userLevel, situation: _proposalStatuses) => {
    const isExternal = UserModels.isExternal(userLevel)
    const validSituation = ['remaking'].includes(situation)
    return isExternal && validSituation
  },

  canSeeSimulatorAndHistory: (userLevel: _userLevel) => userLevel !== 'client',

  canSeeCreditApprove: (userLevel: _userLevel, creditApprovalStatus: CreditStatus |undefined) => userLevel === 'client' && creditApprovalStatus === 'waiting',

  canSeeProposalHistorical: (userLevel: _userLevel, userArea: _tableAreas | undefined): boolean => {
    if (userArea === 'engenharia' && userLevel !== 'administrator') return false
    const canSee = ['auditor', 'administrator', 'commercial', 'master', 'backoffice'].includes(userLevel)

    return canSee || userArea === 'credito'
  },

  messageToReplace: /Valor de crédito não pode ser maior que o valor máximo/i,

  replaceMessages (message: string) {
    return message?.match(this.messageToReplace)?.[0] || message
  },

  factoryErrorMessage (invalids: Record<string, string>) {
    return {
      ...invalids,
      creditValue: this.replaceMessages(invalids?.creditValue)
    }
  },

  mapErrorMessage (invalids: Array<{ field: string, message: string }>) {
    return (invalids || []).map(invalid => {
      if (invalid.field === 'creditValue') {
        return {
          field: invalid.field,
          message: this.replaceMessages(invalid?.message)
        }
      }

      return invalid
    })
  },

  canSeePJForm: (personType: _personTypeEnum) => personType === 'juridica',

  notMarried (maritalStatus: _maritalStatus | undefined) {
    return maritalStatus && maritalStatus !== 'CASADO'
  },

  canSeeComposeIncome (isJuridicalProposal: boolean, stepRef: string | undefined, regime: _maritalRegime | undefined) {
    if (!regime) return false
    const regimes = ['COMUNHAOPARCIAL', 'COMUNHAOUNIVERSAL', 'SEPARACAOTOTALDEBENS', 'SEPARACAOOBRIGATORIADEBENS']
    const regimeCanInclude = regime && regimes.includes(regime)

    const canComposeRefs = ['garantidor', 'proprietario-a', 'proprietario-b', 'proprietario-c', 'proprietario-d']
    const canComposeRef = canComposeRefs.includes(stepRef ?? '')

    if (isJuridicalProposal) return canComposeRef && regimeCanInclude

    return regimeCanInclude
  },

  canSeeComposeIncomeTitular (composeIncome: boolean, stepRef: string | undefined, maritalStatus: _maritalStatus | undefined) {
    const notMarried = this.notMarried(maritalStatus)
    const isTitular = stepRef === 'titular'

    return composeIncome && notMarried && isTitular
  },

  canSelectMaritalRegime (maritalStatus: _maritalStatus| undefined, person: string | undefined) {
    const isMarried = !this.notMarried(maritalStatus)
    return isMarried && person !== 'conjuge'
  },

  canCheckPrenupcial (regime: string | undefined) {
    const regimes = ['COMUNHAOPARCIAL', 'COMUNHAOUNIVERSAL']
    return regime && regimes.includes(regime)
  },

  canCheckStableUnion (maritalStatus: _maritalStatus| undefined, person: string | undefined) {
    return this.notMarried(maritalStatus) && !person?.includes('conjuge')
  },

  getCurrentCndExpirationDate (cnd: IStep['cnd']): string | null {
    return cnd?.result?.service_infos?.[0]?.data?.expiration_date || null
  },

  getCndExpirationDateFromObject (form: IStep['form']): string | null {
    if (!form) return null

    return form.cdtfDueDateForIssuer ||
      form.cdtiDueDateForIssuer ||
      form.cndtDueDateForIssuer ||
      form.dueDate
  },

  canChangeProposalPromoter (auth: IAuth): boolean {
    const isPromoter = ['promoter'].includes(auth.level)
    const isSubUser = !!auth?.mainId

    return Boolean(isPromoter && !isSubUser)
  },

  canSeeDepartmentFilter (userLevel: _userLevel, areaName?: _tableAreas) {
    const isJuridico = areaName === 'juridico'
    const isCommercial = areaName === 'comercial'

    return (
      userLevel === 'master' ||
      userLevel === 'auditor' ||
      userLevel === 'commercial' ||
      (userLevel === 'backoffice' && isJuridico) ||
      (userLevel === 'administrator' && (isJuridico || isCommercial))
    )
  },

  canFetchCndUrl (slug?: string) {
    if (!slug) return false

    if (slug.startsWith('cnd') || slug.includes(_cndSlugToFetch.crf)) {
      return true
    }

    return false
  },

  canCancel (status: _proposalStatuses) {
    return status !== 'approved' &&
      status !== 'reproved' &&
      status !== 'canceled' &&
      status !== 'effectivated'
  }

}

export default ProposalEgiModel

import React, { useLayoutEffect, useState, Fragment, useEffect } from 'react'
import { ServerTable, ChangeUser, Drawer } from 'components'
import { leadsColumnsFunc } from '../../components/leadsColumns/leadsColumns'
import { useHistory } from 'react-router-dom'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'
import format from 'utils/format'
import { useDispatch } from 'react-redux'
import { ISimulation } from 'egi/types'
import { simulationSetId, simulationValues, resetAllSimulation } from 'store/modules/simulation/actions'
import { brDateReverse } from 'utils/date'
import { timeAsDayjs } from 'utils/time'
import { useAuth, useDrawer, useSimulation, useTables } from 'hooks'
import { proposalReset } from 'store/modules/proposal/actions'
import { tablesSetFiltersValues, tablesUpdate } from 'store/modules/tables/actions'
import ButtonModel from 'components/SeverTable/ButtonModel'
import { DownloadOutlined } from '@ant-design/icons'
import filters from 'components/SeverTable/IndexFilters'
import { useForm } from 'antd/lib/form/Form'
import ClientForm from 'egi/app/Client/views/ClientForm/ClientForm'
import Simulator from 'egi/app/Simulator/Simulator'
import { IAddNewClientErros, IAddNewClientValues } from '../../leadInterfaces'
import LeadsModel from 'egi/models/LeadsModel'
import { ColumnsType } from 'antd/lib/table'
import { LimiterTable } from 'components/LimiterTable/LimiterTable'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'
import { useSignupCheckVinculatedAccount } from 'egi/app/Signup/hooks/useSignup'
import { chooseCorrectInternalSignupMethodFn } from 'egi/app/Client/views/ClientsList/ClientsList'

interface IListLeadsProps {
  linked?: boolean
}

function ListClientsLeads ({ linked = false }: IListLeadsProps) {
  const [columns, setColumns] = useState<ColumnsType<Object>>([])
  const [errors, setErrors] = useState<IAddNewClientErros>()
  const [visible, setVisible] = useState(false)
  const [drawerMode, setDrawerMode] = useState<'simulation' | 'form'>('simulation')
  const [simulatorData, setSimulationData] = useState<ISimulation>()
  const [loading, setLoading] = useState<boolean>(false)
  const [currentStep, setCurrentStep] = useState<number | undefined>(undefined)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [visibleModalCommercial, setVisibleModalCommercial] = useState<boolean>(false)
  const [telemarketingInfo, setTelemarketingInfo] = useState<{ _id: string, name: string, type: string }>()
  const [commercialInfo, setCommercialInfo] = useState<string>()

  const [leadId, setLeadId] = useState<string>('')

  const [form] = useForm()
  const history = useHistory()
  const user = useAuth()
  const simulation = useSimulation()
  const drawer = useDrawer()
  const dispatch = useDispatch()
  const tables = useTables()

  const { onCheckCpf, onCheckEmail, isVinculatedAccount, loading: vinculatedLoading } = useSignupCheckVinculatedAccount()

  function showModalTelemarketing (telemarketing: { _id: string, name: string, type: string }, leadId: string) {
    setShowModal(true)
    setTelemarketingInfo(telemarketing)
    setLeadId(leadId)
  }

  function showModalCommercial (commercial: string, leadId: string) {
    setVisibleModalCommercial(true)
    setCommercialInfo(commercial)
    setLeadId(leadId)
  }

  const showUpdateSimulator = (row: ISimulation) => {
    const finalBirthdate = row.birthdate ? brDateReverse(String(row.birthdate)) : undefined
    const finalCpf = row.cpf ? format.onlyDigits(String(row.cpf)) : undefined
    const finalCellphone = format.onlyDigits(row.cellphone)

    const dataToSend = { ...row, birthdate: finalBirthdate, cellphone: finalCellphone, cpf: finalCpf }

    setVisible(true)
    setDrawerMode('simulation')
    if (row.currentStep >= 3) setCurrentStep(3)
    else setCurrentStep(row.currentStep || 1)
    dispatch(simulationValues(dataToSend))
    if (row.simulationId) dispatch(simulationSetId(row.simulationId))
    setSimulationData(dataToSend)
  }

  const redirectToProposal = (proposalId: string) => {
    history.push(`/auth/proposals/show/${proposalId}`)
    dispatch(resetAllSimulation())
  }

  const closeDrawer = () => {
    if (form) form.resetFields()
    setSimulationData(undefined)
    setDrawerMode('form')
    setVisible(false)
    dispatch(resetAllSimulation())
  }

  const onSubmit = async (values: IAddNewClientValues, isVinculation: boolean) => {
    const formData = new FormData()
    if (values.cpf) values.cpf = format.onlyDigits(values.cpf)
    if (values.cellphone) values.cellphone = format.onlyDigits(values.cellphone)

    if (user.level === 'administrator') values.administratorId = user._id
    if (user.level === 'promoter') values.promoterId = user._id
    if (simulation && simulation.id) values.simulationId = simulation.id

    for (const item in values) {
      type _typeNewClient = 'cpf' | 'cellphone' | 'name' | 'birthdate' | 'promoterId' | 'administratorId' | 'simulationId'
      formData.append(item as string, values[item as _typeNewClient])
    }

    setLoading(true)

    try {
      const requestConfig = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }

      const internalSignupMethod = chooseCorrectInternalSignupMethodFn(isVinculation)
      const response = await internalSignupMethod(formData, requestConfig)

      const { message } = response.data.data || {}

      swal.basic({ title: 'Sucesso!', text: message, icon: 'success' })
      setErrors(undefined)
      closeDrawer()
      setLoading(false)
      if (form) form.resetFields()
      dispatch(tablesUpdate())
    } catch (err) {
      if (err.data?.invalid) {
        let errors: IAddNewClientErros = {}
        errors = validateResponse(err.data?.invalid)
        setErrors(errors)
        setLoading(false)
        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
        return
      }

      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      setErrors({})
    }

    setLoading(false)
    dispatch(tablesUpdate())
  }

  const onCpfBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    onCheckCpf(format.onlyDigits(event.target.value))
  }

  const onEmailBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    onCheckEmail(event.target.value)
  }

  useLayoutEffect(() => {
    const finalColumns = leadsColumnsFunc({
      showModalTelemarketing,
      showModalCommercial,
      showUpdateSimulator,
      canSeeTm: LeadsModel.canSeeTm(user.level),
      canEditTm: LeadsModel.canEditTm(user.level, user.areaName),
      canSeeCommercial: LeadsModel.canSeeCommercial(user.level, user.areaName) ?? false,
      canEditCommercial: LeadsModel.canEditCommercial(user.level, user.areaName, linked) ?? false,
      user,
      useLinkedLeads: linked
    })

    setColumns(finalColumns)
    dispatch(proposalReset())
  }, [])

  useEffect(() => {
    if (drawerMode === 'form' && form) {
      form.setFieldsValue({
        ...simulation.values,
        birthdate: timeAsDayjs(simulation.values?.birthdate) ?? undefined,
        cpf: simulation.values?.cpf
      })

      const { cpf, email } = simulation?.values || {}
      if (cpf) onCheckCpf(cpf)
      if (email) onCheckEmail(email)
    }
  }, [drawerMode])

  useEffect(() => {
    const drawerLead = drawer.lead
    if (drawerLead && drawerLead?.status !== '' && drawerLead?.isSubmit) {
      dispatch(tablesUpdate())
    }
  }, [drawer.lead?.status])

  function canSeeFilters (): React.ReactElement[] {
    const addFilters: React.ReactElement[] = [
      filters().updatedAt,
      filters().uf,
      filters().leadStatus,
      filters().currentStep
    ]

    if (LeadsModel.canFilterByPromoter(user.level)) {
      addFilters.push(
        filters().promoter.indicatorId,
        filters().promoter.telemarketingId
      )
    }

    if (LeadsModel.canFilterBySeller(user.level, user.type)) {
      addFilters.push(
        filters().seller
      )
    }

    return addFilters
  }

  return (
    <Fragment>
      <ChangeUser
        show={showModal}
        setShow={setShowModal}
        level="telemarketing"
        lead={true}
        id={leadId}
        user={telemarketingInfo?.name}
      />

      <ChangeUser
        show={visibleModalCommercial}
        setShow={setVisibleModalCommercial}
        level="commercial"
        lead={true}
        showUseSelf={true}
        id={leadId}
        user={commercialInfo}
      />
      <LimiterTable>
        <ServerTable
          url='/leads'
          columns={columns}
          hasLeadStatus={true}
          fixedDate={true}
          csvFilename="leads"
          customsParams={{
            linked,
            indicatorId: tables.filters.indicatorId || undefined,
            telemarketingId: tables.filters.telemarketingId || undefined,
            sellerId: tables.filters.sellerId || undefined
          }}
          primaryFilter={filters().search}
          secondaryFilter={filters().createdAt}
          filters={canSeeFilters()}
          buttons={[
            <ButtonModel
              onClick={() => dispatch(tablesSetFiltersValues({ ...tables.filters, download: true }))}
              text='Gerar relatório'
              icon={<DownloadOutlined className="server-table-filters__icon-button-model" />}
              key='download'
            />
          ]}
        />
      </LimiterTable>
      <Drawer
        title={drawerMode === 'form' ? 'Cadastrar usuário' : 'Completar simulação'}
        closable
        showSubmit={drawerMode === 'form'}
        visible={visible}
        status={loading ? 'sending' : undefined}
        onClose={closeDrawer}
        onSubmit={() => {
          if (form && !loading && !vinculatedLoading) {
            form.submit()
          }
        }}
      >
        <Fragment>
          {drawerMode === 'form' && (
            <ClientForm
              personType={simulation.values?.personType}
              form={form}
              onFinish={(values) => onSubmit(values, isVinculatedAccount)}
              errors={errors}
              onCpfBlur={onCpfBlur}
              onEmailBlur={onEmailBlur}
            />
          )}

          {drawerMode === 'simulation' && (
            <Simulator
              callbackClient={() => setDrawerMode('form')}
              internalSimulator={true}
              simulatorData={simulatorData}
              callbackFunction={redirectToProposal}
              step={currentStep}
              onlyCEP={false}
              hasTerms={false}
            />
          )}
        </Fragment>
      </Drawer>
    </Fragment>
  )
}

export default ListClientsLeads

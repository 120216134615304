import React from 'react'
import { Drawer } from 'components'
import CipForm from './CipForm'
import ProposalStepButton from 'ecp/app/Proposals/components/ProposalStepButton/ProposalStepButton'
import ProposalStepButtonWithHistory from 'ecp/app/Proposals/components/ProposalStepButtonWithHistory/ProposalStepButtonWithHistory'
import useProposalDrawerWithContext from 'ecp/app/Proposals/hooks/useProposalDrawerWithContext'
import useThisStep from 'ecp/app/Proposals/hooks/useThisStep'

export default function CipStep () {
  const { isOpen, open, close } = useProposalDrawerWithContext()
  const { step } = useThisStep()

  const canOpen = step.canOpen

  return (
    <ProposalStepButtonWithHistory>
      <ProposalStepButton
        onClick={open}
        enabled={canOpen}
      >
        {step.name}
      </ProposalStepButton>

      <Drawer
        visible={isOpen}
        onClose={close}
        title={step.name}
        footer={null}
      >
        <CipForm close={close}/>
      </Drawer>
    </ProposalStepButtonWithHistory>
  )
}

import React, { Fragment } from 'react'
import { Space, Skeleton } from 'antd'

function SkeletonsInputs ({ number }: { number: number }) {
  const quantitys = new Array(number).fill('')
  return (
    <Fragment>
      {quantitys.map(item => (
        <Space key={item}>
          <Skeleton.Button active={true} size='default' shape={'square'} />
          <Skeleton.Button active={true} size='default' shape={'square'} />
          <Skeleton.Avatar active={true} size='default' shape={'square'} />
          <Skeleton.Input style={{ width: 200 }} active={true} size={'default'} />
        </Space>
      ))}
    </Fragment>
  )
}

export default SkeletonsInputs

import { Col, Row } from 'antd'
import React, { ReactNode } from 'react'
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

export interface IWarningTextProps {
  children: ReactNode | string
  type?: 'warning' | 'info'
  title?: string | ReactNode
  iconColor?: string
  className?:string
  customIcon?: ReactNode
}

const ICONS = {
  warning: WarningOutlined,
  info: InfoCircleOutlined
}

function WarningText ({ children, title, type = 'info', iconColor = 'var(--blue-status)', className, customIcon }: IWarningTextProps) {
  const { sm: isSmScreen } = useBreakpoint()

  const ICON = ICONS[type]

  return (
    <Row className={className}>
      {isSmScreen && (
        <Col flex='0 0 50px'>
          {customIcon ? customIcon : <ICON
            className='warning-text__alert-icon'
            style={{ color: iconColor }}
          />}
        </Col>
      )}

      <Col flex='1'>
        <h2 className='warning-text__alert-title'>{title}</h2>
        <div className='warning-text__alert-description'>{children}</div>
      </Col>
    </Row>
  )
}

export default WarningText

import React, { useState, useEffect } from 'react'
import { Row, Col, Input, Button, Collapse } from 'antd'
import { useSelector } from 'react-redux'
import { RootState } from 'store/modules/rootReducer'
import { spreadsheet } from '../../../egi/consumers'

import swal from 'utils/swal'
import { useStep } from 'hooks'

import format from 'utils/format'
import InputMoneySpreed from 'components/inputMoney/inputMoneySpreeds'

interface IColumn {
  label: string
  value: number[]
}
interface IColumnString {
  label: string
  value: string[]
}

interface IProps {
  type: string
}
const { Panel } = Collapse

function SpreadsheetHolerite ({ type }: IProps) {
  const client = useSelector((state: RootState) => state.client)
  const proposal = useSelector((state: RootState) => state.proposal)
  const step = useStep()

  const defaultLenght = new Array(15).fill('')
  const [column0, setColumn0] = useState<IColumnString>({ label: 'Descrição', value: [...(defaultLenght)] })
  const [column1, setColumn1] = useState<IColumn>({ label: 'fev-21', value: [...(defaultLenght)] })
  const [column2, setColumn2] = useState<IColumn>({ label: 'mar-21', value: [...(defaultLenght)] })
  const [column3, setColumn3] = useState<IColumn>({ label: 'abr-21', value: [...(defaultLenght)] })
  const [loading, setLoading] = useState(false)

  const [spreadsheetId, setSpreadsheetId] = useState<string>()
  const [work, setWork] = useState<string>('')

  const [open, setOpen] = useState(false)

  function getTotal (column: IColumn) {
    return column.value.reduce(function (total, value) {
      if (value) { return total + value }
      return total
    }, 0)
  }

  async function sendSpreadsheet () {
    if (!proposal.id) { return }

    const data = {
      columns: [column0, column1, column2, column3],
      type: type,
      work: work
    }

    setLoading(true)
    try {
      const response = await spreadsheet.save(proposal.id, data)
      if (response) {
        setSpreadsheetId(response.calculationId)
      }
      setLoading(false)
      swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
    } catch (err) {
      if (err) { swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' }) }
      console.warn(err)
      setLoading(false)
    }
  }

  async function updateSpreadsheet () {
    if (!proposal.id) { return }
    if (!spreadsheetId) { return }
    const data = {
      columns: [column0, column1, column2, column3],
      type: type,
      work: work
    }

    setLoading(true)
    try {
      const response = await spreadsheet.update(proposal.id, spreadsheetId, data)
      setLoading(false)
      swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
    } catch (err) {
      if (err) { swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' }) }
      console.warn(err)
      setLoading(false)
    }
  }

  async function getSpreadsheet () {
    if (!proposal.id) { return }
    setLoading(true)
    try {
      const response = await spreadsheet.get(proposal.id, type)
      const columns = response.columns
      if (response) { setSpreadsheetId(response._id) }
      if (columns) {
        setColumn0(columns[0])
        setColumn1(columns[1])
        setColumn2(columns[2])
        setColumn3(columns[3])
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  function cleantable () {
    setColumn1({ label: 'Descrição', value: [...(defaultLenght)] })
    setColumn1({ label: 'fev-21', value: [...(defaultLenght)] })
    setColumn2({ label: 'mar-21', value: [...(defaultLenght)] })
    setColumn3({ label: 'abr-21', value: [...(defaultLenght)] })
  }

  function getFinalTotal () {
    return Number(getTotal(column1) || 0) + Number(getTotal(column2) || 0) + Number(getTotal(column3) || 0)
  }

  function getAverage () {
    return Number(getFinalTotal() / 3).toFixed(2)
  }
  useEffect(() => {
    cleantable()
    getSpreadsheet()
  }, [step.id])

  return (
    <Collapse onChange={() => { setOpen(!open) }} className='mb-3'>
      <Panel
        header="Calculadora"
        key="1"
        extra={
          open && <Button loading={loading} type='primary' onClick={(e) => { !loading && (spreadsheetId ? updateSpreadsheet() : sendSpreadsheet()); e.stopPropagation() }}>Salvar</Button>
        }
      >
        <Row className='flex-1'>
          <Col span={24} className='cell-primary'>
            {type.toUpperCase()}: {client?.name}
          </Col>
        </Row>
        <Row className='flex-1'>
          <Col span={24} className='cell-secundary'>
            <Input
              className='cell-secundary'
              prefix={'CARGO:'}
              value={work}
              onChange={(e) => { setWork(e.target.value) }}
            />
          </Col>
        </Row>
        <Row className='flex-1 spreadsheet' justify="center" align='middle'>
          <Col span={20} >
            <Row>
              <Col className="cell cell-secundary" span={6}>
                < Input disabled={loading} value={column0?.label} onChange={(e) => { setColumn0({ label: e.target.value, value: column0?.value }) }} />
              </Col>
              <Col className="cell cell-secundary" span={6}>
                < Input disabled={loading} value={column1?.label} onChange={(e) => { setColumn1({ label: e.target.value, value: column1?.value }) }} />
              </Col>
              <Col className="cell cell-secundary" span={6}>
                < Input disabled={loading} value={column2?.label} onChange={(e) => { setColumn2({ label: e.target.value, value: column2?.value }) }} />
              </Col>
              <Col className="cell cell-secundary" span={6}>
                < Input disabled={loading} value={column3?.label} onChange={(e) => { setColumn3({ label: e.target.value, value: column3?.value }) }} />
              </Col>
            </Row>
            <Row className='spreadsheet-limit'>
              <Col span={6}>
                {[...defaultLenght].map((cell, row) => (
                  <Col key={row} className="cell" span={24}>
                    <Input disabled={loading} value={column0?.value[row] || undefined} onChange={(e) => { column0.value[row] = e.target.value; setColumn0({ label: column0?.label, value: column0?.value }) }} />
                  </Col>
                ))}
              </Col>
              <Col span={6}>
                {[...defaultLenght].map((cell, row) => (
                  <Col key={row} className="cell" span={24}>
                    <InputMoneySpreed disabled={loading} value={column1?.value[row] || undefined} onChange={(e) => { column1.value[row] = Number(format.currencyParser(e.target.value)); setColumn1({ label: column1.label, value: column1?.value }) }} />
                  </Col>
                ))}
              </Col>
              <Col span={6}>
                {[...defaultLenght].map((cell, row) => (
                  <Col key={row} className="cell" span={24}>
                    <InputMoneySpreed disabled={loading} value={column2?.value[row] || undefined} onChange={(e) => { column2.value[row] = Number(format.currencyParser(e.target.value)); setColumn2({ label: column2.label, value: column2?.value }) }} />
                  </Col>
                ))}
              </Col>
              <Col span={6}>
                {[...defaultLenght].map((cell, row) => (
                  <Col key={row} className="cell" span={24}>
                    <InputMoneySpreed disabled={loading} value={column3?.value[row] || undefined } onChange={(e) => { column3.value[row] = Number(format.currencyParser(e.target.value)); setColumn3({ label: column3.label, value: column3?.value }) }} />
                  </Col>
                ))}
              </Col>
            </Row>
            <Row>
              <Col className="cell cell-secundary" span={6}>
                <p style={{ margin: 0 }}>Total</p>
              </Col>
              <Col className="cell cell-secundary" span={6}>
                < Input disabled={loading} value={format.formatBRL(getTotal(column1)) || '-'} />
              </Col>
              <Col className="cell cell-secundary" span={6}>
                < Input disabled={loading} value={format.formatBRL(getTotal(column2)) || '-'} />
              </Col>
              <Col className="cell cell-secundary" span={6}>
                < Input disabled={loading} value={format.formatBRL(getTotal(column3)) || '-'} />
              </Col>
            </Row>
            <Row style={{ paddingTop: '2em' }}>
              <Col className="cell cell-text" span={6}>
                <p>TOTAL</p>
              </Col>
              <Col className="cell cell-value" span={6}>
                <p>{format.formatBRL(Number(getFinalTotal())) || '-'}</p>
              </Col>
            </Row>
            <Row>
              <Col className="cell cell-text" span={6}>
                <p>MÈDIA</p>
              </Col>
              <Col className="cell cell-value" span={6}>
                <p>{format.formatBRL(Number(getAverage())) || '-'}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  )
}

export default SpreadsheetHolerite

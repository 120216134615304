import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react'
import { Empty, Skeleton } from 'antd'
import { useParams } from 'react-router-dom'
import ClientRepository from 'egi/repositories/ClientRepository'
import CommercialAttendantHistory from '../../components/CommercialAttendentHistory/CommercialAttendantHistory'
import ReviewStepHistory from 'components/ReviewStepHistory/ReviewStepHistory'
import { IStepHistory } from 'components/ReviewBackoffice/reviewBackofficeInterfaces'
import ProposalEgiRepository from 'egi/repositories/ProposalEgiRepository'

function HistoryDepartments ({ historyData, failRequest }: { historyData: Array<IStepHistory>, failRequest: boolean }) {
  if (failRequest) return <Empty description="Falha ao buscar dados, tente novamente mais tarde" className='card-style card-style-border-radius p-3 mt-3 w-100' />
  if (!historyData.length) return <Empty description="Nenhum histórico encontrado." className='card-style card-style-border-radius p-3 mt-3 w-100' />

  const width = document.body.clientWidth

  return (
    <div className="proposal-container-card p-2 pt-3">
      <ReviewStepHistory
        stepArray={historyData}
        mode={width < 800 ? 'left' : 'alternate'}
        isProposalHistory
      />
    </div>
  )
}

function ProposalEgiHistory () {
  const main: HTMLElement | null = document.querySelector('.ant-layout-content')
  const params: { id: string } = useParams()

  const [loading, setLoading] = useState(false)
  const [departmentsData, setDepartmentsData] = useState<Array<any>>([])
  const [failRequest, setFailRequest] = useState<boolean>(false)

  const getData = async () => {
    try {
      setLoading(true)
      const response = await ProposalEgiRepository.proposalHistory(params.id)

      setDepartmentsData(response.data.data.history)
      setFailRequest(false)
    } catch (e) {
      console.log('Erro', e)
      setFailRequest(true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    async function fetchClient () {
      try {
        if (!params.id) throw new Error('Identificador da proposta não encontrado')
        const response = await ClientRepository.proposalById(params.id)
        const { client } = response.data.data || {}

        const element = document.querySelector('#auth-page-title')
        if (element && element.innerHTML.indexOf(':') === -1) element.insertAdjacentHTML('beforeend', ': ' + client?.name)
      } catch (err) {
        console.warn(err)
      }
    }

    fetchClient()
    getData()
  }, [])

  useLayoutEffect(() => {
    if (main) main.scrollTo(0, main.scrollHeight)
  }, [loading])

  return (
    <Fragment>
      {loading ? (
        <div className="proposal-container-card p-2 pt-3">
          <Skeleton loading={loading} active />
          <Skeleton loading={loading} active />
        </div>
      ) : (
        <HistoryDepartments historyData={departmentsData} failRequest={failRequest} />
      )}

      <CommercialAttendantHistory/>
    </Fragment>

  )
}

export default ProposalEgiHistory

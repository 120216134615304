import gsap from 'gsap'
import { Radio, Tooltip } from 'antd'
import SelectSvg from './SelectSvg'
import { useAuth, useStep } from 'hooks'
import { RadioChangeEvent } from 'antd/lib/radio'
import React, { useEffect, useState } from 'react'
import BackofficeModel from 'egi/models/BackofficeModel'
import { _reviewStatusOption } from 'components/ReviewBackoffice/reviewBackofficeInterfaces'
import { CheckOutlined, CloseOutlined, InfoOutlined, IssuesCloseOutlined, SwapOutlined } from '@ant-design/icons'
import { checkConfig, getStatusOptions, sortStatusOptions, uncheckConfig } from 'components/ReviewBackoffice/functions'
import translate, { _proposalStatusTranslate } from 'utils/translate'

interface IReviewRadioSelect {
  statusOptions?: Array<{label: string, value: string }>
  onChange?: (value: _reviewStatusOption) => void
}

function ReviewRadioStatus ({ statusOptions, onChange }: IReviewRadioSelect) {
  const step = useStep()
  const user = useAuth()
  const [statusSelected, setStatusSelected] = useState(null)
  const [oldStatusSelected, setOldStatusSelected] = useState(null)

  const canInsertProviderJuridico = BackofficeModel.canInsertProviderJuridico(step.hasProvider, user.areaName, user.level)
  const statusOptionsFinal = sortStatusOptions(statusOptions) ?? getStatusOptions(user, step, canInsertProviderJuridico)

  useEffect(() => {
    const check: gsap.core.Timeline = gsap.timeline({ paused: true })
    const uncheck: gsap.core.Timeline = gsap.timeline({ paused: true })

    checkConfig(check, statusSelected)
    uncheckConfig(uncheck, check, oldStatusSelected)

    uncheck.timeScale(1)

    const handleToggle = () => {
      check.play(0)
      uncheck.play(0)
    }
    handleToggle()

    return () => {
      check.kill()
      uncheck.kill()
    }
  }, [statusSelected])

  const handleRadioChange = (event: RadioChangeEvent) => {
    setOldStatusSelected(statusSelected)
    setStatusSelected(event.target.value)
    if (onChange) onChange(event.target.value)
  }

  const getIconForValue = (value: _reviewStatusOption) => {
    switch (value) {
      case 'approved':
      case 'approvedManually':
      case 'approvedAutomatically':
        return <CheckOutlined className="review-radio__icon" />
      case 'remaking':
        return <InfoOutlined className="review-radio__icon" />
      case 'reproved':
      case 'canceled':
        return <CloseOutlined className="review-radio__icon" />
      case 'reservation':
      case 'approvedWithRestriction':
        return <IssuesCloseOutlined className="review-radio__icon"/>
      case 'analysis':
        return <SwapOutlined className="review-radio__icon"/>
      default:
        return null
    }
  }

  function getReviewItemClassName (optionValue: string) {
    const classNames = [
      `review-radio__item review-radio__item--${optionValue}
      ${optionValue === 'approvedManually' ? 'review-radio__item review-radio__item--first' : ''}`
    ]

    if (statusOptionsFinal?.length > 3) classNames.push('review-radio__item--more-than-three')

    if (!['approved', 'approvedManually', 'remaking', 'reproved'].includes(optionValue)) {
      classNames.push('review-radio__item--big-label')
    }

    return classNames.join(' ')
  }

  return (
    <div>
      <Radio.Group
        className='review-radio__container'
        onChange={handleRadioChange}
        value={statusSelected}
      >
        {statusOptionsFinal.map(option => {
          return (
            <Radio
              key={option.value}
              value={option.value}
              className={getReviewItemClassName(option.value)}
            >
              <Tooltip title={translate.proposalStatus(option.value as _proposalStatusTranslate)}>
                <SelectSvg icon={getIconForValue(option.value as _reviewStatusOption)} />
                <span className='review-radio__option-label'>{option.label}</span>
              </Tooltip>
            </Radio>
          )
        })}
      </Radio.Group>
    </div>
  )
}

export default ReviewRadioStatus


import React from 'react'

import InternalReviewForm from '../../components/InternalReviewForm/InternalReviewForm'
import SettingsCard from '../../components/SettignsCard/SettingsCard'

export default function InternalReviewSettigns () {
  return (
    <div className="internal-review-settings">
      <SettingsCard.Header
        title="Revisão interna"
        description={
          <p>
            Configure aqui textos padrão para a&nbsp;
            <b>revisão interna</b> do passo da proposta.
            Não se preocupe, eles poderão ser editados e personalizados conforme a necessidade
          </p>
        } />

      <SettingsCard>
        <InternalReviewForm/>
      </SettingsCard>
    </div>
  )
}

import { IErrorDetails } from 'components/ErrorDetails/interfaces'
import RHTransfersRepository from 'ecp/repositories/RHTransfersRepository'
import { useEffect, useState } from 'react'
import { RHCovenantSelectProps } from '../components/RHCovenantSelect/RHCovenantSelect'
import { ITransferCovenant } from '../rhInterfaces'

interface IParams {
  page: number
  limit: string
}

export const useTransferCovenants = ({ onSelect }: { onSelect: RHCovenantSelectProps['onSelect'] }) => {
  const [loading, setLoading] = useState(false)
  const [covenants, setCovenants] = useState<ITransferCovenant[]>([])
  const [error, setError] = useState<IErrorDetails>()
  const [totalDocs, setTotalDocs] = useState(0)
  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: '10'
  })

  useEffect(() => {
    const abortController = new AbortController()

    async function getAvailableChannels () {
      setLoading(true)
      try {
        const response = await RHTransfersRepository.getTransferCovenants({
          params: params,
          signal: abortController.signal
        })

        const covenants = response?.data?.data?.covenantList?.docs || []
        if (covenants?.length < 1) throw new Error('Não foi encontrado nenhum convênio')

        if (covenants?.length === 1) {
          const { _id, user_funcao } = covenants?.[0]
          onSelect(_id, user_funcao.entidade.nome)
        }

        if (response?.data?.data?.covenantList?.totalDocs) setTotalDocs(response?.data?.data?.covenantList.totalDocs)
        setCovenants(covenants)
        setLoading(false)
      } catch (err) {
        setError(err)
        if (!abortController.signal.aborted) {
          setCovenants([])
        }
      } finally {
        setLoading(false)
      }
    }

    getAvailableChannels()
    return () => abortController.abort()
  }, [
    params
  ])

  const onUpdateParams = (params: Partial<IParams>) => {
    setParams(prev => ({ ...prev, ...params }))
  }

  return {
    loading,
    covenants,
    error,
    totalDocs,
    params,
    onUpdateParams
  }
}

import { Col, Form, Row } from 'antd'
import { Input } from 'components'
import React from 'react'

interface IWitnessFields {
  errors: {
    name: string | undefined
    cpf: string | undefined
    rg: string | undefined
  },
  readOnly: boolean
  witness: 'firstWitness' | 'secondWitness'
}

function WitnessFields ({ errors, readOnly, witness }: IWitnessFields) {
  return (
    <Row>
      <Col className="px-1" lg={8} sm={24} xs={24}>
        <Form.Item
          name={[witness, 'name']}
          label={<label>Nome</label>}
          help={errors.name && (errors.name)}
          validateStatus={errors.name && ('error')}
        >
          <Input placeholder='Digite o nome' readOnly={readOnly} />
        </Form.Item>
      </Col>

      <Col className="px-1" lg={8} sm={24} xs={24}>
        <Form.Item
          name={[witness, 'rg']}
          label={<label>RG</label>}
          help={errors.rg && (errors.rg)}
          validateStatus={errors.rg && ('error')}
        >
          <Input placeholder='Digite o RG' mask='rg' readOnly={readOnly} />
        </Form.Item>
      </Col>

      <Col className="px-1" lg={8} sm={24} xs={24}>
        <Form.Item
          name={[witness, 'cpf']}
          label={<label>CPF</label>}
          help={errors.cpf && (errors.cpf)}
          validateStatus={errors.cpf && ('error')}
        >
          <Input placeholder='Digite o CPF' mask='cpf' readOnly={readOnly} />
        </Form.Item>
      </Col>
    </Row>
  )
}

export default WitnessFields

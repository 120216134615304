import { AxiosRequestConfig } from 'axios'
import { IResponseBase, Repository } from 'ecp/repositories/Repository'
import api from 'services/api'

class SignupRepository extends Repository {
  async association (data: FormData, config?: AxiosRequestConfig) {
    return Repository.handle<{ emailVerified: boolean }>(() =>
      this.api.post(`${this.path}/association`, data, config)
    )
  }

  async verifyAssociation (config?: AxiosRequestConfig) {
    return Repository.handle<{ exists: boolean }>(() =>
      this.api.get(`${this.path}/association`, config)
    )
  }

  async createClientByCB (data: FormData, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.post(`${this.path}/internal`, data, config)
    )
  }

  async associateClientByCB (data: FormData, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.post(`${this.path}/association/internal`, data, config)
    )
  }
}

export default new SignupRepository({ api, path: '/signup' })

import { all } from 'redux-saga/effects'
import proposal from './proposal/sagas'
import resources from './resources/sagas'
import system from './system/sagas'
import chat from './chat/sagas'
import simulation from './simulation/sagas'
import auth from './auth/authSagas'

export default function * rootSaga () {
  return yield all([auth, proposal, resources, system, chat, simulation])
}

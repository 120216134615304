import React, { useState, Fragment } from 'react'
import { FilePdfFilled } from '@ant-design/icons'
import { Col, Modal, Typography, Image } from 'antd'
import { PdfPreview } from 'components'

interface DocumentsViewProps {
  urls?: Array<any>;
  isOnlyLink?: string
}

const { Title } = Typography
const DocumentsView = ({ urls, isOnlyLink }: DocumentsViewProps) => {
  const [HoveredImage, setHoveredImage] = useState<string>('')

  const [pdfModal, setPdfModal] = useState({
    url: '',
    visible: false,
    isPdf: false
  })

  const handleHover = (url: string) => {
    setHoveredImage(url)
  }

  const handleMouseOut = () => {
    setHoveredImage('none')
  }

  const handleImageClick = (url: string) => {
    const isPdf = url.endsWith('pdf')

    if (isPdf) {
      setPdfModal({
        visible: true,
        url: url,
        isPdf: isPdf
      })
    }
  }

  const handleClose = () => {
    setPdfModal({
      url: '',
      visible: false,
      isPdf: false
    })
  }

  return (
    <Fragment>
      <Modal
        visible={pdfModal.visible}
        className="documents-view-content__modal"
        footer={null}
        onCancel={handleClose}
        width={750}
        centered
      >
        {pdfModal.isPdf && (
          <PdfPreview src={pdfModal.url} />
        )}
      </Modal>

      {isOnlyLink ? (
        <div
          key={isOnlyLink}
          className="mb-2"
          onClick={() => { handleImageClick(isOnlyLink) }}
        >
          <Col className="mr-1">
            {!isOnlyLink && (
              <Title level={4}>Imagem/Documento não disponível</Title>
            )}

            {isOnlyLink.endsWith('pdf') ? (
              <a className="documents-view-content__document-card">
                <FilePdfFilled className="file-pdf-filled"/>
                <Title level={5} className="mb-0">Abrir PDF</Title>
                <label className="decode-uri word-break-all">{decodeURI(isOnlyLink).split('-').pop()}</label>
              </a>
            ) : (
              <Fragment>
                <div style={{ display: HoveredImage === isOnlyLink ? 'block' : 'none' }}
                  className='fade-in-animation animation-image'
                />

                <div
                  on-MouseEnter={() => handleHover(isOnlyLink)}
                  onMouseLeave={handleMouseOut}
                >
                  <Image className="documents-view-content__image" src={isOnlyLink} alt="imagem de documentos" />
                </div>
              </Fragment>
            )}
          </Col>
        </div>

      ) : (
        <div className="documents-view-content">
          <Fragment>
            {urls && urls.map((item) => (
              <Fragment key={item.path}>
                {item.path.map((image: any) => (
                  <div
                    key={image}
                    className="flex flex-justify-center flex-align-center mb-2"
                    onClick={() => { handleImageClick(image) }}>
                    <Fragment>
                      {!image && (
                        <Title level={4}>Imagem/Documento não disponível</Title>
                      )}

                      {image && image.endsWith('pdf') ? (
                        <a className="documents-view-content__document-card">
                          <FilePdfFilled className="file-pdf-filled" />
                          <Title level={5} className="mb-0" >Abrir PDF</Title>
                          <label className="decode-uri-name">{item.stepName}</label>
                          <label className="decode-uri">{decodeURI(image).split('-').pop()}</label>
                        </a>
                      ) : (
                        <Fragment>
                          <div style={{ display: HoveredImage === image ? 'block' : 'none' }}
                            className='fade-in-animation animation-image'
                          >
                          </div>

                          <div
                            on-MouseEnter={() => handleHover(image)}
                            onMouseLeave={handleMouseOut}
                          >
                            <img className="documents-view-content__image" src={image} alt="imagem de documentos" />
                          </div>
                        </Fragment>
                      )}
                    </Fragment>
                  </div>
                ))}
              </Fragment>
            ))}
          </Fragment>
        </div>
      )}
    </Fragment>
  )
}

export default DocumentsView

import { Checkbox, Form, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { IError } from 'ecp/app/Proposals/proposalInterfaces'
import LoginRepository from 'ecp/repositories/LoginRepository'
import SignupLayout from 'layouts/SignupLayout/SignupLayout'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { UNAUTHS_PATHS } from 'routes/unauthRoutes'
import { handleOnidataErrors } from 'utils/validate'
import { ISignupClientLGPDForm, ISignupClientLGPDRequest, ISignupClientRequestLGPDRequest } from '../../signUpClientInterfaces'
import swal from 'utils/swal'
import useTokenInfo from '../../hooks/useTokenInfo'
import IframePDF from 'ecp/components/IframePDF/IframePDF'
import SignupButton from 'egi/app/Signup/components/SignupButton/SignupButton'
import { SignupTitle } from 'egi/app/Signup/components/SignupTitle/SignupTitle'
import { swalError } from 'components/SwalError/SwalError'

function SignupClientLGPD () {
  const { tokenInfo } = useTokenInfo()
  const history = useHistory()

  const [form] = useForm<ISignupClientLGPDForm>()
  const [errors, setErrors] = useState<IError<ISignupClientLGPDForm>>({})
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [file, setFile] = useState<string | null>(null)
  const [loadingPdf, setLoadingPdf] = useState(true)

  function redirectToNextStep () {
    history.push({
      pathname: `${UNAUTHS_PATHS.SIGNUP_CLIENT_EMAIL}`,
      state: tokenInfo
    })
  }

  useEffect(() => {
    async function getFile () {
      const config: ISignupClientRequestLGPDRequest = {
        params: {
          onidataToken: tokenInfo.jwtToken as string
        }
      }

      try {
        const response = await LoginRepository.requestLGPD(config)
        const file = response.data.data?.url

        if (file) setFile(file)
      } catch (err) {
        setLoadingPdf(false)
        if (err.message) message.warning(err.message)
        console.error(err)
      }
    }

    getFile()
  }, [])

  async function handleSubmit (values: ISignupClientLGPDForm) {
    if (values.lgpd !== true) {
      return swal.basic({ text: 'Você deve aceitar os termos e condições para continuar.', title: 'Atenção', icon: 'warning' })
    }

    const data: ISignupClientLGPDRequest = {
      onidataToken: tokenInfo.jwtToken as string
    }
    setLoadingSubmit(true)

    try {
      await LoginRepository.confirmLGPD(data)
      redirectToNextStep()
    } catch (err) {
      console.error(err)
      if (err.data?.error) {
        setErrors(handleOnidataErrors(err.data?.error))
        setErrors(errors)
      }
      swalError({ err })
    } finally {
      setLoadingSubmit(false)
    }
  }

  return (
    <SignupLayout>
      <Form
        onFinish={handleSubmit}
        form={form}
        layout='vertical'
        className='unauth-form signup-client-lgpd'
      >
        <SignupTitle>
          Termos de uso
        </SignupTitle>
        <IframePDF
          className='signup-client-lgpd__pdf'
          url={file}
          loading={loadingPdf}
          onLoad={() => setLoadingPdf(false)}
        />
        <Form.Item
          name='lgpd'
          help={(errors?.lgpd)}
          validateStatus={errors?.lgpd && ('error')}
          valuePropName='checked'
        >
          <Checkbox>
            Li e concordo com os <a target='_blank'
              rel='noopener noreferrer'
              href={file || undefined}
            ><strong>Termos e Condições de uso e LGDP</strong></a>
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <SignupButton
            loading={loadingSubmit}
            htmlType='submit'
            className='w-100'
          >
            Enviar
          </SignupButton>
        </Form.Item>
      </Form>
    </SignupLayout>
  )
}

export default SignupClientLGPD

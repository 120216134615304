import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Select, Form, Input, Checkbox, Typography } from 'antd'
import { FormListFieldData } from 'antd/lib/form/FormList'
import { Datepicker, InputMoney } from 'components'
import locale from 'antd/es/date-picker/locale/pt_BR'
import { MinusCircleOutlined } from '@ant-design/icons'
import PEPTooltip from '../../../components/PEPTooltip/PEPTooltip'
import { FormInstance } from 'antd/lib/form'

type IProps = {
  field: FormListFieldData,
  onRemove(event: React.MouseEvent<HTMLSpanElement, MouseEvent>): void,
  readOnly: boolean,
  index: number
  onChange: Function
  form: FormInstance
}

function AssociateFields ({ form, field, onRemove, index, readOnly, onChange }: IProps) {
  const [associateType, setAssociateType] = useState<'fisica' | 'juridica'>('fisica')

  function onAssociateTypeChange (value: 'fisica' | 'juridica') {
    setAssociateType(value)
    onChange(index)
  }

  function getAssociateDocumentType () {
    const associates = form.getFieldValue('associates')
    const chooseAssociate = associates[field.key]
    if (chooseAssociate) return chooseAssociate.personType
    return undefined
  }
  useEffect(() => {
    const associateDocumentType = getAssociateDocumentType()
    if (associateDocumentType) setAssociateType(associateDocumentType)
  }, [form])

  return (
    <Fragment>
      <Typography.Paragraph className="bold">
        Sócio {index + 1} {' '}
        {!readOnly && (
          <MinusCircleOutlined
            onClick={onRemove}
            style={{ color: 'red' }}
          />
        )}
      </Typography.Paragraph>

      <Row align="bottom">
        <Col className="px-1" lg={6} sm={24} xs={24}>
          <Form.Item
            label="Tipo de pessoa"
            name={[field.name, 'personType']}
          >
            <Select
              placeholder="Selecione o tipo de pessoa"
              disabled={readOnly}
              defaultValue={associateType}
              value={associateType}
              onChange={onAssociateTypeChange}
              options={[
                { value: 'fisica', label: 'Física' },
                { value: 'juridica', label: 'Jurídica' }
              ]}
            />
          </Form.Item>
        </Col>

        {associateType === 'fisica' && (
          <Col className="px-1" lg={12} sm={24} xs={24}>
            <Form.Item
              label='Nome'
              name={[field.name, 'name']}
            >
              <Input placeholder='Digite o nome' readOnly={readOnly} />
            </Form.Item>
          </Col>
        )}

        {associateType === 'juridica' && (
          <Col className="px-1" lg={12} sm={24} xs={24}>
            <Form.Item
              label='Razão Social'
              name={[field.name, 'socialName']}
            >
              <Input placeholder='Digite a razão social' readOnly={readOnly} />
            </Form.Item>
          </Col>
        )}

        <Col className="px-1" lg={6} sm={24} xs={24}>
          <Form.Item
            label="Renda total"
            name={[field.name, 'income']}
          >
            <InputMoney placeholder="Digite a renda total" readOnly={readOnly} />
          </Form.Item>
        </Col>

        {associateType === 'fisica' && (
          <Col className="px-1" lg={8} sm={24} xs={24}>
            <Form.Item
              label='Data de nascimento'
              name={[field.name, 'birthdate']}
            >
              <Datepicker
                id="foundationDate"
                locale={locale}
                disabled={readOnly}
              />
            </Form.Item>
          </Col>
        )}

        {associateType === 'juridica' && (
          <Col className="px-1" lg={8} sm={24} xs={24}>
            <Form.Item
              label='Data de fundação'
              name={[field.name, 'foundationDate']}
            >
              <Datepicker
                id="foundationDate"
                locale={locale}
                disabled={readOnly}
              />
            </Form.Item>
          </Col>
        )}

        <Col className="px-1" lg={8} sm={24} xs={24}>
          <Form.Item
            name={[field.name, 'procurator']}
            className="form-user-checkbox"
            valuePropName="checked"
          >
            <Checkbox id="test-checkbox-procurator" disabled={readOnly}>
              É Representante Legal?
            </Checkbox>
          </Form.Item>
        </Col>

        <Col className="px-1" lg={8} sm={24} xs={24}>
          <Form.Item
            name={[field.name, 'pep']}
            className="form-user-checkbox"
            valuePropName="checked"
            label={<PEPTooltip />}
          >
            <Checkbox id="test-checkbox-pep" disabled={readOnly}>
              É Pessoa Exposta Politicamente?
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </Fragment>
  )
}

export default AssociateFields

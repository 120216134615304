import { Button, Col, Collapse, Input, Row } from 'antd'
import { PercentageInput } from 'components'
import { spreadsheet } from 'egi/consumers'
import { usePMT, useProposal, useStep } from 'hooks'
import React, { useState, useEffect } from 'react'
import format from 'utils/format'
import swal from 'utils/swal'
import InputMoneySpreed from 'components/inputMoney/inputMoneySpreeds'

interface IColumn {
  label: string
  value: number[]
}

const { Panel } = Collapse

function SpreadsheetDebt ({ canSave = true }: { canSave?: boolean }) {
  const pmt = usePMT()
  const proposal = useProposal()
  const step = useStep()

  const [column1, setColumn1] = useState<IColumn>({ label: 'Debt', value: [...(new Array(6))] })
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const [spreadsheetId, setSpreadsheetId] = useState<string>()

  const getPMTResult = () => {
    return pmt.BACEN +
      pmt.BACEN_CONJUGE +
      pmt.BACEN_PJ
  }

  const getTotalPMT = () => {
    return Number(getPMTResult() + (column1.value[1] || 0))
  }

  const getPercentage = () => {
    return Number(format.decimalToPercentage(Number(getTotalPMT() / column1.value[3])).toFixed(2))
  }

  const sendSpreadsheet = async () => {
    if (!proposal.id) return

    const data = {
      columns: [column1],
      type: 'debt'
    }

    setLoading(true)
    try {
      const response = await spreadsheet.save(proposal.id, data)

      if (response) {
        setSpreadsheetId(response.calculationId)
      }

      setLoading(false)
      swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
    } catch (err) {
      if (err) { swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' }) }
      console.log(err)
      setLoading(false)
    }
  }

  const updateSpreadsheet = async () => {
    if (!proposal.id) return
    if (!spreadsheetId) { return }

    const data = {
      columns: [column1],
      type: 'debt'
    }

    setLoading(true)
    try {
      const response = await spreadsheet.update(proposal.id, spreadsheetId, data)
      setLoading(false)
      swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
    } catch (err) {
      if (err) { swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' }) }
      console.log(err)
      setLoading(false)
    }
  }

  async function getSpreadsheet () {
    if (!proposal.id) { return }
    setLoading(true)
    try {
      const response = await spreadsheet.get(proposal.id, 'debt')
      const columns = response.columns
      if (response) { setSpreadsheetId(response._id) }
      if (columns) {
        setColumn1(columns[0])
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  function cleantable () {
    setColumn1({ label: 'Debt', value: [...(new Array(6))] })
  }

  useEffect(() => {
    cleantable()
    getSpreadsheet()
  }, [step.id, step.situation])

  return (
    <Collapse onChange={() => { setOpen(!open) }} className='mb-3'>
      <Panel
        header="Calculadora"
        key="1"
        extra={(open && canSave) && <Button loading={loading} type='primary' onClick={(e) => { !loading && (spreadsheetId ? updateSpreadsheet() : sendSpreadsheet()); e.stopPropagation() }}>Salvar</Button>}
      >
        <Row className='flex-1 spreadsheet'>
          <Col span={24}>
            <Row className='flex-1 ' >
              <Col span={24} className='cell-secundary'>
                TOTAL ENDIVIDAMENTO A QUITA
              </Col>
            </Row>
            <Row className='flex-1' justify="center" align='middle'>
              <Row className='flex-1'>
                <Col span={16}>
                  <Col className="cell normal-text" span={24}>
                    <p>PMT DÍVIDAS ATUAIS </p>
                  </Col>
                  <Col className="cell normal-text" span={24}>
                    <p>PMT RODOBENS </p>
                  </Col>
                  <Col className="cell normal-text" span={24}>
                    <p>{"TOTAL PMT'S "}</p>
                  </Col>
                  <Col className="cell normal-text" span={24}></Col>
                  <Col className="cell normal-text" span={24}>
                    <p>RENDA APURADA </p>
                  </Col>
                  <Col className="cell normal-text" span={24}>
                    <p>COMPROMETIMENTO TOTAL </p>
                  </Col>
                </Col>
                <Col span={8}>
                  <Col className="cell" span={24} >
                    < Input disabled={loading} value={format.formatBRL(getPMTResult()) || '-'} />
                  </Col>
                  <Col className="cell" span={24}>
                    <InputMoneySpreed disabled={loading} value={column1?.value[1] || undefined } onChange={(e) => { column1.value[1] = Number(format.currencyParser(e.target.value)); setColumn1({ label: column1.label, value: column1?.value }) }} />
                  </Col>
                  <Col className="cell" span={24}>
                    < Input disabled={loading} value={format.formatBRL(getTotalPMT()) || '-'} />
                  </Col>
                  <Col className="cell normal-text" span={24}></Col>
                  <Col className="cell" span={24}>
                    <InputMoneySpreed disabled={loading} value={column1?.value[3] || undefined } onChange={(e) => { column1.value[3] = Number(format.currencyParser(e.target.value)); setColumn1({ label: column1.label, value: column1?.value }) }} />
                  </Col>
                  <Col className="cell" span={24}>
                    <PercentageInput disabled={loading} value={getPercentage() || 0} />
                  </Col>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  )
}

export default SpreadsheetDebt

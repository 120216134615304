import { IClient } from 'egi/types'

const INITIALSTATE: IClient = {
  birthdate: undefined,
  cellphone: '',
  corbanId: '',
  cpf: '',
  email: '',
  emailVerified: undefined,
  _id: '',
  level: undefined,
  name: '',
  term: undefined,
  uid: '',
  city: undefined,
  proposal: {
    id: ''
  },
  clientApproval: {
    name: '',
    conditional: null,
    message: null,
    credit: null,
    deadline: null,
    status: '',
    tax: null
  }
}

const client = (state = INITIALSTATE, action: any) => {
  switch (action.type) {
    case 'SET_CLIENT_DATA': {
      return {
        ...state,
        ...action.payload.client
      }
    }

    case 'SET_CLIENT_ID': {
      return {
        ...state,
        _id: action.payload._id
      }
    }

    case 'SET_CLIENT_APPROVAL': {
      return {
        ...state,
        clientApproval: action.payload.data
      }
    }

    case 'RESET_CLIENT': {
      return {
        ...INITIALSTATE
      }
    }

    default:
      return state
  }
}

export default client

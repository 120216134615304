import React, { Fragment } from 'react'
import { Switch, Button, Tag, Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import translate from 'utils/translate'
import IUser from 'egi/types/IUser'
import { useAuth, useResources } from 'hooks'
import swal from 'utils/swal'
import format from 'utils/format'
import { EditOutlined, ShareAltOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { tablesSetBackOfficeAreas, tablesSetRowValues } from 'store/modules/tables/actions'
import { varColors } from 'styles/colors'
import { modalFillValues } from 'store/modules/modal/actions'
import { userDetails } from 'routes/routes'
import { Link } from 'react-router-dom'
import UserTableModel from 'egi/models/UserTableModels'
import commonColumns, { commomNameOverflowWidth } from 'components/UserTable/utils/commonColumns'
import { OverflowTooltip } from 'components/OverflowToolTip/OverflowTooltip'

export type _alignType = 'left' | 'center' | 'right' | undefined

const commomColumns: ColumnsType<Object> = [
  {
    ...commonColumns.name,
    width: 175,
    render: (name: string, row: any) => {
      const user = useAuth()
      if (!name) return 'N/A'

      if (UserTableModel.showUsersDetails(user.level)) {
        return (
          <>
            <OverflowTooltip width={commomNameOverflowWidth}
              title={
                <>
                  {name}
                  {row.cpf && (<div>{row.cpf}</div>)}
                </>
              }
            >
              <Link
                className="link-style color-tertiary font-500"
                to={'/auth' + userDetails.path.replace(':userId', row._id)}
              >
                {name}
              </Link>
            </OverflowTooltip>

            {row.cpf ? <div>{row.cpf}</div> : '-'}
          </>
        )
      }

      return (
        <OverflowTooltip
          title={name}
          width={150}
        >
          {name}
        </OverflowTooltip>
      )
    }
  },
  commonColumns.email,
  commonColumns.cellphone,
  commonColumns.createdAt
]

export const columnBlockUser = (blockUser?: Function) => ({
  title: 'Bloqueado',
  dataIndex: 'blocked',
  width: 150,
  sorter: true,
  render: (item: any, row: any) => {
    const { _id } = (row as IUser)
    const user = useAuth()
    const IS_AUDITOR = user.level === 'auditor'

    if ((row as any).active === 'Desativado') return (<></>)
    const isBlocked = item

    return (
      <Switch
        checked={item}
        disabled={IS_AUDITOR}
        onClick={() => {
          swal.confirmNegate({
            title: 'Atenção',
            text: `Tem certeza que deseja ${isBlocked ? 'desbloquear' : 'bloquear'} o usuário?`,
            icon: 'warning',
            confirm: () => {
              if (_id && blockUser) blockUser(_id, isBlocked)
            }
          })
        }}
      />
    )
  }
})

export const columnReceiveProposal = (receiveProposal?: Function) => ({
  title: 'Receber proposta',
  dataIndex: 'receiveProposal',
  sorter: true,
  width: 150,
  render: (item: any, row: any) => {
    const { _id } = (row as IUser)

    if ((row as any).active === 'Desativado') return (<></>)
    const canReceiveProposal = item

    return (
      <Switch
        checked={item}
        onClick={() => {
          swal.confirmNegate({
            title: 'Atenção',
            text: `Tem certeza que deseja ${canReceiveProposal ? 'impedir' : 'permitir'} o usuário de receber proposta?`,
            icon: 'warning',
            confirm: () => {
              if (_id && receiveProposal) receiveProposal(_id, canReceiveProposal)
            }
          })
        }}
      />
    )
  }
})

export const columnProviderDocuments = (openDrawer: Function | undefined) => ({
  title: 'Status',
  dataIndex: 'status',
  align: 'center' as _alignType,
  width: 150,
  sorter: true,
  render: (_: string, row: any) => {
    const dispatch = useDispatch()

    const actions = () => {
      dispatch(tablesSetRowValues({ row }))
      openDrawer && openDrawer()
    }

    if (row.department === 'juridico') return '-'

    if (row.status === 'approved') {
      return (
        <Tag
          className="cursor-pointer"
          color={varColors.approved}
          onClick={actions}
        >
          Aprovado
        </Tag>
      )
    }

    return (
      <div className="no-wrap-white-space">
        <Tag
          onClick={actions}
          className="cursor-pointer"
          color={row.status ? varColors[row.status] : varColors.ongoing}
        >
          {row.status === 'reproved' ? `${translate.level(row.level)} reprovado` : `Aprovar ${translate.level(row.level)}`}
        </Tag>

        <Button
          type="link"
          className="p-0"
          onClick={() => {
            dispatch(modalFillValues({
              visible: true,
              title: 'Reenviar link de documentação',
              type: 'resendLink',
              width: '50',
              hasFooter: false,
              resendLinkUser: {
                phone: row.cellphone,
                id: row._id,
                level: row.level
              }
            }))
          }}
        >
          <Tooltip title="Reenviar link" destroyTooltipOnHide={{ keepParent: false }}>
            <ShareAltOutlined style={{ fontSize: 16 }} />
          </Tooltip>
        </Button>
      </div>
    )
  }
})

export const areasBackOffice = (openModalDrawer: Function) => ({
  title: 'Áreas liberadas',
  dataIndex: 'areas',
  width: 200,
  render: (text: Array<string>, row: Object) => {
    const resources = useResources()
    const dispatch = useDispatch()

    const actions = (areas: Array<string>) => {
      dispatch(tablesSetRowValues({ row }))
      dispatch(tablesSetBackOfficeAreas({ areas }))
      openModalDrawer()
    }

    if (!text) return ''
    if (text.length < 1) return ''
    if (text.length === 1) {
      const findOneArea = resources.areas.find((element: any) => element.value === text[0])

      return (
        <Fragment>
          <label>
            {findOneArea?.label}
          </label>

          <Button
            type="link"
            onClick={() => findOneArea && actions([findOneArea.value])}
          >
            <EditOutlined />
          </Button>
        </Fragment>
      )
    }

    const tempText: Array<string> = []
    const selectValues: Array<string> = []

    text.forEach((item: any) => {
      const findArea = resources.areas.find((element: any) => element.value === item)
      if (findArea) {
        tempText.push(findArea.label)
        selectValues.push(String(findArea.value))
      }
    })

    return (
      <Fragment>
        <Button
          type="link"
          onClick={() => {
            swal.basic({
              title: 'Áreas',
              text: (format.separeteValuesByComma((tempText as any)) as string),
              customClass: {
                content: 'swal-container-scroll'
              }
            })
          }}
        >
          Ver mais
        </Button>

        <Button
          type="link"
          onClick={() => actions(selectValues)}
        >
          <EditOutlined />
        </Button>
      </Fragment>
    )
  }
})

export default commomColumns

import { Button, message } from 'antd'
import { swalError } from 'components/SwalError/SwalError'
import ContractFormContent from 'ecp/app/Proposals/components/ContractFormContent/ContractFormContent'
import UnauthProposalRepository from 'ecp/repositories/UnauthProposalRepository'
import SignupLayout from 'layouts/SignupLayout/SignupLayout'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { handleOnidataErrors } from 'utils/validate'
import AcceptContractModal from '../../components/AcceptContractModal/AcceptContractModal'
import ProposalContractPDF from '../../components/ProposalContractPDF/ProposalContractPDF'
import { formatLocalization, getPosition } from '../../proposalFunctions'
import { IAcceptContract, IContractSignatureResource, IError, ILocalizationContract, ISendStepBody } from '../../proposalInterfaces'

function ProposalUnauthContract () {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<IError<IAcceptContract>>({})
  const [hasBankCreditNoteAgree, setHasBankCreditNoteAgree] = useState<boolean>(false)
  const [hasinstallmentDeductedFromPayrollAgree, setHasinstallmentDeductedFromPayrollAgree] = useState<boolean>(false)
  const [hasadhesionCreditInsuranceAgree, setadhesionCreditInsuranceAgree] = useState<boolean>(false)
  const [contractSignAgree, setContractSignAgree] = useState<boolean>(false)
  const [loadingContract, setLoadingContract] = useState(false)
  const [hasInsurance, setHasInsurance] = useState<boolean>(false)
  const [contract, setContract] = useState<IContractSignatureResource | null>(null)
  const { token } = useParams<{ token: string }>()
  const history = useHistory()

  useEffect(() => {
    async function getContract (token: string) {
      setLoadingContract(true)
      try {
        const response = await UnauthProposalRepository.getUnauthContract(token)
        const contract = response.data.data?.contract
        const hasInsurance = response.data.data?.hasInsurance
        if (hasInsurance) setHasInsurance(hasInsurance)
        if (contract) setContract(contract)
        else throw new Error('Contrato não encontrado')
      } catch (err) {
        console.error(err)
      } finally {
        setLoadingContract(false)
      }
    }

    getContract(token)
  }, [])

  async function onSubmit (localization: ILocalizationContract) {
    const body: ISendStepBody<IAcceptContract> = {
      status: contractSignAgree ? 'approved' : 'reproved',
      parameters: {
        bankCreditNoteAgree: hasBankCreditNoteAgree,
        installmentDeductedFromPayrollAgree: hasinstallmentDeductedFromPayrollAgree,
        adhesionCreditInsuranceAgree: hasadhesionCreditInsuranceAgree,
        contractSignAgree: contractSignAgree,
        localization: formatLocalization(localization)
      }
    }

    setErrors({})
    setSubmitLoading(true)
    try {
      const response = await UnauthProposalRepository.sendUnauthContract(token, body)
      message.success(response.data.message)

      history.push('/')
    } catch (err) {
      const onidataErrors = handleOnidataErrors(err?.data?.error)
      if (onidataErrors) setErrors(onidataErrors)
      swalError({ err })
    } finally {
      setSubmitLoading(false)
      setShowModal(false)
    }
  }

  return (
    <SignupLayout className='proposal-unauth-contract-wrapper'>
      <div className='proposal-unauth-contract'>
        <div className='proposal-unauth-contract__gap'>
          <div>
            <AcceptContractModal
              handleConfirmCLick={() => getPosition(onSubmit)}
              onOkAction={() => setShowModal(false)}
              onCancelAction={() => setShowModal(false)}
              loading={submitLoading}
              visible={showModal}
              contractSignAgree={contractSignAgree}
            />

            <ProposalContractPDF
              loading={loadingContract}
              contract={contract}
            />

            <ContractFormContent
              errors={errors}
              hasInsurance={hasInsurance}
              setAdhesionCreditInsuranceAgree={setadhesionCreditInsuranceAgree}
              setHasBankCreditNoteAgree={setHasBankCreditNoteAgree}
              setHasinstallmentDeductedFromPayrollAgree={setHasinstallmentDeductedFromPayrollAgree}
            />
          </div>

          <div className='proposal-unauth-contract__gap proposal-unauth-contract__buttons'>
            <Button
              type='primary'
              block
              onClick={() => {
                setShowModal(true)
                setContractSignAgree(true)
              }}
            >
              Aceitar
            </Button>

            <Button
              type='ghost'
              block
              onClick={() => {
                setShowModal(true)
                setContractSignAgree(false)
              }}
            >
              Recusar
            </Button>
          </div>
        </div>
      </div>
    </SignupLayout>
  )
}

export default ProposalUnauthContract

import React from 'react'
import mainColumns from 'egi/views/UsersTable/DataTables'

import { TeamOutlined } from '@ant-design/icons'
import translate from 'utils/translate'
import UsersTable from 'egi/views/UsersTable/UsersTable'
import { IRoute } from 'components/Sidebar/types'
import { EGI } from 'routes/egi/paths'

const leaders: IRoute = {
  path: EGI.CREDITO_LEADERS.path,
  name: EGI.CREDITO_LEADERS.name,
  icon: TeamOutlined,
  component: () => <UsersTable url='/administrators' area="credito" level="administrator" inviteButtonText={translate.level('administrator')} columns={mainColumns.administrator} />,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const collaborators: IRoute = {
  path: '/credito/collaborator',
  name: 'Funcionários',
  icon: TeamOutlined,
  component: () => <UsersTable url='/backoffices' area="credito" level="backoffice" inviteButtonText={translate.level('backoffice')} columns={mainColumns.backoffice} />,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const analiseCreditoTeam = {
  leaders,
  collaborators
}

export {
  analiseCreditoTeam
}

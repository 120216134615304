
import { Col, Empty, message, Pagination, Select, Skeleton, Timeline } from 'antd'
import { DatepickerCustom } from 'components'

import locale from 'antd/es/date-picker/locale/pt_BR'
import EmailRepository from 'egi/repositories/EmailRepository'
import React, { useEffect, useState } from 'react'
import { timeAsDayjs } from 'utils/time'

import { ConfigurationFinished } from '../ConfigurationFinished/ConfigurationFinished'
import { EmailConfigurationTimeLine } from '../EmailConfigurationTimeline/EmailConfigurationTimelineItem'
import ReRequestButton from 'components/ReRequest/ReRequest'
import { IConfig } from '../../views/EmailConfig/EmailConfig'

export interface IConfigLogs {
  createdAt: string
  last: IConfig
  new: IConfig
  modifiedBy: {
    name: string
    email: string
    level: string
  }
}

function LoadingComponent () {
  return (
    <>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </>
  )
}

export function EmailLogs () {
  const [logs, setLogs] = useState<IConfigLogs[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [totalDocs, setTotalDocs] = useState(0)
  const [error, setError] = useState<string>()
  const [configFinished, setConfigFinished] = useState<boolean>(true)
  const [params, setParams] = useState({
    page: 1,
    limit: 10
  })

  const selectFilter = [
    { label: 'From', value: 'from' },
    { label: 'Host', value: 'host' },
    { label: 'Password', value: 'pass' },
    { label: 'Port', value: 'port' },
    { label: 'Secure', value: 'secure' },
    { label: 'Usar smtp Rodobens', value: 'useAltMail' },
    { label: 'Usuário', value: 'user' },
    { label: 'TLS', value: 'tls' }
  ]

  const getEmailConfig = async () => {
    try {
      const response = await EmailRepository.config()
      setConfigFinished(response.data.data?.mailerConfiguration.isConfigured ?? false)
    } catch (err) {
      message.error(err.message)
    }
  }

  const getEmailLogs = async (params: any) => {
    setLoading(true)
    setError(undefined)
    try {
      const response = await EmailRepository.logs({ params })
      const mailingConfigurationLogs = response?.data?.data?.mailingConfigurationLogs

      setTotalDocs(mailingConfigurationLogs?.totalDocs ?? 0)

      if (!mailingConfigurationLogs?.docs.length && !logs.length) throw new Error('Não foi possível encontrar alterações')
      if (mailingConfigurationLogs?.docs) setLogs(mailingConfigurationLogs?.docs)
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getEmailConfig()
    getEmailLogs(params)
  }, [params])

  return <div className="email-config">

    {
      !configFinished && <ConfigurationFinished />
    }

    <div>
      <div className="email-config__title-header">
        <Col lg={12}className="flex">
          <ReRequestButton
            onClick={() => getEmailLogs({ page: 1, limit: 10 })}
          />

          <Select
            className="ml-2 w-100"
            onChange={(value) => setParams(prev => ({ ...prev, field: value, page: 1 }))}
            placeholder='Selecione a propriedade'>
            {selectFilter.map(item => <Select.Option key={item.value} value={item.value}>
              {item.label}
            </Select.Option>)
            }

          </Select>

          <DatepickerCustom.MonthPicker
            format='MM/YYYY'
            onChange={(date) => {
              setLogs([])
              setParams(prev => ({
                ...prev,
                date: timeAsDayjs(date ?? new Date()).format('YYYY-MM'),
                page: 1
              }))
            }}
            className="ml-2"
            locale={locale} />

        </Col>
      </div>

      {loading &&
        <LoadingComponent />
      }

      {error && <Empty description={error} />}

      {!loading && (
        <Timeline>
          {logs && logs.map((item, index) => {
            return (
              <Timeline.Item key={index}>
                <EmailConfigurationTimeLine
                  item={item} />
              </Timeline.Item>
            )
          })}
        </Timeline>)
      }

      <Pagination
        total={totalDocs}
        onChange={(page) => setParams(prev => ({ ...prev, page: page }))}
      />
    </div>
  </div>
}


import { EyeOutlined } from '@ant-design/icons'
import { Divider, Tag } from 'antd'

import Modal from 'antd/lib/modal/Modal'
import { ColumnsType } from 'antd/lib/table'
import { Table } from 'components'

import React, { useMemo, useState } from 'react'
import { IFileError, ILineErrors, INotaryFees } from '../../notaryFeesInterfaces'

interface IProps {
  row: INotaryFees
}

function DisplayErrorsModal ({ row }: IProps) {
  const [visible, setVisible] = useState<boolean>(false)

  const onCancel = () => {
    setVisible(false)
  }

  const lineErrorColumns: ColumnsType<ILineErrors> = useMemo(() => [
    {
      align: 'center',
      title: 'Linha',
      dataIndex: 'line'
    },
    {

      title: 'Motivo',
      dataIndex: 'reason'
    }

  ], [])

  const fileErrorColumns: ColumnsType<IFileError> = useMemo(() => [
    {
      align: 'center',
      title: 'Campo',
      dataIndex: 'field'
    },
    {

      title: 'Motivo',
      dataIndex: 'message'
    }

  ], [])

  return (
    <>
      <Modal
        width="700px"
        title={`Detalhamento de erros da importação ${row.notaryFeeCode}`}
        visible={visible}
        destroyOnClose={true}
        onCancel={onCancel}
        footer={null}
      >
        {row?.fileError?.length > 0 && (
          <>
            <h3 className="display-errors-modal__title">Erros de importação:</h3>
            <Table
              data={row?.fileError}
              columns={fileErrorColumns}
              loading={false}
              canSearch={false}
            />
          </>
        )}

        {(row?.fileError?.length && row?.lineErrors?.length > 0) && <Divider />}

        {row.lineErrors.length > 0 && (
          <>
            <h3 className="display-errors-modal__title">
            Erros dentro do arquivo:
            </h3>
            <Table
              data={row?.lineErrors}
              columns={lineErrorColumns}
              loading={false}
              canSearch={false} />
          </>
        )}
      </Modal>

      <Tag
        className="display-errors-modal__see-errors"
        color={'var(--refused-status-color)'}
        onClick={() => setVisible(true)}
      >
        <EyeOutlined />&nbsp;
        Ver erros
      </Tag>
    </>

  )
}

export default DisplayErrorsModal

import { message } from 'antd'
import { AxiosRequestConfig } from 'axios'
import { IErrorDetails } from 'components/ErrorDetails/interfaces'
import { IPermission, IUserPermission } from 'egi/repositories/PermissionsRepository'
import { PermissionNotFound, PermissionsService, UserPermissionsService } from 'egi/services/PermissionService'
import { useAuth, useResources } from 'hooks'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { resourcesSetUserPermissions } from 'store/modules/resources/actions'

function usePermissionsList () {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<IErrorDetails>()
  const [permissions, setPermissions] = useState<Array<IPermission>>([])

  const getPermissions = async (config: AxiosRequestConfig) => {
    try {
      setLoading(true)
      const permissionService = new PermissionsService()
      const response = await permissionService.getAll(config)

      const { permissions } = response.data?.data ?? {}
      if (!permissions) throw new PermissionNotFound()

      setPermissions(permissions)
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    error,
    permissions,
    getPermissions
  }
}

function useUserPermissionsConfigList () {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<IErrorDetails>()
  const [userPermissions, setUserPermissions] = useState<Array<IUserPermission>>([])

  const getUserPermissions = async (userId: string, config: AxiosRequestConfig, permissionsCallback?: (userPermissions: IUserPermission[]) => void) => {
    try {
      setLoading(true)
      const permissionService = new UserPermissionsService()
      const response = await permissionService.getAllByUser(userId, config)

      const userPermissions = response.data?.data?.permissions
      if (!userPermissions) throw new PermissionNotFound()

      if (permissionsCallback) permissionsCallback(userPermissions)
      setUserPermissions(userPermissions)
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  const onRemoveItemFromUserPermissions = async (userPermissionId: string) => {
    setUserPermissions(prev => prev.filter(({ _id }) => _id !== userPermissionId))
  }

  const onAddItemFromUserPermissions = async (userPermission: IUserPermission) => {
    setUserPermissions(prev => [...prev, userPermission])
  }

  const onSetUserPermissions = (userPermissions: IUserPermission[]) => {
    setUserPermissions(userPermissions)
  }

  return {
    loading,
    error,
    getUserPermissions,
    onRemoveItemFromUserPermissions,
    onAddItemFromUserPermissions,
    userPermissions,
    onSetUserPermissions
  }
}

// To check user permission in general, use this implementation
function useUserPermissions () {
  const user = useAuth()
  const dispatch = useDispatch()

  const { getUserPermissions, error, loading, userPermissions, onSetUserPermissions } = useUserPermissionsConfigList()
  const { userPermissions: resourceUserPermissions } = useResources()

  useEffect(() => {
    const abortController = new AbortController()

    const config: AxiosRequestConfig = {
      signal: abortController.signal
    }

    if (!user?._id) return undefined

    if (resourceUserPermissions === null) {
      const permissionsCallback = (userPermissions: IUserPermission[]) => {
        dispatch(resourcesSetUserPermissions(userPermissions))
      }

      getUserPermissions(user._id, config, permissionsCallback)
    } else {
      onSetUserPermissions(resourceUserPermissions)
    }

    return () => {
      abortController.abort()
    }
  }, [user?._id])

  return {
    userPermissions,
    loading,
    error
  }
}

function usePermissionsDelete () {
  const [loading, setLoading] = useState(false)

  const deletePermission = async (userPermissionId: string, config?: AxiosRequestConfig) => {
    try {
      setLoading(true)
      const permissionService = new UserPermissionsService()
      const response = await permissionService.delete(userPermissionId, config)

      message.success(response.data?.message)
    } catch (err) {
      message.error(err?.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    deletePermission
  }
}

export {
  usePermissionsList,
  usePermissionsDelete,
  useUserPermissionsConfigList,
  useUserPermissions
}

import React, { Fragment, useLayoutEffect, useState } from 'react'
import { resources } from 'egi/consumers'
import { Form, Select } from 'antd'
import DrawerFiltersLineCollapsable, { DrawerFiltersLineCollapsableItem } from 'components/DrawerFilters/DrawerFiltersLineCollapsable'
import { useAuth } from 'hooks'
import ProposalEgiModel from 'egi/models/ProposalEgiModel'

export type FilterPromoterProps = {
  types?: 'IN' | 'CB' | 'TM' | undefined

}

function FilterPromoter ({ types = undefined }: FilterPromoterProps) {
  const [selectValues, setSelectValues] = useState([])

  const user = useAuth()

  const getPromoter = async () => {
    try {
      const response = await resources.promoters({ types })
      const promoters = response.data.promoters
      setSelectValues(promoters)
    } catch (err) {
      console.warn(err)
    }
  }

  useLayoutEffect(() => {
    getPromoter()
  }, [])

  if (ProposalEgiModel.canFilterByPromoter(user.level, user.areaName)) {
    return (
      <DrawerFiltersLineCollapsable label='Parceiro'>

        <DrawerFiltersLineCollapsableItem>
          <Form.Item
            name='promoterId'
            initialValue={undefined}
          >
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              className="w-100"
              placeholder='Selecione o parceiro'
            >
              <Select.Option value="">Todos</Select.Option>
              {selectValues.map((item: any) => (
                <Select.Option key={item._id} value={item._id}>{`${types !== undefined ? '' : `${item.type} -`} ${item.name}`}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </DrawerFiltersLineCollapsableItem>
      </DrawerFiltersLineCollapsable>
    )
  }

  return <Fragment/>
}

export default FilterPromoter

import React, { Fragment, useState } from 'react'
import { Drawer } from 'components'
import { Button, Form, Image } from 'antd'
import BacenText from './BacenText/BacenText'
import { PrinterOutlined } from '@ant-design/icons'
import imagesUrls from 'utils/imagesUrls'

import printContent from 'utils/print'
import swal from 'utils/swal'
import { timeAsDayjs } from 'utils/time'
import { IBacenAgreementInformation } from './types'
import { swalError } from 'components/SwalError/SwalError'
import usePartnerStepDrawer from '../../../../hooks/usePartnerStepDrawer'
import usePartnerStep from '../../../../hooks/usePartnerStep'
import usePartnerDepartments from '../../../../hooks/usePartnerDepartments'
import usePartnerDocumentation from '../../../../hooks/usePartnerDocumentation'
import PartnerStepButton from '../../../PartnerStepButton/PartnerStepButton'
import PartnerModel from 'egi/models/PartnerModel'
import PartnersRepository from 'egi/repositories/PartnersRepository'

function BacenStep () {
  const { close, open, visible } = usePartnerStepDrawer()
  const { step } = usePartnerStep()
  const agreementInformation = step.form as IBacenAgreementInformation || undefined
  const { slug } = usePartnerDepartments()
  const [formRef] = Form.useForm()
  const { _id: documentId } = usePartnerDocumentation()
  const [status, setStatus] = useState<'back' | 'saving' | 'sending'>()
  const sendForm = PartnerModel.canSendForm(step)

  async function submitAcceptanceOfTerms () {
    setStatus('sending')
    try {
      const response = await PartnersRepository.send(documentId, { department: slug, step: step.slug, agreement: true })
      swal.basic({ title: 'Sucesso!', text: response?.data.message, icon: 'success' })
      setStatus(undefined)
      close()
    } catch (err) {
      swalError({ title: 'Atenção', icon: 'warning', err })
    }
    setStatus(undefined)
  }

  return (
    <Fragment>
      <PartnerStepButton
        status={step.status}
        enabled={true}
        onClick={open}
        step={step}
      >
        {step.name}
      </PartnerStepButton>

      <Drawer
        showSubmit
        visible={visible}
        onClose={close}
        onSubmit={() => submitAcceptanceOfTerms()}
        onSave={() => formRef.submit()}
        status={status}
        disabled={!sendForm}
        title={step.name}
        sendTextOption='Li e concordo com os termos'
      >
        <Fragment>
          <div id='bacenText'>
            <div className="bacenStep__text my-4">
              <div className='bacenStep__image'>
                <Image
                  src={imagesUrls.rodobensLogoLight}
                  preview={false}
                  alt="rodobens"
                />
              </div>
              <BacenText />
            </div>

            {agreementInformation && agreementInformation.agreement && (
              <div>
                <p className='bacenStep__agreementText'>Código de autenticação: <span className='bold'> {agreementInformation.authenticationCode}</span></p>
                <p className='bacenStep__agreementText'>Assinado em: {timeAsDayjs(agreementInformation.assignedAt).format('DD/MM/YYYY HH:mm')}</p>
              </div>
            )}
          </div>

          <div className='flex flex-justify-center'>
            <Button
              type='primary'
              onClick={() => printContent('bacenText')}
            >
              Imprimir <PrinterOutlined style={{ marginLeft: '10px' }} />
            </Button>
          </div>
        </Fragment>
      </Drawer>
    </Fragment>
  )
}

export default BacenStep

import React from 'react'

export default function ListConfigurationSvg () {
  return (
    <svg width="242" height="202" viewBox="0 0 242 202" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M133.623 130.086C132.545 144.293 121.996 155.478 110.764 164.351C99.0515 173.604 85.2479 182.661 70.4868 179.965C56.0031 177.32 49.0537 162.254 39.3059 151.301C27.9959 138.592 11.0727 128.863 9.98188 111.977C8.74159 92.7767 15.6974 69.2637 33.5156 61.6633C50.9276 54.2362 67.5318 72.8569 85.1398 79.9182C96.1701 84.3417 107.812 86.2415 116.045 94.7599C125.575 104.621 134.655 116.495 133.623 130.086Z" fill="#00441F"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M231.013 57.0771C230.143 68.1304 222.21 76.876 213.778 83.8278C204.985 91.0767 194.631 98.1828 183.615 96.1538C172.806 94.1629 167.683 82.4787 160.45 74.0055C152.058 64.1743 139.459 56.6862 138.721 43.56C137.882 28.6349 143.186 10.3186 156.533 4.32694C169.576 -1.52816 181.896 12.8761 195.019 18.2868C203.24 21.6762 211.929 23.1004 218.041 29.687C225.116 37.312 231.846 46.5039 231.013 57.0771Z" fill="#00441F"/>
      <g clipPath="url(#clip0_2_533)">
        <path d="M211.416 142.78C211.416 147.75 207.386 151.78 202.416 151.78H52C47.0294 151.78 43 147.75 43 142.78V41C43 36.0294 47.0294 32 52 32H202.416C207.386 32 211.416 36.0294 211.416 41V142.78Z" fill="white"/>
        <path d="M56.7188 81.1956C56.2836 81.1956 55.8663 81.3687 55.5585 81.6768C55.2508 81.9849 55.0779 82.4028 55.0779 82.8386C55.0779 83.2743 55.2508 83.6922 55.5585 84.0003C55.8663 84.3084 56.2836 84.4815 56.7188 84.4815H113.593C114.028 84.4807 114.444 84.3072 114.752 83.9992C115.059 83.6912 115.231 83.2738 115.231 82.8386C115.231 82.4033 115.059 81.9859 114.752 81.6779C114.444 81.3699 114.028 81.1964 113.593 81.1956H56.7188Z" fill="#00441F"/>
        <path d="M56.7189 91.0534C56.2837 91.0525 55.8659 91.2247 55.5575 91.5322C55.2492 91.8396 55.0754 92.2572 55.0745 92.6929C55.0735 93.1286 55.2456 93.5469 55.5527 93.8557C55.8597 94.1644 56.2768 94.3384 56.712 94.3393H113.635C114.07 94.3393 114.488 94.1662 114.796 93.8581C115.103 93.55 115.276 93.1321 115.276 92.6964C115.276 92.2606 115.103 91.8427 114.796 91.5346C114.488 91.2265 114.07 91.0534 113.635 91.0534H56.7189Z" fill="#00441F"/>
        <path d="M56.7189 100.77C56.2837 100.769 55.8659 100.942 55.5575 101.249C55.2492 101.556 55.0754 101.974 55.0745 102.41C55.0735 102.845 55.2456 103.264 55.5527 103.572C55.8597 103.881 56.2768 104.055 56.712 104.056H69.5214C69.9566 104.056 70.374 103.883 70.6817 103.575C70.9895 103.267 71.1624 102.849 71.1624 102.413C71.1624 101.977 70.9895 101.56 70.6817 101.251C70.374 100.943 69.9566 100.77 69.5214 100.77H56.7189Z" fill="#00441F"/>
        <path d="M158.423 125.46C157.641 125.459 156.89 125.769 156.335 126.322C155.781 126.875 155.469 127.626 155.467 128.409C155.466 129.193 155.775 129.945 156.327 130.5C156.879 131.056 157.628 131.369 158.411 131.371H186.596C186.984 131.371 187.368 131.295 187.726 131.147C188.085 130.998 188.41 130.781 188.685 130.506C188.959 130.232 189.177 129.906 189.326 129.547C189.474 129.188 189.55 128.804 189.55 128.416C189.55 128.027 189.474 127.643 189.326 127.284C189.177 126.925 188.959 126.599 188.685 126.325C188.41 126.05 188.085 125.833 187.726 125.684C187.368 125.536 186.984 125.46 186.596 125.46H158.423Z" fill="#E4E4E4"/>
        <path d="M158.423 89.6705C157.641 89.6695 156.89 89.9795 156.335 90.5325C155.781 91.0855 155.469 91.8362 155.467 92.6197C155.466 93.4031 155.775 94.1551 156.327 94.7105C156.879 95.2658 157.628 95.579 158.411 95.5813H186.596C186.984 95.5816 187.368 95.5055 187.726 95.3571C188.085 95.2088 188.41 94.9912 188.685 94.7167C188.959 94.4422 189.177 94.1163 189.326 93.7575C189.474 93.3988 189.55 93.0143 189.55 92.6259C189.55 92.2375 189.474 91.853 189.326 91.4943C189.177 91.1355 188.959 90.8096 188.685 90.5351C188.41 90.2606 188.085 90.043 187.726 89.8947C187.368 89.7463 186.984 89.6701 186.596 89.6705H158.423Z" fill="#E4E4E4"/>
        <path d="M56.7189 126.829C56.5034 126.829 56.2899 126.871 56.0907 126.953C55.8914 127.035 55.7102 127.156 55.5575 127.308C55.4048 127.46 55.2836 127.641 55.2007 127.84C55.1178 128.039 55.0749 128.253 55.0745 128.469C55.074 128.684 55.116 128.898 55.1981 129.098C55.2801 129.297 55.4006 129.478 55.5527 129.631C55.7047 129.784 55.8854 129.906 56.0843 129.989C56.2832 130.072 56.4965 130.115 56.712 130.115H113.635C114.07 130.115 114.488 129.942 114.796 129.634C115.103 129.326 115.276 128.908 115.276 128.472C115.276 128.036 115.103 127.618 114.796 127.31C114.488 127.002 114.07 126.829 113.635 126.829H56.7189Z" fill="#E4E4E4"/>
        <path d="M56.7189 136.546C56.5034 136.545 56.2899 136.587 56.0907 136.67C55.8914 136.752 55.7102 136.872 55.5575 137.025C55.4048 137.177 55.2836 137.358 55.2007 137.557C55.1178 137.756 55.0749 137.97 55.0745 138.185C55.074 138.401 55.116 138.615 55.1981 138.814C55.2801 139.014 55.4006 139.195 55.5527 139.348C55.7047 139.501 55.8854 139.622 56.0843 139.705C56.2832 139.788 56.4965 139.831 56.712 139.832H69.5214C69.9566 139.832 70.374 139.659 70.6817 139.351C70.9895 139.043 71.1624 138.625 71.1624 138.189C71.1624 137.753 70.9895 137.335 70.6817 137.027C70.374 136.719 69.9566 136.546 69.5214 136.546H56.7189Z" fill="#E4E4E4"/>
        <path d="M56.7188 116.971C56.2836 116.971 55.8663 117.144 55.5585 117.452C55.2508 117.761 55.0779 118.178 55.0779 118.614C55.0779 119.05 55.2508 119.468 55.5585 119.776C55.8663 120.084 56.2836 120.257 56.7188 120.257H113.593C114.028 120.256 114.444 120.083 114.752 119.775C115.059 119.467 115.231 119.049 115.231 118.614C115.231 118.179 115.059 117.762 114.752 117.454C114.444 117.146 114.028 116.972 113.593 116.971H56.7188Z" fill="#E4E4E4"/>
        <path d="M56.7188 45.4199C56.2836 45.4199 55.8663 45.593 55.5585 45.9011C55.2508 46.2093 55.0779 46.6271 55.0779 47.0629C55.0779 47.4986 55.2508 47.9165 55.5585 48.2246C55.8663 48.5328 56.2836 48.7059 56.7188 48.7059H113.593C114.028 48.705 114.444 48.5316 114.752 48.2235C115.059 47.9155 115.231 47.4981 115.231 47.0629C115.231 46.6277 115.059 46.2103 114.752 45.9022C114.444 45.5942 114.028 45.4207 113.593 45.4199H56.7188Z" fill="#E4E4E4"/>
        <path d="M56.7189 55.2777C56.5034 55.2773 56.2899 55.3193 56.0907 55.4015C55.8914 55.4836 55.7102 55.6042 55.5575 55.7565C55.4048 55.9087 55.2836 56.0896 55.2007 56.2887C55.1178 56.4879 55.0749 56.7015 55.0745 56.9172C55.074 57.133 55.116 57.3467 55.1981 57.5462C55.2801 57.7457 55.4006 57.9271 55.5527 58.08C55.7047 58.2329 55.8854 58.3543 56.0843 58.4373C56.2832 58.5202 56.4965 58.5632 56.712 58.5636H113.635C113.851 58.5636 114.064 58.5212 114.263 58.4386C114.462 58.356 114.643 58.235 114.796 58.0824C114.948 57.9299 115.069 57.7487 115.151 57.5494C115.234 57.3501 115.276 57.1364 115.276 56.9207C115.276 56.7049 115.234 56.4913 115.151 56.2919C115.069 56.0926 114.948 55.9115 114.796 55.7589C114.643 55.6064 114.462 55.4853 114.263 55.4028C114.064 55.3202 113.851 55.2777 113.635 55.2777H56.7189Z" fill="#E4E4E4"/>
        <path d="M56.7189 64.9946C56.2837 64.9937 55.8659 65.1659 55.5575 65.4733C55.2492 65.7808 55.0754 66.1983 55.0745 66.6341C55.0735 67.0698 55.2456 67.4881 55.5527 67.7968C55.8597 68.1056 56.2768 68.2796 56.712 68.2805H69.5214C69.9566 68.2805 70.374 68.1074 70.6817 67.7993C70.9895 67.4912 71.1624 67.0733 71.1624 66.6375C71.1624 66.2018 70.9895 65.7839 70.6817 65.4758C70.374 65.1677 69.9566 64.9946 69.5214 64.9946H56.7189Z" fill="#E4E4E4"/>
        <path d="M158.423 53.9089C157.641 53.9078 156.89 54.2178 156.335 54.7708C155.781 55.3238 155.469 56.0746 155.467 56.858C155.466 57.6414 155.775 58.3935 156.327 58.9488C156.879 59.5042 157.628 59.8174 158.411 59.8196H186.596C186.984 59.82 187.368 59.7438 187.726 59.5955C188.085 59.4471 188.41 59.2295 188.685 58.955C188.959 58.6806 189.177 58.3546 189.326 57.9959C189.474 57.6371 189.55 57.2526 189.55 56.8642C189.55 56.4759 189.474 56.0913 189.326 55.7326C189.177 55.3738 188.959 55.0479 188.685 54.7734C188.41 54.499 188.085 54.2813 187.726 54.133C187.368 53.9846 186.984 53.9085 186.596 53.9089H158.423Z" fill="#E4E4E4"/>
        <path d="M164.361 62.1363C167.284 62.1363 169.654 59.7633 169.654 56.8362C169.654 53.909 167.284 51.5361 164.361 51.5361C161.437 51.5361 159.067 53.909 159.067 56.8362C159.067 59.7633 161.437 62.1363 164.361 62.1363Z" fill="#00CF7B"/>
        <path d="M173.613 93.9075C173.554 94.333 173.59 94.7661 173.716 95.1765C173.843 95.5869 174.058 95.9644 174.346 96.2826C174.634 96.6008 174.988 96.8519 175.384 97.0182C175.779 97.1844 176.207 97.2618 176.635 97.245L179.446 103.34L183.562 102.533L179.472 93.9814C179.396 93.2623 179.059 92.5961 178.525 92.1091C177.991 91.6221 177.297 91.348 176.575 91.3389C175.853 91.3298 175.153 91.5862 174.607 92.0596C174.061 92.533 173.707 93.1905 173.613 93.9075Z" fill="#FFB6B6"/>
        <path d="M202.09 116.974L190.621 111.293L182.527 97.5404L176.87 100.28L184.212 116.168L202.759 126.887L202.09 116.974Z" fill="#00CF7B"/>
        <path d="M176.197 198.275L172.591 198.274L170.875 187.866L176.197 187.867L176.197 198.275Z" fill="#FFB6B6"/>
        <path d="M177.116 201.774L165.49 201.773V201.626C165.49 200.425 165.967 199.272 166.816 198.423C167.664 197.573 168.815 197.096 170.015 197.096H170.016L177.116 197.096L177.116 201.774Z" fill="#00441F"/>
        <path d="M212.186 191.654L209.67 194.24L201.026 188.208L204.739 184.391L212.186 191.654Z" fill="#FFB6B6"/>
        <path d="M215.331 193.436L207.22 201.774L207.114 201.671C206.255 200.833 205.763 199.687 205.747 198.485C205.731 197.284 206.193 196.125 207.03 195.265L211.984 190.172L215.331 193.436Z" fill="#00441F"/>
        <path d="M180.654 136.331H178.16C171.678 143.424 166.729 151.782 163.625 160.88L160.646 169.613L171.264 191.87L177.889 191.989L170.317 168.359L177.946 158.229L178.33 178.591L202.885 191.477L207.799 184.209L187.818 173.27L191.221 155.95C191.221 155.95 198.067 150.235 194.081 141.299L180.654 136.331Z" fill="#00441F"/>
        <path d="M213.474 113.665L203.112 109.121L191.706 114.181C191.706 114.181 188.476 116.021 190.535 120.732L178.16 136.331C178.16 136.331 178.183 136.338 193.735 141.299L207.767 128.927C209.897 127.048 211.533 124.673 212.528 122.011C213.524 119.349 213.849 116.483 213.474 113.665Z" fill="#00CF7B"/>
        <path d="M211.735 108.987C215.724 108.987 218.958 105.749 218.958 101.755C218.958 97.7608 215.724 94.5229 211.735 94.5229C207.746 94.5229 204.512 97.7608 204.512 101.755C204.512 105.749 207.746 108.987 211.735 108.987Z" fill="#FFB6B6"/>
        <path d="M221.732 92.184C220.075 90.8338 217.503 90.539 215.792 91.8203C212.807 90.2518 208.774 91.07 206.634 93.678C205.864 94.6164 205.318 95.886 205.699 97.039C206.08 98.192 207.723 98.8698 208.58 98.0097L208.565 98.3641C209.336 99.1375 209.871 100.115 210.106 101.183C210.341 102.25 210.268 103.362 209.893 104.389C209.519 105.416 208.86 106.314 207.993 106.978C207.126 107.643 206.088 108.045 205 108.139C207.367 109.097 209.734 110.054 212.101 111.011C213.097 111.414 214.125 111.823 215.199 111.818C216.273 111.812 217.418 111.29 217.834 110.299C218.134 111.218 218.434 112.138 218.733 113.058C220.498 110.248 221.949 107.252 223.061 104.125C223.778 102.109 224.357 100.001 224.28 97.8632C224.202 95.7251 223.39 93.5343 221.732 92.184Z" fill="#2F2E41"/>
        <path d="M164.361 133.688C167.284 133.688 169.654 131.315 169.654 128.388C169.654 125.46 167.284 123.087 164.361 123.087C161.437 123.087 159.067 125.46 159.067 128.388C159.067 131.315 161.437 133.688 164.361 133.688Z" fill="#00CF7B"/>
        <path d="M180.83 97.926C183.754 97.926 186.124 95.553 186.124 92.6259C186.124 89.6987 183.754 87.3258 180.83 87.3258C177.906 87.3258 175.536 89.6987 175.536 92.6259C175.536 95.553 177.906 97.926 180.83 97.926Z" fill="#00441F"/>
        <path d="M180.965 90.963C180.907 91.3884 180.942 91.8216 181.068 92.232C181.195 92.6423 181.41 93.0199 181.698 93.3381C181.986 93.6563 182.341 93.9074 182.736 94.0736C183.132 94.2399 183.559 94.3173 183.988 94.3004L186.799 100.396L190.915 99.5887L186.824 91.0369C186.748 90.3178 186.411 89.6516 185.877 89.1646C185.344 88.6775 184.65 88.4035 183.928 88.3944C183.205 88.3853 182.505 88.6417 181.959 89.1151C181.413 89.5885 181.059 90.246 180.965 90.963Z" fill="#FFB6B6"/>
        <path d="M209.443 114.029L197.973 108.349L189.88 94.5959L184.223 97.3358L191.564 113.223L210.111 123.943L209.443 114.029Z" fill="#00CF7B"/>
      </g>
      <defs>
        <clipPath id="clip0_2_533">
          <rect width="199" height="170" fill="white" transform="translate(43 32)"/>
        </clipPath>
      </defs>
    </svg>

  )
}

import React, { useState } from 'react'
import { ModalInvite, ServerTable } from 'components'
import allInvitesColumns from '../components/allInvitesColumns/allInvitesColumns'
import filters from 'components/SeverTable/IndexFilters'
import FilterType from 'components/SeverTable/FilterPromoterType'
import FilterLevel from 'components/SeverTable/FilterLevel'
import FilterArea from 'components/SeverTable/FilterArea'
import FilterValidLink from '../components/FilterValidLink/FilterValidLink'
import { tablesSetFiltersValues } from 'store/modules/tables/actions'
import { useDispatch } from 'react-redux'
import { useAuth, useTables } from 'hooks'
import ButtonModel from 'components/SeverTable/ButtonModel'
import UserModels from 'egi/models/UserModels'
import { LimiterTable } from 'components/LimiterTable/LimiterTable'

function AllInvites () {
  const auth = useAuth()
  const dispatch = useDispatch()
  const tables = useTables()
  const [isModalVisible, setIsModalVisible] = useState(false)

  function canSeeFilters (): React.ReactElement[] {
    const addFilters: React.ReactElement[] = [
      <FilterType
        callback={(value: string) => dispatch(tablesSetFiltersValues({ ...tables.filters, type: value }))}
        key='type'
      />
    ]

    addFilters.push(
      <FilterArea
        onChange={value => dispatch(tablesSetFiltersValues({ ...tables.filters, inviteAreaName: value }))}
        name="inviteAreaName"
        placeholder="Escolha a área"
        label="Áreas"
      />
    )

    addFilters.push(
      <FilterLevel
        callback={(value: string) => dispatch(tablesSetFiltersValues({ ...tables.filters, inviteLevel: value }))}
      />
    )

    addFilters.push(
      <FilterValidLink
        onChange={value => {
          if (!value) return dispatch(tablesSetFiltersValues({ ...tables.filters, valid: undefined }))
          dispatch(tablesSetFiltersValues({ ...tables.filters, valid: value }))
        }}
      />
    )
    return addFilters
  }

  function allButtons (): React.ReactElement[] {
    const addButtons: React.ReactElement[] = [
      <ButtonModel
        onClick={() => dispatch(tablesSetFiltersValues({ ...tables.filters, download: true }))}
        text='Relatório de convites gerados'
        key={0}
      />
    ]

    if (UserModels.hasInviteActions(auth?.inviteAdmin)) {
      addButtons.push(
        <ButtonModel
          onClick={() => setIsModalVisible(true)}
          key={1}
          text='Convidar time'
        />
      )
    }
    return addButtons
  }

  return (
    <>
      <ModalInvite
        level={auth.level}
        onClose={() => setIsModalVisible(false)}
        visible={isModalVisible}
        invitedLevel={'para time'}
        canChooseType={true}
      />
      <LimiterTable>
        <ServerTable
          filters={canSeeFilters()}
          primaryFilter={filters().search}
          secondaryFilter={filters().createdAt}
          csvFilename="convites_gerados"
          buttons={allButtons()}
          columns={allInvitesColumns}
          url="/invites/sent"
        />
      </LimiterTable>
    </>
  )
}

export default AllInvites

import React from 'react'
import { ResponseStepProps } from '../../creditApproveInterfaces'

function CreditResponseStep ({ text, title, icon }: ResponseStepProps) {
  return (
    <div className="text-center">
      { icon }
      <h1 className="color-secondary credit-modal-title credit-approve-modal-title mt-3">{title}</h1>
      <p className="credit-modal-description credit-modal-description-margin">{text}</p>
    </div>
  )
}

export default CreditResponseStep

import { Checkbox, Col, Form, Input, Row } from 'antd'
import { FormListFieldData } from 'antd/lib/form/FormList'
import React, { Fragment, useEffect, useState } from 'react'
import { FormInstance } from 'antd/lib/form'

export interface ICertificateErrors {
  certificate?: string
  corbanCertificateName?: string
  corbanCertificateRole?: string
}

type Props = {
  errors: ICertificateErrors,
  readonly: boolean,
  fields?: FormListFieldData,
  formRef?: FormInstance
}

function getCertificateValue (fields: FormListFieldData | undefined, formRef: FormInstance | undefined) {
  const isAssociate = !!fields
  const associateIndex = fields?.key
  const associates = formRef?.getFieldValue('associates')

  if (isAssociate) {
    if (typeof associateIndex === 'number') return associates[associateIndex] !== undefined ? associates[associateIndex].certificate : undefined
  } else return formRef?.getFieldValue('certificate')
}

function CertificateFields ({ errors, fields, formRef, readonly }: Props) {
  const [hasCertificate, setHasCertificate] = useState<boolean>()

  useEffect(() => {
    setHasCertificate(getCertificateValue(fields, formRef))
  }, [fields, formRef?.getFieldsValue()])

  return (
    <Fragment>
      <Row align="bottom">
        <Col className="px-1" lg={10} sm={24} xs={24}>
          <Form.Item
            name={fields ? [fields.name, 'certificate'] : 'certificate'}
            className="form-user-checkbox"
            valuePropName="checked"
            help={errors?.certificate && (errors.certificate)}
            validateStatus={errors?.certificate && ('error')}
          >
            <Checkbox
              onChange={() => setHasCertificate(prev => !prev)}
              id="test-checkbox-policy"
              disabled={readonly}
            >
              Possui certificado de Correspondente Bancário?
            </Checkbox>
          </Form.Item>
        </Col>

        {hasCertificate && (
          <Fragment>
            <Col className="px-1" lg={7} sm={24} xs={24}>
              <Form.Item
                name={fields ? [fields.name, 'corbanCertificateName'] : ['corbanCertificate', 'name']}
                label={<label>Nome:</label>}
                help={errors?.corbanCertificateName && (errors.corbanCertificateName)}
                validateStatus={errors?.corbanCertificateName && ('error')}
              >
                <Input placeholder="Digite o nome" readOnly={readonly} />
              </Form.Item>
            </Col>

            <Col className="px-1" lg={7} sm={24} xs={24}>
              <Form.Item
                name={fields ? [fields.name, 'corbanCertificateRole'] : ['corbanCertificate', 'role']}
                label={<label>Cargo:</label>}
                help={errors?.corbanCertificateRole && (errors.corbanCertificateRole)}
                validateStatus={errors?.corbanCertificateRole && ('error')}
              >
                <Input placeholder="Digite o cargo" readOnly={readonly} />
              </Form.Item>
            </Col>
          </Fragment>
        )}
      </Row>
    </Fragment>
  )
}

export default CertificateFields

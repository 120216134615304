import { Collapse } from 'antd'
import React, { ReactElement } from 'react'

interface IProps {
  topics: {
    title: string
    content: ReactElement
    subtitles: string[]
  }[],
  showNavbar: boolean,
  showNav: () => void
}

const scrollIntoView = (element: Element) => {
  element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
}

const getElementToScroll = (scrollTo: string) => {
  const element = document.getElementById(`${scrollTo}`)
  if (element) scrollIntoView(element)
}

function DocsNavbar ({ topics, showNavbar, showNav }: IProps) {
  return (

    <div className={!showNavbar ? 'docs-nav' : 'docs-nav docs-nav--show'}>
      <h2 className="docs-nav__header color-primary">Tópicos</h2>
      {topics.map((item, index) =>
        <Collapse className="w-100" bordered={false} key="1" >
          <Collapse.Panel key={index} header={
            <a type="link" className="docs-nav__topic-title" onClick={(e) => {
              e.preventDefault()
              if (showNavbar) showNav()
              getElementToScroll(`${item.title.replace(' ', '')}`)
            }}
            >
              {item.title}
            </a>
          } >
            <ul>
              {
                item.subtitles.map(subtitle => {
                  return (
                    <li key={subtitle}><a href={`#${subtitle.replace(' ', '')}`}
                      onClick={(e) => {
                        e.preventDefault()
                        if (showNavbar) showNav()
                        getElementToScroll(`${item.title.replace(' ', '')}`)
                      }}>{subtitle}</a>
                    </li>
                  )
                })
              }
            </ul>
          </Collapse.Panel>
        </Collapse>)}
    </div>
  )
}

export default DocsNavbar

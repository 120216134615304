import React, { useState, useLayoutEffect, useRef, MutableRefObject } from 'react'
import { Col, Row, Form, Button, Select } from 'antd'
import { Input } from 'components'
import swal from 'utils/swal'
import format from 'utils/format'
import { validateResponse } from 'utils/validate'
import { useParams } from 'react-router-dom'
import { useAuth } from 'hooks'
import RepresentativeRepository from 'egi/repositories/RepresentativeRepository'
import ResourcesRepository from 'egi/repositories/ResourcesRepository'
import { IAttorney } from '../../attorneyInterfaces'
import AttorneyModel from 'egi/models/AttorneyModel'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'

function AttorneyForm () {
  const [loading, setLoading] = useState<boolean>(false)
  const [isUpdate, setIsUpdate] = useState<boolean>(false)
  const [errors, setErrors] = useState<any>({})
  const [states, setStates] = useState<string[]>([])

  const params: { id: string } = useParams()
  const formRef: MutableRefObject<any> = useRef(null)
  const user = useAuth()

  useLayoutEffect(() => {
    async function getStates () {
      try {
        const response = await ResourcesRepository.states()
        const states = response.data.data?.states
        if (!states) throw new Error('Nenhum estado encontrado')
        setStates(states)
      } catch (err) {
        console.log(err)
      }
    }

    getStates()
  }, [])

  useLayoutEffect(() => {
    const getAttorney = async (attorneyId: string) => {
      setIsUpdate(true)
      try {
        const response = await RepresentativeRepository.find(attorneyId)
        const attorney = response.data.data?.representatives
        if (!attorney) throw new Error('Falha ao buscar procurador')
        if (formRef && formRef.current) formRef.current.setFieldsValue(attorney)

        const element = document.querySelector('#auth-page-title')
        if (element && element.innerHTML.indexOf(':') === -1) element.insertAdjacentHTML('beforeend', ': ' + attorney.name)
      } catch (err) {
        swal.basic({ title: 'Atenção', icon: 'warning', text: err.message })
      }
    }

    if (params && params.id) getAttorney(params.id)
  }, [])

  const onSubmit = async (attorney: IAttorney) => {
    if (attorney.cpf) attorney.cpf = format.onlyDigits(attorney.cpf)
    if (attorney.rg) attorney.rg = format.onlyAlphaNumeric(attorney.rg)

    setLoading(true)
    try {
      const response = await RepresentativeRepository.create<IAttorney>({ ...attorney, type: 'attorney' })
      swal.basic({ title: 'Sucesso!', icon: 'success', text: response.data?.message || '' })
      window.history.back()
      setErrors({})
    } catch (err) {
      if (err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
        setLoading(false)
        swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
        return
      }
      setErrors({})
      swal.basic({ title: 'Atenção', icon: 'warning', text: err.message })
    }
    setLoading(false)
  }

  const onUpdate = async (attorney: IAttorney) => {
    if (params && params.id) {
      if (attorney.cpf) attorney.cpf = format.onlyDigits(attorney.cpf)
      if (attorney.rg) attorney.rg = format.onlyAlphaNumeric(attorney.rg)

      setLoading(true)
      try {
        const response = await RepresentativeRepository.update<IAttorney>(params.id, { ...attorney, type: 'attorney' })
        swal.basic({ title: 'Sucesso!', icon: 'success', text: response.data?.message })
        window.history.back()
        setErrors({})
      } catch (err) {
        if (err.data?.invalid) {
          setErrors(validateResponse(err.data?.invalid))
          setLoading(false)
          const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
          invalidFieldsError({ invalidFields: invalidFields })
          return
        }

        setErrors({})
        swal.basic({ title: 'Atenção', icon: 'warning', text: err.message })
      }
      setLoading(false)
    }
  }

  return (
    <div className="card-style my-3 p-3">
      <Form ref={formRef} onFinish={isUpdate ? onUpdate : onSubmit} layout="vertical">
        <Row>
          <Col lg={8} sm={24} xs={24} className="px-1">
            <Form.Item
              name="cpf"
              label={<label>CPF</label>}
              help={errors.cpf && errors.cpf}
              validateStatus={errors.cpf && 'error'}
              rules={[{ required: true, message: 'Digite o cpf.' }]}
            >
              <Input mask="cpf" placeholder="Digite o cpf" />
            </Form.Item>
          </Col>

          <Col lg={8} sm={24} xs={24} className="px-1">
            <Form.Item
              name="rg"
              label={<label>RG</label>}
              help={errors.rg && errors.rg}
              validateStatus={errors.rg && 'error'}
              rules={[{ required: true, message: 'Digite o rg.' }]}
            >
              <Input mask="rg" placeholder="Digite o rg" />
            </Form.Item>
          </Col>

          <Col className="px-1" lg={8} sm={12} xs={24}>
            <Form.Item
              name='SSP'
              label={<label >Orgão expedidor | UF:</label>}
              help={errors.state && (errors.state)}
              validateStatus={errors.state && ('error')}
            >
              <Select
                showSearch
                placeholder={'Escolha o orgão expedidor | UF'}
                filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {states.map(state => <Select.Option key={state} value={state}>{state}</Select.Option>)}
              </Select>
            </Form.Item>
          </Col>

          <Col lg={8} sm={24} xs={24} className="px-1">
            <Form.Item
              name="email"
              help={errors.email && errors.email}
              validateStatus={errors.email && 'error'}
              rules={[{ required: true, message: 'Digite o email.' }]}
              label={<label>Email</label>}
            >
              <Input
                placeholder="Digite o email"
                error={errors.email}
              />
            </Form.Item>
          </Col>

          <Col lg={8} sm={24} xs={24} className="px-1">
            <Form.Item
              name="name"
              label={<label>Nome</label>}
              help={errors.name && errors.name}
              validateStatus={errors.name && 'error'}
              rules={[{ required: true, message: 'Digite o nome.' }]}
            >
              <Input placeholder="Digite o nome" />
            </Form.Item>
          </Col>

          <Col lg={8} sm={24} xs={24} className="px-1">
            <Form.Item
              name='gender'
              label={<label >Gênero</label>}
              help={errors.gender && (errors.gender)}
              rules={[{ required: true, message: 'Escolha o gênero.' }]}
              validateStatus={errors.gender && ('error')}
            >
              <Select placeholder='Escolha o gênero'>
                <Select.Option value="male">Masculino</Select.Option>
                <Select.Option value="female">Feminino</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col lg={8} sm={24} xs={24} className="px-1">
            <Form.Item
              name='occupation'
              label={<label >Profissão</label>}
              help={errors.occupation && (errors.occupation)}
              rules={[{ required: true, message: 'Digite a profissão.' }]}
              validateStatus={errors.occupation && ('error')}
            >
              <Input placeholder="Digite a profissão" />
            </Form.Item>
          </Col>

          <Col lg={8} sm={24} xs={24} className="px-1">
            <Form.Item
              name='nationality'
              label={<label >Nacionalidade</label>}
              help={errors.nationality && (errors.nationality)}
              rules={[{ required: true, message: 'Digite sua nacionalidade' }]}
              validateStatus={errors.nationality && ('error')}
            >
              <Input placeholder="Digite a nacionalidade" />
            </Form.Item>
          </Col>

          <Col lg={8} sm={24} xs={24} className="px-1">
            <Form.Item
              name='maritalStatus'
              label={<label >Estado civil</label>}
              help={errors.gender && (errors.gender)}
              rules={[{ required: true, message: 'Escolha o estado civil.' }]}
              validateStatus={errors.gender && ('error')}
            >
              <Select placeholder='Escolha o estado civil'>
                <Select.Option value="SOLTEIRO">Solteiro(a)</Select.Option>
                <Select.Option value="CASADO">Casado(a)</Select.Option>
                <Select.Option value="DIVORCIADO">Divorciado(a)</Select.Option>
                <Select.Option value="SEPARADO">Separado(a) judicialmente</Select.Option>
                <Select.Option value="VIUVO">Viúvo(a)</Select.Option>
                <Select.Option value="DESQUITADO">Desquitado(a)</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            className="mx-1"
            disabled={AttorneyModel.isDisabledAttorneyFormSubmitButton(user.level)}
          >
            {isUpdate ? 'Salvar' : 'Cadastrar'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default AttorneyForm

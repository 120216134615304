import { AxiosRequestConfig } from 'axios'
import { IDocumentalHistoryLog } from 'ecp/app/Proposals/components/DocumentalStepsLogsHistory/DocumentalStepsLogsHistory'

import { IEmailAgeRequest, IRodobensKycReponse, IUnicoRequest } from 'ecp/app/Proposals/proposalInterfaces'

import { IResponseBase, Repository } from 'ecp/repositories/Repository'
import api from 'services/api'

class RodobensKYCRepository extends Repository {
  async emailAge (proposalId: string, data: IEmailAgeRequest, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.post(`${this.path}/email-age/${proposalId}`, data, config)
    )
  }

  async unico (proposalId: string, data: IUnicoRequest, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.post(`${this.path}/unico/init/${proposalId}`, data, config)
    )
  }

  async getDocumentalStep (proposalId: string, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/${proposalId}/anti-fraude-status`, config)
    )
  }

  async getDocumentalHistoryLogs (proposalId: string, config?: AxiosRequestConfig): Promise<IResponseBase<{
    logs: Array<IDocumentalHistoryLog>
  }>> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/${proposalId}/logs`, config)
    )
  }

  async getDocumentalHistory (proposalId: string, config?: AxiosRequestConfig) {
    return Repository.handle<{ data: {history: Array<IRodobensKycReponse>} }>(() =>
      this.api.get(`${this.path}/${proposalId}/history`, config)
    )
  }
}

export default new RodobensKYCRepository({ api, path: '/ecp-funcao/rodobens-kyc' })

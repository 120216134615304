import { ArrowRightOutlined, CaretRightOutlined } from '@ant-design/icons'
import { Tag, Timeline, Row, Col, Collapse, Tooltip } from 'antd'
import { TimelineProps } from 'antd/lib/timeline'
import Icons from 'components/Icons/Icons'
import { getNewItemProperties } from 'components/ReviewBackoffice/reviewBackofficeConstants'
import StringToHtml from 'components/StringToHtml/StringToHtml'
import { _proposalStatuses } from 'egi/app/ProposalEgi/proposalEgiInterfaces'
import React, { useState } from 'react'
import { colors } from 'styles/colors'
import format from 'utils/format'
import mask from 'utils/masks'
import { timeAsDayjs } from 'utils/time'
import translate, { ITranslateKeys } from 'utils/translate'
import { ILogsEmails, ILogsSMS, IStepHistory, IStepHistoryLastNew } from '../ReviewBackoffice/reviewBackofficeInterfaces'
import * as jsonDiff from 'json-diff'
import { extractJsonDiff, ILogChanges } from 'utils/utils'
import ProposalEgiRepository from 'egi/repositories/ProposalEgiRepository'
import Loading from 'components/Loading/Loading'
import { Link, useParams } from 'react-router-dom'
import translateAntForm, { FormatedFormItem } from 'utils/translateAntForm'
import dayjs from 'dayjs'

export function LastNewStatus ({ step }: { step: IStepHistory }) {
  const { last, new: newItem, newStatus, type } = step || {}

  if (type === 'uploaddocument' || type === 'removeddocument' || type === 'form') {
    return (
      <section>
        <Tag color={colors.primary} className="font-500 color-white mb-2">
          {type === 'uploaddocument' ? 'Inseriu documento' : type === 'removeddocument' ? 'Removeu documento' : 'Alterações no formulário'}
        </Tag>

        {type === 'form' && (
          <Tooltip title='Funcionalidade em fase de testes'>
            <Tag>Beta</Tag>
          </Tooltip>
        )}
      </section>
    )
  }

  const getStatusTag = (status: _proposalStatuses) => (
    <Tag color={!status ? colors.starting : colors[status]} className="font-500 color-white">
      {translate.proposalStatus(!status ? 'starting' : status)}
    </Tag>
  )

  if (last?.status && newItem?.status) {
    return (
      <section className='review-step-history__tags-container'>
        {getStatusTag(last.status)}
        <ArrowRightOutlined className="mr-2" />
        {getStatusTag(newItem.status)}
      </section>
    )
  }

  return getStatusTag(newStatus)
}

function HistoryInfo ({ step }: { step: IStepHistory }) {
  const { modifiedName, modifiedLevel, createdAt } = step || {}
  const itWasModified = !!modifiedName

  return (
    <aside className='review-step-history__modified-info'>
      {itWasModified && (
        <span>
          Alterado por: <strong>{modifiedName.toUpperCase()} {modifiedLevel && `(${translate.level(modifiedLevel)})` }</strong>
        </span>
      )}

      <span>
        Em: <strong>{timeAsDayjs(createdAt).format('DD/MM/YYYY HH:mm')}</strong>
      </span>
    </aside>
  )
}

function EmailSmsList ({ step }: { step: IStepHistory }) {
  if (!step.emails?.length && !step.smss?.length) return null

  const onlyFail = (info: ILogsEmails | ILogsSMS) => (info.failed && !info.sent)
  const onlySent = (info: ILogsEmails | ILogsSMS) => (!info.failed && info.sent)

  const renderEmailInfo = (info: ILogsEmails) => {
    if (onlyFail(info)) {
      return (
        <li key={info._id}>
          <b>Falha ao enviar o email para:</b> {info.email} - {timeAsDayjs(info.failedAt).format('DD/MM/YYYY HH:mm')}
        </li>
      )
    }

    if (onlySent(info)) {
      return (
        <>
          <li key={info._id}>
            <b>Email enviado para:</b> {info.email} - {timeAsDayjs(info.sentAt).format('DD/MM/YYYY HH:mm')}
          </li>

          <li key={`${info._id}-subject`}>
            <b>Assunto:</b> {info.subject}
          </li>
        </>
      )
    }

    return null
  }

  const renderSmsInfo = (info: ILogsSMS) => {
    if (onlyFail(info)) {
      return (
        <li key={info._id}>
          <b>Falha ao enviar SMS para:</b> {mask(String(info.phone), 'phone', true)} - {timeAsDayjs(info.failedAt).format('DD/MM/YYYY HH:mm')}
        </li>
      )
    }

    if (onlySent(info)) {
      return (
        <li key={info._id}>
          <b>SMS enviado para:</b> {mask(String(info.phone), 'phone', true)} - {timeAsDayjs(info.sentAt).format('DD/MM/YYYY HH:mm')}
        </li>
      )
    }

    return null
  }

  return (
    <ul>
      {step.emails?.map((info: ILogsEmails) => renderEmailInfo(info))}
      {step.smss?.map((info: ILogsSMS) => renderSmsInfo(info))}
    </ul>
  )
}

function GenericTagsCNDInsured ({ step }: { step: IStepHistory }) {
  const { new: newItem } = step || {}

  if (!newItem) return null

  const cdnProperties = getNewItemProperties(newItem)

  const renderProperty = ({ label, value }: { label: string; value?: string }, index: number) => (
    <Col key={index} className='review-step-history__new-item-properties'>
      <label>{label}: </label>
      <label className='review-step-history__new-item-properties--value'>{value}</label>

      {index < cdnProperties.length - 1 && (
        <span className='review-step-history__new-item-separator'>|</span>
      )}
    </Col>
  )

  if (cdnProperties.length < 1) return <></>

  return (
    <Row className='mt-2' gutter={[0, 5]}>
      {cdnProperties
        .filter((property) => !!property.value)
        .map((property, index) => renderProperty(property, index))}
    </Row>
  )
}

function ReviewCommentary ({ step }: {
  step: IStepHistory
}) {
  const isDocumentsHistory = (type: IStepHistory['type']) => {
    const typeDocuments: Array<IStepHistory['type']> = ['removeddocument', 'uploaddocument']
    return typeDocuments.includes(type)
  }

  if (!step.commentary) return null

  if (isDocumentsHistory(step.type)) {
    return (
      <div className='review-step-history__commentary mt-2'>
        <StringToHtml value={step?.commentary} />
      </div>
    )
  }

  return (
    <>
      <label className="review-step-history__title-message">Revisão interna do passo</label>
      <div className="review-step-history__commentary">
        <StringToHtml value={step?.commentary} />
      </div>
    </>
  )
}

function ReviewMessage ({ step }: { step: IStepHistory }) {
  if (!step.message) return <></>

  return (
    <div>
      <label className="review-step-history__title-message">Mensagem para o cliente</label>
      <div className="timeline-message-item review-step-history__commentary">
        <StringToHtml value={step.message} />
      </div>
    </div>
  )
}

function ReviewRefuseMessage ({ step }: { step: IStepHistory }) {
  if (!step.refuseCommentary) return <></>

  return (
    <div>
      <div className="timeline-message-item review-step-history__commentary">
        <StringToHtml value={step.refuseCommentary} />
      </div>
    </div>
  )
}

function ReviewShareStatus ({ step }: { step: IStepHistory }) {
  if (!step.shareStatus?.link) return <></>

  return (
    <section className="review-step-history__share-status-link">
      <h3 className="mb-0">
      O link <a
          target="_blank"
          className="link-style"
          rel="noreferrer"
          href={step.shareStatus.link}
        >
          {step.shareStatus.link}
        </a> irá expirar em: <span className='review-step-history__share-status-expires'>{timeAsDayjs(step?.shareStatus?.expiresIn).format('DD/MM/YYYY HH:mm')}</span>
      </h3>
    </section>
  )
}

function ChangeInfo ({ property, lastValue, newValue }: { property: string, lastValue: any, newValue: any }) {
  if (String(lastValue) === String(newValue) || property === 'status') return null

  return (
    <p key={property} className='review-step-history__change-item'>
      <span className='bold mr-1'>{property}:</span>
      <span style={{ color: 'var(--refused-status-color)' }}>{lastValue}</span>
      <ArrowRightOutlined className="ml-2 mr-2" />
      <span style={{ color: 'green' }}>{newValue}</span>
    </p>
  )
}

function extractText (labelFor?: string) {
  if (!labelFor) return undefined

  if (labelFor.includes('_')) {
    const parts = labelFor.split('_')
    return parts[parts.length - 1]
  } else {
    return labelFor
  }
}

function formattedLabels (labels: FormatedFormItem[]) {
  const labelsFormatted = labels.map(label => ({
    label: label.label.replace(':', '').trim(),
    labelFor: extractText(label.labelFor)
  }))

  return labelsFormatted
}

const handleDate = (property: string, value: any) => {
  const isDate = property.toLowerCase().includes('date') && dayjs(value).isValid()

  if (isDate) {
    return timeAsDayjs(value).format('DD/MM/YYYY')
  }

  return undefined
}

const handleTranslate = (property: string, value: any) => {
  if (translate[property as ITranslateKeys]) {
    const valueTranslated = translate[property as ITranslateKeys](value as never)

    if (valueTranslated && valueTranslated !== 'n/a') {
      return valueTranslated
    }
  }

  return undefined
}

const handlePropertyValues = (property: string, value: any) => {
  const dateFormatted = handleDate(property, value)
  const valueTranslated = handleTranslate(property, value)

  if (dateFormatted) return dateFormatted
  if (valueTranslated) return valueTranslated

  return value
}

function mapChangesToObject (changes: ILogChanges, labels: FormatedFormItem[]): ILogChanges {
  const mappedChanges:ILogChanges = {}
  const allLabelsFormatted = formattedLabels(labels)

  for (const labelObj of allLabelsFormatted) {
    const property = labelObj.labelFor

    if (property && changes[property]) {
      const oldValue = handlePropertyValues(property, changes[property].old)
      const newValue = handlePropertyValues(property, changes[property].new)

      mappedChanges[labelObj.label] = { old: oldValue, new: newValue }
    }
  }
  return mappedChanges
}

function HistoryChangesOnForm ({ step, isProposalHistory }: { step: IStepHistory, isProposalHistory?: boolean }) {
  const [changeContent, setChangeContent] = useState<React.ReactNode>()
  const [isLoadingValues, setIsLoadingValues] = useState(false)
  const { id } = useParams<{ id?: string }>()

  const generateChangesContent = (propertyChanges?: { new: IStepHistoryLastNew, last: IStepHistoryLastNew }) => {
    const { last, new: newValues } = propertyChanges || {}

    const allDifferences = extractJsonDiff(jsonDiff.diff(last ?? {}, newValues ?? {}))
    const formItems = translateAntForm.translateFormItems() || []

    const mappedChanges = mapChangesToObject(allDifferences, formItems)
    const changesComponent = Object.entries(mappedChanges).map(([property, values]) => {
      return (
        <ChangeInfo
          key={property}
          property={property}
          lastValue={values.old}
          newValue={values.new}
        />
      )
    }).filter(Boolean)

    return changesComponent.length > 0 ? changesComponent : 'Alteração não encontrada...'
  }

  async function fetchChangesOnStepForm (historyId: string) {
    setIsLoadingValues(true)
    try {
      if (!historyId) throw new Error('Não foi possível obter o ID da mudança.')

      const response = await ProposalEgiRepository.findHistoryChangeForm(historyId)
      const changesContent = generateChangesContent(response?.data?.data?.propertyChanges)

      setChangeContent(changesContent ?? 'Nenhuma alteração encontrada!')
    } catch (error) {
      console.warn(error)
    } finally {
      setIsLoadingValues(false)
    }
  }

  if (step.type !== 'form') return <></>
  if (isProposalHistory) {
    return (
      <div className='review-step-history__proposal-history-change-container'>
        <p className='review-step-history__proposal-history-change-text'>
          Para visualizar todas as alterações no formulário, acesse a proposta e vá até o departamento e passo correspondente.
        </p>

        <Link to={`/auth/proposals/show/${id}`} className='review-step-history__proposal-history-change-link'>
          Clique aqui para acessar
        </Link>
      </div>
    )
  }

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      onChange={key => key.length > 0 && fetchChangesOnStepForm(step._id)}
      ghost
    >
      <Collapse.Panel header='Alterações' key='logs'>
        {isLoadingValues ? <Loading /> : changeContent}
      </Collapse.Panel>
    </Collapse>
  )
}

interface IReviewStepHistory {
  stepArray: Array<IStepHistory>,
  mode?: TimelineProps['mode'],
  isProposalHistory?: boolean
  stepSlug?: string
}

function ReviewStepHistory ({ stepArray, mode, isProposalHistory }: IReviewStepHistory) {
  const getTimeLineIcon = (newStatus: _proposalStatuses, type?: string) => {
    const statusName = newStatus ?? 'starting'
    let color = newStatus ? colors[newStatus] : colors.starting

    if (type === 'uploaddocument' || type === 'removeddocument' || type === 'form') color = colors.primary

    return <Icons name={statusName} color={color} size={20} />
  }

  return (
    <Timeline
      className="lead-history-item-tail"
      mode={mode}
      reverse={false}
    >
      {stepArray.map(step => (
        <Timeline.Item
          key={step._id}
          dot={getTimeLineIcon(step?.newStatus, step.type)}
        >

          {isProposalHistory && (
            <>
              {step.name && (
                <Col className="mb-1 mt-1">
                  <label className="font-500 uppercase color-primary">
                    {step.deptName ? `Departamento: ${format.capitalize(String(step.deptName))} - ` : ''} Passo: <b>{step.name}</b>
                  </label>
                </Col>
              )}

              {!step.name && (
                <Col className="mb-1 mt-1">
                  <strong className="bold uppercase color-primary">Aprovação de crédito</strong>
                </Col>
              )}
            </>
          )}

          <article className='review-step-history'>
            <section className='review-step-history__header'>
              <LastNewStatus step={step}/>
              <HistoryInfo step={step} />
            </section>

            <EmailSmsList step={step} />

            <ReviewCommentary step={step} />

            <ReviewMessage step={step}/>

            <ReviewRefuseMessage step={step}/>

            <ReviewShareStatus step={step}/>

            <GenericTagsCNDInsured step={step} />

            {(step.provider && step.provider.name) && (
              <Tag className='mt-2'>Empresa de avaliação: <strong>{step.provider.name}</strong></Tag>
            )}

            <HistoryChangesOnForm step={step} isProposalHistory={isProposalHistory} />
          </article>
        </Timeline.Item>
      ))}
    </Timeline>
  )
}

export default ReviewStepHistory

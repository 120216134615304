import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Select, Spin } from 'antd'
import { useDispatch } from 'react-redux'
import { useAuth, useResources } from 'hooks'
import { resourcesGetConsultants, resourcesGetSupport, resourcesGetTelemarketing } from 'store/modules/resources/actions'
import { tablesUpdate } from 'store/modules/tables/actions'
import swal from 'utils/swal'
import { proposals } from 'egi/consumers'
import LeadsRepository from 'egi/repositories/LeadsRepository'
import UserTableModel from 'egi/models/UserTableModels'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import ProposalEgiRepository from 'egi/repositories/ProposalEgiRepository'

interface IModalTelemarketingProps {
  level: string
  id?: string
  lead?: boolean
  user?: string
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  showUseSelf?: boolean
  isCommercialAttendant?: boolean
  reminded?: boolean
}

function ChangeUser ({ user, level, lead, id, show, setShow, showUseSelf, isCommercialAttendant, reminded }: IModalTelemarketingProps) {
  const dispatch = useDispatch()
  const resources = useResources()
  const auth = useAuth()

  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState<string>()
  const [errors, setErrors] = useState<any>({})
  const translateLevels = UserTableModel.changeUserTranslateLEvel(level)
  const [vinculateSelf, setVinculateSelf] = useState<boolean>(false)

  useEffect(() => {
    setVinculateSelf(auth._id === user)
    if (auth.level === 'master') setVinculateSelf(false)
  }, [auth, user])

  async function handleEditUsers () {
    if (!id) return
    if (!selected || (selected === user && !vinculateSelf)) {
      setErrors({ user: `Selecione um ${translateLevels} diferente.` })
      return
    }

    const userId = vinculateSelf ? auth._id : selected

    setLoading(true)
    try {
      if (level === 'telemarketing' && !lead) {
        const response = await proposals.changeTelemarketing({ proposalId: id, data: { telemarketingId: userId } })
        swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
      }

      if (level === 'telemarketing' && lead) {
        const response = await LeadsRepository.changeTelemarketing(id, { telemarketingId: userId })
        swal.basic({ title: 'Sucesso!', text: response.data?.message, icon: 'success' })
      }

      if (level === 'commercial' && !lead && !isCommercialAttendant) {
        const response = await proposals.changeSupport({ proposalId: id, data: { commercialId: userId } })
        swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
      }

      if (level === 'commercial' && lead) {
        const response = await LeadsRepository.changeCommercial(id, { commercialId: userId })
        swal.basic({ title: 'Sucesso!', text: response?.data?.message, icon: 'success' })
      }

      if (level === 'commercial' && isCommercialAttendant) {
        const response = await ProposalEgiRepository.changeCommercial(id, { commercialId: userId, reminded })
        swal.basic({ title: 'Sucesso!', text: response?.data?.message, icon: 'success' })
      }

      if (level === 'consultant') {
        const response = await proposals.changeConsultor({ proposalId: id, consultantId: userId })
        swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
      }

      setErrors({})
      setShow(false)
      dispatch(tablesUpdate())
    } catch (err) {
      setErrors({ user: err.message })
    }
    setLoading(false)
  }

  useLayoutEffect(() => {
    function getUsersFromLevel (userLevel: string) {
      if (userLevel === 'telemarketing' && resources.allTelemarketing.length < 1) {
        dispatch(resourcesGetTelemarketing())
      }

      if (userLevel === 'commercial' && resources.commercialSupport.length < 1) {
        dispatch(resourcesGetSupport())
      }

      if (userLevel === 'consultant' && resources.consultants.length < 1) {
        dispatch(resourcesGetConsultants())
      }
    }

    setErrors({})
    setSelected(user)
    if (show) getUsersFromLevel(level)
  }, [show])

  return (
    <Fragment>
      <Modal
        title={`Editar ${translateLevels}`}
        visible={show}
        onCancel={() => setShow(false)}
        footer={
          <Fragment>
            <Button type="ghost" onClick={() => setShow(false)}>Cancelar</Button>
            <Button type="primary" onClick={() => handleEditUsers()}>Ok</Button>
          </Fragment>
        }
      >
        <Spin spinning={loading}>
          <Row>

            <Col className="px-1" lg={24} sm={24} xs={24}>
              <Form layout="vertical">
                {showUseSelf && <Form.Item
                  help={errors.user && (errors.user)}
                  validateStatus={errors.user && ('error')}
                >
                  <Checkbox className="mr-2" checked={vinculateSelf} onChange={(value) => setVinculateSelf(value.target.checked)} />
                  <label>{`Vincular você mesmo ${lead ? 'ao lead' : 'à proposta'}`}</label>

                </Form.Item>
                }

                {!vinculateSelf && <Form.Item
                  label={<label>{translateLevels}</label>}
                  help={errors.user && (errors.user)}
                  validateStatus={errors.user && ('error')}
                >
                  <Select
                    disabled={vinculateSelf}
                    id="test-select-education"
                    showSearch
                    placeholder="Escolha o suporte comercial"
                    optionFilterProp="children"
                    onChange={(value) => { setSelected(value as string) }}
                    loading={loading}
                    value={selected}
                  >
                    {level === 'telemarketing' && (
                      <Fragment>
                        {resources.allTelemarketing.map(telemarketing =>
                          <Select.Option key={telemarketing._id} value={telemarketing._id}>{telemarketing.name}</Select.Option>
                        )}
                      </Fragment>
                    )}

                    {level === 'commercial' && (
                      <Fragment>
                        {resources.commercialSupport.map(commercial =>
                          <Select.Option key={commercial._id} value={commercial._id}>{commercial.name}</Select.Option>
                        )}
                      </Fragment>
                    )}

                    {level === 'consultant' && (
                      <Fragment>
                        {resources.consultants.map(consultant =>
                          <Select.Option key={consultant.value} value={consultant.value}>{consultant.label}</Select.Option>
                        )}
                      </Fragment>
                    )}
                  </Select>
                </Form.Item>}

              </Form>
            </Col>

          </Row>
        </Spin>
      </Modal>
    </Fragment>
  )
}

export default ChangeUser

import { Radio } from 'antd'
import { DrawerFiltersLineCollapsableItem } from 'components/DrawerFilters/DrawerFiltersLineCollapsable'
import React from 'react'

export default function SituationOrigemRadioECPF ({ ...rest }) {
  return (
    <Radio.Group {...rest}>
      <DrawerFiltersLineCollapsableItem key='all'>
        <Radio value=''>Todos</Radio>
      </DrawerFiltersLineCollapsableItem>

      <DrawerFiltersLineCollapsableItem key='inativo'>
        <Radio value='Inativo'>Inativo</Radio>
      </DrawerFiltersLineCollapsableItem>

      <DrawerFiltersLineCollapsableItem key='ativo'>
        <Radio value='Ativo'>Ativo</Radio>
      </DrawerFiltersLineCollapsableItem>
    </Radio.Group>
  )
}

import { IStep } from 'egi/types'

export enum StepActionsTypes {
  FILL_STEP = 'FILL_STEP',
  RESET_STEP = 'RESET_STEP',
  SET_CUSTOM_MODE_STEP = 'SET_CUSTOM_MODE_STEP',
  SET_STEP_FORM = 'SET_STEP_FORM',
  SET_OPEN_DRAWER_LOADING = 'SET_OPEN_DRAWER_LOADING',
  STEP_CHANGE_MODE_OPTIONS = 'STEP_CHANGE_MODE_OPTIONS',
  CHANGE_EXPIRATION_DATE = 'STEP_CHANGE_EXPIRATION_DATE',
  HISTORY_CALLBACK = 'STEP_HISTORY_CALLBACK'
}

export const stepFill = (data: IStep | {visible: boolean}) => {
  return ({
    type: StepActionsTypes.FILL_STEP,
    payload: {
      ...data
    }
  })
}

export const stepReset = () => ({
  type: StepActionsTypes.RESET_STEP
})

export const stepSetMode = ({ mode, name }: {mode: string, name: string}) => ({
  type: StepActionsTypes.SET_CUSTOM_MODE_STEP,
  payload: {
    mode,
    name
  }
})

export const stepChangeModeOptions = ({ selected }: { selected: string }) => ({
  type: StepActionsTypes.STEP_CHANGE_MODE_OPTIONS,
  payload: { selected }
})

export const stepForm = ({ data }: {data: any}) => ({
  type: StepActionsTypes.SET_STEP_FORM,
  payload: { form: data }
})

export const stepSetOpenDrawerLoading = ({ loading }: { loading: boolean }) => ({
  type: StepActionsTypes.SET_OPEN_DRAWER_LOADING,
  payload: {
    loading
  }
})

export const stepUpdateStepExpirationDate = (newDate: string) => ({
  type: StepActionsTypes.CHANGE_EXPIRATION_DATE,
  payload: {
    newDate
  }
})

export const stepSetFetchHistoryCallBack = (fetchHistoryCallback: () => void) => ({
  type: StepActionsTypes.HISTORY_CALLBACK,
  payload: {
    fetchHistoryCallback
  }
})

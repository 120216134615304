import { AxiosRequestConfig } from 'axios'
import { Repository } from 'ecp/repositories/Repository'
import { IDigitador } from 'egi/app/Signup/hooks/useTypist'
import IAuth from 'egi/types/IAuth'

import api from 'services/api'

export enum SigninMethodCheckTypistEnum {
  default = 'default',
  firstAccess = 'firstAccess'
}

export interface ICheckTypistResponse {
  signinMethod: SigninMethodCheckTypistEnum
  tokenId: string
  email: string
  cpf: string
}

export interface IECPFForgotPasswordBody {
  email: string
  token: string
  password: string
  confirmPassword: string
}

class SigninECPFRepository extends Repository<{}> {
  async typist (typistIdentifier: string, config?: AxiosRequestConfig) {
    return Repository.handle<{ digitador: IDigitador }>(() =>
      this.api.get(`${this.path}/typist/${typistIdentifier}`, config)
    )
  }

  async checkTypist (body: { identifier: string }, config?: AxiosRequestConfig) {
    return Repository.handle<ICheckTypistResponse>(() =>
      this.api.post(`${this.path}/check/typist`, body, config)
    )
  }

  async forgotPassword (body: IECPFForgotPasswordBody, config?: AxiosRequestConfig) {
    return Repository.handle<IAuth>(() =>
      this.api.put(`${this.path}/forgot-password`, body, config)
    )
  }
}

export default new SigninECPFRepository({ api, path: '/ecp-funcao/signin' })

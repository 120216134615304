import React, { useEffect, useMemo } from 'react'
import { Form, Radio } from 'antd'
import { useTables } from 'hooks'
import DrawerFilters from 'components/DrawerFilters/DrawerFilters'
import { useForm } from 'antd/lib/form/Form'
import { identifiers } from '../views/BanList/BanList'
import DrawerFiltersLineCollapsable, { DrawerFiltersLineCollapsableItem } from 'components/DrawerFilters/DrawerFiltersLineCollapsable'

function SuspectsFiltersDrawerFields ({ handleFilter }: {handleFilter: ((identifier:string) => void)}) {
  return (
    <>
      <div className='proposal-radio-filter'>
        <DrawerFiltersLineCollapsable label='Tipo pessoa'>
          <Form.Item
            name='id'
            initialValue={undefined}
          >
            <Radio.Group>
              <DrawerFiltersLineCollapsableItem key='suspects_filter_all'>
                <Radio value='' onChange={(value) => handleFilter(value.target.value)}>Todos</Radio>
              </DrawerFiltersLineCollapsableItem>
              {
                identifiers.map(identifier => {
                  return (
                    <DrawerFiltersLineCollapsableItem key={`suspects_filter_${identifier.value}`}>
                      <Radio value={identifier.value} onChange={(value) => handleFilter(value.target.value)}>{identifier.label}</Radio>
                    </DrawerFiltersLineCollapsableItem>
                  )
                })
              }

            </Radio.Group>
          </Form.Item>
        </DrawerFiltersLineCollapsable>
      </div>

    </>
  )
}

function SuspectsFilters ({ handleFilter, isFilterActive }: {handleFilter: ((identifier:string) => void), isFilterActive: boolean}) {
  const tables = useTables()
  const [form] = useForm()

  useEffect(() => {
    form.setFieldsValue(tables.filters)
  }, [])

  function clearFilters () {
    form.resetFields()
    handleFilter('')
  }

  const filterCounter = useMemo(() => {
    return Number(isFilterActive)
  }, [isFilterActive])

  return (

    <DrawerFilters
      onClear={clearFilters}
      counter={filterCounter}
    >
      <Form form={form}>
        <SuspectsFiltersDrawerFields
          handleFilter={handleFilter}/>
      </Form>
    </DrawerFilters>

  )
}

export default SuspectsFilters

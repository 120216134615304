import { AxiosRequestConfig } from 'axios'
import { IResponseBase, Repository } from 'ecp/repositories/Repository'

import api from 'services/api'

export type _possibleSortKeys = 'createdAt' | 'updatedAt' | 'proposalExpiration'
export type _possibleOrders = '1' | '-1'

export interface ICustomSort {
  key: _possibleSortKeys,
  value: _possibleOrders,
}

class CustomSortRepository extends Repository {
  async changeCustomSort (userId: string, body: ICustomSort, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.patch(`${this.path}/change/${userId}`, body, config)
    )
  }

  async addCustomSort (userId: string, body: ICustomSort, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.post(`${this.path}/${userId}`, body, config)
    )
  }

  async getCustomSort (userId: string, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/show/${userId}`, config)
    )
  }
}

export default new CustomSortRepository({ api, path: '/add-custom-sort' })

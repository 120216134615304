import {
  dashboard,
  proposalsListTwo,
  proposalsHistory,
  proposalCheck,
  leads,
  internalNotification,
  compliance,
  precificacaoTable,
  precificacaoCreate,
  precificacaoEdit,
  createAttorney,
  getOneAttorney,
  createWitness,
  getOneWitness,
  products,
  documentos,
  registro,
  comercialTeam,
  telemarketingTeam,
  attorneys,
  witnessess,
  reportProviders,
  reportCommission,
  fiscal,
  precificacao,
  pricingUsers,
  commissionsUsers,
  operationalLimit,
  juridicoTeam,
  analiseCreditoTeam,
  engenhariaTeam,
  leadsHistory,
  teamAdmin,
  population,
  divulgationPage,
  slaConfig,
  declines,
  simulationConfiguration,
  chat,
  auditors,
  agxReports,

  promoters,
  teamPromoterIn,
  promotersIn,
  allInvites,
  userDetails,
  listMaster,
  lgpdTerms,
  bacenTerms,
  proposalsListSearch,
  settingsMenu,
  proposalsList
} from '../routes'
import { BulbOutlined, FileSearchOutlined, HourglassOutlined, InteractionOutlined, ShopOutlined, ShoppingCartOutlined, SolutionOutlined, StarOutlined } from '@ant-design/icons'
import { notaryFees } from 'routes/departments/pricing'

const indications = {
  name: 'Gestor de links',
  icon: InteractionOutlined,
  routes: [
    promotersIn,
    divulgationPage
  ]
}

// AREAS
const comercial = {
  name: 'Comercial',
  icon: ShopOutlined,
  routes: [
    comercialTeam.leaders,
    commissionsUsers
  ]
}

const analiseCredito = {
  name: 'Análise de Crédito',
  icon: FileSearchOutlined,
  routes: [
    analiseCreditoTeam.leaders,
    operationalLimit
  ]
}

const juridico = {
  name: 'Jurídico',
  icon: HourglassOutlined,
  routes: [
    juridicoTeam.leaders,
    attorneys,
    witnessess,
    juridicoTeam.providers,
    juridicoTeam.promoters
  ]
}

const engenharia = {
  name: 'Engenharia',
  icon: BulbOutlined,
  routes: [
    engenhariaTeam.leaders,
    engenhariaTeam.providers
  ]
}

const telemarketing = {
  name: 'Telemarketing',
  icon: BulbOutlined,
  routes: [
    telemarketingTeam.leaders
  ]
}

const partnersSubMenu = {
  name: 'Parceiros',
  icon: StarOutlined,
  routes: [
    promoters,
    reportProviders,
    reportCommission,
    fiscal
  ]
}

const pricing = {
  name: 'Precificação',
  icon: ShoppingCartOutlined,
  routes: [
    precificacao,
    pricingUsers,
    notaryFees
  ]
}

const proposals = {
  name: 'Propostas',
  icon: SolutionOutlined,
  routes: [
    proposalsListSearch,
    proposalsListTwo
  ]
}

const agxRouter = [
  dashboard,
  proposals,
  proposalsList,
  proposalsListTwo,
  population,
  listMaster,
  leads,
  slaConfig,
  declines,
  simulationConfiguration,
  pricing,
  leadsHistory,
  teamAdmin,
  indications,
  teamPromoterIn,

  partnersSubMenu,
  juridico,
  engenharia,
  comercial,
  analiseCredito,
  telemarketing,
  auditors,

  allInvites,
  agxReports,
  internalNotification,
  chat,
  settingsMenu,
  compliance,
  proposalsHistory,
  proposalCheck,
  precificacaoTable,
  precificacaoCreate,
  precificacaoEdit,
  createAttorney,
  getOneAttorney,
  createWitness,
  getOneWitness,
  products,
  registro,
  documentos,
  userDetails,
  lgpdTerms,
  bacenTerms
]

export default agxRouter

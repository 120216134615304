import { IDynamicProposalPending } from './dynamicProposalPendingActionsTypes'
import { IDynamicProposalPendingState } from './dynamicProposalReducer'

export interface IAction<T, P> {
  type: T,
  payload: P
}

export enum DynamicProposalPendingTypes {
  SET_DETAILS = 'DYNAMIC_PROPOSAL_PENDING_SET_DETAILS',
  SET_LOADING= 'DYNAMIC_PROPOSAL_PENDING_SET_LOADING',
  SET_ERROR = 'DYNAMIC_PROPOSAL_PENDING_SET_ERROR',
  RESET = 'DYNAMIC_PROPOSAL_PENDING_RESET',
  OPEN_STEP_DRAWER_FN = 'DYNAMIC_PROPOSAL_PENDING_OPEN_STEP_DRAWER_FN',
  RESET_DETAILS = 'DYNAMIC_PROPOSAL_PENDING_RESET_DETAILS'
}

export const dynamicProposalPendingActions = {
  setDetails: (payload: IDynamicProposalPendingState['details']): IDynamicProposalPending.setDetails => ({
    type: DynamicProposalPendingTypes.SET_DETAILS,
    payload: payload
  }),

  setLoading: (isLoading: IDynamicProposalPendingState['loading']): IDynamicProposalPending.setLoading => ({
    type: DynamicProposalPendingTypes.SET_LOADING,
    payload: isLoading
  }),

  setError: (error: IDynamicProposalPendingState['error']): IDynamicProposalPending.setError => ({
    type: DynamicProposalPendingTypes.SET_ERROR,
    payload: error
  }),

  openStepDrawer: (open: IDynamicProposalPendingState['open']): IDynamicProposalPending.openDrawer => ({
    type: DynamicProposalPendingTypes.OPEN_STEP_DRAWER_FN,
    payload: open
  }),

  reset: (): IDynamicProposalPending.reset => ({
    type: DynamicProposalPendingTypes.RESET,
    payload: undefined
  }),

  resetDetails: (): IDynamicProposalPending.resetDetails => ({
    type: DynamicProposalPendingTypes.RESET_DETAILS,
    payload: undefined
  })

}

import { ColumnsType } from 'antd/lib/table'

const columns: ColumnsType<Object> = [
  {
    title: 'Nome',
    dataIndex: 'name'
  },
  {
    title: 'Taxa mensal',
    dataIndex: 'value'
  },
  {
    title: 'Commissão',
    dataIndex: 'commission'
  },
  {
    title: 'Consultor',
    dataIndex: 'consultor'
  },
  {
    title: 'Corban',
    dataIndex: 'corban'
  },
  {
    title: 'Indicador',
    dataIndex: 'indicador'
  },
  {
    title: 'Marktplace',
    dataIndex: 'marketPlace'
  }
]

export { columns }

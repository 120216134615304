
import { IDynamicProposal } from 'ecp/app/Proposals/proposalInterfaces'
import { DynamicProposalPendingTypes } from './dynamicProposalPendingActions'
import { IDynamicProposalPending } from './dynamicProposalPendingActionsTypes'

export type IDynamicProposalPendingState = {
  details: {
    lastStep: IDynamicProposal.Model['lastStep']
    histories: IDynamicProposal.Model['histories']
  } | undefined
  loading: boolean
  error: string
  open: ()=> void
}

const initialState: Partial<IDynamicProposalPendingState> = {
  details: undefined,
  loading: false,
  error: undefined,
  open: undefined
}

const dynamicProposalPendingReducer = (state = initialState, action: IDynamicProposalPending.allActions) => {
  switch (action.type) {
    case DynamicProposalPendingTypes.SET_DETAILS: {
      return {
        ...state,
        details: action.payload
      }
    }

    case DynamicProposalPendingTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      }
    }

    case DynamicProposalPendingTypes.SET_ERROR: {
      return {
        ...state,
        error: action.payload
      }
    }

    case DynamicProposalPendingTypes.OPEN_STEP_DRAWER_FN: {
      return {
        ...state,
        open: action.payload
      }
    }

    case DynamicProposalPendingTypes.RESET_DETAILS: {
      return {
        ...state,
        details: undefined
      }
    }

    case DynamicProposalPendingTypes.RESET:
      return {
        ...initialState
      }

    default:
      return state
  }
}

export default dynamicProposalPendingReducer

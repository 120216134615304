
import { Button, Form, message } from 'antd'
import TokenVerification from 'components/TokenVerification/TokenVerification'
import TokenHeader from 'ecp/components/TokenHeader/TokenHeader'
import React, { useState } from 'react'
import { IChangeProfileConfirm } from '../../profileInterfaces'
import EcpUsersRepository from 'ecp/repositories/EcpUsers'

import TokenResend from 'ecp/components/TokenResend/TokenResend'
interface IProps {
  email: string
  setStep: (step: 'changeEmail' | 'verifyEmail') => void
  setVisibleEmail: (visible: boolean) => void
  setNewEmail: (email: string) => void
}

export default function ChangeEmailToken ({ email, setStep, setVisibleEmail, setNewEmail }: IProps) {
  const [loading, setLoading] = useState<boolean>(false)
  const handleSubmit = async (values: IChangeProfileConfirm) => {
    setLoading(true)
    try {
      const data = {
        type: 'ativacao-email',
        confirmation_token: values.confirmation_token
      }
      await EcpUsersRepository.confirmToken(data)
      message.success('Email alterado com sucesso')
      setVisibleEmail(false)
      setStep('changeEmail')
      setNewEmail(email)
    } catch (err) {
      message.error(err.message)
    } finally {
      setLoading(false)
    }
  }

  async function resendToken () {
    try {
      const data = {
        email
      }
      await EcpUsersRepository.updateEmailOrPhone(data)
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <div>
      <h2 className="change-email__title">Token enviado</h2>
      <TokenHeader
        infoText={`Foi enviado o token para o email ${email}`}
      />

      <Form
        onFinish={handleSubmit}
        layout='horizontal'
        className='w-100 flex flex-column'

      >     <div className="change-email__token-content">
          <div className='find-user-token__bottom my-2'>
            <Form.Item
              name='confirmation_token'
              className='w-100 m-0'
            >
              <TokenVerification lenght={6} />
            </Form.Item>
          </div>
          <TokenResend
            onResend={() => resendToken()}
          />
        </div>
        <Button className="w-100 " type="primary" htmlType="submit" loading={loading}>Alterar</Button>
      </Form>

    </div>
  )
}

import { AxiosRequestConfig } from 'axios'
import { Repository } from 'ecp/repositories/Repository'
import { duePaymentStatusEnum } from 'ecpf/app/ProposalECPF/ProposalECPFInterfaces'

import api from 'services/api'

export interface IDueCPF {
  numero?: string
  controle?: string
  numeroParcela?: string
  dataVencimentoParcela?: Date
  valorPago?: number
  saldoAtual?: number
  dataLiquidacaoParcela?: Date
  situacaoParcela?: string
  situacaoParcelaDescr?: duePaymentStatusEnum
  valorDaParcela?: number
  valorFinalEncargos?: number
  codigoInstalacao?: string
}

export interface IDueListECPF {
  operacoes: Array<IDueCPF>
}

export interface IParcelDebitBalanceECPF {
  numero: number
  saldo: number
  saldoPrincipal: number
  saldoJuros: number
  saldoRAP: number
  saldoMora: number
  valorMulta: number
  saldoIOF: number
  saldoDespesa: number
  saldoTarifa: number
  saldoDevedor: number
  saldoLiquidacao: number
  dataVencimento: string
}

export interface IMergedDueAndDebitBalanceECPF extends Omit<IDueCPF, 'numero'>, Omit<IParcelDebitBalanceECPF, 'numero'> {
  numero?: string
  numeroSaldo?: number
}

export interface IDebitBalanceResponse {
  numeroOperacao: string
  saldoDevedor: number
  saldoLiquidacao: number
  parcelas: IParcelDebitBalanceECPF []
}

export interface IGetCOntractsECPFParams {
  codigoEmpregador: string
  codigoPromotor: string
  numeroMatricula: string
  documento: string
}

export interface IContractECPF {
  numeroContrato: string
  numeroMatricula: string
  codigoCliente: string
  quantidadeParcelas: number
  quantidadeParcelasVencidas: number
  quantidadeParcelasAbertas: number
  quantidadeParcelasVencer: number
  valorParcela: number
  saldoData: number
  codigoPromotor: string
  codigoEmpregador: string
  codigoOrgao: string
  contratoCedido: boolean
  codigoBancoCessao: string | null
  permiteRefinanciamentoRenegociacao: boolean
  observacao: string | null
  taxaAPAM: number
  taxaAPAA: number
}

export interface IGetContractsResponse {
  operacoes: Array<IContractECPF>
}

class OperationsECPFRepository extends Repository<{}> {
  async getDueList (config?: AxiosRequestConfig) {
    return Repository.handle<IDueListECPF>(() =>
      this.api.get(`${this.path}/parcelas`, config)
    )
  }

  async getDebitBalance (config?: AxiosRequestConfig) {
    return Repository.handle<IDebitBalanceResponse>(() =>
      this.api.get(`${this.path}/parcelas/saldos`, config)
    )
  }

  async getContracts (config?: AxiosRequestConfig) {
    return Repository.handle<IGetContractsResponse>(() =>
      this.api.get(`${this.path}/contratos-refinanciamento`, config)
    )
  }
}

export default new OperationsECPFRepository({ api, path: '/ecp-funcao/operacoes' })

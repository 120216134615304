import React, { useEffect, useState } from 'react'
import { Row, Col, Input, Button, Collapse } from 'antd'
import { spreadsheet } from '../../../egi/consumers'

import { useSelector } from 'react-redux'
import { RootState } from 'store/modules/rootReducer'
import { useStep } from 'hooks'
import swal from 'utils/swal'
import format from 'utils/format'

import InputMoneySpreed from 'components/inputMoney/inputMoneySpreeds'

interface IColumn {
  label: string
  value: number[]
}

const { Panel } = Collapse

function SpreadsheetExtrato ({ type }: { type: string }) {
  const client = useSelector((state: RootState) => state.client)
  const proposal = useSelector((state: RootState) => state.proposal)
  const step = useStep()

  const IS_PJ = type === 'pj'
  const [spreadsheetId, setSpreadsheetId] = useState<string>()
  const defaultLenght = new Array(50).fill('')
  const [column1, setColumn1] = useState<IColumn>({ label: 'fev-21', value: [...(defaultLenght)] })
  const [column2, setColumn2] = useState<IColumn>({ label: 'mar-21', value: [...(defaultLenght)] })
  const [column3, setColumn3] = useState<IColumn>({ label: 'abr-21', value: [...(defaultLenght)] })
  const [column4, setColumn4] = useState<IColumn>({ label: 'mai-21', value: [...(defaultLenght)] })
  const [column5, setColumn5] = useState<IColumn>({ label: 'jun-21', value: [...(defaultLenght)] })
  const [column6, setColumn6] = useState<IColumn>({ label: 'jul-21', value: [...(defaultLenght)] })

  const [loading, setLoading] = useState(false)
  const [month, setMonth] = useState<number>(6)

  const [partnerPercentage, setPartnerPercentage] = useState<number>(100)
  const [liquidPercentage, setLiquidPercentage] = useState<number>(10)

  const [open, setOpen] = useState(false)

  function getTotal (column: IColumn) {
    return column?.value.reduce(function (total, value) {
      if (value) { return total + value }
      return total
    }, 0)
  }

  async function sendSpreadsheet () {
    if (!proposal.id) { return }

    const data = {
      columns: [column1, column2, column3, column4, column5, column6],
      type: 'media',
      work: ''
    }

    setLoading(true)
    try {
      const response = await spreadsheet.save(proposal.id, data)
      if (response) {
        setSpreadsheetId(response.calculationId)
      }
      setLoading(false)
      swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
    } catch (err) {
      if (err) { swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' }) }
      console.log(err)
      setLoading(false)
    }
  }

  async function updateSpreadsheet () {
    if (!proposal.id) { return }
    if (!spreadsheetId) { return }
    const data = {
      columns: [column1, column2, column3, column4, column5, column6],
      type: 'media',
      work: ''
    }

    setLoading(true)
    try {
      const response = await spreadsheet.update(proposal.id, spreadsheetId, data)
      setLoading(false)
      swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
    } catch (err) {
      if (err) { swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' }) }
      console.log(err)
      setLoading(false)
    }
  }

  async function getSpreadsheet () {
    if (!proposal.id) { return }
    setLoading(true)
    try {
      const response = await spreadsheet.get(proposal.id, 'media')
      const columns = response.columns
      if (response) { setSpreadsheetId(response._id) }
      if (columns) {
        setColumn1(columns[0])
        setColumn2(columns[1])
        setColumn3(columns[2])
        setColumn4(columns[3])
        setColumn5(columns[4])
        setColumn6(columns[5])
      }

      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  function cleantable () {
    setColumn1({ label: 'fev-21', value: [...(defaultLenght)] })
    setColumn2({ label: 'mar-21', value: [...(defaultLenght)] })
    setColumn3({ label: 'abr-21', value: [...(defaultLenght)] })
    setColumn4({ label: 'abr-21', value: [...(defaultLenght)] })
    setColumn5({ label: 'abr-21', value: [...(defaultLenght)] })
    setColumn6({ label: 'abr-21', value: [...(defaultLenght)] })
  }

  useEffect(() => {
    cleantable()
    getSpreadsheet()
  }, [step.id])

  function getFinalTotal ():number {
    const columns = [column1, column2, column3, column4, column5, column6]
    const total = columns.reduce(function (total, column) {
      const aux = getTotal(column)
      const res = typeof aux === 'number' ? aux : 0
      return total + res
    }, 0)

    return Number((total / month).toFixed(2))
  }

  function getLiquid () {
    return Number((Number(getFinalTotal() || 0) * format.percentageToDecimal(liquidPercentage)).toFixed(2))
  }

  function getPartner () {
    return Number((Number(getLiquid() || 0) * format.percentageToDecimal(partnerPercentage)).toFixed(2))
  }

  return (
    <Collapse onChange={() => setOpen(prev => !prev) } className='mb-3'>
      <Panel
        header="Calculadora"
        key="1"
        extra={
          open && <Button type='primary' loading={loading} onClick={(e) => { !loading && (spreadsheetId ? updateSpreadsheet() : sendSpreadsheet()); e.stopPropagation() }}>Salvar</Button>
        }
      >
        <Row className='flex-1 spreadsheet ' style={{ paddingRight: '1em', paddingLeft: '1em' }}>
          <Col>
            <Row className='flex-1 ' style={{ marginBottom: '1em' }}>
              <Col span={24} className='cell-primary'>
                MÉDIA EXTRATO - {IS_PJ ? 'PESSOA JURÍDICA' : 'PESSOA FÍSICA'}
              </Col>
            </Row>
            <Row className='flex-1' style={{ marginBottom: '1em' }}>
              <Col span={24} className='cell-secundary'>
                NOME CLIENTE: {client?.name}
              </Col>
            </Row>
            <Row className='flex-1' justify="center" align='middle'>
              <Col span={24} style={{ paddingRight: 5 }}>
                <Row className='flex-1'>
                  <Col className="cell cell-secundary" span={4}>
                    < Input disabled={loading} value={column1?.label} onChange={(e) => { setColumn1({ label: e.target?.value, value: column1?.value }) }} />
                  </Col>

                  <Col className="cell cell-secundary" span={4}>
                    < Input disabled={loading} value={column2?.label} onChange={(e) => { setColumn2({ label: e.target?.value, value: column2?.value }) }} />
                  </Col>

                  <Col className="cell cell-secundary" span={4}>
                    < Input disabled={loading} value={column3?.label} onChange={(e) => { setColumn3({ label: e.target?.value, value: column3?.value }) }} />
                  </Col>

                  <Col className="cell cell-secundary" span={4}>
                    < Input disabled={loading} value={column4?.label} onChange={(e) => { setColumn4({ label: e.target?.value, value: column4?.value }) }} />
                  </Col>

                  <Col className="cell cell-secundary" span={4}>
                    < Input disabled={loading} value={column5?.label} onChange={(e) => { setColumn5({ label: e.target?.value, value: column5?.value }) }} />
                  </Col>

                  <Col className="cell cell-secundary" span={4}>
                    < Input disabled={loading} value={column6?.label} onChange={(e) => { setColumn6({ label: e.target?.value, value: column6?.value }) }} />
                  </Col>
                </Row>
              </Col>
              <Col span={24} className='spreadsheet-limit'>
                <Row className='flex-1'>
                  <Col span={4} >
                    {[...defaultLenght].map((cell, row) => (
                      <Col key={row} className="cell" span={24}>
                        <InputMoneySpreed disabled={loading} value={column1?.value[row] || undefined } onChange={(e) => { column1.value[row] = Number(format.currencyParser(e.target.value)); setColumn1({ label: column1?.label, value: column1?.value }) }} />
                      </Col>
                    ))}
                  </Col>

                  <Col span={4} >
                    {[...defaultLenght].map((cell, row) => (
                      <Col key={row} className="cell" span={24}>
                        <InputMoneySpreed disabled={loading} value={column2?.value[row] || undefined } onChange={(e) => { column2.value[row] = Number(format.currencyParser(e.target.value)); setColumn2({ label: column2?.label, value: column2?.value }) }} />
                      </Col>
                    ))}
                  </Col>

                  <Col span={4} >
                    {[...defaultLenght].map((cell, row) => (
                      <Col key={row} className="cell" span={24}>
                        <InputMoneySpreed disabled={loading} value={column3?.value[row] || undefined } onChange={(e) => { column3.value[row] = Number(format.currencyParser(e.target.value)); setColumn3({ label: column3?.label, value: column3?.value }) }} />
                      </Col>
                    ))}
                  </Col>

                  <Col span={4} >
                    {[...defaultLenght].map((cell, row) => (
                      <Col key={row} className="cell" span={24}>
                        <InputMoneySpreed disabled={loading} value={column4?.value[row] || undefined } onChange={(e) => { column4.value[row] = Number(format.currencyParser(e.target.value)); setColumn4({ label: column4?.label, value: column4?.value }) }} />
                      </Col>
                    ))}
                  </Col>

                  <Col span={4} >
                    {[...defaultLenght].map((cell, row) => (
                      <Col key={row} className="cell " span={24}>
                        <InputMoneySpreed disabled={loading} value={column5?.value[row] || undefined } onChange={(e) => { column5.value[row] = Number(format.currencyParser(e.target.value)); setColumn5({ label: column5?.label, value: column5?.value }) }} />
                      </Col>
                    ))}
                  </Col>

                  <Col span={4} >
                    {[...defaultLenght].map((cell, row) => (
                      <Col key={row} className="cell" span={24}>
                        <InputMoneySpreed disabled={loading} value={column6?.value[row] || undefined } onChange={(e) => { column6.value[row] = Number(format.currencyParser(e.target.value)); setColumn6({ label: column6?.label, value: column6?.value }) }} />
                      </Col>
                    ))}
                  </Col>
                </Row>
              </Col>
              <Col span={24} style={{ paddingRight: 5 }}>
                <Row className='flex-1'>
                  <Col className="cell cell-secundary" span={4}>
                    <Input disabled={loading} value={format.formatBRL(getTotal(column1)) || '-'} />
                  </Col>

                  <Col className="cell cell-secundary" span={4}>
                    <Input disabled={loading} value={format.formatBRL(getTotal(column2)) || '-'} />
                  </Col>

                  <Col className="cell cell-secundary" span={4}>
                    <Input disabled={loading} value={format.formatBRL(getTotal(column3)) || '-'} />
                  </Col>

                  <Col className="cell cell-secundary" span={4}>
                    <Input disabled={loading} value={format.formatBRL(getTotal(column4)) || '-'} />
                  </Col>

                  <Col className="cell cell-secundary" span={4}>
                    <Input disabled={loading} value={format.formatBRL(getTotal(column5)) || '-'} />
                  </Col>

                  <Col className="cell cell-secundary" span={4}>
                    <Input disabled={loading} value={format.formatBRL(getTotal(column6)) || '-'} />
                  </Col>

                </Row>
              </Col>
            </Row>
            <Row className='flex-1' justify="end" style={{ paddingTop: '2em' }}>
              <Col span={6}>
                <Col className="cell cell-secundary" span={24}>
                  <p >MÉDIA MENSAL</p>
                </Col>
                <Col className="cell" span={24}>
                  <Input disabled={loading} value={format.formatBRL(getFinalTotal()) || '-'} />
                </Col>
              </Col>
              <Col span={6}>
                <Col className="cell cell-secundary" span={24}>
                  <p >QTDE MESES</p>
                </Col>
                <Col className="cell " span={24}>
                  < Input disabled={loading} value={month} onChange={(e) => setMonth(Number(e.target?.value))} />
                </Col>
              </Col>
            </Row>
            {IS_PJ && <>
              <Row className='flex-1' justify="end" style={{ paddingTop: '2em' }}>
                <Col className="cell cell-secundary" span={6}>
                  <p >MARGEM LÍQUIDA</p>
                </Col>
                <Col className="cell cell-secundary" span={6}>
                  <Input disabled={loading} value={format.formatBRL(getLiquid()) || '-'} />
                </Col>
                <Col className="cell cell-secundary" span={6}>
                  < Input disabled={loading} value={`${liquidPercentage}%`} onChange={(e) => setLiquidPercentage(Number(format.onlyDigits(e.target?.value)))} />
                </Col>
              </Row>
              <Row className='flex-1' justify="end" >
                <Col className="cell cell-secundary" span={6}>
                  <p >PART. SÓCIO</p>
                </Col>
                <Col className="cell cell-secundary" span={6}>
                  <Input disabled={loading} value={format.formatBRL(getPartner()) || '-'} />
                </Col>
                <Col className="cell cell-secundary" span={6}>
                  < Input disabled={loading} value={`${partnerPercentage}%`} onChange={(e) => setPartnerPercentage(Number(format.onlyDigits(e.target?.value)))} />
                </Col>
              </Row>
              <Row className='flex-1' justify="end" >
                <Col className="cell cell-secundary" span={6}>
                  <p >RENDA APURADA </p>
                </Col>
                <Col className="cell cell-secundary" span={6}>
                  <Input disabled={loading} value={format.formatBRL(getPartner()) || '-'} />
                </Col>
                <Col span={6}>
                </Col>
              </Row>
            </>}
          </Col>
        </Row>
      </Panel>
    </Collapse>
  )
}

export default SpreadsheetExtrato

import React from 'react'
import { Checkbox, Form } from 'antd'

import DrawerFiltersLineCollapsable, { DrawerFiltersLineCollapsableItem } from 'components/DrawerFilters/DrawerFiltersLineCollapsable'
import { PROPOSAL_STATUS_RESOURCE } from '../../proposalEgiConstants'

function ProposalStatusFilter () {
  return (
    <Form.Item
      name='proposalStatus'
    >
      <Checkbox.Group>
        <DrawerFiltersLineCollapsable label='Status da proposta'>
          {PROPOSAL_STATUS_RESOURCE.map(({ value, label }) => (
            <DrawerFiltersLineCollapsableItem key={label}>
              <Checkbox
                value={value}
              >
                {label}
              </Checkbox>
            </DrawerFiltersLineCollapsableItem>
          ))}
        </DrawerFiltersLineCollapsable>
      </Checkbox.Group>
    </Form.Item>
  )
}

export { ProposalStatusFilter }

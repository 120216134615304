import React, { useEffect, useState } from 'react'
import { Empty, Form, Select } from 'antd'
import DrawerFiltersLineCollapsable, { DrawerFiltersLineCollapsableItem } from 'components/DrawerFilters/DrawerFiltersLineCollapsable'
import BackofficeRepository from 'egi/repositories/BackofficeRepository'
import useResources from 'hooks/useResources'
import { ISelect } from 'egi/types'
import { IBackoffice } from '../ShowAllVinculatedBackoffices/ShowAllVinculatedBackOffices'
import { useAuth } from 'hooks'
import ProposalEgiModel from 'egi/models/ProposalEgiModel'

interface IParams { areaName?: string | undefined}

function BackofficeFilter () {
  const user = useAuth()
  const fixedAreaName = ProposalEgiModel.canSendFixedAreaName(user)

  const [backoffices, setBackoffices] = useState<ISelect[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const { areasNames, getAreasName, loadingResources } = useResources()
  const [params, setParams] = useState<IParams>({ areaName: fixedAreaName ?? undefined })
  const [error, setError] = useState<string>()

  const getBackoffices = async (fetchParams: IParams) => {
    setLoading(true)
    setError(undefined)
    try {
      const response = await BackofficeRepository.fetchBackOffices({ params: fetchParams })
      const { data } = response.data

      const formattedBackOffices = data.backoffices.map((item: IBackoffice) => ({
        label: item.name,
        value: item._id
      }))

      setBackoffices(formattedBackOffices)
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getAreasName()
  }, [])

  useEffect(() => {
    getBackoffices(params)
  }, [params])

  const onChangeArea = (area: string) => {
    setParams({ areaName: area })
  }

  return (
    <DrawerFiltersLineCollapsable label='Backoffices Vinculados'>
      { !fixedAreaName && <DrawerFiltersLineCollapsableItem>
        <Form.Item initialValue={undefined}>
          <Select
            showSearch
            allowClear
            optionFilterProp="label"
            className="w-100"
            options={areasNames}
            placeholder='Selecione a área'
            loading={loadingResources.areaNames}
            onChange={(area: string) => onChangeArea(area)}
          />
        </Form.Item>
      </DrawerFiltersLineCollapsableItem>
      }
      <DrawerFiltersLineCollapsableItem>
        <Form.Item name='backofficesIds' initialValue={undefined}>
          <Select
            showSearch
            allowClear
            optionFilterProp="label"
            className="w-100"
            options={backoffices}
            placeholder='Selecione o backoffice'
            loading={loading}
            notFoundContent={
              <Empty description={ error ?? 'Sem dados encontrados, certifique-se de ter selecionado a área'} className="p-2"/>
            }
          />
        </Form.Item>
      </DrawerFiltersLineCollapsableItem>
    </DrawerFiltersLineCollapsable>
  )
}

export { BackofficeFilter }

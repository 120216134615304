import React, { Fragment, useState, useLayoutEffect, useEffect } from 'react'
import { ServerTable, Drawer } from 'components'
import filters from 'components/SeverTable/IndexFilters'
import ButtonModel from 'components/SeverTable/ButtonModel'
import { clientColumnsFunc } from './clientColumns'
import format from 'utils/format'
import { brDateReverse } from 'utils/date'
import { useForm } from 'antd/lib/form/Form'
import { useDispatch } from 'react-redux'
import { setClientId } from 'store/modules/client/actions'
import { useAuth, useSimulation } from 'hooks'
import { validateResponse } from 'utils/validate'
import swal from 'utils/swal'
import { tablesUpdate } from 'store/modules/tables/actions'
import { resetAllSimulation } from 'store/modules/simulation/actions'
import ISimulation from 'egi/types/ISimulation'
import { timeAsDayjs } from 'utils/time'
import { useHistory } from 'react-router-dom'
import ClientForm from 'egi/app/Client/views/ClientForm/ClientForm'
import Simulator from 'egi/app/Simulator/Simulator'
import { IShowCreateProposal } from '../../clientInterfaces'
import { IAddNewClientErros, IAddNewClientValues } from 'egi/app/Leads/leadInterfaces'
import SignupRepository from 'egi/repositories/SignupRepository'
import { LimiterTable } from 'components/LimiterTable/LimiterTable'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'
import { useSignupCheckVinculatedAccount } from 'egi/app/Signup/hooks/useSignup'

export const chooseCorrectInternalSignupMethodFn = (isVinculation: boolean) => {
  if (isVinculation) return SignupRepository.associateClientByCB.bind(SignupRepository)
  return SignupRepository.createClientByCB.bind(SignupRepository)
}

function ClientList ({ type } : {type: 'promoters' | 'commercials'}) {
  const [loading, setLoading] = useState<boolean>(false)
  const [columns, setColumns] = useState<Object[]>([])
  const [drawerMode, setDrawerMode] = useState<'form' | 'simulation' | 'onlySimulation'>('simulation')
  const [visible, setVisible] = useState<boolean>(false)
  const [errors, setErrors] = useState<IAddNewClientErros>()
  const [simulatorData, setSimulationData] = useState<ISimulation>()

  const [form] = useForm()
  const dispatch = useDispatch()
  const user = useAuth()
  const simulation = useSimulation()
  const history = useHistory()

  const { onCheckCpf, onCheckEmail, isVinculatedAccount, loading: vinculatedLoading } = useSignupCheckVinculatedAccount()

  const isAuth = true

  const showCreateProposal = (data: IShowCreateProposal) => {
    const { id, row } = data

    const finalBirthdate = brDateReverse(String(row.birthdate))
    const finalCellphone = format.onlyDigits(row.cellphone)
    const finalCpf = format.onlyDigits(String(row?.cpf)) || undefined

    const dataToSend = { ...row, clientId: id, birthdate: finalBirthdate, cellphone: finalCellphone, cpf: finalCpf }
    delete dataToSend._id

    setVisible(true)
    setDrawerMode('onlySimulation')
    setSimulationData(dataToSend)
    dispatch(setClientId(id))
  }

  const redirectToProposal = (proposalId: string) => {
    history.push(`/auth/proposals/show/${proposalId}`)
    dispatch(resetAllSimulation())
  }

  const closeDrawer = () => {
    if (form) form.resetFields()
    setSimulationData(undefined)
    setVisible(false)
    dispatch(resetAllSimulation())
  }

  const onSubmit = async (values: IAddNewClientValues, isVinculation: boolean) => {
    const formData = new FormData()

    if (values.cpf) values.cpf = format.onlyDigits(values.cpf)
    if (values.cellphone) values.cellphone = format.onlyDigits(values.cellphone)

    if (user.level === 'administrator') values.administratorId = user._id
    if (user.level === 'promoter') values.promoterId = user._id
    if (simulation && simulation.id) values.simulationId = simulation.id

    for (const item in values) {
      type _typeNewClient = 'cpf' | 'cellphone' | 'name' | 'birthdate' | 'administratorId' |'promoterId' | 'simulationId'
      formData.append(item as string, values[item as _typeNewClient])
    }

    setLoading(true)

    try {
      const requestConfig = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }

      const internalSignupMethod = chooseCorrectInternalSignupMethodFn(isVinculation)
      const response = await internalSignupMethod(formData, requestConfig)

      const { message } = response.data.data || {}

      swal.basic({ title: 'Sucesso!', text: message, icon: 'success' })
      setErrors(undefined)
      closeDrawer()
      setLoading(false)
      if (form) form.resetFields()
      dispatch(tablesUpdate())
    } catch (err) {
      if (err.data?.invalid) {
        let errors: IAddNewClientErros = {}
        errors = validateResponse(err.data?.invalid)
        setErrors(errors)
        setLoading(false)
        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
        return
      }

      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      setErrors({})
    }

    setLoading(false)
  }

  useEffect(() => {
    if (drawerMode === 'form' && form) {
      form.setFieldsValue({
        ...simulation.values,
        birthdate: timeAsDayjs(simulation.values?.birthdate) ?? undefined,
        cpf: simulation.values?.cpf
      })

      const { cpf, email } = simulation?.values || {}
      if (cpf) onCheckCpf(cpf)
      if (email) onCheckEmail(email)
    }
  }, [drawerMode])

  const onCpfBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    onCheckCpf(format.onlyDigits(event.target.value))
  }

  const onEmailBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    onCheckEmail(event.target.value)
  }

  function createClient () {
    setDrawerMode('simulation')
    setVisible(true)
  }

  useLayoutEffect(() => {
    setColumns(clientColumnsFunc(showCreateProposal))
  }, [])

  return (
    <Fragment>
      <Drawer
        title={drawerMode === 'form' ? 'Cadastrar usuário' : 'Completar simulação'}
        visible={visible}
        setVisible={setVisible}
        status={loading ? 'sending' : undefined}
        showSubmit={drawerMode === 'form'}
        onClose={closeDrawer}
        onSubmit={() => {
          if (form && !loading && !vinculatedLoading) {
            form.submit()
          }
        }}
      >
        <Fragment>
          {drawerMode === 'form' && (
            <ClientForm
              personType={simulation.values?.personType}
              form={form}
              onFinish={(values) => onSubmit(values, isVinculatedAccount)}
              errors={errors}
              onCpfBlur={onCpfBlur}
              onEmailBlur={onEmailBlur}
            />
          )}

          {(drawerMode === 'simulation' || drawerMode === 'onlySimulation') && (
            <Simulator
              isAuth={isAuth}
              callbackClient={() => { setDrawerMode('form') }}
              callbackFunction={redirectToProposal}
              simulatorData={simulatorData}
              step={0}
              internalSimulator={drawerMode === 'simulation' ? true : false}
              onlyCEP={drawerMode === 'simulation' ? false : true}
              hasTerms={false}
            />
          )}
        </Fragment>
      </Drawer>

      <LimiterTable>
        <ServerTable
          url={`/${type}/clients`}
          columns={columns}
          hasLeadStatus={false}
          fixedDate={false}
          primaryFilter={filters().search}
          secondaryFilter={filters().createdAt}
          buttons={[
            <ButtonModel
              onClick={() => createClient()}
              text='Adicionar novo cliente'
              loading={loading}
              key={0}
            />
          ]}
        />
      </LimiterTable>
    </Fragment>
  )
}

export default ClientList

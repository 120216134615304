import PartnersRepository from 'egi/repositories/PartnersRepository'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import swal from 'utils/swal'

type keysTranslate = Record<string, 'promoter-corban' | 'promoter-indicador' | 'promoter-marketplace'>
type _partnerType = 'CB' | 'IN' | 'MP'

function usePartnerDepartment () {
  const { partnerId } = useParams<{ partnerId: string | undefined }>()
  const [department, setDepartment] = useState<string>()
  const [partnerType, setPartnerType] = useState<_partnerType>()

  const slug: keysTranslate = {
    CB: 'promoter-corban',
    IN: 'promoter-indicador',
    MP: 'promoter-marketplace'
  }

  useEffect(() => {
    const getDepartment = async () => {
      try {
        if (!partnerId) throw new Error('Não foi possível obter o indentificador da proposta.')
        const response = await PartnersRepository.documentation(partnerId)

        const partnerType = response.data.data.partnerDocuments.partnerType
        const departments = response.data.data.partnerDocuments.departments

        const slugDepartment = slug[partnerType]
        const departmentSlug = departments?.[slugDepartment]?.slug
        setPartnerType(partnerType as _partnerType)
        setDepartment(departmentSlug)
      } catch (err: any) {
        swal.basic({ title: 'Aviso', text: err?.message ?? 'Não foi possível obter os dados necessários.' })
      }
    }

    getDepartment()
  }, [])

  return {
    department,
    partnerType
  }
}

export { usePartnerDepartment }

import { WarningOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Empty, Row } from 'antd'
import { AxiosRequestConfig } from 'axios'
import { DocumentUpload } from 'components'
import RichTextEditor from 'components/RichTextEditor/RichTextEditor'
import StringToHtml from 'components/StringToHtml/StringToHtml'
import { _proposalStatuses } from 'egi/app/ProposalEgi/proposalEgiInterfaces'
import { proposals } from 'egi/consumers'
import SimulationModel from 'egi/models/SimulationModel'
import { IDocuments } from 'egi/types'
import { useAuth } from 'hooks'
import React, { Fragment, useEffect, useState } from 'react'
import swal from 'utils/swal'
import { IStorageFileUpload } from './types'

export default function StorageFileUpload ({ exceptionStorage, proposal, tab, onChange, callbackFunction }: IStorageFileUpload) {
  const [documentLoading, setDocumentLoading] = useState<boolean>(false)
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
  const [rebuild, setRebuild] = useState<boolean>(false)
  const [path, setPath] = useState<Array<IDocuments>>([])
  const [richText, setRichText] = useState<string>()
  const documentsUrls = exceptionStorage?.documents.map((document) => document.url)
  const user = useAuth()
  const proposalId = proposal.id as string
  const proposalStatus = proposal.status

  const sendInformation = SimulationModel.canSendInformation(user, tab, proposalStatus as _proposalStatuses)

  const documentParameters = {
    size: '3MB',
    limit: 3
  }

  useEffect(() => {
    setRebuild(prev => !prev)
    if (exceptionStorage?.message) setRichText(exceptionStorage.message)
    return () => setRichText('')
  }, [tab, exceptionStorage])

  async function onFileUpload (request: any) {
    setDocumentLoading(true)
    const { file, onSuccess, onProgress, onError } = request
    if (!file) return

    const formData = new FormData()
    formData.append('file', file)
    formData.append('areaName', tab)

    const config: AxiosRequestConfig = {
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 90000,
      onUploadProgress: ({ total, loaded }) => {
        if (total) onProgress({ percent: Math.round((loaded / total) * 100) })
      }
    }

    try {
      const response = await proposals.sendStorageDocument({ proposalId, data: formData, config })
      onSuccess(response)
    } catch (err) {
      onError(err)
    } finally {
      setDocumentLoading(false)
      onChange()
    }
  }

  const onFileDelete = async (fileUrl: string | object, resolve: Function) => {
    setDocumentLoading(true)

    const data = {
      areaName: tab,
      url: fileUrl
    }
    const config: AxiosRequestConfig = { timeout: 90000 }

    try {
      const response = await proposals.deleteStorageDocument({ proposalId, data, config })
      resolve(true)
      swal.basic({ icon: 'success', title: 'Sucesso!', text: response.message })
    } catch (err) {
      console.error(err)
      swal.basic({ icon: 'warning', title: 'Atenção!', text: err.message })
    } finally {
      setDocumentLoading(false)
      onChange()
    }
  }

  async function sendComment () {
    setLoadingSubmit(true)
    const data = {
      message: richText,
      areaName: tab
    }

    try {
      const response = await proposals.sendStorageComment({ proposalId, data })
      callbackFunction()
      swal.basic({ icon: 'success', title: 'Sucesso!', text: response.message })
    } catch (err) {
      console.error(err)
      swal.basic({ icon: 'warning', title: 'Atenção!', text: err.message })
    } finally {
      setLoadingSubmit(false)
    }
  }

  return (
    <Fragment>
      <div className='my-4'>

        <div className="p-2 upload-button-size-explain display-block">
          <h3 className="color-primary mb-1"><WarningOutlined style={{ color: 'red' }} /> <b>ATENÇÃO:</b></h3>
          <h3 className="color-primary mb-0">- Limite de <b>{documentParameters.limit} {documentParameters.limit > 1 ? 'arquivos' : 'arquivo'}</b></h3>
          <h3 className="color-primary mb-0">- Tamanho máximo do arquivo: <b>{documentParameters.size}</b></h3>
          <h3 className="color-primary mb-0">- Arquivos com a extensão <b>.msg e .eml</b> não possuem preview na plataforma.</h3>
          <h3 className="color-primary mb-0">- Para visualizar o nome completo do arquivo basta passar o cursor sobre o mesmo.</h3>
        </div>
        <Divider />

        {!exceptionStorage?.documents.length && !sendInformation && <Empty description="Nenhum documento encontrado!" />}

        <DocumentUpload
          paths={path}
          getDocuments={() => setPath([{ name: '', path: documentsUrls ?? [], stepId: '', stepName: '' }])}
          loading={documentLoading}
          hideUploadButton={!sendInformation}
          maxDocs={documentParameters.limit}
          onFileUpload={onFileUpload}
          onFileDelete={onFileDelete}
          skeletonQuantity={3}
          rebuild={rebuild}
          isStep={false}
          otherFiles={['.msg', '.eml']}
        />

        <Divider />

        <div className='mt-5 mb-5'>
          <Row gutter={[10, 0]} className='storage-files__comment-placement'>
            <Col span={24}>
              <label className='w-100 font-size-15 bold'>Anotações Gerais</label>
              {sendInformation
                ? <RichTextEditor
                  setContents={richText}
                  onChange={(value: string) => setRichText(value)}
                />
                : richText
                  ? <p className='storage-files__comment'>
                    <StringToHtml value={richText ?? ''} />
                  </p>
                  : <p className='mt-2'>Nenhuma anotação encontrada!</p>
              }
            </Col>
          </Row>

          {sendInformation &&
            <Button
              className='mt-2'
              loading={loadingSubmit}
              type="primary"
              onClick={() => sendComment()}
            >
              Enviar
            </Button>
          }
        </div>
      </div>
    </Fragment>
  )
}

import React, { Fragment } from 'react'
import ExplainSend from 'components/ExplainSend/ExplainSend'
import ReviewStep from '../../../ReviewStep/ReviewStep'
import FormPartner from '../PartnerStep/FormPartner/FormPartner'
import PartnerFormContractFields from './PartnerFormContractFields'
import { FormContractProps } from './types'

function PartnerFormContract ({ formRef, errors, readOnly, onChange, onReviewSuccess }: FormContractProps) {
  return (
    <Fragment>
      <FormPartner formRef={formRef} errors={errors} readonly={readOnly} onChange={onChange} onSubmit={() => {}} />
      <PartnerFormContractFields errors={errors} formRef={formRef} readOnly={readOnly}/>
      <div className="my-4">
        <ExplainSend />
      </div>

      <ReviewStep onSuccess={onReviewSuccess} />
    </Fragment>
  )
}

export default PartnerFormContract


import { Form } from 'antd'
import React, { useEffect } from 'react'
import useInternalSettings from '../../hooks/internalSettingsHook/useInternalSettings'
import InternalReviewSelectFields from '../InternalReviewSelectFields/InternalReviewSelectField'
import { InternalReviewText } from '../InternalReviewTextarea/InternalReviewTextArea'

import SettingsCard from '../SettignsCard/SettingsCard'

export type _mode = 'edit' | 'add' |'display'

export default function InternalReviewForm () {
  const {
    onSelectDepartment,
    selectedStep,
    selectedDepartment,
    onSelectStep,
    onAdd,
    onEdit,
    getTextReview,
    onEditorChange,
    loadingTextArea,
    review,
    mode,
    changeMode,
    formRef,
    onDelete,
    getHistory,
    history,
    historyLoading
  } = useInternalSettings()

  useEffect(() => {
    getTextReview({
      departmentId: selectedDepartment?.value,
      stepSlug: selectedStep?.slug ?? ''
    })
  }, [selectedStep?._id])

  return (
    <Form
      form={formRef}
      onFinish={mode === 'edit' ? onEdit : onAdd}
      layout="vertical">
      <SettingsCard.title>
      Selecionar Passo
      </SettingsCard.title>

      <SettingsCard.description className="pb-1">
          Escolha o para qual&nbsp;
        <b>passo</b> deseja adicionar o texto padrão de&nbsp;
        <b>revisão interna</b>
      </SettingsCard.description>

      <InternalReviewSelectFields
        selectedDepartment={selectedDepartment}
        selectedStep={selectedStep}
        onSelectDepartment={onSelectDepartment}
        onSelectStep={onSelectStep}
      />

      <InternalReviewText
        loading={loadingTextArea}
        text={review?.text}
        history={history}
        review={review}
        step={selectedStep}
        department={selectedDepartment}
        changeMode={changeMode}
        mode={mode}
        onEditorChange={onEditorChange}
        onDelete={onDelete}
        getHistory={getHistory}
        historyLoading={historyLoading}
      />
    </Form>
  )
}

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import api from '../../services/api'
import { ConnectionFailed, ErrInternetDisconnected } from './errors'

const qualification = {
  minutaMold: async (query: { productId: string, type?: string }) => {
    try {
      const response: AxiosResponse = await api.get('/resources/minuta/mold', { params: query })
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  sendMinutaMold: async (data: {text: string, description: string, productId: string}) => {
    try {
      const response: AxiosResponse = await api.post('/resources/minuta/mold', data)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  changeMinutaMold: async (data: {text: string, slug: string, productId: string}) => {
    try {
      const response: AxiosResponse = await api.patch('/resources/minuta/mold', data)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  blockQualificacao: async (data: {slug: string, productId: string}) => {
    try {
      const response: AxiosResponse = await api.patch('/resources/minuta/mold/block', data)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  previewMinutaMold: async (productId: string, data: object) => {
    try {
      const response: AxiosResponse = await api.post(`/contract/molds/preview/${productId}`, data)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  minutaMoldVariables: async (params: AxiosRequestConfig) => {
    try {
      const response: AxiosResponse = await api.get('/resources/minuta/mold/variables', params)
      return response.data
    } catch (err) {
      if (axios.isCancel(err)) throw err
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  minutaMoldTypes: async (params?: AxiosRequestConfig) => {
    try {
      const response: AxiosResponse = await api.get('/resources/minuta/mold/types', params)
      return response.data
    } catch (err) {
      if (axios.isCancel(err)) throw err
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  }
}

export default qualification

import React, { Fragment, useState } from 'react'
import { Col, Divider, Row, Timeline } from 'antd'
import {
  DocumentUpload,
  FinalContract,
  FormUserPJData,
  ModalContract,
  RepresentativeSelection,
  ReviewBackoffice,
  SpreadsheetExtrato,
  SpreadsheetHolerite,
  SpreadsheetIRPF,
  SpreadsheetCR,
  FormAlienating,
  CrivoPdf,
  FormIncomePJ
} from 'components'
import { proposals } from 'egi/consumers'
import { useAuth, useProposal, useStep } from 'hooks'
import FormContract from 'components/FormContract/FormContract'
import FormDate from 'components/FormUserData/FormDate/FormDate'
import FormIncome from 'components/FormUserData/Income/FormIncome'
import ListAvaliations from 'egi/app/ProposalEgi/components/ListAvaliations/ListAvaliations'
import FormPropertyAddress from 'components/FormUserData/Property/FormPropertyAddress'
import FormBank from 'components/FormUserData/Bank/FormBank'
import ExplainSend from 'components/ExplainSend/ExplainSend'
import GoToReview from '../GoToReview/GoToReview'
import MultipleModes from '../MultipleModes/MultipleModes'
import { IDocuments } from 'egi/types'
import swal from 'utils/swal'
import { _formTypes } from 'globals'
import { AxiosRequestConfig } from 'axios'
import FormUserData from 'components/FormUserData/FormUserData'
import { IStepsRenders } from '../../proposalEgiInterfaces'
import ProposalEgiRepository from 'egi/repositories/ProposalEgiRepository'
import ProposalEgiModel from 'egi/models/ProposalEgiModel'

export const StepSectionTitle = ({ title } : {title: string}) => {
  return <h2 className="egi-step__section-title mb-0">{title}</h2>
}

function StepsRenders ({ callbackProposal, formRef, area, type, setStatus, dateFormRef, setFiles, setVisible, fetchProposals, openSimulationDrawer }: IStepsRenders) {
  const user = useAuth()
  const step = useStep()
  const proposal = useProposal()

  const [documentPath, setDocumentPath] = useState<IDocuments[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const IS_MASTER = user.level === 'master'
  const IS_BACKOFFICE = user.level === 'backoffice'
  const IS_AUDITOR = user.level === 'auditor'
  const IS_EXTERNAL = user.level === 'client' || IS_AUDITOR
  const IS_PROVIDER = user.level === 'provider'

  const formType: _formTypes = (step.modeOptions && step.modeOptions.formType) || ''
  const IS_TITULAR = step.ref === 'titular'

  const isMultipleAndLast = (mode: string) => (step.mode === 'multiple' && step.modeOptions && step.modeOptions.modes.length > 0 && step.modeOptions.modes[step.modeOptions.modes.length - 1] === mode) || step.activeMode
  const notMultiple = step.mode !== 'multiple'

  const canSeeUploadButton = step.canSeeUploadButton

  const baseProps = {
    callbackProposal,
    onLoading: (status: string) => setStatus(status),
    formRef
  }

  const props = {
    ...baseProps,
    onType: type,
    readOnlyInput: step.readOnlyInput,
    openSimulationDrawer
  }

  async function fetchPaths () {
    setLoading(true)
    try {
      if (!proposal.id) throw new Error('Falha ao encontrar id da proposta')
      if (!step.id) throw new Error('Falha ao encontrar id do passo')

      const response = await ProposalEgiRepository.paths({ proposalId: proposal.id, stepId: step.id })
      if (!response?.data?.data?.document) throw new Error('Falha ao encontrar documentos')

      const paths = response.data.data.document

      return paths
    } catch (err) {
      console.log(err)
    }
  }

  async function fetchCnds () {
    try {
      if (!proposal.id) throw new Error('Falha ao encontrar id da proposta')
      if (!step.id) throw new Error('Falha ao encontrar id do passo')

      const response = await proposals.cndUrl({ proposalId: proposal.id, stepId: step.id })
      return response.data.url
    } catch (err) {
      console.error(err)
    }
  }

  const getDocuments = async () => {
    setLoading(true)
    try {
      const paths = await fetchPaths()
      if (!step.id) throw new Error('Faha ao encontrar id do passo')

      const urls: string[] = []
      if (paths?.[0]?.path) urls.push(...paths[0].path)

      if (ProposalEgiModel.canFetchCndUrl(step.slug)) {
        const cndsPath: string = await fetchCnds()
        if (cndsPath) urls.push(cndsPath)
      }

      const finalObject = {
        path: urls,
        name: step.name,
        stepId: step.id,
        stepName: step.name,
        crivoPdf: step.crivoPdf || []
      }

      setDocumentPath([finalObject])
    } catch (err) {
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    }
    setLoading(false)
  }

  const onFileUpload = async (request: any) => {
    const { file, onSuccess, onProgress, onError } = request
    if (!file) return

    const formData = new FormData()
    formData.append('file', file)
    formData.append('stepId', step._id)

    const config: AxiosRequestConfig = {
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 90000,
      onUploadProgress: ({ total, loaded }) => {
        if (total) onProgress({ percent: Math.round((loaded / total) * 100) })
      }
    }

    const buttonDrawer = document.getElementById('drawer-submit-button') as HTMLButtonElement
    if (buttonDrawer) buttonDrawer.disabled = true

    try {
      if (!proposal.id) throw Error('Identificador da proposta não encontrado')
      if (!step.areaId) throw Error('Identificador do passo não encontrado')

      const response = await ProposalEgiRepository.uploadDocument({ proposalId: proposal.id, areaId: step.areaId, data: formData }, config)
      const responseFile = { ...response, data: { file } }

      if (step.fetchHistoryCallback) step.fetchHistoryCallback()
      onSuccess(responseFile)
    } catch (err) {
      onError(err)
      swal.basic({ icon: 'warning', title: 'Atenção', text: err.message })
    } finally {
      if (buttonDrawer) buttonDrawer.disabled = false
    }
  }

  const onFileDelete = async (fileUrl: string | object, resolve: Function) => {
    setLoading(true)

    const data = { stepId: step._id, path: fileUrl }
    const config: AxiosRequestConfig = { timeout: 90000 }

    try {
      if (!proposal.id) throw new Error('Identificador da proposta não encontrado')
      const response = await ProposalEgiRepository.deleteDocument({ proposalId: proposal.id, areaId: step.areaId, data }, config)
      resolve(true)

      if (step.fetchHistoryCallback) step.fetchHistoryCallback()
      swal.basic({ icon: 'success', title: 'Sucesso!', text: response.data.message })
    } catch (err) {
      console.error(err)
      swal.basic({ icon: 'warning', title: 'Atenção!', text: err.message })
    } finally {
      setLoading(false)
      getDocuments()
    }
  }

  function stepMode (mode: string) {
    switch (mode) {
      case 'form':
        return (
          <Fragment>
            {(function () {
              switch (formType) {
                case 'dados-bancarios': return <FormBank {...props} getDocuments={getDocuments} documentPath={documentPath}/>
                case 'garantidor-alienante': return <FormAlienating {...props} />
                case 'formulario-do-contrato': return <FormContract {...props} />
                case 'dados-do-imovel-em-garantia': return <FormPropertyAddress {...props} />
                case 'renda': {
                  if (step.personType && ProposalEgiModel.canSeePJForm(step.personType)) {
                    return <FormIncomePJ {...props} />
                  }
                  if (proposal.isPj) {
                    return IS_TITULAR ? <FormIncomePJ {...props} /> : <FormIncome {...props} />
                  }
                  return <FormIncome {...props} />
                }
                case 'cadastro':
                  if (step.personType && ProposalEgiModel.canSeePJForm(step.personType)) {
                    return <FormUserPJData {...props} />
                  }
                  if (proposal.isPj) {
                    return IS_TITULAR ? <FormUserPJData {...props} /> : <FormUserData {...props} />
                  }
                  return <FormUserData {...props} />
                default:
                  return null
              }
            })()}

            <div className="mb-4 mt-4">
              <ExplainSend />
            </div>

            {(isMultipleAndLast(mode) || notMultiple) && (
              <>
                <GoToReview />
                <ReviewBackoffice
                  finishReview={callbackProposal}
                  hide={IS_EXTERNAL}
                />
              </>
            )}
          </Fragment>
        )
      case 'date':
        return (
          <Fragment>
            <div className="mb-4">
              <ExplainSend />
            </div>

            <FormDate
              onLoading={(status: string) => setStatus(status)}
              callbackProposal={callbackProposal}
              dateFormRef={dateFormRef}
            />

            {(isMultipleAndLast(mode) || notMultiple) && (
              <>
                <GoToReview />
                <ReviewBackoffice
                  finishReview={callbackProposal}
                  hide={IS_EXTERNAL}
                />
              </>
            )}
          </Fragment>
        )
      case 'document':
        return (
          <Fragment>
            <ExplainSend
              isDocument={true}
              description={step.description}
              docSize={step.maxSizeDocument ?? proposal.maxSizeDocument}
            />

            <DocumentUpload
              onFileUpload={onFileUpload}
              onFileDelete={onFileDelete}
              skeletonQuantity={3}
              hideUploadButton={!canSeeUploadButton}
              paths={documentPath}
              loading={loading}
              getDocuments={getDocuments}
              maxDocs={proposal.maxDocs}
            />

            {(IS_BACKOFFICE || IS_MASTER) && <div style={{ marginTop: '2em' }}>
              {step.calculation?.map(type => {
                if (type?.startsWith('media')) {
                  return <SpreadsheetExtrato type={proposal.isPj ? 'pj' : 'pf'} />
                } else if (type?.startsWith('inss') || type?.startsWith('holerite')) {
                  return <SpreadsheetHolerite type={type} />
                } else if (type?.startsWith('irpf')) {
                  return <SpreadsheetIRPF type={type} />
                } else if (type?.startsWith('income')) {
                  return <SpreadsheetCR />
                } else {
                  return <></>
                }
              })}
            </div>}

            {(isMultipleAndLast(mode) || notMultiple) && (
              <>
                <GoToReview />
                <ReviewBackoffice
                  finishReview={callbackProposal}
                  canFetchProviders={!IS_EXTERNAL && !IS_PROVIDER}
                  hide={IS_EXTERNAL}
                />
              </>
            )}
          </Fragment>
        )
      case 'avaliation':
        return (
          <Fragment>
            <ListAvaliations
              areaId={area}
              fetchProposals={fetchProposals}
            />

            {step.slug === 'avaliacao-do-imovel' && (
              <Row gutter={15}>
                <Col lg={12}>
                  <div className="card-style" style={{ borderRadius: 5 }}>
                    <h2 className="title-home text-center" style={{ background: '#f8f9fa', borderRadius: 5 }}>Boleto</h2>
                    <Timeline className="pl-3 pr-3 pt-3">
                      <Timeline.Item color="gray">Emitido</Timeline.Item>
                      <Timeline.Item color="gray">Pago</Timeline.Item>
                      <Timeline.Item color="gray">Em aberto</Timeline.Item>
                    </Timeline>
                  </div>
                </Col>
              </Row>
            )}

            {(isMultipleAndLast(mode) || notMultiple) && (
              <>
                <GoToReview />
                <ReviewBackoffice
                  finishReview={callbackProposal}
                  hide={IS_EXTERNAL}
                  canFetchProviders={!IS_EXTERNAL && !IS_PROVIDER}
                />
              </>
            )}
          </Fragment>
        )
      case 'signature':
      case 'term':
        return (
          <Fragment>
            <ModalContract
              areaId={area}
              formRef={formRef}
              setStatus={setStatus}
              setVisible={setVisible}
              fetchProposals={fetchProposals}
            />

            {(isMultipleAndLast(mode) || notMultiple) && (
              <>
                <GoToReview />
                <ReviewBackoffice
                  finishReview={callbackProposal}
                  hide={IS_EXTERNAL}
                />
              </>
            )}
          </Fragment>
        )
      case 'append':
        return (
          <Fragment>
            <GoToReview />
            <ReviewBackoffice
              finishReview={callbackProposal}
              hide={IS_EXTERNAL}
            />
          </Fragment>
        )
      case 'minuta':
        return (
          <Fragment>
            <FinalContract
              areaId={area}
              formRef={formRef}
              setStatus={setStatus}
              setVisible={setVisible}
              fetchProposals={fetchProposals}
              hide={IS_EXTERNAL}
              readOnlyInput={step.readOnlyInput}
            />

            {(isMultipleAndLast(mode) || notMultiple) && (
              <>
                <GoToReview />
                <ReviewBackoffice
                  finishReview={callbackProposal}
                  hide={IS_EXTERNAL}
                />
              </>
            )}
          </Fragment>
        )
      case 'representative':
        return (
          <Fragment>
            <RepresentativeSelection
              formRef={formRef}
              finish={() => {
                fetchProposals()
                setVisible(false)
              }}
              onLoading={(status: string) => setStatus(status)}
              readOnlyInput={step.readOnlyInput}
            />

            {(isMultipleAndLast(mode) || notMultiple) && (
              <>
                <GoToReview />
                <ReviewBackoffice
                  finishReview={callbackProposal}
                  hide={IS_EXTERNAL}
                />
              </>
            )}
          </Fragment>
        )
      case 'crivo':
        return (
          <Fragment>
            <ExplainSend
              isDocument={true}
              description={step.description}
              docSize={step.maxSizeDocument ?? proposal.maxSizeDocument}
            />

            <DocumentUpload
              onFileUpload={onFileUpload}
              onFileDelete={onFileDelete}
              skeletonQuantity={3}
              onFilesChange={setFiles}
              paths={documentPath}
              getDocuments={getDocuments}
              loading={loading}
              maxDocs={proposal.maxDocs}
              hideUploadButton={!canSeeUploadButton}
            />

            <Divider />

            {(IS_BACKOFFICE || IS_MASTER) && <div style={{ marginTop: '2em', marginBottom: '1em' }}>
              {step.calculation?.map(type => {
                if (type?.startsWith('media')) {
                  return <SpreadsheetExtrato type={proposal.isPj ? 'pj' : 'pf'} />
                } else if (type?.startsWith('inss') || type?.startsWith('holerite')) {
                  return <SpreadsheetHolerite type={type} />
                } else if (type?.startsWith('irpf')) {
                  return <SpreadsheetIRPF type={type} />
                } else if (type?.startsWith('income')) {
                  return <SpreadsheetCR />
                } else {
                  return <></>
                }
              })}
            </div>}

            <CrivoPdf />

            {(isMultipleAndLast(mode) || notMultiple) && (
              <>
                <GoToReview />
                <ReviewBackoffice
                  finishReview={callbackProposal}
                  hide={IS_EXTERNAL}
                />
              </>
            )}
          </Fragment>
        )
      case 'multiple':
        if (step.modeOptions && step.modeOptions.modes && Array.isArray(step.modeOptions.modes)) {
          return <MultipleModes stepMode={stepMode} />
        }

        return <></>
      default:
        return <></>
    }
  }

  return stepMode(step.mode)
}

export default StepsRenders

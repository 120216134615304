import React, { Fragment } from 'react'
import { Button, Modal, Typography } from 'antd'
import { useModal } from 'hooks'
import { useDispatch } from 'react-redux'
import { modalReset } from 'store/modules/modal/actions'
import { SpreadsheetCR } from 'components'
import { ICreditReservation } from 'store/modules/modal/types'
import LinkedBackofices from './LinkedBackoffices'
import RequestCND from './RequestCND/RequestCND'
import CreditMainComponent from 'egi/app/CreditApprove/components/CreditMainComponent/CreditMainComponent'
import ChangeEmail from './ChangeEmailUsers'
import ResendLink from './ResendLink'
import PrognumDocs from './PrognumDocs'
import RichTextEditor from 'components/RichTextEditor/RichTextEditor'

function Reservations ({ creditReservation }: { creditReservation?: ICreditReservation }) {
  if (!creditReservation) return <></>
  const { name, commentary, areaName } = creditReservation
  return (
    <Fragment>
      <Typography.Title level={5}>{name}{areaName && ` - ${areaName}`}</Typography.Title>
      <RichTextEditor disable={true} setContents={commentary} />

      <div className="mt-3">
        <SpreadsheetCR canSave={false} />
      </div>
    </Fragment>
  )
}

function ReduxModal () {
  const modal = useModal()
  const dispatch = useDispatch()

  const modalClassName = `modal-config modal-config--w-${modal.width}` + ' ' + modal.className

  return (
    <Modal
      className={modalClassName}
      title={modal.title}
      visible={modal.visible}
      destroyOnClose={true}
      footer={modal.hasFooter ? (
        <Fragment>
          <Button type="ghost" onClick={() => dispatch(modalReset())}>Cancelar</Button>
          <Button type="primary" onClick={() => dispatch(modalReset())}>Ok</Button>
        </Fragment>
      ) : null}
      onCancel={() => dispatch(modalReset())}
      onOk={() => dispatch(modalReset())}
      centered
    >
      {modal.type === 'creditReservation' && (<Reservations creditReservation={modal.creditReservation} />)}
      {modal.type === 'linkedBackoffices' && (<LinkedBackofices proposalId={modal.proposalId} />)}
      {modal.type === 'creditApprove' && (<CreditMainComponent />)}
      {modal.type === 'changeEmail' && (<ChangeEmail />)}
      {modal.type === 'requestCnd' && (<RequestCND />)}
      {modal.type === 'resendLink' && (<ResendLink/>)}
      {modal.type === 'prognumDocs' && (<PrognumDocs/>)}
    </Modal>
  )
}

export default ReduxModal

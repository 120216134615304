import React, { Fragment, MutableRefObject, useRef, useState } from 'react'
import { EditOutlined, LoadingOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Col, Form, Row, Tooltip } from 'antd'
import { colors } from 'styles/colors'
import swal from 'utils/swal'
import { proposals } from 'egi/consumers'
import { validateResponse } from 'utils/validate'
import StringToHtml from 'components/StringToHtml/StringToHtml'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'
import RichTextEditor from 'components/RichTextEditor/RichTextEditor'

interface IMinutaQuadros {
  name: string,
  value: string,
  proposalId?: string,
  slug: string,
  editable: boolean
  addedClausula?: string[]
  onSuccess: () => void
  onVisible: (value: boolean) => void
}

function MinutaQuadros ({ name, value, slug, proposalId, addedClausula, editable, onSuccess, onVisible }: IMinutaQuadros) {
  const [visible, setVisible] = useState<'new' | 'edit' | undefined>(undefined)
  const [status, setStatus] = useState<'sending' | 'editing' | 'removing' | undefined>(undefined)
  const [errors, setErrors] = useState<any>({})

  const formRef: MutableRefObject<any> = useRef(null)
  const ref: MutableRefObject<any> = useRef({
    index: undefined,
    text: ''
  })

  async function insertClausula () {
    if (!proposalId) throw new Error('Falha ao encontrar id da proposta')
    setStatus('sending')
    try {
      const response = await proposals.addClausula({
        proposalId,
        data: {
          slug: slug,
          value: ref.current.text
        }
      })
      setVisible(undefined)
      setStatus(undefined)
      onSuccess()
      onVisible(false)
      ref.current.text = ''
      swal.basic({ title: 'Sucesso!', text: response.message, icon: 'success' })
    } catch (err) {
      if (err.data && err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))

        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
        setStatus(undefined)
        return
      }

      setStatus(undefined)
      swal.basic({ title: 'Atenção!', text: err.message, icon: 'error' })
    }
  }

  async function removeClausula (index: number) {
    if (!proposalId) throw new Error('Falha ao encontrar id da proposta')
    swal.confirmNegate({
      title: 'Atenção!',
      text: 'Você realmente deseja remover essa cláusula?',
      icon: 'question',
      confirm: async () => {
        setStatus('removing')
        try {
          const response = await proposals.removeClausula({
            proposalId,
            data: {
              slug: slug,
              index: index
            }
          })

          setVisible(undefined)
          setStatus(undefined)
          onSuccess()
          ref.current.text = ''
          swal.basic({ title: 'Sucesso!', text: response.message, icon: 'success' })
        } catch (err) {
          setVisible(undefined)
          setStatus(undefined)
          swal.basic({ title: 'Atenção!', text: err.message, icon: 'error' })
        }
      }
    })
  }

  async function sendEditedClausula () {
    if (!proposalId) throw new Error('Falha ao encontrar id da proposta')
    setStatus('editing')
    try {
      const response = await proposals.editClausula({
        proposalId,
        data: {
          slug: slug,
          value: ref.current.text,
          index: ref.current.index
        }
      })
      setVisible(undefined)
      setStatus(undefined)
      onSuccess()
      onVisible(false)
      ref.current.text = ''
      swal.basic({ title: 'Sucesso!', text: response.message, icon: 'success' })
    } catch (err) {
      setVisible(undefined)
      setStatus(undefined)
      swal.basic({ title: 'Atenção!', text: err.message, icon: 'error' })
    }
  }

  function editClausula (value: string, index: number) {
    setVisible('edit')
    ref.current.index = index
    ref.current.text = value
    setTimeout(() => {
      if (formRef && formRef.current) formRef.current.setFieldsValue({ clausulaText: value })
    }, 100)
  }

  const onCancel = () => {
    setVisible(undefined)
    onVisible(false)
    ref.current.text = ''
  }

  const onAdd = () => {
    setVisible('new')
    onVisible(true)
    ref.current.text = ''
  }

  return (
    <Fragment>
      <p style={{ fontWeight: 'bold', padding: 4, backgroundColor: '#DEDEDE' }}>
        {name}
        <Tooltip
          destroyTooltipOnHide={{ keepParent: false }}
          title="Adicionar cláusula"
        >
          {editable && !visible && (
            <PlusOutlined
              style={{ marginLeft: 5, color: colors.primary }}
              className="pointer form-contract-add"
              onClick={onAdd}
            />
          )}
        </Tooltip>
      </p>

      <StringToHtml value={value} />

      {Array.isArray(addedClausula) && addedClausula.length > 0 && (
        <Fragment>
          {addedClausula.map((value, idx) => (
            <div key={idx} className="form-contract-clausulas">
              <StringToHtml value={value} />

              {!visible && (
                <Fragment>
                  <Tooltip title="Remover cláusula" destroyTooltipOnHide={{ keepParent: false }}>
                    {status === 'removing' ? <LoadingOutlined style={{ marginLeft: 5 }} />
                      : <MinusCircleOutlined
                        className="pointer form-contract-add"
                        style={{ color: colors.reproved, marginLeft: 5 }}
                        onClick={() => removeClausula(idx)}
                      />
                    }
                  </Tooltip>

                  <Tooltip title="Editar cláusula" destroyTooltipOnHide={{ keepParent: false }}>
                    <EditOutlined
                      className="pointer form-contract-add"
                      style={{ color: colors.waiting, marginLeft: 5 }}
                      onClick={() => {
                        editClausula(value, idx)
                        onVisible(true)
                      }}
                    />
                  </Tooltip>
                </Fragment>
              )}
            </div>
          ))}
        </Fragment>
      )}

      {(visible === 'new' || visible === 'edit') && (
        <Fragment>
          <Form ref={formRef} >
            <Form.Item
              name="clausulaText"
              help={errors.value && (errors.value)}
              validateStatus={errors.value && ('error')}
            >
              <RichTextEditor
                setContents={ref?.current?.text || ''}
                onChange={(value: string) => { ref.current.text = value }}
              />
            </Form.Item>
          </Form>

          <Row justify="end">
            <Col className="flex my-4">
              <Button
                className="mr-2 text-center w-100 uppercase"
                type="ghost"
                onClick={onCancel}
              >
                Cancelar
              </Button>

              <Button
                loading={status === 'sending' || status === 'editing'}
                className="text-center w-100 color-white uppercase"
                type="primary"
                onClick={() => visible === 'edit' ? sendEditedClausula() : insertClausula()}
              >
                {visible === 'edit' ? 'Editar' : 'Inserir'}
              </Button>
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  )
}

export default MinutaQuadros

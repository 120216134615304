/* eslint-disable react/prop-types */
import React, { MutableRefObject } from 'react'
import CurrencyInput from 'react-currency-input-field'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  loading?: boolean,
  inputRef?: MutableRefObject<any>,
  rest?: any,
  decimalsLimit?: number,
  onValueChange?: any
}

function InputMoney ({ inputRef, className, decimalsLimit = 2, onChange, ...rest }: InputProps) {
  return (
    <CurrencyInput
      {...(rest as any)}
      ref={inputRef}
      prefix="R$ "
      onValueChange={onChange}
      className={className ? className : 'ant-input'}
      decimalsLimit={decimalsLimit}
      decimalSeparator=","
      groupSeparator="."
      onInput={(e) => {
        const value = (e.target as HTMLInputElement).value
        const numericalValue = Number(value.match(/[\d^,]/gi)?.join(''))
        const fractionalValue = value.match(/,\d+$/gi)?.join('') || ''
        if (numericalValue > 999999999 || fractionalValue.length > 5) {
          (e.target as HTMLInputElement).value = value.slice(0, -1)
        }
      }}
    />
  )
}

export default InputMoney

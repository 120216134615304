import React from 'react'

interface IProps {
  name?: string
  className?: string
  filled?: boolean
  size?: 'medium' | 'large' | 'extraLarge'
}

function LetterAvatar ({ name, className, filled = false, size = 'large' }: IProps) {
  const modifiers = {
    filled: filled ? 'letter-avatar--filled' : '',
    medium: size === 'medium' ? 'letter-avatar--medium' : '',
    extraLarge: size === 'extraLarge' ? 'letter-avatar--extra-large' : ''
  }

  const finalClass = `letter-avatar ${modifiers.filled} ${modifiers.medium} ${modifiers.extraLarge} ${className ?? ''}`

  return (
    <div className={finalClass}>
      {name?.charAt?.(0) || '?'}
    </div>
  )
}

export default LetterAvatar

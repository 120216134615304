import React, { ReactNode } from 'react'
import { Modal as AntdModal } from 'antd'
import { ModalProps } from 'antd/lib/modal'

interface IModal extends ModalProps {
  children: ReactNode
  size?: 'lg'
  className?: string
}

function Modal ({ children, size, className, ...rest }: IModal) {
  return (
    <AntdModal
      className={`responsive-modal ${size === 'lg' ? 'responsive-modal--lg' : ''} ${className || ''}`}
      centered
      destroyOnClose
      {...rest}
    >
      {children}
    </AntdModal>
  )
}

export default Modal

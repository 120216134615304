import React from 'react'

export default function SuspectSvg () {
  return (
    <svg width="191" height="191" viewBox="0 0 191 191" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="89.5" cy="67" rx="26.5" ry="28" fill="white"/>
      <ellipse cx="93.5" cy="77" rx="26.5" ry="28" fill="white"/>
      <ellipse cx="101.5" cy="67" rx="26.5" ry="28" fill="white"/>
      <path d="M78.5 52.5L66 53.5L65 73L73.5 94.5L74.5 107H87L78.5 52.5Z" fill="#F5F5F5"/>
      <path d="M115.114 50L127.045 50.9908L128 70.3119L119.886 91.6147L118.932 104H107L115.114 50Z" fill="#F5F5F5"/>
      <ellipse cx="81.5" cy="42" rx="26.5" ry="11" fill="white"/>
      <ellipse cx="107.5" cy="42" rx="26.5" ry="11" fill="white"/>
      <path d="M67.5 32.5L58.5 35.5L60 41.5L71 48.5L92.5 50L120 48.5L133 39L125.5 32.5L112 39H80L67.5 32.5Z" fill="#00CF7B" stroke="black"/>
      <path d="M154.119 114.926C153.565 114.218 150.483 110.632 144.312 108.277C138.918 106.608 125.605 102.195 118.249 96.9152C118.732 96.2786 119.204 95.6217 119.662 94.9402C125.698 85.9605 129.021 73.8788 129.021 60.919C129.021 58.7869 128.945 56.7857 128.808 54.8916C142.159 52.2206 150.789 47.985 150.789 43.2159C150.789 37.7544 139.473 32.9923 122.707 30.48C122.292 27.7719 122.008 24.7711 122.008 21.6081C122.008 9.67452 118.466 0 108.548 0C97.2638 0 99.5997 4.01074 95.5 4.5C91.4007 4.01074 93.7367 0 82.4529 0C72.5345 0 68.9921 9.67452 68.9921 21.6081C68.9921 24.7711 68.7088 27.7727 68.2938 30.48C51.5274 32.9923 40.2112 37.7544 40.2112 43.2159C40.2112 47.985 48.8408 52.2206 62.1926 54.8916C62.055 56.7857 61.9792 58.7869 61.9792 60.919C61.9792 73.8784 65.3028 85.9601 71.338 94.9402C71.796 95.6221 72.268 96.2786 72.751 96.9152C65.3954 102.195 52.0819 106.608 46.688 108.277C40.5168 110.631 37.4351 114.217 36.881 114.926C25.5324 131.774 24.2467 169.573 24.2366 169.949C24.3382 174.978 25.8321 176.697 26.348 176.909C51.3641 188.039 80.7647 190.944 95.5002 191C110.236 190.944 139.636 188.039 164.652 176.909C165.168 176.697 166.662 174.978 166.763 169.948C166.753 169.573 165.468 131.774 154.119 114.926ZM65.9343 33.9466C65.8624 34.201 65.8245 34.4593 65.8245 34.72C65.8245 39.6981 79.1106 43.7337 95.5002 43.7337C111.89 43.7337 125.176 39.6981 125.176 34.72C125.176 34.4589 125.138 34.201 125.066 33.9466C127.302 35.2991 128.561 36.8266 128.561 38.4445C128.561 43.99 113.759 48.4867 95.4999 48.4867C77.2403 48.4867 62.4384 43.99 62.4384 38.4445C62.4388 36.8266 63.6994 35.2991 65.9343 33.9466ZM66.5841 60.9194C66.5841 59.0979 66.6377 57.3633 66.7432 55.7114C75.1219 57.0639 84.9668 57.8431 95.5002 57.8431C106.034 57.8431 115.878 57.0639 124.257 55.7114C124.363 57.3637 124.416 59.0979 124.416 60.9194C124.416 85.5088 111.714 104.769 95.4999 104.769C79.2856 104.769 66.5841 85.5088 66.5841 60.9194Z" fill="#00441F"/>
      <path d="M93.6581 63.8174H97.342C97.8867 70.9538 103.686 76.572 110.764 76.572C117.843 76.572 123.643 70.9538 124.188 63.8174H124.192V63.7631C124.216 63.4176 124.233 63.071 124.233 62.7181H124.192V60.6767H66.7674V63.817H66.8131C67.3579 70.9534 73.157 76.5716 80.2356 76.5716C87.3142 76.5716 93.1137 70.9538 93.6581 63.8174Z" fill="#00441F"/>
    </svg>
  )
}

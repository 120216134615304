import React, { Fragment, useEffect, useMemo, useState } from 'react'
import Form from 'antd/lib/form'
import { useDispatch } from 'react-redux'
import { resourcesGetAreaNames } from 'store/modules/resources/actions'
import { useAuth, useResources } from 'hooks'
import { Select, Typography } from 'antd'
import { MultipleSelect } from 'components'
import format from 'utils/format'
import { ISelect } from 'egi/types'
import { swalError } from 'components/SwalError/SwalError'
import EcpResourcesRepository from 'egi/repositories/ResourcesRepository'
import { _userLevel } from 'ecp/models/UsersModel'
import InviteEgiRepository, { InviteEgiErrors } from 'egi/repositories/InviteEgiRepository'
import swal from 'utils/swal'
import { IInviteRequestBody } from 'components/ModalInvite/types'
import { areasBackofficeInvite } from './functions'
import SelectTypeInvite from 'components/ModalInvite/SelectTypeInvite'
import ModalShare from 'components/ModalShare/ModalShare'
import { isProduction, sendWhatsapp } from 'utils/globals'
import EmailRepository from 'egi/repositories/EmailRepository'
import { useParams } from 'react-router-dom'

interface IProps {
  level: _userLevel
  onSuccess?: (token: string) => void
  areaName?: string
}

function InviteTeamAsMaster ({ level, onSuccess, areaName = '' }: IProps) {
  const host = document.location.host
  const protocol = window.location.protocol
  const user = useAuth()
  const dispatch = useDispatch()
  const resources = useResources()
  const [form] = Form.useForm()
  const [inviteRef] = Form.useForm()
  const params: { id: string } = useParams()
  const [selectedAreaName, setSelectedAreaName] = useState(areaName)
  const [isLoadingFields, setIsLoadingFields] = useState(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [showAreaField, setShowAreaField] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [inviteLink, setInviteLink] = useState('')
  const [admins, setAdmins] = useState<Array<ISelect>>([])
  const finalAreas = useMemo(() => areasBackofficeInvite(user, resources, selectedAreaName), [user, resources.areas, selectedAreaName])
  const availableAreaNames = useMemo(() => {
    const areaNames: Array<ISelect> = []
    resources.areaNames.map(areaName => {
      if (!['cartorio'].includes(areaName)) return areaNames.push({ label: format.capitalize(areaName), value: areaName })
      return null
    })
    return areaNames
  }, [user, resources.areaNames])

  const generateLink = (token: string) => {
    let hostLink = ''
    if (isProduction) hostLink = `https://${host}/signup${token}`
    else hostLink = `${protocol}//${host}/signup${token}`
    setInviteLink(hostLink)
  }

  const generateLinkBackofficeInvite = async (values: IInviteRequestBody) => {
    const { areas, levelName, adminId, areaName } = values

    const data: IInviteRequestBody = {
      areas,
      level,
      levelName,
      adminId: adminId ?? params.id,
      areaName: areaName ?? selectedAreaName
    }

    setIsLoadingSubmit(true)

    try {
      const response = await InviteEgiRepository.administrator<IInviteRequestBody>(data)
      const { link } = response.data.data || {}
      if (!link) throw InviteEgiErrors.link()

      generateLink(link)
      setIsSuccess(true)
      if (onSuccess) onSuccess(link)
      setIsLoadingSubmit(false)
    } catch (err) {
      swal.basic({ title: 'Atenção!', text: err.message, icon: 'warning' })
      setIsLoadingSubmit(false)
    }
  }

  useEffect(() => {
    if (!level) level = 'backoffice'
    if (['comercial', 'telemarketing'].includes(areaName)) generateLinkBackofficeInvite({ level, areaName, adminId: params.id })
    dispatch(resourcesGetAreaNames())
  }, [])

  useEffect(() => {
    setIsLoadingFields(true)
    const controller = new AbortController()

    const fetchAdmins = async (areaName: string) => {
      try {
        const response = await EcpResourcesRepository.getAdministratorsByArea(areaName, { signal: controller.signal })
        const { administrators } = response.data.data || {}

        if (!administrators) throw new Error('Não foi possível obter os administradores da área selecionada.')

        setAdmins(administrators.map((admin: { name: string, _id: string }) => ({ label: admin.name, value: admin._id })))
        setIsLoadingFields(false)
      } catch (err) {
        if (!controller.signal.aborted) {
          return swalError({ err })
        }
      }
    }

    if (selectedAreaName) fetchAdmins(selectedAreaName)
  }, [selectedAreaName])

  const handleAreaNameSelect = (item: string) => {
    setSelectedAreaName(item)
    setShowAreaField(!['comercial', 'telemarketing'].includes(item))
  }

  const inviteEmail = async () => {
    const { email } = inviteRef.getFieldsValue()

    setIsLoadingSubmit(true)
    try {
      const response = await EmailRepository.invite({ email, link: inviteLink, level, inviteId: '' })
      swal.basic({ title: 'Sucesso!', text: response.data?.message, icon: 'success' })
    } catch (err) {
      swal.basic({ title: 'Atenção!', text: err.message, icon: 'warning' })
    } finally {
      setIsLoadingSubmit(true)
    }
  }

  if (isSuccess && !onSuccess) {
    return (
      <>
        <h3 className='text-center bold'>Link de convite:</h3>
        <Typography.Link style={{ wordBreak: 'break-all' }} copyable={true}>{inviteLink}</Typography.Link>
        <ModalShare
          form={inviteRef}
          invite={inviteLink}
          loading={{ list: false, invite: false, modalInvite: false, email: false, generateLink: false }}
          sendWhatsapp={() => {
            const whatsappMessage = `Olá%2C%20Você%20foi%20convidado%20por%20*${user.name}*%20para%20se%20tornar%20um%20usuário%20*${selectedAreaName}*%20na%20plataforma%20UXLine%2C%20clique%20no%20link%20abaixo%20para%20completar%20seu%20cadastro%3A%20${inviteLink}`
            sendWhatsapp(inviteRef.getFieldsValue().cellphone, whatsappMessage)
          }}
          inviteEmail={inviteEmail}
        />
      </>
    )
  }

  if (params.id) {
    return (
      <SelectTypeInvite
        level={level}
        onFinish={generateLinkBackofficeInvite}
        label='Selecione as areas do time convidado:'
        form={form}
        loading={isLoadingSubmit}
        layout='grid w-100'
        formContent={
          <>
            <Form.Item
              name='areas'
              label='Selecione os departamentos'
              rules={[{ required: true, message: 'Escolha os departamentos do time' }]}
            >
              <MultipleSelect
                className='w-100 mb-3'
                placeholder='Selecione as áreas'
                form={form}
                name='areas'
                baseArray={finalAreas}
                maxTagCount='responsive'
                disabled={!selectedAreaName}
                options={finalAreas.map(area => ({ label: format.capitalize(area.label), value: area.value }))}
              />
            </Form.Item>
          </>
        }
      />
    )
  }

  return (
    <>
      <SelectTypeInvite
        level={level}
        onFinish={generateLinkBackofficeInvite}
        label='Selecione as areas do time convidado:'
        form={form}
        loading={isLoadingSubmit}
        layout='grid w-100'
        formContent={
          <>
            <Form.Item
              name='areaName'
              label='Selecione a área'
              rules={[{ required: true, message: 'Escolha a área do time' }]}
            >
              <Select
                className='w-100 mb-3'
                placeholder='Selecione as áreas'
                maxTagCount='responsive'
                options={[
                  { value: '', label: 'Nenhum' },
                  ...availableAreaNames
                ]}
                onSelect={handleAreaNameSelect}
              />
            </Form.Item>
            {showAreaField && (
              <Form.Item
                name='areas'
                label='Selecione os departamentos'
                rules={[{ required: true, message: 'Escolha os departamentos do time' }]}
              >
                <MultipleSelect
                  className='w-100 mb-3'
                  placeholder='Selecione as áreas'
                  form={form}
                  name='areas'
                  baseArray={finalAreas}
                  maxTagCount='responsive'
                  disabled={!selectedAreaName}
                  options={finalAreas.map(area => ({ label: format.capitalize(area.label), value: area.value }))}
                />
              </Form.Item>
            )}
            <Form.Item
              name='adminId'
              label='Selecione o administrador do time'
              rules={[{ required: true, message: 'Escolha o administrador do time' }]}
            >
              <Select
                className='w-100 mb-3'
                placeholder='Selecione as áreas'
                maxTagCount='responsive'
                options={[
                  { value: '', label: 'Nenhum' },
                  ...admins
                ]}
                disabled={!selectedAreaName}
                loading={isLoadingFields}
              />
            </Form.Item>
          </>
        }
      />
    </>
  )
}

export default InviteTeamAsMaster

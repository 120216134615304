import swal from 'utils/swal'
import Swal from 'sweetalert2'
import format from 'utils/format'
import { colors } from 'styles/colors'
import translate from 'utils/translate'
import { useDispatch } from 'react-redux'
import CndReview from '../CndReview/CndReview'
import { validateResponse } from 'utils/validate'
import { WarningFilled } from '@ant-design/icons'
import { Input } from 'components'
import BackofficeModel from 'egi/models/BackofficeModel'
import { correctTimezone, timeAsDayjs } from 'utils/time'
import ProposalEgiModel from 'egi/models/ProposalEgiModel'
import { Button, Form, Row, Select, Checkbox } from 'antd'
import { proposalLoadingStep } from 'store/modules/proposal/actions'
import React, { useState, useEffect, useMemo } from 'react'
import { ALL_CND_STEPS, TIME_VALUES } from '../reviewBackofficeConstants'
import ProposalEgiRepository from 'egi/repositories/ProposalEgiRepository'
import PercentageInsured from 'components/FormContract/PercentageInsureds'
import ReviewRadioStatus from 'components/ReviewRadioStatus/ReviewRadioStatus'
import { useAuth, useClient, useProposal, useResources, useStep } from 'hooks'
import { IReview, ReviewForm, _reviewStatusOption } from '../reviewBackofficeInterfaces'
import { StepSectionTitle } from 'egi/app/ProposalEgi/components/StepRenders/StepRenders'
import translateAntForm from 'utils/translateAntForm'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import useInternalSettings from 'egi/app/Settings/hooks/internalSettingsHook/useInternalSettings'
import { LoadingTextArea } from 'egi/app/Settings/components/InternalReviewTextarea/InternalReviewTextArea'
import RichTextEditor from 'components/RichTextEditor/RichTextEditor'

function ExtraInputsWrapper ({ children, conditional }: { children: React.ReactNode, conditional?: boolean }) {
  if (!conditional) return <></>

  return <>{children}</>
}

interface IBackofficeReviewForm {
  form: ReviewForm,
  finishReview: Function
  resetFields: () => void,
  setForm: (value: ReviewForm) => void,
  setUpdateHistory: React.Dispatch<React.SetStateAction<boolean>>
  setModalShare: ({ show, link }:{show: boolean, link: string}) => void
}

export function ReviewBackofficeForm ({ form, setForm, resetFields, finishReview, setModalShare, setUpdateHistory }: IBackofficeReviewForm) {
  const [errors, setErrors] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [areaRef, setAreaRef] = useState<string>('')
  const [richText, setRichText] = useState<string>('')
  const [conditionalRichText, setConditionalRichText] = useState<string>('')

  const user = useAuth()
  const step = useStep()
  const client = useClient()
  const dispatch = useDispatch()
  const proposal = useProposal()
  const [formRef] = Form.useForm()
  const resources = useResources()

  const filterAreaNames = useMemo(() => (value: string) => ['juridico', 'comercial'].includes(value), [])
  const areaNamesFiltered = resources.areaNames?.length > 0 ? resources.areaNames.filter(filterAreaNames) : []

  const canShowInsured = step.showReservationProps && (BackofficeModel.isApproved(form.status) || BackofficeModel.isReservation(form.status))
  const canShowProviders = step.hasProvider && BackofficeModel.canSeeProviderSelect(step.hasProvider, user.areaName, user.level, form.status) ? true : false
  const canShowCnd = (step.slug && ALL_CND_STEPS.includes(step.slug)) && BackofficeModel.isApproved(form.status) ? true : false
  const canShowAreas = BackofficeModel.canInsertAreaReservation(step.slug, form.status)
  const canShowShareStatus = step.shareStatus && BackofficeModel.isRemaking(form.status)
  const canShowMessage = BackofficeModel.remakingAndClientAction(step.internal, form.status, areaRef)

  const handleReview = async (values: IReview) => {
    const formData = new FormData()
    formData.append('status', String(values.status))

    if (values.message) formData.append('message', values.message)
    if (values.areaName) formData.append('areaName', values.areaName)
    if (values.reprovalReason) formData.append('reprovalReason', values.reprovalReason)
    if (client.id) formData.append('clientId', client.id)
    if (step?.id) formData.append('stepId', String(step?.id))

    if (richText) formData.append('commentary', richText)
    if (conditionalRichText) formData.append('message', conditionalRichText)

    if (values.providerId) formData.append('providerId', values.providerId)
    if (values.selectedAreaId) formData.append('selectedAreaId', String(values.selectedAreaId))
    if (values.shareStatus) formData.append('shareStatus', String(values.shareStatus))
    if (values.percentageForFirstInsured) formData.append('percentageForFirstInsured', String(format.percentageToDecimal(values.percentageForFirstInsured)))
    if (values.percentageForSecondInsured) formData.append('percentageForSecondInsured', String(format.percentageToDecimal(values.percentageForSecondInsured)))
    if (!values.shareStatus) values.shareStatus = false

    if (step.showReservationProps && (BackofficeModel.isApproved(form.status) || BackofficeModel.isReservation(form.status))) {
      if (!values.percentageForFirstInsured) formData.append('percentageForFirstInsured', '0')
      if (!values.percentageForSecondInsured) formData.append('percentageForSecondInsured', '0')
    }

    TIME_VALUES.forEach(item => {
      if (values[item]) formData.append(item, timeAsDayjs(correctTimezone(values[item].toString())).format('YYYY-MM-DD'))
    })

    if (values.cdtfVerificationCodeForIssuer) formData.append('cdtfVerificationCodeForIssuer', values.cdtfVerificationCodeForIssuer)
    if (values.cndtmNumberForIssuer) formData.append('cndtmNumberForIssuer', values.cndtmNumberForIssuer)
    if (values.cndtNumberForIssuer) formData.append('cndtNumberForIssuer', values.cndtNumberForIssuer)
    if (values.cdjfNumberForIssuer) formData.append('cdjfNumberForIssuer', values.cdjfNumberForIssuer)
    if (values.cdjtNumberForIssuer) formData.append('cdjtNumberForIssuer', values.cdjtNumberForIssuer)
    if (values.cdcNumberForIssuer) formData.append('cdcNumberForIssuer', values.cdcNumberForIssuer)
    if (values.cdtiNumberForIssuer) formData.append('cdtiNumberForIssuer', values.cdtiNumberForIssuer)
    if (step.slug) formData.append('stepSlug', step.slug)
    setLoading(true)
    try {
      if (!proposal.id) throw new Error('Proposta não encontrada')
      const response = await ProposalEgiRepository.review(proposal.id, step.areaId, formData)

      if (step.id) dispatch(proposalLoadingStep({ stepId: step.id, loading: true }))

      setErrors({})
      resetFields()
      setAreaRef('')

      if (values.shareStatus) {
        setModalShare({ show: true, link: response.data.data.shareStatusLink })
        return
      }

      finishReview()
      swal.basic({ title: 'Sucesso', text: response.data.message, icon: 'success' })
    } catch (err) {
      if (err.data?.invalid) {
        let errors: any = {}

        errors = validateResponse(err.data?.invalid)
        setErrors(errors)
        setLoading(false)

        if (Array.isArray(err.data?.invalid) && step.slug === 'parecer-engenharia') {
          return Swal.fire({
            title: 'Atenção',
            icon: 'warning',
            html: `
              <b>Erros encontrados (${err.data?.invalid.length}):</b>
              <br/>
              ${err.data?.invalid.map((item: {field: string, message: string}, idx: number) => (
    `
                  <p class="mt-1 mb-0">Mensagem ${idx + 1}: ${item.message}</p>
                  ${err.data?.invalid.length > 1 ? '<hr/>' : ''}
                `
  ))}
            `
          })
        } else {
          const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
          invalidFieldsError({ invalidFields: invalidFields })
        }

        swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
        return
      }

      setErrors({})
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    }

    setLoading(false)
    setUpdateHistory(prev => !prev)
  }

  const onChangeRadio = (value: _reviewStatusOption) => {
    setForm({ ...form, status: value })
    formRef.setFieldsValue({ status: value })
  }

  const updateExpirationsDate = (expirationDate: string | undefined) => {
    formRef.setFieldsValue({
      dueDate: expirationDate,
      cdtfDueDateForIssuer: expirationDate,
      cndtDueDateForIssuer: expirationDate,
      cdtiDueDateForIssuer: expirationDate
    })
  }

  useEffect(() => {
    const autoFillCndFields = () => {
      const expirationDate = ProposalEgiModel.getCurrentCndExpirationDate(step.cnd) || undefined
      updateExpirationsDate(expirationDate)
    }

    autoFillCndFields()
  }, [step._id])

  const { getTextReview, reviewText, loadingTextArea } = useInternalSettings()

  useEffect(() => {
    getTextReview({
      departmentId: step?.areaId,
      stepSlug: step?.slug ?? ''
    })
  }, [step?._id, step?.slug])

  useEffect(() => {
    if (reviewText) setRichText(reviewText)
  }, [reviewText])

  return (
    <section className='mb-4'>
      <header className='mb-3'>
        <StepSectionTitle title="Adicionar nova revisão"/>
      </header>

      <Form
        onFinish={handleReview}
        layout="vertical"
        form={formRef}
      >
        <Form.Item
          name="status"
          help={errors.status && errors.status}
          validateStatus={errors.status && 'error'}
          rules={[{ required: true, message: 'Escolha o status.' }]}
          label='Atualize o status do passo'
          className='review-form__form-item mb-3'
        >
          <ReviewRadioStatus onChange={onChangeRadio}/>
        </Form.Item>

        <ExtraInputsWrapper conditional={BackofficeModel.isReproved(form.status)}>
          <Form.Item
            name="reprovalReason"
            help={errors.declines && errors.declines}
            validateStatus={errors.declines && 'error'}
            rules={[{ required: true, message: 'Escolha um motivo.' }]}
            label='Motivo de recusa'
          >
            <Select placeholder="Selecione o motivo de recusa" showArrow>
              {resources.declines.map((values: string) => (
                <Select.Option value={values} key={values}>
                  {values}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </ExtraInputsWrapper>

        <ExtraInputsWrapper conditional={canShowCnd}>
          <CndReview
            errors={errors}
            updateExpirationsDate={updateExpirationsDate}
          />
        </ExtraInputsWrapper>

        <ExtraInputsWrapper conditional={canShowShareStatus}>
          <Form.Item
            name='shareStatus'
            valuePropName="checked"
            help={errors.shareStatus && (errors.shareStatus)}
            validateStatus={errors.shareStatus && ('error')}
          >
            <Checkbox>Deseja compartilhar a interação com o passo?</Checkbox>
          </Form.Item>
        </ExtraInputsWrapper>

        <ExtraInputsWrapper conditional={canShowAreas}>
          <Form.Item
            name="areaName"
            label='Area vinculada:'
          >
            <Select
              placeholder='Escolha a área para mudar o status'
              onChange={(item: string) => setAreaRef(item)}
              showArrow
            >
              {areaNamesFiltered?.map((provider, index) => (
                <Select.Option
                  id={provider}
                  key={index}
                  value={provider}
                >
                  {translate.areasNames(provider)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </ExtraInputsWrapper>

        <ExtraInputsWrapper conditional={canShowProviders}>
          <Form.Item
            name="providerId"
            label={translate.stepProviderTexts(step.hasProvider)}
          >
            <Select placeholder={translate.stepProviderTexts(step.hasProvider)} showArrow>
              {resources.providers.map((provider: { label: string, value: string }) => (
                <Select.Option
                  id={`providerId-${(provider.label).replaceAll(' ', '-')}`}
                  key={provider.value}
                  value={provider.value}
                >
                  {provider.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </ExtraInputsWrapper>

        <ExtraInputsWrapper conditional={canShowMessage}>
          <Form.Item
            name="message"
            help={errors.message && errors.message}
            validateStatus={errors.status && 'error'}
            label={<label>Instruções para o reenvio do cliente: <label className="bold">{client.name}</label></label>}
          >
            <Input type="text" placeholder="mensagem" />
          </Form.Item>
        </ExtraInputsWrapper>

        <ExtraInputsWrapper conditional={canShowInsured}>
          <Row gutter={[30, 15]}>
            <PercentageInsured
              numberInsured='FirstInsured'
              errors={errors}
              readOnlyInput={false}
            />

            <PercentageInsured
              numberInsured='SecondInsured'
              errors={errors}
              readOnlyInput={false}
            />
          </Row>
        </ExtraInputsWrapper>

        {loadingTextArea ? <LoadingTextArea/>
          : <article className="review__rich pb-2">
            <RichTextEditor
              disable={loading}
              setContents={richText}
              onChange={(value: string) => setRichText(value)}
            />
          </article>
        }

        <ExtraInputsWrapper conditional={BackofficeModel.canReturnContionalClient(step.slug, form.status)}>
          <Form.Item
            help={errors.message && errors.message}
            validateStatus={errors.message && 'error'}
            className='review__rich'
            name='contionalRichText'
            label={<>
              <WarningFilled className="simulation-info mr-1 slide-in-fwd-center" style={{ color: colors.remaking }} />
              <label className="bold" style={{ color: colors.reproved }}>CONDICIONAIS PARA O CLIENTE</label>
            </>}
          >
            <RichTextEditor
              disable={loading}
              setContents={conditionalRichText}
              onChange={(value: string) => setConditionalRichText(value)}
            />
          </Form.Item>
        </ExtraInputsWrapper>

        <article className="text-right">
          <Button
            type="primary"
            loading={loading}
            htmlType='submit'
          >
            Enviar Revisão
          </Button>
        </article>
      </Form>
    </ section>
  )
}

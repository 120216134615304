import { IProgressQueueInfo, IProgressQueueListType } from 'ecp/components/ProgressQueue/progressQueueInterfaces'
import { IProgressQueueActions, IStatusProgressQueue } from 'store/modules/progressQueue/progressQueueReducer'

interface IactiveProgressQueueTextProps {
  selectedRowsLenght: number,
  type: IProgressQueueListType,
  action: IProgressQueueActions
  hasErrors?: boolean
}

interface IProgressQueueTextProps extends IactiveProgressQueueTextProps {
  status?: IStatusProgressQueue
}

const ProgressQueueModels = {
  clientLGPDProgressText: (action: IProgressQueueActions, selectedRowsLenght: number) => {
    switch (action) {
      case 'approve':
        return `Aprovando ${selectedRowsLenght} Cliente${selectedRowsLenght > 1 ? 's' : ''}`
      case 'reprove':
        return `Removendo ${selectedRowsLenght} Cliente${selectedRowsLenght > 1 ? 's' : ''}`
      default:
        return 'Em progresso'
    }
  },

  activeProgressQueueText: function ({ selectedRowsLenght, type, action }: IactiveProgressQueueTextProps) {
    switch (type) {
      case 'pendingUser':
        return this.clientLGPDProgressText(action, selectedRowsLenght)
      case 'proposal':
        return `Averbando ${selectedRowsLenght} Proposta${selectedRowsLenght > 1 ? 's' : ''}`
      case 'report':
        return 'Gerando relatório...'
      default:
        return 'Em progresso'
    }
  },

  clientLGPDSuccessText: (action: IProgressQueueActions, hasError?: boolean) => {
    if (hasError) return 'Operação finalizada'
    switch (action) {
      case 'approve':
        return 'Aprovação concluída'
      case 'reprove':
        return 'Repovação concluída'
      default:
        return 'Concluído'
    }
  },

  successProgressQueueText: function ({ type, action, hasErrors }: IactiveProgressQueueTextProps) {
    switch (type) {
      case 'pendingUser':
        return this.clientLGPDSuccessText(action, hasErrors)
      case 'proposal':
        return 'Averbação'
      case 'report':
        return 'Geração de relatório concluída'
      default:
        return 'Concluído'
    }
  },

  progressQueueText: function ({ selectedRowsLenght, status, type, action, hasErrors } : IProgressQueueTextProps) {
    switch (status) {
      case 'active' :
        return this.activeProgressQueueText({ selectedRowsLenght, type, action })
      case 'success' :
        return this.successProgressQueueText({ selectedRowsLenght, type, action, hasErrors })
      default:
        return ''
    }
  },

  hasPendingUserWarnings: function (status?: IStatusProgressQueue, type?: IProgressQueueListType, hasErrors?: boolean) {
    return status === 'success' && hasErrors && type === 'pendingUser'
  },

  hasErrorsOnOperations: function (progressList: Array<IProgressQueueInfo> = []) {
    let hasErrors = false
    progressList.map(item => {
      if (!item?.succesMessage && !!item?.errorMessage) hasErrors = true

      return hasErrors
    })

    return hasErrors
  }
}

export default ProgressQueueModels

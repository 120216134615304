import { Image, Modal, Row, Spin } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import ornament from 'assets/icons/ornament.svg'
import { ModalProps } from 'antd/lib/modal'
import { Link } from 'react-router-dom'
import swal from 'utils/swal'
import EmailRepository from 'egi/repositories/EmailRepository'

const counterTime = 5

type Props = {
  visible: boolean
  email: string
} & ModalProps
export const ConfirmationEmailModal = ({ visible, email, ...rest } :Props) => {
  const [counter, setCounter] = useState(0)
  const [startCounter, setStartCounter] = useState(false)
  const [loading, setLoading] = useState(false)

  const isFirstRender = useRef(true)

  const resetCounter = () => {
    setCounter(counterTime)
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    const interval = setInterval(() => {
      setCounter(count => count - 1)
    }, 1000)

    return () => clearTimeout(interval)
  }, [startCounter])

  const onResend = async () => {
    setLoading(true)
    try {
      const response = await EmailRepository.verification({ userEmail: email })
      resetCounter()
      setStartCounter(prev => !prev)
      swal.basic({
        title: 'Sucesso',
        text: response.data?.message,
        icon: 'success'
      })
    } catch (err) {
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      visible={visible}
      maskClosable={false}
      centered={true}
      footer={null}
      closable={true}
      destroyOnClose={true}
      className="confirmation-email-modal"
      {...rest}
    >
      <Row justify="center" align="middle" className="flex-column">
        <div className='confirmation-email-modal__title-container'>
          <h1 className="confirmation-email-modal__title">Confirmação de email</h1>
          <Image className='confirmation-email-modal' src={ornament} preview={false} />
        </div>

        <div className='confirmation-email-modal__content-container'>
          <p className="color-primary bold mb-2">
            Seu cadastro foi realizado com sucesso! Confirme seu email para validar sua conta.
          </p>

          <p className="color-primary font-500">
            Enviamos um email de confirmação para &nbsp;
            <strong>{email}</strong>
          </p>
        </div>

        <p className="color-primary mb-1">
          <b>Não recebeu o email?&nbsp;</b>
          <label
            className={`link-style color-primary bold ${counter > 0 && 'text-disabled'}`}
            onClick={() => {
              if (counter <= 0) onResend()
            }}
          >
            Reenviar email {loading && (<Spin className="pl-1" size='small' />)}
            {counter > 0 && <>{counter}s</>}
          </label>
        </p>

        <p className="confirmation-email-modal__enter-link">
          Já tem um cadastro? <Link to='/' className="underline bold">Entrar</Link>
        </p>
      </Row>
    </Modal>
  )
}

import React, { useLayoutEffect, useState } from 'react'
import { Form, Select } from 'antd'
import { useResources } from 'hooks'
import translate from 'utils/translate'

import DrawerFiltersLineCollapsable, { DrawerFiltersLineCollapsableItem } from 'components/DrawerFilters/DrawerFiltersLineCollapsable'
import { FilteredAreas } from 'components/SeverTable/types'

function AreaFilter ({ hasDocumentationAndAvaliation, label, value }: { hasDocumentationAndAvaliation?: boolean, label: string, value: string }) {
  const resources = useResources()

  const [areasRef, setAreasRef] = useState<FilteredAreas>()

  useLayoutEffect(() => {
    if (resources.areas.length < 1) return

    const filterAreas = resources.areas.reduce((acc, item) => {
      if (item.ref === 'documentacao' && acc.documentacao) acc.documentacao = [...acc.documentacao, item.value]
      if (item.ref === 'juridico') acc.juridico = [...acc.juridico, item.value]
      if (item.ref === 'credito') acc.credito = [...acc.credito, item.value]
      if (item.ref === 'avaliacao' && acc.avaliacao) acc.avaliacao = [...acc.avaliacao, item.value]
      return acc
    }, {
      documentacao: [],
      credito: [],
      avaliacao: [],
      juridico: []
    } as FilteredAreas)

    if (!hasDocumentationAndAvaliation) {
      delete filterAreas?.documentacao
      delete filterAreas?.avaliacao

      const formattedFilter = Object.assign({ engenharia: null }, filterAreas)
      return setAreasRef(formattedFilter)
    }

    setAreasRef(filterAreas)
  }, [resources.areas])

  return (
    <DrawerFiltersLineCollapsable label={label}>
      <DrawerFiltersLineCollapsableItem>

        <Form.Item
          name={value}

        >
          <Select
            className="w-100"
            placeholder={'Selecione a área'}

          >
            <Select.Option value="">Todos</Select.Option>
            {areasRef && Object.keys(areasRef).map((item, index) => (
              <Select.Option
                key={index}
                value={item}
              >
                {translate.areasNames(item)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </DrawerFiltersLineCollapsableItem>
    </DrawerFiltersLineCollapsable>
  )
}

export default AreaFilter

import React, { useState } from 'react'
import { INotificationProps } from './types'
import { Row, Col, Typography } from 'antd'
import { timeAsDayjs } from 'utils/time'
import StringToHtml from 'components/StringToHtml/StringToHtml'
import { EyeOutlined } from '@ant-design/icons'
const { Paragraph } = Typography

const Notification = ({ key, notification, handleRead, setVisible, setSelectNot, select, onClick }: INotificationProps) => {
  const [read, setRead] = useState<boolean>(false)

  return (
    <Row
      key={key}
      className="notification__card"
      style={(select?._id === notification._id) ? { borderColor: 'var(--primary-color)', background: 'var(--gradient-primary-color-5)' } : undefined}
      onClick={() => {
        if (onClick) onClick()
        handleRead(notification._id)
        setRead(true)
      }}
    >
      {read && notification.read === false && <p className="notification__notification-read">Lido <EyeOutlined /></p>}
      <Row
        className="row notification__text-padding "
        onClick={() => {
          setVisible(true)
          setSelectNot(notification)
        }}
      >
        <Col span={24}>
          <Row className='row notification__header' justify='space-between' align='top' >
            <Col span={20}>
              <div className="notification__title-card">
                {notification.title}
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Paragraph
                className="notification__message-card"
                ellipsis={{
                  rows: 2,
                  expandable: true,
                  symbol: 'ver mais'
                }}
              >
                {notification.message && <StringToHtml value={notification.message} />}
              </Paragraph>
            </Col>
          </Row>

          <Row justify="end" className="row">
            <Col className="color-primary notification__date-card">
              {timeAsDayjs(notification.createdAt).format('DD/MM/YYYY HH:mm')}
            </Col>
          </Row>
        </Col>
      </Row>
    </Row>
  )
}

export default Notification

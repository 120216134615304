import { CheckOutlined } from '@ant-design/icons'
import { Tag, Tooltip } from 'antd'
import React from 'react'
import IUser from 'egi/types/IUser'
import { timeAsDayjs } from 'utils/time'
import { varColors } from 'styles/colors'
import translate from 'utils/translate'
import { ColumnType } from 'antd/lib/table'

const commonColumns: { [key: string]: ColumnType<any> } = {
  cellphone: {
    title: 'Celular',
    dataIndex: 'cellphone',
    ellipsis: true,
    width: 150,
    sorter: true
  },

  cpf: {
    title: 'CPF',
    dataIndex: 'cpf',
    ellipsis: true,
    width: 150,
    sorter: true
  },

  name: {
    title: 'Nome',
    dataIndex: 'name',
    sorter: true,
    width: 175,
    align: 'left'
  },

  createdAt: {
    title: 'Dt. criação',
    dataIndex: 'createdAt',
    ellipsis: true,
    align: 'center',
    width: 150,
    sorter: true
  },

  email: {
    title: 'Email',
    dataIndex: 'email',
    sorter: true,
    width: 250,
    render: (value: string | undefined, row: object) => {
      const { emailVerified } = (row as IUser)

      return (
        <span className='flex flex-row'>
          <Tooltip title={value} destroyTooltipOnHide={{ keepParent: false }}>
            <span className='overflow-ellipsis w-75 my-0'>{value}</span>
          </Tooltip>
          {emailVerified && <CheckOutlined className="ml-2 mt-1 color-tertiary" />}
        </span>
      )
    }
  },

  fantasyName: {
    title: 'Razão Social',
    dataIndex: 'fantasyName',
    ellipsis: true,
    width: 175,
    sorter: true,
    render: (fantasyName: string) => {
      if (!fantasyName) return 'N/A'
      return (
        <Tooltip title={fantasyName} destroyTooltipOnHide={{ keepParent: false }}>
          <span title=''>{fantasyName}</span>
        </Tooltip>
      )
    }
  },

  foundationDate: {
    title: 'Dt. fundação',
    dataIndex: 'foundationDate',
    ellipsis: true,
    align: 'center',
    width: 150,
    sorter: true,
    render: (foundationDate: string) => {
      if (!foundationDate) return 'N/A'
      return timeAsDayjs(foundationDate).format('DD/MM/YYYY')
    }
  },

  status: {
    title: 'Status',
    dataIndex: 'status',
    ellipsis: true,
    width: 150,
    align: 'center',
    sorter: true,
    render: (value: string) => {
      return (<Tag color={varColors[value]}>{translate.userStatus(value)}</Tag>)
    }
  },

  updatedAt: {
    title: 'Dt. última atualização',
    dataIndex: 'updatedAt',
    ellipsis: true,
    align: 'center',
    width: 200,
    sorter: true
  }
}

export default commonColumns

export const commomNameOverflowWidth = 160

import React, { Fragment } from 'react'
import { Form, Radio } from 'antd'

import DrawerFiltersLineCollapsable, { DrawerFiltersLineCollapsableItem } from 'components/DrawerFilters/DrawerFiltersLineCollapsable'
import ProposalEgiModel from 'egi/models/ProposalEgiModel'
import { useAuth } from 'hooks'
function NeedVinculationFilter ({ needVinculation }: {needVinculation: boolean | string}) {
  const user = useAuth()
  if (ProposalEgiModel.canSeeNeedVinculationFilter(user.level)) {
    return (
      <div className='proposal-radio-filter'>
        <DrawerFiltersLineCollapsable label='Propostas vinculadas'>
          <Form.Item
            name='needVinculation'
            initialValue={needVinculation}
          >
            <Radio.Group>
              <DrawerFiltersLineCollapsableItem key={''}>
                <Radio value={''}>{'Todos'}</Radio>
              </DrawerFiltersLineCollapsableItem>

              <DrawerFiltersLineCollapsableItem key={'vinculadas'}>
                <Radio value='false'>Vinculadas</Radio>
              </DrawerFiltersLineCollapsableItem>

              <DrawerFiltersLineCollapsableItem key={'naoVinculadas'}>
                <Radio value='true'>Não vinculadas</Radio>
              </DrawerFiltersLineCollapsableItem>

            </Radio.Group>
          </Form.Item>
        </DrawerFiltersLineCollapsable>
      </div>
    )
  }

  return <Fragment/>
}

export { NeedVinculationFilter }

import { Alert, Space } from 'antd'
import React, { ReactNode } from 'react'

interface IProps {
  action: ReactNode
  message: ReactNode
  className?: string
}

function MessageLittlePanel ({ action, message, className }: IProps) {
  return (
    <Alert
      message={message}
      type="warning"
      className={`message-little-panel ${className || ''}`}
      closable
      style={{ border: 'none' }}
      action={
        <Space>
          {action}
        </Space>
      }
    />
  )
}

export default MessageLittlePanel

import { IRequestLGPDResponse } from 'ecp/app/LGPD/LGPDInterfaces'
import { IProduct } from 'ecp/models/ProductModel'
import { AuthorizationsUser, SignInCredentials } from 'ecp/repositories/LoginRepository'
import { IAuth } from 'egi/types'
import { IAuthActions, RouterDomPath, SigninCallback } from './authTypes'

export enum AuthActionTypes {
  RESET = 'AUTH_RESET',
  SIGNIN_REQUEST = 'AUTH_SIGNIN_REQUESTED',
  SIGNIN_SUCCEED = 'AUTH_SIGNIN_SUCCEED',
  REFRESH_TOKEN_REQUESTED = 'AUTH_REFRESH_TOKEN_REQUESTED',
  CAN_REFRESH_TOKEN = 'AUTH_CAN_REFRESH_TOKEN',
  SET_LEVELS = 'AUTH_SET_LEVELS',
  RESET_LEVELS = 'AUTH_RESET_LEVELS',
  UPDATE_SELECTED_PRODUCT = 'AUTH_UPDATE_SELECTED_PRODUCT',
  SET_USER_ROUTER_DOM_PATHS = 'AUTH_SET_USER_ROUTER_DOM_PATHS',
  SET_USER_DATA_REQUESTED = 'AUTH_SET_USER_DATA_REQUESTED',
  REFRESH_TOKEN_SUCCEED = 'AUTH_REFRESH_TOKEN_SUCCEED',
  SET_PROPOSAL_AUTH = 'AUTH_SET_PROPOSAL',
  CHANGE_USER_LEVEL = 'AUTH_CHANGE_USER_LEVEL',
  SET_COMMUNICATION_VISIBLE = 'AUTH_SET_COMMUNICATION_VISIBLE',
  SET_LGPD_INFO = 'AUTH_SET_LGPD_INFO'
}

export const authActions = {
  signin: (form: SignInCredentials, callback?: SigninCallback): IAuthActions.SigninRequest => {
    return ({
      type: AuthActionTypes.SIGNIN_REQUEST,
      payload: {
        ...form,
        callback
      }
    })
  },

  signinSucceed: (user: IAuth): IAuthActions.SigninSucceed => ({
    type: AuthActionTypes.SIGNIN_SUCCEED,
    payload: user
  }),

  refresh: (refreshToken: string): IAuthActions.Refresh => ({
    type: AuthActionTypes.REFRESH_TOKEN_REQUESTED,
    payload: {
      refreshToken
    }
  }),

  refreshTokenSucceed: (token: string, expiresAt: Date | string): IAuthActions.RefreshTokenSucceed => ({
    type: AuthActionTypes.REFRESH_TOKEN_SUCCEED,
    payload: {
      token,
      expiresAt
    }
  }),

  canRefreshToken: () => ({
    type: AuthActionTypes.CAN_REFRESH_TOKEN
  }),

  setLevels: (levels: AuthorizationsUser[]): IAuthActions.SetLevels => ({
    type: AuthActionTypes.SET_LEVELS,
    payload: { levels }
  }),

  resetLevels: () => ({
    type: AuthActionTypes.RESET_LEVELS
  }),

  setProposal: (id: string): IAuthActions.SetProposalAuth => ({
    type: AuthActionTypes.SET_PROPOSAL_AUTH,
    payload: {
      proposalId: id
    }
  }),

  changeUserLevel: (user: IAuth): IAuthActions.ChangeUserLevel => ({
    type: AuthActionTypes.CHANGE_USER_LEVEL,
    payload: user
  }),

  updateSelectedProduct: (product: IProduct): IAuthActions.UpdateSelectedProduct => ({
    type: AuthActionTypes.UPDATE_SELECTED_PRODUCT,
    payload: product
  }),

  resetAuth: (): IAuthActions.Reset => ({
    type: AuthActionTypes.RESET
  }),

  setUserRouterDomPaths: (paths: RouterDomPath[]): IAuthActions.SetUserRouterDomPaths => ({
    type: AuthActionTypes.SET_USER_ROUTER_DOM_PATHS,
    payload: { paths }
  }),

  setUserData: (user: IAuth, callback?: (...args: any[]) => any): IAuthActions.SetUserData => ({
    type: AuthActionTypes.SET_USER_DATA_REQUESTED,
    payload: {
      user,
      callback
    }
  }),

  updateCommunicationVisible: (visible: boolean): IAuthActions.UpdateCommunicationVisible => ({
    type: AuthActionTypes.SET_COMMUNICATION_VISIBLE,
    payload: {
      visible
    }
  }),

  setLGPDInfo: (lgpd: IRequestLGPDResponse): IAuthActions.SetLGPDInfo => ({
    type: AuthActionTypes.SET_LGPD_INFO,
    payload: {
      lgpd
    }
  })
}

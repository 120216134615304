import React, { useEffect, useState } from 'react'

import { Empty, Form, Select } from 'antd'
import DrawerFiltersLineCollapsable, { DrawerFiltersLineCollapsableItem } from 'components/DrawerFilters/DrawerFiltersLineCollapsable'
import { ISelect } from 'egi/types'
import ResourcesRepository from 'egi/repositories/ResourcesRepository'
import { IBusinessManager } from 'store/modules/resources/types'
import translate from 'utils/translate'

export type FilterPromoterProps = {
  setSeller: React.Dispatch<React.SetStateAction<string | undefined>>
}

function ManagerFilter () {
  const [managers, setManagers] = useState<ISelect[]>([])
  const [error, setError] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const getManager = async () => {
      setLoading(true)
      setError(undefined)
      try {
        const response = await ResourcesRepository.getAllManagers()

        const allManagers = response.data.data.allPossibleManagers

        const formattedManagers = allManagers.map((item : IBusinessManager) => {
          return {
            label: `${item.name} ${item.areaName ? `(${translate.areasNames(item.areaName)})` : ''}`,
            value: item._id
          }
        })

        setManagers(formattedManagers)
      } catch (err) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    getManager()
  }, [])

  return (
    <DrawerFiltersLineCollapsable label='Gerente de negócios'>
      <DrawerFiltersLineCollapsableItem>
        <Form.Item
          name="promoterManagerIds"
        >
          <Select
            showSearch
            notFoundContent={
              <Empty description={error ?? 'Não foi possível encontrar nenhum item'}/>
            }
            loading={loading}
            optionFilterProp="children"
            className="w-100"
            placeholder='Selecione o gerente de negócios'
            allowClear
          >
            <Select.Option value="">Todos</Select.Option>
            {managers.map(item => {
              return <Select.Option
                value={item.value}
                key={item.value}>
                {item.label}
              </Select.Option>
            })}

          </Select>
        </Form.Item>
      </DrawerFiltersLineCollapsableItem>
    </DrawerFiltersLineCollapsable>

  )
}

export default ManagerFilter

import React from 'react'
import { RHCovenantSelectPendingUser } from '../../components/RHCovenantSelect/RHCovenantSelect'
import { useTransferPendingUserOnidataRedirect } from '../../hooks/usePendingUserCovenants'
import { RH_PENDING_USER_TEXT } from '../../rhConstants'

export default function RHTransferPendingUser () {
  const {
    onSelect
  } = useTransferPendingUserOnidataRedirect()

  return (
    <RHCovenantSelectPendingUser
      textInfo={RH_PENDING_USER_TEXT}
      onSelect={onSelect}
    />
  )
}

import { AxiosRequestConfig } from 'axios'
import { Repository } from 'ecp/repositories/Repository'
import { DashboardEgiBaseTotals } from 'egi/app/MLM/components/MlmProposalsMinorDashCards/MlmProposalsMinorDashCards'
import api from 'services/api'

export type DashboardEgiHistoryItem = {
  [key: string]: string
  time: string
}

type areasTypes = 'credito' | 'engenharia' | 'comercial' | 'juridico'
export type IDepartmentEgiTotals = {
  _id: areasTypes
  count: number
  image: string
  name: string
  total: number
  value: number
}

class DashboardEgiRepository extends Repository {
  async totals (config: AxiosRequestConfig) {
    return Repository.handle<DashboardEgiBaseTotals>(() =>
      this.api.get(`${this.path}/totals`, config)
    )
  }

  async history (config: AxiosRequestConfig) {
    return Repository.handle<{ proposalHistories: DashboardEgiHistoryItem[] }>(() =>
      this.api.get(`${this.path}/history`, config)
    )
  }

  async departments (config: AxiosRequestConfig) {
    return Repository.handle<{ proposals: IDepartmentEgiTotals[] }>(() =>
      this.api.get(`${this.path}/departments`, config)
    )
  }
}

export default new DashboardEgiRepository({ api, path: '/dashboard' })

import React, { Fragment } from 'react'
import { Steps } from 'antd'

interface ICustomStep {
  counterStep: number,
  setCounterStep: React.Dispatch<React.SetStateAction<number>>,
  quantity: number,
  cannotClickStepZero?: boolean
  enableAfter?: boolean
  type?: 'navigation' | 'default'
}

function CustomSteps ({ quantity, counterStep, setCounterStep, cannotClickStepZero, enableAfter, type = 'default' }: ICustomStep) {
  const { Step } = Steps

  return (
    <Steps type={type} current={counterStep} className="steps-container">
      {new Array(quantity).fill('').map((_, index) => {
        const disabledSteps = enableAfter ? undefined : counterStep < index
        const cannotClick = cannotClickStepZero && index === 0
        return (
          <Fragment key={index}>
            <Step
              key={index}
              className={counterStep === index ? 'steps-simulator' : `${disabledSteps ? '' : 'cursor-pointer'}`}
              disabled={disabledSteps}
              onClick={() => {
                if (disabledSteps) return
                if (cannotClick) return setCounterStep(1)
                setCounterStep(index)
              }}
            />
          </Fragment>
        )
      })}
    </Steps>
  )
}

export default CustomSteps

import { _userLevel } from 'ecp/models/UsersModel'

const DeclinesModel = {

  canRemoveReason (userLevel: _userLevel): boolean {
    return userLevel !== 'auditor'
  },
  showDisabledInput (userLevel: _userLevel): boolean {
    return userLevel === 'auditor'
  },

  canAddReason (userLevel: _userLevel): boolean {
    return userLevel !== 'auditor'
  }

}

export default DeclinesModel

import React, { Fragment, useState } from 'react'
import { Button, Col, Form, Input, Row } from 'antd'
import { LockOutlined } from '@ant-design/icons'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'
import { InputPassword } from 'components'
import UsersRepository from 'ecp/repositories/UsersRepository'
import useAuthLayout from 'hooks/useAuthLayout'

export interface IUpdateOnidataCredentials {
  email?: string
  oldPass?: string
  password?: string
  confirmPassword?: string
}

type IUpdateOnidataCredentialsErrors = Partial<IUpdateOnidataCredentials>

function UpdateOnidataCredentials () {
  const [errors, setErrors] = useState<IUpdateOnidataCredentialsErrors>({})
  const [loading, setLoading] = useState<boolean>(false)
  const { handleError, handleStatus, isOnidataCredentialWorking } = useAuthLayout()

  const onSubmit = async (credentials: IUpdateOnidataCredentials) => {
    setLoading(true)
    try {
      const response = await UsersRepository.validatingOnidataCredentials(credentials)
      if (response.data.message) swal.basic({ title: 'Sucesso', icon: 'success', text: response.data.message })
      await isOnidataCredentialWorking()
      handleError('')
      handleStatus('working')
    } catch (err) {
      if (err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
        setLoading(false)
        swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
        return
      }
      setErrors({})
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <Form
        layout="vertical"
        className="p-3"
        onFinish={onSubmit}
      >
        <h3 className='profile__section-title mb-2'>Atualizar credenciais no canal Rodobens</h3>
        <p className='mb-4'>
          Atualize suas credenciais utilizando o seu e-mail e a senha mais recente do <b>canal Rodobens</b>, juntamente com sua senha atual deste sistema.
          Por favor, fique <b>ATENTO</b>, pois a senha será atualizada apenas em nosso sistema, tornando-se sua nova forma de acesso.</p>
        <Row gutter={15}>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name='email'
              rules={[{
                required: true,
                message: 'Digite seu email'
              }]}
              label={<label className="simulator-label">Confirmar e-mail</label>}
              help={errors?.email}
              validateStatus={errors?.email && 'error'}
            >
              <Input placeholder='Digite seu email' />
            </Form.Item>
          </Col>

          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name='oldPass'
              rules={[{
                required: true,
                message: 'Digite sua senha!'
              }]}
              label={<label className="simulator-label">Senha atual(nosso sistema)</label>}
              help={errors?.oldPass}
              validateStatus={errors?.oldPass && 'error'}
            >
              <InputPassword placeholder="Digite sua senha" prefix={<LockOutlined />} />
            </Form.Item>
          </Col>

          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name='password'
              rules={[{
                required: true,
                message: 'Digite sua senha!'
              }]}
              label={<label className="simulator-label">Senha canal Rodobens</label>}
              help={errors?.password}
              validateStatus={errors?.password && 'error'}
            >
              <InputPassword placeholder="Digite sua senha" prefix={<LockOutlined />}/>
            </Form.Item>
          </Col>

          <Col lg={12} md={12} sm={24} xs={24}>
            <Form.Item
              name='confirmPassword'
              rules={[{
                required: true,
                message: 'Digite sua senha!'
              }]}
              label={<label className="simulator-label">Confirme a senha canal Rodobens</label>}
              help={errors?.confirmPassword}
              validateStatus={errors?.confirmPassword && 'error'}
            >
              <InputPassword placeholder="Digite sua senha" prefix={<LockOutlined />} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[15, 0]} align='middle' justify='end'>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
          >
            Atualizar
          </Button>
        </Row>
      </Form>
    </Fragment>
  )
}

export default UpdateOnidataCredentials

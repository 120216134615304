import React, { Fragment } from 'react'
import { ColumnsType } from 'antd/lib/table'
import { Link, useParams } from 'react-router-dom'
import { StopOutlined } from '@ant-design/icons'
import swal from 'utils/swal'
import { Button, Tag } from 'antd'
import { varColors } from 'styles/colors'
import { ICommissionColumns } from '../princingInterfaces'
import { IPricing } from 'egi/types'
import format from 'utils/format'
import { timeAsDayjs } from 'utils/time'
import { states } from 'utils/globals'
import translate from 'utils/translate'
import { useAuth } from 'hooks'

interface IColumns {
  deactivatePricing: Function
}

const genericPricingColumns = {

  deadlineMin: {
    title: 'Prazo mín.',
    dataIndex: 'deadlineMin',
    width: 120
  },

  deadlineMax: {
    title: 'Prazo máx.',
    dataIndex: 'deadlineMax',
    width: 120
  },

  modality: {
    title: 'Modalidade',
    dataIndex: 'modality'
  },

  ltv: {
    title: 'LTV',
    dataIndex: 'ltv'
  },

  status: {
    title: 'Status',
    dataIndex: 'active',
    width: 100,
    align: 'center',
    render: function renderItem (text: string) {
      return (
        <Tag
          className="text-center"
          style={{
            background: text === 'Ativo'
              ? varColors.approved
              : varColors.starting,
            color: 'white'
          }}
        >
          {text}
        </Tag>
      )
    }
  }

}

const pricingBase: ColumnsType<Object> = [
  {
    title: 'Aliquota',
    dataIndex: 'aliquota'
  },
  {
    title: 'Prestamista',
    dataIndex: 'prestamista'
  },
  {
    title: 'Valor min.',
    dataIndex: 'minValue',
    width: 150
  },
  {
    title: 'Dt. criação',
    dataIndex: 'createdAt',
    width: 150
  },
  genericPricingColumns.ltv,
  {
    title: 'UF',
    dataIndex: 'uf',
    render: (text: Array<string>) => {
      let finalText = ''
      if (!text) return ''

      interface ISelect {
        value: number
        label: string
      }

      if (text && text.length === 1) {
        if (text[0] === '') return ('N/A')
        const filterUf = states.find((item: ISelect) => item.value === Number(text[0]))
        if (filterUf) return (filterUf.label)
      }

      const tempArray: string[] = []
      for (const uf of text) {
        const filterItems = states.find((item: ISelect) => item.value === Number(uf))
        if (filterItems) tempArray.push(filterItems.label)
      }
      finalText = (format.separeteValuesByComma(tempArray) as string)

      return (
        <Button
          type="ghost"
          onClick={() => {
            swal.basic({
              title: 'UFs',
              text: finalText
            })
          }}
        >
          Ver mais
        </Button>
      )
    }
  }
]

const columns = ({ deactivatePricing }: IColumns): ColumnsType<Object> => [
  {
    title: 'Código',
    dataIndex: 'code',
    sorter: true,
    width: 120,
    render: function renderItem (text, row) {
      const items = (row as ICommissionColumns)
      const params: { productSlug: string, personType: string, product: string } = useParams()
      return (
        <Link
          className="link-style color-tertiary font-500"
          to={`/auth/pricing/edit/${params.personType}/${params.product}/${params.productSlug}/${items._id}`}>
          {text}
        </Link>
      )
    }
  },
  genericPricingColumns.status,
  {
    title: 'Promoção',
    dataIndex: 'isPromotional',
    width: 120,
    render: value => value ? 'Sim' : 'Não'
  },
  {
    title: 'Vigência',
    dataIndex: 'vigency',
    width: 120,
    render: function renderItem (item) {
      if (item.start && item.end) {
        return (
          <Fragment>
            <label>{timeAsDayjs(item.start, { applyTimezone: false }).format('MM/YYYY')} - </label>
            <label>{timeAsDayjs(item.end, { applyTimezone: false }).format('MM/YYYY')}</label>
          </Fragment>
        )
      }

      return (<div>N/A</div>)
    }
  },
  genericPricingColumns.deadlineMin,
  genericPricingColumns.deadlineMax,
  genericPricingColumns.modality,
  {
    title: 'Indexador',
    dataIndex: 'index',
    render: (item: any) => {
      if (item === 'FLATRATE') return 'PRÉ-FIXADA'
      return item
    }
  },
  ...pricingBase,
  {
    title: '',
    dataIndex: '',
    render: (_, row) => {
      const items = (row as IPricing)
      const user = useAuth()
      const hideDesactivePricing = (row as any).active === 'Desativado' || user.level === 'auditor'

      if (hideDesactivePricing) {
        return (<></>)
      }

      return (
        <StopOutlined
          onClick={() => {
            swal.confirmNegate({
              title: 'Atenção',
              text: 'Tem certeza que deseja desativar a precificação?',
              icon: 'warning',
              confirm: () => {
                if (items?._id) {
                  deactivatePricing(items?._id)
                }
              }
            })
          }}
          className="mr-2"
          style={{ fontSize: '20px', color: varColors.reproved }}
        />
      )
    }
  }
]

const commissionColumns: ColumnsType<Object> = [
  {
    title: 'Código',
    dataIndex: 'code',
    render: function renderItem (text, row) {
      const items = (row as ICommissionColumns)

      return (
        <Link
          className="link-style color-tertiary font-500 font-500"
          to={`/auth/pricing/edit/${items.personType}/${items.product}/${items.sub}/${items._id}`}>
          {text}
        </Link>
      )
    }
  },
  genericPricingColumns.status,
  {
    title: 'Promoção',
    dataIndex: 'isPromotional',
    render: value => value ? 'Sim' : 'Não'
  },
  {
    title: 'Produto',
    dataIndex: 'product',
    render: product => format.capitalize(product)
  },
  {
    title: 'Sub produto',
    dataIndex: 'sub',
    width: 125,
    render: sub => translate.subProducts(sub)
  },
  {
    title: 'Tip. pessoa',
    dataIndex: 'personType',
    width: 125,
    render: personType => format.capitalize(personType)
  },
  genericPricingColumns.deadlineMin,
  genericPricingColumns.deadlineMax,
  genericPricingColumns.modality,
  genericPricingColumns.ltv
]

const pricingColumns = ({ deactivatePricing }: IColumns): ColumnsType<Object> => [
  ...commissionColumns,
  ...pricingBase,
  {
    title: '',
    dataIndex: '',
    render: (_, row) => {
      const items = (row as IPricing)

      if ((row as any).active === 'Desativado') {
        return (<></>)
      }

      return (
        <StopOutlined
          onClick={() => {
            swal.confirmNegate({
              title: 'Atenção',
              text: 'Tem certeza que deseja desativar a precificação?',
              icon: 'warning',
              confirm: () => {
                if (items?._id) {
                  deactivatePricing(items._id)
                }
              }
            })
          }}
          className="mr-2"
          style={{ fontSize: '20px', color: varColors.reproved }}
        />
      )
    }
  }
]

export { columns, commissionColumns, pricingColumns }

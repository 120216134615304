import React from 'react'
import { Col, Row } from 'antd'
import DetailsItem from 'ecp/components/DetailsItem/DetailsItem'
import { IOnidataClient } from 'ecp/models/ClientModel'

export function PaymentFieldsDisplay ({ paymentInfos }: { paymentInfos: IOnidataClient }) {
  return (
    <Row gutter={[25, 25]}>
      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='Banco'
          value={paymentInfos?.pagamento?.banco ?? '-'}
        />
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='Agência'
          value={paymentInfos?.pagamento?.agencia ?? '-'}
        />
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='Dígito da agência (opcional)'
          value={paymentInfos?.pagamento?.agencia_dac ?? '-'}
        />
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='Conta'
          value={paymentInfos?.pagamento?.conta ?? '-'}
        />
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='Dígito da conta'
          value={paymentInfos?.pagamento?.conta_dac ?? '-'}
        />
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='Tipo de conta'
          value={paymentInfos?.pagamento?.tipo ?? '-'}
        />
      </Col>
    </Row>
  )
}

export default PaymentFieldsDisplay

import { Timeline } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { TimelineItemProps } from 'antd/lib/timeline'
import { CaptionIcon, CAPTIONS_TRANSLATED_HISTORY } from 'components/Captions/Captions'
import StringToHtml from 'components/StringToHtml/StringToHtml'
import dayjs from 'dayjs'
import {
  IProposalHistory
} from 'ecp/app/Proposals/proposalInterfaces'
import ProposalModel from 'ecp/models/ProposalModel'
import { useAuth } from 'hooks'
import React from 'react'
import translate from 'utils/translate'

interface IProposalHistoryDotProps {
  status: IProposalHistory.StepItem['status']
}

const ProposalHistoryDot = ({ status }: IProposalHistoryDotProps) => {
  const { sm: isGreaterThanSm } = useBreakpoint()

  return (
    <CaptionIcon name={status} size={isGreaterThanSm ? 32 : 25} />
  )
}

const ClientInstructions = ({ instructions }: { instructions: string }) => {
  if (!instructions) return <></>
  return (
    <p className='proposal-history-item__description'>
      <b>Instruções para o cliente:</b> {instructions ?? '-'}
    </p>
  )
}

const HistoryHeader = ({ item }: { item: IProposalHistory.Item }) => {
  return <hgroup className='proposal-history-item__header'>
    <h3 className='proposal-history-item__title'>{item.currentStep?.step?.name}</h3>
    <p className='proposal-history-item__sub-title'>
      Status atualizado de <strong>{item.prevStatusOfThisStep ? CAPTIONS_TRANSLATED_HISTORY.ecp[item.prevStatusOfThisStep] : 'Pendente '}</strong> para{' '}
      <strong className='no-wrap'>{CAPTIONS_TRANSLATED_HISTORY.ecp[item.currentStep?.label]}</strong>
      {item.nextStep?.name && <><wbr /> e passo atualizado para <strong className='no-wrap'>{item.nextStep?.name}</strong></>}.
    </p>
  </hgroup>
}

const HistoryContent = ({ item }: { item: IProposalHistory.Item }) => {
  const user = useAuth()
  const { md: isMediumScreenOrLarger } = useBreakpoint()

  if (ProposalModel.canOnlySeeClientInstructions(user.level)) {
    return <>
      <HistoryHeader item={item} />
      <ClientInstructions
        instructions={item.currentStep.instructions}
      />
    </>
  }

  return (
    <div className='ml-1'>
      <HistoryHeader item={item} />

      <ClientInstructions
        instructions={item.currentStep.instructions}
      />

      {item.currentStep.reason?.reason &&
        <p className='proposal-history-item__description bold'>
          {item.currentStep.reason?.reason}
        </p>
      }

      {item.currentStep?.description &&
        <p className='proposal-history-item__description'>
          <StringToHtml value={item.currentStep?.description} />
        </p>
      }

      <div className='proposal-history-item__footer'>
        <time
          dateTime={item.currentStep?.updatedAt}
          className='proposal-history-item__datetime'
        >
          {dayjs(item.currentStep?.updatedAt).format(`HH:mm:ss ${isMediumScreenOrLarger ? '[-] ' : ''}DD/MM/YYYY`)}
        </time>

        {ProposalModel.showProposalHistoryAuthor(user.level) && (
          <span className='proposal-history-item__user'>
            {item.currentStep?.user ? (
              <>
                <i className='proposal-history-item__user-level'>
                  {translate.level(item.currentStep?.user.level)}
                </i>
                <p className='proposal-history-item__user-name'>
                  {item.currentStep?.user.name}
                </p>
              </>
            ) : (
              <p className='proposal-history-item__user-name proposal-history-item__user-name--unauth'>
                Não autenticado
              </p>
            )}
          </span>
        )}
      </div>
    </div>
  )
}

interface IProps extends TimelineItemProps {
  item: IProposalHistory.Item
}

function ProposalHistoryItem ({ item, ...rest }: IProps) {
  return (
    <Timeline.Item
      className='proposal-history-item'
      dot={<ProposalHistoryDot status={item.currentStep?.status} />}
      {...rest}
    >
      <HistoryContent item={item} />
    </Timeline.Item>
  )
}

export default ProposalHistoryItem

import { Form, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { swalError } from 'components/SwalError/SwalError'
import TokenVerification from 'components/TokenVerification/TokenVerification'
import { IError } from 'ecp/app/Proposals/proposalInterfaces'
import TokenHeader from 'ecp/components/TokenHeader/TokenHeader'
import LoginRepository from 'ecp/repositories/LoginRepository'
import SignupButton from 'egi/app/Signup/components/SignupButton/SignupButton'
import SignupLayout from 'layouts/SignupLayout/SignupLayout'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { UNAUTHS_PATHS } from 'routes/unauthRoutes'
import { handleOnidataErrors } from 'utils/validate'
import FirstAccesResendToken from '../../components/FirstAccesResendToken/FirstAccesResendToken'
import { IFirstAccessCorbanRHTokenInfo } from '../../firstAccesInterfaces'
import useFirstAccesCorbanTokenInfo from '../../hooks/useFirstAccesCorbanTokenInfo'

interface IFirstAccessPhoneTokenForm {
  token_confirmacao: string
}

export default function FirstAccessPhoneToken () {
  const [form] = useForm()
  const [errors, setErrors] = useState<IError<IFirstAccessPhoneTokenForm>>({})
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const { tokenInfo } = useFirstAccesCorbanTokenInfo()
  const { onidataToken } = tokenInfo
  const history = useHistory()

  function redirectToNextStep (token: string, tokenInfo: IFirstAccessCorbanRHTokenInfo) {
    history.push({
      pathname: `${UNAUTHS_PATHS.FIRST_ACCESS_PASSWORD}`,
      state: tokenInfo
    })
  }

  async function handleSubmit (values: IFirstAccessPhoneTokenForm) {
    setLoadingSubmit(true)
    setErrors({})

    try {
      const data = {
        ...values,
        onidataToken
      }
      const response = await LoginRepository.confirmSms(data)

      message.success(response.data.message)
      redirectToNextStep(String(onidataToken), tokenInfo)
    } catch (err) {
      swalError({ title: 'Atenção', icon: 'warning', err })
      setErrors(handleOnidataErrors(err.data?.error))
    } finally {
      setLoadingSubmit(false)
    }
  }

  return (
    <SignupLayout>
      <TokenHeader
        title='Token enviado para o seu celular'
        infoText={<p className='ecp-token-header__info-text'>Adicione o token que você recebeu no número <b>{tokenInfo?.cellphone}</b></p>}
        className='mb-5'
      />
      <Form
        onFinish={handleSubmit}
        form={form}
        layout='vertical'
        className='unauth-form'
      >
        <Form.Item
          name='token_confirmacao'
          label={<span className='simulator-label'>Digite o token</span>}
          help={errors?.token_confirmacao}
          validateStatus={errors?.token_confirmacao && ('error')}
          rules={[
            {
              required: true,
              message: 'Adicione o token'
            }
          ]}
        >
          <TokenVerification lenght={6}/>
        </Form.Item>
        <Form.Item>
          <SignupButton
            loading={loadingSubmit}
            htmlType='submit'
            className='w-100'
          >
            Enviar
          </SignupButton>
        </Form.Item>
      </Form>

      {tokenInfo?.cellphone &&
        <div className='flex flex-justify-center'>
          <FirstAccesResendToken
            value={{
              telefone_celular: tokenInfo.cellphone,
              onidataToken
            }}
            sendMethod='cellphone'
          />
        </div>
      }
    </SignupLayout>
  )
}

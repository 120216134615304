import React, { Fragment, lazy, Suspense, useLayoutEffect, useState } from 'react'
import { Empty, Skeleton, Spin, Typography } from 'antd'
import { useAuth } from 'hooks'
import { _userLevel } from 'ecp/models/UsersModel'
import { IFilterDate } from '../../views/MlmDashboard/types'
import { chartConfigTop, dashGreaterChartStyle } from '../MlmLeadsChartPie/chartConfig'
import MlmRepository, { MlmDashboardTopResponse } from 'egi/repositories/MlmRepository'

function MlmDashboardTop ({ filterDate }: IFilterDate) {
  const user = useAuth()
  const [data, setData] = useState<MlmDashboardTopResponse[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)

  function topText (level: _userLevel | undefined) {
    if (!level) return ''

    switch (level) {
      case 'master':
        if (filterDate.promoterId) return 'Top cliques vendedores'
        if (!filterDate.promoterId) return 'Top cliques parceiros'
        return
      case 'promoter':
        return 'Top cliques vendedores'
      default:
        return 'Top cliques'
    }
  }

  useLayoutEffect(() => {
    const abortController = new AbortController()
    async function fetchTopChart () {
      setLoading(true)
      try {
        const response = await MlmRepository.top({
          signal: abortController.signal,
          params: filterDate
        })

        const { values } = response.data.data || {}

        if (values && values !== 0) {
          setData(values as MlmDashboardTopResponse[])
        } else setData([])

        setFailed(false)
        setLoading(false)
      } catch (err) {
        if (!abortController.signal.aborted) {
          setFailed(true)
          setLoading(false)
        }
      }
    }

    if (filterDate.createdDate && filterDate.productId) fetchTopChart()
    return () => abortController.abort()
  }, [
    filterDate.createdDate,
    filterDate.productId,
    filterDate.promoterId,
    filterDate.sellerId,
    filterDate.type
  ])

  const mainConfigTop = chartConfigTop(data)
  const Bar = lazy(() => import('@ant-design/charts/lib/bar/index'))

  return (
    <div className="dashboard-status-card h-100">
      {loading ? (
        <Skeleton
          active={true}
          paragraph={{ rows: 2 }}
          avatar
        />
      ) : (
        failed ? (
          <Empty description="Falha ao carregar dados." className="h-100 flex flex-align-center flex-column flex-justify-center" />
        ) : (
          <Fragment>
            <Typography.Title className="color-primary" level={5}>{topText(user.level)}</Typography.Title>
            <Suspense fallback={<Spin />}>
              <Bar style={dashGreaterChartStyle} {...mainConfigTop} />
            </Suspense>
          </Fragment>
        )
      )}
    </div>
  )
}

export default MlmDashboardTop

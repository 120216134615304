import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { slaColors } from 'utils/globals'
import { timeAsDayjs } from 'utils/time'
import { ISLAProps } from '../../views/SLAConfig/slaInterfaces'

function useSLA ({ sla }: ISLAProps) {
  const [slaColor, setColor] = useState<string>('')
  const [slaText, setSlaText] = useState<string>('')
  const maxDate = timeAsDayjs(dayjs(sla?.max)).format('DD-MM-YYYY')

  function calculateActiveSLA () {
    const startDate = timeAsDayjs(dayjs(sla?.start)).format('DD-MM-YYYY')
    const diffTime = (timeAsDayjs(dayjs(sla?.max)).diff(timeAsDayjs(dayjs()), 'days'))
    const color = slaColors(diffTime)

    setColor(color)
    setSlaText(`Data de início: ${startDate} - Data máxima: ${maxDate} = ${diffTime} dias`)
  }

  function calculateFinishedSLA () {
    const endDateFormated = timeAsDayjs(sla?.end).format('DD-MM-YYYY')
    const diffTime = (dayjs(sla?.max).diff(dayjs(sla?.end), 'days'))
    const color = slaColors(diffTime)

    setColor(color)
    setSlaText(`Data de saída: ${endDateFormated} - Data máxima: ${maxDate}`)
  }

  useEffect(() => {
    if (sla.start && !sla.end) calculateActiveSLA()
    if (sla?.end) calculateFinishedSLA()
  }, [sla?.start, sla?.end])

  return { slaColor, slaText }
}

export default useSLA

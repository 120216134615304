import { ArrowRightOutlined } from '@ant-design/icons'
import { Spin, Timeline } from 'antd'
import simulationCard from 'components/SimulationCard/consumer'
import { _userLevel } from 'ecp/models/UsersModel'
import { IParcels } from 'egi/app/Simulator/simulatorInterfaces'
import { ITaxes } from 'egi/types'
import { useProposal } from 'hooks'
import React, { Fragment, useEffect, useState } from 'react'
import format from 'utils/format'
import swal from 'utils/swal'
import { timeAsDayjs } from 'utils/time'

interface IExpirationHistory {
  createdAt: string
  _id: string
  last: {
    proposalExpirationDate: string
    parcels?: IParcels[]
    taxes?: ITaxes
    totalInterest?: number
    totalValue?: number
  }
  modifiedBy: {
    level: _userLevel
    name: string
    _id: string
  }
  new: {
    proposalExpirationDate: string
    parcels?: IParcels[]
    taxes?: ITaxes
    totalInterest?: number
    totalValue?: number
  }
}

function HistoryList ({ history }: { history: IExpirationHistory }) {
  return (
    <ul>
      <li className="mb-3">
        <p className='font-size-15'>Alterado por <b>{history.modifiedBy.name}</b> em <b>{timeAsDayjs(history.createdAt, { applyTimezone: false }).format('DD/MM/YYYY')}</b>:</p>
        <p className='mt-1'><span className='font-500'>Validade da proposta:</span> <b className="color-gray" >{timeAsDayjs(history.last.proposalExpirationDate, { applyTimezone: false }).format('DD/MM/YYYY')}</b> <ArrowRightOutlined /> <b className="color-tertiary" >{timeAsDayjs(history.new.proposalExpirationDate, { applyTimezone: false }).format('DD/MM/YYYY')}</b></p>
        {history.last.parcels && history.last.parcels[0].totalParcel && history.new.parcels && history.new.parcels[0].totalParcel && <p className='mt-1'><span className='font-500'>Primeira parcela: </span> <b className="color-gray">{format.formatBRL(history.last.parcels[0].totalParcel)}</b> <ArrowRightOutlined /> <b className="color-tertiary">{format.formatBRL(history.new.parcels[0].totalParcel)}</b></p>}
        {history.last?.taxes?.monthlyTax && history.new?.taxes?.monthlyTax && <p className='mt-1'><span className='font-500'>Taxa de juros mensal:</span> <b className="color-gray"> {format.decimalToPercentage(history.last.taxes.monthlyTax).toFixed(2)} %</b> <ArrowRightOutlined /> <b className="color-tertiary"> {format.decimalToPercentage(history.new.taxes.monthlyTax).toFixed(2)} %</b></p>}
        {history.last?.taxes?.anualTax && history.new?.taxes?.anualTax && <p className='mt-1'><span className='font-500'>Taxa de juros anual:</span> <b className="color-gray"> {format.decimalToPercentage(history.last.taxes.anualTax).toFixed(2)} %</b> <ArrowRightOutlined /> <b className="color-tertiary"> {format.decimalToPercentage(history.new.taxes.anualTax).toFixed(2)} %</b></p>}
        {history.last?.totalInterest && history.new?.totalInterest && <p className='mt-1'><span className='font-500'>Valor total de juros:</span> <b className="color-gray">{format.formatBRL(history.last.totalInterest)} </b> <ArrowRightOutlined /> <b className="color-tertiary">{format.formatBRL(history.new.totalInterest)} </b></p>}
        {history.last?.totalValue && history.new?.totalValue && <p className='mt-1'><span className='font-500'>Valor total a pagar:</span> <b className="color-gray"> {format.formatBRL(history.last.totalValue)}</b> <ArrowRightOutlined /> <b className="color-tertiary"> {format.formatBRL(history.new.totalValue)}</b></p>}
      </li>
    </ul>
  )
}

export default function RevalidateHistory () {
  const [loading, setLoading] = useState<boolean>()
  const [histories, setHistories] = useState<IExpirationHistory[]>()

  const proposal = useProposal()

  useEffect(() => {
    async function getExpirationHistory () {
      setLoading(true)
      try {
        if (!proposal.id) throw new Error('Falha ao encontrar id da proposta')
        const response = await simulationCard.revalidateHistory(proposal.id)
        if (response.data.revalidateLogs) setHistories(response.data.revalidateLogs)
      } catch (error) {
        swal.basic({ title: 'Atenção!', text: error.message, icon: 'error' })
      } finally {
        setLoading(false)
      }
    }
    getExpirationHistory()
  }, [])

  return (
    <Fragment>
      {loading
        ? <div className='p-4 flex flex-justify-center flex-align-center'>
          <Spin />
        </div>
        : histories?.length
          ? <Fragment>
            <Timeline className="lead-history-item-tail mt-2">
              {Array.isArray(histories) && histories.map(history => (
                <Timeline.Item key={history._id}>
                  <HistoryList
                    history={history}
                  />
                </Timeline.Item>
              )
              )}
            </Timeline>
          </Fragment>
          : <span className='p-4 flex flex-justify-center flex-align-center'>Histórico não encontrado!</span>
      }
    </Fragment>
  )
}

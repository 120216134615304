import React, { Fragment, useLayoutEffect, useState } from 'react'
import { Checkbox, Col, Divider, Form, InputNumber, Row, Select, Tooltip, Typography } from 'antd'
import { Address, Input, InputMoney } from 'components'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useProposal, useStep } from 'hooks'
import { InfoCircleOutlined } from '@ant-design/icons'
import swal from 'utils/swal'
import { IFormPropertyAddressErrors } from 'components/FormUserData/Property/types'
import { FormInstance } from 'antd/lib/form'
import { ISelect } from 'egi/types'
import { resources } from 'egi/consumers'
import { ICoOwners, ICoOwnersFormErrors, PropertyFormOwner } from './components/PropertyFormOwner/PropertyFormOwner'

interface IPropertyFormProps {
  errors: IFormPropertyAddressErrors
  readOnlyInput?: boolean
  formRef: FormInstance
}

function PropertyForm ({ errors, readOnlyInput, formRef }: IPropertyFormProps) {
  const [isOwned, setIsOwned] = useState<'PROPRIA' | 'DETERCEIROS' | '' | string>('')

  const [hasVacancies, setHasVacancies] = useState(false)
  const [hasDismemberedBox, setHasDismemberedBox] = useState(false)
  const [isPropertyCondominium, setIsPropertyCondominium] = useState(false)
  const [hasPropertyVacancies, setHasPropertyVacancies] = useState(false)
  const [coOwners, setCoOwners] = useState<ICoOwners[]>()

  const [coOwnership, setCoOwenership] = useState(false)
  const [hasStorage, setHasStorage] = useState(false)
  const [isFinancialOperation, setIsFinancialOperation] = useState(false)
  const [partnerProperty, setPartnerProperty] = useState<boolean>(false)
  const [partners, setPartners] = useState<ISelect[]>([])

  const step = useStep()
  const proposal = useProposal()

  useLayoutEffect(() => {
    function onFill () {
      try {
        const formData = Object.assign({}, step.form) || {}

        if (formData.ownership) setIsOwned(formData.ownership)
        if (Number(formData.propertyVacancies) > 0) setHasVacancies(true)
        if (formData.hasCoOwnership) setCoOwenership(formData.hasCoOwnership)
        if (formData.hasStorage) setHasStorage(formData.hasStorage)
        if (formData.isFinancialOperation) setIsFinancialOperation(formData.isFinancialOperation)
        if (formData.coOwners) setCoOwners(formData.coOwners)
        if (formData.isPartnerProperty) setPartnerProperty(formData.isPartnerProperty)
        if (formData.hasDismemberedBox) setHasDismemberedBox(formData.hasDismemberedBox)
        if (formData.isPropertyCondominium) setIsPropertyCondominium(formData.isPropertyCondominium)
        if (formData.hasAutonomousVacancies) setHasPropertyVacancies(formData.hasAutonomousVacancies)
      } catch (err) {
        swal.basic({ title: 'Atenção!', text: err.message, icon: 'warning' })
      }
    }

    onFill()

    const formatToLabelValue = (partner: { _id: string, name: string }) => ({
      value: partner._id,
      label: partner.name
    })

    async function fetchPartners () {
      if (!proposal.id) return

      try {
        const response = await resources.partners({ proposalId: proposal.id })
        const partners = response.data?.partners || []

        setPartners(partners.map(formatToLabelValue))
      } catch (err) {
        console.warn(err)
      }
    }

    fetchPartners()
  }, [])

  return (
    <Fragment>
      <Address
        onCepBlur={(address, remainingAddress) => formRef.setFieldsValue({ propertyAddress: { ...address, ...remainingAddress } })}
        isProperty={true}
        formRef={formRef}
        name="property"
        title="Endereço do imóvel"
        errors={errors}
        readOnlyInput={readOnlyInput}
        extraFields={
          <Fragment>
            <Divider />

            <Col span={24}>
              <Row gutter={[15, 0]} align="top">
                <Col span={24}>
                  <Typography.Title className="form-section-title form-section-title--primary" level={4}>
                      Condomínio
                  </Typography.Title>
                </Col>

                <Col>
                  <Form.Item
                    id="test-checkbox-PropertyCondominium"
                    name='isPropertyCondominium'
                    valuePropName="checked"
                    help={errors.isPropertyCondominium && (errors.isPropertyCondominium)}
                    validateStatus={errors.isPropertyCondominium && ('error')}
                    label={<label></label>}
                  >
                    <Checkbox
                      id="test-checkbox-dismemberedBox"
                      disabled={readOnlyInput}
                      onChange={(value: CheckboxChangeEvent) => setIsPropertyCondominium(value.target.checked)}
                    >
                        Imóvel em condomínio
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Row gutter={[15, 15]}>
              {isPropertyCondominium &&
                  <>
                    <Col lg={8} sm={12} xs={24}>
                      <Form.Item
                        name='propertyCondominium'
                        label={<label>Condomínio: </label>}
                        help={errors.propertyCondominium && (errors.propertyCondominium)}
                        validateStatus={errors.propertyCondominium && ('error')}
                      >
                        <Input
                          disabled={readOnlyInput}
                          placeholder="Digite o condomínio."
                        />
                      </Form.Item>
                    </Col>

                    <Col lg={8} sm={12} xs={24}>
                      <Form.Item
                        name='propertyUnity'
                        label={<label>Unidade: </label>}
                        help={errors.propertyUnity && (errors.propertyUnity)}
                        validateStatus={errors.propertyUnity && ('error')}
                      >
                        <Input
                          type="number"
                          disabled={readOnlyInput}
                          placeholder="Digite a unidade."
                        />
                      </Form.Item>
                    </Col>

                    <Col lg={8} sm={12} xs={24}>
                      <Form.Item
                        name='propertyTower'
                        label={<label>Torre: </label>}
                        help={errors.propertyTower && (errors.propertyTower)}
                        validateStatus={errors.propertyTower && ('error')}
                      >
                        <Input
                          disabled={readOnlyInput}
                          placeholder="Digite a torre."
                        />
                      </Form.Item>
                    </Col>

                    <Col lg={8} sm={12} xs={24}>
                      <Form.Item
                        id="test-checkbox-hasStorage"
                        className="form-user-checkbox"
                        name='hasStorage'
                        valuePropName="checked"
                        label={<label></label>}
                        help={errors.hasStorage && (errors.hasStorage)}
                        validateStatus={errors.hasStorage && ('error')}
                      >
                        <Checkbox
                          id="test-checkbox-hasstorage"
                          disabled={readOnlyInput}
                          onChange={(value: CheckboxChangeEvent) => setHasStorage(value.target.checked)}
                        >
                          Possuo depósito
                        </Checkbox>
                      </Form.Item>
                    </Col>

                    {hasStorage && (
                      <Col lg={8} sm={12} xs={24}>
                        <Form.Item
                          id="test-checkbox-isStorageAutonomous"
                          className="form-user-checkbox"
                          name='isStorageAutonomous'
                          valuePropName="checked"
                          label={ <Tooltip
                            destroyTooltipOnHide={{ keepParent: false }}
                            color="white"
                            placement="right"
                            title={<label className="color-black">Depósito autônomo é aquele que possui matrícula própria no registro de imóveis.</label>}
                          >
                            <label>Ajuda: <InfoCircleOutlined className="simulation-info" style={{ color: '#00441F' }} /></label>
                          </Tooltip>}
                        >
                          <Checkbox
                            id="test-checkbox-isStorageAutonomous"
                            disabled={readOnlyInput}
                          >
                            Depósito autônomo?
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    )}

                    <Col lg={8} sm={12} xs={24}>
                      <Form.Item
                        name='propertyVacancies'
                        label={<label>Vagas: </label>}
                        help={errors.propertyVacancies && (errors.propertyVacancies)}
                        validateStatus={errors.propertyVacancies && ('error')}
                      >
                        <Input
                          type="number"
                          disabled={readOnlyInput}
                          placeholder="Digite a quantidade de vagas."
                          min={0}
                          onChange={(e) => {
                            if (Number(e.target.value) > 0) {
                              if (!hasVacancies) setHasVacancies(true)
                            } else {
                              if (hasVacancies) setHasVacancies(false)
                            }
                          }}
                        />
                      </Form.Item>
                    </Col>

                    {hasVacancies && (
                      <>
                        <Divider />

                        <Col span={24}>
                          <Row gutter={[15, 0]} align="top">
                            <Col span={24}>
                              <Typography.Title className="form-section-title form-section-title--primary" level={4}>
                                Vagas autônomas

                                <Tooltip
                                  destroyTooltipOnHide={{ keepParent: false }}
                                  className="ml-2"
                                  color="white"
                                  placement="right"
                                  title={<label className="color-black">Vaga autônoma é aquela que possui matrícula própria no cartório de registro de imóveis.</label>}
                                >
                                  <label><InfoCircleOutlined className="simulation-info" style={{ color: '#00441F' }} /></label>
                                </Tooltip>
                              </Typography.Title>
                            </Col>

                            <Col lg={12} xs={24}>
                              <Form.Item
                                id="test-checkbox-autonomousvacancies"
                                name='hasAutonomousVacancies'
                                valuePropName="checked"
                                help={errors.isOwned && (errors.isOwned)}
                                validateStatus={errors.isOwned && ('error')}
                                label={<label></label>}
                              >
                                <Checkbox
                                  id="test-checkbox-autonomousvacancies"
                                  disabled={readOnlyInput}
                                  onChange={(value: CheckboxChangeEvent) => setHasPropertyVacancies(value.target.checked)}
                                >
                                  Possui Vaga(s) autônomas
                                </Checkbox>
                              </Form.Item>
                            </Col>

                            {hasPropertyVacancies && (
                              <Col lg={8} sm={12} xs={24}>
                                <Form.Item
                                  name='autonomousVacanciesValues'
                                  label={<label>Rendimento total das vagas:</label>}
                                  help={errors.autonomousVacanciesValues && (errors.autonomousVacanciesValues)}
                                  validateStatus={errors.autonomousVacanciesValues && ('error')}
                                >
                                  <InputMoney
                                    id="test-form-item-autonomousVacanciesValues"
                                    readOnly={readOnlyInput}
                                    name='autonomousVacanciesValues'
                                    placeholder='Digite o valor total'
                                  />
                                </Form.Item>
                              </Col>
                            )}
                          </Row>
                        </Col>
                      </>
                    )}
                  </>
              }
              <Divider />

              <Col span={24}>
                <Row gutter={[15, 0]} align="top">
                  <Col span={24}>
                    <Typography.Title className="form-section-title form-section-title--primary" level={4}>
                      Box desmembrados
                    </Typography.Title>
                  </Col>

                  <Col lg={12} xs={24}>
                    <Form.Item
                      id="test-checkbox-dismemberedBox"
                      name='hasDismemberedBox'
                      valuePropName="checked"
                      help={errors.isOwned && (errors.isOwned)}
                      validateStatus={errors.isOwned && ('error')}
                      label={<label></label>}
                      className='mb-0'
                    >
                      <Checkbox
                        id="test-checkbox-dismemberedBox"
                        disabled={readOnlyInput}
                        onChange={(value: CheckboxChangeEvent) => setHasDismemberedBox(value.target.checked)}
                      >
                        Possui box desmembrados
                      </Checkbox>
                    </Form.Item>
                  </Col>

                  {hasDismemberedBox && (
                    <Col lg={12} xs={24}>
                      <Form.Item
                        name='dismemberedBoxValue'
                        label={<label>Rendimento total das box desmembradas:</label>}
                        className='mb-0'
                        help={errors.dismemberedBoxValue && (errors.dismemberedBoxValue)}
                        validateStatus={errors.dismemberedBoxValue && ('error')}
                      >
                        <InputMoney
                          readOnly={readOnlyInput}
                          name='dismemberedBoxValue'
                          placeholder='Digite o valor total'
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Col>

              <Divider />

              <Col lg={8} sm={12} xs={24}>
                <Form.Item
                  name='ownership'
                  label={<label>Propriedade do imóvel:</label>}
                  help={errors.ownership && (errors.ownership)}
                  validateStatus={errors.ownership && ('error')}
                >
                  <Select
                    id="test-form-select-ownership"
                    placeholder='Escolha de quem é a propriedade do imóvel'
                    disabled={readOnlyInput}
                    onChange={(e: 'PROPRIA' | 'DETERCEIROS' | '') => setIsOwned(e.toString())}
                  >
                    <Select.Option id="test-form-select-ownership-propria" value="PROPRIA">Própria</Select.Option>
                    <Select.Option id="test-form-select-ownership-deterceiros" value="DETERCEIROS">De terceiro</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              {(isOwned && isOwned === 'PROPRIA') &&
                <Fragment>
                  <Col lg={12} sm={12} md={12} xs={24}>
                    <Form.Item
                      id="test-checkbox-coownership"
                      className="form-user-checkbox"
                      name="hasCoOwnership"
                      valuePropName="checked"
                      label={<label></label>}
                      help={errors.coOwnership && (errors.coOwnership)}
                      validateStatus={errors.coOwnership && ('error')}
                    >
                      <Checkbox
                        id="test-checkbox-coownership"
                        disabled={readOnlyInput}
                        onChange={(value: CheckboxChangeEvent) => setCoOwenership(value.target.checked)}
                      >
                        Constam outros proprietários na matrícula
                      </Checkbox>
                    </Form.Item>
                  </Col>

                  {coOwnership &&
                    <Fragment>
                      <Col lg={8} sm={12} xs={24}>
                        <Form.Item
                          name='numberOfCoOwners'
                          label={<label>Quantas proprietários além do solicitante</label>}
                          help={errors.numberOfCoOwners && (errors.numberOfCoOwners)}
                          validateStatus={errors.numberOfCoOwners && ('error')}
                        >
                          <InputNumber
                            min={0}
                            max={4}
                            type="number"
                            className="w-100"
                            disabled={readOnlyInput}
                            placeholder="Quantas pessoas além do solicitante"
                          />
                        </Form.Item>
                      </Col>

                      <Col lg={8} sm={12} xs={24}>
                        <Form.Item
                          name='numberOfCoOwnersComposeIncome'
                          label={<label>Quantos proprietários irão compor renda</label>}
                          help={errors.numberOfCoOwnersComposeIncome && (errors.numberOfCoOwnersComposeIncome)}
                          validateStatus={errors.numberOfCoOwnersComposeIncome && ('error')}
                        >
                          <InputNumber
                            min={0}
                            max={4}
                            type="number"
                            className="w-100"
                            disabled={readOnlyInput}
                            placeholder="Quantos proprietários irão compor renda"
                          />
                        </Form.Item>
                      </Col>
                    </Fragment>
                  }
                </Fragment>
              }

              {(isOwned && isOwned === 'DETERCEIROS') && (
                <PropertyFormOwner
                  formRef={formRef}
                  coOwners={coOwners}
                  formErrors={errors as ICoOwnersFormErrors}
                  readOnlyInput={!!readOnlyInput}
                />
              )}

              {(isOwned && isOwned === 'DETERCEIROS' && proposal.isPj) && (
                <Fragment>
                  <Col lg={8} sm={12} xs={24}>
                    <Form.Item
                      id="test-checkbox-isFamilyProperty"
                      className="form-user-checkbox"
                      name='isFamilyProperty'
                      valuePropName="checked"
                      tooltip='Único imóvel na Declaração do imposto de renda de pessoa física.'
                      label={<label>Ajuda:</label>}
                      help={errors.isFamilyProperty && (errors.isFamilyProperty)}
                      validateStatus={errors.isFamilyProperty && ('error')}
                    >
                      <Checkbox
                        id="test-checkbox-isFamilyProperty"
                        disabled={readOnlyInput}
                        onChange={(value: CheckboxChangeEvent) => {
                          if (value.target.checked) swal.basic({ title: 'Atenção', icon: 'warning', text: 'Não é permitido bem de família em contratações via pessoa jurídica.' })
                        }}
                      >
                        O imóvel é bem de família (lei 8009/90)
                      </Checkbox>
                    </Form.Item>
                  </Col>

                  <Col lg={8} sm={12} xs={24}>
                    <Form.Item
                      id="test-checkbox-isFamilyProperty"
                      className="form-user-checkbox"
                      name='isPartnerProperty'
                      valuePropName="checked"
                      label={<label></label>}
                      help={errors.isPartnerProperty && (errors.isPartnerProperty)}
                      validateStatus={errors.isPartnerProperty && ('error')}
                    >
                      <Checkbox
                        id="test-checkbox-isPartnerProperty"
                        disabled={readOnlyInput}
                        onChange={(value: CheckboxChangeEvent) => setPartnerProperty(value.target.checked)}
                      >
                        O imóvel pertence a um dos sócios
                      </Checkbox>
                    </Form.Item>
                  </Col>

                  {partnerProperty && (
                    <Col lg={8} sm={12} xs={24}>
                      <Form.Item
                        name='partnerOwnerName'
                        label={<label>Sócios cadastrados:</label>}
                        help={errors.partnerOwnerName && (errors.partnerOwnerName)}
                        validateStatus={errors.partnerOwnerName && ('error')}
                      >
                        <Select
                          id="test-form-select-partnerOwnerName"
                          placeholder='Escolha o sócio dono do imóvel.'
                          disabled={readOnlyInput}
                          allowClear
                        >
                          {partners && partners.length && partners.map(item => (
                            <Select.Option
                              key={item.value}
                              value={item.value}
                            >
                              {item.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                </Fragment>
              )}

              <Divider />

              <Col span={24}>
                <Row gutter={[30, 15]} align="top">
                  <Col span={24}>
                    <Typography.Title className="form-section-title form-section-title--primary" level={4}>Operação financeira</Typography.Title>
                  </Col>

                  <Col lg={12} xs={24}>
                    <Form.Item
                      id="test-checkbox-isFinancialOperation"
                      name='isFinancialOperation'
                      valuePropName="checked"
                      help={errors.isFinancialOperation && (errors.isFinancialOperation)}
                      validateStatus={errors.isFinancialOperation && ('error')}
                      label={<label></label>}
                      className='mb-0'
                    >
                      <Checkbox
                        id="test-checkbox-isFinancialOperation"
                        disabled={readOnlyInput}
                        onChange={(value: CheckboxChangeEvent) => setIsFinancialOperation(value.target.checked)}
                      >
                        O imóvel é garantia de alguma operação financeira
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              {isFinancialOperation && (
                <Fragment>
                  <Col lg={8} sm={12} xs={24}>
                    <Form.Item
                      name={'financialInstitution'}
                      label={<label>Instituição finaceira:</label>}
                      help={errors.financialInstitution && (errors.financialInstitution)}
                      validateStatus={errors.financialInstitution && ('error')}
                    >
                      <Input
                        readOnly={readOnlyInput}
                        placeholder={'Digite a instituição financeira'}
                        error={errors.financialInstitution}
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={8} sm={12} xs={24}>
                    <Form.Item
                      name='outstandingBalance'
                      label={<label>Saldo devedor (aproximado):</label>}
                      help={errors.outstandingBalance && (errors.outstandingBalance)}
                      validateStatus={errors.outstandingBalance && ('error')}
                    >
                      <InputMoney
                        readOnly={readOnlyInput}
                        name='income'
                        placeholder='Digite o valor aproximado do saldo devedor'
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={8} sm={12} xs={24}>
                    <Form.Item
                      name={'managerName'}
                      label={<label>Nome do gerente:</label>}
                      help={errors.managerName && (errors.managerName)}
                      validateStatus={errors.managerName && ('error')}
                    >
                      <Input
                        readOnly={readOnlyInput}
                        placeholder={'Digite o nome do gerente'}
                        error={errors.managerName}
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={8} sm={12} xs={24}>
                    <Form.Item
                      name='managerPhone'
                      label={<label>Telefone do gerente: </label>}
                      help={errors.managerPhone && (errors.managerPhone)}
                      validateStatus={errors.managerPhone && ('error')}
                    >
                      <Input
                        disabled={readOnlyInput}
                        placeholder="Digite o telefone do gerente"
                        mask="phone"
                      />
                    </Form.Item>
                  </Col>
                </Fragment>
              )}
            </Row>
          </Fragment>
        }
      />
    </Fragment>
  )
}

export default PropertyForm

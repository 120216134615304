import { ColumnsType } from 'antd/lib/table'
import format from 'utils/format'
import { timeAsDayjs } from 'utils/time'

const columns: ColumnsType<Object> = [
  {
    title: 'Período',
    dataIndex: 'id'
  },
  {
    title: 'Vencimento',
    dataIndex: 'dueDate',
    render: value => timeAsDayjs(value, { applyTimezone: false }).format('DD/MM/YYYY')
  },
  {
    title: 'Saldo devedor',
    dataIndex: 'debt',
    render: value => format.formatBRL(value)
  },
  {
    title: 'Amortização',
    dataIndex: 'amortization',
    render: value => format.formatBRL(value)
  },
  {
    title: 'Juros',
    dataIndex: 'interest',
    render: value => format.formatBRL(value)
  },
  {
    title: 'Seguro prestamista',
    dataIndex: 'prestamista',
    render: value => format.formatBRL(value)
  },
  {
    title: 'Seguro DFI',
    dataIndex: 'dfi',
    render: value => format.formatBRL(value)
  },
  {
    title: 'Parcela total',
    dataIndex: 'totalParcel',
    render: value => format.formatBRL(value)
  }
]

export default columns

import { useAuth } from 'hooks'
import { ECP } from 'routes/ecp/constants'
import { RouterDomPath } from 'store/modules/auth/authTypes'

function useFindPath () {
  const auth = useAuth()

  function findPath (route: typeof ECP['CLIENTS']) {
    return auth?.routerDomPaths?.find?.((item: RouterDomPath) => item.path === route.path)
  }

  return {
    findPath
  }
}

export default useFindPath

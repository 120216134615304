import { ArrowLeftOutlined } from '@ant-design/icons'
import ReviewHistories from 'components/ReviewBackoffice/ReviewHistories'
import { StepSectionTitle } from 'egi/app/ProposalEgi/components/StepRenders/StepRenders'
import { useStep } from 'hooks'
import React, { useEffect, useState } from 'react'

interface IBackofficeHistoryProps {
  updateHistory?: boolean
  resetFields: () => void
}

function ReviewBackofficeHistory ({ updateHistory, resetFields }: IBackofficeHistoryProps) {
  const [showHistories, setShowHistories] = useState<boolean>(false)
  const step = useStep()

  useEffect(() => {
    resetFields()
  }, [step._id, showHistories])

  return (
    <div className="mt-3 review-backoffice-card">
      <div className='review-backoffice-card__histories-container'>
        <div className="flex flex-align-center">
          {!showHistories && (
            <section className='review-backoffice-history__header'>
              <StepSectionTitle title="Última Revisão"/>

              <p
                className='review-backoffice-history__action'
                onClick={() => setShowHistories(prev => !prev)}
              >
                Ver histórico
              </p>
            </section>
          )}

          {showHistories && (
            <section className='review-backoffice-history__header'>
              <StepSectionTitle title='Histórico do Passo'/>

              <div className="review-backoffice-history__action"
                onClick={() => setShowHistories(prev => !prev)}
              >
                <ArrowLeftOutlined size={30} className='mr-2'/>

                <span>Voltar</span>
              </div>
            </section>
          )}
        </div>

        <ReviewHistories
          showAll={showHistories}
          updateHistory={updateHistory}
        />

      </div>
    </div>
  )
}

export default ReviewBackofficeHistory

import React from 'react'
import { Button } from 'antd'
import { TokenMethodEnum } from '../../TokenValidator'

type Props = {
  onSelectMethod: (method: TokenMethodEnum) => void
}

const TokenValidationMethod = ({ onSelectMethod }: Props) => {
  return (
    <div className="token-validation__method-container">
      <div className="token-validation__description">
        <p>Escolha uma forma de validação</p>
      </div>

      <Button
        className="token-validation__button mb-3"
        type="primary"
        onClick={() => onSelectMethod(TokenMethodEnum.sms)}
      >
        Celular
      </Button>

      <Button
        className="token-validation__button token-validation__button--email-button"
        type="primary" onClick={() => onSelectMethod(TokenMethodEnum.email)}
      >
        E-mail
      </Button>
    </div>
  )
}

export default TokenValidationMethod

import React, { ReactNode } from 'react'
import { useInternalChangeEmailValidate, useInternalChangeEmailVerify } from './hooks/useInternalChangeEmail'
import { UnauthLayout } from 'layouts'

function InternalChangeEmail ({ title, text, icon }: { title: string, text: string, icon: ReactNode }) {
  return (
    <UnauthLayout>
      <UnauthLayout.Container>
        <UnauthLayout.IconContainer icon={icon}/>
        <div className='text-center'>
          <UnauthLayout.Title title={title}/>
          <UnauthLayout.TextContainer >{text}</UnauthLayout.TextContainer>
        </div>
        <UnauthLayout.ButtonContainer>
          <UnauthLayout.BackToLogin text='' />
        </UnauthLayout.ButtonContainer>
      </UnauthLayout.Container>
    </UnauthLayout>
  )
}

function InternalChangeEmailVerify () {
  const { title, text, icon } = useInternalChangeEmailVerify()
  return <InternalChangeEmail
    title={title}
    text={text}
    icon={icon}
  />
}

function InternalChangeEmailValidate () {
  const { title, text, icon } = useInternalChangeEmailValidate()

  return <InternalChangeEmail
    title={title}
    text={text}
    icon={icon}
  />
}

export {
  InternalChangeEmailVerify,
  InternalChangeEmailValidate
}

import React from 'react'
import { Tag } from 'antd'
import translate from 'utils/translate'
import { IStep } from 'egi/types'
import { colors } from 'styles/colors'
import ProposalEgiModel from 'egi/models/ProposalEgiModel'
import { ExpirationDateTag } from '../ExpirationDateTag/ExpirationDateTag'

const cndTagColor = {
  APPROVED: colors.approved,
  REPROVED: colors.reproved,
  IN_ATTENTION: colors.remaking,
  CERTIFICATE_GENERATION_INFO: colors.remaking,
  NO_DATA_FOUND_INFO: colors.reproved
}

function CndTagStatus ({ step }: { step: IStep }) {
  if (step?.automaticCNDStatus) {
    return (
      <Tag
        className="cnd-tag"
        color={colors.canceled}
      >
        {step?.automaticCNDStatus}
      </Tag>
    )
  }

  if (step.cnd && step.cnd.requestAnalysisStatus) {
    return (
      <Tag
        className="mt-1 ml-1"
        color={cndTagColor[step.cnd.requestAnalysisStatus]}
      >
        {translate.cndTagStatus(step.cnd.requestAnalysisStatus)}
      </Tag>
    )
  }

  return null
}

function convertToAmericanPattern (dateString: string | null): string | null {
  if (!dateString) return null

  const parts = dateString.split('/')

  if (parts.length === 3) {
    const day = parts[0]
    const month = parts[1]
    const year = parts[2]

    // Rearrange in American pattern (month/day/year)
    return `${month}/${day}/${year}`
  } else {
    return null // Return null for invalid date format
  }
}

export function CndTagExpirationDate ({
  step
}: { step: IStep }) {
  const expirationDate = ProposalEgiModel.getCndExpirationDateFromObject(step?.form)
  const expirationDateAutomatic = convertToAmericanPattern(ProposalEgiModel.getCurrentCndExpirationDate(step?.cnd))

  return (
    <ExpirationDateTag
      expirationDate={expirationDate || expirationDateAutomatic}
    />
  )
}

export default CndTagStatus

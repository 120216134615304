import React from 'react'
import { SolutionOutlined, TeamOutlined } from '@ant-design/icons'
import { IRoute } from 'components/Sidebar/types'
import ProposalsListEgiOld from 'egi/app/ProposalEgi/views/ProposalListEgi/ProposalListEgi'
import TelemarketingAdminList from 'egi/app/Telemarketing/views/TelemarketingAdminList/TelemarketingAdminList'
import TelemarketingList from 'egi/app/Telemarketing/views/TelemarketingList/TelemarketingList'
import ProposalsListEgiTwo from 'egi/app/ProposalEgi2/views/ProposalListEgi/ProposalListEgi'
import { EGI } from 'routes/egi/paths'

const leaders: IRoute = {
  path: '/telemarketing/leaders',
  name: 'Administradores',
  icon: TeamOutlined,
  component: TelemarketingAdminList,
  meta: {
    centered: false,
    hidden: false,
    canGoBack: false,
    disabled: false
  }
}

const collaborators: IRoute = {
  path: '/telemarketing/collaborators',
  name: 'Funcionários',
  icon: TeamOutlined,
  component: TelemarketingList,
  meta: {
    centered: false,
    hidden: false,
    canGoBack: false,
    disabled: false
  }
}

const proposalsReminded: IRoute = {
  path: '/proposals/list/reminded-old',
  name: 'Tombadas',
  icon: SolutionOutlined,
  component: () => <ProposalsListEgiOld reminded={true} />,
  meta: {
    centered: false,
    hidden: true,
    canGoBack: false,
    disabled: false
  }
}

const proposalsRemindedTwo: IRoute = {
  path: EGI.PROPOSAL_LIST_REMIDEND_TWO.path,
  name: EGI.PROPOSAL_LIST_REMIDEND_TWO.name,
  icon: SolutionOutlined,
  component: () => <ProposalsListEgiTwo reminded={true} />,
  meta: {
    centered: false,
    hidden: false,
    canGoBack: false,
    disabled: false
  }
}

const proposalsList = {
  path: '/proposals/list-old',
  name: 'Não tombadas',
  icon: SolutionOutlined,
  component: () => <ProposalsListEgiOld reminded={false} />,
  meta: {
    centered: false,
    hidden: true,
    canGoBack: false,
    disabled: false
  }
}

const proposalsListTwo = {
  path: EGI.PROPOSAL_LIST_TWO.path,
  name: 'Não tombadas',
  icon: SolutionOutlined,
  component: () => <ProposalsListEgiTwo reminded={false} />,
  meta: {
    centered: false,
    hidden: false,
    canGoBack: false,
    disabled: false
  }
}

const telemarketingTeam = {
  leaders,
  collaborators,
  proposalsReminded,
  proposalsRemindedTwo,
  proposalsListTwo,
  proposalsList
}

export {
  telemarketingTeam
}

import React, { Fragment, useState, useLayoutEffect } from 'react'
import { Form, Input, Select } from 'antd'
import translate from 'utils/translate'
import SelectTypeInvite from '../SelectTypeInvite'
import { useAuth } from 'hooks'
import { resources } from 'egi/consumers'
import { PercentageInput } from 'components'
import ExampleCommissionTable from 'components/ExampleCommissionTable/ExampleCommissionTable'
import format from 'utils/format'
import { validateResponse } from 'utils/validate'
import { _typePromoterEnum, _userLevel } from 'ecp/models/UsersModel'
import { _promoterChannelEnum } from 'globals'
import { channelOptions } from './constants'
import InviteEgiRepository, { InviteEgiErrors } from 'egi/repositories/InviteEgiRepository'

interface IInvitePromoter {
  level: _userLevel
  invitedLevel?: string
  specificType?: _typePromoterEnum[]
  onSuccess: (id: string, token: string, name?: string) => void
  onFailed: (messsage: string) => void
}

interface IGenerateInvite {
  commissionPercentage: number
  name: string
  type?: _typePromoterEnum,
  channel: _promoterChannelEnum
}

function InvitePromoter ({ level, invitedLevel, specificType, onSuccess, onFailed }: IInvitePromoter) {
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<{ commissionPercentage?: string, name?: string }>({})
  const [currentValue, setCurrentValue] = useState<string | number | null | undefined>(100)
  const [type, setType] = useState<string>('')
  const [promoterType, setPromoterType] = useState<Array<{ label: string, value: _typePromoterEnum }>>([])

  const [form] = Form.useForm()
  const user = useAuth()
  const IS_PROMOTER = user.level === 'promoter'

  useLayoutEffect(() => {
    const abortController = new AbortController()
    const getPromoterLevel = async () => {
      try {
        const response = await resources.promoterType({ signal: abortController.signal })
        const types: Array<{ value: _typePromoterEnum, label: string }> = response.data.types

        if (specificType) {
          const newType = types.filter(item => specificType.includes(item.value))
          setPromoterType(newType)
          return
        }

        setPromoterType(types)
      } catch (err) {
        console.warn(err)
      }
    }

    getPromoterLevel()
    return () => abortController.abort()
  }, [])

  const generateInvite = async (values: IGenerateInvite) => {
    if (IS_PROMOTER) {
      values.commissionPercentage = 100
      values.type = user.type
      if (user.channel) values.channel = user.channel
    }

    if (values.commissionPercentage) values.commissionPercentage = format.percentageToDecimal(values.commissionPercentage)

    setLoading(true)
    setErrors({})
    try {
      const response = await InviteEgiRepository.levelLink<IGenerateInvite>(level, values)
      const { link, inviteId } = response.data.data || {}

      if (!inviteId) throw InviteEgiErrors.invite()
      if (!link) throw InviteEgiErrors.link()

      setLoading(false)
      onSuccess(link, inviteId, values.name)
    } catch (err) {
      if (err && err.data?.invalid) {
        let errors: { commissionPercentage?: string, name?: string } = {}
        errors = validateResponse(err.data.invalid)
        setErrors(errors)
        setLoading(false)
        return
      }

      setLoading(false)
      onFailed(err.message)
    }
  }

  return (
    <SelectTypeInvite
      level={level}
      onFinish={generateInvite}
      label={''}
      loading={loading}
      form={form}
      layout='flex-column w-75'

      formContent={
        <Fragment>
          {!IS_PROMOTER && (
            <Fragment>
              <div className='label-remove-before'>
                <Form.Item
                  label={<label className="px-2 bold">{`Selecione o tipo do ${translate.level('promoter')}:`}</label>}
                  name='type'
                  rules={[{ required: true, message: 'Selecione um tipo.' }]}
                >
                  <Select
                    placeholder={`Tipo do ${translate.level('promoter')}`}
                    onChange={(value: string) => setType(value)}
                    options={
                      promoterType.map((item: { value: string, label: string }) => ({
                        value: item.value,
                        label: format.capitalize(item.label)
                      }))
                    }
                  />
                </Form.Item>
              </div>

              <div className='label-remove-before'>
                <Form.Item
                  label={<label className="px-2 bold">{`Selecione o canal do ${translate.level('promoter')}:`}</label>}
                  name='channel'
                  rules={[{ required: true, message: 'Selecione um canal.' }]}
                >
                  <Select
                    placeholder={`Canal do ${translate.level('promoter')}`}
                    options={channelOptions}
                  />
                </Form.Item>
              </div>
            </Fragment>
          )}

          <Form.Item
            name="name"
            label={<label className="px-2 bold">Digite o nome do {invitedLevel}</label>}
            help={errors.name && (errors.name)}
            validateStatus={errors.name && ('error')}
          >
            <Input required placeholder={`Digite o nome do ${invitedLevel}`} />
          </Form.Item>

          {!IS_PROMOTER && (
            <Fragment>
              <Form.Item
                name="commissionPercentage"
                label={<label className="px-2 bold text-center">Digite a porcentagem da comissão do parceiro</label>}
                help={errors.commissionPercentage && (errors.commissionPercentage)}
                validateStatus={errors.commissionPercentage && ('error')}
              >
                <PercentageInput
                  defaultValue={100}
                  placeholder="Digite a comissão do parceiro"
                  onChange={(value) => setCurrentValue(value)}
                />
              </Form.Item>

              <ExampleCommissionTable
                currentValue={currentValue}
                promoterType={type}
              />
            </Fragment>
          )}

        </Fragment>
      }
    />
  )
}

export default InvitePromoter

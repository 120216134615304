import React, { ReactElement } from 'react'
import { Typography, Tag } from 'antd'
import { useDispatch } from 'react-redux'
import { drawerFillForms } from 'store/modules/drawer/actions'
import IDrawer from 'egi/types/IDrawer'
import { modalFillValues } from 'store/modules/modal/actions'
import ActionButton from './ActionButton'
import printContent from 'utils/print'

export interface IAction {
  _id: string
  type: 'button' | 'label' | 'link'
  title: string
  url?: string,
  icon?: string
  slug: string
}

export interface ActionProps {
  config: IAction
  departmentName: string
  status?: string
}

const { Text, Link } = Typography
function Action ({ config, departmentName, status }: ActionProps): ReactElement {
  let Component
  const dispatch = useDispatch()

  const uploadData: IDrawer = {
    type: 'upload',
    visible: true,
    submitButton: false,
    width: '50vw',
    title: config.title
  }

  const declineProposalData: IDrawer = {
    type: 'declineProposal',
    visible: true,
    submitButton: true,
    width: '50vw',
    title: config.title
  }

  const clientInstructionsData: IDrawer = {
    type: 'clientInstructions',
    visible: true,
    submitButton: false,
    width: '50vw',
    title: config.title
  }

  const actionControl = (slug: string): void => {
    switch (slug) {
      case 'defesa-credito':
        dispatch(drawerFillForms(uploadData))
        break
      case 'declinar-prestador-proposta':
        dispatch(drawerFillForms(declineProposalData))
        break
      case 'instrucoes-boleto':
        dispatch(drawerFillForms(clientInstructionsData))
        break
      case 'aceitar-credito':
        dispatch(modalFillValues({
          visible: true,
          className: 'modal-config-radius',
          title: 'Aceite de crédito',
          type: 'creditApprove',
          width: '50',
          hasFooter: false
        }))
        break
      case 'certidoes':
        dispatch(modalFillValues({
          visible: true,
          title: "Solicitação de CND'S",
          type: 'requestCnd',
          width: '50',
          hasFooter: false,
          departmentName: departmentName
        }))
        break
      case 'cet':
        printContent('cet-template')
        break
      default:
        break
    }
  }

  switch (config.type) {
    case 'button':
      Component = (
        <ActionButton
          slug={config.slug}
          status={status}
          title={config.title}
          icon={config.icon}
          actionControl={value => actionControl(value)}
        />
      )
      break
    case 'label':
      Component = <Tag>{config.title}</Tag>
      break
    case 'link':
      Component = <Link href={config.url} target="_blank"><Tag>{config.title}</Tag></Link>
      break
    default:
      <Text>{config.title}</Text>
      break
  }

  return <div>{Component}</div>
}

export default Action

import { _inputMask } from 'globals'

export function getMaxLength (mask?: _inputMask | string) {
  switch (mask) {
    case 'cpf':
      return 14
    case 'cnpj':
    case 'cpfcnpj':
      return 18
    case 'rg':
      return 16
    case 'phone':
      return 15
    case 'cell':
      return 15
    case 'phonewithoutddd':
      return 10
    case 'date':
      return 10
    case 'cep':
      return 9
    case 'cnh':
      return 15
    case 'agency':
      return 7
    case 'account':
      return 15
    case 'name':
    case 'money':
      return 80
    default:
      return 99
  }
}

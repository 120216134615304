
import { AxiosRequestConfig } from 'axios'
import { Repository } from 'ecp/repositories/Repository'
import api from 'services/api'

export type ICovenantECPF = {
  codigo: string,
  descricao: string
}

export interface IEmployerECPF {
  cargo?: string
  dataAdmissao?: string
  dataNascimento?: string
  documento: string
  email?: string
  nome: string
  numeroMatricula: string
}

export type IClientCovenantECPF = {
  convenios: ICovenantECPF[]
  funcionario: IEmployerECPF
  origens: {
    codigoEmpregador: string

    codigoPromotor?: string
    codigoMatriz?: string
    codigoRegional?: string
    codigoOrgao?: string
  }
  rendas: {
    tipoVinculo: string
    valorBruto: number
    valorLiquido: number
    margemTotalParcelada: number
    margemDisponivelParcelamento: number
    margemTotalCartao: number
    margemDisponivelCartao: number
  }
}

export type IEmployerCovenantECPF = {
  origens: {
    codigoEmpregador: string
    codigoPromotor: string
    codigoOrgao: string
  },
  convenios: [
    {
      codigo: string
      descricao: string
      grupoConvenio: string
    }
  ]
}

export interface ICovenantECPFLimit {
  idade: number
  valorMaximoFinanciado: number
  prazoMaximo: number
  valorRiscoMaximo: number
}

export interface ICovenantECPFLimits {
  codigo: string,
  descricao: string,
  tipoProdutoConvenio: string,
  empregador: {
    codigo: string,
    descricao: string,
    produto: null
  },
  limites: ICovenantECPFLimit[],
  margemConsignavel: {
    percentual: string
  },
  taxasEPrazos: {
    metodoParaCalculo: string,
    tipoDeCalculo: string,
    alteraPadraoTipoDeCalculo: boolean,
    faixaPrazo: {
      inicio: number,
      fim: number
    }[]
  }
}

class CovenantECPFRepository extends Repository<{}> {
  async queryClientCovenant (cpf: string, config?: AxiosRequestConfig) {
    return Repository.handle<{ result: IClientCovenantECPF }>(() =>
      this.api.get(`${this.path}/cliente/${cpf}`, config)
    )
  }

  async queryEmployerCovenant (cnpj: string, config?: AxiosRequestConfig) {
    return Repository.handle<{ result: IEmployerCovenantECPF }>(() =>
      this.api.get(`${this.path}/empregador/${cnpj}`, config)
    )
  }

  async fetchLimits (config?: AxiosRequestConfig) {
    return Repository.handle<{ result: { convenios: Array<ICovenantECPFLimits> } }>(() =>
      this.api.get(this.path, config)
    )
  }
}

export default new CovenantECPFRepository({ api, path: '/ecp-funcao/convenios' })

import { ResultStatusType } from 'antd/lib/result'

export const errorCode = (code: ResultStatusType) => {
  const isErrorTyped = code === '403' || code === '404' || code === '500' || code === 403 || code === 404 || code === 500

  if (code === 404) return 'warning'

  if (isErrorTyped) {
    return code
  }

  return 'error'
}

import React, { Fragment, useLayoutEffect, useState } from 'react'
import { ServerTable, ChangeUser } from 'components'
import { columns, ICommercial } from './dataTable'
import { useAuth, useTables } from 'hooks'
import { ColumnsType } from 'antd/lib/table'
import { IDrawer, ISelect } from 'egi/types'
import { useDispatch } from 'react-redux'
import { tablesSetFiltersValues, tablesUpdate } from 'store/modules/tables/actions'
import ButtonModel from 'components/SeverTable/ButtonModel'
import { DownloadOutlined, ExportOutlined, LoadingOutlined } from '@ant-design/icons'
import filters from 'components/SeverTable/IndexFilters'
import FilterCreditArea from 'components/SeverTable/FilterCreditArea'
import useDownloadNotification from 'hooks/useDownloadNotification'
import { makeMonthPeriod, timeAsDayjs } from 'utils/time'
import swal from 'utils/swal'
import Modal from 'antd/lib/modal/Modal'
import { Button, Form, message, Select, Spin } from 'antd'
import proposalList from './consumer'
import { IProposalColumns, ITableUser } from '../../proposalEgiInterfaces'
import ProposalEgiReportModal, { findProposalReport, proposalStatusesToArray } from '../../components/ProposalEgiReportModal/ProposalsReportModal'
import ProposalEgiRepository, { IProposalPromoterForm } from 'egi/repositories/ProposalEgiRepository'
import ProposalEgiModel from 'egi/models/ProposalEgiModel'
import { LimiterTable } from 'components/LimiterTable/LimiterTable'
import DepartmentSelect from '../../components/DepartmentSelect/DepartmentSelect'
import PromotersRepository from 'egi/repositories/PromotersRepository'

type Props = {
  mlm?: boolean,
  reminded?: boolean,
  needVinculation?: boolean
  hasAction?: boolean | string
}

function ProposalsListEgiOld ({ mlm, reminded, needVinculation, hasAction }: Props) {
  const [finalColumns, setFinalColumns] = useState<ColumnsType<Object>>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false)
  const [proposalId, setProposalId] = useState<string>()
  const [tableUser, setTableUser] = useState<ITableUser>()
  const [userlevel, setUserLevel] = useState<string>('')
  const [loadingSubmmit, setLoadingSubmit] = useState<boolean>()
  const [showReportModal, setShowReportModal] = useState<boolean>(false)
  const [visibleModalCommercial, setVisibleModalCommercial] = useState<boolean>(false)
  const [visibleModalPromoter, setVisibleModalPromoter] = useState<boolean>(false)
  const [proposalPromoterLoading, setProposalPromoterLoading] = useState(false)
  const [proposalPromoterSubmitLoading, setProposalPromoterSubmitLoading] = useState(false)
  const [proposalPromoterInfo, setProposalPromoterInfo] = useState<{ selectedUser?: string, proposalCode?: number }>({})
  const [subPromotersList, setSubPromotersList] = useState<Array<ISelect>>([])
  const [commercialInfo, setCommercialInfo] = useState<ICommercial>()

  const user = useAuth()
  const { requestDownload, isDownloading } = useDownloadNotification('prognumDocs')
  const dispatch = useDispatch()
  const tables = useTables()
  const abortController = new AbortController()
  const canShowDepartmentFilter = ProposalEgiModel.canSeeDepartmentFilter(user.level, user.areaName)

  const IS_MASTER = user.level === 'master'

  const IS_TM = user.areaName === 'telemarketing' || user.type === 'TM'
  const IS_COMMERCIAL = user.level === 'commercial'

  const IS_PROMOTER_TM = user.level === 'promoter' && user.areaName === 'telemarketing'

  const hasProposalStatus = true
  const proposalsUrl = '/proposals'
  const csvFilename = 'propostas'

  const areaNameUser = user.areaName && user.areaName !== 'telemarketing'
  const canFilterByActionProposal = areaNameUser && user.level !== 'provider'
  const notInternal = user.level === 'seller' || !!(mlm)

  async function fetchAvailablePromoters (userId: string) {
    setProposalPromoterLoading(true)
    try {
      const reqParams = {
        signal: abortController.signal,
        params: { proposalId }
      }

      const response = await PromotersRepository.fetchSubPromoters(userId, reqParams)
      const { promoters } = response.data?.data || {}
      if (!promoters) throw new Error('Não foi possível obter a lista de sub-corbans')

      if (Array.isArray(promoters)) {
        const list: typeof subPromotersList = []
        promoters.map(item => {
          const type = item?.type ? `${item.type} - ` : ''
          const label = type + item.name
          return list.push({ label, value: item.id })
        })

        setSubPromotersList(list)
      }

      return setProposalPromoterLoading(false)
    } catch (err) {
      if (!abortController.signal.aborted) {
        if (err?.message) message.error(err.message)
      }
      setProposalPromoterLoading(false)
    }
  }

  function showModalChangeUser ({ user, level, proposalId }: { user: ITableUser, level: string, proposalId: string }) {
    setShowModal(true)
    setTableUser(user)
    setUserLevel(level)
    setProposalId(proposalId)
  }

  function showStatusChangeModal (proposalId: string) {
    setShowStatusModal(true)
    setProposalId(proposalId)
  }

  function showModalCommercial (commercial: ICommercial, leadId: string) {
    setVisibleModalCommercial(true)
    setCommercialInfo(commercial)
    setProposalId(leadId)
  }

  async function changeProposalStatus (id: string) {
    setLoadingSubmit(true)
    try {
      const response = await proposalList.setActiveStatus(id)
      swal.basic({ title: 'Sucesso!', text: response.message, icon: 'success' })
      setShowStatusModal(false)
      dispatch(tablesSetFiltersValues({ ...tables.filters }))
    } catch (error) {
      swal.basic({ title: 'Atenção!', text: error.message, icon: 'error' })
    } finally {
      setLoadingSubmit(false)
    }
  }

  const declineProposalDrawerInfo: IDrawer = {
    type: 'refuseProposal',
    visible: true,
    submitButton: true,
    width: '50vw',
    title: 'Cancelar proposta'
  }

  async function downloadPrognumReport () {
    try {
      if (!tables.filters.createdDate) throw new Error('Selecione a data de criação')
      const filterDate = (tables.filters.createdDate).split('-')
      const { firstDay, lastDay } = makeMonthPeriod({ year: Number(filterDate[1]), month: Number(filterDate[0]) })
      const response = await ProposalEgiRepository.createPrognum({
        startDate: timeAsDayjs(firstDay).format('YYYY-MM-DD'),
        endDate: timeAsDayjs(lastDay).format('YYYY-MM-DD'),
        onlyApproved: false
      })

      const prognumId = response.data.data?.docIds?.[0]
      requestDownload(() => ProposalEgiRepository.getPrognum({ params: { prognumId } }))
    } catch (err) {
      swal.basic({
        title: 'Atenção',
        text: err.message,
        icon: 'warning'
      })
    }
  }

  const handleChangePromoterModal = (proposalId?: string, proposalCode?: number, selectedUser?: string) => {
    setProposalId(proposalId ?? '')
    fetchAvailablePromoters(user._id)
    setProposalPromoterInfo({ selectedUser, proposalCode })
    setVisibleModalPromoter(true)
  }

  useLayoutEffect(() => {
    const finalParams: IProposalColumns = {
      showModalChangeUser,
      showStatusChangeModal,
      canRefuseProposal: ProposalEgiModel.canRefuse(user.level),
      notInternal,
      canEditCommercial: ProposalEgiModel.canEditCommercial(user.level, user.areaName),
      canSeeProviderColumns: ProposalEgiModel.canSeeProviderColumns(user.level, user.areaName),
      canSeeSLAColumns: ProposalEgiModel.canSeeSLAColumns(user.level),
      seeOnlyProviderDate: ProposalEgiModel.canSeeOnlyProviderDate(user.level, user.areaName),
      areaNameUser,
      declineProposalDrawerInfo,
      canEditTm: ProposalEgiModel.canEditTM(user.level, user.areaName),
      canSeeActionColumn: IS_MASTER,
      canSeeAnalysisCollum: ProposalEgiModel.canSeeAnalisys(user.areaName),
      canSeeClosedReminded: IS_TM && reminded,
      canSeeCommercialAttendant: ProposalEgiModel.canSeeCommercialAttendant(user.level, user.areaName) ?? false,
      canEditCommercialAttendant: ProposalEgiModel.canEditCommercialAttendant(user.level, user.areaName) ?? false,
      showModalCommercial,
      canSeeCommercialReminder: ProposalEgiModel.canSeeCommercialReminded(user.level, user.areaName, reminded) ?? false,
      canChangeProposalPromoter: ProposalEgiModel.canChangeProposalPromoter(user) ?? false,
      showChangePromoterModal: handleChangePromoterModal
    }

    if (!IS_PROMOTER_TM && reminded) finalParams.canSeeReminderTelemarketing = true
    if (!IS_TM && !reminded) finalParams.canSeeReminderTelemarketing = true

    const column = columns(finalParams)

    const columnsSplice = column.splice(column.length - 4)

    column.push(...columnsSplice)
    setFinalColumns(column)
  }, [])

  function canSeeFilters (): React.ReactElement[] {
    const addFilters: React.ReactElement[] = [
      filters().proposalStatus,
      filters().person,
      filters().expiredProposals,
      filters().lastTrimester
    ]

    if (ProposalEgiModel.canRefuse(user.level) && !ProposalEgiModel.canSeeProposalRemindedFilters(user, reminded)) { addFilters.push(filters().linkedBackoffice) }
    if (!ProposalEgiModel.canSeeProposalRemindedFilters(user, reminded)) { addFilters.push(filters().reminded) }
    if (ProposalEgiModel.canFilterByPromoter(user.level, user.areaName) && !ProposalEgiModel.canSeeProposalRemindedFilters(user, reminded)) { addFilters.push(filters().promoter.promoterId) }
    if (ProposalEgiModel.canFilterBySeller(user.level, user.type)) { addFilters.push(filters().seller) }
    if (ProposalEgiModel.canFilterByProvider(user.level, user.areaName) && !ProposalEgiModel.canSeeProposalRemindedFilters(user, reminded)) { addFilters.push(filters().provider) }
    if (ProposalEgiModel.canSeeAnalisys(user.areaName)) { addFilters.push(filters().analysis) }

    if ((canFilterByActionProposal || IS_COMMERCIAL) && !ProposalEgiModel.canSeeProposalRemindedFilters(user, reminded)) {
      addFilters.push(filters().actionProposal)
    }

    if (IS_MASTER) { addFilters.push(filters().areasAction) }
    if ((ProposalEgiModel.canSeeProposalRemindedFilters(user, reminded))) { addFilters.push(filters().openContacts) }
    addFilters.push(filters().lastArea)

    if (tables.filters.lastArea && tables.filters.lastArea === 'credito') {
      addFilters.push(
        <FilterCreditArea
          onChange={(value) => [
            dispatch(tablesSetFiltersValues({ ...tables.filters, clientApprovalStatus: value }))
          ]}
          key='clientApprovalStatus'
        />
      )
    }

    return addFilters
  }

  function allButtons (): React.ReactElement[] {
    const addButtons: React.ReactElement[] = [
      <ButtonModel
        onClick={() => {
          const hasSlowStatusReport =
            !tables.filters.proposalStatus ||
            !tables.filters.proposalStatus.length ||
            findProposalReport(proposalStatusesToArray(String(tables.filters.proposalStatus)))

          if (hasSlowStatusReport) setShowReportModal(true)
          else dispatch(tablesSetFiltersValues({ ...tables.filters, download: true }))
        }}
        text='Gerar relatório'
        icon={<DownloadOutlined className="server-table-filters__icon-button-model" />}
        key={'download'}
        disabled={tables.ignoreAll}
      />
    ]

    if (ProposalEgiModel.canChooseReportType(user.level, user.areaName)) {
      addButtons.push(
        <ButtonModel
          onClick={() => dispatch(tablesSetFiltersValues({ ...tables.filters, downloadSignatures: true }))}
          text='Relatório de assinatura'
          icon={<ExportOutlined className="server-table-filters__icon-button-model" />}
          key={'downloadSignatures'}
        />
      )
    }

    if (ProposalEgiModel.canDownloadPrognumReport(user.level, user.areaName)) {
      addButtons.push(
        <ButtonModel
          onClick={() => downloadPrognumReport()}
          text='Relatório Prognum'
          icon={isDownloading ? <LoadingOutlined className="server-table-filters__icon-button-model" /> : <ExportOutlined className="server-table-filters__icon-button-model" />}
          key={'downloadPrognumReport'}
        />
      )
    }

    return addButtons
  }

  function handlePromoterModalCancel () {
    return setVisibleModalPromoter(false)
  }

  async function handlePromoterChange (values: IProposalPromoterForm) {
    setProposalPromoterSubmitLoading(true)
    try {
      const response = await ProposalEgiRepository.changeProposalPromoter(proposalId || '', values, { signal: abortController.signal })

      message.success(response.data.message)
      handlePromoterModalCancel()
      setProposalPromoterSubmitLoading(false)
      return dispatch(tablesUpdate())
    } catch (err) {
      if (!abortController.signal.aborted) {
        if (err?.message) message.error(err.message)
      }
      setProposalPromoterSubmitLoading(false)
    }
  }

  return (
    <Fragment>
      <ChangeUser
        level={userlevel}
        setShow={setShowModal}
        show={showModal}
        id={proposalId}
        user={tableUser?.name}
      />

      <ChangeUser
        show={visibleModalCommercial}
        setShow={setVisibleModalCommercial}
        level="commercial"
        showUseSelf={true}
        isCommercialAttendant={true}
        id={proposalId}
        reminded={reminded}
        user={commercialInfo?._id}
      />

      <ProposalEgiReportModal
        url={proposalsUrl}
        hasProposalStatus
        visible={showReportModal}
        onVisibleChange={setShowReportModal}
      />

      <Modal
        visible={showStatusModal}
        destroyOnClose
        onCancel={() => setShowStatusModal(false)}
        footer={null}
      >
        <span className='font-size-25'>Deseja reativar esta proposta?</span>
        <p><span className='font-size-15'>Caso confirmado, a proposta irá retornar para o status</span> <b>em andamento</b>.</p>
        <div className='w-100 flex flex-justify-center mt-8'>
          <Button
            key='back'
            type='ghost'
            onClick={() => setShowStatusModal(false)}
            className='w-25 mr-1'
          >
            Não
          </Button>

          <Button
            key='submit'
            type='primary'
            onClick={() => {
              if (proposalId) changeProposalStatus(proposalId)
            }}
            className='w-25 ml-1'
          >
            {loadingSubmmit
              ? <Spin
                indicator={<LoadingOutlined spin />}
                style={{ color: '#FFFFFF' }} />
              : 'Sim'
            }
          </Button>
        </div>
      </Modal>
      <Modal
        visible={visibleModalPromoter}
        destroyOnClose
        onCancel={handlePromoterModalCancel}
        footer={null}
      >
        <h4 className='mb-2'>Selecione o novo colaborador responsável pela proposta <strong>{proposalPromoterInfo.proposalCode}</strong></h4>
        <Form layout='vertical' onFinish={handlePromoterChange}>
          <Form.Item label='Parceiro' name='userId' initialValue={proposalPromoterInfo.selectedUser}>
            <Select options={subPromotersList} loading={proposalPromoterLoading} value={proposalPromoterInfo.selectedUser}/>
          </Form.Item>

          <div className='flex flex-row flex-justify-end'>
            <Button onClick={handlePromoterModalCancel} className='mr-2' type='ghost'>
              Cancelar
            </Button>
            <Button type='primary' htmlType='submit' loading={proposalPromoterSubmitLoading}>
              Salvar
            </Button>
          </div>
        </Form>
      </Modal>
      <LimiterTable>
        <ServerTable
          url={proposalsUrl}
          hasAction={hasAction}
          hasProposalStatus={hasProposalStatus}
          csvFilename={csvFilename}
          hasMoreReport={ProposalEgiModel.canChooseReportType(user.level, user.areaName)}
          primaryFilter={filters().typeSearch}
          secondaryFilter={filters().createdAtProposal}
          buttons={allButtons()}
          filters={canSeeFilters()}
          columns={finalColumns}
          fixedDate={true}
          customsParams={{ mlm, reminded, needVinculation }}
          filterRow={canShowDepartmentFilter ? <DepartmentSelect /> : null}
          customClass={canShowDepartmentFilter ? 'server-table--deparment-row' : ''}
          timeout={90000}
        />
      </LimiterTable>
    </Fragment>
  )
}

export default ProposalsListEgiOld

import React, { useState, Fragment } from 'react'
import { Button } from 'antd'
import { useSelector } from 'react-redux'
import { RootState } from 'store/modules/rootReducer'
import imagesUrls from 'utils/imagesUrls'
import SimulatorLayout from 'layouts/Simulator/SimulatorLayout'

function CreateProposalEgi () {
  const user = useSelector((state: RootState) => state.auth)
  const [showSimulator, setShowSimulator] = useState<boolean>(false)
  return (
    <Fragment>
      {showSimulator ? (
        <SimulatorLayout
          internal={true}
          currentStep={0}
          needCEP={true}
        />
      ) : (
        <div className="card-style introduction-main-card">
          <div className="my-3 introduction-first-half flex flex-column flex-justify-center">
            <h3 className="pb-3 m-0 bold introduction-main-text">{`Olá Senhor(a) ${user.name}`}</h3>

            <Fragment>
              <p className="pb-3" style={{ fontSize: '1.1rem' }}>
                Percebemos que você ainda não realizou sua simulação, clique no botao abaixo e preencha seus dados.
              </p>
            </Fragment>

            <Button
              type='primary'
              className='unauth-buttons'
              id="show-simulator-button"
              onClick={async () => {
                setShowSimulator(true)
              }}
            >
              Simular
            </Button>
          </div>

          <div className="my-3 introduction-second-half flex flex-justify-center">
            <img src={imagesUrls.step1} className="introduction-image jello-horizontal flex-1" alt="Icone de um homem subindo uma escada" />

            <div className="flex-align-center flex flex-1">
              <h3 className="pb-3 m-0 bold introduction-secondary-text introduction-main-width-white color-white">Faça sua proposta de forma simples e fácil através de um passo a passo intuitivo.</h3>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default CreateProposalEgi

import { ArrowDownOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useAuth } from 'hooks'
import React, { useEffect, useState } from 'react'

function GoToReview () {
  const [isReviewButtonVisible, setIsReviewButtonVisible] = useState(false)
  const [firstRender, setFirstRender] = useState(false)
  const auth = useAuth()

  useEffect(() => {
    const container = document.querySelector('.ant-drawer-body')
    const element: HTMLElement | null = document.querySelector('.review-backoffice-card')

    const handleScroll = (event: any) => {
      if (container && element && auth.level !== 'client') {
        const elementHeight = element.offsetHeight

        if (event.type === 'mousemove') {
          const isReviewVisible = container.scrollHeight - elementHeight - container.scrollTop >= container.clientHeight
          if (element) {
            setIsReviewButtonVisible(isReviewVisible)
          }
        } else {
          const isReviewVisible = container.scrollHeight - element.offsetHeight - container.scrollTop >= container.clientHeight
          if (element) {
            setIsReviewButtonVisible(isReviewVisible)
            if (firstRender === false) { setFirstRender(true) }
          }
        }
      }
    }

    container?.addEventListener('scroll', handleScroll, { passive: true })
    container?.addEventListener('mousemove', handleScroll, { passive: true })

    return () => {
      container?.removeEventListener('scroll', handleScroll)
      container?.removeEventListener('mousemove', handleScroll)
    }
  }, [])

  function goToReview () {
    const container = document.querySelector('.ant-drawer-body')

    if (container) {
      container.scrollTop = container.scrollHeight
    }
  }

  return (
    <>
      {isReviewButtonVisible ? (
        <Button onClick={goToReview} type="primary" className="go-to-review-button">
          Revisão
          <ArrowDownOutlined />
        </Button>
      ) : !isReviewButtonVisible && firstRender ? (
        <Button onClick={goToReview} className="go-to-review-button-invisible">
          Revisão
          <ArrowDownOutlined />
        </Button>
      ) : (
        <div />
      )}
    </>
  )
}

export default GoToReview

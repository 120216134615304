
import { Divider, message, Skeleton } from 'antd'
import { CaptionIcon } from 'components/Captions/Captions'
import { IDynamicProposalStep, IProposalHistory } from 'ecp/app/Proposals/proposalInterfaces'
import DynamicProposalECPFRepository from 'ecpf/repositories/DynamicProposalECPFRepository'
import React, { useEffect, useState } from 'react'
import { timeAsDayjs } from 'utils/time'
import { ProposalStepContent } from '../ProposalSteps/ProposalSteps'

interface IClientInstructionsProps {
  proposalId?: string
  step: IDynamicProposalStep
}

export function ClientInstructions ({ proposalId, step }: IClientInstructionsProps) {
  const [historyItem, setHistoryItem] = useState<IProposalHistory.Item>()
  const [loading, setLoading] = useState<boolean>()

  const fetchStepHistories = async (departmentId: string, stepId: string) => {
    const abortController = new AbortController()
    setLoading(true)
    try {
      if (!proposalId) throw new Error('Não foi possível obter o ID da proposta')

      const response = await DynamicProposalECPFRepository.fetchStepHistory(proposalId, departmentId, stepId)
      const { history } = response.data?.data || {}
      setHistoryItem(history?.[history.length - 1])

      if (!history) throw new Error('Não foi possível obter o histórico do passo da proposta')
    } catch (err) {
      if (!abortController.signal.aborted) {
        if (err?.message) message.error(err.message)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchStepHistories(step.departmentId, step._id)
  }, [])

  if (!step.canUploadFile) return <></>

  if (loading) {
    return <div className='client-instructions__skeletons'>
      <Skeleton.Input className='client-instructions__skeleton-title' active={true} />
      <Skeleton.Input className='client-instructions__skeleton-card' active={true} />
    </div>
  }

  if (!historyItem?.currentStep?.instructions) return null

  if (historyItem) {
    return <div className="client-instructions">
      <ProposalStepContent.Subtitle>
        Instruções de envio
      </ProposalStepContent.Subtitle>

      <p>Siga as instruções enviadas pela formalização para completar a etapa da {step.name}</p>

      <div className="client-instructions__instructions-container">
        <CaptionIcon name={historyItem.currentStep.status} size={35} />
        <div className="pl-2 ">
          <p>
            {historyItem.currentStep.instructions ?? 'Nenhuma instrução foi adicionada'}
          </p>
          <small>Atualizado em: {timeAsDayjs(historyItem.updatedAt).format('DD/MM/YYYY HH:mm')}</small>
        </div>
      </div>

      <Divider/>
    </div>
  }

  return <></>
}

import React, { useEffect, useState } from 'react'
import { Mention, MentionsInput } from 'react-mentions'
import { defaultStyle, defaultMentionStyle } from './defaultStyle'

interface IMentionInput {
  mentions: string | Array<{id: string, display: string}>
  onChange:(value: string) => void
  placeholder: string,
  initialValue?: string,
  isCreate?: boolean
}

function MentionInput ({ mentions, onChange, placeholder, initialValue, isCreate }: IMentionInput) {
  const [value, setValue] = useState<string>('')

  function handleChange (value: string) {
    setValue(value)
  }

  useEffect(() => {
    onChange(value)
  }, [value])

  useEffect(() => {
    if (initialValue) setValue(initialValue)
  }, [initialValue])

  return (
    <MentionsInput
      value={value}
      onChange={(event) => { handleChange(event.target.value) }}
      placeholder={placeholder}
      spellCheck={false}
      style={defaultStyle}
      a11ySuggestionsListLabel="Sugestões de variáveis"
    >
      <Mention
        trigger="@"
        markup='@[__display__]'
        displayTransform={(_, display) => `@${display}`}
        style={defaultMentionStyle}
        data={typeof mentions === 'string' ? [] : mentions}

      />

      <Mention
        markup="@[__display__]"
        regex={isCreate ? undefined : /(@[À-ú\w]+)/}
        trigger="@"
        displayTransform={(_, display) => `${display}`}
        style={defaultMentionStyle}
        data={[]}
      />
    </MentionsInput>

  )
}

export default MentionInput

import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import translate from 'utils/translate'
import { Tag } from 'antd'
import { proposalStatusColor } from 'styles/colors'
import commomColumns, { columnBlockUser } from './commomDataTable'
import { IColumns } from 'egi/types'

const columns = ({ blockUser }: IColumns): ColumnsType<Object> => [
  ...commomColumns,
  {
    title: 'Status proposta',
    dataIndex: 'proposalStatus',
    render: (value: string) => <Tag color={proposalStatusColor[translate.reverseProposalStatus(value || 'ongoing')]} className="w-100 text-center">{value}</Tag>
  },
  columnBlockUser(blockUser)
]

export default columns

import { IProgressQueueActionsTypes } from './progressQueueActionTypes'
import { ProgressQueuesTypes } from './progressQueueActions'
import { IProgressQueueInfo, IProgressQueueListType } from 'ecp/components/ProgressQueue/progressQueueInterfaces'

export type IStatusProgressQueue = 'active' | 'success' | 'normal' | 'error'

export type IProgressQueueActions = 'approve' | 'reprove' | ''

export interface IProgressQueueItem {
  isImporting: boolean;
  progressCheckId: string;
  listLenght: number
  list: string[]
  isOpen: boolean
  progressQueueInfoList?: IProgressQueueInfo[]
  percentage?: number,
  status?: IStatusProgressQueue
  type: IProgressQueueListType
  action: IProgressQueueActions
}
export interface IProgressQueueReducer {
  progressQueueList: IProgressQueueItem[]
}

const initialState: IProgressQueueReducer = {
  progressQueueList: []
}

const progressQueueReducer = (state = initialState, action: IProgressQueueActionsTypes.allActions) => {
  switch (action.type) {
    case ProgressQueuesTypes.SET_IS_IMPORTING: {
      const { itemIndex, isImporting } = action.payload
      state.progressQueueList[itemIndex].isImporting = isImporting

      const newState = {
        ...state,
        progressQueueList: [...state?.progressQueueList]
      }

      return newState
    }

    case ProgressQueuesTypes.SET_PROGRESS_CHECK_ID: {
      const { itemIndex, progressCheckId } = action.payload
      state.progressQueueList[itemIndex].progressCheckId = progressCheckId

      const newState = {
        ...state,
        progressQueueList: [...state?.progressQueueList]
      }

      return newState
    }

    case ProgressQueuesTypes.SET_LIST_LENGHT: {
      const { itemIndex, listLenght } = action.payload
      state.progressQueueList[itemIndex].listLenght = listLenght

      const newState = {
        ...state,
        progressQueueList: [...state?.progressQueueList]
      }

      return newState
    }

    case ProgressQueuesTypes.SET_LIST: {
      const { itemIndex, list } = action.payload
      state.progressQueueList[itemIndex].list = list

      const newState = {
        ...state,
        progressQueueList: [...state?.progressQueueList]
      }
      return newState
    }

    case ProgressQueuesTypes.SET_IS_OPEN: {
      const { itemIndex, isOpen } = action.payload
      state.progressQueueList[itemIndex].isOpen = isOpen

      const newState = {
        ...state,
        progressQueueList: [...state?.progressQueueList]
      }

      return newState
    }

    case ProgressQueuesTypes.SET_INFO_LIST: {
      const { itemIndex, progressQueueInfoList } = action.payload
      state.progressQueueList[itemIndex].progressQueueInfoList = progressQueueInfoList

      const newState = {
        ...state,
        progressQueueList: [...state?.progressQueueList]
      }

      return newState
    }

    case ProgressQueuesTypes.SET_PERCENTAGE: {
      const { itemIndex, percentage } = action.payload
      state.progressQueueList[itemIndex].percentage = percentage

      const newState = {
        ...state,
        progressQueueList: [...state?.progressQueueList]
      }

      return newState
    }

    case ProgressQueuesTypes.SET_STATUS: {
      const { itemIndex, status } = action.payload
      state.progressQueueList[itemIndex].status = status

      const newState = {
        ...state,
        progressQueueList: [...state?.progressQueueList]
      }

      return newState
    }

    case ProgressQueuesTypes.SET_TYPE: {
      const { itemIndex, type } = action.payload
      state.progressQueueList[itemIndex].type = type

      const newState = {
        ...state,
        progressQueueList: [...state?.progressQueueList]
      }

      return newState
    }

    case ProgressQueuesTypes.SET_ACTION: {
      const { itemIndex } = action.payload
      state.progressQueueList[itemIndex].action = action.payload.action

      const newState = {
        ...state,
        progressQueueList: [...state?.progressQueueList]
      }

      return newState
    }

    case ProgressQueuesTypes.SET_PROGRESS_QUEUE_LIST: {
      const { queueList } = action.payload
      return {
        ...state,
        progressQueueList: queueList
      }
    }

    case ProgressQueuesTypes.RESET: {
      return initialState
    }

    default:
      return state
  }
}

export default progressQueueReducer

import { AxiosResponse } from 'axios'
import { ConnectionFailed, ErrInternetDisconnected } from './errors'
import api from '../../services/api'

interface IColumn {
  label: string
  value: number[]
}

interface IColumnString {
  label: string
  value: string[]
}

interface ICalculation {
  columns: (IColumn | IColumnString)[]
  type: string
  work?: string
}

const spreadsheet = {

  save: async (proposalId: string, data: ICalculation) => {
    try {
      const response: AxiosResponse = await api.post(`/proposals/${proposalId}/calculation`, { ...data })
      return response.data.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  get: async (proposalId: string, type: string) => {
    try {
      const response: AxiosResponse = await api.get(`/proposals/${proposalId}/calculation?type=${type}`)
      return response.data.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  update: async (proposalId: string, calculationId: string, data: ICalculation) => {
    try {
      const response: AxiosResponse = await api.put(`/proposals/${proposalId}/calculation/${calculationId}`, { ...data })
      return response.data.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  }
}

export default spreadsheet

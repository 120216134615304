import { _tabsType } from 'components/SimulationCard/components/StorageFiles/types'
import { _userLevel } from 'ecp/models/UsersModel'
import { _proposalStatuses } from 'egi/app/ProposalEgi/proposalEgiInterfaces'
import { IAuth } from 'egi/types'

const SimulationModel = {

  canSeeBirthdateField (personType: string | undefined): boolean {
    return personType !== 'juridica'
  },

  isPropertyMinDisabled (userLevel: _userLevel): boolean {
    return userLevel === 'auditor'
  },

  isPropertyMaxDisabled (userLevel: _userLevel): boolean {
    return userLevel === 'auditor'
  },

  isCreditMinDisabled (userLevel: _userLevel): boolean {
    return userLevel === 'auditor'
  },

  isCreditMaxDisabled (userLevel: _userLevel): boolean {
    return userLevel === 'auditor'
  },

  canSubmitSimulationConfiguration (userLevel: _userLevel): boolean {
    return userLevel === 'auditor'
  },

  canSendInformation (user: IAuth, tab: _tabsType, status: _proposalStatuses): boolean {
    if (['approved'].includes(status)) return false
    if ([tab, 'master', ...(tab === 'comercial' ? ['commercial'] : [])].includes(user.level)) return true
    if (user.areaName === tab) return true
    return false
  }

}

export default SimulationModel

import { AxiosRequestConfig } from 'axios'
import { IAvailableUsers } from 'components/ReduxModal/types'
import { IResponseBase, Repository } from 'ecp/repositories/Repository'
import { IBankInfoUser } from 'egi/types/IBank'
import api from 'services/api'

export interface IAccessManagerBackoffice {
  cellphone?: string
  cnpj?: string
  blocked?: boolean
  canceled?: boolean
  _id?: string
  status?: string
  details?: {
    NOME_USUARIO: string
    STATUS_EMAIL: string
    _id: string
  }
  name?: string
  cpf?: string
  level?: string
  code?: string
  emailVerified?: boolean
  products?: Array<AccessManagementProducts>,
}

export enum AccessManagementProducts {
  'home-equity' = 'home-equity',
  ecp = 'ecp',
  'ecp-funcao' = 'ecp-funcao',
}

export enum AccessManagementAction {
  UserProducts = 'UserProducts',
  UserAccess = 'UserAccess'
}

export interface IUpdateBackofficeData {
  action: AccessManagementAction,
  observations?: string,
  products?: Array<AccessManagementProducts>,
  canceled?: boolean,
  blocked?: boolean
}

class UsersEgiRepository extends Repository {
  async availableBackoffices (config?: AxiosRequestConfig) {
    return Repository.handle<{ users: IAvailableUsers[] }>(() =>
      this.api.get(`${this.path}/available`, config)
    )
  }

  async cancel (userId: string, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.patch(`${this.path}/${userId}/cancel`, config)
    )
  }

  async getDetails (id: string, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/${id}/details`, config)
    )
  }

  async block (id: string, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.patch(`${this.path}/${id}/block`, config)
    )
  }

  async emailLogs (id: string, config?: AxiosRequestConfig) : Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/${id}/email/logs`, config)
    )
  }

  async transferLogs (id: string, config?: AxiosRequestConfig) : Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/${id}/transfered-team`, config)
    )
  }

  async getEmails (id: string, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/${id}/email`, config)
    )
  }

  async getSmss (id: string, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/${id}/sms`, config)
    )
  }

  async profile (config?: AxiosRequestConfig) : Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/profile`, config)
    )
  }

  async updateProfile (userId: string, data:IBankInfoUser, config?: AxiosRequestConfig) : Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.patch(`${this.path}/${userId}/edit`, data, config)
    )
  }

  async getAccessmanagersBackofficeList (config?: AxiosRequestConfig) {
    return Repository.handle<{backoffices: IAccessManagerBackoffice[]}>(() =>
      this.api.get(`${this.path}/access-manager/users-backoffice/details`, config)
    )
  }

  async getAccessmanagersBackoffice (id: string, config?: AxiosRequestConfig) {
    return Repository.handle<{backoffice: IAccessManagerBackoffice}>(() =>
      this.api.get(`${this.path}/${id}/access-manager/user-backoffice/details`, config)
    )
  }

  async updateBackoffice (userId: string, data: IUpdateBackofficeData, config?: AxiosRequestConfig) : Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.patch(`${this.path}/${userId}/access-manager/user-backoffice`, data, config)
    )
  }
}

export default new UsersEgiRepository({ api, path: '/users' })

import React, { Fragment, lazy, Suspense, useLayoutEffect, useMemo, useState } from 'react'
import { Col, Empty, Skeleton, Typography } from 'antd'
import { timeAsDayjs } from 'utils/time'
import translate from 'utils/translate'
import { dashGreaterChartStyle, historyConfig } from '../MlmLeadsChartPie/chartConfig'
import { IFilterDate } from '../../views/MlmDashboard/types'
import DashboardEgiRepository, { DashboardEgiHistoryItem } from 'egi/repositories/DashboardEgiRepository'

function DashboardHistory ({ filterDate }: IFilterDate) {
  const [loading, setLoading] = useState<boolean>(true)
  const [failed, setFailed] = useState<boolean>(false)
  const [transformData, setTransformData] = useState([])
  const showTrimester = Object.assign({ trimester: true }, filterDate)

  useLayoutEffect(() => {
    const abortController = new AbortController()
    const getHistory = async () => {
      setLoading(true)
      try {
        const requestConfig = {
          signal: abortController.signal,
          params: showTrimester
        }

        const response = await DashboardEgiRepository.history(requestConfig)
        const { proposalHistories: chart } = response.data.data || {}
        if (!chart) throw new Error('Falha ao encontrar valores do histórico')

        const possibleValues = ['ongoing', 'reproved', 'canceled', 'approved']
        const tempFormated: any = []

        possibleValues.forEach((value: any) => {
          const tempValue = chart.map((item: DashboardEgiHistoryItem) => ({
            time: item.time,
            name: value,
            count: item[value]
          }))

          tempFormated.push(...tempValue)
        })

        const translateFormated = tempFormated.map((item: any) => ({
          ...item,
          name: translate.proposalStatus(item.name),
          time: timeAsDayjs(item.time).format('MM-YYYY')
        }))

        setFailed(false)
        setLoading(false)
        setTransformData(translateFormated)
      } catch (err) {
        if (!abortController.signal.aborted) {
          setFailed(true)
          setLoading(false)
        }
      }
    }

    if (filterDate.createdDate && filterDate.productId) getHistory()
    return () => abortController.abort()
  }, [
    filterDate.createdDate,
    filterDate.productId,
    filterDate.promoterId,
    filterDate.sellerId,
    filterDate.type
  ])

  const mainConfig = useMemo(() => historyConfig({ transformData }), [
    transformData
  ])

  const Column = lazy(() => import('@ant-design/charts/lib/column/index'))

  return (
    <Col xl={14} lg={24} md={24} sm={24} xs={24}>
      <div className="dashboard-status-card dashboard-status-card__proposal-history" style={{ height: 'calc(100% - 15px)' }}>
        {loading ? (
          <Skeleton
            active={true}
            paragraph={{ rows: 2 }}
          />
        ) : (
          failed ? <Empty description="Falha ao carregar dados." className="h-100 flex flex-align-center flex-column flex-justify-center" /> : (
            <Fragment>
              {(transformData && Array.isArray(transformData) && transformData.length > 0) ? (
                <div className="w-100">
                  <Typography.Title className="color-primary mb-1" level={5}>Histórico de status da proposta</Typography.Title>
                  <Suspense fallback={<Skeleton active={true} avatar paragraph={{ rows: 2 }} />}>
                    <Column style={dashGreaterChartStyle} {...mainConfig} />
                  </Suspense>
                </div>
              ) : <Empty description="Nenhum dado encontrado." className="h-100 flex flex-align-center flex-column flex-justify-center" />}
            </Fragment>
          )
        )}
      </div>
    </Col>
  )
}

export default DashboardHistory

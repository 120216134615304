import { _userLevel } from 'ecp/models/UsersModel'
import { IUserPermission } from 'egi/repositories/PermissionsRepository'
import translate from 'utils/translate'

export const PermissionModel = {

  canUpdatePermissions (userLevel: _userLevel): boolean {
    return ['master'].includes(userLevel)
  },

  hasParceiroTelevendas (permissions: Array<IUserPermission>): boolean {
    return permissions.some(permission => permission.slug === 'parceiro-televendas')
  },

  canSimulateWithoutLimits (permissions: Array<IUserPermission>): boolean {
    return this.hasParceiroTelevendas(permissions)
  },

  translateLevel (level: _userLevel, permissions: Array<IUserPermission>): string {
    if (this.hasParceiroTelevendas(permissions)) {
      return `${translate.level(level)} (Televendas)`
    }

    return translate.level(level)
  }
}

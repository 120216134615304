import React, { ReactNode } from 'react'
import { _userLevel } from 'ecp/models/UsersModel'
import translate from 'utils/translate'
import { Alert } from 'antd'

type Props = {
  inviterName?: string
  children: ReactNode
}
export const SignupInviteAlert = ({ inviterName, children }: Props) => {
  return (
    <Alert
      message={<p>Olá, {inviterName ? inviterName + '.' : ''}<br /></p>}
      description={children}
      type="info"
      showIcon
      closable
    />
  )
}

interface ISignupInviteMessagesProps {
  level: _userLevel
  promoterType: string
  areaName: string
  inviterName: string
  inviterLevel: string
}
function SignupInviteMessages ({ level, promoterType, areaName, inviterName, inviterLevel }: ISignupInviteMessagesProps) {
  const translatedLevel = translate.level(level)
  const translaterPromoter = translate.promoterType(promoterType)
  const translatedArea = translate.level(areaName)

  const baseInvite = <label>Você está sendo convidado por <b>{inviterName}</b> para ser </label>

  switch (level) {
    case 'promoter':
      if (inviterLevel === level) return <p>{baseInvite}<b>Colaborador</b> do tipo <b>{translaterPromoter}</b>!</p>
      return <p>{baseInvite}<b>{translatedLevel}</b> do tipo <b>{translaterPromoter}</b>!</p>
    case 'commercial':
    case 'pricing':
    case 'commission':
    case 'consultant':
    case 'seller':
    case 'auditor':
    case 'master':
      return <p>{baseInvite} um usuário <b>{translatedLevel}</b>!</p>
    case 'RH':
      return <p>{baseInvite} um usuário <b>{translatedLevel}</b>!</p>
    case 'backoffice':
    case 'administrator':
      return <p>{baseInvite} <b>{translatedLevel}</b> para a área <b className="capitalize">{translatedArea}</b>!</p>
    case 'client':
      return <p>{baseInvite} <b>{translatedLevel}</b> do <b>UX Line</b>!</p>
    case 'provider':
      return <p>{baseInvite} <b>{translatedLevel}</b> para a área <b className="capitalize">{areaName}!</b></p>
    case 'operator':
      return <p>{baseInvite} um usuário <b>{translatedLevel}</b>!</p>
    default:
      return <></>
  }
}

export default SignupInviteMessages

import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import IQualifition from 'egi/types/IMinutaMold'
import { qualification as qualificationConsumer } from 'egi/consumers'
import swal from 'utils/swal'
import { Button, Row } from 'antd'
import { MentionInput } from 'components'
import translate from 'utils/translate'
import VariablesAvailable from 'components/VariablesAvailable/VariablesAvailable'

interface IMinutaMoldVariables {
  id: string
  display: string
}

function PreviewModel ({ item, idx, productId }: {item: IQualifition, idx: number, productId: string}) {
  const [status, setStatus] = useState<string | undefined>(undefined)
  const [variables, setVariables] = useState<string | IMinutaMoldVariables[]>('Nenhuma variável disponível')
  const ref: MutableRefObject<any> = useRef({ text: '' })

  function formatText (text: string) {
    return text.replaceAll('[', '').replaceAll(']', '')
  }

  const onEdit = async (id: string, value: string, key: number) => {
    const data = {
      text: formatText(value),
      slug: id,
      productId: productId
    }

    setStatus(`sending-${key}`)
    try {
      const response = await qualificationConsumer.changeMinutaMold(data)
      setStatus(undefined)
      swal.basic({ icon: 'success', text: response.message, title: 'Sucesso!' })
    } catch (err) {
      setStatus(undefined)
      swal.basic({ icon: 'warning', text: err.message, title: 'Atenção' })
    }
  }

  useEffect(() => {
    const abortController = new AbortController()
    async function getMinutaMoldVariables () {
      setStatus('getVariables')
      try {
        const response = await qualificationConsumer.minutaMoldVariables({
          signal: abortController.signal,
          params: { type: item.type }
        })
        const variables = response.data.variables

        if (variables.length < 1) setVariables('Nenhuma variável disponível')
        else {
          const formatVariables = variables.map((item: string) => ({ id: item, display: translate.minutaMold(item) }))
          setVariables(formatVariables)
        }
        setStatus(undefined)
      } catch (err) {
        if (!abortController.signal.aborted) {
          setStatus(undefined)
          swal.basic({ title: 'Atenção', icon: 'warning', text: err.message })
        }
      }
    }

    if (item.type) getMinutaMoldVariables()
    return () => abortController.abort()
  }, [item])

  return (
    <div className="qualification-container__area">
      <VariablesAvailable variables={variables} />

      <div>
        <MentionInput
          key={idx}
          mentions={variables}
          initialValue={item.original}
          onChange={(value) => { ref.current.text = value }}
          placeholder="Digite a qualificação"
        />

        <Row justify="end">
          <Button
            type="primary"
            className="share-button mt-2"
            loading={status === `sending-${idx}`}
            onClick={() => onEdit(item.slug, ref.current.text, idx)}
          >
            Salvar
          </Button>
        </Row>
      </div>
    </div>
  )
}

export default PreviewModel

import React, { useState } from 'react'
import { Button, Form } from 'antd'
import ProposalReview, { IProposalReviewForm } from 'ecp/app/Proposals/components/ProposalReview/ProposalReview'
import { IAcceptContract, IDynamicProposalStep, ISendStepBody } from 'ecp/app/Proposals/proposalInterfaces'
import { _proposalStatuses } from 'egi/app/ProposalEgi/proposalEgiInterfaces'
import { useForm } from 'antd/lib/form/Form'
import { swalError } from 'components/SwalError/SwalError'
import { handleOnidataErrors } from 'utils/validate'
import { IResponseBase } from 'ecp/repositories/Repository'

type IProps = {
  close(): void
  proposalId: string
  step: IDynamicProposalStep
  sendStep: <T>(body: ISendStepBody<T>, proposalId: string) => Promise<IResponseBase<IAcceptContract>>
  clientName?: string
}

function ReviewDrawer ({ close, proposalId, step, sendStep, clientName }: IProps) {
  const [form] = useForm()
  const [loading, setLoading] = useState(false)
  const [editorValue, setEditorValue] = useState('')
  const [errors, setErrors] = useState<Partial<IProposalReviewForm>>()

  async function onSubmit (values: { status: _proposalStatuses, stepId: string }) {
    const body: ISendStepBody<{}> = {
      parameters: {},
      ...values
    }

    setLoading(true)
    try {
      if (!proposalId) throw new Error('ProposalId não encontrado')
      await sendStep(body, proposalId)

      close()
    } catch (err) {
      if (err?.data && err?.data?.error) setErrors(handleOnidataErrors(err.data.error))
      swalError({ title: 'Atenção', err, icon: 'warning' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={(values) => onSubmit({ ...values, description: editorValue })}
    >
      <ProposalReview
        form={form}
        errors={errors}
        step={step}
        editorValue={editorValue}
        onEditorChange={(value: string) => {
          setEditorValue(value)
        }}
        clientName={clientName}
      />

      <div className='flex flex-justify-end'>
        <Button
          type='primary'
          htmlType='submit'
          loading={loading}
        >
          Enviar
        </Button>
      </div>
    </Form>
  )
}

export default ReviewDrawer

import { Button, Col, Form, Row } from 'antd'
import { Input } from 'components'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import { user } from 'egi/consumers'
import { useTables } from 'hooks'
import translateAntForm from 'utils/translateAntForm'
import React, { MutableRefObject, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { modalReset } from 'store/modules/modal/actions'
import { tablesUpdate } from 'store/modules/tables/actions'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'

function ChangeEmail () {
  // redux
  const tables = useTables()
  const dispatch = useDispatch()
  const formRef: MutableRefObject<any> = useRef(null)

  // states
  const [errors, setErrors] = useState<{ email?: string }>({})
  const [loading, setLoading] = useState<boolean>(false)

  async function submitEmail (values: { email: string }) {
    setLoading(true)
    try {
      if (!tables.row) throw new Error('Falha ao ao buscar itens tabela.')
      if (!tables.row._id) throw new Error('Falha ao encontrar id do usuário.')

      const response = await user.changeUserEmail({ ...values, userId: tables.row._id })
      dispatch(tablesUpdate())
      swal.basic({ title: 'Sucesso', text: response.message, icon: 'success' })
    } catch (err) {
      if (err.data && err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
        return
      }
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    }
    setLoading(false)
  }

  useLayoutEffect(() => {
    if (formRef && formRef.current) {
      if (tables.row && tables.row.email) {
        formRef.current.setFieldsValue({ email: tables.row.email })
      }
    }
  }, [tables.row])

  return (
    <>
      <p className='mb-2'><b>Atenção!</b> Ao enviar uma solicitação de troca de email, o mesmo somente será trocado caso o usuário confirme a solicitação.</p>

      <Form
        ref={formRef}
        onFinish={submitEmail}
        layout="vertical"
      >
        <Form.Item
          name="email"
          help={errors.email && errors.email}
          validateStatus={errors.email && 'error'}
          label={<label>Novo email</label>}
        >
          <Input
            placeholder="Digite seu email"
            error={errors.email}
          />
        </Form.Item>

        <Row gutter={[15, 15]}>
          <Col>
            <Form.Item className="mb-0">
              <Button
                style={{ minWidth: 125 }}
                loading={loading}
                type="primary"
                htmlType="submit"
              >
                Enviar
              </Button>
            </Form.Item>
          </Col>

          <Col>
            <Button
              style={{ minWidth: 125 }}
              onClick={() => dispatch(modalReset())}
              type="ghost"
            >
              Cancelar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default ChangeEmail

import { AxiosRequestConfig } from 'axios'
import { ISignupTokenInfoResponse } from 'ecp/app/SignUpClient/signUpClientInterfaces'
import { IResponseBase, Repository } from 'ecp/repositories/Repository'
import api from 'services/api'

class InviteRepository extends Repository<{}> {
  async getTokenInfo (preSignupToken: string, config?: AxiosRequestConfig): Promise<IResponseBase<ISignupTokenInfoResponse>> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/pre-signup/${preSignupToken}`, config)
    )
  }
}

export default new InviteRepository({ api, path: '/ecp/invites' })

import loadable from '@loadable/component'
import Loading from 'components/Loading/Loading'
import { timeout } from 'promise-timeout'
import React from 'react'
import { TIMEOUT_LOADER } from 'utils/globals'

const ProposalList = loadable(
  () => timeout(import(/* webpackChunkName: "Proposals" */ 'ecp/app/Proposals/views/ProposalList/ProposalList'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const ProposalListSearch = loadable(
  () => timeout(import(/* webpackChunkName: "Proposals" */ 'ecp/app/Proposals/views/ProposalListSearch/ProposalListSearch'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const ProposalDetails = loadable(
  () => timeout(import(/* webpackChunkName: "Proposals" */ 'ecp/app/Proposals/views/ProposalDetails/ProposalDetails'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const ProposalCreate = loadable(
  () => timeout(import(/* webpackChunkName: "Proposals" */ 'ecp/app/Proposals/views/ProposalCreate/ProposalCreate'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const ProposalClientList = loadable(
  () => timeout(import(/* webpackChunkName: "Proposals" */ 'ecp/app/Proposals/views/ProposalClientList/ProposalClientList'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const ProposalFindUserCPF = loadable(
  () => timeout(import(/* webpackChunkName: "Proposals" */ 'ecp/app/Proposals/views/ProposalFindUserCPF/ProposalFindUserCPF'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const ProposalFindUserInformationConfirm = loadable(
  () => timeout(import(/* webpackChunkName: "Proposals" */ 'ecp/app/Proposals/views/ProposalFindUserInformationConfirm/ProposalFindUserInformationConfirm'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const ProposalFindUserCovenantSelect = loadable(
  () => timeout(import(/* webpackChunkName: "Proposals" */ 'ecp/app/Proposals/views/ProposalFindUserCovenantSelect/ProposalFindUserCovenantSelect'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const ProposalConfiguration = loadable(
  () => timeout(import(/* webpackChunkName: "Proposals" */ 'ecp/app/Proposals/views/ProposalConfiguration/ProposalConfiguration'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const ScoreForm = loadable(
  () => timeout(import(/* webpackChunkName: "Proposals" */ 'ecp/app/Proposals/components/ScoreForm/ScoreForm'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const Score = loadable(
  () => timeout(import(/* webpackChunkName: "Proposals" */ 'ecp/app/Proposals/components/Score/Score'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const AcceptContract = loadable(
  () => timeout(import(/* webpackChunkName: "Proposals" */ 'ecp/app/Proposals/components/AcceptContract/AcceptContract'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

export {
  ProposalList,
  ProposalDetails,
  ProposalCreate,
  ProposalClientList,
  ProposalFindUserCPF,
  ProposalFindUserInformationConfirm,
  ProposalFindUserCovenantSelect,
  ProposalConfiguration,
  Score,
  ScoreForm,
  ProposalListSearch,
  AcceptContract
}

import React, { Fragment, useEffect, useState } from 'react'
import { Button, Empty, Row } from 'antd'
import StringToHtml from 'components/StringToHtml/StringToHtml'
import usePartnerStep from '../../../../hooks/usePartnerStep'
import { IContractData } from './types'
import PartnerContractEditBox from './PartnerContractEditBox'
import PartnerModel from 'egi/models/PartnerModel'

function EditPartnerContract ({ onClick }: { onClick: () => void }) {
  const [contract, setContract] = useState<IContractData[]>([])
  const { step } = usePartnerStep()
  const editContract = PartnerModel.canEditContract(step)

  useEffect(() => {
    if (step.drafts) setContract(step.drafts)
  }, [step, step.drafts])

  return (
    <div className="final-contract">
      <Fragment>
        {contract.length < 1 && <Empty description="Contrato ainda não foi emitido!" />}

        {Array.isArray(contract) && contract.length > 0 && contract.map((value, idx) => (
          <Fragment key={`${value.slug}-${idx}`}>
            <p style={{ fontWeight: 'bold', padding: 4, backgroundColor: '#DEDEDE', border: '1px solid black' }}>
              {value.name}
            </p>

            {value.editable ? (
              <PartnerContractEditBox
                readOnly={!editContract}
                value={value.value}
                slug={value.slug}
                onSuccess={(contract: IContractData[]) => setContract(contract)}
              />
            ) : <StringToHtml value={value.value} />}
          </Fragment>
        ))}

        <Row justify="end">
          <Button
            type="primary"
            className="uppercase"
            onClick={onClick}
          >
            Visualizar contrato final
          </Button>
        </Row>
      </Fragment>
    </div>
  )
}

export default EditPartnerContract

import React from 'react'
import { Form, Select } from 'antd'

export type FilterAnalysisProps = {
    onChange: Function
}

function FilterAnalysis ({ onChange }: FilterAnalysisProps) {
  return (
    <Form.Item
      name={'reviewAnalysis'}
      label={<label>Análise</label>}
    >
      <Select
        allowClear
        className="w-100"
        onChange={(value: any) => {
          onChange(value)
        }}
        placeholder='Selecione o status da análise'
      >
        <Select.Option value="">Todos</Select.Option>
        <Select.Option value="starting">Iniciando</Select.Option>
        <Select.Option value="analysis">Análise</Select.Option>
        <Select.Option value="review">Reanálise</Select.Option>
      </Select>
    </Form.Item>
  )
}

export default FilterAnalysis

import { HistoryOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import React from 'react'

interface IProps extends ButtonProps {}

function ProposalHistoryButton ({ ...rest }: IProps) {
  return (
    <Button
      className='proposal-history-button'
      type='ghost'
      {...rest}
    >
      <HistoryOutlined />
    </Button>
  )
}

export default ProposalHistoryButton

export default {
  urltoFile: (url: string, filename: string, mimeType: string) => {
    mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1]
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer() })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }) })
    )
  }
}

export function downloadDocument ({ baseUrl, name }:{ baseUrl?: string, name?: string }) {
  const a = document.createElement('a')
  a.href = String(baseUrl)
  a.download = String(name)
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export function downloadCSVFile (csv: string, name: string) {
  const csvFile = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv)
  const filename = `relatorio${'_' + name}`

  const a = document.createElement('a')

  a.href = 'data:' + csvFile
  a.download = filename + '.csv'

  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

import { Checkbox, Form, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { IError } from 'ecp/app/Proposals/proposalInterfaces'
import LoginRepository from 'ecp/repositories/LoginRepository'
import SignupLayout from 'layouts/SignupLayout/SignupLayout'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { handleOnidataErrors } from 'utils/validate'
import swal from 'utils/swal'
import IframePDF from 'ecp/components/IframePDF/IframePDF'
import { ISignupClientLGPDForm, ISignupClientLGPDRequest, ISignupClientRequestLGPDRequest } from 'ecp/app/SignUpClient/signUpClientInterfaces'
import { UNAUTHS_PATHS } from 'routes/unauthRoutes'
import SignupButton from 'egi/app/Signup/components/SignupButton/SignupButton'
import { SignupTitle } from 'egi/app/Signup/components/SignupTitle/SignupTitle'

export function LgpdTextRedirect ({ href }: { href?: string | null }) {
  return (
    <>
      Li e concordo com os <a target='_blank'
        rel='noopener noreferrer'
        href={href || undefined}
      ><strong>Termos e Condições de uso e LGDP</strong></a>
    </>
  )
}

function FirstAccessClientLGPD () {
  const history = useHistory()

  const [form] = useForm<ISignupClientLGPDForm>()
  const [errors, setErrors] = useState<IError<ISignupClientLGPDForm>>({})
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [file, setFile] = useState<string | null>(null)
  const [loadingPdf, setLoadingPdf] = useState(true)

  const { tokenJwt } = useParams<{ tokenJwt?: string }>()

  function redirectToNextStep () {
    history.push('/')
  }

  useEffect(() => {
    async function getFile (tokenJwt: string) {
      const config: ISignupClientRequestLGPDRequest = {
        params: {
          onidataToken: tokenJwt
        }
      }

      try {
        const response = await LoginRepository.requestLGPD(config)
        const file = response.data.data?.url

        if (file) setFile(file)
      } catch (err) {
        if (err.message) message.warning(err.message)
        console.error(err)
      } finally {
        setLoadingPdf(false)
      }
    }

    if (tokenJwt) getFile(tokenJwt)
    else history.push(UNAUTHS_PATHS.FIRST_ACCESS_CLIENT_ECP_CHECK_LINK)
  }, [])

  function handleSubmit (tokenJwt: string) {
    return async function (values: ISignupClientLGPDForm) {
      if (values.lgpd !== true) {
        return swal.basic({ text: 'Você deve aceitar os termos e condições para continuar.', title: 'Atenção', icon: 'warning' })
      }

      const data: ISignupClientLGPDRequest = {
        onidataToken: tokenJwt
      }
      setLoadingSubmit(true)

      try {
        const response = await LoginRepository.confirmLGPD(data)
        message.success(response.data.message)
        redirectToNextStep()
      } catch (err) {
        console.error(err)
        if (err.data?.error) {
          setErrors(handleOnidataErrors(err.data?.error))
          setErrors(errors)
        }
      } finally {
        setLoadingSubmit(false)
      }
    }
  }

  return (
    <SignupLayout>
      <Form
        onFinish={handleSubmit(String(tokenJwt))}
        form={form}
        layout='vertical'
        className='unauth-form signup-client-lgpd'
      >
        <SignupTitle>
          Termos de uso
        </SignupTitle>

        <IframePDF
          className='signup-client-lgpd__pdf'
          url={file}
          loading={loadingPdf}
          onLoad={() => setLoadingPdf(false)}
        />

        <Form.Item
          name='lgpd'
          help={(errors?.lgpd)}
          validateStatus={errors?.lgpd && ('error')}
          valuePropName='checked'
          className='mt-4'
        >
          <Checkbox>
            <LgpdTextRedirect
              href={file || undefined}
            />
          </Checkbox>
        </Form.Item>

        <Form.Item>
          <SignupButton
            loading={loadingSubmit}
            htmlType='submit'
            className='w-100'
          >
            Enviar
          </SignupButton>
        </Form.Item>
      </Form>
    </SignupLayout>
  )
}

export default FirstAccessClientLGPD

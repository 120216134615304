import { Skeleton } from 'antd'
import Layout from 'antd/lib/layout/layout'
import { EcpCard } from 'ecp/components/EcpCard/EcpCard'
import React, { useEffect, useState } from 'react'

import queryString from 'query-string'
import imagesUrls from 'utils/imagesUrls'

import { UploadStepFiles } from '../../Components/UploadFile/UploadFile'
import { IDocumentsToSent } from '../../UnauthUploadFileInterfaces'
import { IStepFile } from 'ecp/app/Proposals/proposalInterfaces'
import format from 'utils/format'
import ErrorDetails from 'components/ErrorDetails/ErrorDetails'
import { IErrorDetails } from 'components/ErrorDetails/interfaces'
import WarningText from 'components/WarningText/WarningText'
import CelebrateSVG from 'assets/reactSvgs/CelebrateSVG/CelebrateSVG'
import UnauthUploadFIleModel from 'ecp/models/UnauthUploadFIleModel'
import UnauthDynamicProposalRepository from 'ecpf/repositories/UnauthDynamicProposalRepository'

interface IUploadContainerProps {
    documentsToSend: IDocumentsToSent | undefined,
    onUpdateFile: Function,
    loading: boolean
    error: IErrorDetails | undefined
  }

const DocumentsSent = () => {
  return <p className='text-center unauth-upload-file__sent'>
    <div>
      <CelebrateSVG className="unauth-upload-file__sent-svg"/>
    </div>
      Os arquivos necessários para este passo já foram enviados.
  </p>
}

const Instructions = ({ file } : {file: IStepFile}) => {
  const { maxSize, type } = file
  return (
    <div className="unauth-upload-file__instructions">
      <WarningText type="info">
        <ol className='unauth-upload-file__list'>
          {maxSize && <li>O tamanho máximo do arquivo é <b>{maxSize}</b> mb</li>}
          {type && <li>As extenções permitidas são {format.formatList(type)}</li>}
          <li>Só é permitido fazer o upload de <b>um</b> arquivo</li>
          <li>Após escolher seu arquivo, clique no botão <b>enviar</b>, para finalizar o processo</li>
        </ol>
      </WarningText>
    </div>
  )
}

const UploadContainer = ({
  documentsToSend,
  onUpdateFile,
  loading,
  error
}:IUploadContainerProps) => {
  if (loading) return <Skeleton active />

  if (error) {
    return (
      <ErrorDetails
        error={error}
        status={'error'}
        title={error.message ||
        'Não é possível fazer upload no momento, tente novamente mais tarde'}
      />)
  }

  if (documentsToSend?.step?.canUploadDocument) {
    return (
      <>
        <p>
        Olá, <b>{documentsToSend?.proposal?.client?.name}</b>, você precisa <b>{UnauthUploadFIleModel.unauthUploadDescriptionInstruction(documentsToSend?.step?.slug)}</b> para prosseguir com a proposta de número&nbsp;
          <b>{documentsToSend?.proposal?.onidataId || documentsToSend?.proposal?.sensediaId}</b>, siga as instruções abaixo para continuar
        </p>
        <Instructions file={documentsToSend?.file}/>
        <p className="text-center mt-3">
          <UploadStepFiles
            documentsToSend={documentsToSend}
            onUpdateFile={onUpdateFile}
          />
        </p>
      </>
    )
  }

  return <DocumentsSent />
}

function UnauthUploadFile () {
  const query = queryString.parse(location.search)
  const token = query.token

  const [loading, setLoading] = useState<boolean>(false)
  const [documentsToSend, setDocumentsToSend] = useState<IDocumentsToSent>()
  const [error, setError] = useState<IErrorDetails>()

  const getFile = async () => {
    setLoading(true)
    try {
      const response = await UnauthDynamicProposalRepository.getDocuments(String(token))
      setDocumentsToSend(response.data.data.proposalInfo)
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  const onUpdateFile = () => {
    getFile()
  }

  useEffect(() => {
    getFile()
  }, [])

  return <Layout className="unauth-upload-background">
    <header className="unauth-upload-header">
      <img className="unauth-logo-file-upload" src={imagesUrls.rodobensLogoLight} />
    </header>
    <div className="unauth-upload-file__container">
      <EcpCard className="unauth-upload-file__card">
        <h2 className="unauth-upload-file__title">{UnauthUploadFIleModel.unauthUploadTitle(documentsToSend?.step?.slug)}</h2>
        <UploadContainer
          error={error}
          loading={loading}
          documentsToSend={documentsToSend}
          onUpdateFile={onUpdateFile}
        />
      </EcpCard>
    </div>
  </Layout>
}

export default UnauthUploadFile

import { _userLevel } from './UsersModel'

const PendingUserModel = {

  showSelectCorbanInForm: (level: _userLevel | 'RH') => level === 'promoter',

  showSelectSubcorbanInForm: (level: _userLevel | 'RH') => level === 'operator'
}

export default PendingUserModel

import { ECPProductsSlugs, _averbacaoStatus, _proposalChannel } from 'ecp/app/Proposals/proposalInterfaces'
import ProposalModel from 'ecp/models/ProposalModel'
import { _userLevel } from 'ecp/models/UsersModel'
import { ILabelsAndValues } from 'ecp/repositories/Repository'

export const TRANSLATE_STATUS = {
  starting: 'Em Andamento',
  analysis: 'Em Análise',
  remaking: 'Pendências',
  canceled: 'Cancelada',
  reproved: 'Reprovada',
  approved: 'Aprovado',
  approvedManually: 'Aprovado man.',
  approvedAutomatically: 'Aprovado automaticamente',
  approvedWithRestriction: 'Aprovado com ressalvas',
  inProgress: 'Em andamento'
}

export const TRANSLATE_APPROVED_STATUS = {
  approvedManually: 'Apr. manual',
  approvedAutomatically: 'Apr. automática',
  approvedWithRestriction: 'Apr. com ressalvas'
}

export const TRANSLATE_REPROVED_APPROVED_STATUS = {
  ...TRANSLATE_APPROVED_STATUS,
  reprovedAutomatically: 'Rep. automática'
}

export const TRANSLATE_PRODUCT_SLUG: { [key in ECPProductsSlugs]: string } = {
  ecp: 'Novo Contrato',
  portabilidade: 'Portabilidade',
  renegociacao: 'Renegociação'
}

export const TRANSLATE_AVERBACAO: { [key in _averbacaoStatus]?: string } = {
  'aguardando-requisitos': 'Aguardando Requisitos',
  averbado: 'Averbado',
  negada: 'Negada',
  aguardando: 'Aguardando',
  cancelado: 'Cancelado',
  expirado: 'Expirado'
}

export const PROPOSAL_SEARCH_KEYS_RESOURCE: ILabelsAndValues<string> = [
  { value: 'localizador', label: 'Localizador' },
  { value: 'cliente__pessoa__nome', label: 'Nome do cliente' },
  { value: 'cliente__pessoa__sobrenome', label: 'Sobrenome do cliente' },
  { value: 'cliente__pessoa__cpf', label: 'CPF do cliente' },
  { value: 'cliente__emprego__empresa__razao_social', label: 'Razão social da empresa' },
  { value: 'cliente__emprego__empresa__nome_fantasia', label: 'Nome fantasia da empresa' },
  { value: 'financeira__razao_social', label: 'Razão social do corban' },
  { value: 'financeira__nome_fantasia', label: 'Nome fantasia do corban' }
]

export const PROPOSAL_STATUS_RESOURCE: ILabelsAndValues<string | undefined> = [
  { label: 'Efetivado', value: 'efetivado' },
  { label: 'Aprovado', value: 'aprovado' },
  { label: 'Em Andamento', value: 'emProgresso' },
  { label: 'Cancelado', value: 'cancelado' },
  { label: 'Recusado', value: 'recusado' },
  { label: 'Expirado', value: 'expirado' },
  { label: 'Encerrado', value: 'encerrado' },
  { label: 'Erro Crivo', value: 'erroCrivo' },
  { label: 'Pré Formalização', value: 'preFormalizacao' },
  { label: 'Biometria não enviada', value: 'biometriaNaoEnviada' },
  { label: 'Pendências', value: 'pendencia' }
]

export const CONTRACT_AVERBACAO_RESOURCE: ILabelsAndValues<string | undefined> = [
  { label: 'Averbado ', value: 'averbado' },
  { label: 'Aguardando requisitos', value: 'aguardando-requisitos' },
  { label: 'Aguardando', value: 'aguardando' },
  { label: 'Averbação Negada', value: 'negada' },
  { label: 'Expirado', value: 'expirado' },
  { label: 'Cancelado', value: 'cancelado' }
]

export const ALL_RESOURCE = { label: 'Todos', value: '' }

export const CONTRACT_PENDENTE_DE_TED: ILabelsAndValues<boolean | undefined> = [
  { label: 'Todas', value: undefined },
  { label: 'Sim', value: true },
  { label: 'Não', value: false }
]

export const CONTRACT_INITIAL_FILTERS = {
  status: undefined,
  department: undefined,
  step: undefined,
  page: 1,
  limit: 15
}

export const proposalFiltersKeysIgnore = (userLevel: _userLevel) => [
  'search',
  'page',
  'limit',
  'download',
  'downloadSignatures',
  'searchUseFilters',
  'createdDate',
  'identifier',
  'sort',
  ...(ProposalModel.canIgnoreRHDeafaultFilters(userLevel) ? [
    'lastStep',
    'origin'
  ] : [])
]

export const proposalFiltersEntriesToIgnore: {}[] = [
  { situacao: false },
  { status: [] },
  { status_averbacao: [] }
]

export const helpTextForSearchContracts = 'Para pesquisar por contratos utilize as possíveis informações, localizador, nome, sobrenome, CPF do cliente ou com razão social e nome fantasia da empresa ou informações da financeira como razão social e nome fantasia.'

export const CONTRACT_ACTION_MESSAGE = {
  deny: 'Você tem certeza que deseja negar este contrato?',
  approve: 'Você tem certeza que deseja averbar este contrato?'
}

export const CONTRACT_ACTION_BUTTON = {
  deny: 'Negar',
  approve: 'Averbar'
}

export const PROPOSAL_INITIAL_FILTERS = {
  status: undefined,
  situacao: undefined,
  em_andamento: undefined,
  page: 1,
  limit: 15
}

export const PROPOSAL_CHANNEL_RESOURCE: ILabelsAndValues<string | undefined> = [
  { label: 'Todos', value: undefined },
  { label: 'Corban', value: 'corban' },
  { label: 'Auto-atendimento', value: 'auto-atendimento' }
]

export const TRANSLATE_CHANNEL: { [key in _proposalChannel]?: string } = {
  corban: 'Corban',
  'auto-atendimento': 'Auto-atendimento'
}

export const TRANSLATE_PROPOSAL_STEP = {
  'assinatura-do-contrato': 'Assinatura',
  'averbacao-do-contrato': 'Averbação',
  averbacao: 'Averbação',
  'analise-de-credito': 'Crédito',
  'melhore-seu-score': 'Score',
  crivo: 'Crivo',
  'aprovacao-do-juridico': 'Jurídico',
  simulacao: 'Simulação',
  pagamento: 'Pagamento',
  cip: 'Análise CIP'
}

export const PROPOSAL_ORIGIN_RESOURCE: ILabelsAndValues<string | undefined> = [
  { label: 'Todos', value: undefined },
  { label: 'Novo contrato', value: 'novo-contrato' },
  { label: 'Portabilidade', value: 'portabilidade' },
  { label: 'Renegociação', value: 'renegociacao' }
]

export type _hiddenFields = 'matricula' | 'data_admissao' | 'telefone_celular' | 'cargo' | 'inss' | 'irrf' | 'salario' | 'data_nascimento' | 'sexo' | 'nome_mae'
export const HIDDEN_FIELDS: _hiddenFields[] = [
  'matricula', 'data_admissao', 'telefone_celular', 'cargo',
  'inss', 'irrf', 'salario', 'data_nascimento', 'sexo', 'nome_mae'
]

export const ECP_MODALIDADE = {
  'consignado-privado': 'Consignado Privado'
}

export const TRANSLATE_GET_LOCATION_ERROR = {
  'User denied Geolocation': 'Clique em permititr a localização para realizar a assinatura'
}

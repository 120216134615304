import React, { Fragment, useLayoutEffect, useState } from 'react'
import { resources } from 'egi/consumers'
import { Form, Select } from 'antd'
import { ILabelValue } from 'store/modules/resources/types'
import { useAuth, useTables } from 'hooks'
import { useDispatch } from 'react-redux'
import { tablesSetFiltersValues } from 'store/modules/tables/actions'
import DrawerFiltersLineCollapsable, { DrawerFiltersLineCollapsableItem } from 'components/DrawerFilters/DrawerFiltersLineCollapsable'
import ProposalEgiModel from 'egi/models/ProposalEgiModel'

export type FilterProviderProps = {

  types?: _providerType
}

export type _providerType ='juridico' | 'engenharia' | ''| undefined

function ProviderFilter ({ types = undefined }: FilterProviderProps) {
  const tables = useTables()
  const dispatch = useDispatch()
  const user = useAuth()
  const [selectValues, setSelectValues] = useState([])

  const getProvider = async () => {
    try {
      const response = await resources.providers({ areaName: types })
      const providers = response.providers
      setSelectValues(providers)
    } catch (err) {
      console.warn(err)
    }
  }

  useLayoutEffect(() => {
    getProvider()
  }, [])

  const resetProvider = () => dispatch(tablesSetFiltersValues({ ...tables.filters, providerId: undefined }))

  if (ProposalEgiModel.canFilterByProvider(user.level, user.areaName)) {
    return (
      <DrawerFiltersLineCollapsable label='Prestador'>
        <DrawerFiltersLineCollapsableItem>
          <Form.Item
            name="providerId"
          >
            <Select
              showSearch
              onClear={() => resetProvider()}
              placeholder='Selecione o prestador'
              allowClear
            >
              <Select.Option value="">Todos</Select.Option>
              {selectValues.map((item: ILabelValue) => (
                <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </DrawerFiltersLineCollapsableItem>
      </DrawerFiltersLineCollapsable>
    )
  }
  return <Fragment/>
}

export default ProviderFilter

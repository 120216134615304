import React, { Fragment, ReactElement, useEffect, useMemo, useState } from 'react'
import PartnerDepartments from '../components/PartnerDepartments/PartnerDepartments'
import PartnerDepartmentsSkeleton from '../components/PartnerDepartments/Skeleton/PartnerDeparmentsSkeleton'
import { useParams } from 'react-router-dom'
import Caption from 'components/Captions/Captions'

import { IPartnerDocumentation } from '../types'
import { Empty } from 'antd'
import { useAuth, useResources } from 'hooks'
import { useDispatch } from 'react-redux'
import { resourcesGetEconomicActivity, resourcesGetLegalNature } from 'store/modules/resources/actions'
import PartnersRepository from 'egi/repositories/PartnersRepository'
import { SocketSingleton } from 'utils/socket'

interface IParams {
  partnerId: string
}

export const PartnerDocumentContext = React.createContext<IPartnerDocumentation>({} as unknown as IPartnerDocumentation)

const ComponentsControl = ({ children, failed, loading }: {loading: boolean, failed: boolean, children: ReactElement}): ReactElement => {
  if (loading) return <PartnerDepartmentsSkeleton/>

  if (failed) return <Empty description='Não foi possível carregar a documentação.' />

  return children
}

const PartnersLine = () => {
  const [documentation, setDocumentation] = useState<IPartnerDocumentation>()
  const [socketConnect, setSocketConnect] = useState(() => {
    const socket = new SocketSingleton()
    if (socket.io) return socket.io.connected
  })

  const [status, setStatus] = useState<'loading' | 'failed' | undefined>('loading')
  const { partnerId } = useParams<IParams>()
  const user = useAuth()
  const documentId = user.documentId || partnerId

  const departments = useMemo(() => {
    if (documentation) return Object.values(documentation.departments)

    return []
  }, [documentation])

  const resources = useResources()
  const dispatch = useDispatch()

  function leaveSocketPartner () {
    const socket = new SocketSingleton()
    if (socket.io) {
      socket.io.emit('leave-partner-documents', documentId)
    }
  }

  useEffect(() => {
    const socket = new SocketSingleton()
    if (socket.io) {
      socket.io.on('connect', () => {
        setSocketConnect(true)
      })

      socket.io.on('disconnected', () => {
        setSocketConnect(false)
        setStatus('failed')
      })

      return () => {
        if (socket.io) {
          socket.io.off('disconnected')
          socket.io.off('connection')
        }
      }
    }
  }, [])

  useEffect(() => {
    const socket = new SocketSingleton()
    if (socket.io) {
      socket.io.on(documentId + user.level, (props: IPartnerDocumentation) => {
        setDocumentation(props)
        setStatus(undefined)
      })
    }

    return () => {
      leaveSocketPartner()
    }
  }, [documentId])

  useEffect(() => {
    async function getDocumentation (id: string) {
      setStatus('loading')
      try {
        const response = await PartnersRepository.documentation(id)
        setDocumentation(response.data.data.partnerDocuments)
        const socket = new SocketSingleton()
        if (socket.io) {
          socket.io.emit('join-partner-documents', documentId)
        }
        setStatus(undefined)
      } catch (err) {
        setStatus('failed')
        console.log(err)
      }
    }

    if (documentId && socketConnect) getDocumentation(documentId)
    if (!documentId) setStatus('failed')
  }, [documentId, socketConnect])

  useEffect(() => {
    if (resources.legalNature.length < 1) dispatch(resourcesGetLegalNature())
    if (resources.economicActivity.length < 1) dispatch(resourcesGetEconomicActivity())
  }, [
    resources.legalNature,
    resources.economicActivity
  ])

  return (
    <div className="partner-line">
      <div className="partner-line__caption">
        <Caption product='egi'/>
      </div>

      <ComponentsControl
        failed={status === 'failed'}
        loading={status === 'loading'}
      >
        <Fragment>
          {documentation && (
            <PartnerDocumentContext.Provider value={documentation}>
              {departments.length > 0 && departments.map((value, idx) => (
                <PartnerDepartments
                  departments={value}
                  key={value.status + idx}
                />
              ))}
            </PartnerDocumentContext.Provider>
          )}
        </Fragment>
      </ComponentsControl>
    </div>
  )
}

export default PartnersLine


import WhiteListSvg from 'assets/reactSvgs/SuspectsListSVG/SuspectsListSVG'
import WhiteListRepository from 'egi/repositories/WhiteListRepository'
import React, { useEffect, useState } from 'react'
import Suspects from '../../SuspectsList/SuspectsList'
import { message } from 'antd'
import FilterSearchIdentifierInput from 'components/SearchIdentifierInput/FilterSearchIdentifierInput'
import { identifiers } from '../BanList/BanList'
import Form, { useForm } from 'antd/lib/form/Form'
import SuspectsFilters from '../../components/SuspectsFilter'

export default function WhiteList () {
  const [suspectUsers, setSuspectUsers] = useState<{identifier: string, value: string, _id:string}[]>([])
  const [visible, setVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
  const [totalDocs, setTotalDocs] = useState<number>()
  const [params, setParams] = useState<{identifier?: string, value?: string, page: number}>({ page: 1 })
  const [formRef] = useForm()
  const [isFilterActive, setIsFilterActive] = useState<boolean>(false)

  const onVisibleChange = () => {
    setVisible(prev => !prev)
  }
  const getWhiteList = async () => {
    setLoading(true)
    try {
      const response = await WhiteListRepository.getWhiteList({ params })
      setSuspectUsers(response.data.data.identifiersWhiteList.docs)
      setTotalDocs(response.data.data.identifiersWhiteList.totalDocs)
    } catch (err) {
      message.error(err.message)
    } finally {
      setLoading(false)
    }
  }

  const onPageChange = (page: number) => {
    setParams({ ...params, page })
  }

  useEffect(() => {
    getWhiteList()
  }, [params])

  const handleOnReset = () => {
    formRef.setFieldsValue({ search: '' })
    setParams(prev => ({
      ...prev,
      value: '',
      identifier: ''
    }))
  }

  const handleOnClickSearch = (search?: string, identifier?: string) => {
    setParams({
      ...params,
      value: search?.trim(),
      identifier: identifier
    })
  }

  const onSubmit = async (values: {identifier: string, value: string}) => {
    setLoadingSubmit(true)
    try {
      await WhiteListRepository.addUserToWhiteList(values)
      message.success('Usuário adicionado à lista branca com sucesso')
      onVisibleChange()
      getWhiteList()
    } catch (err) {
      message.error(err.message)
    } finally {
      setLoadingSubmit(false)
    }
  }

  const onDelete = async (id: string) => {
    try {
      await WhiteListRepository.deleteWhiteList(id)
    } catch (err) {
      message.error(err.message)
    } finally {
      getWhiteList()
    }
  }

  const handleFilter = (identifier: string) => {
    setParams({
      ...params,
      identifier
    })

    if (identifier) {
      setIsFilterActive(true)
    } else {
      setIsFilterActive(false)
    }
  }

  return (
    <>
      <Suspects.Header
        title='Lista Branca'
        svg={<WhiteListSvg />}
        description={
          <>
            <p>Aqui estão listados os usuários que não podem ser banidos, mesmo que sejam detectados comportamentos maliciosos</p>
            <p>Clique em adicionar usuário, para atribuir a proibição de banimento a alguém</p>
          </>
        }>
        <Suspects.AddToList
          visible={visible}
          onVisibleChange={onVisibleChange}
          onSubmit={onSubmit}
          title="Adicionar usuário na lista branca"
          showWhyField={false}
          loadingSubmit={loadingSubmit}
          description={ <p>
          Para adicionar um usuário na lista branca, certifique-se de enviar o <b>identificador</b> (Domínio, IP ou Id de usuário) e seu <b>valor</b>
          </p>}
        />
      </Suspects.Header>
      <Form
        layout="vertical"
        form={formRef}
      >
        <div className="suspects-list__filters">
          <div className="suspects-list__input-search">
            <FilterSearchIdentifierInput
              placeholder='Escolha o que deseja pesquisar'
              key='identifier'
              label='Pesquisar'
              identifiers={identifiers}
              onReset={handleOnReset}
              onChange={() => {}}
              disabled={isFilterActive}
              onClickSearch={handleOnClickSearch}
            />
          </div>
          <SuspectsFilters
            handleFilter={handleFilter}
            isFilterActive={isFilterActive}/>
        </div>
      </Form>
      <Suspects.List
        suspectUsers={suspectUsers}
        onDelete={onDelete}
        loading={loading}
        totalDocs={totalDocs ?? 0}
        onPageChange={onPageChange}
      />
    </>
  )
}

import React, { useEffect } from 'react'
import RobotSVG from 'assets/icons/Robot.svg'
import { reactAppGRecaptchaKey } from 'utils/config'
import loadRecaptchaScript, { unmountRecaptcha } from 'services/GRecaptcha'

function GRecaptcha ({ callback, hasFetchError, setHasFetchError }: { callback: Function, hasFetchError: boolean, setHasFetchError: (err: boolean) => void }) {
  const siteKey = reactAppGRecaptchaKey ?? ''

  useEffect(() => {
    const handleError = (hasError: boolean) => {
      setHasFetchError(hasError)
    }

    (window as any).gRecaptchaCallback = callback;
    (window as any).errorCallback = () => {
      handleError(true)
    }

    loadRecaptchaScript(siteKey, handleError)
    return () => {
      unmountRecaptcha()
    }
  }, [])

  useEffect(() => {
    if (hasFetchError) callback()
  }, [hasFetchError])

  return (
    <div className='grecaptcha-modal'>
      <img className='grecaptcha-modal__img' src={RobotSVG} />
      <div>
        <h2 className='grecaptcha-modal__title m-1'>Precisamos verificar se você é humano</h2>
        <p className='grecaptcha-modal__description m-1'>Por favor, complete o reCAPTCHA para prosseguir com o login.</p>
      </div>

      <div
        className='g-recaptcha'
        data-sitekey={siteKey}
        data-callback='gRecaptchaCallback'
        data-error-callback='errorCallback'
        onError={() => setHasFetchError(true)}
      />
    </div>
  )
}

export default GRecaptcha

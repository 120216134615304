import { Skeleton } from 'antd'
import React, { Fragment } from 'react'

function UploadSkeleton ({ quantity }: {quantity: number}) {
  return (
    <Fragment>
      <div>
        {new Array(quantity).fill('').map((_, index) => (
          <Skeleton.Button key={index} active size='large' className="upload-skeleton" />
        ))}
      </div>
    </Fragment>
  )
}

export default UploadSkeleton

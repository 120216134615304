import { Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import DrawerFilters from 'components/DrawerFilters/DrawerFilters'
import FilterTypeSearchDropdown from 'components/FilterTypeSearchDropdown/FilterTypeSearchDropdown'
import ReRequestButton from 'components/ReRequest/ReRequest'
import { hasFilterCounter } from 'components/ServerTable/function'
import ServerTableFilters from 'components/ServerTable/ServerTableFilters'
import FilterCreatedProposal from 'components/SeverTable/FilterCreatedAtProposal'
import { formatReduxValuesContract } from 'ecp/app/Proposals/proposalFunctions'
import DepartmentSelect from 'egi/app/ProposalEgi/components/DepartmentSelect/DepartmentSelect'
import ProposalEgiModel from 'egi/models/ProposalEgiModel'
import { useAuth, useTables } from 'hooks'
import { useSetFilters } from 'hooks/useSetFilters'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { tablesSetFiltersValues, tablesUpdate } from 'store/modules/tables/actions'
import { formatFieldsForm } from 'utils/utils'
import { proposalEgiFiltersEntriesToIgnore, proposalEgiFiltersKeysIgnore, PROPOSAL_EGI_INITIAL_FILTERS } from '../../proposalEgiConstants'
import { formatFilters } from '../../proposalFunctions'
import { ActionFilter } from '../ActionFilter/ActionFilter'
import { ActionsButton } from '../ActionsButton/ActionsButton'
import { AnalisysFilter } from '../AnalysisFilter/AnalisysFilter'
import AreaFilter from '../AreaFilters.ts/AreasFilters'
import { BackofficeFilter } from '../BackofficeFilter/BackofficeFilter'
import { ExpiredProposalFilter } from '../ExpiredProposalFilter/ExpiredProposalFilter'
import ManagerFilter from '../ManagerFilter/ManagerFilter'
import { NeedVinculationFilter } from '../NeedVinculationFilter/NeedVinculationFilter'
import { OpenContracts } from '../OpenContractsFilter/OpenContractsFilter'
import { PersonTypeFilter } from '../PersonTypeFilter/PersonTypeFilter'
import FilterPromoter from '../Promoterfilter/PromoterFilter'
import { ProposalStatusFilter } from '../ProposalStatusFilter/ProposalStatusFilter'
import ProviderFilter, { _providerType } from '../ProviderFilter/ProviderFilter'
import { RemindedFilter } from '../RemindedFilter/RemindedFilter'
import SellerFilter from '../SellerFilter/SellerFilter'
import { UpdatedAtFilter } from '../UpdatedAtFilter/UpdatedAtFilter'
import { ValueFilter } from '../ValueFilter/ValueFilter'

interface IProps {
  reminded: boolean | undefined
  hasAction: boolean | string
  needVinculation: boolean | string
}

function ProposalFiltersDrawerFields ({ reminded = false, needVinculation }: IProps) {
  const user = useAuth()

  return (
    <>
      <ProposalStatusFilter />

      <ActionFilter />

      <PersonTypeFilter />

      {ProposalEgiModel.canSeeLinkedBackofficeColumn(user.level, user.type) &&
        <BackofficeFilter/>
      }
      <ExpiredProposalFilter />

      <NeedVinculationFilter needVinculation={needVinculation} />

      <RemindedFilter />

      <AnalisysFilter />

      <FilterPromoter />

      <SellerFilter />

      <ManagerFilter/>

      <ProviderFilter
        types={user.areaName as _providerType}
      />

      {ProposalEgiModel.canSeeAreasActionFilter(user.level) && (
        <AreaFilter
          label="Ações na área"
          value="areaName"
        />
      )}

      <OpenContracts
        reminded={reminded}
      />

      <AreaFilter
        hasDocumentationAndAvaliation={true}
        label="Último departamento"
        value="lastArea"
      />

      <ValueFilter />

      <UpdatedAtFilter />
    </>
  )
}

function ProposalFilters ({ reminded = false, hasAction, needVinculation }: IProps) {
  const tables = useTables()
  const dispatch = useDispatch()
  const [form] = useForm()
  const { withOutSearch } = useSetFilters()
  const user = useAuth()

  const canShowDepartmentFilter = ProposalEgiModel.canSeeDepartmentFilter(user.level, user.areaName)

  function clearFilters () {
    form.resetFields()
    dispatch(tablesSetFiltersValues(PROPOSAL_EGI_INITIAL_FILTERS))
  }

  useEffect(() => {
    form.setFieldsValue(formatReduxValuesContract(tables.filters))
  }, [])

  useEffect(() => {
    const initialFilterStatus = tables.filters.proposalStatus ? tables.filters.proposalStatus : 'ongoing'

    const formatToStatus = (value: string | string[]): Array<string> => {
      if (typeof value !== 'string') return []
      return value?.split(',')
    }

    dispatch(
      tablesSetFiltersValues({
        ...tables.filters,
        proposalStatus: initialFilterStatus,
        hasAction
      }))

    form.setFieldsValue({
      proposalStatus: formatToStatus(initialFilterStatus),
      hasAction
    })
  }, [])

  const filterCounter = useMemo(() => {
    form.setFieldsValue(formatFieldsForm(tables.filters))

    return Object
      .entries(tables.filters)
      .filter(hasFilterCounter(proposalEgiFiltersKeysIgnore, proposalEgiFiltersEntriesToIgnore))
      .length
  }, [JSON.stringify(tables.filters)])

  return (
    <>
      <Form
        layout='vertical'
        form={form}
        onValuesChange={
          (changedValues, values) => {
            const shouldRequest = !(Object.keys(changedValues).includes('minValueNotDebounced') ||
              Object.keys(changedValues).includes('maxValueNotDebounced') ||
              Object.keys(changedValues).includes('search'))

            if (shouldRequest) {
              return withOutSearch(changedValues, formatFilters(values))
            }
          }
        }
      >
        <ServerTableFilters>
          <div className="proposal-filter__created-date">
            <FilterTypeSearchDropdown
              onChange={(value: string | undefined, ignore: boolean | undefined) => {
                dispatch(tablesSetFiltersValues({ ...tables.filters, search: value?.trim(), searchUseFilters: ignore, page: 1 }))
              }}
            />
          </div>

          <ServerTableFilters>
            <ReRequestButton
              className="proposal-filter__rerequest"
              onClick={() => {
                dispatch(tablesUpdate())
              }}
            />
            <div className="proposal-filter__created-date">
              <FilterCreatedProposal
                key='createdDate'
              />
            </div>
            <ActionsButton />

            <DrawerFilters
              showSearch={true}
              disabled={tables.ignoreAll}
              onClear={clearFilters}
              counter={filterCounter}
            >
              <ProposalFiltersDrawerFields
                reminded={reminded}
                hasAction={hasAction}
                needVinculation={needVinculation}
              />
            </DrawerFilters>
          </ServerTableFilters>
        </ServerTableFilters>

        {canShowDepartmentFilter &&
          <div className='mt-2 server-table--deparment-row'>
            <DepartmentSelect />
          </div>
        }
      </Form>
    </>
  )
}

export default ProposalFilters

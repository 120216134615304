
import React, { useEffect, useState } from 'react'
import Suspects from '../../SuspectsList/SuspectsList'
import { message } from 'antd'
import BanRepository from 'ecp/repositories/BanRepository'
import { BanListSvg } from 'assets/reactSvgs/SuspectsListSVG/SuspectsListSVG'
import FilterSearchIdentifierInput from 'components/SearchIdentifierInput/FilterSearchIdentifierInput'
import Form, { useForm } from 'antd/lib/form/Form'

import SuspectsFilters from '../../components/SuspectsFilter'

export const identifiers = [
  { label: 'IP', value: 'ip' },
  { label: 'Domínio', value: 'domain' },
  { label: 'Id de usuário', value: 'userId' }
]

export default function BanList () {
  const [suspectUsers, setSuspectUsers] = useState<{identifier: string, value: string, _id:string}[]>([])
  const [visible, setVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
  const [totalDocs, setTotalDocs] = useState<number>()
  const [params, setParams] = useState<{identifier?: string, value?: string, page: number}>({ page: 1 })
  const [formRef] = useForm()
  const [isFilterActive, setIsFilterActive] = useState<boolean>(false)

  const onVisibleChange = () => {
    setVisible(prev => !prev)
  }
  const getBanList = async () => {
    setLoading(true)
    try {
      const response = await BanRepository.getBanList({ params })
      setSuspectUsers(response.data.data.bannedIdentifiers.docs)
      setTotalDocs(response.data.data.bannedIdentifiers.totalDocs)
    } catch (err) {
      message.error(err.message)
    } finally {
      setLoading(false)
    }
  }

  const onPageChange = (page: number) => {
    setParams({ ...params, page })
  }

  useEffect(() => {
    getBanList()
  }, [params])

  const onSubmit = async (values: {identifier: string, value: string}) => {
    setLoadingSubmit(true)
    try {
      await BanRepository.addUserToBanList(values)
      message.success('Usuário adicionado à lista de banidos com sucesso')
      onVisibleChange()
      getBanList()
    } catch (err) {
      message.error(err.message)
    } finally {
      setLoadingSubmit(false)
    }
  }

  const onDelete = async (id: string) => {
    try {
      await BanRepository.deleteWhiteList(id)
    } catch (err) {
      message.error(err.message)
    } finally {
      getBanList()
    }
  }

  const handleOnReset = () => {
    formRef.setFieldsValue({ search: '' })
    setParams(prev => ({
      ...prev,
      value: '',
      identifier: ''
    }))
  }

  const handleOnClickSearch = (search?: string, identifier?: string) => {
    setParams({
      ...params,
      value: search?.trim(),
      identifier
    })
  }

  const handleFilter = (identifier: string) => {
    setParams({
      ...params,
      identifier
    })

    if (identifier) {
      setIsFilterActive(true)
    } else {
      setIsFilterActive(false)
    }
  }

  return (
    <>
      <Suspects.Header
        svg={<BanListSvg/>}
        title='Lista de banidos'
        description={
          <>
            <p>Aqui estão listados os usuários banidos por atividades suspeitas ou potencialmente maliciosas</p>
            <p>Clique em adicionar usuário para banir</p>
          </>
        }>
        <Suspects.AddToList
          visible={visible}
          onVisibleChange={onVisibleChange}
          onSubmit={onSubmit}
          title="Banir usuário"
          showWhyField={true}
          loadingSubmit={loadingSubmit}
          description={<p>
          Para adicionar um usuário na lista de banidos, certifique-se de enviar o <b>identificador</b> (Domínio, P ou Id de usuário), seu <b>valor e a razão</b>
          </p>}
        />
      </Suspects.Header>
      <Form
        layout="vertical"
        form={formRef}
      >
        <div className="suspects-list__filters">
          <div className="suspects-list__input-search">
            <FilterSearchIdentifierInput
              placeholder='Escolha o que deseja pesquisar'
              key='identifier'
              label='Pesquisar'
              identifiers={identifiers}
              onReset={handleOnReset}
              onChange={() => {}}
              onClickSearch={handleOnClickSearch}
              disabled={isFilterActive}
            />
          </div>
          <SuspectsFilters
            handleFilter={handleFilter}
            isFilterActive={isFilterActive}/>
        </div>
      </Form>
      <Suspects.List
        suspectUsers={suspectUsers}
        onDelete={onDelete}
        loading={loading}
        totalDocs={totalDocs ?? 0}
        onPageChange={onPageChange}
      />
    </>
  )
}

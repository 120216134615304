import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

function LoadingSpinner ({ size = 'default', className }: { size?: 'small' | 'default' | 'large', className?: string }) {
  return (
    <Spin
      size={size}
      className={`loading-spinner ${className || ''}`}
      indicator={
        <LoadingOutlined className='loading-spinner__icon' spin />
      }
    />
  )
}

export default LoadingSpinner

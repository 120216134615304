import React from 'react'
import { Switch } from 'antd'
import swal from 'utils/swal'
import IUser from 'egi/types/IUser'
import UserTableModels from 'egi/models/UserTableModels'
import { _userLevel } from 'ecp/models/UsersModel'

const columnBlockUser = ({ blockUser, userLevel }: {blockUser(id: string): Promise<void>, userLevel: _userLevel}) => ({
  title: 'Bloqueado',
  dataIndex: 'blocked',
  width: 125,
  sorter: true,
  render: (item: any, row: any) => {
    const { _id } = (row as IUser)
    if (row.active === 'Desativado') return (<></>)
    const isBlocked = item

    return (
      <Switch
        checked={item}
        disabled={!UserTableModels.canBlockUser(userLevel)}
        onClick={() => {
          swal.confirmNegate({
            title: 'Atenção',
            text: `Tem certeza que deseja ${isBlocked ? 'desbloquear' : 'bloquear'} o usuário?`,
            icon: 'warning',
            confirm: () => {
              if (_id) blockUser(_id)
            }
          })
        }}
      />
    )
  }
})

export default columnBlockUser

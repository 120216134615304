import { AxiosRequestConfig } from 'axios'
import { Repository } from 'ecp/repositories/Repository'
import api from 'services/api'

interface IinitialData {
  invitedId: string
  message: string
  status: string
}

export interface ILinksHistoriesResponse {
  createdAt: string
  _id: string
  link?: string
  environment?: string
  modifiedBy: {
    _id: string
    name: string
    level: string
  }
}

class InvitedsRepository extends Repository {
  async verifyProvider (data: IinitialData, config?: AxiosRequestConfig) {
    const { invitedId, status, message } = data
    return Repository.handle(() =>
      this.api.post(`/inviteds/${invitedId}/verify`, { status, message }, config)
    )
  }

  async generatedLink (body: { id: string, environment: string }, config?: AxiosRequestConfig) {
    return Repository.handle<{ link: string }>(() =>
      this.api.post(`${this.path}/link`, body, config)
    )
  }

  async resendLinkHistory (invitedId: string, config?: AxiosRequestConfig) {
    return Repository.handle<{ links: ILinksHistoriesResponse[] }>(() =>
      this.api.get(`${this.path}/links/${invitedId}`, config)
    )
  }
}

export default new InvitedsRepository({ api, path: '/inviteds' })

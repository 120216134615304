import { IProgressQueueItem } from 'store/modules/progressQueue/progressQueueReducer'
import { IProgressQueueListType } from './progressQueueInterfaces'

export function isImportingQueueItem (queueList: IProgressQueueItem[], type: IProgressQueueListType) {
  let isImporting = false

  if (Array.isArray(queueList)) {
    queueList.forEach(item => {
      if (item.type === type && item.isImporting) isImporting = true
    })
  }
  return isImporting
}

export function getCorrectQueueItem (queueList: IProgressQueueItem[], type: IProgressQueueListType) : IProgressQueueItem | null {
  let correctItem = null
  if (Array.isArray(queueList)) {
    queueList.forEach(item => {
      if (item.type === type) {
        correctItem = item
      }
    })
  }
  return correctItem
}

import React, { useState, useEffect } from 'react'
import { Form } from 'antd'
import { Bank, DocumentUpload } from 'components'
import { useAuth, useProposal, useStep } from 'hooks'
import { IBank } from 'egi/types'
import { useDispatch } from 'react-redux'
import { proposals } from 'egi/consumers'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'
import { IBankErrors } from 'egi/types/IBank'
import { IFormDataErrors, IFormProps } from '../types'
import { proposalLoadingStep } from 'store/modules/proposal/actions'
import ProposalEgiRepository from 'egi/repositories/ProposalEgiRepository'
import { AxiosRequestConfig } from 'axios'
import Paragraph from 'antd/lib/typography/Paragraph'
import StepsModel from 'egi/models/StepsModel'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'

interface IFormBank {
  bank: IBank
}

function FormBank ({ formRef, onLoading, onType, callbackProposal, readOnlyInput, getDocuments, documentPath }: IFormProps) {
  const [errors, setErrors] = useState<IFormDataErrors>({})
  const [loading, setLoading] = useState<boolean>(false)

  const step = useStep()
  const proposal = useProposal()
  const dispatch = useDispatch()
  const user = useAuth()

  const onFileUpload = async (request: any) => {
    setLoading(true)
    const { file, onSuccess, onProgress, onError } = request
    if (!file) return

    const formData = new FormData()
    formData.append('file', file)
    formData.append('stepId', step._id)

    const config: AxiosRequestConfig = {
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 90000,
      onUploadProgress: ({ total, loaded }) => {
        if (total) onProgress({ percent: Math.round((loaded / total) * 100) })
      }
    }

    const buttonDrawer = document.getElementById('drawer-submit-button') as HTMLButtonElement
    if (buttonDrawer) buttonDrawer.disabled = true

    try {
      if (!proposal.id) throw Error('Identificador da proposta não encontrado')
      if (!step.areaId) throw Error('Identificador do passo não encontrado')
      const response = await ProposalEgiRepository.uploadDocument({ proposalId: proposal.id, areaId: step.areaId, data: formData }, config)
      const responseFile = { ...response, data: { file } }
      onSuccess(responseFile)
    } catch (err) {
      onError(err)
      swal.basic({ icon: 'warning', title: 'Atenção', text: err.message })
    } finally {
      setLoading(false)
      if (buttonDrawer) buttonDrawer.disabled = false
    }
  }

  const onFileDelete = async (fileUrl: string | object, resolve: Function) => {
    setLoading(true)
    const data = { stepId: step._id, path: fileUrl }
    const config: AxiosRequestConfig = { timeout: 90000 }

    try {
      if (!proposal.id) throw new Error('Identificador da proposta não encontrado')
      const response = await ProposalEgiRepository.deleteDocument({ proposalId: proposal.id, areaId: step.areaId, data }, config)
      resolve(true)
      swal.basic({ icon: 'success', title: 'Sucesso!', text: response.data.message })
    } catch (err) {
      console.error(err)
      swal.basic({ icon: 'warning', title: 'Atenção!', text: err.message })
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = async (values: IFormBank) => {
    if (values.bank && values.bank.account) values.bank.account = values.bank.account.replace(/[^0-9x]/ig, '')
    if (values && values.bank.agency) values.bank.agency = values.bank.agency.replace(/\D/g, '')

    const props: any = {
      proposalId: proposal.id,
      stepId: step._id,
      areaId: step.areaId,
      form: values
    }

    try {
      if (!proposal.id) throw new Error('Não foi possível encontrar o identificador da proposta!')
      let response

      if (onType === 'send') {
        onLoading('sending')
        props.form = { ...props.form, finished: true }
        response = await proposals.send(props)
      } else if (onType === 'save') {
        onLoading('saving')
        props.form = { ...props.form, finished: false }
        response = await proposals.send(props)
      }

      if (step.id) dispatch(proposalLoadingStep({ stepId: step.id, loading: true }))

      setErrors({})
      callbackProposal()
      swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
    } catch (err) {
      if (err.data?.invalid) {
        let errors: IBankErrors = {}
        errors = validateResponse(err.data?.invalid)
        setErrors(errors)

        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
        return
      }

      setErrors({})
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    } finally {
      onLoading('')
    }
  }

  useEffect(() => {
    function resetFields () {
      if (formRef) formRef.resetFields()
    }

    function onFill () {
      const formData = Object.assign({}, step.form) || {}

      if (Object.keys(formData).length < 1) {
        resetFields()
        return
      }

      if (formRef) formRef.setFieldsValue({ ...formData })
    }

    resetFields()
    onFill()

    return () => {
      resetFields()
    }
  }, [step.form, formRef, step._id, step.situation])

  return (
    <Form
      onFinish={onSubmit}
      form={formRef}
      layout="vertical"
    >
      <Form.Item name="bank">
        <Bank
          errors={errors}
          readOnlyInput={readOnlyInput}
        />

      </Form.Item>
      <>
        <Paragraph className="bold">Anexar comprovante</Paragraph>
        <DocumentUpload
          onFileDelete={onFileDelete}
          onFileUpload={onFileUpload}
          hideUploadButton={!StepsModel.canUploadBankProof(user.level, user.areaName)}
          getDocuments={getDocuments ? getDocuments : () => null}
          paths={documentPath ?? []}
          loading={loading}
          skeletonQuantity={1}
          maxDocs={3} />
      </>
    </Form>
  )
}

export default FormBank

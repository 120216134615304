import { AxiosRequestConfig } from 'axios'
import { IResponseBase, Repository } from 'ecp/repositories/Repository'
import api from 'services/api'

class TextReviewRepository extends Repository {
  async showTextReview (config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}`, config)
    )
  }

  async addTextReview (body: {stepSlug: string, departmentId: string, text: string}, config?: AxiosRequestConfig) {
    return Repository.handle<{ document: { stepSlug: string }}>(() =>
      this.api.post(`${this.path}`, body, config)
    )
  }

  async changeTextReview (body: {stepSlug: string, departmentId: string, text: string}, config?: AxiosRequestConfig) {
    return Repository.handle<{ document: { stepSlug: string }}>(() =>
      this.api.patch(`${this.path}`, body, config)
    )
  }

  async deleteTextReview (config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.delete(`${this.path}`, config)
    )
  }

  async getTextReviewHistory (config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/history`, config)
    )
  }
}

export default new TextReviewRepository({ api, path: '/text-review' })

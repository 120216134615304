import { SigninService } from 'ecpf/services/signinService'
import { useCallback, useState } from 'react'

export enum FuncaoTypistSituationEnum {
  active = 'Ativada'
}

interface Promotor {
  codigo: string
  descricao: string
}

interface Endereco {
  cep: string
  logradouro: string
  numero: string
  complemento: string
  bairro: string
  cidade: string
  uf: string
}

interface Grupo {
  numero: string
  codigo: string
  descricao: string
}

interface Origem {
  tipo: string
  codigo: string
}

interface Alcada {
  aprovacao: number
  visualizacao: number
  reprovacao: number
  pendente: number
  alteracao: number
}

interface Fases {
  visualizacao: Array<number>
  alteracao: Array<number>
}

interface DireitosComissionados {
  backoffices: Array<Backoffice>
}

interface Backoffice {
  codigo: string
  descricao: string
  comissionados: Array<Comissionado>
}

interface Comissionado {
  codigo: string
  descricao: string
}

interface Usuario {
  ativo: boolean
  login: string
  validade: number
  dataExpiracao: Date
  grupos: Array<Grupo>
  horarioAcessoInicial: string
  horarioAcessoFinal: string
  usuarioRestrito: boolean
  origens: Array<Origem>
  alcadas: Array<Alcada>
  fases: Fases
  visualizarObservacao: boolean
  mostrarListaAprovacao: boolean
  valorLimiteAprovacao: boolean
  direitosComissionados: DireitosComissionados
}

export interface IDigitador {
  codigo: string
  documento: string
  nomeCompleto: string
  nomeResumido: string
  promotor: Promotor
  endereco: Endereco
  email: string
  usuario: Usuario
}

export const useTypist = () => {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [typist, setTypist] = useState<IDigitador>()

  const getTypist = useCallback(
    async (identifier: string) => {
      try {
        setLoading(true)
        const signinService = new SigninService()
        const digitador = await signinService.typist(identifier)

        setTypist(digitador)
        setErrorMessage('')

        return digitador
      } catch (err) {
        setErrorMessage(err?.message)
      } finally {
        setLoading(false)
      }
    },
    []
  )

  return {
    loading,
    errorMessage,
    getTypist,
    typist
  }
}

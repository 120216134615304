import React, { Fragment, MutableRefObject, useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Input, Modal, Tooltip, Row } from 'antd'
import ModalShare from 'components/ModalShare/ModalShare'
import ModalLink from 'components/ModalInvite/ModalLink'
import swal from 'utils/swal'
import { tablesUpdate } from 'store/modules/tables/actions'
import { validateResponse } from 'utils/validate'
import { InfoCircleFilled } from '@ant-design/icons'
import SlugInput from 'components/Inputs/SlugInput/SlugInput'
import { useDispatch } from 'react-redux'
import { sendWhatsapp } from 'utils/globals'
import { useSelectedProduct } from 'hooks'
import { IModalCampaign, IModalCampaignErrors } from '../../campaignLinkInterfaces'
import LinkRepository from 'egi/repositories/LinkRepository'
import EmailRepository from 'egi/repositories/EmailRepository'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'

function CampaignModal ({ visible, setVisible, defaultInvite }: IModalCampaign) {
  const [errors, setErrors] = useState<IModalCampaignErrors>({})
  const [invite, setInvite] = useState<string>('')
  const [loading, setLoading] = useState({
    list: false,
    invite: false,
    modalInvite: false,
    email: false,
    generateLink: false
  })

  const dispatch = useDispatch()
  const { _id: productId } = useSelectedProduct()

  const [inviteRef] = Form.useForm()
  const [campaignRef] = Form.useForm()
  const generateLinkFormRef: MutableRefObject<any> = useRef(null)

  async function generateInvite (values: InviteValues) {
    const campaign = true
    const { campaignName, campaignSlug } = values

    setLoading({
      ...loading,
      modalInvite: true
    })
    try {
      const link = {
        productId,
        campaign,
        campaignName,
        campaignSlug
      }

      const response = await LinkRepository.create(link)
      dispatch(tablesUpdate())
      setInvite(response.data.data?.link)
    } catch (err) {
      if (err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
        swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
        return
      }
      const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
      invalidFieldsError({ invalidFields: invalidFields })
    } finally {
      setLoading({
        ...loading,
        modalInvite: false
      })
    }
  }

  async function inviteEmail () {
    const { email } = inviteRef.getFieldsValue()
    setLoading({
      ...loading,
      email: true
    })
    try {
      const response = await EmailRepository.createLink({ link: invite, email })
      swal.basic({ title: 'Sucesso', text: response.data?.message, icon: 'success' })
    } catch (err) {
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    } finally {
      setLoading({
        ...loading,
        email: false
      })
    }
  }

  interface InviteValues {
    campaignName?: string
    campaignSlug?: string
  }

  const handleInvite = async (type: 'invite' | 'modalInvite') => {
    setLoading({ ...loading, [type]: true })
    await generateInvite({})
    setLoading({ ...loading, [type]: false })
  }

  function resetModal () {
    if (campaignRef) {
      campaignRef.resetFields()
      setVisible(false)
    }

    setVisible(false)
  }

  useEffect(() => {
    if (defaultInvite) setInvite(defaultInvite)
  }, [defaultInvite])

  return (
    <Modal
      visible={visible}
      title="GERAR NOVO LINK DE CAMPANHA"
      footer={null}
      onCancel={resetModal}
    >
      {!invite && (
        <Fragment>
          <Col>
            <Form
              layout="vertical"
              onFinish={generateInvite}
              form={campaignRef}
            >
              <Col className="mt-2">
                <Form.Item
                  name="campaignSlug"
                  label={
                    <Fragment>
                      <label>Slug da campanha</label>

                      <Tooltip
                        title={<label>Permitido apenas <b>letras minúsculas</b>, <b>números</b> e <b>-</b></label>}
                        destroyTooltipOnHide={{ keepParent: false }}
                      >
                        <InfoCircleFilled className="mx-1 color-primary" />
                      </Tooltip>
                    </Fragment>
                  }
                  help={errors.campaignSlug && (errors.campaignSlug)}
                  validateStatus={errors.campaignSlug && ('error')}
                >
                  <SlugInput formRef={campaignRef} field='campaignSlug' />
                </Form.Item>
              </Col>

              <Col>
                <Form.Item
                  name="campaignName"
                  label={<label>Nome da campanha</label>}
                  help={errors.campaignName && (errors.campaignName)}
                  validateStatus={errors.campaignName && ('error')}
                >
                  <Input placeholder="Digite o nome da campanha" />
                </Form.Item>
              </Col>

              <Row justify="end">
                <Col>
                  <Button type="ghost" className="mr-2" onClick={() => resetModal()}>
                    Cancelar
                  </Button>
                </Col>

                <Col>
                  <Button type="primary" loading={loading.modalInvite} htmlType="submit">
                    Gerar novo link
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Fragment>
      )}

      {invite && (
        <Fragment>
          <ModalLink
            handleInvite={() => handleInvite('modalInvite')}
            generateLinkFormRef={generateLinkFormRef}
            loading={loading}
            invite={invite}
            canChooseType={true}
            texts={{
              goInviteText: 'Abrir link',
              inviteLinkText: 'Link de campanha:'
            }}
          />

          <ModalShare
            form={inviteRef}
            sendWhatsapp={() => sendWhatsapp(inviteRef.getFieldsValue().cellphone, `Olá, segue o link da campanha para o Rodobens UX-Line: *${invite}*`)}
            inviteEmail={inviteEmail}
            invite={invite}
            loading={loading}
          />
        </Fragment>
      )}
    </Modal>
  )
}

export default CampaignModal

import React, { Fragment, useLayoutEffect, useState } from 'react'
import { resources } from 'egi/consumers'
import { Form, Select } from 'antd'
import DrawerFiltersLineCollapsable, { DrawerFiltersLineCollapsableItem } from 'components/DrawerFilters/DrawerFiltersLineCollapsable'
import ProposalEgiModel from 'egi/models/ProposalEgiModel'
import { useAuth } from 'hooks'

export type FilterPromoterProps = {
  setSeller: React.Dispatch<React.SetStateAction<string | undefined>>
}

function SellerFilter () {
  const [selectValues, setSelectValues] = useState([])
  const user = useAuth()

  const getSeller = async () => {
    try {
      const response = await resources.seller()
      const sellers = response.data.sellers
      setSelectValues(sellers)
    } catch (err) {
      console.warn(err)
    }
  }

  useLayoutEffect(() => {
    getSeller()
  }, [])

  if (ProposalEgiModel.canFilterBySeller(user.level, user.type)) {
    return (
      <DrawerFiltersLineCollapsable label='Vendedor'>
        <DrawerFiltersLineCollapsableItem>
          <Form.Item
            name="sellerId"
          >
            <Select
              showSearch
              optionFilterProp="children"
              className="w-100"
              placeholder='Selecione o vendedor'
              allowClear
            >
              <Select.Option value="">Todos</Select.Option>
              {selectValues.map((item: any) => {
                return <Select.Option key={item._id} value={item._id}>{item.name}</Select.Option>
              })}
            </Select>
          </Form.Item>
        </DrawerFiltersLineCollapsableItem>
      </DrawerFiltersLineCollapsable>

    )
  }
  return <Fragment/>
}

export default SellerFilter

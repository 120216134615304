import React, { FC } from 'react'
import { IPartnerStep, _allStep } from 'egi/app/PartnersLine/types'
import PartnerRegistrationStep from './steps/PartnerStep/PartnerRegistrationStep'
import AssociateStep from './steps/AssociateStep/AssociateStep'
import DocumentStep from './steps/DocumentStep/DocumentStep'
import { LGPDStep } from './steps/LGPDStep/LGPDStep'
import BacenStep from './steps/BacenStep/BacenStep'
import PartnerContractStep from './steps/PartnerContract/PartnerContractStep'
import GenericStep from './steps/GenericStep/GenericStep'
import BankData from './steps/BankData/BankData'
import PartnerFormContractStep from './steps/PartnerFormContract/PartnerFormContractStep'
import CorbanCertificateStep from './steps/CorbanCertificate/CorbanCertificateStep'

type IProps = {
  step: IPartnerStep
}

export const StepContext = React.createContext<IProps>({} as unknown as IProps)

const StepComponents: {[key in _allStep]: FC} = {
  'ficha-cadastral-pj': PartnerRegistrationStep,
  'ficha-cadastral-pf': AssociateStep,
  'atos-societarios': DocumentStep,
  'documentos-socios-representantes': DocumentStep,
  'comprovante-endereco': DocumentStep,
  'comprovante-bancario': DocumentStep,
  'certificado-correspondente-bancario': CorbanCertificateStep,
  'fotos-estrutura-interna': DocumentStep,
  'fotos-cracha': DocumentStep,
  'fotos-fachada': DocumentStep,
  'certificado-curso-lgpd': LGPDStep,
  'assinatura-bacen': BacenStep,
  'aml-analise-de-risco': DocumentStep,
  'formulario-do-contrato': PartnerFormContractStep,
  'contrato-de-correspondente-bancario': PartnerContractStep,
  'contrato-de-parceria-comercial': PartnerContractStep,
  'compromisso-de-protecao-de-dados': PartnerContractStep,
  'dados-bancarios': BankData,
  'contrato-assinado': DocumentStep,
  'contrato-assinado-banco': DocumentStep
}

function PartnerStep ({ step }: IProps) {
  const StepComponent = StepComponents[step.slug] || GenericStep

  return (
    <div className="partner-step">
      <StepContext.Provider value={{ step }}>
        <StepComponent/>
      </StepContext.Provider>
    </div>
  )
}

export default PartnerStep

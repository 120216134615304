import React, { MouseEventHandler, ButtonHTMLAttributes } from 'react'
import { Button } from 'antd'

export interface IModelButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: MouseEventHandler<HTMLElement> | undefined
  text: string
  loading?: boolean
  icon?: React.ReactElement
  disabled?: boolean
}

function ButtonModel ({ onClick, text, icon, loading, disabled, ...rest }: IModelButton) {
  return (
    <Button
      {...rest}
      className='mt-1 server-table-filters__max-width-button server-table-filters__text-button'
      loading={loading}
      type='text'
      onClick={onClick}
      icon={icon}
      disabled={disabled}
    >
      {text}
    </Button>
  )
}

export default ButtonModel

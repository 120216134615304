import { IValidateResponseData } from 'ecpf/components/TokenValidator/TokenValidator'
import { TokenValidationMessageTemplateType } from 'ecpf/models/TokenValidationModel'
import api from 'services/api'
import { Repository } from './Repository'

interface ITokenRequest {
  methodType: string
  email?: string
  cellphone?: string
  messageTemplateType: TokenValidationMessageTemplateType
}

interface ITokenValidate {
  email?: string
  cellphone?: string
  token: string
}

class TokensValidationRepository extends Repository<{}> {
  async request (data: ITokenRequest) {
    return Repository.handle(() =>
      this.api.post(`${this.path}/create`, data)
    )
  }

  async validate (data: ITokenValidate) {
    return Repository.handle<IValidateResponseData>(() =>
      this.api.post(`${this.path}/verify`, data)
    )
  }

  async resend (data: ITokenRequest) {
    return Repository.handle(() =>
      this.api.put(`${this.path}/resend`, data)
    )
  }
}

export default new TokensValidationRepository({ api, path: '/validation-token' })

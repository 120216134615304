import { CheckCircleFilled, DeleteFilled } from '@ant-design/icons'

import SuspectSvg from 'assets/reactSvgs/SuspectSvg/SuspectSvg'
import Menu from 'components/Menu/Menu'
import React from 'react'
import { ECP } from 'routes/ecp/constants'

export default function SuspectBehaviorMenu () {
  const allCards = [
    {
      icon: <CheckCircleFilled />,
      title: 'Lista branca',
      description: 'Confira a lista de usuários que não podem ser banidos',
      path: `/auth${ECP.SUSPECT_BEHAVIOR_WHITE_LIST.path}`,
      visible: true,
      buttonText: 'Visualizar'
    },
    {
      icon: <DeleteFilled />,
      title: 'Usuários Banidos',
      description: 'Confira a lista de usuários banidos, e restaure, caso necessário ',
      path: `/auth${ECP.SUSPECT_BEHAVIOR_BAN_LIST.path}`,
      visible: true,
      buttonText: 'Visualizar'
    }
  ]

  return (
    <Menu>
      <Menu.Header
        title='Atividades supeitas'
        svg={<SuspectSvg/>}
      >
        <Menu.Paragraph className="pb-2">
          A página de atividades suspeitas é uma ferramenta essencial para <b>garantir a segurança</b> e a integridade da plataforma.
        </Menu.Paragraph>

        <Menu.Paragraph>
          Ela monitora e registra qualquer comportamento anômalo ou potencialmente malicioso.
        </Menu.Paragraph>
      </Menu.Header>

      <Menu.CardContainer
        cardList={allCards}
      />
    </Menu>
  )
}

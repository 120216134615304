import { UNAUTHS_ROUTES } from 'routes/unauthRoutes'
import corbans from '../utils/corbans'
import { getToken } from './localStorage'
const host = document.location.hostname

function changeFavicon (url?: string) {
  if (!url) return
  const favicon = document.getElementById('favicon')
  if (favicon) favicon.setAttribute('href', url)
}

function removeParam (route: string) {
  const [routeParamless] = route.split(':')
  return routeParamless
}

function verifyToken () {
  const token = getToken()
  const hrefFirstPart = document.location.href.split('/')[0]
  const hasAuth = document.location.href.includes('/auth')

  const isUnauthRoute = UNAUTHS_ROUTES.filter(item => document.location.href.includes(removeParam(item.path)) && item.withToken === true).length > 0

  if (token && !hasAuth && !isUnauthRoute) document.location.href = hrefFirstPart + 'auth'
}

function setEvironment () {
  if (corbans[host]) document.title = `${corbans[host].name}-UxLine`
  if (corbans[host] && corbans[host].favicon) changeFavicon(corbans[host].favicon)

  verifyToken()
}

export default setEvironment

import React from 'react'
import mainColumns from 'egi/views/UsersTable/DataTables'
import { FileTextOutlined, TeamOutlined } from '@ant-design/icons'
import translate from 'utils/translate'
import UsersTable from 'egi/views/UsersTable/UsersTable'
import PartnersLine from 'egi/app/PartnersLine/views/PartnersLine'
import PromoterList from 'egi/app/Promoter/views/PromoterList/PromoterList'
import { IRoute } from 'components/Sidebar/types'
import { EGI } from 'routes/egi/paths'

const leaders: IRoute = {
  path: EGI.COMMERCIAL_LEADERS.path,
  name: EGI.COMMERCIAL_LEADERS.name,
  icon: TeamOutlined,
  component: () => <UsersTable url='/administrators' area="comercial" level="administrator" inviteButtonText={translate.level('administrator')} columns={mainColumns.commercial} />,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const collaborators: IRoute = {
  path: '/commercial/collaborator',
  name: 'Suporte comercial',
  icon: TeamOutlined,
  component: () => <UsersTable url='/commercials' level="commercial" inviteButtonText={translate.level('commercial')} columns={mainColumns.commercial} />,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const providers: IRoute = {
  path: '/commercial/providers',
  name: 'Prestadores',
  icon: TeamOutlined,
  component: () => <UsersTable url='/providers' area="comercial" level="provider" inviteButtonText={translate.level('provider')} columns={mainColumns.provider} />,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const promoters: IRoute = {
  path: '/commercial/promoters',
  name: 'Parceiros',
  icon: TeamOutlined,
  component: () => <PromoterList viewerLevel="commercial" canInvite={true} canGenerateReport={true} />,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const partnersLine: IRoute = {
  name: 'Documentação de parceiros',
  path: '/commercial/partner/:partnerId/documentation',
  icon: FileTextOutlined,
  component: PartnersLine,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const comercialTeam = {
  leaders,
  collaborators,
  providers,
  promoters,
  partnersLine
}

export {
  comercialTeam
}

import { Form, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import SignupLayout from 'layouts/SignupLayout/SignupLayout'
import React, { useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { UNAUTHS_PATHS } from 'routes/unauthRoutes'
import { IError } from 'ecp/app/Proposals/proposalInterfaces'

import { Input } from 'components'

import SignupButton from 'egi/app/Signup/components/SignupButton/SignupButton'

import { SignupTitle } from 'egi/app/Signup/components/SignupTitle/SignupTitle'

import { ILGPDAcessSendEmail } from '../../LGPDAccessInterfaces'
import LoginRepository from 'ecp/repositories/LoginRepository'
import { swalError } from 'components/SwalError/SwalError'
import { handleOnidataErrors } from 'utils/validate'

function LGPDAccessSendEmail () {
  const history = useHistory()

  const [form] = useForm<ILGPDAcessSendEmail>()
  const [errors, setErrors] = useState<IError<ILGPDAcessSendEmail>>({})
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const location = useLocation()

  function redirectToNextStep (email: string) {
    history.push({ pathname: UNAUTHS_PATHS.LGPD_ACCESS_CONFIRM_EMAIL, state: email })
  }

  async function handleSubmit (values: ILGPDAcessSendEmail) {
    setErrors({})
    setLoadingSubmit(true)
    try {
      const data = {
        email: values.email,
        cnpj: location.state as string
      }
      await LoginRepository.lgpdSendEmail(data)
      redirectToNextStep(values.email)
    } catch (err) {
      if (err?.data && err?.data?.error) {
        message.error(err.message)
        setErrors(handleOnidataErrors(err.data.error))
      }
      swalError({ title: 'Atenção', err, icon: 'warning' })
    } finally {
      setLoadingSubmit(false)
    }
  }

  return (
    <SignupLayout>
      <div className='mb-3'>
        <Link
          className='signup__link'
          to={UNAUTHS_PATHS.LGPD_ACCESS_CHECK_CNPJ}
        >
          &larr; Voltar
        </Link>
      </div>
      <SignupTitle className='mb-1'>Validação de email</SignupTitle>
      <p className='lgpd-access__info-text'>Antes de continuar, informe seu email:</p>
      <Form
        layout="vertical"
        className='unauth-form'
        onFinish={handleSubmit}
        form={form}
      >
        <Form.Item
          name='email'
          label='Email'
          help={errors?.email}
          validateStatus={errors?.email && ('error')}
          rules={[{
            required: true,
            message: 'Digite um email para prosseguir'
          }]}
        >
          <Input
            className='unauth-inputs'
            placeholder='Digite seu email... '
          />
        </Form.Item>
        <Form.Item>
          <SignupButton
            htmlType='submit'
            className='w-100'
            loading={loadingSubmit}
          >
            Enviar
          </SignupButton>
        </Form.Item>
      </Form>
    </SignupLayout>
  )
}

export default LGPDAccessSendEmail

import { Button, Form, message } from 'antd'
import TokenVerification from 'components/TokenVerification/TokenVerification'
import TokenHeader from 'ecp/components/TokenHeader/TokenHeader'
import TokenResend from 'ecp/components/TokenResend/TokenResend'
import React, { useState } from 'react'
import EcpUsersRepository from 'ecp/repositories/EcpUsers'
import { IChangeProfileConfirm } from '../../profileInterfaces'
interface IProps {
  phone: string
  setVisiblePhone: (visible: boolean) => void
  setNewPhone: (phone: string) => void
}

function ChangePhoneToken ({ phone, setVisiblePhone, setNewPhone }: IProps) {
  const [loading, setLoading] = useState<boolean>(false)
  const handleSubmit = async (values: IChangeProfileConfirm) => {
    setLoading(true)
    try {
      const data = {
        type: 'ativacao-sms',
        confirmation_token: values.confirmation_token

      }
      await EcpUsersRepository.confirmToken(data)
      setNewPhone(phone)
      message.success('Telefone alterado com sucesso')
      setVisiblePhone(false)
    } catch (err) {
      message.error(err.message)
    } finally {
      setLoading(false)
    }
  }
  async function resendToken () {
    try {
      const data = {
        cellphone: phone
      }
      await EcpUsersRepository.updateEmailOrPhone(data)
    } catch (err) {
      message.error(err.message)
    }
  }
  return (
    <div>
      <h2 className="change-phone__title">Token enviado</h2>
      <TokenHeader
        infoText={`Foi enviado o token para o telefone ${phone}`}
      />

      <Form
        onFinish={handleSubmit}
        layout='horizontal'
        className='w-100 flex flex-column'
      >
        <div className="change-phone__token-content">
          <div className='find-user-token__bottom my-2'>
            <Form.Item
              name='confirmation_token'
              className='w-100 m-0'
            >
              <TokenVerification lenght={6} />
            </Form.Item>
          </div>
          <TokenResend
            onResend={() => resendToken()}
          />
        </div>
        <Button className="w-100 "
          type="primary"
          htmlType="submit"
          loading={loading}>
          Alterar
        </Button>
      </Form>
    </div>
  )
}

export default ChangePhoneToken

import { Select } from 'antd'
import React, { useState, useEffect, ReactNode } from 'react'
import MultiSelectModal, { IParams } from 'components/MultiSelectModal/MultiSelectModal'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { OptionGroupData } from 'rc-select/lib/interface'
import { SelectProps, SelectValue } from 'antd/lib/select'

export type IMultiSelectValue = string | number | boolean

export interface IOption extends Omit<OptionGroupData, 'key' | 'value'> {
  _id?: string
  label: string
  value: IMultiSelectValue
}
export type IOnChangeMultiSelect<T extends IOption = any> = (value:string[], options: T[]) => void

type _omitSelectProps = 'mode' | 'value' | 'options' | 'onChange' | 'defaultValue'
interface IMultiSelect<T extends IOption = any> extends Omit<SelectProps<T>, _omitSelectProps > {
  options: T[]
  initialValues?: Array<CheckboxValueType | string | MyObject>
  value?: Array<CheckboxValueType> | undefined
  defaultValue?: string[]
  defaultOption?: IOption
  renderOption?: (option: T) => ReactNode
  onChange?:IOnChangeMultiSelect<T>
  hideQuantity?: boolean
  showTags?: boolean
}

interface IMultiSelectInfinite<T extends IOption = any> extends IMultiSelect<T> {
  hasNextPage?: boolean
  isLoading?: boolean
  setParams?: React.Dispatch<React.SetStateAction<IParams>>
  error?: string | undefined
  searchedOptions?: T[]
}

export type IMulIMultiSelectProps<T extends IOption = any> = IMultiSelect<T> | IMultiSelectInfinite<T>

type MyObject = {
  value: string;
}

function MultiSelect<T extends IOption> (props: IMultiSelect<T> | IMultiSelectInfinite<T>) {
  const { options, onChange, initialValues, value, disabled, renderOption, defaultValue = [], getPopupContainer, loading, hideQuantity, showTags = false } = props
  const [visibleModal, setVisibleModal] = useState(false)
  const [checkboxValues, setCheckboxValues] = useState<Array<CheckboxValueType> | undefined>()

  const handleClick = () => {
    if (!disabled) setVisibleModal(true)
  }

  const handleCloseModal = () => {
    setVisibleModal(false)
  }

  const handleConfirm = (values: Array<CheckboxValueType> = []) => {
    setCheckboxValues(values)
    const optionSelecteds = values?.flatMap(item =>
      options?.filter(opt => opt?.value === item)
    )

    const formattedValues = values?.map(item => String(item))

    if (onChange) onChange(formattedValues, optionSelecteds)
    handleCloseModal()
  }

  const conditionalProps = showTags ? {
    value: checkboxValues as SelectValue ?? []
  } : {}

  useEffect(() => {
    const defaultValues = value ?? initialValues

    const formattedValues = defaultValues?.map(item =>
      typeof item === 'object' && 'value' in item ? (item as MyObject).value : item
    )

    if (Array.isArray(defaultValues)) {
      setCheckboxValues(formattedValues)
    }
  }, [value, initialValues])

  if (visibleModal) {
    return (
      <MultiSelectModal
        options={options}
        initialValues={checkboxValues}
        open={visibleModal}
        onConfirm={handleConfirm}
        onCancel={handleCloseModal}
        renderOption={renderOption}
        hasNextPage={'hasNextPage' in props ? props.hasNextPage : undefined}
        isLoading={'isLoading' in props ? props.isLoading : undefined}
        setParams={'setParams' in props ? props.setParams : undefined}
        error={'error' in props ? props.error : undefined}
        searchedOptions={'searchedOptions' in props ? props.searchedOptions : undefined}
      />
    )
  }

  return (
    <>
      <Select
        showArrow
        open={false}
        mode='multiple'
        maxTagCount='responsive'
        maxTagTextLength={20}
        className='w-100'
        onClick={handleClick}
        loading={loading}
        placeholder='Clique para ver as opções'
        options={options as unknown as OptionGroupData[] }
        disabled={loading || disabled}
        defaultValue={defaultValue}
        getPopupContainer={getPopupContainer}
        {...conditionalProps}
      />

      {!hideQuantity && <p className='multi-select__number-of-selecteds'>Selecionados: {checkboxValues?.length || 0}</p>}
    </>
  )
}

export default MultiSelect

import { swalError } from 'components/SwalError/SwalError'
import { IError } from 'ecp/app/Proposals/proposalInterfaces'
import ProposalRepository from 'ecp/repositories/ProposalRepository'
import { IResponseBase } from 'ecp/repositories/Repository'
import ProposalEgiRepository from 'egi/repositories/ProposalEgiRepository'
import UsersEgiRepository, { AccessManagementAction, AccessManagementProducts, IAccessManagerBackoffice } from 'egi/repositories/UsersEgiRepository'
import { useAuth } from 'hooks'
import { useState } from 'react'
import format from 'utils/format'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'

interface IAccessManagerParams {
  identifier:string
  search: string | undefined
}

interface IAccessManagerBackofficesParams {
  identifier:string
  search: string | undefined
}

export enum AccessManagerEnum {
  client = 'client',
  backoffice = 'backoffice'
}

export default function useAccessManager () {
  const auth = useAuth()
  const [isLoading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [accessDetails, setBackoffices] = useState<Array<Object>>([])
  const [params, setParams] = useState<IAccessManagerParams>({
    identifier: 'cpf',
    search: ''
  })

  const getAccessDetails = async (params:IAccessManagerParams) => {
    setLoading(true)
    setErrorMessage('')
    setBackoffices([])

    try {
      if (!params.search) throw new Error('Pesquise um usuário para obter suas propostas.')

      const data: IAccessManagerParams = {
        ...params,
        search: format.onlyDigits(params.search || '')
      }

      let response: IResponseBase<{proposal: Array<any>}> | undefined
      if (auth.selectedProduct?.slug === 'home-equity') response = await ProposalEgiRepository.accessClientDetails({ params: data })
      if (auth.selectedProduct?.slug === 'ecp') response = await ProposalRepository.accessClientDetails({ params: data })

      if (!response) throw new Error('Não foi possível obter dados sobre sua pesquisa.')
      const { proposal } = response.data.data || {}
      if (!proposal || !Array.isArray(proposal)) throw new Error(`Não foi possível encontrar informações para o ${params.identifier} informado, tente novamente mais tarde`)

      setBackoffices(proposal)
    } catch (err) {
      console.log(err)
      if (err.message) setErrorMessage(err.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    getAccessDetails,
    isLoading,
    errorMessage,
    params,
    setParams,
    accessDetails
  }
}

export function useAccessManagerBackofficeList () {
  const [isLoading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [backoffices, setBackoffices] = useState<Array<Object>>([])
  const [params, setParams] = useState<IAccessManagerBackofficesParams>({
    identifier: 'cpf',
    search: ''
  })

  const getbackoffices = async (params:IAccessManagerBackofficesParams) => {
    setLoading(true)
    setErrorMessage('')
    setBackoffices([])

    try {
      const data: IAccessManagerBackofficesParams = {
        ...params,
        search: format.onlyDigits(params.search || '')
      }

      const response = await UsersEgiRepository.getAccessmanagersBackofficeList({ params: data })
      if (!response) throw new Error('Não foi possível obter dados sobre sua pesquisa.')
      const { backoffices } = response.data.data || {}

      if (!backoffices || !Array.isArray(backoffices)) throw new Error(`Não foi possível encontrar informações para o ${params.identifier} informado, tente novamente mais tarde`)

      setBackoffices(backoffices)
    } catch (err) {
      console.log(err)
      if (err.message) setErrorMessage(err.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    getbackoffices,
    isLoading,
    errorMessage,
    params,
    setParams,
    backoffices
  }
}

export function useAccessManagerBackoffice () {
  const [isLoading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [backoffice, setBackoffice] = useState<IAccessManagerBackoffice>()

  const getbackoffices = async (id?: string) => {
    setLoading(true)
    setErrorMessage('')

    try {
      if (!id) throw new Error('Identificador de usuário não encontrado.')

      const response = await UsersEgiRepository.getAccessmanagersBackoffice(id)
      if (!response) throw new Error('Não foi possível obter dados do backoffice')
      const { backoffice } = response.data.data || {}

      if (!backoffice) throw new Error('Não foi possível encontrar backoffice')

      setBackoffice(backoffice)
    } catch (err) {
      console.log(err)
      if (err.message) setErrorMessage(err.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    getbackoffices,
    isLoading,
    errorMessage,
    backoffice
  }
}

export function useAccessManagerBlockBackoffice (userIsBlocked?: boolean) {
  const [isBlocked, setIsBlocked] = useState(userIsBlocked)
  const [loading, setLoading] = useState(false)

  const onBlock = ({ newValue, id, onFinish } : {newValue: boolean, id?: string, onFinish?: () => void}) => {
    swal.confirmNegate({
      title: 'Atenção',
      text: `Você realmente deseja ${newValue ? 'bloquear' : 'Desbloquear'} esse usuário?`,
      confirmButtonText: `${newValue ? 'Bloquear' : 'Desbloquear'}`,
      cancelButtonText: 'Cancelar',
      icon: 'warning',
      confirm: async () => {
        try {
          setLoading(true)
          if (!id) throw new Error('Identificador de usuário não encontrado.')
          const data = {
            action: AccessManagementAction.UserAccess,
            blocked: newValue
          }

          const response = await UsersEgiRepository.updateBackoffice(id, data)
          if (response.data.message) swal.basic({ title: 'Sucesso!', text: response.data.message, icon: 'success' })
          setIsBlocked(newValue)
          onFinish && onFinish()
        } catch (err) {
          console.error(err)
          swalError({ title: 'Atenção', icon: 'warning', err })
        } finally {
          setLoading(false)
        }
      }
    })
  }

  return {
    isBlocked,
    loading,
    onBlock
  }
}

export function useAccessManagerCancelBackoffice () {
  const [loading, setLoading] = useState(false)

  const onCancel = (id?: string, onFinish?: () => void) => {
    swal.confirmNegate({
      title: 'Atenção',
      text: 'Você realmente deseja cancelar esse usuário?',
      confirmButtonText: 'Cancelar usuário',
      cancelButtonText: 'Desfazer',
      icon: 'warning',
      confirm: async () => {
        try {
          setLoading(true)
          if (!id) throw new Error('Identificador de usuário não encontrado.')
          const data = {
            action: AccessManagementAction.UserAccess,
            canceled: true
          }

          const response = await UsersEgiRepository.updateBackoffice(id, data)
          if (response.data.message) swal.basic({ title: 'Sucesso!', text: response.data.message, icon: 'success' })
          onFinish && onFinish()
        } catch (err) {
          console.error(err)
          swalError({ title: 'Atenção', icon: 'warning', err })
        } finally {
          setLoading(false)
        }
      }
    })
  }

  return {
    loading,
    onCancel
  }
}

export function useAccessManagerBackofficeUpdateProducts () {
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<IError<{products: string}>>({})

  const onUpdateProducts = async ({ products, id, onFinish } : {products?: Array<AccessManagementProducts>, id?: string, onFinish?: () => void}) => {
    try {
      setLoading(true)
      if (!id) throw new Error('Identificador de usuário não encontrado.')
      const data = {
        action: AccessManagementAction.UserProducts,
        products
      }

      const response = await UsersEgiRepository.updateBackoffice(id, data)
      if (response.data.message) swal.basic({ title: 'Sucesso!', text: response.data.message, icon: 'success' })
      onFinish && onFinish()
    } catch (err) {
      console.error(err)
      if (err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
      }
      swalError({ title: 'Atenção', icon: 'warning', err })
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    onUpdateProducts,
    errors
  }
}

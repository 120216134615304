
import React, { ReactNode } from 'react'

function SelectSvg ({ icon }: { icon: ReactNode }) {
  return (
    <div>
      <svg viewBox="0 0 140 140" version="1.1" className="review-radio__select-svg">
        <defs>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur">
            </feGaussianBlur><feColorMatrix in="blur" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo"></feColorMatrix>
            <feBlend in="SourceGraphic" in2="goo"></feBlend>
          </filter>
          <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="linearGradient-1">
            <stop stopColor="#C25068" offset="0%"></stop>
            <stop stopColor="#9E3B5C" offset="100%"></stop>
          </linearGradient>
        </defs>
        <g className="gooey" fill="#00441F" >
          <circle className="dropTop" r="10" cx="70" cy="12"></circle>
          <circle className="drop" r="40" cx="70" cy="70"></circle>
          <path className="ring0" d="M70,140 C31.3400675,140 0,108.659932 0,70 C0,31.3400675 31.3400675,0 70,0 C108.659932,0 140,31.3400675 140,70 C140,108.659932 108.659932,140 70,140 Z M70,129.552239 C102.889793,129.552239 129.552239,102.889793 129.552239,70 C129.552239,37.1102067 102.889793,10.4477612 70,10.4477612 C37.1102067,10.4477612 10.4477612,37.1102067 10.4477612,70 C10.4477612,102.889793 37.1102067,129.552239 70,129.552239 Z" id="Combined-Shape"></path>
          <path className="ring" d="M70,140 C31.3400675,140 0,108.659932 0,70 C0,31.3400675 31.3400675,0 70,0 C108.659932,0 140,31.3400675 140,70 C140,108.659932 108.659932,140 70,140 Z M70,129.552239 C102.889793,129.552239 129.552239,102.889793 129.552239,70 C129.552239,37.1102067 102.889793,10.4477612 70,10.4477612 C37.1102067,10.4477612 10.4477612,37.1102067 10.4477612,70 C10.4477612,102.889793 37.1102067,129.552239 70,129.552239 Z" id="Combined-Shape"></path>
          <circle className="toggler" fill=
            "rgba(255,255,255,0)" r="120" cx="70" cy="70"></circle>
        </g>
      </svg>
      <div className="radio-container">
        {icon}
      </div>
    </div>
  )
}

export default SelectSvg

import { _userLevel } from 'ecp/models/UsersModel'

const SLAModel = {

  isInputDisabled (userLevel: _userLevel): boolean {
    return userLevel === 'auditor'
  }

}

export default SLAModel

import { AxiosResponse } from 'axios'
import { ConnectionFailed, ErrInternetDisconnected } from 'egi/consumers/errors'
import api from 'services/api'

const proposalList = {
  setActiveStatus: async (proposalId: string) => {
    try {
      const response: AxiosResponse = await api.patch(`/proposals/reactivate/${proposalId}`)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  }
}

export default proposalList

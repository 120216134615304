import React, { useLayoutEffect, useState } from 'react'
import { Form, Select } from 'antd'
import { serverTableSelectProps } from 'utils/globals'
import ResourcesRepository from 'egi/repositories/ResourcesRepository'

const sortedOptions = (options: { _id: string, promoterBusinessManager: string }[]) => {
  return options.sort((current, next) =>
    (current.promoterBusinessManager > next.promoterBusinessManager) ? 1 : ((next.promoterBusinessManager > current.promoterBusinessManager) ? -1 : 0))
}

function FilterBusinessManager ({ onChange, defaultValue }: { onChange: (value: string | string[] | undefined) => void, defaultValue?: string[] }) {
  const [managerOptions, setManagerOptions] = useState<{ _id: string, promoterBusinessManager: string }[]>()

  const getBusinessManagers = async () => {
    try {
      const params = { level: 'promoter' }
      const response = await ResourcesRepository.getBusinessManagers({ params })
      setManagerOptions(response.data.data.inviters)
    } catch (err) {
      console.warn(err)
    }
  }

  useLayoutEffect(() => {
    getBusinessManagers()
  }, [])

  return (
    <Form.Item
      name="businessManager"
      label={<label>Gestor de negócios</label>}
    >
      <Select
        {...serverTableSelectProps}
        allowClear
        placeholder="Escolha o gestor de negócios"
        defaultValue={defaultValue}
        onChange={onChange}
      >
        {managerOptions && sortedOptions(managerOptions).map((manager: { _id: string, promoterBusinessManager: string }) => (
          <Select.Option key={manager._id} value={manager._id}>{manager.promoterBusinessManager}</Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default FilterBusinessManager

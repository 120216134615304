import React from 'react'
import { Form, Select } from 'antd'

import { ISelectCategoryDrawerProps } from './types'
import { ILabelValue } from 'store/modules/resources/types'

export function SelectCategoryDrawer ({ userLevel, category, errors, setCategory, userArea }: ISelectCategoryDrawerProps) {
  const commonErrorOptions: ILabelValue[] = [
    { value: 'Reportar | Erro', label: 'Reportar | Erro' },
    { value: 'Reportar | Sugestão', label: 'Reportar | Sugestão' }
  ]

  const commonOptions: ILabelValue[] = [
    { value: 'Dúvidas | Geral', label: 'Dúvidas | Geral' },
    { value: 'Dúvidas | Funções do sistema', label: 'Dúvidas | Funções do sistema' }
  ]

  const clientOptions: ILabelValue[] = [
    ...commonOptions,
    ...commonErrorOptions
  ]

  const nonClientOptions = [
    ...commonOptions,
    { value: 'Dúvidas | Usuários e acessos', label: 'Dúvidas | Usuários e acessos' },
    { value: 'Dúvidas | Relatórios', label: 'Dúvidas | Relatórios' },
    { value: 'Dúvidas | Extratos e pagamentos', label: 'Dúvidas | Extratos e pagamentos' },
    ...commonErrorOptions
  ]

  const masterOptions: ILabelValue[] = [
    ...commonOptions,
    { value: 'Dúvidas | Usuários e acessos', label: 'Dúvidas | Usuários e acessos' },
    { value: 'Dúvidas | Cadastro de parceiros', label: 'Dúvidas | Cadastro de parceiros' },
    { value: 'Dúvidas | Relatórios', label: 'Dúvidas | Relatórios' },
    { value: 'Dúvidas | Extratos e pagamentos', label: 'Dúvidas | Extratos e pagamentos' },
    ...commonErrorOptions
  ]

  const adminCondiction = userLevel && userArea && userLevel === 'administrator'
    // eslint-disable-next-line no-mixed-operators
    ? userArea === 'juridico' || userArea === 'comercial' || userArea === 'engenharia' && true : false

  const optionsType: ILabelValue[] =
        userLevel && userLevel === 'master'
          ? masterOptions
          : userLevel && userLevel.includes('client') ? clientOptions
            : adminCondiction ? masterOptions
              : nonClientOptions

  return (
    <Form.Item
      name="category"
      help={errors.category && (errors.category)}
      validateStatus={errors.category ? 'error' : ''}
      label={<label>Categoria</label>}
      rules={[{ required: true, message: 'Favor selecione uma categoria.' }]}
    >
      <Select
        id="test-form-select-renda"
        placeholder='Escolha a categoria'
        value={category}
        options={optionsType}
        onChange={(value: ILabelValue) => setCategory(value)}
      />
    </Form.Item>
  )
}

import { Col, Form, Row, Select } from 'antd'
import { Drawer, Input } from 'components'
import { useResources } from 'hooks'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { resourcesGetBanks } from 'store/modules/resources/actions'
import usePartnerDocumentation from '../../../../hooks/usePartnerDocumentation'
import usePartnerDepartments from '../../../../hooks/usePartnerDepartments'
import PartnerStepButton from '../../../PartnerStepButton/PartnerStepButton'
import usePartnerStep from '../../../../hooks/usePartnerStep'
import usePartnerStepDrawer from '../../../../hooks/usePartnerStepDrawer'
import { IBankData, IBankDataErrors } from './types'
import swal from 'utils/swal'
import { swalError } from 'components/SwalError/SwalError'
import { validateResponse } from 'utils/validate'
import ReviewStep from '../../../ReviewStep/ReviewStep'
import ReviewDescription from '../../../ReviewDescription/ReviewDescription'
import PartnerModel from 'egi/models/PartnerModel'
import PartnersRepository from 'egi/repositories/PartnersRepository'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'

function BankData () {
  const [errors, setErrors] = useState<IBankDataErrors>({})
  const [status, setStatus] = useState<'back' | 'saving' | 'sending'>()

  const { step } = usePartnerStep()
  const { close, open, visible } = usePartnerStepDrawer()
  const { _id: documentId } = usePartnerDocumentation()
  const { slug: departmentSlug } = usePartnerDepartments()
  const [formRef] = Form.useForm<IBankData>()
  const resources = useResources()
  const dispatch = useDispatch()

  const sendForm = PartnerModel.canSendForm(step)

  useEffect(() => {
    if (resources.banks.length < 1) dispatch(resourcesGetBanks())
  }, [resources.banks])

  useEffect(() => {
    setErrors({})
    function onFill (form: IBankData) {
      formRef.setFieldsValue(form)
    }

    if (step.form) {
      onFill(step.form as IBankData)
    }
  }, [])

  async function onSubmit (values: IBankData) {
    setStatus('sending')
    if (values && values.account) values.account = values.account.replace(/[^0-9x]/ig, '')
    if (values && values.agency) values.agency = values.agency.replace(/\D/g, '')

    const data = {
      department: departmentSlug,
      step: step.slug,
      ...values
    }

    try {
      const response = await PartnersRepository.send(documentId, data)
      close()
      swal.basic({ icon: 'success', title: 'Sucesso!', text: response.data.message })
    } catch (err) {
      console.error(err)
      if (err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
      } else {
        swalError({ title: 'Atenção', icon: 'warning', err })
      }
    }
    setStatus(undefined)
  }

  return (
    <Fragment>
      <PartnerStepButton
        status={step.status}
        enabled={true}
        onClick={open}
        step={step}
      >
        {step.name}
      </PartnerStepButton>
      <Drawer
        visible={visible}
        onClose={close}
        onSubmit={() => formRef.submit()}
        showSubmit={true}
        disabled={!sendForm}
        title={step.name}
        status={status}
      >
        <Form
          form={formRef}
          layout="vertical"
          onFinish={onSubmit}
        >

          <Row>
            <Col className="px-1" lg={8} sm={24} xs={24}>
              <Form.Item
                name='bank'
                label={<label>Banco</label>}
                help={errors?.bank && (errors?.bank)}
                validateStatus={errors?.bank && ('error')}
              >
                <Select
                  showSearch
                  placeholder='Escolha o banco'
                  optionFilterProp="children"
                  aria-readonly={!sendForm}
                  disabled={!sendForm}
                >
                  {(resources.banks).map(bank => (
                    <Select.Option id={bank.name} key={bank.code} value={bank.code}>{bank.code} - {bank.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col className="px-1" lg={8} sm={24} xs={24}>
              <Form.Item
                name='account'
                label={<label>Conta</label>}
                help={errors?.account && (errors?.account)}
                validateStatus={errors?.account && ('error')}
              >
                <Input placeholder='Digite a conta' mask='account' readOnly={!sendForm} />
              </Form.Item>
            </Col>

            <Col className="px-1" lg={8} sm={24} xs={24}>
              <Form.Item
                name='agency'
                label={<label>Agência</label>}
                help={errors?.agency && (errors?.agency)}
                validateStatus={errors?.agency && ('error')}
              >
                <Input placeholder='Digite a agência' mask='agency' readOnly={!sendForm} />
              </Form.Item>
            </Col>

          </Row>
        </Form>

        <div className="my-4">
          <ReviewDescription />
        </div>

        <ReviewStep onSuccess={close} />
      </Drawer>
    </Fragment>
  )
}

export default BankData

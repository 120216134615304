import React, { Fragment } from 'react'
import { Col, Row } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import StringToHtml from 'components/StringToHtml/StringToHtml'
import usePartnerStep from '../../hooks/usePartnerStep'

export default function ReviewDescription () {
  const { step } = usePartnerStep()
  const stepReviewDescription = step.description

  if (!stepReviewDescription) return null

  return (
    <Fragment>
      <Row>
        <Col span={24} className='review-description__content upload-button-size-explain p-2'>
          <span className='w-100 font-size-15 bold'><ExclamationCircleOutlined style={{ color: 'red' }}/> Descrição da última revisão:</span>
          <p className='review-description__text'><StringToHtml value={stepReviewDescription || ''}/></p>
        </Col>
      </Row>
    </Fragment>
  )
}

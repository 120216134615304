import { R$ } from 'ecp/app/Proposals/proposalFunctions'
import { IDynamicProposalStep } from 'ecp/app/Proposals/proposalInterfaces'
import { ECPF_OPTIONS, PhoneValuesEnum } from 'ecpf/app/ProposalECPF/ProposalECPFConstants'
import { returnProposalType } from 'ecpf/app/ProposalECPF/ProposalECPFFunctions'
import { IClientCovenantECPF } from 'ecpf/repositories/CovenantECPFRepository'
import { ContractLogSlugEnum, contractLogStatusEnum, IContractLogs, ProposalECPFTipoConsulta } from 'ecpf/repositories/ProposalECPFRepository'
import { IAuth } from 'egi/types'
import { ECPF } from 'routes/ecpf/constants'
import { IReduxProposalFlow } from 'store/modules/proposalFlow/actions'
import format from 'utils/format'
import mask from 'utils/masks'
import { timeAsDayjs } from 'utils/time'
import { isNullish } from 'utils/utils'
import { CodigoDespesa, ICondicao, ICondicaoCredito, IDadoCadastral, IDadoTelefonesECPF, IEnderecoItem, IProposalDespesasItemECPF, IProposalSubmissionBody, IProposalSubmissionDataBody, ISimulationECPFBody, ISimulationECPFResponse, MetaCalculo, portabildaOperacaoECPF, ProposalType } from '../app/ProposalECPF/ProposalECPFInterfaces'

export type IResourceOptions = {
  label: string
  value: string
  key: string
}

export const removeKeysFromResource = (resources: Array<IResourceOptions>) => {
  return resources.map(resource => ({ label: resource.label, value: resource.value }))
}

export enum ProposalCustomerFlowClientType {
  // If the client exist in the /convenios/cliente/:cpf endpoint
  employer = 'employer',

  // If the client exist in the client get, but is not found at /convenios/cliente/:cpf endpoint
  clientButNotEmployer = 'clientButNotEmployer',

  // doesn't exist in the client and in the employer
  newClient = 'newClient',

  // If the client exist in the client get, and exists at /convenios/cliente/:cpf endpoint
  clientWithEmployer = 'clientWithEmployer'
}

const ProposalFuncaoModel = {

  canShowHoleriteDescription: (userLevel: IAuth['level'], step: IDynamicProposalStep) => {
    return ['corbanFuncao', 'operatorFuncao'].includes(userLevel) && step?.canUploadFile
  },

  translateCCBLogSlugStarting: (status: contractLogStatusEnum, attempt: number) => {
    switch (status) {
      case contractLogStatusEnum.pending:
        return 'Não iniciado'
      case contractLogStatusEnum.inProgress:
        return 'Iniciando processo...'
      case contractLogStatusEnum.failed:
        return attempt >= 10 ? 'Falha ao iniciar processo' : 'Tentando iniciar novamente'
      case contractLogStatusEnum.success:
        return 'Processo iniciado'
      default:
        return status
    }
  },

  translateCCBLogSlugChecklist: (status: contractLogStatusEnum, attempt: number) => {
    switch (status) {
      case contractLogStatusEnum.pending:
        return 'Inserção no check list não iniciada'
      case contractLogStatusEnum.inProgress:
        return 'Inserindo no checklist'
      case contractLogStatusEnum.failed:
        return attempt >= 10 ? 'Falha ao inserir no checklist' : 'Aguardando reenvio no checklist'
      case contractLogStatusEnum.success:
        return 'Documento inserido no checklist'
      default:
        return status
    }
  },

  translateCCBLogSlugSignature: (status: contractLogStatusEnum, attempt: number) => {
    switch (status) {
      case contractLogStatusEnum.pending:
        return 'Assinatura não enviada'
      case contractLogStatusEnum.inProgress:
        return 'Pendendente de assinatura'
      case contractLogStatusEnum.failed:
        return attempt >= 10 ? 'Falha ao receber assinatura' : 'Aguardando retentativa de assinatura'
      case contractLogStatusEnum.success:
        return 'Assinatura recebida'
      default:
        return status
    }
  },

  translateCCBLogSlug (log: IContractLogs) {
    switch (log?.slug) {
      case ContractLogSlugEnum.starting:
        return this.translateCCBLogSlugStarting(log.status, log?.attempts || 0)
      case ContractLogSlugEnum.checklist:
        return this.translateCCBLogSlugChecklist(log.status, log?.attempts || 0)
      case ContractLogSlugEnum.signature:
        return this.translateCCBLogSlugSignature(log.status, log?.attempts || 0)
      default:
        return log?.slug
    }
  },

  translateContractSlugStatusToStepStatus: (status: contractLogStatusEnum) => {
    if (!status) return undefined
    switch (status) {
      case contractLogStatusEnum.pending:
        return 'wait'
      case contractLogStatusEnum.inProgress:
        return 'process'
      case contractLogStatusEnum.success:
        return 'finish'
      case contractLogStatusEnum.failed:
        return 'error'
      default:
        return status
    }
  },

  contractSlugStatusToProposalStatus: (status: contractLogStatusEnum) => {
    if (!status) return ''
    switch (status) {
      case contractLogStatusEnum.pending:
        return 'starting'
      case contractLogStatusEnum.inProgress:
        return 'inProgress'
      case contractLogStatusEnum.success:
        return 'approved'
      case contractLogStatusEnum.failed:
        return 'reproved'
      default:
        return status
    }
  },

  generateRedirectProposalFlow (proposalType: ProposalType, flowType: ProposalCustomerFlowClientType) {
    return {
      pathname: '/auth' + ECPF.PROPOSAL_FLOW.path.replace(':proposalType', proposalType).replace(':cpf?', ''),
      search: `?flowType=${flowType}`
    }
  },

  isNewClient: (client?: IReduxProposalFlow['client']) => {
    if (client?.codigo) return false
    return true
  },

  canShowStepBackButton: (currentStep: number) => currentStep > 0,

  isAnyRequiredClientInformationsInvalid: (fields: ISimulationECPFBody['cliente']) => {
    const {
      dataAdmissao,
      dataNascimento,
      documento,
      nome,
      numeroMatricula,
      valorRenda
    } = fields || {}

    return isNullish(dataAdmissao) ||
      isNullish(dataNascimento) ||
      isNullish(documento) ||
      isNullish(nome) ||
      isNullish(numeroMatricula) ||
      isNullish(valorRenda)
  },

  findPossibleValueInOptions (options: Array<IResourceOptions>, value?: string) {
    return options.find(option => option.label === value || String(option.key) === value || option.value === value)
  },

  pickCorrectValueTipoPessoa (tipoPessoa?: string) {
    return this.findPossibleValueInOptions(ECPF_OPTIONS.TIPO_PESSOA_OPTIONS, tipoPessoa)?.value
  },

  pickCorrectOptionTipoPessoa (tipoPessoa?: string) {
    return this.findPossibleValueInOptions(ECPF_OPTIONS.TIPO_PESSOA_OPTIONS, tipoPessoa)
  },

  pickCorrectValueEstadoCivil (estadoCivil?: string) {
    return this.findPossibleValueInOptions(ECPF_OPTIONS.ESTADO_CIVIL_OPTIONS, estadoCivil)?.value
  },

  pickCorrectOptionEstadoCivil (estadoCivil?: string) {
    return this.findPossibleValueInOptions(ECPF_OPTIONS.ESTADO_CIVIL_OPTIONS, estadoCivil)
  },

  pickCorrectValueDocumento (documento?: string) {
    return this.findPossibleValueInOptions(ECPF_OPTIONS.TIPO_DOCUMENTO_OPTIONS, documento)?.value
  },

  pickCorrectOptionDocumento (documento?: string) {
    return this.findPossibleValueInOptions(ECPF_OPTIONS.TIPO_DOCUMENTO_OPTIONS, documento)
  },

  pickCorrectValueTipoConta (tipoConta?: string) {
    return this.findPossibleValueInOptions(ECPF_OPTIONS.TIPO_CONTA_OPTIONS, tipoConta)?.value
  },

  pickCorrectOptionTipoConta (tipoConta?: string) {
    return this.findPossibleValueInOptions(ECPF_OPTIONS.TIPO_CONTA_OPTIONS, tipoConta)
  },

  pickCorrectValueTipoEndereco (tipoEndereco?: string) {
    return this.findPossibleValueInOptions(ECPF_OPTIONS.TIPO_ENDERECO_OPTIONS, tipoEndereco)?.value
  },

  pickCorrectOptionTipoReferencia (tipoConta?: string) {
    return this.findPossibleValueInOptions(ECPF_OPTIONS.REFERENCIA_TYPE_OPTIONS, tipoConta)
  },

  pickCorrectValueTipoReferenciaTelefone (tipoConta?: string) {
    return this.findPossibleValueInOptions(ECPF_OPTIONS.REFERENCIA_PHONE_TYPE_OPTIONS, tipoConta)?.value
  },

  pickCorrectOptionTipoReferenciaTelefone (tipoConta?: string) {
    return this.findPossibleValueInOptions(ECPF_OPTIONS.REFERENCIA_PHONE_TYPE_OPTIONS, tipoConta)
  },

  pickCorrectValueTipoReferencia (tipoEndereco?: string) {
    return this.findPossibleValueInOptions(ECPF_OPTIONS.REFERENCIA_TYPE_OPTIONS, tipoEndereco)?.value
  },

  pickCorrectValueTipoCorrespondencia (tipoEndereco?: string) {
    return this.findPossibleValueInOptions(ECPF_OPTIONS.TIPO_CORRESPONDENCIA_OPTIONS, tipoEndereco)?.value
  },

  pickCorrectOptionTipoCorrespondencia (tipoEndereco?: string) {
    return this.findPossibleValueInOptions(ECPF_OPTIONS.TIPO_CORRESPONDENCIA_OPTIONS, tipoEndereco)
  },

  pickCorrectOptionTipoEndereco (tipoEndereco?: string) {
    return this.findPossibleValueInOptions(ECPF_OPTIONS.TIPO_ENDERECO_OPTIONS, tipoEndereco)
  },

  pickCorrectValueEscolaridade (escolaridade?: string) {
    return this.findPossibleValueInOptions(ECPF_OPTIONS.ESCOLARIDADE_OPTIONS, escolaridade)?.value
  },

  pickCorrectOptionEscolaridade (escolaridade?: string) {
    return this.findPossibleValueInOptions(ECPF_OPTIONS.ESCOLARIDADE_OPTIONS, escolaridade)
  },

  pickCorrectValueNacionalidade (nacionalidade?: string) {
    return this.findPossibleValueInOptions(ECPF_OPTIONS.NACIONALIDADE_OPTIONS, nacionalidade)?.value
  },

  pickCorrectOptionNacionalidade (nacionalidade?: string) {
    return this.findPossibleValueInOptions(ECPF_OPTIONS.NACIONALIDADE_OPTIONS, nacionalidade)
  },

  pickCorrectValueSexo (sexo?: string) {
    return this.findPossibleValueInOptions(ECPF_OPTIONS.SEXO_OPTIONS, sexo)?.value
  },

  pickCorrectOptionSexo (sexo?: string) {
    return this.findPossibleValueInOptions(ECPF_OPTIONS.SEXO_OPTIONS, sexo)
  },

  pickCorrectValueTipoTelefone (telefone?: string) {
    return this.findPossibleValueInOptions(ECPF_OPTIONS.PHONE_TYPE_OPTIONS, telefone)?.value
  },

  pickCorrectOptionTipoTelefone (telefone?: string) {
    return this.findPossibleValueInOptions(ECPF_OPTIONS.PHONE_TYPE_OPTIONS, telefone)
  },

  translateMetaCalculoByKey (key: number) {
    const possibles: { [key: number]: MetaCalculo } = {
      1: MetaCalculo.ValorSolicitado,
      2: MetaCalculo.ValorParcela
    }

    return possibles[key]
  },

  pickCorrectMetaCalculo (metaCalculo: MetaCalculo | number | undefined) {
    if (Number.isInteger(metaCalculo)) return this.translateMetaCalculoByKey(metaCalculo as number)
    return metaCalculo
  },

  generateCompleteCellphone (contact?: Array<IDadoTelefonesECPF>) {
    const firstCellphone = contact?.find(item => item.tipo === PhoneValuesEnum.Celular)

    return this.joinDDDAndPhone(firstCellphone)
  },

  joinDDDAndPhone (telefone?: IDadoTelefonesECPF) {
    if (!telefone) return null
    if (!telefone.ddd) return null
    return `${telefone.ddd}${telefone.numeroTelefone ?? telefone.numero ?? '-'}`
  },

  formatEmiterCellphone (telefone?: IDadoTelefonesECPF) {
    const phoneNumber = telefone?.numero ?? telefone?.numeroTelefone
    if (!telefone || !phoneNumber) return 'número inválido'

    const phoneNumberOnlyDigits = format.onlyDigits(phoneNumber)
    if (phoneNumberOnlyDigits.length > 9) return phoneNumber

    const phoneNumberPLusDDD = `${telefone.ddd}${phoneNumberOnlyDigits}`

    return this.formatPhoneNumberByType(phoneNumberPLusDDD, telefone?.tipo)
  },

  formatPhoneNumberByType (phoneNumber: string, tipo?: string) {
    if (tipo === PhoneValuesEnum.FoneFisico) return mask(phoneNumber, 'phone')
    return mask(phoneNumber, 'cell')
  },

  pickCorrectValorFinanciado (condicaoCredito?: ICondicaoCredito) {
    return condicaoCredito?.valorPrincipal
  },

  formatedValorFinanciado (condicaoCredito?: ICondicaoCredito) {
    if (!condicaoCredito) return '-'
    return R$(this.pickCorrectValorFinanciado(condicaoCredito))
  },

  pickCorrectValorLiberado (condicaoCredito?: ICondicaoCredito) {
    return condicaoCredito?.valorCliente
  },

  formatValorLiberado (condicaoCredito?: ICondicaoCredito) {
    if (!condicaoCredito) return '-'
    return R$(this.pickCorrectValorLiberado(condicaoCredito))
  },

  concatEnderecoItemECPF (endereco: IEnderecoItem) {
    let enderecoString = ''

    Object.entries(endereco).map(([, value], idx) => {
      if (!value) return false
      if (idx === 0) {
        enderecoString = value
        return true
      }

      enderecoString += `, ${value}`
      return true
    })

    return enderecoString
  },

  proposalTypeToMeioLiberacao (proposalType: ProposalType) {
    const possibles: { [key in ProposalType]: string } = {
      [ProposalType.new]: '001',
      [ProposalType.refinancing]: '001',
      [ProposalType.renegotiation]: '001',
      [ProposalType.portability]: '033'
    }

    return possibles[proposalType]
  },

  canSendRefinancialOperation (proposalType: ProposalType) {
    const validProposalTypes = [ProposalType.refinancing, ProposalType.renegotiation]
    const isValidProposalType = validProposalTypes.includes(proposalType)
    return isValidProposalType
  },

  formatRefinancingOperations (selectContractsFlow?: IReduxProposalFlow['selectContractsFlow']) {
    return selectContractsFlow?.selectedContracts?.map(contract => ({
      numeroOperacao: contract
    }))
  },

  parcelaOrSolicitadoFactory (conditional: ISimulationECPFBody['condicaoCredito'] | ICondicao) {
    const isValorSolicitado = this.pickCorrectMetaCalculo(conditional?.metaCalculo) === MetaCalculo.ValorSolicitado

    if (isValorSolicitado) {
      return {
        ...conditional,
        valorSolicitado: undefined,
        valorParcela: conditional?.valorParcela
      }
    }

    return {
      ...conditional,
      valorParcela: undefined,
      valorSolicitado: conditional?.valorSolicitado
    }
  },

  despesasSeguroFilter (formatedDespesas: IProposalDespesasItemECPF[], seguro?: boolean) {
    const isentar = seguro === false ? true : false

    if (isentar) {
      const filteredDespesas = formatedDespesas.filter(despesa => despesa.codigoTipo === CodigoDespesa.valorSeguro)
      return filteredDespesas
    }

    return formatedDespesas
  },

  formatCreateBody ({
    proposal,
    conditional,
    proposalType,
    isNewClient,
    client,
    portabilitySimulationFlowData,
    selectContractsFlow
  }: IProposalSubmissionBody) {
    const dadosBancarios = proposal?.dadoCadastral?.dadosBancarios?.itens?.map(item => ({
      ...item,
      dataAbertura: format.brDateToDate(item?.dataAbertura)
    }))

    const telefones = proposal?.dadoCadastral?.telefones?.itens?.map(item => ({
      ...item,
      numero: format.onlyDigits(item?.numero ?? item?.numeroTelefone ?? ''),
      numeroTelefone: format.onlyDigits(item?.numeroTelefone ?? item?.numero ?? '')
    }))

    // Max length for the field bairro is 10 in the FuncaoAPI
    const enderecos = proposal?.dadoCadastral?.dadoEndereco?.enderecos?.itens?.map(item => ({
      ...item,
      cep: format.onlyNumber(item?.cep),
      bairro: item.bairro?.slice(0, 10),
      complemento: item.complemento?.slice(0, 10)
    }))

    const {
      quantidadeParcelas,
      valorSolicitado,
      valorPrincipal,
      valorParcela,
      valorBruto,
      valorIOF,
      valorLiquido,
      valorCliente,
      dataPrimeiroVencimento,
      dataUltimoVencimento,
      taxaCETAM,
      taxaCETAA,
      taxaAPAM,
      taxaAPAA,
      taxaCLAM,
      taxaCLAA,
      taxaNominalAM,
      taxaNominalAA,
      metaCalculo,
      despesas
    } = conditional

    const hasSeguro = despesas.itens.some(despesa => despesa.codigoTipo === CodigoDespesa.valorSeguro && !despesa.isentar)
    const filteredDespesas = this.despesasSeguroFilter(despesas?.itens ?? [], hasSeguro)

    const conditionalFactory = this.parcelaOrSolicitadoFactory({
      quantidadeParcelas,
      valorSolicitado,
      valorParcela,
      valorPrincipal,
      valorBruto,
      valorIOF,
      valorLiquido,
      valorCliente,
      dataPrimeiroVencimento,
      dataUltimoVencimento,
      taxaCETAM,
      taxaCETAA,
      taxaAPAM,
      taxaAPAA,
      taxaCLAM,
      taxaCLAA,
      taxaNominalAM,
      taxaNominalAA,
      metaCalculo,
      despesas: { itens: filteredDespesas },
      meioLiberacaoCliente: this.proposalTypeToMeioLiberacao(proposalType)
    })

    const tipoCliente = isNewClient ? 'Informado' : 'Existente'

    const portabilityDataFormated = {
      ...portabilitySimulationFlowData,
      dataBaseContrato: format.brDateToDate(portabilitySimulationFlowData?.dataBaseContrato),
      dataVencimento: format.brDateToDate(portabilitySimulationFlowData?.dataVencimento)
    }

    const refinancingOperations = this.formatRefinancingOperations(selectContractsFlow)

    const proposalBody: IProposalSubmissionDataBody = {
      ...proposal,
      origens: proposal?.origens,

      tipoCliente,
      tipoOperacao: returnProposalType(proposalType),

      documento: format.onlyDigits(proposal?.dadoCadastral?.documento ?? ''),

      condicaoCredito: {
        ...conditionalFactory,
        codigoConvenio: conditional?.convenio?.codigoConvenio,
        codigoProduto: conditional?.produto?.codigoProduto
      },

      // We can only send the dadoCadastral if the client is new
      // Otherwise we'll get the CPF and matricula error
      dadoCadastral: isNewClient ? {
        ...proposal.dadoCadastral,

        documento: format.onlyNumber(proposal?.dadoCadastral?.documento),
        dataNascimento: format.brDateToDate(proposal?.dadoCadastral?.dataNascimento),
        dataEmissaoDocumento: format.brDateToDate(proposal?.dadoCadastral?.dataEmissaoDocumento),
        dadosBancarios: { itens: dadosBancarios },
        numeroDocumento: format.onlyNumber(proposal?.dadoCadastral?.numeroDocumento),

        telefones: { itens: telefones },

        // Enviar sempre 0 - Solicitação Rodobens
        valorRendaOutros: 0,

        // Enviar sempre false - Solicitação Rodobens
        pep: false,

        dadoProfissional: {
          ...proposal?.dadoCadastral?.dadoProfissional,
          dataAdmissao: format.brDateToDate(proposal?.dadoCadastral?.dadoProfissional?.dataAdmissao)
        },

        dadoEndereco: {
          ...proposal?.dadoCadastral?.dadoEndereco,
          tipoEnderecoCorrespondencia: enderecos?.[0]?.tipo,
          enderecos: { itens: enderecos }
        }
      } : undefined,
      codigoCliente: client?.codigo,

      ...(proposalType === ProposalType.portability && portabilityDataFormated ? {
        portabilidade: {
          operacoes: [portabilityDataFormated]
        }
      } : {}),

      ...(this.canSendRefinancialOperation(proposalType) && refinancingOperations ? {
        refinanciamento: {
          operacoes: refinancingOperations
        }
      } : {})
    }

    return proposalBody
  },

  formatDadosCadastral (proposalFlow: IReduxProposalFlow) {
    const { client, customerFlow } = proposalFlow || {}

    const dadosBancarios = client?.bancario?.itens?.map(item => ({
      ...item,
      tipoConta: ProposalFuncaoModel.pickCorrectValueTipoConta(item.tipoConta),
      dataAbertura: timeAsDayjs(format.brDateToDate(item.dataAbertura)).format('DD/MM/YYYY')
    }))

    const telefones = client?.telefone?.itens?.map(item => ({
      ...item,
      numeroTelefone: format.onlyDigits(item.numero),
      tipo: ProposalFuncaoModel.pickCorrectValueTipoTelefone(item?.tipo)
    }))

    const enderecos = client?.dadoEndereco?.endereco?.itens?.map(item => ({
      ...item,
      tipo: ProposalFuncaoModel.pickCorrectValueTipoEndereco(item?.tipo)
    }))

    const referencias = client?.referencia?.itens?.map(item => ({
      ...item,
      tipoTelefone: ProposalFuncaoModel.pickCorrectValueTipoReferenciaTelefone(item?.tipoTelefone),
      tipo: ProposalFuncaoModel.pickCorrectValueTipoReferencia(item?.tipo)
    }))

    const trueCorrespondencia = enderecos?.[0]?.tipo

    const dadoCadastral: IDadoCadastral = {
      ...client,
      documento: format.onlyNumber(client?.documento),
      numeroDocumento: format.onlyNumber(client?.numeroDocumento),
      naturalidadeUF: client?.naturalidade || client?.naturalidadeUF,
      dataNascimento: timeAsDayjs(format.brDateToDate(client?.dataNascimento)).format('DD/MM/YYYY'),
      dataEmissaoDocumento: timeAsDayjs(format.brDateToDate(client?.dataEmissaoDocumento)).format('DD/MM/YYYY'),
      telefones: { itens: telefones },
      telefone: { itens: telefones },
      dadosBancarios: { itens: dadosBancarios },

      tipoPessoa: ProposalFuncaoModel.pickCorrectValueTipoPessoa(client?.tipoPessoa),
      estadoCivil: ProposalFuncaoModel.pickCorrectValueEstadoCivil(client?.estadoCivil),
      nacionalidade: ProposalFuncaoModel.pickCorrectValueNacionalidade(client?.nacionalidade),
      escolaridade: ProposalFuncaoModel.pickCorrectValueEscolaridade(client?.escolaridade),

      dadoEndereco: {
        ...client?.dadoEndereco,
        tipoCorrespondencia: ProposalFuncaoModel.pickCorrectOptionTipoCorrespondencia(trueCorrespondencia)?.value,
        tipoEnderecoCorrespondencia: ProposalFuncaoModel.pickCorrectOptionTipoCorrespondencia(trueCorrespondencia)?.value,
        enderecos: { itens: enderecos },
        endereco: { itens: enderecos }
      },

      referencia: { itens: referencias },
      dadoProfissional: {
        ...client?.dadoProfissional,
        empresaCNPJ: client?.dadoProfissional?.empresaDocumento ?? customerFlow?.cliente?.cnpj,
        dataAdmissao: timeAsDayjs(format.brDateToDate(client?.dadoProfissional?.dataAdmissao)).format('DD/MM/YYYY')
      }
    }

    return dadoCadastral
  },

  translateTipoConsulta: (value: ProposalECPFTipoConsulta) => {
    switch (value) {
      case ProposalECPFTipoConsulta.Andamento:
        return 'Em Andamento'
      case ProposalECPFTipoConsulta.CanceladasIntegradas:
        return 'Integrada / Cancelada'
      default:
        return value
    }
  }
}

export interface IProposalSimulationMinMaxEcpf {
  minValorParcela: number,
  maxValorParcela: number,

  minValorSolicitado: number,
  maxValorSolicitado: number,

  maxQuantidadeParcelas: number,
  despesas: ICondicao['despesas']
}

export const ProposalCustomerFlowFuncaoModel = {
  ...ProposalFuncaoModel,

  isValidCnpj: (clientCovenant?: IReduxProposalFlow['clientCovenant']) => {
    if (!clientCovenant?.convenios) return false
    return Boolean(clientCovenant?.convenios?.length > 0)
  },

  canShowSearchByCNPJ: (proposalType: ProposalType, proposalCustomerFlowClientType?: ProposalCustomerFlowClientType) => {
    if (proposalCustomerFlowClientType === ProposalCustomerFlowClientType.employer) return false

    return [ProposalType.new, ProposalType.portability].includes(proposalType)
  },

  canShowRenda: (proposalType: ProposalType, userLevel: IAuth['level'], proposalCustomerFlowClientType?: ProposalCustomerFlowClientType) => {
    if (userLevel === 'client') return true

    if (proposalCustomerFlowClientType === ProposalCustomerFlowClientType.clientButNotEmployer) return false
    if (proposalCustomerFlowClientType === ProposalCustomerFlowClientType.employer) return false

    return [ProposalType.new, ProposalType.portability].includes(proposalType)
  },

  canSeeTooltipRenda: (userLevel: IAuth['level']) => {
    return ['corbanFuncao', 'operatorFuncao'].includes(userLevel)
  },

  shouldDisabledFields (proposalType: ProposalType, isValidCnpj: boolean, proposalCustomerFlowClientType?: ProposalCustomerFlowClientType) {
    if (proposalCustomerFlowClientType === ProposalCustomerFlowClientType.clientButNotEmployer) return true
    if (proposalCustomerFlowClientType === ProposalCustomerFlowClientType.employer) return true
    if (proposalCustomerFlowClientType === ProposalCustomerFlowClientType.clientWithEmployer) return true

    if (!isValidCnpj) return true

    if (proposalType !== ProposalType.new || isValidCnpj) return false
    return false
  },

  shouldDisableNameAndCpfFields (userLevel: IAuth['level'], proposalType: ProposalType, isValidCnpj: boolean, proposalCustomerFlowClientType?: ProposalCustomerFlowClientType) {
    const disabledFields = this.shouldDisabledFields(proposalType, isValidCnpj, proposalCustomerFlowClientType)

    return disabledFields || userLevel === 'client'
  },

  shouldDisableNextStepButton: (proposalType: ProposalType, isValidCnpj: boolean, proposalCustomerFlowClientType?: ProposalCustomerFlowClientType) => {
    if (proposalCustomerFlowClientType === ProposalCustomerFlowClientType.clientButNotEmployer) {
      if (isValidCnpj) return false

      return true
    }

    const clientTypesThatAreNotDisabled = [ProposalCustomerFlowClientType.employer, ProposalCustomerFlowClientType.clientWithEmployer]

    if (proposalCustomerFlowClientType !== undefined && clientTypesThatAreNotDisabled.includes(proposalCustomerFlowClientType)) return false

    if (!isValidCnpj) return true
    if (proposalType !== ProposalType.new || isValidCnpj) return false

    return false
  }
}

export const ProposalSimulationFuncaoModel = {
  ...ProposalFuncaoModel,

  simulationDespesasFactory (despesa: IProposalDespesasItemECPF, simulation: ISimulationECPFBody) {
    if (despesa.codigoTipo === CodigoDespesa.valorSeguro) {
      const isentar = simulation.condicaoCredito?.seguro === false ? true : false

      const { valor, ...rest } = despesa
      return {
        ...rest,
        ...(isentar ? {} : { valor }),
        isentar
      }
    }

    return despesa
  },

  simulationReponseFactory (conditional: ISimulationECPFResponse, simulation: ISimulationECPFBody): ISimulationECPFResponse {
    const mapped = conditional.condicoes.map(condicao => {
      const despesas = condicao.despesas?.itens?.map((despesa) => this.simulationDespesasFactory(despesa, simulation))

      condicao.despesas = {
        itens: despesas
      }

      return condicao
    })

    return { condicoes: mapped }
  },

  getSeguroInCondicaoCredito (conditional?: ICondicao) {
    if (!conditional) return true

    const despesas = conditional.despesas?.itens

    const despesa = despesas?.find(despesa => despesa.codigoTipo === CodigoDespesa.valorSeguro)
    if (!despesa) return true

    return !despesa.isentar
  },

  getOnlyClientCovenantInSimulationResponse (credito: ISimulationECPFResponse, simulation: ISimulationECPFBody) {
    const onlySentCovenants = (credito.condicoes ?? []).filter(
      condicao => {
        const hasCovenant = (simulation.convenios ?? []).find(
          convenio => convenio.codigo === condicao.convenio?.codigoConvenio
        )

        return hasCovenant
      }
    )

    return onlySentCovenants
  },

  formatToSend ({
    condicaoCredito,
    customerFlow,
    clientCovenant,
    proposalType,
    portabilityOperation,
    selectContractsFlow,
    despesas
  }: {
    condicaoCredito: ISimulationECPFBody['condicaoCredito'],
    customerFlow: IReduxProposalFlow['customerFlow'],
    clientCovenant: IReduxProposalFlow['clientCovenant'],
    proposalType: ProposalType,
    despesas?: ICondicao['despesas'],
    portabilityOperation?: portabildaOperacaoECPF
    selectContractsFlow?: IReduxProposalFlow['selectContractsFlow']
  }) {
    const { cliente } = customerFlow || {}
    const { convenios, origens } = clientCovenant || {}

    const dataNascimento = format?.brDateToDate(cliente?.dataNascimento || '')
    const dataAdmissao = format?.brDateToDate(cliente?.dataAdmissao || '')

    const refinancingOperations = this.formatRefinancingOperations(selectContractsFlow)

    const parcelaOrSolicitadoFactoryInfo = { condicaoCredito: ProposalSimulationFuncaoModel.parcelaOrSolicitadoFactory(condicaoCredito) } as ISimulationECPFBody

    const formatedDespesas = (despesas?.itens ?? []).map(
      despesa => this.simulationDespesasFactory(
        despesa,
        parcelaOrSolicitadoFactoryInfo
      )
    )

    const filteredDespesas = ProposalFuncaoModel.despesasSeguroFilter(formatedDespesas, parcelaOrSolicitadoFactoryInfo?.condicaoCredito?.seguro)

    const simulation: ISimulationECPFBody = {
      convenios,
      origens,
      tipoOperacao: returnProposalType(proposalType),
      naoCalcularComissao: 'NaoDefinido',
      cliente: {
        ...cliente,
        dataNascimento,
        dataAdmissao
      },
      condicaoCredito: ProposalSimulationFuncaoModel.parcelaOrSolicitadoFactory(condicaoCredito),
      ...(portabilityOperation ? { portabilidade: { operacoes: [portabilityOperation] } } : {}),
      ...(selectContractsFlow ? { refinanciamento: { operacoes: refinancingOperations } } : {}),
      ...(despesas && condicaoCredito?.seguro === false ? {
        despesas: {
          despesas: filteredDespesas
        }
      } : {})
    }

    return simulation
  },

  generateMinMaxSimulation (
    maxValorParcela: number,
    maxValorSolicitado: number,
    maxQuantidadeParcelas: number,
    despesas: ICondicao['despesas']
  ): IProposalSimulationMinMaxEcpf {
    return {
      minValorParcela: 50,
      maxValorParcela,

      minValorSolicitado: 300,
      maxValorSolicitado,

      maxQuantidadeParcelas,
      despesas
    }
  }

}

export const ProposalListFuncaoModel = {
  ...ProposalFuncaoModel,

  canFilterByCorbans (auth: IAuth) {
    return ['corbanFuncao', 'master', 'operatorFuncao'].includes(auth?.level)
  },

  canFilterByCovenant (auth: IAuth) {
    return ['corbanFuncao', 'master', 'operatorFuncao'].includes(auth?.level)
  }
}

export const ProposalFuncaoRenegotiationModel = {
  ...ProposalFuncaoModel,
  isValidClientCovenant: (clientCovenant: Partial<IClientCovenantECPF> | undefined): boolean => {
    if (
      !clientCovenant?.origens?.codigoEmpregador ||
      !clientCovenant?.origens?.codigoPromotor ||
      !clientCovenant?.origens?.codigoOrgao
    ) {
      return false
    }
    return true
  }
}

export default ProposalFuncaoModel

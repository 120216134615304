
import { Repository } from 'ecp/repositories/Repository'

import api from 'services/api'

class SimulationECPFRepository extends Repository<any> {

}

export default new SimulationECPFRepository({ api, path: '/ecp-funcao/simulation' })

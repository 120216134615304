import React, { useState, useEffect } from 'react'
import { Button, Checkbox, Col, Form, Radio, Row, Select, Typography } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import { useAuth, useClient, useSimulation } from 'hooks'
import { Input, InputMoney } from 'components'
import { correctTimezone, timeAsDayjs } from 'utils/time'
import locale from 'antd/es/date-picker/locale/pt_BR'
import { simulationSteps, simulationValues } from 'store/modules/simulation/actions'
import { simulation as consumerSimulation } from 'egi/consumers'
import swal from 'utils/swal'
import { useDispatch } from 'react-redux'
import format from 'utils/format'
import dayjs, { Dayjs } from 'dayjs'
import Datepicker from 'components/Datepicker/Datepicker'
import { ISimulatorCommonStep } from '../../simulatorInterfaces'
import { tablesUpdate } from 'store/modules/tables/actions'
import LeadsRepository from 'egi/repositories/LeadsRepository'
import { validateResponse } from 'utils/validate'
import { invalidSimulationFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'

type ISimulatorClientInfoStepValues = {
  personType: string
  bacen: boolean
  birthdate?: string | Dayjs
  foundationDate?: string | Dayjs
  cpf?: string
  cnpj?: string
  currentStep: number
  income: number
  simulationId?: string
}

function SimulatorClientInfoStep ({ simulatorData, remaking, onlyCEP, isAuth }: ISimulatorCommonStep) {
  const [errors, setErrors] = useState<any>({})
  const [personType, setPersonType] = useState<string>('fisica')
  const [loading, setLoading] = useState<boolean>(false)
  const [bacen, setBacen] = useState<boolean>(false)

  const [formRef] = Form.useForm()
  const user = useAuth()
  const client = useClient()
  const simulation = useSimulation()
  const dispatch = useDispatch()

  const isClientDisabled = isAuth && user.level === 'client' && personType === 'fisica' ? true : false
  const disabledInput = (remaking || onlyCEP) && personType === 'fisica'

  const onSubmit = async (values: ISimulatorClientInfoStepValues) => {
    const allSimulationValues = Object.assign({}, simulation.values)

    if (allSimulationValues.cpf) delete allSimulationValues.cpf
    if (allSimulationValues.cnpj) delete allSimulationValues.cnpj
    if (allSimulationValues.bacen) delete allSimulationValues.bacen
    if (allSimulationValues.birthdate) delete allSimulationValues.birthdate

    if (!values.birthdate) {
      if (user.level === 'client') values.birthdate = timeAsDayjs(user.birthdate).format('YYYY-MM-DD')
      if (user.level !== 'client') values.birthdate = timeAsDayjs(client.birthdate).format('YYYY-MM-DD')
    }

    if (values.birthdate) values.birthdate = correctTimezone(String(values.birthdate))

    if (values.foundationDate) {
      values.foundationDate = correctTimezone(String(values.foundationDate))
      delete values.birthdate
    }

    if (values.cpf) values.cpf = format.onlyDigits(values.cpf)
    if (values.cnpj) values.cnpj = format.onlyDigits(values.cnpj)
    if (values.bacen) values.bacen = bacen
    if (values.income) values.income = format.formatMoneySend(String(values.income))
    if (!values.personType) values.personType = personType

    const data: ISimulatorClientInfoStepValues = {
      ...allSimulationValues,
      ...values,
      simulationId: simulation.id || simulatorData?._id,
      currentStep: 3
    }

    setLoading(true)
    try {
      const response = await consumerSimulation.simulation({ ...data, deadline: undefined, creditValue: undefined })
      const productId = response.data.simulation.productId
      const responseData = { ...data, productId: productId }
      dispatch(simulationValues(responseData, 3))
      setLoading(false)
      swal.basic({ title: 'Sucesso', text: response.message, icon: 'success' })
    } catch (err) {
      if (err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
        invalidSimulationFieldsError({ invalidFields: err.data?.invalid })
        return
      }
      if (err?.data?.needsVinculation) {
        swal.confirmNegate({
          icon: 'question',
          title: 'Atenção',
          text: 'Você gostaria de se vincular a essa proposta?',
          confirm: async () => {
            try {
              if (!simulation?.values?._id) throw new Error('Esta proposta não está válida para alteração')
              const response = await LeadsRepository.changeCommercial(simulation?.values?._id, { commercialId: user._id })
              const message = response.data.message

              swal.basic({ title: 'Sucesso!', text: message, icon: 'success' })
              dispatch(tablesUpdate())
              dispatch(simulationSteps(simulation.step))
            } catch (err) {
              swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
            }
          }
        })
      }
      setErrors({})
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    function onFill () {
      const data = Object.assign({}, simulation.values) || {}

      if (data.birthdate && dayjs(data.birthdate).isValid()) data.birthdate = timeAsDayjs(data.birthdate, { applyTimezone: false }).format('DD/MM/YYYY')
      if (data.foundationDate && dayjs(data.foundationDate).isValid()) data.foundationDate = timeAsDayjs(data.foundationDate, { applyTimezone: false }).format('DD/MM/YYYY')

      if (data.personType) setPersonType(data.personType)
      if (data.bacen) setBacen(data.bacen)

      if (isAuth && user.level === 'client') {
        if (user.cpf) data.cpf = user.cpf
        if (user.cnpj) data.cnpj = user.cnpj
        if (user.birthdate && dayjs(user.birthdate).isValid()) data.birthdate = timeAsDayjs(user.birthdate, { applyTimezone: false }).format('DD/MM/YYYY')
      } else {
        if (client.cpf) data.cpf = client.cpf
        if (client.cnpj) data.cnpj = client.cnpj
        if (client.birthdate && dayjs(client.birthdate)) data.birthdate = timeAsDayjs(client.birthdate, { applyTimezone: false }).format('DD/MM/YYYY')
      }

      if (formRef) formRef.setFieldsValue({ ...data })
    }

    onFill()
  }, [simulation.values])

  return (
    <Form
      layout="vertical"
      className="simulator-form"
      form={formRef}
      onFinish={onSubmit}
      initialValues={{
        bacen: false
      }}
    >
      <Form.Item
        name='personType'
        label={<label className="simulator-label">Tipo de pessoa:</label>}
      >
        <Radio.Group
          onChange={(event: RadioChangeEvent) => setPersonType(event.target.value)}
          className="w-100 simulator-radio"
          defaultValue="fisica"
          size="large"
          buttonStyle="outline"
          disabled={remaking}
        >
          <Radio.Button id="simulator-radio-itens-fisica" className="simulator-radio-itens-values" value="fisica">Física</Radio.Button>
          <Radio.Button id="simulator-radio-itens-juridica" className="simulator-radio-itens-values" value="juridica">Jurídica</Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name={personType === 'fisica' ? 'birthdate' : 'foundationDate'}
        label={<label className="simulator-label">{personType === 'fisica' ? 'Data de nascimento' : 'Data de fundação'}</label>}
        help={errors.birthdate && errors.birthdate}
        validateStatus={errors.birthdate && 'error'}
      >
        {user.isAuth && user.level === 'client' && personType === 'fisica' ? (
          <Datepicker
            disabled={true}
            className="unauth-simulator-date"
            placeholder="Ex: DD/MM/YYYY"
            locale={locale}
          />
        ) : (
          <Datepicker
            disabled={disabledInput}
            className="unauth-simulator-date"
            placeholder="Ex: DD/MM/YYYY"
            locale={locale}
          />
        )}
      </Form.Item>

      <Form.Item
        name={personType === 'fisica' ? 'cpf' : 'cnpj'}
        label={<label className="simulator-label">{personType === 'fisica' ? 'CPF' : 'CNPJ'}</label>}
        help={(errors.cpf && errors.cpf) || (errors.cnpj && errors.cnpj)}
        validateStatus={(errors.cpf || errors.cnpj) && ('error')}
      >
        <Input
          placeholder={personType === 'fisica' ? '_ _ _-_ _ _-_ _ _-_ _' : '_ _ -_ _ _ -_ _ _ / _ _ _ _ - _ _'}
          className="simulator-input ant-input"
          disabled={isClientDisabled || disabledInput}
          mask={personType === 'fisica' ? 'cpf' : 'cnpj'}
        />
      </Form.Item>

      <Form.Item
        name='income'
        label={<label className="simulator-label">{personType === 'fisica' ? 'Renda familiar:' : 'Faturamento:'}</label>}
        help={errors.income && errors.income}
        validateStatus={errors.income && 'error'}
      >
        <InputMoney
          id="income"
          className="simulator-input ant-input"
          placeholder='R$'
        />
      </Form.Item>

      {personType === 'fisica' && (
        <Form.Item
          name="employmentType"
          label={<label className="simulator-label">Ocupação</label>}
          help={errors.employmentType && errors.employmentType}
          validateStatus={errors.employmentType && 'error'}
        >
          <Select
            id="test-select-simulation-employmentType"
            placeholder="Selecione uma opção"
            className="unauth-inputs-center"
          >
            <Select.Option id="test-select-simulation-employmentType-assalariado" value="assalariado">Assalariado(a)</Select.Option>
            <Select.Option id="test-select-simulation-employmentType-funcionarioPublico" value="funcionarioPublico">Funcionário(a) Público(a)</Select.Option>
            <Select.Option id="test-select-simulation-employmentType-aposentado" value="aposentado">Aposentado(a)</Select.Option>
            <Select.Option id="test-select-simulation-employmentType-liberal" value="liberal">Profissional Liberal</Select.Option>
            <Select.Option id="test-select-simulation-employmentType-autonomo" value="autonomo">Autônomo(a)</Select.Option>
            <Select.Option id="test-select-simulation-employmentType-empresario" value="empresario">Empresário(a)</Select.Option>
            <Select.Option id="test-select-simulation-employmentType-rural" value="rural">Produtor(a) Rural</Select.Option>
          </Select>
        </Form.Item>
      )}

      {(!user.isAuth || user.level === 'client') && !remaking && (
        <Row>
          <Col lg={25} sm={24} xs={24}>
            <Form.Item
              name="bacen"
              valuePropName="checked"
              help={errors.bacen && errors.bacen}
              validateStatus={errors.bacen && 'error'}
            >
              <Typography.Paragraph className="unauth-terms-label">
                <Checkbox
                  className="px-1 checkbox-lgpd"
                  checked={bacen}
                  onChange={value => {
                    if (value) setBacen(value.target.checked)
                    if (!value) setBacen(false)
                  } }
                />
                <a target="_blank" rel="noreferrer" href="https://agxbucket.s3.sa-east-1.amazonaws.com/BACEN.pdf" className="color-primary">Aceito o termo de consulta ao SCR</a>
              </Typography.Paragraph>
            </Form.Item>
          </Col>
        </Row>
      )}

      <Form.Item>
        <Button
          id="finishButton"
          htmlType="submit"
          className="simulator-calculation-button w-100"
          loading={loading}
        >
          PRÓXIMA ETAPA
        </Button>

        <Button
          className="mt-4 color-primary simulator-specialist-button w-100"
          style={{ height: '50px !important' }}
          onClick={() => { dispatch(simulationSteps(simulation.step - 1)) }}
          type="ghost"
        >
          VOLTAR
        </Button>
      </Form.Item>
    </Form>
  )
}

export default SimulatorClientInfoStep

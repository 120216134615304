import { AxiosRequestConfig } from 'axios'
import { IProduct } from 'ecp/models/ProductModel'
import { IResponseBase, Repository } from 'ecp/repositories/Repository'
import api from 'services/api'

class ResourcesRepository extends Repository {
  async states (config?: AxiosRequestConfig) {
    return Repository.handle<{ states: Array<string> }>(() =>
      this.api.get(`${this.path}/states`, config)
    )
  }

  async declinesPatch (data: { productId: string, values: Array<string>}, config?: AxiosRequestConfig) {
    return Repository.handle<IResponseBase>(() =>
      this.api.put(`${this.path}/reproval`, data, config)
    )
  }

  async getAdministrators (config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle<IResponseBase>(() =>
      this.api.get(`${this.path}/admnistrators`, config)
    )
  }

  async getAdministratorsByArea (areaName:string, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle<IResponseBase>(() =>
      this.api.get(`${this.path}/admnistrators/area/${areaName}`, config)
    )
  }

  async departments (config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle<IResponseBase>(() =>
      this.api.get(`${this.path}/departments`, config)
    )
  }

  async areaNames (config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle<IResponseBase>(() =>
      this.api.get(`${this.path}/areasnames`, config)
    )
  }

  async steps (config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle<IResponseBase>(() =>
      this.api.get(`${this.path}/steps`, config)
    )
  }

  async getAllManagers (config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle<IResponseBase>(() =>
      this.api.get(`${this.path}/managers`, config)
    )
  }

  async getAllProducts (config?: AxiosRequestConfig) {
    return Repository.handle<{ products: Array<IProduct> }>(() =>
      this.api.get(`${this.path}/products`, config)
    )
  }

  async getBusinessManagers (config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle<IResponseBase>(() =>
      this.api.get(`${this.path}/inviters`, config)
    )
  }
}

export default new ResourcesRepository({ api, path: '/resources' })

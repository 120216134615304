import { _userLevel } from 'ecp/models/UsersModel'

const CreditModel = {

  canSeeHistory (userLevel: _userLevel, isAuth: boolean): boolean {
    return !(userLevel === 'client' || userLevel === 'promoter') && isAuth
  },

  canApprove (userLevel: _userLevel, isAuth: boolean): boolean {
    return (userLevel === 'client' || userLevel === 'promoter') || !isAuth
  },

  canRemakeApprove (userLevel: _userLevel): boolean {
    return userLevel !== 'client' && userLevel !== 'promoter' && userLevel !== 'auditor'
  }

}

export default CreditModel

import { AxiosRequestConfig } from 'axios'
import { IResponseBase, Repository } from 'ecp/repositories/Repository'

import api from 'services/api'

class DownloadModelsRepository extends Repository {
  showModel (config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/notary-fees/`, config)
    )
  }

  downloadModel (id: string, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/notary-fees/${id}`, config)
    )
  }

  uploadModel (file: FormData, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.post(`${this.path}/notary-fees`, file, config)
    )
  }
}

export const downLoadModelRepostory = new DownloadModelsRepository({ api, path: '/download-models' })

class PricingRepository extends Repository {
  uploadNotaryFees (file: FormData, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.post(`${this.path}/notary-fees/upload`, file, config)
    )
  }

  getFileStatus (id: string, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/notary-fees/status/${id}`, config)
    )
  }

  getLast (config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/notary-fees/last `, config)
    )
  }
}

export const pricingRepository = new PricingRepository({ api, path: '/pricings' })

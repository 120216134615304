import { Button, Col, Form, Row, Select } from 'antd'
import { qualification as qualificationConsumer } from 'egi/consumers'
import { IMinutaMold } from 'egi/types'
import { useSelectedProduct } from 'hooks'
import React, { useEffect, useState } from 'react'
import swal from 'utils/swal'

function CertificateTextSelect ({ proposalId, onGenerateFrameModel }: { proposalId?: string, onGenerateFrameModel: (value: string) => void }) {
  const { _id: productId } = useSelectedProduct()

  const [minutaMoldInfo, setMinutaMoldInfo] = useState<IMinutaMold[]>([])
  const [slug, setSlug] = useState('')
  const [loading, setLoading] = useState(false)

  async function getCertificateTexts () {
    setLoading(true)
    try {
      const params = { productId, type: 'cnd' }
      const response = await qualificationConsumer.minutaMold(params)
      const { values } = response?.data || {}

      if (values) {
        const minutaInfo = values.filter((item: IMinutaMold) => !item.blocked)
        setMinutaMoldInfo(minutaInfo)
      }
    } catch (err) {
      swal.basic({ title: 'Atenção', icon: 'warning', text: err.message })
    } finally { setLoading(false) }
  }

  const getText = async () => {
    setLoading(true)
    try {
      const params = { slug, proposalId }
      const response = await qualificationConsumer.previewMinutaMold(productId, params)
      const { preview } = response.data || {}

      if (preview)onGenerateFrameModel(preview)
    } catch (err) {
      swal.basic({ icon: 'warning', title: 'Atenção', text: err.message })
    } finally { setLoading(false) }
  }

  const onChange = (value: string) => { setSlug(value) }

  useEffect(() => { getCertificateTexts() }, [])

  return (
    <Row align='bottom' gutter={[15, 15]}>
      <Col span={16}>
        <Form.Item
          className='mb-0'
          label='Texto de Certidões'
          tooltip='Selecione um modelo de quadro do tipo "Texto de Certidões", após isso clique no botão "Gerar Modelo de Quadro" e a caixa de texto será preenchida com o modelo correspondente configurado em “Modelos de Quadros”.

          Esse modelo vai se repetir na ordem a), b), c), conforme a quantidade de vezes que o passo "Tributos Federais" aparecer na proposta.'
        >
          <Select
            placeholder='Escolha um modelo'
            onChange={onChange}
            loading={loading}
          >
            {minutaMoldInfo.map((item, idx) => (
              <Select.Option
                id={`test-select-${item.slug}`}
                value={item.slug}
                key={idx}
              >
                {item.description}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      <Col span={8}>
        <Button
          htmlType='button'
          type='primary'
          loading={loading}
          onClick={getText}
          className='w-100'
        >
          Gerar modelo de quadro
        </Button>
      </Col>
    </Row>
  )
}

export default CertificateTextSelect

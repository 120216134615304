import React, { Fragment, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { simulationSteps, simulationValues } from 'store/modules/simulation/actions'
import SimulatorClientInfoStep from './components/SimulatorClientInfoStep/SimulatorClientInfoStep'
import { useSimulation } from 'hooks'
import SimulatorResultStep from './components/SimulatorResultStep/SimulatorResultStep'
import SimulatorPropertyStep from './components/SimulatorPropertyStep/SimulatorPropertyStep'
import SimulationLoanInfoStep from './components/SimulatorLoanInfoStep/SimulatorLoanInfoStep'
import SimulatorCard from './components/SimulatorCard/SimulatorCard'
import SimulatorSteps from './components/SimulatorSteps/SimulatorSteps'
import SimulatorLeadInfo from './components/SimulatorLeadInfo/SimulatorLeadInfo'
import { ISimulatorParams } from './simulatorInterfaces'

function Simulator ({ callbackFunction, simulatorData, internalSimulator, callbackClient, step, remaking, onlyCEP, hasTerms, isAuth }: ISimulatorParams) {
  const simulation = useSimulation()
  const dispatch = useDispatch()
  useEffect(() => {
    if (simulatorData) { dispatch(simulationValues(simulatorData)) }
  }, [simulatorData])

  useEffect(() => {
    if (step) { dispatch(simulationSteps(step)) }
  }, [step])

  return (
    <Fragment>
      <SimulatorCard>
        <div className="flex mb-3">
          <SimulatorSteps
            quantity={5}
            onClick={(value) => value ? dispatch(simulationSteps(value)) : dispatch(simulationSteps(0))}
            counterStep={simulation.step}
            cannotClickStepZero={simulatorData && !onlyCEP ? true : false}
          />
        </div>

        {(simulation.step === 0) && (
          <SimulatorLeadInfo
            simulatorData={simulatorData}
            product="home-equity"
            onlyCEP={onlyCEP}
            hasTerms={hasTerms}
            isAuth={isAuth}
          />
        )}

        {(simulation.step === 1) && (
          <SimulatorPropertyStep
            isAuth={isAuth}
            remaking={remaking}
            simulatorData={simulatorData}
            onlyCEP={onlyCEP}
          />
        )}

        {(simulation.step === 2) && (
          <SimulatorClientInfoStep
            isAuth={isAuth}
            remaking={remaking}
            simulatorData={simulatorData}
            onlyCEP={onlyCEP}
          />
        )}

        {(simulation.step === 3) && (
          <SimulationLoanInfoStep
            simulatorData={simulatorData}
          />
        )}

        {(simulation.step === 4) && (
          <SimulatorResultStep
            isAuth={isAuth}
            callbackClient={callbackClient}
            callbackFunction={callbackFunction}
            internalSimulator={internalSimulator}
          />
        )}
      </SimulatorCard>
    </Fragment>
  )
}

export default Simulator

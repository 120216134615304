import React, { ReactNode, CSSProperties } from 'react'

interface IInfiniteScroll {
  loading: boolean
  more: boolean
  fetch: () => void
  children: ReactNode
  loadingCover: React.ReactElement
  root: Element | null
}

const style: CSSProperties = {
  width: 10,
  height: 10,
  visibility: 'hidden'
}

function InfiniteScroll ({ fetch, children, root }: IInfiniteScroll) {
  const [infiniteElement, SetInfiniteElement] = React.useState<HTMLDivElement | null>(null)

  const observer = React.useRef(
    new IntersectionObserver(entries => {
      const first = entries[0]
      if (first.isIntersecting === true) fetch()
    }, {
      root: root,
      rootMargin: '400px',
      threshold: 0
    })
  )

  React.useEffect(() => {
    const currentElement = infiniteElement
    const currentObserver = observer.current

    if (currentElement) {
      currentObserver.observe(currentElement)
    }

    return () => {
      if (currentElement) currentObserver.unobserve(currentElement)
    }
  }, [infiniteElement])

  return (
    <>
      {children}
      <div ref={SetInfiniteElement} style={style}></div>
    </>
  )
}

export default InfiniteScroll

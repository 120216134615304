import { resources as consumerResources } from 'egi/consumers'
import { useResources, useStep } from 'hooks'
import { useState } from 'react'

interface ICities {
  value: number
  label: string
}

interface IAddressReponse {
  zipcode: string
  street: string
  neighborhood: string
  city: string
  state: string
  ibge: string

  erro?: string
  unidade?: string
  additional?: string
  gia?: string
}

const useAddress = () => {
  const resources = useResources()
  const step = useStep()
  const [cities, setCities] = useState<ICities[]>([])
  const [country, setCountry] = useState('Brasil')

  async function viaCep (cep: string): Promise<IAddressReponse> {
    const response = await consumerResources.address(cep)

    return {
      zipcode: response.data.cep,
      street: response.data.logradouro,
      additional: response.data.complemento,
      neighborhood: response.data.bairro,
      city: response.data.localidade,
      state: response.data.uf,
      unidade: response.data.unidade,
      ibge: response.data.ibge,
      gia: response.data.gia
    }
  }

  async function cepAwesome (cep: string): Promise<IAddressReponse> {
    const response = await consumerResources.addressFallback(cep)
    const { address, district, city, state, city_ibge } = response.data || {}

    return {
      zipcode: response.data.cep,
      street: address,
      neighborhood: district,
      city: city,
      state: state,
      ibge: city_ibge
    }
  }

  async function fetchAddress (cep: string): Promise<IAddressReponse | null> {
    const finalCep = cep.replace(/\D/g, '')

    try {
      const viaCepReponse = await viaCep(finalCep)
      if (viaCepReponse) return viaCepReponse
    } catch (error) {
      const cepAwesomeReponse = await cepAwesome(finalCep)
      if (cepAwesomeReponse) return cepAwesomeReponse
    }

    return null
  }

  async function getAddress ({ cep, isProperty }: { cep: string, isProperty?: boolean }) {
    const param = cep.replace(/\D/g, '')

    if (param.length === 8) {
      try {
        const response = await fetchAddress(cep)
        if (!response) return

        let finalResources = resources.ibgeStates

        if (response.erro) return

        if (step.form?.address && (step.form.address.street || step.form.address.additional || step.form.address.neighborhood)) {
          if (!response.street) response.street = step.form?.address.street as string
          if (!response.additional) response.additional = step.form?.address.additional
          if (!response.neighborhood) response.neighborhood = step.form?.address.neighborhood as string
        }

        if (isProperty) {
          if (finalResources.length < 1) {
            const response = await consumerResources.statesIBGE()
            const statesIBGEFormated = response.map((values: any) => {
              const statesIBGE = {
                label: String(values.label),
                value: String(values.label),
                cities: [values.cities]
              }
              return statesIBGE
            })
            finalResources = statesIBGEFormated
          }

          const findUF: any = finalResources.find(element => element.label === response.state)
          if (findUF) {
            setCities(findUF.cities)
            interface City {
              value: number
              label: string
            }

            const findCity: City = findUF.cities.find((item: any) => item.label === response.city)
            return ({ ...response, state: findUF.label, city: findCity.label, country: 'Brasil' })
          }
        } else {
          return ({ ...response, country: 'Brasil' })
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  function updateCities (cities: ICities[]) {
    setCities(cities)
  }

  function updateCountry (country: string) {
    setCountry(country)
  }

  return {
    getAddress,
    updateCities,
    updateCountry,
    country,
    fetchAddress,
    cities
  }
}

export default useAddress

import React, { Fragment, useState } from 'react'
import { Button, Collapse, Radio, Select } from 'antd'
import { DatepickerCustom } from 'components'
import locale from 'antd/es/date-picker/locale/pt_BR'
import swal from 'utils/swal'
import { downloadCSVFile } from 'utils/file'
import { RadioChangeEvent } from 'antd/lib/radio'
import { timeAsDayjs } from 'utils/time'
import AGXReportsRepository from 'egi/repositories/AGXReportsRepository'
import { RiseOutlined, ShakeOutlined, SolutionOutlined } from '@ant-design/icons'
import { serverTableSelectProps } from 'utils/globals'

interface IOptions {
  startDate: string,
  endDate: string,
  status?: string | string[]
}

function AGXReports () {
  const [tempDate, setTempDate] = useState<Array<any>>([])
  const [dateIntervalType, setDateIntervalType] = useState<'month' | 'date'>('month')
  const [loading, setLoading] = useState<string | undefined>(undefined)

  const [proposalStatus, setProposalStatus] = useState<string | string[] | undefined>(undefined)

  const onSubmit = async (reportType: string) => {
    const options: IOptions = {
      startDate: tempDate[0],
      endDate: tempDate[1]
    }

    if (dateIntervalType === 'month') {
      options.startDate = timeAsDayjs(options.startDate).format('YYYY-MM')
      options.endDate = timeAsDayjs(options.endDate).format('YYYY-MM')
    }

    if (dateIntervalType === 'date') {
      options.startDate = timeAsDayjs(options.startDate).format('YYYY-MM-DD')
      options.endDate = timeAsDayjs(options.endDate).format('YYYY-MM-DD')
    }

    if (proposalStatus && reportType === 'proposals') {
      options.status = proposalStatus
    }

    setLoading(reportType)

    try {
      const response = await AGXReportsRepository.generate({ reportType: reportType, options: options })
      const csvDownload = response.data.data?.csv
      if (csvDownload) downloadCSVFile(csvDownload, reportType)
      setLoading(undefined)
    } catch (err) {
      setLoading(undefined)
      swal.basic({ icon: 'warning', title: 'Atenção', text: err.message })
    }
  }

  return (
    <Fragment>
      <div className="agx-report">
        <div className="agx-report__filter-container">
          <h1 className="agx-report__filter-title">Selecione o período do relatório</h1>

          <Radio.Group
            onChange={(event: RadioChangeEvent) => setDateIntervalType(event.target.value)}
            className="rounded-radio agx-report__radio agx-report__radio--width"
            defaultValue="month"
            size="large"
            buttonStyle="outline"
          >
            <Radio.Button className="simulator-radio-itens-values user-select" value="month">
              Mensal
            </Radio.Button>

            <Radio.Button className="simulator-radio-itens-values user-select" value="date">
              Diário
            </Radio.Button>
          </Radio.Group>

          <DatepickerCustom.RangePicker
            onCalendarChange={value => setTempDate([value?.[0], value?.[1]])}
            locale={locale}
            picker={dateIntervalType}
            format="DD/MM/YYYY"
            disabledDate={(current) => current.isAfter(new Date())}
            className="agx-report__picker--width"
          />
        </div>

        <div className="agx-report__card">
          <div className="agx-report__text-container">
            <h1>Relatório de propostas</h1>
            <p>Exporte dados gerais de propostas </p>

            <Collapse bordered={false}>
              <Collapse.Panel header="Mais filtros" key="1">
                <div className="agx-report__select-container">
                  <label>Status das propostas</label>
                  <Select
                    {...serverTableSelectProps}
                    allowClear
                    placeholder="Escolha o status"
                    onChange={(value: string | string[]) => setProposalStatus(value)}
                    options={[
                      { value: 'ongoing', label: 'Em andamento' },
                      { value: 'approved', label: 'Aprovado' },
                      { value: 'reproved', label: 'Reprovado' },
                      { value: 'canceled', label: 'Cancelado' },
                      { value: 'inactive', label: 'Inativo' }
                    ]}
                  />
                </div>
              </Collapse.Panel>
            </Collapse>

            <Button
              loading={loading === 'proposals'}
              type="primary"
              className="my-3"
              onClick={() => onSubmit('proposals')}
            >
              Gerar relatório
            </Button>
          </div>

          <div className="agx-report__image-container">
            <SolutionOutlined className="agx-report__icon" />
          </div>
        </div>

        <div className="agx-report__card">
          <div className="agx-report__text-container">
            <h1>Relatório de Leads</h1>
            <p>Exporte dados gerais dos leads </p>

            <Button
              type="primary"
              className="my-3"
              loading={loading === 'leads'}
              onClick={() => onSubmit('leads')}
            >
              Gerar relatório
            </Button>
          </div>

          <div className="agx-report__image-container">
            <RiseOutlined className="agx-report__icon" />
          </div>
        </div>

        <div className="agx-report__card">
          <div className="agx-report__text-container">
            <h1>Relatório de SMS</h1>
            <p>Exporte relatório de {'sms\'s'} enviados</p>

            <Button
              className="my-3"
              type="primary"
              loading={loading === 'sms'}
              onClick={() => onSubmit('sms')}
            >
              Gerar relatório
            </Button>
          </div>

          <div className="agx-report__image-container">
            <ShakeOutlined className="agx-report__icon" />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default AGXReports

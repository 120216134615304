import format from 'utils/format'
import CONVERTLIST, { ECP_CONVERT_LIST } from 'utils/convertList'
import { formatYear } from 'utils/date'
import { timeAsDayjs } from 'utils/time'
import { ColumnsType } from 'antd/lib/table'
import TableTooltip from 'components/TableTooltip/TableTooltip'
import { MutableRefObject } from 'react'
import { elementDimension } from 'utils/utils'
import masks from 'utils/masks'

const FIXCONVERTLIST: { [key: string]: any } = {
  ...CONVERTLIST,
  ...ECP_CONVERT_LIST,
  clientCpf: (value: string) => masks(String(value), 'cpf'),
  birthdate: (value: string) => timeAsDayjs(value, { applyTimezone: false }).format('DD/MM/YYYY'),
  dataAtividadeFormatada: (value: string) => value === '-' ? value : timeAsDayjs(value, { applyTimezone: false }).format('DD/MM/YYYY'),
  createdAt: (value: string) => formatYear(value),
  requestedDate: (value: string) => formatYear(value, { format: 'DD/MM/YYYY' }),
  updatedAt: (value: string) => formatYear(value),
  date: (value: string) => formatYear(value),
  value: (value: string) => format.formatBRL(Number(value))
}

function convertPropertsObject (item: Object) {
  const COPY_ITEM: any = Object.assign({}, item)
  const KEYS_COPY_ITEM = Object.keys(COPY_ITEM)

  const possibles = Object.keys(FIXCONVERTLIST)

  for (let i = 0; i < KEYS_COPY_ITEM.length; i++) {
    for (let j = 0; j < possibles.length; j++) {
      if (possibles[j] === KEYS_COPY_ITEM[i]) {
        COPY_ITEM[KEYS_COPY_ITEM[i]] = FIXCONVERTLIST[possibles[j]](COPY_ITEM[KEYS_COPY_ITEM[i]]) || '-'
      }
    }
  }

  return COPY_ITEM
}

export function enhanceServerTable (data: Array<Object>, columns: ColumnsType<object | { dataIndex: string }>): { finalData: object[], finalColumns: ColumnsType<any> } {
  if (!data) return { finalData: [], finalColumns: columns }

  columns.forEach((column) => {
    column.ellipsis = { showTitle: false }
    column.width = column.width || 150
    column.render = column.render || TableTooltip
  })
  const DATA_INDEXES = columns.map((column: any) => column.dataIndex)
  data.forEach((row: { [key: string]: any }) => {
    DATA_INDEXES.forEach((dataIndex: string) => {
      if (row[dataIndex] === undefined || row[dataIndex] === null) row[dataIndex] = '-'
    })
  })

  const GENERATE_NEW_DATA = []
  const GENERATE_OBJECT = {}
  const GREATER_LENGTH = 0

  for (let i = 0; i < data.length; i++) {
    const tempItem = convertPropertsObject(data[i])
    const lenghtTempItem = Object.keys(tempItem).length

    if (lenghtTempItem >= GREATER_LENGTH) Object.assign(GENERATE_OBJECT, tempItem)
    GENERATE_NEW_DATA.push(tempItem)
  }

  return { finalData: GENERATE_NEW_DATA, finalColumns: columns }
}

export const getHeaderHeight = (ref: MutableRefObject<any>): number => {
  const tableFilters: HTMLElement | null = document.querySelector('.server-table-filters__form')
  const serverTablePagination: HTMLElement | null = document.querySelector('.server-table__pagination')
  const offsetHeight = elementDimension(ref.current)

  if (tableFilters && serverTablePagination) {
    const navheight = 70
    const contentPadding = 21.25 * 2
    const tablesOffsetHeight = elementDimension(tableFilters)
    const paginationHeight = elementDimension(serverTablePagination)
    return offsetHeight + tablesOffsetHeight + navheight + contentPadding + paginationHeight
  }
  return 0
}

import React, { useLayoutEffect, useState, useEffect } from 'react'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Upload } from 'antd'
import swal from 'utils/swal'

function Selfie ({ getFile, defaultImage, resetImage, profile }: { getFile: (file: Blob) => void, defaultImage?: string, resetImage?: boolean, profile?:boolean }) {
  const [loadingImage, setLoadingImage] = useState<boolean>(false)
  const [image, setImage] = useState<string>()

  useLayoutEffect(() => {
    if (defaultImage) setImage(defaultImage)
    else setImage('')
  }, [defaultImage])

  useEffect(() => {
    if (!defaultImage) setImage('')
  }, [resetImage])

  function getBase64 (img: any, callback: any) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  function handleChange (info: any) {
    if (info.file.status === 'uploading') {
      setLoadingImage(true)
      return
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (imageUrl: any) => {
        setLoadingImage(false)
        setImage(imageUrl)
      })
    }
    if (info.file.status === 'error') {
      getBase64(info.file.originFileObj, (imageUrl: any) => {
        setLoadingImage(false)
        setImage(imageUrl)
      })
    }
  }

  function beforeUpload (file: any) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      swal.basic({
        title: 'Atenção',
        text: 'Escolha uma imagem de extensão JPG ou PNG',
        icon: 'warning'
      })
    }

    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      swal.basic({
        title: 'Atenção',
        text: 'Tamanho máximo do arquivo 2mb',
        icon: 'warning'
      })
    }

    getFile(file)
    return isJpgOrPng && isLt2M
  }

  const uploadButton = (
    <div className='selfie__button'>
      {loadingImage ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Enviar</div>
    </div>
  )

  return (
    <Upload
      name="avatar"
      listType="picture"
      className={profile ? 'profile__avatar' : 'selfie__upload'}
      showUploadList={false}
      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
      onChange={handleChange}
      beforeUpload={beforeUpload}

    >
      {image ? (
        <div style={{ position: 'relative', maxHeight: '100%', height: '100%' }}>
          {loadingImage && <LoadingOutlined style={{ position: 'absolute', top: '50%', left: 'calc(50% - 12px)' }} />}
          <img src={image} alt="avatar" style={{ width: '100%', height: '100%' }} />
        </div>
      ) : (uploadButton)}
    </Upload>
  )
}

export default Selfie

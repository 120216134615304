import React, { useEffect, useState } from 'react'
import { Card, Input, Pagination, Result, Tag, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import ClientRepository, { IClientList } from 'egi/repositories/ClientRepository'
import { userDetails } from 'routes/routes'
import mask from 'utils/masks'
import LetterAvatar from 'ecp/components/LetterAvatar/LetterAvatar'

interface IParams {
  search?: string
  page: number
  limit: string
}

function ClientCardList () {
  const [loading, setLoading] = useState(true)
  const [clientes, setClientes] = useState<IClientList[]>([])
  const [totalDocs, setTotalDocs] = useState(0)
  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: '8'
  })

  useEffect(() => {
    const abortController = new AbortController()
    async function getClients () {
      setLoading(true)
      try {
        const response = await ClientRepository.getAll({
          params: params,
          signal: abortController.signal
        })

        setClientes(response.data.data?.clients.docs || [])
        setTotalDocs(response.data.data?.clients.totalDocs || 0)
        setLoading(false)
      } catch (err) {
        if (!abortController.signal.aborted) {
          setClientes([])
          setLoading(false)
        }
        console.log(err)
      }
    }

    getClients()
    return () => abortController.abort()
  }, [params])

  return (
    <>
      <div className='client-card-list'>
        <div className='client-card-list__header'>
          <h2 className='client-card-list__header-tittle'>Todos os Clientes</h2>
          <p className='client-card-list__header-paragraph'>Procure clientes consultando seu CPF</p>
          <Input.Search
            className='table-filter client-card-list__header-search'
            placeholder='Digite um CPF...'
            onSearch={value => setParams(prev => ({ ...prev, search: value, page: 1 }))}
            loading={loading}
          />

          {!loading && clientes.length === 0 ? (
            <div>
              <Result status='404' subTitle='Nenhum cliente encontrado'/>
            </div>
          ) : (
            <>
              <div className='client-card-list__clients-list-grid mb-5'>
                {loading ? (
                  <>
                    {new Array(Number(params.limit)).fill('').map(() => (
                      <>
                        <Card className='client-card-list__clients-card client-card-list__clients-card--skeleton'
                          cover={<div className='client-card-list__clients-selfie--skeleton'></div>}
                        >
                          <Card.Meta
                            className='text-center client-card-list__clients-card--body client-card-list__clients-card--body-skeleton'
                            title={<div className='client-card-list__clients-name--skeleton'></div>}
                            description={<div className='client-card-list__clients-cpf--skeleton'></div>}
                          />
                        </Card>
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    {Array.isArray(clientes) && clientes.map((item) => {
                      if (!item._id) return null

                      return (
                        <>
                          <Link
                            className='client-card-list__clients-link'
                            key={item?._id}
                            to={{
                              pathname: '/auth' + userDetails.path.replace(':userId', item._id)
                            }}
                          >
                            <Card
                              key={item?._id}
                              className='client-card-list__clients-card'
                              cover={
                                <>
                                  {item.selfie && typeof item.selfie === 'string' ? (
                                    <img
                                      className='client-card-list__clients-selfie'
                                      src={item.selfie}
                                    />
                                  ) : (
                                    <div className='client-card-list__clients-selfie'>
                                      <LetterAvatar name={item?.name} size='large' />
                                    </div>
                                  )}
                                </>
                              }
                            >
                              <Card.Meta
                                className='text-center client-card-list__clients-card--body'
                                title={
                                  <Tooltip
                                    title={item?.name}
                                  >
                                    <span className='client-card-list__clients-name'>{item?.name}</span>
                                  </Tooltip>
                                }
                                description={
                                  <>
                                    {(item?.canceled === true) && (
                                      <div className='mb-1'>
                                        <Tag color='red'>Cancelado</Tag>
                                      </div>
                                    )}

                                    <Tooltip
                                      title={mask(String(item?.cpf), 'cpf', true)}
                                    >
                                      <span className='client-card-list__clients-cpf' >
                                        {mask(String(item?.cpf), 'cpf', true)}
                                      </span>
                                    </Tooltip>
                                  </>
                                }
                              />
                            </Card>
                          </Link>
                        </>
                      )
                    })}
                  </>
                )}
              </div>

              <div className='mt-3 client-card-list__pagination' >
                <Pagination
                  pageSize={Number(params.limit)}
                  showSizeChanger={false}
                  total={totalDocs}
                  current={params.page}
                  onChange={page => setParams(prev => ({ ...prev, page }))}
                  size="small"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ClientCardList

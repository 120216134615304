import React, { Fragment } from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'

function TooltipPassword () {
  return (
    <label className="simulator-label">
      Senha: {' '}
      <Tooltip
        destroyTooltipOnHide={{ keepParent: false }}
        color="white"
        placement="top"
        title={
          <Fragment>
            <p style={{ margin: 0 }} className="simulator-tooltip-h1">A sua senha deverá conter:</p>
            <ul style={{ margin: 0, paddingLeft: '15px' }} className="simulator-tooltip-paragraph">
              <li>Pelo menos 11 caracteres.</li>
              <li>Letras maiúsculas e minúsculas.</li>
              <li>Números e caracteres especiais ( #, $, @, ...).</li>
            </ul>
          </Fragment>
        }
      >
        <InfoCircleOutlined className="simulation-info" style={{ color: '#00441F' }}/>
      </Tooltip>
    </label>
  )
}

export default TooltipPassword

import api from '../../services/api'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ConnectionFailed, ErrInternetDisconnected } from './errors'
import fileHelper from '../../utils/file'
import { CreditStatus } from './unauthed'

interface IBaseIds {
  proposalId: string,
  stepId: string
  areaId: string
}

interface IinitialData extends IBaseIds {
  form?: FormData
  areaId: string
}

export interface IPreview extends IBaseIds {
  sign?: string
}

interface ISendContract {
  proposalId: string,
  areaId: string,
  data: any
}

export type CreditResponse = { proposalId: string, data: { status: CreditStatus, reprovedReason?: string, otherReprovedReason?: string } }

const proposals = {

  config: async () => {
    try {
      const response: AxiosResponse = await api.get('/proposals/list')
      return { ...response.data.documents }
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  state: async (proposalId: string, config: AxiosRequestConfig) => {
    try {
      const response: AxiosResponse = await api.get(`/proposals/${proposalId}/departments`, config)
      return response.data.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  preview: async ({ proposalId, stepId, areaId, sign }: IPreview) => {
    try {
      const response: AxiosResponse = await api.post(`/proposals/${proposalId}/departments/${areaId}/contract/preview`, { stepId, sign })
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  finalMinutaPreview: async ({ proposalId }: { proposalId: string }) => {
    try {
      const response: AxiosResponse = await api.get(`/proposals/${proposalId}/minuta/quadro/preview`)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  finalMinutaEdit: async ({ proposalId, data }: { proposalId?: string, data: any }) => {
    try {
      const response: AxiosResponse = await api.patch(`/proposals/${proposalId}/minuta/quadro/edit`, data)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  minutaPreview: async ({ proposalId }: { proposalId: string }) => {
    try {
      const response: AxiosResponse = await api.get(`/proposals/${proposalId}/minuta/preview`)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  sendContract: async ({ proposalId, areaId, data }: ISendContract) => {
    const formData = new FormData()

    const { file } = data

    if (!file) return ''

    const signatureFile = await fileHelper.urltoFile(file, 'file.png', 'image/png')

    formData.append('file', signatureFile)
    formData.append('contract', data.contract)
    formData.append('stepId', data.stepId)
    if (data.activeMode) formData.append('activeMode', data.activeMode)

    try {
      const response: AxiosResponse = await api.post(`/proposals/${proposalId}/departments/${areaId}/send`, formData)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  // SEND
  send: async (props: IinitialData) => {
    const {
      proposalId,
      stepId,
      form,
      areaId
    } = props

    try {
      const response: AxiosResponse = await api.post(`/proposals/${proposalId}/departments/${areaId}/send`, { stepId, ...form })
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  sendVistoria: async ({ proposalId, stepId, form, areaId }: { proposalId: string, stepId: string, areaId: string, form: { vistoria: Date } }) => {
    try {
      const response: AxiosResponse = await api.post(`/proposals/${proposalId}/departments/${areaId}/send`, { stepId, ...form })
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  sendAlienating: async ({ proposalId, stepId, form, areaId }: { proposalId: string, stepId: string, areaId: string, form: { hasGuarantor: boolean, alienante: number } }) => {
    try {
      const response: AxiosResponse = await api.post(`/proposals/${proposalId}/departments/${areaId}/send`, { stepId, ...form })
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  create: async ({ productId, clientId, simulationId }: { productId: string, clientId?: string, simulationId?: string }) => {
    try {
      const response: AxiosResponse = await api.post('/proposals', { productId, clientId, simulationId })
      return response.data.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  get: async (proposalId: string) => {
    try {
      const response: AxiosResponse = await api.get(`/proposals/${proposalId}`)
      return response.data.data.proposal
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  updateAvaliations: async ({ proposalId, data }: { proposalId: string, data: { name: string, value: string | number } }) => {
    try {
      const response: AxiosResponse = await api.patch(`/proposals/${proposalId}/avaliations`, data)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  logs: async ({ proposalId }: { proposalId: string }) => {
    try {
      const response: AxiosResponse = await api.get(`/proposals/${proposalId}/avaliations/logs`)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  changeConsultor: async ({ proposalId, consultantId }: { proposalId: string, consultantId: string }) => {
    try {
      const response: AxiosResponse = await api.patch(`proposals/${proposalId}/consultant/${consultantId}`)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  changeSupport: async ({ proposalId, data }: { proposalId: string, data: { commercialId: string } }) => {
    try {
      const response: AxiosResponse = await api.put(`/proposals/${proposalId}/replace/commercial`, data)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  changeTelemarketing: async ({ proposalId, data }: { proposalId: string, data: { telemarketingId: string } }) => {
    try {
      const response: AxiosResponse = await api.put(`/proposals/${proposalId}/replace/telemarketing`, data)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  providerRefuse: async ({ proposalId, formData }: { proposalId: string, formData: FormData }) => {
    try {
      const response: AxiosResponse = await api.post(`/proposals/${proposalId}/provider/refuse`, formData)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  refuse: async ({ proposalId, formData }: { proposalId: string, formData: FormData }) => {
    try {
      const response: AxiosResponse = await api.post(`/proposals/${proposalId}/refuse`, formData)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  openAreas: async ({ proposalId }: { proposalId: string }) => {
    try {
      const response: AxiosResponse = await api.get(`/proposals/${proposalId}/departments/open`)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  linkBackoffice: async ({ proposalId, areaId, data }: { proposalId: string, areaId: string, data: { backofficeId: string } }) => {
    try {
      const response: AxiosResponse = await api.post(`/proposals/${proposalId}/departments/${areaId}/add/backoffice`, data)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  removeBackoffice: async ({ proposalId, areaId, data }: { proposalId: string, areaId: string, data: { backofficeId: string } }) => {
    try {
      const response: AxiosResponse = await api.post(`/proposals/${proposalId}/departments/${areaId}/remove/backoffice`, data)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  linkNewBackoffice: async ({ proposalId }: { proposalId: string }) => {
    try {
      const response: AxiosResponse = await api.patch(`/proposals/${proposalId}/accept`)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  creditResponse: async ({ proposalId, data }: CreditResponse) => {
    try {
      const response: AxiosResponse = await api.post(`/proposals/${proposalId}/credit`, data)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  creditApprovalHistory: async ({ proposalId, limit }: { proposalId: string, limit?: number }) => {
    try {
      const response: AxiosResponse = await api.get(`/proposals/${proposalId}/credit/history`, {
        params: {
          limit: limit && limit
        }
      })
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw err
      }
      if (!err.response) {
        const error = new Error('CONNECTION_FAILED')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw err.response.data
    }
  },

  cndUrl: async ({ proposalId, stepId }: { proposalId: string, stepId: string }) => {
    try {
      const response: AxiosResponse = await api.get(`/proposals/${proposalId}/step/${stepId}/cnd`)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw err
      if (!err.response) {
        const error = new Error('CONNECTION_FAILED')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw err.response.data
    }
  },

  closeReminded: async ({ proposalId }: { proposalId: string }) => {
    try {
      const response: AxiosResponse = await api.patch(`/proposals/${proposalId}/telemarketing/close`)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw err
      if (!err.response) {
        const error = new Error('CONNECTION_FAILED')
        error.message = 'Não foi possível se comunicar com o servidor'
        throw error
      }
      throw err.response.data
    }
  },

  creditDefense: async ({ proposalId, data }: { data: { textDefense: string }, proposalId: string }) => {
    try {
      const response: AxiosResponse = await api.patch(`/proposals/${proposalId}/defense`, data)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  addClausula: async ({ proposalId, data }: { data: { slug: string, value: string }, proposalId: string }) => {
    try {
      const response: AxiosResponse = await api.post(`/proposals/${proposalId}/minuta/clausula/add`, data)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  removeClausula: async ({ proposalId, data }: { data: { slug: string, index: number }, proposalId: string }) => {
    try {
      const response: AxiosResponse = await api.post(`/proposals/${proposalId}/minuta/clausula/remove`, data)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  editClausula: async ({ proposalId, data }: { data: { slug: string, value: string, index: number }, proposalId: string }) => {
    try {
      const response: AxiosResponse = await api.patch(`/proposals/${proposalId}/minuta/clausula/edit`, data)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  getCNDs: async ({ proposalId }: { proposalId?: string }) => {
    try {
      const response: AxiosResponse = await api.get(`/proposals/${proposalId}/cnd/list`)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  remakingSimulation: async ({ proposalId }: { proposalId?: string }, config?: AxiosRequestConfig) => {
    try {
      const response: AxiosResponse = await api.get(`/proposals/${proposalId}/minuta/simulation`, config)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  opennedPeople: async ({ proposalId }: { proposalId?: string }) => {
    try {
      const response: AxiosResponse = await api.get(`/proposals/${proposalId}/people`)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  followupProposal: async ({ indikyToken }: { indikyToken: string }) => {
    try {
      const response: AxiosResponse = await api.get(`/proposal/indiky/${indikyToken}/followup`)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  sendStorageDocument: async ({ proposalId, data, config }: { data: Object, proposalId: string, config: AxiosRequestConfig }) => {
    try {
      const response: AxiosResponse = await api.patch(`/proposals/${proposalId}/documents/exception/send`, data, config)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  deleteStorageDocument: async ({ proposalId, data, config }: { proposalId: string, data: { url: string | object, areaName: string }, config: AxiosRequestConfig }) => {
    try {
      const response: AxiosResponse = await api.patch(`/proposals/${proposalId}/documents/exception/delete`, data, config)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },
  sendStorageComment: async ({ proposalId, data, config }: { proposalId: string, data: { message: string | undefined, areaName: string }, config?: AxiosRequestConfig }) => {
    try {
      const response: AxiosResponse = await api.post(`/proposals/${proposalId}/documents/exception/comment`, data, config)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  }
}

export default proposals

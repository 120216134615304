import React, { Fragment } from 'react'

function BacenProposalText () {
  return (
    <Fragment>
      <p className='my-2'>
        O tomador do crédito autoriza ao Credor e as sociedades sob seu controle direto ou indireto,
        a qualquer tempo, mesmo após a extinção desta operação, a: (a) trocar entre si informações suas constantes do cadastro de referidas empresas;
        (b) fornecer ao Banco Central, para integrar o SCR3, informações sobre o montante de suas dívidas a vencer e vencidas,
        inclusive as em atraso e as operações baixadas com prejuízo, bem como o valor das coobrigações assumidas pelo
        tomador do crédito e das garantias por ele prestadas, e (c) consultar o SCR sobre eventuais informações nele existentes com relação 30 tomador do crédito.
      </p>
      <p className='my-2'>
        O tomador do crédito declara que está ciente de que a consulta ao SCR depende da autorização prévia e que eventual consulta anterior,
        para fins desta contratação, foi realizada sob autorização, ainda que verbal.
      </p>
      <p className='my-2'>
        O tomador do crédito declara que está ciente de que pode ter acesso, a qualquer tempo, aos dados do SCR pelos meios colocados à sua disposição pelo Banco Central e,
        em caso de divergência nos dados do SCR fornecidos pelo Credor e/ou sociedade sob seu controle direto ou indireto,
        poderá solicitar sua correção, exclusão ou registro de anotação complementar, inclusive de medidas judiciais, mediante soliciação escrita e fundamentada ao Credor.
      </p>
      <p className='mb-3'>
        <b>
          Esclareço, outrossim, que a presente declaração e autorização permanecerão válidas por todo o tempo em que perdurar em aberto
          e não integralmente liquidada a operação de crédito que decorrer da aprovação (parcial ou total) desta Proposta e/ou pelo tempo em que eu for cliente de
          quaisquer empresas parceiras.
        </b>
      </p>
    </Fragment>
  )
}

export default BacenProposalText

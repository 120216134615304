import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import React, { useEffect, useMemo } from 'react'
import { timeAsDayjs } from 'utils/time'
import dayjs from 'dayjs'
import { IClientPending } from 'ecp/app/Clients/clientInterfaces'
import { LimiterTable } from 'components/LimiterTable/LimiterTable'
import ServerTable from 'components/ServerTable/ServerTable'
import { useDispatch } from 'react-redux'
import { tablesSetFiltersValues } from 'store/modules/tables/actions'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { ECP } from 'routes/ecp/constants'
import useFindPath from 'hooks/useFindPath'
import format from 'utils/format'
import { handleServerTableChange } from 'components/ServerTable/function'
import { useTables } from 'hooks'
import commonColumns from 'components/UserTable/utils/commonColumns'
import { ClientPendingOnidataFilters } from '../../components/ClientPendingOnidataFilters/ClientPendingOnidataFilters'
import { EditOutlined } from '@ant-design/icons'
import { changeSystemRouteName } from 'store/modules/system/actions'

function ClientPendingListOnidata () {
  const dispatch = useDispatch()
  const history = useHistory()
  const table = useTables()

  const location = useLocation<{ id: string, name: string }>()
  const userFuncaoId = location?.state?.id

  const historyTo = (id: string) => ({
    pathname: '/auth' + ECP.CORBAN_EDIT_FORM_ONIDATA.path.replace(':clientId?', id),
    search: `userFuncaoId=${userFuncaoId}`
  })

  useEffect(() => {
    const { name } = location?.state || {}
    if (name) {
      setTimeout(() => {
        dispatch(changeSystemRouteName(`Funcionários Pendentes (${name})`))
      }, 100)
    }
  }, [location])

  const columns: ColumnsType<IClientPending> = useMemo(() => ([
    {
      title: 'Matrícula',
      dataIndex: 'matricula',
      key: 'id',
      align: 'center',
      render: (matricula: string, row: IClientPending) => {
        const paths = useFindPath()
        if (!paths.findPath(ECP.CORBAN_EDIT_FORM_ONIDATA)) return null

        return (
          <Link
            className='clients-pending-list__link'
            to={historyTo(row.id)}
          >
            {matricula} &rarr;
          </Link>
        )
      }

    },
    {
      title: 'Nome',
      dataIndex: 'nome'
    },
    {
      title: 'Sobrenome',
      dataIndex: 'sobrenome'
    },
    {
      title: 'Salario',
      dataIndex: 'salario',
      render: (value: number) => {
        return format.formatBRL(value)
      }
    },
    {
      title: 'CPF',
      dataIndex: 'cpf'
    },
    commonColumns.email,
    {
      title: 'Admissão',
      dataIndex: 'data_admissao',
      render: (date: string) => {
        if (!date) return '-'
        return dayjs(date).isValid() ? timeAsDayjs(date).format('DD/MM/YYYY') : '-'
      }
    },
    {
      title: 'IRRF',
      dataIndex: 'irrf',
      render: (value: number) => {
        return format.formatBRL(value)
      }
    },
    {
      title: 'INSS',
      dataIndex: 'inss',
      render: (value: number) => {
        return format.formatBRL(value)
      }
    },
    {
      title: 'Descontos Diversos',
      dataIndex: 'descontos',
      width: '10rem',
      render: (value: number) => {
        return format.formatBRL(value)
      }
    },
    {
      title: 'Comprometimento em outros',
      width: '15rem',
      dataIndex: 'comprometimento_outros',
      render: (value: number) => {
        return format.formatBRL(value)
      }
    },
    {
      title: '',
      dataIndex: 'edit',
      width: 100,
      render: (_, row: IClientPending) => {
        const redirectToFormEdit = () => {
          history.push(historyTo(row.id))
        }

        return (
          <div
            className='clients-pending-list__edit-container'
            onClick={redirectToFormEdit}
          >
            Editar <EditOutlined />
          </div>
        )
      }
    }
  ]), [])

  return (
    <>
      <div className='clients'>
        <LimiterTable>
          <ClientPendingOnidataFilters />

          <ServerTable
            url={'/ecp/clients/pending?userFuncaoId=' + location?.state?.id}
            columns={columns}
            rowKey='_id'
            onChange={
              (pagination: TablePaginationConfig, filters: Record<string, (string | number | boolean)[] | null>, sorter) => {
                const serverTableFiltersData = handleServerTableChange(columns, table.filters)(pagination, filters, sorter)
                dispatch(tablesSetFiltersValues({ ...table.filters, ...serverTableFiltersData }))
              }
            }
          />
        </LimiterTable>
      </div>
    </>
  )
}

export default ClientPendingListOnidata

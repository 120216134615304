import loadable from '@loadable/component'
import Loading from 'components/Loading/Loading'
import { timeout } from 'promise-timeout'
import React from 'react'
import { TIMEOUT_LOADER } from 'utils/globals'

const ForgotPasswordECPF = loadable(
  () => timeout(import(
    /* webpackChunkName: "ForgotPasswordECPF" */
    'ecpf/app/ForgotPasswordECPF/views/ForgotPasswordECPF/ForgotPasswordECPF'
  ), TIMEOUT_LOADER),
  {
    fallback: <Loading/>
  }
)

export { ForgotPasswordECPF }

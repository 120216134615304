import { LoadingOutlined } from '@ant-design/icons'
import React, { Fragment, useState } from 'react'
import format from 'utils/format'
import columns from '../dataTable'
import { Table } from 'antd'
import { useSimulation } from 'hooks'

function SimulatorResume ({ setModalTitle }: { setModalTitle: Function }) {
  const [showTable, setShowTable] = useState<boolean>(false)
  const simulation = useSimulation()

  return (
    <Fragment>
      {showTable ? (
        <Fragment>
          <Table
            pagination={{ responsive: true }}
            scroll={{ x: 400 }}
            className="simulator-table"
            dataSource={simulation.lastStep?.parcels}
            columns={columns}
          />
          <label className="simulator-label">*O valor das parcelas estará sujeito à correção do IPCA no período</label>
        </Fragment>
      ) : (
        <Fragment>
          <div>
            <p className="simulator-resumes-titles single-line-simulation-title">CGI</p>
            <div className="wrapper-single-line-table">
              <div className="flex w-100">
                <p className="flex-1 mt-1 mb-1 ml-1 color-primary simulator-resume-sub-titles">Valor do imóvel</p>
                <p className="flex-1 mt-1 mb-1 ml-1 simulator-label">{format.formatBRL(Number(simulation.lastStep?.propertyValue))}</p>
              </div>

              <div className="flex w-100">
                <p className="flex-1 mt-1 mb-1 ml-1 color-primary simulator-resume-sub-titles">(=) Crédito solicitado</p>
                <p className="flex-1 mt-1 mb-1 ml-1 simulator-label">{format.formatBRL(Number(simulation.lastStep?.creditValue))}</p>
              </div>
            </div>
          </div>

          <div>
            <p className="simulator-resumes-titles single-line-simulation-title simulator-resume-sub-titles">PARCELAS</p>
            <div className="wrapper-single-line-table">
              <div className="flex w-100">
                <p className="flex-1 mt-1 mb-1 ml-1 color-primary simulator-resume-sub-titles">Prazo (meses)</p>
                <p className="flex-1 mt-1 mb-1 ml-1 simulator-label">{simulation.lastStep?.deadline}</p>
              </div>

              <div className="flex w-100">
                <p className="flex-1 mt-1 mb-1 ml-1 color-primary simulator-resume-sub-titles">Sistema de amortização</p>
                <p className="flex-1 mt-1 mb-1 ml-1 simulator-label">{simulation.lastStep?.amortizationType.toLocaleUpperCase()}</p>
              </div>

              <div className="flex w-100">
                <p className="flex-1 mt-1 mb-1 ml-1 color-primary simulator-resume-sub-titles">índice de correção</p>
                <p className="flex-1 mt-1 mb-1 ml-1 simulator-label">{simulation.lastStep?.index === 'FLATRATE' ? 'PRÉ-FIXADA' : 'IPCA'}</p>
              </div>

              <div className="flex w-100">
                <p className="flex-1 mt-1 mb-1 ml-1 color-primary simulator-resume-sub-titles">Primeira parcela</p>
                {simulation.lastStep?.parcels && simulation.lastStep.parcels.length > 0
                  ? <p className="flex-1 mt-1 mb-1 ml-1 simulator-label">{format.formatBRL(simulation.lastStep.parcels[0].totalParcel)}</p>
                  : (<LoadingOutlined />)
                }
              </div>

              <div className="flex w-100">
                <p className="flex-1 mt-1 mb-1 ml-1 color-primary simulator-resume-sub-titles">Última parcela</p>
                {simulation.lastStep?.parcels && simulation.lastStep.parcels.length > 0 ? (
                  <p className="flex-1 mt-1 mb-1 ml-1 simulator-label simulator-resume-sub-titles">
                    {format.formatBRL(simulation.lastStep.parcels[simulation.lastStep.parcels.length - 1].totalParcel)}
                  </p>
                ) : (<LoadingOutlined />)}
              </div>

              <div className="flex w-100">
                <p className="flex-1 mt-1 mb-1 ml-1 bold color-primary">Parcelas</p>
                <p
                  onClick={() => {
                    setModalTitle('TODAS AS PARCELAS')
                    setShowTable(true)
                  }}
                  className="flex-1 mt-1 mb-1 ml-1 link-style"
                  style={{ fontSize: '1.1rem' }}
                >
                  ver todas as parcelas
                </p>
              </div>
            </div>
          </div>
          <div>
            <p className="simulator-resumes-titles single-line-simulation-title">CUSTAS CARTORÁRIAS</p>
            <div className="wrapper-single-line-table">
              {simulation.lastStep?.taxes && (
                <Fragment>

                  <div className="flex w-100">
                    <p className="flex-1 mt-1 mb-1 ml-1 color-primary simulator-resume-sub-titles">Custas cartorárias</p>
                    <p className="flex-1 mt-1 mb-1 ml-1 simulator-label">
                      {format.formatBRL(simulation?.lastStep?.registerValue)}
                    </p>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
          <div>
            <p className="simulator-resumes-titles single-line-simulation-title">JUROS</p>
            <div className="wrapper-single-line-table">
              {simulation.lastStep?.taxes && (
                <Fragment>
                  <div className="flex w-100">
                    <p className="flex-1 mt-1 mb-1 ml-1 color-primary simulator-resume-sub-titles">Taxa de juros anual (CET - Custo Efetivo Total)</p>
                    <p className="flex-1 mt-1 mb-1 ml-1 simulator-label">
                      {format.decimalToPercentage(Number(simulation.lastStep?.taxes.anualCET)).toFixed(2)} %
                    </p>
                  </div>

                  <div className="flex w-100">
                    <p className="flex-1 mt-1 mb-1 ml-1 color-primary simulator-resume-sub-titles">Taxa de juros mensal</p>
                    <p className="flex-1 mt-1 mb-1 ml-1 simulator-label">
                      {format.decimalToPercentage(Number(simulation.lastStep?.taxes.monthlyTax)).toFixed(2)} %
                    </p>
                  </div>
                </Fragment>
              )}
            </div>
          </div>

        </Fragment>
      )}

      {showTable && (
        <p
          onClick={function () {
            setModalTitle('INFORMAÇÕES SOBRE SUA SIMULAÇÃO')
            setShowTable(false)
          }}
          className="flex-1 mt-1 mb-1 ml-1 link-style text-center"
          style={{ fontSize: '1.1rem' }}
        >
          ver detalhes
        </p>
      )}
    </Fragment>
  )
}

export default SimulatorResume

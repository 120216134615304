import loadable from '@loadable/component'
import Loading from 'components/Loading/Loading'
import { timeout } from 'promise-timeout'
import React from 'react'
import { TIMEOUT_LOADER } from 'utils/globals'

const Portability = loadable(
  () => timeout(import(/* webpackChunkName: "Portability" */ 'ecp/app/Portability/views/Portability/Portability'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const PortabilityFindUserCPF = loadable(
  () => timeout(import(/* webpackChunkName: "Portability" */ 'ecp/app/Portability/views/PortabilityFindUserCPF/PortabilityFindUserCPF'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const PortabilityCreate = loadable(
  () => timeout(import(/* webpackChunkName: "Portability" */ 'ecp/app/Portability/views/PortabilityCreate/PortabilityCreate'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const PortabilityAccept = loadable(
  () => timeout(import(/* webpackChunkName: "Portability" */ 'ecp/app/Portability/views/PortabilityAccept/PortabilityAccept'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const PortabilityFindUserInformationConfirm = loadable(
  () => timeout(import(/* webpackChunkName: "Portability" */ 'ecp/app/Portability/views/PortabilityFindUserInformationConfirm/PortabilityFindUserInformationConfirm'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const PortabilityFindUserCovenantSelect = loadable(
  () => timeout(import(/* webpackChunkName: "Portability" */ 'ecp/app/Portability/views/PortabilityFindUserSelectCovenant/PortabilityFindUserCovenantSelect'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

export {
  Portability,
  PortabilityFindUserCPF,
  PortabilityCreate,
  PortabilityAccept,
  PortabilityFindUserInformationConfirm,
  PortabilityFindUserCovenantSelect
}

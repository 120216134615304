import { tablesSetFiltersValues } from 'store/modules/tables/actions'
import useDebounce from 'hooks/useDebounce'
import { useDispatch } from 'react-redux'
import { useTables } from 'hooks'
import { useState } from 'react'

interface IReturns {
  withOutSearch: (changeValues: any, values: any) => void
  withSearch: (value: string) => void
}

function useSetFilters (): IReturns {
  const tables = useTables()
  const dispatch = useDispatch()
  const [search, setSearch] = useState<string>()

  useDebounce(() => {
    dispatch(tablesSetFiltersValues({ ...tables.filters, search: search, page: 1 }))
  }, 500, [search])

  return {
    withOutSearch: (changeValues, values) => {
      if (!changeValues?.search) {
        dispatch(tablesSetFiltersValues({ ...tables.filters, ...values, page: 1 }))
      }
    },
    withSearch: values => setSearch(values)
  }
}

export { useSetFilters }

import React from 'react'
import Routes from './routes'
import { PersistGate } from 'redux-persist/integration/react'
import { history, persistor } from './store/index'
import { ConnectedRouter } from 'connected-react-router'

import './styles/index.less'

function App () {
  return (
    <ConnectedRouter history={history}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes />
      </PersistGate>
    </ConnectedRouter>
  )
}

export default App

import { Form } from 'antd'
import { CustomSteps, Drawer } from 'components'
import GoToReview from 'egi/app/ProposalEgi/components/GoToReview/GoToReview'
import PartnerModel from 'egi/models/PartnerModel'
import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PartnerStepButton from '../../../PartnerStepButton/PartnerStepButton'
import ReviewDescription from '../../../ReviewDescription/ReviewDescription'
import ReviewStep from '../../../ReviewStep/ReviewStep'
import usePartnerStep from '../../../../hooks/usePartnerStep'
import usePartnerStepDrawer from '../../../../hooks/usePartnerStepDrawer'
import EditPartnerContract from './EditPartnerContract'
import PartnerContract from './PartnerContract'

import ChooseMinuta from '../../../ChooseMinuta/ChooseMinuta'

function PartnerContractStep () {
  const [counterStep, setCounterStep] = useState<number>(0)
  const { step } = usePartnerStep()
  const { close, open, visible } = usePartnerStepDrawer()
  const [formRef] = Form.useForm()
  const sendForm = PartnerModel.canSendForm(step)
  const editContract = PartnerModel.canEditContract(step)

  const { partnerId } = useParams<{ partnerId: string }>()

  useEffect(() => {
    function scrollToTop () {
      const antDrawerBody = document.querySelector('.ant-drawer-body')
      if (antDrawerBody) antDrawerBody.scrollTop = 0
    }

    scrollToTop()
  }, [counterStep])

  return (
    <Fragment>
      <PartnerStepButton
        status={step.status}
        enabled={true}
        onClick={open}
        step={step}
      >
        {step.name}
      </PartnerStepButton>

      <Drawer
        visible={visible}
        onClose={close}
        showSubmit={false}
        disabled={!sendForm}
        title={step.name}
        status={status}
      >
        <ChooseMinuta/>

        {editContract
          ? <Fragment>
            <div className="mb-2">
              <CustomSteps
                counterStep={counterStep}
                setCounterStep={setCounterStep}
                quantity={2}
                enableAfter={true}
              />
            </div>

            {counterStep === 0 && (
              <EditPartnerContract
                onClick={() => setCounterStep(1)}
              />
            )}

            {counterStep === 1 && (
              <PartnerContract
                partnerId={partnerId}
                formRef={formRef}
              />
            )}
            <GoToReview />
          </Fragment>

          : <PartnerContract
            partnerId={partnerId}
            formRef={formRef}
          />
        }

        <div className="my-4">
          <ReviewDescription />
        </div>

        <ReviewStep onSuccess={close} />
      </Drawer>
    </Fragment>
  )
}
export default PartnerContractStep

import React, { MutableRefObject, useRef, useState, useEffect, Fragment } from 'react'
import { Button, Form, Radio, Select, Tooltip, InputNumber, Alert } from 'antd'
import { InputMoney } from 'components'
import { InfoCircleOutlined, MinusCircleFilled, PlusCircleFilled, LoadingOutlined } from '@ant-design/icons'
import TextAmortization from 'components/ModalInvite/TextPromoterCommission'
import { simulation as consumerSimulation } from 'egi/consumers'
import { useAuth, useSimulation } from 'hooks'
import { simulationLastStep, simulationPricngValues, simulationSetId, simulationSteps, simulationValues } from 'store/modules/simulation/actions'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'
import { useDispatch } from 'react-redux'
import format from 'utils/format'
import { _amortizationTypeEnum } from 'globals'
import { ISimulationBaseProps } from '../../simulatorInterfaces'
import LeadsRepository from 'egi/repositories/LeadsRepository'
import { tablesUpdate } from 'store/modules/tables/actions'
import { simulationRepository } from 'egi/repositories/SimulationsRepository'
import { ISimulation } from 'egi/types'
import { invalidSimulationFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import ProposalEgiModel from 'egi/models/ProposalEgiModel'

type ISimulatorLoanInfoStepValues = {
  deadline?: number
  resourceGoal: string
  amortizationType: string
  hasRegister?: boolean
  creditValue: number
  currentStep: number
  clientId?: string
  simulationId?: string
  index?: string
  product: 'home-equity'
}

type IGetAvailableCreditValue = {
  deadlineValue?: number
  amortization?: string
  register?: boolean
}

let cancelRequest: any

function SimulationLoanInfoStep ({ simulatorData }: Omit<ISimulationBaseProps, 'onlyCEP'>) {
  const [errors, setErrors] = useState<any>({})
  const [deadline, setDeadline] = useState<number | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState('')
  const [creditValue, setCreditValue] = useState<string | undefined>(undefined)
  const [availableCredit, setAvailableCredit] = useState<string | undefined>('R$ 0')
  const [hasRegister, setHasRegister] = useState<boolean | undefined>(true)
  const [amortizationType, setAmortizationType] = useState<string>('price')
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingAvailable, setLoadingAvailable] = useState<boolean>(false)

  const [formRef] = Form.useForm()
  const inputRef: MutableRefObject<any> = useRef(null)
  const dispatch = useDispatch()
  const simulation = useSimulation()
  const user = useAuth()

  const onSubmit = async (values: ISimulatorLoanInfoStepValues) => {
    const allSimulationValues = Object.assign({}, simulation.values)

    if (!values.deadline) values.deadline = deadline
    if (values.deadline) values.deadline = deadline

    if (creditValue) values.creditValue = format.formatMoneySend(creditValue)
    if (!values.amortizationType) values.amortizationType = amortizationType

    const data: ISimulatorLoanInfoStepValues = {
      ...allSimulationValues,
      ...values,
      index: simulation.lastStep?.index,
      hasRegister: hasRegister,
      currentStep: 4,
      simulationId: simulation.id || simulatorData?.simulationId,
      product: 'home-equity'
    } as const

    setLoading(true)
    setErrors({})

    try {
      const response = await consumerSimulation.simulation(data)
      const lastStepInfo = response.data.simulation
      const simulationId = response.data.simulation.simulationId
      if (!simulation.id || !simulatorData?.simulationId) dispatch(simulationSetId(simulationId))

      dispatch(simulationLastStep(lastStepInfo))
      dispatch(simulationValues(data, 4))
      setLoading(false)
      swal.basic({ title: 'Sucesso', text: response.message, icon: 'success' })
    } catch (err) {
      if (err?.data?.needsVinculation) {
        swal.confirmNegate({
          icon: 'question',
          title: 'Atenção',
          text: 'Você gostaria de se vincular a esse lead?',
          confirm: async () => {
            try {
              if (!simulation?.values?._id) throw new Error('Esta proposta não está válida para alteração')
              const response = await LeadsRepository.changeCommercial(simulation?.values?._id, { commercialId: user._id })
              const message = response.data.message

              swal.basic({ title: 'Sucesso!', text: message, icon: 'success' })
              dispatch(tablesUpdate())
            } catch (err) {
              swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
            }
          }
        })
      }

      if (err.data?.invalid) {
        const invalidsFields = validateResponse(err.data?.invalid)
        setErrors(ProposalEgiModel.factoryErrorMessage(invalidsFields))

        const mappedInvalids = ProposalEgiModel.mapErrorMessage([...err.data?.invalid])
        invalidSimulationFieldsError({
          invalidFields: mappedInvalids
        })

        return
      }

      setErrors({})
      swal.htmlBasic({ title: 'Atenção', text: err.message, icon: 'warning' })
    } finally {
      setLoading(false)
    }
  }

  async function getAvailableCreditValue ({ deadlineValue, amortization, register }: IGetAvailableCreditValue) {
    if (cancelRequest) cancelRequest()

    setLoadingAvailable(true)
    try {
      const data = {
        ...simulation.values,
        hasRegister: register,
        amortizationType: amortization || simulation.values?.amortizationType,
        creditValue: String(simulation.values?.propertyValue) === '' ? undefined : simulation.values?.propertyValue,
        currentStep: 4,
        deadline: deadlineValue || deadline,
        resourceGoal: 'lazer',
        index: simulation.lastStep?.index ?? undefined,
        simulationId: simulation.id || simulatorData?.simulationId,
        product: 'home-equity'
      }

      const response = await simulationRepository.getAvailableCreditValue(data as Partial<ISimulation>)
      setAvailableCredit(response.data.data.maxCreditValue)
      setErrorMessage('')
    } catch (err) {
      setErrorMessage(err.message)
    } finally {
      setLoadingAvailable(false)
    }
  }

  useEffect(() => {
    const simulationEntry = async () => {
      const simulationValues = Object.assign({}, simulation.values)
      const data = {
        product: 'home-equity',
        propertyValue: simulationValues.propertyValue,
        sub: simulationValues.sub,
        birthdate: simulationValues.birthdate,
        personType: simulationValues.personType
      } as const

      try {
        const response = await consumerSimulation.entries(data)
        const ranges = response.data.pricing
        dispatch(simulationPricngValues(ranges))
      } catch (err) {
        if (err.message) {
          if (err?.code === 412) swal.htmlBasic({ icon: 'warning', title: 'Atenção', text: err.message })
          else swal.basic({ icon: 'warning', title: 'Atenção', text: err.message })
          dispatch(simulationSteps(2))
        }

        swal.basic({ icon: 'warning', title: 'Atenção', text: err.message })
        console.warn(err)
      }
    }

    if (simulation.values) simulationEntry()
  }, [simulation.values])

  useEffect(() => {
    function onFill () {
      const data = Object.assign({}, simulation.values) || {}

      if (data.deadline) setDeadline(data.deadline)
      else setDeadline(simulation.pricing?.deadlineMax)

      if (data.amortizationType) {
        const lowerCaseAmortizationType = data.amortizationType.toLowerCase()
        setAmortizationType(lowerCaseAmortizationType)
        data.amortizationType = lowerCaseAmortizationType
      }

      if (data.creditValue) { setCreditValue(String(data.creditValue)) }
      data.amortizationType = data.amortizationType?.toLowerCase() as _amortizationTypeEnum

      if (data.hasRegister !== false) {
        setHasRegister(true)
        data.hasRegister = 'yes'
      } else {
        setHasRegister(false)
        data.hasRegister = 'no'
      }

      if (data.propertyValue) {
        getAvailableCreditValue({
          deadlineValue: data.deadline ?? simulation.pricing?.deadlineMax,
          register: data.hasRegister === 'yes' ? true : false
        })
      }

      if (formRef) formRef.setFieldsValue(data)
    }

    onFill()
  }, [
    simulation.values,
    simulation.pricing
  ])

  useEffect(() => {
    if (creditValue === undefined && simulation.values?.creditValue === undefined) setCreditValue(String(simulation?.pricing?.creditMin))
  }, [simulation?.pricing?.creditMin])

  useEffect(() => {
    if (!simulation.values?.deadline) setDeadline(simulation.pricing?.deadlineMax)
  }, [simulation.pricing])

  return (
    <>
      {errorMessage && (
        <Alert className='mb-3' message={errorMessage} type='error' />
      )}

      <Form
        className="simulator-form"
        layout="vertical"
        form={formRef}
        onFinish={onSubmit}
      >
        <Form.Item
          name="creditValue"
          className="mb-0"
          label={<label className="simulator-label">Valor do empréstimo</label>}
          help={errors.creditValue && errors.creditValue}
          validateStatus={errors.creditValue && 'error'}
        >
          <div className="position-relative">
            <InputMoney
              inputRef={inputRef}
              id="creditValue"
              defaultValue={creditValue}
              value={creditValue}
              className="simulator-input ant-input"
              onChange={(value: any) => { setCreditValue(value) }}
              placeholder="R$"
            />

            <span className="simulation-tag-input user-select" onClick={() => inputRef.current.focus()}>
              Valor mínimo: <label className="simulation-values-text">
                {format.formatBRLMinAndMax(simulation.pricing?.creditMin)}
              </label>
            </span>
          </div>
        </Form.Item>

        <div className="text-center">
          <label className="color-primary">
            Valor de crédito disponível: <b>
              {loadingAvailable
                ? <LoadingOutlined className="ml-2"/>
                : format.precisionFormatBRL(Number(availableCredit))
              }
            </b>
          </label>
        </div>

        <Form.Item
          name="resourceGoal"
          className="simulation-resourceGoal mt-3"
          label={<label className="simulator-label">Qual a finalidade do seu empréstimo?</label>}
          help={errors.resourceGoal && errors.resourceGoal}
          validateStatus={errors.resourceGoal && 'error'}
        >
          <Select
            id="test-select-simulation-resourceGoal"
            placeholder="Escolha uma opção"
            className="unauth-inputs-center"
          >
            <Select.Option id="test-select-simulation-resourceGoal-quitardividas" value="quitardividas">Quitar dívidas</Select.Option>
            <Select.Option id="test-select-simulation-resourceGoal-portabilidade" value="portabilidade">Portabilidade</Select.Option>
            <Select.Option id="test-select-simulation-resourceGoal-construcao" value="construcao">Reforma</Select.Option>
            <Select.Option id="test-select-simulation-resourceGoal-investimento" value="investimento">Investimento</Select.Option>
            <Select.Option id="test-select-simulation-resourceGoal-capitaldegiro" value="capitaldegiro">Capital de giro</Select.Option>
            <Select.Option id="test-select-simulation-resourceGoal-aquisicaodebens" value="aquisicaodebens">Aquisição de bens</Select.Option>
            <Select.Option id="test-select-simulation-resourceGoal-lazer" value="lazer">Viajar</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="deadline"
          className="mb-0 form-slider-simulator"
          label={<label className="simulator-label">Em quantos meses quer pagar o seu empréstimo?</label>}
          help={errors.deadline && errors.deadline}
          validateStatus={errors.deadline && 'error'}
        >
          <div className="flex">
            <div className="flex flex-align-center flex-justify-center mr-3">
              <MinusCircleFilled
                disabled={Number(deadline) <= Number(simulation.pricing?.deadlineMin)}
                onClick={() => {
                  if ((Number(deadline) - 6) >= Number(simulation.pricing?.deadlineMin)) {
                    setDeadline(Number(deadline) - 6)
                    getAvailableCreditValue({ deadlineValue: Number(deadline) - 6, register: hasRegister, amortization: amortizationType })
                  } else {
                    setDeadline(Number(simulation.pricing?.deadlineMin))
                    getAvailableCreditValue({ deadlineValue: Number(simulation.pricing?.deadlineMin), register: hasRegister, amortization: amortizationType })
                  }
                }}
                style={(deadline === Number(simulation.pricing?.deadlineMin))
                  ? { opacity: 0.5, userSelect: 'none' }
                  : { opacity: 1, userSelect: 'none' }
                }
                className='pointer simulator-deadline-increase'
              />
            </div>

            <InputNumber
              id="deadline"
              className="w-100 mx-2 mx-md-0 simulator-input user-select"
              value={Number(deadline) || simulation.pricing?.deadlineMax}
              onChange={(item: string | number | null | undefined) => {
                setDeadline(Number(item))
              }}
              onBlur={(e) => getAvailableCreditValue({ deadlineValue: Number(e.target.value), register: hasRegister, amortization: amortizationType })}
              min={Number(simulation.pricing?.deadlineMin)}
              max={Number(simulation.pricing?.deadlineMax)}
            />

            <div className="flex flex-align-center flex-justify-center ml-3">
              <PlusCircleFilled
                disabled={Number(deadline) >= Number(simulation.pricing?.deadlineMax)}
                onClick={() => {
                  if ((Number(deadline) + 6) <= Number(simulation.pricing?.deadlineMax)) {
                    setDeadline(Number(deadline) + 6)
                    getAvailableCreditValue({ deadlineValue: Number(deadline) + 6, register: hasRegister, amortization: amortizationType })
                  } else {
                    getAvailableCreditValue({ deadlineValue: Number(simulation.pricing?.deadlineMax), register: hasRegister, amortization: amortizationType })
                    setDeadline(Number(simulation.pricing?.deadlineMax))
                  }
                }}
                style={(deadline === Number(simulation.pricing?.deadlineMax)) ? { opacity: 0.5, userSelect: 'none' } : { opacity: 1 }}
                className='pointer simulator-deadline-increase'
              />
            </div>
          </div>
        </Form.Item>

        <div className="flex flex-justify-center my-3 user-select no-wrap-white-space">
          {!!Number(simulation.pricing?.deadlineMin) && (
            <Fragment>
              <h4 className="color-primary">Mínimo: {Number(simulation.pricing?.deadlineMin)} meses</h4> -
              <h4 className="color-primary">Máximo: {Number(simulation.pricing?.deadlineMax)} meses</h4>
            </Fragment>
          )}
        </div>

        <Form.Item
          name="amortizationType"
          className="amortizationType-radio"
          label={
            <label className="simulator-label user-select mb-3">
              Sistema de amortização
              <label className="pl-1">
                <Tooltip
                  color="white"
                  placement="right"
                  destroyTooltipOnHide={{ keepParent: false }}
                  title={<TextAmortization />}
                >
                  <InfoCircleOutlined className="simulation-info "/>
                </Tooltip>
              </label>
            </label>
          }
        >
          <Radio.Group
            className="w-100 simulator-radio"
            defaultValue={amortizationType}
            value={amortizationType}
            size="large"
          >
            <Radio.Button
              className="position-relative simulation-amortization-tag"
              value="price"
              onClick={() => {
                setAmortizationType('price')
                getAvailableCreditValue({ amortization: 'price', register: hasRegister })
              }}
            >
                PRICE
            </Radio.Button>

            <Radio.Button
              value="sac"
              onClick={() => {
                setAmortizationType('sac')
                getAvailableCreditValue({ amortization: 'sac', register: hasRegister })
              }}
            >
                Tabela SAC
            </Radio.Button>
          </Radio.Group>

        </Form.Item>

        <Form.Item
          name="hasRegister"
          className="simulation-hasRegister"
          label={
            <label className="simulator-label">
            Você deseja incluir custos de registro
              <label className="pl-1">
                <Tooltip
                  color="white"
                  placement="right"
                  destroyTooltipOnHide={{ keepParent: false }}
                  title={
                    <p className="simulator-tooltip-paragraph mb-0">
                    O custo de registro é direcionado para pagamento das despesas de cartório para a efetivação do imóvel como garantia do empréstimo,
                    sendo descontado do crédito a liberar.
                    </p>
                  }
                >
                  <InfoCircleOutlined className="simulation-info" />
                </Tooltip>
              </label>
            </label>
          }
        >
          <Radio.Group
            className="w-100 simulator-radio"
            defaultValue="yes"
            size="large"
          >
            <Radio.Button
              value="yes"
              id="simulation-has-register"
              className="w-25"
              onClick={() => {
                getAvailableCreditValue({ register: true, amortization: amortizationType })
                setHasRegister(true)
              }}
            >
                Desejo incluir
            </Radio.Button>

            <Radio.Button
              value="no"
              id="simulation-not-has-register"
              onClick={() => {
                getAvailableCreditValue({ register: false, amortization: amortizationType })
                setHasRegister(false)
              }}
            >
                Não desejo incluir
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item>
          <Button
            id="finishStep"
            className="simulator-calculation-button w-100"
            htmlType="submit"
            loading={loading}
          >
            PRÓXIMA ETAPA
          </Button>

          <Button
            className="mt-3 color-primary simulator-specialist-button w-100"
            style={{ height: '50px !important' }}
            onClick={() => { dispatch(simulationSteps(simulation.step - 1)) }}
            type="ghost"
          >
            VOLTAR
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default SimulationLoanInfoStep

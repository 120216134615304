import React, { Fragment } from 'react'
import { Typography } from 'antd'
import { proposals } from 'egi/consumers'
import { useTables } from 'hooks'
import DeclineProposal from './DeclineProposal'

function RefuseProposalInList () {
  const tables = useTables()

  return (
    <Fragment>
      <div style={{ borderLeft: '2px solid red' }}>
        <Typography.Paragraph className="text-left ml-1">
          Atenção ao cancelar a proposta, nenhuma ação poderá ser tomada nos passos e a proposta ficará parada.
        </Typography.Paragraph>
      </div>

      <DeclineProposal submitFn={proposals.refuse} proposalId={tables.row?._id} />
    </Fragment>
  )
}

export default RefuseProposalInList

import React, { useEffect, useState } from 'react'
import { Image } from 'antd'
import dayjs from 'dayjs'
import format from 'utils/format'
import { timeAsDayjs } from 'utils/time'
import imageUrls from 'utils/imagesUrls'
import { useProposal } from 'hooks'
import { Department } from 'store/modules/proposal/types'
import { IParcels } from 'egi/app/Simulator/simulatorInterfaces'

interface ICETData {
  cronograma: IParcels[]
  operacao_IOF: number
  operacao_prazo: number
  operacao_taxa_juros_mensal: number
  operacao_taxa_juros_anual: number
  operacao_amortizacao: string
  reajuste_indice: string
  operacao_tarifa_avaliacao_bens: number
  operacao_CET_anual: number
  operacao_CET_mensal: number
  operacao_despesas_de_registro: number
  operacao_prestamista_final: number
  operacao_dfi_final: number
  operacao_valor_total: number
  operacao_valor_parcela_mensal: number
  operacao_emprestimo_liberado: number
  operacao_total_juros: number
  operacao_emprestimo_concedido: number
  operacao_valor_financiamento: number
  operacao_vencimento_primeira_parcela: string
  operacao_vencimento_ultima_parcela: string
  operacao_data_emprestimo: string
}

function SimulationCET () {
  const proposal = useProposal()
  const [cetData, setCETData] = useState<ICETData>()

  useEffect(() => {
    function getContractValue () {
      const contractDepartment: Department | undefined = proposal.departments.find((deparment) => deparment.name === 'emissão do contrato')
      if (contractDepartment) {
        const contractStep: ICETData = contractDepartment.steps.find(step => step.slug === 'formulario-do-contrato')?.form as unknown as ICETData

        if (contractStep) {
          if (contractStep.cronograma) {
            contractStep.operacao_prestamista_final = contractStep.cronograma.reduce((acc, obj) => {
              if (!obj.prestamista) return 0
              return acc + obj.prestamista
            }, 0)

            contractStep.operacao_dfi_final = contractStep.cronograma.reduce((acc, obj) => {
              if (!obj.dfi) return 0
              return acc + obj.dfi
            }, 0)

            contractStep.operacao_valor_total = contractStep.cronograma.reduce((acc, obj) => {
              if (!obj.totalParcel) return 0
              return acc + obj.totalParcel
            }, 0)

            contractStep.operacao_total_juros = contractStep.cronograma.reduce((acc, obj) => {
              if (!obj.interest) return 0
              return acc + obj.interest
            }, 0)
          }

          if (contractStep.operacao_CET_anual) contractStep.operacao_CET_mensal = Math.pow((contractStep.operacao_CET_anual + 1), (1 / 12)) - 1

          contractStep.operacao_valor_financiamento = contractStep.operacao_emprestimo_liberado + contractStep.operacao_tarifa_avaliacao_bens

          setCETData(contractStep)
        }
      }
    }

    getContractValue()
  }, [])

  return (
    <div id="cet-template" className="cet-container">
      <div className="flex flex-align-center flex-justify-between">
        <Image className="unauth-logo-file-upload" src={imageUrls.rodobensLogoLight} />
        <h4 className="mb-0 bold">Planilha de Cálculo do Custo Efetivo Total - CET</h4>
        <h3 className="mb-0 bold"/>
      </div>
      <div className="bold" style={{ width: '100%', height: 20, backgroundColor: '#CECECE' }}>Dados da proposta</div>

      <table className="w-100">
        <tr>
          <td>Valor do financiamento</td>
          <td className="cet-container__w-10">{format.formatBRL(cetData?.operacao_valor_financiamento)}</td>
          <td>{cetData?.operacao_valor_total && cetData?.operacao_valor_financiamento ? format.decimalToPercentage(cetData?.operacao_valor_financiamento / cetData?.operacao_valor_total).toFixed(2) + '%' : '-'}</td>
          <td>Periocidade:</td>
          <td>MENSAL</td>
        </tr>

        <tr>
          <td>Imposto (IOF): </td>
          <td className="cet-container__w-10">{format.formatBRL(cetData?.operacao_IOF)}</td>
          <td>{cetData?.operacao_valor_total && cetData?.operacao_IOF ? format.decimalToPercentage(cetData.operacao_IOF / cetData?.operacao_valor_total).toFixed(2) + '%' : '-' }</td>
          <td>Prazo(meses):</td>
          <td>{cetData?.operacao_prazo ? cetData?.operacao_prazo : '-'}</td>
        </tr>

        <tr>
          <td colSpan={3}/>
          <td>Quantidade de Parcelas: </td>
          <td>{cetData?.operacao_prazo ? cetData?.operacao_prazo : '-'}</td>
        </tr>

        <tr>
          <td colSpan={3} >Seguros</td>
          <td>Vencto da 1º PR: </td>
          <td>{cetData?.operacao_vencimento_primeira_parcela ? timeAsDayjs(cetData?.operacao_vencimento_primeira_parcela).format('DD/MM/YYYY') : '-'}</td>
        </tr>

        <tr>
          <td>DFI:</td>
          <td>{format.formatBRL(cetData?.operacao_dfi_final)}</td>
          <td>{cetData?.operacao_dfi_final && cetData?.operacao_valor_total ? format.decimalToPercentage(cetData?.operacao_dfi_final / cetData?.operacao_valor_total).toFixed(2) + '%' : '-'}</td>
          <td>Vencto da última PR:</td>
          <td>{cetData?.operacao_vencimento_ultima_parcela ? timeAsDayjs(cetData?.operacao_vencimento_ultima_parcela).format('DD/MM/YYYY') : '-'}</td>
        </tr>

        <tr>
          <td>Vida - Prestamista:</td>
          <td>{format.formatBRL(cetData?.operacao_prestamista_final)}</td>
          <td>{cetData?.operacao_prestamista_final && cetData?.operacao_valor_total ? format.decimalToPercentage(cetData?.operacao_prestamista_final / cetData?.operacao_valor_total).toFixed(2) + '%' : '-'}</td>
        </tr>

        <tr>
          <td colSpan={3}/>
          <td>Prazo do Seguro: ***</td>
        </tr>

        <tr>
          <td className="py-3">Despesas de Registro da Garantia:</td>
          <td className="py-3">{format.formatBRL(cetData?.operacao_despesas_de_registro)}</td>
          <td className="py-3">{cetData?.operacao_valor_total && cetData?.operacao_despesas_de_registro ? format.decimalToPercentage(cetData?.operacao_despesas_de_registro / cetData?.operacao_valor_total).toFixed(2) + '%' : '-'}</td>
        </tr>

        <tr>
          <td>Tarifa de Avaliação:</td>
          <td>{format.formatBRL(cetData?.operacao_tarifa_avaliacao_bens)}</td>
          <td>{cetData?.operacao_valor_total && cetData?.operacao_tarifa_avaliacao_bens ? format.decimalToPercentage(cetData?.operacao_tarifa_avaliacao_bens / cetData?.operacao_valor_total).toFixed(2) + '%' : '-'}</td>
        </tr>

        <tr>
          <td>Taxa de Juros Efetiva Mensal:</td>
          <td>{format.decimalToPercentage(cetData?.operacao_taxa_juros_mensal).toFixed(4)}%</td>
          <td>{cetData?.operacao_valor_total && cetData?.operacao_total_juros ? format.decimalToPercentage(cetData?.operacao_total_juros / cetData?.operacao_valor_total).toFixed(2) + '%' : '-'}</td>
          <td>(referente ao valor unitário de R$0,00 p/ BR)</td>
        </tr>

        <tr>
          <td colSpan={3}/>
          <td className="pt-4">Valor dos Juros: </td>
          <td className="pt-4">{format.formatBRL(cetData?.operacao_total_juros)}</td>
        </tr>

        <tr>
          <td colSpan={3}/>
          <td>Taxa de Juros Efetiva Anual: </td>
          {cetData?.operacao_taxa_juros_anual && (<td>{format.decimalToPercentage(cetData?.operacao_taxa_juros_anual).toFixed(4)}%</td>)}
        </tr>

        <tr>
          <td>Valor Total (incluso o juros): </td>
          <td colSpan={2}>{format.formatBRL(cetData?.operacao_valor_total)}</td>
          <td>Valor Parcela: </td>
          <td>{cetData && Array.isArray(cetData.cronograma) && cetData.cronograma.length > 0 ? format.formatBRL(cetData.cronograma[0].totalParcel) : '-'}</td>
        </tr>

        <tr>
          <td colSpan={3}/>
          <td>Intermediária: </td>
          <td>{format.formatBRL(0)}</td>
        </tr>

        <tr>
          <td colSpan={3}/>
          <td>Quantidade de intermediárias: </td>
          <td>{0}</td>
        </tr>

        <tr>
          <td colSpan={3}/>
          <td>Correção Monetária: </td>
          <td>{cetData?.reajuste_indice ? cetData?.reajuste_indice : '-'}</td>
        </tr>

        <tr>
          <td colSpan={3}/>
          <td>Tipo de amortização: </td>
          <td>{cetData?.operacao_amortizacao ? cetData?.operacao_amortizacao : '-'}</td>
        </tr>

        <tr>
          <td colSpan={1}/>
          {cetData?.operacao_CET_mensal && <td className="cet-container__cet-result"><b>CET ao mês:</b> {format.decimalToPercentage(cetData?.operacao_CET_mensal).toFixed(4)}%</td>}
        </tr>
        <tr> <td className="pt-2"/> </tr>
        <tr>
          <td colSpan={1}/>
          {cetData?.operacao_CET_anual && <td className="cet-container__cet-result"><b>CET ao ano:</b> {format.decimalToPercentage(cetData?.operacao_CET_anual).toFixed(4)}%</td>}
        </tr>
        <tr> <td className="pt-2"/> </tr>
      </table>

      <div className="bold" style={{ width: '100%', height: 20, backgroundColor: '#CECECE' }}/>
      <div className="cet-container__text">
        <p>
          O Custo Efetivo Total - CET é calculado considerando os fluxos referente às liberações  e os pagamentos previstos no contrato, incluindo a taxa efetiva de juros anual.
        </p>
        <p>
          O percentual apresentado ao lado do valor em R$ dos campos relacionados ao componente do fluxo da operação (valor financiado para compra do bem, tributos, seguros, tarifas e serviços) foi apurado observado o critério estabelecido na Resolução CMN nº. 4.881, de 23/12/2020, do Banco Central do Brasil, ou seja: valor do componente do fluxo da operação em relação ao valor financiado e demais.
        </p>
        <p>
          O Proponente declara ter tomado ciência do presente demonstrativo, cujo conteúdo lhe foi integralmente esclarecido, autorizando o BANCO a destinar os valores estabelecidos para pagamento de terceiros, se for o caso.
        </p>
        <p>
        O Proponente declara ter ciência que SCR é o Sistema de Informações de Crédito do Banco Central do Brasil (BACEN) de compartilhamento de dados entre instituições financeiras, contendo dados e operações e/ou garantias dos clientes das instituições financeiras, para subsidiar sua tomada de decisões de crédito e negócios, que poderão ser utilizadas pelo BANCO RODOBENS S/A, o qual autoriza o BANCO RODOBENS S/A ter o devido acesso aos seus dados lá contidos, bem como faça as devidas inclusões necessárias, nos termos da legislação vigente.
        </p>
      </div>

      <table className="w-100 my-4">
        <tr>
          <td colSpan={3}>Local: São Paulo</td>
          <td className="pb-6">Data:</td>
          <td className="pb-6">{dayjs().format('DD/MM/YYYY')}</td>
        </tr>

        <tr>
          <td colSpan={3}/>
          <td className="cet-container__proponente">Proponente</td>
        </tr>
      </table>

      <label>SAC - <b>0800 709 9220</b> e-mail <i>sacbanco@rodobens.com.br</i> / Ouvidoria - <b>0800 722 2160</b> e-mail - <i>sacbanco@rodobens.com.br</i></label>
    </div>
  )
}

export default SimulationCET

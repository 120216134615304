import { AxiosRequestConfig } from 'axios'
import { IResponseBase, Repository } from 'ecp/repositories/Repository'
import api from 'services/api'

class ContractsEgiRepository extends Repository {
  async controlActive (data: {proposalId?: string, personRef: string, ativo?: boolean}, config?: AxiosRequestConfig) {
    return Repository.handle<IResponseBase>(() =>
      this.api.patch(`${this.path}/compositor/active`, data, config)
    )
  }
}

export default new ContractsEgiRepository({ api, path: '/contract' })

import React, { useLayoutEffect, useState } from 'react'
import { Button, Col, Form, Row, Modal } from 'antd'
import { Input } from 'components'
import { user } from 'egi/consumers'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'

type Props = {
  userInfo: {
    name?: string
    email?: string
    id?: string
  }
  visible: boolean
  onSuccess?: () => void
  onClose(): void
}

function ModalChangeEmail ({ userInfo, visible, onClose, onSuccess }: Props) {
  const [errors, setErrors] = useState<{ email?: string }>({})
  const [loading, setLoading] = useState<boolean>(false)

  const [formRef] = Form.useForm()

  async function submitEmail (values: { email: string }) {
    setLoading(true)
    try {
      if (!userInfo.id) throw new Error('Falha ao encontrar id do usuário.')

      const response = await user.changeUserEmail({ ...values, userId: userInfo.id })
      if (onSuccess) onSuccess()
      swal.basic({ title: 'Sucesso', text: response.message, icon: 'success' })
    } catch (err) {
      if (err.data && err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
        return
      }
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    }

    setLoading(false)
  }

  useLayoutEffect(() => {
    if (formRef && userInfo.email) {
      formRef.setFieldsValue({ email: userInfo.email })
    }
  }, [userInfo.email])

  return (
    <Modal
      visible={visible}
      destroyOnClose={true}
      onCancel={onClose}
      centered={true}
      title={`Solicitação de troca de email: ${userInfo.name}`}
      className="modal-config modal-config--w-30"
      footer={null}
    >
      <p className='mb-2'><b>Atenção!</b> Ao enviar uma solicitação de troca de email, o mesmo somente será trocado caso o usuário confirme a solicitação.</p>

      <Form
        form={formRef}
        onFinish={submitEmail}
        layout="vertical"
      >
        <Form.Item
          name="email"
          help={errors.email && errors.email}
          validateStatus={errors.email && 'error'}
          label={<label>Novo email</label>}
        >
          <Input
            placeholder="Digite seu email"
            error={errors.email}
          />
        </Form.Item>

        <Row gutter={[15, 15]}>
          <Col>
            <Form.Item className="mb-0">
              <Button
                style={{ minWidth: 125 }}
                loading={loading}
                type="primary"
                htmlType="submit"
              >
                Enviar
              </Button>
            </Form.Item>
          </Col>

          <Col>
            <Button
              style={{ minWidth: 125 }}
              onClick={onClose}
              type="ghost"
            >
              Cancelar
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default ModalChangeEmail

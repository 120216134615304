import React, { useEffect, useState } from 'react'
import { Checkbox, Col, Form, Row } from 'antd'
import { useAuth, useClient, useProposal, useStep } from 'hooks'
import { proposals } from 'egi/consumers'

import format from 'utils/format'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'

import { IFormProps } from '../types'
import { InputMoney } from 'components'
import { proposalLoadingStep } from 'store/modules/proposal/actions'
import { useDispatch } from 'react-redux'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'

function FormIncome ({ formRef, onType, onLoading, callbackProposal, readOnlyInput, openSimulationDrawer }: IFormProps) {
  const [errors, setErrors] = useState<{ income?: string, irFree?: string }>({})

  const step = useStep()
  const user = useAuth()
  const client = useClient()
  const proposal = useProposal()
  const dispatch = useDispatch()

  const onSubmit = async (values: { income: string | number }) => {
    if (proposal.id && user) {
      const formData: any = {}

      if (user.level === 'promoter') {
        formData.clientId = String(client.id)
      }
      if (values.income) values.income = format.formatMoneySend(String(values.income))

      const props = {
        proposalId: proposal.id,
        stepId: step._id,
        areaId: step.areaId,
        form: { ...formData, ...values }
      }

      try {
        let response

        if (onType === 'send') {
          onLoading('sending')
          props.form = { ...props.form, finished: true }
          response = await proposals.send(props)
        } else if (onType === 'save') {
          onLoading('saving')
          props.form = { ...props.form, finished: false }
          response = await proposals.send(props)
        }

        if (step.id) dispatch(proposalLoadingStep({ stepId: step.id, loading: true }))
        setErrors({})
        callbackProposal()
        swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
      } catch (err) {
        if (err?.data?.maxDeadline !== undefined && err?.data?.maxDeadline !== null) {
          swal.confirm({
            icon: 'warning',
            title: 'Atenção',
            html: err.message,
            confirmButtonText: 'Refazer simulação',
            showCancelButton: false,
            confirm: () => {
              if (openSimulationDrawer) openSimulationDrawer({ type: 'simulator' })
            }
          })
        }

        if (err.data?.invalid) {
          let errors: { income?: string } = {}
          errors = validateResponse(err.data?.invalid)
          setErrors(errors)

          const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
          invalidFieldsError({ invalidFields: invalidFields })
          return
        }

        setErrors({})
        swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
        return false
      } finally {
        onLoading('')
      }
    }
  }

  useEffect(() => {
    function resetFields () {
      if (formRef) formRef.resetFields()
    }

    async function onFill () {
      const formData = (step as any).form || {}
      if (Object.keys(formData).length < 1) {
        resetFields()
        return
      }

      formRef.setFieldsValue({ ...formData })
    }

    resetFields()
    onFill()

    return () => {
      resetFields()
    }
  }, [step.form, formRef, step._id, step.situation])

  return (
    <Form
      form={formRef}
      onFinish={onSubmit}
      layout="vertical"
      initialValues={{
        irFree: false
      }}
    >
      <Row>
        <Col className="px-1" lg={8} sm={12} xs={24}>
          <Form.Item
            name='income'
            label={<label>Faturamento médio mensal:</label>}
            help={errors.income && (errors.income)}
            validateStatus={errors.income && ('error')}
          >
            <InputMoney
              id="test-select-income"
              readOnly={readOnlyInput}
              name='income'
              placeholder='Digite o valor dos rendimentos'
            />
          </Form.Item>
        </Col>

        <Col lg={8} sm={12} xs={24}>
          <Form.Item
            name='irFree'
            className="form-user-checkbox"
            valuePropName="checked"
            label={<label></label>}
            help={errors.irFree && (errors.irFree)}
            validateStatus={errors.irFree && ('error')}
          >
            <Checkbox
              id="test-checkbox-irFree"
              disabled={readOnlyInput}
            >
              Isento de IR (Imposto de Renda)?
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default FormIncome

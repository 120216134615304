import { Button } from 'antd'
import React, { useState } from 'react'
import BackofficeReportsModal from '../BackofficeReportsModal/BackofficeReportsModal'

export default function BackofficeReportsButton () {
  const [visible, setVisible] = useState(false)

  const onCancel = () => {
    setVisible(false)
  }

  return (
    <>
      <BackofficeReportsModal
        onCancel={onCancel}
        visible={visible}
      />

      <Button
        type='primary'
        onClick={() => {
          setVisible(true)
        }}
        className='back-office-details__button'
      >
        Gerar relatório
      </Button>
    </>
  )
}

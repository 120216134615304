import React, { useState, useEffect, Fragment } from 'react'
import { Form, Button, Divider, Image, Col } from 'antd'
import { UnauthLayout } from 'layouts'
import { Input } from 'components'
import { Link, useHistory } from 'react-router-dom'
import { user } from '../../../../consumers'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'
import queryString from 'query-string'
import InputPassword from 'components/Inputs/InputPassword/InputPassword'
import { owaspVerify } from 'utils/owasp'
import imagesUrls from 'utils/imagesUrls'
import format from 'utils/format'
import { PASSWORD_HELP_TEXT } from 'egi/app/Signup/signupConstants'
import { FortifyChangePasswordText } from '../../../FortifyPassword/FortifyPassword'
import { swalError } from 'components/SwalError/SwalError'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'
import { useResetPassword } from '../../hooks/useResetPassword'
import useAuthLayout from 'hooks/useAuthLayout'
import LoginRepositoryEgi from 'egi/repositories/LoginRepositoryEgi'

interface IConfirmPasswordErrors {
  password?: string
  confirmPassword?: string
}

function ResetPassword () {
  const [loading, setLoading] = useState<boolean>(false)
  const [passwordError, setPasswordError] = useState<string | undefined>(undefined)
  const [token, setToken] = useState<string | undefined>(undefined)
  const [errors, setErrors] = useState<IConfirmPasswordErrors>({})
  const [disableField, setDisableField] = useState<{ cpf: number, email: number }>({
    cpf: 0,
    email: 0
  })

  const history = useHistory()
  const { onSubmit, loading: resetPassordLoading } = useResetPassword()
  const { handleSigninByAuth } = useAuthLayout()

  const query = queryString.parse(history.location.search)
  const isFortifyPassword = query?.fortify

  useEffect(() => {
    async function send (token: string) {
      try {
        await user.validateToken(token)
        setToken(token)
      } catch (err) {
        if (err.data?.invalid) {
          const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
          invalidFieldsError({ invalidFields: invalidFields })
          return
        }

        swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      } finally {
        setTimeout(() => {
          swal.closeSwal()
        }, 5000)
      }
    }

    const auxToken = query.token as string
    if (auxToken) { send(auxToken) }
  }, [])

  const onSubmitPassword = async (passwords: { password: string, confirmPassword: string }) => {
    const { password, confirmPassword } = passwords

    setLoading(true)
    try {
      const response = await LoginRepositoryEgi.changePassword({ password, confirmPassword, token: token as string })

      const auth = response.data?.data
      if (auth?.token) {
        await handleSigninByAuth(auth)
      } else {
        swal.basic({ title: 'Sucesso!', text: response.data?.message, icon: 'success' })
        history.push('/')
      }
    } catch (err) {
      if (err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
      } else {
        swalError({ err })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <UnauthLayout>
      <div className="unauth-form-border">
        <div className='text-center'>
          <Image
            src={imagesUrls.rodobensLogoLight}
            preview={false}
            className='signin-image-logo m-0'
            alt="rodobens"
          />
        </div>

        {!token && (
          <Fragment>
            <p className='unauth-reset-password-text'>
              Preencha seu <b>E-mail</b> ou <b>CPF</b> de acesso para enviar a recuperação de senha.
            </p>

            <Form
              onFinish={onSubmit}
              layout="vertical"
            >
              <Form.Item
                name='email'
                label={<label className="simulator-label">Email</label>}
              >
                <Input
                  placeholder="Digite seu email"
                  className="unauth-inputs"
                  disabled={disableField.cpf > 0}
                  onChange={(e) => setDisableField(prev => ({ ...prev, email: e.target.value.length }))}
                />
              </Form.Item>

              <Divider className="unauth-divider" plain>OU</Divider>

              <Form.Item
                name='cpf'
                label={<label className="simulator-label">CPF</label>}
              >
                <Input
                  placeholder="Digite seu CPF"
                  mask="cpf"
                  className="unauth-inputs"
                  disabled={disableField.email > 0}
                  onChange={(event) => {
                    const hasOneLength = event.target.value?.length === 1
                    const value = hasOneLength ? format.onlyDigits(event.target?.value) : event.target?.value

                    setDisableField(prev => ({
                      ...prev,
                      cpf: value.length
                    }))
                  }}
                />
              </Form.Item>

              <Form.Item className="mb-0">
                <Button
                  loading={resetPassordLoading}
                  htmlType="submit"
                  type="primary"
                  className="w-100 unauth-buttons"
                >
                  Enviar email
                </Button>
              </Form.Item>
            </Form>
          </Fragment>
        )}

        {token && (
          <>
            {isFortifyPassword && (
              <div className='mb-5'>
                <FortifyChangePasswordText />
              </div>
            )}

            <Form
              onFinish={onSubmitPassword}
              layout="vertical"
            >
              <Form.Item
                name='password'
                label={<label className="simulator-label">Digite sua nova senha</label>}
                help={errors.password && (errors.password)}
                validateStatus={errors.password && ('error')}
                rules={[
                  () => ({
                    validator (_, value) {
                      if (!owaspVerify(value)) {
                        setPasswordError(PASSWORD_HELP_TEXT)
                        return Promise.resolve()
                      }

                      setPasswordError(undefined)
                      return Promise.resolve()
                    }
                  })
                ]}
              >
                <InputPassword
                  data-cy="test-login-password"
                  placeholder='Senha'
                  className="unauth-inputs"
                  type="password"
                />
              </Form.Item>

              <Form.Item
                name='confirmPassword'
                label={<label className="simulator-label">Digite novamente a senha</label>}
                help={errors.confirmPassword && (errors.confirmPassword)}
                validateStatus={errors.confirmPassword && ('error')}
              >
                <InputPassword
                  data-cy="test-login-password"
                  placeholder='Confirmação de senha'
                  className="unauth-inputs"
                  type="password"
                />
              </Form.Item>

              {passwordError && (
                <Col lg={24} sm={24} xs={24} className="px-1 mb-4">
                  <p className="color-primary font-500">
                    {passwordError}
                  </p>
                </Col>
              )}

              <Form.Item>
                <Button
                  loading={loading}
                  htmlType="submit"
                  type="primary"
                  className="w-100 unauth-buttons"
                >
                  Resetar senha
                </Button>
              </Form.Item>
            </Form>
          </>
        )}

        <div className="text-center">
          <Link to="/" className="color-primary underline unauth-bottom-label">Voltar ao login</Link>
        </div>
      </div>

    </UnauthLayout>
  )
}

export default ResetPassword

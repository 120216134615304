import React, { Fragment, useState, useLayoutEffect } from 'react'
import { Button, Divider, Form, Input, Row } from 'antd'
import { DocumentUpload } from 'components'
import ExplainSend from 'components/ExplainSend/ExplainSend'
import { proposals } from 'egi/consumers'
import api from 'services/api'
import { useAuth, useProposal, useStep } from 'hooks'
import { getProposalState } from 'store/modules/proposal/actions'
import { IDocuments } from 'egi/types'
import swal from 'utils/swal'
import { SnippetsOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { AxiosRequestConfig } from 'axios'
import ProposalEgiRepository from 'egi/repositories/ProposalEgiRepository'
import UploadDocumentModel from 'egi/models/UploadDocumentModel'

const { TextArea } = Input

function UploadDocumentDrawer () {
  const [loading, setLoading] = useState<boolean>(false)
  const [textLoading, setTextLoading] = useState<boolean>(false)
  const [documentPath, setDocumentPath] = useState<IDocuments[]>([])

  const user = useAuth()
  const proposal = useProposal()
  const step = useStep()
  const dispatch = useDispatch()
  const [formRef] = Form.useForm()

  const canSubmit = UploadDocumentModel.canSubmitUploadDocumentDrawer({ level: user.level, type: user.type, areaName: user.areaName })

  const onSubmit = async (values: { textDefense: string }) => {
    setTextLoading(true)
    try {
      const response = await proposals.creditDefense({ data: values, proposalId: String(proposal.id) })
      setTextLoading(false)
      dispatch(getProposalState(String(proposal.id)))
      swal.basic({ title: 'Sucesso!', text: response.message, icon: 'success' })
    } catch (err) {
      setTextLoading(false)
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    }
  }

  const getDocuments = async () => {
    setLoading(true)
    try {
      const response = await api.get(`/proposals/${proposal.id}/departments`)
      const paths = [response.data.data.creditDefense]
      setDocumentPath(paths)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.error(err)
    }
  }

  const onFileUpload = async (request: any) => {
    const { file, onSuccess, onProgress, onError } = request
    if (!file) return ''

    const formData = new FormData()
    formData.append('file', file)
    formData.append('stepId', step._id)

    const config: AxiosRequestConfig = {
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 90000,
      onUploadProgress: ({ total, loaded }) => {
        if (total) onProgress({ percent: Math.round((loaded / total) * 100) })
      }
    }

    try {
      if (!proposal.id) throw Error('Identificador da proposta não encontrado')
      const response = await ProposalEgiRepository.uploadDefense({ proposalId: proposal.id, data: formData }, config)
      const responseFile = { ...response, data: { file } }
      onSuccess(responseFile)
    } catch (err) {
      onError(err)
    }
  }

  const onFileDelete = async (fileUrl: string | object, resolve: Function) => {
    setLoading(true)

    const data = { stepId: step._id, path: fileUrl }
    const config: AxiosRequestConfig = { timeout: 90000 }

    try {
      if (!proposal.id) throw new Error('Identificador da proposta não encontrado')
      const response = await ProposalEgiRepository.deleteDefense({ proposalId: proposal.id, data }, config)
      resolve(true)
      swal.basic({ icon: 'success', title: 'Sucesso!', text: response.data.message })
    } catch (err) {
      console.error(err)
      swal.basic({ icon: 'warning', title: 'Atenção!', text: err.message })
    } finally {
      setLoading(false)
      getDocuments()
    }
  }

  function pasteText () {
    navigator.clipboard.readText().then((text) => {
      formRef.setFieldsValue({ textDefense: text })
    })
  }

  useLayoutEffect(() => {
    (function onFill () {
      const data = Object.assign({}, proposal.creditDefense)

      if (formRef && data.creditText) formRef.setFieldsValue({ textDefense: data.creditText })
    })()
  }, [])

  return (
    <Fragment>
      <ExplainSend isDocument />

      <DocumentUpload
        maxDocs={1}
        skeletonQuantity={2}
        hideUploadButton={false}
        getDocuments={getDocuments}
        onFileUpload={onFileUpload}
        onFileDelete={onFileDelete}
        paths={documentPath}
        loading={loading}
      />

      <Divider/>

      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={formRef}
      >
        <Form.Item
          name="textDefense"
          label={
            <div>
              <label className="uppercase color-primary bold ml-1">
                Digite ou cole o texto da defesa de crédito
              </label>

              {canSubmit && (
                <Button
                  type="primary"
                  size="middle"
                  className="share-button ml-2"
                  icon={<SnippetsOutlined />}
                  onClick={() => pasteText()}
                >
                  Colar texto
                </Button>
              )}
            </div>
          }
        >
          <TextArea
            rows={6}
            readOnly={!canSubmit}
            autoSize={{ minRows: 12, maxRows: 20 }}
          />
        </Form.Item>

        {canSubmit && (
          <Row justify="end">
            <Button
              type="primary"
              loading={textLoading}
              htmlType="submit"
            >
            Enviar texto
            </Button>
          </Row>
        )}

      </Form>
    </Fragment>
  )
}

export default UploadDocumentDrawer

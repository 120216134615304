const setToken = (token: string) => {
  localStorage.setItem('@ux-line/token', token)
}

const getToken = () => {
  const token = localStorage.getItem('@ux-line/token')
  if (token && token !== null) return token
  return undefined
}

const getLevel = () => {
  const level = localStorage.getItem('@ux-line/level')
  if (level && level !== null) return level
  return undefined
}

const clearStorage = () => localStorage.clear()

export {
  setToken,
  getToken,
  getLevel,
  clearStorage
}

import { Button, Form, Row } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { ColumnsType } from 'antd/lib/table'
import SearchCnpjSVG from 'assets/reactSvgs/SearchCnpjSVG/SearchCnpjSVG'
import InputSearch from 'components/Inputs/InputSearch/InputSearch'
import { TableECPF } from 'components/ServerTable/ServerTable'
import { R$ } from 'ecp/app/Proposals/proposalFunctions'
import { EcpCard } from 'ecp/components/EcpCard/EcpCard'
import FindUserHeaderECPF from 'ecpf/app/FindUserECPF/components/FindUserHeaderECPF/FindUserHeaderECPF'
import { useProposalFlow } from 'ecpf/hooks/useProposalFlow'
import { ProposalCustomerFlowClientType, ProposalFuncaoRenegotiationModel } from 'ecpf/models/ProposalFuncaoModel'
import { IClientCovenantECPF } from 'ecpf/repositories/CovenantECPFRepository'
import { IContractECPF } from 'ecpf/repositories/OperationsECPFRepository'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { IReduxProposalFlow } from 'store/modules/proposalFlow/actions'
import { useContracts } from '../../hooks/useOperationsECPF'
import useValidateCnpjECPF from '../../hooks/useValidateCnpjECPF'

interface ISelectContractsECPF {
  onRedirect: (flowType: ProposalCustomerFlowClientType) => void
  description: string
  initialPath: string
}

const columns: ColumnsType<IContractECPF> = [
  {
    title: 'Contrato',
    dataIndex: 'numeroContrato'
  },
  {
    title: 'Parcelas pagas',
    dataIndex: '',
    render: (row: IContractECPF) => {
      const payedDue = (row?.quantidadeParcelas || 0) - (row?.quantidadeParcelasAbertas || 0)
      return `${payedDue} de ${row?.quantidadeParcelas || 0}`
    }
  },
  {
    title: 'Saldo devedor',
    dataIndex: 'saldoData',
    render: (saldoData: number) => saldoData ? R$(saldoData) : '-'
  }
]

function FindCnpjCard () {
  const [form] = useForm()
  const { loadingValidate, onValidateCnpj } = useValidateCnpjECPF()
  const searchCnpjAbortController = React.useRef(new AbortController()).current

  const onSubmit = (values: { cnpj: string }) => {
    if (values.cnpj) onValidateCnpj(values.cnpj, searchCnpjAbortController)
  }

  return (
    <main className='find-user'>
      <section className='find-user__card'>
        <FindUserHeaderECPF
          title='Buscar CNPJ'
          description={'Digite o CNPJ do empregador para seguir com a proposta.'}
          icon={<SearchCnpjSVG />}
        />

        <Form
          onFinish={onSubmit}
          form={form}
          className='find-user__input'
        >
          <Form.Item
            name='cnpj'
            className='m-0'
          >
            <InputSearch
              mask='cnpj'
              loading={loadingValidate}
              disabled={loadingValidate}
              onSearch={() => form.submit()}
              placeholder='Insira o CNPJ do empregador'
            />
          </Form.Item>
        </Form>
      </section>
    </main>
  )
}

interface IHasConvenantInfoProps {
  hasCovenantInfo: boolean,
  children: React.ReactNode
}

function HasConvenantInfo ({ hasCovenantInfo, children } : IHasConvenantInfoProps) {
  if (!hasCovenantInfo) return <FindCnpjCard />

  return <>{children}</>
}

export default function SelectContractsECPF ({ onRedirect, description, initialPath }: ISelectContractsECPF) {
  const { contracts, fetchContracts, loading, error } = useContracts()
  const {
    customerFlow,
    clientCovenant,
    updateSelectContractsFlow
  } = useProposalFlow()
  const history = useHistory()
  const hasCovenantInfo = ProposalFuncaoRenegotiationModel.isValidClientCovenant(clientCovenant)

  const [selectedContracts, setSelectedContracts] = React.useState<string[]>([])

  const onFetchContracts = (clientParams: {clientCovenant: Partial<IClientCovenantECPF> | undefined, customerFlow?: IReduxProposalFlow['customerFlow']}, abortController: AbortController) => {
    const { clientCovenant, customerFlow } = clientParams
    const params = {
      codigoEmpregador: clientCovenant?.origens?.codigoEmpregador || '',
      codigoPromotor: clientCovenant?.origens?.codigoPromotor || '',
      numeroMatricula: customerFlow?.cliente.numeroMatricula || '',
      documento: customerFlow?.cliente.documento || ''
    }

    fetchContracts(params, abortController)
  }

  React.useEffect(() => {
    if (!customerFlow || !clientCovenant) {
      history.push(initialPath)
    }
    const abortController = new AbortController()

    if (hasCovenantInfo) onFetchContracts({ clientCovenant, customerFlow }, abortController)
    return () => {
      abortController.abort()
    }
  }, [customerFlow, clientCovenant])

  const onContinue = () => {
    updateSelectContractsFlow({ selectedContracts, contracts })
    onRedirect(ProposalCustomerFlowClientType.clientWithEmployer)
  }

  if (!clientCovenant?.origens) {
    return <FindCnpjCard />
  }

  return (
    <React.Fragment>
      <HasConvenantInfo
        hasCovenantInfo={hasCovenantInfo}
      >
        <EcpCard className="renegotiation">
          <h2 className='renegotiation__title'>Selecione seus contratos</h2>
          <p className='renegotiation__paragraph mb-4'>{description}</p>

          <TableECPF
            canSearch={false}
            columns={columns}
            data={contracts || []}
            loading={loading}
            rowSelection={{
              type: 'checkbox',
              onChange: (selectedRowKeys) => setSelectedContracts([...selectedRowKeys] as string[])
            }}
            rowKey='numeroContrato'
            errorDetails={error}
            errorText={error?.message || ''}
          />
        </EcpCard>

        <Row justify="end" >
          <Button
            type="primary"
            onClick={onContinue}
            disabled={selectedContracts.length < 1}
          >
          Continuar
          </Button>
        </Row>
      </HasConvenantInfo>
    </React.Fragment>
  )
}

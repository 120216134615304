import ProposalStepButton from 'ecp/app/Proposals/components/ProposalStepButton/ProposalStepButton'
import React from 'react'
import usePartnerStep from '../../../../hooks/usePartnerStep'

function GenericStep () {
  const { step } = usePartnerStep()

  return (
    <ProposalStepButton
      enabled={false}
      onClick={() => {}}
    >
      {step.name}
    </ProposalStepButton>
  )
}

export default GenericStep

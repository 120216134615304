import React from 'react'
import { Button, Divider } from 'antd'
import { downloadDocument } from 'utils/file'
import { PdfPreview } from 'components'

const cartilha = 'https://agxbucket.s3.sa-east-1.amazonaws.com/Cartilha.pdf'
const instrucoes = 'https://agxbucket.s3.sa-east-1.amazonaws.com/Instru%C3%A7%C3%A3o+para+Cliente+-+EGI.pdf'

function ActionClientInstructions () {
  return (
    <>
      <PdfPreview src={cartilha}/>

      <div className="text-center">
        <Button
          className="mt-3"
          type="ghost"
          onClick={() => downloadDocument({ baseUrl: cartilha, name: 'Cartilha' })}
        >
          Baixar arquivo
        </Button>
      </div>

      <Divider />

      <PdfPreview src={instrucoes}/>

      <div className="text-center">
        <Button
          type="ghost"
          className="mt-3"
          onClick={() => downloadDocument({ baseUrl: instrucoes, name: 'Instruções' })}
        >
          Baixar arquivo
        </Button>
      </div>
    </>
  )
}

export default ActionClientInstructions

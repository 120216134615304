import { _proposalStatuses } from 'egi/app/ProposalEgi/proposalEgiInterfaces'
import React, { ReactElement } from 'react'

export const DEFAULT_COLOR = '#000'

export const DEFAULT_SIZE = 26

export interface IconProps {
  color?: string;
  size?: number | string;
}

interface Props extends IconProps {
  name: _proposalStatuses
}

const reproved = ({ color = DEFAULT_COLOR, size = DEFAULT_SIZE }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="8" fill="white" />
      <path
        d="M10 1C14.977 1 19 5.023 19 10C19 14.977 14.977 19 10 19C5.023 19 1 14.977 1 10C1 5.023 5.023 1 10 1ZM13.231 5.5L10 8.731L6.769 5.5L5.5 6.769L8.731 10L5.5 13.231L6.769 14.5L10 11.269L13.231 14.5L14.5 13.231L11.269 10L14.5 6.769L13.231 5.5Z"
        fill={color}
      />
    </svg>
  )
}

const canceled = ({ color = DEFAULT_COLOR, size = DEFAULT_SIZE }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="8" fill="white" />
      <path
        d="M10 1C14.977 1 19 5.023 19 10C19 14.977 14.977 19 10 19C5.023 19 1 14.977 1 10C1 5.023 5.023 1 10 1ZM13.231 5.5L10 8.731L6.769 5.5L5.5 6.769L8.731 10L5.5 13.231L6.769 14.5L10 11.269L13.231 14.5L14.5 13.231L11.269 10L14.5 6.769L13.231 5.5Z"
        fill={color}
      />
    </svg>
  )
}

const inactive = ({ color = DEFAULT_COLOR, size = DEFAULT_SIZE }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="8" fill="white" />
      <path
        d="M10 1C14.977 1 19 5.023 19 10C19 14.977 14.977 19 10 19C5.023 19 1 14.977 1 10C1 5.023 5.023 1 10 1ZM13.231 5.5L10 8.731L6.769 5.5L5.5 6.769L8.731 10L5.5 13.231L6.769 14.5L10 11.269L13.231 14.5L14.5 13.231L11.269 10L14.5 6.769L13.231 5.5Z"
        fill={color}
      />
    </svg>
  )
}

const remaking = ({
  color = DEFAULT_COLOR,
  size = DEFAULT_SIZE
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="8" fill="white" />
      <path
        fill={color}
        d="M10.9 7.3H9.1V5.5H10.9V7.3ZM10.9 14.5H9.1V9.1H10.9V14.5ZM10 1C8.8181 1 7.64778 1.23279 6.55585 1.68508C5.46392 2.13738 4.47177 2.80031 3.63604 3.63604C1.94821 5.32387 1 7.61305 1 10C1 12.3869 1.94821 14.6761 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C12.3869 19 14.6761 18.0518 16.364 16.364C18.0518 14.6761 19 12.3869 19 10C19 8.8181 18.7672 7.64778 18.3149 6.55585C17.8626 5.46392 17.1997 4.47177 16.364 3.63604C15.5282 2.80031 14.5361 2.13738 13.4442 1.68508C12.3522 1.23279 11.1819 1 10 1V1Z"
      />
    </svg>
  )
}

const approved = ({
  color = DEFAULT_COLOR,
  size = DEFAULT_SIZE
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="8" fill="white" />
      <path
        fill={color}
        d="M8.2 14.5L3.7 10L4.969 8.722L8.2 11.953L15.031 5.122L16.3 6.4L8.2 14.5ZM10 1C8.8181 1 7.64778 1.23279 6.55585 1.68508C5.46392 2.13738 4.47177 2.80031 3.63604 3.63604C1.94821 5.32387 1 7.61305 1 10C1 12.3869 1.94821 14.6761 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C12.3869 19 14.6761 18.0518 16.364 16.364C18.0518 14.6761 19 12.3869 19 10C19 8.8181 18.7672 7.64778 18.3149 6.55585C17.8626 5.46392 17.1997 4.47177 16.364 3.63604C15.5282 2.80031 14.5361 2.13738 13.4442 1.68508C12.3522 1.23279 11.1819 1 10 1V1Z"
      />
    </svg>
  )
}

const reservation = ({
  color = DEFAULT_COLOR,
  size = DEFAULT_SIZE
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="8" fill="white" />
      <path
        fill={color}
        d="M8.2 14.5L3.7 10L4.969 8.722L8.2 11.953L15.031 5.122L16.3 6.4L8.2 14.5ZM10 1C8.8181 1 7.64778 1.23279 6.55585 1.68508C5.46392 2.13738 4.47177 2.80031 3.63604 3.63604C1.94821 5.32387 1 7.61305 1 10C1 12.3869 1.94821 14.6761 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C12.3869 19 14.6761 18.0518 16.364 16.364C18.0518 14.6761 19 12.3869 19 10C19 8.8181 18.7672 7.64778 18.3149 6.55585C17.8626 5.46392 17.1997 4.47177 16.364 3.63604C15.5282 2.80031 14.5361 2.13738 13.4442 1.68508C12.3522 1.23279 11.1819 1 10 1V1Z"
      />
    </svg>
  )
}

const analysis = ({
  color = DEFAULT_COLOR,
  size = DEFAULT_SIZE
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        d="M10 1C8.8181 1 7.64778 1.23279 6.55585 1.68508C5.46392 2.13738 4.47177 2.80031 3.63604 3.63604C1.94821 5.32387 1 7.61305 1 10C1 12.3869 1.94821 14.6761 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C12.3869 19 14.6761 18.0518 16.364 16.364C18.0518 14.6761 19 12.3869 19 10C19 8.8181 18.7672 7.64778 18.3149 6.55585C17.8626 5.46392 17.1997 4.47177 16.364 3.63604C15.5282 2.80031 14.5361 2.13738 13.4442 1.68508C12.3522 1.23279 11.1819 1 10 1Z"
      />
      <path
        d="M10.2778 7.22222H9.44444V10.5556L12.0833 12.1389L12.5 11.4556L10.2778 10.1389V7.22222ZM10 5C7.23889 5 5 7.23889 5 10C5 12.7611 7.23333 15 10 15C12.7611 15 15 12.7611 15 10C15 7.23889 12.7611 5 10 5ZM10 13.8889C7.85 13.8889 6.11111 12.15 6.11111 10C6.11111 7.85 7.85 6.11111 10 6.11111C12.15 6.11111 13.8889 7.85 13.8889 10C13.8889 12.15 12.15 13.8889 10 13.8889Z"
        fill="white"
      />
    </svg>
  )
}

const starting = ({
  size = DEFAULT_SIZE,
  color
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="8.5" stroke={color} strokeWidth="2" />
    </svg>
  )
}

const effectivated = ({
  color = DEFAULT_COLOR,
  size = DEFAULT_SIZE
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="8" fill="white" />
      <path
        fill={color}
        d="M8.2 14.5L3.7 10L4.969 8.722L8.2 11.953L15.031 5.122L16.3 6.4L8.2 14.5ZM10 1C8.8181 1 7.64778 1.23279 6.55585 1.68508C5.46392 2.13738 4.47177 2.80031 3.63604 3.63604C1.94821 5.32387 1 7.61305 1 10C1 12.3869 1.94821 14.6761 3.63604 16.364C4.47177 17.1997 5.46392 17.8626 6.55585 18.3149C7.64778 18.7672 8.8181 19 10 19C12.3869 19 14.6761 18.0518 16.364 16.364C18.0518 14.6761 19 12.3869 19 10C19 8.8181 18.7672 7.64778 18.3149 6.55585C17.8626 5.46392 17.1997 4.47177 16.364 3.63604C15.5282 2.80031 14.5361 2.13738 13.4442 1.68508C12.3522 1.23279 11.1819 1 10 1V1Z"
      />
    </svg>
  )
}

const icons = {
  starting,
  analysis,
  inProgress: analysis,
  remaking,
  reservation,
  reproved,
  approved,
  canceled,
  inactive,
  effectivated
}

export default function Icons ({ name, color, size }: Props): ReactElement {
  const Icon = icons?.[name] || starting

  return <Icon color={color} size={size} />
}

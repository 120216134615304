import React, { Fragment, useEffect, useState } from 'react'
import { Skeleton, Typography } from 'antd'
import { useProposal, useStep } from 'hooks'
import { IReviewHistoriesProps, IStepHistory } from './reviewBackofficeInterfaces'
import ProposalEgiRepository from 'egi/repositories/ProposalEgiRepository'
import { stepSetFetchHistoryCallBack } from 'store/modules/step/actions'
import { useDispatch } from 'react-redux'
import ReviewStepHistory from 'components/ReviewStepHistory/ReviewStepHistory'
const { Paragraph } = Typography

function HistoriesWrapper ({ children, loading, historiesLenght }: { children: React.ReactNode, loading: boolean, historiesLenght: number }) {
  if (loading) {
    return (
      <div className='w-100 text-center'>
        <Skeleton className='w-100' active/>
      </div>
    )
  }

  if (historiesLenght < 1 && !loading) {
    return (
      <Paragraph className="my-3">
        Nenhuma revisão encontrada no histórico.
      </Paragraph>
    )
  }

  return <>{children}</>
}

function ReviewHistories ({ updateHistory, showAll }: IReviewHistoriesProps) {
  const step = useStep()
  const proposal = useProposal()
  const dispatch = useDispatch()

  const [histories, setHistories] = useState<IStepHistory[]>([])
  const [loadingHistories, setLoadingHistories] = useState<boolean>(false)

  async function fetchHistory () {
    if (step && step.id && proposal.id) {
      setLoadingHistories(true)

      try {
        const data = {
          proposalId: proposal.id,
          stepId: step?.id,
          limit: showAll ? undefined : 1
        }

        const response = await ProposalEgiRepository.history(data)
        const histories = response?.data?.data?.histories
        if (!histories) throw new Error('Nenhum histórico encontrado')
        setHistories(histories)
      } catch (err) {
        console.warn(err)
      } finally {
        setLoadingHistories(false)
      }
    }
  }

  useEffect(() => {
    setHistories([])
    fetchHistory()
    dispatch(stepSetFetchHistoryCallBack(fetchHistory))
  }, [step._id, updateHistory, showAll])

  return (
    <HistoriesWrapper
      loading={loadingHistories}
      historiesLenght={histories?.length}
    >
      <ReviewStepHistory stepArray={histories}/>
    </HistoriesWrapper>
  )
}

export default ReviewHistories

import React from 'react'
import { Form, Radio } from 'antd'

import DrawerFiltersLineCollapsable, { DrawerFiltersLineCollapsableItem } from 'components/DrawerFilters/DrawerFiltersLineCollapsable'

function PersonTypeFilter () {
  return (
    <div className='proposal-radio-filter'>
      <DrawerFiltersLineCollapsable label='Tipo pessoa'>
        <Form.Item
          name='clientType'
          initialValue={undefined}
        >
          <Radio.Group>
            <DrawerFiltersLineCollapsableItem key={''}>
              <Radio value={''}>{'Todos'}</Radio>
            </DrawerFiltersLineCollapsableItem>

            <DrawerFiltersLineCollapsableItem key={''}>
              <Radio value={'fisica'}>{'Física'}</Radio>
            </DrawerFiltersLineCollapsableItem>

            <DrawerFiltersLineCollapsableItem key={''}>
              <Radio value={'juridica'}>{'Jurídico'}</Radio>
            </DrawerFiltersLineCollapsableItem>

          </Radio.Group>
        </Form.Item>
      </DrawerFiltersLineCollapsable>
    </div>
  )
}

export { PersonTypeFilter }

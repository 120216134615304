import { IAuth, ISelect } from 'egi/types'
import { IInitialResources } from 'store/modules/resources/types'

function areasBackofficeInvite (user: IAuth, resources: IInitialResources, areaName?: string) {
  const tempAreas: ISelect[] = []

  if (user.level === 'master') {
    if (areaName === 'engenharia') areaName = 'avaliacao'
    const areas = resources.areas.filter(element => [areaName].includes(element.ref))
    tempAreas.push(...areas)
  }

  if (user && user.areas) {
    user.areas.forEach((item) => {
      const areas = resources.areas.filter(element => element.value === item)
      tempAreas.push(...areas)
    })
  }

  return tempAreas
}

export { areasBackofficeInvite }

import React from 'react'
import { Form, Select } from 'antd'
import { serverTableSelectProps } from 'utils/globals'
import { channelOptions } from 'components/ModalInvite/components/constants'

function FilterPromoterChannel ({ onChange, defaultValue }: { onChange: (value: string | string[] | undefined) => void, defaultValue?: string[] }) {
  return (
    <Form.Item
      name="promoterChannel"
      label={<label>Canal do parceiro</label>}
    >
      <Select
        {...serverTableSelectProps}
        allowClear
        placeholder="Escolha o canal"
        defaultValue={defaultValue}
        onChange={onChange}
      >
        {channelOptions.map((option) => (
          <Select.Option key={option.label} value={option.value}>{option.label}</Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default FilterPromoterChannel

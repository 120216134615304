import React from 'react'
import { Skeleton } from 'antd'

type Props = {
  children: React.ReactNode
}

function DepartmentSectionSkeleton ({ children }: Props) {
  return (
    <section className="department-section department-section-skeleton">
      <div className="department-section__image-wrapper">
        <Skeleton.Input
          className="department-section-skeleton__image"
          active={true}
          size="large"
        />
      </div>

      <div className="department-section__content">
        {children}
      </div>
    </section>
  )
}

export default DepartmentSectionSkeleton

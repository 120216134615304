import { Table } from 'components'
import { enhanceServerTable } from 'components/SeverTable/serverTableEnhance'
import React, { useEffect, useState } from 'react'
import UsersEgiRepository from 'egi/repositories/UsersEgiRepository'

interface IData {
  createdAt: string
  template : { subject: string}
  sent?: boolean
  failCounter: number
}

const columns = [
  {
    title: 'Data/hora envio',
    dataIndex: 'createdAt',
    key: 'createdAt'
  },
  {
    title: 'Assunto',
    dataIndex: ['template', 'subject'],
    key: 'subject',
    render: (value: boolean) => value ? value : 'Não possui assunto'
  },
  {
    title: 'Enviado s/n',
    dataIndex: 'sent',
    key: 'sent',
    render: (value: boolean) => value ? 'Sim' : 'Não'
  },
  {
    title: 'Tentativas',
    dataIndex: 'failCounter',
    key: 'failCounter'
  }
]

function UserDetailsEmailTable ({ id } : { id: string}) {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IData[]>([])

  useEffect(() => {
    const abortController = new AbortController()
    async function fetchEmails (id: string) {
      setLoading(true)
      try {
        const response = await UsersEgiRepository.getEmails(id, {
          signal: abortController.signal
        })
        if (response.data.data.emailList) {
          const formatedValues = enhanceServerTable(response.data.data.emailList)
          setData(formatedValues)
        }
      } catch (err) {
        console.log(err)
      }
      setLoading(false)
    }

    if (id) fetchEmails(id)

    return () => abortController.abort()
  }, [id])

  return (
    <Table
      data={data}
      columns={columns}
      loading={loading}
    />
  )
}

export default UserDetailsEmailTable

import axios from 'axios'

function getBase64 (file: any): Promise<any> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

function downloadFile ({ fileUrl, title, format }: {fileUrl?: string, title?: string, format?: (value: string) => string}): void {
  if (!fileUrl) throw new Error('Nome do arquivo não enviado')
  if (!title) throw new Error('Título do arquivo não enviado')
  if (format) fileUrl = format(fileUrl)

  const a = document.createElement('a')
  a.href = fileUrl
  a.download = title
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

async function downloadByRequest (fileUrl: string, title: string, options: { ext?: string, isBase64?: boolean }) {
  const response = await axios({
    url: fileUrl + (options.isBase64 ? '' : '?x-request=html'),
    method: 'GET',
    responseType: 'blob'
  })

  const extension = options.ext ?? fileUrl.split('.').slice(-1).join('')
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const fileName = `${title}.${extension}`

  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export {
  getBase64,
  downloadFile,
  downloadByRequest
}

export function downloadBase64File (base64: string, fileName: string) {
  const link = document.createElement('a')
  link.href = `data:application/octet-stream;base64,${base64}`
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

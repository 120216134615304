import { IStep } from 'egi/types'
import { StepActionsTypes } from './actions'

const INITIALSTATE: IStep | { visible: boolean } = {
  _id: '',
  title: '',
  mode: '',
  internal: false,
  showReservationProps: false,
  name: '',
  commentary: '',
  slug: '',
  areaId: '',
  creditReservation: undefined,
  areaRef: undefined,
  activeMode: undefined,
  pastStatus: undefined,
  situation: undefined,
  status: undefined,
  personType: undefined,
  form: undefined,
  documents: undefined,
  signatures: undefined,
  reviewOptions: [],
  feedback: [],
  avaliations: [],
  dates: [],
  modeOptions: undefined,
  contract: undefined,
  conditional: undefined,
  hasProvider: '',
  openDrawerLoading: false,
  hasGuarantor: false,
  display: undefined,
  review: false,
  view: false,
  ref: undefined,
  criterios: undefined,
  canSeeUploadButton: false,
  showDrawerSave: false,
  disabledDrawerButton: false,
  readOnlyInput: false,
  fetchHistoryCallback: () => {}
}

const step = (state = INITIALSTATE, action: any) => {
  switch (action.type) {
    case 'FILL_STEP': {
      const hasProvider = action.payload.hasProvider
      return {
        ...state,
        ...action.payload,
        hasProvider
      }
    }

    case 'SET_STEP_FORM': {
      return {
        ...state,
        form: {
          ...state.form,
          ...action.payload.form
        }
      }
    }

    case 'SET_CUSTOM_MODE_STEP': {
      return {
        ...state,
        name: action.payload.name,
        mode: action.payload.mode
      }
    }

    case 'SET_OPEN_DRAWER_LOADING': {
      return {
        ...state,
        openDrawerLoading: action.payload.loading
      }
    }

    case 'STEP_CHANGE_MODE_OPTIONS': {
      return {
        ...state,
        modeOptions: {
          ...state.modeOptions,
          selected: action.payload.selected
        }
      }
    }

    case StepActionsTypes.CHANGE_EXPIRATION_DATE: {
      return {
        ...state,
        cnd: {
          ...state.cnd,
          result: {
            ...state.cnd?.result,
            service_infos: [
              {
                ...state.cnd?.result?.service_infos?.[0],
                data: {
                  ...state.cnd?.result?.service_infos?.[0].data,
                  expiration_date: action.payload.newDate
                }
              }
            ]
          }
        }
      }
    }

    case StepActionsTypes.HISTORY_CALLBACK: {
      return {
        ...state,
        fetchHistoryCallback: action.payload.fetchHistoryCallback
      }
    }

    case 'RESET_STEP': {
      return {
        ...INITIALSTATE,
        openDrawerLoading: state.openDrawerLoading
      }
    }

    default:
      return state
  }
}

export default step

import React, { Fragment, useEffect, useState } from 'react'
import { useAuth, useResources } from 'hooks'
import swal from 'utils/swal'
import ChatRepository, { _chatConfigType } from 'egi/repositories/ChatRepository'

import { MessengerList, MessengerMessages, MessengerThemeWrapper, useConversations, useMessages } from 'agx-chat-web'
import { IList, ISocketMessage } from 'agx-chat-web/dist/esm/types'
import dayjs from 'dayjs'
import { timeAsDayjs } from 'utils/time'
import ChatHeader from './components/ChatHeader/ChatHeader'
import { message } from 'antd'

import ReportsDatesFilter from './components/ReportDatesFilter/ReportDatesFilter'
import EndChat from './components/EndChat/EndChat'
import { IProduct } from 'ecp/models/ProductModel'
import useHelpdeskReasons from 'hooks/useHelpdeskReasons'
import { swalError } from 'components/SwalError/SwalError'
import { SocketSingleton } from 'utils/socket'

const theme = {
  asideBg: '#476077',
  asideFontColor: 'white',
  messengerMessagesBg: '#dddddd',
  headerAndSenderBg: '#C5D7D7',
  buttonPrimary: '#25D366',
  buttonPrimaryText: '#fff',
  buttonsDisabled: '#94989D',
  uploadFileIconColor: '#8696a0',
  messengerNotSelectedBg: '#DDDDDD',
  newChatFormBg: '#E3ECEC',
  newChatFormRadius: '0  20px 20px 0',
  newChatFormTexts: '#476077',
  newChatFormDeleteFileButton: '#DD4E4E',
  disclaimerPrimaryColor: '#F5A60C',
  disclaimerSecondaryColor: 'rgba(245, 167, 12, 0.3)',
  disclaimerTextColor: '#000',
  messengerSenderColor: '#00A73E',
  messengerIncomerColor: '#89adad',
  messengerSystemColor: '#707070',
  borderColor: '#dddddd',
  inputBg: 'white',
  chatInputBorder: '#eee',
  listItemHover: '#6687A4',
  active: '#2C4153',
  activeTabBorter: '#25D366',
  emptyMessagesFontColor: '#000'
}

function GenericChat () {
  const [loading, setLoading] = useState<boolean>(false)
  const [status, setStatus] = useState<'close' | 'list' | 'search' | 'messages' | 'failed' | undefined>(undefined)
  const [conversationsTab, setConversationsTab] = useState<string>('inprogress')
  const [loadingMessages, setLoadingMessages] = useState<boolean>(false)
  const [visible, setVisible] = useState(false)
  const [reportsModalVisible, setReportsModalVisible] = useState<boolean>(false)
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false)
  const [loadingClose, setLoadingClose] = useState<boolean>(false)
  const [loadingNewChat, setLoadingNewChat] = useState<boolean>(false)
  const abortController = new AbortController()

  const auth = useAuth()
  const { products } = useResources()

  const {
    onIntersect, addOneUnreadToTicket,
    sliceCount, hasNext,
    reset, addConversations,
    conversations, setAsRead,
    selectChat, currentChat,
    onCloseChat, changeViewDesktop,
    viewDesktop, changeViewMobile,
    viewMobile
  } = useConversations()

  const {
    addMessages, messages,
    incrementMessages, updateMesssagesAsRead,
    addTotalMessages, hasOlderMessages
  } = useMessages()

  const { items: reasons, error: reasonsError } = useHelpdeskReasons(_chatConfigType.report)

  const nature = 'helpdesk'
  const chatListTabs = [
    { label: 'Ativos', value: 'inprogress' },
    { label: 'Finalizados', value: 'closed' }
  ]

  const searchKeys = [
    { label: 'CPF', value: 'cpf' },
    { label: 'Nome', value: 'username' },
    { label: 'Protocolo', value: 'protocol' },
    { label: 'Email', value: 'email' },
    { label: 'Motivo', value: 'reason' },
    { label: 'Local', value: 'issueOrigin' }
  ]

  const startNewChat = () => {
    changeViewDesktop('newChat')
    changeViewMobile('messages')
  }

  const cancelNewChat = () => {
    changeViewDesktop('empty')
    changeViewMobile('list')
  }

  const handleChangeTickets = (chatTab: string) => {
    if (conversationsTab !== chatTab) {
      reset()
      setConversationsTab(chatTab)
    }
  }

  const getMessages = async (id: string, messageCount: number) => {
    if (messageCount === 0) addMessages([])

    setLoadingMessages(true)
    try {
      const params = {
        messageCount
      }

      const response = await ChatRepository.getMessages(id, { params })
      if (messageCount !== 0) {
        const newMessages = response.data.data.messages.messages.reverse()
        addMessages([...newMessages, ...messages])
      } else {
        addMessages(response.data.data.messages.messages.reverse())
      }

      addTotalMessages(response.data.data.messages.total)
      abortController.abort()
      return response
    } catch (err) {
      message.error(err.message)
      return err
    } finally {
      setLoadingMessages(false)
    }
  }

  const goToConversation = async (chat: IList) => {
    try {
      const socket = new SocketSingleton()
      if (socket.io) {
        socket.io.emit('join-chat', chat._id)
      }

      changeViewDesktop('messages')
      selectChat(chat)
      changeViewMobile('messages')
      await getMessages(chat?._id, 0)
      setAsRead(chat?._id)
    } catch (error) {
      console.log({ error })
    }
  }

  interface IGetConversation {
    callback?: (chat: IList) => void
    key?: string
    search?: string
    signal?: AbortSignal
  }

  const getConversations = async ({ callback, key, search, signal }: IGetConversation) => {
    try {
      const params = {
        status: conversationsTab,
        key,
        search
      }

      const response = await ChatRepository.getConversations({ params, signal })
      const tickets = response.data.data.tickets

      addConversations(tickets, callback)
      setLoading(false)
      setLoadingSearch(false)
    } catch (err) {
      if (!abortController.signal.aborted) {
        setLoading(false)
        setLoadingSearch(false)
        setStatus('failed')
      }
    }
  }

  useEffect(() => {
    setLoading(true)
    getConversations({ signal: abortController.signal })

    return () => {
      abortController.abort()
    }
  }, [
    conversationsTab
  ])

  async function closeGroup (values: { comments: string }) {
    setLoadingClose(true)
    try {
      if (currentChat?._id) {
        await ChatRepository.closeChat(currentChat._id, values)
        changeViewDesktop('empty')
        setVisible(false)
        changeViewMobile('list')

        const socket = new SocketSingleton()
        if (socket.io) {
          socket.io.emit('close chat')
        }
      }
    } catch (err) {
      swalError({ err })
    } finally {
      setLoadingClose(false)
    }
  }

  useEffect(() => {
    const socket = new SocketSingleton()
    if (socket.io) {
      socket.io.emit('join-chat-nature', nature)
    }
  }, [])

  const openReportsButtonModal = () => {
    setReportsModalVisible(true)
  }

  const closeReportsButtonModal = () => {
    setReportsModalVisible(false)
  }

  async function onSearchChat (search: string, key: string) {
    setLoadingSearch(true)
    setLoading(true)

    try {
      reset()
      await getConversations({ search, key })
    } catch (err) {
      message.error(err.message)
    } finally {
      setLoadingSearch(false)
    }
  }

  const formatDate = (date: string | Date) => {
    const formattedDate = dayjs(date ?? null).isValid() ? timeAsDayjs(date).format('DD/MM/YYYY HH:mm') : undefined
    return formattedDate
  }

  const createRedP = (className: string) => {
    const p = document.createElement('p')
    p.className = className
    p.style.color = 'red'

    return p
  }

  const handleInvalidProduct = (invalid: Array<{ field: string, message: string }>) => {
    const findProduct = invalid.find((item: { field: string, message: string }) => item.field === 'product')
    const productInput = document.querySelector('div.generic-chat > div > div > form > div:nth-child(2)')
    const invalidClass = 'ant-form-explain-error-product'
    const errorProductMessage = document.querySelector('.' + invalidClass)

    if (findProduct && !errorProductMessage) {
      const p = createRedP(invalidClass)

      productInput?.appendChild(p)
      p.innerHTML = findProduct.message
    }

    if (!findProduct && errorProductMessage) {
      productInput?.removeChild(errorProductMessage)
    }
  }

  const handleInvalidMessage = (invalid: Array<{ field: string, message: string }>) => {
    const findMessage = invalid.find((item: { field: string, message: string }) => item.field === 'message')

    const messageInput = document.querySelector('div.generic-chat > div > div > form > div.text-area')
    const invalidClass = 'ant-form-explain-error-message'
    const errorMesssage = document.querySelector('.' + invalidClass)

    if (findMessage && !errorMesssage) {
      const p = createRedP(invalidClass)
      messageInput?.appendChild(p)
      p.innerHTML = findMessage.message
    }

    if (!findMessage && errorMesssage) {
      messageInput?.removeChild(errorMesssage)
    }
  }

  const handleErrorByInvalids = (invalid: Array<{ field: string, message: string }>) => {
    handleInvalidProduct(invalid)
    handleInvalidMessage(invalid)
  }

  const submitNewChat = async (values: FormData) => {
    setLoadingNewChat(true)

    const selectProduct = (products ?? []).find(item => item?._id === values?.get('product'))

    try {
      if (values?.get('message')) {
        values.set(
          'message',
          `Produto: ${selectProduct?.name}\n\n${values?.get('message') as string}`
        )
      }

      const requestConfig = {
        headers: { 'Content-Type': 'multipart/form-data' }
      }

      await ChatRepository.helpdesk(values, requestConfig)
      changeViewDesktop('messages')
      changeViewMobile('messages')

      getConversations({ callback: goToConversation })
    } catch (err) {
      if (err?.data?.invalid && Array.isArray(err?.data?.invalid)) {
        handleErrorByInvalids(err.data?.invalid)
      }

      swalError({ err })
    } finally {
      setLoadingNewChat(false)
    }
  }

  function handleMessagesScroll () {
    return getMessages(currentChat._id, messages.length)
  }

  useEffect(() => {
    const socket = new SocketSingleton()
    if (socket.io) {
      socket.io.on('mark as read', (ticket: ISocketMessage) => {
        updateMesssagesAsRead(ticket, currentChat?._id)
        setAsRead(ticket.message?.ticketId)
      })

      socket.io.on('new message', (ticket: ISocketMessage) => {
        incrementMessages(ticket, currentChat?._id)
      })
    }

    return () => {
      if (socket.io) {
        socket.io.off('mark as read')
        socket.io.off('new message')
      }
    }
  }, [
    messages,
    currentChat
  ])

  useEffect(() => {
    const productLabel = document.querySelector('div.generic-chat > div > div > form > div:nth-child(2) > label')
    if (productLabel) productLabel.innerHTML = 'Produto'
  }, [viewMobile, viewDesktop])

  useEffect(() => {
    const socket = new SocketSingleton()
    if (socket.io) {
      socket.io.on('new helpdesk', () => {
        getConversations({})
      })

      socket.io.on('new message ticket', (ticket: { _id: string }) => {
        addOneUnreadToTicket(ticket?._id, currentChat?._id)
      })

      socket.io.on('close chat', (conversation: {ticketId: string}) => {
        onCloseChat(conversation.ticketId)

        const closedTicket = conversations.find(item => item._id === conversation.ticketId)
        message.warning(<>Chamado com protocolo <b>{closedTicket?.protocol}</b> foi finalizado</>)
      })
    }

    return () => {
      if (socket.io) {
        socket.io.off('new message ticket')
        socket.io.off('new helpdesk')
        socket.io.off('close chat')
      }
    }
  }, [
    conversations
  ])

  const sendMessage = async (value: FormData, callback: () => void) => {
    setLoadingMessages(true)
    try {
      if (!currentChat?._id) throw new Error('current chat id not found.')
      const requestConfig = {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
      return ChatRepository.sendMessages(currentChat?._id, value, requestConfig)
    } catch (err) {
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      return err
    } finally {
      callback()
      setLoadingMessages(false)
    }
  }

  const chatButtons = [
    { label: 'Novo chamado', onClick: startNewChat, type: 'primary', canSee: !auth.helpdesk, disabled: viewDesktop === 'newChat' },
    { label: 'Baixar relatório', onClick: openReportsButtonModal, type: 'outlined', canSee: auth.helpdesk === true }
  ]

  const handleProductOptions = (products: Array<IProduct>) => {
    const productListSorter = (item: IProduct) => {
      if (item.slug === auth.selectedProduct.slug) return -1
      return 1
    }

    const productOptions = (products ?? [])?.sort(productListSorter).map(item => ({ label: item.name, value: item._id }))
    productOptions.unshift({ label: 'Selecione um produto', value: '' })

    return productOptions
  }

  useEffect(() => {
    if (reasonsError) return message.error(reasonsError)
  }, [reasonsError])

  return (
    <Fragment>
      <ReportsDatesFilter
        visible={reportsModalVisible}
        closeReportsButtonModal={closeReportsButtonModal}
      />

      <EndChat
        closeGroup={closeGroup}
        visible={visible}
        onCancel={() => setVisible(false)}
        loading={loadingClose}
      />

      <div className="generic-chat">
        <MessengerThemeWrapper theme={theme}>
          <MessengerList
            onIntersect={onIntersect}
            hasNext={hasNext()}
            loadingSearch={loadingSearch}
            chatListTabs={chatListTabs}
            chatButtons={chatButtons}
            handleChangeTickets={handleChangeTickets}
            helpDesk={auth.helpdesk ?? false}
            messegerView={viewDesktop}
            searchKeys={searchKeys}
            searchChat={onSearchChat}
            showBadge={false}
            loading={loading}
            sliceCount={sliceCount}
            failed={status === 'failed'}
            currentId={currentChat?._id}
            conversations={conversations}
            canSeeTimer={auth.helpdesk === true}
            tab={viewMobile}
            affix={<div></div>}
            onClick={goToConversation}
          />

          <MessengerMessages
            onIntersect={handleMessagesScroll}
            hasNext={hasOlderMessages()}
            newChatLoading={loadingNewChat}
            formatDate={formatDate}
            messegerView={viewDesktop}
            cancelNewChat={cancelNewChat}
            submitNewChat={submitNewChat}
            allowImages={true}
            className="chat__messages-body"
            creatorId={auth._id}
            current={currentChat}
            loading={loadingMessages}
            tab={viewMobile}
            reasons={reasons}
            products={handleProductOptions(products)}
            messages={messages}
            onSubmit={sendMessage}
            header={
              <ChatHeader
                onClick={() => setVisible(true)}
                currentChat={currentChat}
                setTab={changeViewMobile}
              />
            }
          />
        </MessengerThemeWrapper>
      </div>
    </Fragment>
  )
}

export default GenericChat

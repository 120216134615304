import React from 'react'
import imagesUrls from 'utils/imagesUrls'
import { Image } from 'antd'
import MaintananceModalImage from '../../assets/img/maintanance_modal.png'
import { ModalProps } from 'antd/lib/modal'
import Modal from 'components/Modal/Modal'

export function MaintananceModalText () {
  return (
    <div>
      <div className='maintenance-modal__logo-container'>
        <Image
          src={imagesUrls.rodobensLogoLight}
          preview={false}
          className='maintenance-modal__rodobens-logo'
          alt="logo da rodobens em verde escuro"
        />
      </div>

      <div className='maintenance-modal__base-image-container'>
        <Image
          src={MaintananceModalImage}
          preview={false}
          alt="3 bolas estilizadas no padrão Rodobens"
        />
      </div>

      <p className='maintenance-modal__paragraph'>Olá,</p>
      <p className='maintenance-modal__paragraph'>O sistema está temporariamente indisponível devido a uma atualização sistêmica.</p>
      <p className='maintenance-modal__paragraph'>Em caso de dúvidas entre em contato com a nossa equipe através dos canais abaixo:</p>

      <div className='maintenance-modal__contact-texts'>
        <p className='maintenance-modal__paragraph mb-0'><b>SAC:</b> 0800-709 9220</p>
        <p className='maintenance-modal__paragraph'><b>Whatsapp:</b> (17) 99705-8666</p>
      </div>

      <p className='maintenance-modal__paragraph mb-2'>Agradecemos a compreensão.</p>

      <div className='maintenance-modal__footer'>
        <div className='maintenance-modal__footer-ball maintenance-modal__footer-ball--gray' />
        <div className='maintenance-modal__footer-ball maintenance-modal__footer-ball--orange' />
        <div className='maintenance-modal__footer-ball maintenance-modal__footer-ball--green' />
      </div>
    </div>
  )
}

export default function MaintanceModal ({ ...rest }: ModalProps) {
  return (
    <Modal
      destroyOnClose
      footer={null}
      title={null}
      {...rest}
    >
      <MaintananceModalText />
    </Modal>
  )
}

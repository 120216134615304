import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { UNAUTHS_PATHS } from 'routes/unauthRoutes'
import { ISignupTokenInfo } from '../signUpClientInterfaces'

function useTokenInfo () {
  const { state: tokenInfo } = useLocation<ISignupTokenInfo | null>()
  const history = useHistory()

  useEffect(() => {
    if (!tokenInfo?.preSignupToken) history.push(UNAUTHS_PATHS.SIGNUP_CLIENT_PASSWORDS)
  }, [tokenInfo?.preSignupToken])

  return { tokenInfo: tokenInfo || {} as ISignupTokenInfo }
}

export default useTokenInfo

import { AxiosRequestConfig } from 'axios'
import { IResponseBase, Repository } from 'ecp/repositories/Repository'
import { ISimulation } from 'egi/types'
import api from 'services/api'

interface ISimulationChange {
  simulationId: string | undefined;
  proposalId: string;
}

interface IReturns {
  returnName: string
  proposalId: string
}

class SimulationsRepository extends Repository<ISimulation> {
  async getSimulations (id: string, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/${id}`, config)
    )
  }

  async change (data: ISimulationChange, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.patch(`${this.path}/replace`, data, config)
    )
  }

  async history (config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/history`, config)
    )
  }

  async returnsUpdate (data: IReturns, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.patch(`${this.path}/replace/return`, data, config)
    )
  }
}

export default new SimulationsRepository({ api, path: '/simulations' })

class SimulationRepository extends Repository<ISimulation> {
  async getAvailableCreditValue (data?: Partial<ISimulation>, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.post(`${this.path}/max-credit`, data, config)
    )
  }
}

export const simulationRepository = new SimulationRepository({ api, path: '/simulation' })

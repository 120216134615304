import TokenVerificationSVG from 'assets/reactSvgs/TokenVerificationSVG/TokenVerificationSVG'
import WarningText from 'components/WarningText/WarningText'
import UnauthLayout from 'layouts/UnauthLayout/UnauthLayout'
import React from 'react'
import { useLocation } from 'react-router-dom'

export const FortifyChangePasswordText = () => {
  return (
    <WarningText title='Instruções' type='info'>
      <p>Conforme nova política de segurança de acesso, faça a alteração da sua senha agora mesmo.</p>
      <p>Pré-requisitos: 11 dígitos: 1 Letra Maiúscula / Caractere Especial / Número</p>
    </WarningText>
  )
}

export const FortifyPassword = () => {
  const { state: { email } } = useLocation<{ email: string }>()

  return (
    <UnauthLayout>
      <UnauthLayout.Container>
        <UnauthLayout.IconContainer icon={<TokenVerificationSVG />} />

        <div className='text-center'>
          <UnauthLayout.Title title='Atualização de senha' />
        </div>

        <UnauthLayout.TextContainer>
          <UnauthLayout.Text email={email} />
        </UnauthLayout.TextContainer>

        <UnauthLayout.ButtonContainer>
          <UnauthLayout.BackToLogin text='Já realizou a troca?' />
        </UnauthLayout.ButtonContainer>
      </UnauthLayout.Container>
    </UnauthLayout>
  )
}

/**
 * Retrieves a deeply nested property from an object based on an array of keys or a single key.
 *
 * @template T - The type of the object.
 * @param {T} obj - The object from which to retrieve the property.
 * @param {string[] | string} keysArray - An array of keys or a single key to traverse the object.
 * @returns {*} - The value of the deeply nested property, or undefined if not found.
 */
export function getDeepProperty<T extends object = object> (
  obj: T,
  keysArray: string[] | string
) {
  if (!obj) return undefined
  if (typeof keysArray === 'string') return ((obj as any) || {})[keysArray]
  return keysArray.reduce((acc, key) => ((acc as any) || {})[key], obj)
}

import { _userLevel } from 'ecp/models/UsersModel'
import { _pricingTypes } from 'egi/app/Pricing/views/PricingTable/PricingTable'

const PricingModel = {

  canChange (userLevel: _userLevel): boolean {
    return userLevel === 'pricing' || userLevel === 'master'
  },

  cantSubmit (userLevel: _userLevel): boolean {
    return userLevel === 'auditor'
  },

  canSeeStatesSelect (userLevel: _userLevel): boolean {
    return userLevel === 'pricing' || userLevel === 'master' || userLevel === 'auditor'
  },

  canEditNotaryFeesModel (userLevel: _userLevel): boolean {
    return userLevel === 'master' || userLevel === 'agx'
  },

  canCreate (userLevel: _userLevel, type: _pricingTypes): boolean {
    return ['pricing', 'master'].includes(userLevel) && type === 'precificacao'
  }
}

export default PricingModel

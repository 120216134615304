import React from 'react'
import { Form, Switch, Typography } from 'antd'

function FilterValidLink ({ onChange }: { onChange: (value: boolean | undefined) => void }) {
  return (
    <div className="flex flex-align-center switch-responsive-layout">
      <Typography.Title level={5} className="mr-1 mb-0">Link válido: </Typography.Title>
      <Form.Item
        name="validLink"
        valuePropName="checked"
      >
        <Switch
          onChange={onChange}
        />
      </Form.Item>
    </div>
  )
}

export default FilterValidLink

import React, { PureComponent } from 'react'
import { Layout } from 'antd'

class Footer extends PureComponent {
  render () {
    return (
      <Layout.Footer className="footer">
        Desenvolvido por UX Vision Tech © {new Date().getFullYear()}
      </Layout.Footer>
    )
  }
}

export default Footer

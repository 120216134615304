import { message } from 'antd'
import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { tablesUpdate } from 'store/modules/tables/actions'
import { IImportFile } from '../../notaryFeesInterfaces'
import { RootState } from 'store/modules/rootReducer'
import { NotaryFeestActions } from 'store/modules/notaryFees/notaryFeesActions'
import { _antdStatus } from 'store/modules/notaryFees/notaryFeesActionTypes'
import LoadingBar from 'components/LoadingBar/LoadingBar'
import { pricingRepository } from 'egi/repositories/NotaryFeesRepository'

export interface ILoadingBar {
  importFile: IImportFile |undefined
  onImport: (data: IImportFile | undefined) => void
}

function LoadingNotaryFeesBar ({ importFile, onImport }: ILoadingBar) {
  const dispatch = useDispatch()

  const loadingState = useSelector((state: RootState) => state.loadingReducer)

  const setPercentage = (payload : number) => dispatch(NotaryFeestActions.setPercentage(payload))
  const setStatus = (payload : string | null) => dispatch(NotaryFeestActions.setStatus(payload))
  const setAntdStatus = (payload : _antdStatus) => dispatch(NotaryFeestActions.setAntdStatus(payload))

  const getFileStatus = async () => {
    try {
      if (!importFile?._id) throw new Error('Não foram encontradas importações')
      const response = await pricingRepository.getFileStatus(importFile?._id)
      setStatus(response.data.data.notaryFee.status)
    } catch (err) {
      setStatus('failed')
      message.error(err.message)
    }
  }

  const resetLoading = () => {
    setTimeout(() => {
      dispatch(NotaryFeestActions.reset())
      onImport(undefined)
      dispatch(tablesUpdate())
    }, 1000)
  }

  useEffect(() => {
    if (importFile) {
      const interval = setInterval(() => {
        getFileStatus()
      }, 2000)

      return () => clearInterval(interval)
    }
  }, [importFile])

  useEffect(() => {
    if (loadingState?.status === 'succeed') {
      setPercentage(100)
      setAntdStatus('success')
      resetLoading()
    } else if (loadingState?.status === 'failed') {
      setPercentage(100)
      setAntdStatus('exception')
      resetLoading()
    }
  }, [loadingState?.status])

  useEffect(() => {
    if (
      loadingState?.status !== 'failed' &&
      loadingState?.status !== 'succeed' &&
      loadingState?.percentage < 100 &&
      importFile
    ) {
      const timeout = setTimeout(() => {
        setPercentage(Math.min(loadingState?.percentage + 10, 100))
      }, 2000)

      return () => clearTimeout(timeout)
    }
  }, [loadingState?.status, loadingState?.percentage])

  return importFile ? (
    <div className="loading-notary-fees-bar__card">
      <h3>Importando arquivo…</h3>
      <LoadingBar percent={loadingState?.percentage} status={loadingState?.antdStatus}/>
    </div>
  ) : null
}

export default LoadingNotaryFeesBar

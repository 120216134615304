import { SolutionOutlined } from '@ant-design/icons'
import { IRoute, RouteType } from 'components/Sidebar/types'
import { ProposalListSearchEGI } from 'egi/app/ProposalEgi2/views/ProposalListSearch/ProposalListSearch'
import { IAuth } from 'egi/types'
import React from 'react'
import { mlmProposalsListTwo } from 'routes/areas/mlm'
import { EGI } from 'routes/egi/paths'
import {
  bacenTerms, campaignLink,
  chat, collaborators, dashboard,
  divulgationPage,
  leadDetails, leads,
  leadsHistory,
  lgpdTerms, partnerDocumentation, profile, promoterClients,
  proposalCheck, proposalsHistory,
  proposalsListSearch, proposalsListTwo,
  proposalsTelemarketing,
  sellersList
} from '../routes'

const proposals = {
  name: 'Propostas',
  icon: SolutionOutlined,
  routes: [
    proposalsListSearch,
    proposalsListTwo
  ]
}

const proposalsListSearchMLM: IRoute = {
  path: EGI.PROPOSAL_LIST_SEARCH.path,
  name: EGI.PROPOSAL_LIST_SEARCH.name,
  icon: SolutionOutlined,
  component: () => <ProposalListSearchEGI mlm={true} />,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const mlmProposalsListWithSearch = {
  name: 'Propostas',
  icon: SolutionOutlined,
  routes: [
    proposalsListSearchMLM,
    mlmProposalsListTwo
  ]
}

const promoterRoutes = (auth: IAuth) => {
  const routes = [
    dashboard,
    promoterClients,
    proposalsHistory,
    proposalCheck,
    profile,
    lgpdTerms,
    bacenTerms
  ]

  const slicedRoutes = routes.splice(1)

  const cbRoutes = [
    ...routes,
    proposals,
    ...slicedRoutes,
    ...(!auth.mainId ? [collaborators, partnerDocumentation] : []),
    divulgationPage,
    chat
  ]

  const inRoutes = [
    dashboard,
    mlmProposalsListWithSearch,
    leads,
    sellersList,
    campaignLink,
    divulgationPage,
    profile,
    ...(!auth.mainId ? [collaborators, partnerDocumentation] : []),
    chat
  ]

  const mpRoutes = [
    ...routes,
    proposals,
    ...slicedRoutes,
    ...(!auth.mainId ? [collaborators, partnerDocumentation] : [])
  ]

  function showCorrectRoute (routes: (IRoute | RouteType)[], status?: string) {
    const statusToShow = ['starting', 'track']
    if (status && statusToShow.includes(status)) return [partnerDocumentation, profile]

    return routes
  }

  switch (auth.type) {
    case 'TM':
      return [
        ...routes,
        proposalsTelemarketing,
        ...slicedRoutes,
        leads,
        leadsHistory,
        leadDetails,
        chat
      ]
    case 'CB':
      return showCorrectRoute(cbRoutes, auth.status)
    case 'IN':
      return showCorrectRoute(inRoutes, auth.status)
    case 'MP':
      return showCorrectRoute(mpRoutes, auth.status)
    default:
      return routes
  }
}

export default promoterRoutes

import React, { Fragment, useEffect, useState } from 'react'
import { Drawer } from 'components'

import PartnerStepButton from '../../../PartnerStepButton/PartnerStepButton'
import usePartnerStep from '../../../../hooks/usePartnerStep'
import { partnerFormFill } from 'egi/app/PartnersLine/partnersLineFunctions'
import usePartnerStepDrawer from '../../../../hooks/usePartnerStepDrawer'
import PartnerFormContract from './PartnerFormContract'
import { IFormContract, IFormContractErrors } from './types'
import usePartnerDocumentation from '../../../../hooks/usePartnerDocumentation'
import usePartnerDepartments from '../../../../hooks/usePartnerDepartments'
import { useAuth } from 'hooks'
import { Form } from 'antd'
import format from 'utils/format'
import swal from 'utils/swal'
import { swalError } from 'components/SwalError/SwalError'
import { validateResponse } from 'utils/validate'
import { IAssociates } from '../PartnerStep/types'
import { correctTimezone } from 'utils/time'
import PartnerModel from 'egi/models/PartnerModel'
import PartnersRepository from 'egi/repositories/PartnersRepository'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'

function PartnerFormContractStep () {
  const [errors, setErrors] = useState<IFormContractErrors>({})
  const [status, setStatus] = useState<'back' | 'saving' | 'sending'>()

  const { _id: documentId } = usePartnerDocumentation()
  const { slug: departmentSlug } = usePartnerDepartments()
  const user = useAuth()
  const [formRef] = Form.useForm<IFormContract>()
  const { step } = usePartnerStep()
  const sendForm = PartnerModel.canSendForm(step)
  const { close, open, visible } = usePartnerStepDrawer()

  async function onSubmit (values: IFormContract) {
    setStatus('sending')

    const data = {
      department: departmentSlug,
      step: step.slug,
      ...values
    }

    if (values.foundationDate) data.foundationDate = correctTimezone(values.foundationDate as string)

    if (values.phone) data.phone = format.onlyDigits(values.phone)

    if (values.associates) {
      data.associates = values.associates?.map((associate) => {
        if (!associate.personType) {
          if (associate.name && !associate.socialName) associate.personType = 'fisica'
          if (associate.socialName && !associate.name) associate.personType = 'juridica'
        }

        if (associate.foundationDate)associate.foundationDate = correctTimezone(associate.foundationDate as string)
        if (associate.birthdate) associate.birthdate = correctTimezone(associate.birthdate as string)

        return associate
      })
    }

    try {
      const response = await PartnersRepository.send(documentId, data)
      close()
      setErrors({})
      swal.basic({ icon: 'success', title: 'Sucesso!', text: response.data.message })
    } catch (err) {
      console.error(err)
      if (err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
      } else {
        swalError({ title: 'Atenção', icon: 'warning', err })
      }
    }
    setStatus(undefined)
  }

  useEffect(() => {
    function onFill (form: IFormContract) {
      const data = partnerFormFill(form, user)
      formRef.setFieldsValue(data)
    }

    onFill(step.form as IFormContract)
  }, [step])

  function onChange (associateIdx: number) {
    const associates: IAssociates[] = formRef.getFieldValue('associates')
    const chooseAssociate = associates[associateIdx]
    if (chooseAssociate) {
      chooseAssociate.birthdate = undefined
      chooseAssociate.foundationDate = undefined
      chooseAssociate.name = undefined
      chooseAssociate.socialName = undefined
    }

    if (Array.isArray(associates) && associates.length > 0) {
      const mappedAssociations = associates.map((associate: IAssociates, idx: number) => {
        if (idx === associateIdx) return chooseAssociate

        return associate
      })

      formRef.setFieldsValue({ associates: mappedAssociations })
    }
  }

  return (
    <Fragment>
      <PartnerStepButton
        status={step.status}
        enabled={true}
        onClick={open}
        step={step}
      >
        {step.name}
      </PartnerStepButton>

      <Drawer
        visible={visible}
        onClose={close}
        onSubmit={() => onSubmit(formRef.getFieldsValue())}
        showSubmit={true}
        disabled={!sendForm}
        title={step.name}
        status={status}
      >
        <PartnerFormContract
          formRef={formRef}
          errors={errors}
          onChange={onChange}
          onSubmit={onSubmit}
          readOnly={!sendForm}
          onReviewSuccess={close}
        />
      </Drawer>
    </Fragment>
  )
}

export default PartnerFormContractStep

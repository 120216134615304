import { _typePromoterEnum, _userLevel } from 'ecp/models/UsersModel'
import { _tableAreas } from 'globals'

type ICanSubmitUploadDocumentDrawerProps = {
  level: _userLevel,
  type?: _typePromoterEnum,
  areaName?: _tableAreas
}

const UploadDocumentModel = {
  canSubmitUploadDocumentDrawer ({ level, type, areaName }: ICanSubmitUploadDocumentDrawerProps): boolean {
    if (level === 'promoter' && type === 'CB') return true
    if (level === 'administrator' && areaName === 'comercial') return true
    const levels = ['commercial', 'master']
    return levels.includes(level)
  }
}

export default UploadDocumentModel

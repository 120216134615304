import ProposalRepository from 'ecp/repositories/ProposalRepository'
import { ProposalService } from 'ecpf/services/proposalService'
import { useDynamicProposalPendingStore } from 'hooks'
import { useDispatch } from 'react-redux'
import { dynamicProposalPendingActions } from 'store/modules/dynamicProposalPending/dynamicProposalPendingActions'
import { IDynamicProposalPendingState } from 'store/modules/dynamicProposalPending/dynamicProposalReducer'

import { IDynamicProposal, _proposalSteps } from '../proposalInterfaces'

function usePendentProposal () {
  const { details } = useDynamicProposalPendingStore()

  const dispatch = useDispatch()

  const getPendenciesECPF = async (sensideaId: string) => {
    dispatch(dynamicProposalPendingActions.setLoading(true))
    try {
      const proposalService = new ProposalService()

      const response = await proposalService.fetchPendingStep(sensideaId)

      const { step } = response.data.data ?? {}
      const { slug, status, instructions, name } = step ?? {}

      if (step && slug && status && name) {
        dispatch(dynamicProposalPendingActions.setDetails({
          lastStep: {
            name: name,
            slug: slug,
            status: status,
            index: 0
          },
          histories: [
            {
              prevStatusOfThisStep: status,
              currentStep: {
                instructions: instructions ?? ''
              }
            }
          ] as IDynamicProposal.Model['histories']
        }))
      } else {
        dispatch(dynamicProposalPendingActions.resetDetails())
      }

      dispatch(dynamicProposalPendingActions.setError(''))
    } catch (err) {
      dispatch(dynamicProposalPendingActions.setError(err.message))
    } finally {
      dispatch(dynamicProposalPendingActions.setLoading(false))
    }
  }

  const getPendencies = async (id: string) => {
    dispatch(dynamicProposalPendingActions.setLoading(true))
    try {
      const params = {
        proposalId: id
      }

      const response = await ProposalRepository.getPendingProposals({ params })
      const thisProposal = response.data.data.proposals?.[0]

      dispatch(dynamicProposalPendingActions.setDetails(thisProposal))
      dispatch(dynamicProposalPendingActions.setError(''))
    } catch (err) {
      dispatch(dynamicProposalPendingActions.setError(err.message))
    } finally {
      dispatch(dynamicProposalPendingActions.setLoading(false))
    }
  }

  const setCurrentStepToOpen = (slug: _proposalSteps, open: IDynamicProposalPendingState['open']) => {
    if (details?.lastStep?.slug === slug) dispatch(dynamicProposalPendingActions.openStepDrawer(open))
  }

  const resetPendent = () => {
    dispatch(dynamicProposalPendingActions.reset())
  }

  return {
    getPendencies,
    getPendenciesECPF,
    setCurrentStepToOpen,
    resetPendent,
    details
  }
}

export default usePendentProposal

import { ColumnsType } from 'antd/lib/table'
import { enhanceServerTable } from 'components/ServerTable/serverTableEnhance'
import { ISearch } from 'components/ServerTable/types'
import { PROPOSAL_INITIAL_FILTERS } from 'ecp/app/Proposals/proposalConstants'
import { RH_INITIAL_FILTERS } from 'ecp/app/RH/rhConstants'
import { ACCESS_MANAGER_BACKOFFICE_FILTERS } from 'egi/app/AccessManager/views/AccessManagerBackOffice/AccessManagerBackOffice'
import { ACCESS_MANAGER_CLIENT_FILTERS } from 'egi/app/AccessManager/views/AccessManagerClient/AccessManagerClient'
import { useTables } from 'hooks'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ECP } from 'routes/ecp/constants'
import { EGI } from 'routes/egi/paths'
import { collaborators } from 'routes/routes'
import api from 'services/api'
import { setPreviousTable, tableDisableAll, tablesSetFiltersValues } from 'store/modules/tables/actions'
import { removeEmptyProps } from 'utils/utils'

type Props = {
  url: string
  params: ISearch
  columns: ColumnsType<any>
  additionalRow?: object
  controlAdditional?: boolean
}

export interface ResponseObject {
  data: any[]
  totalDocs: number
  limit: number
}

export default function useServerTable (props: Props, dependencies: any[]) {
  const { url, params } = props
  const [columns, setColumns] = useState<ColumnsType<any>>(props.columns)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState<string>('')
  const [values, setValues] = useState<ResponseObject>({
    data: [],
    totalDocs: 0,
    limit: params.limit
  })

  const updateColumns = () => {
    const { finalColumns } = enhanceServerTable([], props.columns)
    setColumns(finalColumns)
  }

  useEffect(() => {
    const abortController = new AbortController()
    const fetchData = async (params: ISearch): Promise<void> => {
      setLoading(true)

      try {
        const axiosConfig = {
          signal: abortController.signal,
          params: removeEmptyProps(params)
        }

        const response = await api.get(url, axiosConfig)
        const data = response.data.data
        const firstKey = Object.keys(data)[0]
        const item = data[firstKey]
        const { finalData } = enhanceServerTable(item.docs, props.columns)
        updateColumns()
        setValues({
          limit: item.limit,
          data: finalData,
          totalDocs: item.totalDocs
        })

        setLoading(false)
        setError(false)
        setErrorText('')
      } catch (err) {
        if (!abortController.signal.aborted) {
          setValues({ data: [], totalDocs: 0, limit: params.limit })
          setLoading(false)
          setError(true)
          setErrorText(err.response?.data?.message || '')
        }
      }
    }

    fetchData(params as ISearch)
    return () => {
      abortController.abort()
    }
  }, dependencies)

  useEffect(() => {
    updateColumns()
  }, [props.columns])

  return Object.assign(values, { loading, error, errorText, columns })
}

export const DEFAULT_FILTERS = {
  page: 1,
  limit: 15
}

export function useHistoryTable () {
  const dispatch = useDispatch()
  const { previousTable } = useTables()

  const tables: {[key: string] : {}} = {
    [ECP.RH.path]: RH_INITIAL_FILTERS,
    [ECP.RH_ENDORSEMENT_WAITING.path]: DEFAULT_FILTERS,
    [ECP.RH_ENDORSEMENT_APPROVED.path]: DEFAULT_FILTERS,
    [ECP.RH_ENDORSEMENT_CANCELED.path]: DEFAULT_FILTERS,
    [ECP.RH_ENDORSEMENT_DENIED.path]: DEFAULT_FILTERS,
    [ECP.RH_ENDORSEMENT_EXPIRED.path]: DEFAULT_FILTERS,
    [ECP.RH_ENDORSEMENT_IN_COURSE.path]: DEFAULT_FILTERS,
    [ECP.RH_ENDORSEMENT_CLOSED.path]: DEFAULT_FILTERS,
    [ECP.CLIENTS.path]: DEFAULT_FILTERS,
    [ECP.CLIENTS_PENDING.path]: DEFAULT_FILTERS,
    [ECP.CLIENTS_PENDING_ONIDATA.path]: DEFAULT_FILTERS,
    [ECP.PROPOSALS.path]: PROPOSAL_INITIAL_FILTERS,
    [ECP.COVENANTS.path]: DEFAULT_FILTERS,
    [ECP.CORBANS.path]: DEFAULT_FILTERS,
    [ECP.RENEGOTIATION_CONTRACTS.path]: DEFAULT_FILTERS,
    [ECP.RH_TRANSFER_TO_COMPANY.path]: DEFAULT_FILTERS,
    [ECP.RH_TRANSFER_DETAILING.path]: DEFAULT_FILTERS,
    [ECP.CLIENTS_IMPORT.path]: DEFAULT_FILTERS,
    [ECP.OPERATORS.path]: DEFAULT_FILTERS,
    [ECP.PROPOSALS_SEARCH.path]: DEFAULT_FILTERS,
    [collaborators.path]: DEFAULT_FILTERS,
    [EGI.PROPOSAL_LIST_LINKED_TWO.path]: DEFAULT_FILTERS,
    [EGI.PROPOSAL_LIST_UNLINKED_TWO.path]: DEFAULT_FILTERS,
    [EGI.PROPOSAL_LIST_TWO.path]: DEFAULT_FILTERS,
    [EGI.PROPOSAL_LIST_ALL.path]: DEFAULT_FILTERS,
    [EGI.PROPOSAL_LIST_REMIDEND_TWO.path]: DEFAULT_FILTERS,
    [EGI.PROPOSAL_LIST_SEARCH.path]: DEFAULT_FILTERS,
    [EGI.COLLABORATORS.path]: DEFAULT_FILTERS,
    [ECP.ACCESS_MANAGER_BACKOFFICE.path]: ACCESS_MANAGER_BACKOFFICE_FILTERS,
    [ECP.ACCESS_MANAGER_CLIENT.path]: ACCESS_MANAGER_CLIENT_FILTERS,
    [EGI.ACCESS_MANAGER_BACKOFFICE.path]: ACCESS_MANAGER_BACKOFFICE_FILTERS,
    [EGI.ACCESS_MANAGER_CLIENT.path]: ACCESS_MANAGER_CLIENT_FILTERS,
    [ECP.MANAGERS.path]: DEFAULT_FILTERS,
    [ECP.CREDIT_ANALYST.path]: DEFAULT_FILTERS,
    [ECP.REPORTS.path]: DEFAULT_FILTERS,
    [ECP.LOGS_API.path]: DEFAULT_FILTERS,
    [EGI.PROPOSAL_LIST.path]: DEFAULT_FILTERS,
    [EGI.LEADS.path]: DEFAULT_FILTERS,
    [EGI.PRICING_USERS.path]: DEFAULT_FILTERS,
    [EGI.JURIDICO_LEADERS.path]: DEFAULT_FILTERS,
    [EGI.JURIDICO_PROVIDERS.path]: DEFAULT_FILTERS,
    [EGI.JURIDICO_PROMOTERS.path]: DEFAULT_FILTERS,
    [EGI.ENGENHARIA_LEADERS.path]: DEFAULT_FILTERS,
    [EGI.ENGENHARIA_PROVIDERS.path]: DEFAULT_FILTERS,
    [EGI.COMMERCIAL_LEADERS.path]: DEFAULT_FILTERS,
    [EGI.CREDITO_LEADERS.path]: DEFAULT_FILTERS,
    [EGI.AUDITORS.path]: DEFAULT_FILTERS,
    [EGI.ALL_INVITES.path]: DEFAULT_FILTERS,
    [EGI.ATTORNEYS.path]: DEFAULT_FILTERS,
    [EGI.COMISSION_USERS.path]: DEFAULT_FILTERS
  }

  function validateTable (possibleTable: string): string | undefined {
    if (tables?.[possibleTable]) return possibleTable
    return undefined
  }

  function getDefaultValues (path: string) {
    return tables[path]
  }

  return {
    setFiltersValues: (currentPath: string) => {
      if (validateTable(currentPath) && (previousTable !== currentPath)) {
        dispatch(tablesSetFiltersValues(getDefaultValues(currentPath)))
        dispatch(setPreviousTable(currentPath))
        dispatch(tableDisableAll(false))
      }
    }
  }
}

import React, { useEffect, useState } from 'react'
import { useAuth, useResources, useTables } from 'hooks'
import { Button, Form, Modal } from 'antd'
import { ISelect } from 'egi/types'
import swal from 'utils/swal'
import { useDispatch } from 'react-redux'
import { tablesUpdate } from 'store/modules/tables/actions'
import BackofficeRepository from 'egi/repositories/BackofficeRepository'
import format from 'utils/format'
import MultiSelect from 'components/MultiSelect/MultiSelect'

interface IBackofficeAreasProps {
  visible: boolean
  setVisible: Function
}

function ModalBackofficeAreas ({ visible, setVisible }: IBackofficeAreasProps) {
  const user = useAuth()
  const resources = useResources()
  const tables = useTables()
  const dispatch = useDispatch()

  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(false)

  function areasBackofficeInvite () {
    const tempAreas: ISelect[] = []
    if (user && user.areas) {
      user.areas.forEach((item) => {
        const areas = resources.areas.filter(element => element.value === item)
        tempAreas.push(...areas)
      })
    }

    return tempAreas
  }

  const finalAreas = areasBackofficeInvite()

  function resetModal () {
    if (form) {
      form.resetFields()
      setVisible(false)
    }
  }

  async function changeAreas (values: { areas: Array<string> }) {
    const { areas } = values

    setLoading(true)
    if (tables.row && tables.row && tables.row._id) {
      try {
        const response = await BackofficeRepository.updateAreas(tables.row._id, { areas })
        dispatch(tablesUpdate())
        resetModal()
        swal.basic({ title: 'Sucesso', text: response.data?.message, icon: 'success' })
      } catch (err) {
        swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    if (form && tables.backofficeAreas.length > 0) {
      form.setFieldsValue({ areas: tables.backofficeAreas })
    }
  }, [tables.backofficeAreas])

  return (
    <Modal
      title="Editar areas"
      visible={visible}
      onCancel={resetModal}
      footer={null}
    >
      <Form
        form={form}
        onFinish={changeAreas}
      >
        <Form.Item
          name="areas"
          rules={[{ required: true, message: 'Escolha as áreas do Backoffice' }]}
        >

          <MultiSelect
            showSearch
            options={finalAreas.map(area => ({ label: format.capitalize(area.label), value: area.value }))}
            placeholder='Selecione uma opção'
          />
        </Form.Item>

        <Button
          type="primary"
          className="w-50 text-center"
          htmlType="submit"
          loading={loading}
        >
          Atualizar areas
        </Button>
      </Form>
    </Modal>
  )
}

export default ModalBackofficeAreas

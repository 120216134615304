import { Button, Radio, Skeleton } from 'antd'
import { RadioGroupProps } from 'antd/lib/radio'
import ErrorDetails from 'components/ErrorDetails/ErrorDetails'
import dayjs from 'dayjs'
import { EcpCard } from 'ecp/components/EcpCard/EcpCard'
import { InfoCard } from 'ecp/components/InfoCard/InfoCard'
import RadioGroupSquare from 'ecp/components/RadioGroupSquare/RadioGroupSquare'
import { AuthorizationsUser } from 'ecp/repositories/LoginRepository'
import { useAuth } from 'hooks'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import format from 'utils/format'
import { timeAsDayjs } from 'utils/time'
import { useCheckIrPdf, useFetchIrBalance, useRequestIrPdf } from './hooks/useIRReport'

const IRReportLevelSelect = ({
  levels,
  onSelectLevel,
  ...rest
}: {
  levels: Array<AuthorizationsUser>
  onSelectLevel: (user: AuthorizationsUser) => void
} & RadioGroupProps) => {
  if (!levels) return null
  if (!levels.length) return null

  const filteredLevels = useMemo(
    () => levels.filter(level => level.covenant?._id)
    , [levels.length])

  return (
    <RadioGroupSquare {...rest}>
      {filteredLevels.map(level => (
        <Radio.Button
          onClick={() => onSelectLevel(level)}
          key={level._id}
          value={level._id}
        >
          {level.covenant?.fantasyName}
        </Radio.Button>
      ))}
    </RadioGroupSquare>
  )
}

const IRReportWrapper = ({
  loading,
  errorMessage,
  children,
  onRetry
}: { loading: boolean, errorMessage: string, children: ReactNode, onRetry: () => void }) => {
  if (loading) {
    return (
      <EcpCard className='ir-report'>
        <Skeleton
          paragraph={false}
          active={true}
          className='ir-report__title ir-report__title-loading'
        />

        <div className='ir-report__balance-container'>
          <InfoCard.Loading width={284} />
          <InfoCard.Loading width={197} />
        </div>

        <div className='ir-report__pdf-text-container'>
          <Skeleton
            paragraph={false}
            active={true}
            className='ir-report__title-loading'
          />
        </div>

        <Skeleton.Button
          className='ir-report__button ir-report__button--loading'
          active={true}
        />
      </EcpCard>
    )
  }

  if (errorMessage) {
    return (
      <EcpCard className='ir-report text-center'>
        <ErrorDetails
          title='Ops, parece que algo deu errado.'
          subTitle={errorMessage}
          error={{ message: 'Ops, parece que algo deu errado.' }}
        />

        <Button
          type='primary'
          onClick={onRetry}
        >
          Tentar novamente
        </Button>
      </EcpCard>
    )
  }

  return (
    <>{children}</>
  )
}

export const IRReport = () => {
  const {
    loading,
    errorMessage,
    irBalance,
    fetchIrBalance
  } = useFetchIrBalance()

  const {
    loading: pdfLoading,
    requestPdf
  } = useRequestIrPdf()

  const {
    onPollingCheckPdf,
    pollingLoading
  } = useCheckIrPdf()

  const user = useAuth()

  const [selectLevel, setSelectedLevel] = useState<AuthorizationsUser>(
    () => {
      const findLevel = (user.levels || []).find(
        level => level?._id === user?._id
      )

      if (findLevel) return findLevel

      if (user?.levels?.[0]) return user?.levels?.[0]

      return {
        _id: user?._id,
        level: user?.level,
        products: user?.products,
        name: user?.name as string,
        covenant: {
          fantasyName: user?.convenio?.fantasyName,
          _id: user?.convenio?._id
        }
      }
    }
  )
  const { _id: userId, covenant } = selectLevel || {}

  const getFormattedDate = (format: string, date?: string) => {
    if (!date) return '-'
    if (!dayjs(date).isValid()) return '-'
    return timeAsDayjs(date, { applyTimezone: false }).format(format)
  }

  const fullYear = getFormattedDate('YYYY', irBalance?.informacoes_imposto_de_renda?.data_referencia)

  const formattedDate = getFormattedDate('DD/MM/YYYY', irBalance?.informacoes_imposto_de_renda?.data_referencia)

  const onRequestPdf = async () => {
    const pdf = await requestPdf(userId, covenant?._id)
    if (pdf?.data.data?._id) onPollingCheckPdf(pdf?.data.data?._id)
  }

  const onFetchIrBalance = () => {
    fetchIrBalance(userId, covenant?._id)
  }

  const onSelectLevel = (user: AuthorizationsUser) => {
    setSelectedLevel(user)
    onPollingCheckPdf('')
  }

  useEffect(() => {
    onFetchIrBalance()
  }, [
    userId,
    covenant?._id
  ])

  return (
    <div>
      <div className='ir-report__covenant-container'>
        <IRReportLevelSelect
          levels={user.levels}
          onSelectLevel={onSelectLevel}
          defaultValue={selectLevel?._id}
        />
      </div>

      <IRReportWrapper
        loading={loading}
        errorMessage={errorMessage}
        onRetry={onFetchIrBalance}
      >
        <EcpCard className='ir-report'>
          <div className='ir-report__title-container'>
            <h3 className='ir-report__title'>Imposto de renda {fullYear}</h3>
          </div>

          <div className='ir-report__balance-container'>
            <InfoCard
              title={<span>Saldo devedor em {formattedDate}</span>}
              description={format.formatBRL(irBalance?.informacoes_imposto_de_renda.saldo_devedor)}
            />

            <InfoCard
              title={<span>Total pago em {fullYear}</span>}
              description={format.formatBRL(irBalance?.informacoes_imposto_de_renda.total_pago)}
            />
          </div>

          <div className='ir-report__pdf-text-container'>
            <p>Clique no botão abaixo para fazer o download completo das informações do seu imposto de renda</p>
          </div>

          <Button
            className='ir-report__button'
            type='primary'
            onClick={onRequestPdf}
            loading={pdfLoading || pollingLoading}
          >
            Baixar PDF
          </Button>
        </EcpCard>
      </IRReportWrapper>
    </div>
  )
}

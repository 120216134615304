import { INotaryFeesActionsTypes } from './notaryFeesActionTypes'

export enum NotaryFeesTypes {
  SET_PERCENTAGE = 'NOTARY_FEES_SET_PERCENTAGE',
  SET_STATUS = 'NOTARY_FEES_SET_STATUS',
  SET_ANTD_STATUS = 'NOTARY_FEES_SET_ANTD_STATUS',
  SET_IMPORT_FILE = 'SET_IMPORT_FILE',
  RESET = 'PROGRESS_QUEUE_RESET'
}

export const NotaryFeestActions = {

  setImportItem: (payload: INotaryFeesActionsTypes.setImportItem['payload']): INotaryFeesActionsTypes.setImportItem => ({
    type: NotaryFeesTypes.SET_IMPORT_FILE,
    payload
  }),

  setStatus: (payload: INotaryFeesActionsTypes.setStatus['payload']): INotaryFeesActionsTypes.setStatus => ({
    type: NotaryFeesTypes.SET_STATUS,
    payload
  }),

  setAntdStatus: (payload: INotaryFeesActionsTypes.setAntdStatus['payload']): INotaryFeesActionsTypes.setAntdStatus => ({
    type: NotaryFeesTypes.SET_ANTD_STATUS,
    payload
  }),

  setPercentage: (payload: INotaryFeesActionsTypes.setPercentage['payload']): INotaryFeesActionsTypes.setPercentage => ({
    type: NotaryFeesTypes.SET_PERCENTAGE,
    payload
  }),

  reset: (): INotaryFeesActionsTypes.reset => ({
    type: NotaryFeesTypes.RESET
  })

}

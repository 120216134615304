import { Form, Input } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { ColumnsType } from 'antd/lib/table'
import { AxiosRequestConfig } from 'axios'
import DrawerFilters from 'components/DrawerFilters/DrawerFilters'
import DrawerFiltersLineCollapsable, { DrawerFiltersLineCollapsableItem, DrawerFiltersRadioGroupWrapper } from 'components/DrawerFilters/DrawerFiltersLineCollapsable'
import { LimiterTable } from 'components/LimiterTable/LimiterTable'
import ReRequestButton from 'components/ReRequest/ReRequest'
import { ServerTableAndSimplePagination } from 'components/ServerTable/ServerTable'
import ServerTableFilters from 'components/ServerTable/ServerTableFilters'
import GenericOriginRadioECPF from 'ecpf/components/GenericOriginRadioECPF/GenericOriginRadioECPF'
import SituationOrigemRadioECPF from 'ecpf/components/SituationOrigemRadioECPF/SituationOrigemRadioECPF'
import { SituationOrigemTag } from 'ecpf/components/SituationOrigemTag/SituationOrigemTag'
import TypistModels from 'ecpf/models/TypistModels'
import { useAuth } from 'hooks'
import { urlSearchParamsToObj, useQueryFilters } from 'hooks/useQuery'
import React, { Fragment, useEffect, useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { ECPF } from 'routes/ecpf/constants'
import { IEmployerECPFList, useEmployerListECPF } from '../../hooks/useEmployerECPFList'

const filtersToIgnoreCounting = ['descricao', 'offset']

const EmployerECPFList = () => {
  const [drawerForm] = useForm()
  const [rowForm] = useForm()

  const user = useAuth()
  const history = useHistory()

  const {
    urlSearchParams,
    onUpdateSearchUrlResetingOffset,
    getLimit,
    getOffset,
    calculateCountBySearchParams,
    onUpdateSearchUrl
  } = useQueryFilters()

  const {
    loading,
    employers,
    fetchEmployers,
    errorDetails
  } = useEmployerListECPF()

  const historyRedirectToOrganization = (code: string, employer: IEmployerECPFList) => {
    return {
      pathname: `/auth${ECPF.ORGANIZATION_LIST.path}`,
      search: ECPF.ORGANIZATION_LIST.search + '&codigoEmpregador=' + code + '&empregadorNome=' + employer.descricao
    }
  }

  const columns: ColumnsType<IEmployerECPFList> = useMemo(() => [
    {
      title: 'ID',
      dataIndex: 'codigo',
      align: 'center'
    },
    {
      title: 'Razão Social',
      dataIndex: 'descricao'
    },
    {
      title: 'Situação',
      dataIndex: 'situacao',
      render: (situation: IEmployerECPFList['situacao']) => <SituationOrigemTag situation={situation} />
    },
    {
      title: 'Cód. Corban',
      dataIndex: ['promotor', 'codigo'],
      render: (code) => code ?? '-'
    },
    {
      title: 'Corban',
      dataIndex: ['promotor', 'descricao'],
      render: (corban) => corban ?? '-'
    },
    {
      title: 'Convênios',
      dataIndex: 'codigo',
      render: (value, row) => value ? (
        <Link
          className='link-style'
          to={historyRedirectToOrganization(value, row)}
        >
          Ver convênios &rarr;
        </Link>
      ) : '-'
    }
  ], [historyRedirectToOrganization])

  useEffect(() => {
    drawerForm.setFieldsValue(urlSearchParamsToObj(urlSearchParams))
    rowForm.setFieldsValue(urlSearchParamsToObj(urlSearchParams))
  }, [])

  const onFetchEmployer = (abortController: AbortController, urlSearchParams: URLSearchParams) => {
    const requestConfig: AxiosRequestConfig = {
      signal: abortController.signal,
      params: urlSearchParamsToObj(urlSearchParams)
    }

    fetchEmployers(abortController, requestConfig)
  }

  useEffect(() => {
    const abortController = new AbortController()
    onFetchEmployer(abortController, urlSearchParams)

    return () => {
      abortController.abort()
    }
  }, [urlSearchParams.toString()])

  return (
    <Fragment>
      <LimiterTable>
        <ServerTableFilters>
          <Form
            form={rowForm}
            layout='vertical'
          >
            <Form.Item
              label='Nome'
              name='descricao'
            >
              <Input.Search
                className="table-filter"
                placeholder='Pesquise por nome'
                defaultValue={urlSearchParams.get('descricao') ?? ''}
                onSearch={(value) => {
                  onUpdateSearchUrlResetingOffset({ descricao: value })
                }}
              />
            </Form.Item>
          </Form>

          <ServerTableFilters>
            <ReRequestButton
              className='ant-form ant-form-vertical'
              onClick={() => {
                const abortController = new AbortController()
                onFetchEmployer(abortController, urlSearchParams)
              }}
            />

            <Form
              form={drawerForm}
              layout='vertical'
              onFinish={onUpdateSearchUrlResetingOffset}
            >
              <DrawerFilters
                counter={calculateCountBySearchParams(urlSearchParamsToObj(urlSearchParams), filtersToIgnoreCounting) ?? 0}
                showSearch
                closeOnClear
                onClear={() => {
                  drawerForm.resetFields()
                  drawerForm.submit()
                }}
                onSearch={() => {
                  drawerForm.submit()
                }}
              >
                <DrawerFiltersLineCollapsable label='Código (ID)'>
                  <DrawerFiltersLineCollapsableItem>
                    <Form.Item
                      name='codigo'
                      initialValue=''
                    >
                      <Input placeholder='Digite o código' />
                    </Form.Item>
                  </DrawerFiltersLineCollapsableItem>
                </DrawerFiltersLineCollapsable>

                <DrawerFiltersRadioGroupWrapper>
                  <DrawerFiltersLineCollapsable label='Situação'>
                    <Form.Item
                      name='situacao'
                      initialValue=''
                    >
                      <SituationOrigemRadioECPF />
                    </Form.Item>
                  </DrawerFiltersLineCollapsable>
                </DrawerFiltersRadioGroupWrapper>

                {TypistModels.showPromotersFilter(user.level) && (
                  <DrawerFiltersRadioGroupWrapper>
                    <DrawerFiltersLineCollapsable label='Corban'>
                      <Form.Item
                        name='codigoPromotor'
                        initialValue=''
                      >
                        <GenericOriginRadioECPF
                          path='promotor'
                        />
                      </Form.Item>
                    </DrawerFiltersLineCollapsable>
                  </DrawerFiltersRadioGroupWrapper>
                )}
              </DrawerFilters>
            </Form>
          </ServerTableFilters>
        </ServerTableFilters>

        <ServerTableAndSimplePagination
          loading={loading}
          page={getOffset()}
          limit={getLimit()}
          columns={columns}
          errorText={errorDetails?.message}
          data={employers}
          onPaginationChange={(page) => onUpdateSearchUrl({ offset: String(page) })}
          errorDetails={errorDetails}
          onRow={(record) => {
            return {
              onClick: () => {
                history.push(historyRedirectToOrganization(record.codigo, record))
              }
            }
          }}
        />
      </LimiterTable>
    </Fragment>
  )
}

export default EmployerECPFList

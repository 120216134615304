import React, { Fragment, useEffect, useState } from 'react'
import { Col, Form, Row, Empty } from 'antd'
import { Datepicker, DocumentUpload } from 'components'
import { IDocuments } from 'egi/types'
import usePartnerStep from '../../../../../hooks/usePartnerStep'
import { ICorbanCertificateForm, ICorbanCertificateStep } from '../types'
import locale from 'antd/es/date-picker/locale/pt_BR'
import PartnerModel from 'egi/models/PartnerModel'

function CorbanCertificateForm ({ errors, onFileUpload, setFormFields, onFileDelete, readonly }: ICorbanCertificateStep) {
  const [path, setPath] = useState<Array<IDocuments>>([])
  const { step } = usePartnerStep()

  const sendDocument = PartnerModel.canSendDocument(step)

  useEffect(() => {
    setFormFields(step.form as ICorbanCertificateForm)
  }, [])

  return (
    <Fragment>
      <Row>
        <Col className="form-corban-certificate__document-upload" lg={14} sm={24} xs={24}>

          {!step.documents && !sendDocument && <Empty description="Nenhum documento encontrado!" />}

          <DocumentUpload
            paths={path}
            getDocuments={() => setPath([{ name: '', path: step?.documents ?? [], stepId: '', stepName: '' }])}
            loading={false}
            hideUploadButton={!sendDocument}
            maxDocs={3}
            onFileUpload={onFileUpload}
            onFileDelete={onFileDelete}
            skeletonQuantity={2}
          />
        </Col>

        <Col className='mt-4' lg={14} sm={24} xs={24} >
          <Form.Item
            name='validate'
            label={<label>Validade do Certificado:</label>}
            help={errors.validate && (errors.validate)}
            validateStatus={errors.validate && ('error')}
          >
            <Datepicker
              disabled={readonly}
              id="validate"
              locale={locale}
            />
          </Form.Item>
        </Col>

      </Row>

    </Fragment >
  )
}

export default CorbanCertificateForm

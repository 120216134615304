import { IInitialSimulation } from './types'

const INITIALSTATE: IInitialSimulation = {
  id: undefined,
  pricing: undefined,
  values: undefined,
  lastStep: undefined,
  step: 0
}

const simulation = (state = INITIALSTATE, action: any) => {
  switch (action.type) {
    case 'SET_SIMULATION_ID': {
      return {
        ...state,
        id: action.payload
      }
    }

    case 'SIMULATION_PRICING_FILL_VALUES': {
      return {
        ...state,
        pricing: { ...action.payload }
      }
    }

    case 'SIMULATION_FILL_VALUES_SUCCEED': {
      return {
        ...state,
        values: { ...action.payload }
      }
    }

    case 'SIMULATION_LAST_STEP_SUCCEED': {
      return {
        ...state,
        lastStep: { ...action.payload }
      }
    }

    case 'SIMULATION_STEPS_VALUES': {
      return {
        ...state,
        step: action.payload
      }
    }

    case 'RESET_ALL_SIMULATION': {
      return INITIALSTATE
    }
    default:
      return state
  }
}

export default simulation

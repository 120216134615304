import React, { useLayoutEffect, useState, ReactNode } from 'react'
import { Col, Collapse, Divider, Progress, Row, Tag, Tooltip, Typography } from 'antd'
import CollapsePanel from 'antd/lib/collapse/CollapsePanel'
import ActionsContainer from 'components/Actions/ActionsContainer'
import { useAuth } from 'hooks'
import { IStep } from 'egi/types'
import Step from '../Step/Step'
import { IDepartmentCardProps } from '../../proposalEgiInterfaces'
import SLA from 'egi/app/Settings/components/SLA/SLA'
import ProposalEgiModel from 'egi/models/ProposalEgiModel'
import { ZipDocumentsLogModal } from '../ZipDocumentsLogModal/ZipDocumentsLogModal'
import ZipDocumentsDownloadModal from '../ZipDocumentsDownloadModal/ZipDocumentsDownloadModal'
import { DownloadOutlined } from '@ant-design/icons'
import { ButtonProps } from 'antd/lib/button'
import ButtonGreenGhost from 'ecp/components/ButtonGreenGhost/ButtonGreenGhost'
import { colors } from 'styles/colors'
import translate from 'utils/translate'

export const DepartmentCardButtonZip = ({
  children,
  ...rest
}: ButtonProps & {
  children: ReactNode
}) => {
  return (
    <ButtonGreenGhost
      className='department-card__documents-download'
      {...rest}
    >
      {children}
    </ButtonGreenGhost>
  )
}

const { Paragraph, Text, Title } = Typography
const DepartmentCard = ({
  onClick,
  picture,
  department,
  actions,
  status,
  departmentStatus
}: IDepartmentCardProps) => {
  const {
    sla,
    backoffices,
    hasSLA,
    _id,
    avaliations,
    personType,
    name,
    steps,
    canSeeDownloadZipDocuments,
    ref,
    areaId,
    description
  } = department
  const finishedSteps = steps.filter((step) =>
    ['approved', 'refused', 'reservation'].includes(step.status || '')
  ).length

  const totalSteps = steps.length
  const percent = (finishedSteps / totalSteps) * 100

  const [collapseText, setCollapseText] = useState('Fechar')
  const [showCollapse, setShowCallpse] = useState<boolean>(true)
  const [visbleDocumentsModal, setVisibleDocumentsModal] = useState<boolean>(false)

  const user = useAuth()

  const slaProp = {
    end: sla?.end,
    start: sla?.start,
    max: sla?.max,
    recessDays: sla?.recessDays,
    actionAt: sla?.actionAt,
    recessDaysBeforeStart: sla?.recessDaysBeforeStart
  }

  const handleCollapse = (open: string | Array<string>) => {
    if (open.length === 0) {
      setCollapseText('Ver etapas')
    } else setCollapseText('Fechar')
  }

  function formatTitleId (title: string) {
    const regex = /(.*)(?=[(])/g

    if (title.includes('documentos')) {
      const formatString = title.match(regex)
      if (formatString) return formatString[0].trim().replace(/ /g, '-')
    }

    return title.replace(/ /g, '-').replace(/[()]/g, '')
  }

  useLayoutEffect(() => {
    if (ProposalEgiModel.canfindSteps(user.level)) {
      const findSteps = steps.filter(element => element.display === true)
      if (findSteps && findSteps.length === 0) {
        setShowCallpse(false)
      }
    }
  }, [])

  return (

    <Row id={`department-${formatTitleId(name)}`}>
      <ZipDocumentsDownloadModal
        visible={visbleDocumentsModal}
        onCancel={() => setVisibleDocumentsModal(false)}
        departmentId={_id}
      />

      <Col xs={0} sm={0} md={3} className="pr-3">
        <div className="icon-container">{picture}</div>
      </Col>

      <Col xs={24} sm={24} md={21}>
        <Row gutter={[15, 15]} align="middle" justify="space-between">
          <Col>
            <Title className="department-card-title mb-0" level={2}>
              {name}

              {departmentStatus && (
                <Tooltip title={departmentStatus ? translate.departmentStatus(departmentStatus) : ''} className='ml-2'>
                  <Tag color={colors[departmentStatus]}>{translate.departmentStatus(departmentStatus)}</Tag>
                </Tooltip>
              )}
            </Title>

            <div className="flex flex-column">
              {ProposalEgiModel.canSeeLinkedBackoffice(user.level) && backoffices && backoffices.map((info: any) => (
                <label key={info.id} className="color-primary bold uppercase mb-0">Backoffice vinculado: {info.name}</label>
              ))}
            </div>
          </Col>

          {(Array.isArray(actions) && actions) && (
            <ActionsContainer
              actions={actions}
              departmentName={name}
              status={status}
            />
          )}

          {hasSLA && sla?.actionAt && ProposalEgiModel.canSeeSLA(user.level) && (
            <Col>
              <SLA sla={slaProp} />
            </Col>
          )}
        </Row>

        {description && <Paragraph>{description}</Paragraph>}

        <Divider className="divider-style" />

        <div className="department-card__progress-bar-container">
          <Row>
            <div className="progress-bar">
              <Progress
                percent={percent}
                status="success"
                trailColor="#CECECE"
                strokeColor="#3A6D51"
                showInfo={false}
              />
            </div>

            <div>
              <Text className="department-card-status-text">
                {finishedSteps} de {totalSteps} Tarefas finalizadas.
              </Text>
            </div>
          </Row>

          {canSeeDownloadZipDocuments && (
            <div className='department-card__zip-buttons '>
              <DepartmentCardButtonZip
                icon={<DownloadOutlined />}
                className="department-card__zip-button-left"
                onClick={() => setVisibleDocumentsModal(true)}
              >
                Documentos
              </DepartmentCardButtonZip>

              <ZipDocumentsLogModal
                department={{
                  _id,
                  name
                }}
              />
            </div>
          )}
        </div>

        <Row>
          <Col sm={24} md={19}>
            {(steps && steps.length > 0 && showCollapse) && (
              <Collapse defaultActiveKey={['1']} ghost onChange={handleCollapse}>
                <CollapsePanel className="department-steps-collapse" header={collapseText} key="1">
                  {steps.map((step: IStep, index) => {
                    return (
                      <Step
                        personType={personType}
                        step={step}
                        areaId={areaId}
                        departmentRef={ref}
                        avaliations={avaliations}
                        id={step._id}
                        onClick={onClick}
                        hasNext={index !== totalSteps - 1}
                        key={step._id}
                        title={name}
                      />
                    )
                  })}
                </CollapsePanel>
              </Collapse>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default DepartmentCard

import React from 'react'
import { Input as AntInput } from 'antd'
import masks from 'utils/masks'

import { IInputProps } from './types'
import { _inputMask } from 'globals'
import { getMaxLength } from 'components/Inputs/functions'

function Input ({ mask, value = '', min, raw = false, ...rest }: IInputProps) {
  return (
    <AntInput
      maxLength={getMaxLength(mask)}
      className="ant-input"
      onChange={(e) => masks(e.target.value, mask as _inputMask)}
      value={masks(value as string, mask, raw)}
      min={min}
      {...rest}
    />
  )
}

export default Input

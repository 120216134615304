import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import ServerTable from 'components/ServerTable/ServerTable'
import { LimiterTable } from 'components/LimiterTable/LimiterTable'

import { useTables } from 'hooks'
import React, { useMemo } from 'react'

import { tablesSetFiltersValues } from 'store/modules/tables/actions'
import { handleServerTableChange } from 'components/ServerTable/function'
import { useDispatch } from 'react-redux'
import DownloadFilters from '../../components/LogsZipsFilters/LogsZipsFilters'

import { renderBadge } from 'utils/renderBadge'

import translate from 'utils/translate'
import { Link } from 'react-router-dom'
import { DownloadsLogsModal } from '../../components/ZipInfoModal/ZipInfoModal'
import { IZipDocumentLog } from 'egi/app/ProposalEgi/components/ZipDocumentsLogModal/ZipDocumentsLogModal'

function LogsZips () {
  const table = useTables()
  const dispatch = useDispatch()

  const columns: ColumnsType<IZipDocumentLog> = useMemo(() => [

    {
      title: 'Proposta',
      dataIndex: 'proposalNumber',
      sorter: true,
      render: (proposalNumber, row) => {
        return <div className="capitalize">
          <Link
            className="link-style color-tertiary font-500"
            to={`/auth/proposals/show/${row.proposalId}`}
          >
            {proposalNumber}
          </Link>
        </div>
      }
    },
    {
      title: 'Informações de Download',
      dataIndex: 'deviceInfo',
      sorter: true,
      render: (info, row) => <DownloadsLogsModal requestInfo={info} row={row}/>
    },
    {
      title: 'Departamento',
      dataIndex: 'department',
      render: (department: {name: string, id: string}) => {
        return <div className="capitalize text-ellipsis">
          {department.name}
        </div>
      },
      sorter: true
    },
    {
      title: 'Data',
      align: 'center',
      dataIndex: 'updatedAt',
      sorter: true
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      sorter: true,
      render: (status: string) => {
        return (
          renderBadge(translate.DownloadStatus(status), 'fileStatus')
        )
      }
    },
    {
      title: 'Download',
      dataIndex: 'url',
      sorter: true,
      align: 'center',
      render: (url: string, row) => {
        if (row.status !== 'finished') return '-'
        return <a href={url} className="link-style color-tertiary font-500 mb-0 table-name">Download</a>
      }

    }

  ] as ColumnsType<IZipDocumentLog>, [

    table.filters.sort
  ])

  return (
    <div>
      <LimiterTable>
        <DownloadFilters />
        <ServerTable
          url='/proposals/zip/logs'
          columns={columns}
          onChange={
            (pagination: TablePaginationConfig, filters: Record<string, (string | number | boolean)[] | null>, sorter) => {
              const serverTableFiltersData = handleServerTableChange(columns, table.filters)(pagination, filters, sorter)
              dispatch(tablesSetFiltersValues({ ...table.filters, ...serverTableFiltersData }))
            }
          }
        />
      </LimiterTable>
    </div>
  )
}

export default LogsZips

const UnauthUploadFIleModel = {
  unauthUploadDescriptionInstruction: (stepSlug: string) => {
    switch (stepSlug) {
      case 'holerite':
        return 'enviar seu holerite'
      default:
        return 'enviar seus documentos'
    }
  },

  unauthUploadTitle: (stepSlug?: string) => {
    switch (stepSlug) {
      case 'holerite':
        return 'Envio de holerite'
      default:
        return 'Upload de documentos'
    }
  }
}

export default UnauthUploadFIleModel

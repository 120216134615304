import { IResponseBase, Repository } from './Repository'
import api from 'services/api'
import { AxiosRequestConfig } from 'axios'
import { IAllotment, IOnidataDiscounts, ITransferCovenantsResponse } from 'ecp/app/RH/rhInterfaces'

class RHTransfersRepository extends Repository<IAllotment> {
  async submitDiscounts (data: {lotId: string}, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.post(`${this.path}/submit`, data, config)
    )
  }

  async saveDiscounts (id: string, data: {lotsDiscountsPaginated: IOnidataDiscounts[]}, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.patch(`${this.path}/save/`, data, config)
    )
  }

  async downloadCSV (covenantId: string, config?: AxiosRequestConfig): Promise<IResponseBase<{ file: string }>> {
    return Repository.handle(() =>
      this.api.post(`${this.path}/${covenantId}/export`, config)
    )
  }

  async getAll <T = IAllotment> (config?: AxiosRequestConfig): Promise<IResponseBase<T>> {
    return Repository.handle(() =>
      this.api.get(this.path, config)
    )
  }

  async getAllotment (id: string, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/${id}`, config)
    )
  }

  async getTransferCovenants (config?: AxiosRequestConfig): Promise<IResponseBase<ITransferCovenantsResponse>> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/covenants`, config)
    )
  }
}

export default new RHTransfersRepository({ api, path: '/ecp/rh/transfer' })

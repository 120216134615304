import { AxiosRequestConfig } from 'axios'
import OriginsECPFRepository from 'ecpf/repositories/OriginsECPFRepository'
import OriginsUnauthECPFRepository from 'ecpf/repositories/OriginsUnauthECPFRepository'
import { TokenCPFNotFound } from './tokenService'

export class OriginsService {
  updateTypistEmail (cpf?: string, tokenValidationId?: string) {
    if (!cpf || !tokenValidationId) throw new TokenCPFNotFound()
    return OriginsUnauthECPFRepository.updateTypistEmail({ cpf, tokenValidationId })
  }

  async listEmployer (config?: AxiosRequestConfig) {
    return OriginsECPFRepository.listEmployer(config)
  }

  async listMainOffice (config?: AxiosRequestConfig) {
    return OriginsECPFRepository.listMainOffice(config)
  }

  async listRegional (config?: AxiosRequestConfig) {
    return OriginsECPFRepository.listRegional(config)
  }

  async listPromoter (config?: AxiosRequestConfig) {
    return OriginsECPFRepository.listPromoter(config)
  }

  async listTypist (config?: AxiosRequestConfig) {
    return OriginsECPFRepository.listTypist(config)
  }

  async listOrganization (config?: AxiosRequestConfig) {
    return OriginsECPFRepository.listOrganization(config)
  }
}

import { ISimulationPricingValue, ISimulationValues, ISimulationLastStep } from './types'

export const simulationSetId = (simulationId: string | undefined) => ({
  type: 'SET_SIMULATION_ID',
  payload: simulationId
})

export const simulationValues = (data: ISimulationValues, step?: number) => ({
  type: 'SIMULATION_FILL_VALUES_REQUESTED',
  payload: { data, step }
})

export const simulationLastStep = (data: ISimulationLastStep, step?: number) => ({
  type: 'SIMULATION_LAST_STEP_REQUESTED',
  payload: { data, step }
})

export const simulationPricngValues = (data: ISimulationPricingValue) => ({
  type: 'SIMULATION_PRICING_FILL_VALUES',
  payload: { ...data }
})

export const simulationSteps = (step: number) => ({
  type: 'SIMULATION_STEPS_VALUES',
  payload: step
})

export const resetAllSimulation = () => ({
  type: 'RESET_ALL_SIMULATION'
})

import React, { Fragment, ReactNode, useState } from 'react'
import { Col, Divider, Row } from 'antd'
interface IExtraButtons {
  buttonTitle: string
  contents: React.ReactElement
  filtersClicked: boolean
  disabled: boolean
}

function MoreOptionsTwo ({ contents, filtersClicked, buttonTitle, disabled }: IExtraButtons) {
  const [expandMoreButtons, setExpandMoreButtons] = useState(false)

  const items = contents?.props?.children?.filter((items: ReactNode) => items !== false)
  if (items?.length < 2) {
    return (
      <div className="server-table-filters__more-options-button-alone">
        {contents}
      </div>
    )
  }

  return (
    <Fragment>
      <Col className={!expandMoreButtons ? 'server-table-filters__more-options-dropdown' : 'server-table-filters__more-options-dropdown server-table-filters__button-options-actived server-table-filters__button-options-actived-new-server-table'}>
        <button
          disabled={disabled}
          type="button"
          className={`server-table-filters__more-options-button ${disabled ? 'server-table-filters__more-options-button__disabled' : ''}`}
          style={{ zIndex: filtersClicked ? 46 : 53 }}
          onClick={() => setExpandMoreButtons(prev => !prev)}
        >
          <section className="server-table-filters__section-layout">
            <div className="server-table-filters__more-options-text">{buttonTitle}</div>
          </section>
        </button>
        {expandMoreButtons && (
          <Fragment>
            <div
              className="server-table-filters__overlay"
              style={{ zIndex: filtersClicked ? 45 : 52 }}
              onClick={() => setExpandMoreButtons(prev => !prev)}
            />

            <Row className="server-table-filters__grid-more-buttons server-table-filters__grid-more-buttons-new-server-table" justify="center" align="bottom" gutter={[15, 15]}>
              <Divider className="server-table-filters__divider-options" />
              {contents}
            </Row>
          </Fragment>
        )}
      </Col>

    </Fragment>
  )
}

export default MoreOptionsTwo

import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import DynamicColorButton from 'ecp/components/DynamicColorButton/DynamicColorButton'
import React from 'react'

type Props = {
  handleConfirmCLick: () => void
  onOkAction: () => void
  onCancelAction: () => void
  loading: boolean
  visible: boolean
  contractSignAgree: boolean
}

export default function AcceptContractModal ({
  handleConfirmCLick,
  onOkAction,
  onCancelAction,
  loading,
  visible,
  contractSignAgree
}: Props) {
  return (
    <>
      <Modal
        centered
        visible={visible}
        onOk={onOkAction}
        onCancel={onCancelAction}
        className='accept-contract-modal'
        footer={null}
      >
        <h2 className='accept-contract-modal__title mb-4'>
          {contractSignAgree ? 'Aceitar' : 'Recusar'} Contrato
        </h2>
        {contractSignAgree ? (
          <p>
            Sua proposta será enviada à financeira para análise
          </p>
        ) : (
          <div className='flex flex-column'>
            <ExclamationCircleOutlined className='mb-2 accept-contract-modal__decline-icon' />
            <p className='text-center'>
              Seu contrato será recusado deseja confirmar essa ação?
            </p>
          </div>
        )}

        <Row className='mt-5'>
          <Col className='px-1' lg={12} sm={12} xs={24}>
            <Button
              className='w-100 accept-contract-modal__back-button'
              type='ghost'
              key='back'
              onClick={onCancelAction}
            >
              Voltar
            </Button>
          </Col>

          <Col className='px-1' lg={12} sm={12} xs={24}>
            <DynamicColorButton
              htmlType='submit'
              key='submit'
              type='primary'
              loading={loading}
              onClick={handleConfirmCLick}
              className='w-100 '
              colorClass={ contractSignAgree ? 'button-green' : 'button-red'}
            >
              {contractSignAgree ? 'Confirmar' : 'Recusar'}
            </DynamicColorButton>
          </Col>
        </Row>
      </Modal>
    </>
  )
}

import { message } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import {
  filtersProposalsRequest,
  IContractPath, ICreditFactors,
  IDocumentalReport,
  IDocumentalReportSteps, IDynamicProposal,
  ILocalizationContract,
  IOnidataAddress, IProposalFilters,
  IProposalSimulationForm,
  IProposalSimulationFormResponse,
  IReviewCipForm, _EcpProposalModalidade,
  _openStepWithAction
} from 'ecp/app/Proposals/proposalInterfaces'
import { IResource } from 'ecp/repositories/ResourcesRepository'
import { _inputMask } from 'globals'
import pdf from 'pdfjs'
import helvetica from 'pdfjs/font/Helvetica'
import helveticaBold from 'pdfjs/font/Helvetica-Bold'
import { ECP } from 'routes/ecp/constants'
import { stepProposalLabelColor } from 'styles/colors'
import format from 'utils/format'
import mask from 'utils/masks'
import { timeAsDayjs } from 'utils/time'
import translate from 'utils/translate'
import {
  conditionalKeys,
  formatStatusForRequest
} from 'utils/utils'
import { ECP_MODALIDADE, TRANSLATE_GET_LOCATION_ERROR } from './proposalConstants'

function formatDates (filters: IProposalFilters) {
  const dateKeys: (keyof typeof filters)[] = [
    'data_solicitacao_antes_de',
    'data_solicitacao_depois_de',
    'data_averbacao_antes_de',
    'data_averbacao_depois_de'
  ]

  return dateKeys.reduce((acc, key) => {
    if (filters[key]) {
      acc[key] = dayjs(filters[key] as Dayjs).format('YYYY-MM-DD')
    }
    return acc
  }, {} as { [key: string]: string })
}

export function formatFilters (filters: IProposalFilters) {
  const formattedFilters: filtersProposalsRequest = {
    ...filters,
    ...formatDates(filters),
    statusLabel: formatStatusForRequest(filters.statusLabel)
  }

  return formattedFilters
}

export function countApproved (
  acc: number,
  item: IDynamicProposal.Model['departments']['esteira']['steps']['assinatura-do-contrato']
) {
  return item.status === 'approved' ? acc + 1 : acc
}

export const initialSolicitacaoDatesFilter = () => {
  return {
    data_solicitacao_depois_de: timeAsDayjs().startOf('Q'),
    data_solicitacao_antes_de: timeAsDayjs().endOf('Q')
  }
}

export function formatReduxValuesContract (values: { [key: string]: string }) {
  return {
    ...values,
    ...conditionalKeys(values?.data_alterado_depois_de, { data_alterado_depois_de: format.stringDateToObject(values?.data_alterado_depois_de) }),
    ...conditionalKeys(values?.data_alterado_antes_de, { data_alterado_antes_de: format.stringDateToObject(values?.data_alterado_antes_de) }),
    ...(values?.data_solicitacao_depois_de ? {
      data_solicitacao_depois_de: format.stringDateToObject(values?.data_solicitacao_depois_de)
    } : {
      data_solicitacao_depois_de: initialSolicitacaoDatesFilter().data_solicitacao_depois_de
    }),
    ...(values?.data_solicitacao_antes_de ? {
      data_solicitacao_antes_de: format.stringDateToObject(values?.data_solicitacao_antes_de)
    } : {
      data_solicitacao_antes_de: initialSolicitacaoDatesFilter().data_solicitacao_antes_de
    }),
    ...conditionalKeys(values?.data_averbacao_antes_de, { data_averbacao_antes_de: format.stringDateToObject(values?.data_averbacao_antes_de) }),
    ...conditionalKeys(values?.data_averbacao_depois_de, { data_averbacao_depois_de: format.stringDateToObject(values?.data_averbacao_depois_de) }),
    ...conditionalKeys(values?.step, { step: format.stringDateToObject(values?.step) })
  }
}

export function nFix2 (value?: string | number): number {
  return Number(Number(value).toFixed(2)) || 0
}

export function R$ (value?: string | number): string {
  return format.formatBRL(nFix2(value))
}

export function formatSubmitSimulationForm (simulationInfo: IProposalSimulationForm): IProposalSimulationForm {
  return {
    ...simulationInfo,
    valor_solicitado: format.onlyMoney(simulationInfo.valor_solicitado) || 0
  }
}

export function formatFatoresResource (fator: ICreditFactors) {
  return {
    label: fator.prazo + 'x',
    value: fator.prazo
  }
}

export const formatSliderBRL = (value?: number) => {
  return typeof value === 'number' && format.formatBRL(value)
}

export function formatSliderInputChange (value: number | string) {
  const formatedValue: number = typeof value === 'string' ? format.formatMoneySend(value as string) : value as number || 0
  return formatedValue
}

function translateModalidade (modalidade: _EcpProposalModalidade): string {
  return ECP_MODALIDADE[modalidade] || ''
}

function formatPrazo (prazo: number) {
  return prazo === 1 ? `${prazo} mês` : `${prazo} meses`
}

function formatFirstParcel (data_primeiro_vencimento?: string): string {
  if (!data_primeiro_vencimento) return '-'

  return timeAsDayjs(data_primeiro_vencimento).isValid() ? timeAsDayjs(data_primeiro_vencimento, { applyTimezone: false }).format('DD/MM/YYYY') : '-'
}

export function formatSummary (summary: IProposalSimulationFormResponse): { label: string, value: string }[] {
  return [
    { label: 'Tipo de Empréstimo', value: translateModalidade(summary.modalidade) },
    { label: 'Convênio', value: summary?.convenio?.fantasyName ?? '-' },
    { label: 'Número de Parcelas', value: formatPrazo(summary.prazo) },
    { label: 'Data da primeira parcela', value: formatFirstParcel(summary.data_primeiro_vencimento) },
    { label: 'Valor da Parcela', value: R$(summary.valor_recebivel) },
    { label: 'Valor Solicitado', value: R$(summary.liberado) },
    { label: 'Taxas de Juros a.a', value: `${(summary.taxa_contrato_anual).toFixed(2)}%` },
    { label: 'Taxas de Juro a.m', value: `${(summary.taxa_contrato_mensal).toFixed(2)}%` },
    { label: 'Taxas Efetiva - CET a.a', value: `${(summary.cet_anual).toFixed(2)}%` },
    { label: 'Taxas Efetiva - CET a.m', value: `${(summary.cet_mensal).toFixed(2)}%` },
    { label: 'Total IOF', value: R$(summary.total_iof) },
    { label: 'Tarifa', value: R$(summary.tarifa) },
    { label: 'Seguro Mensal', value: R$(summary.seguro) }
  ]
}

export const formatToSelect = (item: IResource) => ({
  value: item._id,
  label: item.name
})

export const formatToSelectStep = (item: IResource) => ({
  value: item.slug,
  label: item.name
})

export const moneyOrNa = <T, >(value: T) => typeof value === 'number' ? format.formatBRL(value) : undefined

export const valueOrHyphen = <T, >(value: T) => value || '-'

export const valuerOrZero = <T, >(value: T) => value || '0'

export function formatPercentage (value: number | undefined): string {
  if (value === null || value === undefined) return '-'
  return `${value} %`
}

export function isElVisible (el: HTMLElement) {
  const rect = el.getBoundingClientRect()
  const elemTop = rect.top
  const elemBottom = rect.bottom

  const isVisible = elemTop >= 0 && elemBottom <= window.innerHeight
  return isVisible
}

function isValidDate (date: string | Dayjs | undefined): boolean {
  return Boolean(date && dayjs(date).isValid())
}

export function formatToFillCipForm (CipInfo: IReviewCipForm) {
  return {
    ...CipInfo,
    data_do_saldo: isValidDate(CipInfo.data_do_saldo) ? timeAsDayjs(CipInfo.data_do_saldo).format('DD/MM/YYYY') : undefined,
    ultimo_vencimento: isValidDate(CipInfo.data_do_saldo) ? timeAsDayjs(CipInfo.data_do_saldo).format('DD/MM/YYYY') : undefined,
    taxa_contrato_mes: CipInfo.taxa_contrato_mes ? Number(CipInfo.taxa_contrato_mes) : 0,
    taxa_cet_mes: CipInfo.taxa_cet_mes ? Number(CipInfo.taxa_cet_mes) : 0
  }
}

export function getPosition (onSubmit: Function) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function (position: ILocalizationContract) {
      onSubmit(position)
    },
    function (error) {
      console.log({ error })
      const messageError = error.message as keyof typeof TRANSLATE_GET_LOCATION_ERROR
      const translatedMessageError = TRANSLATE_GET_LOCATION_ERROR[messageError] || messageError
      message.warning(translatedMessageError)
    })
  } else {
    message.error('Navegador não suporta Geolocalização!')
  }
}

export function formatLocalization (localization: ILocalizationContract) {
  const { coords, timestamp } = localization || {}
  const formatedLocalization = {
    coords: {
      accuracy: coords.accuracy,
      altitude: coords.altitude,
      altitudeAccuracy: coords.altitudeAccuracy,
      heading: coords.heading,
      latitude: coords.latitude,
      longitude: coords.longitude,
      speed: coords.speed
    },
    timestamp
  }
  return formatedLocalization
}

export function daysLeftText (daysLeft: number) {
  if (!daysLeft) return '-'
  if (daysLeft === 0) return 'Hoje'
  if (daysLeft === 1) return `${daysLeft} dia`
  return `${daysLeft} dias`
}

export function redirectToProposalWithAction (id: string, state: _openStepWithAction) {
  const contractPath: IContractPath = {
    pathname: `/auth${ECP.PROPOSAL_DETAILS.path.replace(':proposalId', id)}`,
    state
  }
  return contractPath
}

export function concatClientAddress (address: IOnidataAddress): string {
  let addressString = ''

  Object.entries(address).map(([key, value], idx) => {
    if (['foto', 'residencia_tempo', 'residencia_tipo', 'pais'].includes(key)) return false
    if (key === 'cep') value = mask(value, 'cep')
    if (idx === 0) {
      addressString = value
      return true
    }

    if (value) addressString += `, ${value}`
    return true
  })
  return addressString
}

export async function generateDocumentalReport (data: IDocumentalReport) {
  const a4width = 210 * pdf.mm
  const a4height = 297 * pdf.mm
  const padding = 20
  const defaultHeaderStyle = { backgroundColor: 0x00441F, color: 0xffffff, fontSize: 13, padding: 5 }
  const defaultCellStyle = { fontSize: 10, lineHeight: 0.75, padding: 10 }
  const pdfDoc = new pdf.Document({
    font: helvetica,
    height: a4height,
    padding,
    properties: { title: `RelatorioAnaliseDocumental${data.client.cpf}` },
    width: a4width
  })

  const getLastApprove = (comments: IDocumentalReport['comments']): string => {
    if (!comments.length) return '-'

    const lastIndex = comments.length - 1
    if (lastIndex === null || lastIndex === undefined) return '-'

    const {
      name,
      label
    } = comments[lastIndex] || {}

    return `${name} - ${label}`
  }

  pdfDoc.footer().text(
    getLastApprove(data.comments), { textAlign: 'left' }
  )

  const generateSectionHeader = (text: string = '') => pdfDoc.cell(text, defaultHeaderStyle)
  const generateTableHeader = (text: string = '', section: typeof pdf.Table.prototype.header.prototype) => {
    return section.cell(text, { ...defaultHeaderStyle, borderLeftWidth: 2.5, textAlign: 'center' })
  }
  const generateRow = (key: string = '', value: string = '-', section: typeof pdf.Cell.prototype) => {
    if (!value) value = '-'
    section.text(key, { font: helveticaBold }).add(value, { font: helvetica }).br()
  }

  generateSectionHeader('Dados do cliente - Emitente')
  const clientSection = pdfDoc.cell('', defaultCellStyle)
  Object.entries(data.client).map(([key, value]) => generateRow(translate.documentalReportFields(key), mask(value, key as _inputMask), clientSection))

  generateSectionHeader('Dados da operação')
  const operationSection = pdfDoc.cell('', defaultCellStyle)
  Object.entries(data.operation).map(([key, value]) => {
    if (!value) return false
    const formattedValue = key !== 'product' ? format.formatBRL(Number(value)) : format.capitalize(value.toString())
    return generateRow(translate.documentalReportFields(key), formattedValue as any, operationSection)
  })

  ;(data.steps || []).forEach((step, idx) => {
    pdfDoc.text('Tentativa ' + (idx + 1))
    pdfDoc.cell('', { paddingBottom: 5 })

    const stepsSection = pdfDoc.table({ widths: Array(5).fill(null), borderVerticalWidth: 1, borderColor: 0xffffff })
    const stepsSectionHeader = stepsSection.header()

    generateTableHeader('Solução', stepsSectionHeader)
    generateTableHeader('Tipo', stepsSectionHeader)
    generateTableHeader('Status', stepsSectionHeader)
    generateTableHeader('Validado', stepsSectionHeader)
    generateTableHeader('Validado em', stepsSectionHeader)

    step.map((item: IDocumentalReportSteps) => {
      const stepsSectionRow = stepsSection.row()

      return Object.entries(item).map(([key, value]) => {
        if (value === null || value === undefined) return false
        const formattedValue = key === 'validated' ? translate.yesNo(value) : value
        return stepsSectionRow.cell(formattedValue?.toString(), { fontSize: 10 })
      })
    })

    pdfDoc.cell('', { padding: 10 })
  })

  if (data?.comments.length > 0) {
    generateSectionHeader('Observações')
    pdfDoc.cell('', { ...defaultCellStyle, padding: 5 })

    for (const comment of data.comments) {
      pdfDoc.text(comment.name)
      pdfDoc.text(comment.label, { color: stepProposalLabelColor(comment.label) as unknown as number })

      pdfDoc.cell('', { ...defaultCellStyle, padding: 5 })
    }
  }

  const buf = await pdfDoc.asBuffer()
  const blob = new Blob([buf], { type: 'application/pdf' })
  const url = URL.createObjectURL(blob)

  return url
}

import React from 'react'

export default function SearchSVG () {
  return (
    <svg width="100%" height="100%" viewBox="0 0 342 265" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6858_28615)">
        <path d="M194.295 129.577C201.973 129.577 208.198 123.352 208.198 115.674C208.198 107.996 201.973 101.771 194.295 101.771C186.617 101.771 180.393 107.996 180.393 115.674C180.393 123.352 186.617 129.577 194.295 129.577Z" fill="#fff"/>
        <path d="M228.368 133.539L213.839 119.011C214.953 117.279 215.707 115.34 216.058 113.311C216.409 111.282 216.349 109.203 215.882 107.198C215.415 105.192 214.55 103.301 213.338 101.635C212.127 99.9702 210.593 98.5652 208.829 97.5035C205.506 95.5186 201.559 94.854 197.77 95.6411C193.98 96.4283 190.625 98.6098 188.368 101.754C186.291 104.666 185.293 108.211 185.546 111.779C185.8 115.347 187.288 118.715 189.756 121.305C192.224 123.894 195.517 125.543 199.068 125.968C202.62 126.393 206.209 125.567 209.218 123.632L223.746 138.161C224.359 138.773 225.19 139.117 226.057 139.117C226.923 139.117 227.754 138.772 228.366 138.16C228.979 137.547 229.323 136.716 229.324 135.85C229.324 134.983 228.98 134.152 228.368 133.539ZM208.675 118.468C206.875 120.269 204.506 121.389 201.973 121.639C199.439 121.888 196.897 121.251 194.78 119.837C192.664 118.423 191.103 116.318 190.364 113.882C189.625 111.446 189.753 108.828 190.727 106.476C191.702 104.124 193.461 102.183 195.706 100.983C197.952 99.7825 200.544 99.398 203.041 99.8947C205.538 100.391 207.785 101.738 209.4 103.706C211.015 105.674 211.898 108.142 211.898 110.687C211.9 112.133 211.616 113.564 211.063 114.9C210.51 116.235 209.698 117.448 208.675 118.468Z" fill="#3D5556"/>
        <path d="M333.682 154.97C370.837 196.06 263.131 255.275 176.102 255.275C89.0733 255.275 28.8628 209.394 18.5223 154.97C-6.54978 23.0121 232.143 -60.8598 176.102 54.6653C94.4164 223.056 304.422 122.612 333.682 154.97Z" fill="#1B533F" fillOpacity="0.3"/>
        <path d="M317.012 164.695C354.167 205.784 246.461 265 159.432 265C72.4033 265 12.1929 219.118 1.85242 164.695C-23.2197 32.7367 215.473 -51.1352 159.432 64.3899C77.7465 232.781 287.752 132.336 317.012 164.695Z" fill="#244B3D"/>
        <path d="M51.9547 68.3594C25.8833 67.2115 5.66866 61.5877 5.93423 55.5562C6.09345 51.9401 13.4719 49.0323 26.1776 47.5787C26.2231 47.5732 26.2692 47.5767 26.3133 47.5891C26.3574 47.6015 26.3986 47.6225 26.4346 47.6509C26.4705 47.6792 26.5005 47.7144 26.5228 47.7545C26.5451 47.7945 26.5592 47.8385 26.5644 47.884C26.5697 47.9295 26.5658 47.9756 26.5531 48.0196C26.5404 48.0636 26.5192 48.1047 26.4906 48.1405C26.462 48.1762 26.4265 48.206 26.3864 48.228C26.3462 48.25 26.3021 48.2639 26.2565 48.2688C14.2878 49.6382 6.76662 52.4423 6.62816 55.5867C6.388 61.041 27.159 66.5724 51.9852 67.6655C76.8115 68.7586 97.9883 65.0742 98.2284 59.6199C98.3674 56.4623 91.075 52.9983 79.1968 50.5801C79.152 50.5711 79.1094 50.5534 79.0714 50.5279C79.0335 50.5024 79.001 50.4697 78.9757 50.4316C78.9504 50.3935 78.9329 50.3508 78.9242 50.306C78.9154 50.2611 78.9156 50.215 78.9247 50.1702C78.9339 50.1255 78.9517 50.0829 78.9773 50.0451C79.0029 50.0072 79.0357 49.9747 79.0739 49.9496C79.112 49.9244 79.1547 49.907 79.1996 49.8984C79.2445 49.8898 79.2906 49.8901 79.3354 49.8994C91.943 52.4662 99.0822 56.0203 98.9223 59.6505C98.6568 65.682 78.0261 69.5074 51.9547 68.3594Z" fill="#00441F"/>
        <path d="M28.7988 69.3691C30.9799 73.5124 34.2107 77.0102 38.1681 79.5126C42.1255 82.0151 46.6707 83.4344 51.3489 83.6286C56.0271 83.8227 60.6742 82.785 64.8254 80.6191C68.9766 78.4532 72.4862 75.2351 75.0031 71.2869C59.5781 71.4948 44.1535 70.8546 28.7988 69.3691Z" fill="#022612"/>
        <path d="M77.8878 63.9806C79.2004 60.0435 79.5786 55.8549 78.9925 51.7463C78.4064 47.6377 76.8721 43.7219 74.5109 40.3089C72.1498 36.8958 69.0267 34.0792 65.3887 32.0818C61.7508 30.0845 57.6979 28.9613 53.5508 28.8012C49.4037 28.641 45.2763 29.4483 41.4952 31.1591C37.714 32.87 34.383 35.4374 31.7657 38.6582C29.1484 41.879 27.3166 45.6648 26.4154 49.7159C25.5142 53.7671 25.5683 57.9724 26.5734 61.999C43.5205 64.8759 60.7693 65.542 77.8878 63.9806Z" fill="#022612"/>
        <path d="M58.6375 46.0523C60.3638 46.0523 61.7632 44.6529 61.7632 42.9265C61.7632 41.2002 60.3638 39.8008 58.6375 39.8008C56.9112 39.8008 55.5117 41.2002 55.5117 42.9265C55.5117 44.6529 56.9112 46.0523 58.6375 46.0523Z" fill="white"/>
        <path d="M42.5494 58.2063C45.4266 58.2063 47.759 55.8739 47.759 52.9967C47.759 50.1195 45.4266 47.7871 42.5494 47.7871C39.6723 47.7871 37.3398 50.1195 37.3398 52.9967C37.3398 55.8739 39.6723 58.2063 42.5494 58.2063Z" fill="white"/>
        <path d="M121.176 53.1022C123.094 53.1022 124.649 51.5473 124.649 49.6292C124.649 47.7112 123.094 46.1562 121.176 46.1562C119.258 46.1562 117.703 47.7112 117.703 49.6292C117.703 51.5473 119.258 53.1022 121.176 53.1022Z" fill="#00441F"/>
        <path d="M42.6854 159.379C43.8363 159.379 44.7693 158.446 44.7693 157.295C44.7693 156.144 43.8363 155.211 42.6854 155.211C41.5345 155.211 40.6016 156.144 40.6016 157.295C40.6016 158.446 41.5345 159.379 42.6854 159.379Z" fill="#E6E6E6"/>
        <path d="M188.555 230.924C189.705 230.924 190.638 229.991 190.638 228.84C190.638 227.689 189.705 226.756 188.555 226.756C187.404 226.756 186.471 227.689 186.471 228.84C186.471 229.991 187.404 230.924 188.555 230.924Z" fill="#E6E6E6"/>
        <path d="M52.7568 119.092C53.3322 119.092 53.7987 118.625 53.7987 118.05C53.7987 117.474 53.3322 117.008 52.7568 117.008C52.1813 117.008 51.7148 117.474 51.7148 118.05C51.7148 118.625 52.1813 119.092 52.7568 119.092Z" fill="#E6E6E6"/>
        <path d="M138.89 196.889C139.465 196.889 139.932 196.422 139.932 195.847C139.932 195.271 139.465 194.805 138.89 194.805C138.314 194.805 137.848 195.271 137.848 195.847C137.848 196.422 138.314 196.889 138.89 196.889Z" fill="#E6E6E6"/>
        <path d="M295.524 186.816C296.1 186.816 296.566 186.35 296.566 185.774C296.566 185.199 296.1 184.732 295.524 184.732C294.949 184.732 294.482 185.199 294.482 185.774C294.482 186.35 294.949 186.816 295.524 186.816Z" fill="#E6E6E6"/>
        <path d="M246.556 217.726C247.131 217.726 247.598 217.26 247.598 216.685C247.598 216.109 247.131 215.643 246.556 215.643C245.98 215.643 245.514 216.109 245.514 216.685C245.514 217.26 245.98 217.726 246.556 217.726Z" fill="#E6E6E6"/>
        <path d="M195.501 180.217C196.076 180.217 196.543 179.75 196.543 179.175C196.543 178.599 196.076 178.133 195.501 178.133C194.925 178.133 194.459 178.599 194.459 179.175C194.459 179.75 194.925 180.217 195.501 180.217Z" fill="#E6E6E6"/>
        <path d="M129.513 77.414C130.088 77.414 130.555 76.9475 130.555 76.372C130.555 75.7966 130.088 75.3301 129.513 75.3301C128.937 75.3301 128.471 75.7966 128.471 76.372C128.471 76.9475 128.937 77.414 129.513 77.414Z" fill="#E6E6E6"/>
        <path d="M137.849 26.0136C138.424 26.0136 138.891 25.5471 138.891 24.9716C138.891 24.3962 138.424 23.9297 137.849 23.9297C137.273 23.9297 136.807 24.3962 136.807 24.9716C136.807 25.5471 137.273 26.0136 137.849 26.0136Z" fill="#E6E6E6"/>
        <path d="M132.985 242.385C133.561 242.385 134.027 241.918 134.027 241.343C134.027 240.767 133.561 240.301 132.985 240.301C132.41 240.301 131.943 240.767 131.943 241.343C131.943 241.918 132.41 242.385 132.985 242.385Z" fill="#E6E6E6"/>
        <path d="M81.5849 220.504C82.1604 220.504 82.6269 220.037 82.6269 219.462C82.6269 218.886 82.1604 218.42 81.5849 218.42C81.0095 218.42 80.543 218.886 80.543 219.462C80.543 220.037 81.0095 220.504 81.5849 220.504Z" fill="#E6E6E6"/>
        <path d="M244.472 182.996C245.047 182.996 245.514 182.53 245.514 181.954C245.514 181.379 245.047 180.912 244.472 180.912C243.896 180.912 243.43 181.379 243.43 181.954C243.43 182.53 243.896 182.996 244.472 182.996Z" fill="#E6E6E6"/>
        <path d="M51.532 81.2007C53.2583 81.2007 54.6578 79.8013 54.6578 78.075C54.6578 76.3487 53.2583 74.9492 51.532 74.9492C49.8057 74.9492 48.4062 76.3487 48.4062 78.075C48.4062 79.8013 49.8057 81.2007 51.532 81.2007Z" fill="white"/>
        <path d="M122.218 126.037C122.218 135.372 107.784 157.126 103.566 163.284C103.439 163.47 103.268 163.623 103.069 163.728C102.869 163.833 102.647 163.888 102.421 163.888C102.196 163.888 101.974 163.833 101.774 163.728C101.575 163.623 101.404 163.47 101.277 163.284C97.0589 157.126 82.625 135.372 82.625 126.037C82.625 123.437 83.1371 120.863 84.1319 118.461C85.1268 116.059 86.585 113.877 88.4233 112.038C90.2615 110.2 92.4439 108.742 94.8457 107.747C97.2475 106.752 99.8218 106.24 102.421 106.24C105.021 106.24 107.595 106.752 109.997 107.747C112.399 108.742 114.581 110.2 116.42 112.038C118.258 113.877 119.716 116.059 120.711 118.461C121.706 120.863 122.218 123.437 122.218 126.037Z" fill="white"/>
        <path d="M102.422 134.72C107.984 134.72 112.493 130.211 112.493 124.648C112.493 119.086 107.984 114.576 102.422 114.576C96.859 114.576 92.3496 119.086 92.3496 124.648C92.3496 130.211 96.859 134.72 102.422 134.72Z" fill="#00441F"/>
        <path d="M102.248 174.66C108.674 174.66 113.883 173.649 113.883 172.402C113.883 171.155 108.674 170.145 102.248 170.145C95.8223 170.145 90.6133 171.155 90.6133 172.402C90.6133 173.649 95.8223 174.66 102.248 174.66Z" fill="white"/>
        <path d="M283.698 110.415C283.61 110.585 283.536 110.763 283.475 110.946L266.88 115.557L263.89 112.63L258.883 116.566L263.674 122.262C264.061 122.722 264.586 123.044 265.172 123.18C265.758 123.316 266.372 123.258 266.922 123.015L284.849 115.102C285.457 115.524 286.179 115.751 286.919 115.752C287.66 115.754 288.383 115.53 288.993 115.111C289.603 114.691 290.071 114.096 290.334 113.404C290.598 112.712 290.645 111.957 290.468 111.238C290.292 110.519 289.9 109.87 289.346 109.379C288.793 108.888 288.102 108.577 287.367 108.488C286.632 108.398 285.888 108.535 285.232 108.879C284.577 109.223 284.042 109.759 283.698 110.415Z" fill="#FFB8B8"/>
        <path d="M266.296 115.828L260.067 120.676C259.89 120.814 259.685 120.912 259.466 120.964C259.248 121.016 259.021 121.02 258.8 120.976C258.58 120.931 258.372 120.84 258.19 120.709C258.008 120.577 257.857 120.408 257.746 120.212L253.875 113.361C253.174 112.45 252.863 111.298 253.01 110.158C253.157 109.019 253.75 107.984 254.659 107.28C255.568 106.577 256.719 106.263 257.859 106.407C258.999 106.551 260.036 107.142 260.741 108.049L266.416 113.465C266.578 113.62 266.705 113.808 266.788 114.017C266.871 114.225 266.908 114.449 266.897 114.674C266.886 114.898 266.826 115.117 266.722 115.316C266.619 115.516 266.473 115.69 266.296 115.828Z" fill="#00441F"/>
        <path d="M285.29 108.122L291.606 110.964C291.871 111.083 292.077 111.302 292.18 111.573C292.283 111.844 292.274 112.145 292.155 112.41L291.407 114.072C291.495 114.112 291.564 114.185 291.598 114.275C291.632 114.366 291.629 114.466 291.59 114.554L291.291 115.219C291.251 115.307 291.178 115.376 291.088 115.41C290.997 115.444 290.897 115.441 290.809 115.402L290.51 116.067C290.598 116.107 290.666 116.18 290.701 116.27C290.735 116.361 290.732 116.461 290.693 116.549L290.393 117.214C290.353 117.302 290.28 117.37 290.19 117.405C290.1 117.439 290 117.436 289.911 117.397L285.873 126.373C285.754 126.638 285.535 126.844 285.263 126.947C284.992 127.05 284.691 127.041 284.427 126.922L278.11 124.08C277.845 123.961 277.639 123.742 277.536 123.471C277.433 123.199 277.442 122.898 277.561 122.634L283.843 108.67C283.962 108.406 284.182 108.199 284.453 108.097C284.724 107.994 285.025 108.003 285.29 108.122Z" fill="#3F3D56"/>
        <path d="M279.257 123.197L284.325 125.479C284.579 125.593 284.867 125.601 285.127 125.503C285.388 125.404 285.598 125.207 285.714 124.954L288.054 119.748L288.867 117.944L290.985 113.233C291.098 112.98 291.107 112.692 291.008 112.432C290.91 112.173 290.713 111.963 290.46 111.848L288.867 111.13L285.389 109.566C285.135 109.452 284.847 109.444 284.587 109.544C284.328 109.643 284.118 109.841 284.004 110.094L281.955 114.648L279.622 119.832L278.732 121.812C278.619 122.065 278.61 122.353 278.709 122.613C278.807 122.872 279.004 123.082 279.257 123.197Z" fill="#F2F2F2"/>
        <path d="M268.669 187.699L272.927 187.699L274.952 171.275L268.668 171.276L268.669 187.699Z" fill="#FFB8B8"/>
        <path d="M267.582 186.309L275.967 186.309C276.669 186.309 277.364 186.447 278.013 186.715C278.661 186.984 279.25 187.377 279.746 187.874C280.243 188.37 280.636 188.959 280.905 189.607C281.173 190.256 281.312 190.951 281.312 191.652V191.826L267.582 191.826L267.582 186.309Z" fill="#2F2E41"/>
        <path d="M279.782 186.658L284.04 186.658L286.066 170.234L279.781 170.235L279.782 186.658Z" fill="#FFB8B8"/>
        <path d="M278.697 185.268L287.083 185.268C287.784 185.268 288.48 185.406 289.128 185.674C289.776 185.943 290.365 186.336 290.862 186.833C291.358 187.329 291.751 187.918 292.02 188.566C292.289 189.215 292.427 189.91 292.427 190.611V190.785L278.698 190.785L278.697 185.268Z" fill="#2F2E41"/>
        <path d="M249.569 149.16C250.476 151.849 252.197 154.19 254.493 155.858C256.789 157.526 259.547 158.44 262.385 158.471L262.6 158.475C264.798 158.537 267.379 157.829 269.911 156.811C274.912 154.8 279.715 151.581 280.99 150.695L278.83 171.461L277.954 179.879C277.932 180.097 277.956 180.317 278.024 180.525C278.092 180.733 278.203 180.925 278.35 181.087C278.496 181.25 278.675 181.38 278.875 181.469C279.075 181.559 279.291 181.605 279.51 181.606H284.904C285.25 181.606 285.586 181.491 285.86 181.28C286.134 181.069 286.33 180.773 286.418 180.439L295.323 146.524C295.59 145.509 295.612 144.445 295.386 143.42C295.16 142.395 294.693 141.438 294.024 140.629C293.355 139.82 292.503 139.183 291.539 138.768C290.574 138.354 289.525 138.176 288.478 138.248L269.98 139.515L271.272 134.347L254.761 133.25L254.713 133.285C254.317 133.573 253.931 133.875 253.563 134.191C252.642 134.97 251.826 135.864 251.135 136.852C249.899 138.624 249.11 140.669 248.837 142.813C248.565 144.957 248.816 147.135 249.569 149.16Z" fill="#2E413F"/>
        <path d="M249.569 149.16C250.476 151.849 252.197 154.19 254.493 155.858C256.789 157.526 259.547 158.44 262.385 158.471C265.098 157.098 267.717 155.544 270.223 153.821L269.911 156.811L267.188 183.005C267.166 183.223 267.189 183.443 267.258 183.651C267.326 183.859 267.436 184.051 267.583 184.213C267.73 184.376 267.909 184.506 268.109 184.595C268.308 184.684 268.525 184.731 268.744 184.731H274.137C274.483 184.731 274.819 184.617 275.093 184.406C275.367 184.195 275.564 183.899 275.652 183.564L278.83 171.461L284.557 149.65C284.824 148.635 284.845 147.571 284.619 146.545C284.393 145.52 283.927 144.564 283.258 143.755C282.589 142.946 281.737 142.308 280.772 141.894C279.808 141.48 278.758 141.302 277.711 141.373L259.214 142.641L260.506 137.473L251.135 136.852C249.899 138.624 249.11 140.669 248.837 142.813C248.565 144.957 248.816 147.135 249.569 149.16Z" fill="#2E413F"/>
        <path d="M253.54 135.39L253.416 135.375L250.535 121.211C250.51 121.088 248.155 108.87 255.375 102.482L255.501 101.608C255.533 101.384 255.613 101.17 255.736 100.981C255.859 100.791 256.022 100.63 256.213 100.509C256.404 100.389 256.619 100.311 256.843 100.281C257.067 100.251 257.295 100.271 257.511 100.338L264.313 102.447C264.698 102.566 265.022 102.829 265.217 103.181C265.413 103.534 265.464 103.948 265.361 104.337L264.7 106.853C265.617 107.929 276.409 120.923 272.65 130.933L270.966 137.515L253.54 135.39Z" fill="#00441F"/>
        <path d="M281.784 124.334C281.621 124.436 281.467 124.552 281.322 124.678L264.687 120.212L263.6 116.172L257.287 117.016L258.517 124.356C258.617 124.949 258.906 125.494 259.341 125.909C259.776 126.323 260.334 126.586 260.932 126.656L280.392 128.955C280.701 129.628 281.208 130.19 281.844 130.568C282.481 130.946 283.218 131.121 283.956 131.07C284.695 131.019 285.4 130.744 285.979 130.282C286.557 129.82 286.982 129.193 287.195 128.484C287.409 127.775 287.401 127.018 287.174 126.314C286.947 125.609 286.511 124.99 285.923 124.54C285.336 124.089 284.625 123.828 283.886 123.792C283.146 123.755 282.413 123.944 281.784 124.334Z" fill="#FFB8B8"/>
        <path d="M264.046 120.148L256.218 121.156C255.995 121.185 255.769 121.165 255.554 121.099C255.34 121.032 255.142 120.92 254.975 120.77C254.808 120.62 254.675 120.436 254.585 120.23C254.495 120.024 254.451 119.801 254.455 119.577L254.605 111.709C254.464 110.568 254.782 109.418 255.488 108.512C256.194 107.605 257.231 107.015 258.371 106.872C259.511 106.728 260.661 107.043 261.57 107.746C262.479 108.45 263.071 109.485 263.218 110.625L265.351 118.173C265.412 118.389 265.426 118.616 265.391 118.838C265.356 119.06 265.275 119.272 265.151 119.459C265.027 119.647 264.864 119.805 264.674 119.924C264.483 120.043 264.269 120.119 264.046 120.148Z" fill="#00441F"/>
        <path d="M261.841 98.4139C266.552 98.4139 270.371 94.5948 270.371 89.8837C270.371 85.1726 266.552 81.3535 261.841 81.3535C257.13 81.3535 253.311 85.1726 253.311 89.8837C253.311 94.5948 257.13 98.4139 261.841 98.4139Z" fill="#FFB8B8"/>
        <path d="M261.392 90.5976L260.324 88.6528C258.301 96.8952 261.875 103.717 261.875 103.717L248.006 97.2564L248.138 94.9642L246.766 96.5215L244.794 95.4349L244.533 93.9482L243.045 94.3169L248.478 84.4578C253.751 75.0759 261.417 78.6578 261.417 78.6578C273.613 78.078 272.123 90.1104 272.123 90.1104L261.392 90.5976Z" fill="#2F2E41"/>
        <path d="M164.29 154.166C166.256 152.905 163.865 145.665 158.947 137.994C154.03 130.324 148.45 125.128 146.483 126.389C144.516 127.65 146.908 134.89 151.825 142.56C156.742 150.23 162.323 155.426 164.29 154.166Z" fill="#3D5556"/>
      </g>
      <defs>
        <clipPath id="clip0_6858_28615">
          <rect width="341.224" height="265" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  )
}

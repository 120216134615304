import { IResponseBase, Repository } from 'ecp/repositories/Repository'
import api from 'services/api'

interface IAGXReports {
  csv: string
  fileName: string
}

interface IAGXReportsData {
  reportType: string
  options: {
    startDate: string
    endDate: string
    status?: string | string[]
  }
}

class AGXReportsRepository extends Repository<IAGXReports> {
  async generate (data: IAGXReportsData): Promise<IResponseBase<IAGXReports>> {
    return Repository.handle(() =>
      this.api.post(this.path, data)
    )
  }
}

export default new AGXReportsRepository({ api, path: '/agx/reports' })

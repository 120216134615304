import React, { useState } from 'react'
import Modal from 'antd/lib/modal/Modal'
import { Link } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'
import { OverflowTooltip } from 'components/OverflowToolTip/OverflowTooltip'

export interface IBackoffice {
  email: string
  level: string
  name: string
  _id: string
  cpf: string
  areaName: string
}

function ShowAllVinculatedBackOffices ({ backoffices } : {backoffices: IBackoffice[]}) {
  const [visible, setVisible] = useState(false)

  const onVisibleChange = () => {
    setVisible((prev) => !prev)
  }

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onVisibleChange}
        destroyOnClose
        footer={null}>
        <h2 className="show-backoffices__title">BackOffices Vinculados</h2>
        <p>Confira aqui a lista com <b>todos</b> os backoffices vinculados à essa proposta</p>
        <ul className="show-backoffices__list" >
          {Array.isArray(backoffices) && backoffices?.length > 0 && backoffices?.map(backoffice => {
            return (<li key={backoffice._id}>{backoffice?.name} </li>)
          })}
        </ul>
      </Modal>

      <div>
        <OverflowTooltip width={170}>
          <div>{backoffices?.[0]?.name ?? '-'}</div>
        </OverflowTooltip>
        {backoffices?.length > 1 && <div>
          <Link to="#"
            className='link-style color-tertiary font-500 mb-0 table-name'
            onClick={onVisibleChange}
          >
            <PlusOutlined /> Ver Mais ({backoffices?.length ?? '-'})
          </Link>
        </div>}
      </div>
    </>
  )
}

export { ShowAllVinculatedBackOffices }


import { Button, Form, message, Select } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import Modal from 'antd/lib/modal/Modal'
import { _chatConfigType } from 'egi/repositories/ChatRepository'
import useHelpdeskReasons from 'hooks/useHelpdeskReasons'

import React, { useEffect } from 'react'

export interface IUserChat {
  email: string
  protocol: string
  reason: string
}

interface IProps {
  visible: boolean
  loading: boolean
  onCancel: () => void
  closeGroup: (values: { comments: string; }) => Promise<void>
}

function EndChat ({ visible, onCancel, closeGroup, loading }: IProps) {
  const { items, isLoading, error } = useHelpdeskReasons(_chatConfigType.resolution)

  useEffect(() => {
    if (error) return message.error(error)
  }, [error])

  return (
    <Modal
      closable={true}
      title='Deseja mesmo encerrar o chamado?'
      visible={visible}
      footer={null}
      onCancel={() => onCancel()}
      destroyOnClose={true}
    >
      <Form layout="vertical" onFinish={closeGroup}>
        <Form.Item label="Motivo para fechamento" name="resolution">
          <Select
            options={items}
            loading={isLoading}
            placeholder='Selecione o motivo do fechamento'
          />
        </Form.Item>

        <Form.Item
          label="Comentário"
          name="comments"
        >
          <TextArea placeholder='Adicione um comentário sobre o fechamento' />
        </Form.Item>

        <div className="generic-chat__modal-buttons">
          <Button
            className='mx-1'
            type="ghost"
            key="cancel"
            onClick={() => onCancel()}
          >
            Cancelar
          </Button>
          <Button
            loading={loading}
            htmlType="submit"
            type="primary"
            key="cancel"
            className='mx-1'
          >
            Confirmar
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default EndChat

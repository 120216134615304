import { Button } from 'antd'
import React from 'react'
import { ReactSVG } from 'react-svg'
import { varColors } from 'styles/colors'
import translate from 'utils/translate'

type props = {
  slug: string
  status?: string
  title: string
  icon?: string
  actionControl: (value: string) => void
}

function ActionButton ({ slug, status, title, icon, actionControl } : props) {
  function labelText (status?: string) {
    if (!status) return title
    if (status === 'waiting' || status === 'remaking') return `${translate.creditApprovalStatus(status)} Aceite`
    return `Crédito ${translate.creditApprovalStatus(status)}`
  }

  if (slug === 'aceitar-credito') {
    return (
      <Button
        type='primary'
        onClick={() => actionControl(slug)}
        style={{ backgroundColor: varColors[String(status)], borderColor: varColors[String(status)] }}>
        <div className="flex flex-align-center">
          <ReactSVG
            beforeInjection={(svg) => svg.classList.add('action-svgs-class')}
            className="flex flex-align-center pointer"
            alt='icone sobre cada passo do sistema uxline'
            src={icon || ''}
          />
          <label className="ml-1 pointer">{labelText(status)}</label>
        </div>
      </Button>
    )
  }

  return (
    <Button type='primary' onClick={() => actionControl(slug)}>
      <div className="flex flex-align-center">
        <ReactSVG
          beforeInjection={(svg) => svg.classList.add('action-svgs-class')}
          className="flex flex-align-center pointer"
          alt='icone sobre cada passo do sistema uxline'
          src={icon || ''}
        />
        <label className="ml-1 pointer">{title}</label>
      </div>
    </Button>
  )
}

export default ActionButton

import { AxiosRequestConfig } from 'axios'
import { IResponseBase, Repository } from './Repository'
import api from 'services/api'

class OIDCRepository extends Repository {
  async getOIDCLink (config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}`, config)
    )
  }
}

export default new OIDCRepository({ api, path: '/oidc/url' })

import React, { Fragment, lazy, Suspense, useLayoutEffect, useMemo, useState } from 'react'
import { Empty, Skeleton, Spin, Typography } from 'antd'
import { chartConfigDays, dashGreaterChartStyle } from '../MlmLeadsChartPie/chartConfig'
import { IFilterDate } from '../../views/MlmDashboard/types'
import MlmRepository, { MlmDashboardWeekly } from 'egi/repositories/MlmRepository'

const WeeklyClicks = ({ failed, loading, chartsData } :{failed: boolean, loading: boolean, chartsData:MlmDashboardWeekly[] }) => {
  const mainConfigDays = chartConfigDays(chartsData)
  const Column = lazy(() => import('@ant-design/charts/lib/column/index'))

  const totalValue = useMemo(() => {
    return chartsData.reduce((acc, item) => acc + item.value, 0)
  }, [chartsData])

  if (loading) {
    return (
      <Skeleton
        active={true}
        avatar
        paragraph={{ rows: 2 }}
      />
    )
  }

  if (failed) {
    return (
      <Empty
        description="Falha ao carregar dados."
        className="h-100 flex flex-align-center flex-column flex-justify-center"
      />
    )
  }

  return (
    <Fragment>
      <Typography.Title className="color-primary" level={5}>Cliques na semana (total: {totalValue})</Typography.Title>
      <Suspense fallback={<Spin />}>
        <Column style={dashGreaterChartStyle} {...mainConfigDays} />
      </Suspense>
    </Fragment>
  )
}

function MlmWeekly ({ filterDate }: IFilterDate) {
  const [data, setData] = useState<MlmDashboardWeekly[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)

  useLayoutEffect(() => {
    const abortController = new AbortController()
    async function fetchWeeklyChart () {
      setLoading(true)
      try {
        const response = await MlmRepository.weekly({
          signal: abortController.signal,
          params: filterDate
        })

        const { values } = response.data.data || {}
        if (!values) throw new Error('Falha ao encontrar os valores mensais')
        setData(values)
        setFailed(false)
        setLoading(false)
      } catch (err) {
        if (!abortController.signal.aborted) {
          setFailed(true)
          setLoading(false)
        }
      }
    }

    if (filterDate.createdDate && filterDate.productId) fetchWeeklyChart()
    return () => abortController.abort()
  }, [
    filterDate.createdDate,
    filterDate.productId,
    filterDate.promoterId,
    filterDate.sellerId,
    filterDate.type
  ])

  return (
    <div className="dashboard-status-card h-100">
      <WeeklyClicks
        loading={loading}
        chartsData={data}
        failed={failed}
      />
    </div>
  )
}

export default MlmWeekly

import { Col, Row } from 'antd'
import React from 'react'
import { AreaChartOutlined, DollarCircleOutlined, FileTextFilled, WalletFilled } from '@ant-design/icons'
import { ProgressCard } from '../ProgressCard/ProgressCard'
import InfoCard from '../InfoCard/InfoCard'
import { EcpCard } from 'ecp/components/EcpCard/EcpCard'
import { Table } from 'components'
import ErrorDetails from 'components/ErrorDetails/ErrorDetails'

import { IErrorDetails } from 'components/ErrorDetails/interfaces'
import { IScoreListItem, IScoreTotal } from 'ecp/app/Proposals/proposalInterfaces'
import { ColumnsType } from 'antd/lib/table'
import { moneyOrNa } from 'ecp/app/Proposals/proposalFunctions'

type Props = {
  canIformDebts: boolean
  totalScore: IScoreTotal | undefined
  scoreList?: IData[]
  loading: boolean
  error?: IErrorDetails
  redirectToScoreForm?: () => void
}

interface IData {
  createdAt: string
  template : { subject: string}
  sent?: boolean
  failCounter: number
}

const ScoreColumns : ColumnsType<IScoreListItem> = [
  {
    title: 'Divida',
    dataIndex: 'name'
  },
  {
    title: 'Credor',
    dataIndex: 'credor'
  },
  {
    title: 'N.º Parcelas',
    dataIndex: 'parcel_count'
  },
  {
    title: 'N.º Parcelas Restantes',
    dataIndex: 'remaining_parcel'
  },
  {
    title: 'Valor da parcela',
    dataIndex: 'parcel_value',
    render: value => moneyOrNa(Number(value))
  },
  {
    title: 'Valor Total',
    dataIndex: 'total',
    render: value => moneyOrNa(value)
  },
  {
    title: 'Saldo Devedor',
    dataIndex: 'debit_balance',
    render: value => moneyOrNa(Number(value))
  }
]

export default function ScoreDrawer ({ canIformDebts, totalScore, scoreList, loading, error, redirectToScoreForm } : Props) {
  if (!loading && error) {
    return (
      <EcpCard>
        <ErrorDetails status='404' title='Ops, parece que algo deu errado' subTitle={error.message} error={error}></ErrorDetails>
      </EcpCard>
    )
  }

  return (
    <div className='score mt-3'>
      <Row gutter={[15, 15]}>
        <Col lg={8} md={24} sm={24} xs={24}>
          <div style={{ height: 'calc(100% - 15px)' }}>
            <ProgressCard
              numberOfParcels={totalScore?.total_parcel}
              parcelsPaid={totalScore?.total_paid_parcel}
              percentagePaid={totalScore?.percentage ? Number(totalScore?.percentage?.toFixed(2)) : 0}
              isLoading={loading}
              canInformDebits={canIformDebts}
              handleCLick={() => redirectToScoreForm ? redirectToScoreForm() : ''}
            />
          </div>
        </Col>

        <Col lg={16} md={24} sm={24} xs={24}>
          <Row gutter={[15, 15]} className="h-100">
            <Col lg={12} md={24} sm={24} xs={24}>
              <InfoCard
                title='Total a Pagar por Mês'
                value={moneyOrNa(totalScore?.total_payment_month)}
                isLoading={loading}
                icon={
                  <AreaChartOutlined className='info-card__icon'/>
                }
              />
            </Col>

            <Col lg={12} md={24} sm={24} xs={24}>
              <InfoCard
                title='Soma Valor Total'
                value={moneyOrNa(totalScore?.total_sum)}
                isLoading={loading}
                icon={<DollarCircleOutlined className='info-card__icon'/>}
              />
            </Col>

            <Col lg={12} md={24} sm={24} xs={24}>
              <InfoCard
                title='Total de Parcelas Restantes'
                value={totalScore?.total_parcel_remaining}
                isLoading={loading}
                icon={<FileTextFilled className='info-card__icon' />}
              />
            </Col>

            <Col lg={12} md={24} sm={24} xs={24}>
              <InfoCard
                title='Saldo Devedor Total'
                value={moneyOrNa(totalScore?.total_debit_balance)}
                isLoading={loading}
                icon={<WalletFilled className='info-card__icon' />}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <h2 className='score__titles mb-4'>Dívidas</h2>

      <Table
        data={scoreList}
        columns={ScoreColumns}
        loading={loading}
      />

    </div>
  )
}

import React from 'react'
import { Form, Select } from 'antd'
import { serverTableSelectProps } from 'utils/globals'

function FilterPromoterStatus ({ onChange, defaultValue }: { onChange: (value: string | string[] | undefined) => void, defaultValue?: string[] }) {
  return (
    <Form.Item
      name="promoterStatus"
      label={<label>Status</label>}
    >
      <Select
        {...serverTableSelectProps}
        allowClear
        placeholder="Escolha o status"
        defaultValue={defaultValue}
        onChange={onChange}
      >
        <Select.Option value="starting">Iniciando</Select.Option>
        <Select.Option value="analysis">Em análise</Select.Option>
        <Select.Option value="pendencies">Com pendências</Select.Option>
        <Select.Option value="signature">Processo de Assinatura</Select.Option>
        <Select.Option value="reproved">Reprovado</Select.Option>
        <Select.Option value="approved">Aprovado</Select.Option>
        <Select.Option value="blocked">Bloqueado</Select.Option>
        <Select.Option value="canceled">Cancelado</Select.Option>
        <Select.Option value="ongoing">Em andamento</Select.Option>
        <Select.Option value="track">Esteira</Select.Option>
        <Select.Option value="remaking">Refazer</Select.Option>
      </Select>
    </Form.Item>
  )
}

export default FilterPromoterStatus

import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react'
import { Row, Col, Form, Select, InputNumber, Typography, Button } from 'antd'
import { Input } from 'components'
import { useClient, useResources, useStep } from 'hooks'
import useAddress from 'components/Address/hooks/useAddress'
import IAddress from 'egi/types/IAddress'
import { useDispatch } from 'react-redux'
import { resourcesGetCountries, resourcesGetStates } from 'store/modules/resources/actions'
import { FormInstance } from 'antd/lib/form'
import { FormListFieldData } from 'antd/lib/form/FormList'

interface IAddressProps {
  errors: any
  formRef: FormInstance
  title: string
  readOnlyInput?: boolean
  person?: string
  name?: string
  extraFields?: React.ReactNode | React.ReactElement
  field?: FormListFieldData | string
  onCepBlur: (address?: IAddress, remainingAddress?: IAddress) => void
  isProperty?: boolean
}

function Address ({ errors, readOnlyInput, formRef, name, title, field, extraFields, person, isProperty, onCepBlur }: IAddressProps) {
  const dispatch = useDispatch()

  const [address, setAddress] = useState<string | number>('address')
  const [nameErrors, setNameErrors] = useState({
    zipcode: 'zipcode',
    street: 'street',
    number: 'number',
    additional: 'additional',
    neighborhood: 'neighborhood',
    state: 'state',
    city: 'city',
    country: 'country'
  })

  const client = useClient()
  const resources = useResources()
  const step = useStep()
  const showUseUserFormData = !readOnlyInput && isProperty && client.address

  const findActualField = (field: FormListFieldData | string) => typeof field === 'object' ? field.name : field

  const {
    getAddress,
    updateCities,
    updateCountry,
    cities,
    country
  } = useAddress()

  useLayoutEffect(() => {
    function fillErrorsName () {
      if (name) {
        setAddress(name + 'Address')

        setNameErrors({
          zipcode: `${name}Zipcode`,
          street: `${name}Street`,
          number: `${name}Number`,
          additional: `${name}Additional`,
          neighborhood: `${name}Neighborhood`,
          state: `${name}State`,
          city: `${name}City`,
          country: `${name}Country`
        })
      }
    }

    fillErrorsName()
  }, [])

  async function updateAddress (cep: string, remainingAddress?: IAddress) {
    const addressValues = await getAddress({ cep, isProperty })
    onCepBlur(addressValues, remainingAddress)
  }

  const onChangeCep = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const formated = value.replace(/\D/g, '')
    const cepLength = 8

    if (formated.length === cepLength) {
      updateAddress(value)
    }
  }

  useEffect(() => {
    const { zipcode } = step?.form?.address || {}
    if (zipcode) updateAddress(zipcode, step?.form?.[address])
  }, [step?.form?.address?.zipcode])

  useEffect(() => {
    async function fillCity () {
      const myStep = step.form
      if (isProperty && myStep?.propertyAddress?.state) {
        const findUF = resources.ibgeStates.find((element) => element.label === myStep.propertyAddress?.state)
        if (findUF) updateCities(findUF.cities)
      }
    }

    fillCity()
  }, [resources.ibgeStates])

  function useUserFormData () {
    const { zipcode } = client?.address || {}
    formRef?.setFieldsValue?.({ [address]: client.address })
    if (zipcode) {
      updateAddress(zipcode, client.address)
      if (client?.address?.country) updateCountry(client?.address?.country)
    }
  }

  useLayoutEffect(() => {
    if (resources.states.length < 1) dispatch(resourcesGetStates())
    if (resources.countries.length < 1) dispatch(resourcesGetCountries())
  }, [resources.states, resources.countries])

  return (
    <Fragment>
      <Typography.Paragraph className="bold">
        {person ? `${title} do ${person}` : title}
      </Typography.Paragraph>

      {showUseUserFormData && (
        <Row>
          <Col className="px-1 pb-2" xs={24}>
            <Form.Item>
              <Button
                type='primary'
                onClick={useUserFormData}
              >
                Utilizar endereço do seu cadastro
              </Button>
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row>
        <Col className="px-1" lg={8} sm={8} xs={24}>
          <Form.Item
            // TODO: CREATE A FUNCTION WHAT MAKE A CORRECT NAME
            name={field ? [findActualField(field), address, 'zipcode'] : [address, 'zipcode']}
            label={<label>CEP:</label>}
            help={(errors?.[nameErrors?.zipcode] && (errors[nameErrors?.zipcode])) || errors?.zipcode}
            validateStatus={(errors?.[nameErrors?.zipcode] || errors?.zipcode) && ('error')}
          >
            <Input
              placeholder={person ? `Digite o cep do(a) ${person}` : 'Digite o cep'}
              mask='cep'
              error={errors?.[nameErrors?.zipcode] || errors?.zipcode}
              readOnly={readOnlyInput}
              onBlur={(e) => { e.target.value && updateAddress(e.target.value) }}
              onChange={onChangeCep}
            />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={8} sm={16} xs={24}>
          <Form.Item
            name={field ? [findActualField(field), address, 'country'] : [address, 'country']}
            label={<label>País:</label>}
            help={(errors?.[nameErrors?.country] && (errors[nameErrors?.country])) || errors?.country}
            validateStatus={(errors?.[nameErrors?.country] || errors?.country) && ('error')}
          >
            <Select
              placeholder={person ? `Digite o país do(a) ${person}` : 'Digite o país'}
              disabled={readOnlyInput}
              optionFilterProp="children"
              onChange={(e) => updateCountry(e.toString())}
              showSearch
            >
              {resources.countries.map(item =>
                <Select.Option key={item} value={item}>{item}</Select.Option>
              )}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col className="px-1" lg={18} sm={18} xs={24}>
          <Form.Item
            name={field ? [findActualField(field), address, 'street'] : [address, 'street']}
            label={<label >Logradouro:</label>}
            help={(errors?.[nameErrors?.street] && (errors[nameErrors?.street])) || errors?.street}
            validateStatus={(errors?.[nameErrors?.street] || errors?.street) && ('error')}
          >
            <Input
              readOnly={readOnlyInput}
              placeholder={person ? `Digite o logradouro do(a) ${person}` : 'Digite o logradouro'}
              error={errors?.[nameErrors?.street]}
            />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={6} sm={6} xs={24}>
          <Form.Item
            name={field ? [findActualField(field), address, 'number'] : [address, 'number']}
            label={<label>Número:</label>}
            help={(errors?.[nameErrors?.number] && (errors[nameErrors?.number])) || errors?.number}
            validateStatus={(errors?.[nameErrors?.number] || errors?.number) && ('error')}
          >
            <InputNumber
              type="number"
              min={0}
              className="w-100"
              readOnly={readOnlyInput}
              placeholder={person ? `Digite o número do(a) ${person}` : 'Digite o número'}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col className="px-1" lg={12} sm={12} xs={24}>
          <Form.Item
            name={field ? [findActualField(field), address, 'additional'] : [address, 'additional']}
            label={<label >Complemento:</label>}
            help={(errors?.[nameErrors?.additional] && (errors[nameErrors?.additional])) || errors?.additional}
            validateStatus={(errors?.[nameErrors?.additional] || errors?.additional) && ('error')}
          >
            <Input
              readOnly={readOnlyInput}
              placeholder={person ? `Digite o complemento do(a) ${person}` : 'Digite o complemento'}
              error={errors?.[nameErrors?.additional]}
            />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={12} sm={12} xs={24}>
          <Form.Item
            name={field ? [findActualField(field), address, 'neighborhood'] : [address, 'neighborhood']}
            label={<label >Bairro:</label>}
            help={(errors?.[nameErrors?.neighborhood] && (errors[nameErrors?.neighborhood])) || errors?.neighborhood}
            validateStatus={(errors?.[nameErrors?.neighborhood] || errors?.neighborhood) && ('error')}
          >
            <Input
              readOnly={readOnlyInput}
              placeholder={person ? `Digite o bairro do(a) ${person}` : 'Digite o bairro'}
              error={errors?.[nameErrors?.neighborhood]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        {country === 'Brasil' && (
          <Col className="px-1" lg={8} sm={8} xs={24}>
            <Form.Item
              name={field ? [findActualField(field), address, 'state'] : [address, 'state']}
              label={<label >UF:</label>}
              help={(errors?.[nameErrors?.state] && (errors[nameErrors?.state])) || errors?.state}
              validateStatus={(errors?.[nameErrors?.state] || errors?.state) && ('error')}
            >
              {isProperty ? (
                <Select
                  showSearch
                  placeholder={person ? `Escolha o UF do(a) ${person}` : 'Escolha o UF'}
                  aria-readonly={readOnlyInput}
                  onChange={(value) => {
                    if (resources.ibgeStates.length > 0) {
                      const findUF: any = resources.ibgeStates.find(element => element.label === value)
                      if (findUF) updateCities(findUF.cities)
                    }
                  }}
                  disabled={readOnlyInput}
                  optionFilterProp="children"
                >
                  {resources.ibgeStates.map(item => (
                    <Fragment key={item.value}>
                      <Select.Option value={item.label}>{item.label}</Select.Option>
                    </Fragment>
                  ))}
                </Select>
              ) : (
                <Select
                  showSearch
                  placeholder={person ? `Escolha o UF do(a) ${person}` : 'Escolha o UF'}
                  aria-readonly={readOnlyInput}
                  disabled={readOnlyInput}
                  optionFilterProp="children">
                  {resources.states.map(state =>
                    <Select.Option key={state} value={state}>{state}</Select.Option>
                  )}
                </Select>
              )}
            </Form.Item>
          </Col>
        )}

        <Col className="px-1" lg={16} sm={16} xs={24}>
          <Form.Item
            name={field ? [findActualField(field), address, 'city'] : [address, 'city']}
            label={<label >Município:</label>}
            help={(errors?.[nameErrors?.city] && (errors[nameErrors.city])) || errors?.city}
            validateStatus={(errors?.[nameErrors?.city] || errors?.city) && ('error')}
          >
            {(isProperty && country === 'Brasil') ? (
              <Select
                showSearch
                placeholder={person ? `Digite o município do(a) ${person}` : 'Digite o município'}
                aria-readonly={readOnlyInput}
                disabled={readOnlyInput}
                optionFilterProp="children">
                {cities.map(state =>
                  <Select.Option
                    key={state.value}
                    value={state.label}
                  >
                    {state.label}
                  </Select.Option>
                )}
              </Select>
            ) : (
              <Input
                readOnly={readOnlyInput}
                placeholder={person ? `Digite o município do(a) ${person}` : 'Digite o município'}
                error={errors?.[nameErrors?.city] || errors?.city}
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      {extraFields}
    </Fragment>
  )
}

export default Address


import ProposalHistoryButton from 'ecp/app/Proposals/components/ProposalHistoryButton/ProposalHistoryButton'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { renderBadge } from 'utils/renderBadge'
import useThisStep from '../../hooks/useThisStep'
import { TRANSLATE_REPROVED_APPROVED_STATUS } from '../../proposalConstants'
import { isElVisible } from '../../proposalFunctions'
import ProposalHistory from '../../views/ProposalHistory/ProposalHistory'

interface IProps {
  children: ReactNode
}

function ProposalStepButtonWithHistory ({ children }: IProps) {
  const el = useRef<HTMLDivElement>(null)
  const { stepHistory, step } = useThisStep()

  const [isCollapsed, setIsCollapsed] = useState(true)

  useEffect(() => {
    if (!el.current) return
    const isHistoryOutOfView = !isElVisible(el.current)
    if (isHistoryOutOfView) el.current.scrollIntoView({ block: 'nearest' })
  }, [isCollapsed])

  const hashistory = stepHistory?.length

  return (
    <div className='proposal-step-button-with-history'>
      <div className='proposal-step-button-with-history__content'>
        <span className='proposal-step-button-with-history__children'>
          {children}
        </span>

        {stepHistory && !!hashistory && (
          <span className='proposal-step-button-with-history__button'>
            <ProposalHistoryButton
              onClick={() => setIsCollapsed(prev => !prev)}
            />
          </span>
        )}

        {step.label &&
          renderBadge(
            TRANSLATE_REPROVED_APPROVED_STATUS?.[step.label as keyof typeof TRANSLATE_REPROVED_APPROVED_STATUS],
            'proposalStepLabel'
          )
        }
      </div>

      {!isCollapsed && (
        <div
          ref={el}
          className='proposal-step-button-with-history__container'
        >
          <ProposalHistory historyList={stepHistory} />
        </div>
      )}
    </div>
  )
}

export default ProposalStepButtonWithHistory

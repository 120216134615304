import React, { useState } from 'react'
import { Form, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import TokenVerification from 'components/TokenVerification/TokenVerification'
import { IError } from 'ecp/app/Proposals/proposalInterfaces'

import SignupLayout from 'layouts/SignupLayout/SignupLayout'
import { handleOnidataErrors } from 'utils/validate'
import TokenHeader from 'ecp/components/TokenHeader/TokenHeader'
import { useHistory, useParams } from 'react-router-dom'
import { UNAUTHS_PATHS } from 'routes/unauthRoutes'
import { ISignupClienttoken_confirmacaoForm } from 'ecp/app/SignUpClient/signUpClientInterfaces'
import useFirstAccessTokenInfo from '../../hooks/useFirstAccessTokenInfo'
import { IFirstAccessConfirmEmail, IFirstAccessTokenInfo } from '../../firstAccesInterfaces'
import FirstAccesResendToken from '../../components/FirstAccesResendToken/FirstAccesResendToken'
import LoginRepository from 'ecp/repositories/LoginRepository'
import SignupButton from 'egi/app/Signup/components/SignupButton/SignupButton'
import { swalError } from 'components/SwalError/SwalError'

export default function FirstAccessClientEmailToken () {
  const [form] = useForm<ISignupClienttoken_confirmacaoForm>()
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [errors, setErrors] = useState<IError<ISignupClienttoken_confirmacaoForm>>({})
  const { tokenJwt } = useParams<{ tokenJwt?: string }>()

  const { tokenInfo } = useFirstAccessTokenInfo()
  const history = useHistory()

  function redirectToNextStep (token: string, tokenInfo: IFirstAccessTokenInfo) {
    history.push({
      pathname: `${UNAUTHS_PATHS.FIRST_ACCESS_CLIENT_PHONE.replace(':tokenJwt', String(token))}`,
      state: tokenInfo
    })
  }

  function handleSubmit (token: string) {
    return async function (values: ISignupClienttoken_confirmacaoForm) {
      setLoadingSubmit(true)

      const data: IFirstAccessConfirmEmail = {
        token_confirmacao: values.token_confirmacao,
        onidataToken: token,
        email: tokenInfo.email
      }

      try {
        const response = await LoginRepository.confirmEmail(data)
        message.success(response?.data?.message)
        redirectToNextStep(token, tokenInfo)
      } catch (err) {
        swalError({ err })
        if (err?.data?.error) setErrors(handleOnidataErrors(err.data?.error))
      } finally {
        setLoadingSubmit(false)
      }
    }
  }

  return (
    <SignupLayout>

      <TokenHeader
        title='Token enviado para seu email'
        infoText={
          <p className='ecp-token-header__info-text'>Confirme seu email a partir do token recebido no seu email <b>{tokenInfo.email}</b></p>
        }
        className='mb-5'
      />
      <Form
        onFinish={handleSubmit(String(tokenJwt))}
        form={form}
        layout='vertical'
        className='unauth-form'
      >
        <Form.Item
          name='token_confirmacao'
          label={<span className='simulator-label'>Digite o token</span>}
          help={errors?.token_confirmacao}
          validateStatus={errors?.token_confirmacao && ('error')}
          rules={[
            {
              required: true,
              message: 'Adicione o token.'
            }
          ]}
        >
          <TokenVerification lenght={6}/>
        </Form.Item>
        <Form.Item>
          <SignupButton
            loading={loadingSubmit}
            htmlType='submit'
            className='w-100'
          >
            Enviar
          </SignupButton>
        </Form.Item>
      </Form>

      {tokenInfo.email &&
        <div className='flex flex-justify-center'>
          <FirstAccesResendToken
            value={{
              tipo: 'ativacao-email',
              onidataToken: tokenJwt
            }}
            sendMethod='email'
          />
        </div>
      }
    </SignupLayout>
  )
}

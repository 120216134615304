import React from 'react'
import { Form, Select } from 'antd'

export type FilterPersonProps = {
    onChange: Function
}

function FilterPerson ({ onChange }: FilterPersonProps) {
  return (
    <Form.Item
      name='clientType'
      key='clientType'
      label={<label>Tipo Pessoa</label>}
    >
      <Select
        allowClear
        className="w-100"
        onChange={(value: any) => {
          onChange(value)
        }}
        placeholder='Selecione o tipo de pessoa'
      >
        <Select.Option value="">Todos</Select.Option>
        <Select.Option value="fisica">Física</Select.Option>
        <Select.Option value="juridica">Jurídica</Select.Option>
      </Select>
    </Form.Item>
  )
}

export default FilterPerson

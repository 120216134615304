import { IDrawer } from 'egi/types'

const INITIALSTATE: IDrawer = {
  title: '',
  type: '',
  status: '',
  width: '30vw',
  submitButton: true,
  visible: false,
  isLoading: false,
  formRef: undefined,
  updatedTableFn: undefined,
  lead: {
    id: '',
    status: '',
    isSubmit: false
  },
  contact: {
    applicant: '',
    product: '',
    message: '',
    client: {
      id: '',
      name: '',
      cpf: '',
      email: '',
      number: ''
    }
  }
}

const drawer = (state = INITIALSTATE, action: any) => {
  switch (action.type) {
    case 'DRAWER_FILL_FORMS': {
      return {
        ...state,
        ...action.payload
      }
    }

    case 'DRAWER_MANAGE_VISIBLE': {
      return {
        ...state,
        visible: action.payload.visible
      }
    }

    case 'DRAWER_MANAGE_FORM_REF': {
      return {
        ...state,
        formRef: action.payload.ref
      }
    }

    case 'DRAWER_LEAD_STATUS_UPDATE': {
      return {
        ...state,
        lead: {
          ...state.lead,
          status: action.payload.newStatus,
          isSubmit: action.payload.isSubmit
        }
      }
    }

    case 'DRAWER_BUTTON_IS_LOADING': {
      return {
        ...state,
        status: action.payload.isLoading ? 'sending' : ''
      }
    }

    case 'RESET_DRAWER': {
      return {
        ...INITIALSTATE
      }
    }

    default:
      return state
  }
}

export default drawer

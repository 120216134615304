import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import React, { ReactNode } from 'react'

type Props = {
  children: ReactNode
  className?: string
} & ButtonProps
export default function SignupButton ({ children, className, ...rest }: Props) {
  return (
    <Button
      className={`signup-button ${className || ''}`}
      type="primary"
      {...rest}
    >
      {children}
    </Button>
  )
}

import owasp from 'owasp-password-strength-test'

owasp.config({
  allowPassphrases: true,
  maxLength: 128,
  minLength: 11,
  minPhraseLength: 20,
  minOptionalTestsToPass: 4
})

export function owaspVerify (password: string) {
  if (!password) return false
  if (owasp.test(password).errors.length < 1) return true

  return false
}

import React, { Fragment, useLayoutEffect, useRef, useState } from 'react'
import { Empty, Form, Spin, Tabs, Timeline } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { Input } from 'components'
import { useAuth, useDrawer } from 'hooks'
import { useDispatch } from 'react-redux'
import { drawerFormRefSubmit } from 'store/modules/drawer/actions'
import IDrawer from 'egi/types/IDrawer'
import { SelectCategoryDrawer } from './SelectCategoryDrawer'
import swal from 'utils/swal'
import { consultantNumber } from 'utils/globals'
import { timeAsDayjs } from 'utils/time'
import translate from 'utils/translate'
import { IErrors, IContact } from './types'
import { ILabelValue } from 'store/modules/resources/types'
import LeadsRepository, { ILeadHistory } from 'egi/repositories/LeadsRepository'
import ContactModel from 'egi/models/ContactModel'
import ProposalEgiRepository from 'egi/repositories/ProposalEgiRepository'

function ContactDrawer () {
  const dispatch = useDispatch()
  const [errors, setErrors] = useState<IErrors>({})
  const [category, setCategory] = useState<ILabelValue>()
  const [chatHistory, setChatHistory] = useState<ILeadHistory[]>()
  const [tabType, setTabType] = useState('chat')
  const [loading, setLoading] = useState<boolean>(false)

  const user = useAuth()
  const formRef = useRef<any>(null)
  const drawer: IDrawer = useDrawer()

  const isChat = tabType === 'chat'
  const isHistory = tabType === 'chatHistory'
  const cannotSeeHistorico = !ContactModel.canSeeHistory(user.level)
  const proposalId = String(drawer.contact?.proposalId)
  const leadId = String(drawer.lead?.id)

  const contactClient = async (values: IContact) => {
    const data: IContact = {
      message: values.message,
      type: values.type
    }

    try {
      if (!drawer.lead?.id) {
        const response = await ProposalEgiRepository.chatContact(proposalId, data)
        formRef.current.resetFields(['message'])
        return response
      } else {
        const response = await LeadsRepository.chatContact(leadId, data)
        formRef.current.resetFields(['message'])
        return response
      }
    } catch (err) {
      swal.basic({ icon: 'warning', text: err.message, title: 'Atenção' })
    }
  }

  function sendMessageWhatsapp (textAreaValue: string) {
    const { contact, type }: IDrawer = drawer

    if (((!category || !textAreaValue) && user.level === 'client') || !textAreaValue) {
      setErrors({
        category: !category ? 'Favor selecione uma categoria' : '',
        message: !textAreaValue ? 'Favor escreva uma descrição' : ''
      })
      return
    }

    if ((ContactModel.isClientContact(user.level, type))) {
      window.open(`https://api.whatsapp.com/send?1=pt_br&phone=${consultantNumber}&text=CPF%3A%20%2A${contact?.client.cpf}%2A%0ANome%20requerente%3A%20%2A${contact?.client.name}%2A%0AEmail%3A%20%2A${contact?.client.email}%2A%0ACategoria:%20%2A${category}%2A%0A%0AMensagem%3A%20${textAreaValue}`)
    }

    if (ContactModel.isNotClientWithClientId(user.level, type, contact?.client.id)) {
      contactClient({ type: 'whatsapp', message: textAreaValue })
      window.open(`https://api.whatsapp.com/send?1=pt_br&phone=55${contact?.client.number}&text=Cliente%3A%20%2A${contact?.client.name}%2A${contact?.product ? `%0AProduto%3A%20%2A${contact.product}` : ''}%2A%0ANúmero%20da%20proposta%20%2A${contact?.client.id}%2A%0A%0ANome%20do%20backoffice%3A%20%2A${user.name}%2A%0A%0AMensagem%3A%20${textAreaValue}`)
    }

    if (ContactModel.isNotClientWithOutClientId(user.level, type, contact?.client.id)) {
      contactClient({ type: 'whatsapp', message: textAreaValue })
      window.open(`https://api.whatsapp.com/send?1=pt_br&phone=55${contact?.client.number}&text=Cliente%3A%20%2A${contact?.client.name}%2A%0A%0ANome%20do%20backoffice%3A%20%2A${user.name}%2A%0A%0AMensagem%3A%20${textAreaValue}`)
    }
  }

  function finishContact (values: any) {
    sendMessageWhatsapp(values.message)
  }

  const resetFields = () => {
    if (formRef && formRef.current) {
      formRef?.current.resetFields()
    }
  }

  useLayoutEffect(() => {
    function fillData () {
      const { contact: { applicant, client, product } = {} } = drawer
      const { name, cpf, email } = client || {}

      if (formRef && formRef.current) {
        formRef?.current.setFieldsValue({ applicant, cpf, email, clientName: name, product })
      }
    }

    fillData()

    return () => {
      resetFields()
    }
  }, [drawer.visible])

  useLayoutEffect(() => {
    async function chatHistory () {
      setLoading(true)
      try {
        if (!drawer.lead?.id) {
          const response = await ProposalEgiRepository.chatHistory(proposalId)
          const contactsHistory = response?.data.data.contacts
          setChatHistory(contactsHistory)
          setLoading(false)
          return response
        } else {
          const response = await LeadsRepository.chatHistory(leadId)
          const { contacts } = response.data.data || {}

          setLoading(false)
          setChatHistory(contacts)

          return response
        }
      } catch (err) {
        setLoading(false)
        swal.basic({ icon: 'warning', text: err.message, title: 'Atenção' })
      }
    }

    if (isHistory && !drawer.lead) chatHistory()
    if (isHistory && drawer.lead) chatHistory()
  }, [isHistory])

  useLayoutEffect(() => {
    dispatch(drawerFormRefSubmit(formRef))
  }, [])

  const { contact, type } = drawer

  return (
    <Form
      ref={formRef}
      onFinish={finishContact}
      layout="vertical"
    >
      <Tabs defaultActiveKey="1" type="card" className="tab-contract" onChange={key => setTabType(key)}>
        <Tabs.TabPane tab="Chat" key="chat" />
        {!cannotSeeHistorico && (<Tabs.TabPane tab="Histórico" key="chatHistory" />)}
      </Tabs>

      <Fragment>
        {isChat && (
          <Fragment>
            {ContactModel.canSeeApplicantField(user.level) && (
              <Form.Item
                name="applicant"
                label={<label>Nome requerente</label>}
                className="mt-2"
              >
                <Input
                  disabled={true}
                  placeholder="Digite o nome do requerente"
                />
              </Form.Item>
            )}

            {contact?.client?.cpf && (
              <Form.Item
                name="cpf"
                label={<label>CPF</label>}
              >
                <Input
                  disabled={true}
                  mask="cpf"
                  placeholder="Digite cpf"
                />
              </Form.Item>
            )}

            {contact?.client?.email && (
              <Form.Item
                name="email"
                label={<label>Email</label>}
              >
                <Input
                  disabled={true}
                  placeholder="Digite o email"
                />
              </Form.Item>
            )}

            {contact?.product && (
              <Form.Item
                name="product"
                label={<label>Produto</label>}
              >
                <Input
                  disabled={true}
                  placeholder="Escolha seu produto"
                />
              </Form.Item>
            )}

            {contact?.client?.name && (
              <Form.Item
                name="clientName"
                label={<label>Nome cliente</label>}
              >
                <Input
                  disabled={true}
                  placeholder="Nome do cliente"
                />
              </Form.Item>
            )}

            { ContactModel.canSeeSelectCategoryDrawer(user.level, type) && (
              <SelectCategoryDrawer
                userLevel={user.level}
                category={category}
                errors={errors}
                setCategory={setCategory}
              />
            )}

            <Form.Item
              name="message"
              help={errors.message && (errors.message)}
              validateStatus={errors.message ? 'error' : ''}
              label={<label>Mensagem: </label>}
            >
              <TextArea
                rows={5}
                style={{ borderRadius: 5 }}
                placeholder="Sua mensagem"
              />
            </Form.Item>
          </Fragment>
        )}

        { isHistory && (
          <Spin spinning={loading}>
            <Timeline mode="alternate" className="lead-history-item-tail mt-6" >
              {!chatHistory?.length && (
                <Empty description="Nenhum histórico encontrado." />
              )}

              {chatHistory?.length && chatHistory.map((item) => (
                <Timeline.Item
                  key={item._id}
                  color="#25d366"
                >
                  <div>
                    <h3 style={{ borderBottom: '2px solid #242424', display: 'inline-block' }}>{timeAsDayjs(item?.updatedAt).format('DD/MM/YYYY HH:mm')} - {item.issuer?.name}</h3>
                    <h4>Enviada por: {item.issuer.name} - <b>{translate.level(item.issuer.level)}</b></h4>
                    <p>{item?.message}</p>
                  </div>
                </Timeline.Item>
              ))}
            </Timeline>
          </Spin>
        )}
      </Fragment>

    </Form>
  )
}

export default ContactDrawer

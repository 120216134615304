import React, { Fragment } from 'react'
import { Col, Form } from 'antd'
import { Input } from 'components'
import locale from 'antd/es/date-picker/locale/pt_BR'
import Datepicker from 'components/Datepicker/Datepicker'
import { JuridicalFormProps } from '../../signupInterfaces'

function JuridicalForm ({ errors, companyName }: JuridicalFormProps & { companyName: string }) {
  return (
    <Fragment>
      <Col lg={24} sm={24} xs={24}>
        <Form.Item
          name={companyName}
          label={<label className="simulator-label">Nome da empresa</label>}
          help={errors.name && errors.name}
          validateStatus={errors.name && 'error'}
        >
          <Input
            className="unauth-inputs"
            placeholder="Digite o nome da empresa"
          />
        </Form.Item>
      </Col>

      <Col lg={24} sm={24} xs={24}>
        <Form.Item
          name="fantasyName"
          label={<label className="simulator-label">Razão Social</label>}
          help={errors.fantasyName && (errors.fantasyName)}
          validateStatus={errors.fantasyName && ('error')}
        >
          <Input
            className="unauth-inputs"
            placeholder="Digite a razão social"
          />
        </Form.Item>
      </Col>

      <Col lg={12} sm={24} xs={24}>
        <Form.Item
          name="cnpj"
          label={<label className="simulator-label">CNPJ</label>}
          help={errors.cnpj && errors.cnpj}
          validateStatus={errors.cnpj && 'error'}
        >
          <Input
            className="unauth-inputs"
            placeholder="Digite o cnpj"
            mask="cnpj"
          />
        </Form.Item>
      </Col>

      <Col lg={12} sm={24} xs={24}>
        <Form.Item
          name="foundationDate"
          label={<label className="simulator-label">Data de fundação</label>}
          help={errors.foundationDate && (errors.foundationDate)}
          validateStatus={errors.foundationDate && ('error')}
        >
          <Datepicker
            className="unauth-inputs"
            locale={locale}
          />
        </Form.Item>
      </Col>
    </Fragment>
  )
}

export default JuridicalForm

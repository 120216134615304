import React, { Fragment, useState } from 'react'
import UserTable from 'components/UserTable/UserTable'
import TelemarketingListColumns from 'components/UserTable/usersColumns/TelemarketingColumns'
import { useDispatch } from 'react-redux'
import swal from 'utils/swal'
import { tablesSetFiltersValues, tablesUpdate } from 'store/modules/tables/actions'
import ButtonModel from 'components/SeverTable/ButtonModel'
import translate from 'utils/translate'
import FilterSwitch from 'components/SeverTable/FilterSwitch'
import { useTables } from 'hooks'
import { ModalInvite } from 'components'
import UsersEgiRepository from 'egi/repositories/UsersEgiRepository'

function TelemarketingList () {
  const [visible, setVisible] = useState<boolean>(false)

  const dispatch = useDispatch()
  const tables = useTables()

  async function blockUser (id: string) {
    try {
      const response = await UsersEgiRepository.block(id)
      dispatch(tablesUpdate())
      swal.basic({ title: 'Sucesso!', text: response.data.message, icon: 'success' })
    } catch (err) {
      swal.basic({ title: 'Atenção!', text: err.message, icon: 'warning' })
    }
  }

  const buttons = [
    <ButtonModel
      onClick={() => setVisible(true)}
      key={0}
      text={`Convidar usuário ${translate.level('promoter')}`}
    />,
    <ButtonModel
      onClick={() => dispatch(tablesSetFiltersValues({ ...tables.filters, download: true }))}
      key={1}
      text={`Relatório de ${translate.level('promoter')}`}
    />
  ]

  const filters = [
    <FilterSwitch
      placeholder="Email verificado:"
      name="hasEmailVerified"
      key='hasEmailVerified'
      onChange={(value) => {
        if (value) dispatch(tablesSetFiltersValues({ ...tables.filters, hasEmailVerified: true }))
        else dispatch(tablesSetFiltersValues({ ...tables.filters, hasEmailVerified: undefined }))
      }}
    />
  ]

  return (
    <Fragment>
      <ModalInvite
        visible={visible}
        level="promoter"
        canChooseType={false}
        specificType={['TM']}
        invitedLevel={translate.level('promoter')}
        area="telemarketing"
        onClose={() => setVisible(false)}
      />

      <UserTable
        url="/telemarketing?areaName=telemarketing"
        buttons={buttons}
        fileName="telemarketing"
        filters={filters}
        columns={TelemarketingListColumns({
          blockUser
        })}
      />
    </Fragment>
  )
}

export default TelemarketingList

import React, { useState } from 'react'
import UserTable from 'components/UserTable/UserTable'
import PromoterColumns from 'components/UserTable/usersColumns/PromoterColumns'
import { useDispatch } from 'react-redux'
import { tablesSetFiltersValues, tablesUpdate } from 'store/modules/tables/actions'
import swal from 'utils/swal'
import ModalChangeCommission from 'egi/app/Promoter/components/ModalChangeCommission/ModalChangeCommission'
import { ModalInvite } from 'components'
import ButtonModel from 'components/SeverTable/ButtonModel'
import translate from 'utils/translate'
import { useTables } from 'hooks'
import DrawerApproveDocuments from 'egi/app/Promoter/components/DrawerApproveDocuments/DrawerApproveDocuments'
import ChangeChannel from 'egi/app/Promoter/components/ChangeChannel/ChangeChannel'
import ChangeBusinessManager from 'egi/app/Promoter/components/ChangeBusinessManager/ChangeBusinessManager'
import FilterSwitch from 'components/SeverTable/FilterSwitch'
import FilterPromoterType from 'components/SeverTable/FilterPromoterType'
import { IPromoterListProps, _promoterListActions } from 'egi/app/Promoter/promoterInterfaces'
import FilterPromoterStatus from 'components/SeverTable/FilterPromoterStatus'
import FilterPromoterChannel from 'components/SeverTable/FilterPromoterChannel'
import FilterCurrentAction from 'components/SeverTable/FilterCurrentAction'
import FilterBusinessManager from 'components/SeverTable/FilterBusinessManager'
import { filterValueFormatter } from 'utils/utils'
import UsersEgiRepository from 'egi/repositories/UsersEgiRepository'

export interface IPromoterListAction {
  type: 'commission' | 'approve-documents' | 'change-channel' | 'change-manager'
  info?: Object
}

function PromoterList ({ viewerLevel, specificType, canInvite, canGenerateReport }: IPromoterListProps) {
  const [visible, setVisible] = useState<_promoterListActions>(undefined)
  const [promoterInfo, setPromoterInfo] = useState<any>()

  const dispatch = useDispatch()
  const tables = useTables()

  async function blockUser (id: string) {
    try {
      const response = await UsersEgiRepository.block(id)
      dispatch(tablesUpdate())
      swal.basic({ title: 'Sucesso!', text: response.data.message, icon: 'success' })
    } catch (err) {
      swal.basic({ title: 'Atenção!', text: err.message, icon: 'warning' })
    }
  }

  function action ({ info, type }: IPromoterListAction) {
    setVisible(type)
    setPromoterInfo(info)
  }

  const filters = [
    <FilterSwitch
      placeholder="Email verificado:"
      name="hasEmailVerified"
      key='hasEmailVerified'
      onChange={(value) => {
        if (value) dispatch(tablesSetFiltersValues({ ...tables.filters, hasEmailVerified: true }))
        else dispatch(tablesSetFiltersValues({ ...tables.filters, hasEmailVerified: undefined }))
      }}
    />,
    ...(!specificType?.includes('IN') ? [
      <FilterPromoterType
        key='promoterType'
        callback={(value: string) => {
          if (value) dispatch(tablesSetFiltersValues({ ...tables.filters, promoterType: value }))
          else dispatch(tablesSetFiltersValues({ ...tables.filters, promoterType: undefined }))
        }}
      />,
      <FilterPromoterStatus
        key='promoterStatus'
        onChange={(value) => {
          dispatch(tablesSetFiltersValues({ ...tables.filters, promoterStatus: filterValueFormatter(value) }))
        }}
      />,
      <FilterPromoterChannel
        key='promoterChannel'
        onChange={(value) => {
          dispatch(tablesSetFiltersValues({ ...tables.filters, promoterChannel: filterValueFormatter(value) }))
        }}
      />,
      <FilterCurrentAction
        key='promoterChannel'
        onChange={(value) => {
          dispatch(tablesSetFiltersValues({ ...tables.filters, promoterCurrentAction: filterValueFormatter(value) }))
        }}
      />,
      <FilterBusinessManager
        key='promoterChannel'
        onChange={(value) => {
          dispatch(tablesSetFiltersValues({ ...tables.filters, promoterBusinessManager: filterValueFormatter(value) }))
        }}
      />
    ] : [])
  ]

  const buttons = [
    ...(canInvite ? [
      <ButtonModel
        onClick={() => setVisible('invite')}
        key={0}
        text={`Convidar usuário ${translate.level('promoter')}`}
      />
    ] : []),
    ...(canGenerateReport ? [
      <ButtonModel
        onClick={() => dispatch(tablesSetFiltersValues({ ...tables.filters, download: true }))}
        key={1}
        text={`Relatório de ${translate.level('promoter')}`}
      />
    ] : [])
  ]

  return (
    <div className="promoter-list">
      <ModalInvite
        visible={visible === 'invite'}
        level="promoter"
        invitedLevel={translate.level('promoter')}
        canChooseType={true}
        onClose={() => setVisible(undefined)}
        specificType={specificType}
      />

      <ModalChangeCommission
        visible={visible === 'commission'}
        onClose={() => setVisible(undefined)}
        promoterCommission={promoterInfo?.commissionPercentage}
        promoterType={promoterInfo?.promoterType}
        promoterId={promoterInfo?.id}
      />

      <ChangeBusinessManager
        visible={visible === 'change-manager'}
        onClose={() => setVisible(undefined)}
        currentBusinessManager={promoterInfo?.currentBusinessManager}
        id={promoterInfo?.id}
      />

      <ChangeChannel
        visible={visible === 'change-channel'}
        onClose={() => setVisible(undefined)}
        currentChannel={promoterInfo?.currentChannel}
        id={promoterInfo?.id}
      />

      <DrawerApproveDocuments
        visible={visible === 'approve-documents'}
        onClose={() => setVisible(undefined)}
      />

      <UserTable
        url="/promoters"
        fileName="parceiros"
        filters={filters}
        buttons={buttons}
        customParams={{ promoterType: specificType?.toString() }}
        columns={PromoterColumns({
          blockUser,
          action,
          viewerLevel
        })}
      />
    </div>
  )
}

export default PromoterList

import ResourcesRepository from 'egi/repositories/ResourcesRepository'
import { ISelect } from 'egi/types'
import { useState } from 'react'
import format from 'utils/format'
import translate from 'utils/translate'

export interface IStepIdentification {name: string, _id: string, slug: string}

function useResources () {
  const [resourcesError, setResourcesError] = useState<{[key: string]: string}>({})
  const [departments, setDepartments] = useState<ISelect[]>()
  const [areasNames, setAreasName] = useState<ISelect[]>([])
  const [steps, setSteps] = useState<IStepIdentification[]>()
  const [loadingResources, setLoadingResources] = useState<{[key: string]: boolean}>({})

  const [formattedSteps, setFormattedSteps] = useState<ISelect[]>()

  const getDepartments = async () => {
    try {
      setLoadingResources(prev => ({ ...prev, departments: true }))
      const response = await ResourcesRepository.departments()
      const departmentAreas = response?.data?.data.departments[0]?.areas
      const formattedDepartments = departmentAreas.map((department: {name: string, _id: string}) => {
        return {
          label: format.capitalize(department.name),
          value: department._id
        }
      })

      setDepartments(formattedDepartments)
    } catch (err) {
      setResourcesError(prev => ({ ...prev, departments: err.message }))
    } finally {
      setLoadingResources(prev => ({ ...prev, departments: false }))
    }
  }

  const getAreasName = async () => {
    try {
      setLoadingResources(prev => ({ ...prev, departments: true }))
      const response = await ResourcesRepository.areaNames()

      const areaNames = response?.data?.data.areaNames
      const formattedAreas = areaNames.map((area: string) => {
        return {
          label: translate.areasNames(area),
          value: area
        }
      })

      setAreasName(formattedAreas)
    } catch (err) {
      setResourcesError(prev => ({ ...prev, areasName: err.message }))
    } finally {
      setLoadingResources(prev => ({ ...prev, areasName: false }))
    }
  }

  const getSteps = async (department: string) => {
    setLoadingResources(prev => ({ ...prev, steps: true }))
    try {
      const params = {
        departmentId: department
      }
      const response = await ResourcesRepository.steps({ params })
      const aggregateSteps = response.data.data.aggregateStepsByDepartment[0].steps
      const formattedSteps = aggregateSteps.map((step: {name: string, _id: string}) => {
        return {
          label: format.capitalize(step?.name),
          value: step?._id
        }
      })
      setSteps(aggregateSteps)
      setFormattedSteps(formattedSteps)
    } catch (err) {
      setResourcesError(prev => ({ ...prev, steps: err.message }))
    } finally {
      setLoadingResources(prev => ({ ...prev, steps: false }))
    }
  }

  return {
    getDepartments,
    departments,
    steps,
    getSteps,
    resourcesError,
    loadingResources,
    formattedSteps,
    getAreasName,
    areasNames
  }
}

export default useResources

import { useEffect, useState } from 'react'
import { ISelect } from 'egi/types'
import ChatRepository, { _chatConfigType } from 'egi/repositories/ChatRepository'

function useHelpdeskReasons (configType: _chatConfigType) {
  const [items, setItems] = useState<Array<ISelect>>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    const fetchResource = async (type: typeof configType) => {
      setIsLoading(true)
      const abortController = new AbortController()

      const reqConfig = {
        signal: abortController.signal
      }

      try {
        const response = await ChatRepository.fetchReasons(type, reqConfig)
        const { reason } = response.data?.data || {}

        if (!reason) throw new Error('Não foi possível obter os dados do resource')

        setItems(reason)
        setIsLoading(false)
      } catch (err) {
        if (!abortController.signal.aborted) {
          setError(err.message)
          return setIsLoading(false)
        }
      }
    }

    fetchResource(configType)
  }, [configType])

  return { items, isLoading, error }
}

export default useHelpdeskReasons

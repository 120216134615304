import { AxiosRequestConfig, AxiosResponse } from 'axios'
import api from '../../services/api'
import { ConnectionFailed, ErrInternetDisconnected } from './errors'

interface IBaseData {
  data: FormData
  proposalId: string
  config: AxiosRequestConfig
}

const fileHandler = {

  uploadUnauthProposalDocument: async ({ data, config }: Omit<IBaseData, 'proposalId'>) => {
    try {
      const response: AxiosResponse = await api.patch('/link/document', data, config)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  uploadProviderDocument: async ({ data, config }: Omit<IBaseData, 'proposalId'>) => {
    try {
      const response: AxiosResponse = await api.post('/inviteds/upload', data, config)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  }
}

export default fileHandler

import loadable from '@loadable/component'
import Loading from 'components/Loading/Loading'
import { timeout } from 'promise-timeout'
import React from 'react'
import { TIMEOUT_LOADER } from 'utils/globals'

const ConfigurationMainECPF = loadable(
  () => timeout(import(
    /* webpackChunkName: "ConfigurationECPF" */
    'ecpf/app/ConfigurationECPF/views/ConfigurationMainECPF/ConfigurationMainECPF'
  ), TIMEOUT_LOADER),
  {
    fallback: <Loading/>
  }
)

const ConfigurationInsuranceECPF = loadable(
  () => timeout(import(
    /* webpackChunkName: "ConfigurationECPF" */
    'ecpf/app/ConfigurationECPF/views/ConfigurationInsuranceECPF/ConfigurationInsuranceECPF'
  ), TIMEOUT_LOADER),
  {
    fallback: <Loading/>
  }
)

const ConfigurationMaximumRequestedValueECPF = loadable(
  () => timeout(import(
    /* webpackChunkName: "ConfigurationECPF" */
    'ecpf/app/ConfigurationECPF/views/ConfigurationMaximumRequestedValueECPF/ConfigurationMaximumRequestedValueECPF'
  ), TIMEOUT_LOADER),
  {
    fallback: <Loading/>
  }
)

export { ConfigurationMainECPF, ConfigurationInsuranceECPF, ConfigurationMaximumRequestedValueECPF }

import React from 'react'
import { Image } from 'antd'

type Props = { imgUrl: string }
export default function SignupHeader ({ imgUrl }: Props) {
  return (
    <div className="signup-layout__header">
      <div>
        <Image
          src={imgUrl}
          preview={false}
          className='signup-layout__logo'
          alt="rodobens"
        />
      </div>
    </div>
  )
}

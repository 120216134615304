import { AxiosRequestConfig } from 'axios'
import { Repository } from 'ecp/repositories/Repository'
import api from 'services/api'

export interface IMainOfficeItem {
  codigo: string
  descricao: string
}

export interface IRegionalItem {
  codigo: string
  descricao: string
  matriz: {
    codigo: string
    descricao: string
  }
}

export interface IOrganizationItem {
  codigo: string
  descricao: string
  empregador: {
    codigo: string
    descricao: string
  }
}

export interface IOrganizationByCnpj {
  codigo: string
  documento: string
  descricao: string
  codigoEmpregador: string
}

class OriginsUnauthECPFRepository extends Repository<{}> {
  async updateTypistEmail (body: { cpf: string, tokenValidationId: string }, config?: AxiosRequestConfig) {
    return Repository.handle<{ orgaos: Array<IOrganizationByCnpj>, total: number }>(() =>
      this.api.post(`${this.path}/digitador/email`, body, config)
    )
  }
}

export default new OriginsUnauthECPFRepository({ api, path: '/ecp-funcao/unauth/origens' })

import { useForm } from 'antd/lib/form/Form'
import React, { useState } from 'react'
import SignupFormJuridical from './components/SignupFormJuridical/SignupFormJuridical'
import queryString from 'query-string'
import { Link, useHistory } from 'react-router-dom'
import { UNAUTHS_PATHS } from 'routes/unauthRoutes'
import { Typography, Form, Col } from 'antd'
import LoginRepository from 'ecp/repositories/LoginRepository'
import { handleOnidataErrors } from 'utils/validate'
import SignupLayout from 'layouts/SignupLayout/SignupLayout'
import { IEcpSignupForm } from './signupFormInterfaces'
import locale from 'antd/es/date-picker/locale/pt_BR'
import Datepicker from 'components/Datepicker/Datepicker'
import SignupInviteMessages, { SignupInviteAlert } from 'egi/app/Signup/components/SignupInviteMessages/SignupInviteMessages'
import SignupButton from 'egi/app/Signup/components/SignupButton/SignupButton'
import { SignupTitle } from 'egi/app/Signup/components/SignupTitle/SignupTitle'
import useInvite from 'egi/app/Signup/hooks/useInvite'
import SignupModel from 'egi/models/SignupModel'
import { swalError } from 'components/SwalError/SwalError'

export default function EcpSignupForm () {
  const history = useHistory()
  const [form] = useForm<IEcpSignupForm>()

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Partial<IEcpSignupForm>>({})

  const queryToken = queryString.parse(history.location.search)
  const {
    data: invite,
    isInvited
  } = useInvite({ token: queryToken.inviteToken || '' })

  const handleSubmit = async (values: IEcpSignupForm) => {
    setLoading(true)
    try {
      await LoginRepository.forgotPassword({ email_ou_cpf: values.email_ou_cpf })
      history.push({
        pathname: UNAUTHS_PATHS.ECP_FORGOT_TOKEN,
        state: values,
        search: history.location.search
      })
    } catch (err) {
      if (err.data?.error) setErrors(handleOnidataErrors(err.data.error))
      swalError({ err })
    } finally {
      setLoading(false)
    }
  }

  return (
    <SignupLayout>
      {isInvited && (
        <div className='mb-3'>
          <SignupInviteAlert
            inviterName={invite.name}
          >
            <SignupInviteMessages
              level={invite.level}
              inviterName={invite.inviterName}
              areaName={invite.areaName}
              promoterType={invite.promoterType}
              inviterLevel={invite.inviterLevel}
            />
          </SignupInviteAlert>
        </div>
      )}

      <SignupTitle>Adicione seu email e uma nova senha:</SignupTitle>

      {SignupModel.canReturnToSignup(invite) && (
        <div className='mb-3'>
          <Link
            className='signup__link'
            to={{
              pathname: UNAUTHS_PATHS.SIGNUP,
              search: history.location.search
            }}
          >
            &larr; Voltar ao cadastro
          </Link>
        </div>
      )}

      <Typography.Paragraph>
        Fique atento. Ao vincular sua conta, sua senha do <b>canal rodobens</b> será a nova senha cadastrada.
      </Typography.Paragraph>

      <Form
        onFinish={handleSubmit}
        form={form}
        layout="vertical"
        className='unauth-form'
      >
        {SignupModel.canShowFoundationDateSignup(invite) && (
          <Col span={24}>
            <Form.Item
              name="foundationDate"
              label={<label className="simulator-label">Data de fundação</label>}
              help={errors.foundationDate && (errors.foundationDate)}
              validateStatus={errors.foundationDate && ('error')}
              rules={[
                {
                  required: true,
                  message: 'Adicione a data de validação!'
                }
              ]}
            >
              <Datepicker
                className="unauth-inputs"
                locale={locale}
              />
            </Form.Item>
          </Col>
        )}

        <SignupFormJuridical
          errors={errors}
        />

        <Form.Item>
          <SignupButton
            loading={loading}
            htmlType='submit'
            className='w-100'
          >
            Vincular minha conta
          </SignupButton>
        </Form.Item>
      </Form>
    </SignupLayout>
  )
}

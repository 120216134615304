import React, { useCallback, useEffect } from 'react'
import { ColumnType } from 'antd/lib/table'
import { useAuth } from 'hooks'
import { useHistory } from 'react-router-dom'
import { accessManagerDetails } from 'routes/routes'
import { IAccessManagerBackoffice } from 'egi/repositories/UsersEgiRepository'
import translate from 'utils/translate'
import mask from 'utils/masks'
import dayjs from 'dayjs'
import { timeAsDayjs } from 'utils/time'
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import { varColors } from 'styles/colors'
import { Tag } from 'antd'
import { _productSlugs } from 'ecp/models/ProductModel'
import BackofficeBlockSwitch from '../components/BackofficeBlockSwitch/BackofficeBlockSwitch'

function validateDate (date: string | dayjs.Dayjs | undefined) {
  if (date && dayjs(date).isValid()) return timeAsDayjs(date, { applyTimezone: false }).format('DD/MM/YYYY')
  return '-'
}

const COMMUN_COLUMNS: {[key:string] : ColumnType<IAccessManagerBackoffice>} = {
  code: {
    title: 'Id',
    width: 50,
    ellipsis: true,
    dataIndex: 'code',
    render: (code: string) => {
      if (!code) return 'N/A'
      return code
    }
  },
  cpf: {
    dataIndex: ['details', 'CPF_USUARIO'],
    title: 'CPF',
    width: 120,
    render: (cpf: string) => {
      if (!cpf) return 'N/A'
      return mask(String(cpf), 'cpf', true)
    }
  },
  lastAccess: {
    dataIndex: ['details', 'ULTIMO_ACESSO'],
    title: 'Último acesso',
    width: 150
  },
  clientCPF: {
    dataIndex: ['details', 'CPF_CLIENTE'],
    title: 'CPF do cliente'
  },
  statusEmail: {
    dataIndex: ['details', 'STATUS_EMAIL'],
    title: 'status do email'
  },
  level: {
    dataIndex: 'level',
    title: 'Nível',
    width: 100,
    ellipsis: true,
    render: (level: string) => {
      if (!level) return '-'
      return translate.level(level)
    }
  },
  type: {
    dataIndex: 'type',
    title: 'Tipo ',
    width: 50,
    render: (type: string) => {
      if (!type) return '-'
      return type
    }
  },
  createdAt: {
    dataIndex: 'createdAt',
    title: 'Dt. criação',
    width: 100,
    render: (createdAt: string) => {
      if (!createdAt) return '-'
      return validateDate(createdAt)
    }
  },
  verified: {
    dataIndex: 'emailVerified',
    title: 'Verificado',
    width: 90,
    align: 'left',
    render: (emailVerified?: boolean) => {
      if (!emailVerified) return <CloseCircleFilled className="font-size-15 ml-2 mt-1 color-refused" />
      return <CheckCircleFilled className=" font-size-15 ml-2 mt-1 color-tertiary" />
    }
  },
  status: {
    title: 'Status',
    dataIndex: 'status',
    width: 125,
    render: (value: string) => {
      if (!value) return 'N/A'
      return <Tag color={varColors[value]}>{translate.userStatus(value)}</Tag>
    }
  },
  products: {
    title: 'Produtos',
    dataIndex: 'products',
    width: 125,
    render: (products: _productSlugs[]) => {
      if (!products || products.length < 1) return 'N/A'
      const translatedProducts = products.map((item) => translate.productSlugToUsualName(item))
      return translatedProducts.join(', ')
    }
  },
  block: {
    title: 'Bloquear',
    dataIndex: '',
    width: 125,
    render: (_, row: IAccessManagerBackoffice) => {
      return <BackofficeBlockSwitch userId={row._id} userIsBlocked={row.blocked || false} />
    }
  },
  canceled: {
    title: 'Cancelado',
    dataIndex: 'canceled',
    width: 125,
    render: (canceled?: boolean) => {
      return canceled ? 'Sim' : 'Não'
    }
  }
}

function useAccessManagerBackofficeColumns () {
  const auth = useAuth()
  const history = useHistory()

  const redirectToFormEditCallback = useCallback((id: string) => {
    history.push('/auth' + accessManagerDetails.path.replace(':backofficeId', id))
  }, [])

  const handleBackOfficeColumns = () => {
    return [
      COMMUN_COLUMNS.code,
      {
        title: 'Nome',
        ellipsis: true,
        width: 150,
        render: (row: IAccessManagerBackoffice) => {
          return (
            <p
              className="link-style text-ellipsis"
              onClick={() => {
                if (row?._id) redirectToFormEditCallback(row?._id)
              }}
            >
              {row?.details?.NOME_USUARIO ? row?.details?.NOME_USUARIO : 'N/A'}
            </p>
          )
        }
      },
      COMMUN_COLUMNS.cpf,
      COMMUN_COLUMNS.level,
      COMMUN_COLUMNS.type,
      COMMUN_COLUMNS.createdAt,
      COMMUN_COLUMNS.verified,
      COMMUN_COLUMNS.status,
      COMMUN_COLUMNS.products,
      COMMUN_COLUMNS.lastAccess,
      COMMUN_COLUMNS.canceled,
      COMMUN_COLUMNS.block
    ]
  }

  useEffect(() => {
  }, [auth.selectedProduct?.slug])

  return {
    columns: handleBackOfficeColumns()
  }
}

export default useAccessManagerBackofficeColumns

import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import commonColumns from 'components/UserTable/utils/commonColumns'
import blockColumn from 'components/UserTable/utils/blockColumn'
import NameDropdownController from 'components/NameDropdownController/NameDropdownController'
import { useAuth } from 'hooks'
import { IBaseProps } from '../userTableInterfaces'
import UserTableModel from 'egi/models/UserTableModels'
import { OverflowTooltip } from 'components/OverflowToolTip/OverflowTooltip'

function TelemarketingAdminColumns ({ blockUser, areaName }: IBaseProps): ColumnsType<any> {
  const user = useAuth()

  const columns = [
    {
      ...commonColumns.name,
      render: (name: string, row: { type: string, _id: string, cpf: string }) => {
        return (
          <>
            <OverflowTooltip width={200}
              title={
                <>
                  {name}
                  {row?.cpf && <div>
                    {row?.cpf}
                  </div>}
                </>
              }>
              <NameDropdownController
                showUserDetails={UserTableModel.showUsersDetails(user.level)}
                dropDownLabel={name}
                userName={name}
                userId={row._id}
                tableLevel='Administrador'
                extraAction={{
                  name: 'Time',
                  url: `/auth/administrators/${row._id}/invited/${name}`,
                  hasExtraAction: true,
                  area: areaName
                }}
              />
              {row?.cpf && row?.cpf}
            </OverflowTooltip>

          </>
        )
      }
    },
    commonColumns.email,
    commonColumns.cellphone,
    commonColumns.createdAt,
    ...(blockUser ? [blockColumn({ blockUser, userLevel: user.level })] : [])
  ]

  return columns
}

export default TelemarketingAdminColumns

import React, { useState } from 'react'
import { swalError } from 'components/SwalError/SwalError'
import { useUploadStepFiles } from '../../../Proposals/components/ProposalSteps/UploadStepSection/UploadStepSection'
import { AxiosRequestConfig } from 'axios'
import { Button, message } from 'antd'
import { IDocumentsToSent } from '../../UnauthUploadFileInterfaces'
import { UploadFile } from 'antd/lib/upload/interface'
import Dragger from 'antd/lib/upload/Dragger'
import UploadSVG from 'assets/reactSvgs/UploadSVG/UploadSvg'
import UnauthDynamicProposalRepository from 'ecpf/repositories/UnauthDynamicProposalRepository'

export function UploadStepFiles ({ documentsToSend, onUpdateFile } : {documentsToSend: IDocumentsToSent, onUpdateFile: Function}) {
  const {
    addFile,
    updateFilePercentage,
    updateFileId
  } = useUploadStepFiles()

  const [loading, setLoading] = useState<boolean>(false)
  const [file, setFile] = useState<UploadFile>()

  const removeFile = () => {
    setFile(undefined)
  }

  const onUploadFile = async (
    file: UploadFile,
    ids: { stepId: string, proposalId: string, departmentId: string, linkId: string }
  ) => {
    setLoading(true)
    try {
      if (!file || !file.originFileObj) throw new Error('Nenhum arquivo anexado')
      addFile(file)

      const formData = new FormData()

      formData.append('file', file.originFileObj)

      const config: AxiosRequestConfig = {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 90000,
        onUploadProgress: ({ total, loaded }) => {
          if (total) {
            updateFilePercentage(
              file?.uid,
              loaded,
              total
            )
          }
        }
      }

      const response = await UnauthDynamicProposalRepository.uploadOnStep(ids, formData, config)
      const { _id } = response.data.data || {}
      if (!_id) throw new Error('Falha ao obter identificador da proposta')
      updateFileId(file?.uid, _id)

      onUpdateFile()
      message.success('Arquivo enviado com sucesso')
    } catch (err) {
      swalError({ title: 'Atenção', err, icon: 'warning' })
      removeFile()
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div>
        <Dragger
          maxCount={1}
          listType="picture"
          customRequest={() => { }}
          fileList={file ? [file] : []}
          onRemove={removeFile}
          beforeUpload={() => {
            const canUploadFile = true
            return canUploadFile
          }}
          onChange={
            async (info) => {
              if (info.file.status === 'uploading') {
                setFile(info.file)
                setTimeout(() => {
                  info.file = {
                    ...info.file,
                    status: 'done',
                    percent: 100
                  }

                  setFile(info.file)
                }, 300)
              }

              if ((info.file.status === 'done') && info.file.originFileObj) {
                message.success(`${info.file.name} arquivo anexado com sucesso`)
              }
            }}
          className={file ? 'unauth-upload-file__hide-upload' : ''}
        >
          <div className="p-2">
            <UploadSVG className="unauth-upload-file__upload-svg"/>
            <p> Clique ou arraste arquivos até essa área para fazer upload
            </p>
          </div>
        </Dragger>
      </div>
      {file && (
        <div className="text-right my-3">
          <Button type='primary'
            loading={loading}
            onClick={() => {
              if (documentsToSend) {
                onUploadFile(file, {
                  stepId: documentsToSend.step._id,
                  departmentId: documentsToSend.department._id,
                  proposalId: documentsToSend.proposal._id,
                  linkId: documentsToSend.linkId
                })
              }
            }}
          >
        Enviar Arquivo
          </Button>
        </div>
      )}
    </>
  )
}

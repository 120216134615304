import React, { ReactNode } from 'react'
import { Tooltip } from 'antd'

function TableTooltip (text: string | ReactNode) {
  return (
    <Tooltip
      autoAdjustOverflow
      title={text}
      trigger={['click', 'hover']}
      destroyTooltipOnHide={{ keepParent: false }}
    >
      {text}
    </Tooltip>
  )
}

export default TableTooltip

import React, { useEffect, useState } from 'react'
import { timeAsDayjs } from 'utils/time'
import { ClockCircleFilled } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { colors } from 'styles/colors'
import dayjs from 'dayjs'

export function ExpirationDateTag ({
  expirationDate
}: {
  expirationDate: string | null
}) {
  const [isExpired, setIsExpired] = useState(false)

  useEffect(() => {
    if (!expirationDate) return

    const currentTime = timeAsDayjs(undefined, { applyTimezone: false }).startOf('day')

    if (!dayjs(expirationDate).isValid()) return

    const startOfDayExpirationDate = timeAsDayjs(expirationDate, { applyTimezone: false }).startOf('day')

    if (currentTime.isAfter(startOfDayExpirationDate, 'day')) {
      setIsExpired(true)
    } else {
      setIsExpired(false)
    }
  }, [expirationDate])

  const color = isExpired ? colors.reproved : colors.approved

  if (!expirationDate) return null

  if (!dayjs(expirationDate).isValid()) return null

  const formatedExpirationDate = timeAsDayjs(expirationDate, { applyTimezone: false }).format('DD/MM/YYYY')

  return (
    <Tooltip title={`Validade: ${formatedExpirationDate}`}>
      <div className='mt-1 ml-1'>
        <ClockCircleFilled className='mr-1' style={{ color }} />
        <span
          style={{ color: isExpired ? colors.reproved : 'black' }}
        >
          {formatedExpirationDate}
        </span>
      </div>
    </Tooltip>
  )
}

import { ColumnsType } from 'antd/lib/table'
import { IColumns } from 'egi/types'
import commomColumns, { columnBlockUser } from './commomDataTable'

const columns = ({ blockUser }: IColumns): ColumnsType<Object> => [
  ...commomColumns,
  columnBlockUser(blockUser)
]

export default columns

import React from 'react'
import SkeletonAvatar from 'antd/lib/skeleton/Avatar'
import SkeletonTextLine from 'components/Skeletons/SkeletonTextLine/SkeletonTextLine'

export const ProposalStepSkeleton = () => (
  <div className='proposal-step proposal-step-skeleton'>
    <SkeletonAvatar />
    <SkeletonTextLine />
  </div>
)

import { useState } from 'react'
import { message } from 'antd'
import { IDynamicProposalStep, IProposalDepartment, ISendStepBody, ISendStepQuery } from 'ecp/app/Proposals/proposalInterfaces'
import { swalError } from 'components/SwalError/SwalError'
import useDynamicProposal from './useDynamicProposal'
import DynamicProposalECPFRepository from 'ecpf/repositories/DynamicProposalECPFRepository'

interface IUseProposalDrawer {
  department: IProposalDepartment
  step: IDynamicProposalStep
}

const useProposalDrawer = ({ department, step }: IUseProposalDrawer) => {
  const [isOpen, setIsOpen] = useState(false)
  const { reloadProposalAndSteps } = useDynamicProposal()

  const open = () => setIsOpen(true)

  const close = () => setIsOpen(false)

  async function sendStep <T> (body: ISendStepBody<T>, proposalId: string) {
    try {
      const query: ISendStepQuery = {
        departmentId: department._id,
        proposalId,
        stepId: step._id
      }

      const response = await DynamicProposalECPFRepository.sendStep(query, body)
      message.success(response.data?.message)

      await reloadProposalAndSteps({ proposalId, stepId: step._id, departmentId: department._id })
      close()
      return response
    } catch (err) {
      swalError({ err })
      throw err
    }
  }

  return {
    isOpen,
    open,
    close,
    sendStep
  }
}

export default useProposalDrawer

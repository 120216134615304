import React from 'react'
import { Select, Form } from 'antd'
import { allLevels } from 'utils/globals'
import translate from 'utils/translate'

function FilterLevel ({ callback }: {callback: Function}) {
  return (
    <Form.Item
      name="level"
      label={<label>Nível de acesso</label>}
    >
      <Select
        placeholder="Escolha o nível de acesso"
        onChange={(value: string) => {
          if (value) callback(value)
          else callback(undefined)
        }}
        allowClear
      >
        <Select.Option value=''>Todos</Select.Option>
        {allLevels.map((item, index) => (
          <Select.Option key={index} value={item}>{translate.level(item)}</Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default FilterLevel

import React, { Fragment, useLayoutEffect, useState } from 'react'
import { DatepickerCustom, Input } from 'components'
import locale from 'antd/es/date-picker/locale/pt_BR'
import { Col, Form, Row } from 'antd'
import { proposals } from 'egi/consumers'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'
import { useDispatch } from 'react-redux'
import { useStep, useClient, useProposal, useAuth } from 'hooks'
import time, { timeAsDayjs } from 'utils/time'
import { IFormDateErrors, IFormDateProps } from './types'
import { proposalLoadingStep } from 'store/modules/proposal/actions'
import ProposalEgiModel from 'egi/models/ProposalEgiModel'

function FormDate ({ dateFormRef, onLoading, callbackProposal }: IFormDateProps) {
  const [errors, setErrors] = useState<IFormDateErrors>({})

  const step = useStep()
  const clientData = useClient()
  const proposal = useProposal()
  const user = useAuth()

  const dispatch = useDispatch()

  useLayoutEffect(() => {
    function fillFields () {
      if (step.dates && step.dates.length > 0) {
        if (dateFormRef && dateFormRef.current) {
          dateFormRef?.current.setFieldsValue({ vistoria: timeAsDayjs(step.dates[step.dates.length - 1].value) })
        }
      }
    }

    fillFields()
  }, [])

  const onSubmit = async (values: { vistoria: Date }) => {
    const { vistoria } = values

    values.vistoria = time(vistoria)

    if (proposal.id && step.id) {
      try {
        onLoading('sending')
        const response = await proposals.sendVistoria({
          proposalId: proposal.id,
          stepId: step.id,
          areaId: step.areaId,
          form: values
        })

        if (step.id) dispatch(proposalLoadingStep({ stepId: step.id, loading: true }))

        setErrors({})
        swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
        callbackProposal()
      } catch (err) {
        if (err.data?.allInvalids) {
          let errors: IFormDateErrors = {}
          errors = validateResponse(err.data?.allInvalids)
          setErrors(errors)
          swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
          onLoading('')
          return
        }

        swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      } finally {
        onLoading('')
      }
    }
  }

  return (
    <Fragment>
      <Row gutter={15}>
        <Col lg={8} md={24} sm={24} xs={24}>
          <p className="mb-1">Nome:</p>
          <Input
            className='read-only-formdate-input w-100'
            readOnly={true}
            value={clientData.name}
          />
        </Col>

        <Col lg={8} md={24} sm={24} xs={24} className="email-wrapper-formdate">
          <p className="mb-1">Email:</p>
          <Input
            className='read-only-formdate-input w-100'
            readOnly={true}
            value={clientData.email}
          />
        </Col>

        <Col lg={8} md={24} sm={24} xs={24} className="phone-wrapper-formdate">
          <p className="mb-1">Telefone / Celular: </p>
          <Input
            className='read-only-formdate-input w-100'
            value={clientData.cellphone}
            readOnly={true}
            mask="cell"
          />
        </Col>
      </Row>

      <Form
        layout="vertical"
        ref={dateFormRef}
        onFinish={onSubmit}
      >
        <Row>
          <Form.Item
            className='valuation-date-formdate w-100'
            name="vistoria"
            label={<label>Data vistoria</label>}
            help={errors.vistoria && errors.vistoria}
            validateStatus={errors.vistoria && 'error'}
            rules={[{
              required: true,
              message: 'Escolha sua data de vistoria!'
            }]}
          >
            <DatepickerCustom
              disabled={ProposalEgiModel.formDateInspectionDateDisabled({ userlevel: user.level, stepInternal: step.internal, stepMode: step.mode })}
              showTime={{ format: 'HH:mm' }}
              locale={locale}
              format="DD/MM/YYYY HH:mm"
            />
          </Form.Item>
        </Row>
      </Form>
    </Fragment>
  )
}

export default FormDate

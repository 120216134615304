import swal, { IBasic } from 'utils/swal'
import { translateInvalidFields } from 'utils/translate'

export type IInvalidFieldsSwalError = Omit<Omit<IBasic, 'text'>, 'title'> & {
  invalidFields: { field: string, message: string }[]
  title?: string
}

export function invalidFieldsError ({ invalidFields, title = 'Campos Inválidos', icon = 'warning', ...rest }: IInvalidFieldsSwalError) {
  if (!invalidFields.length) {
    return swal.htmlBasic({
      customClass: {
        footer: 'swal-error-footer'
      },
      icon,
      title,
      ...rest
    })
  }

  const text = `<div class="invalid-fields">
    <input id='invalid-fields-toggle' class='invalid-fields__checkbox' type='checkbox'/>
    <label 
      for='invalid-fields-toggle' 
      class='invalid-fields__label invalid-fields__label--on'>
        Ver Todos os campos
    </label>
    <label 
      for='invalid-fields-toggle'
      class='invalid-fields__label invalid-fields__label--off'>
        Fechar campos
    </label>
    <div class="invalid-fields__wrapper"> 
      ${invalidFields.map(field => {
    return (
      `<div class="invalid-fields__item">
            <b class="py-1">${field.field}</b> 
            <span>
              ${field.message}
            </span>
          </div>`
    )
  })
    .join('')}
    </div> 
  </div>`

  swal.htmlBasic({
    text: text,
    customClass: {
      footer: 'swal-error-footer'
    },
    icon,
    title,
    ...rest
  })
}

export function invalidSimulationFieldsError (props: IInvalidFieldsSwalError) {
  const fields = translateInvalidFields.simulator(props.invalidFields)
  return invalidFieldsError({ ...props, invalidFields: fields })
}

import { Button } from 'antd'
import TokenVerificationSVG from 'assets/reactSvgs/TokenVerificationSVG/TokenVerificationSVG'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import { B2CService } from 'ecpf/services/b2cService'
import useAuthLayout from 'hooks/useAuthLayout'
import { UnauthLayout } from 'layouts'
import React, { useCallback, useEffect, useState } from 'react'

const SigninB2CCheckUserECPF = () => {
  const { handleSigninByAuth } = useAuthLayout()

  const parsedUrl = new URL(window.location.href)
  const fragment = parsedUrl.hash.substring(1)

  const searchParams = new URLSearchParams(fragment)
  const id_token = searchParams.get('id_token') as string

  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const onLoginUser = useCallback(
    async (id_token: string) => {
      try {
        setLoading(true)

        const b2cService = new B2CService()
        const response = await b2cService.login({ id_token })
        const auth = response.data.data

        if (auth?.token) {
          await handleSigninByAuth(auth)
        }
      } catch (err) {
        setErrorMessage(err?.message)
      } finally {
        setLoading(false)
      }
    },
    []
  )

  useEffect(() => {
    onLoginUser(id_token)
  }, [id_token])

  if (errorMessage) {
    return (
      <UnauthLayout>
        <section className='signin-b2c-check-user-ecpf unauth-form-border'>
          <div className='signin-b2c-check-user-ecpf__loading-svg'>
            <TokenVerificationSVG />
          </div>

          <h3 className='signin-b2c-check-user-ecpf__title'>Ops, parece que algo deu errado.</h3>
          <p className='signin-b2c-check-user-ecpf__description mb-3'>{errorMessage}</p>

          <Button
            type='primary'
            loading={loading}
            onClick={() => {
              if (id_token) {
                onLoginUser(id_token)
              }
            }}
          >
            Tentar novamente
          </Button>
        </section>
      </UnauthLayout>
    )
  }

  return (
    <UnauthLayout>
      <section className='signin-b2c-check-user-ecpf unauth-form-border'>
        <div className='signin-b2c-check-user-ecpf__loading-svg'>
          <TokenVerificationSVG />
        </div>

        <LoadingSpinner className='signin-b2c-check-user-ecpf__spinner' />
        <p className='signin-b2c-check-user-ecpf__description'>Estamos validando seu acesso...</p>
      </section>
    </UnauthLayout>
  )
}

export default SigninB2CCheckUserECPF

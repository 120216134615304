
import React, { useEffect } from 'react'
import { AuthLayout } from 'layouts'
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom'
import { getToken } from 'utils/localStorage'

interface IAuthPathsProps extends RouteProps {
  authed?: boolean
}

function AuthRouter ({ authed, ...rest }: IAuthPathsProps) {
  const pageHistory = useHistory()

  useEffect(() => {
    const interval = setInterval(() => {
      const token = getToken()
      if (!token) pageHistory.push('/')
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <Route
      {...rest}
      render={({ location }) => authed === true ? (
        <AuthLayout />
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: { from: location }
          }}
        />
      )}
    />
  )
}

export default AuthRouter

import React from 'react'
import { Input } from 'antd'
import { FormInstance } from 'antd/lib/form'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> { formRef: FormInstance<any>, field: string, rest?: any }

function SlugInput ({ formRef, field, ...rest }: InputProps) {
  return (
    <Input
      {...(rest as any)}
      placeholder="Digite o slug da campanha"
      onChange={(event) => {
        const value = event.target.value
        const regexCampaign = /^([a-z0-9\\-]+$)/ig.test(value)

        if (formRef) {
          if (regexCampaign || !value) {
            formRef.setFieldsValue({ [field]: value })
          } else {
            formRef.setFieldsValue({ [field]: value.substring(0, value.length - 1) })
          }
        }
      }}
    />
  )
}

export default SlugInput

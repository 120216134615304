import React, { Fragment, MutableRefObject, useEffect, useRef, useState } from 'react'
import { Form, Input, Select, Tooltip } from 'antd'
import useDebounce from 'hooks/useDebounce'
import { useTables } from 'hooks'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { tableDisableAll } from 'store/modules/tables/actions'

const { Option } = Select

function FilterTypeSearch ({ onChange }: { onChange: (value: string | undefined, ignore: boolean | undefined) => void }) {
  const [value, setValue] = useState<string | undefined>(undefined)
  const [ignoreFilter, setIgnoreFilter] = useState<boolean>(true)

  const tables = useTables()
  const dispatch = useDispatch()
  const formRef: MutableRefObject<any> = useRef(null)

  function handleSearch ({ value }:{value: string}) {
    if (!value) return setValue(undefined)
    setValue(value)
  }

  useEffect(() => {
    function checkSearchUseFilters () {
      if (formRef && formRef.current) {
        if (!tables.filters.searchUseFilters && tables.filters.searchUseFilters !== undefined) {
          formRef.current.setFieldsValue({ searchUseFilters: 'general' })
          setIgnoreFilter(false)
          dispatch(tableDisableAll(true))
        } else {
          formRef.current.setFieldsValue({ searchUseFilters: 'specific' })
          setIgnoreFilter(true)
          dispatch(tableDisableAll(false))
        }
      }
    }

    checkSearchUseFilters()
  }, [])

  useDebounce(() => {
    if (value) onChange(value, ignoreFilter)
    if (!value) onChange(undefined, ignoreFilter)
  }, 300, [value])

  const selectFilterType = (
    <Form ref={formRef}>
      <Form.Item name="searchUseFilters" >
        <Select
          defaultValue='specific'
          className="table-filter__select"
          onChange={(value) => {
            if (value === 'specific') {
              setIgnoreFilter(true)
              dispatch(tableDisableAll(false))
              return
            }

            dispatch(tableDisableAll(true))
            setIgnoreFilter(false)
          }}
        >
          <Option value="general">Busca geral</Option>
          <Option value="specific">Busca com filtros</Option>
        </Select>
      </Form.Item>
    </Form>
  )

  const toolTip = (
    <Fragment>
      <h1 className="simulator-tooltip-h1">Como funciona ?</h1>
      <p className="simulator-tooltip-paragraph">
          Busca geral - Irá ignorar todos os outros filtros aplicados;
      </p>
      <p className="simulator-tooltip-paragraph">
          Busca com filtros - Funcionará em conjunto com os outros filtro disponíveis na listagem de propostas.
      </p>
    </Fragment>
  )

  return (
    <Form.Item
      name="search"
    >
      <label>
        <span>Pesquisar</span>
        <Tooltip title={toolTip} color="#fff" placement="right" destroyTooltipOnHide={{ keepParent: false }}>
          <InfoCircleOutlined className="simulation-info ml-1" style={{ color: '#0f4070' }}/>
        </Tooltip>
      </label>
      <Input
        addonBefore={selectFilterType}
        className="table-filter"
        placeholder='Pesquisar'
        onChange={(e) => { handleSearch({ value: e.target.value.trim() }) }}
        onPressEnter={(e) => { onChange(e.currentTarget.value.trim(), ignoreFilter) }}
      />
    </Form.Item>
  )
}

export default FilterTypeSearch

import loadable from '@loadable/component'
import Loading from 'components/Loading/Loading'
import { timeout } from 'promise-timeout'
import React from 'react'
import { TIMEOUT_LOADER } from 'utils/globals'

const FindUserECPF = loadable(
  () => timeout(import(
    /* webpackChunkName: "FindUserECPF" */
    'ecpf/app/FindUserECPF/views/FindUserECPF/FindUserECPF'
  ), TIMEOUT_LOADER),
  {
    fallback: <Loading/>
  }
)

const FindUserRefinancingECPF = loadable(
  () => timeout(import(
    /* webpackChunkName: "FindUserECPF" */
    'ecpf/app/FindUserECPF/views/FindUserRefinancingECPF/FindUserRefinancingECPF'
  ), TIMEOUT_LOADER),
  {
    fallback: <Loading/>
  }
)

export { FindUserECPF, FindUserRefinancingECPF }

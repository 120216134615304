import dayjs, { Dayjs } from 'dayjs'
import { IProposalFilters } from './proposalEgiInterfaces'

export function formatStatusForRequest (values: string[]): string {
  if (!values) return ''
  return values.toString()
}

function formatDates (filters: IProposalFilters) {
  const dateKeys: (keyof typeof filters)[] = [
    'updatedDateAfter',
    'updatedDateBefore',
    'createdDateBefore',
    'createdDateAfter'
  ]

  return dateKeys.reduce((acc, key) => {
    if (filters[key]) {
      acc[key] = dayjs(filters[key] as Dayjs).format('YYYY-MM-DD')
    }
    return acc
  }, {} as { [key: string]: string })
}

export function formatFilters (filters: IProposalFilters) {
  const formattedFilters: any = {
    ...filters,
    ...formatDates(filters),
    proposalStatus: formatStatusForRequest(filters.proposalStatus)
  }

  return formattedFilters
}

export declare type filtersProposalsRequest = Omit<
  IProposalFilters,
  'proposalStatus'
> & { proposalStatus: string, valueMin: number, valueMax: number, }

import { Button, Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Input } from 'components'
import { swalError } from 'components/SwalError/SwalError'
import DetailsItem from 'ecp/components/DetailsItem/DetailsItem'
import TokensValidationRepository from 'ecp/repositories/TokensValidationRepository'
import { TokenValidationMessageTemplateType } from 'ecpf/models/TokenValidationModel'
import React, { useEffect, useState } from 'react'
import format from 'utils/format'
import { TokenMethodEnum } from '../../TokenValidator'

interface TokenInputProps {
  method: TokenMethodEnum
  onSubmit: (value: string) => void
  onGoBack: () => void
  input?: string
  readOnlyInput?: boolean
  messageTemplate: TokenValidationMessageTemplateType
}

const TokenInput = ({ method, onSubmit, onGoBack, input, readOnlyInput, messageTemplate }: TokenInputProps) => {
  const [loading, setLoading] = useState(false)

  const [form] = useForm()

  const mask = method === TokenMethodEnum.sms ? '(15) 99999-9999' : 'email@email.com'

  function translate (value: string) {
    if (value === TokenMethodEnum.sms) {
      return 'Celular'
    } else {
      return 'E-mail'
    }
  }

  useEffect(() => {
    form.setFieldsValue({ input })
  }, [input])

  const handleFinish = async (values: { input: string }) => {
    try {
      setLoading(true)
      const contactInfo = values.input

      await TokensValidationRepository.request({
        methodType: method,
        email: method === TokenMethodEnum.email ? contactInfo : undefined,
        cellphone: method === TokenMethodEnum.sms ? format.onlyDigits(contactInfo) : undefined,
        messageTemplateType: messageTemplate
      })

      onSubmit(contactInfo)
    } catch (error) {
      swalError({ err: error })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="token-validation__input-container">
      <p className="token-validation__input-label">O Token será enviado para o seguinte {translate(method)}:</p>

      <Form
        form={form}
        onFinish={handleFinish}
      >
        <Form.Item
          name="input"
          rules={[{ required: true, message: 'Este campo é obrigatório!' }]}
        >
          {readOnlyInput ? (
            <DetailsItem label={translate(method)} value={input} />
          ) : (
            <Input
              mask={method === TokenMethodEnum.email ? '' : 'cell'}
              placeholder={mask}
            />
          )}
        </Form.Item>

        <Button
          className="token-validation__submit-button"
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Enviar
        </Button>
      </Form>

      <Button
        type='ghost'
        onClick={onGoBack}
        className="token-validation__back-button"
      >
        Voltar
      </Button>
    </div>
  )
}

export default TokenInput

import React, { Fragment, useState, useEffect } from 'react'
import { Button, Tag, Collapse } from 'antd'
import { useProposal, useModal } from 'hooks'
import { useDispatch } from 'react-redux'

import { IStep } from 'egi/types'
import { modalReset } from 'store/modules/modal/actions'
import translate from 'utils/translate'
import { colors } from 'styles/colors'
import { timeAsDayjs } from 'utils/time'
import CndTagStatus from 'egi/app/ProposalEgi/components/CndStatus/CndStatus'
import ProposalEgiModel from 'egi/models/ProposalEgiModel'

const requestColors = {
  PROCESSING: colors.remaking,
  PROCESSED: colors.approved,
  ERROR: colors.reproved,
  PENDING: colors.analysis
}

function RequestCND () {
  const [currentStepId, setCurrentStepId] = useState<Array<string>>([])

  const proposal = useProposal()
  const dispatch = useDispatch()
  const modal = useModal()

  const commercialDepartment = (proposal.departments.find((item) => item.name === modal.departmentName))
  const checkedSteps: Array<string> = currentStepId

  useEffect(() => {
    if (modal.visible) setCurrentStepId(checkedSteps)
    if (!modal.visible) setCurrentStepId([])
  }, [modal.visible])

  return (
    <Fragment>
      <div className="text-center mb-4">
        <h3 className="bold uppercase color-primary">Status solicitações</h3>
      </div>

      {commercialDepartment?.steps.map((step: IStep) => {
        const expirationDate = ProposalEgiModel.getCurrentCndExpirationDate(step?.cnd)

        return (
          <div key={step._id} className="request-cnd">
            <Collapse defaultActiveKey={step.name} ghost>
              <Collapse.Panel className="request-cnd__panel" header={step.name} key={step.name}>
                {step.cnd &&
                  <ul className="request-cnd__list">
                    {step.cnd.requestedBy &&
                      <li>
                        Solicitante:
                        <ul>
                          <li>Nome solicitante: <strong>{step.cnd.requestedBy?.name}</strong></li>
                          <li>Email solicitante: <strong>{step.cnd.requestedBy?.email}</strong></li>
                          <li>Nível solicitante: <strong>{translate.level(step.cnd.requestedBy?.level)}</strong></li>
                        </ul>
                      </li>
                    }
                    <li>Data requisição: {timeAsDayjs(step.cnd.requestDate, { applyTimezone: false }).format('DD/MM/YYYY HH:mm')}</li>
                    <li>Status requisição: <Tag color={requestColors[step.cnd.requestStatus]} className="ml-1">{translate.cndRequestStatus(step.cnd.requestStatus)}</Tag></li>
                    <li>Status analise: <CndTagStatus step={step} /></li>
                    {step.cnd.msg_infos.length > 0 && (
                      <li>Mais informações:
                        {step.cnd.msg_infos.map((values) => (
                          <ul key={values.status}>
                            <li>Status: <Tag color={colors.remaking} className="ml-1">{translate.cndMoreInfoStatus(values.status)}</Tag></li>
                            <li>Mensagem: {values.msg}</li>
                          </ul>
                        ))}
                      </li>
                    )}

                    {expirationDate && (
                      <li>
                        Data de validade <Tag color={requestColors.PENDING}>API externa</Tag>: {expirationDate}
                      </li>
                    )}
                  </ul>
                }
              </Collapse.Panel>
            </Collapse>
          </div>
        )
      })}

      <div className='mt-4 w-100 flex flex-justify-center'>
        <Button
          type="ghost"
          className="cnd-request-buttons"
          onClick={() => dispatch(modalReset())}
        >
          Cancelar
        </Button>
      </div>
    </Fragment>
  )
}

export default RequestCND

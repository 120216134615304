import { IAuth } from 'egi/types'
import { AuthActionTypes } from './authActions'
import { IAuthActions } from './authTypes'

const INITIAL_STATE: IAuth = {
  _id: '',
  covenantId: '',
  isAuth: false,
  cellphone: '',
  cpf: '',
  emailVerified: undefined,
  expiresAt: undefined,
  expiresIn: undefined,
  level: '',
  name: '',
  proposalId: '',
  refreshToken: '',
  followUpProposal: false,
  token: '',
  levelName: '',
  gender: '',
  birthdate: undefined,
  areaName: undefined,
  administratorId: undefined,
  promoterId: undefined,
  canSeeCorbanSelect: false,
  canCreateEcpProposal: true,
  areas: [],
  levels: [],
  routerDomPaths: [],
  products: [],
  selectedProduct: {},
  convenio: {},
  bank: {},
  communicationVisible: true,
  isConfigUser: false,
  lgpd: {
    url: '',
    tipo: '',
    status: 'assinado',
    ip: '',
    browser: '',
    assinado_em: '',
    lgpd: undefined
  }
}

const auth = (state = INITIAL_STATE, action: IAuthActions.AllActions) => {
  switch (action.type) {
    case AuthActionTypes.SIGNIN_SUCCEED:
      return {
        ...state,
        isAuth: true,
        ...action.payload
      }

    case AuthActionTypes.UPDATE_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: action.payload
      }

    case AuthActionTypes.REFRESH_TOKEN_SUCCEED:
      return {
        ...state,
        token: action.payload.token,
        expiresAt: action.payload.expiresAt
      }

    case AuthActionTypes.CHANGE_USER_LEVEL:
      return {
        ...state,
        isAuth: true,
        ...action.payload
      }

    case AuthActionTypes.SET_PROPOSAL_AUTH:
      return {
        ...state,
        proposal: action.payload.proposalId
      }

    case AuthActionTypes.SET_LEVELS:
      return {
        ...state,
        levels: action.payload.levels
      }

    case AuthActionTypes.RESET_LEVELS:
      return {
        ...state,
        levels: []
      }

    case AuthActionTypes.SET_USER_ROUTER_DOM_PATHS: {
      return {
        ...state,
        routerDomPaths: (action.payload as IAuthActions.SetUserRouterDomPaths['payload']).paths
      }
    }

    case AuthActionTypes.SET_COMMUNICATION_VISIBLE: {
      return {
        ...state,
        communicationVisible: (action.payload as IAuthActions.UpdateCommunicationVisible['payload']).visible
      }
    }

    case AuthActionTypes.SET_LGPD_INFO: {
      return {
        ...state,
        lgpd: (action.payload as IAuthActions.SetLGPDInfo['payload']).lgpd
      }
    }

    case AuthActionTypes.RESET: {
      return INITIAL_STATE
    }

    default:
      return state
  }
}

export default auth

import { AxiosRequestConfig } from 'axios'
import B2CLoginRepository from 'ecpf/repositories/B2CLoginRepository'

export class B2CURLNotFound extends Error {
  name = 'B2C_URL_NOT_FOUND'
  message = 'URL de login não encontrada.'
  code = 404
}

export class B2CService {
  async fetchRedirectUrl (config?: AxiosRequestConfig) {
    return B2CLoginRepository.fetchRedirectUrl(config)
  }

  async login (body: Partial<{ id_token: string }>) {
    return B2CLoginRepository.login(body)
  }
}

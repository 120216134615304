
import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import SettingsCard from '../SettignsCard/SettingsCard'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import { resources as resourcesConsumer } from 'egi/consumers'
import { validateResponse } from 'utils/validate'
import swal from 'utils/swal'
import { colors } from 'styles/colors'
import { useAuth, useSelectedProduct } from 'hooks'
import { LoadingOrEmpty } from 'egi/app/Settings/views/PopulationSettings/Population'
import ResourcesRepository from 'egi/repositories/ResourcesRepository'
import DeclinesModel from 'egi/models/DeclinesModel'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'
import { Form, Input, Col, Row } from 'antd'

interface IErrorsDeclines {
  declines?: string
}

interface IResponseData {
  name: string,
  index: number
}

export default function DeclinesForm () {
  const formRef: MutableRefObject<any> = useRef(null)
  const [size, setSize] = useState(0)

  const [responseData, setResponseData] = useState<any>()
  const [errors, setErrors] = useState<IErrorsDeclines>({})
  const [failRequest, setFailRequest] = useState<boolean>(false)
  const [loading, setLoading] = useState({
    button: false,
    value: true
  })

  const { _id: productId } = useSelectedProduct()
  const user = useAuth()

  async function onSubmit () {
    const responseDataFilter = responseData.filter((item: IResponseData) => item.name !== '')
    const responseDataNames = responseDataFilter.map((item: IResponseData) => item.name)

    if (!responseDataNames || !productId) return

    setLoading({ ...loading, button: true })
    try {
      const response = await ResourcesRepository.declinesPatch({ productId, values: responseDataNames })
      const message = response?.data?.message
      swal.basic({ title: 'Sucesso', text: message, icon: 'success' })
      setLoading({ ...loading, button: false })
    } catch (err) {
      if (err.data?.invalid) {
        let errors: IErrorsDeclines = {}
        errors = validateResponse(err.data?.invalid)
        setErrors(errors)
        setLoading({ ...loading, button: false })

        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
        return
      }

      setErrors({})
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    }
    setLoading({ ...loading, button: false })
  }

  useEffect(() => {
    const getReasons = async () => {
      try {
        const response = await resourcesConsumer.declines({ productId: productId })
        const declines = response.data.options

        const declinesMapOptions = declines.map((item: string, index: number) => ({
          name: item,
          index
        }))
        setLoading({ ...loading, value: false })
        setResponseData(declinesMapOptions)
        setSize(declines?.length)
      } catch (err) {
        setFailRequest(true)
        swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      }
      setLoading({ ...loading, value: false })
    }
    if (productId) getReasons()
  }, [productId])

  function removeButton (item: IResponseData, i: number) {
    const filteredResponseData = responseData.filter((value: IResponseData) => value.index !== responseData[i].index)
    setResponseData(filteredResponseData)
  }

  function inputValue (e: any, item: IResponseData, i: number) {
    const saveInput = responseData.map((value: IResponseData) => value.index === responseData[i].index)
    if (saveInput) {
      responseData[i] = {
        name: e.target.value,
        index: i
      }
    } else {
      responseData.push({
        name: e.target.value,
        index: parseInt(e.target.id)
      })
    }
    setResponseData(responseData)
  }

  function plusButton (e: any) {
    e.preventDefault()
    setResponseData([...responseData, {
      name: '',
      index: size
    }])
    setSize(size + 1)
  }

  return (
    <LoadingOrEmpty
      loading={loading.value}
      failRequest={failRequest}
    >

      <SettingsCard.title>
Motivos de recusa
      </SettingsCard.title>

      <SettingsCard.description className="pb-1">
    Adicione os motivos de recusa das prospostas
      </SettingsCard.description>

      <Form
        ref={formRef}
        layout="vertical"
        onFinish={onSubmit}
        className="form-without-margin-last-form-item"
      >

        <Row gutter={[15, 15]}>
          {responseData?.map((item: IResponseData, i: number) => (
            <Col sm={24} md={12} lg={12} key={item.index}>
              <Form.Item
                help={errors.declines && errors.declines}
                validateStatus={errors.declines && 'error'}
                className="mt-1"
                name={item.index}
              >
                <div className="flex flex-space-between w-100">
                  <label className="ml-1">Motivo {i + 1}</label>
                  {DeclinesModel.canRemoveReason(user.level) && (
                    <MinusOutlined
                      className="pt-1 mr-1"
                      onClick={() => removeButton(item, i) }
                      style={{ color: colors.reproved }}
                    />
                  )}
                </div>
                <Input
                  required
                  defaultValue={responseData[i].name || ''}
                  placeholder="Digite o motivo de recusa"
                  key={i}
                  onBlur={(e) => { inputValue(e, item, i) }}
                  disabled={DeclinesModel.showDisabledInput(user.level)}
                />
              </Form.Item>
            </Col>))}
        </Row>

        <Form.Item>
          <Row gutter={24}>
            {DeclinesModel.canAddReason(user.level) && (

              <SettingsCard.ButtonsContainer>
                <SettingsCard.submitButton
                  loading={loading.button}
                >
                    Salvar
                </SettingsCard.submitButton>

                <PlusOutlined
                  onClick={(e) => plusButton(e)}
                  className="ml-4 reproval-plus-icon"
                />

              </SettingsCard.ButtonsContainer>

            )}
          </Row>
        </Form.Item>
      </Form>

    </LoadingOrEmpty>
  )
}

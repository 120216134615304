import React from 'react'
import { Form, Select } from 'antd'
import translate from 'utils/translate'
import { SelectValue } from 'antd/lib/select'

function FilterCreditArea ({ onChange }: { onChange: (value: SelectValue) => void }) {
  return (
    <Form.Item
      name="clientApprovalStatus"
      label={<label>Status aceite</label>}
    >
      <Select
        placeholder="Escolha o status do aceite"
        onChange={onChange}
      >
        <Select.Option value=''>Todos</Select.Option>
        <Select.Option value='approved'>{translate.creditApprovalStatus('approved')}</Select.Option>
        <Select.Option value='reproved'>{translate.creditApprovalStatus('reproved')}</Select.Option>
        <Select.Option value='waiting'>{translate.creditApprovalStatus('waiting')}</Select.Option>
      </Select>
    </Form.Item>
  )
}

export default FilterCreditArea

import { Drawer } from 'components'
import { useAuth } from 'hooks'
import React, { useState } from 'react'
import { enhanceServerTable } from 'components/SeverTable/serverTableEnhance'
import ReviewDrawer from '../ReviewDrawer/ReviewDrawer'
import { Collapse, Divider } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import ScoreRepository from 'ecp/repositories/ScoreRepository'
import useDynamicProposal from 'ecp/app/Proposals/hooks/useDynamicProposal'
import ProposalStepButton from 'ecp/app/Proposals/components/ProposalStepButton/ProposalStepButton'
import { IErrorDetails } from 'components/ErrorDetails/interfaces'
import ProposalStepButtonWithHistory from 'ecp/app/Proposals/components/ProposalStepButtonWithHistory/ProposalStepButtonWithHistory'
import useThisStep from 'ecp/app/Proposals/hooks/useThisStep'
import useProposalDrawerWithContext from 'ecp/app/Proposals/hooks/useProposalDrawerWithContext'
import { IScoreTotal } from 'ecp/app/Proposals/proposalInterfaces'
import ScoreDrawer from '../../ScoreDrawer/ScoreDrawer'

interface IData {
  createdAt: string
  template : { subject: string}
  sent?: boolean
  failCounter: number
}

function ScoreStep () {
  const [scoreList, setScoreList] = useState<IData[]>([])
  const [totalScore, setTotalScore] = useState<IScoreTotal>()
  const [error, setError] = useState<IErrorDetails>()
  const [loading, setLoading] = useState(false)

  const { isOpen, open, close, sendStep } = useProposalDrawerWithContext()
  const { step } = useThisStep()
  const { details } = useDynamicProposal()
  const user = useAuth()

  const isStartingStatus = details?.value?.departments?.esteira?.steps?.['melhore-seu-score']?.status === 'starting'
  const isAnalisysStatus = details?.value?.departments?.esteira?.steps?.['melhore-seu-score']?.status === 'analysis'
  const canIformDebts = false
  const canReviewScore = (user.level === 'master' || user.level === 'administrator') && (isStartingStatus || isAnalisysStatus)

  async function fetchDebts (userId: string) {
    setLoading(true)
    try {
      if (!userId || userId === undefined) throw new Error('Id de cliente não encontrado')
      const response = await ScoreRepository.scoreList(userId)
      if (response?.data?.data?.scores) {
        const formatedValues = enhanceServerTable(response.data.data.scores)
        setScoreList(formatedValues)
      }
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }

  const getScore = async (userId: string) => {
    setLoading(true)
    try {
      if (!userId || userId === undefined) throw new Error('Id de cliente não encontrado')
      const response = await ScoreRepository.scoreIndex(userId)
      const totalScore = response?.data?.data?.dashboard
      setTotalScore(totalScore)
    } catch (err) {
      console.error(err)
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  async function handleOpenStep () {
    open()
    const { clientId } = details?.value || {}
    if (clientId) {
      fetchDebts(clientId)
      getScore(clientId)
    }
  }

  return (
    <ProposalStepButtonWithHistory>
      <ProposalStepButton
        onClick={handleOpenStep}
        enabled={true}
      >
        {step.name}
      </ProposalStepButton>
      <Drawer
        visible={isOpen}
        onClose={close}
        title={step.name}
        footer={null}
      >
        <>
          <Collapse
            ghost
            defaultActiveKey={'score'}
            expandIcon={({ isActive }) => <RightOutlined className='mt-1' rotate={isActive ? 90 : 0} />}
          >
            <Collapse.Panel className="score-step__collapse-panel" header='Score' key='score'>
              <div className='mt-5'>
                <ScoreDrawer
                  canIformDebts={canIformDebts}
                  totalScore={totalScore}
                  scoreList={scoreList}
                  loading={loading}
                  error={error}
                />
              </div>
            </Collapse.Panel>
          </Collapse>

          {canReviewScore &&
              <>
                <Divider/>
                <div className='mb-5'>
                  <ReviewDrawer
                    close={close}
                    sendStep={sendStep}
                    step={step}
                    proposalId={details?.value?._id || ''}
                    clientName={details?.value?.client?.name || ''}
                  />
                </div>
              </>
          }
        </>
      </Drawer>
    </ProposalStepButtonWithHistory>
  )
}

export default ScoreStep

import { ReactComponent as MailSent } from 'assets/icons/mail-sent.svg'
import UnauthLayout from 'layouts/UnauthLayout/UnauthLayout'
import React from 'react'
import { useLocation } from 'react-router-dom'

export const ResendEmail = () => {
  const { state: { email } } = useLocation<{ email: string }>()

  const description = <>
    o e-mail <b>{email}</b> ainda não foi confirmado, enviamos um novo e-mail de confirmação para sua caixa de entrada.
  </>

  return (
    <UnauthLayout>
      <UnauthLayout.Container>
        <UnauthLayout.IconContainer icon={<MailSent />} />

        <div className='text-center'>
          <UnauthLayout.Title title='Confirmação de email' />
        </div>

        <UnauthLayout.TextContainer>
          <UnauthLayout.Text email={email} text={description} firstLineText='Atenção' />
        </UnauthLayout.TextContainer>

        <UnauthLayout.ButtonContainer>
          <UnauthLayout.BackToLogin text='Já confirmou seu email?' />
        </UnauthLayout.ButtonContainer>
      </UnauthLayout.Container>
    </UnauthLayout>
  )
}

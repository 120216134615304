import React, { useEffect, useMemo } from 'react'
import { Col, Form, Row, Spin } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useAccessManagerBackofficeList } from 'egi/app/AccessManager/hooks/useAccessManager'
import { Table } from 'components'
import useAccessManagerBackofficeColumns from '../../hooks/useAccessManagerBackofficeColumns'
import SearchSVG from 'assets/img/SearchSVG/SearchSVG'
import { LimiterTable } from 'components/LimiterTable/LimiterTable'
import BackofficeReportsButton from '../../components/BackofficeReportsButton/BackofficeReportsButton'
import FilterSearchIdentifierInput from 'components/SearchIdentifierInput/FilterSearchIdentifierInput'
import { useTables } from 'hooks'
import { tablesSetFiltersValues } from 'store/modules/tables/actions'
import { useDispatch } from 'react-redux'

const identifiers = [
  { label: 'CPF', value: 'cpf' },
  { label: 'CNPJ', value: 'cnpj' }
]

export const ACCESS_MANAGER_BACKOFFICE_FILTERS = {
  identifier: 'cpf',
  search: ''
}

function AccessManagerBackOffice () {
  const { setParams, backoffices, isLoading: loading, errorMessage, params, getbackoffices } = useAccessManagerBackofficeList()
  const { columns } = useAccessManagerBackofficeColumns()
  const [formRef] = useForm()
  const tables = useTables()
  const dispatch = useDispatch()

  const description = useMemo(() => {
    if (errorMessage) return errorMessage
    if (!params.search) return 'Pesquise um CPF ou CNPJ para encontrar backoffices.'
    return ''
  }, [JSON.stringify(params), backoffices])

  const handleOnReset = () => {
    formRef.setFieldsValue({ search: '' })
    setParams(prev => ({
      ...prev,
      search: ''
    }))
    dispatch(tablesSetFiltersValues(ACCESS_MANAGER_BACKOFFICE_FILTERS))
  }

  const handleGetBackoffice = (search?: string, identifier?: string) => {
    setParams({
      search: search?.trim(),
      identifier: identifier || 'cpf'
    })

    getbackoffices({
      search: search?.trim(),
      identifier: identifier || 'cpf'
    })
  }

  const handleOnClickSearch = (search?: string, identifier?: string) => {
    handleGetBackoffice(search, identifier)

    dispatch(tablesSetFiltersValues({ search, identifier: identifier || 'cpf' }))
  }

  useEffect(() => {
    if (tables.filters.search) {
      handleGetBackoffice(tables.filters.search, tables.filters.identifier)

      formRef.setFieldsValue({ search: tables.filters.search })
    }
  }, [])

  return (
    <LimiterTable>
      <p className='mb-4 font-size-15 font-500'>Pesquise abaixo utilizando cpf ou cnpj para consultar os usuários internos.</p>

      <Form
        layout="vertical"
        form={formRef}
      >
        <Row gutter={[15, 15]} align='bottom' justify='space-between' wrap>
          <Col flex="auto" lg={6} md={24} sm={24} xs={24}>
            <FilterSearchIdentifierInput
              placeholder='Escolha o que deseja pesquisar'
              key='identifier'
              label='Pesquisar'
              identifiers={identifiers}
              onReset={handleOnReset}
              onChange={() => {}}
              onClickSearch={handleOnClickSearch}
            />
          </Col>

          <Col className='flex flex-justify-end' lg={6} md={24} sm={24} xs={24}>
            <BackofficeReportsButton/>
          </Col>

        </Row>
      </Form>

      {description && (
        <Spin spinning={loading} >
          <div className="mt-5 w-100 access-manager-client__svg-wrapper">
            <div className='access-manager-client__search-svg-container'>
              <SearchSVG/>
            </div>
            <p>{description}</p>
          </div>
        </Spin>
      )}

      {(!!params.search && !description) && (
        <Table
          canSearch={false}
          showPagination={true}
          data={backoffices}
          columns={columns}
          loading={loading}
          limit={12}
        />
      )}

    </LimiterTable>
  )
}

export default AccessManagerBackOffice

import { IPMT } from 'egi/types'

const INITIALSTATE: IPMT = {
  BACEN: 0,
  BACEN_CONJUGE: 0,
  BACEN_PJ: 0
}

const pmt = (state = INITIALSTATE, action: any) => {
  switch (action.type) {
    case 'SET_PMT': {
      const BACEN = action.payload.BACEN !== undefined ? action.payload.BACEN : state.BACEN
      const BACEN_CONJUGE = action.payload.BACEN_CONJUGE !== undefined ? action.payload.BACEN_CONJUGE : state.BACEN_CONJUGE
      const BACEN_PJ = action.payload.BACEN_PJ !== undefined ? action.payload.BACEN_PJ : state.BACEN_PJ
      return {
        ...state,
        BACEN,
        BACEN_CONJUGE,
        BACEN_PJ
      }
    }
    default:
      return state
  }
}

export default pmt

import React, { useState } from 'react'
import { notification, Row } from 'antd'
import { ExclamationCircleOutlined, CheckCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { downloadFile } from 'utils/download'

interface IDownloadMessage {
  text: string
  status: 'success' | 'error' | 'loading'
}

function useDownloadNotification (key: string) {
  const [isDownloading, setIsDownloading] = useState(false)

  async function requestDownload (request: () => Promise<any>) {
    let stopInterval = false
    setIsDownloading(true)
    const DownloadingMessage = ({ text, status }: IDownloadMessage) => (
      notification.open({
        key,
        message: status === 'loading' ? 'Baixando relatório' : status === 'success' ? 'Download concluído' : 'Ocorreu um problema',
        duration: null,
        placement: 'bottomRight',
        onClose: () => {
          stopInterval = true
          setIsDownloading(false)
        },
        description: (
          <div className='u-flex u-gap'>
            <div>
              {status === 'loading' ? (
                <Row justify="center" align="middle">
                  <LoadingOutlined />
                </Row>) : status === 'success'
                ? <CheckCircleOutlined className='info-icon--success' />
                : <ExclamationCircleOutlined className='info-icon--error' />
              }
            </div>
            <div>
              {text}
            </div>
          </div>
        )
      })
    )

    DownloadingMessage({ status: 'loading', text: 'Iniciando download...' })
    const interval: any = setInterval(async () => {
      if (stopInterval) {
        notification.close(key)
        setIsDownloading(false)
        return window.clearInterval(interval)
      }

      try {
        const response = await request()
        const data = response.data.data
        if (data.prognum[0].finished) stopInterval = true
        if (data.prognum[0].finished && data.prognum[0].documents.path[0]) {
          downloadFile({ fileUrl: data.prognum[0].documents.path[0], title: 'Relatório de propostas' })
        }
        if (!stopInterval) DownloadingMessage({ status: !data.prognum[0].documents.finished ? 'loading' : 'success', text: 'Carregando...' })
      } catch (err) {
        console.log(err)
        DownloadingMessage({ status: 'error', text: err.message || 'Ocorreu um problema' })
        setIsDownloading(false)
        return setTimeout(() => window.clearInterval(interval), 3000)
      }
    }, 3000)
  }

  return { requestDownload, isDownloading }
}

export default useDownloadNotification

import { Drawer } from 'components'
import { IDynamicProposal, IDynamicProposalStep, IGetActionLink, IProposalDepartment, _openStepWithAction } from 'ecp/app/Proposals/proposalInterfaces'
import useProposalDrawer from 'ecp/app/Proposals/hooks/useProposalDrawer'
import AcceptContract from 'ecp/app/Proposals/components/AcceptContract/AcceptContract'
import useDynamicProposal from 'ecp/app/Proposals/hooks/useDynamicProposal'
import ProposalRepository from 'ecp/repositories/ProposalRepository'
import { useAuth } from 'hooks'
import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { swalError } from 'components/SwalError/SwalError'
import { _userLevel } from 'ecp/models/UsersModel'
import { useLocation } from 'react-router-dom'
import useThisStep from 'ecp/app/Proposals/hooks/useThisStep'
import ProposalModel from 'ecp/models/ProposalModel'

export function getPermisisons ({
  details,
  department,
  step,
  userLevel
}: {
  details: IDynamicProposal.Model
  department: IProposalDepartment
  step: IDynamicProposalStep
  userLevel: _userLevel
}) {
  const proposalStep =
    details?.departments?.[department.slug]?.steps?.[step.slug]
  const { status } = proposalStep || {}
  const permissions = step.permissionConfiguration?.[userLevel]?.[status] || []
  return permissions
}

type ISignatureStepComposeContext = {
  handleOpen(): void
  actions: IGetActionLink | null
  getActions(proposalId: string): Promise<IGetActionLink | undefined>
}

export const SignatureStepComposeContext = React.createContext<ISignatureStepComposeContext>({} as unknown as ISignatureStepComposeContext)
export const useSignatureStepContext = () => useContext(SignatureStepComposeContext)

interface ISignatureStepCompose {
  department: IProposalDepartment
  step: IDynamicProposalStep
  initialActions?: IGetActionLink | null
  children: ReactNode
}

export interface IContractState {
  showOpenContract: boolean,
  isSigned: boolean
}

function SignatureStepCompose ({ department, step, initialActions, children }: ISignatureStepCompose) {
  const { isOpen, open, close, sendStep } = useProposalDrawer({ department, step })
  const { statusLabel, lastStep } = useThisStep()

  const [loading, setLoading] = useState(false)
  const [actions, setActions] = useState<IGetActionLink | null>(initialActions || null)

  const user = useAuth()
  const { details } = useDynamicProposal()

  const { state } = useLocation<_openStepWithAction | null>()

  const permissions = details?.value ? getPermisisons({
    department,
    details: details?.value,
    step,
    userLevel: user.level
  }) : []

  async function getActions (proposalId: string): Promise<IGetActionLink | undefined> {
    setLoading(true)

    try {
      const response = await ProposalRepository.getAction<IGetActionLink>({
        proposalId,
        departmentId: department._id,
        stepId: step._id,
        signed: ProposalModel.canSeeSignedContrat(statusLabel, lastStep.slug)
      })

      const actions = response?.data?.data?.actions
      if (!actions) return undefined

      if (actions) setActions(actions)

      return actions
    } catch (err) {
      swalError({ err })
      return err
    } finally {
      setLoading(false)
    }
  }

  function handleOpen () {
    if (!actions && details?.value) getActions(details.value._id)
    open()
  }

  useEffect(() => {
    if (state === 'openContract') handleOpen()
  }, [])

  const context: ISignatureStepComposeContext = {
    handleOpen,
    actions,
    getActions
  }

  return (
    <SignatureStepComposeContext.Provider value={context}>
      {children}
      <Drawer
        visible={isOpen}
        onClose={close}
        title={step.name}
        footer={null}
      >
        <AcceptContract
          loading={loading}
          actions={actions}
          permissions={permissions}
          onSend={async (body) => {
            const proposalId = details?.value?._id
            const departmentId = department._id
            const stepId = step._id

            if (departmentId && proposalId && stepId) {
              await sendStep(body, proposalId)
              close()
            }
          }}
        />
      </Drawer>
    </SignatureStepComposeContext.Provider >
  )
}

export default SignatureStepCompose

import { EditOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { userDetails } from 'routes/routes'

type Props = {
  showUserDetails: boolean
  dropDownLabel: string
  userName: string
  userId: string
  tableLevel: string
  extraAction?: {
    name: 'Editar' | 'Time'
    url: string
    hasExtraAction: boolean
    area?: string
  }
}

export default function NameDropdownController ({ showUserDetails, dropDownLabel, tableLevel, userId, userName, extraAction } : Props) {
  const history = useHistory()

  const onlyShowUserDetails = showUserDetails && !extraAction?.hasExtraAction
  const onlyShowExtraAction = extraAction && !showUserDetails
  const hasOneAction = onlyShowUserDetails || onlyShowExtraAction
  const oneActionFinalUrl = onlyShowUserDetails ? '/auth' + userDetails.path.replace(':userId', userId) : extraAction?.url

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          history.push('/auth' + userDetails.path.replace(':userId', userId))
        }}
      >
        <div className="color-tertiary">
          <UserOutlined />
          <span className="font-500 ml-2">Detalhes do usuário</span>
        </div>
      </Menu.Item>

      {extraAction &&
        <Menu.Item
          onClick={() => {
            history.push(extraAction.url, { area: extraAction.area })
          }}
        >
          <div className="color-tertiary">
            {extraAction.name === 'Editar'
              ? <EditOutlined/>
              : <TeamOutlined />
            }
            <span className="font-500 ml-2">{extraAction.name} {tableLevel}</span>
          </div>
        </Menu.Item>
      }
    </Menu>
  )

  if (!extraAction && !showUserDetails) return <span className="font-500 overflow-ellipsis">{ userName }</span>

  if (hasOneAction && oneActionFinalUrl) {
    return (
      <Link className="link-style color-tertiary font-500 overflow-ellipsis" to={oneActionFinalUrl}>{userName}</Link>
    )
  }

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
    >
      <span className="link-style color-tertiary font-500 overflow-ellipsis">{dropDownLabel}</span>
    </Dropdown>
  )
}


import { message, Form } from 'antd'

import TextReviewRepository from 'egi/repositories/TextReviewRepository'
import { ISelect } from 'egi/types'
import { IStepIdentification } from 'hooks/useResources'

import { useState } from 'react'
import { _mode } from '../../components/InternalReviewForm/InternalReviewForm'
import { ISettignsHistory } from '../../components/InternalReviewHistory/InternalReviewHistory'
import { IReview } from '../../types'

function useInternalSettings () {
  const [formRef] = Form.useForm()
  const [selectedDepartment, setSelectedDepartment] = useState<ISelect>()
  const [selectedStep, setSelectedStep] = useState<IStepIdentification>()
  const [review, setReview] = useState<IReview >()
  const [reviewText, setReviewText] = useState<string>()
  const [loadingTextArea, setLoadingTextArea] = useState<boolean>(false)
  const [mode, setMode] = useState<_mode>('display')
  const [history, setHistory] = useState<ISettignsHistory[]>()
  const [getTextError, setGetTextError] = useState<string>()
  const [historyError, setHistoryError] = useState<string>()
  const [historyLoading, setHistoryLoading] = useState<boolean>(false)

  const onEditorChange = (text: string) => {
    setReviewText(text)
  }

  const changeMode = (mode: _mode) => {
    setMode(mode)
    if (mode === 'edit') formRef.setFieldsValue({ text: review?.text })
  }

  const resetStep = () => {
    setSelectedStep(undefined)
    formRef.setFieldsValue({ stepId: undefined })
    changeMode('display')
  }

  const resetReviewText = () => {
    setReview(undefined)
    setReviewText(undefined)
    formRef.setFieldsValue({ text: undefined })
  }

  const onSelectDepartment = ({ departmentId, departments, getSteps }: {departmentId: string, departments: ISelect[]| undefined, getSteps: (departmentId: string) => {}}) => {
    setSelectedDepartment(departments?.find(department => department.value === departmentId))
    getSteps(departmentId)
    resetStep()
  }

  const onSelectStep = (stepId: string, steps: IStepIdentification[] | undefined) => {
    resetReviewText()
    changeMode('display')
    setSelectedStep(steps?.find(step => step._id === stepId))
  }

  const getTextReview = async ({ departmentId, stepSlug }: { departmentId: string | undefined, stepSlug: string }) => {
    resetReviewText()
    setLoadingTextArea(true)
    try {
      if (!departmentId || !stepSlug) throw new Error('Selecione um passo e um departamento')
      const params = { departmentId, stepSlug }

      const response = await TextReviewRepository.showTextReview({ params })
      setReview(response?.data?.data?.document)
      setReviewText(response.data.data.document.text)
    } catch (err) {
      setGetTextError(err.message)
    } finally {
      setLoadingTextArea(false)
    }
  }

  const onAdd = async (values: { stepSlug: string, departmentId: string }) => {
    try {
      if (!reviewText || !selectedStep?.slug) throw new Error('Digite um texto antes de continuar')

      const response = await TextReviewRepository.addTextReview({
        departmentId: values.departmentId,
        text: reviewText ?? '',
        stepSlug: selectedStep?.slug
      })

      const { document } = response?.data?.data || {}
      if (document) getTextReview({ departmentId: values.departmentId, stepSlug: document?.stepSlug })

      setMode('display')
      message.success('Texto para o passo adicionado com sucesso')
    } catch (err) {
      message.error(err.message)
    }
  }

  const onEdit = async (values: { departmentId: string }) => {
    try {
      if (!reviewText) throw new Error('Digite um texto antes de continuar')
      if (!selectedStep?.slug) throw new Error('Não foi possível obter o stepSlug deste passo.')

      const response = await TextReviewRepository.changeTextReview({
        departmentId: values.departmentId,
        text: reviewText ?? '',
        stepSlug: selectedStep?.slug
      })

      const { document } = response?.data?.data || {}
      if (document) getTextReview({ departmentId: values.departmentId, stepSlug: document?.stepSlug })

      setMode('display')
      message.success('Texto para o passo alterado com sucesso')
    } catch (err) {
      message.error(err.message)
    }
  }

  async function onDelete (configurationReviewStepId: string) {
    try {
      if (!selectedDepartment || !selectedStep) throw new Error('Não foi possível deletar')
      const params = {
        configurationReviewStepId,
        departmentId: selectedDepartment?.value,
        stepSlug: selectedStep?.slug
      }
      await TextReviewRepository.deleteTextReview({ params })
      message.success('Texto para o passo deletado com sucesso')
      await getTextReview({ departmentId: selectedDepartment?.value, stepSlug: selectedStep?.slug })
    } catch (err) {
      message.error(err.message ?? 'Não foi possível deletar o item')
    }
  }

  const getHistory = async (configurationReviewStepId: string) => {
    setHistoryLoading(true)
    if (!selectedDepartment || !selectedStep) throw new Error('Não foi possível deletar')
    try {
      const params = {
        departmentId: selectedDepartment?.value,
        stepSlug: selectedStep?.slug,
        configurationReviewStepId
      }

      const response = await TextReviewRepository.getTextReviewHistory({ params })
      setHistory(response.data.data.logs.docs)
    } catch (err) {
      setHistoryError(err.message ?? 'Não foi possível buscar o histórico do item')
    } finally {
      setHistoryLoading(false)
    }
  }

  return {
    selectedDepartment,
    selectedStep,
    onSelectDepartment,
    onSelectStep,
    onAdd,
    onEdit,
    getTextReview,
    onEditorChange,
    loadingTextArea,
    review,
    mode,
    changeMode,
    formRef,
    onDelete,
    getHistory,
    history,
    getTextError,
    historyError,
    reviewText,
    historyLoading
  }
}

export default useInternalSettings

import React, { ReactNode } from 'react'

type Props = { children: ReactNode, className?: string }
export default function SignupContainer ({ children, className }: Props) {
  return (
    <div className={`signup-layout__container ${className || ''}`}>
      {children}
    </div>
  )
}


import { WarningOutlined } from '@ant-design/icons'

import React from 'react'

export function ConfigurationFinished () {
  return (
    <div className="m-5 email-config__config-not-finished">
      <WarningOutlined className="email-config__config-not-finished-icon" />
      <div>
        <h2 className="email-config__title">  Configurações não finalizadas!</h2>
        <p>Um ou mais itens não foram preenchidos. Para garantir o funcionamento correto,
            não esqueça de terminar de preencher as configurações de email</p>
      </div>
    </div>
  )
}

import React, { Fragment, useEffect, useState } from 'react'
import { Button, Col, Form, Row, Select } from 'antd'
import { IMinutaMold } from 'egi/types'
import { qualification as qualificationConsumer } from 'egi/consumers'
import swal from 'utils/swal'
import { useResources, useStep } from 'hooks'
import { FormInstance } from 'antd/lib/form'
import { valuesFormatToDate } from './contractFields'
import time, { correctTimezone } from 'utils/time'
import RichTextEditor from 'components/RichTextEditor/RichTextEditor'

interface IQualificationSelect {
  selectSlug: {name: string, label: string}
  text?: string
  readOnlySelect?: boolean
  placeholder: string
  qualification: Array<IMinutaMold>
  errors?: string
  setText: (value: string) => void
  type: 'comum' | 'qualificacao',
  formRef: FormInstance
}

function MinutaMoldSelect ({
  selectSlug, readOnlySelect,
  placeholder, qualification, errors,
  type, setText, text, formRef
}: IQualificationSelect) {
  const [slug, setSlug] = useState<string>('')
  const [filtered, setFiltered] = useState<IMinutaMold[]>()
  const [loading, setLoading] = useState<boolean>(false)
  const [qualificationPreview, setQualificationPreview] = useState<string>('')

  const step = useStep()
  const resources = useResources()

  function formatFieldsKeys (value: object & {[key: string]: any}) {
    const regex = /(?:(?:titular|IQ)_)(.*)/g
    const newObj = {}
    for (const key in value) {
      const format = key.replace(regex, '$1')
      if (valuesFormatToDate.includes(key) && value[key]) value[key] = time(correctTimezone(value[key]))

      if (format) {
        const formattedObj = { [format.toLowerCase()]: value[key] }
        Object.assign(newObj, formattedObj)
      }

      if (!format) {
        const formattedObj = { [key.toLowerCase()]: value[key] }
        Object.assign(newObj, formattedObj)
      }
    }

    return newObj
  }

  const showPreview = async () => {
    if (!slug) return

    const fieldsValues = formatFieldsKeys(formRef.getFieldsValue())

    const data = {
      ...fieldsValues,
      slug: slug,
      productId: resources.products[0]._id
    }

    setLoading(true)
    try {
      const response = await qualificationConsumer.previewMinutaMold(String(resources.products[0]._id), { ...data })
      const preview = response.data.preview
      setLoading(false)
      setQualificationPreview(preview)
    } catch (err) {
      setLoading(false)
      swal.basic({ icon: 'warning', title: 'Atenção', text: err.message })
    }
  }

  useEffect(() => {
    if (text) setQualificationPreview(text)
  }, [text])

  useEffect(() => {
    if (step.form && step.form[selectSlug.name]) {
      setSlug(step.form[selectSlug.name])
    }
  }, [step])

  useEffect(() => {
    const filterResource = qualification.filter(value => type.includes(value.type))
    setFiltered([...filterResource])
  }, [type])

  return (
    <Fragment>
      <Row align="middle">
        <Col lg={16} className="px-1">
          <Form.Item
            name={selectSlug.name}
            key={selectSlug.name}
            label={<label>{selectSlug.label}</label>}
            help={errors && errors}
            validateStatus={errors && ('error')}
          >
            <Select
              disabled={readOnlySelect}
              placeholder={placeholder}
              key={selectSlug.name}
              onChange={(value: string) => setSlug(value)}
            >
              {filtered && filtered.map((item, idx) => (
                <Fragment key={idx}>
                  {!item.blocked && (
                    <Select.Option id={`test-select-${item.slug}`} value={item.slug} key={idx}>{item.description}</Select.Option>
                  )}
                </Fragment>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col lg={8} className="px-1">
          <Button
            className="w-100"
            type="primary"
            disabled={readOnlySelect || !slug}
            loading={loading}
            onClick={() => showPreview()}
          >
            Gerar modelo de quadro
          </Button>
        </Col>
      </Row>

      <Col span={24}>
        <RichTextEditor
          setContents={qualificationPreview}
          onChange={(a: string) => setText(a)}
        />
      </Col>

    </Fragment>
  )
}

export default MinutaMoldSelect

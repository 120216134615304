import { Form, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Input } from 'components'
import { IError } from 'ecp/app/Proposals/proposalInterfaces'
import SignupButton from 'egi/app/Signup/components/SignupButton/SignupButton'
import { SignupTitle } from 'egi/app/Signup/components/SignupTitle/SignupTitle'
import SignupLayout from 'layouts/SignupLayout/SignupLayout'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import LoginRepository from 'ecp/repositories/LoginRepository'
import { swalError } from 'components/SwalError/SwalError'
import { UNAUTHS_PATHS } from 'routes/unauthRoutes'
import { IFirstAccessCorbanRHTokenInfo } from '../../firstAccesInterfaces'
import { handleOnidataErrors } from 'utils/validate'

interface IFirstAccessPhone {
  telefone_celular: string
}

export default function FirstAccessPhone () {
  const [form] = useForm()
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [errors, setErrors] = useState<IError<IFirstAccessPhone>>({})
  const location = useLocation()
  const history = useHistory()
  const query = queryString.parse(location.search)
  const token = query.token
  const cpf = query.cpf as string

  function redirectToNextStep (tokenInfo: IFirstAccessCorbanRHTokenInfo) {
    history.push({
      pathname: `${UNAUTHS_PATHS.FIRST_ACCESS_PHONE_TOKEN}`,
      state: tokenInfo
    })
  }

  async function handleSubmit (data: IFirstAccessPhone, cpf: string) {
    setLoadingSubmit(true)
    setErrors({})

    try {
      const body = {
        ...data,
        tipo: 'convite_gestor',
        cpf: cpf as string,
        token: token as string
      }

      const response = await LoginRepository.sendFirstAccessPhone(body)
      const routeResponse = response?.data?.data?.routeResponse

      const tokenInfo = {
        cellphone: data.telefone_celular,
        onidataToken: routeResponse?.onidataToken,
        email: routeResponse?.email,
        cpf
      }

      if (!tokenInfo) throw new Error('Informações da token não encontradas ')
      if (response.data.message) message.success(response.data.message)
      redirectToNextStep(tokenInfo)
    } catch (err) {
      swalError({ title: 'Atenção', icon: 'warning', err })
      setErrors(handleOnidataErrors(err.data?.error))
    } finally {
      setLoadingSubmit(false)
    }
  }

  return (
    <SignupLayout>

      <SignupTitle className='mb-1'>
        Informe seu celular
      </SignupTitle>

      <Form
        onFinish={(values) => handleSubmit(values, cpf)}
        form={form}
        layout="vertical"
        className='unauth-form'
      >
        <p className='signup-check-link__paragraph mb-4'>Vamos confirmar seu celular para finalizar seu cadastro</p>

        <Form.Item
          name='telefone_celular'
          label='Celular'
          help={errors?.telefone_celular}
          validateStatus={errors?.telefone_celular && ('error')}
        >
          <Input
            className='unauth-inputs'
            placeholder='Insira o celular'
            mask='cell'
          />
        </Form.Item>

        <Form.Item>
          <SignupButton
            loading={loadingSubmit}
            htmlType='submit'
            className='w-100'
          >
            Enviar
          </SignupButton>
        </Form.Item>
      </Form>
    </SignupLayout>
  )
}

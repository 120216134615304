import React, { ReactNode, useEffect, useState } from 'react'
import { Empty, Skeleton, Form, Input, Button, Pagination } from 'antd'
import { validateResponse } from 'utils/validate'
import swal from 'utils/swal'
import { useAuth, useSelectedProduct } from 'hooks'
import ConfigurationsRepository, { ILogs } from 'egi/repositories/ConfigurationsRepository'
import PopulationModel from 'egi/models/PopulationModel'

import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'
import SettingsCard from '../../components/SettignsCard/SettingsCard'
import SettingsHistory from '../../components/SettignsHistory/SettingsHistory'
import { ShowHistory } from '../../components/PopulationSettingsHistory/PopulationHistory'

type LoadingEmptyProps = {
  loading: boolean
  failRequest: boolean
  children: ReactNode
}

interface IParams {
  page: number
  limit: string
}

export function LoadingOrEmpty ({ loading, failRequest, children }: LoadingEmptyProps) {
  if (loading) {
    return <>
      <Skeleton active className=' p-3 mb-2' />
    </>
  }
  if (failRequest) return <Empty description="Falha ao buscar dados, tente novamente mais tarde" className='card-style p-3' />
  return <>{children}</>
}

function Population () {
  const [errors, setErrors] = useState<{ populationLimit?: string }>({})
  const [failRequest, setFailRequest] = useState<boolean>(false)
  const [logs, setLog] = useState<ILogs[]>()
  const [totalDocs, setTotalDocs] = useState<number>()
  const [visible, setVisible] = useState<boolean>(false)

  const [loadingPopulation, setloadingPopulation] = useState<boolean>(false)
  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: '3'
  })

  const [loading, setLoading] = useState({
    button: false,
    value: false,
    history: false
  })

  const [formRef] = Form.useForm()
  const user = useAuth()
  const { _id: productId } = useSelectedProduct()

  async function onSubmit (values: { populationLimit: string }) {
    const { populationLimit } = values

    if (!populationLimit || !productId) return

    setLoading({ ...loading, button: true })
    try {
      const response = await ConfigurationsRepository.updatePopulationLimit(productId, { populationLimit })
      swal.basic({ title: 'Sucesso', text: response.data.message, icon: 'success' })
    } catch (err) {
      if (err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
        setLoading({ ...loading, button: false })
        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
        return
      }

      setErrors({})
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    }
    setLoading({ ...loading, button: false })
  }

  useEffect(() => {
    async function fetchPopulation () {
      setloadingPopulation(true)
      try {
        const response = await ConfigurationsRepository.getPopulationLimit(productId)
        const population = response.data.data?.populationLimit

        if (formRef) formRef.setFieldsValue({ populationLimit: population })
      } catch (err) {
        setFailRequest(true)
      } finally {
        setloadingPopulation(false)
      }
    }

    if (productId) fetchPopulation()
  }, [])

  useEffect(() => {
    async function fetchPopulationHistoric (params: IParams) {
      setLoading({ ...loading, history: true })
      try {
        const response = await ConfigurationsRepository.getPopulationLimitHistory(productId, { params })
        setLog(response?.data?.data?.logs?.docs)
        setTotalDocs(response?.data?.data?.logs?.totalDocs)
      } catch (err) {
        setFailRequest(true)
      }
      setLoading({ ...loading, history: false })
    }
    if (productId) fetchPopulationHistoric(params)
  }, [productId, params])

  const onChangeVisible = () => {
    setVisible(prev => !prev)
  }

  return (

    <>
      <SettingsCard.Header
        title="População mínima"
        description={
          <p>
            Configure aqui textos padrão para a&nbsp;
            <b>revisão interna</b> do passo da proposta.
            Não se preocupe, eles poderão ser editados e personalizados conforme a necessidade
          </p>
        } />
      <SettingsCard>
        <LoadingOrEmpty
          loading={loadingPopulation}
          failRequest={failRequest}
        >
          <div className="population-limit__title mb-3">
            <div>
              <SettingsCard.title>
        Limite Populacional
              </SettingsCard.title>
              <SettingsCard.description >
        Ao mudar o valor populacional, apenas cidades com número de habitantes superior ao número colocado poderão prosseguir na simulação.
              </SettingsCard.description>
            </div>
            { logs &&
          <Button type="primary" onClick={onChangeVisible}>
            Histórico de alteração
          </Button>
            }
          </div>

          <Form
            form={formRef}
            layout="vertical"
            onFinish={onSubmit}
            className="form-without-margin-last-form-item"
          >
            <Form.Item>
              <Form.Item
                name="populationLimit"
                label={<label>Limite populacional</label>}
                help={errors.populationLimit && errors.populationLimit}
                validateStatus={errors.populationLimit && 'error'}
              >
                <Input
                  placeholder="Digite o limite populacional"
                  defaultValue={0}
                  disabled={PopulationModel.isDisabledPopulationLimitInput(user.level)}
                />
              </Form.Item>

              {PopulationModel.showPopulationSubmtiButton(user.level) && (
                <SettingsCard.ButtonsContainer>
                  <SettingsCard.submitButton loading={loading.button}>
                  Salvar
                  </SettingsCard.submitButton>
                </SettingsCard.ButtonsContainer>
              )}
            </Form.Item>
          </Form>

          <SettingsHistory
            visible={visible}
            onChangeVisible={onChangeVisible}
            title="Log de alteração"
            description="Para manter o controle das alterações, aqui você pode conferir as alterações que foram feitas no limite populacional">
            <ShowHistory history={logs}
              historyLoading={loading.history}
            />
            <Pagination
              total={totalDocs}
              current={params.page}
              onChange={page => setParams(prev => ({ ...prev, page }))}
              pageSize={Number(params.limit)}
            />
          </SettingsHistory>

        </LoadingOrEmpty>
      </SettingsCard>

    </>
  )
}

export default Population

import React from 'react'
import { Form, Select } from 'antd'
import { serverTableSelectProps } from 'utils/globals'

function FilterProposalStatus ({ onChange, defaultValue }: { onChange: (value: string | string[] | undefined) => void, defaultValue?: string[] }) {
  return (
    <Form.Item
      name="proposalStatus"
      label={<label className="ml-1">Status da proposta</label>}
    >
      <Select
        {...serverTableSelectProps}
        allowClear
        placeholder="Escolha o status"
        defaultValue={defaultValue}
        onChange={onChange}
      >
        <Select.Option value="ongoing">Em andamento</Select.Option>
        <Select.Option value="approved">Aprovado</Select.Option>
        <Select.Option value="effectivated">Efetivada</Select.Option>
        <Select.Option value="reproved">Reprovado</Select.Option>
        <Select.Option value="canceled">Cancelado</Select.Option>
        <Select.Option value="inactive">Inativo</Select.Option>
      </Select>
    </Form.Item>
  )
}

export default FilterProposalStatus

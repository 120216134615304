import React, { Fragment, useEffect, useState } from 'react'
import { BasicCreditProps } from '../../creditApproveInterfaces'
import { proposals, unauthed } from 'egi/consumers'
import { Button, Col, Empty, Row, Skeleton, Table } from 'antd'
import { CreditErrorIcon, CreditSuccessIcon } from '../../views/CreditApprove'
import { timeAsDayjs } from 'utils/time'
import format from 'utils/format'
import { useAuth, useClient, useProposal, useModal } from 'hooks'
import { useDispatch } from 'react-redux'
import { setClientApproval } from 'store/modules/client/actions'
import { CreditStatus } from 'egi/consumers/unauthed'
import translate from 'utils/translate'
import swal from 'utils/swal'
import { modalReset } from 'store/modules/modal/actions'
import { WarningFilled } from '@ant-design/icons'
import columns from 'egi/app/Simulator/components/dataTable'
import StringToHtml from 'components/StringToHtml/StringToHtml'
import CreditModel from 'egi/models/CreditModel'
import { IStepHistory } from 'components/ReviewBackoffice/reviewBackofficeInterfaces'
import ReviewStepHistory from 'components/ReviewStepHistory/ReviewStepHistory'

function ApprovedAuth ({ credit, deadline, tax, conditional, conditionalMessage }: { credit: string, deadline: number, tax: number, conditional: string | null, conditionalMessage: string | null }) {
  return (
    <div className="wrapper-single-line-table w-100">
      <div className="flex w-100">
        <p className="flex-1 mt-1 mb-1 ml-1 color-primary simulator-resume-sub-titles">Valor de crédito</p>
        <p className="flex-1 mt-1 mb-1 ml-1 simulator-label">{credit}</p>
      </div>

      <div className="flex w-100">
        <p className="flex-1 mt-1 mb-1 ml-1 color-primary simulator-resume-sub-titles">Prazo</p>
        <p className="flex-1 mt-1 mb-1 ml-1 simulator-label">{deadline} meses</p>
      </div>

      <div className="flex w-100">
        <p className="flex-1 mt-1 mb-1 ml-1 color-primary simulator-resume-sub-titles">Taxa</p>
        <p className="flex-1 mt-1 mb-1 ml-1 simulator-label">{tax.toFixed(2)} %</p>
      </div>

      <div className="w-100">
        <p className="mt-1 mb-1 ml-1 color-primary simulator-resume-sub-titles">Condicionantes</p>
        <p className="mt-1 mb-1 ml-1 simulator-label">
          {(conditional && conditionalMessage) && (
            <div className="mt-3 credit-choose-step-render-contionals">
              <StringToHtml value={conditionalMessage} />
            </div>
          )}
        </p>
      </div>
    </div>
  )
}

function CreditChooseStep ({ changeCurrent, changeUserResponse, token }: BasicCreditProps) {
  const [pageLoading, setPageLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingHistories, setLoadingHistories] = useState<boolean>(false)
  const [showParcels, setShowParcels] = useState<boolean>(false)
  const [showCreditApprovalHistory, setShowCreditApprovalHistory] = useState<boolean>(false)
  const [creditApprovalHistories, setCreditApprovalHistories] = useState<Array<IStepHistory>>([])

  const dispatch = useDispatch()
  const client = useClient()
  const user = useAuth()
  const proposal = useProposal()
  const modal = useModal()
  const parcels = proposal.simulation?.parcels

  async function fetchClient () {
    if (!token) return

    setPageLoading(true)
    try {
      const response = await unauthed.creditApprove.creditInfo({ token: token as string })
      const clientInfo = response.data

      if (clientInfo.clientApproval) dispatch(setClientApproval(clientInfo.clientApproval))
      else dispatch(setClientApproval(clientInfo))
    } catch (err) {
      if (err.message) {
        changeUserResponse({ title: 'Atenção', text: err.message, icon: CreditErrorIcon })
        changeCurrent(2)
      }
      console.warn(err)
    }
    setPageLoading(false)
  }

  async function getClientApprovalHistory () {
    setLoadingHistories(true)
    try {
      const response = await proposals.creditApprovalHistory({ proposalId: String(proposal.id) })
      const histories = response.data.histories
      setCreditApprovalHistories(histories)
      setLoadingHistories(false)
    } catch (err) {
      setLoadingHistories(false)
      console.warn(err)
      swal.basic({ icon: 'warning', title: 'Atenção', text: err.message })
    }
  }

  async function sendClientResponse () {
    let request

    if (!token && proposal.id) {
      const body = {
        proposalId: proposal.id,
        data: { status: 'approved' as CreditStatus }
      }
      request = proposals.creditResponse(body)
    }

    if (token && !proposal.id) {
      const body = {
        token: token as string,
        status: 'approved' as CreditStatus
      }
      request = unauthed.creditApprove.creditResponse(body)
    }

    setLoading(true)
    try {
      const response = await request

      const info = { title: 'Sucesso', text: response.message }

      if (proposal.id) {
        if (proposal.callback) proposal.callback()
        swal.basic({ ...info, icon: 'success' })
        dispatch(modalReset())
      }
      if (!proposal.id) {
        changeUserResponse({ ...info, icon: CreditSuccessIcon })
        changeCurrent(2)
      }
    } catch (err) {
      if (err.response) {
        const errorInfo = { title: 'Atenção', text: err.message }

        if (proposal.id) swal.basic({ ...errorInfo, icon: 'warning' })
        if (!proposal.id) {
          changeUserResponse({ ...errorInfo, icon: CreditErrorIcon })
          changeCurrent(2)
        }

        return
      }

      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    }
    setLoading(false)
  }

  async function sendClientRemake () {
    if (!proposal.id) return

    const body = {
      proposalId: proposal.id,
      data: { status: 'waiting' as CreditStatus }
    }

    setLoading(true)
    try {
      const response = await proposals.creditResponse(body)

      const info = { title: 'Sucesso', text: response.message }
      if (proposal.id) {
        if (proposal.id) {
          if (proposal.callback) proposal.callback()
          swal.basic({ ...info, icon: 'success' })
          dispatch(modalReset())
        }
      }
      if (!proposal.id) {
        changeUserResponse({ ...info, icon: CreditSuccessIcon })
        changeCurrent(2)
      }
    } catch (err) {
      if (err.response) {
        const errorInfo = { title: 'Atenção', text: err.message }

        if (proposal.id) swal.basic({ ...errorInfo, icon: 'warning' })
        if (!proposal.id) {
          changeUserResponse({ ...errorInfo, icon: CreditErrorIcon })
          changeCurrent(2)
        }
      }
    }
    setLoading(false)
  }

  function showHistories () {
    setShowCreditApprovalHistory(true)
    getClientApprovalHistory()
  }

  useEffect(() => {
    if (!modal.visible) {
      setShowParcels(false)
      setShowCreditApprovalHistory(false)
    }
  }, [modal.visible])

  useEffect(() => {
    fetchClient()
  }, [])

  return (
    <Fragment>
      {pageLoading ? (
        <Skeleton avatar paragraph={{ rows: 7 }} />
      ) : (
        <Fragment>
          {showParcels ? (
            <Fragment>
              <Table
                className="simulator-table mt-2"
                columns={columns}
                dataSource={parcels || client.clientApproval?.parcels}
                pagination={{ responsive: true }}
                scroll={{ x: 400 }}
              />
              <label className="simulator-label">*O valor das parcelas estará sujeito à correção do IPCA no período</label>
              <div className="flex flex-justify-center">
                <p
                  className="link-style mb-0 mt-3"
                  style={{ fontSize: '1.1rem' }}
                  onClick={() => setShowParcels(false)}
                >
                  ver dados de aprovação
                </p>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              {(client.clientApproval) && (
                <Fragment>
                  {(client.clientApproval.status === 'approved' || client.clientApproval.status === 'reproved') && (
                    <Fragment>
                      {showCreditApprovalHistory ? (
                        <Fragment>
                          <div style={{ maxHeight: 600, overflowY: 'auto', overflowX: 'auto' }} className="pt-1">
                            {loadingHistories ? (
                              <Skeleton loading={loadingHistories} active />
                            ) : (
                              <Fragment>
                                {creditApprovalHistories.length < 1 && (
                                  <Empty description="Nenhum histórico encontrado." className="mb-2" />
                                )}

                                <ReviewStepHistory stepArray={creditApprovalHistories} mode='left' />
                              </Fragment>
                            )}
                          </div>
                          <div className="flex flex-justify-center">
                            <p
                              className="link-style mb-0 mt-2"
                              style={{ fontSize: '1.1rem' }}
                              onClick={() => setShowCreditApprovalHistory(false)}
                            >
                              ver dados de aprovação
                            </p>
                          </div>
                        </Fragment>
                      ) : (
                        <aside className="text-center">
                          { CreditSuccessIcon }
                          <h1 className="color-primary credit-modal-title my-6">Olá {client.name || ''}</h1>

                          {client.clientApproval.status === 'approved' && (
                            <Fragment>
                              <div className="mt-3">
                                <p className="credit-modal-description credit-modal-description-margin">Sua resposta já foi enviada. Data: <strong>{timeAsDayjs(client.clientApproval.approvedAt, { applyTimezone: false }).format('DD/MM/YYYY HH:mm')}</strong>.</p>
                                <p className="credit-modal-description credit-modal-description-margin">Resposta: <strong>{translate.creditApprovalStatus(client.clientApproval.status)}</strong></p>
                              </div>

                              {client.clientApproval.credit && (
                                <div className="mb-3 flex flex-justify-center">
                                  <div className="w-75">
                                    <ApprovedAuth
                                      credit={format.formatBRL(client.clientApproval.credit || 0)}
                                      deadline={client.clientApproval.deadline || 0}
                                      tax={format.decimalToPercentage(client.clientApproval.tax || 0)}
                                      conditional={client.clientApproval.conditional}
                                      conditionalMessage={client.clientApproval.message}
                                    />

                                    {CreditModel.canSeeHistory(user.level, user.isAuth ?? false) && (
                                      <p
                                        className="link-style mb-0 mt-2"
                                        style={{ fontSize: '1.1rem' }}
                                        onClick={() => showHistories()}
                                      >
                                        ver histórico de aprovação
                                      </p>
                                    )}
                                  </div>
                                </div>
                              )}
                            </Fragment>
                          )}

                          {client.clientApproval.status === 'reproved' && (
                            <div className="mt-3">
                              <p className="credit-modal-description credit-modal-description-margin">Sua resposta já foi enviada. Data: <strong>{timeAsDayjs(client.clientApproval.reprovedAt, { applyTimezone: false }).format('DD/MM/YYYY HH:mm')}</strong>.</p>
                              <p className="credit-modal-description credit-modal-description-margin">Resposta: <strong>{translate.creditApprovalStatus(client.clientApproval.status)}</strong></p>
                              <p className="credit-modal-description credit-modal-description-margin">Motivo: <strong>{client.clientApproval.reprovedReason}</strong></p>
                              {client.clientApproval.otherReprovedReason && <p className="credit-modal-description credit-modal-description-margin">Mensagem: <strong>{client.clientApproval.otherReprovedReason}</strong></p>}
                              {CreditModel.canSeeHistory(user.level, user.isAuth ?? false) && (
                                <p
                                  className="link-style mb-2"
                                  style={{ fontSize: '1.1rem' }}
                                  onClick={() => showHistories()}
                                >
                                  ver histórico de aprovação
                                </p>
                              )}
                            </div>
                          )}

                          {CreditModel.canRemakeApprove(user.level) && (
                            <Button
                              type="default"
                              className="unauth-button credit-base-button w-50"
                              loading={loading}
                              onClick={() => sendClientRemake()}
                            >
                              MANDAR PARA REFAZER
                            </Button>
                          )}
                        </aside>
                      )}
                    </Fragment>
                  )}

                  {(client.clientApproval.status === 'waiting') && (
                    <Fragment>
                      <aside className="text-center">
                        <h1 className="color-primary credit-modal-title">Olá, {client.name || client.clientApproval.name}</h1>
                        <p className="credit-modal-description credit-modal-description-margin">Temos uma ótima notícia. Seu crédito foi aprovado!</p>
                        <p className="simulator-resumes-titles credit-approve-modal-title">Dados de aprovação</p>
                      </aside>

                      <div className="flex flex-justify-center">
                        <article className="w-75">
                          <ApprovedAuth
                            credit={format.formatBRL(client.clientApproval.credit || 0)}
                            deadline={client.clientApproval.deadline || 0}
                            tax={format.decimalToPercentage(client.clientApproval.tax || 0)}
                            conditional={client.clientApproval.conditional}
                            conditionalMessage={client.clientApproval.message}
                          />

                          <div className="flex flex-justify-center">
                            <p
                              className="link-style mb-0 mt-3"
                              style={{ fontSize: '1.1rem' }}
                              onClick={() => setShowParcels(true)}
                            >
                            ver todas as parcelas
                            </p>
                          </div>

                          {CreditModel.canApprove(user.level, user.isAuth ?? false) ? (
                            <Fragment>
                              <h3 className="text-center credit-modal-progress">Você deseja prosseguir com a contratação ?</h3>

                              <Row gutter={[15, 15]}>
                                <Col xxl={12} xl={24} lg={24} md={24} sm={24} xs={24}>
                                  <Button
                                    type="default"
                                    className="unauth-button credit-base-button w-100"
                                    loading={loading}
                                    onClick={() => sendClientResponse()}
                                  >
                                  SIM
                                  </Button>
                                </Col>

                                <Col xxl={12} xl={24} lg={24} md={24} sm={24} xs={24}>
                                  <Button
                                    type="ghost"
                                    className="unauth-button credit-ghost-button w-100"
                                    onClick={() => changeCurrent(1)}
                                  >
                                  NÃO
                                  </Button>
                                </Col>
                              </Row>
                            </Fragment>
                          ) : (
                            <div className="flex flex-align-center flex-justify-center my-6">
                              <h1 className="color-secondary credit-modal-title"><WarningFilled className="mr-2" />Pendente de aceite</h1>
                            </div>
                          )}
                        </article>
                      </div>
                    </Fragment>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

export default CreditChooseStep

import { ArrowRightOutlined, CheckOutlined, CrownOutlined, LoadingOutlined, TeamOutlined } from '@ant-design/icons'
import { Button, Empty, Modal, Pagination, Switch, Tabs, Tag, Timeline, Tooltip } from 'antd'
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import mask from 'utils/masks'
import { timeAsDayjs } from 'utils/time'
import { IDetails, ILead, IProposal } from 'egi/app/User/userInterfaces'
import mainColumns from 'egi/views/UsersTable/DataTables'
import translate from 'utils/translate'
import { useDispatch } from 'react-redux'
import { tablesResetFiltersValues } from 'store/modules/tables/actions'
import { setIsTableRouteConfig } from 'store/modules/system/actions'
import UserDetailsSMSTable from 'egi/app/User/components/UserDetailsSMSTable/UserDetailsSMSTable'
import UserDetailsEmailTable from 'egi/app/User/components/UserDetailsEmailTable/UserDetailsEmailTable'
import UserDetailsSimulationTable from 'egi/app/User/components/UserDetailsSimulationTable/UserDetailsSimulationTable'
import UserDetailsChangeEmailModal from 'egi/app/User/components/UserDetailsChangeEmailModal/UserDetailsChangeEmailModal'
import { varColors } from 'styles/colors'
import { useAuth } from 'hooks'
import dayjs, { Dayjs } from 'dayjs'
import swal from 'utils/swal'
import LGPDModalText from 'egi/app/LGPD/components/LGPDModalText/LGPDModalText'
import UsersTable from 'egi/views/UsersTable/UsersTable'
import { USER_DETAILS_CLIENT_CANCEL, USER_DETAILS_USER_CANCEL } from 'egi/app/User/userConstants'
import UserModels from 'egi/models/UserModels'
import LetterAvatar from 'ecp/components/LetterAvatar/LetterAvatar'
import ClientRepository from 'egi/repositories/ClientRepository'
import UsersEgiRepository from 'egi/repositories/UsersEgiRepository'
import AdminModel from 'egi/models/AdminModel'
import { BackofficeIconProductButton } from 'egi/app/AccessManager/components/BackofficeProductsButton/BackofficeProductsButton'
import { PermissionModel } from 'models/PermissionModel'
import { UserDetailsPermissionsList } from '../../components/UserDetailsPermissionsList/UserDetailsPermissionsList'

type ControlLoaderEmpty = {
  loaderElement: React.ReactElement
  loading: boolean
  emptyElement: React.ReactElement
  isEmpty: boolean
  component: React.ReactElement
}

interface ILogs {
  _id: string
  type: string
  last: {
    name: string
  }
  new: {
    name: string
  }
  modifiedBy: {
    name: string
    level: string
  }
  createdAt: string | Dayjs
}

export const ControlLoadingEmpty: FunctionComponent<ControlLoaderEmpty> = ({ loading, isEmpty, component, emptyElement, loaderElement }) => {
  if (loading) return loaderElement
  if (isEmpty) return emptyElement
  return component
}

const valueOrNa = <T, >(value: T) => value || 'n/a'

function TabLineContent ({ label, value }: { label: string | ReactNode, value: string | ReactNode }) {
  return (
    <div className="user-details__info-tabs-content mt-5">
      <p className="user-details__info-tabs-label">{label}</p>
      <p className="user-details__info-tabs-information">
        {value}
      </p>
    </div>
  )
}

function TimelineLogs ({ error, logs }: { error: string | undefined, logs: ILogs[] | undefined }) {
  if (error) {
    return (
      <Empty description={error} />
    )
  }

  return (
    <>
      <Timeline>
        {(logs || []).map((log) => {
          return (
            <Timeline.Item key={log?.type} color="green">
              <p>Modificado por <b>{log?.modifiedBy?.name}</b> ({translate.level(log?.modifiedBy?.level)})</p>
              <p>Data: {timeAsDayjs(log?.createdAt).format('DD/MM/YYYY HH:mm')}&nbsp;</p>
              {(typeof log?.last !== 'object' && typeof log?.new !== 'object')
                ? <p> {log?.last ?? '-'} <ArrowRightOutlined /> {log.new ?? '-'}</p> : <p> {log?.last.name ??
                  '-'} <ArrowRightOutlined /> {log.new.name ?? '-'}</p>
              }
            </Timeline.Item>
          )
        })}
      </Timeline>

    </>
  )
}

function UserDetails () {
  const [status, setStatus] = useState<'loading' | 'failed' | undefined>(undefined)
  const { userId } = useParams<{ userId?: string }>()
  const [blocked, setBlocked] = useState<boolean>(false)
  const [visible, setVisible] = useState<'change-email' | 'terms' | undefined>(undefined)
  const [hasTeam, setHastTeam] = useState(false)
  const [teamLink, setTeamLink] = useState('')
  const [isUsersTab, setIsUsersTab] = useState(false)
  const [details, setDetails] = useState<IDetails>()
  const [lead, setLead] = useState<ILead>()
  const [proposal, setProposal] = useState<IProposal>()
  const [canAcces, setCanAcces] = useState<boolean>(false)
  const [reloadPage, setReloadPage] = useState<boolean>(false)
  const [errorEmailLogs, setErrorEmailLogs] = useState<string>('')
  const [errorTransferLogs, setErrorTransferLogs] = useState<string>('')
  const [emailLogs, setEmailLogs] = useState<ILogs[]>()
  const [transferLogs, setTransferLogs] = useState<ILogs[]>()
  const [totalTransferLogs, setTotalTransferLogs] = useState<number>(0)

  const dispatch = useDispatch()
  const user = useAuth()

  const checkedEmail = details?.emailVerified && !details?.canceled
  const canCancelUser = !details?.canceled && UserModels.canCancelUser({ userLevel: user.level, levelToCompare: details?.level || '' })

  useEffect(() => {
    const abortController = new AbortController()
    async function fetchDetails (id: string) {
      setStatus('loading')
      try {
        const response = await UsersEgiRepository.getDetails(id, {
          signal: abortController.signal
        })
        const details = response.data.data.userDetails
        const { level, name, type } = details

        setHastTeam(UserModels.hasTeam(level, type))
        setTeamLink(UserModels.teamLink({ level, userId, userName: name }))

        dispatch(setIsTableRouteConfig(false))
        if (Object.keys(details).length < 1) return setStatus('failed')

        setDetails(details)
        setBlocked(details.blocked)
      } catch (err) {
        console.error(err)
      }
      setStatus(undefined)
    }

    if (userId) {
      fetchDetails(userId)
    }
    return () => abortController.abort()
  }, [userId, reloadPage])

  useEffect(() => {
    const abortController = new AbortController()
    async function getClientLead (id: string) {
      setStatus('loading')
      try {
        const response = await ClientRepository.getLeads(id, {
          signal: abortController.signal
        })

        const { leadInfo } = response.data.data || {}
        setLead(leadInfo)
      } catch (err) {
        console.error(err)
      }
      setStatus(undefined)
    }

    async function getClientProposal (id: string) {
      setStatus('loading')
      try {
        const response = await ClientRepository.getProposal(id, {
          signal: abortController.signal
        })

        const { proposalInfo } = response.data.data || {}
        setProposal(proposalInfo)
      } catch (err) {
        console.error(err)
      }
      setStatus(undefined)
    }

    if (details?.level === 'client' && details._id) {
      getClientLead(details._id)
      getClientProposal(details._id)
    }

    if (details?.blocked) setBlocked(details.blocked)
    setCanAcces(Boolean(hasTeam || details?.administratorId || details?.promoterId))

    return () => abortController.abort()
  }, [details])

  const getTransferLogs = async (id: string, page: number) => {
    try {
      const params = {
        page: page
      }
      const response = await UsersEgiRepository.transferLogs(id, { params })
      setTransferLogs(response.data.data.histories.docs)
      setTotalTransferLogs(response.data.data.histories.totalDocs)
      if (response.data.data.histories.docs.length === 0) throw new Error('Não foram encontradas alterações')
    } catch (err) {
      setErrorTransferLogs(err.message)
    }
  }

  useEffect(() => {
    const getEmailLogs = async (id: string) => {
      try {
        const response = await UsersEgiRepository.emailLogs(id)
        setEmailLogs(response.data.data.logs)
        if (response.data.data.logs.length === 0) throw new Error('Não foram encontradas alterações')
      } catch (err) {
        setErrorEmailLogs(err.message)
      }
    }

    if (userId) {
      getEmailLogs(userId)
      getTransferLogs(userId, 1)
    }
  }, [])

  const blockId = (id: string) => {
    swal.confirmNegate({
      title: 'Atenção',
      text: `Você realmente deseja ${blocked ? 'desbloquear' : 'bloquear'} esse usuário?`,
      confirmButtonText: `${blocked ? 'Desbloquear' : 'Bloquear'}`,
      cancelButtonText: 'Cancelar',
      confirm: async () => {
        try {
          const response = await UsersEgiRepository.block(id)
          setBlocked(prev => !prev)
          swal.basic({ title: 'Sucesso!', text: response.data.message, icon: 'success' })
        } catch (err) {
          console.error(err)
          swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
        }
      }
    })
  }

  const cancelClient = async (id: string) => {
    const finalText = details?.level === 'client' ? USER_DETAILS_CLIENT_CANCEL : USER_DETAILS_USER_CANCEL

    swal.confirmNegate({
      title: 'Atenção',
      text: finalText,
      icon: 'warning',
      confirm: async () => {
        try {
          const response = await UsersEgiRepository.cancel(id)
          setReloadPage(true)
          swal.basic({ title: 'Sucesso!', text: response.data.message, icon: 'success' })
        } catch (err) {
          console.error(err)
          swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
        }
      }
    })
  }

  function removeOld (text: string) {
    return (text.replace('_old', ''))
  }

  function validateDate (date: string | dayjs.Dayjs | undefined) {
    if (date && dayjs(date).isValid()) return timeAsDayjs(date, { applyTimezone: false }).format('DD/MM/YYYY')
    return '-'
  }

  async function handleInviteUpdate ({ userId, inviteAdmin }: { userId?: string, inviteAdmin: boolean }) {
    try {
      if (!userId) throw new Error('Não foi possível obter o ID do usuário.')
      const reqBody = { inviteAdmin }
      const response = await UsersEgiRepository.updateProfile(userId, reqBody)
      setDetails(prev => ({ ...prev, inviteAdmin } as any))
      swal.basic({ title: 'Sucesso!', text: response.data.message, icon: 'success' })
    } catch (err) {
      console.error(err)
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    }
  }

  return (
    <>
      <Modal
        className="user-details__lgpd-modal"
        data-cy='modal-terms'
        title='Termos de uso'
        closable
        visible={visible === 'terms'}
        footer={null}
        onCancel={() => setVisible(undefined)}
        centered
        okText='Eu li e concordo'
        cancelText='Cancelar'
        width={800}
      >
        <LGPDModalText />
      </Modal>

      <UserDetailsChangeEmailModal
        visible={visible === 'change-email'}
        onClose={() => setVisible(undefined)}
        onSuccess={() => setVisible(undefined)}
        userInfo={{
          email: details?.email,
          id: details?._id,
          name: details?.name
        }}
      />

      <section className="user-details">
        <div className="user-details__perfil mt-5">
          <ControlLoadingEmpty
            loading={status === 'loading'}
            loaderElement={<div className="user-details__perfil-img-container"><LoadingOutlined /></div>}
            isEmpty={status === 'failed'}
            emptyElement={<> </>}
            component={
              <div className="user-details__perfil-img-container">
                {details?.selfie ? (
                  <img
                    src={details.selfie}
                    width="250"
                    height='250'
                  />
                ) : (
                  <LetterAvatar
                    name={details?.name}
                    size='large'
                  />
                )}
              </div>
            }
          />
          <ControlLoadingEmpty
            loading={status === 'loading'}
            loaderElement={
              <div className="user-details__perfil-name-container">
                <p className="user-details__perfil-name mb-0">loading...</p>
              </div>
            }
            isEmpty={status === 'failed'}
            emptyElement={<></>}
            component={
              <>
                {details?.name ? (
                  <div className="user-details__perfil-name-container">
                    <p className="user-details__perfil-name mb-0">{details?.name}</p>
                  </div>
                ) : (
                  <div className="user-details__perfil-name-container">
                    <p className="user-details__perfil-name mb-0">Usuários não encontrado</p>
                  </div>
                )}
              </>
            }
          />

          <ControlLoadingEmpty
            loading={status === 'loading'}
            loaderElement={
              <>
                <div className="user-details__perfil-switch-loading mt-1"></div>
              </>
            }
            isEmpty={status === 'failed'}
            emptyElement={<> </>}
            component={
              <>
                {canCancelUser &&
                  <Button
                    className="user-details__perfil-cancel-button"
                    onClick={() => details?._id && cancelClient(details._id)}
                  >
                    Cancelar Usuário
                  </Button>
                }

                <Switch
                  className="user-details__perfil-switch"
                  defaultChecked={blocked}
                  checkedChildren='Bloqueado'
                  unCheckedChildren='Bloquear'
                  loading={status === 'loading'}
                  checked={blocked}
                  onChange={() => {
                    if (details?._id) blockId(details._id)
                  }}
                />
              </>
            }
          />

          <ControlLoadingEmpty
            loading={status === 'loading'}
            loaderElement={
              <>
                <div className="user-details__perfil-team-admim-container user-details__perfil-team-admim-container-loading mt-3"></div>
              </>
            }
            isEmpty={status === 'failed'}
            emptyElement={<> </>}
            component={
              <>
                {canAcces &&
                  <div className="user-details__perfil-team-admim-container mt-3">
                    {(hasTeam && user.level === 'master') &&
                      <div className="user-details__perfil-team-adm ">
                        <TeamOutlined /><Link className="ml-2" to={teamLink}>Time do usuário</Link>
                      </div>
                    }
                    {(details?.administratorId && user.level === 'master') &&
                      <div className="user-details__perfil-team-adm ">
                        <CrownOutlined /><Link className="ml-2" to={`/auth/details/user/${details.administratorId}`}>Administrador do usuário</Link>
                      </div>
                    }
                    {details?.promoterId &&
                      <div className="user-details__perfil-team-adm ">
                        <CrownOutlined /><Link className="ml-2" to={`/auth/details/user/${details.promoterId}`}>Parceiro do usuário</Link>
                      </div>
                    }
                  </div>
                }
              </>
            }
          />
        </div>

        <div className="user-details__info">
          <ControlLoadingEmpty
            loading={status === 'loading'}
            loaderElement={<div className="user-details__info-skeleton mt-2"></div>}
            isEmpty={status === 'failed'}
            emptyElement={<> </>}
            component={
              <Tabs className="user-details__info-tabs" size='large' centered>
                <Tabs.TabPane tab="Dados de acesso" key="access" className="user-details__info-tab-pane">
                  <p className="user-details__info-tabs-header mt-2">Perfil</p>

                  <div className="user-details__info-tabs-container">

                    {details?.email &&
                      <TabLineContent
                        label='Email'
                        value={
                          <b className='link-style color-tertiary' onClick={() => setVisible('change-email')}>{valueOrNa(removeOld(details?.email))}
                            {checkedEmail &&
                              <CheckOutlined className="ml-2 color-tertiary" />
                            }
                          </b>
                        }
                      />
                    }

                    <TabLineContent
                      label='Email verificado'
                      value={<b>{details?.emailVerified ? 'Sim' : 'Não'}</b>}
                    />

                    <TabLineContent
                      label='CPF'
                      value={<b>{valueOrNa(mask(String(details?.cpf), 'cpf', true))}</b>}
                    />

                    {details?.level &&
                      <TabLineContent
                        label='Level de usuário'
                        value={<b>{valueOrNa(translate.level(details.level))}</b>}
                      />
                    }

                    {details?.areaName &&
                      <TabLineContent
                        label='Área'
                        value={<b>{valueOrNa(details?.areaName)}</b>}
                      />
                    }

                    {details?.canceled &&
                      <TabLineContent
                        label='Status'
                        value={<Tag color='var(--refused-status-color)'>Cancelado</Tag>}
                      />
                    }

                    {UserModels.hasInviteSwitch(details?.level || '') &&
                      <TabLineContent
                        label='Permitir o convite de administradores'
                        value={
                          <Switch
                            className="mb-1"
                            defaultChecked={false}
                            checkedChildren='Sim'
                            unCheckedChildren='Não'
                            loading={status === 'loading'}
                            checked={details?.inviteAdmin}
                            onChange={e => handleInviteUpdate({ userId: details?._id, inviteAdmin: e })}
                          />
                        }
                      />
                    }

                  </div>
                </Tabs.TabPane>

                <Tabs.TabPane tab="Dados de Cadastro" key="register" className="user-details__info-tab-pane">
                  <p className="user-details__info-tabs-header mt-2">Dados de Cadastro</p>

                  <div className="user-details__info-tabs-container">
                    <TabLineContent
                      label='Nome de usuário'
                      value={<b>{valueOrNa(details?.name)}</b>}
                    />

                    <TabLineContent
                      label='CPF'
                      value={<b>{valueOrNa(mask(String(details?.cpf), 'cpf', true))}</b>}
                    />

                    <TabLineContent
                      label='CNPJ'
                      value={<b>{valueOrNa(mask(String(details?.cnpj), 'cnpj', true))}</b>}
                    />

                    <TabLineContent
                      label='Telefone'
                      value={<b>{valueOrNa(mask(String(details?.cellphone), 'phone', true))}</b>}
                    />

                    <TabLineContent
                      label='Data de nascimento'
                      value={<b>{validateDate(details?.birthdate)}</b>}
                    />

                    <TabLineContent
                      label='Data de criação'
                      value={<b>{validateDate(details?.createdAt)}</b>}
                    />

                    <TabLineContent
                      label='Data de atualização'
                      value={<b>{validateDate(details?.updatedAt)}</b>}
                    />

                    {details?.gender &&
                      <TabLineContent
                        label='Gênero'
                        value={<b>{valueOrNa(translate.gender(details.gender))}</b>}
                      />
                    }
                  </div>
                </Tabs.TabPane>

                {details?.lastLogin &&
                  <Tabs.TabPane tab="Informações de acesso" key="accesInfo" className="user-details__info-tab-pane">
                    <p className="user-details__info-tabs-header mt-2">Acesso</p>

                    <div className="user-details__info-tabs-container">
                      <TabLineContent
                        label='Último login'
                        value={<b>{validateDate(details?.lastLogin)}</b>}
                      />
                    </div>
                  </Tabs.TabPane>
                }

                {details?.term && details?.lgpd &&
                  <Tabs.TabPane tab="LGPD" key="lgpd" className="user-details__info-tab-pane">
                    <p className="user-details__info-tabs-header mt-2">
                      Termos aceitos pelo usuário: <a onClick={() => setVisible('terms')}><span className="color-tertiary">termos de uso</span></a>
                    </p>

                    <div className="user-details__info-tabs-container">
                      <TabLineContent
                        label='Status'
                        value={
                          <div className='mb-1'>
                            <b>{details?.term
                              ? <Tag color='var(--approved-status-color)'>Aceito</Tag>
                              : <Tag color='var(--refused-status-color)'>Não aceito</Tag>}
                            </b>

                            <b>
                              {details?.lgpd.type === 'proposal' &&
                                <Tooltip title="Aprovado pela proposta" destroyTooltipOnHide={{ keepParent: false }}>
                                  <Tag color='var(--approved-status-color)'>Proposta</Tag>
                                </Tooltip>
                              }
                            </b>
                          </div>
                        }
                      />

                      <TabLineContent
                        label='Código de assinatura'
                        value={<b>{valueOrNa(details?.lgpd?.signatureCode)}</b>}
                      />

                      <TabLineContent
                        label='Aceito em'
                        value={
                          <b>
                            {details.lgpd?.signatureDate && dayjs(details.lgpd?.signatureDate).isValid()
                              ? <>{timeAsDayjs(details.lgpd?.signatureDate).format('DD/MM/YYYY')} às {timeAsDayjs(details.lgpd?.signatureDate).format('HH:mm:ss')}</>
                              : '-'
                            }
                          </b>
                        }
                      />
                    </div>
                  </Tabs.TabPane>
                }

                <Tabs.TabPane tab="Troca de email" key="email" className="user-details__info-tab-pane">
                  <h3 className="user-details__info-tabs-header my-3">Log de alteração de email</h3>
                  <TimelineLogs error={errorEmailLogs} logs={emailLogs} />
                </Tabs.TabPane>

                {UserModels.canEditProducts(user.level) && (
                  <Tabs.TabPane
                    tab="Produtos"
                    key="products"
                    className="user-details__info-tab-pane"
                  >
                    <TabLineContent
                      label='Produtos'
                      value={
                        <div className='user-details__products-tags'>
                          {details?.products && Array.isArray(details?.products) &&
                            <>
                              {details?.products.map((product, idx) => {
                                return (
                                  <Tag className='user-details__products-tag' key={idx}>
                                    {translate.productSlugToSigla(product)}
                                  </Tag>
                                )
                              })}
                            </>
                          }
                          <div>
                            <BackofficeIconProductButton
                              userId={details?._id}
                              onFinish={() => setReloadPage(prev => !prev)}
                              products={details?.products || []}
                            />
                          </div>
                        </div>
                      }
                    />
                  </Tabs.TabPane>
                )}

                {AdminModel.canSeeTransferLog(user.level) && <Tabs.TabPane tab="Transferências" key="admin" className="user-details__info-tab-pane">
                  <h3 className="user-details__info-tabs-header my-3">Transferências de administradores</h3>
                  <TimelineLogs error={errorTransferLogs} logs={transferLogs} />
                  <Pagination onChange={page => { if (userId) getTransferLogs(userId, page) }} total={totalTransferLogs} />
                </Tabs.TabPane>
                }

                {(details?.level === 'client' && lead) &&
                  <Tabs.TabPane tab="Dados do Lead" key="lead" className="user-details__info-tab-pane">
                    <p className="user-details__info-tabs-header mt-2">Lead</p>
                    <div className="user-details__info-tabs-container">

                      {lead.promoterId &&
                        <TabLineContent
                          label='Dados do telemarketing'
                          value={
                            <Link to={`/auth/details/user/${lead.promoterId}`}><b className="color-tertiary">{valueOrNa(lead.telemarketing?.name)}</b></Link>
                          }
                        />
                      }

                      <TabLineContent
                        label='Status do Lead'
                        value={<Tag color={varColors[lead.status]}><b>{valueOrNa(translate.leadStatus(lead.status))}</b></Tag>}
                      />

                      <TabLineContent
                        label='Histórico de contato do Lead'
                        value={
                          <b>
                            <Link to={`/auth/leads/history/${lead._id}`} className="color-tertiary">
                              Histórico de contato
                            </Link>
                          </b>
                        }
                      />

                      <TabLineContent
                        label='E-mail do lead'
                        value={<b>{valueOrNa(lead.email)}</b>}
                      />

                      <TabLineContent
                        label='Data de criação do Lead '
                        value={<b>{validateDate(lead?.createdAt)}</b>}
                      />

                      <TabLineContent
                        label='Data de criação do Lead '
                        value={<b>{validateDate(lead?.createdAt)}</b>}
                      />

                      <TabLineContent
                        label='Pré aprovação'
                        value={<b>{lead.crivoId ? 'Possui' : 'Não possui'}</b>}
                      />

                      {(lead.crivoId && lead.preApproval) &&
                        <TabLineContent
                          label='Status da pré aprovação'
                          value={<Tag color={varColors[lead.preApproval]}><b>{valueOrNa(translate.preApproval(lead.preApproval))}</b></Tag>}
                        />
                      }
                    </div>
                  </Tabs.TabPane>
                }

                {(details?.level === 'client' && proposal) &&
                  <Tabs.TabPane tab="Dados da proposta" key="proposal" className="user-details__info-tab-pane">
                    <p className="user-details__info-tabs-header mt-2">proposta</p>

                    <div className="user-details__info-tabs-container">

                      <TabLineContent
                        label='Número da proposta'
                        value={<b>{valueOrNa(proposal.number)}</b>}
                      />

                      <TabLineContent
                        label='Status da proposta'
                        value={<Tag color={varColors[proposal.status]}><b>{translate.proposalStatus(proposal.status)}</b></Tag>}
                      />

                      <TabLineContent
                        label='Detalhes da proposta'
                        value={
                          <b>
                            <Link to={`/auth/proposals/show/${proposal._id}`} className="color-tertiary">
                              Acessar detalhes da proposta
                            </Link>
                          </b>
                        }
                      />

                      {(proposal.neededReminder && proposal.reminderTelemarketing) &&
                        <>
                          <TabLineContent
                            label='Dados do telemarketing'
                            value={<Link to={`/auth/details/user/${proposal.reminderTelemarketingId}`}><b className="color-tertiary">{valueOrNa(proposal.reminderTelemarketing.name)}</b></Link>}
                          />

                          <TabLineContent
                            label='email do Telemarketing'
                            value={<b>{valueOrNa(proposal.reminderTelemarketing.email)}</b>}
                          />
                        </>
                      }

                      {proposal?.mlm?.promoterId &&
                        <>
                          <TabLineContent
                            label='Nome indicador'
                            value={<b>{valueOrNa(proposal.mlm.promoter.name)}</b>}
                          />

                          <TabLineContent
                            label='Email indicador'
                            value={<b>{valueOrNa(proposal.mlm.promoter.email)}</b>}
                          />
                        </>
                      }

                      <TabLineContent
                        label='Mar aberto'
                        value={<b>{proposal.promoterId ? 'Não' : 'Sim'}</b>}
                      />

                      {proposal?.promoter &&
                        <>
                          <TabLineContent
                            label='Nome do parceiro'
                            value={<b>{valueOrNa(proposal.promoter.name)}</b>}
                          />

                          <TabLineContent
                            label='Email do parceiro'
                            value={<b>{valueOrNa(proposal.promoter.email)}</b>}
                          />

                          <TabLineContent
                            label='Tipo do parceiro'
                            value={<b>{valueOrNa(translate.promoterType(proposal.promoter.type))}</b>}
                          />
                        </>
                      }
                    </div>
                  </Tabs.TabPane>
                }

                {(details?._id && PermissionModel.canUpdatePermissions(user.level)) && (
                  <Tabs.TabPane tab="Permissões" key="permissions">
                    <UserDetailsPermissionsList
                      userId={details?._id}
                    />
                  </Tabs.TabPane>
                )}
              </Tabs>
            }
          />
        </div>

        <div className="user-details__tables">
          {details?._id && (
            <Tabs
              className="user-details__table-tabs"
              size='large'
              centered
              onChange={(e) => {
                if (e === 'users') {
                  setIsUsersTab(true)
                  dispatch(setIsTableRouteConfig(true))
                }
                if (isUsersTab) {
                  dispatch(setIsTableRouteConfig(false))
                  dispatch(tablesResetFiltersValues(false))
                  setIsUsersTab(false)
                }
              }}
            >
              <Tabs.TabPane tab="Histórico de E-mails" key="email">
                <UserDetailsEmailTable
                  id={details?._id}
                />
              </Tabs.TabPane>

              <Tabs.TabPane tab="Histórico de SMS" key="sms">
                <UserDetailsSMSTable
                  id={details?._id}
                />
              </Tabs.TabPane>

              {(details?.level === 'administrator') && (
                <Tabs.TabPane tab="Usuários" key="users" id="server-table-reset">
                  <UsersTable url={`/administrators/${userId}/invited`} level={'commom' as any} inviteButtonText={translate.level('commom')} columns={mainColumns.teamColumns} />
                </Tabs.TabPane>
              )}

              {(details?.level === 'client') && (
                <Tabs.TabPane tab="Simulações" key="simulations">
                  <UserDetailsSimulationTable
                    id={details?._id}
                  />
                </Tabs.TabPane>
              )}

            </Tabs>
          )}
        </div>
      </section>

    </>

  )
}

export default UserDetails

import { IAuth } from 'egi/types'
import { timeAsDayjs } from 'utils/time'
import { IAssociate } from './components/PartnerStep/steps/AssociateStep/types'
import { IAssociates, IFormPartner } from './components/PartnerStep/steps/PartnerStep/types'

export function partnerFormFill (values: IFormPartner, user: IAuth) {
  const data = Object.assign({}, values)

  if (!data.cnpj && user.cnpj && ['promoter'].includes(user.level)) data.cnpj = user.cnpj
  if (!data.fantasyName && user.fantasyName && ['promoter'].includes(user.level)) data.fantasyName = user.fantasyName
  if (!data.socialName && user.name && ['promoter'].includes(user.level)) data.socialName = user.name
  if (!data.email && user.email && ['promoter'].includes(user.level)) data.email = user.email
  if (!data.phone && user.cellphone && ['promoter'].includes(user.level)) data.phone = user.cellphone

  if (!data.foundationDate && user.foundationDate && ['promoter'].includes(user.level)) {
    data.foundationDate = timeAsDayjs(user.foundationDate).format('DD/MM/YYYY')
  } else if (data.foundationDate) data.foundationDate = timeAsDayjs(data.foundationDate).format('DD/MM/YYYY')

  if (data.associates) {
    const mappedAssociates = data.associates.map((associate: IAssociates) => {
      if (associate.birthdate) associate.birthdate = timeAsDayjs(associate.birthdate).format('DD/MM/YYYY')
      if (associate.foundationDate) associate.foundationDate = timeAsDayjs(associate.foundationDate).format('DD/MM/YYYY')
      return associate
    })

    data.associates = mappedAssociates
  }

  return data
}

export function associateFormFill (values: IAssociate[]) {
  const data: IAssociate[] = [...values]

  const mappedData = data.map(associates => {
    if (associates.birthdate) associates.birthdate = timeAsDayjs(associates.birthdate).format('DD/MM/YYYY')
    if (associates.expeditionDate) associates.expeditionDate = timeAsDayjs(associates.expeditionDate).format('DD/MM/YYYY')
    return associates
  })

  return mappedData
}

import React from 'react'

export default function UploadSVG ({ className = '' } : {className?: string}) {
  return (
    <svg className={className} width="753" height="599" viewBox="0 0 753 599" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6666_31420)">
        <path d="M137.292 70.6488C135.877 70.8063 134.513 71.2748 133.297 72.0213C132.082 72.7679 131.043 73.7742 130.254 74.9695C129.465 76.1649 128.946 77.5201 128.733 78.9397C128.52 80.3594 128.618 81.8089 129.02 83.1862L100.869 103.478L117.649 109.992L141.809 89.7239C144.058 88.889 145.926 87.2509 147.057 85.12C148.189 82.9891 148.506 80.5135 147.95 78.1623C147.393 75.811 146 73.7475 144.036 72.3627C142.072 70.978 139.672 70.3681 137.292 70.6488Z" fill="#FFB8B8"/>
        <path d="M134.673 101.215L99.7543 136.657C94.9688 141.483 88.6584 144.465 81.9197 145.085L38.7715 149.057C37.1897 149.209 35.5936 149.04 34.0777 148.559C32.5618 148.079 31.1567 147.296 29.9456 146.259C28.27 144.844 27.024 142.981 26.3507 140.884C25.6775 138.787 25.6043 136.541 26.1395 134.404C26.6748 132.267 27.7968 130.326 29.3766 128.803C30.9565 127.281 32.9301 126.238 35.0708 125.796L79.1515 116.328L119.284 86.4868C119.842 86.1701 120.472 86.006 121.112 86.0108C121.752 86.0157 122.38 86.1892 122.933 86.5143L133.847 95.5329C134.504 95.8959 135.031 96.4572 135.356 97.138C135.68 97.8187 135.785 98.5849 135.656 99.3288C135.528 100.044 135.185 100.703 134.673 101.215Z" fill="#00441F"/>
        <path d="M553.329 120.262C553.589 117.843 553.104 115.401 551.94 113.269C550.776 111.138 548.99 109.419 546.824 108.347L329.51 1.20525C328.128 0.523241 326.626 0.122479 325.091 0.0258571C323.555 -0.0707651 322.016 0.138637 320.561 0.642111C319.106 1.14559 317.763 1.93328 316.609 2.9602C315.456 3.98712 314.514 5.23316 313.838 6.62719L313.834 6.63541L232.327 174.857C230.967 177.675 230.77 180.922 231.779 183.886C232.788 186.85 234.921 189.29 237.71 190.67L455.025 297.812C457.818 299.184 461.037 299.383 463.975 298.365C466.914 297.347 469.332 295.196 470.7 292.382L552.207 124.16C552.803 122.936 553.182 121.617 553.329 120.262Z" fill="#F2F2F2"/>
        <path d="M426.018 493.631H144.041C140.491 493.627 137.088 492.203 134.578 489.671C132.067 487.138 130.655 483.705 130.651 480.124V93.9835C130.655 90.4025 132.067 86.9693 134.578 84.4371C137.088 81.9049 140.491 80.4806 144.041 80.4766H426.018C429.568 80.4806 432.971 81.9049 435.481 84.4371C437.992 86.9693 439.404 90.4025 439.408 93.9835V480.124C439.404 483.705 437.992 487.138 435.481 489.671C432.971 492.203 429.568 493.627 426.018 493.631Z" fill="white"/>
        <path d="M218.831 195.62C228.991 195.62 237.228 187.311 237.228 177.062C237.228 166.813 228.991 158.505 218.831 158.505C208.671 158.505 200.435 166.813 200.435 177.062C200.435 187.311 208.671 195.62 218.831 195.62Z" fill="#E6E6E6"/>
        <path d="M190.792 227.782C193.392 229.273 196.112 230.537 198.924 231.563L199.421 231.736C205.085 233.742 211.046 234.764 217.049 234.756C218.257 234.756 219.421 234.714 220.508 234.631C223.153 234.467 225.783 234.102 228.373 233.537C229.795 233.224 231.212 232.849 232.583 232.424C232.915 232.326 233.222 232.229 233.558 232.123C239.146 230.295 244.397 227.55 249.1 223.998C248.723 223.278 248.309 222.578 247.862 221.9C245.444 218.173 242.143 215.113 238.257 212.997C234.371 210.881 230.024 209.776 225.609 209.782H215.926C210.819 209.783 205.82 211.265 201.524 214.051C197.228 216.836 193.817 220.809 191.695 225.495C191.356 226.242 191.055 227.005 190.792 227.782Z" fill="#E6E6E6"/>
        <path d="M225.074 233.075C224.456 233.121 223.862 233.332 223.351 233.685C225.947 233.522 228.526 233.161 231.068 232.604L225.074 233.075Z" fill="#2F2E41"/>
        <path d="M219.946 126.703C208.098 126.704 196.581 130.651 187.184 137.93C177.786 145.21 171.034 155.415 167.975 166.962C164.915 178.509 165.72 190.751 170.265 201.789C174.809 212.827 182.838 222.043 193.106 228.007C195.832 229.599 198.693 230.943 201.656 232.022L202.159 232.197C207.875 234.221 213.889 235.252 219.946 235.245C221.118 235.245 222.289 235.206 223.441 235.118C226.108 234.952 228.759 234.583 231.371 234.014C232.834 233.692 234.267 233.311 235.622 232.891C235.951 232.793 236.261 232.695 236.6 232.588C248.777 228.587 259.141 220.335 265.827 209.317C272.513 198.298 275.084 185.235 273.077 172.475C271.07 159.715 264.618 148.096 254.879 139.706C245.14 131.316 232.753 126.706 219.946 126.703ZM251.162 221.629C246.521 225.242 241.296 228.021 235.719 229.843C235.419 229.941 235.109 230.039 234.799 230.136C233.511 230.537 232.156 230.888 230.761 231.191C228.29 231.735 225.782 232.088 223.257 232.246C222.163 232.324 221.05 232.363 219.946 232.363C214.213 232.367 208.52 231.393 203.108 229.482L202.634 229.316C199.687 228.248 196.847 226.9 194.152 225.292C184.766 219.718 177.399 211.253 173.135 201.142C168.871 191.032 167.934 179.808 170.462 169.121C172.99 158.433 178.85 148.845 187.181 141.766C195.511 134.687 205.873 130.49 216.743 129.792C227.613 129.094 238.419 131.931 247.573 137.886C256.726 143.842 263.744 152.603 267.596 162.88C271.448 173.158 271.93 184.411 268.972 194.985C266.014 205.56 259.772 214.899 251.162 221.629Z" fill="#CCCCCC"/>
        <path d="M426.018 493.631H144.041C140.491 493.627 137.088 492.203 134.578 489.671C132.067 487.138 130.655 483.705 130.651 480.124V93.9835C130.655 90.4025 132.067 86.9693 134.578 84.4371C137.088 81.9049 140.491 80.4806 144.041 80.4766H426.018C429.568 80.4806 432.971 81.9049 435.481 84.4371C437.992 86.9693 439.404 90.4025 439.408 93.9835V480.124C439.404 483.705 437.992 487.138 435.481 489.671C432.971 492.203 429.568 493.627 426.018 493.631ZM144.041 82.0656C140.909 82.0692 137.906 83.326 135.691 85.5603C133.476 87.7945 132.23 90.8238 132.227 93.9835V480.124C132.23 483.284 133.476 486.313 135.691 488.547C137.906 490.782 140.909 492.039 144.041 492.042H426.018C429.15 492.039 432.153 490.782 434.368 488.547C436.583 486.313 437.829 483.284 437.832 480.124V93.9835C437.829 90.8238 436.583 87.7945 434.368 85.5603C432.153 83.326 429.15 82.0692 426.018 82.0656L144.041 82.0656Z" fill="#CCCCCC"/>
        <path d="M371.43 180.765H291.641C289.967 180.765 288.361 180.094 287.177 178.9C285.994 177.706 285.329 176.086 285.329 174.398C285.329 172.709 285.994 171.089 287.177 169.895C288.361 168.701 289.967 168.03 291.641 168.03H371.43C373.105 168.03 374.71 168.701 375.894 169.895C377.078 171.089 377.743 172.709 377.743 174.398C377.743 176.086 377.078 177.706 375.894 178.9C374.71 180.094 373.105 180.765 371.43 180.765Z" fill="#E6E6E6"/>
        <path d="M397.469 202.254H291.641C289.967 202.254 288.361 201.584 287.177 200.389C285.994 199.195 285.329 197.576 285.329 195.887C285.329 194.198 285.994 192.579 287.177 191.385C288.361 190.19 289.967 189.52 291.641 189.52H397.469C399.143 189.52 400.748 190.19 401.932 191.385C403.116 192.579 403.781 194.198 403.781 195.887C403.781 197.576 403.116 199.195 401.932 200.389C400.748 201.584 399.143 202.254 397.469 202.254Z" fill="#E6E6E6"/>
        <path d="M371.314 284.678H172.591C170.916 284.678 169.311 284.007 168.127 282.813C166.943 281.619 166.278 280 166.278 278.311C166.278 276.622 166.943 275.002 168.127 273.808C169.311 272.614 170.916 271.943 172.591 271.943H371.314C372.988 271.943 374.594 272.614 375.778 273.808C376.962 275.002 377.627 276.622 377.627 278.311C377.627 280 376.962 281.619 375.778 282.813C374.594 284.007 372.988 284.678 371.314 284.678Z" fill="#E6E6E6"/>
        <path d="M397.352 306.168H172.591C170.916 306.168 169.311 305.498 168.127 304.303C166.943 303.109 166.278 301.49 166.278 299.801C166.278 298.112 166.943 296.493 168.127 295.299C169.311 294.104 170.916 293.434 172.591 293.434H397.352C399.026 293.434 400.632 294.104 401.816 295.299C403 296.493 403.665 298.112 403.665 299.801C403.665 301.49 403 303.109 401.816 304.303C400.632 305.498 399.026 306.168 397.352 306.168Z" fill="#E6E6E6"/>
        <path d="M371.314 355.561H172.591C170.916 355.561 169.311 354.89 168.127 353.696C166.943 352.502 166.278 350.882 166.278 349.194C166.278 347.505 166.943 345.885 168.127 344.691C169.311 343.497 170.916 342.826 172.591 342.826H371.314C372.988 342.826 374.594 343.497 375.778 344.691C376.962 345.885 377.627 347.505 377.627 349.194C377.627 350.882 376.962 352.502 375.778 353.696C374.594 354.89 372.988 355.561 371.314 355.561Z" fill="#E6E6E6"/>
        <path d="M397.352 377.051H172.591C170.916 377.051 169.311 376.38 168.127 375.186C166.943 373.992 166.278 372.373 166.278 370.684C166.278 368.995 166.943 367.376 168.127 366.181C169.311 364.987 170.916 364.316 172.591 364.316H397.352C399.026 364.316 400.632 364.987 401.816 366.181C403 367.376 403.665 368.995 403.665 370.684C403.665 372.373 403 373.992 401.816 375.186C400.632 376.38 399.026 377.051 397.352 377.051Z" fill="#E6E6E6"/>
        <path d="M371.314 426.444H172.591C170.916 426.444 169.311 425.773 168.127 424.579C166.943 423.385 166.278 421.765 166.278 420.076C166.278 418.388 166.943 416.768 168.127 415.574C169.311 414.38 170.916 413.709 172.591 413.709H371.314C372.988 413.709 374.594 414.38 375.778 415.574C376.962 416.768 377.627 418.388 377.627 420.076C377.627 421.765 376.962 423.385 375.778 424.579C374.594 425.773 372.988 426.444 371.314 426.444Z" fill="#E6E6E6"/>
        <path d="M397.352 447.934H172.591C170.916 447.934 169.311 447.263 168.127 446.069C166.943 444.875 166.278 443.255 166.278 441.567C166.278 439.878 166.943 438.258 168.127 437.064C169.311 435.87 170.916 435.199 172.591 435.199H397.352C399.025 435.201 400.629 435.873 401.811 437.067C402.993 438.261 403.657 439.879 403.657 441.567C403.657 443.254 402.993 444.872 401.811 446.066C400.629 447.26 399.025 447.932 397.352 447.934Z" fill="#E6E6E6"/>
        <path d="M543.646 230.629C543.906 228.21 543.421 225.768 542.257 223.637C541.094 221.505 539.307 219.786 537.142 218.714L319.827 111.572C318.445 110.89 316.944 110.49 315.408 110.393C313.873 110.296 312.333 110.506 310.878 111.009C309.423 111.513 308.08 112.3 306.927 113.327C305.773 114.354 304.832 115.6 304.156 116.994L304.152 117.003L222.645 285.224C221.284 288.042 221.087 291.289 222.096 294.253C223.105 297.217 225.238 299.657 228.028 301.037L445.342 408.179C448.135 409.551 451.354 409.75 454.293 408.732C457.231 407.714 459.65 405.563 461.018 402.749L542.525 234.527C543.12 233.303 543.499 231.984 543.646 230.629Z" fill="white"/>
        <path d="M543.646 230.629C543.906 228.21 543.421 225.768 542.257 223.637C541.094 221.505 539.307 219.786 537.142 218.714L319.827 111.572C318.445 110.89 316.944 110.49 315.408 110.393C313.873 110.296 312.333 110.506 310.878 111.009C309.423 111.513 308.08 112.3 306.927 113.327C305.773 114.354 304.832 115.6 304.156 116.994L304.152 117.003L222.645 285.224C221.284 288.042 221.087 291.289 222.096 294.253C223.105 297.217 225.238 299.657 228.028 301.037L445.342 408.179C448.135 409.551 451.354 409.75 454.293 408.732C457.231 407.714 459.65 405.563 461.018 402.749L542.525 234.527C543.12 233.303 543.499 231.984 543.646 230.629ZM459.779 402.138C458.572 404.621 456.438 406.52 453.845 407.419C451.252 408.317 448.412 408.141 445.947 406.93L228.633 299.788C226.172 298.57 224.289 296.418 223.399 293.802C222.508 291.186 222.683 288.321 223.883 285.835L305.39 117.613C306.597 115.13 308.731 113.231 311.324 112.333C313.917 111.435 316.757 111.611 319.222 112.822L536.536 219.964C538.998 221.181 540.88 223.334 541.771 225.95C542.661 228.565 542.487 231.43 541.286 233.916L459.779 402.138Z" fill="#00441F"/>
        <path d="M519.943 238.279L463.029 355.743C462.65 356.527 461.977 357.127 461.159 357.41C460.341 357.694 459.444 357.638 458.667 357.256L260.99 259.795C260.849 259.727 260.714 259.647 260.586 259.555C259.938 259.11 259.47 258.444 259.269 257.68C259.068 256.915 259.147 256.103 259.491 255.393L316.405 137.929C316.593 137.54 316.855 137.193 317.176 136.907C317.497 136.621 317.871 136.401 318.276 136.261C318.681 136.12 319.109 136.062 319.537 136.088C319.964 136.115 320.383 136.226 320.768 136.416L518.444 233.876C519.222 234.259 519.816 234.938 520.098 235.763C520.379 236.588 520.324 237.492 519.944 238.277L519.943 238.279Z" fill="#E6E6E6"/>
        <path d="M346.397 232.314C362.208 232.314 375.025 219.384 375.025 203.436C375.025 187.487 362.208 174.558 346.397 174.558C330.587 174.558 317.77 187.487 317.77 203.436C317.77 219.384 330.587 232.314 346.397 232.314Z" fill="#00CF7B"/>
        <path d="M388.314 323.153L262.139 260.946C261.98 260.87 261.829 260.779 261.686 260.675L371.881 185.828C372.56 185.358 373.351 185.078 374.172 185.018C374.993 184.957 375.815 185.117 376.555 185.481C377.294 185.846 377.925 186.402 378.382 187.093C378.838 187.784 379.105 188.585 379.155 189.414L385.301 279.132L385.597 283.426L388.314 323.153Z" fill="#00441F"/>
        <path opacity="0.2" d="M388.314 323.153L345.235 301.914L381.396 281.354L383.999 279.873L385.301 279.132L385.597 283.425L388.314 323.153Z" fill="black"/>
        <path d="M458.567 357.79L349.437 303.986L385.597 283.426L388.201 281.944L435.323 255.15C438.412 253.394 442.838 255.372 443.842 258.732C443.908 258.96 443.96 259.192 443.997 259.426L458.567 357.79Z" fill="#00441F"/>
        <path d="M121.848 368.043L133.717 368.042L139.365 321.855L121.845 321.856L121.848 368.043Z" fill="#FFB8B8"/>
        <path d="M118.819 364.134L142.196 364.133C144.153 364.133 146.091 364.521 147.899 365.277C149.706 366.032 151.348 367.139 152.732 368.534C154.115 369.93 155.213 371.586 155.961 373.41C156.71 375.233 157.095 377.187 157.095 379.161V379.649L118.82 379.65L118.819 364.134Z" fill="#2F2E41"/>
        <path d="M29.5811 360.418L41.8797 362.844L57.0114 316.148L38.8607 312.566L29.5811 360.418Z" fill="#FFB8B8"/>
        <path d="M27.2296 355.749L51.4496 360.528L51.4506 360.528C53.4775 360.928 55.4065 361.727 57.1274 362.879C58.8483 364.031 60.3275 365.514 61.4803 367.242C62.6332 368.971 63.4372 370.912 63.8465 372.954C64.2557 374.996 64.2623 377.1 63.8657 379.144L63.7675 379.65L24.1123 371.825L27.2296 355.749Z" fill="#2F2E41"/>
        <path d="M139.117 208.721C138.715 207.344 138.019 206.072 137.077 204.996C136.135 203.919 134.971 203.064 133.666 202.49C132.361 201.917 130.947 201.639 129.524 201.676C128.101 201.713 126.704 202.065 125.43 202.706L100.687 178.309L97.2734 196.116L121.293 216.554C122.502 218.641 124.429 220.208 126.707 220.958C128.985 221.708 131.457 221.589 133.654 220.624C135.851 219.658 137.621 217.913 138.627 215.72C139.634 213.526 139.809 211.036 139.117 208.721Z" fill="#FFB8B8"/>
        <path d="M47.3191 83.1439C60.4531 83.1439 71.1002 72.4037 71.1002 59.1549C71.1002 45.9062 60.4531 35.166 47.3191 35.166C34.1852 35.166 23.5381 45.9062 23.5381 59.1549C23.5381 72.4037 34.1852 83.1439 47.3191 83.1439Z" fill="#FFB8B8"/>
        <path d="M24.2414 353.271C23.9085 352.789 23.6954 352.234 23.6199 351.652C23.5445 351.071 23.6091 350.479 23.8081 349.927L42.6439 280.855L44.0363 213.174L107.443 208.788L107.751 208.767L126.849 243.683C132.828 254.6 136.488 266.652 137.596 279.074L143.89 348.645C143.985 349.59 143.709 350.536 143.12 351.278C142.532 352.02 141.678 352.5 140.742 352.614L121.624 354.449C120.703 354.562 119.774 354.307 119.036 353.74C118.299 353.172 117.81 352.336 117.675 351.41L109.911 280.713L81.9777 233.103L72.6011 283.226L53.1754 352.445C52.9214 353.136 52.4651 353.732 51.867 354.155C51.269 354.579 50.5575 354.808 49.827 354.814L27.1699 354.823C26.5931 354.826 26.0244 354.686 25.5137 354.415C25.0031 354.145 24.5662 353.751 24.2414 353.271Z" fill="#00CF7B"/>
        <path d="M42.3251 83.5113C43.5887 84.069 46.1761 73.9128 45.0171 71.8815C43.2934 68.8606 43.3953 68.8824 42.251 66.8837C41.1067 64.8851 40.8507 62.1495 42.3325 60.391C43.8143 58.6324 47.2434 58.8657 47.9197 61.0706C47.4846 56.8826 51.5911 53.5162 55.6588 52.5701C59.7265 51.6241 64.0172 52.2115 68.1072 51.3696C72.854 50.3926 77.7927 46.3789 75.8989 41.1135C75.5739 40.2369 75.0873 39.4302 74.4649 38.7361C72.2767 36.2544 69.2159 35.1946 66.2232 34.17C59.9973 32.0384 53.6011 29.88 47.0305 30.1153C41.6167 30.4009 36.4028 32.2722 32.027 35.5003C27.6511 38.7285 24.3029 43.1734 22.3924 48.2911C21.9395 49.5714 21.5888 50.8861 21.3433 52.2227C19.0881 64.4552 26.13 76.5211 37.5674 81.1792L42.3251 83.5113Z" fill="#2F2E41"/>
        <path d="M36.4864 215.293C35.8799 214.923 35.3938 214.382 35.088 213.737C34.7821 213.092 34.6699 212.371 34.765 211.662L24.034 158.04L13.1564 116.886C11.9622 112.377 12.3231 107.592 14.1794 103.317C16.0357 99.0413 19.2775 95.5293 23.3728 93.3568L30.564 86.4932L46.2518 93.311L53.7063 100.957C59.053 103.583 63.7959 107.309 67.63 111.894C71.4642 116.48 74.3048 121.824 75.9693 127.583L87.9255 168.853L115.537 210.292C115.998 210.982 116.214 211.81 116.149 212.64C116.084 213.47 115.742 214.253 115.18 214.862C111.338 219.031 36.4864 215.293 36.4864 215.293Z" fill="#00441F"/>
        <path d="M108.827 211.483L68.1119 183.022C62.5625 179.116 58.545 173.372 56.7575 166.789L45.3128 124.635C44.8867 123.091 44.7719 121.476 44.975 119.886C45.178 118.297 45.695 116.764 46.4952 115.379C47.582 113.467 49.1814 111.903 51.1099 110.866C53.0383 109.83 55.2175 109.363 57.397 109.52C59.5765 109.677 61.6681 110.45 63.4313 111.752C65.1945 113.054 66.5579 114.831 67.3649 116.88L84.3378 158.995L120.502 193.616C120.909 194.114 121.179 194.712 121.287 195.348C121.395 195.985 121.335 196.639 121.115 197.245L114.228 209.666C113.989 210.382 113.533 211.004 112.926 211.445C112.318 211.887 111.589 212.125 110.84 212.128C110.12 212.127 109.417 211.901 108.827 211.483Z" fill="#00441F"/>
        <path d="M509.746 580.442H31.952C23.4808 580.433 15.3592 577.034 9.3691 570.991C3.37901 564.949 0.00956914 556.756 0 548.211V283.523C0.00956914 274.978 3.37901 266.785 9.3691 260.743C15.3592 254.701 23.4808 251.302 31.952 251.292H90.5334C98.4819 251.285 106.146 254.274 112.024 259.672L175.552 305.432C179.693 309.25 185.102 311.364 190.713 311.359H512.713C516.948 311.36 521.141 312.209 525.048 313.858C528.955 315.508 532.498 317.924 535.471 320.967C538.444 324.01 540.787 327.619 542.365 331.584C543.943 335.548 544.723 339.79 544.661 344.062L541.695 548.682C541.538 557.137 538.107 565.194 532.136 571.129C526.165 577.065 518.129 580.408 509.746 580.442Z" fill="white"/>
        <path d="M505.501 564.815H34.7786C30.692 564.81 26.7741 563.171 23.8845 560.256C20.9948 557.341 19.3694 553.389 19.3647 549.266V280.251C19.3694 276.128 20.9948 272.176 23.8845 269.261C26.7741 266.346 30.692 264.707 34.7786 264.702C38.3932 264.706 41.8585 266.156 44.4144 268.735C46.9703 271.313 48.408 274.809 48.412 278.455V530.709C48.4164 534.572 49.9398 538.276 52.6479 541.008C55.356 543.74 59.0278 545.276 62.8577 545.281H503.911C506.102 545.256 508.239 545.973 509.979 547.316C511.719 548.659 512.963 550.552 513.512 552.691C514.127 554.721 514.05 556.899 513.294 558.879C512.539 560.859 511.147 562.527 509.342 563.616C508.197 564.367 506.867 564.783 505.501 564.815Z" fill="#F2F2F2"/>
        <path d="M274.424 435.624L273.184 434.374C272.928 434.131 272.777 433.796 272.765 433.441C272.754 433.086 272.882 432.741 273.123 432.482C273.143 432.461 273.163 432.44 273.184 432.42L284.058 421.442C284.177 421.314 284.32 421.21 284.478 421.138C284.637 421.065 284.808 421.025 284.983 421.019C285.157 421.014 285.33 421.042 285.493 421.104C285.656 421.166 285.806 421.26 285.933 421.38C285.954 421.4 285.974 421.421 285.994 421.442L296.868 432.41C296.995 432.534 297.097 432.682 297.167 432.846C297.238 433.009 297.275 433.185 297.279 433.363C297.282 433.542 297.25 433.719 297.185 433.885C297.12 434.05 297.024 434.202 296.901 434.33L296.868 434.364L295.628 435.614C295.506 435.742 295.359 435.845 295.197 435.916C295.035 435.987 294.86 436.025 294.684 436.029C294.507 436.032 294.331 436 294.167 435.934C294.003 435.869 293.852 435.772 293.725 435.648L293.692 435.614L287.263 428.777V445C287.264 445.177 287.231 445.353 287.165 445.517C287.099 445.681 287.001 445.83 286.878 445.956C286.755 446.082 286.608 446.183 286.447 446.251C286.285 446.32 286.112 446.356 285.936 446.358L285.927 446.358H284.135C283.778 446.358 283.436 446.215 283.184 445.96C282.931 445.706 282.789 445.36 282.789 445V428.797L276.36 435.634C276.111 435.891 275.771 436.039 275.414 436.043C275.057 436.048 274.713 435.909 274.457 435.658L274.424 435.624Z" fill="#00CF7B"/>
        <path d="M285.007 411.47C289.371 411.47 293.637 412.776 297.266 415.222C300.895 417.667 303.723 421.144 305.393 425.211C307.063 429.278 307.5 433.754 306.649 438.072C305.798 442.39 303.696 446.356 300.61 449.469C297.524 452.582 293.592 454.702 289.312 455.561C285.031 456.42 280.594 455.979 276.562 454.294C272.53 452.609 269.084 449.756 266.659 446.096C264.235 442.435 262.94 438.132 262.94 433.729C262.946 427.827 265.272 422.169 269.409 417.995C273.546 413.822 279.156 411.475 285.007 411.47ZM285.007 408.54C280.068 408.54 275.24 410.017 271.134 412.785C267.027 415.553 263.826 419.487 261.936 424.09C260.046 428.693 259.552 433.757 260.515 438.643C261.479 443.53 263.857 448.018 267.349 451.541C270.842 455.064 275.291 457.463 280.135 458.435C284.979 459.406 290 458.908 294.563 457.001C299.125 455.095 303.025 451.866 305.769 447.724C308.513 443.581 309.978 438.711 309.978 433.729C309.978 427.049 307.347 420.642 302.664 415.918C297.981 411.194 291.629 408.54 285.007 408.54Z" fill="#00CF7B"/>
        <path d="M509.746 580.442H31.952C23.4808 580.433 15.3592 577.034 9.3691 570.991C3.37901 564.949 0.00956914 556.756 0 548.211V283.523C0.00956914 274.978 3.37901 266.785 9.3691 260.743C15.3592 254.701 23.4808 251.302 31.952 251.292H90.5334C98.4819 251.285 106.146 254.274 112.024 259.672L175.552 305.432C179.693 309.25 185.102 311.364 190.713 311.359H512.713C516.948 311.36 521.141 312.209 525.048 313.858C528.955 315.508 532.498 317.924 535.471 320.967C538.444 324.01 540.787 327.619 542.365 331.584C543.943 335.548 544.723 339.79 544.661 344.062L541.695 548.682C541.538 557.137 538.107 565.194 532.136 571.129C526.165 577.065 518.129 580.408 509.746 580.442ZM31.952 253.245C23.9942 253.255 16.3649 256.447 10.7378 262.124C5.11078 267.8 1.94553 275.496 1.93649 283.523V548.211C1.94553 556.238 5.11078 563.934 10.7378 569.611C16.3649 575.287 23.9942 578.48 31.952 578.489H509.746C517.621 578.456 525.17 575.316 530.779 569.74C536.388 564.165 539.611 556.596 539.758 548.654L542.725 344.033C542.783 340.02 542.05 336.036 540.568 332.311C539.086 328.587 536.884 325.197 534.092 322.338C531.299 319.48 527.971 317.21 524.3 315.66C520.63 314.111 516.691 313.313 512.713 313.313H190.713C184.64 313.318 178.783 311.036 174.29 306.914L110.762 261.154C105.236 256.061 98.0191 253.239 90.5334 253.245H31.952Z" fill="#00441F"/>
        <path d="M550.238 585.596H538.368L534.657 539.409L550.24 539.41L550.238 585.596Z" fill="#FFB8B8"/>
        <path d="M553.265 597.203L514.99 597.202V596.713C514.99 594.74 515.376 592.786 516.124 590.962C516.873 589.139 517.97 587.482 519.354 586.087C520.737 584.692 522.379 583.585 524.187 582.829C525.994 582.074 527.932 581.685 529.888 581.686L553.266 581.687L553.265 597.203Z" fill="#00441F"/>
        <path d="M682.888 585.596H671.017L665.37 539.409L682.89 539.41L682.888 585.596Z" fill="#FFB8B8"/>
        <path d="M685.915 597.203L647.64 597.202V596.713C647.64 594.74 648.025 592.786 648.774 590.962C649.522 589.139 650.62 587.482 652.003 586.087C653.386 584.692 655.029 583.585 656.836 582.829C658.644 582.074 660.581 581.685 662.538 581.686L685.916 581.687L685.915 597.203Z" fill="#00441F"/>
        <path d="M643.856 442.815C645.495 440.878 646.418 438.428 646.47 435.882C646.521 433.336 645.698 430.85 644.139 428.847C643.823 428.463 643.476 428.107 643.102 427.781L652.042 394.483L637.141 399.47L630.935 430.326C629.905 432.228 629.449 434.393 629.624 436.554C629.799 438.715 630.597 440.776 631.92 442.485C632.612 443.407 633.5 444.161 634.52 444.69C635.539 445.219 636.664 445.511 637.81 445.542C638.956 445.574 640.094 445.345 641.141 444.873C642.188 444.401 643.116 443.698 643.856 442.815Z" fill="#FFB8B8"/>
        <path d="M643.283 422.332C643.096 422.332 642.91 422.318 642.725 422.29L634.96 421.109C634.356 421.004 633.782 420.767 633.278 420.415C632.774 420.063 632.353 419.604 632.043 419.07C631.37 417.934 631.128 416.59 631.362 415.288L641.05 360.707L634.48 325.642C633.213 318.894 636.481 312.079 642.082 309.788C643.743 309.103 645.553 308.864 647.333 309.094C649.114 309.324 650.805 310.016 652.241 311.101C653.791 312.285 655.076 313.785 656.014 315.503C656.952 317.221 657.521 319.118 657.684 321.072L662.334 365.782L662.279 365.977L647.313 418.962C647.097 419.89 646.587 420.721 645.859 421.33C645.132 421.938 644.227 422.29 643.283 422.332Z" fill="#00CF7B"/>
        <path d="M640.99 290.465C654.124 290.465 664.771 279.725 664.771 266.476C664.771 253.228 654.124 242.487 640.99 242.487C627.856 242.487 617.209 253.228 617.209 266.476C617.209 279.725 627.856 290.465 640.99 290.465Z" fill="#FFB8B8"/>
        <path d="M628.814 377.987C628.222 377.987 627.635 377.876 627.082 377.661L589.494 363.078C588.477 362.686 587.62 361.959 587.065 361.014C586.509 360.069 586.286 358.963 586.433 357.874L587.943 346.453C587.047 342.405 587.13 338.198 588.183 334.189C589.236 330.179 591.229 326.483 593.994 323.413L594.909 322.397L606.03 301.941C606.783 300.555 607.801 299.333 609.025 298.346C610.249 297.359 611.654 296.626 613.16 296.189C614.666 295.753 616.243 295.621 617.799 295.803C619.356 295.984 620.862 296.474 622.229 297.245L641.868 308.315C644.452 309.77 646.404 312.148 647.338 314.981C649.981 323.002 649.785 331.699 646.784 339.591L633.338 374.856C632.983 375.776 632.361 376.566 631.554 377.124C630.747 377.683 629.793 377.983 628.814 377.987Z" fill="#00441F"/>
        <path d="M530.307 574.256L530.256 574.246C529.615 574.122 529.006 573.868 528.464 573.499C527.923 573.131 527.461 572.656 527.107 572.104C526.752 571.551 526.512 570.931 526.4 570.282C526.289 569.633 526.309 568.969 526.459 568.328L555.263 441.317C559.979 421.183 566.493 401.521 574.727 382.572L586.981 354.388L632.707 375.446L639.236 387.491C644.327 400.906 646.944 415.147 646.959 429.511L693.422 566.867C693.649 567.538 693.728 568.25 693.655 568.955C693.581 569.66 693.357 570.34 692.996 570.948C692.636 571.557 692.149 572.079 691.569 572.478C690.989 572.877 690.329 573.145 689.636 573.261L664.201 572.971C663.962 573.015 663.718 573.035 663.474 573.033C662.51 573.034 661.568 572.743 660.77 572.197C659.972 571.651 659.355 570.876 659 569.972L605.887 431.097C605.812 430.913 605.682 430.756 605.515 430.648C605.349 430.54 605.154 430.486 604.956 430.492C604.763 430.495 604.575 430.559 604.418 430.673C604.262 430.788 604.144 430.949 604.082 431.134L553.687 570.699C553.301 571.784 552.544 572.695 551.553 573.27C550.562 573.844 549.401 574.044 548.277 573.834L530.307 574.256Z" fill="#00441F"/>
        <path d="M565.08 418.064C566.421 417.583 567.641 416.811 568.655 415.803C569.668 414.795 570.45 413.574 570.945 412.228C571.441 410.882 571.637 409.443 571.521 408.012C571.406 406.581 570.98 405.193 570.275 403.945L593.051 377.671L575.236 375.221L556.339 400.547C554.34 401.881 552.896 403.908 552.28 406.244C551.664 408.58 551.919 411.063 552.996 413.222C554.074 415.382 555.899 417.067 558.126 417.96C560.353 418.852 562.828 418.889 565.08 418.064Z" fill="#FFB8B8"/>
        <path d="M579.273 404.976C578.481 404.975 577.7 404.779 577 404.404L568.985 400.091C568.397 399.775 567.88 399.339 567.467 398.812C567.053 398.285 566.752 397.677 566.582 397.027C566.412 396.376 566.377 395.698 566.48 395.033C566.582 394.369 566.819 393.733 567.176 393.165L596.779 346.11L601.912 310.601C602.178 308.765 602.81 307.003 603.77 305.422C604.73 303.84 605.998 302.471 607.498 301.398C608.998 300.325 610.698 299.571 612.495 299.18C614.293 298.789 616.15 298.77 617.955 299.125C619.76 299.479 621.474 300.199 622.995 301.241C624.516 302.283 625.811 303.626 626.803 305.188C627.794 306.75 628.461 308.499 628.763 310.328C629.065 312.158 628.996 314.03 628.56 315.832L617.962 359.599L583.044 403.165C582.59 403.732 582.015 404.189 581.363 404.503C580.71 404.816 579.996 404.978 579.273 404.976Z" fill="#00CF7B"/>
        <path d="M619.585 246.683C622.335 237.584 638.228 233.524 651.438 237.3C664.648 241.075 674.137 250.974 676.347 260.652C681.238 282.083 655.722 299.476 660.346 320.91C663.405 335.091 681.101 348.498 701.032 351.736C679.068 356.372 652.259 352.297 631.89 341.228C619.213 334.338 608.577 323.941 608.738 313.455C608.835 307.105 612.84 301.49 617.736 296.64C622.633 291.789 628.494 287.477 632.988 282.414C637.482 277.351 640.595 271.288 639.284 264.861C637.973 258.433 631.31 251.731 622.346 249.86L619.585 246.683Z" fill="#2F2E41"/>
      </g>
      <defs>
        <clipPath id="clip0_6666_31420">
          <rect width="753" height="599" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  )
}

import dayjs, { ConfigType, Dayjs } from 'dayjs'
import { timeAsDayjs } from './time'

function timestampToSeconds (expiresIn: number): number {
  if (!expiresIn) return 0

  const expires = dayjs.unix(expiresIn)
  const diffInSeconds: number = expires.diff(timeAsDayjs(), 'second')
  return diffInSeconds
}

function formatYear (value: ConfigType | undefined, options?: { format: string }) {
  const { format } = options || {}

  if (!value) return 'Data indisponível'
  if (!dayjs(value).isValid()) return 'Data inválida'

  return timeAsDayjs(value).format(format ?? 'DD/MM/YYYY HH:mm')
}

const monthCommaYear = (value: Dayjs) => {
  if (!value.isValid()) return ''
  return dayjs(value).format('MMMM[,] YYYY')
}

const brDateReverse = (value: string) => value?.split('/').reverse().join('-')

export { timestampToSeconds, brDateReverse, formatYear, monthCommaYear }

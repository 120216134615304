import {
  dashboard,
  proposalsListTwo,
  proposalsHistory,
  proposalCheck,
  leads,
  internalNotification,
  compliance,
  precificacaoTable,
  precificacaoCreate,
  precificacaoEdit,
  createAttorney,
  getOneAttorney,
  createWitness,
  getOneWitness,
  products,
  documentos,
  registro,
  comercialTeam,
  telemarketingTeam,
  attorneys,
  witnessess,
  reportProviders,
  reportCommission,
  fiscal,
  precificacao,
  pricingUsers,
  commissionsUsers,
  juridicoTeam,
  analiseCreditoTeam,
  engenhariaTeam,
  leadsHistory,
  teamAdmin,
  divulgationPage,
  slaConfig,
  declines,
  simulationConfiguration,
  chat,
  settingsMenu,
  auditors,
  qualification,

  promoters,
  partnersLine,
  teamPromoterIn,
  promotersIn,
  allInvites,
  userDetails,
  exclusiveFees,
  profile,
  clientList,
  docs,
  leadDetails,
  emailConfig,
  bacenTerms,
  lgpdTerms,
  proposalsListSearch,
  accessManager,
  accessManagerDetails,
  operationalLimit,
  population,
  internReviewSettings
} from '../routes'

import { BulbOutlined, FileSearchOutlined, HourglassOutlined, InteractionOutlined, ShopOutlined, ShoppingCartOutlined, SolutionOutlined, StarOutlined } from '@ant-design/icons'
import { notaryFees } from 'routes/departments/pricing'
import { ecpRoutes } from 'routes/ecp/ecpRoutes'
import { IAuth } from 'egi/types'
import { RouteGroup, RouteType } from 'components/Sidebar/types'
import UserModels from 'egi/models/UserModels'

const indications = {
  name: 'Gestor de links',
  icon: InteractionOutlined,
  routes: [
    promotersIn,
    divulgationPage
  ]
}

// AREAS
const comercial = {
  name: 'Comercial',
  icon: ShopOutlined,
  routes: [
    comercialTeam.leaders,
    commissionsUsers
  ]
}

const analiseCredito = {
  name: 'Análise de Crédito',
  icon: FileSearchOutlined,
  routes: [
    analiseCreditoTeam.leaders
  ]
}

const juridico = {
  name: 'Jurídico',
  icon: HourglassOutlined,
  routes: [
    juridicoTeam.leaders,
    attorneys,
    witnessess,
    juridicoTeam.providers,
    juridicoTeam.promoters
  ]
}

const engenharia = {
  name: 'Engenharia',
  icon: BulbOutlined,
  routes: [
    engenhariaTeam.leaders,
    engenhariaTeam.providers
  ]
}

const telemarketing = {
  name: 'Telemarketing',
  icon: BulbOutlined,
  routes: [
    telemarketingTeam.leaders
  ]
}

const partnersSubMenu = {
  name: 'Parceiros',
  icon: StarOutlined,
  routes: [
    promoters,
    reportProviders,
    reportCommission,
    fiscal
  ]
}

const pricing = {
  name: 'Precificação',
  icon: ShoppingCartOutlined,
  routes: [
    precificacao,
    pricingUsers,
    exclusiveFees,
    notaryFees
  ]
}

const proposal = {
  name: 'Propostas',
  icon: SolutionOutlined,
  routes: [
    proposalsListSearch,
    proposalsListTwo
  ]
}

const masterRoutes = (auth: IAuth): Array<RouteType | RouteGroup> => {
  const routes = [
    dashboard,
    proposal,
    leads,
    leadDetails,
    operationalLimit,
    population,
    internReviewSettings,
    slaConfig,
    declines,
    simulationConfiguration,
    qualification,
    pricing,
    leadsHistory,
    teamAdmin,
    indications,
    teamPromoterIn,
    emailConfig,
    ecpRoutes.ecpLogsMenu,
    juridicoTeam.logsZip,
    partnersSubMenu,
    partnersLine,
    juridico,
    engenharia,
    comercial,
    analiseCredito,
    telemarketing,
    auditors,
    allInvites,
    internalNotification,
    clientList,
    chat,
    ...(UserModels.hasAccessManagerSidebarItem(auth) ? [
      accessManager,
      accessManagerDetails
    ] : []),
    settingsMenu,
    compliance,
    proposalsHistory,
    proposalCheck,
    precificacaoTable,
    precificacaoCreate,
    precificacaoEdit,
    createAttorney,
    getOneAttorney,
    createWitness,
    getOneWitness,
    products,
    registro,
    documentos,
    userDetails,
    profile,
    docs,
    bacenTerms,
    lgpdTerms
  ]
  return routes
}

export default masterRoutes

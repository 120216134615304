import React, { useState, useEffect } from 'react'
import { AuditOutlined, CreditCardOutlined, FileDoneOutlined, FundViewOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu, Select } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { useTables } from 'hooks'
import { useDispatch } from 'react-redux'
import { tablesSetFiltersValues, tablesSetTotalDocs } from 'store/modules/tables/actions'
import { DEPARTMENT_SELECTION_OPTIONS } from '../../proposalEgiConstants'
import { colors } from 'styles/colors'

const handleFilterIcon = (areaRef: string) => {
  switch (areaRef) {
    case 'documentos': return <UserOutlined />
    case 'credito': return <CreditCardOutlined />
    case 'engenharia': return <FundViewOutlined />
    case 'analise-documental': return <SolutionOutlined />
    case 'contrato': return <FileDoneOutlined />
    case 'cartorio': return <AuditOutlined />
    default: return null
  }
}

function DepartmentSelect () {
  const dispatch = useDispatch()
  const tables = useTables()

  const breakpoint = useBreakpoint()
  const [filterOptions, setFilterOptions] = useState(DEPARTMENT_SELECTION_OPTIONS)
  const [dropdownOptions, setDropdownOptions] = useState<typeof DEPARTMENT_SELECTION_OPTIONS>([])

  const handleFilterSize = (pageSize: typeof breakpoint) => {
    if (pageSize.xxl) {
      setDropdownOptions([])
      return setFilterOptions([...DEPARTMENT_SELECTION_OPTIONS])
    }

    if (pageSize.xl) {
      const dropdown = DEPARTMENT_SELECTION_OPTIONS.slice(5)
      setFilterOptions(DEPARTMENT_SELECTION_OPTIONS.slice(0, DEPARTMENT_SELECTION_OPTIONS.length - dropdown.length))
      return setDropdownOptions(dropdown)
    }

    if (pageSize.lg) {
      const dropdown = DEPARTMENT_SELECTION_OPTIONS.slice(3)
      setFilterOptions(DEPARTMENT_SELECTION_OPTIONS.slice(0, DEPARTMENT_SELECTION_OPTIONS.length - dropdown.length))
      return setDropdownOptions(dropdown)
    }

    if (pageSize.md) {
      const dropdown = DEPARTMENT_SELECTION_OPTIONS.slice(2)
      setFilterOptions(DEPARTMENT_SELECTION_OPTIONS.slice(0, DEPARTMENT_SELECTION_OPTIONS.length - dropdown.length))
      return setDropdownOptions(dropdown)
    }

    if (pageSize.sm || pageSize.xs) {
      setFilterOptions([])
      return setDropdownOptions([...DEPARTMENT_SELECTION_OPTIONS])
    }
  }

  useEffect(() => {
    handleFilterSize(breakpoint)
  }, [
    breakpoint.xxl,
    breakpoint.xl,
    breakpoint.md,
    breakpoint.xs,
    breakpoint.sm
  ])

  const onSelectDepartment = (newDepartment: string) => {
    dispatch(tablesSetTotalDocs(undefined))

    if (newDepartment === '') {
      return dispatch(tablesSetFiltersValues({
        ...tables.filters,
        department: '',
        departmentStatus: undefined
      }))
    }

    return dispatch(tablesSetFiltersValues({
      ...tables.filters,
      department: newDepartment ?? undefined,
      departmentStatus: newDepartment ? 'analysis' : tables.filters.departmentStatus
    }))
  }

  const onSelectStatus = (newStatus: string) => {
    if (tables.filters.department) {
      dispatch(tablesSetFiltersValues({
        ...tables.filters,
        departmentStatus: newStatus ?? 'analysis'
      }))
    }
  }

  const canShowSelect = !!tables.filters.department

  const isSelectedOption = (value: string) => {
    return tables.filters.department === value && tables.totalDocs
  }

  const MenuOverlay = () => (
    <Menu className='department-select__menu'>
      {dropdownOptions.map((item, idx) => (
        <Menu.Item key={idx} className='department-select__menu-item'>
          <Button
            type={tables.filters.department === item.value ? 'primary' : 'ghost'}
            onClick={() => onSelectDepartment(item.value)}
            className='department-select__menu-button'
          >
            <span className='mr-1'>{item.name}</span>
            {isSelectedOption(item.value) && `(${tables.totalDocs})`}
          </Button>
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <div className='department-select__filters'>
      <div className='department-select__filters-buttons'>
        {filterOptions.map((item, idx) => (
          <Button
            icon={handleFilterIcon(item.value)}
            key={idx}
            type={tables.filters.department === item.value ? 'primary' : 'ghost'}
            onClick={() => onSelectDepartment(item.value)}
            className='department-select__button'
          >
            <span className='mr-1'>{item.name}</span>
            {isSelectedOption(item.value) && `(${tables.totalDocs})`}
          </Button>
        ))}

        {dropdownOptions.length > 0 && (
          <Dropdown overlay={<MenuOverlay />} trigger={['click', 'hover']}>
            <Button
              type='ghost'
              className='department-select__button'
            >
              {(!breakpoint.md) ? 'Filtrar por depart.' : 'Outros'} &#8595;
            </Button>
          </Dropdown>
        )}
      </div>

      {canShowSelect &&
        <Select
          onSelect={onSelectStatus}
          placeholder='Selecione o status'
          value={tables.filters.departmentStatus}
          className='department-select__status-select'
          options={[
            {
              value: 'analysis',
              label: <p className='department-select__status-label'>
                <span className='department-select__status-color' style={{ background: colors.analysis }}/>
                Possui pendência
              </p>
            },
            {
              value: 'approved',
              label: <p className='department-select__status-label'>
                <span className='department-select__status-color' style={{ background: colors.approved }}/>
                Aprovado
              </p>
            },
            {
              value: 'reproved',
              label: <p className='department-select__status-label'>
                <span className='department-select__status-color' style={{ background: colors.reproved }}/>
                Reprovado
              </p>
            },
            {
              value: 'starting',
              label: <p className='department-select__status-label'>
                <span className='department-select__status-color' style={{ background: colors.starting }}/>
                Não iniciado
              </p>
            }
          ]}
        />
      }
    </div>
  )
}

export default DepartmentSelect

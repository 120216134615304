import React, { Fragment, useState, useMemo } from 'react'
import { Row, Col, Form, Select, Checkbox, Input } from 'antd'
import { FormListFieldData } from 'antd/lib/form/FormList'
import { IMaritalFieldsErrors } from 'egi/app/PartnersLine/types'

export type _maritalStatus = 'solteiro' | 'casado' | 'divorciado' | 'separado' | 'viuvo' | 'desquitado'
export type _maritalRegime = 'comunhaoParcial' | 'comunhaoUniversal' | 'separacaoTotalDeBens' | 'separadoJudicialmente' | 'separacaoFinalDosAquestos' | 'separacaoObrigatoriaDeBens'

type Props = {
  field: FormListFieldData
  errors: IMaritalFieldsErrors
  readonly: boolean
  maritalInformation:{
    regime?: _maritalRegime | undefined,
    status?: _maritalStatus | undefined
  }
}

function MaritalFields ({ field, errors, readonly, maritalInformation = {} }: Props) {
  const [maritalRegime, setMaritalRegime] = useState<_maritalRegime | undefined>(maritalInformation.regime)
  const [maritalStatus, setMaritalStatus] = useState<_maritalStatus | undefined>(maritalInformation.status)
  const [stableUnion, setStableUnion] = useState<boolean>(false)

  const showNameAndCPF = useMemo(
    () => (maritalRegime && ['comunhaoParcial', 'comunhaoUniversal'].includes(maritalRegime)) || stableUnion
    , [maritalRegime, stableUnion])

  return (
    <Fragment>
      <Row align="bottom">
        <Col className="px-1" lg={12} sm={24} xs={24}>
          <Form.Item
            name={[field.name, 'maritalStatus']}
            label={<label>Estado civil:</label>}
            help={errors?.maritalStatus && (errors.maritalStatus)}
            validateStatus={errors?.maritalStatus && ('error')}
          >
            <Select
              placeholder="Escolha seu estado civil"
              disabled={readonly}
              onChange={(value: _maritalStatus) => {
                setStableUnion(false)
                setMaritalRegime(undefined)
                setMaritalStatus(value)
              }}
              options={[
                { label: 'Solteiro(a)', value: 'solteiro' },
                { label: 'Casado(a)', value: 'casado' },
                { label: 'Divorciado(a)', value: 'divorciado' },
                { label: 'Separado(a) judicialmente', value: 'separado' },
                { label: 'Viúvo(a)', value: 'viuvo' },
                { label: 'Desquitado(a)', value: 'desquitado' }
              ]}
            />
          </Form.Item>
        </Col>

        {maritalStatus === 'solteiro' && (
          <Col className="px-1" lg={12} sm={24} xs={24}>
            <Form.Item
              name={[field.name, 'stableUnion']}
              className="form-user-checkbox"
              valuePropName="checked"
              help={errors?.stableUnion && (errors.stableUnion)}
              validateStatus={errors?.stableUnion && ('error')}
            >
              <Checkbox onChange={() => setStableUnion(prev => !prev)} disabled={readonly}>
                Convive em união estável?
              </Checkbox>
            </Form.Item>
          </Col>
        )}

        {maritalStatus === 'casado' && (
          <Col className="px-1" lg={12} sm={24} xs={24}>
            <Form.Item
              name={[field.name, 'maritalRegime']}
              label={<label>Regime de casamento:</label>}
              help={errors?.maritalRegime && (errors.maritalRegime)}
              validateStatus={errors?.maritalRegime && ('error')}
            >
              <Select
                placeholder="Escolha o regime de casamento"
                disabled={readonly}
                onChange={(value: _maritalRegime) => setMaritalRegime(value)}
                options={[
                  { label: 'Comunhão parcial', value: 'comunhaoParcial' },
                  { label: 'Comunhão universal', value: 'comunhaoUniversal' },
                  { label: 'Separação total de bens', value: 'separacaoTotalDeBens' },
                  { label: 'Separado(a) judicialmente', value: 'separadoJudicialmente' },
                  { label: 'Separação final dos aquestos', value: 'separacaoFinalDosAquestos' },
                  { label: 'Separação obrigatória de bens', value: 'separacaoObrigatoriaDeBens' }
                ]}
              />
            </Form.Item>
          </Col>
        )}
      </Row>

      {showNameAndCPF && (
        <Row>
          <Col className="px-1" lg={12} sm={24} xs={24}>
            <Form.Item
              name={[field.name, 'maritalName']}
              label={<label>Nome Conjugê/Companheiro(a):</label>}
              help={errors?.maritalName && (errors.maritalName)}
              validateStatus={errors?.maritalName && ('error')}
            >
              <Input placeholder="Digite o nome" readOnly={readonly} />
            </Form.Item>
          </Col>

          <Col className="px-1" lg={12} sm={24} xs={24}>
            <Form.Item
              name={[field.name, 'maritalCPF']}
              label={<label>CPF Conjugê/Companheiro(a):</label>}
              help={errors?.maritalCPF && (errors.maritalCPF)}
              validateStatus={errors?.maritalCPF && ('error')}
            >
              <Input placeholder="Digite o cpf" readOnly={readonly} />
            </Form.Item>
          </Col>
        </Row>
      )}
    </Fragment>
  )
}

export default MaritalFields

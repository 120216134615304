import { IChatMessage, IChatInfo, IChatFile, IChatOtherPerson } from './types'

export const chatInfo = (chatInfo: IChatInfo) => ({
  type: 'SET_CHAT_INFO',
  payload: {
    chatInfo
  }
})

export const chatOtherPerson = (person: IChatOtherPerson) => ({
  type: 'SET_CHAT_OTHER_PERSON',
  payload: {
    person
  }
})

export const setChatMessages = (messages: IChatMessage[]) => ({
  type: 'SET_CHAT_MESSAGES',
  payload: {
    messages
  }
})

export const setShowChatMoreDetails = (mode: boolean) => ({
  type: 'SET_SHOW_CHAT_MORE_DETAILS',
  payload: {
    mode
  }
})

export const setTabType = (tabType: 'active' | 'closed') => ({
  type: 'SET_CHAT_TYPE',
  payload: {
    tabType
  }
})

export const setMobileTab = (type: 'list' | 'messages') => ({
  type: 'SET_CHAT_MOBILE_TYPE',
  payload: {
    type
  }
})

export const setUserPage = (page?: number) => ({
  type: 'SET_USERS_PAGE',
  payload: {
    page
  }
})

export const setChatFile = (file: IChatFile) => ({
  type: 'SET_CHAT_FILE',
  payload: {
    file
  }
})

export const pendingChat = () => ({
  type: 'GET_CHAT_PENDING_REQUESTED'
})

export const updateMessages = () => ({
  type: 'UPDATE_MESSAGES'
})

export const updateChats = () => ({
  type: 'UPDATE_CHATS'
})

export const resetChatFile = () => ({
  type: 'RESET_CHAT_FILE'
})

export const resetChatInfo = () => ({
  type: 'RESET_CHAT_INFO'
})

export const resetChat = () => ({
  type: 'RESET_ALL'
})

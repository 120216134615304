import { useState, useCallback } from 'react'
import { message } from 'antd'
import { validateCNPJ } from 'utils/validate'
import { InvalidCNPJ, ProposalService } from 'ecpf/services/proposalService'
import format from 'utils/format'
import { useProposalFlow } from 'ecpf/hooks/useProposalFlow'
import { swalErrorECPF } from 'components/SwalErrorECPF/SwalErrorECPF'
import { ProposalCustomerFlowFuncaoModel } from 'ecpf/models/ProposalFuncaoModel'

const useValidateCnpjECPF = () => {
  const {
    updateClientCovenant,
    clientCovenant
  } = useProposalFlow()

  const [loadingValidate, setLoadingValidate] = useState(false)
  const [cnpjErrorCount, setCnpjErrorCount] = useState(0)
  const isValidCnpj = ProposalCustomerFlowFuncaoModel.isValidCnpj(clientCovenant)

  const onValidateCnpj = useCallback(async (cnpj: string, abortController: AbortController) => {
    try {
      if (!validateCNPJ(cnpj)) {
        throw new InvalidCNPJ()
      }

      const proposalService = new ProposalService()

      setLoadingValidate(true)
      const cnpjOnlyNumbers = format.onlyNumber(cnpj)

      const origensAndConvenants = await proposalService.fetchOrigensAndConvenioByOrgaoCNPJ(cnpjOnlyNumbers, { signal: abortController.signal })

      updateClientCovenant({
        origens: origensAndConvenants?.origens,
        convenios: origensAndConvenants?.convenios
      })

      message.success('CNPJ validado com sucesso.')
      setLoadingValidate(false)
    } catch (err) {
      if (!abortController.signal.aborted) {
        setCnpjErrorCount(prev => prev + 1)
        swalErrorECPF({ error: err })
        setLoadingValidate(false)
      }
    }
  }, [])

  return {
    loadingValidate,
    cnpjErrorCount,
    onValidateCnpj,
    isValidCnpj
  }
}

export default useValidateCnpjECPF

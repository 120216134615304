import { IRoute } from 'components/Sidebar/types'
import { ICorban } from 'egi/types'
import { RouteNameConfigAction } from './types'

export const getSystemConfig = (domain: string) => ({
  type: 'GET_SYSTEM_CONFIG',
  payload: domain
})

export const setSystemConfig = ({ corban }: { corban: ICorban }) => ({
  type: 'SET_SYSTEM_CONFIG',
  payload: { corban }
})

export const setRouteConfig = ({ route }: { route: IRoute }) => ({
  type: 'SET_REQUEST_ROUTE_CONFIG',
  payload: { route }
})

export const resetRouteConfig = () => ({
  type: 'RESET_ROUTE_CONFIG'
})

export const changeSystemRouteName = (newName: string): RouteNameConfigAction => ({
  type: 'SET_ROUTE_NAME_CONFIG',
  payload: newName
})

export const setIsTableRouteConfig = (isTable: boolean) => ({
  type: 'SET_IS_TABLE_ROUTE_CONFIG',
  payload: isTable
})

export const followUpProposal = (active: boolean) => ({
  type: 'FOLLOW_UP_PROPOSAL',
  payload: active
})

import { Tabs } from 'antd'
import Icons from 'components/Icons/Icons'
import { colors } from 'styles/colors'
import React, { Fragment, useEffect, useState } from 'react'
import { IExceptionDocuments } from 'store/modules/proposal/types'
import StorageFileUpload from './StorageFileUpload'
import { IStorageFiles, _tabsType } from './types'

export default function StorageFiles ({ exceptionStorage, proposal, callbackFunction }: IStorageFiles) {
  const [tabType, setTabType] = useState<_tabsType>(exceptionStorage[0].areaName as _tabsType | 'credito')
  const [storage, setStorage] = useState<IExceptionDocuments[]>([])
  const [reload, setReload] = useState<boolean>()

  useEffect(() => {
    setStorage(exceptionStorage)
  }, [exceptionStorage, reload])

  return (
    <Fragment>
      <Tabs defaultActiveKey={tabType} activeKey={tabType} type="card" onChange={key => setTabType(key as _tabsType)}>
        {storage.map((item: IExceptionDocuments) => {
          const storageStatus = item.documents.length ? 'approved' : 'starting'
          return (
            <Tabs.TabPane
              tab={
                <p className='flex flex-space-between flex-align-center'>
                  <span className='mr-2 mt-1'>
                    <Icons name={storageStatus} color={colors[storageStatus]} />
                  </span>
                  {item.name}
                </p>
              }
              key={item.areaName} />
          )
        })}
      </Tabs>
      <StorageFileUpload
        exceptionStorage={exceptionStorage.find((item) => item.areaName === tabType)}
        proposal={proposal}
        tab={tabType}
        onChange={() => setReload(prev => !prev)}
        callbackFunction={callbackFunction}
      />
    </Fragment>
  )
}

import format from 'utils/format'
import { timeAsDayjs } from 'utils/time'
import translate from 'utils/translate'
import { IStepHistoryLastNew } from './reviewBackofficeInterfaces'

export const ALL_CND_STEPS = [
  'cnd-tributos-federais', 'cnd-justica-federal',
  'cnd-justica-do-trabalho-da-comarca-local', 'certidao-de-distribuicoes-civeis',
  'cnd-tributos-imobiliarios', 'cnd-tributos-estaduais',
  'cndt-debitos-trabalhistas', 'cnd-tributos-municipais',
  'matricula-do-imovel', 'matricula-do-imovel-locado'
]

type _time_values = 'cdtfEmissorForIssuer' | 'cdtfDueDateForIssuer' | 'cndtmDateForIssuer' |
'cndtmDueDateForIssuer' | 'cndtDateForIssuer' | 'cndtDueDateForIssuer' | 'cdjfDateForIssuer' |
'cdjfDueDateForIssuer' | 'cdjtDateForIssuer' | 'cdjtDueDateForIssuer' | 'cdcDateForIssuer' |
'cdcDueDateForIssuer' | 'cdtiDateForIssuer' | 'cdtiDueDateForIssuer' | 'dueDate'

export const TIME_VALUES: _time_values[] = [
  'cdtfEmissorForIssuer', 'cdtfDueDateForIssuer',
  'cndtmDateForIssuer', 'cndtmDueDateForIssuer',
  'cndtDateForIssuer', 'cndtDueDateForIssuer',
  'cdjfDateForIssuer', 'cdjfDueDateForIssuer',
  'cdjtDateForIssuer', 'cdjtDueDateForIssuer',
  'cdcDateForIssuer', 'cdcDueDateForIssuer',
  'cdtiDateForIssuer', 'cdtiDueDateForIssuer',
  'dueDate'
]

export function getNewItemProperties (newItem: IStepHistoryLastNew): { label: string, value: string | undefined }[] {
  return [
    {
      label: 'Validade da matrícula',
      value: newItem?.dueDate && timeAsDayjs(newItem.dueDate, { applyTimezone: false }).format('DD/MM/YYYY')
    },
    {
      label: 'Área Vinculada',
      value: translate.areasNames(newItem?.areaName)
    },
    {
      label: 'Porcent. p/ Segurado 1',
      value: newItem?.percentageForFirstInsured && format.decimalToPercentage(Number(newItem.percentageForFirstInsured)).toFixed(2) + '%'
    },
    {
      label: 'Porcent. p/ Segurado 2',
      value: newItem?.percentageForSecondInsured && format.decimalToPercentage(Number(newItem.percentageForSecondInsured)).toFixed(2) + '%'
    },
    {
      label: 'Validade certidão',
      value: newItem?.cdtfDueDateForIssuer && timeAsDayjs(newItem.cdtfDueDateForIssuer, { applyTimezone: false }).format('DD/MM/YYYY')
    },
    {
      label: 'Data emissão',
      value: newItem?.cdtfEmissorForIssuer && timeAsDayjs(newItem.cdtfEmissorForIssuer, { applyTimezone: false }).format('DD/MM/YYYY')
    },
    {
      label: 'Código de verificação',
      value: newItem?.cdtfVerificationCodeForIssuer
    },
    {
      label: 'Data emissão',
      value: newItem?.cndtmDateForIssuer && timeAsDayjs(newItem.cdtfEmissorForIssuer, { applyTimezone: false }).format('DD/MM/YYYY')
    },
    {
      label: 'Validade certidão',
      value: newItem?.cndtmDueDateForIssuer && timeAsDayjs(newItem.cdtfDueDateForIssuer, { applyTimezone: false }).format('DD/MM/YYYY')
    },
    {
      label: 'Código de verificação',
      value: newItem?.cndtmNumberForIssuer
    },
    {
      label: 'Data emissão',
      value: newItem?.cdjfDateForIssuer && timeAsDayjs(newItem.cdjfDateForIssuer, { applyTimezone: false }).format('DD/MM/YYYY')
    },
    {
      label: 'Validade certidão',
      value: newItem?.cdjfDueDateForIssuer && timeAsDayjs(newItem.cdjfDueDateForIssuer, { applyTimezone: false }).format('DD/MM/YYYY')
    },
    {
      label: 'Código de verificação',
      value: newItem?.cdjfNumberForIssuer
    },
    {
      label: 'Data emissão',
      value: newItem?.cndtDateForIssuer && timeAsDayjs(newItem.cndtDateForIssuer, { applyTimezone: false }).format('DD/MM/YYYY')
    },
    {
      label: 'Validade certidão',
      value: newItem?.cndtDueDateForIssuer && timeAsDayjs(newItem.cndtDueDateForIssuer, { applyTimezone: false }).format('DD/MM/YYYY')
    },
    {
      label: 'Código de verificação',
      value: newItem?.cndtNumberForIssuer
    },
    {
      label: 'Data emissão',
      value: newItem?.cdjtDateForIssuer && timeAsDayjs(newItem.cdjtDateForIssuer, { applyTimezone: false }).format('DD/MM/YYYY')
    },
    {
      label: 'Validade certidão',
      value: newItem?.cdjtDueDateForIssuer && timeAsDayjs(newItem.cdjtDueDateForIssuer, { applyTimezone: false }).format('DD/MM/YYYY')
    },
    {
      label: 'Código de verificação',
      value: newItem?.cdjtNumberForIssuer
    },
    {
      label: 'Data emissão',
      value: newItem?.cdcDateForIssuer && timeAsDayjs(newItem.cdcDateForIssuer, { applyTimezone: false }).format('DD/MM/YYYY')
    },
    {
      label: 'Validade certidão',
      value: newItem?.cdcDueDateForIssuer && timeAsDayjs(newItem.cdcDueDateForIssuer, { applyTimezone: false }).format('DD/MM/YYYY')
    },
    {
      label: 'Código de verificação',
      value: newItem?.cdcNumberForIssuer
    },
    {
      label: 'Data emissão',
      value: newItem?.cdtiDateForIssuer && timeAsDayjs(newItem.cdtiDateForIssuer, { applyTimezone: false }).format('DD/MM/YYYY')
    },
    {
      label: 'Validade certidão',
      value: newItem?.cdtiDueDateForIssuer && timeAsDayjs(newItem.cdtiDueDateForIssuer, { applyTimezone: false }).format('DD/MM/YYYY')
    },
    {
      label: 'Código de verificação',
      value: newItem?.cdtiNumberForIssuer
    }
  ].filter((property) => !!property.value)
}

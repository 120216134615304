import { ILabelsAndValues } from 'ecp/repositories/Repository'
import { _typeAccount, _typePix } from './clientInterfaces'
import BetterRateSVG from 'assets/icons/BetterRatesSVG.svg'
import FinancialEducationSVG from 'assets/icons/FinancialEducationSVG.svg'
import AgilitySVG from 'assets/icons/AgilitySVG.svg'
import EasySVG from 'assets/icons/EasySVG.svg'
import PayrollDeductonSVG from 'assets/icons/PayrollDeductonSVG.svg'

export const TIPO_DOCUMENTO_RESOURCE = [
  { value: 'RG', label: 'RG' },
  { value: 'CNH', label: 'CNH' },
  { value: 'RNE', label: 'RNE' },
  { value: 'PASSAPORTE', label: 'PASSAPORTE' }
]

export const TIPO_DOCUMENTO_MASK: {[key: string]: string | undefined} = {
  RG: 'rg',
  CNH: undefined,
  RNE: undefined,
  PASSAPORTE: 'passport'
}

export const POSSIBLES_STATUS_RESOURCE: ILabelsAndValues<string | undefined> = [
  { label: 'Ativo', value: 'ativo' },
  { label: 'Desligado', value: 'desligado' }
]

export const POSSIBLES_ACCESS: ILabelsAndValues<boolean | undefined> = [
  { label: 'Todas', value: undefined },
  { label: 'Sim', value: true },
  { label: 'Não', value: false }
]

export const CLIENTS_FILTERS_ENTRIES_TO_IGNORE: {}[] = [
  { possui_acesso: undefined },
  { status: [] }
]

export const CLIENTS_FILTERS_KEYS_IGNORE = [
  'search',
  'page',
  'limit',
  'sort',
  'identifier'
]

export const CLIENTS_INITIAL_FILTERS = {}

export const HELP_TEXT_FOR_SEARCH_CLIENTS = 'Para buscas referente a informações do cliente use nome, matricula ou CPF.'

export const PAYMENT_TYPE_RESOURCE: ILabelsAndValues<_typeAccount> = [
  { value: 'corrente', label: 'Corrente' },
  { value: 'poupanca', label: 'Poupança' }
]

export const PIX_TYPE_RESOURCE: ILabelsAndValues<_typePix> = [
  { value: 'CHAVE_ALEATORIA', label: 'Chave aleatória' },
  { value: 'CNPJ', label: 'CNPJ' },
  { value: 'CPF', label: 'CPF' },
  { value: 'EMAIL', label: 'E-mail' },
  { value: 'TELEFONE', label: 'Telefone' }
]

export const DISABLED_CLIENT_COMMOM_FIELDS = {
  telefone_celular: true,
  email: true,
  email_secundario: true,
  data_nascimento: true,
  estado_civil: true,
  nome_mae: true,
  sexo: true,
  dependentes: true,
  referencia_nome: true,
  referencia_parentesco: true,
  referencia_telefone: true,
  cargo: true,
  data_admissao: true,
  salario: true,
  inss: true,
  irrf: true,
  descontos: true,
  outros_vencimentos: true,
  comprometimento_outros: true,
  documento: {
    tipo: true,
    numero: true,
    emissor: true,
    uf: true,
    nome_mae: true
  },
  endereco: {
    numero: true,
    complemento: true,
    cep: true,
    logradouro: true,
    uf: true,
    bairro: true
  },
  pagamento: {
    banco: true,
    conta: true,
    conta_dac: true,
    agencia: true,
    agencia_dac: true,
    tipo: true
  },
  convenio: true,
  corban: true
}

export const DISABLED_CLIENT_UNCOMOMON_FIELDS = {
  nome: true,
  sobrenome: true,
  cpf: true,
  matricula: true,
  convenio: true
}

export type DisabledUserFields = Partial<typeof DISABLED_CLIENT_COMMOM_FIELDS & typeof DISABLED_CLIENT_UNCOMOMON_FIELDS>
export const ALL_CLIENT_FIELDS_DISABLED = { ...DISABLED_CLIENT_COMMOM_FIELDS, ...DISABLED_CLIENT_UNCOMOMON_FIELDS }
export const USER_FORM_FIELDS_DISABLED = {
  ...DISABLED_CLIENT_UNCOMOMON_FIELDS,
  sexo: true,
  nome_mae: true,
  data_nascimento: true,
  data_admissao: true,
  cargo: true,
  salario: true,
  inss: true,
  irrf: true,
  descontos: true,
  outros_vencimentos: true,
  comprometimento_outros: true,
  dependentes: true,
  convenio: true,
  corban: true
}

export const CLIENT_INITIAL_PAGE_ADVANTAGES = [
  {
    title: 'Melhores Taxas do Mercado',
    description: 'As taxas de juros mais competitivas do mercado.​',
    icon: BetterRateSVG,
    className: 'advantage-card--first-card'
  },
  {
    title: 'Educação Financeira',
    description: 'Acesso aos cursos da Universidade Rodobens.​​',
    icon: FinancialEducationSVG,
    className: 'advantage-card--second-card'
  },
  {
    title: 'Agilidade',
    description: 'Após a aprovação, o dinheiro pode ser liberado na sua conta em até 1 dia útil.​',
    icon: AgilitySVG,
    className: 'advantage-card--third-card'
  },
  {
    title: 'Facilidade',
    description: 'Contratação rápida e 100% digital.​',
    icon: EasySVG,
    className: 'advantage-card--fourth-card'
  },
  {
    title: 'Desconta na Folha',
    description: 'Menos preocupação: descontos feitos diretamente na sua folha de pagamento.​',
    icon: PayrollDeductonSVG,
    className: 'advantage-card--fifth-card'
  }
]

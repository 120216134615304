import { Button, Col, Form, Row, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { PercentageInput } from 'components'
import simulationCard from 'components/SimulationCard/consumer'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { tablesUpdate } from 'store/modules/tables/actions'
import format from 'utils/format'
import swal from 'utils/swal'
import translate from 'utils/translate'
import StringToHtml from 'components/StringToHtml/StringToHtml'

export interface IFeeReviewFormProps {
  sub: string
  amortizationType: string
  monthlyTax: number,
  commission: number,
  promoterId?: string,
  pricingRevision: {
    needsRevision: boolean
    reason: string
    requestedRevisionAt: string
    _id: string
  }
}

function FeeReviewForm ({ id, fields, onCancel }: { id: string, fields: IFeeReviewFormProps, onCancel: () => void }) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [formRef] = Form.useForm<IFeeReviewFormProps>(undefined)
  const infoFields = fields
  const dispatch = useDispatch()

  async function changesSubmit (values: { monthlyTax: number, commission: number }) {
    setIsLoading(true)

    if (values.monthlyTax) values.monthlyTax = format.percentageToDecimal(values.monthlyTax)

    if (values.commission) values.commission = format.percentageToDecimal(values.commission)

    try {
      const data = {
        monthlyTax: values.monthlyTax,
        commission: values.commission,
        proposalId: id
      }

      const response = await simulationCard.changes(data)
      swal.basic({ title: 'Sucesso!', text: response.message, icon: 'success' })
      setIsLoading(false)
      onCancel()
      dispatch(tablesUpdate())
    } catch (error) {
      const errors = error.data?.invalid
      if (errors) {
        const errorMessages = Array.isArray(errors) ? errors.map((error: { field: string, message: string }) => `${error?.message}`).join('\r\n') : ''
        swal.basic({ title: 'Atenção!', text: errorMessages, icon: 'error' })
      } else {
        swal.basic({ title: 'Atenção!', text: error.message, icon: 'error' })
      }
      setIsLoading(false)
    }
  }

  useEffect(() => {
    function onFill () {
      const data = Object.assign({}, infoFields)

      if (data.monthlyTax) data.monthlyTax = format.decimalToPercentage(data.monthlyTax)

      if (data.commission) data.commission = format.decimalToPercentage(data.commission)

      if (data.monthlyTax) data.monthlyTax = Number(data.monthlyTax.toFixed(2))

      formRef.setFieldsValue(data)
    }

    if (infoFields) onFill()
  }, [infoFields])

  function commissionLabel (promoterName: boolean) {
    if (promoterName) return 'Comissão'

    return (
      <>
        <span>Comissão</span>
        <Tooltip
          title='Essa proposta não possui nenhum parceiro vinculado. Portanto, não é possível revisar a comissão.'
        >
          <InfoCircleOutlined className='ml-1' />
        </Tooltip>
      </>
    )
  }

  return (
    <Row className='review_form'>
      <Form
        form={formRef}
        layout='vertical'
        className='w-100'
        onFinish={changesSubmit}
        initialValues={{
          monthlyTax: 0,
          commission: 0
        }}
      >
        <div className='review_form__infos ml-2'>
          <span>Tipo do Imóvel: </span><span className='review_form__infos__item'>{translate.subProducts(infoFields.sub)}</span>
        </div>
        <div className='review_form__infos ml-2'>
          <span>Amortização: </span><span className='review_form__infos__item'>{infoFields.amortizationType}</span>
        </div>

        <Row gutter={[10, 0]} className='review_form__inputs ml-2'>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              label='Taxa de Juros'
              name='monthlyTax'
              className='review_form__inputs__item'
            >
              <PercentageInput placeholder="Digite a taxa de juros" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              label={commissionLabel(!!fields.promoterId)}
              name='commission'
              className='review_form__inputs__item'
            >
              <PercentageInput disabled={!fields.promoterId} placeholder="Digite a comissão" />

            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[10, 0]} className='review_form__reason-placement ml-2'>
          <Col span={24}>
            <span className='review_form__label'>Motivo:</span>
            <p className='review_form__reason'>
              <StringToHtml value={infoFields.pricingRevision.reason} />
            </p>
          </Col>
        </Row>
        <Row gutter={[10, 0]} className='review_form__button ml-2'>
          <Col span={24}>
            <Form.Item>
              <Button
                htmlType='submit'
                type='primary'
                style={{ width: '100%' }}
                loading={isLoading}
              >
                Confirmar alterações
              </Button>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item>
              <Button
                type='ghost'
                style={{ width: '100%' }}
                onClick={onCancel}
              >
                Cancelar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Row >
  )
}

export default FeeReviewForm

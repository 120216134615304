import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Input, message, Row, Skeleton } from 'antd'
import { Input as CustomInput } from 'components'
import { useForm } from 'antd/lib/form/Form'
import { handleOnidataErrors } from 'utils/validate'
import { formatFieldsValue, formatFinalBodyRequest } from '../../clientFunctions'
import EmployeeFields from '../../components/ClientFields/EmployeeFields'
import { EcpCard } from 'ecp/components/EcpCard/EcpCard'
import { useHistory, useParams } from 'react-router-dom'
import { swalError } from 'components/SwalError/SwalError'
import ErrorDetails from 'components/ErrorDetails/ErrorDetails'
import Datepicker from 'components/Datepicker/Datepicker'
import Select from 'components/Select/Select'
import CovenantSelect from '../../components/CovenantSelect/CovenantSelect'
import { IClientPending } from '../../clientInterfaces'
import CorbanSelect from 'ecp/app/Proposals/components/CorbanSelect/CorbanSelect'
import ClientHeaderInfo from '../../components/ClientHeaderInfo/ClientHeaderInfo'
import ClientPendingRepository from 'ecp/repositories/ClientPendingRepository'
import { getCLientLGPD, useSubmitEndorsement } from '../../hooks/useClientLGPD'
import SubCorbanSelect from 'ecp/app/Proposals/components/SubCorbanSelect/SubCorbanSelect'
import PendingUserModel from 'ecp/models/PendingUserModel'
import { useAuth } from 'hooks'
import { _userLevel } from 'ecp/models/UsersModel'
import DynamicColorButton from 'ecp/components/DynamicColorButton/DynamicColorButton'
import { ClientUploadContracheque } from '../../components/ClientUploadContracheque/ClientUploadContracheque'
import { UploadFile } from 'antd/lib/upload/interface'

interface PropsFields {
  errors?: Partial<IClientPending>
  loading?: boolean
  setSelectedCorban: (id: string) => void
  selectedCorban: string
  isEditing: boolean
  level: _userLevel
}

interface PropsHeader {
  onEndorseClient: Function
  approveLoading: boolean
  reproveLoading: boolean
  clientHeaderInfo: {
    name?: string,
    cpf?: string,
    id?: string
  }
}

export function CorbanClientHeader ({
  clientHeaderInfo,
  onEndorseClient,
  approveLoading,
  reproveLoading
}: PropsHeader) {
  return (
    <div className='client-details__header'>
      <ClientHeaderInfo
        client={{
          name: clientHeaderInfo?.name,
          cpf: clientHeaderInfo?.cpf
        }}
        className='mb-1'
      />

      <div className='client-details__header-buttons flex w-100'>
        <DynamicColorButton
          colorClass='button-red'
          type='primary'
          className='client-details__button'
          onClick={() => onEndorseClient(clientHeaderInfo.id, 'reprove')}
          loading={reproveLoading}
        >
          Remover
        </DynamicColorButton>

        <Button
          type='primary'
          className='client-details__button'
          onClick={() => onEndorseClient(clientHeaderInfo.id, 'approve')}
          loading={approveLoading}
        >
          Aprovar
        </Button>
      </div>
    </div>
  )
}

export function CorbanClientFields ({ errors, loading, setSelectedCorban, selectedCorban, isEditing, level }: PropsFields) {
  const showCorbanSession = !isEditing
  const showCovenantSession = !isEditing

  return (
    <>
      {showCorbanSession &&
        <>
          <h3 className='color-primary'>Corbans</h3>

          <Row gutter={[14, 14]}>
            {PendingUserModel.showSelectCorbanInForm(level) &&
              <Col lg={8} sm={12} xs={24}>
                <Form.Item
                  name='corbanId'
                  label='Corban'
                  help={errors?.corbanId}
                  validateStatus={errors?.corbanId && ('error')}
                  rules={[{ required: true, message: 'Escolha o corban!' }]}
                >
                  <CorbanSelect
                    placeholder='Selecione o corban'
                    onChange={(e) => setSelectedCorban(e)}
                    hasAllResource={false}
                  />
                </Form.Item>
              </Col>
            }

            {PendingUserModel.showSelectSubcorbanInForm(level) &&
              <Col lg={8} sm={12} xs={24}>
                <Form.Item
                  name='corbanId'
                  label='Sub Corban'
                  help={errors?.corbanId}
                  validateStatus={errors?.corbanId && ('error')}
                  rules={[{ required: true, message: 'Escolha o sub-corban!' }]}
                >
                  <SubCorbanSelect
                    placeholder='Selecione o sub-corban'
                    onChange={(e) => setSelectedCorban(e)}
                    hasAllResource={false}
                  />
                </Form.Item>
              </Col>
            }
          </Row>
        </>
      }

      <h3 className='color-primary'>Dados Pessoais</h3>
      <Row gutter={[12, 12]}>
        <Col lg={8} sm={12} xs={24}>
          <Form.Item
            name='nome'
            label='Nome'
            help={errors?.nome}
            validateStatus={errors?.nome && ('error')}
          >
            <Input
              placeholder='Insira o nome'
              disabled={loading}
            />
          </Form.Item>
        </Col>

        <Col lg={8} sm={12} xs={24}>
          <Form.Item
            name='sobrenome'
            label='Sobrenome'
            help={errors?.sobrenome}
            validateStatus={errors?.sobrenome && ('error')}
          >
            <Input
              placeholder='Insira o sobrenome'
              disabled={loading}
            />
          </Form.Item>
        </Col>

        <Col lg={8} sm={12} xs={24}>
          <Form.Item
            name='cpf'
            label='CPF'
            help={errors?.cpf}
            validateStatus={errors?.cpf && ('error')}
          >
            <CustomInput
              mask='cpf'
              placeholder='Insira o CPF'
              disabled={loading}
            />
          </Form.Item>
        </Col>

        <Col lg={8} sm={24} xs={24} className="px-1">
          <Form.Item
            name="data_nascimento"
            label={<label>Data de nascimento</label>}
            help={errors?.data_nascimento && errors.data_nascimento}
            validateStatus={errors?.data_nascimento && 'error'}
            rules={[{ required: true, message: 'Escolha a data de nascimento do cliente!' }]}
          >
            <Datepicker placeholder='Insira a data de nascimento' />
          </Form.Item>
        </Col>

        <Col lg={8} sm={12} xs={24}>
          <Form.Item
            name='email'
            label='Email corporativo'
            help={errors?.email}
            validateStatus={errors?.email && ('error')}
          >
            <Input
              lang='pt-br'
              placeholder='Insira o email corporativo'
              disabled={loading}
            />
          </Form.Item>
        </Col>

        <Col lg={8} sm={12} xs={24}>
          <Form.Item
            name='sexo'
            label='Sexo'
            help={errors?.sexo}
            validateStatus={errors?.sexo && ('error')}
          >
            <Select
              placeholder='Selecione o sexo'
              showSearch
              disabled={loading}
            >
              <Select.Option value='M'>Masculino</Select.Option>
              <Select.Option value='F'>Feminino</Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col lg={8} sm={12} xs={24}>
          <Form.Item
            name='nome_mae'
            label='Nome da mãe'
            help={errors?.nome_mae}
            validateStatus={errors?.nome_mae && ('error')}
          >
            <Input
              placeholder='Insira o nome da mãe'
              disabled={loading}
            />
          </Form.Item>
        </Col>
      </Row>

      {showCovenantSession &&
        <>
          <h3 className='color-primary'>Dados Profissionais</h3>
          <Row gutter={[12, 12]}>
            <Col lg={8} sm={24} xs={24}>
              <Form.Item
                name='covenantId'
                label='Empresa'
                help={errors?.covenantId}
                validateStatus={errors?.covenantId && ('error')}
              >
                <CovenantSelect
                  hasAllResource={false}
                  corbanId={selectedCorban}
                  disabled={!selectedCorban}
                  placeholder='Selecione a empresa'
                />
              </Form.Item>
            </Col>

            <Col lg={16} sm={24} xs={24}>
              <Form.Item
                name='email_gestor_imediato'
                label='Email do gestor imediato'
                help={errors?.email_gestor_imediato}
                validateStatus={errors?.email_gestor_imediato && ('error')}
              >
                <Input
                  lang='pt-br'
                  placeholder='Insira o email do gestor imediato'
                  type='email_gestor_imediato'
                  disabled={loading}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      }

      <h3 className='color-primary'>Detalhes do funcionário</h3>
      <EmployeeFields
        dataNascimento={null}
        errors={errors}
        disabledFields={{}}
      />
    </>
  )
}

export function CorbanClientPayCheck ({ payCheckUrl }: { payCheckUrl: string }) {
  return (
    <>
      <h3 className="color-primary">Contracheque</h3>
      <img src={payCheckUrl} alt="contracheque" className="m-3" />
    </>
  )
}

function CorbanClientForm () {
  const [form] = useForm()
  const history = useHistory()
  const user = useAuth()
  const { clientId } = useParams<{ clientId?: string }>() || {}
  const [file, setFile] = useState<UploadFile>()

  const {
    approveLoading,
    reproveLoading,
    onEndorseClient
  } = useSubmitEndorsement()

  const formData = new FormData()
  const isEdit = clientId ? true : false

  const [errors, setErrors] = useState<Partial<IClientPending>>()
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
  const [selectedCorban, setSelectedCorban] = useState<string>('')
  const { loadingGetClient, errorGetClient, clientLGPD } = getCLientLGPD(clientId || '')
  const [clientHeaderInfo, setCLientHeaderInfo] = useState({
    name: '',
    cpf: '',
    id: ''
  })

  const onUploadFile = (file: UploadFile) => {
    setFile(file)
  }

  const onRemoveFile = () => {
    formData.delete('contracheque')
    setFile(undefined)
  }

  async function onSubmit (values: IClientPending) {
    setLoadingSubmit(true)
    setErrors(undefined)

    try {
      if (!form) throw new Error('Não foi possível validar os dados inseridos.')

      const finalBody = formatFinalBodyRequest(values)

      Object.entries(finalBody).forEach(([key, value]) => {
        if (value !== null && value !== undefined) formData.append(key, value as string)
      })

      if (file) formData.append('contracheque', file.originFileObj as Blob)

      const requestConfig = {
        headers: { 'Content-Type': 'multipart/form-data' }
      }

      let response
      if (isEdit) {
        if (!clientId) throw new Error('Não foi possível obter o Id do Cliente.')
        response = await ClientPendingRepository.updateClientInvite(clientId, formData, requestConfig)
      } else {
        response = await ClientPendingRepository.createClientInvite(formData, requestConfig)
      }

      const responseMessage = response?.data?.message
      if (!responseMessage) throw new Error('Não possível obter uma resposta referente a ação realizada.')

      message.success(responseMessage)
      onRemoveFile()

      if (isEdit) {
        history.goBack()
      } else {
        form.resetFields()
        setSelectedCorban('')
      }
    } catch (err) {
      if (err?.data && err?.data?.error) setErrors(handleOnidataErrors(err.data.error))
      swalError({ title: 'Atenção', err, icon: 'warning' })
    }
    setLoadingSubmit(false)
  }

  useEffect(() => {
    function onFill (clientLGPD: IClientPending, id: string) {
      setCLientHeaderInfo({
        name: clientLGPD?.nome,
        cpf: clientLGPD?.cpf,
        id: id
      })

      form.setFieldsValue(formatFieldsValue(clientLGPD))
    }

    if (clientLGPD && clientId) onFill(clientLGPD, clientId)
  }, [clientLGPD])

  if (errorGetClient) {
    return (
      <EcpCard>
        <ErrorDetails
          title="500"
          error={errorGetClient}
          subTitle={errorGetClient?.message}
          status="error"
        />
      </EcpCard>
    )
  }

  if (loadingGetClient) {
    return (
      <EcpCard>
        <Skeleton />
      </EcpCard>
    )
  }

  return (
    <EcpCard>
      <Form
        form={form}
        onFinish={onSubmit}
        layout='vertical'
      >
        {isEdit && clientId && (
          <CorbanClientHeader
            approveLoading={approveLoading}
            reproveLoading={reproveLoading}
            onEndorseClient={onEndorseClient}
            clientHeaderInfo={clientHeaderInfo}
          />
        )}

        <CorbanClientFields
          errors={errors}
          loading={loadingGetClient}
          setSelectedCorban={setSelectedCorban}
          selectedCorban={selectedCorban}
          isEditing={isEdit}
          level={user.level}
        />

        {clientLGPD?.contracheque ? (
          <CorbanClientPayCheck
            payCheckUrl={clientLGPD?.contracheque}
          />
        ) : (
          <div className='mb-3'>
            <ClientUploadContracheque
              file={file}
              onRemoveFile={onRemoveFile}
              onUploadFile={onUploadFile}
            />
          </div>
        )}

        <div className='client-form__footer'>
          <Button
            type='ghost'
            onClick={() => history.goBack()}
          >
            Cancelar
          </Button>

          <Button
            type='primary'
            htmlType='submit'
            loading={loadingSubmit}
          >
            Salvar
          </Button>
        </div>
      </Form>
    </EcpCard>
  )
}

export default CorbanClientForm

import React from 'react'
import mainColumns from 'egi/views/UsersTable/DataTables'
import { TeamOutlined } from '@ant-design/icons'
import translate from 'utils/translate'
import UsersTable from 'egi/views/UsersTable/UsersTable'
import { IRoute } from 'components/Sidebar/types'
import { EGI } from 'routes/egi/paths'

const leaders: IRoute = {
  path: EGI.ENGENHARIA_LEADERS.path,
  name: EGI.ENGENHARIA_LEADERS.name,
  icon: TeamOutlined,
  component: () => <UsersTable url='/administrators' area="engenharia" level="administrator" inviteButtonText={translate.level('administrator')} columns={mainColumns.administrator} />,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const collaborators: IRoute = {
  path: '/engenharia/collaborator',
  name: 'Funcionários',
  icon: TeamOutlined,
  component: () => <UsersTable url='/backoffices' area="engenharia" level="backoffice" inviteButtonText={translate.level('backoffice')} columns={mainColumns.backoffice} />,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const providers: IRoute = {
  path: EGI.ENGENHARIA_PROVIDERS.path,
  name: EGI.ENGENHARIA_PROVIDERS.name,
  icon: TeamOutlined,
  component: () => <UsersTable url='/providers' area="engenharia" level="provider" inviteButtonText={translate.level('provider')} columns={mainColumns.provider} />,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const engenhariaTeam = {
  leaders,
  collaborators,
  providers
}

export {
  engenhariaTeam,
  providers
}

import { Form } from 'antd'
import React, { Fragment, useState } from 'react'
import usePartnerStep from '../../../../hooks/usePartnerStep'
import usePartnerStepDrawer from '../../../../hooks/usePartnerStepDrawer'
import { Drawer } from 'components'
import ExplainSend from 'components/ExplainSend/ExplainSend'
import { AxiosRequestConfig } from 'axios'
import swal from 'utils/swal'
import { correctTimezone, timeAsDayjs } from 'utils/time'
import { validateResponse } from 'utils/validate'
import { swalError } from 'components/SwalError/SwalError'
import usePartnerDocumentation from '../../../../hooks/usePartnerDocumentation'
import useDepartmentStep from '../../../../hooks/usePartnerDepartments'
import ReviewStep from '../../../ReviewStep/ReviewStep'
import PartnerStepButton from '../../../PartnerStepButton/PartnerStepButton'
import { ICorbanCertificateForm, ICorbanCertificateFormErrors } from './types'
import CorbanCertificateForm from './CorbanCertificateForm/CorbanCertificateForm'
import ReviewDescription from '../../../ReviewDescription/ReviewDescription'
import PartnerModel from 'egi/models/PartnerModel'
import PartnersRepository from 'egi/repositories/PartnersRepository'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'

function CorbanCertificateStep () {
  const [documentPath, setDocumentPath] = useState<string>()
  const [errors, setErrors] = useState<ICorbanCertificateFormErrors>({})
  const [status, setStatus] = useState<'back' | 'saving' | 'sending'>()

  const { step } = usePartnerStep()
  const { slug } = useDepartmentStep()
  const { close, open, visible } = usePartnerStepDrawer()
  const { _id: documentId } = usePartnerDocumentation()
  const [formRef] = Form.useForm<ICorbanCertificateForm>()
  const sendForm = PartnerModel.canSendForm(step)

  function setFormFields (form: ICorbanCertificateForm) {
    try {
      const data = Object.assign({}, form)

      formRef.setFieldsValue({
        validate: data.validate ? timeAsDayjs(data.validate).format('DD/MM/YYYY') : undefined
      })
    } catch (err) {
      swal.basic({ title: 'Aviso', text: err?.message ?? 'Não foi possível realizar a ação.' })
    }
  }

  async function onFileDelete (url: string, resolve: Function) {
    try {
      const response = await PartnersRepository.deleteDocument(documentId, { department: slug, step: step.slug, file: url })
      if (!response) throw new Error('Não foi possível deletar o arquivo.')
      resolve(true)
    } catch (err) {
      swal.basic({ title: 'Aviso', text: err?.message ?? 'Não foi possível realizar a ação.' })
    }

    setFormFields(step.form as ICorbanCertificateForm)
  }

  async function onFileUpload (request: any) {
    const { file, onSuccess, onProgress, onError } = request
    if (!file) return

    const formData = new FormData()
    formData.append('file', file)
    formData.append('department', slug)
    formData.append('step', step.slug)

    const config: AxiosRequestConfig = {
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 90000,
      onUploadProgress: ({ total, loaded }) => {
        if (total) onProgress({ percent: Math.round((loaded / total) * 100) })
      }
    }

    try {
      const response = await PartnersRepository.sendDocument(documentId, formData, config)
      setDocumentPath(response?.data.data.file)
      onSuccess(response)
    } catch (err) {
      onError(err)
    }
  }

  function onClose () {
    formRef.resetFields()
    close()
  }

  async function onFinish (values: ICorbanCertificateForm) {
    setStatus('sending')
    const data = {
      ...values,
      url: documentPath,
      department: slug,
      step: step.slug,
      validate: correctTimezone(values?.validate as string)
    }

    try {
      const response = await PartnersRepository.send(documentId, data)
      if (!response) throw new Error('Não foi possível salvar as informações.')
      swal.basic({ icon: 'success', title: 'Sucesso!', text: response.data.message })
      onClose()
    } catch (err) {
      if (err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
      } else {
        swalError({ title: 'Atenção', icon: 'warning', err })
      }
    }
    setStatus(undefined)
  }

  return (
    <Fragment>
      <PartnerStepButton
        status={step.status}
        enabled={true}
        onClick={open}
        step={step}
      >
        {step.name}
      </PartnerStepButton>

      <Drawer
        visible={visible}
        onClose={onClose}
        onSubmit={() => formRef.submit()}
        onSave={() => formRef.submit()}
        showSubmit={true}
        disabled={!sendForm}
        title={step.name}
        status={status}
      >
        <Fragment>
          <div className="my-4">
            <ExplainSend
              isDocument={true}
            />
          </div>

          <Form
            form={formRef}
            layout="vertical"
            onFinish={onFinish}
          >
            <CorbanCertificateForm
              readonly={!sendForm}
              errors={errors}
              onFileUpload={onFileUpload}
              setFormFields={setFormFields}
              onFileDelete={onFileDelete}
              formRef={formRef}
            />
          </Form>

          <div className="my-4">
            <ReviewDescription />
          </div>

          <ReviewStep onSuccess={close} />
        </Fragment>
      </Drawer>
    </Fragment>
  )
}

export default CorbanCertificateStep

const defaultStyle = {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 'normal'
  },

  '&multiLine': {
    control: {
      fontFamily: 'Montserrat'
    },
    highlighter: {
      padding: 12,
      border: '1px solid transparent',
      minHeight: 150,
      maxHeight: 250,
      overflowY: 'auto',
      overflowX: 'hidden'
    },
    input: {
      padding: 12,
      border: '1px solid #d9d9d9',
      borderRadius: 20,
      outline: 'none'
    }
  },

  '&singleLine': {
    display: 'inline-block',
    width: 180,

    highlighter: {
      padding: 1,
      border: '2px inset transparent'
    },
    input: {
      padding: 1,
      border: '2px inset'
    }
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      borderRadius: 12,
      fontSize: 14,
      maxHeight: 200,
      overflow: 'auto',
      border: '1px solid lightgray'
    },
    item: {
      padding: '5px 15px',
      '&focused': {
        backgroundColor: 'lightgray'
      }
    }
  }
}

const defaultMentionStyle = {
  backgroundColor: '#A8E5AE',
  padding: '1px 0',
  borderRadius: 3
}

export {
  defaultStyle,
  defaultMentionStyle
}

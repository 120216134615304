import React, { useLayoutEffect, useState } from 'react'
import { Col, Row, Skeleton, Typography } from 'antd'
import MlmBaseCard from '../MlmBaseCard/MlmBaseCard'
import format from 'utils/format'
import { CheckCircleFilled, ExclamationCircleFilled, CloseCircleFilled, StopOutlined } from '@ant-design/icons'
import { IFilterDate } from '../../views/MlmDashboard/types'
import DashboardEgiRepository from 'egi/repositories/DashboardEgiRepository'
const { compressedMoney } = format

const baseTotals = {
  approved: { count: 0, value: 0 },
  reproved: { count: 0, value: 0 },
  ongoing: { count: 0, value: 0 },
  canceled: { count: 0, value: 0 }
}

export type DashboardEgiBaseTotals = typeof baseTotals

function MlmProposalsMinorDashCards ({ filterDate }: IFilterDate) {
  const [loading, setLoading] = useState<boolean>(true)
  const [failed, setFailed] = useState<boolean>(false)
  const [totals, setTotals] = useState<DashboardEgiBaseTotals>(baseTotals)

  const finalTitleClass = 'dashboard-card-title-letter-spacing'

  useLayoutEffect(() => {
    const abortController = new AbortController()
    async function fetchStatus () {
      setLoading(true)
      try {
        const response = await DashboardEgiRepository.totals({
          signal: abortController.signal,
          params: filterDate
        })

        if (response.data.data) setTotals(response.data.data)
        else setTotals(baseTotals)

        setFailed(false)
        setLoading(false)
      } catch (err) {
        if (!abortController.signal.aborted) {
          setFailed(true)
          setLoading(false)
        }
      }
    }
    if (filterDate.createdDate && filterDate.productId) fetchStatus()
    return () => abortController.abort()
  }, [filterDate.createdDate, filterDate.productId, filterDate.promoterId, filterDate.sellerId, filterDate.type])

  const { approved, reproved, ongoing, canceled } = totals

  return (
    <Row className="dashboard-status-card-square" align="stretch" style={{ margin: '7.5px 0 15px 0' }}>
      <Col xl={4} lg={8} md={24} sm={24} xs={24}>
        {loading ? (
          <Skeleton
            active={true}
            paragraph={{ rows: 1 }}
          />
        ) : (
          <MlmBaseCard
            titleClass={finalTitleClass}
            valueColor="primary"
            failed={failed}
            description='Total de propostas'
            value={`${reproved.count + ongoing.count + approved.count + canceled.count}`}
            rawValue={reproved.value + ongoing.value + approved.value + canceled.value}
          />
        )}
      </Col>

      <Col xl={5} lg={8} md={24} sm={24} xs={24} className="mb-md-3">
        {loading ? (
          <Skeleton
            active={true}
            paragraph={{ rows: 1 }}
          />
        ) : (
          <MlmBaseCard
            titleClass={finalTitleClass}
            valueColor='approved'
            failed={failed}
            value={compressedMoney(approved.value)}
            rawValue={approved.value}
            description='Aprovadas:'
            Icon={
              <div>
                <Typography.Title className="text-center color-primary" level={4}>{failed ? '/' : approved.count}</Typography.Title>
                <CheckCircleFilled className={'dashboard-icon-size-mini color-approved'} />
              </div>
            }
          />
        )}
      </Col>

      <Col xl={5} lg={8} md={24} sm={24} xs={24} className="mb-md-3">
        {loading ? (
          <Skeleton
            active={true}
            paragraph={{ rows: 1 }}
          />
        ) : (
          <MlmBaseCard
            titleClass={finalTitleClass}
            valueColor="analysis"
            failed={failed}
            value={compressedMoney(ongoing.value)}
            rawValue={ongoing.value}
            description='Andamento:'
            Icon={
              <div>
                <Typography.Title className="text-center color-primary" level={4}>{failed ? '/' : ongoing.count}</Typography.Title>
                <ExclamationCircleFilled className={'dashboard-icon-size-mini color-analysis'} />
              </div>
            }
          />
        )}
      </Col>

      <Col xl={5} lg={12} md={24} sm={24} xs={24} className="mb-md-3">
        {loading ? (
          <Skeleton
            active={true}
            paragraph={{ rows: 1 }}
          />
        ) : (
          <MlmBaseCard
            titleClass={finalTitleClass}
            valueColor="refused"
            failed={failed}
            value={compressedMoney(reproved.value)}
            description='Recusadas:'
            Icon={
              <div>
                <Typography.Title className="text-center color-primary" level={4}>{failed ? '/' : reproved.count}</Typography.Title>
                <CloseCircleFilled className={'dashboard-icon-size-mini color-refused'} />
              </div>
            }
            rawValue={reproved.value}
          />
        )}
      </Col>

      <Col xl={5} lg={12} md={24} sm={24} xs={24} className="mb-md-3">
        {loading ? (
          <Skeleton
            active={true}
            paragraph={{ rows: 1 }}
          />
        ) : (
          <MlmBaseCard
            titleClass={finalTitleClass}
            valueColor="canceled"
            failed={failed}
            value={compressedMoney(canceled.value)}
            description='Canceladas:'
            Icon={
              <div>
                <Typography.Title className="text-center color-primary" level={4}>{failed ? '/' : canceled.count}</Typography.Title>
                <StopOutlined className={'dashboard-icon-size-mini color-canceled'} />
              </div>
            }
            rawValue={canceled.value}
          />
        )}
      </Col>
    </Row>
  )
}

export default MlmProposalsMinorDashCards

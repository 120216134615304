import React, { FC, ReactNode } from 'react'
import {
  IDynamicProposal,
  IDynamicProposalLastStep,
  IDynamicProposalStep,
  IProposalDepartment,
  IProposalHistory,
  _proposalSteps,

  _stepActions
} from 'ecp/app/Proposals/proposalInterfaces'
import { useAuth, useDynamicProposalStore } from 'hooks'
import GenericStepWithDrawer from './GenericStepWithDrawer/GenericStepWithDrawer'
import AnalisaCreditoStep from './AnaliseCreditoStep/AnaliseCreditoStep'
import AverbacaoStep from './AverbacaoStep/AverbacaoStep'
import ScoreStep from './ScoreStep/ScoreStep'
import JuridicalStep from './JuridicalStep/JuridicalStep'
import SignatureStep from './SignatureStep/SignatureStep'
import CrivoStep from 'ecp/app/Proposals/components/ProposalSteps/CrivoStep/CrivoStep'
import PagamentoStep from 'ecp/app/Proposals/components/ProposalSteps/PagamentoStep/PagamentoStep'
import CipStep from './CipStep/CipStep'
import { DocumentalStep } from './DocumentalStep/DocumentalStep'
import { _proposalStatusLabel } from 'globals'

export type IThisStepContext = {
  statusLabel: _proposalStatusLabel
  lastStep: IDynamicProposalLastStep
  step: IDynamicProposalStep
  department: IProposalDepartment
  permissions: _stepActions[]
  proposalStep: IDynamicProposal.Step
  stepHistory: IProposalHistory.List
}

export const ThisStepContext = React.createContext<IThisStepContext>({} as unknown as IThisStepContext)

type IProps = {
  step: IDynamicProposalStep
  department: IProposalDepartment
  stepHistory: IProposalHistory.List,
}

/* *****************************************************
👀 If you need to pass props to any step component,
move this object inside ProposalStep and call them from there
********************************************************/
export const StepComponents: { [key in _proposalSteps]?: FC } = {
  'assinatura-do-contrato': SignatureStep,
  averbacao: AverbacaoStep,
  'analise-de-credito': AnalisaCreditoStep,
  'melhore-seu-score': ScoreStep,
  'aprovacao-do-juridico': JuridicalStep,
  crivo: CrivoStep,
  pagamento: PagamentoStep,
  cip: CipStep,
  'analise-documental': DocumentalStep
}

const ProposalStep = ({ step, department, stepHistory }: IProps) => {
  const user = useAuth()
  const { details } = useDynamicProposalStore()

  const proposalDepartment = details?.value?.departments?.[department.slug]
  const { slug } = step || {}
  const proposalStep = proposalDepartment?.steps?.[slug] as IDynamicProposal.Step
  const { status } = proposalStep || {}

  const permissions = status ? step.permissionConfiguration?.[user.level]?.[status] || [] : []

  const lastStep = details?.value.lastStep as IDynamicProposalLastStep
  const statusLabel = details?.value?.statusLabel as _proposalStatusLabel

  const StepComponent = StepComponents[step.slug] || GenericStepWithDrawer

  const context: IThisStepContext = {
    step,
    department,
    proposalStep,
    lastStep,
    stepHistory,
    statusLabel,
    permissions
  }

  if (!status) return null

  return (
    <div className='proposal-step'>
      <ThisStepContext.Provider value={context}>
        <StepComponent key={step?._id} />
      </ThisStepContext.Provider>
    </div>
  )
}

export const ProposalStepContent = ({ children }: { children: ReactNode }) => {
  return <div className='proposal-step__content'>
    {children}
  </div>
}

ProposalStepContent.Title = ({ children, className }: { children: ReactNode, className?: string }) => {
  return <h3 className={`proposal-step__content-title ${className || ''}`}>
    {children}
  </h3>
}

ProposalStepContent.Subtitle = ({ children, className }: { children: ReactNode, className?: string }) => {
  return <h3 className={`proposal-step__content-subtitle ${className || ''}`}>
    {children}
  </h3>
}

ProposalStepContent.Description = ({ children, className }: { children: ReactNode, className?: string }) => {
  return <p className={`proposal-step__content-description ${className || ''}`}>
    {children}
  </p>
}

export default ProposalStep

import React, { Fragment } from 'react'

function BacenText () {
  return (
    <Fragment>

      <p className='my-2'>
        O PARCEIRO/CORRESPONDENTE autoriza a RODOBENS e as sociedades sob seu controle direto ou indireto, a qualquer tempo, mesmo após a extinção desta operação, a:
        (a) trocar entre si informações suas constantes do cadastro de referidas empresas;
        (b) fornecer ao Banco Central, para integrar o SCR, informações sobre o montante de suas dívidas a vencer e vencidas, inclusive as em atraso e as operações baixadas com prejuízo, bem como o valor das coobrigações assumidas pelo PARCEIRO/CORRESPONDENTE e das garantias por ele prestadas, e
        (c) consultar o SCR sobre eventuais informações nele existentes com relação ao PARCEIRO/CORRESPONDENTE.
      </p>

      <p className='my-2'>
        O PARCEIRO / CORRESPONDENTE declara que está ciente de que a consulta ao SCR depende da autorização prévia e que eventual consulta anterior, para fins desta contratação, foi realizada sob autorização, ainda que verbal.
      </p>

      <p className='my-2'>
        O PARCEIRO / CORRESPONDENTE declara que está ciente de que pode ter acesso, a qualquer tempo, aos dados do SCR pelos meios colocados à sua disposição pelo Banco Central e, em caso de divergência nos dados do SCR fornecidos pela RODOBENS e / ou sociedade sob seu controle direto ou indireto, poderá solicitar sua correção, exclusão ou registro de anotação complementar, inclusive de medidas judiciais, mediante solicitação escrita e fundamentada a RODOBENS.
      </p>

      <p>
        Do tratamento de Dados Pessoais:
      </p>

      <p>
        O Banco Rodobens realiza o tratamento de dados pessoais de acordo com as determinações indicadas pela Lei nº 13.709 / 2018(LGPD), respeitando a boa fé e os direitos dos titulares de dados, certo de que em casos de dúvidas acesse nossa política de privacidade por meio de nossa plataforma digital <a className='link-style' href='https://www.rodobens.com.br' target='_blank' rel='noreferrer'>www.rodobens.com.br</a>
      </p>

      <p className='my-2'>
        <b>
          As Partes declaram, nos termos da Medida Provisória nº 2200-2, de 24.08.2001, em seu artigo 10, parágrafo 2º, que aceitam como válidos todos os documentos, termos e
          contratos eletrônicos firmados por meio de assinaturas eletrônicas.
        </b>
      </p>
    </Fragment>
  )
}

export default BacenText

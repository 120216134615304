import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { handleServerTableChange } from 'components/ServerTable/function'
import ServerTable from 'components/ServerTable/ServerTable'
import { useAuth, useTables } from 'hooks'
import React from 'react'
import { useDispatch } from 'react-redux'
import { tablesSetFiltersValues, tablesUpdate } from 'store/modules/tables/actions'
import { LimiterTable } from 'components/LimiterTable/LimiterTable'
import { MasterFilters } from '../../components/MasterFilters/MasterFilters'
import translate from 'utils/translate'
import { _productSlugs } from 'ecp/models/ProductModel'
import { Switch } from 'antd'
import swal from 'utils/swal'
import { IUser } from 'egi/types'
import UsersEgiRepository from 'egi/repositories/UsersEgiRepository'
import { MasterModal } from '../../components/MastersModal/MastersModal'
import commonColumns from 'components/UserTable/utils/commonColumns'
import UserTableModels from 'egi/models/UserTableModels'
import { Link } from 'react-router-dom'
import { userDetails } from 'routes/routes'

export interface IMaster {
  name: string
  cpf: string
  email: string
  cellphone: string,
  createdAt: string,
  emailVerified: boolean,
  products: _productSlugs[],
  blocked: boolean

}

const columns: ColumnsType<IMaster> = [
  {
    title: 'Nome',
    dataIndex: 'name',
    sorter: true,
    render: (name: string, row: any) => {
      const user = useAuth()
      if (!name) return 'N/A'

      if (UserTableModels.showUsersDetails(user.level)) {
        return (
          <Link
            className="link-style color-tertiary font-500"
            to={'/auth' + userDetails.path.replace(':userId', row._id)}
          >
            {name}
          </Link>
        )
      }

      return name
    }
  },
  {
    title: 'CPF',
    dataIndex: 'cpf',
    sorter: true
  },
  commonColumns.email,
  {
    title: 'Celular',
    dataIndex: 'cellphone',
    sorter: true
  },
  {
    title: 'Dt. criação',
    dataIndex: 'createdAt',
    sorter: true
  },
  {
    title: 'Produtos',
    dataIndex: 'products',
    sorter: true,
    width: 230,

    render: (products: _productSlugs[], row: IMaster) => {
      if (!products || products.length === 0) return '-'

      return (
        <div className="master-list__products">
          <p>{translate.productSlug(products[0] ?? '-')}</p>
          <MasterModal row={row} />
        </div>)
    }
  },
  {
    title: 'Bloquear',
    dataIndex: 'blocked',
    sorter: true,
    render: (isBlocked: boolean, row: IUser) => {
      const { _id } = (row as IUser)
      const user = useAuth()
      const dispatch = useDispatch()

      return (
        <Switch
          checked={isBlocked}
          disabled={!UserTableModels.canBlockUser(user.level)}
          onClick={() => {
            swal.confirmNegate({
              title: 'Atenção',
              text: `Tem certeza que deseja ${isBlocked ? 'desbloquear' : 'bloquear'} o usuário?`,
              icon: 'warning',
              confirm: async () => {
                if (_id) await UsersEgiRepository.block(_id)
                dispatch(tablesUpdate())
              }
            })
          }}
        />
      )
    }
  }
]

export default function MasterList () {
  const table = useTables()
  const dispatch = useDispatch()

  return (
    <>
      <LimiterTable>
        <MasterFilters />
        <ServerTable
          url='/master'
          columns={columns}
          onChange={
            (pagination: TablePaginationConfig, filters: Record<string, (string | number | boolean)[] | null>, sorter) => {
              const serverTableFiltersData = handleServerTableChange(columns, table.filters)(pagination, filters, sorter)
              dispatch(tablesSetFiltersValues({ ...table.filters, ...serverTableFiltersData }))
            }
          }
        />
      </LimiterTable>
    </>
  )
}

import { Button, Checkbox, Col, Form, Row, Select } from 'antd'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import React, { Fragment, useState } from 'react'
import DynamicColorButton from 'ecp/components/DynamicColorButton/DynamicColorButton'
import { IFormPropertyAddressErrors } from 'components/FormUserData/Property/types'
import { FormInstance } from 'antd/lib/form'

export interface ICoOwnersFormErrors extends IFormPropertyAddressErrors {
  [key: string]: string | undefined;
}

export interface ICoOwners {kinshipDegree: string, composeIncome: boolean}

interface PropertyFormOwnerProps {
  readOnlyInput: boolean
  formErrors: ICoOwnersFormErrors | undefined
  coOwners: ICoOwners[] | undefined
  formRef: FormInstance
}

const PropertyFormOwnerList = (props: PropertyFormOwnerProps) => {
  const {
    readOnlyInput,
    formErrors,
    coOwners,
    formRef
  } = props

  const [forceRender, setForceRender] = useState(false)
  const [selectedKinshipDegrees, setSelectedKinshipDegrees] = useState<ICoOwners[]>(coOwners ?? [])

  return (
    <Form.List
      name='coOwners'>
      {(fields, { add, remove }, { errors }) => {
        if (!fields.length) {
          add()
          setForceRender(!forceRender)
        }

        return (
          <>
            {fields.map((field, index) => {
              const handleKinshipDegreeChange = (value: string) => {
                setSelectedKinshipDegrees((prevDegrees) => {
                  const updatedDegrees = [...prevDegrees]
                  if (!updatedDegrees[index]) {
                    updatedDegrees[index] = { kinshipDegree: '', composeIncome: false }
                  }

                  updatedDegrees[index].kinshipDegree = value

                  if (value === 'PJ') {
                    formRef.setFieldsValue({
                      coOwners: formRef.getFieldValue('coOwners').map((coOwner: ICoOwners, i: number) => {
                        if (i === index) {
                          return {
                            ...coOwner,
                            composeIncome: false
                          }
                        }
                        return coOwner
                      })
                    })
                  }
                  return updatedDegrees
                })
              }

              return (
                <Row key={index} gutter={[15, 0]} align='middle'>
                  <Col lg={8} sm={24} xs={24} md={24}>
                    <Form.Item
                      help={ formErrors?.[`kinshipDegree_${index}`] && formErrors?.[`kinshipDegree_${index}`]}
                      validateStatus={formErrors?.[`kinshipDegree_${index}`] && ('error')}
                      name={[field.name, 'kinshipDegree']}
                      label={
                        <label>
                          Categoria do terceiro {index + 1}:
                        </label>
                      }
                    >
                      <Select
                        id="test-form-select-kinshipDegree"
                        placeholder='Escolha a categoria do terceiro'
                        disabled={readOnlyInput}
                        onChange={handleKinshipDegreeChange}
                      >
                        <Select.Option id="test-form-select-kinshipDegree-propria" value="FAMILIAR">Pai/Mãe/Filho(a)</Select.Option>
                        <Select.Option id="test-form-select-kinshipDegree-dejuridicos" value="PJ">Pessoa Jurídica</Select.Option>
                        <Select.Option id="test-form-select-kinshipDegree-deterceiros" value="OUTROS">Outros</Select.Option>

                      </Select>
                    </Form.Item>
                  </Col>

                  {selectedKinshipDegrees[index]?.kinshipDegree !== 'PJ' && (
                    <Col lg={8} sm={22} xs={22} md={22}>
                      <Form.Item
                        initialValue={false}
                        className="form-user-checkbox ml-0 property-form-owner__checkbox"
                        help={ formErrors?.[`composeIncome_${index}`] && formErrors?.[`composeIncome_${index}`]}
                        validateStatus={formErrors?.[`composeIncome_${index}`] && ('error')}
                        name={[field.name, 'composeIncome']}
                        valuePropName="checked"
                        label={<label></label>}
                      >
                        <Checkbox
                          id="test-checkbox-kinshipComposeIncome"
                          disabled={readOnlyInput}
                        >
                          Vai compor renda?
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  )}

                  {fields.length > 1 && !readOnlyInput ? (
                    <Col lg={8} sm={2} xs={2} md={2}>
                      <DynamicColorButton colorClass="button-red" onClick={() => remove(field.name)} className="property-form-owner__remove-icon">
                        <DeleteOutlined />
                      </DynamicColorButton>
                    </Col>
                  ) : null}

                </Row>
              )
            })}

            {fields.length < 4 && !readOnlyInput && (
              <Form.Item>
                <Button
                  className="mt-1"
                  type="primary"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
              Adicionar novo
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            )}
          </>
        )
      }}
    </Form.List>
  )
}

export const PropertyFormOwner = (props: PropertyFormOwnerProps) => {
  return (
    <Col lg={24}>
      <PropertyFormOwnerList {...props} />
    </Col>
  )
}

import React, { Fragment, useEffect, useState } from 'react'
import { Drawer } from 'components'
import { Col, Collapse, Form, Tooltip } from 'antd'
import ExplainSend from 'components/ExplainSend/ExplainSend'
import usePartnerStep from 'egi/app/PartnersLine/hooks/usePartnerStep'
import usePartnerStepDrawer from 'egi/app/PartnersLine/hooks/usePartnerStepDrawer'
import usePartnerDocumentation from 'egi/app/PartnersLine/hooks/usePartnerDocumentation'
import usePartnerDepartments from 'egi/app/PartnersLine/hooks/usePartnerDepartments'
import AssociateForm from './AssociateForm/AssociateForm'
import PlusButton from 'components/PlusButton/PlusButton'
import { MinusCircleOutlined } from '@ant-design/icons'
import { IAssociate, IAssociates, IAssociateErrors } from './types'
import swal from 'utils/swal'
import ReviewStep from 'egi/app/PartnersLine/components/ReviewStep/ReviewStep'
import { validateResponse } from 'utils/validate'
import { associateFormFill } from 'egi/app/PartnersLine/partnersLineFunctions'
import { swalError } from 'components/SwalError/SwalError'
import PartnerStepButton from 'egi/app/PartnersLine/components/PartnerStepButton/PartnerStepButton'
import { correctTimezone } from 'utils/time'
import ReviewDescription from '../../../ReviewDescription/ReviewDescription'
import PartnerModel from 'egi/models/PartnerModel'
import PartnersRepository from 'egi/repositories/PartnersRepository'

type ErrorsRecord = Record<number, Array<{ field: string, message: string }>>

function AssociateStep () {
  const { close, open, visible } = usePartnerStepDrawer()
  const { step } = usePartnerStep()
  const { _id: documentId } = usePartnerDocumentation()
  const { slug: departmentSlug } = usePartnerDepartments()
  const [formRef] = Form.useForm<IAssociates>()
  const sendForm = PartnerModel.canSendForm(step)
  const [errors, setErrors] = useState<Array<IAssociateErrors>>([])
  const [status, setStatus] = useState<'back' | 'saving' | 'sending'>()

  function setErrorsAssociate (errors: Array<{ field: string, index: number, message: string }>): Array<IAssociateErrors> {
    let noFormatErrors: ErrorsRecord = {}

    errors?.forEach(value => {
      const { field, index, message } = value

      if (noFormatErrors?.[index]) noFormatErrors[index] = [...noFormatErrors?.[index], { field, message }]
      else noFormatErrors = { ...noFormatErrors, [index]: [{ field, message }] }
    })

    const errorsFormat: any = Object.entries(noFormatErrors)?.map(([, value]) => validateResponse(value))

    return errorsFormat
  }

  async function onSubmit (values: IAssociates) {
    setStatus('sending')
    const data = {
      department: departmentSlug,
      step: step.slug,
      associates: values?.associates?.map(value => {
        if (!value) return {}

        const associateData = Object.assign({}, value)
        if (value.birthdate) associateData.birthdate = correctTimezone(value.birthdate as string)
        if (value.expeditionDate) associateData.expeditionDate = correctTimezone(value.expeditionDate as string)

        return associateData
      })
    }

    try {
      const response = await PartnersRepository.send(documentId, data)
      swal.basic({ icon: 'success', title: 'Sucesso!', text: response.data.message })
      setErrors([])
      close()
    } catch (err) {
      const invalid = err?.data?.invalid
      if (invalid) setErrors(setErrorsAssociate(invalid))
      swalError({ title: 'Atenção', icon: 'warning', err })
    }
    setStatus(undefined)
  }

  useEffect(() => {
    setErrors([])
    function onFill (form: IAssociate[]) {
      const data = associateFormFill(form)

      formRef.setFieldsValue({ associates: data })
    }

    if (step.form) {
      onFill(step.form as IAssociate[])
    }
  }, [])

  return (
    <Fragment>
      <PartnerStepButton
        status={step.status}
        enabled={true}
        onClick={open}
        step={step}
      >
        {step.name}
      </PartnerStepButton>

      <Drawer
        visible={visible}
        onClose={close}
        onSubmit={() => formRef.submit()}
        showSubmit={true}
        disabled={!sendForm}
        title={step.name}
        status={status}
      >
        <Fragment>
          <Form
            form={formRef}
            layout="vertical"
            onFinish={onSubmit}
          >
            <Form.List name="associates">
              {(fields, { add, remove }) => (
                <Fragment>
                  <Collapse destroyInactivePanel accordion={true}>
                    {fields.map((field, idx) => (
                      <Collapse.Panel
                        header={`Sócio ${idx + 1}`}
                        key={field.key}
                        extra={
                          <Fragment>
                            {sendForm && (
                              <Tooltip
                                title={`Excluir formulário do socio ${idx}`}
                                placement="left"
                              >
                                <MinusCircleOutlined
                                  onClick={() => remove(field.name)}
                                  style={{ color: 'red' }}
                                />
                              </Tooltip>
                            )}
                          </Fragment>
                        }
                      >
                        <AssociateForm
                          errors={errors[idx]}
                          field={field}
                          form={formRef}
                          readonly={!sendForm}
                        />
                      </Collapse.Panel>
                    ))}
                  </Collapse>

                  {sendForm && (
                    <Col className="px-1" lg={24} sm={24} xs={24}>
                      <PlusButton
                        label="Adicionar ficha cadastral do sócio"
                        onClick={() => add()}
                      />
                    </Col>
                  )}
                </Fragment>
              )}
            </Form.List>
          </Form>

          <div className="my-4">
            <ExplainSend />
          </div>

          <div className="my-4">
            <ReviewDescription />
          </div>

          <ReviewStep onSuccess={close} />
        </Fragment>
      </Drawer>
    </Fragment>
  )
}

export default AssociateStep

export const MASTER_INITIAL_FILTERS = {
  page: 1,
  limit: 15
}

export const MasterFiltersKeysIgnore = [
  'search',
  'page',
  'limit',
  'sort'
]

export const MasterFiltersEntriesToIgnore: {}[] = [
  { }
]

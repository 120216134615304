import React, { Fragment, ReactElement } from 'react'
import { Typography, Tooltip } from 'antd'
import format from 'utils/format'

const { Title } = Typography

type OrderTupla = [string, string]

interface IMLMBaseCard {
  description: string | React.ReactElement
  failed: boolean
  valueColor: 'primary' | 'secondary' | 'ligth-blue-dash' | 'approved' | 'refused' | 'analysis' | 'canceled'
  Icon?: React.ReactElement | React.ReactNode
  value?: number | string
  rawValue?: number
  titleClass?: string
  mainOrder?: OrderTupla
}

function MlmBaseCard ({ description, titleClass = 'dashboard-card-title', value, rawValue, failed, Icon, valueColor, mainOrder = ['title', 'value'] }: IMLMBaseCard) {
  function displayOrder (order: OrderTupla): ReactElement[] {
    const finalOrder = order.map((item, index) => {
      switch (item) {
        case 'title':
          return <Typography.Title key={index} className={`${titleClass} ${index === 0 ? ' mb-3' : 'mt-2'}`}>{description}</Typography.Title>
        case 'value':
          return (
            <div>
              <Tooltip key={index} title={rawValue ? format.formatBRL(rawValue) : value} destroyTooltipOnHide={{ keepParent: false }}>
                {failed ? 'Falha ao carregar dados' : <Title className={`dashboard-card-number uppercase bold color-${valueColor}`}>{value}</Title>}
              </Tooltip>
            </div>
          )
        default:
          return <></>
      }
    })

    return finalOrder
  }

  return (
    <div className="flex h-100">
      <div className="flex-2 flex flex-column flex-justify-center">
        {displayOrder(mainOrder)}
      </div>

      {Icon && (
        <div className="flex-1 flex flex-justify-center flex-align-center">
          { Icon }
        </div>
      )}
    </div>
  )
}

export default MlmBaseCard

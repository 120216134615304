import React, { DetailedHTMLProps, ReactNode } from 'react'

type IDrawerFiltersLine = DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> & {
  label: ReactNode
}

function DrawerFiltersLine ({ children, label, className, ...rest }: IDrawerFiltersLine) {
  return (
    <label className={`drawer-filters__line ${className || ''}`} {...rest}>
      {label}
      {children}
    </label>
  )
}

export default DrawerFiltersLine

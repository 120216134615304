
import { Button, message } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import LoadingBar from 'components/LoadingBar/LoadingBar'
import { swalError } from 'components/SwalError/SwalError'
import ProposalEgiRepository from 'egi/repositories/ProposalEgiRepository'
import { useAuth, useProposal } from 'hooks'
import React, { useEffect, useState } from 'react'
import { _antdStatus } from 'store/modules/notaryFees/notaryFeesActionTypes'
import swal from 'utils/swal'
import { ErrorZip } from '../ZipDocumentsLogModal/ZipDocumentsLogModal'

interface IProps {
  visible: boolean
  onCancel: Function
  departmentId: string
}

const formatStatus = (color: string):_antdStatus => {
  switch (color) {
    case 'finished': return 'success'
    case 'error': return 'exception'
    case 'awaiting': return 'active'
    case 'processing': return 'active'
    default:
      return 'active'
  }
}

interface ILoadingBar {
  antdStatus: _antdStatus,
  percent: number,
  url?:string,
  isProcessing?:boolean,
  error?: string
}

function ZipDocumentsDownloadModal ({ visible, onCancel, departmentId }: IProps) {
  const { id: proposalId } = useProposal()

  const inicialBar:ILoadingBar = {
    antdStatus: undefined,
    percent: 0,
    url: undefined,
    isProcessing: false,
    error: undefined
  }

  const [loadingBar, setLoadingBar] = useState<ILoadingBar>(inicialBar)

  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null)

  const getStatus = (proposalId: string) => {
    setLoadingBar((prev) => ({
      ...prev,
      error: undefined,
      percent: 0
    }))

    const id = setInterval(async () => {
      try {
        const response = await ProposalEgiRepository.seeProcessZipDocuments(proposalId)
        const percentage = response.data.data.processDocument.processPercentage
        const currentStatus = response.data.data.processDocument.status
        const error = response.data.data.processDocument.error

        if (visible) {
          setLoadingBar((prev) => ({
            ...prev,
            percent: percentage * 100,
            antdStatus: formatStatus(currentStatus),
            url: response.data.data.processDocument.url
          }))
        }

        if (currentStatus === 'finished' || currentStatus === 'error' || !visible) {
          clearInterval(id)
          setIntervalId(null)
        }

        if (error) {
          setLoadingBar((prev) => ({
            ...prev,
            error: 'Ocorreu um erro ao processar o arquivo'
          }))
        }
      } catch (err) {
        clearInterval(id)

        setLoadingBar((prev) => ({
          ...prev,
          antdStatus: 'exception',
          error: err.message
        }))
      }
    }, 2000)

    setIntervalId(id)
  }

  useEffect(() => {
    return () => clearInterval(intervalId as NodeJS.Timeout)
  }, [intervalId])

  const downloadDocument = async (downloadMode: 'email' | 'download') => {
    try {
      if (!proposalId) throw new Error('Error')
      const response = await ProposalEgiRepository.downloadZipDocuments(proposalId, { departmentId, receiveMethod: downloadMode })

      if (downloadMode === 'download' && response.data.data.documentId) {
        setLoadingBar((prev) => ({
          ...prev,
          isProcessing: true
        }))
        getStatus(response.data.data.documentId)
      }
      if (downloadMode === 'email') message.success('Arquivo enviado para o email com sucesso')
    } catch (err) {
      swalError({ err })
    }
  }

  const downloadZip = () => {
    window.open(loadingBar.url, '_blank')
  }

  const endProcess = async () => {
    try {
      if (intervalId !== null) {
        clearInterval(intervalId)
        setIntervalId(null)
      }

      await setLoadingBar(inicialBar)
    } finally {
      onCancel()
    }
  }

  const auth = useAuth()

  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={() => {
        if (loadingBar.isProcessing && loadingBar.antdStatus !== 'success') {
          swal.confirmNegate({
            icon: 'warning',
            title: 'Atenção',
            text: 'Tem certeza que deseja fechar? O progresso será perdido.',
            confirm: () => {
              endProcess()
            }
          })
        } else {
          endProcess()
        }
      }}
      destroyOnClose={true}
      width={600}
    >
      <h2 className='zip-documents-download-modal__title'>
        Baixar documentos
      </h2>

      {loadingBar.isProcessing && (
        <div>
          <p className='mb-4'>
            Você possui um processamento de arquivos em <b>andamento</b>. Após sua conclusão, o download do arquivo ficará disponível no botão <b>baixar</b> abaixo.
          </p>

          <div>
            <LoadingBar
              percent={loadingBar.percent}
              status={loadingBar.antdStatus}
              showInfo={false}
            />
          </div>

          {!loadingBar.error ? (
            <div className="text-right">
              <Button
                type="primary"
                disabled={!loadingBar.url}
                loading={status === 'success' && !loadingBar.url}
                onClick={() => downloadZip()}
              >
              Baixar
              </Button>
            </div>
          ) : loadingBar.error && (
            <>
              <ErrorZip message={loadingBar.error}/>
              <div className="text-right">
                <Button
                  type="primary"
                  onClick={() => {
                    downloadDocument('download')
                  }}
                >
                Tentar novamente
                </Button>
              </div>
            </>
          )}
        </div>
      )}

      {!loadingBar.isProcessing && (
        <div>
          <p className="mb-3">Você pode escolher entre fazer o <b>download direto</b> ou enviar o arquivo por <b>email</b></p>
          <p className="mb-5">Caso seja selecionada a opção &ldquo;email&ldquo; será enviado para: <b>{auth.email}</b></p>

          <div className="text-right pt-2 zip-documents-download-modal__buttons">
            <Button
              type="ghost"
              className="zip-documents-download-modal__direct-download"
              onClick={() => {
                downloadDocument('download')
              }}
            >
              Fazer o download direto
            </Button>

            <Button
              type="primary"
              onClick={() => downloadDocument('email')}
            >
              Enviar arquivo por email
            </Button>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default ZipDocumentsDownloadModal

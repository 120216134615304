import React, { Fragment, useLayoutEffect, useState } from 'react'
import { Col, Form, Select } from 'antd'
import { Input } from 'components'
import locale from 'antd/es/date-picker/locale/pt_BR'
import { useResources, useStep } from 'hooks'
import { IFormPF } from './types'
import Datepicker from 'components/Datepicker/Datepicker'

function FormPF ({ errors, readOnlyInput, person }: IFormPF) {
  const step = useStep()
  const resources = useResources()

  const [documentType, setDocumentType] = useState<string>('')

  useLayoutEffect(() => {
    if (step && step.form && step.form.documentType) setDocumentType(step.form.documentType)
  }, [])

  return (
    <Fragment>
      <Fragment>
        <Col className="px-1" lg={8} sm={24} xs={24}>
          <Form.Item
            name='name'
            label={<label>Nome:</label>}
            help={errors.name && (errors.name)}
            validateStatus={errors.name && ('error')}
          >
            <Input
              readOnly={readOnlyInput}
              placeholder={'Digite o nome'}
              error={errors.name}
            />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={8} sm={12} xs={24}>
          <Form.Item
            name='cpf'
            label={<label>CPF:</label>}
            help={errors.cpf && (errors.cpf)}
            validateStatus={errors.cpf && ('error')}
          >
            <Input
              readOnly={readOnlyInput}
              placeholder={'Digite o cpf'}
              mask="cpf"
              error={errors.cpf}
            />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={8} sm={12} xs={24}>
          <Form.Item
            name='birthdate'
            label={<label>{person ? `Data de nascimento do ${person}:` : 'Data de nascimento:'}</label>}
            help={errors.birthdate && (errors.birthdate)}
            validateStatus={errors.birthdate && ('error')}
          >
            <Datepicker
              disabled={readOnlyInput}
              locale={locale}
            />
          </Form.Item>
        </Col>
      </Fragment>

      <Col className="px-1" lg={8} sm={12} xs={24}>
        <Form.Item
          name='documentType'
          label={<label>Tipo do documento:</label>}
          help={errors.documentType && (errors.documentType)}
          validateStatus={errors.documentType && ('error')}
        >
          <Select
            id="test-form-select-document"
            placeholder="Escolha o tipo de documento"
            disabled={readOnlyInput}
            onChange={(value: string) => setDocumentType(value)}
          >
            <Select.Option id="test-form-select-document-rg" value="RG">RG</Select.Option>
            <Select.Option id="test-form-select-document-rne" value="RNE">RNE</Select.Option>
            <Select.Option id="test-form-select-document-cnh" value="CNH">CNH</Select.Option>
            <Select.Option id="test-form-select-document-passport" value="PASSPORT">PASSAPORTE</Select.Option>
            <Select.Option id="test-form-select-document-professionalId" value="PROFESSIONALID">Identificação profissional (CREA, OAB, CRM, outros)</Select.Option>
          </Select>
        </Form.Item>
      </Col>

      <Fragment>
        {documentType === 'CNH' && (
          <Col className="px-1" lg={8} sm={12} xs={24}>
            <Form.Item
              name='cnh'
              label={<label>CNH:</label>}
              help={errors.cnh && (errors.cnh)}
              validateStatus={errors.cnh && ('error')}
            >
              <Input
                className="w-100"
                readOnly={readOnlyInput}
                placeholder={person ? `Digite a cnh do(a) ${person}` : 'Digite sua cnh'}
              />
            </Form.Item>
          </Col>
        )}

        {(documentType === 'RNE') && (
          <Col className="px-1" lg={8} sm={12} xs={24}>
            <Form.Item
              name='rne'
              label={<label>RNE:</label>}
              help={errors.rne && (errors.rne)}
              validateStatus={errors.rne && ('error')}
            >
              <Input
                readOnly={readOnlyInput}
                placeholder={person ? `Digite o rne do(a) ${person}` : 'Digite seu rne'} error={errors.rne}
              />
            </Form.Item>
          </Col>
        )}

        {(documentType === 'RG') && (
          <Col className="px-1" lg={8} sm={12} xs={24}>
            <Form.Item
              name='rg'
              label={<label>RG:</label>}
              help={errors.rg && (errors.rg)}
              validateStatus={errors.rg && ('error')}
            >
              <Input
                readOnly={readOnlyInput}
                placeholder={person ? `Digite o rg do(a) ${person}` : 'Digite seu rg'}
                mask="rg"
                error={errors.rg}
              />
            </Form.Item>
          </Col>
        )}

        {(documentType === 'PASSPORT') && (
          <Col className="px-1" lg={8} sm={12} xs={24}>
            <Form.Item
              name='passport'
              label={<label>Passaporte:</label>}
              help={errors.passport && (errors.passport)}
              validateStatus={errors.passport && ('error')}
            >
              <Input
                readOnly={readOnlyInput}
                placeholder={person ? `Digite o passaporte do(a) ${person}` : 'Digite seu passaporte'}
                mask="passport"
                error={errors.passport}
              />
            </Form.Item>
          </Col>
        )}

        {(documentType === 'PROFESSIONALID') && (
          <Col className="px-1" lg={8} sm={12} xs={24}>
            <Form.Item
              name='professionalId'
              label={<label>Identificação Profissional:</label>}
              help={errors.professionalId && (errors.professionalId)}
              validateStatus={errors.professionalId && ('error')}
            >
              <Input readOnly={readOnlyInput} placeholder={person
                ? `Digite a identificação profissional do(a) ${person}`
                : 'Digite sua identificação profissional'}
              mask="professionalId" error={errors.professionalId}/>
            </Form.Item>
          </Col>
        )}

        <Col className="px-1" lg={8} sm={12} xs={24}>
          <Form.Item
            name='expeditionDate'
            label={<label>Data de expedição:</label>}
            help={errors.expeditionDate && (errors.expeditionDate)}
            validateStatus={errors.expeditionDate && ('error')}
          >
            <Datepicker
              id="expeditionDate"
              disabled={readOnlyInput}
              locale={locale}
            />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={8} sm={12} xs={24}>
          <Form.Item
            name='expeditionIssuer'
            label={<label>Orgão expedidor</label>}
            help={errors.expeditionUF && (errors.expeditionUF)}
            validateStatus={errors.expeditionUF && ('error')}
          >
            <Input
              readOnly={readOnlyInput}
              placeholder='Digite o orgão expedidor'
              error={errors.nationality}
            />
          </Form.Item>
        </Col>

        {(documentType !== 'PASSPORT') &&
        <Col className="px-1" lg={8} sm={12} xs={24}>
          <Form.Item
            name='expeditionUF'
            label={<label>Orgão expedidor | UF:</label>}
            help={errors.expeditionUF && (errors.expeditionUF)}
            validateStatus={errors.expeditionUF && ('error')}
          >
            <Select
              showSearch
              placeholder={person ? `Escolha o orgão expedidor | UF do(a) ${person}` : 'Escolha o orgão expedidor | UF'}
              aria-readonly={readOnlyInput}
              disabled={readOnlyInput}
              filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {resources.states.map(state => <Select.Option key={state} value={state}>{state}</Select.Option>)}
            </Select>
          </Form.Item>
        </Col>
        }
      </Fragment>

      <Col className="px-1" lg={8} sm={12} xs={24}>
        <Form.Item
          name='nationality'
          label={<label>Nacionalidade:</label>}
          help={errors.nationality && (errors.nationality)}
          validateStatus={errors.nationality && ('error')}
        >
          <Input
            readOnly={readOnlyInput}
            placeholder={person ? `Digite a nacionalidade do(a) ${person}` : 'Digite sua nacionalidade'}
            error={errors.nationality}
          />
        </Form.Item>
      </Col>

      <Col className="px-1" lg={8} sm={12} xs={24}>
        <Form.Item
          name='placeOfBirth'
          label={<label>Naturalidade:</label>}
          help={errors.placeOfBirth && (errors.placeOfBirth)}
          validateStatus={errors.placeOfBirth && ('error')}
        >
          <Input
            readOnly={readOnlyInput}
            placeholder={person ? `Digite a naturalidade do(a) ${person}` : 'Digite sua naturalidade'}
            error={errors.placeOfBirth}
          />
        </Form.Item>
      </Col>

      <Col className="px-1" lg={8} sm={12} xs={24}>
        <Form.Item
          name='motherName'
          label={<label>Nome da mãe:</label>}
          help={errors.motherName && (errors.motherName)}
          validateStatus={errors.motherName && ('error')}
        >
          <Input
            readOnly={readOnlyInput}
            placeholder={person ? `Digite o nome da mãe do(a) ${person}` : 'Digite o nome da sua mãe'}
            error={errors.motherName}
          />
        </Form.Item>
      </Col>

      <Col className="px-1" lg={8} sm={12} xs={24}>
        <Form.Item
          name='fatherName'
          label={<label>Nome do pai:</label>}
          help={errors.fatherName && (errors.fatherName)}
          validateStatus={errors.fatherName && ('error')}
        >
          <Input
            readOnly={readOnlyInput}
            placeholder={person ? `Digite o nome do pai do(a) ${person}` : 'Digite o nome do seu pai'} error={errors.fatherName}
          />
        </Form.Item>
      </Col>

      <Col className="px-1" lg={8} sm={12} xs={24}>
        <Form.Item
          name='email'
          label={<label>Email:</label>}
          help={errors.email && (errors.email)}
          validateStatus={errors.email && ('error')}
        >
          <Input
            readOnly={readOnlyInput}
            placeholder={person ? `Digite o email do(a) ${person}` : 'Digite seu email'} error={errors.email}
          />
        </Form.Item>
      </Col>
    </Fragment>
  )
}

export default FormPF

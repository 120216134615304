import { IAuth, IStep } from 'egi/types'
import { ReactNode } from 'react'
import { _reviewStatusOption } from './reviewBackofficeInterfaces'
import gsap, { Power0, Power1, Elastic } from 'gsap'

export function getStatusOptions (user: IAuth, step: IStep, canInsertProviderJuridico: boolean): Array<{ label: string | ReactNode, value: string }> {
  const IS_PROMOTER = user.level === 'promoter'
  const reviewOptions = [{ label: 'Refazer', value: 'remaking' }]

  if (!user) return []
  if (step.reviewOptions[0]) reviewOptions.push({ label: 'Aprovado com ressalvas', value: 'reservation' })
  if (canInsertProviderJuridico) reviewOptions.push({ label: 'Vincular prestador', value: 'analysis' })
  if (!IS_PROMOTER) reviewOptions.unshift({ label: 'Aprovado', value: 'approved' }, { label: 'Reprovado', value: 'reproved' })

  return reviewOptions.sort((a, b) => (a.label).localeCompare(b.label))
}

export function sortStatusOptions (options?: {label: string, value: string}[]) {
  if (!options) return undefined
  const sort = options.sort((a, b) => (a.label).localeCompare(b.label))
  return sort
}

const getCorrectVinculateColor = (value: _reviewStatusOption) => {
  if (value === 'approved' || value === 'reservation' || value.includes('approved')) return 'var(--approved-status-color)'
  if (value === 'reproved') return 'var(--refused-status-color)'
  if (value === 'remaking') return 'var(--remaking-status-color)'
  return 'var(--primary-color)'
}

export const getIconColor = (value: _reviewStatusOption | null) => {
  if (value === 'approved' || value === 'reservation' || value?.includes('approved')) return 'var(--approved-status-color)'
  if (value === 'reproved') return 'var(--refused-status-color)'
  if (value === 'remaking') return 'var(--remaking-status-color)'
  if (value === 'analysis') return getCorrectVinculateColor(value)
  return 'var(--primary-color)'
}

export const checkConfig = (check: gsap.core.Timeline, statusSelected: _reviewStatusOption | null) => {
  return check
    .set(`.review-radio__item--${statusSelected}  .ring`, { opacity: 1 })
    .set(`.review-radio__item--${statusSelected}`, { outline: `1px solid ${getIconColor(statusSelected)}` })
    .set(`.review-radio__item--${statusSelected}`, { color: getIconColor(statusSelected) })
    .set(`.review-radio__item--${statusSelected} .drops`, { opacity: 0 })
    .set(`.review-radio__item--${statusSelected}  .ring0`, { opacity: 0 })
    .set(`.review-radio__item--${statusSelected} .drop`, { opacity: 0, y: -32, scale: 0.4, x: 0, transformOrigin: '50%, 0%' })
    .set(`.review-radio__item--${statusSelected}  .ring`, { transformOrigin: '50%, 50%' })
    .set(`.review-radio__item--${statusSelected}  .dropTop`, { opacity: 1, scale: 0.2, transformOrigin: '50%, 0%' })
    .add('sync')
    .to(`.review-radio__item--${statusSelected} .ring`, { scaleY: 0.95, duration: 0.17 }, 'sync')
    .to(`.review-radio__item--${statusSelected} .dropTop`, { scale: 1, y: 0.5, ease: Power0.easeNone, duration: 0.1 }, 'sync')
    .to(`.review-radio__item--${statusSelected}  .dropTop`, { scale: 0.3, ease: Power0.easeNone, duration: 0.1 }, 'sync +=.08')
    .set(`.review-radio__item--${statusSelected}  .review-radio__icon`, { opacity: 1, duration: 0.7 })
    .to(`.review-radio__item--${statusSelected} .dropTop`, { transformOrigin: '50%, 40%', scale: 0, ease: Power0.easeNone, duration: 0.08, fill: getIconColor(statusSelected) }, 'sync +=.181')
    .set(`.review-radio__item--${statusSelected}  .drop`, { opacity: 1, ease: Power0.easeNone }, 'sync')
    .to(`.review-radio__item--${statusSelected} .drop`, { y: 0, ease: Power1.easeIn, duration: 0.17 }, 'sync')
    .to(`.review-radio__item--${statusSelected} .drop`, { scale: 0.9, ease: Power0.easeNone, duration: 0.08, fill: getIconColor(statusSelected) }, 'sync +=.02')
    .to(`.review-radio__item--${statusSelected}  .ring`, { transformOrigin: '50%, 50%', scaleY: 1, ease: Elastic.easeOut.config(0.8, 0.1), duration: 2, fill: getIconColor(statusSelected) }, 'sync +=.14')
    .to(`.review-radio__item--${statusSelected} .drop`, { transformOrigin: '50%, 0%', scale: 1, ease: Elastic.easeOut.config(0.8, 0.14), duration: 1.8, fill: getIconColor(statusSelected) }, 'sync +=.14')
}

export const uncheckConfig = (uncheck: gsap.core.Timeline, check:gsap.core.Timeline, oldStatusSelected: _reviewStatusOption | null) => {
  uncheck
    .set(`.review-radio__item--${oldStatusSelected} .review-radio__icon`, { opacity: 0, duration: 0.2 })
    .set(`.review-radio__item--${oldStatusSelected}`, { color: 'var(--primary-color)' })
    .set(`.review-radio__item--${oldStatusSelected}`, { outlineColor: 'var(--light-gray-4)' })
    .to(`.review-radio__item--${oldStatusSelected} .drop `, { transformOrigin: '50% 0%', scale: 0, ease: Power0.easeNone, duration: 1, fill: 'var(--primary-color)' })
    .to(`.review-radio__item--${oldStatusSelected} .ring`, { transformOrigin: '50% 50%', scaleY: 0.95, ease: Elastic.easeOut.config(0.8, 0.1), duration: 1.5, fill: 'var(--primary-color)' }, '-=1')
    .to(`.review-radio__item--${oldStatusSelected} .drop`, { scale: 1, ease: Elastic.easeOut.config(0.8, 0.14), duration: 0.06, fill: 'var(--primary-color)' }, '-=1.5')
    .to(`.review-radio__item--${oldStatusSelected} .drop`, { y: -32, ease: Power1.easeOut, duration: 0.12 }, '-=1.44')
    .to(`.review-radio__item--${oldStatusSelected} .dropTop`, { transformOrigin: '50% 40%', scale: 0.3, ease: Power0.easeNone, duration: 0.06, fill: 'var(--primary-color)' }, '-=1.38')
    .to(`.review-radio__item--${oldStatusSelected} .dropTop`, { scale: 1, ease: Power0.easeNone, duration: 0.08 }, '-=1.32')
    .to(`.review-radio__item--${oldStatusSelected}  .dropTop`, { scale: 0.2, y: 0, ease: Power0.easeNone, duration: 0.08 }, '-=1.24')
    .to(`.review-radio__item--${oldStatusSelected}  .ring`, { scaleY: 1, duration: 0.1 }, '-=1.24')
    .set(`.review-radio__item--${oldStatusSelected} .ring`, { transformOrigin: '50% 50%' })
    .set(`.review-radio__item--${oldStatusSelected}  .dropTop`, { opacity: 0, scale: 0.2, transformOrigin: '50% 0%' })
    .set(`.review-radio__item--${oldStatusSelected} .drop`, { opacity: 0, y: -32, scale: 0.4, x: 0, transformOrigin: '50% 0%' })
    .set(`.review-radio__item--${oldStatusSelected}  .ring`, { opacity: 1, transformOrigin: '50% 50%' })

  return check.timeScale(1.27)
}

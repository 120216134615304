import React from 'react'
import { Form, Select } from 'antd'
import { IChooseContractComponentProps } from './formContractInterfaces'
import { InputMoney, PercentageInput } from 'components'
import locale from 'antd/es/date-picker/locale/pt_BR'
import { states } from 'utils/globals'
import Detapicker from 'components/Datepicker/Datepicker'

function ChooseContractComponent ({ type, item, errors, readOnlyInput, component }: IChooseContractComponentProps) {
  switch (type) {
    case 'currency':
      return (
        <Form.Item
          name={item.field}
          label={<label>{item.label}</label>}
          help={errors[item.field] && (errors[item.field])}
          validateStatus={errors[item.field] && ('error')}
        >
          <InputMoney
            id={`test-form-item-${item.field}`}
            disabled={readOnlyInput}
            placeholder={'Digite o(a) ' + item.field}
            defaultValue={0}
          />
        </Form.Item>
      )
    case 'percentage':
      return (
        <Form.Item
          name={item.field}
          label={<label>{item.label}</label>}
          help={errors[item.field] && (errors[item.field])}
          validateStatus={errors[item.field] && ('error')}
        >
          <PercentageInput
            id={`test-form-item-${item.field}`}
            disabled={readOnlyInput}
            placeholder={'Digite o(a) ' + item.field}
          />
        </Form.Item>
      )
    case 'uf':
      return (
        <Form.Item
          name={item.field}
          label={<label>{item.label}</label>}
          help={errors[item.field] && (errors[item.field])}
          validateStatus={errors[item.field] && ('error')}
        >
          <Select
            id={`test-form-item-${item.field}`}
            showSearch
            placeholder={'Escolha o ' + item.label}
            aria-readonly={readOnlyInput}
            disabled={readOnlyInput}
            filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {states.map(state =>
              <Select.Option key={state.value} value={state.label}>{state.label}</Select.Option>
            )}
          </Select>
        </Form.Item>
      )
    case 'date':
      return (
        <Form.Item
          name={item.field}
          label={<label>{item.label}</label>}
          help={errors[item.field] && (errors[item.field])}
          validateStatus={errors[item.field] && ('error')}
        >
          <Detapicker disabled={readOnlyInput} locale={locale} />
        </Form.Item>
      )
    default:
      return component
  }
}

export default ChooseContractComponent

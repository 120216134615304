
import { Empty } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import { ColumnsType } from 'antd/lib/table'
import { Table } from 'components'
import React, { useMemo } from 'react'
import format from 'utils/format'
import { IFees } from '../../notaryFeesInterfaces'

interface IProps {
  visible: boolean
  onCancel: () => void
  fees: IFees | undefined
  error: string | undefined
  loading: boolean
}

function NotaryFeesLastModal ({ visible, onCancel, fees, error, loading }: IProps) {
  const columns: ColumnsType = useMemo(() => [
    {
      title: 'Estado',
      dataIndex: 'uf'
    },
    {
      title: 'De',
      dataIndex: 'from',
      render: (value:number) => {
        if (!value) return '-'
        return format.formatBRL(value)
      }
    },
    {
      title: 'Até',
      dataIndex: 'to',
      render: (value:number) => {
        if (!value) return '-'
        return format.formatBRL(value)
      }
    },
    {
      title: 'Valor Do Custo',
      dataIndex: 'costValue',
      render: (value:number) => {
        if (!value) return '-'
        return format.formatBRL(value)
      }
    }
  ], [])

  return (
    <Modal
      width="800px"
      title="Ver Tabela vigente"
      visible={visible}
      destroyOnClose={true}
      onCancel={onCancel}
      footer={
        <></>
      }
    >

      <h3 className='bold color-primary'>
        Tabela Vigente
      </h3>

      {fees || !error
        ? <Table data={fees} columns={columns} loading={loading} canSearch={false}/>
        : <Empty description={error}/>
      }
    </Modal>

  )
}

export default NotaryFeesLastModal

import React from 'react'
import mainColumns from 'egi/views/UsersTable/DataTables'
import { DownloadOutlined, FileTextOutlined, TeamOutlined } from '@ant-design/icons'
import translate from 'utils/translate'
import UsersTable from 'egi/views/UsersTable/UsersTable'
import { IRoute } from 'components/Sidebar/types'
import CreateWitness from 'egi/app/Witnesses/views/WitnessForm/WitnessForm'
import WitnessesList from 'egi/app/Witnesses/views/WitnessesList/WitnessesList'
import AttorneyForm from 'egi/app/Attorney/views/AttorneyForm/AttorneyForm'
import AttorneysList from 'egi/app/Attorney/views/AttorneysList/AttorneysList'
import PartnersLine from 'egi/app/PartnersLine/views/PartnersLine'
import PromoterList from 'egi/app/Promoter/views/PromoterList/PromoterList'
import TelemarketingList from 'egi/app/Telemarketing/views/TelemarketingList/TelemarketingList'
import DownloadDocuments from 'ecp/app/Logs/views/LogsZip/LogsZip'
import { EGI } from 'routes/egi/paths'

const leaders: IRoute = {
  path: EGI.JURIDICO_LEADERS.path,
  name: EGI.JURIDICO_LEADERS.name,
  icon: TeamOutlined,
  component: () => <UsersTable url='/administrators' area="juridico" level="administrator" inviteButtonText={translate.level('administrator')} columns={mainColumns.administrator} />,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const collaborators: IRoute = {
  path: '/juridico/collaborator',
  name: 'Funcionários',
  icon: TeamOutlined,
  component: () => <UsersTable url='/backoffices' area="juridico" level="backoffice" inviteButtonText={translate.level('backoffice')} columns={mainColumns.backoffice} />,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const createAttorney: IRoute = {
  path: '/attorney/create',
  name: 'Adicionar procurador',
  icon: TeamOutlined,
  component: AttorneyForm,
  meta: {
    centered: false,
    hidden: true,
    canGoBack: true,
    disabled: false
  }
}

const getOneAttorney: IRoute = {
  path: '/attorney/show/:id',
  name: 'Atualizar procurador',
  icon: TeamOutlined,
  component: AttorneyForm,
  meta: {
    centered: false,
    hidden: true,
    canGoBack: true,
    disabled: false
  }
}

const createWitness: IRoute = {
  path: '/witness/create',
  name: 'Adicionar testemunha',
  icon: TeamOutlined,
  component: CreateWitness,
  meta: {
    centered: false,
    hidden: true,
    canGoBack: true,
    disabled: false
  }
}

const getOneWitness: IRoute = {
  path: '/witness/show/:id',
  name: 'Atualizar testemunha',
  icon: TeamOutlined,
  component: CreateWitness,
  meta: {
    centered: false,
    hidden: true,
    canGoBack: true,
    disabled: false
  }
}

const witnessess: IRoute = {
  path: '/witnessess',
  name: 'Testemunhas',
  icon: TeamOutlined,
  component: WitnessesList,
  meta: {
    centered: false,
    hidden: false,
    canGoBack: false,
    disabled: false
  }
}

const attorneys: IRoute = {
  path: EGI.ATTORNEYS.path,
  name: EGI.ATTORNEYS.name,
  icon: TeamOutlined,
  component: AttorneysList,
  meta: {
    centered: false,
    hidden: false,
    canGoBack: false,
    disabled: false
  }
}

const LOGS_ZIP_PATH = '/download-documents'

const logsZip: IRoute = {
  path: LOGS_ZIP_PATH,
  name: 'Download de documentos',
  icon: DownloadOutlined,
  component: DownloadDocuments,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: false
  }
}

const providers: IRoute = {
  path: EGI.JURIDICO_PROVIDERS.path,
  name: EGI.JURIDICO_PROVIDERS.name,
  icon: TeamOutlined,
  component: () => <UsersTable url='/providers' area="juridico" level="provider" inviteButtonText={translate.level('provider')} columns={mainColumns.provider} />,
  meta: {
    centered: false,
    hidden: false,
    canGoBack: false,
    disabled: false
  }
}

const promoters: IRoute = {
  path: EGI.JURIDICO_PROMOTERS.path,
  name: EGI.JURIDICO_PROMOTERS.name,
  icon: TeamOutlined,
  component: () => <PromoterList viewerLevel="juridico" canGenerateReport={true} canInvite={true} />,
  meta: {
    centered: false,
    hidden: false,
    canGoBack: false,
    disabled: false
  }
}

const telemarketing: IRoute = {
  path: '/juridico/telemarketing',
  name: 'Telemarketing',
  icon: TeamOutlined,
  component: TelemarketingList,
  meta: {
    centered: false,
    hidden: false,
    canGoBack: false,
    disabled: false
  }
}

const partnersLine: IRoute = {
  name: 'Documentação de parceiros',
  path: '/juridico/partner/:partnerId/documentation',
  icon: FileTextOutlined,
  component: PartnersLine,
  meta: {
    centered: false,
    hidden: true,
    canGoBack: true,
    disabled: false
  }
}

const juridicoTeam = {
  leaders,
  collaborators,
  promoters,
  providers,
  telemarketing,
  partnersLine,
  logsZip
}

export {
  createAttorney,
  createWitness,
  getOneAttorney,
  getOneWitness,
  witnessess,
  attorneys,
  juridicoTeam,
  promoters,
  providers,
  telemarketing,
  LOGS_ZIP_PATH
}

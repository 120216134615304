import { AxiosRequestConfig } from 'axios'
import { IResponseBase, Repository } from 'ecp/repositories/Repository'
import api from 'services/api'

class UnauthDynamicProposalRepository extends Repository {
  async uploadOnStep ({ proposalId, departmentId, stepId, linkId }: {proposalId: string, departmentId: string, stepId: string, linkId: string}, file: FormData, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.post(`${this.path}/${proposalId}/department/${departmentId}/step/${stepId}/link/${linkId}/file`, file, config)
    )
  }

  async getDocuments (token: string, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.get(`${this.path}/documents?token=${token}`, config)
    )
  }
}

export default new UnauthDynamicProposalRepository({ api, path: 'unauth/dynamic-proposal' })

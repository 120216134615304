import React, { useLayoutEffect, useState } from 'react'
import { resources } from 'egi/consumers'
import { Form, Select } from 'antd'
import { ILabelValue } from 'store/modules/resources/types'
import { useTables } from 'hooks'
import { useDispatch } from 'react-redux'
import { tablesSetFiltersValues } from 'store/modules/tables/actions'

export type FilterProviderProps = {
  onChange: Function
  types?: 'juridico' | 'engenharia' | '' | undefined
}

function FilterProvider ({ onChange, types = undefined }: FilterProviderProps) {
  const tables = useTables()
  const dispatch = useDispatch()
  const [selectValues, setSelectValues] = useState([])

  const getProvider = async () => {
    try {
      const response = await resources.providers({ areaName: types })
      const providers = response.providers
      setSelectValues(providers)
    } catch (err) {
      console.warn(err)
    }
  }

  useLayoutEffect(() => {
    getProvider()
    if (tables.filters.providerId) onChange(tables.filters.providerId)
  }, [])

  const resetProvider = () => dispatch(tablesSetFiltersValues({ ...tables.filters, providerId: undefined }))

  return (
    <Form.Item
      name="providerId"
      label={<label>Prestador</label>}
    >
      <Select
        showSearch
        optionFilterProp="children"
        onChange={(value: any) => {
          onChange(value)
          if (!value) resetProvider()
        }}
        onClear={() => resetProvider()}
        placeholder='Selecione o prestador'
        allowClear
      >
        <Select.Option value="">Todos</Select.Option>
        {selectValues.map((item: ILabelValue) => (
          <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default FilterProvider

import { IErrorDetails } from 'components/ErrorDetails/interfaces'
import LeadsRepository from 'egi/repositories/LeadsRepository'
import { useCallback, useState } from 'react'
import { ILeadDetails } from '../leadInterfaces'

export function useLeadsDetails () {
  const [lead, setLead] = useState<ILeadDetails>()
  const [error, setError] = useState<IErrorDetails>()
  const [loading, setLoading] = useState<boolean>(false)

  const fetchLeads = useCallback(async (leadId, abortController: AbortController) => {
    setLoading(true)
    try {
      const requestConfig = {
        signal: abortController.signal
      }

      const response = await LeadsRepository.details(leadId, requestConfig)
      const lead = response?.data?.data?.lead

      if (!lead) throw new Error('Nenhum passo encontrado')
      setLead(lead)
      setError(undefined)
      setLoading(false)
    } catch (err) {
      setError(err)
      if (!abortController.signal.aborted) {
        setLoading(false)
      }
    }
  }, [])

  return ({
    lead,
    error,
    fetchLeads,
    loading
  })
}

import React, { Fragment } from 'react'
import { Col, Form } from 'antd'
import { Input } from 'components'

function DatesReview ({ cndType, errors }: { cndType: string, errors: any }) {
  return (
    <Fragment>
      <Col lg={12} sm={24} xs={24}>
        <Form.Item
          name={`${cndType}NumberForIssuer`}
          label={<label>Número da Certidão</label>}
          help={errors[`${cndType}NumberForIssuer`] && errors[`${cndType}NumberForIssuer`]}
          validateStatus={errors[`${cndType}NumberForIssuer`] && 'error'}
        >
          <Input
            placeholder='Digite o Número da Certidão'
            error={errors[`${cndType}NumberForIssuer`]} type={cndType === 'cdti' ? 'text' : 'number'}
          />
        </Form.Item>
      </Col>
    </Fragment>
  )
}

export default DatesReview

import React from 'react'
import mainColumns from 'egi/views/UsersTable/DataTables'
import { ShoppingCartOutlined, ContainerOutlined, DollarOutlined } from '@ant-design/icons'
import translate from 'utils/translate'

import UsersTable from 'egi/views/UsersTable/UsersTable'
import { IRoute } from 'components/Sidebar/types'
import pricingViews from 'egi/app/Pricing/views/pricingViews'
import NotaryFees from 'egi/app/NotaryFees/views/NotaryFees'
import { EGI } from 'routes/egi/paths'

const precificacao: IRoute = {
  path: '/pricing',
  name: 'Editar/Criar',
  icon: ShoppingCartOutlined,
  component: pricingViews.PricingProducts,
  meta: {
    centered: false,
    hidden: false,
    canGoBack: false,
    disabled: false
  }
}

const precificacaoTable: IRoute = {
  path: '/pricing/table/:personType/:product/:productSlug',
  name: 'Precificação',
  component: () => <pricingViews.PricingTable type="precificacao" />,
  meta: {
    centered: false,
    hidden: true,
    canGoBack: true,
    disabled: false
  }
}

const precificacaoCreate: IRoute = {
  path: '/pricing/create/:personType/:product/:productSlug',
  name: 'Nova precificação',
  component: pricingViews.PricingCreate,
  meta: {
    centered: false,
    hidden: true,
    canGoBack: true,
    disabled: false
  }
}

const precificacaoEdit: IRoute = {
  path: '/pricing/edit/:personType/:product/:productSlug/:precificacaoId',
  name: 'Criar precificação a partir da selecionada',
  component: pricingViews.PricingCreate,
  meta: {
    centered: false,
    hidden: true,
    canGoBack: true,
    disabled: false
  }
}

const pricingUsers: IRoute = {
  path: EGI.PRICING_USERS.path,
  name: EGI.PRICING_USERS.name,
  icon: ShoppingCartOutlined,
  component: () => <UsersTable url='/pricings' level="pricing" inviteButtonText={translate.level('pricing')} columns={mainColumns.pricing} />,
  meta: {
    centered: false,
    hidden: false,
    canGoBack: false,
    disabled: false
  }
}

const pricingTable: IRoute = {
  path: '/pricing/commission/table',
  name: 'Precificação',
  icon: ShoppingCartOutlined,
  component: () => <pricingViews.PricingTable type="precificacao" />,
  meta: {
    centered: false,
    hidden: false,
    canGoBack: false,
    disabled: false
  }
}

const pricingCommissionTable: IRoute = {
  path: '/pricing/commission/table',
  name: 'Precificação',
  icon: ShoppingCartOutlined,
  component: () => <pricingViews.PricingTable type="commissao" />,
  meta: {
    centered: false,
    hidden: false,
    canGoBack: false,
    disabled: false
  }
}

const exclusiveFees: IRoute = {
  path: '/fees/table',
  name: 'Taxas exclusivas',
  icon: ContainerOutlined,
  component: () => <pricingViews.ExclusiveFees />,
  meta: {
    centered: false,
    hidden: false,
    canGoBack: false,
    disabled: false
  }
}

const notaryFees: IRoute = {
  path: '/notary-fees',
  name: 'Custas Cartorárias',
  icon: DollarOutlined,
  component: () => <NotaryFees/>,
  meta: {
    centered: true,
    hidden: false,
    disabled: false,
    canGoBack: true
  }
}

export {
  precificacao,
  precificacaoTable,
  pricingCommissionTable,
  precificacaoCreate,
  precificacaoEdit,
  pricingUsers,
  pricingTable,
  exclusiveFees,
  notaryFees
}

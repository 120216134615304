import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { Button, Tag } from 'antd'
import { Link } from 'react-router-dom'
import translate from 'utils/translate'
import { varColors } from 'styles/colors'
import { timeAsDayjs } from 'utils/time'
import { IProposalDrawer } from '../../views/ExclusiveFees'

import { _proposalStatuses } from 'egi/app/ProposalEgi/proposalEgiInterfaces'
import { OverflowTooltip } from 'components/OverflowToolTip/OverflowTooltip'
import commonColumns, { commomNameOverflowWidth } from 'components/UserTable/utils/commonColumns'

interface ITaxesRow {
  monthlyTax: number
  commission: number
  sub: string
  amortizationType: string
  promoterId: string
  pricingRevision: {
    needsRevision: boolean
    reason: string
    requestedRevisionAt: string
    _id: string
  }
  _id: string
}

export interface IExclusiveFeesColumns {
  showProposalDrawer?: ({ proposalId, row }: IProposalDrawer) => void
}

const ExclusiveFeesColumns = ({ showProposalDrawer }: IExclusiveFeesColumns): ColumnsType<Object> => [
  {
    title: 'ID',
    dataIndex: 'number',
    key: 'number',
    width: 80,
    render: function renderItem (text, row) {
      const values = row as {_id: string}
      return (
        <Link
          className="link-style color-tertiary font-500"
          to={`/auth/proposals/show/${values._id}`}
        >
          {text} &rarr;
        </Link>
      )
    },
    sorter: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    sorter: true,
    width: 150,
    render: (text: string) => {
      const textProposal = text as _proposalStatuses
      const translatedColor = translate.reverseProposalStatus(text)

      return (
        <Tag
          className="text-center w-100 capitalize"
          color={varColors[translatedColor]}
        >
          {translate.proposalStatus(textProposal)}
        </Tag>
      )
    }
  },
  {
    title: 'Cliente',
    dataIndex: 'name',
    key: 'name',
    width: 150,
    render: (name: string, row : object) => {
      const { cpf } = row as { cpf: string }
      if (!name) return 'N/A'
      return <>
        <OverflowTooltip width={commomNameOverflowWidth}>
          {name}
        </OverflowTooltip>
        <div>
          {cpf && cpf}
        </div>
      </>
    }
  },
  {
    title: 'Indicador',
    dataIndex: 'indicator',
    width: 150,
    render: (item, row: object) => {
      const rowTyped = row as { indicator: { type: string, _id: string } }
      const type = rowTyped.indicator?.type ? `${rowTyped.indicator?.type} - ` : ''
      if (!item?.name) return '-'
      return <OverflowTooltip width={150}>
        <span className="table-name">{type}{item?.name}</span>
      </OverflowTooltip>
    }
  },
  {
    title: 'Parceiro',
    dataIndex: 'promoter',
    key: 'producer',
    width: 200,
    render: (promoter: { name: string, type: string, code: number }) => {
      if (!promoter?.name) return '-'
      return <OverflowTooltip width={200}>
        <span className="table-name">{promoter?.type || '-'} - {promoter?.name || '-'}</span>
      </OverflowTooltip>
    }
  },
  {
    title: 'Telemarketing tombada',
    dataIndex: 'reminderTelemarketingName',
    sorter: false,
    width: 200,
    render: (tm: string) => {
      if (!tm) return '-'
      return <OverflowTooltip width={200}><p className='capitalize'>{tm.toLowerCase()}</p></OverflowTooltip>
    }
  },
  {
    title: 'Consultor',
    dataIndex: 'consultant',
    key: 'consultant',
    width: 150,
    sorter: true,
    render: (consultant: { id: string, name: string }) => {
      if (!consultant.name) return '-'
      return <OverflowTooltip width={150}><span className="table-name capitalize">{consultant?.name.toLowerCase()}</span></OverflowTooltip>
    }
  },
  {
    title: 'Suporte',
    dataIndex: 'support',
    width: 150,
    key: 'support',
    sorter: true,
    render: (support: { id: string, name: string }) => {
      if (!support?.name) return '-'
      return <OverflowTooltip width={150}><span className="table-name capitalize">{<span className="table-name">{support?.name}</span>}</span></OverflowTooltip>
    }
  },
  {
    title: 'Valor',
    dataIndex: 'value',
    width: 150,
    key: 'value',
    sorter: true
  },
  commonColumns.createdAt,
  {
    title: 'Atualização',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    ellipsis: true,
    align: 'center',
    width: 150,
    sorter: true,
    render: (updatedAt) => {
      return <span className="table-name">{updatedAt}</span>
    }
  },
  {
    title: 'Último departamento',
    dataIndex: 'lastArea',
    width: 150,
    align: 'center',
    key: 'lastArea',
    render: (item) => <Tag>{item}</Tag>
  },
  {
    title: 'Data de Solicitação',
    dataIndex: 'dateRevisionRequest',
    align: 'center',
    width: 150,
    key: 'dateRevisionRequest',
    render: (item) => timeAsDayjs(item).format('DD/MM/YYYY HH:MM'),
    sorter: true
  },
  {
    title: '',
    dataIndex: 'revised',
    key: 'revised',
    align: 'center',
    width: 150,
    render: (item, row) => {
      const values = row as ITaxesRow

      return (
        <>
          <Button
            className='font-size-12'
            type={item === false ? 'primary' : 'ghost'}
            onClick={() => showProposalDrawer && showProposalDrawer({
              proposalId: values._id,
              row: { ...values, isRevised: item, promoterId: values.promoterId }
            })
            }
          >
            {item === false ? 'Revisar Taxa' : 'Taxa Revisada'}
          </Button>
        </>
      )
    }
  }
]

export { ExclusiveFeesColumns }

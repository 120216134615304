import React, { useState } from 'react'
import { Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import TokenVerification from 'components/TokenVerification/TokenVerification'
import { IError } from 'ecp/app/Proposals/proposalInterfaces'

import SignupLayout from 'layouts/SignupLayout/SignupLayout'
import LoginRepository from 'ecp/repositories/LoginRepository'
import { handleOnidataErrors } from 'utils/validate'
import TokenHeader from 'ecp/components/TokenHeader/TokenHeader'
import { useHistory } from 'react-router-dom'
import { UNAUTHS_PATHS } from 'routes/unauthRoutes'
import useTokenInfo from '../../hooks/useTokenInfo'
import { ISignupClienttoken_confirmacaoForm } from '../../signUpClientInterfaces'
import { IFirstAccessConfirmEmail } from 'ecp/app/FirstAccess/firstAccesInterfaces'
import SignupButton from 'egi/app/Signup/components/SignupButton/SignupButton'
import { SignupTitle } from 'egi/app/Signup/components/SignupTitle/SignupTitle'
import { swalError } from 'components/SwalError/SwalError'

function SignupClientEmailToken () {
  const [form] = useForm<ISignupClienttoken_confirmacaoForm>()
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [errors, setErrors] = useState<IError<ISignupClienttoken_confirmacaoForm>>({})

  const { tokenInfo } = useTokenInfo()
  const history = useHistory()

  function redirectToNextStep () {
    history.push({
      pathname: `${UNAUTHS_PATHS.SIGNUP_CLIENT_PHONE}`,
      state: tokenInfo
    })
  }

  async function handleSubmit (values: ISignupClienttoken_confirmacaoForm) {
    setLoadingSubmit(true)

    const data: IFirstAccessConfirmEmail = {
      ...values,
      email: tokenInfo?.email,
      onidataToken: tokenInfo.jwtToken as string
    }

    try {
      await LoginRepository.confirmEmail(data)

      redirectToNextStep()
    } catch (err) {
      if (err?.data?.error) setErrors(handleOnidataErrors(err.data?.error))
      swalError({ err })
    } finally {
      setLoadingSubmit(false)
    }
  }

  return (
    <SignupLayout>
      <SignupTitle>
        Confirmar email
      </SignupTitle>

      <TokenHeader
        title='Token enviado para seu email'
        infoText={
          <p className='ecp-token-header__info-text'>Adicione o token que você recebeu no email <b>{tokenInfo?.email}</b></p>
        }
        className='mb-5'
      />
      <Form
        onFinish={handleSubmit}
        form={form}
        layout='vertical'
        className='unauth-form'
      >
        <Form.Item
          name='token_confirmacao'
          label={<span className='simulator-label'>Digite o token</span>}
          help={errors?.token_confirmacao}
          validateStatus={errors?.token_confirmacao && ('error')}
          rules={[
            {
              required: true,
              message: 'Adicione o token.'
            }
          ]}
        >
          <TokenVerification lenght={6}/>
        </Form.Item>
        <Form.Item>
          <SignupButton
            loading={loadingSubmit}
            htmlType='submit'
            className='w-100'
          >
            Enviar
          </SignupButton>
        </Form.Item>
      </Form>
    </SignupLayout>
  )
}

export default SignupClientEmailToken

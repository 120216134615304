import React, { useEffect, useMemo, useState } from 'react'
import { Col, Collapse, Divider, Progress, Row, Typography } from 'antd'
import DepartmentSection from '../DepartmentSection/DepartmentSection'
import PartnerStep from '../PartnerStep/PartnerStep'
import SLA from 'egi/app/Settings/components/SLA/SLA'
import { IPartnerDepartment, IPartnerStep } from '../../types'

type Props = {
  departments: IPartnerDepartment
}

export const DepartmentContext = React.createContext<Omit<IPartnerDepartment, 'steps'>>({} as unknown as IPartnerDepartment)

function PartnerDepartments ({ departments }: Props) {
  const [steps, setSteps] = useState<IPartnerStep[]>([])

  const approvedSteps = useMemo(() => steps.filter(value => value.status === 'approved').length, [steps])
  const isSLALocked = departments.slaIsLocked

  useEffect(() => {
    if (departments) {
      const departmentSteps = Object.values(departments.steps)
      setSteps(departmentSteps)
    }
  }, [departments])

  return (
    <DepartmentSection
      image={{
        src: departments.image,
        alt: 'Imagem do departamento'
      }}
    >
      <div className="department-info">
        <div className='flex flex-row flex-space-between'>
          <Row gutter={[10, 10]}>
            <Col xs={24} md={14} lg={18}>
              <Typography.Title className="department-info__title" level={3}>
                {departments.name}
              </Typography.Title>
            </Col>
            <Col xs={24} md={10} lg={6}>
              {!isSLALocked && departments.sla && <div className='department-info__SLA'><SLA sla={departments.sla} /></div> }
            </Col>
          </Row>
        </div>

        <Divider className="department-info__divider" />

        <div className='department-info__progress-bar-wrapper'>
          <Progress
            className='department-info__progress-bar'
            strokeColor='var(--primary-color)'
            trailColor='#CECECE'
            status='success'
            percent={(approvedSteps / steps.length) * 100}
            showInfo={false}
          />

          <p className='department-info__progress-bar-text'>
            {approvedSteps} de {steps.length} tarefas finalizadas.
          </p>
        </div>

        <DepartmentContext.Provider value={departments}>
          <Collapse defaultActiveKey="1" ghost>
            <Collapse.Panel header="Ver etapas" key='1'>
              <div className='department-info__steps-wrapper'>
                {steps.length > 0 && steps.map((step, idx) => (
                  <PartnerStep
                    step={step}
                    key={idx}
                  />
                ))}
              </div>
            </Collapse.Panel>
          </Collapse>
        </DepartmentContext.Provider>
      </div>
    </DepartmentSection>
  )
}

export default PartnerDepartments

import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { IFirstAccessCorbanRHTokenInfo } from '../firstAccesInterfaces'

export default function useFirstAccesCorbanTokenInfo () {
  const { state: tokenInfo } = useLocation<IFirstAccessCorbanRHTokenInfo | null>()
  const history = useHistory()

  useEffect(() => {
    if (!tokenInfo || !tokenInfo.onidataToken) history.push('/')
  }, [tokenInfo])

  return { tokenInfo: tokenInfo || {} as IFirstAccessCorbanRHTokenInfo }
}

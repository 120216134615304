import React, { Fragment, useEffect, useState } from 'react'
import { Form, Row, Col, Select, Checkbox, Divider } from 'antd'
import { useDispatch } from 'react-redux'
import { validateResponse } from 'utils/validate'
import { useAuth, useClient, useProposal, useResources, useStep } from 'hooks'
import { Address, Input, FormPF } from 'components'
import time, { correctTimezone, timeAsDayjs } from 'utils/time'
import swal from 'utils/swal'
import format from 'utils/format'

import { IFormData, IFormDataErrors, IFormProps, _maritalRegime, _maritalStatus } from './types'
import TooltipAntinupcial from './TooltipAntinupcial'
import { proposalLoadingStep } from 'store/modules/proposal/actions'
import translate from 'utils/translate'
import mask from 'utils/masks'
import { proposals } from 'egi/consumers'
import { CheckTermsFields } from 'components/CheckTermsFields/CheckTermsFields'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'
import ProposalEgiModel from 'egi/models/ProposalEgiModel'

const FormUserData = ({ formRef, onType, onLoading, callbackProposal, readOnlyInput, openSimulationDrawer }: IFormProps) => {
  const [errors, setErrors] = useState<IFormDataErrors>({})
  const [maritalStatus, setMaritalStatus] = useState<_maritalStatus>()
  const [resourceGoal, setResourceGoal] = useState<string>()
  const [composeIncome, setComposeIncome] = useState<boolean>()
  const [person, setPerson] = useState<string>()
  const [regime, setRegime] = useState<_maritalRegime>()

  const step = useStep()
  const user = useAuth()
  const client = useClient()
  const proposal = useProposal()
  const resources = useResources()
  const dispatch = useDispatch()

  const IS_CLIENT = user.level === 'client'
  const canComposeIncome = ProposalEgiModel.canSeeComposeIncome(proposal.isPj ?? false, step?.ref, regime)

  const onSubmit = async (values: IFormData & { clientId: string }) => {
    const formData = Object.assign({}, values)

    if (formData.rg) formData.rg = format.onlyAlphaNumeric(formData.rg)
    if (formData.expeditionDate) formData.expeditionDate = time(correctTimezone(String(formData.expeditionDate)))
    if (formData.birthdate) formData.birthdate = time(correctTimezone(String(formData.birthdate)))

    // only digits //
    if (formData.whatsapp) formData.whatsapp = format.onlyDigits(formData.whatsapp)
    if (formData.phone) formData.phone = format.onlyDigits(formData.phone)
    if (formData.cpf) formData.cpf = format.onlyDigits(formData.cpf)
    if (formData.cnh) formData.cnh = format.onlyDigits(formData.cnh)

    if (!formData.composeIncomeStableUnion) formData.composeIncomeStableUnion = false
    if (!formData.stableUnion) formData.stableUnion = false
    if (!formData.prenuptional) formData.prenuptional = false

    if (user.level === 'promoter') formData.clientId = String(client.id)

    try {
      if (!proposal.id) throw new Error('Identificador da proposta não encontrado!')

      const props: any = {
        proposalId: proposal.id,
        stepId: step._id,
        areaId: step.areaId,
        form: formData
      }

      let response

      if (onType === 'send') {
        onLoading('sending')
        props.form = { ...props.form, finished: true }
        response = await proposals.send(props)
      } else if (onType === 'save') {
        onLoading('saving')
        props.form = { ...props.form, finished: false }
        response = await proposals.send(props)
      }

      if (step.id) dispatch(proposalLoadingStep({ stepId: step.id, loading: true }))
      setErrors({})
      callbackProposal()
      swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
    } catch (err) {
      if (err?.data?.maxDeadline !== undefined && err?.data?.maxDeadline !== null) {
        swal.confirm({
          icon: 'warning',
          title: 'Atenção',
          html: err.message,
          confirmButtonText: 'Refazer simulação',
          showCancelButton: false,
          confirm: () => {
            if (openSimulationDrawer) openSimulationDrawer({ type: 'simulator' })
          }
        })
      }

      if (err.data?.invalid) {
        let errors: IFormDataErrors = {}
        errors = validateResponse(err.data?.invalid)
        setErrors(errors)
        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
        return
      }

      setErrors({})
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      return false
    } finally {
      onLoading('')
    }
  }

  useEffect(() => {
    function resetFields () {
      if (formRef) formRef.resetFields()
    }

    async function onFill () {
      const formData = JSON.parse(JSON.stringify(step.form || {}))

      if (proposal.id && IS_CLIENT && user && step.ref === 'titular') {
        if (!formData.email) formData.email = user.email

        if (formData.whatsapp) formData.whatsapp = mask(formData.whatsapp, 'phone', true)
      }

      if (formData.expeditionDate) formData.expeditionDate = timeAsDayjs(formData.expeditionDate, { applyTimezone: false }).format('DD/MM/YYYY')
      else formData.expeditionDate = undefined

      if (formData.birthdate) formData.birthdate = timeAsDayjs(formData.birthdate, { applyTimezone: false }).format('DD/MM/YYYY')
      else formData.birthdate = undefined

      if (formData.resourceGoal) setResourceGoal(formData.resourceGoal)
      if (formData.maritalStatus) setMaritalStatus(formData.maritalStatus)
      if (formData.maritalRegime) setRegime(formData.maritalRegime)
      if (formData.stableUnion) setComposeIncome(formData.stableUnion)
      if (formData.phone) formData.phone = mask(formData.phone, 'phone', true)

      if (Object.keys(formData).length < 1) {
        resetFields()
        return
      }

      formRef.setFieldsValue(formData)
    }

    const getPerson = () => {
      if (step) {
        if (step.ref?.includes('conjuge')) {
          setPerson('conjuge')
        } else if (step.name.includes('fiador')) {
          setPerson('fiador')
        } else {
          setPerson(undefined)
        }
      }
    }

    resetFields()
    getPerson()
    onFill()

    return () => {
      resetFields()
    }
  }, [
    step.form,
    formRef,
    step._id,
    step.situation
  ])

  return (
    <Fragment>
      <Form
        form={formRef}
        onFinish={onSubmit}
        layout="vertical"
        initialValues={{
          politicallyExposed: false,
          hasAttorney: false,
          isRural: false,
          maritalStatus: step.ref?.includes('conjuge') ? 'CASADO' : null
        }}
      >
        <Row>
          <FormPF
            errors={errors}
            person={person}
            readOnlyInput={readOnlyInput}
          />

          <Col className="px-1" lg={8} sm={12} xs={24}>
            <Form.Item
              name='politicallyExposed'
              help={errors.politicallyExposed && (errors.politicallyExposed)}
              validateStatus={errors.politicallyExposed && ('error')}
              className="form-user-checkbox"
              valuePropName="checked"
              label={<label></label>}
            >
              <Checkbox
                id="test-checkbox-policy"
                disabled={readOnlyInput}
              >
                Politicamente exposto
              </Checkbox>
            </Form.Item>
          </Col>

          <Col className="px-1" lg={8} sm={12} xs={24}>
            <Form.Item
              name='phone'
              label={<label>Telefone: </label>}
              help={errors.phone && (errors.phone)}
              validateStatus={errors.phone && ('error')}
            >
              <Input
                disabled={readOnlyInput} placeholder="Contato telefone"
                mask="phone"
              />
            </Form.Item>
          </Col>

          <Col className="px-1" lg={8} sm={12} xs={24}>
            <Form.Item
              name='whatsapp'
              label={<label>Contato whatsapp (opcional): </label>}
              help={errors.whatsapp && (errors.whatsapp)}
              validateStatus={errors.whatsapp && ('error')}
            >
              <Input disabled={readOnlyInput} placeholder="Contato whatsapp" mask="phone" />
            </Form.Item>
          </Col>

          {step.ref?.includes('conjuge') && (
            <>
              <Col className="px-1" lg={8} sm={12} xs={24}>
                <Form.Item
                  name='maritalStatus'
                  label={<label>Estado civil do cônjuge:</label>}
                  help={errors.maritalStatus && (errors.maritalStatus)}
                  validateStatus={errors.maritalStatus && ('error')}
                >
                  <Select
                    id="test-select-form-maritalStatus"
                    placeholder='Selecione o estado civil do cônjuge'
                    defaultValue='CASADO'
                    disabled={readOnlyInput}
                  >
                    <Select.Option id="test-select-form-maritalStatus-solteiro" value="SOLTEIRO">Solteiro(a)</Select.Option>
                    <Select.Option id="test-select-form-maritalStatus-casado" value="CASADO">Casado(a)</Select.Option>
                    <Select.Option id="test-select-form-maritalStatus-divorciado" value="DIVORCIADO">Divorciado(a)</Select.Option>
                    <Select.Option id="test-select-form-maritalStatus-separado" value="SEPARADO">Separado(a) judicialmente</Select.Option>
                    <Select.Option id="test-select-form-maritalStatus-viuvo" value="VIUVO">Viúvo(a)</Select.Option>
                    <Select.Option id="test-select-form-maritalStatus-desquitado" value="DESQUITADO">Desquitado(a)</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className="px-1" lg={8} sm={12} xs={24}>
                <Form.Item
                  name='occupation'
                  label={<label>Profissão do cônjuge:</label>}
                  help={errors.occupation && (errors.occupation)}
                  validateStatus={errors.occupation && ('error')}
                >
                  <Input
                    readOnly={readOnlyInput}
                    placeholder={'Digite a profissão do conjuge'}
                    error={errors.occupation}
                  />
                </Form.Item>
              </Col>
            </>
          )}

        </Row>

        {step?.ref === 'titular' && (
          <Fragment>
            <Divider />
            <Row>
              <Col className="px-1" lg={12} sm={12} xs={24}>
                <Form.Item
                  name="resourceGoal"
                  label={<label>Como este empréstimo irá te ajudar</label>}
                  help={errors.resourceGoal && (errors.resourceGoal)}
                  validateStatus={errors.resourceGoal && ('error')}
                >
                  <Select
                    onChange={(value: string) => setResourceGoal(value)}
                    id="test-select-form-resourcesPurpose"
                    placeholder="Selecione uma opção"
                    showSearch
                    disabled={readOnlyInput}
                    aria-readonly={readOnlyInput}
                  >
                    {resources.goals.map((goals: any) =>
                      <Select.Option id={`test-select-form-${goals}`} key={goals} value={goals}>{translate.goals(goals)}</Select.Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {resourceGoal === 'outro' && (
              <Col className="px-1" lg={12} sm={12} xs={24}>
                <Form.Item
                  name="otherGoal"
                  label={<label>Outra finalidade</label>}
                  help={errors.ohterGoal && (errors.ohterGoal)}
                  validateStatus={errors.ohterGoal && ('error')}
                >
                  <Input disabled={readOnlyInput} placeholder="Digite a outra finalidade" />
                </Form.Item>
              </Col>
            )}
            <Divider />
          </Fragment>
        )}

        <Row>
          {(!step.ref?.startsWith('conjuge')) && (
            <Col className="px-1" lg={8} sm={12} xs={24}>
              <Form.Item
                name='maritalStatus'
                label={<label>Estado civil:</label>}
                help={errors.maritalStatus && (errors.maritalStatus)}
                validateStatus={errors.maritalStatus && ('error')}
              >
                <Select
                  onChange={(value) => setMaritalStatus(value)}
                  id="test-select-form-maritalStatus"
                  placeholder={person ? `Selecione o estado civil do ${person}` : 'Escolha seu estado civil'}
                  defaultValue={person === 'conjuge' ? 'CASADO' : undefined}
                  disabled={person === 'conjuge' || readOnlyInput}
                >
                  <Select.Option id="test-select-form-maritalStatus-solteiro" value="SOLTEIRO">Solteiro(a)</Select.Option>
                  <Select.Option id="test-select-form-maritalStatus-casado" value="CASADO">Casado(a)</Select.Option>
                  <Select.Option id="test-select-form-maritalStatus-divorciado" value="DIVORCIADO">Divorciado(a)</Select.Option>
                  <Select.Option id="test-select-form-maritalStatus-separado" value="SEPARADO">Separado(a) judicialmente</Select.Option>
                  <Select.Option id="test-select-form-maritalStatus-viuvo" value="VIUVO">Viúvo(a)</Select.Option>
                  <Select.Option id="test-select-form-maritalStatus-desquitado" value="DESQUITADO">Desquitado(a)</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          )}

          {(ProposalEgiModel.canCheckStableUnion(maritalStatus, person)) &&
            <Col className="px-1" lg={8} sm={12} xs={24}>
              <Form.Item
                className="form-user-checkbox"
                name="stableUnion"
                valuePropName="checked"
                label={<label></label>}
                help={errors.stableunion && (errors.stableunion)}
                validateStatus={errors.stableunion && ('error')}
              >
                <Checkbox
                  id="test-checkbox-stableunion"
                  disabled={readOnlyInput}
                  onChange={(value) => setComposeIncome(value.target.checked)}
                >
                  Convive em união estável
                </Checkbox>
              </Form.Item>
            </Col>
          }

          {(ProposalEgiModel.canSeeComposeIncomeTitular(composeIncome ?? false, step.ref, maritalStatus)) && (
            <>
              <Col className="px-1" lg={8} sm={12} xs={24}>
                <Form.Item
                  name="composeIncomeStableUnion"
                  className="form-user-checkbox"
                  valuePropName="checked"
                  label={<label></label>}
                  help={errors.composeIncomeStableUnion && (errors.composeIncomeStableUnion)}
                  validateStatus={errors.composeIncomeStableUnion && ('error')}
                >
                  <Checkbox
                    id="test-checkbox-composeIncomeStableUnion"
                    disabled={readOnlyInput}
                  >
                    Pessoa vai compor renda
                  </Checkbox>
                </Form.Item>
              </Col>
            </>
          )}

          {(ProposalEgiModel.canSelectMaritalRegime(maritalStatus, person)) && (
            <>
              <Col className="px-1" lg={8} sm={12} xs={24}>
                <Form.Item
                  name='maritalRegime'
                  label={<label>Regime:</label>}
                  help={errors.maritalRegime && (errors.maritalRegime)}
                  validateStatus={errors.maritalRegime && ('error')}
                >
                  <Select
                    id="test-select-maritalStatus"
                    placeholder={person ? `Selecione o regime do casamento do(a) ${person}` : 'Escolha o regime'}
                    aria-readonly={readOnlyInput}
                    disabled={person === 'conjuge' || readOnlyInput}
                    onChange={(e) => setRegime(e.toString() as _maritalRegime)}
                  >
                    <Select.Option id="test-select-maritalStatus-comunhaoparcial" value="COMUNHAOPARCIAL">Comunhão parcial</Select.Option>
                    <Select.Option id="test-select-maritalStatus-comunhaouniversal" value="COMUNHAOUNIVERSAL">Comunhão universal</Select.Option>
                    <Select.Option id="test-select-maritalStatus-separacaototal" value="SEPARACAOTOTALDEBENS">Separação total de bens</Select.Option>
                    <Select.Option id="test-select-maritalStatus-separacaofinal" value="PARTICIPACAOFINALDOSAQUESTOS">Separação final dos aquestos</Select.Option>
                    <Select.Option id="test-select-maritalStatus-separacaoobrigatoria" value="SEPARACAOOBRIGATORIADEBENS">Separação obrigatória de bens</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              {(ProposalEgiModel.canCheckPrenupcial(regime)) && (
                <>
                  <Col className="px-1" lg={8} sm={12} xs={24}>
                    <Form.Item
                      name="prenuptional"
                      className="form-user-checkbox"
                      valuePropName="checked"
                      label={<TooltipAntinupcial />}
                      help={errors.prenuptional && (errors.prenuptional)}
                      validateStatus={errors.prenuptional && ('error')}
                    >
                      <Checkbox
                        id="test-checkbox-prenuptional"
                        disabled={readOnlyInput}
                      >
                        Existe pacto antenupcial de bens
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </>
              )
              }

              {canComposeIncome && (
                <Col className="px-1" lg={8} sm={12} xs={24}>
                  <Form.Item
                    name="composeIncomeStableUnion"
                    className="form-user-checkbox"
                    valuePropName="checked"
                    label={<label></label>}
                    help={errors.composeIncomeStableUnion && (errors.composeIncomeStableUnion)}
                    validateStatus={errors.composeIncomeStableUnion && ('error')}
                  >
                    <Checkbox
                      id="test-checkbox-composeIncomeStableUnion"
                      disabled={readOnlyInput}
                    >
                      Cônjuge irá compor renda
                    </Checkbox>
                  </Form.Item>
                </Col>
              )}
            </>
          )}
        </Row>
        <Divider />
        <Row>
          {(step.ref === 'titular') && (
            <Col className="px-1" lg={8} sm={12} xs={24}>
              <Form.Item
                name="hasAttorney"
                className="form-user-checkbox"
                valuePropName="checked"
                label={<label></label>}
                help={errors.hasAttorney && (errors.hasAttorney)}
                validateStatus={errors.hasAttorney && ('error')}
              >
                <Checkbox
                  id="test-checkbox-hasAttorney"
                  disabled={readOnlyInput}
                >
                  Representado por procurador
                </Checkbox>
              </Form.Item>
            </Col>
          )}

          <Col className="px-1" lg={8} sm={12} xs={24}>
            <Form.Item
              name="isRural"
              valuePropName="checked"
              className="form-user-checkbox"
              label={<label></label>}
              help={errors.isRural && (errors.isRural)}
              validateStatus={errors.isRural && ('error')}
            >
              <Checkbox
                id="test-checkbox-isRural"
                disabled={readOnlyInput}
              >
                É produtor rural
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Address
          onCepBlur={(address, remainingAddress) => formRef.setFieldsValue({ address: { ...address, ...remainingAddress } })}
          formRef={formRef}
          errors={errors}
          person={person}
          title="Endereço"
          readOnlyInput={readOnlyInput}
        />
        <Divider />
        <CheckTermsFields
          errors={errors}
          readOnlyInput={readOnlyInput}
        />
      </Form>
    </Fragment>
  )
}

export default FormUserData

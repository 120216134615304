import { Col, Row } from 'antd'
import { ITotalLead } from 'egi/repositories/LeadsRepository'
import React, { Suspense } from 'react'
import { leadsColor, leadsColorsRgb } from 'styles/colors'
import translate from 'utils/translate'

function LeadPie ({ data }: { data: ITotalLead[] }) {
  const translatedTotals = data.map((item: { count: number, status: string }) => ({
    count: item.count,
    status: translate.leadStatus(item.status)
  }))

  const mainColors = (mydata: ITotalLead[]) => {
    const finalColors: any = {}
    mydata.forEach(function (item) {
      finalColors[translate.leadStatus(item.status)] = leadsColorsRgb[item.status]
    })
    return finalColors
  }

  function colorsPie (_ref: any) {
    const translatedColors = mainColors(data)
    const status = _ref.status
    return translatedColors[status]
  }

  const config = {
    appendPadding: 10,
    position: 'left',
    data: translatedTotals,
    angleField: 'count',
    colorField: 'status',
    radius: 1,
    innerRadius: 0.6,
    label: false as any,
    legend: false as any,
    color: colorsPie,
    statistic: {
      title: {
        customHtml: () => 'Leads'
      }
    }
  }
  const LeadsPie = React.lazy(() => import('@ant-design/charts/lib/pie'))

  return (
    <Suspense fallback={<h1>carregando</h1>}>
      <Row className="h-100">
        <Col lg={12} md={24} sm={24} xs={24} className="flex flex-align-center flex-justify-center">
          <LeadsPie {...config} style={{ height: 160, width: 160 }} />
        </Col>

        <Col lg={12} md={24} sm={24} xs={24}>
          <div className="flex flex-column flex-justify-center h-100">
            {data && Array.isArray(data) && data.map(item => (
              <div key={item.status}>
                <div className="flex flex-align-center mb-1">
                  <div style={{ width: 15, height: 15, borderRadius: 7.5, border: `3px solid ${leadsColor[item.status]}` }}></div>
                  <div className="flex flex-space-between w-100">
                    <label className="mx-3 color-primary">{translate.leadStatus(item.status)}</label><label className="color-gray">{item.count}</label>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Suspense>
  )
}

export default LeadPie

import { Empty, Timeline } from 'antd'
import ProposalHistoryItem from 'ecp/app/Proposals/components/ProposalHistoryItem/ProposalHistoryItem'
import { ProposalStepSkeleton } from 'ecp/app/Proposals/components/ProposalStepSkeleton/ProposalStepSkeleton'
import { IProposalHistory } from 'ecp/app/Proposals/proposalInterfaces'
import React, { RefObject, useLayoutEffect } from 'react'

export interface IProposalHistoryProps {
  historyList?: IProposalHistory.List
  shouldFocusHistory?: boolean
  cancelFocusHistory?: () => void
  historyEl?: RefObject<HTMLDivElement>
  loading?: boolean
  error?: string
}

function ProposalHistory ({ historyList, shouldFocusHistory, cancelFocusHistory, historyEl, loading, error }: IProposalHistoryProps) {
  useLayoutEffect(() => {
    if (historyList?.length && shouldFocusHistory) {
      historyEl?.current?.scrollIntoView?.({ block: 'start' })
      cancelFocusHistory?.()
    }
  }, [shouldFocusHistory, historyEl, historyList, loading])

  return (
    <div className='proposal-history' ref={historyEl}>
      <Timeline>
        {loading
          ? <ProposalStepSkeleton />
          : historyList?.length
            ? historyList.map((item, idx) => (
              <ProposalHistoryItem key={item.currentStep?.updatedAt + idx} item={item} />
            )) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={error ? error : 'Nenhum histórico encontrato.'}/>
            )
        }
      </Timeline>
    </div>
  )
}

export default ProposalHistory

import { notification } from 'antd'
import { ReactNode } from 'react'

interface Args {
  title: string
  text: string
  duration?: number,
  btn?: ReactNode
}

const pop = ({ title, text, btn, duration = 1.5 }: Args) => {
  notification.info({
    message: title,
    description: text,
    placement: 'bottomRight',
    duration,
    className: 'document-preview-pop',
    btn
  })
}

export default pop

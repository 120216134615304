import React, { Fragment } from 'react'
import { Divider } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { IUnauthedMessagesProps } from './types'

function UnauthedMessages ({ title, messageContent, description }: IUnauthedMessagesProps) {
  return (
    <Fragment>
      <div className="messages">
        <Fragment>
          <div className="messages-content-header">
            <h1 className="messages-content-info color-primary">Atenção</h1>
            <ExclamationCircleOutlined className="ml-2" style={{ fontSize: 20, color: '#00441F' }} />
          </div>

          <Divider className="mt-1 mb-1"/>

          <div className="messages-content">
            <div className="flex flex-justify-start flex-column">
              <h2 className="color-primary mb-1">{title} </h2>
              <p className="mb-3">{description}</p>

            </div>
            {messageContent}
          </div>
        </Fragment>
      </div>
    </Fragment>
  )
}

export default UnauthedMessages

import { _typePromoterEnum, _userLevel } from 'ecp/models/UsersModel'
import { _tableAreas } from 'globals'

const LeadsModel = {
  canSeeSimulateAndStatusValidAreaName: function (areaName?: _tableAreas, userType?: _typePromoterEnum) {
    if (areaName) return (['telemarketing', 'comercial'].includes(areaName))
    if (userType) return ['TM'].includes(userType)
  },

  canSeeSimulateAndStatus: function (userLevel: _userLevel, areaName?: _tableAreas, userType?:_typePromoterEnum, useLinkedLeads?: boolean) {
    if (['administrator', 'promoter', 'commercial'].includes(userLevel)) {
      if (areaName === 'comercial') return useLinkedLeads
      if (userLevel === 'commercial') return true
      if (!areaName && !userType) return false
      return this.canSeeSimulateAndStatusValidAreaName(areaName, userType)
    }

    return (['master'].includes(userLevel))
  },

  canEditCommercial: (userLevel:_userLevel, userArea?: _tableAreas, useLinkedLeads?: boolean) => {
    if ((userArea && ['comercial'].includes(userArea))) {
      return useLinkedLeads
    }
    return ['commercial', 'master'].includes(userLevel)
  },

  canSeeCommercial: (userLevel:_userLevel, userArea?: _tableAreas) => {
    return ['commercial', 'master'].includes(userLevel) || (userArea && ['comercial'].includes(userArea))
  },

  canSeeActions: (userLevel: _userLevel, type?: _typePromoterEnum) => {
    const isPromoterIn = userLevel === 'promoter' && type === 'IN'
    return !isPromoterIn
  },

  canSeeDetails: function (userLevel: _userLevel, areaName?: _tableAreas, userType?:_typePromoterEnum) {
    if (['administrator', 'promoter'].includes(userLevel)) {
      if (!areaName && !userType) return false
      return this.canSeeSimulateAndStatusValidAreaName(areaName, userType)
    }
    return (['master'].includes(userLevel))
  },

  canFilterBySeller: (userLevel: _userLevel, type?: _typePromoterEnum) => {
    const isPromoterIn = userLevel === 'promoter' && type === 'IN'
    return isPromoterIn
  },

  canFilterByPromoter: (userLevel: _userLevel) => {
    return userLevel === 'master' || userLevel === 'administrator'
  },

  canSeeTm: (userLevel: _userLevel) => {
    return userLevel !== 'promoter'
  },

  canEditTm: (userLevel: _userLevel, userArea: _tableAreas | undefined) => {
    return userLevel === 'master' || (userLevel === 'administrator' && userArea === 'telemarketing')
  }
}

export default LeadsModel

import React, { Fragment } from 'react'
import { Divider } from 'antd'
import { StepWarning } from 'components/StepWarning/StepWarning'

function ExplainSend ({ isDocument = false, docSize = 4000000, docLimit, description }: { isDocument?: boolean, docSize?: number, docLimit?: number, description?: string }) {
  const documentSize = docSize ? docSize / 1000000 : 0

  return (
    <Fragment>
      <StepWarning
        isDocument={isDocument}
        docSize={documentSize}
        docLimit={docLimit}
        description={description}
      />

      {isDocument && <Divider />}
    </Fragment>
  )
}

export default ExplainSend

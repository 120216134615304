export class BroadcastChannelRouter extends BroadcastChannel {
  static login = 'LOGIN'
  static logout = 'LOGOUT'

  constructor () {
    super('channel')
  }

  postLogin () {
    this.postMessage(BroadcastChannelRouter.login)
  }

  postLogout () {
    this.postMessage(BroadcastChannelRouter.logout)
  }
}

import backoffice from './backofficeColumns'
import clients from './clientsColumns'
import commission from './commissionColumns'
import pricing from './pricingColumns'
import commercial from './commercialColumns'
import administrator from './administratorColumns'
import consultant from './consultantColumns'
import telemarketing from './telemarketingColumns'
import provider from './providerColumns'
import commom from './commomColumns'
import teamColumns from './teamColumns'
import seller from './sellerColumns'
import auditor from './auditorColumns'

const finalcolumns = {
  backoffice,
  clients,
  commission,
  pricing,
  commercial,
  administrator,
  consultant,
  telemarketing,
  provider,
  commom,
  teamColumns,
  seller,
  auditor
}

export default finalcolumns

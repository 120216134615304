import React from 'react'
import ProposalStepButton, { IProposalStepButtonProps } from 'ecp/app/Proposals/components/ProposalStepButton/ProposalStepButton'
import ProposalStepButtonWithHistory from 'ecp/app/Proposals/components/ProposalStepButtonWithHistory/ProposalStepButtonWithHistory'
import useThisStep from 'ecp/app/Proposals/hooks/useThisStep'

interface IGenericStepProps {
  onClick?: IProposalStepButtonProps['onClick']
  enabled?: boolean
}

const GenericStep = ({ enabled = false, onClick = () => {} }: IGenericStepProps) => {
  const { step } = useThisStep()

  return (
    <ProposalStepButtonWithHistory>
      <ProposalStepButton
        onClick={onClick}
        enabled={enabled}
      >
        {step?.name}
      </ProposalStepButton>
    </ProposalStepButtonWithHistory>
  )
}

export default GenericStep

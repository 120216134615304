export const getProposalConfig = () => ({
  type: 'GET_PROPOSAL_CONFIG'
})

export const getProposalState = (id: string, callback?: Function) => ({
  type: 'GET_PROPOSAL_STATE',
  payload: {
    id,
    callback
  }
})

export const createProposal = ({ productId, clientId, simulationId }: { productId: string, clientId?:string, simulationId?: string }, callback?: (...args: any[]) => any) => ({
  type: 'CREATE_PROPOSAL',
  payload: {
    productId,
    clientId,
    simulationId,
    callback
  }
})

export const setProposal = (id: string) => ({
  type: 'REQUEST_PROPOSAL_AUTH',
  payload: {
    id
  }
})

export const setCallbackProposal = (fn: Function) => ({
  type: 'PROPOSAL_SET_CALLBACK',
  payload: { fn }
})

export const proposalReset = () => ({
  type: 'RESET_PROPOSAL'
})

export const proposalLoadingStep = ({ stepId, loading }: { stepId?: string, loading: boolean }) => ({
  type: 'PROPOSAL_LOADING_STEP',
  payload: {
    step: {
      id: stepId,
      loading
    }
  }
})

export const newProposal = (id: string, callback?: Function) => ({
  type: 'SET_NEW_PROPOSAL',
  payload: {
    id,
    callback
  }
})

export const setNeedVinculation = (needVinculation: boolean) => ({
  type: 'SET_NEED_VINCULATION',
  payload: { needVinculation }
})

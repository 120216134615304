import React from 'react'

export default function NewEmailSVG () {
  return (
    <svg width="200" height="192" viewBox="0 0 682 658" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M634.13 650.099C633.089 651.554 632.038 652.999 630.998 654.432C628.006 656.494 624.458 657.594 620.826 657.587H61.2803C57.81 657.59 54.4133 656.586 51.5015 654.698C50.5351 653.423 49.5796 652.138 48.6133 650.853L49.5796 650.322L332.846 493.266C335.359 491.876 338.184 491.144 341.056 491.141C343.928 491.137 346.754 491.861 349.271 493.245L633.174 649.568L634.13 650.099Z" fill="#00441F" />
        <path d="M620.826 319.856L375.328 178.04C365.003 172.075 353.29 168.932 341.367 168.926C329.443 168.92 317.727 172.051 307.396 178.006L61.2803 319.856C56.4953 319.862 51.9082 321.767 48.5246 325.151C45.1411 328.536 43.2372 333.124 43.2305 337.911V639.533C43.236 644.32 45.1395 648.909 48.5233 652.294C51.9071 655.679 56.4949 657.583 61.2803 657.588H620.826C625.611 657.58 630.197 655.675 633.58 652.291C636.963 648.907 638.868 644.319 638.876 639.533V337.911C638.869 333.124 636.965 328.536 633.582 325.151C630.198 321.767 625.611 319.862 620.826 319.856ZM636.752 639.533C636.742 643.755 635.061 647.801 632.076 650.787C629.092 653.772 625.047 655.454 620.826 655.464H61.2803C57.0587 655.456 53.0122 653.776 50.0271 650.79C47.042 647.804 45.3616 643.756 45.354 639.533V337.911C45.3591 333.687 47.0387 329.638 50.0244 326.651C53.01 323.665 57.058 321.985 61.2803 321.98L307.396 180.13C317.727 174.176 329.443 171.044 341.367 171.05C353.29 171.056 365.003 174.199 375.328 180.164L620.826 321.98C625.048 321.985 629.096 323.665 632.082 326.651C635.068 329.638 636.747 333.687 636.752 337.911V639.533Z" fill="#3F3D56" />
        <path d="M341.371 490.506C339.522 490.512 337.685 490.204 335.939 489.594L335.618 489.481L335.553 488.767C335.353 488.865 335.151 488.96 334.949 489.049L334.735 489.143L334.521 489.048C334.074 488.852 333.63 488.629 333.201 488.388L137.727 378.515V154.175C137.732 148.966 139.804 143.972 143.486 140.289C147.169 136.605 152.161 134.533 157.369 134.527H524.737C529.944 134.533 534.937 136.605 538.62 140.289C542.302 143.972 544.373 148.966 544.379 154.175V378.802L544.109 378.954L349.542 488.388C347.044 489.781 344.231 490.51 341.371 490.506Z" fill="white" />
        <path d="M341.371 491.037C339.462 491.043 337.565 490.725 335.763 490.095L335.12 489.868L335.092 489.565L334.735 489.722L334.307 489.535C333.844 489.331 333.384 489.1 332.94 488.85L137.195 378.826V154.175C137.201 148.825 139.329 143.696 143.111 139.913C146.893 136.13 152.02 134.002 157.369 133.996H524.736C530.085 134.002 535.212 136.13 538.994 139.913C542.776 143.696 544.904 148.825 544.91 154.175V379.112L349.801 488.851C347.224 490.288 344.322 491.041 341.371 491.037ZM337.11 488.3C341.013 489.422 345.202 488.954 348.762 486.999L542.786 377.87V154.175C542.781 149.388 540.877 144.799 537.494 141.414C534.11 138.03 529.522 136.126 524.736 136.12H157.369C152.583 136.126 147.995 138.029 144.611 141.414C141.228 144.799 139.324 149.388 139.319 154.175V377.582L152.049 384.734L333.981 486.999C334.229 487.139 334.48 487.27 334.735 487.393C334.99 487.27 335.241 487.139 335.488 487L336.918 486.194L337.11 488.3Z" fill="#3F3D56" />
        <path d="M634.173 327.694L557.121 371.026L543.849 378.492L349.283 487.926C346.865 489.274 344.141 489.98 341.372 489.975C339.583 489.981 337.806 489.683 336.117 489.094C335.647 488.944 335.186 488.766 334.736 488.563C334.301 488.372 333.877 488.159 333.463 487.926L151.53 385.661L138.259 378.205L48.0942 327.524C47.8491 327.383 47.6692 327.152 47.5937 326.879C47.5182 326.607 47.5531 326.316 47.6909 326.069C47.829 325.824 48.0587 325.644 48.3294 325.568C48.6001 325.493 48.8897 325.528 49.1347 325.665L138.259 375.762L151.53 383.218L334.503 486.067C335.11 486.408 335.742 486.703 336.393 486.949C336.669 487.055 336.956 487.15 337.242 487.235C339.06 487.793 340.971 487.977 342.862 487.777C344.752 487.576 346.582 486.994 348.242 486.067L543.849 376.06L557.121 368.594L633.132 325.846C633.254 325.778 633.387 325.734 633.525 325.717C633.664 325.7 633.804 325.711 633.938 325.749C634.072 325.786 634.197 325.85 634.307 325.936C634.417 326.021 634.508 326.128 634.576 326.25C634.645 326.371 634.688 326.504 634.705 326.643C634.722 326.781 634.711 326.921 634.674 327.055C634.636 327.189 634.573 327.315 634.487 327.424C634.401 327.534 634.294 327.626 634.173 327.694Z" fill="#3F3D56" />
        <path d="M217.438 204.092H187.709C185.456 204.092 183.296 203.197 181.703 201.604C180.11 200.011 179.215 197.849 179.215 195.596C179.215 193.343 180.11 191.182 181.703 189.588C183.296 187.995 185.456 187.1 187.709 187.1H217.438C219.691 187.1 221.851 187.995 223.444 189.588C225.037 191.182 225.932 193.343 225.932 195.596C225.932 197.849 225.037 200.011 223.444 201.604C221.851 203.197 219.691 204.092 217.438 204.092Z" fill="#00441F" />
        <path d="M252.476 232.766H187.709C185.456 232.766 183.296 231.871 181.703 230.278C180.11 228.684 179.215 226.523 179.215 224.27C179.215 222.016 180.11 219.855 181.703 218.262C183.296 216.669 185.456 215.773 187.709 215.773H252.476C254.729 215.773 256.889 216.669 258.482 218.262C260.075 219.855 260.97 222.016 260.97 224.27C260.97 226.523 260.075 228.684 258.482 230.278C256.889 231.871 254.729 232.766 252.476 232.766Z" fill="#E6E6E6" />
        <path d="M433.426 309.235H249.742C247.489 309.235 245.329 308.34 243.736 306.746C242.143 305.153 241.248 302.992 241.248 300.739C241.248 298.485 242.143 296.324 243.736 294.731C245.329 293.137 247.489 292.242 249.742 292.242H433.426C435.679 292.242 437.839 293.137 439.432 294.731C441.025 296.324 441.92 298.485 441.92 300.739C441.92 302.992 441.025 305.153 439.432 306.746C437.839 308.34 435.679 309.235 433.426 309.235Z" fill="#00441F" />
        <path d="M450.413 342.159H231.691C229.439 342.159 227.278 341.264 225.685 339.67C224.092 338.077 223.197 335.916 223.197 333.662C223.197 331.409 224.092 329.248 225.685 327.655C227.278 326.061 229.439 325.166 231.691 325.166H450.413C452.666 325.166 454.826 326.061 456.419 327.655C458.012 329.248 458.907 331.409 458.907 333.662C458.907 335.916 458.012 338.077 456.419 339.67C454.826 341.264 452.666 342.159 450.413 342.159Z" fill="#E6E6E6" />
        <path d="M450.413 374.02H231.691C229.439 374.02 227.278 373.125 225.685 371.532C224.092 369.938 223.197 367.777 223.197 365.524C223.197 363.27 224.092 361.109 225.685 359.516C227.278 357.922 229.439 357.027 231.691 357.027H450.413C452.666 357.027 454.826 357.922 456.419 359.516C458.012 361.109 458.907 363.27 458.907 365.524C458.907 367.777 458.012 369.938 456.419 371.532C454.826 373.125 452.666 374.02 450.413 374.02Z" fill="#E6E6E6" />
      </g>
      <path d="M42.8582 227.668H7.75529C5.69917 227.666 3.72794 226.848 2.27405 225.394C0.820157 223.94 0.0023335 221.969 0 219.913V184.81C0.0023335 182.754 0.820157 180.783 2.27405 179.329C3.72794 177.875 5.69917 177.057 7.75529 177.055H42.8582C44.9143 177.057 46.8855 177.875 48.3394 179.329C49.7933 180.783 50.6111 182.754 50.6134 184.81V219.913C50.6111 221.969 49.7933 223.94 48.3394 225.394C46.8855 226.848 44.9143 227.666 42.8582 227.668ZM7.75529 178.687C6.13203 178.689 4.57578 179.335 3.42797 180.483C2.28015 181.63 1.6345 183.187 1.63269 184.81V219.913C1.6345 221.536 2.28015 223.092 3.42797 224.24C4.57578 225.388 6.13203 226.034 7.75529 226.035H42.8582C44.4814 226.034 46.0377 225.388 47.1855 224.24C48.3333 223.092 48.9789 221.536 48.9808 219.913V184.81C48.9789 183.187 48.3333 181.63 47.1855 180.483C46.0377 179.335 44.4814 178.689 42.8582 178.687H7.75529Z" fill="#CCCCCC" />
      <path d="M36.7306 211.346H13.8729C13.2236 211.346 12.601 211.087 12.1419 210.628C11.6828 210.169 11.4245 209.547 11.4238 208.897V195.836C11.4245 195.186 11.6828 194.564 12.1419 194.105C12.601 193.646 13.2236 193.387 13.8729 193.387H36.7306C37.3799 193.387 38.0024 193.646 38.4615 194.105C38.9206 194.564 39.1789 195.186 39.1796 195.836V208.897C39.1789 209.547 38.9206 210.169 38.4615 210.628C38.0024 211.087 37.3799 211.346 36.7306 211.346ZM13.8729 195.019C13.6564 195.02 13.4489 195.106 13.2959 195.259C13.1428 195.412 13.0567 195.619 13.0565 195.836V208.897C13.0567 209.114 13.1428 209.321 13.2959 209.474C13.4489 209.627 13.6564 209.713 13.8729 209.714H36.7306C36.947 209.713 37.1545 209.627 37.3076 209.474C37.4606 209.321 37.5467 209.114 37.5469 208.897V195.836C37.5467 195.619 37.4606 195.412 37.3076 195.259C37.1545 195.106 36.947 195.02 36.7306 195.019H13.8729Z" fill="#CCCCCC" />
      <path d="M25.3098 204.514C24.8512 204.514 24.4015 204.386 24.012 204.144L11.8155 196.521C11.6325 196.406 11.5025 196.223 11.4542 196.013C11.4059 195.802 11.4432 195.581 11.5578 195.397C11.6724 195.214 11.855 195.084 12.0656 195.035C12.2762 194.986 12.4976 195.023 12.6812 195.137L24.8778 202.759C25.0077 202.84 25.1575 202.882 25.3103 202.882C25.4631 202.882 25.6129 202.84 25.7428 202.759L37.9385 195.137C38.1221 195.023 38.3435 194.986 38.5541 195.035C38.7647 195.084 38.9473 195.214 39.062 195.397C39.1766 195.581 39.2138 195.802 39.1655 196.013C39.1172 196.223 38.9873 196.406 38.8043 196.521L26.6085 204.144C26.2187 204.386 25.7688 204.514 25.3098 204.514Z" fill="#CCCCCC" />
      <circle cx="47.1027" cy="181.978" r="11.9504" fill="#00441F" />
      <path d="M473.577 67.9889H432.171C429.746 67.9861 427.421 67.0215 425.706 65.3065C423.991 63.5915 423.026 61.2663 423.023 58.841V17.435C423.026 15.0096 423.991 12.6845 425.706 10.9695C427.421 9.25454 429.746 8.28986 432.171 8.28711H473.577C476.003 8.28986 478.328 9.25454 480.043 10.9695C481.758 12.6845 482.722 15.0096 482.725 17.435V58.841C482.722 61.2663 481.758 63.5915 480.043 65.3065C478.328 67.0215 476.003 67.9861 473.577 67.9889ZM432.171 10.213C430.257 10.2151 428.421 10.9767 427.067 12.3306C425.713 13.6845 424.951 15.5202 424.949 17.435V58.841C424.951 60.7558 425.713 62.5915 427.067 63.9454C428.421 65.2993 430.257 66.0609 432.171 66.063H473.577C475.492 66.0609 477.328 65.2993 478.682 63.9454C480.036 62.5915 480.797 60.7558 480.799 58.841V17.435C480.797 15.5202 480.036 13.6845 478.682 12.3306C477.328 10.9767 475.492 10.2151 473.577 10.213H432.171Z" fill="#CCCCCC" />
      <path d="M466.351 48.7314H439.389C438.623 48.7305 437.889 48.4259 437.347 47.8843C436.805 47.3428 436.501 46.6085 436.5 45.8426V30.4357C436.501 29.6698 436.805 28.9355 437.347 28.3939C437.889 27.8523 438.623 27.5477 439.389 27.5469H466.351C467.117 27.5477 467.851 27.8523 468.393 28.3939C468.934 28.9355 469.239 29.6698 469.24 30.4357V45.8426C469.239 46.6085 468.934 47.3428 468.393 47.8843C467.851 48.4259 467.117 48.7305 466.351 48.7314ZM439.389 29.4727C439.133 29.473 438.889 29.5745 438.708 29.7551C438.528 29.9356 438.426 30.1804 438.426 30.4357V45.8426C438.426 46.0979 438.528 46.3427 438.708 46.5232C438.889 46.7037 439.133 46.8053 439.389 46.8055H466.351C466.606 46.8053 466.851 46.7037 467.032 46.5232C467.212 46.3427 467.314 46.0979 467.314 45.8426V30.4357C467.314 30.1804 467.212 29.9356 467.032 29.7551C466.851 29.5745 466.606 29.473 466.351 29.4727H439.389Z" fill="#CCCCCC" />
      <path d="M452.878 40.6823C452.337 40.683 451.806 40.532 451.347 40.2464L436.96 31.2551C436.744 31.1194 436.591 30.9035 436.534 30.655C436.477 30.4064 436.521 30.1454 436.656 29.9291C436.791 29.7129 437.007 29.5591 437.255 29.5015C437.504 29.4439 437.765 29.4871 437.981 29.6217L452.368 38.613C452.521 38.708 452.698 38.7583 452.878 38.7583C453.058 38.7583 453.235 38.708 453.388 38.613L467.774 29.6217C467.991 29.4871 468.252 29.4439 468.5 29.5015C468.749 29.5591 468.964 29.7129 469.099 29.9291C469.234 30.1454 469.278 30.4064 469.221 30.655C469.164 30.9035 469.011 31.1194 468.795 31.2551L454.41 40.2464C453.95 40.5321 453.419 40.6832 452.878 40.6823Z" fill="#CCCCCC" />
      <circle cx="478.581" cy="14.0963" r="14.0963" fill="#00441F" />
      <path d="M104.256 134.386H54.2827C51.3556 134.383 48.5493 133.218 46.4795 131.149C44.4098 129.079 43.2455 126.272 43.2422 123.345V73.3725C43.2455 70.4454 44.4098 67.6392 46.4795 65.5694C48.5493 63.4996 51.3556 62.3354 54.2827 62.332H104.256C107.183 62.3354 109.989 63.4996 112.059 65.5694C114.128 67.6392 115.293 70.4454 115.296 73.3725V123.345C115.293 126.272 114.128 129.079 112.059 131.149C109.989 133.218 107.183 134.383 104.256 134.386ZM54.2827 64.6563C51.9718 64.6589 49.7563 65.5781 48.1223 67.2121C46.4882 68.8461 45.5691 71.0617 45.5665 73.3725V123.345C45.5691 125.656 46.4882 127.872 48.1223 129.506C49.7563 131.14 51.9718 132.059 54.2827 132.062H104.256C106.566 132.059 108.782 131.14 110.416 129.506C112.05 127.872 112.969 125.656 112.972 123.345V73.3725C112.969 71.0617 112.05 68.8461 110.416 67.2121C108.782 65.5781 106.566 64.6589 104.256 64.6563H54.2827Z" fill="#CCCCCC" />
      <path d="M95.5347 111.15H62.9943C62.0699 111.149 61.1837 110.781 60.5301 110.127C59.8765 109.474 59.5088 108.587 59.5078 107.663V89.0685C59.5088 88.1441 59.8765 87.2579 60.5301 86.6043C61.1837 85.9507 62.0699 85.583 62.9943 85.582H95.5347C96.4591 85.583 97.3453 85.9507 97.9989 86.6043C98.6526 87.2579 99.0202 88.1441 99.0212 89.0685V107.663C99.0202 108.587 98.6526 109.474 97.9989 110.127C97.3453 110.781 96.4591 111.149 95.5347 111.15ZM62.9943 87.9063C62.6862 87.9067 62.3908 88.0292 62.1729 88.2471C61.955 88.465 61.8324 88.7604 61.8321 89.0685V107.663C61.8324 107.971 61.955 108.267 62.1729 108.484C62.3908 108.702 62.6862 108.825 62.9943 108.825H95.5347C95.8429 108.825 96.1383 108.702 96.3562 108.484C96.5741 108.267 96.6966 107.971 96.6969 107.663V89.0685C96.6966 88.7604 96.5741 88.465 96.3562 88.2471C96.1383 88.0292 95.8429 87.9067 95.5347 87.9063H62.9943Z" fill="#CCCCCC" />
      <path d="M79.27 101.428C78.617 101.429 77.9769 101.247 77.4223 100.902L60.0593 90.0508C59.7987 89.887 59.6138 89.6265 59.545 89.3265C59.4762 89.0265 59.5293 88.7115 59.6925 88.4505C59.8556 88.1896 60.1156 88.0039 60.4154 87.9344C60.7152 87.8648 61.0304 87.917 61.2918 88.0795L78.6549 98.931C78.8399 99.0456 79.0531 99.1064 79.2706 99.1064C79.4882 99.1064 79.7014 99.0456 79.8863 98.931L97.2484 88.0795C97.5097 87.917 97.8249 87.8648 98.1247 87.9344C98.4245 88.0039 98.6845 88.1896 98.8477 88.4505C99.0108 88.7115 99.0638 89.0265 98.9951 89.3265C98.9263 89.6265 98.7414 89.887 98.4809 90.0508L81.1189 100.902C80.5639 101.247 79.9234 101.429 79.27 101.428Z" fill="#CCCCCC" />
      <circle cx="110.292" cy="69.3467" r="17.0127" fill="#00441F" />
      <path d="M654.339 179.662H594.372C590.859 179.658 587.492 178.261 585.008 175.777C582.524 173.293 581.127 169.926 581.123 166.413V106.446C581.127 102.933 582.524 99.5658 585.008 97.0821C587.492 94.5984 590.859 93.2013 594.372 93.1973H654.339C657.852 93.2013 661.219 94.5984 663.703 97.0821C666.187 99.5658 667.584 102.933 667.588 106.446V166.413C667.584 169.926 666.187 173.293 663.703 175.777C661.219 178.261 657.852 179.658 654.339 179.662ZM594.372 95.9864C591.599 95.9895 588.94 97.0925 586.979 99.0534C585.018 101.014 583.915 103.673 583.912 106.446V166.413C583.915 169.186 585.018 171.845 586.979 173.806C588.94 175.767 591.599 176.87 594.372 176.873H654.339C657.112 176.87 659.771 175.767 661.732 173.806C663.692 171.845 664.795 169.186 664.799 166.413V106.446C664.795 103.673 663.692 101.014 661.732 99.0534C659.771 97.0925 657.112 95.9895 654.339 95.9864H594.372Z" fill="#CCCCCC" />
      <path d="M643.875 151.769H604.826C603.717 151.768 602.654 151.327 601.869 150.542C601.085 149.758 600.644 148.694 600.643 147.585V125.272C600.644 124.162 601.085 123.099 601.869 122.315C602.654 121.53 603.717 121.089 604.826 121.088H643.875C644.984 121.089 646.048 121.53 646.832 122.315C647.616 123.099 648.057 124.162 648.059 125.272V147.585C648.057 148.694 647.616 149.758 646.832 150.542C646.048 151.327 644.984 151.768 643.875 151.769ZM604.826 123.877C604.457 123.877 604.102 124.024 603.841 124.286C603.579 124.547 603.432 124.902 603.432 125.272V147.585C603.432 147.955 603.579 148.309 603.841 148.571C604.102 148.832 604.457 148.979 604.826 148.98H643.875C644.245 148.979 644.599 148.832 644.861 148.571C645.122 148.309 645.269 147.955 645.269 147.585V125.272C645.269 124.902 645.122 124.547 644.861 124.286C644.599 124.024 644.245 123.877 643.875 123.877H604.826Z" fill="#CCCCCC" />
      <path d="M624.358 140.114C623.574 140.115 622.806 139.896 622.14 139.482L601.305 126.461C600.992 126.264 600.77 125.951 600.688 125.591C600.605 125.231 600.669 124.853 600.865 124.54C601.06 124.227 601.372 124.004 601.732 123.921C602.092 123.837 602.47 123.9 602.784 124.095L623.62 137.117C623.841 137.254 624.097 137.327 624.358 137.327C624.619 137.327 624.875 137.254 625.097 137.117L645.932 124.095C646.245 123.9 646.623 123.837 646.983 123.921C647.343 124.004 647.655 124.227 647.851 124.54C648.047 124.853 648.11 125.231 648.028 125.591C647.945 125.951 647.723 126.264 647.411 126.461L626.576 139.482C625.91 139.896 625.142 140.115 624.358 140.114Z" fill="#CCCCCC" />
      <circle cx="661.583" cy="101.603" r="20.4153" fill="#00441F" />
      <defs>
        <clipPath id="clip0_5629_26029">
          <rect width="595.645" height="523.591" fill="white" transform="translate(43.2305 133.996)" />
        </clipPath>
      </defs>
    </svg>

  )
}

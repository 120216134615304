import React, { ReactNode } from 'react'
import { ReactSVG } from 'react-svg'

type ProductItemDescriptionProps = {
  name: string
  description?: string
}

type ProductItemProps = {
  icon?: string
} & ProductItemDescriptionProps

const ProductItemWrapper = ({ children }: { children: ReactNode }) => {
  return <div className='product-item__item-wrapper'>{children}</div>
}

const ProductItemDescription = ({ name, description }: ProductItemDescriptionProps) => {
  return (
    <div className='product-item__item'>
      <h5 className='product-item__item-name'>{name}</h5>
      {description && <p className='product-item__item-description'>{description}</p>}
    </div>
  )
}

const ProductItem = ({ icon, name, description }: ProductItemProps) => (
  <ProductItemWrapper>
    {icon
      ? (
        <figure className='product-item__item-image'>
          <ReactSVG
            src={icon}
            alt="icone de produto no sistema uxline"
            beforeInjection={(svg) => {
              svg.classList.add('product-item-svg')
            }}
          />
        </figure>
      ) : <figure className='product-item__item-image product-item__item-image--backgroud' />
    }

    <ProductItemDescription
      name={name}
      description={description}
    />
  </ProductItemWrapper>
)

export { ProductItemDescription, ProductItemWrapper }
export default ProductItem

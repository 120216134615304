import React, { Fragment, useEffect, useState } from 'react'
import { Button, Tooltip, Form, Row } from 'antd'
import { LoadingOutlined, PrinterOutlined } from '@ant-design/icons'
// import { Worker, Viewer } from '@react-pdf-viewer/core'
import SignatureCanvas from 'react-signature-canvas'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/modules/rootReducer'
import proposals from 'egi/consumers/proposals'
import { validateResponse } from 'utils/validate'
import swal from 'utils/swal'
import { useStep } from 'hooks'
import ReactDOM from 'react-dom'

import { ISignature, IModalContract } from './types'
import { proposalLoadingStep } from 'store/modules/proposal/actions'
import StringToHtml from 'components/StringToHtml/StringToHtml'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'
import { PdfPreview } from 'components'

const ModalContract = ({ areaId, formRef, setStatus, setVisible, fetchProposals }: IModalContract) => {
  const [showInputSignature, setShowInputSignature] = useState(true)
  const [errors, setErrors] = useState<ISignature>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [contract, setContract] = useState<string>('')
  const [hasSignature, setHasSignature] = useState(false)

  const user = useSelector((state: RootState) => state.auth)
  const client = useSelector((state: RootState) => state.client)
  const proposal = useSelector((state: RootState) => state.proposal)
  const step = useStep()

  const [signatureRef, setSignatureRef] = useState<any>({})

  const IS_CLIENT = user.level === 'client' || user.level === 'promoter'
  const dispatch = useDispatch()

  const handleContract = async () => {
    type Contract = {
      file: any
      contract: string
      stepId: string | undefined
      activeMode?: string
      clientId?: string
    }

    const data: Contract = {
      file: signatureRef.toDataURL(),
      contract,
      stepId: step.id
    }

    if (user.level === 'promoter') data.clientId = String(client.id)
    if (step.modeOptions && step.modeOptions.selected) data.activeMode = step.modeOptions.selected

    if (proposal.id) {
      try {
        setStatus('sending')
        const response = await proposals.sendContract({ proposalId: proposal.id, areaId, data })
        if (step.id) dispatch(proposalLoadingStep({ stepId: step.id, loading: true }))
        swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
        fetchProposals()
        setVisible(false)
        setErrors({})
        setStatus('')
      } catch (err) {
        if (err.data?.invalid) {
          let errors: ISignature = {}
          errors = validateResponse(err.data?.invalid)
          setErrors(errors)
          setStatus('')

          const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
          invalidFieldsError({ invalidFields: invalidFields })
          return
        }
        setStatus('')
        setErrors({})
        swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      }
    }
  }

  const resetFields = () => {
    formRef?.resetFields()
  }

  async function getPreview () {
    if (!proposal.id) return
    try {
      setLoading(true)
      const response = await proposals.preview({ proposalId: proposal.id, stepId: step._id, areaId: step.areaId })
      setContract(response.data.contract)
      setLoading(false)
    } catch (err) {
      console.error(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (step.signatures && step.signatures.length && step.signatures[0].contract && (step.status !== 'remaking')) {
      const contract = step.signatures[0].contract
      setContract(contract.signed)
      setShowInputSignature(false)
    } else {
      getPreview()
      setShowInputSignature(true)
    }

    setErrors({})
    resetFields()
    setHasSignature(false)
  }, [step])

  useEffect(() => {
    if (step.signatures && step.signatures.length && step.signatures[0].contract && (step.status !== 'remaking')) {
      const signature = step.signatures[0]
      const signatureInput = document.getElementById('signatureInput')
      const signatureCanvas = (<img className="w-100 mb-3" src={signature.sign} />)

      if (signatureInput) {
        setHasSignature(false)
        ReactDOM.render(signatureCanvas, signatureInput)
      } else {
        setHasSignature(true)
      }
    }
  })

  async function printContract () {
    const width = window.innerWidth
    const height = window.innerHeight
    const page = await window.open('', '_blank', `channelmode=yes,resizable=yes,width=${width},height=${height}`)

    if (page) {
      const contractHtml = document.getElementById('contract')?.innerHTML

      if (contractHtml) {
        page.document.body.innerHTML = contractHtml
        setTimeout(() => {
          page.window.print()
          page.window.onafterprint = () => page.window.close()
        }, 500)
      }
    }
  }

  return (
    <div>
      {loading ? (
        <Row justify="center">
          <LoadingOutlined />
        </Row>
      ) : (
        <Fragment>
          {step.files?.length ? (
            <PdfPreview src={step.files[0].file} height='750px' />
          ) : <StringToHtml value={contract} />}

          {(showInputSignature && IS_CLIENT) && (
            <Fragment>
              <div className="contract-container">
                <p style={{ fontSize: '1.2em' }}><b>ASSINE ABAIXO</b></p>

                <div className="contract-container__input">
                  <Form
                    form={formRef}
                    onFinish={handleContract}
                    className="w-100"
                  >
                    <Form.Item
                      name="signature"
                      help={errors.signature && errors.signature}
                      validateStatus={errors.signature && 'error'}
                    >
                      <div className='signature-input'>
                        <SignatureCanvas
                          canvasProps={{ style: { width: '100%' } }}
                          ref={(ref) => setSignatureRef(ref)}
                        />
                      </div>

                      <Button
                        className="mt-3"
                        type="primary"
                        htmlType="button"
                        onClick={() => { signatureRef.clear() }}
                      >
                        Limpar
                      </Button>
                    </Form.Item>
                  </Form>
                </div>

                <label style={{ fontSize: '10px' }} className="mb-4">
                  Declaro que li e estou ciente dos termos acima.
                </label>

              </div>
            </Fragment>
          )}
        </Fragment>
      )}

      <div>
        {hasSignature && step.signatures && step.signatures.length ? <img src={step.signatures[0]?.sign} /> : <></>}

        <Tooltip title="Imprimir" destroyTooltipOnHide={{ keepParent: false }}>
          <Button
            onClick={() => printContract()}
            icon={<PrinterOutlined />}
            className="m-0"
            type="ghost"
          />
        </Tooltip>
      </div>
    </div>
  )
}

export default ModalContract

import { ArrowRightOutlined, PoweroffOutlined, ReloadOutlined } from '@ant-design/icons'
import { Button, Space } from 'antd'
import { MaintananceModalText } from 'components/MaintenanceModal/MaintanceModal'
import DynamicColorButton from 'ecp/components/DynamicColorButton/DynamicColorButton'
import ProductModel from 'ecp/models/ProductModel'
import { FullScreenProducts } from 'layouts/FullScreenLoading/FullScreenLoading'
import React from 'react'

type Props = {
  logo: string
  message: string
  onClick: () => void
  loading: boolean
  onExit: () => void
  onEnter: () => void
}
export default function FullScreenError ({ onClick, onExit, loading, onEnter }: Props) {
  return (
    <div className='full-screen-error'>

      <div className='full-screen-error__card mb-3'>
        <MaintananceModalText />
      </div>

      <div className='full-screen-error__card mb-3'>
        <FullScreenProducts />
      </div>

      <Space>
        <Button
          loading={loading}
          type='primary'
          onClick={onClick}
          icon={<ReloadOutlined />}
        >
          Tentar novamente
        </Button>

        <DynamicColorButton
          colorClass='button-red'
          onClick={onExit}
          type='ghost'
          icon={<PoweroffOutlined />}
        >
          Sair do sistema
        </DynamicColorButton>

        {ProductModel.canShowSkipFullScreenError() &&
          <DynamicColorButton
            colorClass='button-green'
            onClick={onEnter}
            type='ghost'
            icon={<ArrowRightOutlined />}
          >
            Entrar no sistema
          </DynamicColorButton>
        }
      </Space>
    </div>
  )
}

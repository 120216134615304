import React from 'react'

export default function UserInformationSVG ({ className = '', size }: { className?: string, size?: number }) {
  return (
    <svg className={className} width={size || '250'} height={size || '250'} viewBox="0 0 1620 1620" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6010_25801)">
        <path d="M824.495 1057.52C969.714 1057.52 1087.44 939.794 1087.44 794.575C1087.44 649.356 969.714 531.633 824.495 531.633C679.276 531.633 561.553 649.356 561.553 794.575C561.553 939.794 679.276 1057.52 824.495 1057.52Z" fill="#FFB6B6"/>
        <path d="M673.548 1096.4L661.669 976.319L898.887 931.963L1038.96 1273.02L795.353 1553.17L621.781 1212.11L673.548 1096.4Z" fill="#FFB6B6"/>
        <path d="M657.857 1036.43L709.719 1048.14C709.719 1048.14 668.951 918.866 682.788 906.523C696.626 894.181 730.536 913.628 730.536 913.628L766.17 953.58L809.575 909.388C809.575 909.388 856.698 850.794 875.148 834.337C893.598 817.881 863.963 756.798 863.963 756.798C863.963 756.798 1137.69 682.987 1035.84 550.218C1035.84 550.218 976.121 446.123 956.673 480.032C937.226 513.942 914.037 460.086 914.037 460.086L843.226 473.552C843.226 473.552 703.473 391.15 570.831 567.431C438.189 743.713 657.857 1036.43 657.857 1036.43Z" fill="#2F2E41"/>
        <path d="M1319.02 1440.19C1175.32 1556.69 997.548 1620 809.999 1620C638.742 1620 475.616 1567.2 339.164 1469.33C339.408 1466.77 339.651 1464.24 339.865 1461.72C343.488 1422.13 346.716 1383.64 348.847 1351.73C357.1 1227.82 646.172 1145.24 646.172 1145.24C646.172 1145.24 647.482 1146.55 650.101 1148.83C666.057 1162.84 730.826 1213.63 844.409 1227.82C945.69 1240.49 979.034 1180.41 989.113 1151.63C992.158 1142.86 993.072 1136.98 993.072 1136.98L1290.4 1277.39C1309.79 1305.11 1317.38 1364.79 1318.87 1431.87C1318.93 1434.64 1318.99 1437.38 1319.02 1440.19Z" fill="#00441F"/>
        <path d="M1382.76 237.245C1229.77 84.2582 1026.36 0 810 0C593.645 0 390.231 84.2582 237.245 237.245C84.2584 390.231 0 593.645 0 810C0 1007.48 70.19 1194.17 198.816 1341.59C211.087 1355.68 223.907 1369.42 237.245 1382.76C240.381 1385.89 243.548 1389.03 246.746 1392.07C255.272 1400.33 263.951 1408.4 272.781 1416.22C278.141 1421 283.561 1425.69 289.042 1430.29C293.336 1433.91 297.63 1437.48 301.984 1440.98C302.075 1441.07 302.136 1441.13 302.228 1441.19C314.317 1450.94 326.619 1460.31 339.165 1469.33C475.616 1567.2 638.743 1620 810 1620C997.549 1620 1175.32 1556.69 1319.02 1440.19C1330.74 1430.69 1342.22 1420.82 1353.49 1410.62C1363.45 1401.6 1373.19 1392.32 1382.76 1382.76C1385.77 1379.74 1388.78 1376.7 1391.74 1373.62C1399.96 1365.15 1407.94 1356.54 1415.73 1347.8C1547.8 1199.44 1620 1010.28 1620 810C1620 593.645 1535.74 390.231 1382.76 237.245ZM1413.75 1340.22C1406.14 1348.86 1398.35 1357.39 1390.37 1365.7C1377.94 1378.64 1365.12 1391.19 1351.88 1403.28C1346.85 1407.91 1341.77 1412.41 1336.62 1416.89C1330.78 1421.98 1324.87 1426.97 1318.87 1431.87C1271.58 1470.7 1219.78 1504.19 1164.39 1531.48C1142.5 1542.29 1120.05 1552.12 1097.09 1560.92C1007.9 1595.15 911.098 1613.91 810 1613.91C712.435 1613.91 618.889 1596.43 532.316 1564.46C496.78 1551.36 462.431 1535.8 429.452 1517.99C424.611 1515.37 419.799 1512.72 415.019 1510.01C389.866 1495.76 365.535 1480.2 342.18 1463.39C341.418 1462.84 340.626 1462.26 339.865 1461.72C323.239 1449.72 307.069 1437.08 291.448 1423.8C285.723 1418.96 280.059 1414.06 274.487 1409.03C265.412 1400.97 256.52 1392.65 247.842 1384.13C231.398 1368.02 215.685 1351.24 200.673 1333.79C79.4471 1192.98 6.09023 1009.91 6.09023 810C6.09023 366.723 366.723 6.09023 810 6.09023C1253.28 6.09023 1613.91 366.723 1613.91 810C1613.91 1012.99 1538.3 1198.62 1413.75 1340.22Z" fill="#656565"/>
      </g>
      <defs>
        <clipPath id="clip0_6010_25801">
          <rect width="1620" height="1620" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  )
}

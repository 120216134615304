import { LoadingOutlined } from '@ant-design/icons'
import VerifyEmailSuccesSVG from 'assets/reactSvgs/VerifyEmailSuccesSVG/VerifyEmailSuccesSVG'
import WarningSVG from 'assets/reactSvgs/WarningSvg/WarningSVG'

import EmailRepository from 'egi/repositories/EmailRepository'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

const ICONS = {
  error: <WarningSVG className="internal-change-email__icon"/>,
  loading: <LoadingOutlined className="internal-change-email__loading"/>,
  success: <VerifyEmailSuccesSVG className="internal-change-email__icon"/>
}

export const useInternalChangeEmailValidate = () => {
  const history = useHistory()
  const query = queryString.parse(history.location.search)

  const [title, setTitle] = useState<string>('')
  const [text, setText] = useState<string>('')
  const [icon, setIcon] = useState<keyof typeof ICONS>('loading')

  useEffect(() => {
    async function requestChange () {
      try {
        if (!query.token) throw new Error('Falha ao encontrar token!')

        const response = await EmailRepository.changeValidate({ params: { token: query.token.toString() } })
        setTitle('Sucesso!')
        setText(response.data?.message || '')
        setIcon('success')
      } catch (err) {
        setTitle('Atenção!')
        setText(err.message || '')
        setIcon('error')
      }
    }

    requestChange()
  }, [])

  return {
    title,
    text,
    icon: ICONS[icon]
  }
}

export const useInternalChangeEmailVerify = () => {
  const history = useHistory()
  const query = queryString.parse(history.location.search)

  const [title, setTitle] = useState<string>('')
  const [text, setText] = useState<string>('')
  const [icon, setIcon] = useState<keyof typeof ICONS>('loading')
  useEffect(() => {
    async function requestChange () {
      try {
        if (!query.token) throw new Error('Falha ao encontrar token!')

        const requestConfig = {
          params: { token: query.token.toString() }
        }

        const response = await EmailRepository.verify(requestConfig)
        setTitle('Sucesso!')
        setText(response.data?.message || '')
        setIcon('success')
      } catch (err) {
        setTitle('Atenção!')
        setText(err?.message || '')
        setIcon('error')
      }
    }

    requestChange()
  }, [])

  return {
    title,
    text,
    icon: ICONS[icon]
  }
}

import loadable from '@loadable/component'
import Loading from 'components/Loading/Loading'
import { timeout } from 'promise-timeout'
import React from 'react'
import { TIMEOUT_LOADER } from 'utils/globals'

const ClientList = loadable(
  () => timeout(import(/* webpackChunkName: "Clients" */ 'ecp/app/Clients/views/ClientList/ClientList'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const ClientDetails = loadable(
  () => timeout(import(/* webpackChunkName: "Clients" */ 'ecp/app/Clients/views/ClientDetails/ClientDetails'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const ClientImport = loadable(
  () => timeout(import(/* webpackChunkName: "Clients" */ 'ecp/app/Clients/views/ClientImport/ClientImport'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const ClientPendingList = loadable(
  () => timeout(import(/* webpackChunkName: "Clients" */ 'ecp/app/Clients/views/ClientPendingList/ClientPendingList'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const ClientPendingListOnidata = loadable(
  () => timeout(import(/* webpackChunkName: "Clients" */ 'ecp/app/Clients/views/ClientPendingListOnidata/ClientPendingListOnidata'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const ClientForm = loadable(
  () => timeout(import(/* webpackChunkName: "Clients" */ 'ecp/app/Clients/views/ClientForm/ClientForm'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const ClientDismiss = loadable(
  () => timeout(import(/* webpackChunkName: "Clients" */ 'ecp/app/Clients/views/ClientDismiss/ClientDismiss'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const ClientNotification = loadable(
  () => timeout(import(/* webpackChunkName: "Clients" */ 'ecp/app/Clients/views/ClientNotification/ClientNotification'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const ClientTransfer = loadable(
  () => timeout(import(/* webpackChunkName: "Clients" */ 'ecp/app/Clients/views/ClientTransfer/ClientTransfer'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const ClientInitialPage = loadable(
  () => timeout(import(/* webpackChunkName: "Clients" */ 'ecp/app/Clients/views/ClientInitialPage/ClientInitialPage'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

export {
  ClientList,
  ClientDetails,
  ClientImport,
  ClientPendingList,
  ClientForm,
  ClientDismiss,
  ClientNotification,
  ClientTransfer,
  ClientInitialPage,
  ClientPendingListOnidata
}

import useProgressQueue from 'hooks/useProgressQueue'
import React from 'react'
import ProgressQueueCardController from './components/ProgressQueueCardController/ProgressQueueCardController'

export default function ProgressQueueContainer () {
  const {
    progressQueue
  } = useProgressQueue()
  const { progressQueueList } = progressQueue

  return (
    <div className='progress-queue pr-2'>
      {Array.isArray(progressQueueList) && progressQueueList.map((item, idx) => {
        if (item.isOpen) {
          return (
            <ProgressQueueCardController
              itemIndex={idx}
              key={item.progressCheckId}
              queueItem={item}
              queueList={progressQueueList}
            />
          )
        } else return <></>
      })}
    </div>
  )
}

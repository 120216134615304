import React from 'react'

export default function VerifyEmailSuccesSVG ({ className = '', isAnimated = false }: {className?: string, isAnimated?: boolean}) {
  const cssProps = {
    svg: `verify-email-succes-svg ${className}`,
    firstBaloon: isAnimated ? 'verify-email-succes-svg__first-baloon' : '',
    secondBaloon: isAnimated ? 'verify-email-succes-svg__second-baloon' : '',
    thirdBaloon: isAnimated ? 'verify-email-succes-svg__third-baloon' : ''
  }

  return (
    <svg className={cssProps.svg} width="485" height="297" viewBox="0 0 485 297" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.0665 296.489H104.136C104.201 296.489 104.263 296.463 104.309 296.417C104.355 296.371 104.381 296.309 104.381 296.244C104.381 296.179 104.355 296.117 104.309 296.072C104.263 296.026 104.201 296 104.136 296H11.0665C11.0018 296 10.9396 296.026 10.8938 296.072C10.848 296.117 10.8223 296.179 10.8223 296.244C10.8223 296.309 10.848 296.371 10.8938 296.417C10.9396 296.463 11.0018 296.489 11.0665 296.489Z" fill="#E6EDE9"/>
      <path d="M367.244 296.489H460.314C460.379 296.489 460.441 296.463 460.487 296.417C460.533 296.371 460.558 296.309 460.558 296.244C460.558 296.179 460.533 296.117 460.487 296.072C460.441 296.026 460.379 296 460.314 296H367.244C367.179 296 367.117 296.026 367.072 296.072C367.026 296.117 367 296.179 367 296.244C367 296.309 367.026 296.371 367.072 296.417C367.117 296.463 367.179 296.489 367.244 296.489Z" fill="#E6EDE9"/>
      <g clipPath="url(#clip0_4879_24963)">
        <path d="M367.496 147.648C367.4 147.648 367.306 147.618 367.227 147.563L236.945 56.8312C236.549 56.5563 236.078 56.4095 235.595 56.4103C235.113 56.4111 234.642 56.5596 234.247 56.8358L104.903 147.563C104.801 147.634 104.674 147.662 104.551 147.641C104.428 147.619 104.318 147.55 104.247 147.447C104.175 147.345 104.147 147.218 104.168 147.095C104.19 146.972 104.259 146.863 104.362 146.791L233.706 56.0643C234.259 55.6777 234.918 55.4699 235.594 55.4688C236.269 55.4676 236.929 55.6733 237.484 56.0581L367.766 146.79C367.848 146.847 367.91 146.93 367.942 147.025C367.975 147.119 367.976 147.222 367.946 147.318C367.916 147.414 367.856 147.497 367.775 147.557C367.694 147.616 367.597 147.648 367.496 147.648L367.496 147.648Z" fill="#45563D"/>
        <path d="M112.294 150.863L235.723 59.3867L360.089 157.372L242.083 227.328L178.016 212.724L112.294 150.863Z" fill="#E6EDE9"/>
        <path d="M183.423 270.537H122.446C122.072 270.538 121.701 270.465 121.355 270.322C121.009 270.179 120.694 269.969 120.429 269.704C120.165 269.44 119.954 269.126 119.811 268.78C119.668 268.434 119.594 268.063 119.594 267.689C119.594 267.314 119.668 266.943 119.811 266.598C119.954 266.252 120.165 265.938 120.429 265.673C120.694 265.408 121.009 265.199 121.355 265.056C121.701 264.913 122.072 264.839 122.446 264.84H183.423C183.798 264.839 184.169 264.913 184.515 265.056C184.861 265.199 185.175 265.408 185.44 265.673C185.705 265.938 185.915 266.252 186.059 266.598C186.202 266.943 186.276 267.314 186.276 267.689C186.276 268.063 186.202 268.434 186.059 268.78C185.915 269.126 185.705 269.44 185.44 269.704C185.175 269.969 184.861 270.179 184.515 270.322C184.169 270.465 183.798 270.538 183.423 270.537Z" fill="#00441F"/>
        <path d="M144.323 258.289H122.446C122.072 258.29 121.701 258.217 121.355 258.074C121.009 257.931 120.694 257.721 120.429 257.456C120.165 257.192 119.954 256.878 119.811 256.532C119.668 256.186 119.594 255.815 119.594 255.441C119.594 255.066 119.668 254.695 119.811 254.35C119.954 254.004 120.165 253.689 120.429 253.425C120.694 253.16 121.009 252.951 121.355 252.808C121.701 252.665 122.072 252.591 122.446 252.592H144.323C144.698 252.591 145.069 252.665 145.415 252.808C145.761 252.951 146.075 253.16 146.34 253.425C146.605 253.689 146.815 254.004 146.959 254.35C147.102 254.695 147.176 255.066 147.176 255.441C147.176 255.815 147.102 256.186 146.959 256.532C146.815 256.878 146.605 257.192 146.34 257.456C146.075 257.721 145.761 257.931 145.415 258.074C145.069 258.217 144.698 258.29 144.323 258.289Z" fill="#00441F"/>
        <path d="M237.46 201.815C236.996 201.815 236.537 201.725 236.108 201.55L159.985 169.965V71.0964C159.986 70.2221 160.334 69.384 160.952 68.7658C161.57 68.1476 162.409 67.7998 163.283 67.7988H309.319C310.193 67.7998 311.031 68.1476 311.649 68.7658C312.268 69.384 312.615 70.2222 312.616 71.0964V170.034L312.473 170.095L238.852 201.535C238.411 201.72 237.938 201.815 237.46 201.815Z" fill="white"/>
        <path d="M237.459 202.051C236.965 202.052 236.475 201.956 236.018 201.769L159.749 170.123V71.0976C159.75 70.1609 160.123 69.2628 160.785 68.6005C161.447 67.9381 162.345 67.5655 163.282 67.5645H309.318C310.255 67.5655 311.153 67.9381 311.815 68.6005C312.477 69.2628 312.85 70.1609 312.851 71.0976V170.19L238.944 201.752C238.474 201.95 237.969 202.052 237.459 202.051ZM160.691 169.494L236.377 200.898C237.083 201.185 237.873 201.18 238.576 200.885L311.909 169.568V71.0976C311.908 70.4107 311.635 69.7521 311.149 69.2664C310.663 68.7807 310.005 68.5074 309.318 68.5066H163.282C162.595 68.5074 161.937 68.7807 161.451 69.2664C160.965 69.7521 160.692 70.4107 160.691 71.0976L160.691 169.494Z" fill="#45563D"/>
        <path d="M236.698 173.78C233.888 173.786 231.152 172.877 228.903 171.191L228.764 171.087L199.41 148.612C198.051 147.571 196.91 146.272 196.053 144.789C195.195 143.306 194.638 141.67 194.414 139.972C194.189 138.274 194.301 136.549 194.743 134.894C195.185 133.24 195.948 131.688 196.99 130.329C198.032 128.969 199.331 127.828 200.813 126.971C202.296 126.114 203.933 125.557 205.631 125.332C207.328 125.107 209.054 125.219 210.708 125.661C212.363 126.103 213.914 126.867 215.274 127.909L234.287 142.488L279.218 83.8911C280.26 82.532 281.559 81.3915 283.042 80.5346C284.525 79.6778 286.162 79.1214 287.86 78.8973C289.558 78.6732 291.283 78.7857 292.937 79.2284C294.592 79.6711 296.143 80.4353 297.502 81.4775L297.223 81.8568L297.509 81.483C300.251 83.5899 302.044 86.6983 302.497 90.1261C302.949 93.5539 302.024 97.0212 299.923 99.7674L247.073 168.691C245.851 170.279 244.279 171.564 242.48 172.447C240.68 173.33 238.702 173.786 236.698 173.78Z" fill="#00441F"/>
        <path d="M366.555 146.705H366.46L312.38 169.798L238.387 201.393C238.098 201.515 237.788 201.578 237.474 201.58C237.161 201.581 236.85 201.521 236.559 201.403L160.22 169.732L104.811 146.743L104.727 146.705H104.633C103.758 146.706 102.92 147.054 102.302 147.672C101.684 148.29 101.336 149.128 101.335 150.003V293.212C101.336 294.086 101.684 294.924 102.302 295.543C102.92 296.161 103.758 296.509 104.633 296.51H366.555C367.429 296.509 368.267 296.161 368.885 295.543C369.504 294.924 369.851 294.086 369.852 293.212V150.003C369.851 149.128 369.504 148.29 368.885 147.672C368.267 147.054 367.429 146.706 366.555 146.705ZM368.91 293.212C368.91 293.837 368.662 294.436 368.22 294.877C367.778 295.319 367.179 295.567 366.555 295.567H104.633C104.008 295.567 103.409 295.319 102.967 294.877C102.526 294.436 102.277 293.837 102.277 293.212V150.003C102.278 149.394 102.514 148.808 102.937 148.369C103.359 147.93 103.934 147.672 104.543 147.647L160.22 170.749L236.197 202.274C237.019 202.609 237.941 202.604 238.759 202.26L312.38 170.82L366.649 147.647C367.257 147.673 367.831 147.933 368.252 148.372C368.673 148.81 368.909 149.395 368.91 150.003V293.212Z" fill="#45563D"/>
      </g>
      <path d="M31.3269 278.479C31.0562 278.048 30.7374 277.649 30.3766 277.287C30.2278 277.135 30.0708 276.992 29.909 276.853C28.925 275.991 27.7254 275.402 26.4336 275.148C25.1418 274.893 23.8045 274.982 22.5591 275.405C21.3137 275.828 20.2052 276.569 19.3476 277.554C18.49 278.538 17.9143 279.73 17.6795 281.006C16.5926 277.049 19.4221 273.151 22.2669 270.157C25.1133 267.164 28.3665 263.881 28.3501 259.78C28.3422 257.498 27.2082 255.462 25.496 253.953C25.4432 253.907 25.3904 253.861 25.3365 253.817C23.2671 252.071 20.6047 251.158 17.881 251.26C12.821 251.526 8.40286 254.874 5.33459 258.845C0.395828 265.234 -1.76834 274.203 1.68916 281.477C4.73032 287.873 11.2164 291.672 17.836 294.726C18.7603 295.153 19.6828 295.566 20.6034 295.967C20.6095 295.969 20.6161 295.973 20.6223 295.974C20.6666 295.994 20.7109 296.013 20.7524 296.033C20.9424 296.115 21.1325 296.198 21.3209 296.279L21.2336 296.335L20.9615 296.509C21.0558 296.452 21.1492 296.398 21.2436 296.342C21.2716 296.325 21.3013 296.309 21.3293 296.291C24.5805 294.371 27.8769 292.34 30.1229 289.342C32.4523 286.229 33.3889 281.77 31.3269 278.479Z" fill="#E6EDE9"/>
      <path d="M9.40725 270.902C8.6751 272.537 8.18974 274.267 7.96648 276.039C7.79084 277.619 7.85688 279.216 8.16242 280.777C8.81895 283.985 10.2507 286.99 12.3374 289.542C13.9116 291.479 15.729 293.213 17.7463 294.702C18.6863 295.135 19.6244 295.554 20.5606 295.961C20.5668 295.962 20.5735 295.966 20.5798 295.968C20.6248 295.988 20.6699 296.007 20.7122 296.027C20.9054 296.111 21.0987 296.194 21.2902 296.277L21.2015 296.334L20.9247 296.51C21.0207 296.453 21.1156 296.397 21.2116 296.34C21.2401 296.323 21.2703 296.307 21.2987 296.29C20.5711 293.792 20.4642 291.159 20.9868 288.613C21.5094 286.066 22.6464 283.679 24.3024 281.653C25.9674 279.652 28.0906 278.065 30.499 277.023C30.3478 276.869 30.1882 276.723 30.0237 276.582C29.096 277 28.2077 277.498 27.3694 278.07C24.3709 280.104 22.1194 283.031 20.9481 286.418C19.9024 289.491 19.7718 292.793 20.5716 295.937C20.4057 295.83 20.2391 295.721 20.0771 295.612C16.9971 293.574 14.1586 291.126 12.0204 288.124C10.1763 285.586 8.9962 282.643 8.58406 279.553C8.17426 276.177 9.0349 272.858 10.5705 269.846C12.2663 266.623 14.4031 263.644 16.9214 260.99C19.4509 258.262 22.311 255.849 25.4379 253.803C25.5083 253.756 25.5578 253.685 25.576 253.604C25.5943 253.522 25.58 253.437 25.536 253.366C25.5025 253.301 25.4442 253.251 25.3737 253.228C25.338 253.219 25.3006 253.218 25.2645 253.225C25.2283 253.233 25.1942 253.248 25.1648 253.27C24.7788 253.522 24.3961 253.777 24.018 254.04C20.8691 256.228 18.0094 258.792 15.5045 261.672C13.0841 264.448 10.8813 267.533 9.40725 270.902Z" fill="white"/>
      <path d="M96.8977 186.336C96.8539 186.534 96.8254 186.735 96.8124 186.938L81.4858 196.029L77.7605 193.824L73.7891 199.169L81.7586 205.01L99.2438 190.827C99.957 191.098 100.734 191.14 101.47 190.946C102.207 190.753 102.869 190.333 103.368 189.743C103.867 189.153 104.18 188.421 104.265 187.644C104.35 186.867 104.202 186.082 103.842 185.394C103.482 184.705 102.927 184.145 102.25 183.789C101.574 183.432 100.807 183.296 100.053 183.398C99.2996 183.501 98.5938 183.836 98.0301 184.361C97.4664 184.886 97.0715 185.575 96.8977 186.336Z" fill="#FFB8B8"/>
      <path d="M62.0316 192.232L74.234 201.074L79.6915 194.117L67.8297 184.441C67.3194 184.024 66.7308 183.718 66.1001 183.541C65.4694 183.364 64.8098 183.32 64.1617 183.411C63.5136 183.503 62.8907 183.728 62.331 184.072C61.7714 184.417 61.2869 184.874 60.9071 185.416C60.1672 186.471 59.87 187.783 60.0809 189.061C60.2918 190.339 60.9935 191.48 62.0316 192.232Z" fill="#00441F"/>
      <path d="M37.3848 289.477L41.3415 290.604L47.2232 274.557L41.3839 272.895L37.3848 289.477Z" fill="#FFB8B8"/>
      <path d="M48.5771 296.506L35.4173 293.058L36.7496 287.973L44.8249 290.089C46.1734 290.442 47.3263 291.317 48.03 292.52C48.7337 293.724 48.9305 295.157 48.5771 296.506Z" fill="#2F2E41"/>
      <path d="M74.6306 292.57L78.7082 291.803L77.8392 274.861L71.8213 275.994L74.6306 292.57Z" fill="#FFB8B8"/>
      <path d="M87.7422 294.115L74.3501 296.507L73.4258 291.333L81.6437 289.865C82.3232 289.744 83.0199 289.758 83.6941 289.905C84.3683 290.053 85.0068 290.333 85.5731 290.727C86.1394 291.122 86.6224 291.624 86.9946 292.206C87.3668 292.787 87.6208 293.436 87.7422 294.115Z" fill="#2F2E41"/>
      <path d="M66.1779 168.719C67.1873 168.982 68.0085 170.085 68.5016 171.363C68.9565 172.705 69.2644 174.099 69.4194 175.517C69.8068 178.159 70.179 181.018 69.3775 183.464C68.4382 186.331 65.9353 187.833 63.7642 187.127C61.5932 186.421 59.8665 183.773 59.2765 180.724C58.7395 177.643 59.0855 174.46 60.2691 171.594C60.8858 170.088 61.7359 168.699 62.8656 168.02C63.4197 167.66 64.0802 167.539 64.7142 167.681C65.3482 167.822 65.9086 168.216 66.2828 168.783" fill="#2F2E41"/>
      <path d="M71.6361 214.848L79.6914 288.041L73.0384 290.604L60.768 241.522L43.6846 286.399L36.4023 286.809C36.4023 286.809 46.394 216.982 53.7989 215.395L71.6361 214.848Z" fill="#2F2E41"/>
      <path d="M65.4242 178.447C70.0428 178.447 73.7868 174.483 73.7868 169.593C73.7868 164.703 70.0428 160.738 65.4242 160.738C60.8056 160.738 57.0615 164.703 57.0615 169.593C57.0615 174.483 60.8056 178.447 65.4242 178.447Z" fill="#FFB8B8"/>
      <path d="M69.0259 181.655L59.7683 180.414C59.7683 180.414 53.7307 187.861 54.1332 200.686C54.5357 213.511 53.127 217.442 53.127 217.442L72.4294 217.8C69.6391 213.856 81.3102 191.207 69.0259 181.655Z" fill="#00441F"/>
      <path d="M74.0211 219.32C73.826 219.321 73.6314 219.338 73.4389 219.37L61.4676 206.742L62.6882 202.71L56.7888 200.09L53.127 208.959L70.3504 222.535C70.2578 223.274 70.3944 224.023 70.7421 224.683C71.0897 225.343 71.632 225.883 72.2966 226.23C72.9613 226.578 73.7169 226.716 74.463 226.628C75.2091 226.539 75.9103 226.227 76.4733 225.734C77.0363 225.241 77.4344 224.59 77.6148 223.867C77.7952 223.144 77.7492 222.384 77.483 221.688C77.2168 220.992 76.7431 220.392 76.1246 219.969C75.5062 219.546 74.7725 219.32 74.0211 219.32Z" fill="#FFB8B8"/>
      <path d="M60.7031 186.36L55.0947 200.269L62.9137 204.026L69.389 190.262C69.6676 189.67 69.8241 189.026 69.8489 188.37C69.8737 187.715 69.7663 187.061 69.5332 186.449C69.3002 185.837 68.9465 185.279 68.4937 184.811C68.0409 184.342 67.4986 183.973 66.8999 183.724C66.3224 183.484 65.704 183.363 65.08 183.365C64.4561 183.368 63.8387 183.495 63.2633 183.74C62.6879 183.985 62.1656 184.342 61.7263 184.792C61.287 185.242 60.9393 185.775 60.7031 186.36Z" fill="#00441F"/>
      <path d="M63.1108 177.469C64.4747 176.679 64.6202 174.775 64.4348 173.206C64.2495 171.638 63.9398 169.889 64.8589 168.606C65.9559 167.075 68.1597 167.045 70.0383 166.995C71.9169 166.945 74.2046 166.393 74.6913 164.572C75.013 163.369 74.3248 162.077 73.3374 161.322C72.3499 160.567 71.1145 160.233 69.9137 159.916C67.2276 159.206 64.4541 158.49 61.7037 158.88C58.9794 159.266 56.5164 160.713 54.8475 162.908C53.1786 165.102 52.4381 167.868 52.7861 170.606C53.2312 174.109 53.8769 175.638 53.4531 177.182C52.6637 180.059 49.2602 181.907 49.1924 184.89C49.1394 187.223 51.1935 189.023 53.1977 190.207C54.7874 191.147 56.5031 191.944 58.3351 192.164C60.1671 192.384 62.1395 191.963 63.5042 190.718C65.2644 189.111 65.6998 186.426 65.1951 184.093C64.6904 181.76 63.3956 179.688 62.1148 177.676" fill="#2F2E41"/>
      <path d="M447.057 273.383C447.384 272.863 447.768 272.381 448.204 271.945C448.383 271.761 448.573 271.589 448.768 271.421C449.955 270.38 451.403 269.67 452.961 269.363C454.52 269.056 456.133 269.163 457.636 269.673C459.139 270.184 460.476 271.079 461.511 272.266C462.546 273.454 463.24 274.892 463.524 276.432C464.835 271.657 461.421 266.954 457.988 263.342C454.554 259.731 450.629 255.769 450.649 250.821C450.658 248.067 452.027 245.611 454.092 243.79C454.156 243.735 454.22 243.68 454.285 243.627C456.782 241.52 459.994 240.418 463.28 240.541C469.386 240.862 474.716 244.902 478.419 249.693C484.378 257.402 486.989 268.224 482.817 276.999C479.148 284.718 471.322 289.301 463.335 292.986C462.22 293.5 461.106 294 459.996 294.483C459.988 294.485 459.98 294.49 459.973 294.492C459.919 294.515 459.866 294.538 459.816 294.562C459.587 294.662 459.357 294.761 459.13 294.86L459.235 294.927L459.564 295.137C459.45 295.069 459.337 295.003 459.223 294.935C459.189 294.915 459.154 294.895 459.12 294.875C455.197 292.558 451.22 290.106 448.51 286.489C445.699 282.733 444.569 277.354 447.057 273.383Z" fill="#E6EDE9"/>
      <path d="M473.506 264.24C474.39 266.212 474.975 268.3 475.245 270.438C475.457 272.345 475.377 274.272 475.008 276.155C474.216 280.025 472.489 283.652 469.971 286.73C468.072 289.067 465.879 291.159 463.445 292.957C462.311 293.479 461.179 293.985 460.049 294.475C460.042 294.477 460.033 294.482 460.026 294.484C459.972 294.507 459.917 294.531 459.866 294.555C459.633 294.656 459.4 294.757 459.169 294.857L459.276 294.925L459.61 295.138C459.494 295.069 459.379 295.002 459.264 294.933C459.229 294.912 459.193 294.893 459.158 294.872C460.036 291.858 460.165 288.682 459.535 285.609C458.904 282.537 457.532 279.657 455.534 277.212C453.525 274.797 450.964 272.883 448.058 271.625C448.24 271.439 448.433 271.264 448.631 271.094C449.751 271.598 450.822 272.199 451.834 272.888C455.452 275.342 458.168 278.874 459.581 282.961C460.843 286.669 461.001 290.654 460.036 294.446C460.236 294.318 460.437 294.186 460.632 294.055C464.349 291.595 467.774 288.641 470.353 285.019C472.578 281.958 474.002 278.407 474.5 274.678C474.994 270.605 473.956 266.6 472.103 262.966C470.057 259.078 467.479 255.482 464.44 252.28C461.388 248.989 457.937 246.077 454.164 243.609C454.079 243.553 454.02 243.466 453.998 243.368C453.976 243.27 453.993 243.167 454.046 243.081C454.086 243.003 454.157 242.943 454.242 242.915C454.285 242.904 454.33 242.903 454.373 242.912C454.417 242.921 454.458 242.939 454.494 242.965C454.959 243.27 455.421 243.578 455.877 243.895C459.677 246.535 463.127 249.628 466.15 253.104C469.07 256.453 471.728 260.175 473.506 264.24Z" fill="white"/>
      <path d="M429.94 289.286L426.006 290.606L419.118 275.829L424.925 273.881L429.94 289.286Z" fill="#FFB8B8"/>
      <path d="M422.938 290.198L430.47 287.709L432.037 292.451L419.764 296.508C419.558 295.885 419.477 295.228 419.525 294.574C419.573 293.92 419.749 293.281 420.044 292.695C420.339 292.11 420.746 291.587 421.243 291.159C421.739 290.731 422.315 290.404 422.938 290.198Z" fill="#2F2E41"/>
      <path d="M404.359 292.574L400.36 292.573L398.457 276.832L404.36 276.832L404.359 292.574Z" fill="#FFB8B8"/>
      <path d="M397.495 291.59H405.344V296.509H392.554C392.554 295.863 392.682 295.223 392.93 294.627C393.178 294.03 393.542 293.487 394.001 293.031C394.46 292.574 395.005 292.212 395.604 291.964C396.204 291.717 396.846 291.59 397.495 291.59Z" fill="#2F2E41"/>
      <path d="M400.908 233.443L400.702 234.379C397.918 247.026 396.927 265.428 397.758 278.365L398.401 288.359C398.423 288.702 398.571 289.023 398.815 289.257C399.059 289.492 399.381 289.622 399.716 289.622H404.559C404.882 289.622 405.194 289.501 405.435 289.281C405.676 289.06 405.83 288.757 405.868 288.428L409.627 255.647C409.662 255.34 409.799 255.054 410.015 254.838C410.23 254.622 410.512 254.488 410.812 254.458C411.112 254.429 411.413 254.507 411.664 254.678C411.916 254.848 412.102 255.103 412.193 255.398L421.935 285.021C422.015 285.281 422.17 285.51 422.379 285.678C422.588 285.846 422.842 285.946 423.107 285.963L430.505 286.453C430.719 286.467 430.932 286.428 431.128 286.338C431.323 286.248 431.494 286.111 431.627 285.939C431.759 285.766 431.848 285.563 431.887 285.347C431.925 285.131 431.912 284.909 431.848 284.699L427.395 272.137C423.954 260.863 422.104 243.732 421.902 231.92C421.899 231.734 421.858 231.55 421.783 231.381C421.707 231.211 421.598 231.06 421.463 230.936C421.327 230.811 421.168 230.717 420.995 230.659C420.823 230.601 420.64 230.58 420.46 230.598L402.068 232.395C401.792 232.422 401.531 232.538 401.322 232.726C401.114 232.914 400.969 233.165 400.908 233.443Z" fill="#2F2E41"/>
      <path d="M411.739 181.4C416.357 181.4 420.101 177.876 420.101 173.529C420.101 169.182 416.357 165.658 411.739 165.658C407.12 165.658 403.376 169.182 403.376 173.529C403.376 177.876 407.12 181.4 411.739 181.4Z" fill="#A0616A"/>
      <path d="M405.925 236.494C404.34 236.49 402.757 236.405 401.181 236.237C400.674 236.181 400.21 235.924 399.886 235.521C399.562 235.118 399.405 234.601 399.448 234.081L402.161 207.223C402.815 199.626 403.826 192.292 408.939 186.082C409.113 185.871 409.327 185.7 409.569 185.578C409.81 185.456 410.074 185.388 410.342 185.375L410.535 185.368C412.552 185.282 413.481 185.357 413.743 185.625L415.149 187.046C423.729 193.548 424.085 234.737 421.971 233.925C421.562 233.769 416.68 233.926 416.34 234.164C413.584 236.089 409.155 236.493 405.925 236.494Z" fill="#00441F"/>
      <path d="M373.218 221.571C373.433 221.104 373.747 220.69 374.136 220.359C374.525 220.029 374.981 219.789 375.471 219.657C375.961 219.526 376.473 219.505 376.972 219.597C377.471 219.689 377.943 219.891 378.357 220.19L388.375 212.883L388.618 219.404L379.067 225.343C378.53 226.018 377.768 226.469 376.926 226.61C376.084 226.751 375.22 226.571 374.499 226.106C373.778 225.642 373.249 224.923 373.013 224.087C372.777 223.251 372.85 222.356 373.218 221.571Z" fill="#A0616A"/>
      <path d="M383.117 222.721L379.764 216.932L410.307 195.334C410.996 194.847 411.781 194.504 412.611 194.327C413.441 194.151 414.3 194.144 415.133 194.307C415.967 194.471 416.756 194.801 417.454 195.277C418.151 195.753 418.741 196.366 419.186 197.076C419.631 197.785 419.922 198.578 420.041 199.403C420.159 200.228 420.103 201.068 419.876 201.871C419.649 202.674 419.255 203.423 418.719 204.07C418.184 204.717 417.518 205.248 416.763 205.632L383.117 222.721Z" fill="#00441F"/>
      <path d="M415.299 180.918C415.581 181.201 415.953 181.37 416.347 181.396C416.742 181.422 417.132 181.302 417.447 181.058C419.405 179.58 421.125 177.74 422.143 175.496C423.309 172.924 423.439 169.768 422.033 167.324C420.844 165.258 418.746 163.925 416.654 162.83C415.842 162.363 414.967 162.02 414.057 161.811C413.138 161.605 412.176 161.763 411.367 162.253C410.971 162.506 410.659 162.874 410.472 163.31C410.285 163.745 410.231 164.229 410.318 164.696C409.313 163.529 408.026 162.649 406.585 162.145C405.677 161.783 404.683 161.71 403.734 161.934C402.407 162.318 401.438 163.518 400.908 164.815C400.479 165.801 400.331 166.891 400.48 167.958C400.559 168.49 400.746 168.999 401.03 169.452C401.314 169.905 401.688 170.292 402.129 170.589C403.226 171.28 404.626 171.23 405.886 170.948C407.146 170.665 408.366 170.174 409.651 170.05C410.936 169.926 412.366 170.252 413.148 171.297C413.699 172.033 413.84 172.995 413.964 173.911L414.607 178.679C414.659 179.368 414.832 180.043 415.117 180.671C415.167 180.761 415.228 180.844 415.299 180.918Z" fill="#2F2E41"/>
      <path d="M432.903 237.807C432.467 237.517 432.105 237.144 431.842 236.712C431.58 236.28 431.425 235.801 431.386 235.308C431.348 234.815 431.427 234.32 431.62 233.858C431.812 233.397 432.113 232.979 432.5 232.636L426.988 221.738L433.715 222.661L437.918 232.868C438.503 233.503 438.815 234.314 438.793 235.146C438.772 235.978 438.418 236.773 437.8 237.382C437.182 237.991 436.342 238.37 435.44 238.449C434.537 238.527 433.634 238.298 432.903 237.807V237.807Z" fill="#A0616A"/>
      <path d="M435.843 226.338L430.253 229.61L409.4 199.811C408.93 199.139 408.599 198.374 408.428 197.563C408.257 196.753 408.251 195.916 408.409 195.103C408.566 194.29 408.885 193.519 409.345 192.839C409.805 192.159 410.396 191.583 411.081 191.149C411.767 190.715 412.532 190.431 413.328 190.315C414.125 190.199 414.937 190.254 415.712 190.476C416.487 190.697 417.21 191.082 417.834 191.604C418.459 192.126 418.972 192.776 419.342 193.512L435.843 226.338Z" fill="#00441F"/>
      <path className={cssProps.secondBaloon} d="M404.887 90.6932C405.578 80.2626 398.191 71.2806 391.103 70.811C384.014 70.3415 375.507 78.2707 374.816 88.7013C374.177 98.356 380.774 106.785 387.054 108.337C386.981 108.394 386.919 108.464 386.872 108.544L386.072 109.98C386.011 110.089 385.979 110.213 385.979 110.338C385.979 110.463 386.011 110.586 386.072 110.696C386.134 110.805 386.222 110.897 386.328 110.963C386.435 111.029 386.556 111.066 386.682 111.072L390.18 111.228C390.305 111.234 390.429 111.207 390.541 111.151C390.654 111.095 390.749 111.012 390.82 110.908C390.891 110.804 390.933 110.685 390.945 110.56C390.956 110.435 390.935 110.309 390.884 110.195L390.215 108.694C390.191 108.642 390.161 108.593 390.125 108.548C396.572 107.867 404.246 100.369 404.887 90.6932Z" fill="#E6EDE9"/>
      <path className={cssProps.secondBaloon} d="M396.544 79.3901C398.553 81.7497 399.58 84.7908 399.412 87.8855C399.244 90.9802 397.894 93.8923 395.641 96.0208C395.131 96.5134 395.972 97.2271 396.479 96.7377C398.879 94.4192 400.313 91.2791 400.495 87.9474C400.676 84.6156 399.591 81.3383 397.458 78.7729C397.007 78.232 396.09 78.8452 396.544 79.3901Z" fill="white"/>
      <path className={cssProps.thirdBaloon} d="M100.76 63.361C100.543 56.5111 95.1493 51.123 90.494 51.27C85.8387 51.417 80.7952 57.1348 81.0116 63.9847C81.2118 70.3251 86.0559 75.403 90.2523 76.014C90.2082 76.0557 90.1725 76.1054 90.1471 76.1606L89.717 77.1486C89.6842 77.2239 89.6712 77.3063 89.6793 77.3881C89.6873 77.4698 89.7161 77.5482 89.7629 77.6157C89.8098 77.6831 89.8731 77.7375 89.9468 77.7737C90.0206 77.8098 90.1024 77.8265 90.1844 77.8221L92.4769 77.7005C92.5589 77.6961 92.6385 77.6709 92.708 77.6271C92.7775 77.5834 92.8347 77.5226 92.8741 77.4505C92.9136 77.3785 92.9339 77.2975 92.9333 77.2154C92.9326 77.1332 92.9109 77.0526 92.8704 76.9812L92.338 76.0443C92.3188 76.012 92.2958 75.9822 92.2695 75.9554C96.4326 75.0983 100.96 69.7152 100.76 63.361Z" fill="#00441F"/>
      <path className={cssProps.thirdBaloon} d="M94.5939 56.52C96.0559 57.9312 96.9203 59.8497 97.0087 61.8797C97.0971 63.9096 96.4028 65.896 95.069 67.4289C94.7677 67.783 95.3621 68.1948 95.6614 67.8431C97.0788 66.1768 97.8139 64.0363 97.7192 61.8508C97.6245 59.6652 96.707 57.5963 95.1508 56.0589C94.8222 55.7348 94.2629 56.1935 94.5939 56.52Z" fill="white"/>
      <path className={cssProps.firstBaloon} d="M315.074 17.5782C314.636 7.56867 306.654 -0.211569 299.852 0.0864054C293.049 0.38438 285.779 8.83269 286.217 18.8422C286.623 28.1072 293.795 35.4437 299.94 36.262C299.876 36.3237 299.825 36.3971 299.789 36.4781L299.178 37.9301C299.131 38.0408 299.114 38.1615 299.127 38.2809C299.14 38.4002 299.184 38.5142 299.253 38.6121C299.323 38.7099 299.416 38.7883 299.525 38.8398C299.633 38.8913 299.753 38.9142 299.873 38.9064L303.222 38.6876C303.342 38.6798 303.458 38.6415 303.559 38.5763C303.659 38.5111 303.742 38.4212 303.798 38.3152C303.855 38.2091 303.883 38.0904 303.881 37.9704C303.878 37.8503 303.845 37.7328 303.784 37.6292L302.99 36.2691C302.961 36.2223 302.927 36.1791 302.888 36.1403C308.958 34.8132 315.481 26.8633 315.074 17.5782Z" fill="#00441F"/>
      <path className={cssProps.firstBaloon} d="M305.938 7.68895C308.1 9.72565 309.398 12.5148 309.564 15.4807C309.729 18.4465 308.749 21.3627 306.827 23.6273C306.393 24.1502 307.269 24.7417 307.7 24.2222C309.743 21.7611 310.779 18.6189 310.602 15.4258C310.424 12.2326 309.046 9.22457 306.744 7.0049C306.258 6.53697 305.448 7.21754 305.938 7.68895Z" fill="white"/>
      <defs>
        <clipPath id="clip0_4879_24963">
          <rect width="268.517" height="241.041" fill="white" transform="translate(101.335 55.4688)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

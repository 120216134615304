import React from 'react'
import { Col, Form } from 'antd'
import { PercentageInput } from 'components'

type PercentageInsuredProps = {
  numberInsured: 'FirstInsured' | 'SecondInsured',
  readOnlyInput?: boolean,
  errors: any
}

function PercentageInsured ({ numberInsured, errors, readOnlyInput }: PercentageInsuredProps) {
  const insuredNumber: number = numberInsured === 'FirstInsured' ? 1 : 2

  return (
    <Col lg={12} xs={24}>
      <Form.Item
        name={`percentageFor${numberInsured}`}
        label={<label>Porcentagem para Segurado {insuredNumber}</label>}
        help={errors[`percentageFor${numberInsured}`] && (errors[`percentageFor${numberInsured}`])}
        validateStatus={errors[`percentageFor${numberInsured}`] && ('error')}

      >
        <PercentageInput
          disabled={readOnlyInput}
          placeholder={`Porcentagem para Segurado ${insuredNumber}`}
        />
      </Form.Item>
    </Col>
  )
}

export default PercentageInsured

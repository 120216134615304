import React, { useEffect, useState } from 'react'
import { Input, Address } from 'components'
import locale from 'antd/es/date-picker/locale/pt_BR'
import { Checkbox, Col, Divider, Form, InputNumber, Row, Select } from 'antd'
import { proposals } from 'egi/consumers'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'
import { useStep, useResources, useProposal } from 'hooks'
import { IFormDataPJ, IFormDataPJErrors } from './types'
import { IFormProps } from '../types'
import format from 'utils/format'
import time, { correctTimezone, timeAsDayjs } from 'utils/time'
import { proposalLoadingStep } from 'store/modules/proposal/actions'
import { useDispatch } from 'react-redux'
import Datepicker from 'components/Datepicker/Datepicker'
import { CheckTermsFields } from 'components/CheckTermsFields/CheckTermsFields'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'

function FormUserPJData ({ formRef, onLoading, onType, callbackProposal, readOnlyInput, openSimulationDrawer }: IFormProps) {
  const [errors, setErrors] = useState<IFormDataPJErrors>({})

  const resources = useResources()
  const step = useStep()
  const proposal = useProposal()
  const dispatch = useDispatch()

  useEffect(() => {
    function resetFields () {
      if (formRef) formRef.resetFields()
    }

    async function onFill () {
      const formData = Object.assign({}, step.form)

      if (formData.foundationDate) formData.foundationDate = timeAsDayjs(formData.foundationDate, { applyTimezone: false }).format('DD/MM/YYYY')
      else formData.foundationDate = undefined

      if (formData.lastUpdateDate) formData.lastUpdateDate = timeAsDayjs(formData.lastUpdateDate, { applyTimezone: false }).format('DD/MM/YYYY')
      else formData.lastUpdateDate = undefined

      if (Object.keys(formData).length < 1) {
        resetFields()
        return
      }

      formRef.setFieldsValue(formData)
    }

    onFill()
  }, [step.form, formRef, step._id, step.situation])

  const onSubmit = async (values: IFormDataPJ) => {
    const formData: any = values

    if (formData.foundationDate) formData.foundationDate = time(correctTimezone(formData.foundationDate.toString()))
    if (formData.lastUpdateDate) formData.lastUpdateDate = time(correctTimezone(formData.lastUpdateDate.toString()))

    if (formData.phone) formData.phone = format.onlyDigits(formData.phone)
    if (formData.whatsapp) formData.whatsapp = format.onlyDigits(formData.whatsapp)
    if (formData.phone) formData.phone = format.onlyDigits(formData.phone)
    if (formData.cnpj) formData.cnpj = format.onlyDigits(formData.cnpj)

    try {
      if (!proposal.id) throw new Error('Identificador da proposta não encontrado!')
      if (!step._id) throw new Error('Identificador do passo não encontrado!')

      const props = {
        proposalId: proposal.id,
        stepId: step._id,
        areaId: step.areaId,
        form: formData
      }

      let response
      if (onType === 'send') {
        onLoading('sending')
        props.form = { ...props.form, finished: true }
        response = await proposals.send(props)
      } else if (onType === 'save') {
        onLoading('saving')
        props.form = { ...props.form, finished: false }
        response = await proposals.send(props)
      }

      if (step.id) dispatch(proposalLoadingStep({ stepId: step.id, loading: true }))
      setErrors({})
      callbackProposal()
      swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
    } catch (err) {
      if (err?.data?.maxDeadline !== undefined && err?.data?.maxDeadline !== null) {
        swal.confirm({
          icon: 'warning',
          title: 'Atenção',
          html: err.message,
          confirmButtonText: 'Refazer simulação',
          showCancelButton: false,
          confirm: () => {
            if (openSimulationDrawer) openSimulationDrawer({ type: 'simulator' })
          }
        })
      }
      if (err.data?.invalid) {
        let errors: IFormDataPJErrors = {}
        errors = validateResponse(err.data?.invalid)
        setErrors(errors)

        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
        return
      }

      setErrors({})
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      return false
    } finally {
      onLoading('')
    }
  }

  return (
    <Form
      layout="vertical"
      form={formRef}
      onFinish={onSubmit}
      initialValues={{
        hasPartnerRepresentative: false
      }}
    >
      <Row>
        <Col className="px-1" lg={8} md={24} sm={24} xs={24}>
          <Form.Item
            name="fantasyName"
            label={<label>Razão social</label>}
            help={errors.fantasyName && errors.fantasyName}
            validateStatus={errors.fantasyName && 'error'}
          >
            <Input
              id="test-select-form-fantasyName"
              name='name'
              readOnly={readOnlyInput}
              placeholder={'Digite a razão social'}
              error={errors.fantasyName}
            />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={8} md={24} sm={24} xs={24}>
          <Form.Item
            name="socialName"
            label={<label>Nome fantasia</label>}
            help={errors.socialName && errors.socialName}
            validateStatus={errors.socialName && 'error'}
          >
            <Input
              name='socialName'
              id="test-select-form-socialName"
              readOnly={readOnlyInput}
              placeholder={'Digite o Nome Fantasia'}
              error={errors.socialName}
            />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={8} md={24} sm={24} xs={24}>
          <Form.Item
            name="cnpj"
            label={<label>CNPJ</label>}
            help={errors.cnpj && errors.cnpj}
            validateStatus={errors.cnpj && 'error'}
          >
            <Input
              name='cnpj'
              readOnly={readOnlyInput}
              mask="cnpj"
              id="test-select-form-cnpj"
              placeholder={'Digite o CNPJ'}
              error={errors.cnpj}
            />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={8} sm={24} xs={24}>
          <Form.Item
            name='foundationDate'
            label={<label>Data de fundação</label>}
            help={errors.foundationDate && (errors.foundationDate)}
            validateStatus={errors.foundationDate && ('error')}
          >
            <Datepicker
              id="test-select-foundationDate"
              disabled={readOnlyInput}
              locale={locale}
            />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={8} sm={24} xs={24}>
          <Form.Item
            name='lastUpdateDate'
            label={<label>Data da última alteração:</label>}
            help={errors.lastUpdateDate && (errors.lastUpdateDate)}
            validateStatus={errors.lastUpdateDate && ('error')}
          >
            <Datepicker
              id="test-select-lastUpdateDate"
              disabled={readOnlyInput}
              locale={locale}
            />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={8} sm={24} xs={24}>
          <Form.Item
            name='email'
            label={<label>Email:</label>}
            help={errors.email && (errors.email)}
            validateStatus={errors.email && ('error')}
          >
            <Input
              type="email"
              id="email"
              readOnly={readOnlyInput}
              placeholder='Digite seu email'
              error={errors.email}
            />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={8} sm={24} xs={24}>
          <Form.Item
            name='whatsapp'
            label={<label>Contato whatsapp (opcional): </label>}
            help={errors.whatsapp && (errors.whatsapp)}
            validateStatus={errors.whatsapp && ('error')}
          >
            <Input
              id="whatsapp"
              disabled={readOnlyInput}
              placeholder="Contato whatsapp"
              mask="phone"
            />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={8} sm={24} xs={24}>
          <Form.Item
            name='phone'
            label={<label>Tel. Res./Comer.: </label>}
            help={errors.phone && (errors.phone)}
            validateStatus={errors.phone && ('error')}
          >
            <Input
              id="phone"
              disabled={readOnlyInput}
              placeholder="Digite o Tel. Res./Comer."
              mask="phone"
            />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={12} sm={24} xs={24}>
          <Form.Item
            name="legalNature"
            label={<label>Natureza Jurídica:</label>}
            help={errors.legalNature && (errors.legalNature)}
            validateStatus={errors.legalNature && ('error')}
          >
            <Select
              id="test-select-legalNature"
              placeholder="Selecione a Natureza Jurídica"
              aria-readonly={readOnlyInput}
              disabled={readOnlyInput}
            >
              {resources.legalNature.map((item) => {
                return (
                  <Select.Option
                    id={`teste-form-${item?.toLowerCase().replace(/[ /()]+/g, '')}`}
                    key={item}
                    value={item}>
                    {item}
                  </Select.Option>)
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col className="px-1" lg={12} sm={24} xs={24}>
          <Form.Item
            name="economicActivity"
            label={<label>Atividade econômica:</label>}
            help={errors.economicActivity && (errors.economicActivity)}
            validateStatus={errors.economicActivity && ('error')}
          >
            <Select
              id="test-select-economicActivity"
              placeholder="Selecione a Atividade econômica"
              aria-readonly={readOnlyInput}
              disabled={readOnlyInput}
            >
              {resources.economicActivity.map((item) => {
                return (
                  <Select.Option
                    id={`teste-form-${item?.toLowerCase().replace(/ ()/g, '-').replace(' ', '-')}`}
                    key={item}
                    value={item}>
                    {item}
                  </Select.Option>)
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col className="px-1" lg={8} sm={24} xs={24}>
          <Form.Item
            name='partnersNumber'
            label={<label>Quantidade de sócios PF: </label>}
            help={errors.partnersNumber && (errors.partnersNumber)}
            validateStatus={errors.partnersNumber && ('error')}
          >
            <InputNumber
              min={0}
              max={4}
              id="test-select-partnersNumber"
              className="w-100"
              disabled={readOnlyInput}
              placeholder="Digite a quantidade de sócios PF."
            />
          </Form.Item>
        </Col>

        <Col className="px-1" lg={8} sm={12} xs={24}>
          <Form.Item
            name='hasPartnerRepresentative'
            className="form-user-checkbox"
            valuePropName="checked"
            label={<label></label>}
            help={errors.hasPartnerRepresentative && (errors.hasPartnerRepresentative)}
            validateStatus={errors.hasPartnerRepresentative && ('error')}
          >
            <Checkbox
              id="test-checkbox-hasPartnerRepresentative"
              disabled={readOnlyInput}
            >
              Representante é sócio na empresa?
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Address
        onCepBlur={(address, remainingAddress) => formRef.setFieldsValue({ commercialAddress: { ...address, ...remainingAddress } })}
        formRef={formRef}
        name="commercial"
        title="Endereço comercial"
        errors={errors}
        readOnlyInput={readOnlyInput}
      />
      <Divider />
      <CheckTermsFields
        errors={errors}
        readOnlyInput={readOnlyInput}
      />
    </Form>
  )
}

export default FormUserPJData

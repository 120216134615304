import React, { useState } from 'react'
import queryString from 'query-string'

import { ResponseStepProps } from '../../creditApproveInterfaces'
import CreditChooseStep from '../CreditChooseStep/CreditChooseStep'
import CreditRefusedStep from '../CreditRefusedStep/CreditRefusedStep'
import CreditResponseStep from '../CreditResponseStep/CreditResponseStep'

function CreditMainComponent () {
  const [current, setCurrent] = useState(0)
  const [userResponse, setUserResponse] = useState({
    text: '',
    title: '',
    icon: <></>
  })

  const token = queryString.parse(location.search).token

  function changeCurrent (key: number) {
    setCurrent(key)
  }

  function changeUserResponse (updatedData: ResponseStepProps) {
    setUserResponse(updatedData)
  }

  const MySteps = {
    CreditChooseStep,
    CreditRefusedStep,
    CreditResponseStep
  }

  return (
    <div className="flex flex-justify-center credit-modal">
      <div className="credit-modal-wrapper mt-3">
        {current === 0 && <MySteps.CreditChooseStep token={token} changeCurrent={changeCurrent} changeUserResponse={changeUserResponse} />}
        {current === 1 && <MySteps.CreditRefusedStep token={token} changeCurrent={changeCurrent} changeUserResponse={changeUserResponse} />}
        {current === 2 && <MySteps.CreditResponseStep {...userResponse} />}
      </div>
    </div>
  )
}

export default CreditMainComponent

import { AccessManagerDetailsGroupEnum, IDetailSchema } from 'egi/app/AccessManager/accessManagerInterfaces'
import format from 'utils/format'
import { getDeepProperty } from 'utils/getDeepProperty'
import mask from 'utils/masks'
import translate from 'utils/translate'

/**
 * Checks if a given value is a valid non-null, non-undefined, and non-array object.
 *
 * @param {*} value - The value to be checked.
 * @returns {boolean} - Returns true if the value is a valid object, otherwise false.
 */
const isValidObject = (value: any) => typeof value !== 'undefined' && value !== null && !Array.isArray(value)

/**
 * Generates a new schema item based on the provided key.
 *
 * @param {Array<string>} key - The key for the schema item.
 * @returns {IDetailSchema} - The generated schema item.
 */
const getNewSchemaItem = (key: Array<string>):IDetailSchema => ({
  key,
  label: key.join('_').toUpperCase(),
  group: AccessManagerDetailsGroupEnum.others,
  format: 'jsonToString'
})

/**
 * Recursively handles object schema creation and returns an array of IDetailSchema.
 *
 * @param {Array<string>} keyRoot - The root key for the schema item.
 * @param {Object} data - The data object to generate the schema from.
 * @returns {Array<IDetailSchema>} - An array of generated schema items.
 */
const handleObjectSchema = (keyRoot: Array<string>, data: Object = {}): Array<IDetailSchema> => {
  return Object.entries(data).reduce((acc, item) => {
    const [key, value] = item

    if (typeof value === 'object' && isValidObject(value)) {
      const objectSchema = handleObjectSchema([...keyRoot, key], value)
      return [...acc, ...objectSchema]
    }

    return [...acc, getNewSchemaItem([...keyRoot, key])]
  }, [] as Array<IDetailSchema>)
}

/**
 * Builds and returns an array of IDetailSchema based on the provided data object.
 *
 * @param {Object} data - The data object to generate the schema from.
 * @returns {Array<IDetailSchema>} - An array of generated schema items.
 */
export const buildDataSchema = (data:Object = {}):Array<IDetailSchema> => {
  return Object.entries(data).reduce((acc, item) => {
    const [key, value] = item

    if (typeof value === 'object' && isValidObject(value)) {
      const objectSchema = handleObjectSchema([key], value)
      return [...acc, ...objectSchema]
    }

    return [...acc, getNewSchemaItem([key])]
  }, [] as Array<IDetailSchema>)
}

/**
 * Formats the value based on the provided schema item and data object.
 *
 * @param {IDetailSchema} item - The schema item to format the value for.
 * @param {Object} data - The data object containing the value.
 * @returns {*} - The formatted value.
 */
export const formatValue = (item: IDetailSchema, data: Object): any => {
  try {
    const value = getDeepProperty(data, item.key)
    if (typeof value === 'undefined' || value === null) throw new Error(`O parâmetro ${item.key} não foi encontrado.`)
    if (Array.isArray(value) && !value.length) return 'n/a'
    if (item.format) return (format[item.format] as Function)(value)
    if (item.mask) return mask(value, item.mask)
    if (item.translate) return (translate[item.translate] as Function)(value, undefined)
    if (typeof value === 'number') return value
    return value || '-'
  } catch (err) {
    console.log(err)
    return 'n/a'
  }
}

export const accessManagerBuildSchema = (mappedSchema: Array<IDetailSchema>, detailsData: Object) => {
  try {
    const dataSchema = buildDataSchema(detailsData)
    return [mappedSchema, dataSchema].flat().reduce((acc: IDetailSchema[], item: IDetailSchema) => {
      const findedItemIndex = acc.findIndex(addedItem => JSON.stringify(addedItem.key) === JSON.stringify(item.key))
      if (findedItemIndex >= 0) {
        if (acc[findedItemIndex].group === AccessManagerDetailsGroupEnum.others) acc[findedItemIndex] = item
        return acc
      }
      return [...acc, item]
    }, [])
  } catch (err) {
    console.log(err)
    return []
  }
}

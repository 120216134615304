import React, { useLayoutEffect, useState } from 'react'
import { Empty, Spin, Timeline } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import swal from 'utils/swal'
import { timeAsDayjs } from 'utils/time'
import translate from 'utils/translate'
import { ISimulationHistory, SimulationHistoryProps, _simulationHistoryTypes } from 'components/SimulationCard/types'

import SimulationsRepository from 'egi/repositories/SimulationsRepository'

function HistoryList ({ title, lastItem, newItem }: {title: string, lastItem: string | number | undefined, newItem: string | number | undefined}) {
  return (
    <ul>
      <li className="mb-3">{title}: <b className="color-gray" >{lastItem}</b> <ArrowRightOutlined/> <b className="color-tertiary" >{newItem}</b> </li>
    </ul>
  )
}

function SimulationHistory ({ proposalId, content }: SimulationHistoryProps) {
  const [simulationHistory, setSimulationHistory] = useState<ISimulationHistory[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const drawerBody = document.querySelector('.fee-drawer .ant-drawer-body')

  const translatedTypes: {[key in _simulationHistoryTypes]: string} = {
    pricingrevision: 'Revisão de Taxa',
    replacesimulation: 'Refazer Simulação'
  }

  useLayoutEffect(() => {
    async function getHistory () {
      setLoading(true)
      try {
        if (!proposalId) throw new Error('Falha ao encontrar id da proposta')
        const params = {
          proposalId
        }
        const response = await SimulationsRepository.history({ params })
        const historyData = response.data.data.history
        if (historyData && Array.isArray(historyData)) setSimulationHistory(historyData)
        setLoading(false)
      } catch (err) {
        setLoading(false)
        swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      }
    }

    getHistory()
  }, [])

  useLayoutEffect(() => {
    if (drawerBody) drawerBody.scrollTo(0, drawerBody.scrollHeight)
  }, [loading])

  return (
    <Timeline className="lead-history-item-tail mt-2">
      <Spin spinning={loading} >
        {!simulationHistory?.length && <Empty description="Nenhum histórico encontrado." />}

        {Array.isArray(simulationHistory) && simulationHistory.map((item: ISimulationHistory) => {
          const propertyData = content.property(item).map(item => item)
          const personalData = content.personal(item).map(item => item)
          const loanData = content.loan(item).map(item => item)
          const historyType = translatedTypes[item.type]

          return (
            <Timeline.Item key={item._id}>
              <div>
                <h3 className="bold uppercase color-primary mb-3">{timeAsDayjs(item.createdAt).format('DD/MM/YYYY HH:mm')} - {item.modifiedBy?.name} - {translate.level(item.modifiedBy.level)} - {historyType}</h3>
                <h4 className="bold uppercase color-primary mb-3">Dados do imóvel:</h4>
                {Array.isArray(propertyData) && propertyData.map((values) => (
                  <HistoryList
                    key={values.title}
                    title={values.title}
                    lastItem={values.lastItem}
                    newItem={values.newItem}
                  />
                ))}

                <h4 className="bold uppercase color-primary mb-3">Dados pessoais:</h4>
                {Array.isArray(personalData) && personalData.map((values) => (
                  <HistoryList
                    key={values.title}
                    title={values.title}
                    lastItem={values.lastItem}
                    newItem={values.newItem}
                  />
                ))}

                <h4 className="bold uppercase color-primary mb-3">Dados do empréstimo:</h4>
                {Array.isArray(loanData) && loanData.map((values) => (
                  <HistoryList
                    key={values.title}
                    title={values.title}
                    lastItem={values.lastItem}
                    newItem={values.newItem}
                  />
                ))}
              </div>
            </Timeline.Item>
          )
        })}
      </Spin>
    </Timeline>
  )
}

export default SimulationHistory

import { Empty, Select } from 'antd'
import axios, { AxiosRequestConfig } from 'axios'
import { _reviewStatusOption } from 'components/ReviewBackoffice/reviewBackofficeInterfaces'
import { ISelectProps } from 'components/Select/Select'
import { IReasons } from 'ecp/app/Proposals/proposalInterfaces'
import StepConfigurationRepository from 'ecp/repositories/StepConfigurationRepository'
import React, { useEffect, useState } from 'react'

const formatToSelectUser = (item: IReasons) => ({
  value: item._id,
  label: item.reason
})

interface Props extends ISelectProps {
  stepId?: string
  status?: _reviewStatusOption
  proposalId?: string
}

export default function ProposalReasonsSelect ({ proposalId, status, stepId, disabled, onChange, placeholder, ...rest }: Props) {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [reasons, setReasons] = useState<{ value: string, label: string }[]>([])

  useEffect(() => {
    const abortController = new AbortController()
    async function fetchLevels () {
      try {
        setLoading(true)

        const requestConfig: AxiosRequestConfig = {
          signal: abortController.signal,
          params: { proposalId, status, stepId }
        }

        const response = await StepConfigurationRepository.getReasons(requestConfig)
        const reasons = response.data.data?.reasons

        if (!reasons?.length) {
          setReasons([])
          throw new Error('Nenhum motivo encontrado')
        }

        const formatedValues = (reasons || []).map(formatToSelectUser)
        setReasons(formatedValues)
        setLoading(false)
      } catch (err) {
        if (!axios.isAxiosError(err)) {
          setErrorMessage(err?.message)
          setLoading(false)
        }
      }
    }

    fetchLevels()
    return () => abortController.abort()
  }, [status])

  return (
    <Select
      {...rest}
      onChange={onChange}
      showSearch
      optionFilterProp="children"
      disabled={loading || disabled}
      loading={loading}
      placeholder={placeholder}
      getPopupContainer={trigger => trigger.parentNode}
      notFoundContent={
        errorMessage && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={errorMessage} />
      }
    >
      {reasons.map((item, idx) => (
        <Select.Option
          value={item.value}
          key={item.value + (idx)}
        >
          {item.label}
        </Select.Option>
      ))}
    </Select>
  )
}

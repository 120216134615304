import React from 'react'
import { Form, Select } from 'antd'

function FilterProviderDepartment ({ callback }: { callback: Function }) {
  return (
    <Form.Item
      name="providerDepartment"
      label={<label>Tipo de prestador</label>}
    >
      <Select
        placeholder="Escolha o tipo do prestador"
        onChange={(value: string) => {
          if (value) callback(value)
          else callback(undefined)
        }}
        className="w-100"
        allowClear
      >
        <Select.Option value="">Todos</Select.Option>
        <Select.Option value="engenharia">Engenharia</Select.Option>
        <Select.Option value="juridico">Jurídico</Select.Option>
      </Select>
    </Form.Item>
  )
}

export default FilterProviderDepartment

import React, { Fragment, useEffect, useState } from 'react'
import { Button, Empty, Row } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import proposals from 'egi/consumers/proposals'
import { useProposal, useStep } from 'hooks'
import { IMinutaData } from './types'
import ContractEditbox from './ContractEditbox'
import StringToHtml from 'components/StringToHtml/StringToHtml'

function EditMinuta ({ readonly, onClick }: {readonly?: boolean, onClick: () => void}) {
  const [loading, setLoading] = useState<boolean>(false)
  const [contract, setContract] = useState<IMinutaData[]>([])
  const proposal = useProposal()

  const step = useStep()

  async function getPreview () {
    if (!proposal.id) return

    try {
      setLoading(true)
      const response: any = await proposals.finalMinutaPreview({ proposalId: proposal.id })
      setContract(response.data.quadros)
      setLoading(false)
    } catch (err) {
      console.error(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    getPreview()
  }, [step])

  return (
    <div className="final-contract">
      {loading
        ? <Row
          className="context"
          justify="center"
        >
          <LoadingOutlined />
        </Row>
        : <Fragment>
          {contract.length < 1 && <Empty description="Minuta ainda não foi emitida!" />}

          {Array.isArray(contract) && contract.length > 0 && contract.map((value, idx) => (
            <Fragment key={`${value.slug}-${idx}`}>
              <p style={{ fontWeight: 'bold', padding: 4, backgroundColor: '#DEDEDE' }}>
                {value.name}
              </p>

              {value.editable ? (
                <ContractEditbox
                  readOnly={readonly}
                  value={value.value}
                  slug={value.slug}
                  onSuccess={() => getPreview()}
                />
              ) : (
                <StringToHtml value={value.value} />
              )}
            </Fragment>
          ))}

          <Row justify="end">
            <Button
              type="primary"
              className="uppercase"
              onClick={onClick}
            >
              Visualizar minuta final
            </Button>
          </Row>
        </Fragment>
      }
    </div>
  )
}

export default EditMinuta

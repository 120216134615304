import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { UNAUTHS_PATHS } from 'routes/unauthRoutes'

export const useFortity = () => {
  const history = useHistory()

  const redirectToFortifyOrEcpPath = useCallback(
    (err: { data: { needChangePassword: boolean, onidata: boolean } }, identifier: string) => {
      if (err.data?.needChangePassword === true) {
        const userHasOnidataAccount = err.data?.onidata === true
        const redirectPath = userHasOnidataAccount ? UNAUTHS_PATHS.ECP_FORGOT_TOKEN_ONIDATA : UNAUTHS_PATHS.FORTIFY_PASSWORD

        history.push({
          pathname: redirectPath,
          state: {
            email: identifier,
            fortifyPassword: true
          }
        })
      }
    },
    []
  )

  return {
    redirectToFortifyOrEcpPath
  }
}

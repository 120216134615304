import React, { ReactNode } from 'react'

type Props = {
  children: ReactNode
  image: {src: string, alt: string}
}

function DepartmentSection ({ children, image }: Props) {
  return (
    <section className="department-section">
      <div className="department-section__image-wrapper">
        <img
          src={image.src}
          alt={image.alt}
        />
      </div>

      <div className="department-section__content">
        {children}
      </div>
    </section>
  )
}

export default DepartmentSection

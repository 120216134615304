import React from 'react'
import { IProgressQueueItem } from 'store/modules/progressQueue/progressQueueReducer'
import ProgressQueueCard from '../ProgressQueueCard/ProgressQueueCard'
import ProgressQueueCardReports from '../ProgressQueueCardReports/ProgressQueueCardReports'

type Props = {
  queueItem: IProgressQueueItem
  itemIndex: number
  queueList: IProgressQueueItem[]
}

export default function ProgressQueueCardController ({ queueItem, itemIndex, queueList }: Props) {
  const { type } = queueItem

  switch (type) {
    case 'pendingUser':
      return (
        <ProgressQueueCard
          itemIndex={itemIndex}
          key={queueItem.progressCheckId}
          queueItem={queueItem}
          queueList={queueList}
        />
      )
    case 'proposal':
      return (
        <ProgressQueueCard
          itemIndex={itemIndex}
          key={queueItem.progressCheckId}
          queueItem={queueItem}
          queueList={queueList}
        />
      )
    case 'report':
      return (
        <ProgressQueueCardReports
          itemIndex={itemIndex}
          key={queueItem.progressCheckId}
          queueItem={queueItem}
          queueList={queueList}
        />
      )
  }
}

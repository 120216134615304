import { Switch } from 'antd'
import React from 'react'
import { useAccessManagerBlockBackoffice } from '../../hooks/useAccessManager'

type Props = {
  userIsBlocked?: boolean
  onFinish?: () => void
  userId?: string
}

export default function BackofficeBlockSwitch ({ userIsBlocked, onFinish, userId }: Props) {
  const { isBlocked, loading, onBlock } = useAccessManagerBlockBackoffice(userIsBlocked)

  return (
    <Switch
      className="user-details__perfil-switch"
      checkedChildren='Bloqueado'
      unCheckedChildren='Bloquear'
      loading={loading}
      checked={isBlocked}
      onClick={(newValue) => {
        onBlock({ newValue, id: userId, onFinish })
      }}
    />
  )
}

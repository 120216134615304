import React from 'react'
import { Col, Row } from 'antd'

import DetailsItem from 'ecp/components/DetailsItem/DetailsItem'
import { IOnidataClient } from 'ecp/models/ClientModel'

function ContactFieldsDisplay ({ contactInfos }: { contactInfos: IOnidataClient }) {
  return (
    <Row gutter={[25, 25]}>
      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='Celular'
          value={contactInfos?.telefone_celular ?? '-'}
        />
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='E-mail'
          value={contactInfos?.email ?? '-'}
        />
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='Email secundário'
          value={contactInfos?.email_secundario ?? '-'}
        />
      </Col>
    </Row>
  )
}

export default ContactFieldsDisplay

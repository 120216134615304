import { AreaChartOutlined, DeliveredProcedureOutlined, FileProtectOutlined, FileTextOutlined, NotificationOutlined, ProfileOutlined, ReadOutlined, ShoppingCartOutlined, SolutionOutlined } from '@ant-design/icons'
import { IRoute } from 'components/Sidebar/types'
import ProposalDetailsEgi from 'egi/app/ProposalEgi/views/ProposalDetailsEgi/ProposalDetailsEgi'

const documents: IRoute = {
  path: '/documents',
  name: 'Documentos',
  icon: DeliveredProcedureOutlined,
  component: ProposalDetailsEgi,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const proposalCheck: IRoute = {
  path: '/proposals/show/:id',
  name: 'Proposta',
  component: ProposalDetailsEgi,
  meta: {
    centered: false,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const marketingLeads: IRoute = {
  path: '/marketing-leads',
  name: 'Marketing Leads',
  icon: AreaChartOutlined,
  component: ProposalDetailsEgi,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const proposalMainPage: IRoute = {
  path: '/proposal',
  name: 'Sua Proposta',
  icon: SolutionOutlined,
  component: ProposalDetailsEgi,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const internalCommunication: IRoute = {
  path: '/internal/communication',
  name: 'Comunicação interna',
  icon: NotificationOutlined,
  component: ProposalDetailsEgi,
  meta: {
    centered: false,
    hidden: false,
    disabled: true,
    canGoBack: false
  }
}

const reportProviders: IRoute = {
  path: '/reports/providers',
  name: 'Relatório de Faturamento Prestadores',
  icon: ReadOutlined,
  component: ProposalDetailsEgi,
  meta: {
    centered: false,
    hidden: false,
    disabled: true,
    canGoBack: false
  }
}

const reportCommission: IRoute = {
  path: '/reports/commission',
  name: 'Relatório de Comissionamento',
  icon: ReadOutlined,
  component: ProposalDetailsEgi,
  meta: {
    centered: false,
    hidden: false,
    disabled: true,
    canGoBack: false
  }
}

const fiscal: IRoute = {
  path: '/fiscal',
  name: 'Emissão de Nota Fiscal',
  icon: ProfileOutlined,
  component: ProposalDetailsEgi,
  meta: {
    centered: false,
    hidden: false,
    disabled: true,
    canGoBack: false
  }
}

const compliance: IRoute = {
  path: '/compliance',
  name: 'Compliance',
  icon: FileProtectOutlined,
  component: ProposalDetailsEgi,
  meta: {
    centered: false,
    hidden: false,
    disabled: true,
    canGoBack: false
  }
}

const homeEquity: IRoute = {
  path: '/compliance',
  name: 'Home Equity',
  icon: ShoppingCartOutlined,
  component: ProposalDetailsEgi,
  meta: {
    centered: false,
    hidden: false,
    disabled: true,
    canGoBack: false
  }
}

const seguro: IRoute = {
  path: '/compliance',
  name: 'Seguro',
  icon: ShoppingCartOutlined,
  component: ProposalDetailsEgi,
  meta: {
    centered: false,
    hidden: false,
    disabled: true,
    canGoBack: false
  }
}

const consorcio: IRoute = {
  path: '/compliance',
  name: 'Consórcio',
  icon: ShoppingCartOutlined,
  component: ProposalDetailsEgi,
  meta: {
    centered: false,
    hidden: false,
    disabled: true,
    canGoBack: false
  }
}

const documentos: IRoute = {
  path: '/documentos',
  name: 'Documentos',
  icon: FileTextOutlined,
  component: ProposalDetailsEgi,
  meta: {
    centered: false,
    hidden: false,
    disabled: true,
    canGoBack: false
  }
}

const registro: IRoute = {
  path: '/registro',
  name: 'Registro',
  component: ProposalDetailsEgi,
  icon: SolutionOutlined,
  meta: {
    centered: false,
    hidden: false,
    disabled: true,
    canGoBack: false
  }
}

const products: IRoute = {
  path: '/products',
  name: 'Produtos',
  icon: ShoppingCartOutlined,
  component: ProposalDetailsEgi,
  meta: {
    centered: false,
    hidden: false,
    disabled: true,
    canGoBack: false
  }
}

const refinDisabled = {
  path: '/refinancing',
  name: 'Refinanciamento',
  icon: ShoppingCartOutlined,
  component: ProposalDetailsEgi,
  meta: {
    centered: false,
    hidden: false,
    disabled: true,
    canGoBack: false
  }
}

const portabilityDisabled = {
  path: '/portability',
  name: 'Portabilidade',
  icon: ShoppingCartOutlined,
  component: ProposalDetailsEgi,
  meta: {
    centered: false,
    hidden: false,
    disabled: true,
    canGoBack: false
  }
}

export {
  products,
  registro,
  documentos,
  consorcio,
  seguro,
  homeEquity,
  compliance,
  fiscal,
  reportCommission,
  reportProviders,
  documents,
  proposalCheck,
  marketingLeads,
  proposalMainPage,
  internalCommunication,

  portabilityDisabled,
  refinDisabled
}

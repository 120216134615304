import dayjs, { Dayjs } from 'dayjs'
import { ICovenantProposal } from 'ecp/app/Clients/clientInterfaces'
import { _typeOfDischarge } from 'ecp/app/Clients/views/ClientDismiss/ClientDismiss'
import { R$, valuerOrZero } from 'ecp/app/Proposals/proposalFunctions'
import {
  IDynamicProposal, IOnidataContract,
  IOnidataPayment, IParcels,
  IProposalDismiss,
  _proposalSteps,
  _rodobensKYCAPI,
  IOnidataAddress,
  _proposalCreateSteps,
  IRenegociationOriginalContract,
  IRodobensKYCProcess,
  _openStepWithAction
} from 'ecp/app/Proposals/proposalInterfaces'
import { ISimulationType } from 'ecp/components/SimulationEcp/simulationEcpInterfaces'
import { IAuth } from 'egi/types'
import { _proposalStatusLabel } from 'globals'

import format from 'utils/format'
import { timeAsDayjs } from 'utils/time'
import { IOnidataClient } from './ClientModel'
import { IOnidataCompany } from './CovenantModel'
import { _userLevel } from './UsersModel'

export type _onidataPaymentStatus = 'pago' | 'aguardando' | 'estornado'
export type _onidataProposalOrigem = 'novo-contrato' | 'portabilidade' | 'renegociacao'

export interface IProposalOnidata {
  contrato: IOnidataContract
  funcionario: IOnidataClient
  empresa: IOnidataCompany
  pagamento: IOnidataPayment
  recebiveis: []
  cliente_info_no_contrato: {
    endereco: IOnidataAddress
  }
  origem: _onidataProposalOrigem,
  autorizador_msg: string,
  status?: 'erro' | 'aprovada'
}

export interface IProposalAction {
  waiting: string,
  redirect: _openStepWithAction,
  clickAction: string
}

const ProposalModel = {
  getActualProposalStepIndex (lastStep: _proposalSteps): number {
    const steps = ['simulacao', 'analise-de-credito', 'assinatura-do-contrato',
      'averbacao', 'cip', 'aprovacao-do-juridico', 'pagamento',
      'crivo', 'melhore-seu-score', 'formalizacao'
    ] as _proposalSteps[]

    return steps.findIndex(step => step === lastStep)
  },

  returnCorrectEmailAgeCLass (api: _rodobensKYCAPI, score: number, item: IRodobensKYCProcess) {
    if (api === 'Email Age') {
      if (item.status?.description === 'Aprovado') return 'documental-process__email-age--green documental-process__email-age'
      if (item.status?.description === 'Risco Baixo') return 'documental-process__email-age--yellow documental-process__email-age'
      return 'documental-process__email-age--red documental-process__email-age'
    }
  },

  canAcceptContract (hasBankCreditNoteAgree: boolean, hasinstallmentDeductedFromPayrollAgree: boolean, hasAdhesionCreditInsuranceAgree: boolean, hasInsurance: boolean):boolean {
    return !hasBankCreditNoteAgree || !hasinstallmentDeductedFromPayrollAgree || (hasInsurance && !hasAdhesionCreditInsuranceAgree)
  },

  showSolicitationValue: function (userLevel: _userLevel, statusLabel: _proposalStatusLabel, origin: _onidataProposalOrigem) {
    const levels = ['master', 'RH', 'client']
    const hasValidLevel = levels.includes(userLevel)

    if (!hasValidLevel) return false

    if (userLevel === 'RH') {
      const statusLabels = ['efetivado', 'aprovado', 'emProgresso', 'cancelado', 'expirado', 'finalizado', 'encerrado']
      const hasStatusLabelValid = statusLabels.includes(statusLabel)
      const hasValidOrigin = origin !== 'portabilidade'
      return hasStatusLabelValid && hasValidOrigin
    }

    return true
  },

  showReleasedAmount: function (userLevel: _userLevel, origin: _onidataProposalOrigem) {
    if (userLevel === 'RH') {
      const hasValidOrigin = origin !== 'portabilidade'
      return hasValidOrigin
    }

    return true
  },

  showSignatureLocalization: function (userLevel: _userLevel, origin: _onidataProposalOrigem) {
    const levels = ['RH']
    const hasValidLEvel = levels.includes(userLevel)
    return hasValidLEvel && origin
  },

  showStatus: function (userLevel: _userLevel, statusLabel: string) {
    const hasValidLevel = userLevel !== 'RH'
    const hasStatusLabelValid = statusLabel !== 'reprovado' && statusLabel !== 'emProgresso'
    return hasValidLevel || hasStatusLabelValid
  },

  isContractTabAvailable: (origem: _onidataProposalOrigem): boolean => {
    return origem !== 'portabilidade'
  },

  showCovenantColumn: (userLevel: _userLevel) => (userLevel !== 'client'),

  showEndorsementColumn: function (userLevel: _userLevel, statusLabel: string) {
    const levels = ['backoffice', 'master', 'promoter', 'corban',
      'commercial', 'commission', 'pricing', 'administrator',
      'consultant', 'provider', 'seller', 'collaborators',
      'auditor', 'agx', 'operator', 'RH'
    ]

    const hasValidLevel = levels.includes(userLevel)
    if (!hasValidLevel) return false

    if (userLevel === 'RH') {
      const hasStatusLabelValid = statusLabel !== 'reprovado' && statusLabel !== 'emProgresso'
      return hasStatusLabelValid
    }

    return true
  },

  showChannelColumn: (userLevel: _userLevel) => (userLevel !== 'client' && userLevel !== 'RH'),

  showInsuranceFilter: (userLevel: _userLevel) => (userLevel === 'master' || userLevel === 'promoter' || userLevel === 'operator'),

  showInsuranceColumn: (userLevel: _userLevel) => (userLevel === 'master' || userLevel === 'promoter' || userLevel === 'operator'),

  showSubcorbanColumn: (userLevel: _userLevel) => (userLevel !== 'client' && userLevel !== 'RH'),

  showOperatorColumn: (userLevel: _userLevel) => (userLevel !== 'client' && userLevel !== 'RH'),

  showStep: (userLevel: _userLevel) => (userLevel !== 'RH'),

  showPaymentColumn: (userLevel: _userLevel) => (userLevel !== 'RH'),

  showChannelFilter: (userLevel: _userLevel) => (userLevel !== 'client' && userLevel !== 'RH'),

  showCurrentCommitment: (userLevel: _userLevel, statusLabel: _proposalStatusLabel | _proposalStatusLabel[] | undefined, origin: _onidataProposalOrigem) => {
    const hasValidLevel = userLevel === 'RH'
    if (!hasValidLevel) return false

    const hasStatusLabelValid = statusLabel === 'recusado' || statusLabel === 'emProgresso'
    if (!hasStatusLabelValid) return false

    if (statusLabel === 'emProgresso') {
      const hasValidOrigin = origin !== 'portabilidade'
      return hasValidOrigin
    }
    return true
  },

  showCommitmentAfterEndorsement: (userLevel: _userLevel, statusLabel: _proposalStatusLabel | _proposalStatusLabel[] | undefined, origin: _onidataProposalOrigem) => {
    const hasValidLevel = userLevel === 'RH'
    if (!hasValidLevel) return false

    const hasStatusLabelValid = statusLabel === 'recusado' || statusLabel === 'emProgresso'
    if (!hasStatusLabelValid) return false

    if (statusLabel === 'emProgresso') {
      const hasValidOrigin = origin !== 'portabilidade'
      return hasValidOrigin
    }
    return true
  },

  showRegistration: function (userLevel: _userLevel, statusLabel: _proposalStatusLabel) {
    const labels: _proposalStatusLabel[] = ['recusado', 'expirado', 'efetivado', 'cancelado', 'encerrado', 'aprovado', 'emProgresso']
    const hasValidLevel = userLevel === 'RH'
    const hasStatusLabelValid = labels.includes(statusLabel)
    return hasValidLevel && hasStatusLabelValid
  },

  showCellphone: function (userLevel: _userLevel, statusLabel: _proposalStatusLabel, origin: _onidataProposalOrigem) {
    const statusLabels = ['expirado', 'efetivado', 'cancelado', 'encerrado', 'aprovado', 'emProgresso']
    const hasValidLevel = userLevel === 'RH'
    if (!hasValidLevel) return false

    const hasStatusLabelValid = statusLabels.includes(statusLabel)
    if (!hasStatusLabelValid) return false

    if (statusLabel === 'emProgresso') {
      const hasOriginValid = origin === 'portabilidade'
      return hasOriginValid
    }

    return true
  },

  canIgnoreRHDeafaultFilters: function (userLevel: _userLevel): boolean {
    return userLevel === 'RH'
  },

  showBill: function (userLevel: _userLevel) {
    const levels = ['client', 'promoter', 'master', 'operator']
    return levels.includes(userLevel)
  },

  showSolicitationDate: function (userLevel: _userLevel, statusLabel: _proposalStatusLabel) {
    const hasValidLevel = userLevel !== 'RH'
    const hasStatusLabelValid = statusLabel !== 'recusado'
    return hasValidLevel || hasStatusLabelValid
  },

  showLimitEndorsementDate: function (userLevel: _userLevel, origin: _onidataProposalOrigem) {
    const hasValidLevel = userLevel === 'RH'
    const hasOriginValid = origin && origin !== 'portabilidade'
    return hasValidLevel && hasOriginValid
  },

  showOmniChannel: (userLevel: _userLevel) => (userLevel !== 'RH'),

  showRowSelection: function (userLevel: _userLevel, statusLabel: _proposalStatusLabel) {
    const statusLabels = ['emProgresso']
    const hasValidLevel = userLevel === 'RH'
    const hasStatusLabelValid = statusLabels.includes(statusLabel)
    return hasValidLevel && hasStatusLabelValid
  },

  showOrigin: (origin: _onidataProposalOrigem) => (!origin),

  showOriginFilter: (userLevel: _userLevel) => (userLevel !== 'RH'),

  showLastStepFIlter: (origin: _onidataProposalOrigem) => (!origin),

  canSeeSignedContrat: (statusLabel: _proposalStatusLabel, lastStep: _proposalSteps) => {
    return statusLabel === 'efetivado' || lastStep === 'averbacao'
  },

  showCCBCollumn: function (userLevel:_userLevel, statusLabel: _proposalStatusLabel, lastStep: _proposalSteps) {
    return (
      (userLevel === 'RH' && statusLabel === 'emProgresso' && lastStep === 'averbacao') || (userLevel === 'RH' && statusLabel === 'efetivado')
    )
  },

  showDenyEndorsementButton: (userLevel: _userLevel, selectedRowsLenght: number) => {
    return userLevel === 'RH' && selectedRowsLenght > 0
  },

  showApproveEndorsementButton: (userLevel: _userLevel, statusLabel: _proposalStatusLabel) => {
    return userLevel === 'RH' && statusLabel === 'emProgresso'
  },

  showBalanceToBeCarried: (userLevel: _userLevel, origin: _onidataProposalOrigem) => {
    return userLevel === 'RH' && origin === 'portabilidade'
  },

  showSubcorbanInfo: (userLevel: _userLevel) => (userLevel !== 'client'),

  showClientInformationFields: (selectedStep: _proposalCreateSteps) => {
    if (selectedStep === 'validate-token') return true
    return false
  },

  hasParcelsInput: (simulationType: ISimulationType) => (simulationType !== 'portability'),

  showProposalAntecipation: (userLevel: _userLevel) => (userLevel === 'client' || userLevel === 'master' || userLevel === 'promoter' || userLevel === 'operator'),

  approveEndorsementButtonText: (selectedRowsLenght: number) => {
    if (selectedRowsLenght > 0) return `Averbar Proposta${selectedRowsLenght > 1 ? 's' : ''} (${selectedRowsLenght})`
    return 'Averbar Propostas'
  },

  validateInstallmentStatus: (installment: IParcels): IParcels => {
    const isAfterDueDate = dayjs(installment.data_vencimento).isValid() && dayjs().isAfter(dayjs(installment.data_vencimento))
    const hasPaid = installment.pagamentos.length > 0

    if (isAfterDueDate && !hasPaid) {
      return {
        ...installment,
        status: 'Vencida'
      }
    }

    if (!hasPaid) {
      return {
        ...installment,
        status: 'Em aberto'
      }
    }

    return {
      ...installment,
      status: `Pago ${format.formatBRL((installment?.pagamentos?.[0]?.total_pago as number) ?? 0)}`
    }
  },

  showCorrectInstallments: (installments: IParcels[]) => {
    const openedInstalments = installments.filter((installment) => !installment.cancelado)
    return openedInstalments
  },

  parcelValue: (value: IParcels) => {
    if (value.valor_presente > value.valor_parcela) {
      return value.valor_presente
    } else {
      return value.valor_parcela
    }
  },

  formattedTax: (tax?: number) => {
    const months = 12
    if (!tax) return '-'
    return `${valuerOrZero(tax.toFixed(2).toString())} % (a.m.) / ${valuerOrZero((tax * months).toFixed(2).toString())}% (a.a)`
  },

  formatParcela: (contract?: { prazo: number, valor_recebivel: number }) => {
    if (!contract) return '-'
    const { prazo, valor_recebivel } = contract
    return `${valuerOrZero(prazo)}x de ${valuerOrZero(R$(valor_recebivel))}`
  },

  showProposalHistoryAuthor (userLevel: _userLevel) {
    return userLevel !== 'client'
  },

  isAverbationDateDisabled: (current: Dayjs, contractCreatedAt: string) => {
    if (!current) return true
    if (dayjs(contractCreatedAt).isToday()) return !dayjs(current).isToday()
    return current < dayjs().endOf('day').subtract(1, 'day') || current > dayjs().add(20, 'day')
  },

  monthlyCommitment: (simulation: { comprometido_mensal: number, total_mensal: number }) => {
    const { comprometido_mensal, total_mensal } = simulation
    return (comprometido_mensal / (comprometido_mensal + total_mensal) * 100)
  },

  salaryCommitmentAfterRegistration: (totalMonth: number, salary: number) => {
    return (totalMonth / salary) * 100
  },

  showProposalStatusLabelFilter: function (userLevel: _userLevel) {
    return userLevel !== 'RH'
  },

  proposalUrl: (statusLabel: _proposalStatusLabel | string, changedFilters: _proposalStatusLabel | string) => {
    if (changedFilters) return ''
    return statusLabel !== 'proposals' ? `?statusLabel=${statusLabel}` : ''
  },
  hasFilter: (statusLabel: _proposalStatusLabel | string) => statusLabel !== 'proposals',

  isFinalStepApproved: (proposalDetails: IDynamicProposal.Model | undefined) => {
    if (!proposalDetails?.departments) return false

    const finalSteps: _proposalSteps[] = ['formalizacao', 'pagamento']

    const steps = Object.values(proposalDetails.departments)?.[0]?.steps

    if (!steps) return false

    for (const stepKey of finalSteps) {
      const step = steps[stepKey]

      if (step && step.status === 'approved') {
        return true
      }
    }

    return false
  },

  proposalDismissDetails: (type: _typeOfDischarge, value: IProposalDismiss) => {
    if (type === 'boleto') {
      const date = value?.info_pagamento?.boletos?.[0]?.data_vencimento ?? null
      return {
        label: 'Data de vencimento',
        value: dayjs(date).isValid() ? timeAsDayjs(date).format('DD/MM/YYYY') : '-'
      }
    }

    if (type === 'lote') {
      const date = value?.info_pagamento?.lotes?.[0]?.mes_referencia ?? null
      return {
        label: 'Data do repasse',
        value: dayjs(date).isValid() ? timeAsDayjs(date).format('MM/YYYY') : '-'
      }
    }

    return {
      label: 'Data',
      value: '-'
    }
  },

  translate_proposal_values: {
    released: 'Valor liberado',
    financed: 'Valor financiado'
  },

  chooseCorrectCovenant: (covenantArray: ICovenantProposal[], userLevel: _userLevel, currentId?: string, selectedId?: string) => {
    if (covenantArray.length === 1) return covenantArray?.[0]
    const filterCovenant = covenantArray.find(convenio => {
      const covenantId = userLevel === 'client' ? currentId : selectedId
      return covenantId === convenio._id
    })
    return filterCovenant
  },

  canRedirectToSelectCovenantView: (client: IOnidataClient): boolean => {
    if (!client?.convenios) return false
    return client?.convenios?.length > 1
  },

  redirectToFindUserCpf: (client: IOnidataClient, userLevel: _userLevel) => {
    return !client?.cpf && userLevel !== 'client'
  },

  showStepSearchIdentifier: (userLevel: _userLevel) => (userLevel !== 'RH'),

  showReasonSelectInReview: (stepSlug: _proposalSteps) => (stepSlug === 'averbacao'),

  showValorSolicitadoInResume: (userLevel: _userLevel) => (userLevel === 'RH' || userLevel === 'promoter' || userLevel === 'operator'),

  keepRHFilters: (userLevel: _userLevel) => userLevel === 'RH',

  canSeeTokenStep: (userLevel: _userLevel, client?: IOnidataClient) => (userLevel !== 'client') && !client?.telefone_celular_verificado,

  hasOnidataLogsAccess: (userLevel: _userLevel) => {
    return ['agx', 'master'].includes(userLevel)
  },

  canSeeDocumentalHistoryLogs: (userLevel: _userLevel) => {
    return ['agx', 'master'].includes(userLevel)
  },

  canOnlySeeClientInstructions: (userLevel: _userLevel) => {
    const levels = ['client', 'promoter'].includes(userLevel)
    return levels
  },

  showCorrectClientAction: (stepSlug: _proposalSteps): IProposalAction => {
    if (stepSlug === 'analise-documental') {
      return {
        waiting: 'o envio de documentos',
        clickAction: 'enviar',
        redirect: 'openDocumental'
      }
    }

    return {
      waiting: 'sua assinatura',
      clickAction: 'assinar',
      redirect: 'openContract'
    }
  },

  canSeeOnidataStatusError: (status: string | undefined, userLevel: _userLevel): boolean => {
    return userLevel === 'master' && status === 'erro'
  },

  canSeeHasPendenciesWarning: (level: _userLevel) => {
    const levels: Array<_userLevel> = ['client', 'corbanFuncao', 'operatorFuncao']
    return levels.includes(level)
  },

  onSelectCovenantClientFactory (client: IOnidataClient, selectedCovenant?: ICovenantProposal) {
    if (!selectedCovenant) return client
    if (!selectedCovenant.clientOnidataId) return client

    return {
      ...client,
      id: selectedCovenant.clientOnidataId
    }
  }
}

const RenegotiationModel = {
  ...ProposalModel,

  simulationReleasedValue ({ valor_liberado, soma_contratos }: { valor_liberado: number, soma_contratos: number }) {
    return valor_liberado - soma_contratos
  },

  minReleasedValue: 300,
  canContinueSimulation (releasedValue: number): boolean {
    return releasedValue >= this.minReleasedValue
  },

  contractListParams: (client: IOnidataClient, options: { user: IAuth, selectedCovenant?: Partial<ICovenantProposal> }) => {
    const { user, selectedCovenant } = options
    if (user.level === 'client') return { covenantId: user.covenantId }
    return {
      search: format.onlyDigits(client?.cpf),
      covenantId: selectedCovenant?._id
    }
  },

  canShowRenegociationSection: (origin: _onidataProposalOrigem | undefined) => origin === 'renegociacao',
  canSeeAllContractsButton: (contracts: IRenegociationOriginalContract[]) => contracts?.length > 1
}

const PortabilityModel = {
  ...ProposalModel,
  canSearch: (userLevel: _userLevel) => userLevel === 'client',
  canGet: (userLevel: _userLevel) => userLevel === 'client',
  isClientCreating: (userLevel: _userLevel) => userLevel === 'client'
}

export { RenegotiationModel }
export { PortabilityModel }
export default ProposalModel

import React, { useState } from 'react'
import { Row, Col, Form, Button } from 'antd'
import { _proposalStatuses } from 'egi/app/ProposalEgi/proposalEgiInterfaces'
import usePartnerDocumentation from '../../hooks/usePartnerDocumentation'
import usePartnerDepartments from '../../hooks/usePartnerDepartments'
import usePartnerStep from '../../hooks/usePartnerStep'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'
import PartnerModel from 'egi/models/PartnerModel'
import PartnersRepository from 'egi/repositories/PartnersRepository'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'
import { StepSectionTitle } from 'egi/app/ProposalEgi/components/StepRenders/StepRenders'
import ReviewRadioStatus from 'components/ReviewRadioStatus/ReviewRadioStatus'
import { _reviewStatusOption } from 'components/ReviewBackoffice/reviewBackofficeInterfaces'
import RichTextEditor from 'components/RichTextEditor/RichTextEditor'

function ReviewStep ({ onSuccess }: { onSuccess(): void}) {
  const [loading, setLoading] = useState<boolean>(false)
  const [editorValue, setEditorValue] = useState('')
  const [status, setStatus] = useState<_reviewStatusOption>('')
  const [errors, setErrors] = useState<{status?: string, description?: string}>({})
  const [formRef] = Form.useForm()

  const { step } = usePartnerStep()
  const { slug: departmentSlug } = usePartnerDepartments()
  const { _id: documentId } = usePartnerDocumentation()
  const reviewStep = PartnerModel.canReview(step)

  const onChangeStatus = (value: _reviewStatusOption) => setStatus(value)

  async function onSubmit () {
    setLoading(true)

    const data = {
      department: departmentSlug,
      step: step.slug,
      status,
      description: editorValue
    }

    try {
      const response = await PartnersRepository.review(documentId, data)
      swal.basic({ title: 'Sucesso!', icon: 'success', text: response.data.message })
      onSuccess()
    } catch (err) {
      if (err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
      } else {
        swal.basic({ title: 'Atenção!', icon: 'warning', text: err.message })
      }
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  function validateSelect (status: _proposalStatuses) {
    return step.permissionConfiguration[step.status] && step.permissionConfiguration[step.status].includes(status)
  }

  const options = [
    ...(validateSelect('approved') ? [{ value: 'approved', label: 'Aprovado' }] : []),
    ...(validateSelect('reproved') ? [{ value: 'reproved', label: 'Reprovado' }] : []),
    ...(validateSelect('remaking') ? [{ value: 'remaking', label: 'Refazer' }] : [])
  ]

  if (!reviewStep) return null

  return (
    <Form
      layout='vertical'
      form={formRef}
      onFinish={onSubmit}
    >
      <div className="review-step">
        <section className='mb-3'>
          <StepSectionTitle title="Adicionar nova revisão" />
        </section>

        <Row gutter={15}>
          <Col span={24}>
            <Form.Item
              name='status'
              help={errors.status && errors.status}
              validateStatus={errors.status && 'error'}
              rules={[{ required: true, message: 'Escolha o status' }]}
              label='Novo status'
            >
              <ReviewRadioStatus
                statusOptions={options}
                onChange={onChangeStatus}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col lg={24} sm={24} xs={24}>
            <Form.Item
              help={errors.description && errors.description}
              validateStatus={errors.description && 'error'}
              label={<span className='bold'>Revisão interna do passo</span>}
              className='review__rich'
            >
              <RichTextEditor
                onChange={setEditorValue}
                setContents={editorValue}
                disable={!reviewStep}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>

      <div className='flex flex-justify-end'>
        <Button
          type='primary'
          htmlType='submit'
          loading={loading}
          disabled={!reviewStep}
        >
          Enviar
        </Button>
      </div>
    </Form>
  )
}

export default ReviewStep

import { Tag } from 'antd'
import commonColumns from 'components/UserTable/utils/commonColumns'
import { _preApprovalStatus } from 'globals'
import React from 'react'
import { varColors } from 'styles/colors'
import format from 'utils/format'
import translate from 'utils/translate'

export const columns = [
  {
    title: 'Data da simulação',
    dataIndex: 'createdAt'
  },
  {
    title: 'Passo atual',
    dataIndex: 'currentStep'
  },
  commonColumns.email,
  {
    title: 'Possui pré-aprovação',
    dataIndex: 'crivoId',
    render: (value: boolean) => value ? 'Sim' : 'Não'
  },
  {
    title: 'Status pré-aprovação',
    dataIndex: 'preApproval',
    render: (value: _preApprovalStatus) => {
      return <Tag color={varColors[value]}>{translate.preApproval(value)}</Tag>
    }
  },
  {
    title: 'Feito por parceiro',
    dataIndex: 'promoterId',
    render: (value: boolean) => value ? 'Sim' : 'Não'
  },
  {
    title: 'Valor do financiamento',
    dataIndex: 'financing',
    render: (value: boolean) => value ? format.formatBRL(Number(value)) : 'Não possui'
  }
]

import DisplayTerms from 'components/DisplayTerms/DisplayTerms'
import LgpdText from 'components/LgpdText/LgpdText'
import { EcpCard } from 'ecp/components/EcpCard/EcpCard'
import React from 'react'

function LgpdTerms () {
  return (
    <EcpCard>
      <h3>
        <b>Termo LGPD
        </b>
      </h3>
      <DisplayTerms>
        <LgpdText />
      </DisplayTerms>
      <div className="text-center mt-3">
          Esta política de cookies foi criada e atualizada por <a href="https://agxsoftware.com/" target="_blank" rel="noreferrer">UX Vision Tech</a>.
      </div>
    </EcpCard>
  )
}

export default LgpdTerms

import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { CloseOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { useAuth } from 'hooks'
import format from 'utils/format'
import commonColumns, { commomNameOverflowWidth } from 'components/UserTable/utils/commonColumns'
import { OverflowTooltip } from 'components/OverflowToolTip/OverflowTooltip'

const columns = (handleDelete: Function): ColumnsType<Object> => {
  return [
    {
      ...commonColumns.name,
      render: (text: string, row: any) => (
        <>
          <OverflowTooltip width={commomNameOverflowWidth} title={text}>
            <Link
              className="underline color-tertiary font-500 capitalize"
              to={`/auth/attorney/show/${row._id}`}
            >
              {text.toLowerCase()} &rarr;
            </Link>
          </OverflowTooltip>

          {row?.cpf ? row?.cpf : '-'}
        </>
      )
    },
    {
      title: 'RG',
      width: 100,
      dataIndex: 'rg',
      render: (rg: string) => format?.formatRg(rg)
    },
    commonColumns.email,
    { ...commonColumns.createdAt },
    {
      title: 'Nacionalidade',
      dataIndex: 'nationality',
      width: 250,
      ellipsis: true,
      render: (nacionality: string) => {
        if (!nacionality) return '-'
        return format.capitalize(nacionality)
      }
    },
    {
      title: 'Profissão',
      dataIndex: 'occupation',
      width: 250,
      ellipsis: true,
      render: (occupation: string) => {
        if (!occupation) return '-'
        return format.capitalize(occupation)
      }
    },
    {
      title: 'Estado civil',
      width: 100,
      dataIndex: 'maritalStatus',
      render: (maritalStatus: string) => {
        if (!maritalStatus) return '-'
        return maritalStatus
      }
    },
    {
      title: '',
      dataIndex: '_id',
      width: 80,
      render: (id: string, row: any) => {
        const user = useAuth()
        const IS_AUDITOR = user.level === 'auditor'
        return (
          <CloseOutlined
            onClick={() => {
              if (IS_AUDITOR) return
              handleDelete(id, row.name)
            }}
          />
        )
      }
    }
  ]
}

export { columns }

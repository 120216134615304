import React from 'react'

import SettingsCard from 'egi/app/Settings/components/SettignsCard/SettingsCard'
import SimulationConfigurationForm from '../../components/SimulationConfigurationForm/SimulationConfigurationForm'

function SimulationConfiguration () {
  return (
    <div >
      <SettingsCard.Header
        title="Valores da simulação"
        description={
          <p>
            Altere aqui os valores mínimos e máximos para a simulação da proposta. É possível alterar tanto o valor do imóvel quanto o valor de crédito
          </p>
        } />
      <SimulationConfigurationForm/>
    </div>
  )
}

export default SimulationConfiguration

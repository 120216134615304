import React, { useEffect } from 'react'
import ProposalStepButtonWithHistory from 'ecp/app/Proposals/components/ProposalStepButtonWithHistory/ProposalStepButtonWithHistory'
import ProposalStepButton from 'ecp/app/Proposals/components/ProposalStepButton/ProposalStepButton'
import useThisStep from 'ecp/app/Proposals/hooks/useThisStep'
import SignatureStepCompose, { useSignatureStepContext } from './SignatureStepCompose'
import SignatureStepActions from './SignatureStepActions'
import usePendentProposal from 'ecp/app/Proposals/hooks/usePendentProposals'

const Content = () => {
  const { step } = useThisStep()
  const { handleOpen } = useSignatureStepContext()
  const { setCurrentStepToOpen } = usePendentProposal()
  const canOpen = step.canOpen

  useEffect(() => {
    setCurrentStepToOpen(step.slug, handleOpen)
  }, [])

  return (
    <ProposalStepButtonWithHistory>
      <ProposalStepButton
        onClick={handleOpen}
        enabled={canOpen}
      >
        {step.name}
      </ProposalStepButton>
      <SignatureStepActions/>
    </ProposalStepButtonWithHistory>
  )
}

function SignatureStep () {
  const { department, step } = useThisStep()

  const props = {
    department,
    step,
    initialActions: null
  }

  return (
    <SignatureStepCompose
      {...props}
    >
      <Content />
    </SignatureStepCompose>
  )
}

export default SignatureStep


import Modal from 'antd/lib/modal/Modal'
import { ColumnsType } from 'antd/lib/table'

import { Table } from 'components'

import React, { useMemo } from 'react'

interface IProps {
  visible: boolean
  onCancel: () => void

}

function NotaryFeesHintModal ({ visible, onCancel }: IProps) {
  const data = [
    {
      subtitle: 'O nome do arquivo deve ser custas_cartorarias_V + ano',
      example: 'custas_cartorarias_V2023.xlsx'
    },
    {
      subtitle: 'O formato do arquivo deve ser .xls ou .csv',
      example: 'custas_cartorarias_V2023.csv'
    },

    {
      subtitle: 'Campo estado deve ser sempre a sigla em letras maiúsculas',
      example: 'SP'
    },
    {
      subtitle: 'Campo "De" deve ser um valor númerico',
      example: '102780,00'
    },
    {
      subtitle: 'Campo "Até" deve ser um valor númerico',
      example: '34260,01'
    },

    {
      subtitle: 'Campo "Valor do custo" deve ser um valor númerico',
      example: '1328,2'
    },
    {
      subtitle: 'Não deve haver lacunas entre os valores numéricos nos campos referentes ao mesmo estado',
      example: ' Se o campo Até da linha 15 for 3000, o campo De da linha 16 deve ser 3000,01 (Desde que eles sejam referentes ao mesmo estado)'
    }
  ]

  const columns: ColumnsType = useMemo(() => [
    {
      title: 'Legenda',
      dataIndex: 'subtitle',
      align: 'left'
    },
    {
      title: 'Exemplo',
      dataIndex: 'example',
      width: '400px'
    }
  ], [])
  return (
    <Modal
      width="1000px"
      title="Ver exemplo"
      visible={visible}
      destroyOnClose={true}
      onCancel={onCancel}
      footer={
        <></>
      }
    >

      <h3
        className='bold color-primary'>
        Exemplos
      </h3>
      <Table
        showPagination={false}
        canSearch={false}
        data={data}
        columns={columns}
        loading={false}
        className='proposal-configuration__table'
      />

    </Modal>

  )
}

export default NotaryFeesHintModal

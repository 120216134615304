import { CheckOutlined, DownOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { AuthorizationsUser } from 'ecp/repositories/LoginRepository'
import { LevelIcon } from 'egi/app/Signin/components/SelectLevel/SelectLevel'
import React, { ButtonHTMLAttributes, ReactNode, useState } from 'react'
import translate from 'utils/translate'

type Props = {
  authorizations: AuthorizationsUser[]
  userId: string
  onItemSelect: (levelId: string) => Promise<void>
  loading: boolean
}

type ISelectLevelButtonProps = {
  children: ReactNode | string
  selectedLevel?: boolean
  loading?: boolean
  newSelected?: boolean
} & ButtonHTMLAttributes<HTMLButtonElement>

function SelectLevelButton ({ newSelected, selectedLevel, loading, children, ...rest }: ISelectLevelButtonProps) {
  const cssProps = {
    selectedLevel: selectedLevel ? 'navbar-select-level__button--selected' : ''
  }

  return (
    <button
      {...rest}
      className={`navbar-select-level__button ${cssProps.selectedLevel} mb-1`}
      disabled={loading}
    >
      {children}
      {loading && newSelected && <Spin className='product-item-button__icon' />}
      {selectedLevel && <CheckOutlined className='product-item-button__icon' />}
    </button>

  )
}

export default function NavbarSelectLevel ({ userId, authorizations, onItemSelect, loading }: Props) {
  const [newSelected, setNewSelected] = useState<Partial<AuthorizationsUser>>({})
  const [collapsed, setCollapsed] = useState(false)
  const cssProps = {
    collapsableIcon: collapsed ? 'navbar-select-level__collapsable-icon--collapsed' : '',
    collapsableLevelsContainer: collapsed ? 'navbar-select-level__possible-levels-container--collapsed' : ''
  }
  return (
    <div className='navbar-select-level w-100'>
      <div
        onClick={() => setCollapsed(prev => !prev)}
        className='flex flex-justify-between flex-align-cente mb-3'
      >
        <p>Seus níveis</p>
        <DownOutlined className={`navbar-select-level__collapsable-icon ${cssProps.collapsableIcon}`} />
      </div>

      <div className={`navbar-select-level__possible-levels-container ${cssProps.collapsableLevelsContainer}`}>
        {Array.isArray(authorizations) && authorizations.map(authorization => (
          <SelectLevelButton
            loading={loading}
            selectedLevel={userId === authorization._id}
            key={authorization._id}
            onClick={async () => {
              setNewSelected(authorization)
              await onItemSelect(String(authorization._id))
            }}
            newSelected={authorization._id === newSelected._id}
          >
            <LevelIcon level={authorization.level}/>

            <div className='ml-2 navbar-select-level__display-user-level'>
              <div className='navbar-select-level__user-level-name'>
                {translate.level(authorization.level)}
              </div>
              <div className='navbar-select-level__user-covenant-name'>
                {authorization?.covenant?.fantasyName}
              </div>
            </div>
          </SelectLevelButton>
        ))}
      </div>
    </div>
  )
}

import { Button, Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import Modal from 'antd/lib/modal/Modal'
import MultiSelect from 'components/MultiSelect/MultiSelect'
import ResourcesRepository from 'egi/repositories/ResourcesRepository'
import { AccessManagementProducts } from 'egi/repositories/UsersEgiRepository'
import React, { useEffect, useState } from 'react'
import translate from 'utils/translate'
import { useAccessManagerBackofficeUpdateProducts } from '../../hooks/useAccessManager'

type Props = {
  products?: Array<AccessManagementProducts>,
  userId?: string
  visible: boolean
  onCancel: () => void
  onFinish?: () => void
}

export default function BackofficeSelectProductsModal ({ products, userId, visible, onCancel, onFinish }: Props) {
  const [form] = useForm()

  const [selectOptions, setSelectOptions] = useState<Array<{ value: string, label: string }>>([])
  const [loadingSelectOptions, setLoadingSelectOptions] = useState(false)
  const [selectOptionError, setSelectOptionError] = useState('')

  const { loading, onUpdateProducts, errors } = useAccessManagerBackofficeUpdateProducts()

  const handleOnFinish = () => {
    form.resetFields()
    onFinish && onFinish()
    onCancel()
  }

  useEffect(() => {
    const getProducts = async () => {
      try {
        setLoadingSelectOptions(true)
        const response = await ResourcesRepository.getAllProducts()
        const products = response.data.data?.products

        setSelectOptions(
          (products ?? []).map((product) => ({
            value: product.slug,
            label: translate?.productSlug(product.slug)
          }))
        )

        setSelectOptionError('')
      } catch (error) {
        setSelectOptionError(error?.message)
        setSelectOptions([])
        console.log({ error })
      } finally {
        setLoadingSelectOptions(false)
      }
    }

    getProducts()
  }, [])

  return (
    <>
      <Modal
        title="Alterar produtos do usuário"
        visible={visible}
        destroyOnClose
        onCancel={onCancel}
        footer={null}>
        <Form
          onFinish={(values) => {
            onUpdateProducts({
              products: values.products,
              id: userId,
              onFinish: handleOnFinish
            })
          }}
          layout="vertical"
          form={form}
        >
          <Form.Item
            initialValue={products}
            label="Escolha os produtos que o usuário terá acesso"
            name='products'
            help={errors?.products && (errors.products)}
          >
            <MultiSelect
              error={selectOptionError}
              loading={loadingSelectOptions}
              showSearch
              options={selectOptions ?? []}
              placeholder="Escolha os produtos..."
            />
          </Form.Item>

          <div className="flex flex-space-between">
            <Button
              type="ghost"
              onClick={() => onCancel()}
            >
              Cancelar
            </Button>

            <Button
              loading={loading}
              className="ml-2"
              type='primary'
              htmlType="submit"
            >
              Salvar
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  )
}

import React, { useEffect, useMemo, useState } from 'react'
import DrawerFiltersLineCollapsable, { DrawerFiltersLineCollapsableItem } from 'components/DrawerFilters/DrawerFiltersLineCollapsable'
import { Form, Radio, Checkbox, Button, Empty } from 'antd'
import DatepickerCustom from 'components/DatepickerCustom/DatepickerCustom'
import locale from 'antd/es/date-picker/locale/pt_BR'
import { useTables } from 'hooks'
import { useDispatch } from 'react-redux'
import { tablesSetFiltersValues, tablesUpdate } from 'store/modules/tables/actions'
import ServerTableFilters from 'components/ServerTable/ServerTableFilters'
import DrawerFilters from 'components/DrawerFilters/DrawerFilters'
import { useForm } from 'antd/lib/form/Form'
import { formatFieldsForm } from 'utils/utils'
import FilterSearchIdentifier from 'components/SearchFilterBySelect/FilterSearchIdentifier'
import ReRequestButton from 'components/ReRequest/ReRequest'
import dayjs, { Dayjs } from 'dayjs'
import { useSetFilters } from 'hooks/useSetFilters'
import { MasterFiltersEntriesToIgnore, MasterFiltersKeysIgnore, MASTER_INITIAL_FILTERS } from '../../MasterConstants'
import { hasFilterCounter } from 'components/ServerTable/function'
import { resources } from 'egi/consumers'
import { formatMasterFilters } from '../../Masterfunctions'
import { PlusOutlined } from '@ant-design/icons'

import { ModalInvite } from 'components'

const identifiers = [
  { label: 'Nome', value: 'name' },
  { label: 'Email', value: 'email' },
  { label: 'CPF', value: 'cpf' },
  { label: 'Celular', value: 'cellphone' }
]

const disabledDateTo = (date: Dayjs, value: string) => {
  if (!value) return date.isAfter(dayjs())
  return date.isAfter(dayjs()) || date.isBefore(dayjs(value))
}

const disabledDateOf = (date: Dayjs, value: string) => {
  return date.isAfter(dayjs()) || date.isAfter(dayjs(value).endOf('d'))
}

interface ISelect {
  value: string
  label: string
}

function DrawerProductsSelect () {
  const [products, setProducts] = useState<ISelect[]>()
  const [error, setError] = useState<string>('')

  const getProducts = async () => {
    try {
      const productsRequest = await resources.products()
      const products = productsRequest.data?.products
      const allProducts = products.map((product) => { return { label: product.name, value: product.slug } })
      if (allProducts.length === 0) throw new Error('Nenhum produto disponível')
      setProducts(allProducts)
    } catch (err) {
      setError(err.message)
    }
  }

  useEffect(() => {
    getProducts()
  }, [])

  if (error) {
    return (
      <DrawerFiltersLineCollapsable label='Produtos'>
        <Empty className="m-4" description={error} />
      </DrawerFiltersLineCollapsable>
    )
  }

  return (
    <DrawerFiltersLineCollapsable label='Produtos'>
      {products?.map(({ value, label }) => (
        <DrawerFiltersLineCollapsableItem key={label}>
          <Checkbox
            value={value}
          >
            {label}
          </Checkbox>
        </DrawerFiltersLineCollapsableItem>
      ))}

    </DrawerFiltersLineCollapsable>
  )
}

function MasterDrawerFields () {
  const tables = useTables()

  return (
    <>
      <DrawerFiltersLineCollapsable label='Data de Criação'>
        <DrawerFiltersLineCollapsableItem>
          <Form.Item
            label="De"
            id='createdAtStart'
            name='createdAtStart'
          >
            <DatepickerCustom
              locale={locale}
              format='DD/MM/YYYY'
              disabledDate={date => disabledDateOf(date, tables.filters.data_criacao_antes_de)}
            />
          </Form.Item>
        </DrawerFiltersLineCollapsableItem>

        <DrawerFiltersLineCollapsableItem>
          <Form.Item
            label="Até"
            id='createdAtEnd'
            name='createdAtEnd'
          >
            <DatepickerCustom
              locale={locale}
              format='DD/MM/YYYY'
              disabledDate={date => disabledDateTo(date, tables.filters.data_criacao_depois_de)}
            />
          </Form.Item>
        </DrawerFiltersLineCollapsableItem>
      </DrawerFiltersLineCollapsable>
      <Form.Item
        name='products'
        initialValue={undefined}
      >
        <Checkbox.Group>
          <DrawerProductsSelect />
        </Checkbox.Group>
      </Form.Item>

      <Form.Item
        name='blocked'
        initialValue={undefined}
      >
        <DrawerFiltersLineCollapsable label='Usuários bloqueados'>
          <Radio.Group>
            <DrawerFiltersLineCollapsableItem key='all'>
              <Radio value=''>Todos</Radio>
            </DrawerFiltersLineCollapsableItem>

            <DrawerFiltersLineCollapsableItem key='true'>
              <Radio value='true'>Sim</Radio>
            </DrawerFiltersLineCollapsableItem>

            <DrawerFiltersLineCollapsableItem key='false'>
              <Radio value='false'>Não</Radio>
            </DrawerFiltersLineCollapsableItem>
          </Radio.Group>
        </DrawerFiltersLineCollapsable>
      </Form.Item>

      <Form.Item
        name='emailVerified'
        initialValue={undefined}
      >
        <DrawerFiltersLineCollapsable label='Email Verificado'>
          <Radio.Group>
            <DrawerFiltersLineCollapsableItem key='all'>
              <Radio value=''>Todos</Radio>
            </DrawerFiltersLineCollapsableItem>

            <DrawerFiltersLineCollapsableItem key='true'>
              <Radio value='true'>Sim</Radio>
            </DrawerFiltersLineCollapsableItem>

            <DrawerFiltersLineCollapsableItem key='false'>
              <Radio value='false'>Não</Radio>
            </DrawerFiltersLineCollapsableItem>
          </Radio.Group>
        </DrawerFiltersLineCollapsable>
      </Form.Item>
    </>
  )
}

function MasterFilters () {
  const [form] = useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const dispatch = useDispatch()
  const tables = useTables()
  const { withOutSearch } = useSetFilters()

  function clearFilters () {
    form.resetFields()
    dispatch(tablesSetFiltersValues(MASTER_INITIAL_FILTERS))
  }

  const filterCounter = useMemo(() => {
    form.setFieldsValue(formatFieldsForm(tables.filters))

    return Object
      .entries(tables.filters)
      .filter(hasFilterCounter(MasterFiltersKeysIgnore, MasterFiltersEntriesToIgnore))
      .length
  }, [JSON.stringify(tables.filters)])

  useEffect(() => {
    form.setFieldsValue(tables.filters)
  }, [])

  return (
    <Form
      layout='vertical'
      form={form}
      onValuesChange={
        (changedValues, values) => {
          return withOutSearch(changedValues, formatMasterFilters(values))
        }
      }
    >
      <ServerTableFilters>

        <FilterSearchIdentifier
          placeholder='Escolha o que deseja pesquisar'
          key='identifier'
          label='Pesquisar'
          identifiers={identifiers}
          onReset={() => {
            form.setFieldsValue({ search: '' })
          }}
          onChange={(search, identifier) => {
            dispatch(tablesSetFiltersValues({ ...tables.filters, search, identifier, page: 1 }))
          }}
        />

        <ServerTableFilters>
          <ReRequestButton
            onClick={() => {
              dispatch(tablesUpdate())
            }}
          />
          <Button type="primary"
            onClick={() => setModalVisible(true)}>
            <PlusOutlined /> Convidar Master
          </Button>

          <ModalInvite
            level={'master'}
            onClose={() => setModalVisible(false)}
            visible={modalVisible}
            invitedLevel={'master'}
          />

          <DrawerFilters
            onClear={clearFilters}
            counter={filterCounter}

          >
            <MasterDrawerFields />
          </DrawerFilters>

        </ServerTableFilters>
      </ServerTableFilters>
    </Form>
  )
}

export { MasterFilters }

import React, { Fragment, useEffect, useState } from 'react'
import { Divider, Col, Typography, Form, Row, Button, Checkbox, Empty } from 'antd'
import { Input, DocumentsView } from 'components'
import ChooseContractComponent from '../ChooseContractComponent'
import { ccbParticularesWitness, valuesFormatToDate, valuesToFormatOnlyAlphaNumeric } from '../contractFields'
import { IOnidataContractErrors } from '../contract'
import locale from 'antd/es/date-picker/locale/pt_BR'
import { ArrowRightOutlined, LoadingOutlined } from '@ant-design/icons'
import { useProposal, useStep } from 'hooks'
import time, { correctTimezone, timeAsDayjs } from 'utils/time'
import { proposals } from 'egi/consumers'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'
import translate from 'utils/translate'
import { useDispatch } from 'react-redux'
import { stepForm } from 'store/modules/step/actions'
import Datepicker from 'components/Datepicker/Datepicker'
import { swalError } from 'components/SwalError/SwalError'
import format from 'utils/format'
import translateAntForm from 'utils/translateAntForm'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import CertificateTextSelect from 'components/SelectFrameType/SelectFrameType'
import RichTextEditor from 'components/RichTextEditor/RichTextEditor'

interface ICNDValues {
  [key: string]: any
  ccb_particulares_certidoes: string
  ccb_particulares_existe_testemunhas: boolean
  ccb_particulares_imovel_cnd_municipais_emissao: string
  ccb_particulares_imovel_cnd_municipais_validade: string
  ccb_particulares_imovel_cnd_municipais_numero: string
  ccb_particulares_imovel_certidao_acoes_reais_pessoais_emissao: string
  ccb_particulares_primeiro_testemunha_nome: string
  ccb_particulares_primeiro_testemunha_rg: string
  ccb_particulares_primeiro_testemunha_local_de_expedicao: string
  ccb_particulares_primeiro_testemunha_cpf: string
  ccb_particulares_primeiro_testemunha_estado_civil: string
  ccb_particulares_segundo_testemunha_nome: string
  ccb_particulares_segundo_testemunha_rg: string
  ccb_particulares_segundo_testemunha_local_de_expedicao: string
  ccb_particulares_segundo_testemunha_cpf: string
  ccb_particulares_segundo_testemunha_estado_civil: string
}

interface ICNDList {
  ref: string
  values: {
    cndf?: {
      dueDate: string
      issueDate: string
      number: string
      paths?: string[]
    }
    cndt?: {
      dueDate: string
      issueDate: string
      number: string
      paths?: string[]
    }
  }
}

function CNDStep ({ readOnly, onSuccess }: {readOnly?: boolean, onSuccess: (value: boolean) => void}) {
  const [errors, setErrors] = useState<IOnidataContractErrors>({})
  const [status, setStatus] = useState<'sending' | 'loading' | undefined>(undefined)
  const [cndList, setCndList] = useState<ICNDList[]>([])
  const [cndText, setCndText] = useState<string | undefined>(undefined)
  const [hasWitnesses, setHasWitnesses] = useState<boolean>(false)

  const [formRef] = Form.useForm()
  const proposal = useProposal()
  const dispatch = useDispatch()
  const step = useStep()

  function onError (err: any) {
    swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    setStatus(undefined)
  }

  const onSubmit = async (values: ICNDValues) => {
    setStatus('sending')
    setErrors({})

    try {
      if (!proposal.id) throw new Error('Falha ao encontrar id da proposta')
      if (!step.form) throw new Error('Falha ao encontrar form')
      if (!step.id) throw new Error('Falha ao encontrar step id')
      if (!step.areaId) throw new Error('Falha ao encontrar area Id')
      if (!cndText) throw new Error('Texto das certidões não informado!')

      if (cndText) values.ccb_particulares_certidoes = cndText
      if (hasWitnesses) values.ccb_particulares_existe_testemunhas = hasWitnesses
      else values.ccb_particulares_existe_testemunhas = false

      valuesFormatToDate.forEach(item => {
        if (values[item]) values[item] = time(correctTimezone(values[item]))
      })

      valuesToFormatOnlyAlphaNumeric.forEach(item => {
        if (values[item]) values[item] = format.onlyAlphaNumeric(values[item])
      })

      const props: any = {
        form: { ...step.form, ...values, finished: false, currentStep: 7 },
        proposalId: proposal.id,
        stepId: step.id,
        areaId: step.areaId
      }

      const response = await proposals.send(props)
      dispatch(stepForm({ data: values }))
      onSuccess(true)
      setStatus(undefined)
      swal.basic({ title: 'Sucesso!', text: response.message, icon: 'success' })
    } catch (err) {
      if ([
        'Falha ao encontrar id da proposta',
        'Falha ao encontrar form',
        'Falha ao encontrar step id',
        'Falha ao encontrar area Id',
        'Texto das certidões não informado!'
      ].includes(err.message)) return onError(err)

      if (err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
      } else {
        swalError({ title: 'Atenção', icon: 'warning', err })
      }

      setStatus(undefined)
    }
  }

  const onGenerateFrameModel = (value: string) => {
    formRef.setFieldsValue({ ccb_particulares_certidoes: value })
    setCndText(value)
  }

  useEffect(() => {
    async function getCNDs () {
      setStatus('loading')

      try {
        const response = await proposals.getCNDs({ proposalId: proposal.id })
        setCndList(response.data.cnds)
        setStatus(undefined)
      } catch (err) {
        setStatus(undefined)
        swal.basic({ title: 'Sucesso!', text: err.message, icon: 'warning' })
      }
    }

    function onFill () {
      try {
        const tempForm = JSON.parse(JSON.stringify(step.form))

        valuesFormatToDate.forEach(item => {
          if (tempForm[item]) tempForm[item] = timeAsDayjs(tempForm[item], { applyTimezone: false }).format('DD/MM/YYYY')
        })

        if (tempForm.ccb_particulares_certidoes) setCndText(tempForm.ccb_particulares_certidoes)
        if (tempForm.ccb_particulares_existe_testemunhas === true) setHasWitnesses(true)

        if (formRef) formRef.setFieldsValue({ ...tempForm })
      } catch (err) {
        swal.basic({ title: 'Sucesso!', text: err.message, icon: 'warning' })
      }
    }

    getCNDs()
    onFill()
  }, [])

  return (
    <Fragment>
      <Form
        form={formRef}
        layout="vertical"
        onFinish={onSubmit}
        className="form-contract mt-5"
        initialValues={{
          ccb_particulares_existe_testemunhas: false
        }}
      >
        <Row gutter={[15, 15]}>
          {status === 'loading' && (
            <Row justify="center" className="w-100 my-4">
              <LoadingOutlined style={{ fontSize: 20 }} />
            </Row>
          )}

          {cndList.length < 1 && status !== 'loading' && (
            <Row justify="center" className="w-100">
              <Empty description="Nenhuma CND encontrada." />
            </Row>
          )}

          {Array.isArray(cndList) && cndList.length > 0 && cndList.map((item, idx) => (
            <Fragment key={idx} >
              <Col span={24}>
                <Typography.Title
                  className="form-section-title form-section-title--primary"
                  level={4}
                >
                  {translate.translatePerson(item.ref)}
                </Typography.Title>
              </Col>

              <Col span={24}>
                <Typography.Title level={5} className="bold">
                  CND Tributos Federais
                </Typography.Title>

                <Row gutter={[15, 15]} justify="start">
                  <Col>
                    <b>Data de emissão:</b> {item.values.cndf && item.values.cndf.issueDate ? timeAsDayjs(item.values.cndf?.issueDate).format('DD/MM/YYYY') : '-'}
                  </Col>

                  <Col>
                    <b>Data de vencimento:</b> {item.values.cndf && item.values.cndf?.dueDate ? timeAsDayjs(item.values.cndf?.dueDate).format('DD/MM/YYYY') : '-'}
                  </Col>

                  <Col>
                    <b>Número da Certidão:</b> {item.values.cndf && item.values.cndf.number ? item.values.cndf.number : '-'}
                  </Col>
                </Row>

                <Row className="gap-2">
                  {item.values.cndf && Array.isArray(item.values.cndf.paths) && item.values.cndf.paths.length > 0 && item.values.cndf.paths.map((paths) => (
                    <DocumentsView isOnlyLink={paths} key={paths} />
                  ))}
                </Row>
              </Col>

              <Divider/>
            </Fragment>
          ))}

          <Col span={24}>
            <CertificateTextSelect
              proposalId={proposal.id}
              onGenerateFrameModel={onGenerateFrameModel}
            />
          </Col>

          <Col span={24}>
            <Form.Item
              name='ccb_particulares_certidoes'
              help={errors.ccb_particulares_certidoes && (errors.ccb_particulares_certidoes)}
              validateStatus={errors.ccb_particulares_certidoes && ('error')}
            >
              <RichTextEditor
                setContents={cndText}
                disable={readOnly}
                onChange={setCndText}
              />
            </Form.Item>
          </Col>

          <Divider />

          <Col span={24}>
            <Typography.Title
              className="form-section-title form-section-title--primary"
              level={4}
            >
              Ccb particulares imóvel cnd municipais
            </Typography.Title>

            <Row gutter={[15, 15]}>
              <Col lg={8} md={24} sm={24} xs={24}>
                <Form.Item
                  name='ccb_particulares_imovel_cnd_municipais_emissao'
                  label={<label>Data emissão</label>}
                  help={errors.ccb_particulares_imovel_cnd_municipais_emissao && (errors.ccb_particulares_imovel_cnd_municipais_emissao)}
                  validateStatus={errors.ccb_particulares_imovel_cnd_municipais_emissao && ('error')}
                >
                  <Datepicker
                    id="test-form-item-ccb_particulares_imovel_cnd_municipais_emissao"
                    disabled={readOnly}
                    locale={locale}
                  />
                </Form.Item>
              </Col>

              <Col lg={8} md={24} sm={24} xs={24}>
                <Form.Item
                  name='ccb_particulares_imovel_cnd_municipais_validade'
                  label={<label>Data de validade</label>}
                  help={errors.ccb_particulares_imovel_cnd_municipais_validade && (errors.ccb_particulares_imovel_cnd_municipais_validade)}
                  validateStatus={errors.ccb_particulares_imovel_cnd_municipais_validade && ('error')}
                >
                  <Datepicker
                    id="test-form-item-ccb_particulares_imovel_cnd_municipais_validade"
                    disabled={readOnly}
                    locale={locale}
                  />
                </Form.Item>
              </Col>

              <Col lg={8} md={24} sm={24} xs={24}>
                <Form.Item
                  name='ccb_particulares_imovel_cnd_municipais_numero'
                  label={<label>Número da certidão</label>}
                  help={errors.ccb_particulares_imovel_cnd_municipais_numero && (errors.ccb_particulares_imovel_cnd_municipais_numero)}
                  validateStatus={errors.ccb_particulares_imovel_cnd_municipais_numero && ('error')}
                >
                  <Input
                    readOnly={readOnly}
                    placeholder='Digite o(a) número'
                    type="text"
                    error={errors.ccb_particulares_imovel_cnd_municipais_numero}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider/>

            <Row gutter={[15, 15]} align="top">
              <Col lg={8} md={24} sm={24} xs={24}>
                <Form.Item
                  name='ccb_particulares_imovel_certidao_acoes_reais_pessoais_emissao'
                  label={<label>Emissão da certidão de ações reais e pessoais reipersecutórias</label>}
                  help={errors.ccb_particulares_imovel_certidao_acoes_reais_pessoais_emissao && (errors.ccb_particulares_imovel_certidao_acoes_reais_pessoais_emissao)}
                  validateStatus={errors.ccb_particulares_imovel_certidao_acoes_reais_pessoais_emissao && ('error')}
                >
                  <Datepicker
                    id="test-form-item-ccb_particulares_imovel_certidao_acoes_reais_pessoais_emissao"
                    disabled={readOnly}
                    locale={locale}
                  />
                </Form.Item>
              </Col>

              <Divider />

              <Row gutter={[15, 15]} className="mt-5 w-100">
                <Col>
                  <Typography.Title className="form-section-title form-section-title--primary " level={4}>
                  Ccb particulares testemunhas
                  </Typography.Title>
                </Col>

                <Col>
                  <Form.Item
                    name="ccb_particulares_existe_testemunhas"
                    className="mb-0"
                    valuePropName="checked"
                    help={errors.ccb_particulares_existe_testemunhas && (errors.ccb_particulares_existe_testemunhas)}
                    validateStatus={errors.ccb_particulares_existe_testemunhas && ('error')}
                  >
                    <Checkbox
                      disabled={readOnly}
                      onChange={value => { setHasWitnesses(value.target.checked) }}
                      id="test-form-item-hasCompositor"
                    >
                    Possui testemunhas
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              {hasWitnesses && (
                <Fragment>
                  <Col span={24}>
                    <Typography.Paragraph className="bold">
                    Ccb particulares primeira testemunha
                    </Typography.Paragraph>
                  </Col>

                  {ccbParticularesWitness('ccb_particulares_primeiro_testemunha_').map(item => (
                    <Col lg={8} md={24} sm={24} xs={24} key={item.id}>
                      <ChooseContractComponent
                        errors={errors}
                        item={item}
                        type={item.type || ''}
                        readOnlyInput={readOnly}
                        component={(
                          <Form.Item
                            name={item.field}
                            label={<label>{item.label}</label>}
                            help={errors[item.field] && (errors[item.field])}
                            validateStatus={errors[item.field] && ('error')}
                          >
                            <Input
                              readOnly={readOnly}
                              placeholder={'Digite o(a) ' + item.label}
                              type="text"
                              error={errors[item.field]}
                              mask={item.mask || ''}
                            />
                          </Form.Item>
                        )}
                      />
                    </Col>
                  ))}

                  <Divider />

                  <Col span={24}>
                    <Typography.Paragraph className="bold">
                    Ccb particulares segunda testemunha
                    </Typography.Paragraph>
                  </Col>

                  {ccbParticularesWitness('ccb_particulares_segundo_testemunha_').map(item => (
                    <Col lg={8} md={24} sm={24} xs={24} key={item.id}>
                      <ChooseContractComponent
                        errors={errors}
                        item={item}
                        type={item.type || ''}
                        readOnlyInput={readOnly}
                        component={(
                          <Form.Item
                            name={item.field}
                            label={<label>{item.label}</label>}
                            help={errors[item.field] && (errors[item.field])}
                            validateStatus={errors[item.field] && ('error')}
                          >
                            <Input
                              readOnly={readOnly}
                              placeholder={'Digite o(a) ' + item.label}
                              type="text"
                              error={errors[item.field]}
                              mask={item.mask || ''}
                            />
                          </Form.Item>
                        )}
                      />
                    </Col>
                  ))}
                </Fragment>
              )}
            </Row>
          </Col>
        </Row>

        <Row justify="end">
          <Col>
            <Button
              loading={status === 'sending'}
              className="mt-4 text-center w-100 color-white uppercase"
              type="primary"
              htmlType="submit"
              disabled={readOnly}
            >
              Avançar
              <ArrowRightOutlined/>
            </Button>
          </Col>
        </Row>
      </Form>
    </Fragment>
  )
}

export default CNDStep

import React from 'react'
import { Col, Row } from 'antd'
import DetailsItem from 'ecp/components/DetailsItem/DetailsItem'
import { IOnidataClient } from 'ecp/models/ClientModel'

function ContactReferenceFieldsDisplay ({ contactReferenceInfos }: { contactReferenceInfos: IOnidataClient }) {
  return (
    <Row gutter={[14, 14]}>
      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='Nome completo'
          value={contactReferenceInfos?.referencia_nome ?? '-'}
        />
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='Parentesco'
          value={contactReferenceInfos?.referencia_parentesco ?? '-'}
        />
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='Telefone/Celular'
          value={contactReferenceInfos?.referencia_telefone ?? '-'}
        />
      </Col>
    </Row>
  )
}

export default ContactReferenceFieldsDisplay

import api from '../../services/api'
import { AxiosResponse } from 'axios'
import { ConnectionFailed, ErrInternetDisconnected } from './errors'

export interface ContractPdf {
  _id: string,
  description: string,
  file: string
}

export interface IFile {
  type: 'document' | 'signature' | 'multiple',
  slug: string,
  title: string,
  stepId: string,
  documentId: string,
  done: boolean,
  contract?: string,
  documents?: Array<string>
  modes?: string[]
  files?: ContractPdf[]
  commentary?: string,
  description?: string
}

export interface FileConfig {
  fileList: IFile[],
  isOnlySignatures: boolean,
  signaturesCount: number
}

export type CreditStatus = 'waiting' | 'approved' | 'reproved' | 'other' | ''
export type CreditResponse = { token: string, status: CreditStatus, reprovedReason?: string, otherReprovedReason?: string }

// Remove after Augusto's branch get to upstream
function urltoFile (url: any, filename: any, mimeType: any) {
  mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1]
  return (fetch(url)
    .then(function (res) { return res.arrayBuffer() })
    .then(function (buf) { return new File([buf], filename, { type: mimeType }) })
  )
}

const unauthed = {
  getFileConfig: async ({ token }: {token : string }): Promise<FileConfig> => {
    try {
      const response: AxiosResponse = await api.get(`/proposal?token=${token}`)
      const fileList: IFile[] = response.data.data.files
      const signaturesCount = fileList.filter(d => d.type === 'signature').length

      return { fileList, signaturesCount, isOnlySignatures: signaturesCount === fileList.length }
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  getFileProviderConfig: async (token: string | string[] | null): Promise<FileConfig> => {
    try {
      const response: AxiosResponse = await api.get(`/inviteds/documents?token=${token}`)
      const fileList: IFile[] = response.data.data.documents
      const signaturesCount = fileList.filter(d => d.type === 'signature').length

      return { fileList, signaturesCount, isOnlySignatures: signaturesCount === fileList.length }
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  stepStatusLink: async (token: string | string[] | null) => {
    try {
      const response: AxiosResponse = await api.get(`/link/step?token=${token}`)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }

      throw err.response.data
    }
  },

  finishStep: async ({ token, stepId }: {token: string, stepId: string}) => {
    try {
      const response: AxiosResponse = await api.post(`/link/send?token=${token}`, { stepId })
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  finishProviderDocument: async ({ token, documentsIds }: {token: string, documentsIds: Array<string>}) => {
    try {
      const response: AxiosResponse = await api.post(`/inviteds/send?token=${token}`, { documentsIds })
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  sendContract: async ({ token, signature, stepId, activeMode }: {token: string, stepId: string, signature: string, activeMode?: string }) => {
    try {
      const signatureFile = await urltoFile(signature, 'signature.png', 'image/png')
      const formData = new FormData()

      formData.append('stepId', stepId)
      formData.append('file', signatureFile)
      if (activeMode) formData.append('activeMode', activeMode)

      const response: AxiosResponse = await api.post(`/link/send?token=${token}`, formData)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  createStepLink: async ({ stepId, proposalId }: {stepId: string, proposalId: string}) => {
    try {
      const response: AxiosResponse = await api.get(`/proposals/${proposalId}/step/${stepId}/link`)
      const link = response.data.data.link

      return link
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  sendStepLink: async ({ stepId, proposalId }: {stepId: string, proposalId: string}) => {
    try {
      const response: AxiosResponse = await api.post(`/proposals/${proposalId}/step/${stepId}/link`)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
      if (!err.response) throw new ConnectionFailed()
      throw err.response.data
    }
  },

  simulationDate: async (simulationId : string) => {
    try {
      const response: AxiosResponse = await api.get(`/simulations/${simulationId}/birthdate`)
      return response.data.data.birthdate
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  createProposalLink: async ({ proposalId }: { proposalId: string}) => {
    try {
      const response: AxiosResponse = await api.get(`/proposals/${proposalId}/link`)
      const link = response.data?.data?.url

      return link
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  creditApprove: {
    creditInfo: async ({ token }: { token: string }) => {
      try {
        const response: AxiosResponse = await api.get(`/proposal/credit?token=${token}`)
        return response.data
      } catch (err) {
        if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
        if (!err.response) throw new ConnectionFailed()
        throw err.response.data
      }
    },

    creditResponse: async ({ token, status, reprovedReason, otherReprovedReason }: CreditResponse) => {
      try {
        const response: AxiosResponse = await api.post(`/proposal/credit?token=${token}`, { status, reprovedReason, otherReprovedReason })
        return response.data
      } catch (err) {
        if (err.name === 'ERR_INTERNET_DISCONNECTED') throw new ErrInternetDisconnected()
        if (!err.response) throw new ConnectionFailed()
        throw err.response.data
      }
    }
  }
}

export default unauthed

import React, { MutableRefObject, useRef, useState } from 'react'
import { Button, Col, Form, Row, Typography } from 'antd'
import ModalShare from 'components/ModalShare/ModalShare'
import ModalLink from 'components/ModalInvite/ModalLink'
import swal from 'utils/swal'
import { sendWhatsapp } from 'utils/globals'
import { useSelectedProduct } from 'hooks'
import EmailRepository from 'egi/repositories/EmailRepository'
import LinkRepository from 'egi/repositories/LinkRepository'

const { Title } = Typography

function GenerateLink () {
  const [invite, setInvite] = useState<string>('')
  const [loading, setLoading] = useState({
    list: false,
    invite: false,
    modalInvite: false,
    email: false,
    generateLink: false
  })

  const generateLinkFormRef: MutableRefObject<any> = useRef(null)
  const [inviteRef] = Form.useForm()
  const { _id: productId } = useSelectedProduct()

  async function inviteEmail () {
    const { email } = inviteRef.getFieldsValue()
    try {
      const response = await EmailRepository.createLink({ link: invite, email })
      swal.basic({ title: 'Sucesso', text: response.data?.message, icon: 'success' })
    } catch (err) {
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    }
  }

  interface InviteValues {
    campaign?: boolean
    campaignName?: string
    campaignSlug?: string
  }

  async function generateInvite (values: InviteValues) {
    const { campaign, campaignName, campaignSlug } = values

    try {
      const response = await LinkRepository.create({ productId, campaign, campaignSlug, campaignName })
      setInvite(response.data.data?.link)
    } catch (err) {
      swal.basic({ title: 'Atenção', icon: 'warning', text: err.message })
    }
  }

  const handleInvite = async (type: 'invite' | 'modalInvite') => {
    setLoading({ ...loading, [type]: true })
    await generateInvite({})
    setLoading({ ...loading, [type]: false })
  }

  return (
    <Row className="flex flex-justify-center mt-3">
      <Col lg={12} md={12} sm={24} xs={24}>
        <div className="card-style card-style-border-radius p-3">
          <Title level={3} className="color-primary mb-0">Gerar link</Title>

          <Row className="my-3" gutter={15}>
            <Col lg={8} md={12} sm={24} xs={24} className="pr-0 pr-md-0">
              <Button className="w-100" type="primary" loading={loading.modalInvite} onClick={() => handleInvite('modalInvite')}>
                Gerar link
              </Button>
            </Col>
          </Row>
        </div>

        {invite && (
          <div className="card-style card-style-border-radius p-3 mt-3">
            <ModalLink
              handleInvite={() => handleInvite('modalInvite')}
              generateLinkFormRef={generateLinkFormRef}
              loading={loading}
              invite={invite}
              canChooseType={true}
              texts={{
                goInviteText: 'Abrir link',
                inviteLinkText: 'Link de convite:'
              }}
            />

            <ModalShare
              form={inviteRef}
              sendWhatsapp={() => sendWhatsapp(inviteRef.getFieldsValue().cellphone, `Olá, segue o link para o Rodobens UX-Line: *${invite}*`)}
              inviteEmail={inviteEmail}
              invite={invite}
              loading={loading}
            />
          </div>
        )}
      </Col>
    </Row>
  )
}

export default GenerateLink

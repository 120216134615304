
import { UserOutlined } from '@ant-design/icons'
import EmailModel from 'egi/models/EmailModel'

import React from 'react'
import { timeAsDayjs } from 'utils/time'
import translate from 'utils/translate'
import { IConfigLogs } from '../Logs/EmailLogs'

export function EmailConfigurationTimeLine ({ item }: { item: IConfigLogs }) {
  return (
    <>
      <p>Alterações em {timeAsDayjs(item.createdAt).format('DD [de] MMMM [de] YYYY [às] HH:mm')}</p>
      <small><p><UserOutlined /> {item.modifiedBy.name} - {item.modifiedBy.email} ({item.modifiedBy.level})</p></small>

      <table className="ml-3 w-100">
        <tr className="email-config__logs-table-header">
          <th className="email-config__logs-table-header-item email-config__logs-table-header-first-item">Propriedade</th>
          <th className="email-config__logs-table-header-item">Novo valor</th>
          <th className="email-config__logs-table-header-item">Valor antigo</th>
        </tr>

        {EmailModel.canSeeRow(item.last.useAltMail, item.new.useAltMail) &&
          <tr key="value">
            <td className="email-config__logs-table-header-first-item"><p className="email-config__logs-table-property email-config__logs-table-item" >Usar smtp Rodobens</p></td>
            <td ><p className="email-config__logs-table-item  email-config__logs-table-new">{translate.yesNo(item.new.useAltMail)}</p>
            </td>
            <td><p className=" email-config__logs-table-item email-config__logs-table-old">{translate.yesNo(item.last.useAltMail)}</p></td>
          </tr>
        }
        {EmailModel.canSeeRow(item.last.port, item.new.port) && <tr key="value">
          <td className="email-config__logs-table-header-first-item"><p className="email-config__logs-table-property email-config__logs-table-item" >Porta</p></td>
          <td ><p className="email-config__logs-table-item  email-config__logs-table-new">{item.new.port}</p>
          </td>
          <td><p className=" email-config__logs-table-item email-config__logs-table-old">{item.last.port}</p></td>
        </tr>
        }

        {EmailModel.canSeeRow(item.last.host, item.new.host) &&
          <tr key="value">
            <td className="email-config__logs-table-header-first-item"><p className="email-config__logs-table-property email-config__logs-table-item" >Host</p></td>
            <td ><p className="email-config__logs-table-item  email-config__logs-table-new">{item.new.host}</p>
            </td>
            <td><p className=" email-config__logs-table-item email-config__logs-table-old">{item.last.host}</p></td>
          </tr>
        }
        {EmailModel.canSeeRow(item.last.from, item.new.from) &&
          <tr key="value">
            <td className="email-config__logs-table-header-first-item"><p className="email-config__logs-table-property email-config__logs-table-item" >Nome Remetente</p></td>
            <td ><p className="email-config__logs-table-item  email-config__logs-table-new">{item.new.from}</p>
            </td>
            <td><p className=" email-config__logs-table-item email-config__logs-table-old">{item.last.from}</p></td>
          </tr>
        }
        {EmailModel.canSeeRow(item.last.user, item.new.user) &&
          <tr key="value">
            <td className="email-config__logs-table-header-first-item"><p className="email-config__logs-table-property email-config__logs-table-item" >Usuário</p></td>
            <td ><p className="email-config__logs-table-item  email-config__logs-table-new">{item.new.user}</p>
            </td>
            <td><p className=" email-config__logs-table-item email-config__logs-table-old">{item.last.user}</p></td>
          </tr>
        }
        {EmailModel.canSeeRow(item.last.tls, item.new.tls) &&
          <tr key="value">
            <td className="email-config__logs-table-header-first-item"><p className="email-config__logs-table-property email-config__logs-table-item" >TLS</p></td>
            <td ><p className="email-config__logs-table-item  email-config__logs-table-new">{translate.yesNo(item.new.tls)}</p>
            </td>
            <td><p className=" email-config__logs-table-item email-config__logs-table-old">{translate.yesNo(item.last.tls)}</p></td>
          </tr>
        }
        {EmailModel.canSeeRow(item.last.host, item.new.secure) &&
          <tr key="value">
            <td className="email-config__logs-table-header-first-item"><p className="email-config__logs-table-property email-config__logs-table-item" >Secure</p></td>
            <td ><p className="email-config__logs-table-item  email-config__logs-table-new">{translate.yesNo(item.new.secure)}</p>
            </td>
            <td><p className=" email-config__logs-table-item email-config__logs-table-old">{translate.yesNo(item.last.secure)}</p></td>
          </tr>
        }

        {item.new.pass &&
          <tr key="value">
            <td className="email-config__logs-table-header-first-item"><p className="email-config__logs-table-property email-config__logs-table-item" >Password</p></td>
            <td ><p className="email-config__logs-table-item  email-config__logs-table-new">{item.new.pass}</p>
            </td>
            <td><p className=" email-config__logs-table-item email-config__logs-table-old">{item.new.pass}</p></td>
          </tr>
        }
      </table>
    </>
  )
}

import { Form, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Input } from 'components'
import { swalError } from 'components/SwalError/SwalError'
import { IError } from 'ecp/app/Proposals/proposalInterfaces'
import LoginRepository from 'ecp/repositories/LoginRepository'
import SignupButton from 'egi/app/Signup/components/SignupButton/SignupButton'
import { SignupTitle } from 'egi/app/Signup/components/SignupTitle/SignupTitle'
import SignupLayout from 'layouts/SignupLayout/SignupLayout'
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { UNAUTHS_PATHS } from 'routes/unauthRoutes'
import format from 'utils/format'
import { handleOnidataErrors } from 'utils/validate'
import { ICLientCheckLink, _registrationSituation } from '../../firstAccesInterfaces'

export default function FirstAccessClientCheckLink () {
  const [form] = useForm<ICLientCheckLink>()
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [errors, setErrors] = useState<IError<ICLientCheckLink>>({})

  const history = useHistory()

  function redirectToNextStep () {
    history.push(UNAUTHS_PATHS.FIRST_ACCESS_CLIENT_PASSWORD)
  }

  function redirectToLgpdStep () {
    history.push(UNAUTHS_PATHS.LGPD_ACCESS_CHECK_CNPJ)
  }

  function redirectToChangePassword () {
    history.push(UNAUTHS_PATHS.FORGOT)
  }

  const handleRegistrationStatus = (situation: _registrationSituation, messageText?: string) => {
    if (situation === 'aguardar-aprovacao-cadastro-lgpd') {
      message.warning('Você precisa da aprovação do RH para continuar!')
    }

    if (situation === 'ativo') {
      message.warning('Esse usuário já realizou primeiro acesso.')
    }

    if (situation === 'atualizar-senha') {
      message.warning('É necessário atualizar a senha, por favor insira suas informações para prosseguir.')
      redirectToChangePassword()
    }

    if (situation === 'cadastro-lgpd') {
      message.success(messageText)
      redirectToLgpdStep()
    }

    if (situation === 'primeiro-acesso' || situation === 'cadastro-de-senha') {
      message.success(messageText)
      redirectToNextStep()
    }

    if (situation === 'bloqueado') {
      message.warning('Usuário bloqueado')
    }
  }

  async function handleSubmit (values: ICLientCheckLink) {
    setLoadingSubmit(true)
    if (values.cpf) values.cpf = format.onlyDigits(values.cpf)

    try {
      const response = await LoginRepository.registrationStatus(values)
      const situacao = response.data.data?.situacao

      if (situacao) handleRegistrationStatus(situacao, response.data.message)
    } catch (err) {
      if (err?.data && err?.data?.error) {
        setErrors(handleOnidataErrors(err.data.error))
      }
      swalError({ title: 'Atenção', err, icon: 'warning' })
    } finally {
      setLoadingSubmit(false)
    }
  }

  return (
    <div className='signup-check-link'>
      <SignupLayout>
        <div className='mb-3'>
          <Link
            className='signup__link'
            to={UNAUTHS_PATHS.MAIN}
          >
              &larr; Voltar
          </Link>
        </div>

        <SignupTitle>Verificar vínculo com a empresa</SignupTitle>

        <p className='signup-check-link__paragraph mb-4'>Para verificar seu vinculo com a empresa, preencha o campo abaixo com seu cpf.</p>
        <Form
          onFinish={handleSubmit}
          form={form}
          layout="vertical"
          className='unauth-form'
        >
          <Form.Item
            name='cpf'
            label='CPF '
            help={errors?.cpf}
            validateStatus={errors?.cpf && ('error')}
            rules={[{
              required: true,
              message: 'Digite seu CPF'
            }]}
          >
            <Input
              mask='cpf'
              className='unauth-inputs'
              placeholder='Digite seu CPF...'
            />
          </Form.Item>

          <Form.Item>
            <SignupButton
              loading={loadingSubmit}
              htmlType='submit'
              className='w-100 mt-4'
            >
              Enviar
            </SignupButton>
          </Form.Item>
        </Form>
      </SignupLayout>
    </div>
  )
}

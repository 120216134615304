import React from 'react'
import FilterCreatedAt from './FilterCreatedAt'
import FilterArea from './FilterArea'
import FilterCurrentStep from './FilterCurrentStep'
import FilterPromoter from './FilterPromoter'
import FilterPromoterType from './FilterPromoterType'
import FilterProvider from './FilterProvider'
import FilterProviderDepartment from './FIlterProviderDepartment'
import FilterTypeSearch from './FilterTypeSearch'
import FilterSearch from './FilterSearch'
import FilterSeller from './FilterSeller'
import FilterUF from './FIlterUF'
import FilterUpdatedAt from './FilterUpdatedAt'
import FilterSwitch from './FilterSwitch'
import { tablesSetFiltersValues } from 'store/modules/tables/actions'
import { useAuth, useTables } from 'hooks'
import { useDispatch } from 'react-redux'
import dayjs, { Dayjs } from 'dayjs'
import { timeAsDayjs } from 'utils/time'
import { IAuth } from 'egi/types'
import translate from 'utils/translate'
import FilterPerson from './FilterPerson'
import { serverTableDefaultFilters } from 'utils/globals'
import FilterPromoterStatus from './FilterPromoterStatus'
import FilterPromoterChannel from './FilterPromoterChannel'
import FilterCurrentAction from './FilterCurrentAction'
import FilterBusinessManager from './FilterBusinessManager'
import { filterValueFormatter } from 'utils/utils'
import FilterLeadsStatus from 'components/SeverTable/FilterLeadsStatus'
import FilterProposalStatus from 'egi/app/ProposalEgi/components/FilterProposalStatus/FilterProposalStatus'
import FilterAnalysis from './FilterAnalysis'
import FilterCreatedProposal from './FilterCreatedAtProposal'

function filters () {
  const user: IAuth = useAuth()
  const dispatch = useDispatch()
  const tables = useTables()

  const filters = {
    proposalStatus: <FilterProposalStatus
      defaultValue={serverTableDefaultFilters.proposals}
      onChange={(value) => {
        dispatch(tablesSetFiltersValues({ ...tables.filters, proposalStatus: filterValueFormatter(value) }))
      }}
      key='proposalStatus'
    />,
    createdAt: <FilterCreatedAt
      onChange={(value: Dayjs) => {
        if (!value) dispatch(tablesSetFiltersValues({ ...tables.filters, createdDate: undefined }))
        else dispatch(tablesSetFiltersValues({ ...tables.filters, createdDate: timeAsDayjs(value).format('MM-YYYY') }))
      }}
      key='createdDate'
    />,
    createdAtProposal: <FilterCreatedProposal
      key='createdDate'
    />,
    lastArea: <FilterArea
      onChange={(value) => {
        dispatch(tablesSetFiltersValues({ ...tables.filters, lastArea: value, creditApproval: undefined, clientApprovalStatus: undefined }))
        if (!value) dispatch(tablesSetFiltersValues({ ...tables.filters, lastArea: undefined, creditApproval: undefined, clientApprovalStatus: undefined }))
      }}
      placeholder="Escolha o último departamento"
      hasDocumentationAndAvaliation={true}
      label="Último departamento"
      key='lastArea'
      name="lastArea"
    />,
    areasAction: <FilterArea
      onChange={(value) => {
        dispatch(tablesSetFiltersValues({ ...tables.filters, areaName: value }))
        if (!value) dispatch(tablesSetFiltersValues({ ...tables.filters, areaName: undefined }))
      }}
      placeholder="Escolha a área"
      label="Ações na área"
      key='areaName'
      name="areaName"
    />,
    currentStep: <FilterCurrentStep
      onChange={(value) => {
        dispatch(tablesSetFiltersValues({ ...tables.filters, leadCurrentStep: value }))
      }}
      key='leadCurrentStep'
    />,

    lastTrimester: <FilterSwitch
      placeholder="Último trimestre: "
      name="trimester"
      onChange={(value) => {
        if (!value) dispatch(tablesSetFiltersValues({ ...tables.filters, trimester: undefined, createdDate: timeAsDayjs(dayjs()).format('MM-YYYY') }))
        else dispatch(tablesSetFiltersValues({ ...tables.filters, trimester: true, createdDate: undefined }))
      }}
      key='trimester'
    />,
    promoter: {
      indicatorId: <FilterPromoter
        onChange={(value: string | undefined) => {
          dispatch(tablesSetFiltersValues({ ...tables.filters, indicatorId: value }))
          if (!value) dispatch(tablesSetFiltersValues({ ...tables.filters, indicatorId: undefined }))
        }}
        onClear={() => {
          dispatch(tablesSetFiltersValues({ ...tables.filters, indicatorId: undefined }))
        }}
        queryTitle="indicatorId"
        types='IN'
        key='indicatorId'
      />,
      telemarketingId: <FilterPromoter
        onChange={(value: string | undefined) => {
          dispatch(tablesSetFiltersValues({ ...tables.filters, telemarketingId: value }))
          if (!value) dispatch(tablesSetFiltersValues({ ...tables.filters, telemarketingId: undefined }))
        }}
        onClear={() => {
          dispatch(tablesSetFiltersValues({ ...tables.filters, telemarketingId: undefined }))
        }}
        queryTitle="telemarketingId"
        types='TM'
        key='telemarketingId'
      />,
      promoterId: <FilterPromoter
        onChange={(value: string | undefined) => {
          dispatch(tablesSetFiltersValues({ ...tables.filters, promoterId: value }))
          if (!value) dispatch(tablesSetFiltersValues({ ...tables.filters, promoterId: undefined }))
        }}
        onClear={() => {
          dispatch(tablesSetFiltersValues({ ...tables.filters, promoterId: undefined }))
        }}
        queryTitle="promoterId"
        key='promoterId'
      />
    },
    promoterType: <FilterPromoterType
      callback={(value: string) => {
        if (value) dispatch(tablesSetFiltersValues({ ...tables.filters, promoterType: value }))
        else dispatch(tablesSetFiltersValues({ ...tables.filters, promoterType: undefined }))
      }}
      key='promoterType'
    />,
    promoterStatus: <FilterPromoterStatus
      onChange={(value) => {
        dispatch(tablesSetFiltersValues({ ...tables.filters, promoterStatus: filterValueFormatter(value) }))
      }}
      key='promoterStatus'
    />,
    promoterChannel: <FilterPromoterChannel
      onChange={(value) => {
        dispatch(tablesSetFiltersValues({ ...tables.filters, promoterChannel: filterValueFormatter(value) }))
      }}
      key='promoterChannel'
    />,
    currentAction: <FilterCurrentAction
      onChange={(value) => {
        dispatch(tablesSetFiltersValues({ ...tables.filters, promoterCurrentAction: filterValueFormatter(value) }))
      }}
      key='promoterChannel'
    />,
    businessManager: <FilterBusinessManager
      onChange={(value) => {
        dispatch(tablesSetFiltersValues({ ...tables.filters, promoterBusinessManager: filterValueFormatter(value) }))
      }}
      key='promoterChannel'
    />,
    provider: <FilterProvider
      onChange={(value: string | undefined) => {
        dispatch(tablesSetFiltersValues({ ...tables.filters, providerId: value }))
      }}
      types={user.areaName as 'juridico' | 'engenharia' | ''}
      key='providerId'
    />,
    providerDepartment: <FilterProviderDepartment
      callback={(value: string) => {
        if (value) dispatch(tablesSetFiltersValues({ ...tables.filters, providerAreaName: value }))
        else dispatch(tablesSetFiltersValues({ ...tables.filters, providerAreaName: undefined }))
      }}
      key='areaName'
    />,
    search: <FilterSearch
      onChange={(value: string | undefined) => {
        dispatch(tablesSetFiltersValues({ ...tables.filters, search: value?.trim() }))
      }}
      key='search'
    />,
    typeSearch: <FilterTypeSearch
      onChange={(value: string | undefined, ignore: boolean | undefined) => {
        dispatch(tablesSetFiltersValues({ ...tables.filters, search: value?.trim(), searchUseFilters: ignore }))
      }}
      key='typeSearch'
    />,
    actionProposal: <FilterSwitch
      placeholder={`Ação ${translate.areasNames(user.areaName || '')}: `}
      name="hasAction"
      defaultChecked={tables.filters.hasAction}
      onChange={(value: boolean | undefined) => {
        if (value) dispatch(tablesSetFiltersValues({ ...tables.filters, hasAction: true }))
        else dispatch(tablesSetFiltersValues({ ...tables.filters, hasAction: undefined }))
      }}
      key='hasAction'
    />,
    seller: <FilterSeller
      onChange={(value) => {
        dispatch(tablesSetFiltersValues({ ...tables.filters, sellerId: value }))
        if (!value) dispatch(tablesSetFiltersValues({ ...tables.filters, sellerId: undefined }))
      }}
      onClear={() => {
        dispatch(tablesSetFiltersValues({ ...tables.filters, sellerId: undefined }))
      }}
      key={'sellerId'} />,
    uf: <FilterUF
      onChange={(value) => {
        dispatch(tablesSetFiltersValues({ ...tables.filters, ufFilter: value }))
      }}
      key='ufFilter'
    />,
    updatedAt: <FilterUpdatedAt
      onChange={(value: Dayjs | undefined | any) => {
        if (value) dispatch(tablesSetFiltersValues({ ...tables.filters, updatedDate: timeAsDayjs(value).format('YYYY-MM-DD') }))
        else dispatch(tablesSetFiltersValues({ ...tables.filters, updatedDate: undefined }))
      }}
      key='updatedDate'
    />,
    verifyEmail: <FilterSwitch
      placeholder="Email verificado: "
      name="hasEmailVerified"
      onChange={(value) => {
        if (value) dispatch(tablesSetFiltersValues({ ...tables.filters, hasEmailVerified: true }))
        else dispatch(tablesSetFiltersValues({ ...tables.filters, hasEmailVerified: undefined }))
      }}
      key='hasEmailVerified'
    />,
    leadStatus: <FilterLeadsStatus
      onChange={(value) => {
        dispatch(tablesSetFiltersValues({ ...tables.filters, leadStatus: value && value.length !== 0 ? Array(value).join(',') : [] }))
      }}
      key='leadStatus'
    />,
    reminded: <FilterSwitch
      placeholder="Propostas tombadas: "
      name="reminded"
      onChange={(value: boolean | undefined) => {
        if (value) dispatch(tablesSetFiltersValues({ ...tables.filters, reminded: true }))
        else dispatch(tablesSetFiltersValues({ ...tables.filters, reminded: undefined }))
      }}
      key='reminded'
    />,
    openContacts: <FilterSwitch
      placeholder="Atendimentos não finalizados: "
      name="openContacts"
      onChange={(value: boolean | undefined) => {
        if (value) dispatch(tablesSetFiltersValues({ ...tables.filters, openContacts: true }))
        else dispatch(tablesSetFiltersValues({ ...tables.filters, openContacts: undefined }))
      }}
      key='openContacts'
    />,
    linkedBackoffice: <FilterSwitch
      placeholder="Propostas vinculadas: "
      name="needVinculation"
      onChange={(value: boolean | undefined) => {
        if (value) dispatch(tablesSetFiltersValues({ ...tables.filters, needVinculation: false }))
        else dispatch(tablesSetFiltersValues({ ...tables.filters, needVinculation: undefined }))
      }}
      key='needVinculation'
    />,
    analysis: <FilterAnalysis
      onChange={(value: string | undefined) => {
        if (value) dispatch(tablesSetFiltersValues({ ...tables.filters, reviewAnalysis: value }))
        else dispatch(tablesSetFiltersValues({ ...tables.filters, reviewAnalysis: undefined }))
      }}
      key='reviewAnalysis'
    />,
    person: <FilterPerson
      onChange={(value: string | undefined) => {
        if (value) dispatch(tablesSetFiltersValues({ ...tables.filters, clientType: value }))
        else dispatch(tablesSetFiltersValues({ ...tables.filters, clientType: undefined }))
      }}
      key='clientType'
    />,
    expiredProposals: <FilterSwitch
      placeholder="Propostas expiradas: "
      name="expiredProposal"
      onChange={(value: boolean | undefined) => {
        if (value) dispatch(tablesSetFiltersValues({ ...tables.filters, expiredProposal: false }))
        else dispatch(tablesSetFiltersValues({ ...tables.filters, expiredProposal: undefined }))
      }}
      key='expiredProposal'
    />
  }

  return (
    filters
  )
}

export default filters

import { Col, Row } from 'antd'
import { swalError } from 'components/SwalError/SwalError'
import ContractFormContent from 'ecp/app/Proposals/components/ContractFormContent/ContractFormContent'
import ProposalContractPDF from 'ecp/app/Proposals/components/ProposalContractPDF/ProposalContractPDF'
import {
  IAcceptContract,
  IError,
  IGetActionLink,
  ILocalizationContract,
  ISendStepBody,
  _stepActions
} from 'ecp/app/Proposals/proposalInterfaces'
import DynamicColorButton from 'ecp/components/DynamicColorButton/DynamicColorButton'
import ProposalModel from 'ecp/models/ProposalModel'
import React, { useState } from 'react'
import { handleOnidataErrors } from 'utils/validate'
import useThisStep from '../../hooks/useThisStep'
import { formatLocalization, getPosition } from '../../proposalFunctions'
import AcceptContractModal from '../AcceptContractModal/AcceptContractModal'

export interface IAcceptContractProps {
  loading: boolean
  onSend(body: ISendStepBody<IAcceptContract>): Promise<void>
  permissions: _stepActions[]

  actions: IGetActionLink | null
}

export default function AcceptContract ({
  loading,
  actions,
  onSend
}: IAcceptContractProps) {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<IError<IAcceptContract>>({})
  const [hasBankCreditNoteAgree, setHasBankCreditNoteAgree] = useState<boolean>(false)
  const [hasinstallmentDeductedFromPayrollAgree, setHasinstallmentDeductedFromPayrollAgree] = useState<boolean>(false)
  const [hasAdhesionCreditInsuranceAgree, setHasAdhesionCreditInsuranceAgree] = useState<boolean>(false)
  const [contractSignAgree, setContractSignAgree] = useState<boolean>(false)

  const { step } = useThisStep()

  const canApprove = step.canApprove
  const canReprove = step.canReprove

  const hasInsurance = actions?.hasInsurance

  async function onSubmit (localization: ILocalizationContract) {
    const body: ISendStepBody<IAcceptContract> = {
      status: contractSignAgree ? 'approved' : 'reproved',
      parameters: {
        bankCreditNoteAgree: hasBankCreditNoteAgree,
        installmentDeductedFromPayrollAgree: hasinstallmentDeductedFromPayrollAgree,
        adhesionCreditInsuranceAgree: hasAdhesionCreditInsuranceAgree,
        contractSignAgree: contractSignAgree,
        localization: formatLocalization(localization)
      }
    }

    setErrors({})
    setSubmitLoading(true)
    try {
      await onSend(body)
    } catch (err) {
      console.error(err)
      if (err?.data?.error) setErrors(handleOnidataErrors(err?.data?.error))
      swalError({ title: 'Atenção', err, icon: 'warning' })
    } finally {
      setSubmitLoading(false)
      setShowModal(false)
    }
  }

  return (
    <>
      <AcceptContractModal
        handleConfirmCLick={() => getPosition(onSubmit)}
        onOkAction={() => setShowModal(false)}
        onCancelAction={() => setShowModal(false)}
        loading={submitLoading}
        visible={showModal}
        contractSignAgree={contractSignAgree}
      />

      <ProposalContractPDF
        contract={actions?.contract || null}
        loading={loading}
      />

      <div className='mt-5'>
        {canApprove && (
          <div className='accept-contract__inputs-container'>
            <ContractFormContent
              hasInsurance={hasInsurance}
              setAdhesionCreditInsuranceAgree={setHasAdhesionCreditInsuranceAgree}
              errors={errors}
              setHasBankCreditNoteAgree={setHasBankCreditNoteAgree}
              setHasinstallmentDeductedFromPayrollAgree={setHasinstallmentDeductedFromPayrollAgree}
            />
          </div>
        )}

        <Row className='mt-5'>
          {canReprove && (
            <Col className='px-1' lg={12} sm={24} xs={24}>
              <DynamicColorButton
                colorClass="button-red"
                className='w-100'
                onClick={() => {
                  setShowModal(true)
                  setContractSignAgree(false)
                }}
              >
                Recusar
              </DynamicColorButton>
            </Col>
          )}

          {canApprove && (
            <Col className='px-1' lg={12} sm={24} xs={24}>
              <DynamicColorButton
                colorClass='button-green'
                className='w-100'
                disabled={ProposalModel.canAcceptContract(
                  hasBankCreditNoteAgree,
                  hasinstallmentDeductedFromPayrollAgree,
                  hasAdhesionCreditInsuranceAgree,
                  hasInsurance ?? false
                )}
                onClick={() => {
                  setShowModal(true)
                  setContractSignAgree(true)
                }}
              >
                Aceitar
              </DynamicColorButton>
            </Col>
          )}
        </Row>
      </div>
    </>
  )
}

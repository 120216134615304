import { IProgressQueueActionsTypes } from './progressQueueActionTypes'

export enum ProgressQueuesTypes {
  SET_PROGRESS_QUEUE_LIST = 'PROGRESS_QUEUE_SET_LIST',
  SET_IS_IMPORTING = 'PROGRESS_QUEUE_SET_IS_IMPORTING',
  SET_PROGRESS_CHECK_ID = 'PROGRESS_QUEUE_SET_PROGRESS_CHECK_ID',
  SET_LIST_LENGHT = 'PROGRESS_QUEUE_SET_LIST_LENGHT',
  SET_LIST = 'PROGRESS_QUEUE_ITEM_SET_LIST',
  SET_IS_OPEN = 'PROGRESS_QUEUE_SET_IS_OPEN',
  SET_INFO_LIST = 'PROGRESS_QUEUE_SET_INFO_LIST',
  SET_PERCENTAGE = 'PROGRESS_QUEUE_SET_PERCENTAGE',
  SET_STATUS = 'PROGRESS_QUEUE_SET_STATUS',
  SET_TYPE = 'PROGRESS_QUEUE_SET_TYPE',
  SET_ACTION = 'PROGRESS_QUEUE_SET_ACTION',
  RESET = 'PROGRESS_QUEUE_RESET'
}

export const progressQueuestActions = {
  setQueueList: (payload: IProgressQueueActionsTypes.setQueueList['payload']): IProgressQueueActionsTypes.setQueueList => ({
    type: ProgressQueuesTypes.SET_PROGRESS_QUEUE_LIST,
    payload
  }),

  seIsImporting: (payload: IProgressQueueActionsTypes.setIsImporting['payload']): IProgressQueueActionsTypes.setIsImporting => ({
    type: ProgressQueuesTypes.SET_IS_IMPORTING,
    payload
  }),

  setProgressCheckId: (payload: IProgressQueueActionsTypes.setProgressCheckId['payload']): IProgressQueueActionsTypes.setProgressCheckId => ({
    type: ProgressQueuesTypes.SET_PROGRESS_CHECK_ID,
    payload
  }),

  setListLenght: (payload: IProgressQueueActionsTypes.setListLenght['payload']): IProgressQueueActionsTypes.setListLenght => ({
    type: ProgressQueuesTypes.SET_LIST_LENGHT,
    payload
  }),

  setList: (payload: IProgressQueueActionsTypes.setList['payload']): IProgressQueueActionsTypes.setList => ({
    type: ProgressQueuesTypes.SET_LIST,
    payload
  }),

  setIsOpen: (payload: IProgressQueueActionsTypes.setIsOpen['payload']): IProgressQueueActionsTypes.setIsOpen => ({
    type: ProgressQueuesTypes.SET_IS_OPEN,
    payload
  }),

  setProgressQueueInfoList: (payload: IProgressQueueActionsTypes.setprogressQueueInfoList['payload']): IProgressQueueActionsTypes.setprogressQueueInfoList => ({
    type: ProgressQueuesTypes.SET_INFO_LIST,
    payload
  }),

  setPercentage: (payload: IProgressQueueActionsTypes.setPercentage['payload']): IProgressQueueActionsTypes.setPercentage => ({
    type: ProgressQueuesTypes.SET_PERCENTAGE,
    payload
  }),

  setStatus: (payload: IProgressQueueActionsTypes.setStatus['payload']): IProgressQueueActionsTypes.setStatus => ({
    type: ProgressQueuesTypes.SET_STATUS,
    payload
  }),

  setType: (payload: IProgressQueueActionsTypes.setType['payload']): IProgressQueueActionsTypes.setType => ({
    type: ProgressQueuesTypes.SET_TYPE,
    payload
  }),

  setAction: (payload: IProgressQueueActionsTypes.setAction['payload']): IProgressQueueActionsTypes.setAction => ({
    type: ProgressQueuesTypes.SET_ACTION,
    payload
  }),

  reset: (): IProgressQueueActionsTypes.reset => ({
    type: ProgressQueuesTypes.RESET
  })
}

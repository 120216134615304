import { Col, Tag } from 'antd'
import { AccessManagerDetailsGroupEnum, IDetailSchema } from 'egi/app/AccessManager/accessManagerInterfaces'
import React from 'react'
import translate from 'utils/translate'
import { formatValue } from './accessManagerFunctions'

export const accessManagerDetailsGroups = [
  { title: 'Dados Pessoais', key: AccessManagerDetailsGroupEnum.personalData },
  { title: 'Configurações', key: AccessManagerDetailsGroupEnum.configurations },
  { title: 'Aceite LGPD', key: AccessManagerDetailsGroupEnum.lgpd },
  { title: 'Onidata', key: AccessManagerDetailsGroupEnum.onidata },
  { title: 'Conta', key: AccessManagerDetailsGroupEnum.account },
  { title: 'Outros', key: AccessManagerDetailsGroupEnum.others }
]

export const detailsSchema: Array<IDetailSchema> = [
  {
    label: 'Nível de acesso',
    key: ['level'],
    group: AccessManagerDetailsGroupEnum.personalData,
    translate: 'level'
  },
  {
    label: 'Nome',
    key: ['name'],
    group: AccessManagerDetailsGroupEnum.personalData
  },
  {
    label: 'Celular',
    key: ['cellphone'],
    group: AccessManagerDetailsGroupEnum.personalData,
    mask: 'phone'
  },
  {
    label: 'E-mail',
    key: ['email'],
    group: AccessManagerDetailsGroupEnum.personalData
  },
  {
    label: 'CPF',
    key: ['cpf'],
    group: AccessManagerDetailsGroupEnum.personalData,
    mask: 'cpfcnpj'
  },
  {
    label: 'Data de nascimento',
    key: ['birthdate'],
    group: AccessManagerDetailsGroupEnum.personalData,
    format: 'brasilDate'

  },
  {
    label: 'Termos aceitos',
    key: ['term'],
    group: AccessManagerDetailsGroupEnum.configurations,
    translate: 'yesNo'
  },
  {
    label: 'Pode convidar administradores',
    key: ['inviteAdmin'],
    group: AccessManagerDetailsGroupEnum.configurations,
    translate: 'yesNo'
  },
  {
    label: 'E-mail verificado',
    key: ['emailVerified'],
    group: AccessManagerDetailsGroupEnum.configurations,
    translate: 'yesNo'
  },
  {
    label: 'Bloqueado',
    key: ['blocked'],
    group: AccessManagerDetailsGroupEnum.configurations,
    translate: 'yesNo'
  },
  {
    label: 'Pode receber atribuição de propostas',
    key: ['receiveProposal'],
    group: AccessManagerDetailsGroupEnum.configurations,
    translate: 'yesNo'
  },
  {
    label: 'Cancelado',
    key: ['canceled'],
    group: AccessManagerDetailsGroupEnum.configurations,
    translate: 'yesNo'
  },
  {
    label: 'Possuí helpdesk',
    key: ['helpdesk'],
    group: AccessManagerDetailsGroupEnum.configurations,
    translate: 'yesNo'
  },
  {
    label: 'Área',
    key: ['areaName'],
    group: AccessManagerDetailsGroupEnum.configurations
  },
  {
    label: 'Produto selecionado',
    key: ['selectedProduct'],
    group: AccessManagerDetailsGroupEnum.configurations,
    translate: 'productSlug'
  },
  {
    label: 'Produtos',
    key: ['products'],
    group: AccessManagerDetailsGroupEnum.configurations,
    Component: (item, data) => {
      const products = formatValue(item, data)

      return (
        <Col key={JSON.stringify(item.key)} className="access-manager-details__detail-wrapper access-manager-details__products-tags-wrapper">
          <label className="access-manager-details__detail-wrapper__label">{item.label}</label>
          {Array.isArray(products) && products.map((product, idx) => (
            <Tag className="access-manager-details__tag" key={idx}>
              {translate.productSlug(product)}
            </Tag>
          ))}
        </Col>
      )
    }
  },
  {
    label: 'Código da assinatura',
    key: ['lgpd', 'signatureCode'],
    group: AccessManagerDetailsGroupEnum.lgpd
  },
  {
    label: 'Aceito em',
    key: ['lgpd', 'signatureDate'],
    group: AccessManagerDetailsGroupEnum.lgpd,
    format: 'brasilDate'
  },
  {
    label: 'Tipo',
    key: ['lgpd', 'type'],
    group: AccessManagerDetailsGroupEnum.lgpd
  },
  {
    label: 'Assinado',
    key: ['lgpd', 'signed'],
    group: AccessManagerDetailsGroupEnum.lgpd,
    translate: 'yesNo'
  },
  {
    label: 'Identificador',
    key: ['onidata', 'identifier'],
    group: AccessManagerDetailsGroupEnum.onidata
  },
  {
    label: 'Método de autenticação',
    key: ['onidata', 'authentication', 'metodo_autenticacao'],
    group: AccessManagerDetailsGroupEnum.onidata
  },
  {
    label: 'Telefone/Celular',
    key: ['onidata', 'authentication', 'usuario', 'telefone_celular'],
    group: AccessManagerDetailsGroupEnum.onidata
  },
  {
    label: 'Telefone/Celular verificado',
    key: ['onidata', 'authentication', 'usuario', 'telefone_celular_verificado'],
    group: AccessManagerDetailsGroupEnum.onidata,
    translate: 'yesNo'
  },
  {
    label: 'E-mail',
    key: ['onidata', 'authentication', 'usuario', 'email'],
    group: AccessManagerDetailsGroupEnum.onidata
  },
  {
    label: 'E-mail verificado',
    key: ['onidata', 'authentication', 'usuario', 'email_verificado'],
    group: AccessManagerDetailsGroupEnum.onidata,
    translate: 'yesNo'
  },
  {
    label: 'Último login',
    key: ['onidata', 'authentication', 'usuario', 'ultimo_login'],
    group: AccessManagerDetailsGroupEnum.onidata,
    format: 'brasilDate'
  },
  {
    label: 'Possuí acesso',
    key: ['onidata', 'authentication', 'usuario', 'possui_acesso'],
    group: AccessManagerDetailsGroupEnum.onidata,
    translate: 'yesNo'
  },
  {
    label: 'Cadastro ativo',
    key: ['onidata', 'authentication', 'usuario', 'cadastro_ativo'],
    group: AccessManagerDetailsGroupEnum.onidata
  },
  {
    label: 'Acesso externo',
    key: ['onidata', 'authentication', 'usuario', 'acesso_externo'],
    group: AccessManagerDetailsGroupEnum.onidata
  },
  {
    label: 'Domnio de acesso externo',
    key: ['onidata', 'authentication', 'usuario', 'dominio_acesso_externo'],
    group: AccessManagerDetailsGroupEnum.onidata,
    format: 'brasilDate'
  },
  {
    label: 'Validade certificado',
    key: ['onidata', 'authentication', 'usuario', 'validade_certificado'],
    group: AccessManagerDetailsGroupEnum.onidata
  },
  {
    label: 'Observação',
    key: ['onidata', 'authentication', 'usuario', 'observacao'],
    group: AccessManagerDetailsGroupEnum.onidata
  },
  {
    label: 'Validade do convite do gestor',
    key: ['onidata', 'authentication', 'usuario', 'convite_gestor', 'criado_em'],
    group: AccessManagerDetailsGroupEnum.onidata,
    format: 'brasilDate'
  },
  {
    label: 'Convite do gestor criado em',
    key: ['onidata', 'authentication', 'usuario', 'convite_gestor', 'validade_do_token'],
    group: AccessManagerDetailsGroupEnum.onidata,
    format: 'brasilDate'
  },
  {
    label: 'Nome usuário',
    key: ['details', 'NOME_USUARIO'],
    group: AccessManagerDetailsGroupEnum.account
  },
  {
    label: 'CPF usuário',
    key: ['details', 'CPF_USUARIO'],
    group: AccessManagerDetailsGroupEnum.account
  },
  {
    label: 'Data de criação',
    key: ['createdAt'],
    group: AccessManagerDetailsGroupEnum.account,
    format: 'brasilDate'
  },
  {
    label: 'Data de criação',
    key: ['createdAt'],
    group: AccessManagerDetailsGroupEnum.account,
    format: 'brasilDate'
  },
  {
    label: 'Última atualização',
    key: ['updatedAt'],
    group: AccessManagerDetailsGroupEnum.account,
    format: 'brasilDate'
  },
  {
    label: 'Última atualização',
    key: ['updatedAt'],
    group: AccessManagerDetailsGroupEnum.account,
    format: 'brasilDate'
  },
  {
    label: 'Último acesso',
    key: ['details', 'ULTIMO_ACESSO'],
    group: AccessManagerDetailsGroupEnum.account,
    format: 'brasilDate'
  },
  {
    label: 'Último navegador usado',
    key: ['details', 'NAVEGADOR_ULTIMO_ACESSO'],
    group: AccessManagerDetailsGroupEnum.account
  },
  {
    label: 'Ip último acesso',
    key: ['details', 'IP_DO_ULTIMO_ACESSO'],
    group: AccessManagerDetailsGroupEnum.account
  },
  {
    label: 'Último dispositivo usado',
    key: ['details', 'DISPOSITIVO_USADO_ULTIMO_ACESSO'],
    group: AccessManagerDetailsGroupEnum.account
  },
  {
    label: 'Último sistema operactional usado',
    key: ['details', 'SISTEMA_OPERACIONAL_ULTIMO_ACESSO'],
    group: AccessManagerDetailsGroupEnum.account
  },
  {
    label: 'Tentativas de login',
    key: ['loginInfo', 'loginAttempts'],
    group: AccessManagerDetailsGroupEnum.account
  },
  {
    label: 'Primeiro login na agx',
    key: ['details', 'PRIMEIRO_LOGIN_NA_AGX'],
    group: AccessManagerDetailsGroupEnum.account,
    format: 'brasilDate'
  },
  {
    label: 'Primeiro login na agx',
    key: ['details', 'ULTIMA_TROCA_DE_SENHA'],
    group: AccessManagerDetailsGroupEnum.account,
    format: 'brasilDate'
  }
]

export const auditoriaEGIGroups = [
  { title: 'Propostas', key: AccessManagerDetailsGroupEnum.proposal },
  { title: 'Cliente', key: AccessManagerDetailsGroupEnum.client },
  { title: 'Usuário', key: AccessManagerDetailsGroupEnum.user },
  { title: 'Outros', key: AccessManagerDetailsGroupEnum.others }
]

export const auditoriaEGISchema: Array<IDetailSchema> = [
  { label: 'Número da proposta', key: ['NUMERO_DA_PROPOSTA'], group: AccessManagerDetailsGroupEnum.proposal },
  { label: 'Passo', key: ['PASSO'], group: AccessManagerDetailsGroupEnum.proposal },
  { label: 'Documento', key: ['DOCUMENTO'], group: AccessManagerDetailsGroupEnum.proposal },
  { label: 'Tipo de alteração', key: ['QUAL_O_TIPO_DE_ALTERACAO'], group: AccessManagerDetailsGroupEnum.proposal },
  { label: 'Arquivo do documento', key: ['ARQUIVO_DO_DOCUMENTO'], group: AccessManagerDetailsGroupEnum.proposal, translate: 'proposalStatus' },
  { label: 'Status alterado de', key: ['STATUS_ALTERADO_DE'], group: AccessManagerDetailsGroupEnum.proposal },
  { label: 'Status alterado para', key: ['STATUS_ALTERADO_PARA'], group: AccessManagerDetailsGroupEnum.proposal },
  { label: 'Data da alteração', key: ['DATA_ALTERACAO'], group: AccessManagerDetailsGroupEnum.proposal },
  { label: 'Nome', key: ['NOME_CLIENTE'], group: AccessManagerDetailsGroupEnum.client },
  { label: 'CPF', key: ['CPF_CLIENTE'], group: AccessManagerDetailsGroupEnum.client, mask: 'cpf' },
  { label: 'E-mail', key: ['EMAIL_CLIENTE'], group: AccessManagerDetailsGroupEnum.client },
  { label: 'Telefone', key: ['TELEFONE_CLIENTE'], group: AccessManagerDetailsGroupEnum.client, mask: 'phone' },
  { label: 'Nome', key: ['USUARIO_ALTERACAO'], group: AccessManagerDetailsGroupEnum.user },
  { label: 'Departamento', key: ['DEPARTAMENTO'], group: AccessManagerDetailsGroupEnum.user, translate: 'level' },
  { label: 'Dispositivo', key: ['DISPOSITIVO'], group: AccessManagerDetailsGroupEnum.user, translate: 'device' },
  { label: 'Sistema operacional ', key: ['SISTEMA_OPERACIONAL'], group: AccessManagerDetailsGroupEnum.user },
  { label: 'IP', key: ['IP'], group: AccessManagerDetailsGroupEnum.user },
  { label: 'Navegador', key: ['NAVEGADOR'], group: AccessManagerDetailsGroupEnum.user }
]

export const auditoriaECPGroup = [
  { title: 'Propostas', key: AccessManagerDetailsGroupEnum.proposal },
  { title: 'Cliente', key: AccessManagerDetailsGroupEnum.client },
  { title: 'Referência', key: AccessManagerDetailsGroupEnum.reference },
  { title: 'Ocupação', key: AccessManagerDetailsGroupEnum.job },
  { title: 'Documento', key: AccessManagerDetailsGroupEnum.document },
  { title: 'Endereço', key: AccessManagerDetailsGroupEnum.address },
  { title: 'Pagamento', key: AccessManagerDetailsGroupEnum.payment },
  { title: 'Outros', key: AccessManagerDetailsGroupEnum.others }
]

export const auditoriaECPSchema: Array<IDetailSchema> = [

  { label: 'ID', key: ['ID_PROPOSTA'], group: AccessManagerDetailsGroupEnum.proposal },
  { label: 'Data de solicitação', key: ['DATA_SOLICITACAO'], group: AccessManagerDetailsGroupEnum.proposal, format: 'brasilDate' },
  { label: 'Corban do convênio', key: ['CORBAN_DO_CONVENIO'], group: AccessManagerDetailsGroupEnum.proposal },
  { label: 'Último passo', key: ['ULTIMO_PASSO'], group: AccessManagerDetailsGroupEnum.proposal },
  { label: 'Último status', key: ['ULTIMO_STATUS'], group: AccessManagerDetailsGroupEnum.proposal },
  { label: 'Averbado pelo front-end AGX', key: ['AVERBADO_FRONT_AGX'], group: AccessManagerDetailsGroupEnum.proposal, translate: 'yesNo' },

  { label: 'Nome', key: ['NOME_CLIENTE'], group: AccessManagerDetailsGroupEnum.client },
  { label: 'CPF', key: ['CPF_CLIENTE'], group: AccessManagerDetailsGroupEnum.client },
  { label: 'Data de nascimento', key: ['DATA_NASCIMENTO'], group: AccessManagerDetailsGroupEnum.client, format: 'brasilDate' },
  { label: 'Telefone', key: ['NUMERO_TELEFONE'], group: AccessManagerDetailsGroupEnum.client },
  { label: 'Telefone verificado', key: ['TELEFONE_VERIFICAO'], group: AccessManagerDetailsGroupEnum.client, translate: 'yesNo' },
  { label: 'E-mail', key: ['EMAIL'], group: AccessManagerDetailsGroupEnum.client },
  { label: 'E-mail verificado', key: ['EMAIL_VERIFICADO'], group: AccessManagerDetailsGroupEnum.client, translate: 'yesNo' },
  { label: 'Criação do cliente', key: ['CRIACAO_DO_USUARIO_CLIENTE'], group: AccessManagerDetailsGroupEnum.client },

  { label: 'Nome do contato', key: ['NOME_CONTATO_REFERENCIA'], group: AccessManagerDetailsGroupEnum.reference },
  { label: 'Parentesco', key: ['PARENTESTO_REFERENCIA'], group: AccessManagerDetailsGroupEnum.reference },
  { label: 'Telefone', key: ['TELEFONE_CONTATO_REFERENCIA'], group: AccessManagerDetailsGroupEnum.reference },

  { label: 'Cargo', key: ['CARGO_NA_EMPRESA'], group: AccessManagerDetailsGroupEnum.job },
  { label: 'Razão Social', key: ['RAZAO_SOCIAL_EMPRESA'], group: AccessManagerDetailsGroupEnum.job },
  { label: 'CNPJ', key: ['CNPJ_EMPRESA'], group: AccessManagerDetailsGroupEnum.job },

  { label: 'Tipo', key: ['INFO_CLIENTE_DOCUMENTO', 'tipo'], group: AccessManagerDetailsGroupEnum.document },
  { label: 'Número', key: ['INFO_CLIENTE_DOCUMENTO', 'numero'], group: AccessManagerDetailsGroupEnum.document },
  { label: 'Data de emissão', key: ['INFO_CLIENTE_DOCUMENTO', 'data_emissao'], group: AccessManagerDetailsGroupEnum.document, format: 'brasilDate' },
  { label: 'Emissor', key: ['INFO_CLIENTE_DOCUMENTO', 'emissor'], group: AccessManagerDetailsGroupEnum.document },
  { label: 'UF', key: ['INFO_CLIENTE_DOCUMENTO', 'uf'], group: AccessManagerDetailsGroupEnum.document },
  { label: 'Foto', key: ['INFO_CLIENTE_DOCUMENTO', 'foto'], group: AccessManagerDetailsGroupEnum.document },

  { label: 'CEP', key: ['INFO_CLIENTE_ENDEREÇO', 'cep'], group: AccessManagerDetailsGroupEnum.address },
  { label: 'País', key: ['INFO_CLIENTE_ENDEREÇO', 'pais'], group: AccessManagerDetailsGroupEnum.address },
  { label: 'UF', key: ['INFO_CLIENTE_ENDEREÇO', 'uf'], group: AccessManagerDetailsGroupEnum.address },
  { label: 'Cidade', key: ['INFO_CLIENTE_ENDEREÇO', 'cidade'], group: AccessManagerDetailsGroupEnum.address },
  { label: 'Bairro', key: ['INFO_CLIENTE_ENDEREÇO', 'bairro'], group: AccessManagerDetailsGroupEnum.address },
  { label: 'Logradouro', key: ['INFO_CLIENTE_ENDEREÇO', 'logradouro'], group: AccessManagerDetailsGroupEnum.address },
  { label: 'Número', key: ['INFO_CLIENTE_ENDEREÇO', 'numero'], group: AccessManagerDetailsGroupEnum.address },
  { label: 'Complemento', key: ['INFO_CLIENTE_ENDEREÇO', 'complemento'], group: AccessManagerDetailsGroupEnum.address },
  { label: 'Fotos', key: ['INFO_CLIENTE_ENDEREÇO', 'foto'], group: AccessManagerDetailsGroupEnum.address },
  { label: 'Tipo de residência', key: ['INFO_CLIENTE_ENDEREÇO', 'residencia_tipo'], group: AccessManagerDetailsGroupEnum.address },
  { label: 'Tempo de residência', key: ['INFO_CLIENTE_ENDEREÇO', 'residencia_tempo'], group: AccessManagerDetailsGroupEnum.address },

  { label: 'Código do Banco', key: ['INFO_CLIENTE_PAGAMENTO', 'banco'], group: AccessManagerDetailsGroupEnum.payment },
  { label: 'Banco', key: ['INFO_CLIENTE_PAGAMENTO', 'banco_nome'], group: AccessManagerDetailsGroupEnum.payment },
  { label: 'Tipo', key: ['INFO_CLIENTE_PAGAMENTO', 'tipo'], group: AccessManagerDetailsGroupEnum.payment },
  { label: 'Tipo de conta', key: ['INFO_CLIENTE_PAGAMENTO', 'individual_ou_conjunta'], group: AccessManagerDetailsGroupEnum.payment },
  { label: 'Conta', key: ['INFO_CLIENTE_PAGAMENTO', 'conta'], group: AccessManagerDetailsGroupEnum.payment },
  { label: 'Dígito da conta', key: ['INFO_CLIENTE_PAGAMENTO', 'conta_dac'], group: AccessManagerDetailsGroupEnum.payment },
  { label: 'Agência', key: ['INFO_CLIENTE_PAGAMENTO', 'agencia'], group: AccessManagerDetailsGroupEnum.payment },
  { label: 'Dígito da Agência', key: ['INFO_CLIENTE_PAGAMENTO', 'agencia_dac'], group: AccessManagerDetailsGroupEnum.payment },
  { label: 'CPF responsável', key: ['INFO_CLIENTE_PAGAMENTO', 'cpf_responsavel'], group: AccessManagerDetailsGroupEnum.payment },
  { label: 'CNPJ Responsável', key: ['INFO_CLIENTE_PAGAMENTO', 'cnpj_responsavel'], group: AccessManagerDetailsGroupEnum.payment },
  { label: 'Nome do titular', key: ['INFO_CLIENTE_PAGAMENTO', 'nome_titular'], group: AccessManagerDetailsGroupEnum.payment },
  { label: 'Cliente desde', key: ['INFO_CLIENTE_PAGAMENTO', 'cliente_desde'], group: AccessManagerDetailsGroupEnum.payment },
  { label: 'Praça', key: ['INFO_CLIENTE_PAGAMENTO', 'praca'], group: AccessManagerDetailsGroupEnum.payment },
  { label: 'Telefone', key: ['INFO_CLIENTE_PAGAMENTO', 'telefone'], group: AccessManagerDetailsGroupEnum.payment },
  { label: 'Nome do gerente', key: ['INFO_CLIENTE_PAGAMENTO', 'nome_gerente'], group: AccessManagerDetailsGroupEnum.payment }
]

import { Form } from 'antd'
import { PropertyForm } from 'components'
import { proposals } from 'egi/consumers'
import { useStep } from 'hooks'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { proposalLoadingStep } from 'store/modules/proposal/actions'
import { RootState } from 'store/modules/rootReducer'
import { IAuth } from 'egi/types'
import format from 'utils/format'
import mask from 'utils/masks'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'
import { IFormProps } from '../types'
import { IFormPropertyAddress, IFormPropertyAddressErrors } from './types'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'

function FormPropertyAddress ({ formRef, onType, onLoading, callbackProposal, readOnlyInput, openSimulationDrawer }: IFormProps) {
  const [person, setPerson] = useState<string>()
  const [errors, setErrors] = useState<any>({})

  const step = useStep()
  const user: IAuth = useSelector((state: RootState) => state.auth)
  const proposal = useSelector((state: RootState) => state.proposal)

  const dispatch = useDispatch()

  const onSubmit = async (values: IFormPropertyAddress) => {
    if (proposal.id && user) {
      if (values.outstandingBalance) values.outstandingBalance = format.formatMoneySend(String(values.outstandingBalance))
      if (values.dismemberedBoxValue) values.dismemberedBoxValue = format.formatMoneySend(String(values.dismemberedBoxValue))

      const props: any = {
        proposalId: proposal.id,
        stepId: step._id,
        areaId: step.areaId,
        form: { ...values }
      }

      try {
        let response

        if (onType === 'send') {
          onLoading('sending')
          props.form = { ...props.form, finished: true }
          response = await proposals.send(props)
        } else if (onType === 'save') {
          onLoading('saving')
          props.form = { ...props.form, finished: false }
          response = await proposals.send(props)
        }

        if (step.id) dispatch(proposalLoadingStep({ stepId: step.id, loading: true }))
        setErrors({})
        callbackProposal()
        swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
      } catch (err) {
        if (err.data?.invalid) {
          let errors: IFormPropertyAddressErrors = {}
          errors = validateResponse(err.data?.invalid)
          setErrors(errors)

          const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
          invalidFieldsError({ invalidFields: invalidFields })
          return
        }

        if (err?.data?.maxDeadline !== undefined && err?.data?.maxDeadline !== null) {
          swal.confirm({
            icon: 'warning',
            title: 'Atenção',
            html: err.message,
            confirmButtonText: 'Refazer simulação',
            showCancelButton: false,
            confirm: () => {
              if (openSimulationDrawer) openSimulationDrawer({ type: 'simulator' })
            }
          })
        }

        setErrors({})
        swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
        return false
      } finally {
        onLoading('')
      }
    }
  }

  useEffect(() => {
    function resetFields () {
      if (formRef) formRef.resetFields()
    }

    async function onFill () {
      const formData = Object.assign({}, (step as any).form) || {}

      if (Object.keys(formData).length < 1) {
        resetFields()
        return
      }

      if (formData.managerPhone) formData.managerPhone = mask(formData.managerPhone, 'phone', true)
      formRef.setFieldsValue({ ...formData })
    }

    const getPerson = () => {
      if (step) {
        if (step.name.includes('cônjuge')) {
          setPerson('cônjuge')
        } else if (step.name.includes('fiador')) {
          setPerson('fiador')
        } else {
          setPerson(undefined)
        }
      }
    }

    resetFields()
    getPerson()
    onFill()

    return () => {
      resetFields()
    }
  }, [step.form, formRef, step._id, step.situation])

  return (
    <Fragment>
      <Form
        form={formRef}
        onFinish={onSubmit}
        layout="vertical"
        initialValues={{
          hasStorage: false,
          hasCoOwnership: false,
          isFinancialOperation: false
        }}
      >
        {person !== 'cônjuge' && (
          <PropertyForm
            formRef={formRef}
            errors={errors}
            readOnlyInput={readOnlyInput}
          />
        )}
      </Form>
    </Fragment>
  )
}

export default FormPropertyAddress

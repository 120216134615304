import React from 'react'
import VerificationInput, { VerificationInputProps } from 'react-verification-input'

type props = {
  lenght: number
} & VerificationInputProps

export default function TokenVerification ({ lenght, ...rest } : props) {
  return (
    <VerificationInput
      autoFocus
      length={lenght}
      classNames={{
        container: 'verification-input'
      }}
      {...rest}
    />
  )
}

import { Col, Empty, message, Radio, Skeleton, Typography } from 'antd'

import PartnerModel from 'egi/models/PartnerModel'
import React, { Fragment, useEffect, useState } from 'react'

import PartnersRepository from 'egi/repositories/PartnersRepository'
import { usePartnerDepartment } from 'egi/app/PartnersLine/hooks/usePartnerDepartment'
import usePartnerDocumentation from 'egi/app/PartnersLine/hooks/usePartnerDocumentation'
import usePartnerStep from '../../hooks/usePartnerStep'
import { IFormPartner } from '../PartnerStep/steps/PartnerStep/types'

function OptionsWrapper ({ children, error, loading }: { children: React.ReactNode, error: string, loading: boolean }) {
  if (loading) return <Skeleton.Button active={true} shape={'square'} className='choose-minuta__skeleton' />
  if (error) return <Empty description={error} />
  return <>{children}</>
}

function ChooseMinuta () {
  const { step } = usePartnerStep()
  const { department } = usePartnerDepartment()
  const [minutaOptions, setMinutaOptions] = useState<string[]>()
  const [version, setVersion] = useState<string>()
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
  const [loadingOptions, setLoadingOptions] = useState(false)
  const [error, setError] = useState<string>('')

  const { _id: documentId } = usePartnerDocumentation()

  useEffect(() => {
    const getMinutaOptions = async () => {
      setLoadingOptions(true)
      try {
        if (department) {
          const response = await PartnersRepository.minutaOptions(department, step.slug)
          setMinutaOptions(response?.data?.data.draftsOptions)
        }
      } catch (err) {
        message.error(err)
        setError(err.message ?? 'Nenhuma opção encontrada')
      } finally { setLoadingOptions(false) }
    }

    getMinutaOptions()
  }, [step.status, department])

  useEffect(() => {
    if (minutaOptions) {
      const form = step?.form as IFormPartner
      if (form?.version) setVersion(form?.version)
    }
  }, [minutaOptions])

  const onSubmit = async (value: string) => {
    setLoadingSubmit(true)
    try {
      const data = { version: value, department, step: step.slug }
      await PartnersRepository.send(documentId, data)
      message.success('Minuta enviada com sucesso')
    } catch (err) {
      message.error(err.message)
    } finally {
      setLoadingSubmit(false)
    }
  }

  const onChangeOption = (value: string) => {
    setVersion(value)
    onSubmit(value)
  }

  if (!PartnerModel.canChooseMinuta(step)) return <Fragment />

  return (
    <div className="pb-3">
      <Col span={24}>
        <Typography.Title
          className="form-section-title form-section-title--primary"
          level={4}
        >
          Selecione o tipo de contrato
        </Typography.Title>
      </Col>

      <OptionsWrapper error={error} loading={loadingOptions}>
        <Radio.Group
          className='my-4'
          value={version}
          onChange={event => onChangeOption(event.target.value)}
        >
          {minutaOptions?.map(item =>
            <Radio disabled={loadingSubmit} value={item} key={item}>
              {item}
            </Radio>
          )}
        </Radio.Group>
      </OptionsWrapper>

    </div>
  )
}

export default ChooseMinuta

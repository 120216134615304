
import { Col, Form, Row, Select } from 'antd'
import { ISelect } from 'egi/types'
import useResources, { IStepIdentification } from 'hooks/useResources'
import React, { useEffect } from 'react'

interface IProps {
  selectedStep?: IStepIdentification,
  selectedDepartment?: ISelect,
  onSelectDepartment: Function,
  onSelectStep: Function
}

export default function InternalReviewSelectFields ({ selectedStep, selectedDepartment, onSelectDepartment, onSelectStep }: IProps) {
  const { getDepartments, departments, getSteps, steps, resourcesError, formattedSteps, loadingResources } = useResources()

  useEffect(() => {
    getDepartments()
  }, [])

  return (
    <>
      <Row gutter={[15, 15]}>
        <Col lg={12} sm={24} xs={24}>
          <Form.Item
            label='Departamento'
            name="departmentId"
            rules={[{ required: true, message: 'Escolha o status.' }]}
          >
            <Select
              showSearch
              optionFilterProp='label'
              loading={loadingResources.departments}
              disabled={loadingResources.departments}
              notFoundContent={resourcesError.departments}
              onSelect={(departmentId: string) => onSelectDepartment({ departmentId, departments, getSteps })}
              placeholder="Selecione novo status"
              options={departments}
            >
            </Select>
          </Form.Item>
        </Col>
        {selectedDepartment &&
            <Col lg={12} sm={24} xs={24}>
              <Form.Item label="Passo"
                name="stepId"
              >
                <Select
                  showSearch
                  optionFilterProp='label'
                  loading={loadingResources.steps}
                  disabled={loadingResources.steps}
                  value={selectedStep?._id}
                  placeholder="Selecione o passo"
                  notFoundContent={resourcesError.departments}
                  options={formattedSteps}
                  onSelect={(selectedStep) => onSelectStep(selectedStep, steps)}
                />
              </Form.Item>
            </Col>
        }
      </Row>
    </>
  )
}

import React, { Fragment } from 'react'
import DeclinesForm from '../../components/DeclinesForm/DeclinesForm'

import SettingsCard from '../../components/SettignsCard/SettingsCard'

function Declines () {
  return (
    <Fragment>
      <SettingsCard.Header
        title="Motivos de recusa"
        description={
          <p>
            Configure aqui motivos de <b>recusa</b> padrão para mais tarde serem utilizados na sua proposta
          </p>
        } />

      <SettingsCard>
        <DeclinesForm/>
      </SettingsCard>

    </Fragment>
  )
}

export default Declines

import { ColumnsType } from 'antd/lib/table'
import commonColumns, { commomNameOverflowWidth } from 'components/UserTable/utils/commonColumns'
import blockColumn from 'components/UserTable/utils/blockColumn'
import { useAuth } from 'hooks'
import { IBaseProps } from '../userTableInterfaces'
import { Link } from 'react-router-dom'
import UserTableModel from 'egi/models/UserTableModels'
import { userDetails } from 'routes/routes'
import React from 'react'
import { OverflowTooltip } from 'components/OverflowToolTip/OverflowTooltip'

function TelemarketingColumns ({ blockUser }: IBaseProps): ColumnsType<any> {
  const user = useAuth()

  const columns : ColumnsType<Object> = [
    {
      ...commonColumns.name,
      render: (name: string, row: any) => {
        const user = useAuth()
        if (!name) return 'N/A'

        if (UserTableModel.showUsersDetails(user.level)) {
          return (
            <>
              <OverflowTooltip width={commomNameOverflowWidth} title={name}>
                <Link
                  className="link-style color-tertiary font-500"
                  to={'/auth' + userDetails.path.replace(':userId', row._id)}
                >
                  {name}
                </Link>
              </OverflowTooltip>
              {row?.cpf ? row?.cpf : '-'}
            </>
          )
        }

        return name
      }
    },
    {
      ...commonColumns.status,
      width: 125
    },
    commonColumns.email,
    commonColumns.cellphone,
    commonColumns.createdAt,
    ...(blockUser ? [blockColumn({ blockUser, userLevel: user.level })] : [])
  ]

  return columns
}

export default TelemarketingColumns

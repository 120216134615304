import { AxiosRequestConfig } from 'axios'
import { IAcceptContract, IContractSignatureResource, ISendStepBody } from 'ecp/app/Proposals/proposalInterfaces'
import { IResponseBase, Repository } from 'ecp/repositories/Repository'
import api from 'services/api'

class UnauthProposalRepository extends Repository {
  async getUnauthContract (token: string, config?: AxiosRequestConfig): Promise<IResponseBase<{ contract: IContractSignatureResource, hasInsurance: boolean }>> {
    return Repository.handle(() =>
      this.api.get(`${this.path}contract/${token}`, config)
    )
  }

  async sendUnauthContract (token: string, data: ISendStepBody<IAcceptContract>, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.post(`${this.path}contract/${token}`, data, config)
    )
  }
}

export default new UnauthProposalRepository({ api, path: 'ecp/unauth/proposals/' })

import React, { Fragment } from 'react'
import { Button, Col, Form, Row, Spin, Typography } from 'antd'
import { IModalLink } from './types'

function ModalLink ({ generateLinkFormRef, loading, invite, canChooseType, texts, handleInvite }: IModalLink) {
  return (
    <Form onFinish={handleInvite} ref={generateLinkFormRef}>
      <Fragment>
        <Row className="pb-3 flex-justify-center">
          {loading.invite ? (<Spin size="default" />) : (
            <Fragment>
              <label className="bold mx-1 uppercase">{texts.inviteLinkText}</label><Typography.Link style={{ wordBreak: 'break-all' }} copyable={true}>{invite}</Typography.Link>
            </Fragment>
          )}
        </Row>
      </Fragment>

      <div className="text-center">
        {!canChooseType && (<Typography.Paragraph className="text-center">{texts.generateAnotherLink}</Typography.Paragraph>)}

        <Row>
          <Col className="pr-1 px-md-0" lg={canChooseType ? 24 : 12} md={canChooseType ? 24 : 12} sm={24} xs={24}>
            <Form.Item>
              <Button
                type="ghost"
                className={'w-100'}
                onClick={() => window.open(invite)}
              >
                {texts.goInviteText}
              </Button>
            </Form.Item>
          </Col>

          {!canChooseType && (
            <Col className="pl-1 px-md-0" lg={12} md={12} sm={24} xs={24}>
              <Form.Item>
                <Button
                  className="w-100"
                  loading={loading.modalInvite}
                  type="primary"
                  htmlType="submit"
                >
                  Gerar outro convite
                </Button>
              </Form.Item>
            </Col>
          )}
        </Row>
      </div>
    </Form>
  )
}

export default ModalLink

import React, { useEffect } from 'react'
import { Form } from 'antd'
import { useAuth, useTables } from 'hooks'
import { useDispatch } from 'react-redux'
import { tablesUpdate } from 'store/modules/tables/actions'
import ServerTableFilters from 'components/ServerTable/ServerTableFilters'
import { useForm } from 'antd/lib/form/Form'
import { formatFiltersClients } from 'ecp/app/Clients/clientFunctions'
import { TableSearch } from 'components/TableSearch/TableSearch'
import { useSetFilters } from 'hooks/useSetFilters'
import ClientModel from 'ecp/models/ClientModel'
import ReRequestButton from 'components/ReRequest/ReRequest'
import { HELP_TEXT_FOR_SEARCH_CLIENTS } from '../../clientConstants'

function ClientPendingOnidataFilters () {
  const auth = useAuth()
  const dispatch = useDispatch()
  const tables = useTables()
  const [form] = useForm()
  const { withOutSearch, withSearch } = useSetFilters()

  const clientCorrectName = ClientModel.chooseCorrectName(auth.level)

  useEffect(() => {
    form.setFieldsValue(tables.filters)
  }, [])

  return (
    <Form
      layout='vertical'
      form={form}
      onValuesChange={
        (changedValues, allValues) => {
          if (Object.keys(changedValues).includes('corbanId')) {
            form.setFieldsValue({ covenantId: undefined, subcorbanId: undefined })
            allValues.covenantId = undefined
            allValues.subcorbanId = undefined
          }

          if (Object.keys(changedValues).includes('subcorbanId')) {
            form.setFieldsValue({ covenantId: undefined })
            allValues.covenantId = undefined
          }

          return withOutSearch(changedValues, formatFiltersClients(allValues))
        }
      }
    >
      <ServerTableFilters>
        <TableSearch
          placeholder={`Pesquise por ${clientCorrectName}`}
          helpText={HELP_TEXT_FOR_SEARCH_CLIENTS.replace('cliente', clientCorrectName.toLowerCase())}
          onSearch={withSearch}
        />

        <ServerTableFilters>
          <ReRequestButton
            onClick={() => {
              dispatch(tablesUpdate())
            }}
          />

        </ServerTableFilters>
      </ServerTableFilters>
    </Form>
  )
}

export { ClientPendingOnidataFilters }

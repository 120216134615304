import { ILayoutActionsTypes } from './layoutActionTypes'

export enum LayoutTypes {
  SET_LOADING = 'LAYOUT_SET_LOADING',
  SET_CREDENTIAL = 'LAYOUT_SET_CREDENTIAL',
  SET_ERROR = 'LAYOUT_SET_ERROR',
  RESET = 'LAYOUT_RESET'
}

export const layoutActions = {
  setLoading: (payload: ILayoutActionsTypes.setLoading['payload']): ILayoutActionsTypes.setLoading => ({
    type: LayoutTypes.SET_LOADING,
    payload
  }),

  setCredential: (payload: ILayoutActionsTypes.setCredential['payload']): ILayoutActionsTypes.setCredential => ({
    type: LayoutTypes.SET_CREDENTIAL,
    payload
  }),

  setError: (payload: ILayoutActionsTypes.setError['payload']): ILayoutActionsTypes.setError => ({
    type: LayoutTypes.SET_ERROR,
    payload
  }),

  reset: (): ILayoutActionsTypes.reset => ({
    type: LayoutTypes.RESET
  })
}

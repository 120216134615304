import { _userLevel } from 'ecp/models/UsersModel'
import format from './format'

const env = process.env.REACT_APP_ENV

export const isProduction = env === 'production'
export const isDevelopment = env === 'development'
export const isLocal = !isProduction && !isDevelopment
export const isLoft = '60abf71002037518460bb981'
export const isFrank = '60ec96947a5de07852736798'

export const devCorbanId = '5fe2417e2031d9019c4fde37'
export const rodobanId = '5fe2417e2031d9019c4fde37'

export const preSignupNumber = '5511951608641'

export const consultantNumber = '5511951608641'

export const pdfWorker = 'https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js'

export const allLevels: _userLevel[] = ['backoffice', 'master', 'client', 'promoter', 'commercial', 'commission', 'pricing', 'administrator', 'consultant', 'provider', 'seller', 'collaborators']

export const serverTableSelectProps = {
  mode: 'multiple' as const,
  style: { width: '100%' },
  placeholder: 'Selecionar status...',
  maxTagCount: 'responsive' as const
}

export const serverTableDefaultFilters = {
  leads: ['awaiting'],
  proposals: ['ongoing']
}

export const states = [
  { value: 11, label: 'RO' },
  { value: 12, label: 'AC' },
  { value: 13, label: 'AM' },
  { value: 14, label: 'RR' },
  { value: 15, label: 'PA' },
  { value: 16, label: 'AP' },
  { value: 17, label: 'TO' },
  { value: 21, label: 'MA' },
  { value: 22, label: 'PI' },
  { value: 23, label: 'CE' },
  { value: 24, label: 'RN' },
  { value: 25, label: 'PB' },
  { value: 26, label: 'PE' },
  { value: 27, label: 'AL' },
  { value: 28, label: 'SE' },
  { value: 29, label: 'BA' },
  { value: 31, label: 'MG' },
  { value: 32, label: 'ES' },
  { value: 33, label: 'RJ' },
  { value: 35, label: 'SP' },
  { value: 41, label: 'PR' },
  { value: 42, label: 'SC' },
  { value: 43, label: 'RS' },
  { value: 50, label: 'MS' },
  { value: 51, label: 'MT' },
  { value: 52, label: 'GO' },
  { value: 53, label: 'DF' }
]

states.sort(function (a, b) {
  if (a.label > b.label) {
    return 1
  }
  if (a.label < b.label) {
    return -1
  }
  return 0
})

export function addNameAfterTitle (name: string) {
  const element = document.querySelector('#auth-page-title')
  if (element && element.innerHTML.indexOf(':') === -1) element.insertAdjacentHTML('beforeend', ': ' + name)
}

export function sendWhatsapp (cellphone: string, text: string) {
  const rawCellphone = format.onlyDigits(cellphone)
  window.open(`https://api.whatsapp.com/send?phone=+55${rawCellphone}&text=${text}`)
}

export function slaColors (time: number): string {
  if (time > 0) return '#00CF7B'
  if (time === 0) return '#FF7A40'
  if (time < 0) return '#DD4E4E'

  return ''
}

export const requestPricingIcon = 'https://raw.githubusercontent.com/AGX-Software/public_assets/93a8819643d1ed4535ad20b38ece0a4f4c5d033f/rodobens/rodobens_ic-request_pricing.svg'

export const TIMEOUT_LOADER = 30000

import React, { Fragment, MutableRefObject, useEffect, useRef, useState } from 'react'
import { Button, Checkbox, Col, Form, Row, Typography } from 'antd'
import { Address, Input } from 'components'
import { IOnidataContractErrors } from '../contract'
import ChooseContractComponent from '../ChooseContractComponent'
import { conjugeFieldsString, fieldsString, valuesFormatToDate } from '../contractFields'
import locale from 'antd/es/date-picker/locale/pt_BR'
import MinutaMoldSelect from '../MinutaMoldSelect'
import { useProposal, useResources, useStep } from 'hooks'
import { validateResponse } from 'utils/validate'
import swal from 'utils/swal'
import { proposals } from 'egi/consumers'
import { ArrowRightOutlined } from '@ant-design/icons'
import time, { correctTimezone, timeAsDayjs } from 'utils/time'
import format from 'utils/format'
import { useDispatch } from 'react-redux'
import { stepForm } from 'store/modules/step/actions'
import Datepicker from 'components/Datepicker/Datepicker'
import { swalError } from 'components/SwalError/SwalError'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'

interface IIQStepValues {
  [key: string]: any
  hasIQ: boolean
  IQ_incluir_conjuge: boolean
  IQ_nome: string
  IQ_email: string
  IQ_data_de_nascimento: Date
  IQ_nacionalidade: string
  IQ_filiacao: string
  IQ_profissao: string
  IQ_estado_civil: string
  IQ_identidade: string
  IQ_CPF: string
  IQ_CEP: string
  IQ_pais: string
  IQ_logradouro: string
  IQ_numero_residencial: string
  IQ_complemento_residencial: string
  IQ_bairro: string
  IQ_UF: string
  IQ_municipio: string

  IQ_nome_conjuge?: string
  IQ_email_conjuge?: string
  IQ_data_de_nascimento_conjuge?: Date
  IQ_nacionalidade_conjuge?: string
  IQ_filiacao_conjuge?: string
  IQ_filiacao_pai_conjuge?: string
  IQ_profissao_conjuge?: string
  IQ_identidade_conjuge?: string
  IQ_CPF_conjuge?: string
}

function IQStep ({ readOnly, onSuccess }: {readOnly?: boolean, onSuccess: (value: boolean) => void}) {
  const [errors, setErrors] = useState<IOnidataContractErrors>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [minutaMoldText, setMinutaMoldText] = useState<string | undefined>(undefined)
  const [hasConjuge, setHasConjuge] = useState<boolean>(false)
  const [readOnlyInput, setReadOnlyInput] = useState<boolean | undefined>(true)
  const [hasIQ, setHasIQ] = useState<boolean>(false)

  const resources = useResources()
  const step = useStep()
  const proposal = useProposal()
  const dispatch = useDispatch()
  const [formRef] = Form.useForm()

  const ref: MutableRefObject<{minutaText: string}> = useRef({
    minutaText: ''
  })

  function onError (err: any) {
    swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    setLoading(false)
  }

  const onSubmit = async (values: IIQStepValues) => {
    setLoading(true)
    setErrors({})

    try {
      if (!proposal.id) throw new Error('Falha ao encontrar id da proposta')
      if (!step.form) throw new Error('Falha ao encontrar form')
      if (!step.id) throw new Error('Falha ao encontrar step id')
      if (!step.areaId) throw new Error('Falha ao encontrar area Id')
      if (!ref.current.minutaText && hasIQ) throw new Error('Texto de qualificação do IQ não informado!');

      ['IQ_data_de_nascimento', 'IQ_data_expedicao', 'IQ_data_de_nascimento_conjuge'].forEach(item => {
        if (values[item]) values[item] = time(correctTimezone(values[item]))
      })

      if (hasIQ) values.hasIQ = hasIQ
      else values.hasIQ = false

      if (values.IQ_CPF) values.IQ_CPF = format.onlyDigits(values.IQ_CPF)
      if (ref.current.minutaText) values.IQ_qualificacao = ref.current.minutaText

      const data: any = {
        form: { ...step.form, ...values, finished: false, currentStep: 4 },
        proposalId: proposal.id,
        stepId: step.id,
        areaId: step.areaId
      }

      const response = await proposals.send(data)
      dispatch(stepForm({ data: values }))
      onSuccess(true)
      swal.basic({ title: 'Sucesso!', text: response.message, icon: 'success' })
    } catch (err) {
      if ([
        'Falha ao encontrar id da proposta',
        'Falha ao encontrar form',
        'Falha ao encontrar step id',
        'Falha ao encontrar area Id',
        'Texto de qualificação do IQ não informado!'
      ].includes(err.message)) return onError(err)

      if (err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
        setLoading(false)

        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
      } else {
        swalError({ title: 'Atenção', icon: 'warning', err })
      }

      setLoading(false)
    }
  }

  useEffect(() => {
    async function onFill () {
      try {
        const tempForm = JSON.parse(JSON.stringify(step.form))

        if (tempForm.hasIQ === true) {
          setHasIQ(true)
          setReadOnlyInput(false)
        }

        if (tempForm.IQ_incluir_conjuge === true) setHasConjuge(true)
        if (tempForm.IQ_qualificacao) {
          setMinutaMoldText(tempForm.IQ_qualificacao)
          ref.current.minutaText = tempForm.IQ_qualificacao
        }

        valuesFormatToDate.forEach(item => {
          if (tempForm[item]) tempForm[item] = timeAsDayjs(tempForm[item], { applyTimezone: false }).format('DD/MM/YYYY')
        })

        if (formRef) formRef.setFieldsValue({ ...tempForm })
      } catch (err) {
        swal.basic({ title: 'Atenção!', text: err.message, icon: 'warning' })
      }
    }

    onFill()
  }, [])

  return (
    <Fragment>
      <Form
        form={formRef}
        layout='vertical'
        onFinish={onSubmit}
        className="form-contract"
      >
        <Col span={24}>
          <Row gutter={[15, 15]} align="top">
            <Row gutter={[15, 15]} className="mt-5 w-100">
              <Col>
                <Typography.Title className="form-section-title form-section-title--primary " level={4}>
                  Interveniente quitante
                </Typography.Title>
              </Col>

              <Col>
                <Form.Item
                  name="hasIQ"
                  className="mb-0"
                  valuePropName="checked"
                  help={errors.hasIQ && (errors.hasIQ)}
                  validateStatus={errors.hasIQ && ('error')}
                >
                  <Checkbox
                    onChange={value => {
                      if (value.target.checked) {
                        setReadOnlyInput(false)
                        setHasIQ(true)
                      } else {
                        setReadOnlyInput(true)
                        setHasIQ(false)
                      }
                    }}
                    id="test-form-item-hasCompositor"
                  >
                    Possui IQ ?
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Col lg={8} md={24} sm={24} xs={24}>
              <Form.Item
                name='IQ_data_de_nascimento'
                label={<label>Data nascimento</label>}
                help={errors.IQ_data_de_nascimento && (errors.IQ_data_de_nascimento)}
                validateStatus={errors.IQ_data_de_nascimento && ('error')}
              >
                <Datepicker
                  id='test-form-item-IQ_data_de_nascimento'
                  disabled={readOnlyInput}
                  locale={locale}
                />
              </Form.Item>
            </Col>

            {fieldsString('IQ_').map(item => (
              <Col lg={8} md={24} sm={24} xs={24} key={item.id}>
                <ChooseContractComponent
                  errors={errors}
                  item={item}
                  type={item.type || ''}
                  readOnlyInput={readOnlyInput}
                  component={(
                    <Form.Item
                      name={item.field}
                      label={<label>{item.label}</label>}
                      help={errors[item.field] && (errors[item.field])}
                      validateStatus={errors[item.field] && ('error')}
                    >
                      <Input
                        readOnly={readOnlyInput}
                        id={'test-form-item-' + item.field}
                        placeholder={'Digite o(a) ' + item.label}
                        type="text"
                        error={errors[item.field]}
                        mask={item.mask || ''}
                      />
                    </Form.Item>
                  )}
                />
              </Col>
            ))}

            <Col lg={8} md={24} sm={24} xs={24}>
              <Form.Item
                name='IQ_incluir_conjuge'
                valuePropName="checked"
                label={<label></label>}
                help={errors.IQ_incluir_conjuge && errors.IQ_incluir_conjuge}
                validateStatus={errors.IQ_incluir_conjuge && ('error')}
              >
                <Checkbox
                  disabled={readOnlyInput}
                  onChange={value => setHasConjuge(value.target.checked) }
                >
                  Incluir cônjuge ?
                </Checkbox>
              </Form.Item>
            </Col>

            <Row align="bottom" className="w-100" gutter={[15, 15]}>
              <Col lg={24} md={24} sm={24} xs={24} className="pr-0">
                <MinutaMoldSelect
                  selectSlug={{ name: 'IQ_qualificacao_slug', label: 'Qualificação' }}
                  text={minutaMoldText}
                  placeholder="Selecione a qualificação"
                  readOnlySelect={readOnlyInput}
                  qualification={resources.minutaMold}
                  type="qualificacao"
                  errors={errors.IQ_qualificacao_slug}
                  setText={(value: string) => { ref.current.minutaText = value }}
                  formRef={formRef}
                />
              </Col>
            </Row>

            {hasConjuge && (
              <Col span={24}>
                <Typography.Paragraph className="bold">
                  CÔNJUGE
                </Typography.Paragraph>

                <Row gutter={[15, 15]}>
                  <Col lg={8} md={24} sm={24} xs={24}>
                    <Form.Item
                      name='IQ_data_de_nascimento_conjuge'
                      label={<label>Data nascimento do cônjuge</label>}
                      help={errors.IQ_data_de_nascimento_conjuge && (errors.IQ_data_de_nascimento_conjuge)}
                      validateStatus={errors.IQ_data_de_nascimento_conjuge && ('error')}
                    >
                      <Datepicker disabled={readOnlyInput} locale={locale} />
                    </Form.Item>
                  </Col>

                  {conjugeFieldsString('IQ_').map(item => (
                    <Col lg={8} md={24} sm={24} xs={24} key={item.id}>
                      <ChooseContractComponent
                        errors={errors}
                        item={item}
                        type={item.type || ''}
                        readOnlyInput={readOnlyInput}
                        component={(
                          <Form.Item
                            name={item.field}
                            label={<label>{item.label}</label>}
                            help={errors[item.field] && (errors[item.field])}
                            validateStatus={errors[item.field] && ('error')}
                          >
                            <Input
                              id={`test-form-item-${item.field}`}
                              disabled={readOnlyInput}
                              placeholder={'Digite o(a) ' + item.label + ' do cônjuge'}
                              type="text"
                              error={errors[item.field]}
                              mask={item.mask || ''}
                            />
                          </Form.Item>
                        )}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            )}

            <Col span={24}>
              <Address
                title="ENDEREÇO"
                name='IQ_'
                readOnlyInput={readOnlyInput}
                onCepBlur={(address, remainingAddress) => formRef.setFieldsValue({ IQ_Address: { ...address, ...remainingAddress } })}
                formRef={formRef}
                errors={errors}
              />
            </Col>
          </Row>
        </Col>

        <Row justify="end">
          <Col>
            <Button
              loading={loading}
              className="mt-4 text-center w-100 color-white uppercase"
              type="primary"
              htmlType="submit"
              disabled={readOnly}
            >
              Avançar
              <ArrowRightOutlined/>
            </Button>
          </Col>
        </Row>
      </Form>
    </Fragment>
  )
}

export default IQStep

import { Button, Divider } from 'antd'
import React, { useState } from 'react'
import DocsNavbar from '../components/DocsNavBar/DocsNavegation'
import { topics } from '../topics'

function Docs () {
  const [showNavbar, setShowNavbar] = useState(false)
  const showNav = () => {
    setShowNavbar(prev => !prev)
  }

  return (
    <div className="docs">
      <Button type="primary" className="docs__show-nav"
        onClick={() => showNav()}>
        {!showNavbar
          ? 'Ver todos os tópicos'
          : 'Fechar navegação'}
      </Button>
      <h2 className="docs__header">Documentação</h2>
      <p>Esta página é uma visão geral da documentação do Line-Web.</p>
      <div className="docs__main-content">
        <div className="docs__container">
          {topics.map(item =>
            <div key={item.title} id={item.title.replace(' ', '')} className="docs__item">
              <h1>{item.title}</h1>
              {item.content}
              <Divider />
            </div>
          )}
        </div>
        <DocsNavbar topics={topics} showNavbar={showNavbar} showNav={showNav} />
      </div>
    </div>
  )
}

export default Docs

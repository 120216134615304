export const avaliationTooltip = 'Ao habilitar este campo, você poderá indicar um valor para substituir o valor de mercado (no empréstimo concedido e na minuta).'

export const DEPARTMENT_SELECTION_OPTIONS = [
  { name: 'Todos', value: '' },
  { name: 'Documentos', value: 'documentos' },
  { name: 'Crédito', value: 'credito' },
  { name: 'Engenharia', value: 'engenharia' },
  { name: 'Análise documental', value: 'analise-documental' },
  { name: 'Contrato', value: 'contrato' },
  { name: 'Cartório', value: 'cartorio' }
]

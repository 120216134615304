import corbans from 'utils/corbans'

let reactAppGRecaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY
let reactAppEnv = process.env.REACT_APP_ENV
let reactAppLine = process.env.REACT_APP_LINE
let reactAppServer = process.env.REACT_APP_SERVER
let reactAppRodobensUrl = process.env.REACT_APP_RODOBENS_URL
let reactAppLineRodobens = process.env.REACT_APP_LINE_RODOBENS

try {
  const localVars = require('./_localVars')

  const {
    REACT_APP_GOOGLE_RECAPTCHA_KEY,
    REACT_APP_ENV,
    REACT_APP_LINE,
    REACT_APP_SERVER,
    REACT_APP_RODOBENS_URL,
    REACT_APP_LINE_RODOBENS
  } = localVars.default.variables

  reactAppGRecaptchaKey = REACT_APP_GOOGLE_RECAPTCHA_KEY
  reactAppEnv = REACT_APP_ENV
  reactAppLine = REACT_APP_LINE
  reactAppServer = REACT_APP_SERVER
  reactAppRodobensUrl = REACT_APP_RODOBENS_URL
  reactAppLineRodobens = REACT_APP_LINE_RODOBENS
} catch (error) {
  const host = document.location.hostname
  reactAppLine = corbans[host]?.line || reactAppLine
  reactAppServer = corbans[host]?.server || reactAppServer
}

export {
  reactAppGRecaptchaKey,
  reactAppEnv,
  reactAppLine,
  reactAppServer,
  reactAppRodobensUrl,
  reactAppLineRodobens
}

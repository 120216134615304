import React, { useEffect, useState } from 'react'
import Form, { useForm } from 'antd/lib/form/Form'
import { useAuth } from 'hooks'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { IClientPending } from '../../clientInterfaces'
import { EcpCard } from 'ecp/components/EcpCard/EcpCard'
import ErrorDetails from 'components/ErrorDetails/ErrorDetails'
import { Button, message, Skeleton } from 'antd'
import { CorbanClientFields, CorbanClientHeader, CorbanClientPayCheck } from '../CorbanClientForm/CorbanClientForm'
import { useSubmitEndorsement } from '../../hooks/useClientLGPD'
import { IErrorDetails } from 'components/ErrorDetails/interfaces'
import ClientPendingRepository, { ILGPDEndorse } from 'ecp/repositories/ClientPendingRepository'
import { AxiosRequestConfig } from 'axios'
import { handleOnidataErrors } from 'utils/validate'
import { swalError } from 'components/SwalError/SwalError'
import { formatFieldsValue } from '../../clientFunctions'
import queryString from 'query-string'

export default function CorbanClientFormOnidata () {
  const [form] = useForm()
  const history = useHistory()
  const user = useAuth()
  const { clientId } = useParams<{ clientId?: string }>() || {}
  const location = useLocation<{ userFuncaoId: string }>()

  const {
    approveLoading,
    reproveLoading,
    onEndorseOnidataClient
  } = useSubmitEndorsement()

  const isEdit = clientId ? true : false

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<IErrorDetails>()
  const [client, setClient] = useState<IClientPending>()

  const [errors, setErrors] = useState<Partial<IClientPending>>()
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
  const [selectedCorban, setSelectedCorban] = useState<string>('')

  const getUserFuncaoId = (): string | null => {
    const searchParams = queryString.parse(location?.search)
    if (!searchParams?.userFuncaoId) return null
    return String(searchParams?.userFuncaoId)
  }

  useEffect(() => {
    const fetchPendingUser = async (clientId: string) => {
      setLoading(true)
      try {
        setError(undefined)

        const requestConfig: AxiosRequestConfig = {
          params: {
            userFuncaoId: getUserFuncaoId()
          }
        }

        const response = await ClientPendingRepository.findPendingOnidata(clientId, requestConfig)
        const { client } = response?.data?.data || {}
        if (!client) throw new Error('Cliente não encontrado')

        setClient(client)
        form.setFieldsValue(formatFieldsValue(client))
      } catch (error) {
        setError(error.message)
      } finally {
        setLoading(false)
      }
    }

    if (clientId) {
      fetchPendingUser(clientId)
    }
  }, [clientId])

  async function onSubmit (clientId: string, client: IClientPending & { userFuncaoId: string }) {
    setLoadingSubmit(true)
    setErrors(undefined)

    try {
      if (!clientId) throw new Error('Identificador do cliente não enviado.')

      const response = await ClientPendingRepository.updateClientOnidata(clientId, client)
      message.success(response?.data?.message)

      if (isEdit) {
        history.goBack()
      }
    } catch (error) {
      if (error?.data?.error) setErrors(handleOnidataErrors(error.data.error))
      swalError({
        title: 'Atenção',
        err: error,
        icon: 'warning'
      })
    }
    setLoadingSubmit(false)
  }

  if (error) {
    return (
      <EcpCard>
        <ErrorDetails
          title="500"
          error={error}
          subTitle={error?.message}
          status="error"
        />
      </EcpCard>
    )
  }

  if (loading) {
    return (
      <EcpCard>
        <Skeleton />
      </EcpCard>
    )
  }

  return (
    <EcpCard>
      <Form
        form={form}
        onFinish={(client) => {
          if (clientId) {
            onSubmit(
              clientId,
              {
                ...client,
                userFuncaoId: getUserFuncaoId()
              }
            )
          }
        }}
        layout='vertical'
      >
        {isEdit && clientId && (
          <CorbanClientHeader
            onEndorseClient={(clientId: string, endorse: ILGPDEndorse) => {
              const userFuncaoId = getUserFuncaoId()
              if (userFuncaoId) onEndorseOnidataClient(clientId, endorse, userFuncaoId)
            }}
            approveLoading={approveLoading}
            reproveLoading={reproveLoading}
            clientHeaderInfo={{
              name: `${client?.nome || ''} ${client?.sobrenome || ''}`,
              cpf: client?.cpf,
              id: client?.id
            }}
          />
        )}

        <CorbanClientFields
          errors={errors}
          loading={loading}
          setSelectedCorban={setSelectedCorban}
          selectedCorban={selectedCorban}
          isEditing={isEdit}
          level={user.level}
        />

        {client?.contracheque && (
          <CorbanClientPayCheck
            payCheckUrl={client?.contracheque}
          />
        )}

        <div className='client-form__footer'>
          <Button
            type='ghost'
            onClick={() => history.goBack()}
          >
            Cancelar
          </Button>

          <Button
            type='primary'
            htmlType='submit'
            loading={loadingSubmit}
          >
            Salvar
          </Button>
        </div>
      </Form>
    </EcpCard>
  )
}

import React, { useEffect, useState } from 'react'
import { ClockCircleOutlined } from '@ant-design/icons'
import { Button, message } from 'antd'
import swal from 'utils/swal'
import { swalError } from 'components/SwalError/SwalError'
import { IFirstAccessSendEmail, IFirstAccessSendPhone } from '../../firstAccesInterfaces'
import LoginRepository from 'ecp/repositories/LoginRepository'

interface IFirstAccesResendTOken {
  onidataToken?: string
  celular_eh_whatsapp?: boolean
  telefone_celular?: string
  tipo?: string
}

type Props = {
  sendMethod: 'email' | 'cellphone'
  value: IFirstAccesResendTOken
}

export default function FirstAccesResendToken ({ value, sendMethod } : Props) {
  const [secondsTime, setSecondsTime] = useState<number | undefined>(0)
  const [sent, setSent] = useState<Boolean>(false)
  const [time, setTime] = useState<string>('')
  const [loading, setLoading] = useState(false)

  const resendToken = async (value : IFirstAccesResendTOken) => {
    setLoading(true)

    try {
      if (sendMethod === 'email') {
        const data : IFirstAccessSendEmail = {
          tipo: value.tipo,
          onidataToken: value.onidataToken
        }

        const response = await LoginRepository.sendEmailTolken(data)

        if (response.data.message) swal.basic({ title: 'Sucesso!', text: response.data.message, icon: 'success' })
      } else {
        const data : IFirstAccessSendPhone = {
          onidataToken: value.onidataToken,
          celular_eh_whatsapp: value.celular_eh_whatsapp,
          telefone_celular: value.telefone_celular
        }

        const response = await LoginRepository.sendPhoneToken(data)
        if (response.data.message) swal.basic({ title: 'Sucesso!', text: response.data.message, icon: 'success' })
      }

      setSecondsTime(undefined)
      setSecondsTime(15)

      setSent(true)
    } catch (err) {
      swalError({ title: 'Atenção', icon: 'warning', err })
    }
    setLoading(false)
  }

  useEffect(() => {
    if (secondsTime) {
      let time = secondsTime || 0
      const interval = setInterval(() => {
        time--
        if (time < 1) {
          setSent(false)
          clearInterval(interval)
        }
        setTime(time.toString())
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [secondsTime])

  if (sent) {
    return (
      <p className='text-center mt-2 forgot-password-onidata__sent-token' ><ClockCircleOutlined /> Reenviar token em { time }s</p>
    )
  }

  return (
    <>
      <Button
        loading={loading}
        type='link'
        onClick={() => {
          if (value) resendToken(value)
          else message.error('Email não encontrado')
        }}
      >
        Reenviar token
      </Button>
    </>
  )
}

import Swal, { SweetAlertOptions } from 'sweetalert2'

function closeSwal () {
  Swal.close()
}

export interface IBasic extends SweetAlertOptions {
  icon?: 'success' | 'error' | 'warning' | 'info' | 'question';
  title: string;
  text?: string;
  force?: boolean;
  content?: string
}

interface IClose extends IBasic {
  close: Function;
}

export interface IConfirm extends IBasic {
  confirm: any;
  negate?: Function;
}

const anySwalOpen = () => {
  const classes = document.body.classList
  return classes.contains('swal2-shown')
}

const basic = ({
  icon,
  title,
  text,
  force,
  confirmButtonText,
  didClose,
  ...rest
}: IBasic) => {
  if (anySwalOpen() && !force) return

  Swal.fire({
    icon,
    title,
    text,
    didClose,
    confirmButtonText: confirmButtonText || 'OK',
    customClass: {
      ...rest.customClass,
      confirmButton: 'btn btn-success w-100',
      cancelButton: 'btn btn-danger'
    }
  })
}

const htmlBasic = ({ icon, title, text, force, confirmButtonText, didClose, ...rest }: IBasic) => {
  if (anySwalOpen() && !force) return

  Swal.fire({
    ...rest,
    icon,
    title,
    html: text,
    didClose,
    confirmButtonText: confirmButtonText || 'OK',
    customClass: {
      ...rest.customClass,
      confirmButton: 'btn btn-success w-100',
      cancelButton: 'btn btn-danger'
    }
  })
}

const confirmSwal = ({ confirm, force, confirmButtonText = 'OK', ...rest }: IConfirm) => {
  if (anySwalOpen() && !force) return

  Swal.fire({
    ...rest,
    confirmButtonText
  }).then((result) => {
    if (result.isConfirmed) {
      confirm()
    }
  })
}

const onClose = ({ icon, title, text, close, force }: IClose) => {
  if (anySwalOpen() && !force) return

  Swal.fire({
    icon,
    title,
    text,
    willClose: () => {
      close()
    }
  })
}

const confirmNegate = ({ confirm, negate, force, allowOutsideClick = true, confirmButtonText = 'OK', cancelButtonText = 'Cancelar', ...rest }: IConfirm) => {
  if (anySwalOpen() && !force) return

  Swal.fire({
    ...rest,
    allowOutsideClick,
    showCancelButton: true,
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText
  }).then((result) => {
    if (result.isConfirmed) {
      confirm()
    } else if (negate) {
      negate()
    }
  })
}

const timerSwal = ({ icon, title, text, confirm, force }: IConfirm) => {
  if (anySwalOpen() && !force) return

  Swal.fire({
    icon,
    title,
    text,
    timer: 5000,
    showConfirmButton: true,
    confirmButtonText: 'OK',
    allowOutsideClick: true
  }).then((result) => {
    if (result.isConfirmed) {
      confirm()
    }
  })
}

const message = ({ title, text, force }: IBasic) => {
  if (anySwalOpen() && !force) return

  Swal.fire({
    title,
    text,
    customClass: {
      confirmButton: 'btn btn-success w-100',
      cancelButton: 'btn btn-danger'
    }
  })
}

const swal = {
  basic,
  htmlBasic,
  text: message,
  onClose,
  confirmNegate,
  confirm: confirmSwal,
  timerSwal,
  closeSwal
}

export default swal

import React, { MutableRefObject, useLayoutEffect, useRef, useState } from 'react'
import swal from 'utils/swal'
import { Form } from 'antd'
import { validateResponse } from 'utils/validate'
import { drawerButtonIsLoading, drawerReset, drawerFormRefSubmit } from 'store/modules/drawer/actions'
import { tablesUpdate } from 'store/modules/tables/actions'
import { useDispatch } from 'react-redux'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'
import RichTextEditor from 'components/RichTextEditor/RichTextEditor'

function DeclineProposal ({ proposalId, submitFn }: { proposalId: string | undefined, submitFn: Function }) {
  const dispatch = useDispatch()

  const [errors, setErrors] = useState<any>({})
  const [richText, setRichText] = useState<string>('')

  const formRef: MutableRefObject<any> = useRef(null)

  async function onSubmit () {
    if (!proposalId) return

    const formData = new FormData()
    if (richText) formData.append('commentary', richText)

    dispatch(drawerButtonIsLoading(true))
    swal.confirmNegate({
      title: 'Atenção',
      text: `Ao cancelar essa proposta, não será possível revogá-la.
            Além disso, o cliente perderá seu acesso. \nVocê tem certeza que deseja cancelar?`,
      icon: 'info',
      confirm: async () => {
        try {
          const response = await submitFn({ proposalId, formData })
          setErrors({})
          dispatch(drawerReset())
          dispatch(tablesUpdate())
          swal.basic({ title: 'Sucesso', text: response.message, icon: 'success' })
        } catch (err) {
          if (err.data?.invalid) {
            let errors: { commentary?: string } = {}
            errors = validateResponse(err.data?.invalid)
            setErrors(errors)
            dispatch(drawerButtonIsLoading(false))

            const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
            invalidFieldsError({ invalidFields: invalidFields })
            return
          }

          setErrors({})
          dispatch(drawerButtonIsLoading(false))
          swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
        }
      },
      negate: () => dispatch(drawerButtonIsLoading(false))
    })
  }

  useLayoutEffect(() => {
    dispatch(drawerFormRefSubmit(formRef))
  }, [])

  return (
    <Form
      ref={formRef}
      onFinish={onSubmit}
      layout="vertical"
    >
      <Form.Item
        label={<label>Motivo do cancelamento</label>}
        help={errors.name && errors.name}
        validateStatus={errors.name && 'error'}
        name="commentary"
      >
        <RichTextEditor
          setContents={richText}
          onChange={(value: string) => setRichText(value)}
        />
      </Form.Item>
    </Form>
  )
}

export default DeclineProposal

import { message, Spin } from 'antd'
import { useAuth, useResources } from 'hooks'
import React, { useEffect } from 'react'
import ProductDropdown from './components/ProductDropdown/ProductDropdown'
import ProductModel from 'ecp/models/ProductModel'
import useSelectedProducts from './hooks/useSelectedProducts'
import { resourcesGetProducts } from 'store/modules/resources/actions'
import { useDispatch } from 'react-redux'

export const SelectProductsSidebar = () => {
  const user = useAuth()
  const resources = useResources()
  const dispatch = useDispatch()
  const { loading, onProductSelect } = useSelectedProducts()

  const { _id: productId } = user.selectedProduct || {}

  useEffect(() => {
    try {
      if (resources.products.length < 1) dispatch(resourcesGetProducts())
    } catch (err) {
      message.error(err?.message)
    }
  }, [])

  if (loading || !productId) return <Spin />
  if (!ProductModel.canSeeProductsSelect(resources.products)) return null

  return (
    <ProductDropdown
      selectedProductId={productId}
      products={resources.products || []}
      onProductSelect={(id, products) => {
        if (id === productId) return new Promise(() => {})
        return onProductSelect(id, products, user)
      }}
    />
  )
}

export default SelectProductsSidebar

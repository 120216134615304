import axios, { AxiosRequestConfig } from 'axios'
import { _reviewStatusOption } from 'components/ReviewBackoffice/reviewBackofficeInterfaces'
import ReviewRadioStatus from 'components/ReviewRadioStatus/ReviewRadioStatus'
import { TRANSLATE_STATUS } from 'ecp/app/Proposals/proposalConstants'
import DynamicProposalECPFRepository from 'ecpf/repositories/DynamicProposalECPFRepository'
import React, { useEffect, useState } from 'react'
import format from 'utils/format'

const formatToSelectStatusLabel = (item: string) => ({
  value: item,
  label: format.capitalize(TRANSLATE_STATUS?.[item as keyof typeof TRANSLATE_STATUS])
})

interface Props {
  stepSlug?: string
  onChangeRadio: (value: _reviewStatusOption) => void
}

function ProposalStatus ({ stepSlug, onChangeRadio }: Props) {
  const [status, setStatus] = useState<{ value: string, label: string }[]>([])

  useEffect(() => {
    const abortController = new AbortController()
    async function fetchStatusLabel () {
      try {
        const requestConfig: AxiosRequestConfig = {
          signal: abortController.signal,
          params: { slug: stepSlug }
        }

        const response = await DynamicProposalECPFRepository.getProposalStatus(requestConfig)
        const status = response.data.data?.status

        if (!status?.length) {
          setStatus([])
          throw new Error('Nenhum status encontrado')
        }

        const formatedValues = (status || []).map(formatToSelectStatusLabel)
        setStatus(formatedValues)
      } catch (err) {
        if (!axios.isAxiosError(err)) {
          console.log(err?.message)
        }
      }
    }

    fetchStatusLabel()
    return () => abortController.abort()
  }, [])

  return (
    <ReviewRadioStatus
      statusOptions={status}
      onChange={onChangeRadio}
    />
  )
}

export default ProposalStatus

import { IPortabilitySimulationDataECPF } from 'ecpf/app/ProposalECPF/components/PortabilitySimulationFlowECPF/PortabilitySimulationFlowECPF'
import { IDadoCadastral, IEnderecoItem, ISimulationECPFResponse } from 'ecpf/app/ProposalECPF/ProposalECPFInterfaces'
import { IProposalSimulationMinMaxEcpf } from 'ecpf/models/ProposalFuncaoModel'
import { IClientCovenantECPF } from 'ecpf/repositories/CovenantECPFRepository'
import { IContractECPF } from 'ecpf/repositories/OperationsECPFRepository'

export interface IPortabilitySimulationFlow {
  portabilitySimulationData?: IPortabilitySimulationDataECPF
  conditionals?: ISimulationECPFResponse
}

export interface ISelectContractsFlow {
  selectedContracts?: string[]
  contracts?: IContractECPF[]
}

// algumas propriedades retornadas pelo backend no redux estão com nomes diferentes
export interface IReduxProposalFlow {
  customerFlow?: {
    cliente: {
      cnpj?: string
      nome?: string
      documento?: string
      dataNascimento?: string
      numeroMatricula?: string
      dataAdmissao?: string
      valorRenda?: number
    }
  }
  simulationFlowMinMax?: IProposalSimulationMinMaxEcpf
  extraCustomerFlow?: {
    dadoCadastral: IDadoCadastral
  }
  clientCovenant?: Partial<IClientCovenantECPF>
  chosenCovenant?: string
  simulationFlow?: ISimulationECPFResponse
  portabilitySimulationFlow?: IPortabilitySimulationFlow
  client?: {
    naturalidade?: string
    telefone?: { itens: { ddd: string, tipo: string, numero: string }[]}
    bancario?: IDadoCadastral['dadosBancarios']
    dadoEndereco?: {
      tipoCorrespondencia?: string
      endereco: { itens: Array<IEnderecoItem> }
    } & IDadoCadastral['dadoEndereco'],
    dadoProfissional: {
      empresaDocumento?: string
    } & IDadoCadastral['dadoProfissional']
  } & IDadoCadastral
  tokenValidationFlowEcpf?: {
    isValid: boolean
  }
  selectContractsFlow?: ISelectContractsFlow
}

export enum ProposalFlowTypes {
  SET_CLIENT_INFO = 'PROPOSAL_FLOW_SET_CLIENT_INFO',
  SET_CLIENT_COVENANT = 'PROPOSAL_FLOW_SET_CLIENT_COVENANT',
  SET_CUSTOMER_FLOW = 'PROPOSAL_FLOW_SET_CUSTOMER_FLOW',
  SET_SIMULATION_FLOW = 'PROPOSAL_FLOW_SET_SIMULATION_FLOW',
  RESET_PROPOSAL_FLOW = 'PROPOSAL_FLOW_RESET_PROPOSAL_FLOW',
  RESET_SIMULATION_FLOW = 'PROPOSAL_FLOW_RESET_SIMULATION_FLOW',
  RESET_CLIENT_COVENANT = 'PROPOSAL_FLOW_RESET_CLIENT_COVENANT',
  SET_CHOSEN_COVENANT = 'PROPOSAL_FLOW_SET_CHOSEN_COVENANT',
  SET_EXTRA_CUSTOMER_DATA = 'PROPOSAL_FLOW_SET_EXTRA_CUSTOMER_DATA',
  SET_SIMULATION_FLOW_MIN_MAX = 'PROPOSAL_FLOW_SET_SIMULATION_FLOW_MIN_MAX',
  SET_TOKEN_VALIDATION_FLOW_ECPF = 'PROPOSAL_FLOW_SET_TOKEN_VALIDATION_FLOW_ECPF',
  RESET_SIMULATION_FLOW_MIN_MAX = 'PROPOSAL_FLOW_RESET_SIMULATION_FLOW_MIN_MAX',
  SET_PORTABILITY_SIMULATION_FLOW = 'PROPOSAL_FLOW_SET_PORTABILITY_SIMULATION_FLOW',
  SET_SELECT_CONTRACTS_FLOW = 'PROPOSAL_FLOW_SET_SELECT_CONTRACTS_FLOW'
}

export const proposalFlowActions = {
  setClient: (client?: IDadoCadastral) => {
    return ({
      type: ProposalFlowTypes.SET_CLIENT_INFO,
      payload: {
        client
      }
    })
  },

  setPortabilitySimulationFlow: (portabilitySimulationFlow?: IPortabilitySimulationFlow) => {
    return ({
      type: ProposalFlowTypes.SET_PORTABILITY_SIMULATION_FLOW,
      payload: {
        portabilitySimulationFlow
      }
    })
  },

  setSelectContractsFlow: (selectContractsFlow?: ISelectContractsFlow) => {
    return ({
      type: ProposalFlowTypes.SET_SELECT_CONTRACTS_FLOW,
      payload: {
        selectContractsFlow
      }
    })
  },

  setTokenValidationFlowEcpf: (isValid?: boolean) => {
    return ({
      type: ProposalFlowTypes.SET_TOKEN_VALIDATION_FLOW_ECPF,
      payload: {
        tokenValidationFlowEcpf: {
          isValid
        }
      }
    })
  },

  setCustomerFlow: (customerFlow?: IReduxProposalFlow['customerFlow']) => {
    return ({
      type: ProposalFlowTypes.SET_CUSTOMER_FLOW,
      payload: {
        customerFlow
      }
    })
  },

  setSimulationFlow: (simulationFlow?: IReduxProposalFlow['simulationFlow']) => {
    return ({
      type: ProposalFlowTypes.SET_SIMULATION_FLOW,
      payload: {
        simulationFlow
      }
    })
  },

  setSimulationFlowMinMax: (simulationFlowMinMax?: IReduxProposalFlow['simulationFlowMinMax']) => {
    return ({
      type: ProposalFlowTypes.SET_SIMULATION_FLOW_MIN_MAX,
      payload: {
        simulationFlowMinMax
      }
    })
  },

  setExtraCustomerData: (extraCustomerData?: IReduxProposalFlow['extraCustomerFlow']) => {
    return ({
      type: ProposalFlowTypes.SET_EXTRA_CUSTOMER_DATA,
      payload: {
        extraCustomerData
      }
    })
  },

  setClientCovenant: (clientCovenant?: IReduxProposalFlow['clientCovenant']) => {
    return ({
      type: ProposalFlowTypes.SET_CLIENT_COVENANT,
      payload: {
        clientCovenant
      }
    })
  },

  setChoosenCovenant: (choosenCovenant?: string) => {
    return ({
      type: ProposalFlowTypes.SET_CHOSEN_COVENANT,
      payload: {
        choosenCovenant
      }
    })
  },

  resetProposalFlow: () => {
    return ({
      type: ProposalFlowTypes.RESET_PROPOSAL_FLOW,
      payload: null
    })
  },

  resetSimulationFlow: () => {
    return ({
      type: ProposalFlowTypes.RESET_SIMULATION_FLOW
    })
  },

  resetSimulationFlowMinMax: () => {
    return ({
      type: ProposalFlowTypes.RESET_SIMULATION_FLOW_MIN_MAX
    })
  },

  resetClientCovenant: () => {
    return ({
      type: ProposalFlowTypes.RESET_CLIENT_COVENANT
    })
  }
}

import { IUserPermission } from 'egi/repositories/PermissionsRepository'
import { IAuth } from 'egi/types'
import { PermissionModel } from 'models/PermissionModel'
import { EcpfRoutes } from 'routes/ecpf/ecpfRoutes'
import { UserEcpfSidebar } from './EcpSidebar'

interface IEcpfSidebar {
  userRoutes: UserEcpfSidebar
}

export default class EcpfSidebar {
  protected userRoutes: UserEcpfSidebar

  constructor ({ userRoutes }: IEcpfSidebar) {
    this.userRoutes = userRoutes
  }

  getUserRoutes (routes: EcpfRoutes) {
    return this.userRoutes.filterRoutes(routes)
  }
}

export class CorbanRoutesEcpf extends UserEcpfSidebar {
  televendasRoutes (routes: EcpfRoutes) {
    return [
      routes.ecpfProposalsList,
      routes.ecpfProposalDetails,
      routes.ecpfFindUserNewContract,
      routes.ecpfProposalFlow,

      // routes.ecpfFindUserRenegotiation,
      // routes.ecpfSelectContractsRenegotiation,

      routes.ecpfFindUserRefinancing,
      routes.ecpfSelectContractsRefinancing,

      // TODO - Rodobens hasn't finished configuring this product.
      // routes.ecpfPortability,
      // routes.ecpfFindUserPortability,

      routes.ecpfOperatorList,
      routes.ecpfProposalDetails,
      routes.ecpfEmployerList,
      this.hideRoute(routes.ecpfOrganizationList)
    ]
  }

  filterRoutes (routes: EcpfRoutes) {
    if (PermissionModel.hasParceiroTelevendas(this.permissions)) {
      return this.televendasRoutes(routes)
    }

    return [
      routes.ecpfProposalsList,
      routes.ecpfFindUserNewContract,
      routes.ecpfProposalFlow,
      routes.ecpfFindUserRefinancing,
      routes.ecpfSelectContractsRefinancing,

      // TODO - Rodobens hasn't finished configuring this product.
      // routes.ecpfPortability,
      // routes.ecpfFindUserPortability,

      routes.ecpfOperatorList,
      routes.ecpfProposalDetails,
      routes.ecpfEmployerList,
      this.hideRoute(routes.ecpfOrganizationList)
    ]
  }
}

export class OperatorRoutesEcpf extends UserEcpfSidebar {
  televendasRoutes (routes: EcpfRoutes) {
    return [
      routes.ecpfProposalsList,
      routes.ecpfProposalDetails,
      routes.ecpfFindUserNewContract,
      routes.ecpfProposalFlow,

      // routes.ecpfFindUserRenegotiation,
      // routes.ecpfSelectContractsRenegotiation,

      routes.ecpfFindUserRefinancing,
      routes.ecpfSelectContractsRefinancing,

      // TODO - Rodobens hasn't finished configuring this product.
      // routes.ecpfPortability,
      // routes.ecpfFindUserPortability,

      routes.ecpfEmployerList,
      this.hideRoute(routes.ecpfOrganizationList)
    ]
  }

  filterRoutes (routes: EcpfRoutes) {
    if (PermissionModel.hasParceiroTelevendas(this.permissions)) {
      return this.televendasRoutes(routes)
    }

    return [
      routes.ecpfProposalsList,
      routes.ecpfProposalDetails,
      routes.ecpfFindUserNewContract,
      routes.ecpfProposalFlow,
      routes.ecpfFindUserRefinancing,
      routes.ecpfSelectContractsRefinancing,

      // TODO - Rodobens hasn't finished configuring this product.
      // routes.ecpfPortability,
      // routes.ecpfFindUserPortability,

      routes.ecpfEmployerList,
      this.hideRoute(routes.ecpfOrganizationList)
    ]
  }
}

export class MasterRoutesEcpf extends UserEcpfSidebar {
  televendasRoutes (routes: EcpfRoutes) {
    return [
      routes.ecpfProposalsList,
      routes.ecpfProposalDetails,

      this.changeName(routes.ecpfFindUserNewContract, 'Novo contrato'),
      routes.ecpfProposalFlow,

      // routes.ecpfFindUserRenegotiation,
      // routes.ecpfSelectContractsRenegotiation,

      routes.ecpfFindUserRefinancing,
      routes.ecpfSelectContractsRefinancing,

      // TODO - Rodobens hasn't finished configuring this product.
      // routes.ecpfPortability,
      // routes.ecpfFindUserPortability,

      routes.ecpfMainOfficeList,
      routes.ecpfRegionalList,
      routes.ecpfPromoterList,
      routes.ecpfEmployerList,
      routes.ecpfOrganizationList,
      routes.ecpfOperatorList,
      routes.ecpfClientDetails,
      routes.ecpfClientForm,
      routes.ecpfConfiguration,
      routes.ecpfConfigurationInsurance,
      routes.ecpfConfigurationMaximumRequestedValue,
      routes.userDetails
    ]
  }

  filterRoutes (routes: EcpfRoutes) {
    if (PermissionModel.hasParceiroTelevendas(this.permissions)) {
      return this.televendasRoutes(routes)
    }

    return [
      routes.ecpfProposalsList,
      routes.ecpfProposalDetails,
      routes.ecpfMainOfficeList,
      routes.ecpfRegionalList,
      routes.ecpfPromoterList,
      routes.ecpfEmployerList,
      routes.ecpfOrganizationList,
      routes.ecpfOperatorList,
      routes.ecpfClientDetails,
      routes.ecpfClientForm,
      routes.ecpfConfiguration,
      routes.ecpfConfigurationInsurance,
      routes.ecpfConfigurationMaximumRequestedValue,
      routes.userDetails
    ]
  }
}

export class ClientRoutesEcpf extends UserEcpfSidebar {
  filterRoutes (routes: EcpfRoutes) {
    return [
      routes.ecpfClientInitial,
      routes.ecpfProposalsList,
      routes.ecpfProposalFlow,
      routes.ecpfProposalDetails,
      routes.ecpfClientDetails,
      routes.ecpfClientForm,
      routes.ecpfFindUserNewContract,

      // routes.ecpfFindUserRenegotiation,
      // routes.ecpfSelectContractsRenegotiation,

      routes.ecpfFindUserRefinancing,
      routes.ecpfSelectContractsRefinancing

      // TODO - Rodobens hasn't finished configuring this product.
      // routes.ecpfPortability,
      // routes.ecpfFindUserPortability
    ]
  }
}

export class AdminRoutesEcpf extends UserEcpfSidebar {
  protected auth: IAuth

  constructor (auth: IAuth, permissions: IUserPermission[]) {
    super(permissions)
    this.auth = auth
  }

  juridicoRoutes (routes: EcpfRoutes) {
    const currentSearch = routes.ecpfProposalsList?.meta?.location?.search ?? ''

    return [
      this.changeSearch(routes.ecpfProposalsList, currentSearch + '&situation=43'),
      routes.ecpfProposalDetails,
      routes.ecpfClientDetails,
      routes.ecpfClientForm
    ]
  }

  filterRoutes (routes: EcpfRoutes) {
    if (this.auth.areaName === 'juridico') {
      return this.juridicoRoutes(routes)
    }

    return []
  }
}

export class BackofficeRoutesEcpf extends UserEcpfSidebar {
  protected auth: IAuth

  constructor (auth: IAuth, permissions: IUserPermission[]) {
    super(permissions)

    this.auth = auth
  }

  juridicoRoutes (routes: EcpfRoutes) {
    const currentSearch = routes.ecpfProposalsList?.meta?.location?.search ?? ''

    return [
      this.changeSearch(routes.ecpfProposalsList, currentSearch + '&situation=43'),
      routes.ecpfProposalDetails,
      routes.ecpfClientDetails,
      routes.ecpfClientForm
    ]
  }

  filterRoutes (routes: EcpfRoutes) {
    if (this.auth.areaName === 'juridico') {
      return this.juridicoRoutes(routes)
    }

    return []
  }
}

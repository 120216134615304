import { MlmDashboardTopResponse, MlmDashboardWeekly } from 'egi/repositories/MlmRepository'
import { colors } from 'styles/colors'

export const chartConfigDays = (data: MlmDashboardWeekly[]) => ({
  data: data,
  xField: 'day',
  yField: 'value',
  seriesField: 'name',
  smooth: true,
  label: {
    style: {
      fontSize: 12,
      fontWeight: 300,
      textAlign: 'center',
      textBaseline: 'middle',
      shadowColor: 'white',
      shadowBlur: 10
    }
  },
  columnStyle: {
    radius: [10, 10, 0, 0],
    fill: colors.primary
  },
  minColumnWidth: 20,
  maxColumnWidth: 20,
  tooltip: {
    customContent: (title: any, data: any) => {
      return `<div class="p-3">${title}: <b>${data[0]?.data?.value || 0}</b></div>`
    }
  },
  animation: {
    appear: {
      animation: 'path-in',
      duration: 2000
    }
  }
})

export const chartConfigTop = (data: MlmDashboardTopResponse[]) => ({
  data: data,
  xField: 'value',
  yField: 'name',
  seriesField: 'name',
  color: ['#2B6D80', '#2d6cc4', '#4A78FF', '#43E8E0', '#4AFFC3'],
  label: {
    style: {
      fontSize: 12,
      fontWeight: 300,
      textAlign: 'center',
      textBaseline: 'middle',
      shadowColor: 'white',
      shadowBlur: 10
    },
    content: function content (_ref: any) {
      const { name, value } = _ref
      return `${name}: ${value}`
    }
  },
  barStyle: {
    radius: [10, 10, 10, 10]
  },
  yAxis: {
    label: {
      formatter: () => ''
    }
  }
})

export const chartConfigStatus = (data: any) => ({
  data: data,
  appendPadding: 10,
  angleField: 'value',
  colorField: 'type',
  color: (_ref: any): any => {
    const type = _ref.type
    switch (type) {
      case 'Aprovado':
        return colors.approved
      case 'Reprovado':
        return colors.reproved
      case 'Em andamento':
        return colors.analysis
      default:
        return 'black'
    }
  },
  radius: 0.9,
  label: {
    type: 'inner',
    offset: '-30%',
    content: function content (_ref: any) {
      const percent = _ref.percent
      return ''.concat(((percent * 100).toFixed(1)), '%')
    },
    style: {
      fontSize: 14,
      textAlign: 'center'
    }
  },
  interactions: [{ type: 'element-active' }]
})

interface IValue {
  time: string
  count: number
  name: string
}

interface IConfig {
  transformData: Array<IValue>
}

function colorsHistory (_ref: any) {
  const name = _ref.name
  switch (name) {
    case 'Analisando':
    case 'Em andamento':
      return colors.analysis
    case 'Refazer':
      return colors.remaking
    case 'Reprovado':
      return colors.reproved
    case 'Aprovado':
      return colors.approved
    case 'Cancelado':
      return colors.canceled
    default:
      return 'black'
  }
}

export const historyConfig = ({ transformData }: IConfig) => ({
  data: transformData,
  xField: 'time',
  yField: 'count',
  seriesField: 'name',
  isGroup: 'true' as any,
  columnStyle: {
    radius: [10, 10, 0, 0]
  },
  maxColumnWidth: 20,
  color: colorsHistory
})

export const dashChartStyle = { minHeight: 125 }
export const dashGreaterChartStyle = { minHeight: 160 }

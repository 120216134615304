import React, { Fragment, useLayoutEffect, useState } from 'react'
import BaseCard from '../MlmBaseCard/MlmBaseCard'
import { IFilterDate } from '../../views/MlmDashboard/types'
import { FunnelPlotFilled } from '@ant-design/icons'
import { Skeleton } from 'antd'
import MlmRepository from 'egi/repositories/MlmRepository'

function MlmTotalLeads ({ filterDate }: IFilterDate) {
  const [total, setTotal] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [failed, setFailed] = useState<boolean>(false)

  useLayoutEffect(() => {
    const abortController = new AbortController()
    async function fetchApprovedToday () {
      setLoading(true)
      try {
        const response = await MlmRepository.leads({
          signal: abortController.signal,
          params: filterDate
        })
        const { count } = response.data.data || {}

        setTotal(count || 0)
        setFailed(false)
        setLoading(false)
      } catch (err) {
        if (!abortController.signal.aborted) {
          setFailed(true)
          setLoading(false)
        }
      }
    }

    if (filterDate.createdDate && filterDate.productId) fetchApprovedToday()
    return () => abortController.abort()
  }, [filterDate.createdDate, filterDate.productId, filterDate.promoterId, filterDate.sellerId, filterDate.type])

  const finalColor = 'primary'

  return (
    <Fragment>
      {loading ? (
        <Skeleton
          active={true}
          paragraph={{ rows: 1 }}
        />
      ) : (
        <BaseCard
          mainOrder={['value', 'title']}
          valueColor={finalColor}
          failed={failed}
          value={total}
          description='Total Leads'
          Icon={<FunnelPlotFilled className={'dashboard-icon-size color-' + finalColor} />}
        />
      )}
    </Fragment>
  )
}

export default MlmTotalLeads

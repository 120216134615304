import { Checkbox, Col, Divider, Form, Row, Typography } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { Address, Input } from 'components'
import React, { Fragment, useEffect, useState } from 'react'
import { usePartnerDepartment } from '../../../../hooks/usePartnerDepartment'
import { IFormContractErrors } from './types'
import WitnessFields from './WitnessFields'

type Props = {
  errors: IFormContractErrors,
  readOnly: boolean,
  formRef: FormInstance
}

const cannotHaveResponsible = (type: string | undefined) => type ? ['IN', 'MP'].includes(type) : false

function PartnerFormContractFields ({ formRef, errors, readOnly }: Props) {
  const [hasResponsible, setHasResponsible] = useState<boolean>()
  const { partnerType } = usePartnerDepartment()
  const canHaveResponsible = !cannotHaveResponsible(partnerType)

  useEffect(() => {
    const partnerHasResponsible = formRef.getFieldValue('hasResponsible')
    if (partnerHasResponsible) setHasResponsible(partnerHasResponsible)
  }, [formRef])

  return (
    <Fragment>

      <Divider />

      <Form
        form={formRef}
        layout="vertical"
        initialValues={{
          hasResponsible: false
        }}
      >

        <Typography.Paragraph className="bold">Testemunha 1</Typography.Paragraph>
        <WitnessFields
          witness='firstWitness'
          errors={{
            name: errors.firstWitnessName,
            rg: errors.firstWitnessRg,
            cpf: errors.firstWitnessCpf
          }}
          readOnly={readOnly}
        />

        <Divider />

        <Typography.Paragraph className="bold">Testemunha 2</Typography.Paragraph>
        <WitnessFields
          witness='secondWitness'
          errors={{
            name: errors.secondWitnessName,
            rg: errors.secondWitnessRg,
            cpf: errors.secondWitnessCpf
          }}
          readOnly={readOnly}
        />

        <Divider />

        {canHaveResponsible && (
          <Fragment>

            <Row>
              <Col className="px-1" lg={10} sm={24} xs={24}>
                <Form.Item
                  name={'hasResponsible'}
                  className="form-user-checkbox"
                  valuePropName="checked"
                  help={errors?.hasResponsible && (errors.hasResponsible)}
                  validateStatus={errors?.hasResponsible && ('error')}
                >
                  <Checkbox
                    onChange={() => setHasResponsible(prev => !prev)}
                    id="test-checkbox-policy"
                    disabled={readOnly}
                  >
                    Possui responsável pela plataforma eletrônica?
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>

            {hasResponsible && (
              <Fragment>
                <Typography.Paragraph className="bold">Responsável pela Plataforma eletrônica</Typography.Paragraph>

                <Row>
                  <Col className="px-1" lg={8} sm={24} xs={24}>
                    <Form.Item
                      name={['electronicPlatformResponsible', 'name']}
                      label={<label>Nome</label>}
                      help={errors?.electronicPlatformResponsibleName && (errors?.electronicPlatformResponsibleName)}
                      validateStatus={errors?.electronicPlatformResponsibleName && ('error')}
                    >
                      <Input placeholder='Digite o nome' readOnly={readOnly} />
                    </Form.Item>
                  </Col>

                  <Col className="px-1" lg={8} sm={24} xs={24}>
                    <Form.Item
                      name={['electronicPlatformResponsible', 'cpf']}
                      label={<label>CPF</label>}
                      help={errors?.electronicPlatformResponsibleCpf && (errors?.electronicPlatformResponsibleCpf)}
                      validateStatus={errors?.electronicPlatformResponsibleCpf && ('error')}
                    >
                      <Input placeholder='Digite o CPF' mask="cpf" readOnly={readOnly} />
                    </Form.Item>
                  </Col>
                </Row>

                <Address
                  onCepBlur={(address, remainingAddress) => formRef.setFieldsValue({ electronicPlatformResponsible: { ...formRef.getFieldValue('electronicPlatformResponsible'), address: { ...address, ...remainingAddress } } })}
                  formRef={formRef}
                  errors={{
                    zipcode: errors.electronicPlatformResponsibleAddressZipcode,
                    street: errors.electronicPlatformResponsibleAddressStreet,
                    number: errors.electronicPlatformResponsibleAddressNumber,
                    additional: errors.electronicPlatformResponsibleAddressAdditional,
                    neighborhood: errors.electronicPlatformResponsibleAddressNeighborhood,
                    state: errors.electronicPlatformResponsibleAddressState,
                    city: errors.electronicPlatformResponsibleAddressCity,
                    country: errors.electronicPlatformResponsibleAddressCountry
                  }}
                  person='Responsável pela Plataforma eletrônica'
                  title='Endereço'
                  readOnlyInput={readOnly}
                  field='electronicPlatformResponsible'
                />
              </Fragment>
            )}
          </Fragment>
        )}
      </Form>
    </Fragment>

  )
}

export default PartnerFormContractFields

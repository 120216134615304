/* eslint-disable react/prop-types */
import React, { MutableRefObject } from 'react'
import CurrencyInput from 'react-currency-input-field'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> { loading?: boolean, inputRef?: MutableRefObject<any>, rest?: any, onValueChange?: any }

function InputMoneySpreed ({ inputRef, className, ...rest }: InputProps) {
  return (
    <CurrencyInput
      {...(rest as any)}
      ref={inputRef}
      prefix="R$ "
      className={className ? className : 'ant-input'}
      decimalsLimit={2}
      decimalSeparator=","
      groupSeparator="."
      onInput={(e) => {
        const value = (e.target as HTMLInputElement).value
        const numericalValue = Number(value.match(/[\d^,]/gi)?.join(''))
        const fractionalValue = value.match(/,\d+$/gi)?.join('') || ''
        if (numericalValue > 999999999 || fractionalValue.length > 3) {
          (e.target as HTMLInputElement).value = value.slice(0, -1)
        }
      }}
    />
  )
}

export default InputMoneySpreed

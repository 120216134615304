import format from 'utils/format'
import CONVERTLIST from 'utils/convertList'
import { timeAsDayjs } from 'utils/time'
import { formatYear } from 'utils/date'
import translate from 'utils/translate'
import { IProposal } from 'egi/types'

const FIXCONVERTLIST: { [key: string]: any } = {
  ...CONVERTLIST,
  birthdate: (value: string) => format.brasilDate(timeAsDayjs(value, { applyTimezone: false })),
  createdAt: (value: string) => formatYear(value),
  updatedAt: (value: string) => formatYear(value),
  value: (value: string) => format.formatBRL(Number(value)),
  lastArea: (value: string) => translate.areasNames(value)
}

function convertPropertsObject (item: Object) {
  const COPY_ITEM: any = Object.assign({}, item)
  const KEYS_COPY_ITEM = Object.keys(COPY_ITEM)

  const possibles = Object.keys(FIXCONVERTLIST)

  for (let i = 0; i < KEYS_COPY_ITEM.length; i++) {
    for (let j = 0; j < possibles.length; j++) {
      if (possibles[j] === KEYS_COPY_ITEM[i]) {
        COPY_ITEM[KEYS_COPY_ITEM[i]] = FIXCONVERTLIST[possibles[j]](COPY_ITEM[KEYS_COPY_ITEM[i]])
      }
    }
  }

  return COPY_ITEM
}

export function enhanceServerTable (data: Array<Object> | Array<IProposal>) {
  if (!data) return []

  const GENERATE_NEW_DATA = []
  const GENERATE_OBJECT = {}
  const GREATER_LENGTH = 0

  for (let i = 0; i < data.length; i++) {
    const tempItem = convertPropertsObject(data[i])
    const lenghtTempItem = Object.keys(tempItem).length

    if (lenghtTempItem >= GREATER_LENGTH) Object.assign(GENERATE_OBJECT, tempItem)
    GENERATE_NEW_DATA.push(tempItem)
  }

  return GENERATE_NEW_DATA
}

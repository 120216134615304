import TokenVerificationSVG from 'assets/reactSvgs/TokenVerificationSVG/TokenVerificationSVG'
import { TokenValidationMessageTemplateType } from 'ecpf/models/TokenValidationModel'
import React, { ReactNode, useState } from 'react'
import TokenInput from './components/TokenInput/TokenInput'
import TokenValidationConfirm from './components/TokenValidationConfirm/TokenValidationConfirm'
import TokenValidationMethod from './components/TokenValidationMethod/TokenValidationMethod'
import { TokenService } from '../../services/tokenService'

interface IShowCorrectTokenStep {
  children?: ReactNode
  onSubmit: (token: string, contact: string, method: TokenMethodEnum | null) => Promise<any>

  messageTemplate: TokenValidationMessageTemplateType
  email?: string
  cellphone?: string

  readOnlyInput?: boolean
}

export interface IValidateResponseData {
  isValidToken?: boolean;
}

export enum TokenMethodEnum {
  sms = 'sms',
  email = 'email'
}

const TokenValidator = ({ children, email, cellphone, readOnlyInput, onSubmit, messageTemplate }: IShowCorrectTokenStep) => {
  const [step, setStep] = useState<'method' | 'input' | 'confirm'>('method')
  const [method, setMethod] = useState<TokenMethodEnum | null>(null)
  const [contactInfo, setContactInfo] = useState<string>('')

  const onSelectMethod = (selectedMethod: TokenMethodEnum) => {
    setMethod(selectedMethod)
    setStep('input')
  }

  const handleSubmitInput = (input: string) => {
    setContactInfo(input)
    setStep('confirm')
  }

  const handleGoBack = () => {
    if (step === 'confirm') {
      setStep('input')
    } else if (step === 'input') {
      setStep('method')
    }
  }

  const handleResendToken = async (contactInfo: string, method: TokenMethodEnum | null, messageTemplate: TokenValidationMessageTemplateType) => {
    const tokenService = new TokenService()
    return tokenService.resend(contactInfo, method, messageTemplate)
  }

  return (
    <div>
      <div className="token-validation__svg">
        <TokenVerificationSVG />
      </div>

      {step === 'method' && (
        <TokenValidationMethod
          onSelectMethod={onSelectMethod}
        />
      )}

      {step === 'input' && (
        <TokenInput
          input={method === TokenMethodEnum.sms ? cellphone : email}
          method={method!}
          messageTemplate={messageTemplate}
          readOnlyInput={readOnlyInput}
          onSubmit={handleSubmitInput}
          onGoBack={handleGoBack}
        />
      )}

      {step === 'confirm' && (
        <TokenValidationConfirm
          description={
            <div className='text-center'>
              <p>Digite o token que enviamos para <b>{contactInfo}</b></p>
            </div>
          }
          onSubmit={(token) => onSubmit(token, contactInfo, method)}
          onResendToken={() => handleResendToken(contactInfo, method, messageTemplate)}
          onGoBack={handleGoBack}
        />
      )}

      {children}
    </div>
  )
}

export default TokenValidator

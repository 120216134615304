import React from 'react'
import { states } from 'utils/globals'
import { Form, Select } from 'antd'
import { SelectValue } from 'antd/lib/select'

function FilterUF ({ onChange }: { onChange: (value: SelectValue) => void }) {
  return (
    <Form.Item
      name="ufFilter"
      label={<label>UF</label>}
    >
      <Select
        showSearch
        optionFilterProp="children"
        className="w-100"
        onChange={onChange}
        placeholder='Selecione a UF'
        allowClear
      >
        <Select.Option value=''>Todos</Select.Option>
        {states.map(item => (
          <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default FilterUF

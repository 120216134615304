import { Col, Row, Skeleton, Tag } from 'antd'
import { Gutter } from 'antd/lib/grid/row'
import ErrorDetails from 'components/ErrorDetails/ErrorDetails'
import dayjs, { Dayjs } from 'dayjs'
import { R$, valueOrHyphen, valuerOrZero } from 'ecp/app/Proposals/proposalFunctions'
import DetailsItem from 'ecp/components/DetailsItem/DetailsItem'
import { EcpCard } from 'ecp/components/EcpCard/EcpCard'
import ValueCard from 'ecp/components/ValueCard/ValueCard'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { colors } from 'styles/colors'
import CONVERTLIST from 'utils/convertList'
import { timeAsDayjs } from 'utils/time'
import translate from 'utils/translate'
import { useLeadsDetails } from '../../hooks/useLeads'

const rowGutter: [Gutter, Gutter] = [12, 36]

export const dateOrHyphen = (date: string | Dayjs | undefined) => dayjs(date).isValid() ? timeAsDayjs(date).format('DD/MM/YYYY') : '-'

export default function LeadDetails () {
  const { leadId } = useParams<{ leadId?: string }>()
  const { lead, error, loading, fetchLeads } = useLeadsDetails()

  useEffect(() => {
    const abortController = new AbortController()

    if (leadId) fetchLeads(leadId, abortController)

    return () => abortController.abort()
  }, [])

  if (loading) {
    return (
      <EcpCard>
        <Skeleton active />
        <Skeleton active />
      </EcpCard>
    )
  }

  if (error && !loading) {
    return (
      <EcpCard>
        <ErrorDetails
          status='error'
          title='Ops, parece que algo deu errado'
          error={error}
          subTitle={error.message}
        />

      </EcpCard>
    )
  }

  return (
    <EcpCard>
      <div className='mb-3'>
        <Row gutter={rowGutter}>
          <Col lg={4} sm={12} xs={24}>
            <ValueCard
              label='Renda'
              value={R$(lead?.income)}
              backGroundColor={'var(--light-gray)'}
              alignment='start'
            />
          </Col>

          <Col lg={5} sm={12} xs={24}>
            <ValueCard
              label='Valor da Propriedade'
              value={R$(lead?.propertyValue)}
              backGroundColor={'var(--light-gray)'}
              alignment='start'
            />
          </Col>

          <Col lg={4} sm={12} xs={24}>
            <ValueCard
              label='Valor do Crédito'
              value={R$(lead?.creditValue)}
              backGroundColor={'var(--light-gray)'}
              alignment='start'
            />
          </Col>
        </Row>
      </div>

      <div className='mb-3'>
        <h2 className='color-primary bold'>Dados pessoais</h2>
        <Row gutter={rowGutter}>
          <Col lg={12} sm={12} xs={24}>
            <DetailsItem
              label='Nome'
              value={valueOrHyphen(lead?.name)}
            />
          </Col>

          <Col lg={12} sm={12} xs={24}>
            <DetailsItem
              label='Email'
              value={valueOrHyphen(lead?.email)}
            />
          </Col>

          <Col lg={8} sm={12} xs={24}>
            <DetailsItem
              label='CPF'
              value={lead?.cpf ? CONVERTLIST.cpf(lead?.cpf) : '-'}
            />
          </Col>

          <Col lg={8} sm={12} xs={24}>
            <DetailsItem
              label='Data de nascimento'
              value={dateOrHyphen(lead?.birthdate)}
            />
          </Col>

          <Col lg={8} sm={12} xs={24}>
            <DetailsItem
              label='Tipo de Pessoa'
              value={lead?.personType ? lead?.personType : '-'}
            />
          </Col>

          <Col lg={8} sm={12} xs={24}>
            <DetailsItem
              label='Tipo de Emprego'
              value={lead?.employmentType ? translate.employmentType(lead?.employmentType) : '-' }
            />
          </Col>

          <Col lg={8} sm={12} xs={24}>
            <DetailsItem
              label='Cidade'
              value={`${valueOrHyphen(lead?.cityLabel)} - ${valueOrHyphen(lead?.ufLabel)}`}
            />
          </Col>

          <Col lg={8} sm={12} xs={24}>
            <DetailsItem
              label='CEP'
              value={valueOrHyphen(lead?.zipcode)}
            />
          </Col>
        </Row>
      </div>

      <div className='mb-3'>
        <h2 className='color-primary bold'>Informações do Lead</h2>
        <Row gutter={rowGutter}>
          <Col lg={8} sm={12} xs={24}>
            <DetailsItem
              label='Produto'
              value={lead?.product ? translate.productSlug(lead?.product) : '-'}
            />
          </Col>

          <Col lg={8} sm={12} xs={24}>
            <DetailsItem
              label='Passo atual'
              value={
                <span className="step-lead">
                  {lead?.currentStep}º passo
                </span>
              }
            />
          </Col>

          <Col lg={8} sm={12} xs={24}>
            <DetailsItem
              label='Prazo'
              value={`${valuerOrZero(lead?.deadline)} meses`}
            />
          </Col>

          <Col lg={8} sm={12} xs={24}>
            <DetailsItem
              label='Tipo de Amortização'
              value={lead?.amortizationType ? translate.amortizationType(lead.amortizationType) : '-'}
            />
          </Col>

          <Col lg={8} sm={12} xs={24}>
            <DetailsItem
              label='Data de criação'
              value={dateOrHyphen(lead?.createdAt)}
            />
          </Col>

          <Col lg={8} sm={12} xs={24}>
            <DetailsItem
              label='Objetivo do recurso'
              value={lead?.resourceGoal ? translate.goals(lead.resourceGoal) : '-'}
            />
          </Col>

          <Col lg={8} sm={12} xs={24}>
            <DetailsItem
              label='Status'
              value={lead?.status ? translate.leadStatus(lead?.status) : '-'}
            />
          </Col>

          <Col lg={8} sm={12} xs={24}>
            <DetailsItem
              label='Tem tarifa de avaliação'
              value={lead?.hasAvaliationFare ? 'Sim' : 'Não'}
            />
          </Col>

          <Col lg={8} sm={12} xs={24}>
            <DetailsItem
              label='Tem registro'
              value={lead?.hasRegister ? 'Sim' : 'Não'}
            />
          </Col>

          <Col lg={8} sm={12} xs={24}>
            <DetailsItem
              label='Cidade inválida'
              value={lead?.invalidCity ? 'Sim' : 'Não'}
            />
          </Col>

          <Col lg={8} sm={12} xs={24}>
            <DetailsItem
              label='É integração'
              value={lead?.isIntegration ? 'Sim' : 'Não'}
            />
          </Col>

          <Col lg={8} sm={12} xs={24}>
            <DetailsItem
              label='Resolvido'
              value={lead?.settled ? 'Sim' : 'Não'}
            />
          </Col>

          <Col lg={8} sm={12} xs={24}>
            <DetailsItem
              label='Tipo de imóvel'
              value={lead?.sub ? translate.subProducts(lead.sub) : '-'}
            />
          </Col>

          <Col lg={8} sm={12} xs={24}>
            <DetailsItem
              label='Última Atualização'
              value={dateOrHyphen(lead?.updatedAt)}
            />
          </Col>

          <Col lg={8} sm={12} xs={24}>
            <DetailsItem
              label='Pré-aprovação'
              value={lead?.preApproval ? <Tag color={colors[lead?.preApproval]}>{translate.preApproval(lead?.preApproval)}</Tag> : <Tag>N/A</Tag>}
            />
          </Col>
        </Row>
      </div>

      <div className='mb-3'>
        <h2 className='color-primary bold'>Informações do Telemarketing</h2>
        <Row gutter={rowGutter}>
          <Col lg={16} sm={12} xs={24}>
            <DetailsItem
              label='Nome'
              value={valueOrHyphen(lead?.telemarketing?.name)}
            />
          </Col>

          <Col lg={8} sm={12} xs={24}>
            <DetailsItem
              label='Cpf'
              value={lead?.telemarketing?.cpf ? CONVERTLIST.cpf(lead?.telemarketing?.cpf) : '-'}
            />
          </Col>

          <Col lg={12} sm={12} xs={24}>
            <DetailsItem
              label='Telefone Celular'
              value={lead?.telemarketing?.cellphone ? CONVERTLIST.cellphone(lead?.telemarketing?.cellphone) : '-'}
            />
          </Col>

          <Col lg={12} sm={12} xs={24}>
            <DetailsItem
              label='Email'
              value={valueOrHyphen(lead?.telemarketing?.email)}
            />
          </Col>
        </Row>
      </div>

    </EcpCard>

  )
}

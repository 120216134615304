import { leadTypes } from 'egi/types/IDrawer'
import { MutableRefObject } from 'react'
import { IDrawer } from 'egi/types'

export const drawerButtonIsLoading = (isLoading: boolean) => ({
  type: 'DRAWER_BUTTON_IS_LOADING',
  payload: {
    isLoading
  }
})

export const drawerUpdateStatus = ({ newStatus, isSubmit }: { newStatus: leadTypes, isSubmit: boolean }) => ({
  type: 'DRAWER_LEAD_STATUS_UPDATE',
  payload: {
    newStatus,
    isSubmit
  }
})

export const drawerFillForms = (data: IDrawer) => ({
  type: 'DRAWER_FILL_FORMS',
  payload: {
    ...data
  }
})

export const drawerFormRefSubmit = (ref: MutableRefObject<any>) => ({
  type: 'DRAWER_MANAGE_FORM_REF',
  payload: {
    ref
  }
})

export const drawerReset = () => ({
  type: 'RESET_DRAWER'
})

export const drawerSetVisible = ({ visible }: { visible: boolean }) => ({
  type: 'DRAWER_MANAGE_VISIBLE',
  payload: {
    visible
  }
})

import { ColumnsType } from 'antd/lib/table'
import format from 'utils/format'
import Sorter from 'utils/sorter'
import { TypeOfTag } from 'typescript'
import CONVERTLIST from 'utils/convertList'
import dayjs from 'dayjs'
import { timeAsDayjs } from 'utils/time'
import { formatYear } from 'utils/date'
import translate from 'utils/translate'
import { IProposal } from 'egi/app/ProposalEgi/proposalEgiInterfaces'

const MONETARYLIST: Array<string> = ['value', 'minValue', 'maxValue']
const PERCENTAGELIST: Array<string> = ['amTax', 'aaTax', 'amortization', 'iofTotal', 'iofPmt']
const DATELIST: Array<string> = ['birthdate', 'createdAt', 'updatedAt']
const SUBLIST: Array<string> = ['sub']

const FINALCONVERTLIST: { [key: string]: any } = {
  ...CONVERTLIST,
  birthdate: (value: string) => timeAsDayjs(value, { applyTimezone: false }),
  createdAt: (value: string) => timeAsDayjs(value, { applyTimezone: false }),
  updatedAt: (value: string) => timeAsDayjs(value, { applyTimezone: false })
}

function convertPropertsObject (item: Object) {
  const COPY_ITEM: any = Object.assign({}, item)
  const KEYS_COPY_ITEM = Object.keys(COPY_ITEM)

  const possibles = Object.keys(FINALCONVERTLIST)

  for (let i = 0; i < KEYS_COPY_ITEM.length; i++) {
    for (let j = 0; j < possibles.length; j++) {
      if (possibles[j] === KEYS_COPY_ITEM[i]) {
        COPY_ITEM[KEYS_COPY_ITEM[i]] = FINALCONVERTLIST[possibles[j]](COPY_ITEM[KEYS_COPY_ITEM[i]])
      }
    }
  }

  return COPY_ITEM
}

type ExtendedTypeof = TypeOfTag | 'date'

function columnsInsertSorter (data: ColumnsType<Object>, baseValues: {[key: string]:string}): Array<Object> {
  const COPY_DATA: ColumnsType<Object> = data

  for (const item in baseValues) {
    let type: ExtendedTypeof = typeof baseValues[item]

    if (type === 'object') {
      if (dayjs(baseValues[item]).isValid()) type = 'date'
    }

    const findDataItem = COPY_DATA.find(element => (element as any).dataIndex === item)

    let findDataIndex
    if (findDataItem !== undefined) findDataIndex = COPY_DATA.findIndex(element => (element as any).dataIndex === item)

    if ((findDataIndex !== undefined) && findDataItem) {
      switch (type) {
        case 'number':
          COPY_DATA[findDataIndex].sorter = { compare: (first: any, second: any) => Sorter.DEFAULT(first[item], second[item]) }
          if (MONETARYLIST.includes(item)) COPY_DATA[findDataIndex].render = value => format.formatBRL(value)
          if (PERCENTAGELIST.includes(item)) COPY_DATA[findDataIndex].render = value => String(value + ' %')
          break
        case 'string':
          if (SUBLIST.includes(item)) COPY_DATA[findDataIndex].render = value => translate.subProducts(value)
          COPY_DATA[findDataIndex].sorter = { compare: (first: any, second: any) => Sorter.STRING(first[item], second[item]) }
          break
        case 'date':
          if (DATELIST.includes(item)) COPY_DATA[findDataIndex].render = value => formatYear(value)
          COPY_DATA[findDataIndex].sorter = { compare: (first: any, second: any) => Sorter.DATE(first[item], second[item]) }
          break
        default:
          break
      }
    }
  }

  return COPY_DATA
}

export function enhanceTable (data: Array<Object> | Array<IProposal>, columns: ColumnsType<Object>) {
  if (!data) return [[], []]

  const GENERATE_NEW_DATA = []
  const GENERATE_OBJECT = {}
  const GREATER_LENGTH = 0

  for (let i = 0; i < data.length; i++) {
    const tempItem = convertPropertsObject(data[i])
    const lenghtTempItem = Object.keys(tempItem).length

    if (lenghtTempItem >= GREATER_LENGTH) Object.assign(GENERATE_OBJECT, tempItem)
    GENERATE_NEW_DATA.push(tempItem)
  }

  const finalColumns = columnsInsertSorter(columns, GENERATE_OBJECT)

  return [GENERATE_NEW_DATA, finalColumns]
}

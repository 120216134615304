import React from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'

function UploadButton () {
  return (
    <Button type="primary" className="mb-2">
      <PlusOutlined />
      Adicionar Arquivo
    </Button>
  )
}

export default UploadButton

import { Image } from 'antd'
import React, { ReactElement } from 'react'
import imagesUrls from 'utils/imagesUrls'

interface IProps {
  children: ReactElement
}

function DisplayTerms ({ children }: IProps) {
  return (
    <div className='display-terms'>
      <div className='display-terms__image'>
        <Image
          src={imagesUrls.rodobensLogoLight}
          preview={false}
          alt="rodobens"
        />
      </div>
      <div>
        {children}
      </div>
    </div>
  )
}

export default DisplayTerms

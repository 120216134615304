import React from 'react'
import { Form, Switch, Typography } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'

interface IFilterSwitch {
  onChange: (value: boolean | undefined) => void
  name: string
  placeholder: string
  defaultChecked?: boolean
}

function FilterSwitch ({ onChange, placeholder, name, defaultChecked }: IFilterSwitch) {
  return (
    <div className="flex flex-align-center switch-responsive-layout">
      <Typography.Title level={5} className="mr-1 mb-0">{placeholder}</Typography.Title>
      <Form.Item
        name={name}
        valuePropName="checked"
      >
        <Switch
          defaultChecked={defaultChecked}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={onChange}
        />
      </Form.Item>
    </div>
  )
}

export default FilterSwitch


import { CheckOutlined, DeleteFilled, UserOutlined } from '@ant-design/icons'
import { Button, Drawer, Empty, Form, Input, message, Pagination, Select, Skeleton, Spin } from 'antd'
import Loading from 'components/Loading/Loading'

import ResourcesRepository from 'ecp/repositories/ResourcesRepository'
import { ISelect } from 'egi/types'
import React, { ReactNode, useEffect, useState } from 'react'
import swal from 'utils/swal'

interface ISuspectsCard {
  identifier: string,
  value: string, id:
  string,
  onDelete: ((id: string) => Promise<void>) | undefined
  why?: string
}

interface ISuspectsUsers { identifier: string, value: string, _id: string, why?: string }
interface ISuspectsList {
  loading: boolean,
  suspectUsers: ISuspectsUsers[],
  onDelete: ((id: string) => Promise<void>) | undefined
  totalDocs: number,
  onPageChange: (page: number) => void
}

  type _identifierType = 'Ip'| 'Domínio' | 'Id de usuário'

const COLOR_BY_IDENTIFIER: { [key in _identifierType]: string } = {
  Ip: '#FFA800',
  Domínio: '#399CD3',
  'Id de usuário': '#972FE8'
}

type _identifierTypeSend = keyof typeof PLACE_HOLDERS;

const PLACE_HOLDERS = {
  domain: 'gmail.com',
  userId: 'Id de usuário',
  ip: '192.0.2.0/24'
}

export default function Suspects ({ children, className }: { children: ReactNode, className?: string }) {
  return (
    <div className={`menu ${className}`}>
      {children}
    </div>
  )
}

Suspects.Card = ({ identifier, value, id, onDelete, why }: ISuspectsCard) => {
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false)

  const handleDelete = async (id: string) => {
    swal.confirmNegate({
      title: 'Atenção',
      text: 'Deseja realmente remover esse item da lista?',
      icon: 'warning',
      confirm: async () => {
        setDeleteLoading(true)
        try {
          await onDelete?.(id)
        } finally {
          setDeleteLoading(false)
        }
      }
    })
  }

  return (
    <div className="suspects-list__card">
      <div className="suspects-list__content">
        <div>
          <CheckOutlined className="suspects-list__check" />
        </div>
        <div className="ml-3">
          <UserOutlined className="suspects-list__user" style={{ color: COLOR_BY_IDENTIFIER[identifier as _identifierType] }}/>
          <h3 className="suspects-list__identifier">{identifier}</h3>
          <p>{value}</p>
          <p>{why}</p>
        </div>
      </div>
      <div>
        {
          deleteLoading
            ? <Loading/>
            : <DeleteFilled
              className="suspects-list__delete"
              onClick={() => handleDelete(id)}
            />
        }
      </div>
    </div>
  )
}

Suspects.LoadingCard = () => {
  return (
    <div className="suspects-list__card">
      <Skeleton avatar/>
    </div>
  )
}

Suspects.ListContent = ({ loading, suspectUsers, onDelete }: Partial<ISuspectsList>) => {
  if (loading) {
    return <>
      { new Array(3).fill(' ').map((_, idx) =>
        <Suspects.LoadingCard key={idx} />)
      }
    </>
  }

  return <>
    {suspectUsers && suspectUsers.map(user => {
      return <Suspects.Card
        identifier={user.identifier}
        value={user.value}
        key={user.value}
        onDelete={onDelete}
        id={user._id}
        why={user?.why}
      />
    })}
  </>
}

Suspects.List = ({ suspectUsers, onDelete, loading, totalDocs, onPageChange }: ISuspectsList) => {
  if (!suspectUsers.length && !loading) {
    return <div className="suspects-list__card  suspects-list__empty">
      <div className="text-center">
        <Empty description='Não foi possível encontrar nenhum usuário nessa lista'/>
      </div>
    </div>
  }

  return (
    <>
      <div className="suspects-list__list">
        <Suspects.ListContent
          suspectUsers={suspectUsers}
          onDelete={onDelete}
          loading={loading}/>
      </div>
      <div className="text-right mt-2">
        <Pagination total={totalDocs} showSizeChanger={false} onChange={onPageChange} showTotal={total => <label className="server-table__color-black">
              Total: <strong>{loading ? <Spin size="small" className="server-table__limits-pagination" /> : total}</strong>
        </label>}/>
      </div>
    </>
  )
}

Suspects.Header = ({ title, children, description, svg }: { title: string, children: ReactNode, description: ReactNode, svg: ReactNode }) => {
  return <header className="suspects-list__header">
    {svg}
    <div>
      <h2 className="color-primary"><b>{title}</b></h2>
      {description}
    </div>
    {children}
  </header>
}

Suspects.AddToList = ({ visible, title, onVisibleChange, onSubmit, showWhyField, loadingSubmit, description }: {
  visible: boolean, title: string,
  onVisibleChange: () => void, showWhyField: boolean, loadingSubmit: boolean,
  description:ReactNode
  onSubmit: (values: {
    identifier: string;
    value: string;
  }) => Promise<void>
}) => {
  const [resources, setResources] = useState<ISelect[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  const getResources = async () => {
    setLoading(true)
    try {
      const response = await ResourcesRepository.getBannedIdentifiers()
      setResources(response.data.data?.resource ?? [])
    } catch (err) {
      setErrorMessage(err.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getResources()
  }, [])

  const onFinish = async (values: {
    identifier: string;
    value: string;
}) => {
    try {
      await onSubmit(values)
    } catch (err) {
      message.error(err)
    }
  }

  const [identifier, setIdentifier] = useState<_identifierTypeSend>('ip')

  return <>
    <Drawer visible={visible} title={title} width={600} onClose={onVisibleChange} destroyOnClose>
      <h2 className="color-primary">
        <b>{title}
        </b>
      </h2>
      {description}
      <Form layout="vertical" className="mt-4" onFinish={onFinish}>
        <Form.Item
          name="identifier"
          label="Identificador"
        >
          <Select
            loading={loading}
            className="w-100"
            placeholder="Escolha o identificador"
            options={resources}
            onChange={(identifier: _identifierTypeSend) => setIdentifier(identifier)}
            notFoundContent={<Empty description={errorMessage ?? 'Não foi possível encontrar itens'}/>}
          />
        </Form.Item>

        <Form.Item
          name="value"
          label="Valor"
        >
          <Input
            className="w-100"
            placeholder={PLACE_HOLDERS?.[identifier]}
          />
        </Form.Item>

        {
          showWhyField &&
          <Form.Item
            name="why"
            label="Motivo"
          >
            <Input
              className="w-100"
              placeholder="Usuário apresentou tentativa de fraude"
            />
          </Form.Item>
        }
        <div className="text-right pt-2">
          <Button type="primary" htmlType="submit" loading={loadingSubmit}>Adicionar</Button>
        </div>

      </Form>
    </Drawer>
    <Button type="primary" onClick={onVisibleChange} className='suspects-list__add-user'>
      Adicionar usuário
    </Button>
  </>
}

import { QuestionCircleOutlined } from '@ant-design/icons'
import { Input, Form, Tooltip } from 'antd'
import React from 'react'

interface Props {
  placeholder: string
  helpText?: string
  onSearch: (search: string) => void
}

function TooltipCustom ({ children, message } : { children: string, message: string }) {
  return (
    <label className='tooltip-custom'>
      {children}
      <Tooltip title={message}>
        <QuestionCircleOutlined />
      </Tooltip>
    </label>
  )
}

function InputSearch ({ placeholder, onSearch, label } : Omit<Props, 'helpText'> & { label: string | React.ReactNode }) {
  return (
    <Form.Item
      name='search'
      label={label}
    >
      <Input
        placeholder={placeholder}
        className='search'
        onChange={change => onSearch(change.target.value)}
      />
    </Form.Item>
  )
}

function SearchWithOutTooltip ({ onSearch, placeholder }: Omit<Props, 'helpText'>) {
  return (
    <div>
      <InputSearch
        placeholder={placeholder}
        onSearch={onSearch}
        label='Pesquisar'
      />
    </div>
  )
}

function SearchWithTooltip ({ helpText, onSearch, placeholder } : Props & { helpText: string }) {
  return (
    <div className='search-with-tool-tip'>
      <InputSearch
        onSearch={onSearch}
        placeholder={placeholder}
        label={<TooltipCustom message={helpText}>Pesquisar</TooltipCustom>}
      />
    </div>
  )
}

function TableSearch ({ placeholder, helpText, onSearch } : Props) {
  if (!helpText) return <SearchWithOutTooltip onSearch={onSearch} placeholder={placeholder} />
  return <SearchWithTooltip helpText={helpText} onSearch={onSearch} placeholder={placeholder} />
}

export { TableSearch }

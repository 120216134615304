import React, {
  useState,
  useRef,
  useLayoutEffect,
  Fragment,
  MutableRefObject,
  useEffect
} from 'react'
import { Content } from 'antd/lib/layout/layout'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { DepartmentCard, SimulationCard, ShareButtons, Skeletons } from 'components'
import DrawerComponent from 'components/Drawer/Drawer'
import {
  getProposalState,
  proposalLoadingStep,
  proposalReset,
  setCallbackProposal
} from 'store/modules/proposal/actions'
import { Button, Empty, Form, Spin } from 'antd'
import { ReactSVG } from 'react-svg'
import swal from 'utils/swal'
import { useAuth, useClient, useProposal, useResources, useStep } from 'hooks'
import { stepReset } from 'store/modules/step/actions'
import { clientReset } from 'store/modules/client/actions'

import unauthed from 'egi/consumers/unauthed'
import { IStep } from 'egi/types'
import {
  resourcesGetBanks,
  resourcesGetCountries,
  resourcesGetStates,
  resourcesGetLegalNature,
  resourcesGetEconomicActivity,
  resourcesGetStatesIBGE,
  resourcesGetGoals,
  resourcesGetProducts
} from 'store/modules/resources/actions'
import { proposals } from 'egi/consumers'
import { ShareAltOutlined, WarningFilled } from '@ant-design/icons'
import { modalFillValues } from 'store/modules/modal/actions'
import { IReduxModal } from 'store/modules/modal/types'
import Captions from 'components/Captions/Captions'
import useAuthLayout from 'hooks/useAuthLayout'
import CreditApprove from 'egi/app/CreditApprove/views/CreditApprove'
import StepsRenders from '../../components/StepRenders/StepRenders'
import CreateProposalEgi from '../CreateProposalEgi/CreateProposalEgi'
import { _stepModes } from 'egi/types/IStep'
import ProposalEgiModel from 'egi/models/ProposalEgiModel'
import SolicitationModal from '../../components/SolicitationModal/SolicitationModal'
import { openTypeDrawer } from 'components/SimulationCard/types'
import { SocketSingleton } from 'utils/socket'

function DrawerTitle () {
  const step = useStep()
  const dispatch = useDispatch()

  if (step.creditReservation) {
    const fillValues: IReduxModal = {
      visible: true,
      hasFooter: true,
      title: 'Ressalvas',
      type: 'creditReservation',
      width: '75',
      creditReservation: step.creditReservation
    }

    return (
      <div>
        <label className="mr-2">{step.name}</label>
        <Button
          className="share-button pulsate-fwd"
          icon={<WarningFilled className="mr-1" />}
          type="primary"
          onClick={() => dispatch(modalFillValues(fillValues))}
        >
          Ressalva
        </Button>
      </div>
    )
  }

  return <label>{step.name}</label>
}

const ProposalDetailsEgi = () => {
  const dispatch = useDispatch()
  const params = useParams<{ id?: string }>()
  const [formRef] = Form.useForm()
  const dateFormRef: MutableRefObject<any> = useRef(null)

  const user = useAuth()
  const client = useClient()
  const proposal = useProposal()
  const step = useStep()
  const resources = useResources()
  const history = useHistory()
  const { handleReset } = useAuthLayout()

  const [visible, setVisible] = useState<boolean>(false)
  const [validityVisible, setValidityVisible] = useState<boolean>(false)
  const [status, setStatus] = useState<string>('')
  const [area, setArea] = useState<string>('')
  const [type, setType] = useState<string>('')
  const [files, setFiles] = useState<File[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [firstTime, setFirstTime] = useState(true)
  const [simulationDrawerOpen, setSimulationDrawerOpen] = useState<boolean>(false)
  const [openType, setOpenType] = useState<openTypeDrawer>()

  const IS_CLIENT = user.level === 'client'

  const showSave = step.showDrawerSave

  const showValidateProposal = (validity: boolean) => {
    setValidityVisible(validity)
  }

  const openSimulationDrawer = ({ type }: { type: openTypeDrawer }) => {
    setSimulationDrawerOpen(true)
    setOpenType(type)
  }

  const closeSimulationDrawer = () => {
    setSimulationDrawerOpen(false)
  }

  useEffect(() => {
    showValidateProposal(proposal?.proposalExpiration?.isDateExpired ?? false)
  }, [proposal?.proposalExpiration?.isDateExpired])

  useLayoutEffect(() => {
    const element = document.querySelector('#auth-page-header')
    const container = document.querySelector('.ant-layout-content')

    if (IS_CLIENT) return

    container?.addEventListener('scroll', () => {
      const bodyScrollTop = container.scrollTop

      if (element) {
        if (bodyScrollTop > 200) {
          element.classList.add('fixed-header')
          element.classList.add('bg-white-status-color')
        } else {
          element.classList.remove('fixed-header')
          element.classList.remove('bg-white-status-color')
        }
      }
    })
  }, [])

  useEffect(() => {
    const socket = new SocketSingleton()
    if (socket.io) {
      socket.io.on('ProposalChanged', () => {
        if (proposal.id) dispatch(getProposalState(proposal.id))
      })
    }
  }, [proposal.id])

  function enterProposalId (id: string) {
    const socket = new SocketSingleton()
    if (id && socket.io) socket.io.emit('join-proposal', id)
  }

  function exitProposalId () {
    const socket = new SocketSingleton()
    if (params.id && socket.io) socket.io.emit('leave-proposal', params.id)
  }

  function callbackReduxProposal (id: string) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    dispatch(setCallbackProposal(callbackProposal))
    enterProposalId(id)
    setLoading(false)
  }

  useEffect(() => {
    if (proposal.id && proposal.needVinculation) {
      swal.confirmNegate({
        icon: 'question',
        title: 'Vincular à proposta',
        text: 'Você será vinculado como consultor desta proposta. Deseja continuar?',
        allowOutsideClick: false,
        confirmButtonText: 'Sim, desejo ser vinculado(a)',
        confirm: async () => {
          try {
            const response = await proposals.linkNewBackoffice({ proposalId: String(proposal.id) })
            swal.basic({
              icon: 'success',
              title: 'Sucesso!',
              text: response.message
            })
          } catch (err) {
            swal.basic({
              icon: 'warning',
              title: 'Atenção!',
              text: err.message
            })
          }
        }
      })
    }
  }, [proposal.id])

  async function fetchProposals () {
    if (firstTime) {
      setLoading(true)
      setFirstTime(false)
    }

    if (IS_CLIENT && user.proposalId) {
      dispatch(getProposalState(user?.proposalId, callbackReduxProposal))
    } else if (IS_CLIENT && proposal.id) {
      dispatch(getProposalState(proposal.id, callbackReduxProposal))
    } else if (params.id && !IS_CLIENT) {
      dispatch(getProposalState(params.id, callbackReduxProposal))
    } else {
      setLoading(false)
    }
  }

  useLayoutEffect(() => {
    fetchProposals()

    return () => {
      exitProposalId()
      dispatch(proposalReset())
      dispatch(clientReset())
      dispatch(stepReset())
    }
  }, [user.proposalId])

  useLayoutEffect(() => {
    if (resources.products.length < 1) dispatch(resourcesGetProducts())
  }, [resources.products])

  useLayoutEffect(() => {
    if (resources.ibgeStates.length < 1) dispatch(resourcesGetStatesIBGE())
  }, [resources.ibgeStates])

  useLayoutEffect(() => {
    if (resources.banks.length < 1) dispatch(resourcesGetBanks())
  }, [resources.banks])

  useLayoutEffect(() => {
    if (resources.states.length < 1) dispatch(resourcesGetStates())
  }, [resources.states])

  useLayoutEffect(() => {
    if (resources.countries.length < 1) dispatch(resourcesGetCountries())
  }, [resources.countries])

  useLayoutEffect(() => {
    if (resources.legalNature.length < 1) dispatch(resourcesGetLegalNature())
  }, [resources.legalNature])

  useLayoutEffect(() => {
    if (resources.economicActivity.length < 1) dispatch(resourcesGetEconomicActivity())
  }, [resources.economicActivity])

  useLayoutEffect(() => {
    if (resources.goals.length < 1) dispatch(resourcesGetGoals())
  }, [resources.goals])

  function callbackProposal () {
    setVisible(false)
    dispatch(stepReset())
    fetchProposals()
  }

  const handleFilesUpload = async (step: IStep) => {
    setStatus('sending')

    if (!step.id) throw new Error('Identificar do passo não encontrado')
    if (!proposal.id) throw new Error('Identificar da proposta não encontrado')

    const data: {
      stepId: string
      proposalId: string
      areaId: string
      form: any
    } = {
      stepId: step.id,
      proposalId: proposal.id,
      areaId: step.areaId,
      form: {}
    }

    if (step.modeOptions && step.modeOptions.selected) data.form.activeMode = step.modeOptions.selected

    try {
      const response = await proposals.send(data)
      if (step.id) dispatch(proposalLoadingStep({ stepId: step.id, loading: true }))
      swal.basic({ title: 'Sucesso!', text: response.message, icon: 'success' })
      setVisible(false)
      callbackProposal()
    } catch (err) {
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      setStatus('')
      return
    }

    setVisible(false)
    setStatus('')
  }

  const onCancelValidity = () => {
    setValidityVisible(false)
  }

  async function generateLink () {
    try {
      const link = await unauthed.createProposalLink({ proposalId: String(proposal.id) })
      if (!link) throw new Error('Failed to create Step Link')
      return link
    } catch (err) {
      console.log(err)
    }
  }

  const disableDrawerButton = (step: IStep) => {
    if (!step) return

    if (step.mode === 'multiple' && step.modeOptions?.selected) {
      const buttonsOptions = step.disabledDrawerButton as { [key in _stepModes]: boolean }
      return buttonsOptions[step.modeOptions.selected as _stepModes]
    }

    return step.disabledDrawerButton as boolean
  }

  const handleOnclickDepartment = (areaId: string) => {
    setVisible(true)
    setArea(areaId)
  }

  const submitDrawer = (mode: string) => {
    setType('send')
    switch (mode) {
      case 'date':
        if (dateFormRef !== null) {
          dateFormRef.current?.submit()
        }
        break
      case 'append':
      case 'document':
      case 'crivo':
        if (files && step) {
          handleFilesUpload(step)
        }
        break
      case 'signature':
      case 'term':
        if (formRef) {
          formRef.submit()
          setStatus('')
        }
        break
      case 'form':
      case 'representative':
        if (formRef) formRef.submit()
        break
      case 'multiple':
        if (step.activeMode) submitDrawer(step.activeMode)
        if (step.modeOptions && step.modeOptions.selected) submitDrawer(step.modeOptions.selected)
        break
    }
  }

  if (loading) {
    return (
      <div className="mb-4">
        <Skeletons.SkeletonProposal />
      </div>
    )
  }

  return (
    <Content className="mb-4">
      {(IS_CLIENT && !proposal?.id) && <CreateProposalEgi />}

      {(client.clientApproval && ProposalEgiModel.canSeeCreditApprove(user.level, client.clientApproval.status)) && (
        <CreditApprove />
      )}

      {(proposal?.departments?.length < 1 && !IS_CLIENT) && (
        <Empty
          className="proposal-container-card p-3"
          description="A proposta não está disponível para o seu nível de usuário."
        />
      )}

      { proposal.proposalExpiration?.canSeeSolicitationModal &&
        <SolicitationModal visible={validityVisible} onCancel={onCancelValidity} proposal={proposal} />
      }

      {(proposal?.departments?.length > 0) && (
        <Fragment>
          <DrawerComponent
            title={<DrawerTitle />}
            closable={true}
            visible={visible}
            canUpdate
            showSubmit={true}
            showSave={showSave}
            disabled={disableDrawerButton(step)}
            status={status}
            setVisible={setVisible}
            onClose={() => {
              setVisible(false)
              dispatch(stepReset())
            }}
            onSave={async () => {
              setType('save')
              if (formRef) formRef.submit()
            }}
            onSubmit={() => submitDrawer(step.mode)}
            footer={step.slug === 'formulario-do-contrato' ? null : undefined}
          >
            <Fragment>
              {step.openDrawerLoading ? (
                <Spin className="flex flex-justify-center flex-align-center" />
              ) : (
                <div className="step-renders-container">
                  <StepsRenders
                    openSimulationDrawer={openSimulationDrawer}
                    area={area}
                    callbackProposal={callbackProposal}
                    dateFormRef={dateFormRef}
                    fetchProposals={fetchProposals}
                    formRef={formRef}
                    setFiles={setFiles}
                    setStatus={setStatus}
                    setVisible={setVisible}
                    type={type}
                  />
                </div>
              )}
            </Fragment>
          </DrawerComponent>

          <div className="proposal-container-card">
            <div className='proposal-container-card__header'>
              {ProposalEgiModel.canSeeShareButtons(user.level) && (
                <div>
                  <ShareButtons
                    icon={<ShareAltOutlined />}
                    generateLink={generateLink}
                    phone={client.cellphone}
                    shareButtonText="Link documentos"
                  />
                </div>
              )}

              {user.followUpProposal && (
                <>
                  <div className="mb-md-3 mt-md-3">
                    <Button
                      className='ant-btn ant-btn-primary'
                      type='primary'
                      onClick={() => swal.confirmNegate({
                        icon: 'warning',
                        title: 'Atenção',
                        confirmButtonText: 'Confirmar',
                        text: 'Deseja retornar ao login? Caso confirme, a sessão atual da proposta será perdida.',
                        confirm: () => {
                          handleReset()
                          history.push('/')
                        }
                      })}
                    >
                      Fazer login no sistema
                    </Button>
                  </div>

                  <div className="flex flex-align-center flex-justify-start mb-2 flex-direction-row">
                    <p className="ant-typography department-card-title mb-0 pb-0">Número da proposta: {proposal.number} </p>
                  </div>
                </>
              )}

              <Captions product='egi' />
            </div>

            {proposal?.simulation && (
              <SimulationCard
                closeSimulationDrawer={closeSimulationDrawer}
                openSimulationDrawer={openSimulationDrawer}
                openType={openType}
                simulationDrawerOpen={simulationDrawerOpen}
                showValidateProposal={showValidateProposal}
                canRemakeSimulation={ProposalEgiModel.canChangeSimulation(user.level, user.areaName)}
                canChangeCommission={ProposalEgiModel.canChangeComission(user.level, user.areaName)}
                canRequestPricing={ProposalEgiModel.canRequestPricing(user.level, user.areaName)}
                exceptionStorage={proposal.exceptionDocuments}
              />
            )}

            {proposal?.departments.map((department: any) => {
              let proposalConfig = proposal.departments?.find((proposal) => proposal._id === department._id) || ({} as any)
              if (!proposalConfig || !proposalConfig.actions) {
                proposalConfig = department || ({} as any)
              }

              const actions = proposalConfig.actions || []

              return (
                <DepartmentCard
                  department={department}
                  key={department._id}
                  onClick={() => handleOnclickDepartment(department.areaId)}
                  actions={actions}
                  picture={
                    <ReactSVG
                      src={department.image}
                      beforeInjection={(svg) => {
                        svg.classList.add('department-image-documents')
                      }}
                    />
                  }
                  status={client?.clientApproval?.status}
                  departmentStatus={department.status}
                />
              )
            })}
          </div>
        </Fragment>
      )}
    </Content>
  )
}

export default ProposalDetailsEgi

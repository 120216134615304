import { all, put, select, takeLatest } from 'redux-saga/effects'
import { IReduxRoute } from './types'

type updatesRoutesProps = {
  type: 'SET_REQUEST_ROUTE_CONFIG',
  payload: { route: IReduxRoute }
}

function * updateRoutes (action: updatesRoutesProps): any {
  const selector = yield select()
  const system = selector.system

  const { historyRoutes } = system
  const lastRoute = action.payload.route
  const temphistory: IReduxRoute[] = historyRoutes

  try {
    if (temphistory.length < 3) {
      temphistory.push(lastRoute)
    } else {
      temphistory.shift()
      temphistory.push(lastRoute)
    }

    yield put({
      type: 'SET_ROUTE_CONFIG',
      payload: {
        lastRoute,
        historyRoutes: temphistory
      }
    })

    let lastNotBack = false

    const finalElements = temphistory.slice(Math.max(temphistory.length - 2, 0))
    finalElements.forEach((item, index) => {
      const last = finalElements[index - 1]
      if (last && item.meta.canGoBack === undefined && item.isTable === undefined && last.isTable === true) lastNotBack = true
    })

    if (lastNotBack) yield put({ type: 'TABLE_RESET_FILTER_VALUES', payload: { fixedDate: true } })
  } catch (err) {
    console.log(err)
  }
}

export default all([
  takeLatest('SET_REQUEST_ROUTE_CONFIG', updateRoutes)
])

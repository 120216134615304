import { PhoneOutlined } from '@ant-design/icons'
import { Button, Col, Form, message } from 'antd'
import { IError } from 'ecp/app/Proposals/proposalInterfaces'
import { Input } from 'components'
import React, { useState } from 'react'

import mask from 'utils/masks'
import EcpUsersRepository from 'ecp/repositories/EcpUsers'
import { IChangePhone } from '../../profileInterfaces'
import ChangePhoneToken from './ChangePhoneToken'

interface IProps {
  currentPhone: string
  step: 'changePhone' | 'verifyPhone'
  setStep: (step: 'changePhone' | 'verifyPhone') => void
  setVisiblePhone: (visible: boolean) => void
  setNewPhone: (phone: string) => void
}

export default function ChangePhoneModal ({ currentPhone, step, setStep, setVisiblePhone, setNewPhone }: IProps) {
  const [loading, setLoading] = useState<boolean>(false)
  const [errors] = useState<IError<IChangePhone>>({})
  const [phone, setPhone] = useState<string>()

  async function handleSubmit (values: IChangePhone) {
    setLoading(true)
    try {
      await EcpUsersRepository.updateEmailOrPhone(values)
      setPhone(values.cellphone)
      setStep('verifyPhone')
    } catch (err) {
      message.error(err.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {
        step === 'changePhone'
          ? <div className="change-phone"><Form onFinish={handleSubmit} layout="vertical">
            <div className="change-phone__header">
              <PhoneOutlined className="change-phone__icon" />
              <h2 className="change-phone__title">Alterar Telefone</h2>
              <p className="change-phone__subtitle">Deseja fazer a alteração do telefone&nbsp;
                <span className="change-phone__current-phone">{mask(currentPhone, 'cell')}</span>?</p>
            </div>
            <Col lg={24} sm={24} xs={24}>
              <Form.Item className="py-2"
                name='cellphone'
                label='Novo telefone'
                help={errors?.cellphone}
                validateStatus={errors?.cellphone && ('error')}
                rules={[{
                  required: true,
                  message: 'Digite seu telefone'
                }]}
              >
                <Input placeholder='Digite seu novo telefone' mask="cell" />
              </Form.Item>
            </Col>
            <Button
              className="w-100"
              type="primary"
              htmlType="submit"
              loading={loading}>
              Alterar
            </Button>
          </Form>
          </div>
          : <ChangePhoneToken
            setNewPhone={setNewPhone}
            phone={phone ?? '-'}
            setVisiblePhone={setVisiblePhone}
          />
      }
    </>
  )
}

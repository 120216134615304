import { Button, Checkbox, Form, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import React, { MutableRefObject, useLayoutEffect, useRef, useState } from 'react'
import { proposals } from 'egi/consumers'
import format from 'utils/format'
import swal from 'utils/swal'
import { useAuth, useProposal, useStep } from 'hooks'
import { avaliationTooltip } from '../../proposalEgiConstants'
import { IAvaliationsProps } from '../../proposalEgiInterfaces'
import DetailsItem from 'ecp/components/DetailsItem/DetailsItem'
import mask from 'utils/masks'
import { valueOrNa } from 'utils/utils'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'
import { swalError } from 'components/SwalError/SwalError'
import { InputMoney } from 'components/IntlInput/IntlInput'

type InputWrapperProps = {
  children: React.ReactNode,
  isReadOnly: boolean,
  label: string,
  value: string
}

function InputWrapper ({ children, isReadOnly, label, value }: InputWrapperProps) {
  if (isReadOnly) {
    return <DetailsItem label={label} value={<span>{mask(value || '0', 'money')}</span>}/>
  }

  return <>{children}</>
}

function Avaliation ({ title, fields, areaId, fetchLogs, fetchProposals }: IAvaliationsProps) {
  const formRef: MutableRefObject<any> = useRef(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [check, setCheck] = useState<boolean>(fields.divergence || false)
  const treatedTitle = title.replace(/-/g, ' ')
  const itemsToHaveDivergenceCheckbox = ['valor-area-averbada']
  const hasDivergenceCheckbox = itemsToHaveDivergenceCheckbox.includes(title)

  const proposal = useProposal()
  const step = useStep()
  const user = useAuth()
  const IS_AUDITOR = user.level === 'auditor'
  const isReadOnly = !fields.writeAreaIds.includes(areaId) || IS_AUDITOR

  const isDisableField = hasDivergenceCheckbox && !isReadOnly && !check

  const onChangeOption = () => {
    if (check && formRef.current) formRef.current.submit()
    setCheck(prev => !prev)
  }

  useLayoutEffect(() => {
    const { value } = fields
    if (formRef && formRef.current) formRef?.current.setFieldsValue({ value })
  }, [])

  const sendAvaliation = async (values: { value: string }) => {
    const { value } = values
    const numberValue = format.formatMoneySend(value)

    const data = {
      value: numberValue,
      name: String(title),
      areaId,
      stepId: step.id,
      divergence: check
    }

    if (proposal.id) {
      setLoading(true)
      try {
        const response = await proposals.updateAvaliations({ proposalId: proposal.id, data })
        swal.basic({ title: 'Sucesso!', text: response?.message, icon: 'success' })
        fetchLogs()
        fetchProposals()
      } catch (err) {
        if (err.data?.invalid) {
          const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
          invalidFieldsError({ invalidFields: invalidFields })
        } else {
          swalError({ err })
        }
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <div className="avaliation-card" style={{ fontSize: 14, borderRadius: 5 }}>
      {hasDivergenceCheckbox && !isReadOnly && (
        <span className='avaliation-card__checkbox'>
          <Checkbox
            onChange={onChangeOption}
            checked={check}
            disabled={loading}
          >
            Habilitar

            <Tooltip
              destroyTooltipOnHide={{ keepParent: false }}
              title={avaliationTooltip}
            >
              <InfoCircleOutlined className='ml-1' size={8} />
            </Tooltip>
          </Checkbox>
        </span>
      )}

      <div
        className="avaliation-card__item"
        style={{ pointerEvents: hasDivergenceCheckbox && !check ? 'none' : 'visible' }}
      >
        <Form
          ref={formRef}
          onFinish={sendAvaliation}
          layout="vertical"
          className='avaliation-card__form'
        >
          <InputWrapper
            label={treatedTitle}
            value={valueOrNa(fields?.value)}
            isReadOnly={isReadOnly}
          >
            <Form.Item
              name='value'
              className='mb-0 flex-1'
              label={treatedTitle}
              rules={[{ required: true, message: 'Digite o valor' }]}
            >
              <InputMoney id={title} disabled={isDisableField} />
            </Form.Item>

            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              disabled={isDisableField}
            >
              Enviar
            </Button>
          </InputWrapper>
        </Form>
      </div>
    </div>
  )
}

export default Avaliation

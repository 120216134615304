import { IResponseBase, Repository } from './Repository'
import api from 'services/api'
import { AxiosRequestConfig } from 'axios'

class ProductsRepository extends Repository {
  async selectProduct (productId: string, config?: AxiosRequestConfig): Promise<IResponseBase> {
    return Repository.handle(() =>
      this.api.put(`${this.path}/${productId}/select`, config)
    )
  }
}

export default new ProductsRepository({ api, path: '/products' })

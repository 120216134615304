import { Drawer } from 'antd'
import React, { ReactNode } from 'react'
import SettingsCard from '../SettignsCard/SettingsCard'

interface IProps {
  children: ReactNode
  visible: boolean,
  onChangeVisible: () => void
  title: string,
  description: string
}

export default function SettingsHistory ({ children, visible, onChangeVisible, title, description }: IProps) {
  return (
    <Drawer
      title="Histórico de alterações"
      className="settings-history"
      width={1000}
      destroyOnClose={true}
      onClose={onChangeVisible}
      visible={visible}>

      <SettingsCard.title>
        {title}
      </SettingsCard.title>

      <SettingsCard.description className="mb-3">
        {description}
      </SettingsCard.description>
      {children}
    </Drawer>
  )
}

import React, { useState } from 'react'
import { Button } from 'antd'
import { useTables } from 'hooks'
import Modal from 'antd/lib/modal/Modal'
import proposalList from 'egi/app/ProposalEgi/views/ProposalListEgi/consumer'
import swal from 'utils/swal'
import { useDispatch } from 'react-redux'
import { tablesSetFiltersValues } from 'store/modules/tables/actions'

interface IProps {
  showStatusModal: boolean
  handleClose: ()=> void
  proposalId: string
}

function ReativateProposalModal ({ showStatusModal, handleClose, proposalId }: IProps) {
  const [loadingSubmmit, setLoadingSubmit] = useState<boolean>()

  const dispatch = useDispatch()
  const tables = useTables()

  async function changeProposalStatus (id: string) {
    setLoadingSubmit(true)
    try {
      const response = await proposalList.setActiveStatus(id)
      swal.basic({ title: 'Sucesso!', text: response.message, icon: 'success' })

      dispatch(tablesSetFiltersValues({ ...tables.filters }))
      handleClose()
    } catch (error) {
      swal.basic({ title: 'Atenção!', text: error.message, icon: 'error' })
    } finally {
      setLoadingSubmit(false)
    }
  }
  return (

    <Modal
      visible={showStatusModal}
      destroyOnClose
      onCancel={() => handleClose()}
      footer={null}
    >
      <span className='font-size-25'>Deseja reativar esta proposta ?</span>
      <p><span className='font-size-15'>Caso confirmado, a proposta irá retornar para o status</span> <b>em andamento</b>.</p>
      <div className='w-100 flex flex-justify-center mt-8'>
        <Button
          key='back'
          type='ghost'
          onClick={handleClose}
          className='w-25 mr-1'
        >
          Não
        </Button>

        <Button
          key='submit'
          type='primary'
          loading={loadingSubmmit}
          onClick={() => {
            if (proposalId) changeProposalStatus(proposalId)
          }}
          className='w-25 ml-1'
        >
        Sim
        </Button>
      </div>
    </Modal>
  )
}

export { ReativateProposalModal }

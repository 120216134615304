import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react'
import { IFormLGPD, ILGPDStep } from '../types'
import { Col, Form, Input, Row, Checkbox, Empty } from 'antd'
import { DocumentUpload } from 'components'
import { IDocuments } from 'egi/types'
import usePartnerStep from '../../../../../hooks/usePartnerStep'
import PartnerModel from 'egi/models/PartnerModel'

function FormLGPD ({ errors, onFileUpload, setFormFields, onFileDelete, formRef, readonly } : ILGPDStep) {
  const [hasLink, setHasLink] = useState<boolean>(false)
  const [path, setPath] = useState<Array<IDocuments>>([])
  const { step } = usePartnerStep()

  const hasLinkValue = formRef?.getFieldValue('hasLink')
  const columnSize = { lg: 14, sm: 24, xs: 24 }
  const sendDocument = PartnerModel.canSendDocument(step)

  useEffect(() => {
    setFormFields(step.form as IFormLGPD)
  }, [])

  useLayoutEffect(() => {
    setHasLink(hasLinkValue)
  }, [hasLinkValue])

  return (
    <Fragment>
      <Row>
        <Col className="form-lgpd__document-upload" {...columnSize}>
          <label className='form-lgpd__upload-label'>Certificado LGPD</label>

          {!step.documents && !sendDocument && <Empty description="Nenhum documento encontrado!" />}

          <DocumentUpload
            paths={path}
            getDocuments={() => setPath([{ name: '', path: step?.documents ?? [], stepId: '', stepName: '' }])}
            loading={false}
            hideUploadButton={!sendDocument}
            maxDocs={3}
            onFileUpload={onFileUpload}
            onFileDelete={onFileDelete}
            skeletonQuantity={2}
          />
        </Col>

      </Row>

      <Row gutter={[15, 0]}>
        <Col className="px-1" lg={8} sm={24} xs={24} >
          <Form.Item
            name='hasLink'
            className="form-user-checkbox"
            valuePropName="checked"
            label={<label></label>}
          >
            <Checkbox
              onChange={() => setHasLink(prev => !prev)}
              disabled={readonly}
            >
              Possui link do curso?
            </Checkbox>
          </Form.Item>
        </Col>

        {hasLink &&
          <Col className="px-1" lg={8} sm={24} xs={24}>
            <Form.Item
              name='certificateUrl'
              label={<label>Link do curso:</label>}
              help={errors?.certificateUrl && (errors?.certificateUrl)}
              validateStatus={errors?.certificateUrl && ('error')}
            >
              <Input placeholder='Digite o link do curso' readOnly={readonly}/>
            </Form.Item>
          </Col>
        }
      </Row>
    </Fragment>
  )
}

export { FormLGPD }

import { Button, Spin } from 'antd'
import VerifyEmailSuccesSVG from 'assets/reactSvgs/VerifyEmailSuccesSVG/VerifyEmailSuccesSVG'
import SignupLayout from 'layouts/SignupLayout/SignupLayout'
import React, { ReactNode, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { UNAUTHS_PATHS } from 'routes/unauthRoutes'
import queryString from 'query-string'
import useAuthLayout from 'hooks/useAuthLayout'
import swal from 'utils/swal'
import BugSVG from 'assets/reactSvgs/BugSVG/BugSVG'
import EmailRepository from 'egi/repositories/EmailRepository'
import { SignupTitle } from '../Signup/components/SignupTitle/SignupTitle'
import { ArrowDownOutlined } from '@ant-design/icons'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'

type VerifyEmailResultProps = {
  errorMessage: string
  loading: boolean
  setTryAgain: (tryAgain: boolean) => void
  verifyToken?: string | string[] | null
}

const VERIFY_EMAIL_TEXT = 'A verificação do seu email é necessária para poder acessar o sistema,'

function ResultText ({ children }: { children: ReactNode }) {
  return (
    <p className='verify-email__result-text mt-5 font-size-20 mb-5 text-center'>
      {children}
    </p>
  )
}

function VerifyEmailResult ({ errorMessage, loading, setTryAgain, verifyToken }: VerifyEmailResultProps) {
  const history = useHistory()
  const { handleReset } = useAuthLayout()
  const [loadingResendEmail, setLoadingResendEmail] = useState<boolean>(false)
  const isExpiredToken = errorMessage === 'Token de verificação expirada!'

  function handleGoToLogin () {
    handleReset()
    history.push(UNAUTHS_PATHS.MAIN)
  }

  async function resendMail (verifyToken: string | string[] | null) {
    if (verifyToken) {
      setLoadingResendEmail(true)
      try {
        const response = await EmailRepository.verification({ token: String(verifyToken) })
        swal.basic({
          title: 'Sucesso',
          text: response.data.message,
          icon: 'success'
        })
      } catch (err) {
        swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      } finally {
        setLoadingResendEmail(false)
      }
    }
  }

  if (loading) {
    return (
      <>
        <SignupTitle className='verify-email__title mb-4 text-center'>
          Verificação de email
        </SignupTitle>

        <p className='text-center color-primary font-size-15 mb-5'>{VERIFY_EMAIL_TEXT}</p>

        <div className='mt-5 mb-5'>
          <Spin size="large" />
        </div>

        <p>Aguarde um momento, estamos verificando seu email...</p>
      </>
    )
  }

  if (errorMessage && !loading) {
    return (
      <>
        <SignupTitle className='verify-email__title mb-4 text-center color-refused'>
          {errorMessage}
        </SignupTitle>

        <p className='text-center font-size-15 mb-5'>Ocorreu um erro durante a verificação do seu email</p>

        <BugSVG color='red' className='verify-email__error-image mt-5 mb-5' />
        <ResultText>Clique no botão abaixo para tentar novamenente</ResultText>

        <Button className='mt-5 verify-email__try-again-button' type='ghost' onClick={() => setTryAgain(true)}>
          <b>Tentar Novamente</b>
        </Button>

        {verifyToken && isExpiredToken &&
          <Button
            loading={loadingResendEmail}
            type='link'
            onClick={() => resendMail(verifyToken)}
            className='mt-2'
          >
            Reenviar email
          </Button>
        }
      </>
    )
  }

  return (
    <>
      <SignupTitle className='verify-email__title mb-4 text-center'>
        Parabéns, Seu email foi verificado com sucesso!
      </SignupTitle>

      <VerifyEmailSuccesSVG
        isAnimated={true}
        className='verify-email__succes-image mt-5 mb-5 w-100'
      />
      <ResultText>
        Agora você já pode logar no sistema, clique no botão abaixo para efetuar o login
      </ResultText>
      <h2>
        <b>Atenção usuários do consignado!
        </b>
      </h2>
      <p className="verify-email__instruction">
        Insira sua senha do canal rodobens (Onidata) para efetuar login em nossa plataforma
      </p>
      <ArrowDownOutlined
        className="verify-email__arrow-down"
      />
      <Button type='primary' onClick={() => handleGoToLogin()}>
        <b>Efetuar Login</b>
      </Button>
    </>
  )
}

export default function VerifyEmail () {
  const history = useHistory()
  const query = queryString.parse(history.location.search)
  const verifyToken = query.verifyToken

  const [loading, setLoading] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [tryAgain, setTryAgain] = useState<boolean>(false)

  const { handleSigninByAuth } = useAuthLayout()

  useEffect(() => {
    async function send (verifyToken: string | string[] | null) {
      setErrorMessage('')
      setLoading(true)
      try {
        if (!verifyToken) throw new Error('Token não encontrada')

        const response = await EmailRepository.verify({ token: verifyToken.toString() })
        const auth = response.data.data
        if (auth) {
          await handleSigninByAuth(auth)
        }
      } catch (err) {
        if (err.data?.invalid) {
          const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
          invalidFieldsError({ invalidFields: invalidFields })
          return
        }
        setErrorMessage(err.message)
      } finally {
        setTryAgain(false)
        setLoading(false)
      }
    }

    send(verifyToken)
  }, [tryAgain])

  return (
    <SignupLayout className='verify-email flex flex-align-center flex-column'>
      <div className='verify-email flex flex-align-center flex-column'>
        <VerifyEmailResult
          errorMessage={errorMessage}
          loading={loading}
          setTryAgain={setTryAgain}
          verifyToken={verifyToken}
        />
      </div>
    </SignupLayout>
  )
}

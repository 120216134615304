import dayjs from 'dayjs'
import { _proposalStatuses } from 'egi/app/ProposalEgi/proposalEgiInterfaces'
import format from './format'
import mask from './masks'
import { timeAsDayjs } from './time'
import translate from './translate'

const formatCleanDecimal = (value: number) => {
  if (!value) return 'n/a'
  const precise = parseFloat((value * 100).toPrecision(6))
  return precise + '%'
}

const CONVERTLIST: { [key: string]: any } = {
  cellphone: (value: string) => mask(String(value), 'phone', true),
  phone: (value: string) => mask(String(value), 'phone', true),
  cpf: (value: string) => mask(String(value), 'cpf', true),
  cnpj: (value: string) => mask(String(value), 'cnpj', true),
  cpfOrCnpj: (value: string) => mask(String(value), 'cpfcnpj'),
  gender: (value: string) => translate.gender(value),
  aliquota: formatCleanDecimal,
  ltv: formatCleanDecimal,
  prestamista: formatCleanDecimal,
  proposalStatus: (value: _proposalStatuses | 'withoutProposal') => translate.proposalStatus(value),
  active: (value: boolean) => value ? 'Ativo' : 'Desativado'
}

const ECP_CONVERT_LIST = {
  valor_financiado: (value: number) => format.formatBRL(Number(value)),
  valor_parcela: (value: number) => format.formatBRL(Number(value)),
  valor_solicitado: (value: number) => format.formatBRL(Number(value)),
  data_solicitacao: (value: string) => dayjs(value).isValid() ? timeAsDayjs(value, { applyTimezone: false }).format('DD/MM/YYYY') : 'Data inválida'
}

export { ECP_CONVERT_LIST }
export default CONVERTLIST

import { LoadingOutlined } from '@ant-design/icons'
import { message, Row, Tag, Tooltip } from 'antd'
import { IMinutaMoldVariables } from 'egi/app/FrameModel/components/FrameModalForm/FrameModelForm'
import React from 'react'
import { copyToClipboard } from 'utils/utils'

function VariablesAvailable ({ variables }: { variables: string | IMinutaMoldVariables[]}) {
  async function copyTheVariable (variable: string) {
    await copyToClipboard(`@${variable}`)
    message.success('Variável copiada com sucesso!')
  }

  return (
    <Row className="mb-4">
      <div>Variáveis disponíveis:</div>

      {status === 'getVariables'
        ? <LoadingOutlined className="ml-2"/>
        : (
          <div>
            {typeof variables === 'string' && (
              <Tooltip title='Clique para copiar' key={variables}>
                <strong className='variables-available__tag' >
                  {variables}
                </strong>
              </Tooltip>
            )}

            <aside className='variables-available__tags-container'>
              {Array.isArray(variables) && variables.map(item => (
                <Tooltip title='Clique para copiar' key={item.id}>
                  <Tag
                    className="variables-available__tag"
                    color='var(--primary-color)'
                    onClick={() => copyTheVariable(item.display)}
                  >
                    {item.display}
                  </Tag>
                </Tooltip>
              ))}
            </aside>
          </div>
        )}
    </Row>
  )
}

export default VariablesAvailable

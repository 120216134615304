import React, { Fragment } from 'react'
import { ColumnsType } from 'antd/lib/table'
import { Button, Space, Tag, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { ISimulation } from 'egi/types'
import translate from 'utils/translate'
import { colors, leadsColor } from 'styles/colors'
import { useDispatch } from 'react-redux'
import { drawerFillForms } from 'store/modules/drawer/actions'
import IContactDrawer, { leadTypes } from 'egi/types/IDrawer'
import format from 'utils/format'
import { BookOutlined, EditFilled, EyeFilled, InfoCircleFilled } from '@ant-design/icons'
import ContactToClient from 'components/ContactToClient/ContactToClient'
import { IntegrationTooltip } from 'egi/app/ProposalEgi/views/ProposalListEgi/dataTable'
import { ILeadsColums } from '../../leadInterfaces'
import LeadsModel from 'egi/models/LeadsModel'
import { leadDetails } from 'routes/routes'
import commonColumns, { commomNameOverflowWidth } from 'components/UserTable/utils/commonColumns'
import { OverflowTooltip } from 'components/OverflowToolTip/OverflowTooltip'

interface ILeadsColumns {
  status: leadTypes
  name: string
  email: string
  cellphone: string
  _id: string
  isIntegration: boolean
}

const telemarketing = (canSeeTm: boolean, canEditTm: boolean, showModalTelemarketing?: (telemarketing: { _id: string, name: string, type: string }, leadId: string) => void): Array<object> => {
  if (!canSeeTm) return []
  return [{
    title: 'Telemarketing',
    width: 200,
    ellipsis: true,
    dataIndex: 'telemarketing',
    render: (item: { name: string, type: string, _id: string }, row: any) => {
      if (!item) return 'N/A'

      if (canEditTm) {
        return (
          <OverflowTooltip width={175} title={item?.name}>
            <p className="link-style color-tertiary font-500 capitalize"
              onClick={() => showModalTelemarketing && showModalTelemarketing(item, (row as any)._id)}>{item.name} &rarr;</p>
          </OverflowTooltip> || 'N/A'
        )
      }

      return <OverflowTooltip width={175}>{item?.name}</OverflowTooltip>
    }
  }]
}

const commercial = (canSeeCommercial: boolean, canEditCommercial: boolean, showModalCommercial?: (commercial: string, leadId: string) => void): Array<object> => {
  if (!canSeeCommercial) return []
  return [{
    title: 'Comercial',
    dataIndex: 'commercialId',
    width: 200,
    render: (item: string, row: any) => {
      if (!item) return 'N/A'

      if (canEditCommercial) {
        return (
          <OverflowTooltip width={175} title={row?.commercial?.name}>
            <p className="link-style color-tertiary font-500 capitalize"
              onClick={() => showModalCommercial && showModalCommercial(item, (row as any)._id)}>
              {row?.commercial?.name} &rarr;
            </p>
          </OverflowTooltip> || 'N/A'
        )
      }

      return row?.commercial?.name
    }
  }]
}

const leadsColumnsFunc = ({
  showUpdateSimulator,
  showModalCommercial,
  canSeeCommercial,
  canEditCommercial,
  canSeeTm,
  canEditTm = false,
  showModalTelemarketing,
  user,
  useLinkedLeads = false
}: ILeadsColums): ColumnsType<Object> => {
  const columns: ColumnsType<Object> = [
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      width: 250,
      sorter: true,
      render: (value: string, record: Object) => {
        const dispatch = useDispatch()

        const translateLead = translate.leadStatus(value) || 'N/A'
        const row = record as ILeadsColumns
        const formatedPhone = format.onlyDigits(row.cellphone)
        const canSeeSimulateAndStatus = LeadsModel.canSeeSimulateAndStatus(user.level, user.areaName, user.type, useLinkedLeads)

        const leadsDrawer: IContactDrawer = {
          visible: true,
          title: `Ligar para o cliente ${row?.name || ''}`,
          type: 'leads',
          outsideClick: false,
          lead: {
            id: row._id,
            status: row.status,
            isSubmit: false
          },
          contact: {
            client: {
              id: row._id,
              number: formatedPhone
            }
          }
        }

        return (
          <Tag
            className={canSeeSimulateAndStatus ? 'pointer text-center' : 'text-center'}
            style={{ minWidth: 150 }}
            color={leadsColor[value]}
            onClick={() => { canSeeSimulateAndStatus && dispatch(drawerFillForms(leadsDrawer)) }}
          >
            {canSeeSimulateAndStatus && <EditFilled className="mx-1" />}
            {translateLead}
          </Tag>
        )
      }
    },
    {
      ...commonColumns.name,
      render: (name: string) => {
        if (!name) return 'N/A'

        return (
          <OverflowTooltip width={commomNameOverflowWidth} title={name}>
            {format.formatName(name)}
          </OverflowTooltip>
        )
      }
    },
    commonColumns.email,
    ...telemarketing(canSeeTm, canEditTm, showModalTelemarketing),
    ...commercial(canSeeCommercial, canEditCommercial, showModalCommercial),
    {
      title: 'Indicador/API',
      dataIndex: 'indicator',
      width: 160,
      render: (item, lead: ILeadsColumns) => {
        if (!item || !item?.name) return 'N/A'
        return (
          <Space>
            <Tooltip
              title={item?.name}
              destroyTooltipOnHide={{ keepParent: false }}
            >
              <span style={{ maxWidth: 120 }} className="overflow-ellipsis">{item?.name}</span>
            </Tooltip>
            {lead?.isIntegration && <IntegrationTooltip title="Lead gerado por integração" />}
          </Space>
        )
      }
    },
    {
      title: 'UF',
      dataIndex: 'uf',
      render: (item) => {
        if (item.label) return item.label
        return <></>
      },
      width: 80,
      sorter: true
    },
    {
      title: 'Cidade',
      dataIndex: 'city',
      width: 150,
      render: (item, row) => {
        if (item.label) {
          const isInvalid = (row as { invalidCity: boolean }).invalidCity
          if (isInvalid) {
            return (
              <Tooltip
                title="Cidade com número de habitantes abaixo do minímo."
                destroyTooltipOnHide={{ keepParent: false }}
              >
                <Tag color='red'>
                  <div className='flex flex-align-center'>
                    <InfoCircleFilled className='mr-1' />
                    <span style={{ maxWidth: 100 }} className="overflow-ellipsis">{item?.label}</span>
                  </div>
                </Tag>
              </Tooltip>
            )
          }
          return item.label
        }

        return <></>
      },
      sorter: true
    },
    {
      title: 'Criação',
      dataIndex: 'createdAt',
      width: 150,
      sorter: true
    },
    {
      title: 'Atualização',
      width: 150,
      dataIndex: 'updatedAt',
      sorter: true
    },
    {
      title: 'Pré-aprovação',
      dataIndex: 'preApproval',
      align: 'center',
      width: '150px',
      sorter: true,
      render: value => {
        if (!value) return <Tag>N/A</Tag>
        return <Tag color={colors[value]}>{translate.preApproval(value)}</Tag>
      }
    },
    ...(LeadsModel.canSeeSimulateAndStatus(user.level, user.areaName, user.type, useLinkedLeads) ? [{
      title: 'Simulação',
      dataIndex: 'currentStep',
      width: 300,
      sorter: true,
      render: (currentStep: number, record: Object) => {
        const row = record as ISimulation
        const status = (record as { status: string }).status

        if (status !== 'approved') {
          return (
            <div className="no-wrap-white-space">
              <Button
                type="link"
                onClick={() => showUpdateSimulator(row)}
              >
                Completar simulação
              </Button>
              <span className="step-lead">
                {currentStep}º passo
              </span>
            </div>
          )
        }

        return <label className="success-lead">Lead convertido</label>
      }
    }] : []),
    ...((LeadsModel.canSeeActions(user.level, user.type)) ? [{
      title: '',
      key: '',
      width: 150,
      render: (row: ILeadsColumns) => {
        const { _id, cellphone, name, email, status } = (row as ILeadsColumns)
        const canSeeSimulateAndStatus = LeadsModel.canSeeSimulateAndStatus(user.level, user.areaName, user.type, useLinkedLeads)
        return (
          <div className="flex">
            <Tooltip title="Histórico" destroyTooltipOnHide={{ keepParent: false }}>
              <Link to={`/auth/leads/history/${_id}`} className="color-black">
                <BookOutlined className="mr-2" style={{ fontSize: 20 }} />
              </Link>
            </Tooltip>

            {canSeeSimulateAndStatus && (
              <ContactToClient
                leadStatus={status}
                contactType="contact"
                leadId={_id}
                canCall={true}
                numberClient={cellphone}
                emailClient={{
                  destination: email,
                  type: 'simulação'
                }}
                nameClient={name}
              />
            )}
          </div>
        )
      }
    }] : []),
    ...((LeadsModel.canSeeDetails(user.level, user.areaName, user.type)) ? [{
      title: '',
      key: '',
      width: 50,
      render: (row: ILeadsColumns) => {
        return (
          <Link
            className="link-style"
            to={'/auth' + leadDetails.path.replace(':leadId', row._id)}
          >
            <EyeFilled className='cursor-pointer font-size-20' />
          </Link>
        )
      }
    }] : [])
  ]

  return [...columns]
}

export { leadsColumnsFunc }

import { useState } from 'react'

const usePartnerStepDrawer = () => {
  const [visible, setVisible] = useState<boolean>(false)

  const open = () => setVisible(true)
  const close = () => setVisible(false)

  return {
    visible,
    open,
    close
  }
}

export default usePartnerStepDrawer

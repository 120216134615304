import { Divider, Tabs } from 'antd'
import { useStep } from 'hooks'
import React from 'react'
import { useDispatch } from 'react-redux'
import { stepChangeModeOptions } from 'store/modules/step/actions'
import translate from 'utils/translate'

function MultipleModes ({ stepMode }: { stepMode: Function }) {
  const dispatch = useDispatch()
  const step = useStep()

  if (!step.modeOptions) return <></>
  if (step.activeMode) return stepMode(step.activeMode)
  if (step.modeOptions.optional) {
    return (
      <Tabs onChange={item => dispatch(stepChangeModeOptions({ selected: item }))}>
        {step.modeOptions.modes.length > 0 && step.modeOptions.modes.map(item => (
          <Tabs.TabPane tab={`${translate.modes(item as 'term' | 'document')}`} key={item}>
            {stepMode(item)}
          </Tabs.TabPane>
        ))}
      </Tabs>
    )
  }

  return (
    <>
      {step.modeOptions.modes.length > 0 && step.modeOptions.modes.map(item => (
        <>
          {stepMode(item)}
          <Divider />
        </>
      ))}
    </>
  )
}

export default MultipleModes

export const PREFIX = '/ecp'

export default abstract class EntityRoute {
  protected PREFIX = PREFIX
  protected FORM = '/form'
  protected FORM_PREFIX = this.PREFIX + this.FORM
  protected DETAILS = '/details'
  protected DETAILS_PREFIX = this.PREFIX + this.DETAILS

  abstract name: string
  abstract preposition: string
  abstract path: string

  protected getPathId = (path: string) => path.replace(/[\\/:]/, '') + 'Id'

  public list () {
    return {
      name: this.name + 's',
      path: this.PREFIX + this.path + 's'
    }
  }

  public details () {
    return {
      name: `Detalhes ${this.preposition} ${this.name.toLocaleLowerCase()}`,
      path: `${this.DETAILS_PREFIX}${this.path}/:${this.getPathId(this.path)}`
    }
  }

  public form () {
    return {
      name: `Formulário ${this.name.toLocaleLowerCase()}`,
      path: `${this.FORM_PREFIX}${this.path}/:${this.getPathId(this.path)}?`,
      paramLessPath: this.FORM_PREFIX + this.path
    }
  }
}

import loadable from '@loadable/component'
import Loading from 'components/Loading/Loading'
import { timeout } from 'promise-timeout'
import React from 'react'
import { TIMEOUT_LOADER } from 'utils/globals'

const CorbanList = loadable(
  () => timeout(import(/* webpackChunkName: "Corban" */ 'ecp/app/Corbans/views/CorbanList/CorbanList'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const CorbanDetails = loadable(
  () => timeout(import(/* webpackChunkName: "Corban" */ 'ecp/app/Corbans/views/CorbanDetails/CorbanDetails'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const CorbansFormController = loadable(
  () => timeout(import(/* webpackChunkName: "Corban" */ 'ecp/app/Corbans/views/CorbanForm/CorbansFormController'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const CorbanAssociateChannel = loadable(
  () => timeout(import(/* webpackChunkName: "Corban" */ 'ecp/app/Corbans/views/CorbanAssociateChannel/CorbanAssociateChannel'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const SubCorbansForm = loadable(
  () => timeout(import(/* webpackChunkName: "Corban" */ 'ecp/app/Corbans/views/SubCorbansForm/SubCorbansForm'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const SubCorbanAssociateChannel = loadable(
  () => timeout(import(/* webpackChunkName: "Corban" */ 'ecp/app/Corbans/views/SubCorbanAssociateChannel/SubCorbanAssociateChannel'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const CorbanClientForm = loadable(
  () => timeout(import(/* webpackChunkName: "Corban" */ 'ecp/app/Clients/views/CorbanClientForm/CorbanClientForm'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const CorbanClientFormOnidata = loadable(
  () => timeout(import(/* webpackChunkName: "Corban" */ 'ecp/app/Clients/views/CorbanClientFormOnidata/CorbanClientFormOnidata'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

export {
  CorbanList,
  CorbanDetails,
  CorbansFormController,
  CorbanAssociateChannel,
  CorbanClientFormOnidata,
  SubCorbansForm,
  SubCorbanAssociateChannel,
  CorbanClientForm
}

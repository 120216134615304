import swal, { IBasic } from 'utils/swal'
import { prettyPrintJson } from 'pretty-print-json'
import CopyIcon from 'assets/icons/copy.svg'
import { copyToClipboard } from 'utils/utils'
import { message } from 'antd'

type ISwalError = Omit<Omit<IBasic, 'text'>, 'title'> & {
  err: any
  title?: string
}

export function swalError ({ err, title = 'Ocorreu um problema', icon = 'warning', ...rest }: ISwalError) {
  if (!err?.message) return

  const html = prettyPrintJson.toHtml(err.data || err)

  swal.htmlBasic({
    text: `<span class='swal-error-text'>${err.message}</span>`,
    customClass: {
      footer: 'swal-error-footer'
    },
    icon,
    title,
    footer: `<input id='swal-error-toggle' class='swal-error-checkbox' type='checkbox'/><label for='swal-error-toggle' class='swal-error-label swal-error-label-on'>Ver Detalhes</label><label for='swal-error-toggle' class='swal-error-label swal-error-label-off'>Fechar Detalhes</label><div class='swal-error-wrapper'><pre class="swal-error json-container">${html}</pre></div>`,
    ...rest
  })

  const swalEl = document.querySelector('.swal-error-wrapper')
  const copyButton = document.createElement('button')

  copyButton.onclick = () => {
    copyToClipboard(JSON.stringify(err.data || err))
    message.success({ content: 'Mensagem copiada' })
  }
  copyButton.classList.add('swal-error-copy')
  copyButton.innerHTML = `<img src='${CopyIcon}'/>`

  swalEl?.appendChild(copyButton)
}

type ISwalErrorList = Omit<Omit<IBasic, 'text'>, 'title'> & {
  title?: string
  errors?: string[]
}

export function swalErrorList ({ title = 'Algo está errado', errors, icon = 'warning', ...rest }: ISwalErrorList) {
  let errorsLi = ''
  errors?.forEach(
    function (error) {
      errorsLi += `<li>${error}</li>`
    }
  )

  swal.htmlBasic({
    text: `<ul class='swal-error-list'>${errorsLi}</ul>`,
    customClass: {
      footer: 'swal-error-footer'
    },
    icon,
    title,
    ...rest
  })
}

import React, { useEffect, useState } from 'react'
import { ISignatureViewerProps } from './types'
import { Typography, Row, Col } from 'antd'
import StringToHtml from 'components/StringToHtml/StringToHtml'
const { Title } = Typography

const SignatureViewer = ({ signatures, title }: ISignatureViewerProps) => {
  const [contract, setContract] = useState<string>('')

  useEffect(() => {
    if (signatures && signatures[0]) {
      setContract(signatures[0].contract.signed)
    }
  }, [])

  return (
    <Row>
      <Col>
        <Title level={4}>{title}</Title>
        {contract && <StringToHtml value={contract} />}
      </Col>
    </Row>
  )
}

export default SignatureViewer

import { Button, Form, message } from 'antd'
import locale from 'antd/lib/date-picker/locale/en_US'
import Modal from 'antd/lib/modal/Modal'
import { DatepickerCustom } from 'components'
import { downloadCSVFile } from 'components/SeverTable/serverTableFunctions'

import ChatRepository from 'egi/repositories/ChatRepository'
import React, { useState } from 'react'
import { timeAsDayjs } from 'utils/time'

interface IProps {
  closeReportsButtonModal: () => void
  visible: boolean
}
function ReportsDatesFilter ({ visible, closeReportsButtonModal }: IProps) {
  const [loading, setLoading] = useState<boolean>(false)

  async function downloadReports (values: any) {
    setLoading(true)
    try {
      const params = {
        month: timeAsDayjs(values.month).format('YYYY-MM')
      }
      const response = await ChatRepository.reports({ params })
      const CSV = response.data.data.report
      if (CSV) downloadCSVFile(CSV, '_chat')
    } catch (err) {
      message.error(err.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      closable={false}
      title='Baixar relatório'
      visible={visible}
      footer={null}
      destroyOnClose={true}
    >
      <Form
        layout="vertical"
        onFinish={downloadReports}
      >
        <Form.Item label="Data" name="month">
          <DatepickerCustom
            disabledDate={(current) => current.isAfter(new Date())}
            allowClear={false}
            placeholder='Selecione o mês'
            size='small'
            picker='month'
            format='MM/YYYY'
            locale={locale}
          />
        </Form.Item>
        <div className="generic-chat__modal-buttons">
          <Button
            className='mx-1'
            type="ghost"
            key="cancel"
            onClick={closeReportsButtonModal}
          >
            Cancelar
          </Button>
          <Button
            loading={loading}
            htmlType="submit"
            className='mx-1'
            type="primary"
            key="cancel"
          >
            Confirmar
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default ReportsDatesFilter

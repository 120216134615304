
import React from 'react'

import mainColumns from 'egi/views/UsersTable/DataTables'
import { PictureOutlined, SolutionOutlined, TeamOutlined } from '@ant-design/icons'
import translate from 'utils/translate'
import UsersTable from 'egi/views/UsersTable/UsersTable'

import { IRoute } from 'components/Sidebar/types'
import ProposalsListEgiOld from 'egi/app/ProposalEgi/views/ProposalListEgi/ProposalListEgi'
import CampaignLink from 'egi/app/CampaignLink/components/CampaignLink/CampaignLink'
import GenerateLink from 'egi/app/MLM/views/GenerateLink/GenerateLink'
import Divulgation from 'egi/app/MLM/views/Divulgation/Divulgation'
import ProposalsListEgiTwo from 'egi/app/ProposalEgi2/views/ProposalListEgi/ProposalListEgi'

const sellersList: IRoute = {
  path: '/mlm/sellers',
  name: 'Indicadores',
  icon: TeamOutlined,
  component: () => <UsersTable url='/sellers' level="seller" inviteButtonText={translate.level('seller')} columns={mainColumns.seller} />,
  meta: {
    centered: false,
    hidden: false,
    canGoBack: false,
    disabled: false
  }
}

const mlmProposalsList: IRoute = {
  path: '/mlm/proposals/list-old',
  name: 'Propostas',
  icon: SolutionOutlined,
  component: () => <ProposalsListEgiOld mlm={true} />,
  meta: {
    centered: false,
    hidden: true,
    canGoBack: false,
    disabled: false
  }
}

const mlmProposalsListTwo: IRoute = {
  path: '/mlm/proposals/list',
  name: 'Propostas ',
  icon: SolutionOutlined,
  component: () => <ProposalsListEgiTwo mlm={true} />,
  meta: {
    centered: false,
    hidden: false,
    canGoBack: false,
    disabled: false
  }
}

const generateLink: IRoute = {
  path: '/mlm/generatelink',
  name: 'Gerar link',
  icon: TeamOutlined,
  component: GenerateLink,
  meta: {
    centered: false,
    hidden: false,
    canGoBack: false,
    disabled: false
  }
}

const divulgationPage: IRoute = {
  path: '/mlm/divulgation',
  name: 'Divulgação',
  icon: PictureOutlined,
  component: Divulgation,
  meta: {
    centered: true,
    hidden: false,
    canGoBack: false,
    disabled: false
  }
}

const campaignLink: IRoute = {
  path: '/mlm/campaignlink',
  name: 'Campanhas',
  icon: TeamOutlined,
  component: CampaignLink,
  meta: {
    centered: false,
    hidden: false,
    canGoBack: false,
    disabled: false
  }
}

export {
  sellersList,
  generateLink,
  campaignLink,
  mlmProposalsList,
  mlmProposalsListTwo,
  divulgationPage
}

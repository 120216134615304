import { IClient } from 'egi/types'
import { IClientApproval } from 'egi/types/IClient'

export const setClientData = (client: IClient) => ({
  type: 'SET_CLIENT_DATA',
  payload: {
    client
  }
})

export const setClientId = (id: string) => ({
  type: 'SET_CLIENT_ID',
  payload: {
    _id: id
  }
})

export const setClientApproval = (data: IClientApproval) => ({
  type: 'SET_CLIENT_APPROVAL',
  payload: { data }
})

export const clientReset = () => ({
  type: 'RESET_CLIENT'
})

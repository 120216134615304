export const tablesSetBackOfficeAreas = ({ areas }: { areas?: Array<string> }) => ({
  type: 'TABLE_SET_BACKOFFICE_AREAS',
  payload: {
    areas
  }
})

export const tablesSetRowValues = ({ row }: { row?: Object }) => ({
  type: 'TABLE_SET_ROW_VALUES',
  payload: { row }
})

export const tablesSetFiltersValues = (filters: Object) => ({
  type: 'TABLE_SET_FILTER_VALUES',
  payload: { filters }
})

export const setPreviousTable = (table: string | undefined) => ({
  type: 'SET_PREVIOUS_TABLE',
  payload: table
})

export const tablesUpdate = () => ({
  type: 'TABLE_UPDATE'
})

export const tableDisableAll = (ignoreAll: boolean) => ({
  type: 'TABLE_DISABLE_ALL',
  payload: { ignoreAll }
})

export const tablesResetFiltersValues = (
  fixedDate: boolean,
  hasProposalStatus?: boolean,
  hasLeadStatus?: boolean,
  hasAction?: boolean | string,
  trimester?: boolean,
  needVinculation?: boolean
) => ({
  type: 'TABLE_RESET_FILTER_VALUES',
  payload: { fixedDate, hasProposalStatus, hasLeadStatus, hasAction, trimester, needVinculation }
})

export const tablesPromoterSetFn = (promoterSetFn: Function) => ({
  type: 'TABLE_SET_PROMOTER_FN',
  payload: { promoterSetFn }
})

export const tablesResetAll = () => ({
  type: 'TABLES_RESET_ALL'
})

export const tablesSetTotalDocs = (totalDocs: number | undefined) => ({
  type: 'TABLE_SET_TOTAL_DOCS',
  payload: { totalDocs }
})

import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { SelectProps } from 'antd/lib/select'
import { IOption } from 'components/MultiSelect/MultiSelect'
import { ALL_CHECKED_TEXT, ONLY_CHECKED_TEXT, showOnlyCheckedDisabledText } from 'components/MultiSelectModal/MultiSelectModalConstants'
import { useEffect, useMemo, useState } from 'react'

interface IUseMultiSelect<T extends IOption = any> extends Omit<SelectProps<T>, 'mode' | 'options'> {
   options: T[]
   initialValues?: Array<CheckboxValueType>
}

function useMultiSelect<T extends IOption> ({ options, initialValues }: IUseMultiSelect<T>) {
  const [checkedList, setCheckedList] = useState<Array<CheckboxValueType> | undefined>(initialValues)
  const [checkAll, setCheckAll] = useState(false)
  const [haveSearch, setHaveSearch] = useState(false)
  const [showOnlyChecked, setShowOnlyChecked] = useState(false)
  const [filteredList, setFilteredList] = useState(options)
  const [searchValue, setSearchValue] = useState<string>()

  const handleShowOnlyChecked = () => {
    if (checkAll) return undefined
    setShowOnlyChecked(prev => !prev)
  }

  const allValues = useMemo(() => {
    return options?.map?.((option) => typeof option === 'object' && 'value' in option && option.value)
  }, [options])

  const handleCheckedList = (values: Array<CheckboxValueType>) => {
    setCheckedList(values)
    setCheckAll(values?.length === allValues?.length)
  }

  const onCheckAll = (event: CheckboxChangeEvent) => {
    setCheckedList(event.target.checked ? allValues : [])
    setCheckAll(event.target.checked)

    if (event.target.checked) setShowOnlyChecked(false)
  }

  const onOpenDropdown = (value?: string) => {
    setHaveSearch(value ? true : false)
    setSearchValue(value)
  }

  const onCloseDropdown = () => {
    if (searchValue) return
    setSearchValue(undefined)
    setHaveSearch(false)
  }

  const onClearSearch = () => {
    setSearchValue('')
    setHaveSearch(false)
  }

  const onSelectSearchValue = (valueSelected?: string) => {
    if (!valueSelected) {
      return
    }

    if (!checkedList) {
      setCheckedList([valueSelected])
    } else if (!checkedList?.includes(valueSelected)) {
      setCheckedList([...checkedList, valueSelected])
    } else {
      const withoutValue = checkedList?.filter(item => item !== valueSelected)
      setCheckedList(withoutValue)
    }
  }

  const filterText = useMemo(() => {
    if (checkedList?.length === allValues?.length) {
      setCheckAll(true)
      return ALL_CHECKED_TEXT
    }

    if (showOnlyChecked) return ONLY_CHECKED_TEXT

    return showOnlyCheckedDisabledText(checkedList?.length)
  }, [showOnlyChecked, checkedList])

  useEffect(() => {
    if (showOnlyChecked) {
      const newOptions = options?.filter?.(opt => {
        return checkedList?.includes(opt.value)
      })
      setFilteredList(newOptions)
    } else {
      setFilteredList(options)
    }
  }, [showOnlyChecked, checkedList, options])

  return {
    onSelectSearchValue,
    onOpenDropdown,
    onClearSearch,
    onCheckAll,
    handleCheckedList,
    handleShowOnlyChecked,
    onCloseDropdown,

    filterText,
    haveSearch,
    filteredList,
    checkAll,
    checkedList,
    searchValue
  }
}

export default useMultiSelect

import promoter from 'egi/consumers/partners'
import { call, put, all, takeLatest } from 'redux-saga/effects'
import { resources as consumerResource } from 'egi/consumers'
import { resourcesSetProducts } from './actions'

function * products (action: any): any {
  const callback = yield action.payload.callback

  try {
    const response = yield call(consumerResource.products)
    const products = response.data.products

    yield put(yield call(resourcesSetProducts, products))

    if (callback) yield callback(products)
  } catch (err) {
    console.warn(err)
    if (callback) yield callback()
  }
}

function * providers (action: any): any {
  const areaName = action.payload.areaName

  try {
    const response = yield call(consumerResource.providers, { areaName })
    const providers = response.providers

    yield put({
      type: 'RESOURCES_PROVIDERS_SUCCEED',
      payload: {
        providers
      }
    })
  } catch (err) {
    yield put({ type: 'RESOURCES_PROVIDERS_RESET' })
    console.warn(err)
  }
}

function * getDeclines (action: any): any {
  const productId = action.payload.productId

  try {
    const response = yield call(consumerResource.declines, { productId })
    const declines = response.data.options

    yield put({
      type: 'RESOURCES_DECLINES_SUCCEED',
      payload: {
        declines
      }
    })
  } catch (err) {
    console.warn(err)
  }
}

function * areas (): any {
  try {
    const response = yield call(consumerResource.areas)
    const areas = response.areas

    yield put({
      type: 'RESOURCES_AREAS_SUCCEED',
      payload: {
        areas
      }
    })
  } catch (err) {
    console.warn(err)
  }
}

function * states (): any {
  try {
    const response = yield call(consumerResource.states)
    const states = response

    yield put({
      type: 'RESOURCES_STATES_SUCCEED',
      payload: {
        states
      }
    })
  } catch (err) {
    console.warn(err)
  }
}

function * ibgeStates (): any {
  try {
    const response = yield call(consumerResource.statesIBGE)
    const statesIBGEFormated = response.map((values: any) => {
      const statesIBGE = {
        label: String(values.label),
        value: String(values.value),
        cities: values.cities
      }
      return statesIBGE
    })
    const states = statesIBGEFormated
    yield put({
      type: 'RESOURCES_STATES_IBGE_SUCCEED',
      payload: {
        states
      }
    })
  } catch (err) {
    console.warn(err)
  }
}

function * banks (): any {
  try {
    const response = yield call(consumerResource.banks)
    const banks = response

    yield put({
      type: 'RESOURCES_BANKS_SUCCEED',
      payload: {
        banks
      }
    })
  } catch (err) {
    console.warn(err)
  }
}

function * consultants (): void | any {
  try {
    const response = yield call(consumerResource.consultants)
    const consultants = response
    yield put({
      type: 'RESOURCES_CONSULTANTS_SUCCEED',
      payload: {
        consultants
      }
    })
  } catch (err) {
    console.warn(err)
  }
}

function * supportCommercial (): void | any {
  try {
    const response = yield call(consumerResource.support)
    const commercialSupport = response.data.commercials

    yield put({
      type: 'RESOURCES_SUPPORT_SUCCEED',
      payload: {
        commercialSupport
      }
    })
  } catch (err) {
    console.warn(err)
  }
}

function * telemarketing (): void | any {
  try {
    const response = yield call(consumerResource.telemarketing)
    const allTelemarketing = response.data.telemarketing

    yield put({
      type: 'RESOURCES_TELEMARKETING_SUCCEED',
      payload: {
        allTelemarketing
      }
    })
  } catch (err) {
    console.warn(err)
  }
}

function * businessManagers (): void | any {
  try {
    const response = yield call(promoter.getAllManagers)
    const allBusinessManagers = response.data.allPossibleManagers

    yield put({
      type: 'RESOURCES_BUSINESS_MANAGERS_SUCCEED',
      payload: {
        allBusinessManagers
      }
    })
  } catch (err) {
    console.warn(err)
  }
}

function * countries (): any {
  try {
    const response = yield call(consumerResource.countries)
    const countries = response
    yield put({
      type: 'RESOURCES_COUNTRIES_SUCCEED',
      payload: {
        countries
      }
    })
  } catch (err) {
    console.warn(err)
  }
}

function * legalNature (): any {
  try {
    const response = yield call(consumerResource.legalNature)
    const legalNature = response
    yield put({
      type: 'RESOURCES_LEGAL_NATURE_SUCCEED',
      payload: {
        legalNature
      }
    })
  } catch (err) {
    console.warn(err)
  }
}

function * economicActivity (): any {
  try {
    const response = yield call(consumerResource.economicActivity)
    const economicActivity = response
    yield put({
      type: 'RESOURCES_ECONOMIC_ACTIVITY_SUCCEED',
      payload: {
        economicActivity
      }
    })
  } catch (err) {
    console.warn(err)
  }
}

function * goals (): any {
  try {
    const response = yield call(consumerResource.goals)
    const goals = response.data.resourceGoals
    yield put({
      type: 'RESOURCES_GOALS_SUCCEED',
      payload: {
        goals
      }
    })
  } catch (err) {
    console.warn(err)
  }
}

function * areaNames (): any {
  try {
    const response = yield call(consumerResource.areaNames)
    const areaNames = response.data.areaNames

    yield put({
      type: 'RESOURCES_AREA_NAMES_SUCCEED',
      payload: { areaNames }
    })
  } catch (err) {
    console.warn(err)
  }
}

function * refusedReasons (): any {
  try {
    const response = yield call(consumerResource.refusedReasons)
    const refusedReasons = response.data.reasons

    yield put({
      type: 'RESOURCES_REFUSED_REASONS_SUCCEED',
      payload: {
        refusedReasons
      }
    })
  } catch (err) {
    console.warn(err)
  }
}

function * getQualifications (action: any): any {
  const productId = action.payload.productId

  try {
    const response = yield call(consumerResource.minutaMold, { productId: productId })
    const minutaMold = response.data.values

    yield put({
      type: 'RESOURCES_MINUTA_MOLD_SUCCEED',
      payload: {
        minutaMold
      }
    })
  } catch (err) {
    console.warn(err)
  }
}

export default all([
  takeLatest('RESOURCES_PRODUCTS_REQUESTED', products),
  takeLatest('RESOURCES_AREAS_REQUESTED', areas),
  takeLatest('RESOURCES_PROVIDERS_REQUESTED', providers),
  takeLatest('RESOURCES_STATES_REQUESTED', states),
  takeLatest('RESOURCES_BANKS_REQUESTED', banks),
  takeLatest('RESOURCES_STATES_IBGE_REQUESTED', ibgeStates),
  takeLatest('RESOURCES_CONSULTANTS_REQUESTED', consultants),
  takeLatest('RESOURCES_BUSINESS_MANAGERS_REQUESTED', businessManagers),
  takeLatest('RESOURCES_COUNTRIES_REQUESTED', countries),
  takeLatest('RESOURCES_LEGAL_NATURE_REQUESTED', legalNature),
  takeLatest('RESOURCES_ECONOMIC_ACTIVITY_REQUESTED', economicActivity),
  takeLatest('RESOURCES_GOALS_REQUESTED', goals),
  takeLatest('RESOURCES_REFUSED_REASONS_REQUESTED', refusedReasons),
  takeLatest('RESOURCES_AREA_NAMES_REQUESTED', areaNames),
  takeLatest('RESOURCES_SUPPORT_REQUESTED', supportCommercial),
  takeLatest('RESOURCES_TELEMARKETING_REQUESTED', telemarketing),
  takeLatest('RESOURCES_DECLINES_REQUESTED', getDeclines),
  takeLatest('RESOURCES_MINUTA_MOLD_REQUESTED', getQualifications)
])

import { Skeleton } from 'antd'
import React, { ReactNode } from 'react'

const InfoCard = ({
  title,
  description
}: {
  title: string | ReactNode
  description: string | ReactNode
}) => {
  return (
    <div className='info-card'>
      <h3 className='info-card__title'>{title}</h3>
      <h3 className='info-card__info'><b>{description}</b></h3>
    </div>
  )
}

InfoCard.Loading = ({ width }: { width: number }) => {
  return (
    <Skeleton.Button
      style={{ width }}
      className='info-card__loading'
      active={true}
    />
  )
}

export { InfoCard }

import { Repository } from './Repository'
import api from 'services/api'
import { AxiosRequestConfig } from 'axios'
import { IScore, IScoreForm, IScoreTotal } from 'ecp/app/Proposals/proposalInterfaces'

class ScoreRepository extends Repository<IScore> {
  async bulk (id: string, data: IScoreForm, config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.put(`${this.path}/${id}`, data, config)
    )
  }

  async scoreIndex (id: string, config?: AxiosRequestConfig) {
    return Repository.handle<{ [key: string]: IScoreTotal }>(() =>
      this.api.get(`${this.path}/${id}/dashboard`, config)
    )
  }

  async scoreList (id: string, config?: AxiosRequestConfig) {
    return Repository.handle<{ [key: string]: IScore[] }>(() =>
      this.api.get(`${this.path}/${id}`, config)
    )
  }
}

export default new ScoreRepository({ api, path: '/ecp/scores' })

import { AxiosRequestConfig } from 'axios'
import { IRBalance } from 'ecp/app/IRReport/hooks/useIRReport'
import api from 'services/api'
import { Repository } from './Repository'

export enum IRReportRequestStatus {
  awaiting = 'awaiting',
  processing = 'processing',
  error = 'error',
  finished = 'finished'
}

class IRRepository extends Repository<{}> {
  async fetchBalance (userId: string, config?: AxiosRequestConfig) {
    return Repository.handle<{ balance: IRBalance }>(() =>
      this.api.get(`${this.path}/user/${userId}`, config)
    )
  }

  async downloadPdf (userId: string, config?: AxiosRequestConfig) {
    return Repository.handle<{ pdf: Blob }>(() =>
      this.api.get(`${this.path}/user/${userId}/pdf`, config)
    )
  }

  async requestPdf (body: { userId: string, covenantId: string }, config?: AxiosRequestConfig) {
    return Repository.handle<{ _id: string }>(() =>
      this.api.post(`${this.path}/pdf/request`, body, config)
    )
  }

  async checkPdf (irReportRequest: string, config?: AxiosRequestConfig) {
    return Repository.handle<{ pdf: { result?: Blob, status: IRReportRequestStatus } }>(() =>
      this.api.get(`${this.path}/pdf/request/${irReportRequest}`, config)
    )
  }
}

export default new IRRepository({ api, path: '/ecp/ir' })

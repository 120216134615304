import React from 'react'
import { ShareButtons } from 'components'
import useDynamicProposal from 'ecp/app/Proposals/hooks/useDynamicProposal'
import useThisStep from 'ecp/app/Proposals/hooks/useThisStep'
import { useSignatureStepContext } from './SignatureStepCompose'

function LinkStepActions () {
  const { details } = useDynamicProposal()
  const { step } = useThisStep()
  const canShareLink = step.canShareLink
  const { actions, getActions } = useSignatureStepContext()

  async function gerenateLink (proposalId?: string) {
    // No try/catch because of ShareButtons' await to
    if (actions) return actions.link
    if (!proposalId) throw new Error('ProposalId não encontrado.')
    const response = await getActions(proposalId)
    const { link } = response || {}
    return link
  }

  return (
    <>
      {canShareLink && (
        <ShareButtons
          messagePrefix={'Link de assinatura do contrato ECP: '}
          generateLink={() => gerenateLink(details?.value._id)}
          email={details?.value?.client?.email}
          phone={details?.value?.client?.cellphone}
        />
      )}
    </>
  )
}

export default LinkStepActions

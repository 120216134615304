import {
  proposalsHistory,
  proposalMainPage,
  profile,
  chat,
  lgpdTerms,
  bacenTerms
} from '../routes'

const clientRouter = [
  proposalMainPage,
  proposalsHistory,
  profile,
  chat,
  lgpdTerms,
  bacenTerms
]

export default clientRouter

import React, { useState, useLayoutEffect } from 'react'
import { Form, Col, Row } from 'antd'
import { InputMoney } from 'components'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'

import { useAuth, useSelectedProduct } from 'hooks'
import { LoadingOrEmpty } from 'egi/app/Settings/views/PopulationSettings/Population'
import format from 'utils/format'
import ConfigurationsRepository from 'egi/repositories/ConfigurationsRepository'
import SimulationModel from 'egi/models/SimulationModel'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'
import SettingsCard from 'egi/app/Settings/components/SettignsCard/SettingsCard'
import { ISimulationConfigurationsValues, ISimulationConfigurationsValuesErrors } from '../../views/SimulationConfiguration/simulationConfigurationInterfaces'

function SimulationConfigurationForm () {
  const [errors, setErrors] = useState<ISimulationConfigurationsValuesErrors>({})
  const [loading, setLoading] = useState({
    list: true,
    button: false
  })

  const { _id: productId } = useSelectedProduct()
  const [failRequest, setFailRequest] = useState<boolean>(false)

  const [formRef] = Form.useForm()
  const user = useAuth()

  useLayoutEffect(() => {
    const getLimitValues = async () => {
      setLoading({ ...loading, list: true })

      try {
        const response = await ConfigurationsRepository.getSimulationLimits(productId)
        const simulationLimit = response.data.data
        setLoading({
          ...loading,
          list: false
        })

        if (formRef) {
          formRef.setFieldsValue({
            propertyMin: simulationLimit?.values.propertyMin,
            propertyMax: simulationLimit?.values.propertyMax,
            creditMin: simulationLimit?.values.creditMin,
            creditMax: simulationLimit?.values.creditMax
          })
        }
      } catch (err) {
        setFailRequest(true)
      } finally {
        setLoading({
          ...loading,
          list: false
        })
      }
    }

    if (productId) getLimitValues()
  }, [productId])

  const onSubmit = async (values: ISimulationConfigurationsValues) => {
    if (values.propertyMin) values.propertyMin = format.formatMoneySend(String(values.propertyMin))
    if (values.propertyMax) values.propertyMax = format.formatMoneySend(String(values.propertyMax))
    if (values.creditMin) values.creditMin = format.formatMoneySend(String(values.creditMin))
    if (values.creditMax) values.creditMax = format.formatMoneySend(String(values.creditMax))

    setLoading({ ...loading, button: true })

    try {
      const response = await ConfigurationsRepository.updateSimulationLimits(productId, { values })
      swal.basic({ title: 'Sucesso!', text: response.data?.message, icon: 'success' })
      setErrors({})
    } catch (err) {
      if (err.data?.invalid) {
        let errors: ISimulationConfigurationsValuesErrors = {}
        errors = validateResponse(err.data?.invalid)
        setErrors(errors)
        setLoading({ ...loading, button: false })

        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
        return
      }
      setErrors({})
      swal.basic({ title: 'Atenção!', text: err.message, icon: 'warning' })
    } finally {
      setLoading({
        ...loading,
        button: false
      })
    }
  }

  return (

    <SettingsCard>
      <LoadingOrEmpty
        loading={loading.list}
        failRequest={failRequest}
      >

        <SettingsCard.title>Valores da simulação</SettingsCard.title>
        <Form
          form={formRef}
          className="p-4 w-100"
          layout="inline"
          onFinish={onSubmit}
          initialValues={{
            propertyMin: 0,
            propertyMax: 0,
            creditMin: 0,
            creditMax: 0
          }}
        >
          <Row>
            <Col lg={24} sm={24} xs={24} className="pb-2">
              <div className="simulation-configuration__header-title">
                  IMÓVEL
              </div>

              <Form.Item
                name="propertyMin"
                label={<label className="simulation-configuration__value-title">Valor mínimo</label>}
                help={errors.propertyMin && errors.propertyMin}
                validateStatus={errors.propertyMin && 'error'}
              >
                <InputMoney
                  required
                  disabled={SimulationModel.isPropertyMinDisabled(user.level)}
                  placeholder="Digite o limite mínimo"
                  defaultValue={0}
                />
              </Form.Item>
            </Col>

            <Col lg={24} sm={24} xs={24} className="pb-4">
              <Form.Item
                name="propertyMax"
                label={<label className="simulation-configuration__value-title">Valor máximo</label>}
                help={errors.propertyMax && errors.propertyMax}
                validateStatus={errors.propertyMax && 'error'}
              >
                <InputMoney
                  required
                  disabled={SimulationModel.isPropertyMaxDisabled(user.level)}
                  placeholder="Digite o limite máximo"
                  defaultValue={0}
                />
              </Form.Item>
            </Col>
            <Col lg={24} sm={24} xs={24} className="pb-2">
              <div className="simulation-configuration__header-title">
                  CRÉDITO
              </div>

              <Form.Item
                name="creditMin"
                label={<label className="simulation-configuration__value-title" >Valor mínimo</label>}
                help={errors.creditMin && errors.creditMin}
                validateStatus={errors.creditMin && 'error'}
              >
                <InputMoney
                  required
                  disabled={SimulationModel.isCreditMinDisabled(user.level)}
                  placeholder="Digite o limite mínimo"
                  defaultValue={0}
                />
              </Form.Item>
            </Col>

            <Col lg={24} sm={24} xs={24} className="pb-2">
              <Form.Item
                name="creditMax"
                label={<label className="simulation-configuration__value-title" >Valor máximo</label>}
                help={errors.creditMax && errors.creditMax}
                validateStatus={errors.creditMax && 'error'}
              >
                <InputMoney
                  required
                  disabled={SimulationModel.isCreditMaxDisabled(user.level)}
                  placeholder="Digite o limite máximo"
                  defaultValue={0}
                />
              </Form.Item>
            </Col>
            <SettingsCard.ButtonsContainer>
              <SettingsCard.submitButton
                loading={loading.button}
                className="mt-4 text-center w-100 uppercase button-text"
                disabled={SimulationModel.canSubmitSimulationConfiguration(user.level)}
              >
                  Salvar
              </SettingsCard.submitButton>
            </SettingsCard.ButtonsContainer>
          </Row>
        </Form>

      </LoadingOrEmpty>
    </SettingsCard>

  )
}

export default SimulationConfigurationForm

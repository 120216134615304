import React, { Fragment } from 'react'
import { Typography, Popover, Tag, Button } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { timeAsDayjs } from 'utils/time'
import translate from 'utils/translate'
import format from 'utils/format'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import masks from 'utils/masks'
import { useResources } from 'hooks'
import swal from 'utils/swal'
import { IAreas } from '../../allInvitesInterfaces'

const allInvitesColumns: ColumnsType<Object> = [
  {
    title: 'Link',
    dataIndex: '_id',
    render: (_, row: any) => {
      const { _id } = row
      const initialUrl = (document.location.href).split('/auth')
      const finalUrl = initialUrl[0] + '/signup?inviteToken=' + _id
      return (
        <Popover
          placement="right"
          content={
            <Fragment>
              <Typography.Link
                className="link-style color-tertiary font-500 font-size-12"
                copyable
                href={finalUrl}
                target="_blank"
              >
                {finalUrl}
              </Typography.Link>
            </Fragment>
          }
        >
          <Typography.Link className="link-style color-tertiary font-500">Compartilhar Link</Typography.Link>
        </Popover>
      )
    }
  },
  {
    title: 'Criador',
    dataIndex: 'inviterName',
    render: (inviterName: string) => {
      return <p className="capitalize">{inviterName}</p>
    }
  },
  {
    title: 'Data de criação',
    dataIndex: 'createdAt'
  },
  {
    title: 'Nome convidado',
    dataIndex: 'name',
    sorter: true,
    render: (name: string) => {
      if (!name) return '-'
      return format.capitalize(name)
    }
  },
  {
    title: 'Nível de acesso',
    dataIndex: 'level',
    sorter: true,
    render: (level: string, row: any) => {
      const { areaName, promoterType } = row
      const finalText = translate.level(level)

      if (!level) return '-'
      if (areaName) return finalText + ' - ' + format.capitalize(areaName)
      if (level === 'promoter' && promoterType) return finalText + ' - ' + translate.promoterType(promoterType)
      return format.capitalize(finalText)
    }
  },
  {
    title: 'Documentação',
    dataIndex: 'docFree',
    sorter: true,
    render: (item) => {
      if (!item) return 'Não isento'
      return 'Isento'
    }
  },
  {
    title: 'Usuário cadastrado',
    dataIndex: 'user',
    sorter: true,
    render: (item, row: any) => {
      const { createdAt } = row
      const beforeDate = dayjs('09/13/2021')

      function formatCreatedAt () {
        const splitCreated = createdAt.split('/')
        const findYear = splitCreated.filter((item: any) => item.length !== 2)
        const year = findYear.toString().split(' ')
        const newCreatedAt = splitCreated[1] + '/' + splitCreated[0] + '/' + year[0]
        return dayjs(newCreatedAt)
      }

      if (!item) {
        if (formatCreatedAt().isBefore(beforeDate)) return <Tag className='uppercase'>Não há informações</Tag>
        return <Tag className='uppercase'>Nenhum usuário cadastrado</Tag>
      }

      function formatCpfCnpj () {
        if (item.cpf) return masks(item.cpf, 'cpf')
        if (item.cnpj) return masks(item.cnpj, 'cnpj')
        return '-'
      }

      return (
        <Button
          type="link"
          className="p-0 color-tertiary font-500 font-size-12 uppercase"
          onClick={() => {
            Swal.fire({
              title: 'Informações do usuário',
              html: `
              <b>Nome</b>: ${item.name}
              <br/><br/>
              <b>Email</b>: ${item.email}
              <br/><br/>
              <b>CPF/CNPJ</b>: ${formatCpfCnpj()}
              <br/><br/>
              <b>Celular</b>: ${masks(item.cellphone, 'phone', true)}
              `
            })
          }}
        >
          Ver Mais Informações
        </Button>
      )
    }
  },
  {
    title: 'Áreas',
    dataIndex: 'areas',
    sorter: true,
    render: (areas: string[]) => {
      const resources = useResources()
      const tempText: Array<string> = []

      if (!areas || areas.length < 1) return '-'
      if (areas) {
        areas.forEach((item: string) => {
          const findArea = resources.areas.find((element: IAreas) => element.value === item)
          if (findArea) tempText.push(findArea.label)
        })
      }

      return (
        <Button
          type="link"
          className="p-0 color-tertiary font-500 font-size-12"
          onClick={() => {
            swal.basic({ title: 'Áreas', text: (format.separeteValuesByComma((tempText as any)) as string) })
          }}
        >
          ÁREAS
        </Button>
      )
    }
  },
  {
    title: 'Expiração',
    dataIndex: 'expiresIn',
    sorter: true,
    render: (dateString: string) => {
      const date = new Date(dateString)
      const diffTime = timeAsDayjs(date).diff(timeAsDayjs(dayjs(), { applyTimezone: true }))
      if (!date || !timeAsDayjs(date).isValid()) return '-'
      if (diffTime < 0) return 'Link expirado'
      return timeAsDayjs(date).format('DD/MM HH:mm')
    }
  }
]

export default allInvitesColumns

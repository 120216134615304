import { AxiosRequestConfig } from 'axios'
import { _userLevel } from 'ecp/models/UsersModel'
import { IPaginate, Repository } from 'ecp/repositories/Repository'
import { IOperationalLimitValues } from 'egi/app/OperationalLimit/operationalLimitInterfaces'
import { ISimulationConfigurationsValues } from 'egi/app/Settings/views/SimulationConfiguration/simulationConfigurationInterfaces'
import { ISLAList } from 'egi/app/Settings/views/SLAConfig/slaInterfaces'
import api from 'services/api'

export interface ILogs {
  _id: string
  createdAt: string
  last: {
    populationLimit: number
  }
  modifiedBy: {
    email: string
    name: string,
    level: _userLevel
  }
  new: {
    [key: string] : string | number
  }
  updatedAt: string
}

class ConfigurationsRepository extends Repository {
  // SIMULATION VALUES CONFIGURATIONS
  async getSimulationLimits (productId: string) {
    return Repository.handle<{ values: ISimulationConfigurationsValues }>(() =>
      this.api.get(`${this.path}/${productId}/simulation/values`)
    )
  }

  async updateSimulationLimits (productId: string, data: { values: ISimulationConfigurationsValues }) {
    return Repository.handle(() =>
      this.api.patch(`${this.path}/${productId}/simulation/values`, data)
    )
  }

  // SLA CONFIGURATIONS
  async getSLAValues (productId: string) {
    return Repository.handle<{ configurations: ISLAList }>(() =>
      this.api.get(`${this.path}/${productId}/sla`)
    )
  }

  async updateSLAValues (productId: string, data: ISLAList) {
    return Repository.handle(() =>
      this.api.put(`${this.path}/${productId}/sla`, data)
    )
  }

  // POPULATION LIMIT CONFIGURATIONS
  async getPopulationLimit (productId: string) {
    return Repository.handle<{ populationLimit: number }>(() =>
      this.api.get(`${this.path}/${productId}/population`)
    )
  }

  async updatePopulationLimit (productId: string, data: { populationLimit: string }) {
    return Repository.handle(() =>
      this.api.patch(`${this.path}/${productId}/population`, data)
    )
  }

  async getPopulationLimitHistory (productId: string, config?: AxiosRequestConfig) {
    return Repository.handle<IPaginate<ILogs>>(() =>
      this.api.get(`${this.path}/${productId}/population/history`, config)
    )
  }

  // CREDIT OPERATIONAL LIMIT CONFIGURATIONS
  async getCreditOperationalLimit (productId: string) {
    return Repository.handle<IOperationalLimitValues>(() =>
      this.api.get(`${this.path}/${productId}/credit`)
    )
  }

  async updateCreditOperationalLimit (productId: string, data: IOperationalLimitValues) {
    return Repository.handle(() =>
      this.api.patch(`${this.path}/${productId}/credit/conditional`, data)
    )
  }
}

export default new ConfigurationsRepository({ api, path: '/configurations' })

import { Button, Col, Form, InputNumber, Row, Skeleton } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import Datepicker from 'components/Datepicker/Datepicker'
import ErrorDetails from 'components/ErrorDetails/ErrorDetails'
import { IErrorDetails } from 'components/ErrorDetails/interfaces'
import { InputMoney, InputPercentage } from 'components/IntlInput/IntlInput'
import { swalError } from 'components/SwalError/SwalError'
import ProposalReview from 'ecp/app/Proposals/components/ProposalReview/ProposalReview'
import useDynamicProposal from 'ecp/app/Proposals/hooks/useDynamicProposal'
import useProposalDrawer from 'ecp/app/Proposals/hooks/useProposalDrawer'
import useThisStep from 'ecp/app/Proposals/hooks/useThisStep'
import { formatToFillCipForm } from 'ecp/app/Proposals/proposalFunctions'
import { ICepFormErrors, ICipForm, IReviewCipForm } from 'ecp/app/Proposals/proposalInterfaces'
import BankSelect from 'ecp/components/BankSelect/BankSelect'
import { EcpCard } from 'ecp/components/EcpCard/EcpCard'
import SkeletonInput from 'ecp/components/SkeletonInput/SkeletonInput'
import ProposalModel from 'ecp/models/ProposalModel'
import ProposalRepository from 'ecp/repositories/ProposalRepository'
import React, { ReactNode, useEffect, useState } from 'react'
import { correctTimezone } from 'utils/time'
import { handleOnidataErrors } from 'utils/validate'
const CommomCol = ({ children }: { children: ReactNode }) => <Col xs={24} md={12} lg={6}>{children}</Col>

type IProps = {
  close(): void
}

export default function CipForm ({ close }: IProps) {
  const { department, step, statusLabel, lastStep } = useThisStep()
  const { details } = useDynamicProposal()
  const { sendStep } = useProposalDrawer({ department, step })
  const [form] = useForm<IReviewCipForm>()

  const [submitLoading, setSubmitLoading] = useState(false)
  const [errors, setErrors] = useState<ICepFormErrors>({})
  const [error, setError] = useState<IErrorDetails>()
  const [editorValue, setEditorValue] = useState('')
  const [loading, setLoading] = useState(false)
  const proposalId = details?.value._id

  async function getCipInfo (proposalId: string) {
    setLoading(true)
    try {
      const response = await ProposalRepository.getAction<IReviewCipForm>({
        proposalId,
        departmentId: department._id,
        stepId: step._id,
        signed: ProposalModel.canSeeSignedContrat(statusLabel, lastStep.slug)
      })

      const actions = response?.data?.data?.actions
      if (!actions) return undefined
      const formatedValues = formatToFillCipForm(actions)
      form.setFieldsValue(formatedValues)
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (details?.value) getCipInfo(details.value._id)
  }, [details])

  const onSubmit = async (cip: IReviewCipForm) => {
    const cipFormat = (cip: IReviewCipForm) => ({
      ...cip,
      ultimo_vencimento: correctTimezone(cip.ultimo_vencimento.toString()),
      data_do_saldo: correctTimezone(cip.data_do_saldo.toString()),
      status: undefined
    })

    const body = {
      status: cip.status,
      parameters: cipFormat(cip),
      description: editorValue
    }

    try {
      setSubmitLoading(true)
      if (!proposalId) throw new Error('id da proposta não encontrado')
      await sendStep<ICipForm>(body, proposalId)
      close()
    } catch (err) {
      if (err.data?.error) setErrors(handleOnidataErrors(err.data.error))
      swalError({ title: 'Atenção', err, icon: 'warning' })
    } finally {
      setSubmitLoading(false)
    }
  }

  if (error) {
    return (
      <EcpCard>
        <ErrorDetails title="Ops, parece que algo deu errado" subTitle={error.message} error={error} status='error'/>
      </EcpCard>
    )
  }

  return (
    <Form
      onFinish={onSubmit}
      form={form}
      layout='vertical'
    >
      <Row gutter={[15, 15]}>
        <CommomCol>
          <Form.Item
            name="banco"
            label={<label>Banco</label>}
            help={errors?.banco}
            validateStatus={errors?.banco && 'error'}
          >
            {loading
              ? <SkeletonInput/>
              : <BankSelect disabled={loading}/>
            }

          </Form.Item>
        </CommomCol>

        <CommomCol>
          <Form.Item
            name="num_contrato"
            label={<label>Número do contrato</label>}
            help={errors?.num_contrato}
            validateStatus={errors?.num_contrato && 'error'}
          >
            {loading
              ? <SkeletonInput/>
              : <InputNumber className='w-100' min={0} placeholder='Digite o número do contrato' />
            }
          </Form.Item>
        </CommomCol>

        <CommomCol>
          <Form.Item
            name="ultimo_vencimento"
            label={<label>Último vencimento</label>}
            help={errors?.ultimo_vencimento}
            validateStatus={errors?.ultimo_vencimento && 'error'}
          >
            {loading
              ? <SkeletonInput/>
              : <Datepicker placeholder="DD/MM/YYYY" />
            }
          </Form.Item>
        </CommomCol>

        <CommomCol>
          <Form.Item
            name="saldo_devedor"
            label={<label>Saldo devedor</label>}
            help={errors?.saldo_devedor}
            validateStatus={errors?.saldo_devedor && 'error'}
          >
            {loading
              ? <SkeletonInput/>
              : <InputMoney placeholder='Digite o saldo devedor' />
            }
          </Form.Item>
        </CommomCol>

        <CommomCol>
          <Form.Item
            name="data_do_saldo"
            label={<label>Data do saldo</label>}
            help={errors?.data_do_saldo}
            validateStatus={errors?.data_do_saldo && 'error'}
          >
            {loading
              ? <SkeletonInput/>
              : <Datepicker placeholder="DD/MM/YYYY" />
            }
          </Form.Item>
        </CommomCol>

        <CommomCol>
          <Form.Item
            name="prazo"
            label={<label>Total de parcelas</label>}
            help={errors?.prazo}
            validateStatus={errors?.prazo && 'error'}
          >
            {loading
              ? <SkeletonInput/>
              : <InputNumber disabled={loading} className='w-100' min={0} placeholder='Digite o total de parcelas' />
            }
          </Form.Item>
        </CommomCol>

        <CommomCol>
          <Form.Item
            name="qntde_parcelas_pagas"
            label={<label>Parcelas pagas</label>}
            help={errors?.qntde_parcelas_pagas}
            validateStatus={errors?.qntde_parcelas_pagas && 'error'}
          >
            {loading
              ? <SkeletonInput/>
              : <InputNumber className='w-100' min={0} placeholder='Digite a parcelas pagas' />
            }
          </Form.Item>
        </CommomCol>

        <CommomCol>
          <Form.Item
            name="valor_recebivel"
            label={<label>Valor da parcela CIP</label>}
            help={errors?.valor_recebivel}
            validateStatus={errors?.valor_recebivel && 'error'}
          >
            {loading
              ? <SkeletonInput/>
              : <InputMoney placeholder='Digite o valor da parcela CIP' />
            }
          </Form.Item>
        </CommomCol>

        <CommomCol>
          <Form.Item
            name="valor_recebivel_financeira"
            label={<label>Valor da parcela calculada</label>}
            help={errors?.valor_recebivel_financeira}
            validateStatus={errors?.valor_recebivel_financeira && 'error'}
          >
            {loading
              ? <SkeletonInput/>
              : <InputMoney placeholder='Digite o valor da parcela calculada' />
            }
          </Form.Item>
        </CommomCol>

        <CommomCol>
          <Form.Item
            name="taxa_contrato_mes"
            label={<label>Taxa a.m</label>}
            help={errors?.taxa_contrato_mes}
            validateStatus={errors?.taxa_contrato_mes && 'error'}
          >
            {loading
              ? <SkeletonInput/>
              : <InputPercentage placeholder='Digite a Taxa a.m' />
            }
          </Form.Item>
        </CommomCol>

        <CommomCol>
          <Form.Item
            name="taxa_cet_mes"
            label={<label>CET a.m</label>}
            help={errors?.taxa_cet_mes}
            validateStatus={errors?.taxa_cet_mes && 'error'}
          >
            {loading
              ? <SkeletonInput/>
              : <InputPercentage placeholder='Digite a CET a.m' />
            }
          </Form.Item>
        </CommomCol>
      </Row>

      {loading
        ? <Skeleton active />
        : (
          <ProposalReview
            clientName={details?.value?.client?.name}
            form={form}
            step={step}
            errors={{}}
            editorValue={editorValue}
            onEditorChange={(value: string) => {
              setEditorValue(value)
            }}
          />

        )
      }

      <Button
        htmlType='submit'
        loading={submitLoading}
        className='mt-3'
        type='primary'
        disabled={loading}
      >
        Enviar
      </Button>
    </Form>
  )
}

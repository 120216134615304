import React from 'react'
import dg_uml from './DG_uml.png'

function Esteiradeparceiros () {
  return (
    <>
      <hr />
      <h2>1. Parceiro.</h2>
      <h3>1.1. O que é um parceiro.</h3>
      <p>
        Na visão da plataforma, um usuário parceiro é um ator com o objetivo de adicionar novos clientes,
        permeando análise de crédito, na plataforma. Além disso o parceiro pode adicionar os dados de cadastro
        do cliente e enviar links de cadastro para ele, mas não é o parceiro quem aprova o crédito, ele apenas faz
        um cadastro de cliente para análise de crédito.
        Esse ator parceiro na visão do banco é uma empresa que faz empréstimos a seus clientes, se for EGI é para
        empréstimo de garantia de imóvel, se for ECP é empréstimo consignado privado.
        Seu ingresso na plataforma inicia-se por um convite feito pelo jurídico ou pelo usuário Master da
        plataforma, sendo que esse parceiro terá um canal de parceria que pode ser: New Qualy, Parceria,
        associações e digital.
        Após ter sido convidado o parceiro fará um cadastro inicial, enviará suas documentações e o jurídico ou o
        Master poderá aprovar seu ingresso.
      </p>
      <h3>1.2. Tipos de parceiro.</h3>
      <p>Existe Três tipos de parceiros:</p>
      <h3>1.2.1. Parceiro Corban.</h3>
      <p>
        O parceiro corban pode analisar as propostas por ele simulada, faz a verificação do andamento das
        propostas, cadastra e envia links de cadastro para novos clientes, também pode fazer simulações de
        propostas e convidar novos colaboradores ao sistema. Os colaboradores por ele convidados serão
        cadastrados dentro do seu tipo de parceria.
      </p>
      <h3>1.1.1. Parceiro Indicação.</h3>
      <p>
        O parceiro indicação pode analisar as propostas por ele simulada, faz a verificação do andamento das
        propostas, cadastra e envia links de leads para novos clientes, também pode fazer simulações de propostas
        e convidar novos colaboradores ao sistema. Os colaboradores por ele convidados serão cadastrados dentro
        do seu tipo de parceria.
        Diferente do ator corban, o indicado poderá gerar leads campanhas e faz o convite de vendedores ao
        sistema.
      </p>
      <h3>1.1.2. Parceiro Marketplace.</h3>
      <p>
        Um parceiro Market Place tem menos funcionários podendo apenas verificar propostas e adicionar clientes
        e colaboradores.
      </p>
      <h3>1.2. Colaborador.</h3>
Um parceiro colaborador é de um tipo de ator diferente no sistema, ele entra em cena apenas quando é
convidado ao sistema, exerce as mesmas funções de quem o convidou, com exceção de convidar novos
colaboradores.
      <h3>1.3. Canais do parceiro.</h3>
      <p>
        Os parceiros poderão ser subdivididos em canais que servirão para controlar a produção, sem diferenciação
        de funcionalidade por classificação. A classificação deverá ser informada manualmente pelo time comercial
        no momento do envio do invite, juntamente com a classificação do tipo (Corban/ Parceiro-Indicador).
        Exatamente como funciona a seleção do tipo do parceiro. Os tipos de classificação de parceiros são New
        Qualy, Parceria, Associações e Digital.
      </p>
      <h2>2. Diagrama de casos de uso.</h2>
      <h5>Figura 1 Diagrama Uml do parceiro</h5>
      <img src={dg_uml} alt="#" />

      <h2>3. Requisitos funcionais do sistema</h2>
      <h3>RF1 Colaboradores</h3>
      <p>O parceiro poderá visualizar os colaboradores por ele convidado.</p>
      <h3>RF2 Convidar colaborador</h3>
      <p>O parceiro pode gerar um link de convite de novos colaboradores.</p>
      <h3>RF3 Bloquear colaborador</h3>
      <p>O parceiro pode bloquear o colaborador por ele convidado.</p>
      <h3>RF4 Sua documentação</h3>
      <p>O parceiro pode verificar a documentação que ele fez envio antes de ingressar na plataforma.</p>
      <h3>RF5 Notificações</h3>
      <p>O parceiro receberá notificações pertinentes a sua função.</p>
      <h3>RF6 Visão geral</h3>
      <p>Em visão geral o parceiro tem uma visão geral de suas tarefas, como o total de propostas, o andamento da
proposta e se ela foi cancelada ou aprovada, permeando as visões de ECP e EGI.</p>
      <h3>RF7 Propostas</h3>
      <p>Em propostas o parceiro tem uma visão de uma tabela as propostas que ele é responsável.</p>
      <h3>RF8 Contatar cliente</h3>
      <p>O parceiro poderá contatar o cliente via e-mail, WhatsApp ou ligação.</p>
      <h3>RF9 Gerar relatório proposta</h3>
      <p>O parceiro poderá exportar um arquivo no formato CSV das propostas.</p>
      <h3>RF10 Verificar proposta</h3>
      <p>
        O parceiro pode ver o andamento da proposta, qual foi o último departamento, verificar o status da
        proposta, etc.
      </p>
      <h3>RF11 Leads</h3>
      <p>O parceiro indicação poderá ver os leads gerados</p>
      <h3>RF12 Gerar relatório de leads</h3>
      <p>O parceiro indicação poderá exportar um arquivo no formato CSV dos leads</p>
      <h3>RF13 Indicadores</h3>
      <p>
        O parceiro indicação pode visualizar uma lista com os vendedores por ele ou seu colaborador convidado a
        ingressar na plataforma.
      </p>
      <h3>RF14 Convidar vendedor de leads</h3>
      <p>O parceiro indicador pode convidar novos vendedores para a plataforma.</p>
      <h3>RF15 Bloquear vendedor de leads</h3>
      <p>
        Caso exista necessidade o parceiro indicação poderá bloquear um vendedor, não permitindo que ele acesse
        o sistema.
      </p>
      <h3>RF16 Campanha</h3>
      <p>O parceiro indicação pode visualizar uma lista com as campanhas existentes</p>
      <h3>RF17 Novo link de campanha</h3>
      <p>O parceiro indicação consegue adicionar novos link de campanhas ao sistema.</p>
      <h3>RF18 Divulgação</h3>
      <p>O Corban e o parceiro de indicação conseguem verificar as divulgações existentes no sistema.</p>
      <h3>RF19 Criar nova divulgação</h3>
      <p>
        O Corban e o parceiro de indicação podem criar novas campanhas de divulgações e deixar salvos no
        sistema.
      </p>
      <h3>RF20 Chat</h3>
      <p>
        O Corban e o parceiro indicação podem enviar uma mensagem ao time de suporte da Rodobens direto da
        plataforma
      </p>
      <h3>RF21 Clientes</h3>
      <p>O Corban e o Marketplace conseguem verificar os clientes existentes no sistema.</p>
      <h3>RF22 Adicionar Cliente</h3>
      <p>O Corban e o Marketplace podem adicionar novos clientes ao sistema.</p>
      <h3>RF23 Adicionar Dados</h3>
      <p>
        O Corban Marketplace, após terem adicionado o cliente ao sistema podem adicionar a documentação do
        cliente para seguir com a proposta.
      </p>
      <h2>4. Requisitos não funcionais</h2>
      <h2>4.1. Regras de negócio</h2>
      <h3>4.1.1. Deliberação</h3>
      <p>
        Na esteira de propostas o time de formalização, crédito e usuários acima do seu nivel poderão REFAZER,
        APROVAR ou REPROVAR a documentação dos clientes, dentro de cada uma de suas propostas.
        No caso de um parceiro ele poderá apenas mandar refazer uma proposta:
        <br />
  Time de formalização poderá deliberar: <br />
      </p>
      <p>
        Complete seu cadastro. <br />
  Dados do imóvel. <br />
  Comprovante de endereço. <br />
  Time de crédito poderá deliberar:<br />
  Renda. <br />
  Holerite. <br />
  Assinatura Bacen. <br />
  Assinatura LGPD. <br />
      </p>

      <strong>Nota*.:</strong><br />
      <p>
        No caso das assinaturas elas serão aprovadas automaticamente após a assinatura do cliente da
        proposta.
        Time parceiro poderá deliberar:
        Complete seu cadastro.
        Dados do imóvel.
        Comprovante de endereço.
        Caso o time de crédito delibere todos os seus documentos de crédito, o time parceiro não poderá deliberar
        os passos citados para ele.
      </p>
      <h3>4.1.2 Filtro de ação</h3>
      <p>
        Os filtros de ação serão utilizados para separar as propostas conforme as ações de deliberação dos atores,
        verificar o capítulo 4.1.1. O parceiro não necessita deste filtro.
        Caso o atuante da proposta faça todas as suas ações o filtro ação proposta deverá anular a visão da
        proposta
      </p>
    </>
  )
}

export default Esteiradeparceiros

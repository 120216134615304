import dayjs, { ConfigType, Dayjs } from 'dayjs'
import dayjsBR from 'dayjs/locale/pt-br'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isToday from 'dayjs/plugin/isToday'
import { MultiDatepickerType } from 'components/MultiDatepicker/MultDatePicker'
const serverTz = 'America/Sao_Paulo'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isToday)
dayjs.locale(dayjsBR)

const time = (value: ConfigType = new Date(), options?: {server?: boolean, applyTimezone?: boolean}): Date => {
  const server = options?.server
  const applyTimezone = options?.applyTimezone
  let aux: Dayjs

  if (!(applyTimezone ?? true)) aux = dayjs.tz(value, 'GMT') // Set applyTimezone to false when timezone is applied incorrectly (usually when working with JSDate and timestamps)
  else if (server ?? false) aux = dayjs.tz(value, serverTz)
  else aux = dayjs.tz(value, dayjs.tz.guess())

  return new Date(Date.UTC(aux.year(), aux.month(), aux.date(), aux.hour(), aux.minute(), aux.second(), aux.millisecond()))
}

const timeAsDayjs = (value: ConfigType = new Date(), options?: {server?: boolean, applyTimezone?: boolean}): Dayjs => {
  const server = options?.server
  const applyTimezone = options?.applyTimezone

  let timezone = dayjs.tz.guess()

  if (!(applyTimezone ?? true)) timezone = 'GMT'
  else if (server ?? false) timezone = serverTz

  return dayjs.tz(value, timezone)
}

const makeMonthPeriod = ({ year, month }:{year: number, month: number}): {firstDay: Date; lastDay: Date} => {
  const firstDay = new Date(year, month - 1, 1)
  const lastDay = new Date(year, month, 0)

  return { firstDay, lastDay }
}

const correctTimezone = (date: string): string => {
  if (!date) return ''

  const splittedValue = date.split('/').reverse()
  let final: string = ''

  for (let i = 0; i < splittedValue.length; i++) {
    if (splittedValue.length - 1 === i) final += `${splittedValue[i]}`
    else final += `${splittedValue[i]}-`
  }

  return final
}

export { makeMonthPeriod, timeAsDayjs, correctTimezone }
export default time

export const initialDateRange: [Dayjs, Dayjs] = [dayjs().subtract(Number(dayjs().format('DD')) - 1, 'days'), dayjs()]

export function formatDateByDateType (date: Dayjs, dateType: MultiDatepickerType) {
  if (dateType === 'month') return timeAsDayjs(date).format('MM-YYYY')
  if (dateType === 'year') return timeAsDayjs(date).format('YYYY')
  return timeAsDayjs(date).format('DD-MM-YYYY')
}

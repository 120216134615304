import React, { useEffect, useState } from 'react'
import { Button, Tooltip, Row } from 'antd'
import { LoadingOutlined, PrinterOutlined } from '@ant-design/icons'
import StringToHtml from 'components/StringToHtml/StringToHtml'
import ReactDOM from 'react-dom'
import { FormInstance } from 'antd/lib/form'
import usePartnerStep from '../../../../hooks/usePartnerStep'
import usePartnerDepartments from '../../../../hooks/usePartnerDepartments'
import usePartnerDocumentation from '../../../../hooks/usePartnerDocumentation'
import PartnersRepository from 'egi/repositories/PartnersRepository'
import { PdfPreview } from 'components'

function PartnerContract ({ partnerId, formRef }: { partnerId: string, formRef: FormInstance }) {
  const [loading, setLoading] = useState<boolean>(false)
  const [contract, setContract] = useState<string>('')

  const { step } = usePartnerStep()
  const { slug: departmentSlug } = usePartnerDepartments()
  const { _id: documentId } = usePartnerDocumentation()

  const resetFields = () => {
    formRef.resetFields()
  }

  async function getPreview () {
    try {
      setLoading(true)
      const response: any = await PartnersRepository.finalContract(documentId, departmentSlug, step.slug)

      setContract(response.data.data.contract)
      setLoading(false)
    } catch (err) {
      console.error(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (step.signatures && step.signatures[0] && step.signatures[0].contract) {
      const contract = step.signatures[0].contract
      setContract(contract.signed)
    } else {
      getPreview()
    }
    resetFields()
  }, [partnerId, step])

  useEffect(() => {
    if (step.signatures && step.signatures[0] && step.signatures[0].contract) {
      const signature = step.signatures[0]
      const signatureInput = document.getElementById('signatureInput')
      const signatureCanvas = (<img src={signature.sign} />)
      if (signatureInput) {
        ReactDOM.render(signatureCanvas, signatureInput)
      }
    }
  }, [])

  async function printContract () {
    const width = window.innerWidth
    const height = window.innerHeight
    const page = await window.open('', '_blank', `channelmode=yes,resizable=yes,width=${width},height=${height}`)
    if (page) {
      const contractHtml = document.getElementById('contract')?.innerHTML
      if (contractHtml) {
        page.document.body.innerHTML = contractHtml
        setTimeout(() => {
          page.window.print()
          page.window.onafterprint = () => page.window.close()
        }, 500)
      }
    }
  }

  return (
    <div className="final-contract">
      {loading
        ? <Row
          className="context"
          justify="center"
        >
          <LoadingOutlined />
        </Row>
        : <>
          {step.files?.length ? (
            <PdfPreview src={step.files[0].file} height='750px' />
          ) : <StringToHtml value={contract} />}
        </>
      }

      <div className="final-contract__container-signature">
        <Row className="gap-2">

          <Tooltip title="Imprimir" destroyTooltipOnHide={{ keepParent: false }}>
            <Button
              onClick={() => printContract()}
              icon={<PrinterOutlined />}
              className="m-0"
              type="ghost"
            >
              Imprimir
            </Button>
          </Tooltip>
        </Row>
      </div>
    </div>
  )
}

export default PartnerContract

import React from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'

function TooltipAntinupcial () {
  return (
    <label>
      Ajuda: {' '}
      <Tooltip
        color="white"
        placement="right"
        destroyTooltipOnHide={{ keepParent: false }}
        title={
          <table className="table-form-user-data w-100">
            <thead>
              <th>Regime de bens</th>
              <th>Pacto</th>
              <th>Comunicação dos bens</th>
            </thead>

            <tbody>
              <tr>
                <td>Universal/Total de bens</td>
                <td>Para casamentos realizados após 26/12/1997</td>
                <td>Há comunicação dos bens, sempre em partes iguais. (Exceção: doação com clásula de incomunilidade).</td>
              </tr>

              <tr>
                <td>Parcial dos bens</td>
                <td>Para casamentos realizados antes 26/12/1997</td>
                <td>Há comunicação de todos os bens adquiridos após o casamento.</td>
              </tr>
            </tbody>
          </table>
        }
      >
        <InfoCircleOutlined className="simulation-info" style={{ color: '#00441F' }}/>
      </Tooltip>
    </label>
  )
}

export default TooltipAntinupcial

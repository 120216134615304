import { ICondicao, ProposalType } from 'ecpf/app/ProposalECPF/ProposalECPFInterfaces'
import { useDispatch, useSelector } from 'react-redux'
import { IPortabilitySimulationFlow, IReduxProposalFlow, ISelectContractsFlow, proposalFlowActions } from 'store/modules/proposalFlow/actions'
import { RootState } from 'store/modules/rootReducer'

export const useProposalFlow = () => {
  const proposalFlow = useSelector((state: RootState) => state.proposalFlow)
  const dispatch = useDispatch()

  const updateCustomerFlow = (customerFlow: IReduxProposalFlow['customerFlow']) => {
    dispatch(proposalFlowActions.setCustomerFlow(customerFlow))
  }

  const updateClientCovenant = (clientCovenant: IReduxProposalFlow['clientCovenant']) => {
    dispatch(proposalFlowActions.setClientCovenant(clientCovenant))
  }

  const updateSimulationFlow = (simulationFlow: IReduxProposalFlow['simulationFlow']) => {
    dispatch(proposalFlowActions.setSimulationFlow(simulationFlow))
  }

  const updateSimulationFlowMinMax = (simulationFlowMinMax: IReduxProposalFlow['simulationFlowMinMax']) => {
    dispatch(proposalFlowActions.setSimulationFlowMinMax(simulationFlowMinMax))
  }

  const updateClient = (client: IReduxProposalFlow['client']) => {
    dispatch(proposalFlowActions.setClient(client))
  }

  const updatePortabilitySimulation = (portabilitySimulationFlow: IPortabilitySimulationFlow) => {
    dispatch(proposalFlowActions.setPortabilitySimulationFlow(portabilitySimulationFlow))
  }

  const updateSelectContractsFlow = (selectContractsFlow: ISelectContractsFlow) => {
    dispatch(proposalFlowActions.setSelectContractsFlow(selectContractsFlow))
  }

  const updateExtraCustomerFlow = (extraCustomerData: IReduxProposalFlow['extraCustomerFlow']) => {
    dispatch(proposalFlowActions.setExtraCustomerData(extraCustomerData))
  }

  const resetProposalFlow = () => {
    dispatch(proposalFlowActions.resetProposalFlow())
  }

  const resetSimulationFlow = () => {
    dispatch(proposalFlowActions.resetSimulationFlow())
  }

  const resetClientCovenant = () => {
    dispatch(proposalFlowActions.resetClientCovenant())
  }

  const resetSimulationFlowMinMax = () => {
    dispatch(proposalFlowActions.resetSimulationFlowMinMax())
  }

  const updateIsTokenValidInTokenValidationFlowEcpf = (isValid: boolean) => {
    dispatch(proposalFlowActions.setTokenValidationFlowEcpf(isValid))
  }

  const getClientCovenant = (condicoes?: ICondicao[]) => {
    const firstCovenant = proposalFlow?.clientCovenant?.convenios?.[0]

    return condicoes?.find(
      (condicao) => condicao.convenio.codigoConvenio === firstCovenant?.codigo
    ) ?? null
  }

  const getClientCovenantConditional = (proposalType: ProposalType) => {
    if (proposalType === ProposalType.portability) {
      return getClientCovenant(proposalFlow?.portabilitySimulationFlow?.conditionals?.condicoes)
    }

    return getClientCovenant(proposalFlow?.simulationFlow?.condicoes)
  }

  return {
    client: proposalFlow?.client,

    customerFlow: proposalFlow?.customerFlow,
    clientCovenant: proposalFlow?.clientCovenant,
    simulationFlow: proposalFlow?.simulationFlow,
    extraCustomerFlow: proposalFlow?.extraCustomerFlow,
    tokenValidationFlowEcpf: proposalFlow?.tokenValidationFlowEcpf,
    simulationFlowMinMax: proposalFlow?.simulationFlowMinMax,
    portabilitySimulationFlow: proposalFlow?.portabilitySimulationFlow,
    selectContractsFlow: proposalFlow?.selectContractsFlow,

    getClientCovenantConditional,
    getClientCovenant,
    updateIsTokenValidInTokenValidationFlowEcpf,
    updateSimulationFlowMinMax,
    updateCustomerFlow,
    updateClientCovenant,
    updateSimulationFlow,
    updateClient,
    updateExtraCustomerFlow,
    resetProposalFlow,
    resetSimulationFlow,
    resetSimulationFlowMinMax,
    resetClientCovenant,
    updatePortabilitySimulation,
    updateSelectContractsFlow
  }
}

import React, { useEffect } from 'react'
import { ColumnType } from 'antd/lib/table'
import { useAuth } from 'hooks'
import mask from 'utils/masks'
import { IProposal } from 'egi/app/ProposalEgi/proposalEgiInterfaces'

const COMMUN_COLUMNS: {[key:string] : ColumnType<IProposal>} = {
  proposalNumber: {
    dataIndex: 'NUMERO_DA_PROPOSTA',
    title: 'ID'
  },
  proposalId: {
    dataIndex: 'ID_PROPOSTA',
    title: 'ID'
  },
  clientName: {
    dataIndex: 'NOME_CLIENTE',
    title: 'Nome'
  },
  clientCPF: {
    dataIndex: 'CPF_CLIENTE',
    title: 'CPF',
    render: (cpf: string) => {
      if (!cpf) return 'N/A'
      return mask(String(cpf), 'cpf', true)
    }
  },
  clientEmail: {
    dataIndex: 'EMAIL_CLIENTE',
    title: 'E-mail'
  },
  clientEmailECP: {
    dataIndex: 'EMAIL',
    title: 'E-mail'
  },
  clientPhone: {
    dataIndex: 'TELEFONE_CLIENTE',
    title: 'Telefone',
    render: (cellphone: string) => {
      if (!cellphone) return 'N/A'
      return mask(String(cellphone), 'cell', true)
    }
  },
  clientPhoneECP: {
    dataIndex: 'NUMERO_TELEFONE',
    title: 'Telefone'
  }
}

function useProposalClientColumns () {
  const auth = useAuth()

  const handleEGIColumns = (onSelect: (proposal: IProposal) => void) => {
    return [
      {
        ...COMMUN_COLUMNS.proposalNumber,
        render: (proposalNumber: string, proposal: IProposal) => {
          return (
            <p
              className="link-style"
              onClick={() => onSelect(proposal)}>
              {proposalNumber}
            </p>
          )
        }

      },
      COMMUN_COLUMNS.clientName,
      COMMUN_COLUMNS.clientCPF,
      COMMUN_COLUMNS.clientEmail,
      COMMUN_COLUMNS.clientPhone
    ]
  }

  const handleECPColumns = (onSelect: (proposal: IProposal) => void) => {
    return [
      {
        ...COMMUN_COLUMNS.proposalId,
        render: (proposalId: string, proposal: IProposal) => {
          return (
            <p
              className="link-style"
              onClick={() => onSelect(proposal)}
            >
              {proposalId}
            </p>
          )
        }

      },
      COMMUN_COLUMNS.clientName,
      COMMUN_COLUMNS.clientCPF,
      COMMUN_COLUMNS.clientEmailECP,
      COMMUN_COLUMNS.clientPhoneECP
    ]
  }

  const buildColumns = (onSelect: (proposal: IProposal) => void) => {
    if (auth.selectedProduct?.slug === 'home-equity') return handleEGIColumns(onSelect)
    if (auth.selectedProduct?.slug === 'ecp') return handleECPColumns(onSelect)
    return []
  }

  useEffect(() => {
  }, [auth.selectedProduct?.slug])

  return {
    columns: (onSelect:(proposal: IProposal) => void) => buildColumns(onSelect)
  }
}

export default useProposalClientColumns

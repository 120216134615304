import { Skeleton } from 'antd'
import React from 'react'

interface IProps {
  className?: string
}

export default function SkeletonInput ({ className = '' }: IProps) {
  return (
    <Skeleton.Input
      active={true}
      size='large'
      className={`${className} w-100 skeleton-input`}
    />
  )
}

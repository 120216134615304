import { Button, Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import Modal from 'antd/lib/modal/Modal'
import ReportsQueueButton from 'ecp/components/ReportsQueueButton/ReportsQueueButton'
import UsersSelect from 'ecp/components/UsersSelect/UsersSelect'
import { useAuth } from 'hooks'
import React, { useState } from 'react'

type Props = {
  visible: boolean
  onCancel: () => void
  onFinish?: () => void
}

export default function BackofficeReportsModal ({ visible, onCancel }: Props) {
  const [form] = useForm()
  const user = useAuth()
  const [selectedLevel, setSelectedlevel] = useState('')

  return (
    <>
      <Modal
        title="Relatório de Backoffices"
        visible={visible}
        destroyOnClose
        onCancel={onCancel}
        footer={null}>
        <Form
          layout="vertical"
          form={form}
        >
          <Form.Item
            label="Escolha o nível de usuário que deseja exportar o relatório"
            name='level'
            rules={[{
              required: true,
              message: 'Campo obrigatório'
            }]}
          >
            <UsersSelect
              placeholder='Escolha um ou mais níveis de usuário... '
              product={user.selectedProduct.slug}
              onChange={(e: string) => {
                setSelectedlevel(e)
              }}
            />
          </Form.Item>

          <div className="flex flex-space-between">
            <Button
              className='mr-2'
              type="ghost"
              onClick={onCancel}
            >
              Cancelar
            </Button>

            <ReportsQueueButton
              filters={{ level: selectedLevel, page: 0, limit: 0 }}
              path={'/access-management-backoffice'}
              onFinish={onCancel}
              disabled={!selectedLevel}
            />
          </div>
        </Form>
      </Modal>
    </>
  )
}

const loadRecaptchaScript = async (sitekey: string, errorCb: Function) => {
  const handleOnload = async () => {
    try {
      return await (window as any).grecaptcha.render('g-recaptcha', { sitekey })
    } catch (err) {
      return err
    }
  }

  const script = document.createElement('script')
  script.src = 'https://www.google.com/recaptcha/api.js?hl=pt-BR'
  script.id = 'GRecaptcha'
  script.async = true
  script.setAttribute('data-testid', 'GRecaptcha')
  script.defer = true
  document.body.appendChild(script)

  script.onload = handleOnload
  script.onerror = () => errorCb(true)
}

const unmountRecaptcha = () => document.getElementById('GRecaptcha')?.remove()

export default loadRecaptchaScript
export { unmountRecaptcha }


import { AxiosRequestConfig } from 'axios'
import { Repository } from 'ecp/repositories/Repository'
import { ISignupValues } from 'egi/app/Signup/signupInterfaces'
import { IAuth } from 'egi/types'

import api from 'services/api'

class SignupECPFRepository extends Repository<{}> {
  async signup (body: FormData | ISignupValues & { nomeResumido?: string }, config?: AxiosRequestConfig) {
    return Repository.handle<IAuth>(() =>
      this.api.post(this.path, body, config)
    )
  }

  async associate (body: FormData | ISignupValues & { nomeResumido?: string }, config?: AxiosRequestConfig) {
    return Repository.handle<IAuth>(() =>
      this.api.post(`${this.path}/associate`, body, config)
    )
  }
}

export default new SignupECPFRepository({ api, path: '/ecp-funcao/signup' })

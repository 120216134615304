import { Checkbox, Form } from 'antd'
import { IError } from 'ecp/app/Proposals/proposalInterfaces'
import React from 'react'

type IProps = {
  setAdhesionCreditInsuranceAgree(value: boolean): void
  setHasBankCreditNoteAgree(value: boolean): void
  setHasinstallmentDeductedFromPayrollAgree(value: boolean): void
  errors: IError<any>
  hasInsurance?: boolean
}

function ContractFormContent ({ setHasBankCreditNoteAgree, setAdhesionCreditInsuranceAgree, setHasinstallmentDeductedFromPayrollAgree, errors, hasInsurance }: IProps) {
  return (
    <div className='contract-form-content'>
      <h2 className='contract-form-content__title'>
        Aceite de termos
      </h2>
      <Form.Item
        name='bankCreditNoteAgre'
        className='mb-0 mt-3'
        help={
          errors.bankCreditNoteAgree && errors.bankCreditNoteAgree
        }
        validateStatus={errors.bankCreditNoteAgree && 'error'}
      >
        <Checkbox
          className='mb-2'
          onChange={value => {
            setHasBankCreditNoteAgree(value.target.checked)
          }}
        >
          Li e concordo com a Cédula de Crédito Bancária
        </Checkbox>
      </Form.Item>

      <Form.Item
        name='installmentDeductedFromPayrollAgree'
        className='mb-0'
        help={
          errors.installmentDeductedFromPayrollAgree &&
              errors.installmentDeductedFromPayrollAgree
        }
        validateStatus={
          errors.installmentDeductedFromPayrollAgree && 'error'
        }
      >
        <Checkbox
          className='ml-0'
          onChange={value => {
            setHasinstallmentDeductedFromPayrollAgree(
              value.target.checked
            )
          }}
        >
          Autorizo que as parcelas sejam descontadas na minha folha de pagamento
        </Checkbox>
      </Form.Item>

      {hasInsurance && <Form.Item
        name='adhesionCreditInsuranceAgree'
        className='mb-0 mt-3'
        help={
          errors.adhesionCreditInsuranceAgree && errors.adhesionCreditInsuranceAgree
        }
        validateStatus={errors.adhesionCreditInsuranceAgree && 'error'}
      >
        <Checkbox
          className='mb-2'
          onChange={value => {
            setAdhesionCreditInsuranceAgree(value.target.checked)
          }}
        >
          Li e concordo com o Termo de Adesão ao Seguro Prestamista
        </Checkbox>
      </Form.Item>
      }

    </div>
  )
}

export default ContractFormContent

import React, { Fragment } from 'react'
import { Steps } from 'antd'

interface ISimulationStep {
  counterStep: number,
  onClick: (value?: number) => void,
  quantity: number,
  cannotClickStepZero?: boolean
}

function SimulatorSteps ({ counterStep, onClick, quantity, cannotClickStepZero }: ISimulationStep) {
  const { Step } = Steps

  return (
    <Steps current={counterStep} className="steps-container">
      {new Array(quantity).fill('').map((_, index) => {
        const disabledSteps = counterStep < index
        const cannotClick = cannotClickStepZero && index === 0
        return (
          <Fragment key={index}>
            <Step
              key={index}
              className={counterStep === index ? 'steps-simulator' : `${disabledSteps ? '' : 'cursor-pointer'}`}
              onClick={() => {
                if (disabledSteps) return
                if (cannotClick) return onClick(1)
                onClick(index)
              }}
              disabled={disabledSteps}
            />
          </Fragment>
        )
      })}
    </Steps>
  )
}

export default SimulatorSteps

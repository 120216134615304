import React, { useEffect, useState } from 'react'
import { Table } from 'components'
import { enhanceServerTable } from 'components/SeverTable/serverTableEnhance'
import { columns } from './dataTable'
import SimulationsRepository from 'egi/repositories/SimulationsRepository'

interface IData {
  createdAt: string
  currentStep : number
  email: string
  preApproval: string
  promoterId?: string
  financing?: number
}

function UserDetailsSimulationTable ({ id } : { id: string}) {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IData[]>([])

  useEffect(() => {
    const abortController = new AbortController()
    async function fetchSimulations (id: string) {
      setLoading(true)
      try {
        const response = await SimulationsRepository.getSimulations(id, {
          signal: abortController.signal
        })
        if (response.data.data.simulations && response.data.data.simulations.docs) {
          const formatedValues = enhanceServerTable(response.data.data.simulations.docs)
          setData(formatedValues)
        }
      } catch (err) {
        console.log(err)
      }
      setLoading(false)
    }
    if (id) fetchSimulations(id)

    return () => abortController.abort()
  }, [id])

  return (
    <Table
      data={data}
      columns={columns}
      loading={loading}
    />
  )
}

export default UserDetailsSimulationTable

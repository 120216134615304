import { Empty, Select } from 'antd'
import { AxiosRequestConfig } from 'axios'
import { ISelectProps } from 'components/Select/Select'
import { ALL_RESOURCE } from 'ecp/app/Proposals/proposalConstants'
import ResourcesRepository, { IResourceWithFantasyName } from 'ecp/repositories/ResourcesRepository'
import React, { useEffect, useState } from 'react'

interface Props extends ISelectProps {
  hasAllResource?: boolean
  corbanId?: string
  subcorbanId?: string
}

export const formatToSelectWithFantasyName = (item: IResourceWithFantasyName) => ({
  value: item._id,
  label: item.fantasyName
})

export default function CovenantSelect ({ hasAllResource = true, onChange, corbanId, subcorbanId, ...rest }: Props) {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [covenants, setCovenants] = useState<{ value: string, label: string }[]>([])
  const [options, setOptions] = useState<{ value: string, label: string }[]>([])

  useEffect(() => {
    const abortController = new AbortController()
    async function fetchCovenants (corbanId?: string, subcorbanId?: string) {
      try {
        setLoading(true)

        const params = { corbanId, subcorbanId }

        const requestConfig: AxiosRequestConfig = {
          signal: abortController.signal,
          params
        }

        const response = await ResourcesRepository.covenants(requestConfig)
        const covenants = response.data.data?.covenants

        if (!covenants?.length) {
          setCovenants([])
          throw new Error('Nenhum convênio encontrado')
        }

        setCovenants((covenants || []).map(formatToSelectWithFantasyName))
        setLoading(false)
      } catch (err) {
        if (!abortController.signal.aborted) {
          setErrorMessage(err?.message)
          setLoading(false)
        }
      }
    }

    fetchCovenants(corbanId, subcorbanId)
    return () => {
      abortController.abort()
    }
  }, [corbanId, subcorbanId])

  useEffect(() => {
    if (hasAllResource) setOptions([ALL_RESOURCE, ...covenants])
    else setOptions(covenants)
  }, [covenants])

  return (
    <Select
      {...rest}
      onChange={onChange}
      disabled={loading}
      allowClear
      showSearch
      optionFilterProp="children"
      loading={loading}
      getPopupContainer={trigger => trigger.parentNode}
      notFoundContent={
        errorMessage && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={errorMessage} />
      }
    >
      {options.map(item => (
        <Select.Option value={item.value} key={item.value + 'step'}>
          {item.label}
        </Select.Option>
      ))}
    </Select>
  )
}

import React from 'react'
import { Col, Row } from 'antd'
import DetailsItem from 'ecp/components/DetailsItem/DetailsItem'
import { IOnidataClient } from 'ecp/models/ClientModel'

function DocumentsFieldsDisplay ({ documentsInfos }: { documentsInfos: IOnidataClient }) {
  return (
    <Row gutter={[25, 25]}>
      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='CPF'
          value={documentsInfos?.cpf ?? '-'}
        />
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='Tipo de documento'
          value={documentsInfos?.documento?.tipo ?? '-'}
        />
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='Número do documento'
          value={documentsInfos?.documento?.numero ?? '-'}
        />
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='Órgão emissor'
          value={documentsInfos?.documento?.emissor ?? '-'}
        />
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='UF do documento'
          value={documentsInfos?.documento?.uf ?? '-'}
        />
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <DetailsItem
          label='Nome da mãe'
          value={documentsInfos?.nome_mae ?? '-'}
        />
      </Col>
    </Row>
  )
}

export default DocumentsFieldsDisplay

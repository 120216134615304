import React from 'react'
import { Form, Radio } from 'antd'

import DrawerFiltersLineCollapsable, { DrawerFiltersLineCollapsableItem } from 'components/DrawerFilters/DrawerFiltersLineCollapsable'

function ExpiredProposalFilter () {
  return (
    <div className='proposal-radio-filter'>
      <DrawerFiltersLineCollapsable label='Propostas expiradas'>
        <Form.Item
          name='expiredProposal'
          initialValue={undefined}
        >
          <Radio.Group>

            <DrawerFiltersLineCollapsableItem key={''}>
              <Radio value={''}>{'Todos'}</Radio>
            </DrawerFiltersLineCollapsableItem>

            <DrawerFiltersLineCollapsableItem key={'expired'}>
              <Radio value={'true'}>{'Sim'}</Radio>
            </DrawerFiltersLineCollapsableItem>

            <DrawerFiltersLineCollapsableItem key={'notExpired'}>
              <Radio value={'false'}>{'Não'}</Radio>
            </DrawerFiltersLineCollapsableItem>

          </Radio.Group>
        </Form.Item>
      </DrawerFiltersLineCollapsable>
    </div>

  )
}

export { ExpiredProposalFilter }

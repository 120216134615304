import React from 'react'
import { ShareButtons } from 'components'
import useDynamicProposal from 'ecp/app/Proposals/hooks/useDynamicProposal'
import useThisStep from 'ecp/app/Proposals/hooks/useThisStep'
import { message } from 'antd'
import DynamicProposalECPFRepository from 'ecpf/repositories/DynamicProposalECPFRepository'

function GenerateSendDocumentsLink ({ messagePrefix = 'Link de assinatura do contrato ECP: ' }: { messagePrefix?: string }) {
  const { details } = useDynamicProposal()
  const { step } = useThisStep()

  const { canGenerateSendDocumentsLink, departmentId, _id } = step

  const generateLink = async () => {
    try {
      if (!details?.value._id) throw new Error('')
      const body = {
        departmentId,
        stepId: _id
      }
      const response = await DynamicProposalECPFRepository.generateUploadDocumentsLink(details?.value._id, body)

      return response.data.data?.link
    } catch (err) {
      message.error(err.message)
    }
  }

  return (
    <>
      {canGenerateSendDocumentsLink && (
        <ShareButtons
          messagePrefix={messagePrefix}
          generateLink={() => generateLink()}
          phone={details?.value?.client?.cellphone}
        />
      )}
    </>
  )
}

export default GenerateSendDocumentsLink

import React, { useEffect, useState, Fragment } from 'react'
import { IProps, IErrors, IResources, IRepresentative } from './types'
import { useDispatch } from 'react-redux'
import { Form, Row, Col, Select } from 'antd'
import { resources } from '../../egi/consumers'
import { validateResponse } from '../../utils/validate'
import swal from '../../utils/swal'
import { useProposal, useStep } from 'hooks'
import { IWitness } from 'egi/types'
import { proposalLoadingStep } from 'store/modules/proposal/actions'
import { IAttorney } from 'egi/app/Attorney/attorneyInterfaces'
import ProposalEgiRepository from 'egi/repositories/ProposalEgiRepository'

function RepresentativeSelection ({ formRef, finish, onLoading, readOnlyInput }: IProps) {
  const proposal = useProposal()
  const step = useStep()

  const dispatch = useDispatch()

  const [attorneysList, setAttorneysList] = useState<IResources[]>([])
  const [witnessesList, setWitnessesList] = useState<IResources[]>([])
  const [errors, setErrors] = useState<IErrors>({})

  const onSubmit = async (values: IRepresentative) => {
    const representativesId = []
    if (values.attorneys) representativesId.push(...values.attorneys)
    if (values.witnesses) representativesId.push(...values.witnesses)

    if (!proposal.id) return
    if (!step.id) return

    try {
      onLoading('sending')
      const data = { proposalId: proposal.id, stepId: step.id, representativesId }
      const response = await ProposalEgiRepository.sendRepresentative(data)
      const message = response?.data?.message
      if (step.id) dispatch(proposalLoadingStep({ stepId: step.id, loading: true }))
      setErrors({})
      swal.basic({ title: 'Sucesso!', text: message, icon: 'success' })
      finish()
    } catch (err) {
      if (err.data?.allInvalids) {
        let errors: IErrors = {}
        errors = validateResponse(err.data?.allInvalids)
        setErrors(errors)
        swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
        return
      }

      setErrors({})
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
      return false
    } finally {
      onLoading('')
    }
  }

  useEffect(() => {
    async function getAttorneys () {
      try {
        const attorneysList = await resources.attorney()
        setAttorneysList(attorneysList)
      } catch (err) {
        console.error(err)
      }
    }

    async function getWitnesses () {
      try {
        const witnessesList = await resources.witness()
        setWitnessesList(witnessesList)
      } catch (err) {
        console.log(err)
      }
    }

    function resetFields () {
      if (formRef) formRef.resetFields()
    }
    async function onFill () {
      let attorneys = (step as any).attorney || []
      let witnesses = (step as any).witness || []

      if (attorneys.length < 1 && witnesses.length < 1) {
        resetFields()
        return
      }

      if (witnesses) {
        witnesses = witnesses.map((witness: IWitness) => witness._id)
      }

      if (attorneys) {
        attorneys = attorneys.map((attorney: IAttorney) => attorney._id)
      }

      formRef.setFieldsValue({ attorneys, witnesses })
    }

    resetFields()
    getAttorneys()
    getWitnesses()
    onFill()
  }, [])

  return (
    <Fragment>
      <Form
        form={formRef}
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row>
          <Col className="px-1" lg={12} xs={24}>
            <Form.Item
              name='attorneys'
              label={<label >Procurador Bancário:</label>}
              help={errors.attorneys && (errors.attorneys)}
              validateStatus={errors.attorneys && ('error')}
              rules={[{ required: true, message: 'Selecione um procurador' }]}
              getValueFromEvent={(e: string[]) => {
                if (e.length > 2) {
                  e.pop()
                }
                return e
              }}
            >
              <Select
                id="test-multiSelect-attorney"
                showSearch
                mode="multiple"
                placeholder="Selecione um procurador"
                aria-readonly={readOnlyInput}
                disabled={readOnlyInput}
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {attorneysList.map(attorney =>
                  <>
                    <Select.Option id={`test-multiSelect-attorney-${(attorney.label).replaceAll(' ', '-')}`} key={attorney.value} value={attorney.value}>{attorney.label}</Select.Option>
                  </>
                )}
              </Select>
            </Form.Item>
          </Col>

          <Col className="px-1" lg={12} xs={24}>
            <Form.Item
              name='witnesses'
              label={<label >Testemunhas:</label>}
              help={errors.witnesses && (errors.witnesses)}
              validateStatus={errors.witnesses && ('error')}
              getValueFromEvent={(e: string[]) => {
                if (e.length > 2) {
                  e.pop()
                }
                return e
              }}
            >
              <Select
                id="test-multiSelect-witness"
                showSearch
                mode="multiple"
                placeholder="Selecione as testemunhas"
                aria-readonly={readOnlyInput}
                disabled={readOnlyInput}
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {witnessesList.map(witness =>
                  <Select.Option id={`test-multiSelect-witness-${(witness.label).replaceAll(' ', '-')}`} key={witness.value} value={witness.value}>{witness.label}</Select.Option>
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </Fragment>
  )
}

export default RepresentativeSelection

import React, { Fragment, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { WhatsAppOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons'
import { Popconfirm, Tooltip } from 'antd'
import swal from 'utils/swal'
import { drawerFillForms } from 'store/modules/drawer/actions'
import { Props } from './types'
import { useAuth } from 'hooks'
import IDrawer from 'egi/types/IDrawer'
import ContactModel from 'egi/models/ContactModel'

const disabledStyle = {
  fontSize: '20px',
  color: 'black',
  opacity: '.5',
  cursor: 'not-allowed'
}

const ContactToClient = ({
  numberClient,
  emailClient,
  productNameClient,
  nameClient,
  idProposal,
  canCall,
  leadId,
  contactType,
  proposalNumber
}: Props) => {
  const dispatch = useDispatch()
  const user = useAuth()
  const emailText = `mailto:${emailClient?.destination}?subject:${nameClient}%2C%20${productNameClient}&body=Olá%20sou%20${user.name}%20e%20gostaria%20de%20entrar%20em%20contato%20sobre%20a%20${emailClient?.type}:${productNameClient}%20ID:${idProposal}`

  const cellphoneText = useMemo(() => {
    if (numberClient) return (<p>Ligar para o numero <label className="bold">{numberClient}</label></p>)
    return 'Sem celular'
  }, [numberClient])

  const whatsappData: IDrawer = {
    visible: true,
    title: 'Envie sua mensagem',
    width: '50vw',
    outsideClick: false,
    type: contactType,
    contact: {
      client: {
        id: proposalNumber,
        name: nameClient,
        email: emailClient?.destination,
        number: numberClient
      },
      product: productNameClient,
      applicant: user.name,
      proposalNumber: proposalNumber,
      proposalId: idProposal
    },
    lead: {
      id: leadId || '',
      status: '',
      isSubmit: false
    }
  }

  return (
    <Fragment>
      {numberClient && (
        <Tooltip title="WhatsApp" destroyTooltipOnHide={{ keepParent: false }}>
          <a onClick={() => dispatch(drawerFillForms(whatsappData))} className="color-black" >
            <WhatsAppOutlined className="mr-2" style={{ fontSize: '20px' }} />
          </a>
        </Tooltip>
      )}

      {emailClient && (
        <Tooltip title="Email" destroyTooltipOnHide={{ keepParent: false }}>
          <a href={ContactModel.canSendEmail(user.level) ? emailText : undefined} target="_blank" rel="noreferrer" className="color-black">
            <MailOutlined className="mr-2" style={ !ContactModel.canSendEmail(user.level) ? disabledStyle : { fontSize: '20px' }} />
          </a>
        </Tooltip>
      )}

      {canCall && (
        <Tooltip title="Telefone" destroyTooltipOnHide={{ keepParent: false }}>
          <Popconfirm
            disabled={true}
            cancelText="Cancelar"
            okText="Ligar"
            title={cellphoneText}
            onConfirm={() => {
              swal.basic({
                title: 'Atenção',
                text: 'Indisponível no momento',
                icon: 'warning'
              })
            }}
          >
            <a><PhoneOutlined style={disabledStyle} /></a>
          </Popconfirm>
        </Tooltip>
      )}
    </Fragment>
  )
}

export default ContactToClient

import React, { Fragment, useEffect, useState } from 'react'
import { Table, Tooltip, Typography } from 'antd'
import format from 'utils/format'
import { InfoCircleOutlined } from '@ant-design/icons'
import TextPromoterCommission from '../ModalInvite/TextPromoterCommission'
import translate from 'utils/translate'

const { Title } = Typography

interface IExampleCommissionTable {
  currentValue: string | number | null | undefined
  promoterType: string
}

function ExampleCommissionTable ({ currentValue, promoterType }:IExampleCommissionTable) {
  const [tariffValue, setTariffValue] = useState({
    R1: 0,
    R2: 0,
    R3: 0
  })

  useEffect(() => {
    function calculateTariff () {
      const percentageValue = format.percentageToDecimal(Number(currentValue))
      const firstR = (1 * percentageValue)
      const secondR = (2 * percentageValue)
      const thirdR = (3 * percentageValue)

      setTariffValue({
        R1: firstR,
        R2: secondR,
        R3: thirdR
      })
    }

    if (currentValue) calculateTariff()
  }, [currentValue])

  const data = [
    {
      name: 'R1',
      value: '1.00%',
      commission: '1.00%',
      promoter: `${(Number(tariffValue.R1).toFixed(2)) + '%'}`
    },
    {
      name: 'R2',
      value: '1.05%',
      commission: '2.00%',
      promoter: `${(Number(tariffValue.R2).toFixed(2)) + '%'}`
    },
    {
      name: 'R3',
      value: '1.09%',
      commission: '3.00%',
      promoter: `${(Number(tariffValue.R3).toFixed(2)) + '%'}`
    }
  ]

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Taxa mensal',
      dataIndex: 'value',
      key: 'value'
    },
    {
      title: 'Comissão',
      dataIndex: 'commission',
      key: 'commission'
    },
    {
      title: `${promoterType ? translate.promoterType(promoterType) : 'Parceiro'}`,
      dataIndex: 'promoter',
      key: 'promoter'
    }
  ]

  return (
    <Fragment>

      <div className="flex flex-align-center mb-2">
        <Title className="color-primary mr-1 mb-0" level={5}>Tabela de exemplo</Title>
        <Tooltip
          color="white"
          placement="right"
          title={<TextPromoterCommission/>}
          destroyTooltipOnHide={{ keepParent: false }}
        >
          <InfoCircleOutlined className="simulation-info" style={{ color: '#00441F' }}/>
        </Tooltip>
      </div>

      <Table
        className="antd-base-table mb-4"
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </Fragment>

  )
}

export default ExampleCommissionTable

import loadable from '@loadable/component'
import Loading from 'components/Loading/Loading'
import { timeout } from 'promise-timeout'
import React from 'react'
import { TIMEOUT_LOADER } from 'utils/globals'

const RenegotiationContracts = loadable(
  () => timeout(import(
    /* webpackChunkName: "Renegotiation" */
    'ecp/app/Renegotiation/views/RenegotiationContracts/RenegotiationContracts'
  ), TIMEOUT_LOADER),
  {
    fallback: <Loading/>
  }
)

const RenegotiationCreate = loadable(
  () => timeout(import(
    /* webpackChunkName: "Renegotiation" */
    'ecp/app/Renegotiation/views/RenegociationCreate/RenegotiationCreate'
  ), TIMEOUT_LOADER),
  {
    fallback: <Loading/>
  }
)

const RenegotiationFindUserCovenantSelect = loadable(
  () => timeout(import(
    /* webpackChunkName: "Renegotiation" */
    'ecp/app/Renegotiation/views/RenegociationFindUserCovenantSelect/RenegotiationFindUserCovenantSelect'
  ), TIMEOUT_LOADER),
  {
    fallback: <Loading/>
  }
)

const RenegotiationFindUserInformationConfirm = loadable(
  () => timeout(import(
    /* webpackChunkName: "Renegotiation" */
    'ecp/app/Renegotiation/views/RenegociationFindUserInformationConfirm/RenegociationFindUserInformationConfirm'
  ), TIMEOUT_LOADER),
  {
    fallback: <Loading/>
  }
)

const RenegotiationFindUserCPF = loadable(
  () => timeout(import(
    /* webpackChunkName: "Renegotiation" */
    'ecp/app/Renegotiation/views/RenegotiationFindUserCPF/RenegotiationFindUserCPF'
  ), TIMEOUT_LOADER),
  {
    fallback: <Loading/>
  }
)

export {
  RenegotiationContracts,
  RenegotiationCreate,
  RenegotiationFindUserCovenantSelect,
  RenegotiationFindUserInformationConfirm,
  RenegotiationFindUserCPF
}

import { all, call, put, takeLatest } from 'redux-saga/effects'
import { AxiosRequestConfig } from 'axios'
import ChatRepository from 'egi/repositories/ChatRepository'

const chatPending = async (config?: AxiosRequestConfig) => {
  const response = await ChatRepository.pending(config)
  return response.data
}

function * getPendingChats (): any {
  const abortController = new AbortController()
  try {
    const response = yield call(chatPending, { signal: abortController.signal })
    const count = response.data.count

    yield put({
      type: 'CHAT_PENDING_SUCCESS',
      payload: {
        chatPending: count
      }
    })
  } catch (err) {
    console.error(err)
  } finally {
    abortController.abort()
  }
}

export default all([
  takeLatest('GET_CHAT_PENDING_REQUESTED', getPendingChats)
])

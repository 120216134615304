import { Button, message } from 'antd'
import { ISearch } from 'components/ServerTable/types'
import ReportsRepository from 'ecp/repositories/ReportsRepository'
import useProgressQueue from 'hooks/useProgressQueue'
import React, { useState } from 'react'
import { IProgressQueueActions, IStatusProgressQueue } from 'store/modules/progressQueue/progressQueueReducer'
import { isImportingQueueItem } from '../ProgressQueue/progressQueueFunctions'
import { IProgressQueueListType } from '../ProgressQueue/progressQueueInterfaces'

interface IReportsQueueButton {
  filters: ISearch
  path: string
  className?: string
  onFinish?: () => void
  disabled?: boolean
}

export default function ReportsQueueButton ({ filters, path, className = '', onFinish, disabled }: IReportsQueueButton) {
  const [loadingReportRequest, setLoadingReportRequest] = useState<boolean>(false)
  const { progressQueue, addProgressQueueItens } = useProgressQueue()
  const isImporting = isImportingQueueItem(progressQueue.progressQueueList, 'report')

  async function reportRequest () {
    setLoadingReportRequest(true)
    try {
      const response = await ReportsRepository.sendQueue(filters, path)
      const requestId = response.data.data?.requestId

      if (requestId) {
        const queueItem = {
          listLenght: 1,
          list: [],
          isOpen: true,
          isImporting: true,
          progressCheckId: requestId,
          type: 'report' as IProgressQueueListType,
          action: '' as IProgressQueueActions,
          status: 'active' as IStatusProgressQueue
        }

        addProgressQueueItens(queueItem, progressQueue.progressQueueList)
      }
    } catch (error) {
      if (error.message) message.error(error.message)
    } finally {
      setLoadingReportRequest(false)
    }
  }

  const handleOnClick = async () => {
    await reportRequest()
    onFinish && onFinish()
  }

  return (
    <Button
      type='primary'
      onClick={handleOnClick}
      loading={loadingReportRequest}
      disabled={disabled || isImporting}
      className={`${className}`}
    >
      Gerar relatório
    </Button>
  )
}

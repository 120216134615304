import { AuditOutlined, CheckOutlined, CloseOutlined, DollarOutlined, FileSearchOutlined, FileTextOutlined, FundViewOutlined, GlobalOutlined, HomeOutlined, MailOutlined, MessageOutlined, NotificationOutlined, PaperClipOutlined, PartitionOutlined, RiseOutlined, SearchOutlined, SettingOutlined, SolutionOutlined, StopOutlined, TeamOutlined, UserAddOutlined, UserSwitchOutlined } from '@ant-design/icons'
import { Empty } from 'antd'
import { IRoute, RouteGroup } from 'components/Sidebar/types'
import MasterList from 'ecp/app/Masters/Views/MasterList/MasterList'
import AccessManagerBackOffice from 'egi/app/AccessManager/views/AccessManagerBackOffice/AccessManagerBackOffice'
import AccessManagerBackOfficeDetails from 'egi/app/AccessManager/views/AccessManagerBackOfficeDetails/AccessManagerBackOfficeDetails'
import AccessManagerClient from 'egi/app/AccessManager/views/AccessManagerClient/AccessManagerClient'
import AGXReports from 'egi/app/AGXReports/views/AGXReports'
import AllInvites from 'egi/app/AllInvites/views/AllInvites'
import BacenTerms from 'egi/app/BacenTerms/views/BacenTerms'
import ClientCardList from 'egi/app/Client/views/ClientCardList/ClientCardList'
import ClientList from 'egi/app/Client/views/ClientsList/ClientsList'
import Docs from 'egi/app/Docs/views/Docs'
import FrameModel from 'egi/app/FrameModel/FrameModel'
import GenericChat from 'egi/app/GenericChat/GenericChat'
import InternalNotification from 'egi/app/InternalNotification/views/InternalNotification'
import LeadDetails from 'egi/app/Leads/views/LeadDetails/LeadDetails'
import LeadHistory from 'egi/app/Leads/views/LeadHistory/LeadHistory'
import ListLeads from 'egi/app/Leads/views/ListLeads/ListLeads'
import LgpdTerms from 'egi/app/LGPDTerms/views/LgpdTerms'
import MlmDashboard from 'egi/app/MLM/views/MlmDashboard/MlmDashboard'
import PartnersLine from 'egi/app/PartnersLine/views/PartnersLine'
import Profile from 'egi/app/Profile/views/Profile/Profile'
import PromoterCollaboratorsList from 'egi/app/Promoter/views/PromoterCollaboratorsList/PromoterCollaboratorsList'
import PromoterList from 'egi/app/Promoter/views/PromoterList/PromoterList'
import ProposalEgiHistory from 'egi/app/ProposalEgi/views/ProposalEgiHistory/ProposalsHistory'
import ProposalsListEgiOld from 'egi/app/ProposalEgi/views/ProposalListEgi/ProposalListEgi'
import ProposalsListEgiTwo from 'egi/app/ProposalEgi2/views/ProposalListEgi/ProposalListEgi'
import { ProposalListSearchEGI } from 'egi/app/ProposalEgi2/views/ProposalListSearch/ProposalListSearch'
import Declines from 'egi/app/Settings/views/Declines/Declines'
import { EmailConfig } from 'egi/app/Settings/views/EmailConfig/EmailConfig'
import InternalReviewSettigns from 'egi/app/Settings/views/InternalSettings/InternalReviewSettings'
import OperationalLimitSettings from 'egi/app/Settings/views/OperationalLimitSettings/OperationalLimitSettings'
import Population from 'egi/app/Settings/views/PopulationSettings/Population'
import SettingsMenu from 'egi/app/Settings/views/SettingsMenu/SettingsMenu'
import SimulationConfiguration from 'egi/app/Settings/views/SimulationConfiguration/SimulationConfiguration'
import SLAConfig from 'egi/app/Settings/views/SLAConfig/SLAConfig'
import UserDetails from 'egi/app/User/views/UserDetails/UserDetails'
import ProposalEgiModel from 'egi/models/ProposalEgiModel'
import UsersTable from 'egi/views/UsersTable/UsersTable'
import { useAuth } from 'hooks'
import React from 'react'
import { useParams } from 'react-router-dom'
import translate from 'utils/translate'
import mainColumns from '../egi/views/UsersTable/DataTables'
import { analiseCreditoTeam } from './areas/analiseCredito'
import { comercialTeam } from './areas/comercial'
import { engenhariaTeam } from './areas/engenharia'
import { attorneys, createAttorney, createWitness, getOneAttorney, getOneWitness, juridicoTeam, witnessess } from './areas/juridico'
import { campaignLink, divulgationPage, generateLink, mlmProposalsList, sellersList } from './areas/mlm'
import { telemarketingTeam } from './areas/telemarketing'
import { exclusiveFees, precificacao, precificacaoCreate, precificacaoEdit, precificacaoTable, pricingCommissionTable, pricingTable, pricingUsers } from './departments/pricing'
import { compliance, documentos, documents, fiscal, internalCommunication, marketingLeads, products, proposalCheck, proposalMainPage, registro, reportCommission, reportProviders } from './disableds'
import { EGI } from './egi/paths'

const dashboard: IRoute = {
  path: '/dashboard',
  name: 'Visão geral',
  icon: HomeOutlined,
  component: MlmDashboard,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const proposalsList: IRoute = {
  path: EGI.PROPOSAL_LIST.path,
  name: EGI.PROPOSAL_LIST.name,
  icon: SolutionOutlined,
  component: ProposalsListEgiOld,
  meta: {
    centered: false,
    hidden: true,
    disabled: false,
    canGoBack: false
  }
}

const proposalsListTwo: IRoute = {
  path: EGI.PROPOSAL_LIST_TWO.path,
  name: EGI.PROPOSAL_LIST_TWO.name,
  icon: SolutionOutlined,
  component: ProposalsListEgiTwo,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const proposalsListSearch: IRoute = {
  path: EGI.PROPOSAL_LIST_SEARCH.path,
  name: EGI.PROPOSAL_LIST_SEARCH.name,
  icon: SearchOutlined,
  component: ProposalListSearchEGI,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false,
    hiddenTitle: true
  }
}

const proposalsHistory: IRoute = {
  path: '/proposals/history/:id',
  name: 'Histórico da proposta',
  icon: FileSearchOutlined,
  component: ProposalEgiHistory,
  meta: {
    centered: false,
    hidden: true,
    disabled: false,
    canGoBack: true,
    hiddenTitle: false
  }
}

const leads: IRoute = {
  path: EGI.LEADS.path,
  name: EGI.LEADS.name,
  icon: RiseOutlined,
  component: () => <ListLeads />,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const leadsCommercial: IRoute = {
  path: '/leads',
  name: 'Visão geral',
  icon: GlobalOutlined,
  component: () => <ListLeads />,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const leadsCommercialLinked: IRoute = {
  path: '/leads/linked',
  name: 'Vinculadas',
  icon: CheckOutlined,
  component: () => <ListLeads linked={true} />,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const leadDetails: IRoute = {
  path: '/leads/details/:leadId',
  name: 'Detalhes do lead',
  icon: RiseOutlined,
  component: () => <LeadDetails />,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const leadsMenu: RouteGroup = {
  name: 'Leads',
  icon: RiseOutlined,
  routes: [
    leadsCommercial,
    leadsCommercialLinked
  ]
}

const internalNotification: IRoute = {
  path: '/internalnotification',
  name: 'Comunicação Interna',
  icon: NotificationOutlined,
  component: InternalNotification,
  meta: {
    centered: true,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const allInvites: IRoute = {
  path: EGI.ALL_INVITES.path,
  name: EGI.ALL_INVITES.name,
  icon: UserAddOutlined,
  component: AllInvites,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const promoters: IRoute = {
  path: '/promoters',
  name: 'Parceiro',
  icon: TeamOutlined,
  component: () => <PromoterList viewerLevel="master" canInvite={true} canGenerateReport={true} />,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const auditors: IRoute = {
  path: EGI.AUDITORS.path,
  name: EGI.AUDITORS.name,
  icon: TeamOutlined,
  component: () => <UsersTable url='/auditors' level="auditor" inviteButtonText={translate.level('auditor')} columns={mainColumns.auditor} />,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const promotersIn: IRoute = {
  path: '/promoters/in',
  name: 'Parceiro IN',
  icon: TeamOutlined,
  component: () => <PromoterList viewerLevel='mlm' specificType={['IN']} canGenerateReport={false} canInvite={true} />,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const collaborators: IRoute = {
  path: EGI.COLLABORATORS.path,
  name: EGI.COLLABORATORS.name,
  icon: TeamOutlined,
  component: PromoterCollaboratorsList,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const promoterClients: IRoute = {
  path: '/promoter/clients/list',
  name: 'Clientes',
  icon: TeamOutlined,
  component: () => <ClientList type='promoters'/>,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const commercialClients: IRoute = {
  path: '/commercial/clients/list',
  name: 'Clientes',
  icon: TeamOutlined,
  component: () => <ClientList type='commercials'/>,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const providers: IRoute = {
  path: '/providers',
  name: 'Prestadores',
  icon: TeamOutlined,
  component: () => <UsersTable url='/providers' level="provider" inviteButtonText={translate.level('provider')} columns={mainColumns.provider} />,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const operationalLimit: IRoute = {
  path: '/limit/operational',
  name: 'Limite Operacional',
  icon: TeamOutlined,
  component: OperationalLimitSettings,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const commissionsUsers: IRoute = {
  path: EGI.COMISSION_USERS.path,
  name: EGI.COMISSION_USERS.name,
  icon: DollarOutlined,
  component: () => <UsersTable url='/commissions' level="commission" inviteButtonText={translate.level('commission')} columns={mainColumns.commission} />,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const consultants: IRoute = {
  path: '/consultants',
  name: 'Consultores Comerciais',
  icon: TeamOutlined,
  component: () => <UsersTable url='/consultants' level="consultant" inviteButtonText={translate.level('consultant')} columns={mainColumns.consultant} />,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const population: IRoute = {
  path: '/population',
  name: 'População mínima',
  icon: TeamOutlined,
  component: () => <Population />,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const declines: IRoute = {
  path: '/declines',
  name: 'Motivos de recusa',
  icon: StopOutlined,
  component: Declines,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const simulationConfiguration: IRoute = {
  path: '/values',
  name: 'Valores da simulação',
  icon: DollarOutlined,
  component: SimulationConfiguration,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const leadsHistory: IRoute = {
  path: '/leads/history/:id',
  name: 'Histórico lead',
  component: LeadHistory,
  meta: {
    centered: false,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const chat: IRoute = {
  path: '/chat',
  name: 'Chamados',
  icon: MessageOutlined,
  component: GenericChat,
  meta: {
    centered: true,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const profile: IRoute = {
  path: '/profile',
  name: 'Perfil',
  component: Profile,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const slaConfig: IRoute = {
  path: '/sla/configuration',
  name: 'SLA',
  icon: SettingOutlined,
  component: SLAConfig,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const proposalsTelemarketing: RouteGroup = {
  name: 'Propostas',
  icon: SolutionOutlined,
  routes: [
    proposalsListSearch,
    telemarketingTeam.proposalsList,
    telemarketingTeam.proposalsReminded
  ],
  meta: {
    hidden: true
  }
}

const proposalsTelemarketingTwo: RouteGroup = {
  name: 'Propostas',
  icon: SolutionOutlined,
  routes: [
    proposalsListSearch,
    telemarketingTeam.proposalsListTwo,
    telemarketingTeam.proposalsRemindedTwo
  ]
}

const teamAdmin: IRoute = {
  path: '/administrators/:id/invited/:name',
  name: 'Time administrador',
  component: function () {
    const params: { id: string, name: string } = useParams()
    if (!params.id) return (<Empty description="Falha ao carregar dados" />)
    return (
      <UsersTable
        url={`/administrators/${params.id}/invited`}
        level={'commom' as any}
        inviteButtonText={translate.level('commom')}
        columns={mainColumns.teamColumns}
      />
    )
  },
  meta: {
    centered: false,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const teamPromoterIn: IRoute = {
  path: '/mlm/promoters/:id/:name',
  name: 'Time parceiro',
  meta: {
    centered: false,
    hidden: true,
    disabled: false,
    canGoBack: true
  },
  component: function () {
    const params: { id: string, name: string } = useParams()
    if (!params.id) return (<Empty description="Falha ao carregar dados" />)
    return (<UsersTable url={`/sellers?promoterId=${params.id}`} level="seller" inviteButtonText={translate.level('seller')} columns={mainColumns.seller} />)
  }
}

const allProposalsList = {
  path: '/proposals/all-old',
  name: 'Visão geral',
  icon: GlobalOutlined,
  component: () => <ProposalsListEgiOld />,
  meta: {
    centered: false,
    hidden: true,
    disabled: false,
    canGoBack: false
  }
}

const allProposalsListTwo = {
  path: EGI.PROPOSAL_LIST_ALL.path,
  name: EGI.PROPOSAL_LIST_ALL.name,
  icon: GlobalOutlined,
  component: () => <ProposalsListEgiTwo />,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const proposalsAdminCommercial: RouteGroup = {
  name: 'Propostas',
  icon: SolutionOutlined,
  meta: {
    hidden: true
  },
  routes: [
    proposalsListSearch,
    allProposalsList,
    telemarketingTeam.proposalsList,
    telemarketingTeam.proposalsReminded
  ]
}

const proposalsAdminCommercialTwo: RouteGroup = {
  name: 'Propostas',
  icon: SolutionOutlined,
  routes: [
    proposalsListSearch,
    allProposalsListTwo,
    telemarketingTeam.proposalsListTwo,
    telemarketingTeam.proposalsRemindedTwo
  ]
}

const linkedProposals = {
  path: '/proposals/linked-old',
  name: 'Vinculadas',
  icon: CheckOutlined,
  component: () => {
    const user = useAuth()
    return (
      <ProposalsListEgiOld
        needVinculation={false}
        hasAction={ProposalEgiModel.vinculateUnlinkedAction(user.level)}
      />
    )
  },
  meta: {
    centered: false,
    hidden: true,
    disabled: false,
    canGoBack: false
  }
}

const linkedProposalsTwo = {
  path: EGI.PROPOSAL_LIST_LINKED_TWO.path,
  name: EGI.PROPOSAL_LIST_LINKED_TWO.name,
  icon: CheckOutlined,
  component: () => {
    const user = useAuth()
    return (
      <ProposalsListEgiTwo
        needVinculation={false}
        hasAction={ProposalEgiModel.vinculateUnlinkedAction(user.level)}
      />
    )
  },
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const unlinkedProposals: IRoute = {
  path: '/proposals/unlinked-old',
  name: 'Não vinculadas',
  icon: CloseOutlined,
  component: () => {
    const user = useAuth()
    return (
      <ProposalsListEgiOld
        needVinculation={true}
        hasAction={ProposalEgiModel.vinculateUnlinkedAction(user.level)}
      />
    )
  },
  meta: {
    centered: false,
    hidden: true,
    disabled: false,
    canGoBack: false
  }
}

const unlinkedProposalsTwo: IRoute = {
  path: EGI.PROPOSAL_LIST_UNLINKED_TWO.path,
  name: EGI.PROPOSAL_LIST_UNLINKED_TWO.name,
  icon: CloseOutlined,
  component: () => {
    const user = useAuth()
    return (
      <ProposalsListEgiTwo
        needVinculation={true}
        hasAction={ProposalEgiModel.vinculateUnlinkedAction(user.level)}
      />
    )
  },
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const allProposals = {
  name: 'Propostas',
  icon: SolutionOutlined,
  routes: [
    proposalsListSearch,
    allProposalsList,
    linkedProposals,
    unlinkedProposals
  ],
  meta: {
    hidden: true
  }
}

const allProposalsTwo = {
  name: 'Propostas',
  icon: SolutionOutlined,
  routes: [
    proposalsListSearch,
    allProposalsListTwo,
    linkedProposalsTwo,
    unlinkedProposalsTwo
  ]
}

const vinculationList: RouteGroup = {
  name: 'Propostas',
  icon: SolutionOutlined,
  routes: [
    proposalsListSearch,
    linkedProposals,
    unlinkedProposals
  ],
  meta: {
    hidden: true
  }
}

const vinculationListTwo: RouteGroup = {
  name: 'Propostas',
  icon: SolutionOutlined,
  routes: [
    proposalsListSearch,
    linkedProposalsTwo,
    unlinkedProposalsTwo
  ]
}

const proposalsCommercial: RouteGroup = {
  name: 'Propostas',
  icon: SolutionOutlined,
  routes: [
    proposalsListSearch,
    allProposalsList,
    telemarketingTeam.proposalsList,
    telemarketingTeam.proposalsReminded,
    linkedProposals,
    unlinkedProposals
  ],
  meta: {
    hidden: true
  }
}

const proposalsCommercialTwo: RouteGroup = {
  name: 'Propostas',
  icon: SolutionOutlined,
  routes: [
    proposalsListSearch,
    allProposalsListTwo,
    telemarketingTeam.proposalsListTwo,
    telemarketingTeam.proposalsRemindedTwo,
    linkedProposalsTwo,
    unlinkedProposalsTwo
  ]
}

const qualification: IRoute = {
  name: 'Modelos de quadros',
  icon: AuditOutlined,
  path: '/qualification',
  component: FrameModel,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false,
    hiddenTitle: true
  }
}

const agxReports: IRoute = {
  name: 'Relatórios Fechamento',
  path: '/reports',
  icon: FundViewOutlined,
  component: AGXReports,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const userDetails: IRoute = {
  name: EGI.USER_DETAILS.name,
  path: EGI.USER_DETAILS.path,
  component: UserDetails,
  meta: {
    centered: false,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const clientList: IRoute = {
  name: 'Consultar CPF',
  path: '/cpfquery',
  component: ClientCardList,
  icon: SearchOutlined,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const partnersLine: IRoute = {
  name: 'Documentação de parceiros',
  path: '/partner/:partnerId/documentation',
  icon: FileTextOutlined,
  component: PartnersLine,
  meta: {
    centered: false,
    disabled: false,
    hidden: true,
    canGoBack: true
  }

}

const partnerDocumentation: IRoute = {
  name: 'Sua documentação',
  path: '/documentation',
  icon: FileTextOutlined,
  component: PartnersLine,
  meta: {
    canGoBack: false,
    centered: false,
    disabled: false,
    hidden: false
  }
}

const docs: IRoute = {
  path: '/docs',
  name: 'docs',
  icon: PaperClipOutlined,
  component: Docs,
  meta: {
    centered: false,
    hidden: true,
    disabled: false,
    canGoBack: false
  }
}

const listMaster: IRoute = {
  path: '/master/list',
  name: 'Masters',
  icon: TeamOutlined,
  component: MasterList,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const emailConfig: IRoute = {
  path: '/email/config',
  name: 'Configurações de email',
  icon: MailOutlined,
  component: EmailConfig,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const bacenTerms: IRoute = {
  path: '/terms/bacen',
  name: 'Termo BACEN',
  icon: MailOutlined,
  component: BacenTerms,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: false
  }
}

const lgpdTerms: IRoute = {
  path: '/terms/lgpd',
  name: 'Termo LGPD',
  icon: MailOutlined,
  component: LgpdTerms,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: false
  }
}

const internReviewSettings: IRoute = {
  path: '/settings/internal-review',
  name: 'Revisão Interna',
  icon: MailOutlined,
  component: InternalReviewSettigns,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const settingsMenu: IRoute = {
  name: 'Configurações',
  path: '/settings',
  icon: SettingOutlined,
  component: SettingsMenu,
  meta: {
    centered: true,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const accessManagerClient: IRoute = {
  path: EGI.ACCESS_MANAGER_CLIENT.path,
  name: EGI.ACCESS_MANAGER_CLIENT.name,
  icon: SolutionOutlined,
  component: AccessManagerClient,
  meta: {
    centered: false,
    hidden: false,
    canGoBack: false,
    disabled: false
  }
}

const accessManagerBackOffice: IRoute = {
  path: EGI.ACCESS_MANAGER_BACKOFFICE.path,
  name: EGI.ACCESS_MANAGER_BACKOFFICE.name,
  icon: UserSwitchOutlined,
  component: AccessManagerBackOffice,
  meta: {
    centered: false,
    hidden: false,
    canGoBack: false,
    disabled: false
  }
}

const accessManagerDetails: IRoute = {
  path: '/access-manager/backoffice/:backofficeId',
  name: 'Detalhes do Usuário Interno',
  icon: UserSwitchOutlined,
  component: AccessManagerBackOfficeDetails,
  meta: {
    centered: false,
    hidden: true,
    canGoBack: true,
    disabled: false
  }
}

const accessManager = {
  name: 'Gestão de acessos',
  icon: PartitionOutlined,
  routes: [
    accessManagerClient,
    accessManagerBackOffice
  ]
}

export {
  dashboard,
  proposalsList,
  proposalsListTwo,
  proposalsListSearch,
  proposalsCommercial,
  proposalsCommercialTwo,
  proposalsHistory,
  listMaster,
  emailConfig,
  accessManagerClient,
  accessManagerBackOffice,
  accessManagerDetails,
  accessManager,

  // Leads
  leadDetails,
  leads,
  leadsMenu,

  internalNotification,
  chat,
  clientList,

  promoters,
  collaborators,
  promotersIn,
  teamPromoterIn,

  providers,
  precificacao,
  promoterClients,
  commercialClients,
  precificacaoTable,
  pricingCommissionTable,
  precificacaoCreate,
  precificacaoEdit,
  createAttorney,
  createWitness,
  getOneAttorney,
  getOneWitness,
  pricingTable,
  exclusiveFees,
  pricingUsers,
  attorneys,
  witnessess,
  commissionsUsers,
  operationalLimit,
  consultants,
  leadsHistory,
  population,
  declines,

  simulationConfiguration,
  proposalsTelemarketing,
  proposalsTelemarketingTwo,
  proposalsAdminCommercial,
  proposalsAdminCommercialTwo,
  comercialTeam,
  engenhariaTeam,
  juridicoTeam,
  analiseCreditoTeam,
  telemarketingTeam,
  teamAdmin,
  profile,
  slaConfig,
  allInvites,
  vinculationList,
  vinculationListTwo,
  allProposals,
  allProposalsTwo,
  auditors,
  qualification,
  agxReports,
  userDetails,
  partnersLine,
  partnerDocumentation,

  // mlm
  campaignLink,
  sellersList,
  generateLink,
  mlmProposalsList,
  divulgationPage,

  // disabled
  documents,
  proposalCheck,
  marketingLeads,
  proposalMainPage,
  internalCommunication,
  reportProviders,
  reportCommission,
  fiscal,
  compliance,
  products,
  documentos,
  registro,
  docs,
  bacenTerms,
  lgpdTerms,
  settingsMenu,
  internReviewSettings
}

import React, { ReactNode, useMemo } from 'react'
import { Icons } from 'components'
import { colors } from 'styles/colors'
import { LoadingOutlined } from '@ant-design/icons'
import useDynamicProposal from 'ecp/app/Proposals/hooks/useDynamicProposal'
import useThisStep from '../../hooks/useThisStep'

export interface IProposalStepButtonProps {
  children: ReactNode
  onClick(event: React.MouseEvent<HTMLButtonElement>): void
  enabled: boolean
}

function ProposalStepButton ({ children, onClick, enabled }: IProposalStepButtonProps) {
  const { steps, loadingSteps } = useDynamicProposal()
  const { department, step } = useThisStep()
  const status = step?.status

  const isLoading = useMemo(() => {
    const findStep = steps?.[department._id]?.value.find(item => step._id === item._id)
    return loadingSteps.includes(findStep?._id || '')
  }, [loadingSteps.length])

  return (
    <span className='proposal-step-button'>
      <button
        className={`proposal-step-button__button ${enabled ? 'proposal-step-button__button--enabled' : ''}`}
        onClick={(e) => enabled && onClick(e)}
      >
        <figure className='proposal-step-button__icon'>
          {!!(step?.status) && (
            isLoading ? (
              <LoadingOutlined style={{ fontSize: 25 }} />
            ) : (
              <Icons name={status} color={colors[status]} />
            )
          )}
        </figure>
        <p className='proposal-step-button__name'>{children}</p>
      </button>
    </span>
  )
}

export default ProposalStepButton

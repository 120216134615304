import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import { Collapse } from 'antd'
import StringToHtml from 'components/StringToHtml/StringToHtml'
import ProgressQueueModels from 'ecp/models/ProgressQueueModels'
import useProgressQueue from 'hooks/useProgressQueue'
import { prettyPrintJson } from 'pretty-print-json'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { IProgressQueueItem } from 'store/modules/progressQueue/progressQueueReducer'
import { IProgressQueueInfo, IProgressQueueInfoStatus } from '../../progressQueueInterfaces'
import ProgressQueueCardView from '../ProgressQueueCardView/ProgressQueueCardView'

type Props = {
  queueItem: IProgressQueueItem
  itemIndex: number
  queueList: IProgressQueueItem[]
}

type ICardBodyProps = {
  progressQueueInfoList: IProgressQueueInfo[]
}

function CardBody ({ progressQueueInfoList }: ICardBodyProps) {
  function handleItemLabelClassName (status: IProgressQueueInfoStatus) {
    if (status === 'finish') return 'color-approved'
    if (status === 'error') return 'color-refused'
  }

  const handleErrorMessage = (errorMessage: string | { message?: string }) => {
    if (typeof errorMessage === 'string') return errorMessage
    if (errorMessage?.message) return errorMessage.message
    return null
  }

  const handleErrorJSON = (errorMessage?: string | Object) => {
    if (!errorMessage || typeof errorMessage === 'string') return null
    const html = prettyPrintJson.toHtml(errorMessage)

    return <><b>Detalhes do erro: </b><pre className="swal-error json-container"><StringToHtml value={html}/></pre></>
  }

  return (
    <Collapse bordered={false}>
      {Array.isArray(progressQueueInfoList) && progressQueueInfoList.map((item, index) => {
        return (
          <Collapse.Panel
            header={
              <div className='flex flex-space-between'>
                <label className={handleItemLabelClassName(item.status)}>
                  <b className='text-ellipsis'>{item.clientName}</b>
                </label>

                {item.status === 'finish'
                  ? <CheckCircleFilled className='color-approved mt-1'/>
                  : <CloseCircleFilled className='color-refused mt-1'/>
                }
              </div>
            }
            key={index}
          >
            <p className={`progress-queue-card__message ${handleItemLabelClassName(item.status)} mb-2`}>
              <b>{item.errorMessage ? handleErrorMessage(item.errorMessage) : item.succesMessage}</b>
            </p>
            <p className={`progress-queue-card__message ${handleItemLabelClassName(item.status)} mb-2`}>
              {handleErrorJSON(item.errorMessage)}
            </p>

            {item.info &&
              <p className={`progress-queue-card__message ${handleItemLabelClassName(item.status)}`}>
                {item.info}
              </p>
            }

            {item.link?.path &&
              <Link
                className={`link-style ${handleItemLabelClassName(item.status)} font-500 mb-0 table-name`}
                to={item.link.path}
              >
                {item.link.text}
              </Link>
            }
          </Collapse.Panel>
        )
      })}
    </Collapse>
  )
}

export default function ProgressQueueCard ({ queueItem, itemIndex, queueList }: Props) {
  const {
    removeProgressQueueItem, progressQueueProgressCheck
  } = useProgressQueue()

  const {
    isImporting, progressCheckId,
    listLenght, progressQueueInfoList,
    percentage, status, type, action
  } = queueItem

  useEffect(() => {
    const abortController = new AbortController()
    const progressCheck = setInterval(async () => {
      if (isImporting) {
        progressQueueProgressCheck(abortController, progressCheckId, itemIndex)
      }
    }, 4500)

    return () => {
      abortController.abort()
      clearInterval(progressCheck)
    }
  }, [isImporting, itemIndex])

  return (
    <>
      <ProgressQueueCardView
        onClose={removeProgressQueueItem}
        percentage={percentage ?? 0}
        status={status}
        listLenght={listLenght}
        progressQueueInfoList={progressQueueInfoList}
        progressText={ProgressQueueModels.progressQueueText({ selectedRowsLenght: listLenght, status: status, type, action, hasErrors: ProgressQueueModels.hasErrorsOnOperations(progressQueueInfoList) })}
        itemIndex={itemIndex}
        queueList={queueList}
        type={type}
        body={
          <CardBody progressQueueInfoList={progressQueueInfoList || []}
          />}
      />
    </>
  )
}

import React from 'react'
import { Select as AntdSelect } from 'antd'
import { OptionData, OptionGroupData } from 'rc-select/lib/interface'
import { SelectProps, SelectValue } from 'antd/lib/select'

export interface ISelectProps extends Omit<SelectProps<SelectValue | undefined>, 'onChange' > {
  onChange?: (value: any)=> void
}
function Select ({ onChange, ...rest }: ISelectProps) {
  const getOptions = (options?: (OptionData | OptionGroupData)[], children?: React.ReactNode) => {
    if (options) return options
    if (children) return Object.values(children).map(element => element.props)
    return undefined
  }

  const getEventVariables = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const target = event.target as HTMLSelectElement
    const current = target.getAttribute('aria-activedescendant')
    const typedValue = target.value
    return { current, typedValue }
  }

  const getFilterByTyped = (typedValue: string, options: (OptionData | OptionGroupData)[]) => {
    if (typedValue === undefined) return options
    return options.filter((element) => {
      const elementValue = element?.value?.toString().toLowerCase()
      const formattedTypedValue = typedValue.toLocaleLowerCase()
      const conditional = elementValue?.includes(formattedTypedValue)
      return conditional
    })
  }

  const handleSelect = (event: React.KeyboardEvent<HTMLDivElement>, options: (OptionData | OptionGroupData)[]) => {
    const { current, typedValue } = getEventVariables(event)
    const selectedPosition = current?.replace(/^(.*?)_list_/, '')
    const filterTyped = getFilterByTyped(typedValue, options)
    const filter = filterTyped?.filter((element) => filterTyped?.indexOf(element)?.toString() === selectedPosition)

    if (selectedPosition === '-1' && !filter[0]?.value) {
      return undefined
    } else {
      return filter[0]?.value
    }
  }

  return (
    <AntdSelect
      {...rest}
      onChange={onChange}
      onKeyUp={(e: React.KeyboardEvent<HTMLDivElement>) => {
        const { options, children } = rest
        const noptions = getOptions(options, children)
        if (e.key !== 'Enter' && e.key !== 'Tab' && onChange && noptions) {
          onChange(handleSelect(e, noptions))
        }
      }}
    />
  )
}

Select.Option = AntdSelect.Option

export default Select

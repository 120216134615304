import React from 'react'

export default function SearchCnpjSVG () {
  return (
    <svg width="209" height="194" viewBox="0 0 209 194" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M140.244 137.529H65.6079C59.3343 137.529 54.2305 132.425 54.2305 126.152V51.5156C54.2305 45.242 59.3343 40.1382 65.6079 40.1382H140.244C146.517 40.1382 151.621 45.242 151.621 51.5156V126.152C151.621 132.425 146.517 137.529 140.244 137.529ZM65.6079 41.9586C60.3297 41.9586 56.0509 46.2374 56.0509 51.5156V126.152C56.0509 131.43 60.3297 135.709 65.6079 135.709H117.889C135.513 135.709 149.801 121.421 149.801 103.796V51.5156C149.801 46.2374 145.522 41.9586 140.244 41.9586L65.6079 41.9586Z" fill="#E0E5D0"/>
      <path d="M73.6561 49.72C70.1237 49.72 67.25 52.5936 67.25 56.1261V66.1531C67.25 69.6855 70.1237 72.5592 73.6561 72.5592H83.6831C87.2156 72.5592 90.0893 69.6855 90.0893 66.1531V56.1261C90.0893 52.5936 87.2156 49.72 83.6831 49.72H73.6561Z" fill="#E0E5D0"/>
      <path d="M73.6561 77.9473C70.1237 77.9473 67.25 80.8209 67.25 84.3534V94.3804C67.25 97.9128 70.1237 100.787 73.6561 100.787H83.6831C87.2156 100.787 90.0893 97.9128 90.0893 94.3804V84.3534C90.0893 80.8209 87.2156 77.9473 83.6831 77.9473H73.6561Z" fill="#E0E5D0"/>
      <path d="M73.6561 106.175C70.1237 106.175 67.25 109.048 67.25 112.581V122.608C67.25 126.14 70.1237 129.014 73.6561 129.014H83.6831C87.2156 129.014 90.0893 126.14 90.0893 122.608V112.581C90.0893 109.048 87.2156 106.175 83.6831 106.175H73.6561Z" fill="#E0E5D0"/>
      <path d="M80.3024 25.2563C75.8514 25.2563 72.2305 28.8773 72.2305 33.3283V45.9626C72.2305 50.4136 75.8514 54.0345 80.3024 54.0345H92.9367C97.3877 54.0345 101.009 50.4136 101.009 45.9626V33.3283C101.009 28.8773 97.3877 25.2563 92.9367 25.2563H80.3024Z" fill="#00441F"/>
      <path d="M121.9 60.564C117.449 60.564 113.828 64.1849 113.828 68.6359V81.2702C113.828 85.7212 117.449 89.3421 121.9 89.3421H134.534C138.985 89.3421 142.606 85.7212 142.606 81.2702V68.6359C142.606 64.1849 138.985 60.564 134.534 60.564H121.9Z" fill="#00441F"/>
      <path d="M103.816 187.733L101.249 187.712L100.113 177.581L103.901 177.613L103.816 187.733Z" fill="#A0616A"/>
      <path d="M95.5938 191.815C95.5882 192.446 96.0975 192.966 96.7315 192.972L101.835 193.015L102.728 191.204L103.056 193.024L104.982 193.041L104.495 186.55L103.826 186.505L101.093 186.317L100.211 186.258L100.196 188.095L96.0966 190.875C95.7847 191.087 95.5969 191.438 95.5938 191.815Z" fill="#2F2E41"/>
      <path d="M116.875 187.733L114.308 187.712L113.172 177.581L116.96 177.613L116.875 187.733Z" fill="#A0616A"/>
      <path d="M108.652 191.815C108.647 192.446 109.156 192.966 109.79 192.972L114.893 193.015L115.787 191.204L116.115 193.024L118.04 193.041L117.554 186.55L116.884 186.505L114.152 186.317L113.27 186.258L113.255 188.095L109.155 190.875C108.843 191.087 108.656 191.438 108.652 191.815Z" fill="#2F2E41"/>
      <path d="M93.7426 105.825L104.147 106.154L114.003 108.344C114.003 108.344 113.017 109.549 116.303 112.834C116.303 112.834 120.684 120.5 117.617 127.947L116.303 157.736C116.303 157.736 120.135 178.735 117.207 185.418L113.338 185.66L106.79 157.978L103.599 128.167L102.066 157.517C102.066 157.517 107.931 177.662 104.228 185.66L100.037 185.418L92.8665 157.517C92.8665 157.517 89.8001 125.319 90.2381 119.186C90.6762 113.053 93.7426 105.825 93.7426 105.825Z" fill="#2F2E41"/>
      <path d="M128.685 58.7553C127.263 59.1235 126.409 60.575 126.777 61.9973C126.946 62.649 127.343 63.1792 127.86 63.5294L127.86 63.5331L127.658 68.4201L131.398 70.1508L131.696 62.6305L131.67 62.6313C131.995 62.0536 132.107 61.3556 131.927 60.6636C131.559 59.2414 130.108 58.387 128.685 58.7553Z" fill="#A0616A"/>
      <path d="M107.782 76.1968C106.997 75.4181 106.541 74.3623 106.524 73.2142C106.501 71.674 107.273 70.2761 108.589 69.4749C110.104 68.5521 111.998 68.6455 113.415 69.7124L125.159 78.5545L127.252 65.8168L131.744 67.263L130.672 83.8493C130.597 85.0095 129.941 86.0316 128.918 86.5833C127.895 87.1351 126.681 87.1213 125.67 86.5464L108.699 76.8907C108.358 76.6967 108.051 76.4628 107.782 76.1968Z" fill="#41563D"/>
      <path d="M89.8751 51.6304C90.6258 52.8932 90.2106 54.5256 88.9477 55.2763C88.3691 55.6203 87.7134 55.7169 87.1 55.6019L87.0975 55.6046L83.8001 59.2171L79.9258 57.8131L85.0085 52.2625L85.0264 52.2814C85.2029 51.6428 85.6147 51.0683 86.2291 50.703C87.492 49.9523 89.1243 50.3675 89.8751 51.6304Z" fill="#A0616A"/>
      <path d="M91.2985 78.58L72.4483 73.4882C71.3259 73.185 70.454 72.34 70.1157 71.2276C69.7774 70.1153 70.0314 68.9279 70.7949 68.0511L81.7114 55.5177L85.9199 57.6537L78.4379 68.1726L93.0029 70.1616C94.7604 70.4017 96.1716 71.6693 96.5979 73.3913C96.9681 74.8865 96.532 76.4227 95.4314 77.5003C94.6109 78.3036 93.5439 78.7325 92.4378 78.7324C92.0599 78.7324 91.6772 78.6823 91.2985 78.58Z" fill="#41563D"/>
      <path d="M118.172 110.208L91.8125 111.417C91.0886 110.317 91.2846 108.664 92.2985 106.594C96.1266 98.7782 93.2193 83.9918 91.2636 76.2925C90.7148 74.1323 92.1879 71.9847 94.4056 71.7629L96.891 71.5144L99.1884 64.5012H107.235L110.573 68.3148L116.238 71.7562C115.207 82.3774 110.813 97.3755 117.072 104.897C118.441 106.542 118.829 108.321 118.172 110.208Z" fill="#41563D"/>
      <path d="M102.189 64.6632C106.517 64.6632 110.026 61.1544 110.026 56.826C110.026 52.4976 106.517 48.9888 102.189 48.9888C97.8604 48.9888 94.3516 52.4976 94.3516 56.826C94.3516 61.1544 97.8604 64.6632 102.189 64.6632Z" fill="#A0616A"/>
      <path d="M111.058 51.8094C112.148 47.8339 100.264 44.4249 97.9548 47.6264C97.6337 47.1712 96.4469 46.8947 95.9024 47.0344C95.3578 47.174 94.8999 47.523 94.4531 47.8609C93.8387 48.3328 93.2021 48.8215 92.8251 49.5001C92.4453 50.1758 92.3867 51.0974 92.8977 51.6838C93.3026 52.1501 94.0141 54.6282 94.6229 54.751C95.0473 54.8376 95.4047 54.9074 95.7119 54.9577C95.9856 54.5584 96.6834 54.0568 96.6332 53.5737C97.0464 53.8473 96.8955 54.3288 96.8121 54.8209C96.5347 56.4594 90.2836 69.1435 93.8639 65.4058C94.2185 65.6152 94.657 65.8107 95.1624 65.9894C96.0196 64.6965 96.7233 63.1718 97.1869 61.5773L97.1902 61.6068C97.3498 62.9924 98.3624 64.1294 99.7021 64.5175C105.076 66.0745 109.424 63.7899 110.919 59.67C110.372 58.5586 110.133 58.6793 110.182 58.6463C110.865 58.1857 111.805 58.4869 112.098 59.2569C112.185 59.4839 112.264 59.6777 112.332 59.8236C113.111 57.0367 114.038 57.439 111.058 51.8094Z" fill="#2F2E41"/>
      <path d="M107.348 48.925L106.799 46.1185C106.753 45.8826 106.709 45.6266 106.816 45.411C106.953 45.134 107.304 45.0192 107.61 45.0634C107.915 45.1075 108.144 45.3868 108.448 45.4405C109.506 45.6275 110.903 44.583 111.282 47.2254C111.44 48.3271 113.198 48.4435 113.785 49.3891C114.373 50.3346 114.445 51.6994 113.643 52.4719C113.003 53.0888 111.976 53.1601 111.145 52.8449C110.313 52.5297 109.646 51.8932 109.064 51.2219C108.481 50.5506 107.953 49.8242 107.281 49.2424" fill="#2F2E41"/>
      <path d="M111.475 53.1938C109.331 52.8942 107.869 52.1143 107.131 50.8758C106.164 49.2547 106.812 47.4696 106.84 47.3944L107.293 47.5629C107.287 47.5793 106.7 49.2134 107.549 50.6324C108.209 51.7362 109.553 52.4369 111.542 52.7149L111.475 53.1938Z" fill="#00441F"/>
      <path d="M121.54 22.8687C115.235 22.8687 110.105 17.7389 110.105 11.4344C110.105 5.12983 115.235 0 121.54 0C127.844 0 132.974 5.12983 132.974 11.4344C132.974 17.7389 127.844 22.8687 121.54 22.8687ZM121.54 4.57375C117.757 4.57375 114.679 7.6512 114.679 11.4344C114.679 15.2175 117.757 18.295 121.54 18.295C125.323 18.295 128.4 15.2175 128.4 11.4344C128.4 7.6512 125.323 4.57375 121.54 4.57375Z" fill="#00441F"/>
      <path d="M139.132 30.6395C138.572 30.6395 138.01 30.4351 137.569 30.0208L126.837 19.9633C125.915 19.1001 125.868 17.653 126.732 16.7317C127.594 15.8072 129.041 15.7614 129.964 16.6268L140.696 26.6843C141.618 27.5475 141.665 28.9947 140.801 29.9159C140.35 30.3972 139.742 30.6395 139.132 30.6395Z" fill="#00441F"/>
    </svg>
  )
}


import { Spin } from 'antd'
import React from 'react'
interface ICardProps {
  children: React.ReactElement | React.ReactNode
  loading?: boolean
}

function SimulatorCard ({ children, loading }: ICardProps) {
  return (
    <div className="position-relative flex flex-justify-center flex-align-center" style={loading ? { pointerEvents: 'none' } : {}}>
      {loading && (
        <div className="simulator-card-loading">
          <div className="flex flex-justify-center flex-align-center h-100">
            <Spin size='large' />
          </div>
        </div>
      )}

      <div className="simulator-card-fields">
        <div className="simulator-inputs">
          {children}
        </div>
      </div>
    </div>
  )
}

export default SimulatorCard

import React, { Fragment, useState } from 'react'
import { Button, Form, Input } from 'antd'
import { user } from 'egi/consumers'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'

interface IChangePasswordErrors {
  oldPass?: string
  password?: string
  confirmPassword?: string
}

function ChangePasswordModal () {
  const [errors, setErrors] = useState<IChangePasswordErrors>({})
  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = async (values: { oldPass: string, password: string, confirmPassword: string }) => {
    setLoading(true)
    setErrors({})
    try {
      const response = await user.password.authReset(values)
      swal.basic({ title: 'Sucesso', icon: 'success', text: response.message })
    } catch (err) {
      if (err.data?.invalid) setErrors(validateResponse(err.data?.invalid))
      swal.basic({ title: 'Atenção', text: err.message, icon: 'warning' })
    }
    setLoading(false)
  }

  return (
    <Fragment>
      <Form
        layout="vertical"
        className="p-3"
        onFinish={onSubmit}
      >
        <Form.Item
          name='oldPass'
          rules={[{
            required: true,
            message: 'Digite sua senha atual!'
          }]}
          help={errors.oldPass && (errors.oldPass)}
          validateStatus={errors.oldPass && ('error')}
          label='Senha atual'
        >
          <Input
            placeholder='Insira a senha atual'
            type='password'
          />
        </Form.Item>

        <Form.Item
          rules={[{
            required: true,
            message: 'Digite sua nova senha!'
          }]}
          help={errors.password && (errors.password)}
          validateStatus={errors.password && ('error')}
          name='password'
          label='Nova senha'
        >
          <Input
            placeholder='Insira nova senha'
            type='password'
          />
        </Form.Item>

        <Form.Item
          rules={[{
            required: true,
            message: 'Repita sua senha!'
          }]}
          help={errors.confirmPassword && (errors.confirmPassword)}
          validateStatus={errors.confirmPassword && ('error')}
          name='confirmPassword'
          label='Confirmação de senha'
        >
          <Input
            type='password'
            placeholder='Insira nova senha'
          />
        </Form.Item>

        <Button
          loading={loading}
          type="primary"
          className='w-100'
          htmlType='submit'
        >
          Alterar senha
        </Button>
      </Form>
    </Fragment>
  )
}

export default ChangePasswordModal

import { IErrorDetails } from 'components/ErrorDetails/interfaces'
import { useHistoryTable } from 'components/ServerTable/hooks/useServerTable'
import RHRepository from 'ecp/repositories/RHRepository'
import useFindPath from 'hooks/useFindPath'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ECP } from 'routes/ecp/constants'
import { RHCovenantSelectProps } from '../components/RHCovenantSelect/RHCovenantSelect'
import { IPendingUserCovenants } from '../rhInterfaces'

interface IParams {
  page: number
  limit: string
}

export const usePendingUserCovenants = ({ onSelect }: { onSelect: RHCovenantSelectProps['onSelect'] }) => {
  const [loading, setLoading] = useState(false)
  const [covenants, setCovenants] = useState<IPendingUserCovenants[]>([])
  const [error, setError] = useState<IErrorDetails>()
  const [totalDocs, setTotalDocs] = useState(0)
  const [params, setParams] = useState<IParams>({
    page: 1,
    limit: '10'
  })

  useEffect(() => {
    const abortController = new AbortController()

    async function getPendingUserCovenants () {
      setLoading(true)
      try {
        const response = await RHRepository.getPendingUserCovenants({
          params: params,
          signal: abortController.signal
        })

        const covenants = response?.data?.data?.covenants?.docs || []
        if (covenants?.length < 1) throw new Error('Não foi encontrado nenhum convênio')

        if (covenants?.length === 1) {
          const { user_funcao } = covenants?.[0]
          const { id, entidade } = user_funcao || {}

          onSelect(id, entidade?.nome)
        }

        if (response?.data?.data?.covenants?.totalDocs) setTotalDocs(response?.data?.data?.covenants.totalDocs)
        setCovenants(covenants)
        setLoading(false)
      } catch (err) {
        setError(err)
        if (!abortController.signal.aborted) {
          setCovenants([])
        }
      } finally {
        setLoading(false)
      }
    }

    getPendingUserCovenants()
    return () => abortController.abort()
  }, [
    params
  ])

  const onUpdateParams = (params: Partial<IParams>) => {
    setParams(prev => ({ ...prev, ...params }))
  }

  return {
    loading,
    covenants,
    error,
    totalDocs,
    params,
    onUpdateParams
  }
}

export const useTransferPendingUserOnidataRedirect = () => {
  const { findPath } = useFindPath()
  const history = useHistory()
  const historyTable = useHistoryTable()

  function onSelect (id: string, name: string) {
    if (findPath(ECP.CLIENTS_PENDING_ONIDATA)) {
      const path = `/auth${ECP.CLIENTS_PENDING_ONIDATA.path.replace(':covenantId', id)}`
      const covenantSelected = { name, id }

      history.push({ pathname: path, state: covenantSelected })
      historyTable.setFiltersValues(ECP.CLIENTS_PENDING_ONIDATA.path)
    }
  }

  return {
    onSelect
  }
}

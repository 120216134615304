import React, { useState } from 'react'
import unauthed, { CreditStatus } from 'egi/consumers/unauthed'
import { CreditErrorIcon, CreditSuccessIcon } from '../../views/CreditApprove'
import { Button, Col, Form, Input, Row, Select, Image } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons/lib/icons'
import { BasicCreditProps } from '../../creditApproveInterfaces'
import { useProposal } from 'hooks'
import { proposals } from 'egi/consumers'
import swal from 'utils/swal'
import { modalReset } from 'store/modules/modal/actions'
import { useDispatch } from 'react-redux'
import imageUrls from 'utils/imagesUrls'

const reprovedReasons = [
  'Crédito abaixo do esperado',
  'Tenho proposta com melhor taxa',
  'Não concordo com as condicionantes',
  'Gostaria de um prazo maior',
  'Outro(s)'
]

function CreditRefusedStep ({ changeCurrent, changeUserResponse, token }: BasicCreditProps) {
  const [selectType, setSelectType] = useState<string>('')
  const [loading, setLoading] = useState(false)

  const proposal = useProposal()
  const dispatch = useDispatch()

  const onSubmit = async (values: { reprovedReason?: string, otherReprovedReason?: string }) => {
    let request

    if (!token && proposal.id) {
      const body = {
        proposalId: proposal.id,
        data: {
          status: 'reproved' as CreditStatus,
          ...values
        }
      }
      request = proposals.creditResponse(body)
    }

    if (token && !proposal.id) {
      const body = {
        ...values,
        token: token as string,
        status: 'reproved' as CreditStatus
      }
      request = unauthed.creditApprove.creditResponse(body)
    }

    setLoading(true)
    try {
      const response = await request

      const info = { title: 'Sucesso', text: response.message }
      if (proposal.id) {
        if (proposal.callback) proposal.callback()
        swal.basic({ ...info, icon: 'success' })
        dispatch(modalReset())
      }
      if (!proposal.id) {
        changeUserResponse({ ...info, icon: CreditSuccessIcon })
        changeCurrent(2)
      }
      if (!token && proposal.id) swal.basic({ title: 'Sucesso', text: response.text, icon: 'success' })
    } catch (err) {
      if (err.message) {
        const errorInfo = { title: 'Atenção', text: err.message }

        if (proposal.id) swal.basic({ ...errorInfo, icon: 'warning' })
        if (!proposal.id) {
          changeUserResponse({ ...errorInfo, icon: CreditErrorIcon })
          changeCurrent(2)
        }
      }
    }
    setLoading(false)
  }

  return (
    <Form className="text-center" layout="vertical" onFinish={onSubmit}>
      <div>
        <ArrowLeftOutlined style={{ fontSize: 28 }} className="color-primary mr-3 display-inline-block" onClick={() => changeCurrent(0)} />
        <h1 className="color-primary credit-modal-title display-inline-block">Qual o motivo da recusa ?</h1>
      </div>
      <div>
        <Image
          src={imageUrls.ornament}
          preview={false}
        />
      </div>

      <div className="flex flex-justify-center">
        <Row gutter={[15, 15]} className="text-center w-75">
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="reprovedReason"
              label={<label className="simulator-label">Motivo</label>}
            >
              <Select
                style={{ textAlign: 'left' }}
                onChange={(value: CreditStatus) => setSelectType(value)}
                placeholder="Escolha o motivo"
                className="unauth-inputs w-100"
                dropdownStyle={{ border: '1px solid #00441F', borderRadius: 24 }}
              >
                {reprovedReasons.map((item, index) => (
                  <Select.Option key={index} value={item}>{item}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {selectType === 'Outro(s)' && (
            <Col lg={24} md={24} sm={24} xs={24}>
              <Form.Item
                name="otherReprovedReason"
                label={<label className="simulator-label">Seu motivo</label>}
              >
                <Input className="unauth-inputs w-100" placeholder="Escreva seu motivo" />
              </Form.Item>
            </Col>
          )}

          <Col lg={24} md={24} sm={24} xs={24}>
            <Button
              loading={loading}
              type="default"
              htmlType="submit"
              className="unauth-button credit-base-button w-100"
            >
              ENVIAR
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  )
}

export default CreditRefusedStep

import React, { useState, useEffect, Fragment } from 'react'
import { columns } from './dataTable'
import { Table } from 'components'
import { enhanceTable } from 'components/Table/tableEnhance'
import { Button } from 'antd'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'hooks'
import swal from 'utils/swal'
import RepresentativeRepository from 'egi/repositories/RepresentativeRepository'
import { IAttorney } from '../../attorneyInterfaces'
import AttorneyModel from 'egi/models/AttorneyModel'

function AttorneysList () {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IAttorney[]>()
  const [finalColmun, setFinalColumn] = useState<Object[]>(columns(() => {}))

  const history = useHistory()
  const user = useAuth()

  const handleDelete = (id: string, name: string) => {
    swal.confirmNegate({
      title: 'Atenção',
      text: `Tem certeza que deseja deletar o procurador ${name}?`,
      icon: 'warning',
      confirm: async () => {
        if (id) {
          try {
            const response = await RepresentativeRepository.delete(id)
            swal.basic({ title: 'Sucesso!', icon: 'success', text: response.data?.message })
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            getAttorneys()
          } catch (err) {
            if (err.date?.message) {
              swal.basic({ title: 'Atenção', text: err.date.message, icon: 'warning' })
              return
            }
            swal.basic({ title: 'Atenção', icon: 'warning', text: err.message })
          }
        }
      }
    })
  }

  async function getAttorneys () {
    setLoading(true)
    try {
      const response = await RepresentativeRepository.attorneysList()
      const attorneis = response.data.data?.representatives
      const [formatedValues, formatedColums] = enhanceTable(attorneis || [], columns(handleDelete))

      setData(formatedValues)
      setFinalColumn(formatedColums)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    getAttorneys()
  }, [])

  return (
    <Table
      filters={
        <Fragment>
          {AttorneyModel.canAddAttorney(user.level) && (
            <Button
              className="w-100"
              type="primary"
              onClick={() => history.push('/auth/attorney/create')}
            >
              Adicionar novo procurador
            </Button>
          )}
        </Fragment>
      }
      data={data}
      columns={finalColmun}
      loading={loading}
    />
  )
}

export default AttorneysList

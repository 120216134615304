import { IFieldsOptions } from './formContractInterfaces'

const operationsRequiredFields: IFieldsOptions[] = [
  { id: 1, field: 'operacao_emprestimo_concedido', label: 'Emprestimo concedido', type: 'currency' },
  { id: 2, field: 'operacao_tarifa_avaliacao_bens', label: 'Tarifa avaliação bens', type: 'currency' },
  { id: 3, field: 'operacao_IOF', label: 'IOF', type: 'currency' },
  { id: 4, field: 'operacao_emprestimo_liberado', label: 'Empresimo liberado', type: 'currency' },
  { id: 5, field: 'operacao_prazo', label: 'Prazo' },
  { id: 6, field: 'operacao_quantidade_parcelas', label: 'Quantidade parcelas' },
  { id: 7, field: 'operacao_taxa_juros_mensal', label: 'Taxa juros mensal', type: 'percentage' },
  { id: 8, field: 'operacao_taxa_juros_anual', label: 'Taxa juros anual', type: 'percentage' },
  { id: 9, field: 'operacao_valor_parcela_mensal', label: 'Valor parcela mensal', type: 'currency' },
  { id: 10, field: 'operacao_CET_anual', label: 'CET anual', type: 'percentage' }
]

const operationsOptionalFields: IFieldsOptions[] = [
  { id: 1, field: 'operacao_custas_cartorarias', label: 'Tarifa de cadastro' },
  { id: 2, field: 'operacao_divida_junto_terceiro', label: 'Dívida junto ao terceiro' },
  { id: 3, field: 'operacao_pagamento_quitante', label: 'Saldo devedor do IQ' },
  { id: 4, field: 'operacao_recursos_livres', label: 'Liberação de recurso livre' },
  { id: 5, field: 'operacao_despesas_de_registro', label: 'Despesas de registro da garantia' },
  { id: 6, field: 'operacao_emissao_de_certificado', label: 'Emissão de certificado digital' },
  { id: 7, field: 'operacao_plataforma_eletronica', label: 'Plataforma eletrônica para envio do contrato' }
]

const formContractFields = (field: string, isPj: boolean | undefined): IFieldsOptions[] => {
  if (isPj) {
    return [
      { id: 1, field: field + 'razao_social', label: 'Razão Social' },
      { id: 2, field: field + 'CNPJ', label: 'CNPJ', mask: 'cnpj' },
      { id: 3, field: field + 'email', label: 'Email' }
    ]
  }
  return [
    { id: 1, field: field + 'nome', label: 'Nome' },
    { id: 2, field: field + 'CPF', label: 'CPF', mask: 'cpf' },
    { id: 3, field: field + 'email', label: 'Email' },
    { id: 4, field: field + 'nacionalidade', label: 'Nacionalidade' },
    { id: 5, field: field + 'filiacao', label: 'Nome da mãe' },
    { id: 6, field: field + 'filiacao_pai', label: 'Nome do pai' },
    { id: 7, field: field + 'profissao', label: 'Profissão' },
    { id: 8, field: field + 'tipo_identidade', label: 'Tipo de Identidade' },
    { id: 9, field: field + 'identidade', label: 'Identidade' },
    { id: 10, field: field + 'expedidor', label: 'Orgão expedidor' },
    { id: 11, field: field + 'local_expedicao', label: 'Orgão expedidor | UF' },
    { id: 12, field: field + 'data_expedicao', label: 'Data de expedição', type: 'date' },
    { id: 13, field: field + 'estado_civil', label: 'Estado civil' }
  ]
}

const fieldsString = (field: string): IFieldsOptions[] => [
  { id: 1, field: field + 'nome', label: 'Nome' },
  { id: 2, field: field + 'email', label: 'Email' },
  { id: 3, field: field + 'nacionalidade', label: 'Nacionalidade' },
  { id: 4, field: field + 'filiacao', label: 'Nome da mãe' },
  { id: 5, field: field + 'filiacao_pai', label: 'Nome do pai' },
  { id: 6, field: field + 'profissao', label: 'Profissão' },
  { id: 7, field: field + 'tipo_identidade', label: 'Tipo de Identidade' },
  { id: 8, field: field + 'identidade', label: 'Identidade' },
  { id: 9, field: field + 'expedidor', label: 'Orgão expedidor' },
  { id: 10, field: field + 'local_expedicao', label: 'Orgão expedidor | UF' },
  { id: 11, field: field + 'data_expedicao', label: 'Data de expedição', type: 'date' },
  { id: 12, field: field + 'CPF', label: 'CPF', mask: 'cpf' },
  { id: 13, field: field + 'estado_civil', label: 'Estado civil' }
]

const conjugeFieldsString = (field: string): IFieldsOptions[] => [
  { id: 1, field: field + 'nome_conjuge', label: 'Nome' },
  { id: 2, field: field + 'email_conjuge', label: 'Email' },
  { id: 3, field: field + 'nacionalidade_conjuge', label: 'Nacionalidade' },
  { id: 4, field: field + 'filiacao_conjuge', label: 'Nome da mãe' },
  { id: 5, field: field + 'filiacao_pai_conjuge', label: 'Nome do pai' },
  { id: 6, field: field + 'profissao_conjuge', label: 'Profissão' },
  { id: 8, field: field + 'identidade_conjuge', label: 'Identidade' },
  { id: 9, field: field + 'expedidor_conjuge', label: 'Orgão expedidor' },
  { id: 10, field: field + 'local_expedicao_conjuge', label: 'Orgão expedidor | UF' },
  { id: 11, field: field + 'estado_civil_conjuge', label: 'Estado civil' },
  { id: 12, field: field + 'CPF_conjuge', label: 'CPF', mask: 'cpf' }
]

const operacaoDateFields: IFieldsOptions[] = [
  { id: 1, field: 'operacao_data_emprestimo', label: 'Data empréstimo' },
  { id: 2, field: 'operacao_vencimento_primeira_parcela', label: 'Vencimento primeira parcela' },
  { id: 3, field: 'operacao_vencimento_ultima_parcela', label: 'Vencimento última parcela' }
]

const seguradoFieldsString = (field: string): IFieldsOptions[] => [
  { id: 1, field: field + '_segurado_cpf', label: 'Cpf Segurado', mask: 'cpf' },
  { id: 2, field: field + '_segurado_nome', label: 'Nome contrato' }
]

const seguroFieldsNumber: IFieldsOptions[] = [
  { id: 1, field: 'seguro_morte_invalidez_cesh', label: 'Seguro morte invalidez cesh', type: 'percentage' },
  { id: 2, field: 'seguro_danos_fisicos_cesh', label: 'Seguro danos físicos cesh', type: 'percentage' },
  { id: 3, field: 'seguro_danos_fisicos_valor_mensal', label: 'Seguro danos físicos valor mensal', type: 'currency' },
  { id: 4, field: 'seguro_morte_invalidez_valor_mensal', label: 'Seguro danos invalidez valor mensal', type: 'currency' }
]

const ccbParticulasresContainers: IFieldsOptions[] = [
  { id: 1, field: 'ccb_particulares_emitente_cnd_federais', title: 'Ccb particulares emitente cnd federais' },
  { id: 2, field: 'ccb_particulares_emitente_cnd_trabalhista', title: 'Ccb particulares emitente cnd trabalhista' },
  { id: 3, field: 'ccb_particulares_imovel_cnd_municipais', title: 'Ccb particulares imóvel cnd municipais' }
]

const ccbParticularesFields = (field: string): IFieldsOptions[] => [
  { id: 1, field: field + '_emissao', label: 'Data emissão' },
  { id: 2, field: field + '_validade', label: 'Data validade' }
]

const ccbParticularesWitness = (field: string): IFieldsOptions[] => [
  { id: 1, field: field + 'nome', label: 'Nome' },
  { id: 2, field: field + 'rg', label: 'RG', mask: 'rg' },
  { id: 3, field: field + 'local_de_expedicao', label: 'Local de expedição', type: 'uf' },
  { id: 4, field: field + 'cpf', label: 'CPF', mask: 'cpf' },
  { id: 5, field: field + 'estado_civil', label: 'Estado civil' }
]

const ccbParticularesNumbers: IFieldsOptions[] = [
  { id: 1, field: 'ccb_particulares_tarifa_valor_total', label: 'Valor total tarifa', type: 'currency' },
  { id: 2, field: 'ccb_particulares_tarifa_valor_pago_pelo_cliente', label: 'Valor tarifa paga pelo cliente', type: 'currency' },
  { id: 3, field: 'ccb_particulares_pagamento_quitante_valor_pagamento', label: 'Pagamento quitante valor pagamento', type: 'currency' }
]

const valuesFormatToDate = [
  'data_de_nascimento', 'data_de_nascimento_conjuge', 'titular_data_do_casamento', 'titular_data_de_nascimento', 'titular_data_de_fundacao', 'titular_data_da_ultima_alteracao', 'titular_data_de_nascimento_conjuge', 'compositor_data_de_nascimento',
  'compositor_data_de_nascimento_conjuge', 'IQ_data_de_nascimento', 'IQ_data_de_nascimento_conjuge',
  'data_expedicao', 'titular_data_expedicao', 'compositor_data_expedicao',
  'IQ_data_expedicao', 'operacao_data_emprestimo', 'operacao_vencimento_primeira_parcela',
  'operacao_vencimento_ultima_parcela', 'onus_data',
  'ccb_particulares_emitente_cnd_federais_emissao', 'ccb_particulares_emitente_cnd_federais_validade',
  'ccb_particulares_emitente_cnd_trabalhista_emissao', 'ccb_particulares_emitente_cnd_trabalhista_validade',
  'ccb_particulares_imovel_cnd_municipais_emissao', 'ccb_particulares_imovel_cnd_municipais_validade',
  'ccb_particulares_imovel_certidao_acoes_reais_pessoais_emissao'
]

const valuesToFormatOnlyAlphaNumeric = [
  'ccb_particulares_primeiro_testemunha_cpf', 'ccb_particulares_primeiro_testemunha_rg',
  'ccb_particulares_segundo_testemunha_cpf', 'ccb_particulares_segundo_testemunha_rg'
]

const valuesFormatMoney = [
  'operacao_emprestimo_concedido', 'operacao_tarifa_cadastro',
  'operacao_tarifa_avaliacao_bens', 'operacao_IOF',
  'operacao_emprestimo_liberado', 'operacao_valor_parcela_mensal',
  'operacao_razao_decrescimo', 'seguro_danos_fisicos_valor_mensal',
  'seguro_morte_invalidez_valor_mensal', 'ccb_particulares_tarifa_valor_total',
  'ccb_particulares_tarifa_valor_pago_pelo_cliente', 'ccb_particulares_pagamento_quitante_valor_pagamento',
  'operacao_divida_junto_terceiro', 'operacao_despesas_de_registro',
  'operacao_custas_cartorarias', 'operacao_plataforma_eletronica',
  'operacao_emissao_de_certificado', 'operacao_recursos_livres'
]

const valuesFormatPercentage = [
  'operacao_taxa_juros_mensal', 'operacao_taxa_juros_anual',
  'operacao_CET_anual', 'seguro_danos_fisicos_cesh',
  'seguro_morte_invalidez_cesh', 'seguro_primeiro_segurado_porcentagem',
  'seguro_segundo_segurado_porcentagem', 'seguro_terceiro_segurado_porcentagem'
]

const valuesFormatRemakeSimulation = [
  'operacao_IOF', 'operacao_emprestimo_liberado',
  'operacao_valor_parcela_mensal', 'operacao_emprestimo_concedido',
  'operacao_despesas_de_registro'
]

export {
  valuesFormatPercentage,
  valuesFormatMoney,
  valuesFormatToDate,
  valuesToFormatOnlyAlphaNumeric,
  valuesFormatRemakeSimulation,
  ccbParticularesNumbers,
  ccbParticularesFields,
  ccbParticularesWitness,
  ccbParticulasresContainers,
  operationsRequiredFields,
  operationsOptionalFields,
  formContractFields,
  fieldsString,
  conjugeFieldsString,
  operacaoDateFields,
  seguradoFieldsString,
  seguroFieldsNumber
}

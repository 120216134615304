import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { Link } from 'react-router-dom'
import { IShowCreateProposal } from '../../clientInterfaces'
import commonColumns from 'components/UserTable/utils/commonColumns'
import { Tag } from 'antd'
import { colors } from 'styles/colors'
import translate from 'utils/translate'
import { _proposalStatuses } from 'egi/app/ProposalEgi/proposalEgiInterfaces'

interface IClientsCorbanColumns {
  name: string,
  email: _proposalStatuses,
  status: string,
  number: string
  _id: string
}

const clientColumnsFunc = (showCreateProposalModal: ({ id, row }: IShowCreateProposal) => void): ColumnsType<IClientsCorbanColumns> => {
  const columns: ColumnsType<IClientsCorbanColumns> = [
    {
      title: 'Proposta',
      dataIndex: 'proposalId',
      align: 'center',
      width: 70,
      render: (proposalId: string, record : IClientsCorbanColumns) => {
        if (proposalId) {
          return (
            <Link
              className="p-0 link-style color-tertiary font-500"
              to={`/auth/proposals/show/${proposalId}`}
            >
              {record.number}
            </Link>
          )
        }

        return (
          <Link
            to='#'
            type="link"
            className="p-0 link-style color-tertiary font-500"
            onClick={() => showCreateProposalModal({ id: String(record._id), row: (record as any) })}
          >
            Criar Proposta
          </Link>
        )
      }
    },
    {
      title: 'Status proposta',
      dataIndex: 'status',
      align: 'center',
      width: 120,
      sorter: true,
      render: (status) => {
        if (!status) return '-'
        return <Tag color={colors[status]}>{translate.proposalStatus(status)}</Tag>
      }
    },
    commonColumns.name,
    commonColumns.email,
    commonColumns.cellphone,
    commonColumns.createdAt,
    {
      title: 'Nascimento',
      dataIndex: 'birthdate',
      align: 'center',
      width: 150,
      sorter: true
    }

  ]

  return columns
}

export { clientColumnsFunc }

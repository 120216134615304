import React from 'react'
import { Col, Form, Row } from 'antd'
import { Input } from 'components'
import { IEmployeeFields } from '../../clientInterfaces'
import locale from 'antd/es/date-picker/locale/pt_BR'
import { InputMoney } from 'components/IntlInput/IntlInput'
import Datepicker from 'components/Datepicker/Datepicker'
import { Dayjs } from 'dayjs'

export function EmployeeFields ({ errors, disabledFields, dataNascimento }: IEmployeeFields) {
  return (
    <Row gutter={[14, 14]}>
      <Col lg={8} sm={12} xs={24}>
        <Form.Item
          name='cargo'
          label='Cargo'
          help={errors?.cargo}
          validateStatus={errors?.cargo && ('error')}
        >
          <Input
            placeholder='Cargo'
            disabled={disabledFields.cargo}
          />
        </Form.Item>
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <Form.Item
          name='data_admissao'
          label='Data de admissão'
          help={errors?.data_admissao}
          validateStatus={errors?.data_admissao && ('error')}
        >
          <Datepicker
            disabledDate={(current: Dayjs) => {
              if (dataNascimento) current.isBefore(dataNascimento)
              return false
            }}
            locale={locale}
            disabled={disabledFields.data_admissao}
          />
        </Form.Item>
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <Form.Item
          name='salario'
          label='Salário'
          initialValue={0}
          help={errors?.salario}
          validateStatus={(errors?.salario || undefined) as undefined && ('error')}
        >
          <InputMoney
            placeholder='Insira o salário'
            disabled={disabledFields.salario}
          />
        </Form.Item>
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <Form.Item
          name='inss'
          initialValue={0}
          label='Desconto INSS'
          help={errors?.inss}
          validateStatus={(errors?.inss || undefined) as undefined && ('error')}
        >
          <InputMoney
            placeholder='Insira o desconto INSS'
            disabled={disabledFields.inss}
          />
        </Form.Item>
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <Form.Item
          name='irrf'
          initialValue={0}
          label='Desconto IRRF'
          help={errors?.irrf}
          validateStatus={(errors?.irrf || undefined) as undefined && ('error')}
        >
          <InputMoney
            placeholder='Insira o desconto IRRF'
            disabled={disabledFields.irrf}
          />
        </Form.Item>
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <Form.Item
          name='descontos'
          label='Descontos diversos'
          initialValue={0}
          help={errors?.descontos}
          validateStatus={(errors?.descontos || undefined) as undefined && ('error')}
        >
          <InputMoney
            placeholder='Insira os descontos diversos'
            disabled={disabledFields.descontos}
          />
        </Form.Item>
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <Form.Item
          name='comprometimento_outros'
          label='Compromentimento em outros'
          initialValue={0}
          help={errors?.comprometimento_outros}
          validateStatus={(errors?.comprometimento_outros || undefined) as undefined && ('error')}
        >
          <InputMoney
            placeholder='Insira o comprometimento em outros'
            disabled={disabledFields.comprometimento_outros}
          />
        </Form.Item>
      </Col>

      <Col lg={8} sm={12} xs={24}>
        <Form.Item
          name='matricula'
          label='Matrícula'
          help={errors?.matricula}
          validateStatus={errors?.matricula && ('error')}
        >
          <Input
            placeholder='Insira a matrícula'
            disabled={disabledFields.matricula}
          />
        </Form.Item>
      </Col>

    </Row>
  )
}

export default EmployeeFields

import { Button, Col, Form, Input, message } from 'antd'

import React, { useState } from 'react'

import { IChangeEmail } from '../../profileInterfaces'

import ChangeEmailToken from './ChangeEmailToken'
import EcpUsersRepository from 'ecp/repositories/EcpUsers'
import { MailOutlined } from '@ant-design/icons'
import { IError } from 'ecp/app/Proposals/proposalInterfaces'

interface IProps {
  setStep: (step: 'changeEmail' | 'verifyEmail') => void
  step: 'changeEmail' | 'verifyEmail'
  currentEmail: string
  setVisibleEmail: (visible: boolean) => void
  setNewEmail: (email: string)=> void
}

export default function ChangeEmailModal ({ step, setStep, currentEmail, setVisibleEmail, setNewEmail }: IProps) {
  const [loading, setLoading] = useState<boolean>()
  const [errors] = useState<IError<IChangeEmail>>({})
  const [email, setEmail] = useState<string>('')

  async function handleSubmit (values: IChangeEmail) {
    setLoading(true)

    try {
      await EcpUsersRepository.updateEmailOrPhone(values)

      setEmail(values.email)
      setStep('verifyEmail')
    } catch (err) {
      message.error(err.message)
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
      {
        step === 'changeEmail'
          ? <div className="change-email">
            <Form onFinish={handleSubmit} layout="vertical">
              <div className="change-email__header">
                <MailOutlined className="change-email__icon" />
                <h2 className="change-email__title">Alterar email</h2>
                <p className="change-email__subtitle">Deseja fazer a alteração do email&nbsp;
                  <span className="change-email__current-email">
                    {currentEmail}
                  </span>?
                </p>
              </div>
              <Col lg={24} sm={24} xs={24}>
                <Form.Item className="py-2"
                  name='email'
                  label='Novo email'
                  help={errors?.email}
                  validateStatus={errors?.email && ('error')}
                  rules={[{
                    required: true,
                    message: 'Digite seu Email'
                  }]}
                >
                  <Input placeholder='Digite seu novo email' />
                </Form.Item>
              </Col>
              <Button className="w-100"
                type="primary"
                htmlType="submit"
                loading={loading}>
                Alterar
              </Button>
            </Form>
          </div>
          : <ChangeEmailToken
            setNewEmail={setNewEmail}
            email={email}
            setStep={setStep}
            setVisibleEmail={setVisibleEmail} />
      }
    </>
  )
}

import { Button, Row } from 'antd'
import React, { Fragment, MutableRefObject, useRef } from 'react'
import DOMPurify from 'dompurify'

interface EditBoxProps {
  loading: boolean
  readOnly: boolean
  slug: string
  value: string
  visible: boolean
  setVisible: (value: boolean) => void
  onSubmit: () => void
}

export default function EditBox ({ loading, readOnly, slug, value, visible, setVisible, onSubmit }: EditBoxProps) {
  const divRef: MutableRefObject<HTMLDivElement | null> = useRef(null)

  function onCancel () {
    const div = document.getElementById(`editable-content-${slug}`) as HTMLDivElement
    div.innerHTML = value
    setVisible(false)
  }

  function modifyFontStyle (type: 'bold' | 'italic', event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.currentTarget.classList.forEach(item => {
      if (['contract-editable--active'].includes(item)) event.currentTarget.classList.remove('contract-editable--active')
      else event.currentTarget.classList.add('contract-editable--active')
    })

    document.execCommand(type)
    if (divRef && divRef.current) divRef.current.focus()
  }

  return (
    <Fragment>
      {visible && (
        <Row>
          <button
            className="contract-editable--bold"
            onClick={(event) => modifyFontStyle('bold', event)}
          >
            <b>B</b>
          </button>
          <button
            className="contract-editable--italic"
            onClick={(event) => modifyFontStyle('italic', event)}
          >
            <i>I</i>
          </button>
        </Row>
      )}

      <div
        ref={divRef}
        onClick={() => readOnly ? undefined : setVisible(true)}
        contentEditable={readOnly ? false : true}
        id={`editable-content-${slug}`}
        className="p-1 contract-editable"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value || '') }}
      />

      {visible && (
        <Row justify="end" className="my-3">
          <Button
            type="ghost"
            className="mr-2"
            onClick={() => onCancel()}
          >
            Cancelar
          </Button>

          <Button
            type="primary"
            loading={loading}
            onClick={() => onSubmit()}
          >
            Salvar edição
          </Button>
        </Row>
      )}
    </Fragment>

  )
}

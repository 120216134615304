import { AxiosResponse } from 'axios'
import { ConnectionFailed, ErrInternetDisconnected } from 'egi/consumers/errors'
import api from 'services/api'

const simulationCard = {
  feeReview: async (id: string, reason: string) => {
    try {
      const response: AxiosResponse = await api.post('proposals/revision/pricing', { proposalId: id, reason: reason })
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },
  changes: async (data: { monthlyTax: number, commission: number, proposalId: string }) => {
    try {
      const response: AxiosResponse = await api.post('proposals/revision/tax', data)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  },

  revalidateHistory: async (proposalId: string) => {
    try {
      const response: AxiosResponse = await api.get(`proposals/${proposalId}/revalidate/history`)
      return response.data
    } catch (err) {
      if (err.name === 'ERR_INTERNET_DISCONNECTED') {
        throw new ErrInternetDisconnected()
      }
      if (!err.response) {
        throw new ConnectionFailed()
      }
      throw err.response.data
    }
  }
}

export default simulationCard


import { RetweetOutlined } from '@ant-design/icons'
import { Button, Form, message, Select } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import AdministratorsRepostory from 'egi/repositories/AdministratorsRepostory'
import ResourcesRepository from 'egi/repositories/ResourcesRepository'
import { ITeamColumns } from 'egi/views/UsersTable/DataTables/teamColumns'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { tablesUpdate } from 'store/modules/tables/actions'
import swal from 'utils/swal'

interface IProps {
  row: Partial<ITeamColumns>
}

interface IAdmTransferForm {
  transfer: string
}

export default function TransferModal ({ row }: IProps) {
  const [visible, setVisible] = useState<boolean>(false)
  const [administrators, setAdministrators] = useState<any[]>()
  const [newAdministrator, setNewAdministrator] = useState<string>('Novo administrador')

  const [form] = Form.useForm()
  const location = useLocation()
  const dispatch = useDispatch()

  const regex = /\/invited\/(.+)$/
  const match = location.pathname.match(regex)

  const oldAdmin = match ? match[1] : 'Administrador antigo'

  const getAdministrator = async () => {
    try {
      const params = {
        administratorId: row.administratorId
      }
      const response = await ResourcesRepository.getAdministrators({ params })
      setAdministrators(response?.data?.data?.allAdministrators)
    } catch (err) {
      message.error(err.message)
    }
  }

  useEffect(() => {
    getAdministrator()
  }, [])

  const changeTeam = (form: IAdmTransferForm) => {
    swal.confirmNegate({
      title: 'Atenção!',
      text: 'Você realmente deseja realizar a transferência? Certifique-se que escolheu o administrador certo antes de realizar a ação.',
      icon: 'warning',
      confirm: async () => {
        try {
          const data = {
            telemarketingId: row._id ?? '-',
            administratorId: form.transfer ?? '-'
          }

          await AdministratorsRepostory.changeTeam(data)
          message.success('Transferência realizada com sucesso')
          dispatch(tablesUpdate())
        } catch (err) {
          message.error(err.message || 'Não foi possivel completar a transferência')
        }
      }
    })
  }

  return (
    <>
      <Modal title="Transferência de BackOffices" visible={visible} onCancel={() => setVisible(false)} footer={<></>}>

        <p>Selecione um novo administrador, não se esqueça de conferir sua escolha</p>

        <div className="transfer-modal__display-new-admin">
          <p><b>{oldAdmin}</b></p>
          <RetweetOutlined className="transfer-modal__icon" />
          <p><b>{newAdministrator}</b></p>
        </div>
        <Form
          layout='vertical'
          form={form}
          onFinish={changeTeam}
        >
          <Form.Item
            name="transfer"
            label={<label>Novo Administrador</label>}
          >
            <Select placeholder="Selecione o administrador" showSearch onSelect={(value) => {
              const adm = administrators?.find(administrator => administrator._id === value)
              setNewAdministrator(adm.name)
            }}>
              {administrators?.map((administrator) =>
                <Select.Option
                  value={administrator._id}
                  key={administrator._id}>
                  {administrator.name}
                </Select.Option>)
              }
            </Select>
          </Form.Item>
          <div className="transfer-modal__buttons">
            <Button type="primary" htmlType="submit" className="mr-2" >Enviar</Button>
            <Button type="ghost" onClick={() => setVisible(false)}>Cancelar</Button>
          </div>
        </Form>
      </Modal>
      <div className="link-style color-tertiary font-500 overflow-ellipsis" onClick={() => setVisible(true)}>
        Transferir <RetweetOutlined />
      </div>
    </>
  )
}

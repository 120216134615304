import { _typePromoterEnum, _userLevel } from 'ecp/models/UsersModel'
import { _tableAreas } from 'globals'

type ICanSeeSelectProps = {
  userLevel: _userLevel,
  userType?: _typePromoterEnum,
  areaName?: _tableAreas
}

type ICanSeeLinks = {
  userLevel: _userLevel,
  userType?: _typePromoterEnum,
  type?: string
}

type ICanSeeLeads = {
  userLevel: _userLevel,
  userType?: _typePromoterEnum,
  type?: string
}

type ICanSeeProviderTypes = {
  userLevel: _userLevel,
  userType?: _typePromoterEnum,
  areaName?: _tableAreas
}

const MlmDashboardModel = {
  canSeeSelect: ({ userLevel, userType, areaName } : ICanSeeSelectProps) => {
    if (userLevel === 'promoter' && userType === 'IN') return true
    if (userLevel === 'administrator') {
      const validType = userType === 'TM'
      const validAreaName = areaName === 'comercial'
      return validAreaName || validType
    }

    const levels = ['master', 'auditor', 'commercial']
    return levels.includes(userLevel)
  },

  canSeeLinks: ({ userLevel, userType, type } : ICanSeeLinks) => {
    const validType = type === 'IN' || type === undefined
    if (!validType) return false

    if (userLevel === 'promoter' && userType === 'IN') return true
    if (userLevel === 'administrator' && userType === 'TM') return true

    const levels = ['master', 'seller', 'auditor']
    return levels.includes(userLevel)
  },

  canSeeLeads: ({ userLevel, userType, type } : ICanSeeLeads) => {
    const validType = type === 'IN' || type === 'TM' || type === undefined
    if (!validType) return false

    if (userLevel === 'promoter') {
      return userType === 'IN' || userType === 'TM'
    }
    if (userLevel === 'administrator' && userType === 'TM') return true

    const levels = ['master', 'seller', 'auditor']
    return levels.includes(userLevel)
  },

  canSeeProviderTypes: ({ userLevel, userType, areaName } : ICanSeeProviderTypes) => {
    if (userLevel === 'administrator') {
      return areaName === 'comercial' || userType === 'TM'
    }

    const levels = ['master', 'auditor', 'commercial']
    return levels.includes(userLevel)
  },

  canSeeTopChart: (userLevel: _userLevel, sellerId?: string) => {
    return userLevel !== 'seller' && !sellerId
  }
}

export default MlmDashboardModel

import React, { Fragment } from 'react'
import { Form, Radio } from 'antd'

import DrawerFiltersLineCollapsable, { DrawerFiltersLineCollapsableItem } from 'components/DrawerFilters/DrawerFiltersLineCollapsable'
import translate from 'utils/translate'
import { useAuth } from 'hooks'
import ProposalEgiModel from 'egi/models/ProposalEgiModel'

function ActionFilter () {
  const user = useAuth()
  if (ProposalEgiModel.canSeeActionFilter(user.level, user.areaName)) {
    return (
      <div className='proposal-radio-filter'>
        <DrawerFiltersLineCollapsable label={`Ação ${translate.areasNames(user.areaName || '')}`}>
          <Form.Item
            name='hasAction'

          >
            <Radio.Group>
              <DrawerFiltersLineCollapsableItem key={''}>
                <Radio value={''}>Todos</Radio>
              </DrawerFiltersLineCollapsableItem>

              <DrawerFiltersLineCollapsableItem key='action'>
                <Radio value={true}>Ação</Radio>
              </DrawerFiltersLineCollapsableItem>

              <DrawerFiltersLineCollapsableItem key='no-action'>
                <Radio value={false}>Sem Ação</Radio>
              </DrawerFiltersLineCollapsableItem>

            </Radio.Group>
          </Form.Item>
        </DrawerFiltersLineCollapsable>
      </div>
    )
  }
  return <Fragment/>
}

export { ActionFilter }

import { Col } from 'antd'
import React, { ReactElement } from 'react'
import Action, { IAction } from './Action'

export interface ActionContainerProps {
  actions: IAction[]
  departmentName: string
  status?: string
}

export default function ActionsContainer ({ actions, departmentName, status }: ActionContainerProps): ReactElement {
  const actionsWeigth = {
    button: 1,
    label: 2,
    link: 3
  }

  const sortedActions = actions.sort((a: IAction, b: IAction) => {
    return actionsWeigth[a.type] - actionsWeigth[b.type]
  })

  return (
    <>
      {sortedActions.map(config => <Col key={config._id}><Action config={config} departmentName={departmentName} key={config._id} status={status}/></Col>)}
    </>
  )
}

import React from 'react'

import { useTables } from 'hooks'

import SearchFilterBySelect from 'components/SearchFilterBySelect/SearchFilterBySelect'
import { useDispatch } from 'react-redux'
import { tablesSetFiltersValues } from 'store/modules/tables/actions'

interface IFilterSearchIdentifier {
  onChange: (search: string | undefined, identifier: string | undefined) => void
  identifiers: Array<{value: string, label: string}>
  placeholder?: string
  large?: boolean
  label?: string
  borderless?: boolean
  autoFocus?: boolean
  onReset(): void
}

function FilterSearchIdentifier (props : IFilterSearchIdentifier) {
  const { onChange, identifiers, placeholder, large, borderless, label, autoFocus, onReset, ...rest } = props

  const tables = useTables()
  const dispatch = useDispatch()

  return (
    <SearchFilterBySelect
      identifier={tables.filters.identifier || undefined}
      options={identifiers}
      placeholder={placeholder ?? 'Pesquisar'}
      onChange={(search, item) => onChange(search, item)}
      large={large}
      borderless={borderless}
      label={label}
      autoFocus={autoFocus}
      onReset={() => {
        dispatch(tablesSetFiltersValues({ ...tables.filters, search: '' }))
        onReset()
      }}
      value={tables.filters?.search}
      {...rest}
    />
  )
}

export default FilterSearchIdentifier

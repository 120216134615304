
import DisplayTerms from 'components/DisplayTerms/DisplayTerms'
import { EcpCard } from 'ecp/components/EcpCard/EcpCard'
import React from 'react'
import BacenProposalText from '../Components/BacenProposalText'

function BacenTerms () {
  return (
    <EcpCard>
      <h3>
        <b>
          Autorização para consulta ao SCR
        </b>
      </h3>
      <DisplayTerms>
        <BacenProposalText />
      </DisplayTerms>
    </EcpCard>
  )
}

export default BacenTerms

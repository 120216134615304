import React, { Fragment, useEffect, useState } from 'react'
import { Drawer } from 'components'
import usePartnerStepDrawer from '../../../../hooks/usePartnerStepDrawer'
import { Form } from 'antd'
import FormPartner from './FormPartner/FormPartner'
import ExplainSend from 'components/ExplainSend/ExplainSend'
import { IAssociates, IFormPartner, IFormPartnerErrors } from './types'
import swal from 'utils/swal'
import { validateResponse } from 'utils/validate'
import format from 'utils/format'
import { useAuth } from 'hooks'
import { swalError } from 'components/SwalError/SwalError'
import usePartnerStep from '../../../../hooks/usePartnerStep'
import usePartnerDocumentation from '../../../../hooks/usePartnerDocumentation'
import ReviewStep from '../../../ReviewStep/ReviewStep'
import usePartnerDepartments from '../../../../hooks/usePartnerDepartments'
import { partnerFormFill } from 'egi/app/PartnersLine/partnersLineFunctions'
import PartnerStepButton from '../../../PartnerStepButton/PartnerStepButton'
import { correctTimezone } from 'utils/time'
import ReviewDescription from '../../../ReviewDescription/ReviewDescription'
import PartnerModel from 'egi/models/PartnerModel'
import PartnersRepository from 'egi/repositories/PartnersRepository'
import { invalidFieldsError } from 'components/InvalidFieldsError/InvalidFieldsError'
import translateAntForm from 'utils/translateAntForm'

function PartnerRegistrationStep () {
  const [errors, setErrors] = useState<IFormPartnerErrors>({})
  const [status, setStatus] = useState<'back' | 'saving' | 'sending'>()

  const { step } = usePartnerStep()
  const { close, open, visible } = usePartnerStepDrawer()
  const { _id: documentId } = usePartnerDocumentation()
  const { slug: departmentSlug } = usePartnerDepartments()
  const user = useAuth()
  const [formRef] = Form.useForm<IFormPartner>()
  const sendForm = PartnerModel.canSendForm(step)

  async function onSubmit (values: IFormPartner) {
    setStatus('sending')

    const data = {
      department: departmentSlug,
      step: step.slug,
      ...values
    }

    if (values.foundationDate) data.foundationDate = correctTimezone(values.foundationDate as string)

    if (values.phone) data.phone = format.onlyDigits(values.phone)

    if (values.associates) {
      data.associates = values.associates?.map((associate) => {
        if (!associate.personType) {
          if (associate.name && !associate.socialName) associate.personType = 'fisica'
          if (associate.socialName && !associate.name) associate.personType = 'juridica'
        }

        if (associate.foundationDate) associate.foundationDate = correctTimezone(associate.foundationDate as string)
        if (associate.birthdate) associate.birthdate = correctTimezone(associate.birthdate as string)

        return associate
      })
    }

    try {
      const response = await PartnersRepository.send(documentId, data)
      close()
      setErrors({})
      swal.basic({ icon: 'success', title: 'Sucesso!', text: response.data.message })
    } catch (err) {
      console.error(err)
      if (err.data?.invalid) {
        setErrors(validateResponse(err.data?.invalid))
        const invalidFields = translateAntForm.formatToSwalError(err.data?.invalid)
        invalidFieldsError({ invalidFields: invalidFields })
      } else {
        swalError({ title: 'Atenção', icon: 'warning', err })
      }
    }
    setStatus(undefined)
  }

  useEffect(() => {
    function onFill (form: IFormPartner) {
      const data = partnerFormFill(form, user)
      formRef.setFieldsValue(data)
    }

    onFill(step.form as IFormPartner)
  }, [step])

  function onChange (associateIdx: number) {
    const associates: IAssociates[] = formRef.getFieldValue('associates')
    const chooseAssociate = associates[associateIdx]
    if (chooseAssociate) {
      chooseAssociate.birthdate = undefined
      chooseAssociate.foundationDate = undefined
      chooseAssociate.name = undefined
      chooseAssociate.socialName = undefined
    }

    if (Array.isArray(associates) && associates.length > 0) {
      const mappedAssociations = associates.map((associate: IAssociates, idx: number) => {
        if (idx === associateIdx) return chooseAssociate

        return associate
      })

      formRef.setFieldsValue({ associates: mappedAssociations })
    }
  }

  return (
    <Fragment>
      <PartnerStepButton
        status={step.status}
        enabled={true}
        onClick={open}
        step={step}
      >
        {step.name}
      </PartnerStepButton>

      <Drawer
        visible={visible}
        onClose={close}
        onSubmit={() => formRef.submit()}
        showSubmit={true}
        disabled={!sendForm}
        title={step.name}
        status={status}
      >
        <Fragment>
          <FormPartner formRef={formRef} errors={errors} readonly={!sendForm} onChange={onChange} onSubmit={onSubmit} />

          <div className="my-4">
            <ExplainSend />
          </div>

          <div className="my-4">
            <ReviewDescription />
          </div>

          <ReviewStep onSuccess={close} />
        </Fragment>
      </Drawer>
    </Fragment>
  )
}

export default PartnerRegistrationStep

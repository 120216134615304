import React, { useLayoutEffect, useState } from 'react'
import { resources } from 'egi/consumers'
import { Form, Select } from 'antd'
import { useTables } from 'hooks'
import { useDispatch } from 'react-redux'
import { tablesPromoterSetFn } from 'store/modules/tables/actions'
import translate from 'utils/translate'

export type FilterPromoterProps = {
  onChange: Function
  queryTitle: 'promoterId' | 'indicatorId' | 'telemarketingId'
  types?: 'IN' | 'CB' | 'TM' | undefined
  onClear: () => void
}

function FilterPromoter ({ onChange, onClear, types = undefined, queryTitle = 'promoterId' }: FilterPromoterProps) {
  const [selectValues, setSelectValues] = useState([])
  const tables = useTables()
  const dispatch = useDispatch()

  const getPromoter = async () => {
    try {
      const response = await resources.promoters({ types: types })
      const promoters = response.data.promoters
      setSelectValues(promoters)
    } catch (err) {
      console.warn(err)
    }
  }

  useLayoutEffect(() => {
    getPromoter()
    dispatch(tablesPromoterSetFn(onChange))
    if (tables.filters.indicatorId) onChange(tables.filters[queryTitle])
  }, [])

  return (
    <Form.Item
      name={queryTitle}
      label={<label>Parceiro {types && translate.promoterType(types)}</label>}
    >
      <Select
        showSearch
        allowClear
        optionFilterProp="children"
        className="w-100"
        onChange={(value: any) => {
          onChange(value)
        }}
        onClear={onClear}
        placeholder='Selecione o parceiro'
      >
        <Select.Option value="">Todos</Select.Option>
        {selectValues.map((item: any) => (
          <Select.Option key={item._id} value={item._id}>{`${types !== undefined ? '' : `${item.type} -`} ${item.name}`}</Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

export default FilterPromoter

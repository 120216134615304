import React, { useState } from 'react'
import { Button, Modal, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { requestPricingIcon } from 'utils/globals'
import swal from 'utils/swal'
import Swal from 'sweetalert2'
import simulationCard from '../../consumer'
import { useProposal } from 'hooks'
import { useDispatch } from 'react-redux'
import { getProposalState } from 'store/modules/proposal/actions'
import RichTextEditor from 'components/RichTextEditor/RichTextEditor'

function RequestFeeModal ({ visible, onCancel }: {visible: boolean, onCancel: () => void}) {
  const [loading, setLoading] = useState<boolean>()
  const [richText, setRichText] = useState<string>()

  const proposal = useProposal()
  const dispatch = useDispatch()

  async function requestFeeReview (reason: string) {
    setLoading(true)
    try {
      if (!proposal.id) throw new Error('Falha ao encontrar id da proposta')
      const response = await simulationCard.feeReview(proposal.id, reason)
      swal.basic({ title: 'Sucesso!', text: response.message, icon: 'success' })
      dispatch(getProposalState(proposal.id))
      onCancel()
    } catch (error) {
      swal.basic({ title: 'Atenção!', text: error.message, icon: 'error' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      title='Revisão de Pricing'
      visible={visible}
      confirmLoading={loading}
      onCancel={onCancel}
      footer={[
        <Button
          key='back'
          type='ghost'
          onClick={onCancel}
        >
          Cancelar
        </Button>,
        <Button
          key='submit'
          type='primary'
          onClick={() => {
            if (!richText) return Swal.fire({ title: 'Atenção!', text: 'Por favor, digite o motivo da solicitação', icon: 'error' })

            requestFeeReview(richText)
          }}
        >
          {loading
            ? <Spin
              indicator={<LoadingOutlined spin />}
              style={{ color: '#FFFFFF' }} />
            : 'Solicitar Revisão'
          }
        </Button>
      ]}
    >
      <div className='simulation-card-review-modal'>
        <div className='simulation-card-review-modal__img'>
          <img
            src={requestPricingIcon}
            alt='Ícone de pricing'
          />
        </div>
        <h1>Essa tarefa não poderá ser refeita</h1>
        <label className='simulation-card-review-modal__label'>Motivo da solicitação:</label>
        <RichTextEditor
          setContents={richText}
          onChange={(value: string) => setRichText(value)}
        />
      </div>
    </Modal>
  )
}

export default RequestFeeModal

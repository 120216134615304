import loadable from '@loadable/component'
import Loading from 'components/Loading/Loading'
import { timeout } from 'promise-timeout'
import React from 'react'
import { TIMEOUT_LOADER } from 'utils/globals'

const RHList = loadable(
  () => timeout(import(/* webpackChunkName: "Rh" */ 'ecp/app/RH/views/RHList/RHList'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const RHTransferDetailing = loadable(
  () => timeout(import(/* webpackChunkName: "Rh" */ 'ecp/app/RH/views/RHTransferDetailing/RHTransferDetailing'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const RHTransfersController = loadable(
  () => timeout(import(/* webpackChunkName: "Rh" */ 'ecp/app/RH/views/RHTransfersController/RHTransfersController'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const RHTransfersControllerSelect = loadable(
  () => timeout(import(/* webpackChunkName: "Rh" */ 'ecp/app/RH/views/RHTransfersControllerSelect/RHTransfersControllerSelect'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const RHTransferPendingUser = loadable(
  () => timeout(import(/* webpackChunkName: "Rh" */ 'ecp/app/RH/views/RHTransferPendingUser/RHTransferPendingUser'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const RHTransferPrevAllotmentSelect = loadable(
  () => timeout(import(/* webpackChunkName: "Rh" */ 'ecp/app/RH/views/RHTransferPrevAllotmentSelect/RHTransferPrevAllotmentSelect'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const RHForm = loadable(
  () => timeout(import(/* webpackChunkName: "Rh" */ 'ecp/app/RH/views/RHForm/RHForm'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

const RHDetails = loadable(
  () => timeout(import(/* webpackChunkName: "Rh" */ 'ecp/app/RH/views/RHDetails/RHDetails'), TIMEOUT_LOADER),
  { fallback: <Loading/> }
)

export {
  RHList,
  RHTransferDetailing,
  RHTransfersController,
  RHTransfersControllerSelect,
  RHTransferPrevAllotmentSelect,
  RHTransferPendingUser,
  RHForm,
  RHDetails
}

import { Form, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import SignupLayout from 'layouts/SignupLayout/SignupLayout'
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { UNAUTHS_PATHS } from 'routes/unauthRoutes'
import { IError } from 'ecp/app/Proposals/proposalInterfaces'

import { Input } from 'components'

import SignupButton from 'egi/app/Signup/components/SignupButton/SignupButton'

import { SignupTitle } from 'egi/app/Signup/components/SignupTitle/SignupTitle'

import { ILGPDAcessSendEmail } from '../../LGPDAccessInterfaces'
import { handleOnidataErrors } from 'utils/validate'
import { swalError } from 'components/SwalError/SwalError'

function LGPDAccessCheckCnpj () {
  const [form] = useForm<ILGPDAcessSendEmail>()
  const [errors, setErrors] = useState<IError<ILGPDAcessSendEmail>>({})
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const history = useHistory()

  function redirectToNextStep (cnpj: string) {
    history.push({
      pathname: UNAUTHS_PATHS.LGPD_ACCESS_SEND_EMAIL,
      state: cnpj
    })
  }

  async function handleSubmit (values:ILGPDAcessSendEmail) {
    setErrors({})
    setLoadingSubmit(true)
    try {
      redirectToNextStep(values.cnpj)
    } catch (err) {
      if (err?.data && err?.data?.error) {
        message.error(err.message)
        setErrors(handleOnidataErrors(err.data.error))
      }
      swalError({ title: 'Atenção', err, icon: 'warning' })
    } finally {
      setLoadingSubmit(false)
    }
  }

  return (
    <SignupLayout>

      <div className='mb-3'>
        <Link
          className='signup__link'
          to={UNAUTHS_PATHS.FIRST_ACCESS_CLIENT_ECP_CHECK_LINK}
        >
          &larr; Voltar
        </Link>
      </div>

      <SignupTitle className='mb-1'>LGPD: Autorização para uso dos dados pessoais</SignupTitle>

      <p className='lgpd-access__info-text'>Antes de continuar, tenha em mãos o contra-cheque ou qualquer documento que tenha informações da empresa.</p>

      <Form
        layout="vertical"
        className='unauth-form'
        onFinish={handleSubmit}
        form={form}
      >

        <Form.Item
          name='cnpj'
          label='CNPJ'
          help={errors?.cnpj}
          validateStatus={errors?.cnpj && ('error')}
        >
          <Input
            className='unauth-inputs'
            placeholder='Digite seu CNPJ... '
            mask='cnpj'
          />
        </Form.Item>

        <Form.Item>
          <SignupButton
            htmlType='submit'
            className='w-100'
            loading={loadingSubmit}
          >
            Enviar
          </SignupButton>
        </Form.Item>
      </Form>

    </SignupLayout>
  )
}

export default LGPDAccessCheckCnpj

import React, { useState } from 'react'
import { Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import TokenVerification from 'components/TokenVerification/TokenVerification'
import { IError } from 'ecp/app/Proposals/proposalInterfaces'
import SignupLayout from 'layouts/SignupLayout/SignupLayout'
import { handleOnidataErrors } from 'utils/validate'
import TokenHeader from 'ecp/components/TokenHeader/TokenHeader'
import LoginRepository from 'ecp/repositories/LoginRepository'
import { useHistory } from 'react-router-dom'
import swal from 'utils/swal'
import useTokenInfo from '../../hooks/useTokenInfo'
import { ISignupClientConfirmRequest, ISignupClienttoken_confirmacaoForm } from '../../signUpClientInterfaces'
import SignupButton from 'egi/app/Signup/components/SignupButton/SignupButton'
import { SignupTitle } from 'egi/app/Signup/components/SignupTitle/SignupTitle'
import { swalError } from 'components/SwalError/SwalError'

function SignupClientPhoneToken () {
  const [form] = useForm<ISignupClienttoken_confirmacaoForm>()
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [errors, setErrors] = useState<IError<ISignupClienttoken_confirmacaoForm>>({})

  const { tokenInfo } = useTokenInfo()
  const history = useHistory()

  function redirectToNextStep () {
    history.push('/')
  }

  async function handleSubmit (values: ISignupClienttoken_confirmacaoForm) {
    setLoadingSubmit(true)

    const data: ISignupClientConfirmRequest = {
      ...values,
      onidataToken: tokenInfo.jwtToken as string
    }

    try {
      const response = await LoginRepository.confirmSms(data)
      redirectToNextStep()
      if (response.data.message) swal.basic({ title: 'Sucesso!', text: response.data.message, icon: 'success' })
    } catch (err) {
      if (err.data?.error) {
        console.error(err)
        setErrors(handleOnidataErrors(err.data?.error))
      }

      swalError({ err })
    } finally {
      setLoadingSubmit(false)
    }
  }

  return (
    <SignupLayout>
      <SignupTitle>
        Confirmar celular
      </SignupTitle>
      <TokenHeader
        title='Token enviado para o seu celular'
        infoText={
          <p className='ecp-token-header__info-text'>Adicione o token que você recebeu no número <b>{tokenInfo?.cellphone}</b></p>
        }
        className='mb-5'
      />
      <Form
        onFinish={handleSubmit}
        form={form}
        layout='vertical'
        className='unauth-form'
      >
        <Form.Item
          name='token_confirmacao'
          label={<span className='simulator-label'>Digite o token</span>}
          help={errors?.token_confirmacao}
          validateStatus={errors?.token_confirmacao && ('error')}
          rules={[
            {
              required: true,
              message: 'Adicione o token'
            }
          ]}
        >
          <TokenVerification lenght={6}/>
        </Form.Item>
        <Form.Item>
          <SignupButton
            loading={loadingSubmit}
            htmlType='submit'
            className='w-100'
          >
            Enviar
          </SignupButton>
        </Form.Item>
      </Form>
    </SignupLayout>
  )
}

export default SignupClientPhoneToken

import { message } from 'antd'
import dayjs from 'dayjs'
import IRRepository, { IRReportRequestStatus } from 'ecp/repositories/IRRepository'
import { useEffect, useState } from 'react'

interface IR {
  contratos: {
    texto: string
    lista: Array<string>
  }
  data_referencia: string
  saldo_devedor: number
  total_encargos: number
  total_pago: number
  total_pago_com_encargos: number
}

export interface IRBalance {
  id: string
  informacoes_imposto_de_renda: IR
  saldo_devedor: number
  saldo_devedor_no_proximo_vencimento: number
}

export const useFetchIrBalance = () => {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [irBalance, setIrBalance] = useState<IRBalance>()

  async function fetchIrBalance (userId: string, covenantId?: string) {
    try {
      setLoading(true)
      const response = await IRRepository.fetchBalance(userId, { params: { covenantId } })

      const balance = response.data.data?.balance
      if (!balance) throw new Error('Falha ao encontrar IR')

      setIrBalance(balance)
      setErrorMessage('')
    } catch (error) {
      setErrorMessage(error?.message)
    } finally {
      setLoading(false)
    }
  }

  return {
    fetchIrBalance,
    loading,
    errorMessage,
    irBalance
  }
}

export const useRequestIrPdf = () => {
  const [loading, setLoading] = useState(false)

  async function requestPdf (userId: string, covenantId?: string) {
    try {
      setLoading(true)
      if (!covenantId) throw new Error('Identicador do convênio não encontrado')

      const pdf = await IRRepository.requestPdf({ userId, covenantId }, {
        timeout: 90000
      })

      return pdf
    } catch (error) {
      message.error(error?.message ?? 'Falha ao se comunicar com a API de integração externa.')
    } finally {
      setLoading(false)
    }
  }

  return {
    requestPdf,
    loading
  }
}

export const useCheckIrPdf = () => {
  const [loading, setLoading] = useState(false)
  const [pollingLoading, setPollingLoading] = useState(false)
  const [irReportRequestId, setIrReportRequestId] = useState('')

  const generateFile = (pdf: Blob) => {
    const url = window.URL.createObjectURL(new Blob([pdf]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `ir_${dayjs().format('DD_MM_YYYY')}.pdf`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  async function checkPdf (irPdfReport: string) {
    try {
      setLoading(true)
      const response = await IRRepository.checkPdf(irPdfReport, {
        timeout: 90000
      })

      const pdf = response.data.data?.pdf

      if (pdf?.status === IRReportRequestStatus.finished && pdf.result) {
        generateFile(pdf.result)
        setIrReportRequestId('')
        setPollingLoading(false)
      }

      if (pdf?.status === IRReportRequestStatus.error) {
        setIrReportRequestId('')
        setPollingLoading(false)
        throw new Error('Falha ao gerar PDF')
      }
    } catch (error) {
      setIrReportRequestId('')
      setPollingLoading(false)
      message.error(error?.message ?? 'Falha ao se comunicar com a API de integração externa.')
    } finally {
      setLoading(false)
    }
  }

  const onPollingCheckPdf = (irPdfReport: string) => {
    setIrReportRequestId(irPdfReport)
  }

  useEffect(() => {
    if (!irReportRequestId) return

    setPollingLoading(true)
    const timeout: ReturnType<typeof setTimeout> = setInterval(() => {
      checkPdf(irReportRequestId)
    }, 1000)

    return () => {
      clearTimeout(timeout)
      setPollingLoading(false)
    }
  }, [irReportRequestId])

  return {
    checkPdf,
    onPollingCheckPdf,
    pollingLoading,
    loading
  }
}

import { IDynamicProposal, IDynamicProposalStep, IProposalDepartment } from 'ecp/app/Proposals/proposalInterfaces'
import { IActionDynamicProposal, IResourceStore } from 'store/modules/dynamicProposal/dynamicProposalActionsTypes'
import { DynamicProposalTypes } from './dynamicProposalActions'

export type IDynamicProposalStore = {
  details: IResourceStore<IDynamicProposal.Model> | undefined
  departments: IProposalDepartment[]
  steps: {
    [key: string]: IResourceStore<IDynamicProposalStep[]>
  } | undefined
  loadingSteps: string[]
}

const initialState: IDynamicProposalStore = {
  details: undefined,
  departments: [],
  steps: undefined,
  loadingSteps: []
}

const dynamicProposalReducer = (state = initialState, action: IActionDynamicProposal.allActions) => {
  switch (action.type) {
    case DynamicProposalTypes.SET_DETAILS: {
      return {
        ...state,
        details: {
          ...state.details,
          ...action.payload
        }
      }
    }

    case DynamicProposalTypes.RESET:
      return {
        ...initialState
      }

    case DynamicProposalTypes.SET_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload
      }

    case DynamicProposalTypes.STEP_LOADING_ON:
      return {
        ...state,
        loadingSteps: [state.loadingSteps, action.payload.stepId].flat()
      }

    case DynamicProposalTypes.STEP_LOADING_OFF:
      return {
        ...state,
        loadingSteps: state.loadingSteps.filter(item => item !== action.payload.stepId)
      }

    case DynamicProposalTypes.SET_STEPS: {
      const { departmentId, steps } = action.payload

      const newState = {
        ...state,
        steps: {
          ...state?.steps,
          [departmentId]: {
            ...state?.steps?.[departmentId],
            ...steps
          }
        }
      }

      return newState
    }

    default:
      return state
  }
}

export default dynamicProposalReducer

import { RouteType } from 'components/Sidebar/types'
import { IAuth } from 'egi/types'

import {
  dashboard,
  proposalsHistory,
  vinculationList,
  allProposals,
  settingsMenu,
  proposalCheck,
  internalNotification,
  juridicoTeam,
  chat,
  teamPromoterIn,
  allProposalsTwo,
  vinculationListTwo,
  lgpdTerms,
  bacenTerms,
  internReviewSettings
} from '../routes'

const backOfficeRoutes = (auth: IAuth): Array<RouteType> => {
  const routes = [
    dashboard,
    proposalsHistory,
    proposalCheck,
    ...(auth && auth.areaName && ['juridico', 'credito'].includes(auth?.areaName) ? [allProposals, allProposalsTwo] : [vinculationList, vinculationListTwo]),
    internalNotification,
    teamPromoterIn,
    ...(auth && auth.areaName === 'juridico'
      ? [juridicoTeam.providers, juridicoTeam.promoters, juridicoTeam.partnersLine, juridicoTeam.logsZip, internReviewSettings]
      : []
    ),
    chat,
    ...(auth && auth.areaName && ['juridico'].includes(auth?.areaName) ? [settingsMenu] : []),
    lgpdTerms,
    bacenTerms
  ]

  return routes
}

export default backOfficeRoutes

import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'

export const SimplePagination = ({ page, hasNextPage, onNextPage, onPreviousPage, className }: {
  onNextPage: (page: number) => void
  onPreviousPage: (page: number) => void
  page: number
  limit: number
  hasNextPage: boolean
  className?: string
}) => {
  return (
    <div className={`simple-pagination ${className ?? ''}`}>
      <Button
        type='ghost'
        className='simple-pagination__button simple-pagination__button--prev'
        disabled={page === 1}
        onClick={
          () => {
            onPreviousPage(page - 1)
          }
        }
      >
        <LeftOutlined />
      </Button>

      <div className='simple-pagination__page'>
        {page}
      </div>

      <Button
        type='ghost'
        className='simple-pagination__button simple-pagination__button--next'
        disabled={!hasNextPage}
        onClick={() => {
          onNextPage(page + 1)
        }}
      >
        <RightOutlined />
      </Button>
    </div>
  )
}

import Esteiradeparceiros from './components/Documentation/Esteira de parceiros/Esteira-de-parceiros'
import MasterElement from './components/Documentation/Master/Master'

export const topics = [
  {
    title: 'Master',
    subtitles: ['Função de Cancelamento de Usuario'],
    content: MasterElement()
  },
  {
    title: 'Esteira de parceiros',
    subtitles: ['Parceiro', 'Diagrama de casos de uso', 'Requisitos funcionais do sistema', 'Requisitos não funcionais'],
    content: Esteiradeparceiros()
  }
]

import React, { Fragment, useLayoutEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Tooltip } from 'antd'
import { WhatsAppOutlined } from '@ant-design/icons'
import { drawerFillForms } from 'store/modules/drawer/actions'
import IContactDrawer from 'egi/types/IDrawer'
import { useAuth } from 'hooks'

const ContactToSupport = () => {
  const [userHovering, setUserHovering] = useState(false)

  const dispatch = useDispatch()
  const auth = useAuth()

  const contactData: IContactDrawer = {
    visible: true,
    title: 'Envie sua mensagem',
    type: 'contact',
    contact: {
      client: {
        id: auth._id,
        name: auth.name,
        cpf: auth.cpf,
        email: auth.email
      },
      applicant: auth.name
    }
  }

  useLayoutEffect(() => {
    const element = document.querySelector('.contact-button')
    const container = document.querySelector('.ant-layout-content')

    container?.addEventListener('scroll', () => {
      const bottom = container.scrollHeight - container.scrollTop === container.clientHeight

      if (bottom) {
        element?.setAttribute('id', 'up-contact-button')
      } else {
        element?.setAttribute('id', 'user-contact-support')
      }
    })
  })

  return (
    <Fragment>
      <Tooltip title="Nós podemos te ajudar!" destroyTooltipOnHide={{ keepParent: false }}>
        {!userHovering ? (
          <Button
            className='contact-button'
            id='user-contact-support'
            shape="circle"
            icon={
              <WhatsAppOutlined
                style={{
                  color: '#fff',
                  fontSize: 25,
                  display: 'flex',
                  justifyContent: 'center'
                }}
              />}
            onMouseEnter={() => setUserHovering(true)}
            onClick={() => { dispatch(drawerFillForms(contactData)) }}
            size='large'
          />
        ) : (
          <Button
            className='contact-button'
            id='user-contact-support'
            shape="circle"
            onMouseLeave={() => setUserHovering(false)}
            onClick={() => { dispatch(drawerFillForms(contactData)) }}
            size='large'
          >
            <p>Precisa de ajuda ?</p>
          </Button>
        )}
      </Tooltip>
    </Fragment>
  )
}

export default ContactToSupport

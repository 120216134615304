function printContent (divName: string) {
  const printContents = (document.getElementById(divName) as HTMLButtonElement).innerHTML
  const originalContents = document.body.innerHTML
  document.body.innerHTML = printContents
  window.print()
  document.body.innerHTML = originalContents
  window.location.reload()
}

export default printContent

import React, { Fragment, useLayoutEffect, useState } from 'react'
import { Form, Select } from 'antd'
import { useResources } from 'hooks'
import translate from 'utils/translate'
import { FilteredAreas } from './types'
import { SelectValue } from 'antd/lib/select'

function FilterAreas ({ onChange, label, placeholder, name, hasDocumentationAndAvaliation }: { onChange: (value: SelectValue) => void, label: string, placeholder: string, name: string, hasDocumentationAndAvaliation?: boolean }) {
  const resources = useResources()

  const [areasRef, setAreasRef] = useState<FilteredAreas>()

  useLayoutEffect(() => {
    if (resources.areas.length < 1) return

    const filterAreas = resources.areas.reduce((acc, item) => {
      if (item.ref === 'documentacao' && acc.documentacao) acc.documentacao = [...acc.documentacao, item.value]
      if (item.ref === 'juridico') acc.juridico = [...acc.juridico, item.value]
      if (item.ref === 'credito') acc.credito = [...acc.credito, item.value]
      if (item.ref === 'avaliacao' && acc.avaliacao) acc.avaliacao = [...acc.avaliacao, item.value]
      return acc
    }, {
      documentacao: [],
      credito: [],
      avaliacao: [],
      juridico: []
    } as FilteredAreas)

    if (!hasDocumentationAndAvaliation) {
      delete filterAreas?.documentacao
      delete filterAreas?.avaliacao

      const formattedFilter = Object.assign({ engenharia: null }, filterAreas)
      return setAreasRef(formattedFilter)
    }

    setAreasRef(filterAreas)
  }, [resources.areas])

  return (
    <Fragment>
      <Form.Item
        name={name}
        label={<label>{label}</label>}
      >
        <Select
          className="w-100"
          placeholder={placeholder}
          onChange={onChange}
        >
          <Select.Option value="">Todos</Select.Option>
          {areasRef && Object.keys(areasRef).map((item, index) => (
            <Select.Option
              key={index}
              value={item}
            >
              {translate.areasNames(item)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Fragment>
  )
}

export default FilterAreas

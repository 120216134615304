import React, { useState } from 'react'
import { Button } from 'antd'

import Modal from 'antd/lib/modal/Modal'
import swal from 'utils/swal'
import ProposalEgiRepository from 'egi/repositories/ProposalEgiRepository'
import { swalError } from 'components/SwalError/SwalError'
import { useDispatch } from 'react-redux'
import { getProposalState } from 'store/modules/proposal/actions'
import { ProposalState } from 'store/modules/proposal/types'
import WarningText from 'components/WarningText/WarningText'

interface IProps {
  proposal?: ProposalState
  visible: boolean
  onCancel: () => void
}

export function SolicitationModalContent ({ proposal }: { proposal?: ProposalState }) {
  if (proposal?.proposalExpiration?.canShowExpiredButton) {
    return (
      <p className="proposal-validity-modal__content">Clique no botão revalidar proposta para revalidar a <b>data de validade</b> da proposta</p>
    )
  }

  return (
    <div className="proposal-validity-modal__content">
      <p>Solicite uma nova validade para o Suporte comercial.</p>
      <p>Clique no botão solicitar revisão para a proposta ser enviada ao comercial.</p>
    </div>
  )
}

function ModalSolicitationTitle ({ proposal }: { proposal?: ProposalState }) {
  if (proposal?.proposalExpiration?.canShowExpiredButton) return <span className="proposal-validity-modal__title">Revisão de validade!</span>

  return <span className="proposal-validity-modal__title">Proposta expirada!</span>
}

function Footer ({ proposal, onCancel }: { proposal?: ProposalState, onCancel: () => void }) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)

  const revalidateExpiration = async () => {
    swal.confirmNegate({
      icon: 'warning',
      title: 'Atenção',
      text: 'Você tem certeza que deseja revalidar essa proposta? ',
      confirm: async () => {
        setLoading(true)
        try {
          if (!proposal?.id) throw new Error('Falha ao encontrar id da proposta')
          const response = await ProposalEgiRepository.revalidate(proposal?.id)
          swal.basic({ title: 'Sucesso!', text: response.data.message, icon: 'success' })
          onCancel()
        } catch (err) {
          swalError({ err })
        } finally {
          setLoading(false)
          if (proposal?.id) {
            dispatch(getProposalState(proposal?.id, () => {
              setLoading(false)
            }))
          }
        }
      }
    })
  }

  const requestRevalidation = async () => {
    setLoading(true)
    try {
      if (!proposal?.id) throw new Error('Falha ao encontrar id da proposta')
      const response = await ProposalEgiRepository.redirect(proposal?.id)
      swal.basic({ title: 'Sucesso!', text: response.data.message, icon: 'success' })
      onCancel()
    } catch (err) {
      swalError({ err })
    } finally {
      setLoading(false)
      if (proposal?.id) {
        dispatch(getProposalState(proposal?.id, () => {
          setLoading(false)
        }))
      }
    }
  }

  if (proposal?.proposalExpiration?.canShowExpiredButton) {
    return <Button
      type="primary"
      onClick={() => revalidateExpiration()}
      loading={loading}>
      Revalidar expiração
    </Button>
  }
  return <Button
    type="primary"
    onClick={() => requestRevalidation()}
    loading={loading}>
    Solicitar Revisão
  </Button>
}

function SolicitationModal ({ visible, onCancel, proposal }: IProps) {
  return (
    <Modal
      visible={visible}
      footer={null}
      className="proposal-validity-modal"
      onCancel={() => onCancel()}
    >
      <WarningText
        iconColor="var(--warning-status)"
        title={<ModalSolicitationTitle proposal={proposal}/>}
        type="warning"
      >
        <SolicitationModalContent proposal={proposal} />
      </WarningText>
      <div className="text-right">
        <Footer
          proposal={proposal}
          onCancel={onCancel}
        />
      </div>
    </Modal>
  )
}

export default SolicitationModal

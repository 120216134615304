import { CloseOutlined, EyeOutlined, FileTextOutlined } from '@ant-design/icons'
import { Divider, Empty, Modal } from 'antd'
import LetterAvatar from 'ecp/components/LetterAvatar/LetterAvatar'
import React, { useState } from 'react'
import translate from 'utils/translate'
import { IMaster } from '../../Views/MasterList/MasterList'

interface Props {
  row: IMaster
}

function MasterModal ({ row }: Props) {
  const [visible, setVisible] = useState<boolean>(false)
  function openOrClose () {
    setVisible(prev => !prev)
  }

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        title={<p>Todos os produtos</p>}
        onOk={openOrClose}
        destroyOnClose
        closeIcon={<CloseOutlined onClick={openOrClose} />}
        footer={null}
      >
        <div className="modal-product">
          <div className="modal-product__header">
            <LetterAvatar name={row.name} />
            <div className="px-2">
              <p>Todos os produtos de</p>
              <h3>
                <b>{row.name}</b>:</h3>
            </div>
          </div>
          <Divider />

          {Array.isArray(row.products) ? row.products.map((product) => {
            return (<div className="modal-product__content" key={product}>
              <FileTextOutlined className="px-2" />
              <p>{translate.productSlug(product)}</p>
            </div>)
          }
          ) : <Empty />}
        </div>
      </Modal>

      <EyeOutlined className="mx-2 modal-product__see-more" onClick={openOrClose} />
    </>
  )
}

export { MasterModal }

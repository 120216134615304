import React, { useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import { Button, Col, Row, Tag, Tooltip } from 'antd'
import translate from 'utils/translate'
import { partnersStatusColor } from 'styles/colors'
import commonColumns, { commomNameOverflowWidth } from 'components/UserTable/utils/commonColumns'
import blockColumn from 'components/UserTable/utils/blockColumn'
import { timeAsDayjs } from 'utils/time'
import Swal from 'sweetalert2'
import { slaColors } from 'utils/globals'
import dayjs from 'dayjs'
import { ISLA } from 'egi/app/Settings/views/SLAConfig/slaInterfaces'
import NameDropdownController from 'components/NameDropdownController/NameDropdownController'
import { channelOptions } from 'components/ModalInvite/components/constants'
import format from 'utils/format'
import { Link } from 'react-router-dom'
import { EditOutlined, EyeOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { useAuth } from 'hooks'
import PromoterListModel from 'egi/models/PromoterListModel'
import { IPromoterColumns } from '../userTableInterfaces'

import UserTableModel from 'egi/models/UserTableModels'
import { OverflowTooltip } from 'components/OverflowToolTip/OverflowTooltip'
import Modal from 'antd/lib/modal/Modal'
import DetailsItem from 'ecp/components/DetailsItem/DetailsItem'
import { IBank } from 'egi/types'
import { IPix } from 'egi/types/IBank'

const ModalBankInfo = ({ bank, visible, pix, onVisibleChange } : {bank: IBank, visible: boolean, pix: IPix, onVisibleChange: ()=> void}) => {
  return <Modal visible={visible} footer={null} onCancel={() => onVisibleChange()} width={700}>
    <h3 className="color-primary">
      <b>Informações Bancárias</b>
    </h3>
    <Row gutter={[15, 15]}>
      <Col lg={12} sm={24}>
        <DetailsItem label='Banco' value={bank.name ?? '-'}/>
      </Col>
      <Col lg={12} sm={24}>
        <DetailsItem label='Número da conta' value={bank.account ?? '-'}/>
      </Col>
    </Row>
    <Row gutter={[15, 15]}>
      <Col lg={12} sm={24}>
        <DetailsItem label='Agência' value={bank.agency ?? '-'}/>
      </Col>
      <Col lg={12} sm={24}>
        <DetailsItem label='Titular da conta' value={bank.accountHolder ?? '-'}/>
      </Col>
    </Row>
    <Row>
      <Col lg={12} sm={24}>
        <DetailsItem label='Tipo' value={bank.type ?? '-'}/>
      </Col>
    </Row>
    <h3 className="color-primary pt-2">
      <b>Informações Pix</b>
    </h3>
    <Row gutter={[15, 15]}>
      <Col lg={24} sm={24}>
        <DetailsItem label='Tipo' value={translate.pixTypes(pix.type) ?? '-'}/>
      </Col>
      <Col lg={24} sm={24}>
        <DetailsItem label='Chave' value={pix[pix.type as keyof IPix] ?? '-'}/>
      </Col>
    </Row>
  </Modal>
}

function PromoterColumns ({ blockUser, action, viewerLevel }: IPromoterColumns): ColumnsType<any> {
  const user = useAuth()

  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'lineDocument',
      ellipsis: true,
      align: 'center',
      width: 100,
      sorter: true,
      render: (document: string, row: { code: string }) => {
        if (!document) return 'N/A'

        if (PromoterListModel.canAccessLink(user.level)) {
          return (
            <Link
              className="column-link"
              to={PromoterListModel.partnersLineRoute(user, document)}
            >
              {row.code}
            </Link>
          )
        }

        return row.code
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 160,
      sorter: true,
      align: 'center',
      render: (value: string) => {
        if (!value) return 'N/A'

        return (
          <Tag color={partnersStatusColor[value]}>
            {translate.partnersStatus(value)}
          </Tag>
        )
      }
    },
    {
      title: 'SLA',
      dataIndex: 'sla',
      ellipsis: true,
      align: 'center',
      width: 100,
      key: 'sla',
      render: (SLA: ISLA) => {
        if (!SLA) return 'N/A'

        const diffTime = timeAsDayjs(dayjs(SLA?.max)).diff(timeAsDayjs(dayjs()), 'days')
        const diffTimeEnd = timeAsDayjs(dayjs(SLA?.max)).diff(timeAsDayjs(dayjs(SLA?.end)), 'days')

        if (SLA?.end) {
          return (
            <Button
              type="link"
              onClick={() => {
                Swal.fire({
                  title: 'SLA - Finalizado',
                  html: `
                <b>Data de fim</b>: ${timeAsDayjs(SLA?.end).format('DD-MM-YYYY')}
                <br/><br/>
                <b>Data máxima</b>: ${timeAsDayjs(SLA?.max).format('DD-MM-YYYY')}
                `
                })
              }}
            >
              <div className="crivo-status-circle mr-1" style={{ backgroundColor: slaColors(diffTimeEnd) }} />
            </Button>
          )
        }

        return (
          <Button
            type="link"
            onClick={() => {
              Swal.fire({
                title: 'SLA - Em andamento',
                html: `
              <b>Data de início</b>: ${timeAsDayjs(SLA?.actionAt).format('DD-MM-YYYY')}
              <br/><br/>
              <b>Data máxima</b>: ${timeAsDayjs(SLA?.max).format('DD-MM-YYYY')}
              <br/><br/>
              <b>Dias restantes</b>: ${diffTime}
              `
              })
            }}
          >
            <div className="crivo-status-circle" style={{ backgroundColor: slaColors(diffTime) }} />
          </Button>
        )
      }
    },
    {
      title: 'Vencimento SLA',
      dataIndex: ['sla', 'max'],
      ellipsis: true,
      width: 150,
      key: 'sla-max',
      sorter: true,
      render: (max: string) => {
        if (!max) return 'N/A'
        return <span className="table-name">{timeAsDayjs(max).format('DD/MM/YYYY HH:mm')}</span>
      }
    },
    {
      title: 'Informações Bancárias',
      dataIndex: 'bank',
      width: 250,
      sorter: true,
      render: (bank, row) => {
        if (!bank) return <div className="text-center">-</div>
        const [visible, setVisible] = useState<boolean>(false)

        const onVisibleChange = () => {
          setVisible(prev => !prev)
        }
        return (
          <>
            <ModalBankInfo
              visible={visible}
              onVisibleChange={onVisibleChange}
              pix={row?.pix}
              bank={bank}/>
            <div className="flex flex-justify-center flex-align-center">
              <div className="mr-2">
                <p><b>Conta:</b> {bank.account ?? '-'}</p>
                <p><b>Chave pix:</b> <Tooltip title={row.pix?.[row?.pix?.type]}>
                  {
                    row.pix?.[row?.pix?.type]
                      ? row.pix?.[row?.pix?.type].substring(0, 5) + '...' : '-'}
                </Tooltip>
                </p>
              </div>

              <Link to="#" type={'primary'} onClick={() => onVisibleChange()} className='link-style color-tertiary'>Ver Tudo <EyeOutlined/></Link>
            </div>
          </>
        )
      }
    },
    {
      ...commonColumns.name,
      render: (name: string, row: { type: string, _id: string, cpfOrCnpj: string }) => {
        const type = row?.type ? `${row?.type} -` : ''
        const team = PromoterListModel.canSeePromoterIndicator(user.level)

        return (
          <>
            <OverflowTooltip width={commomNameOverflowWidth} title={
              <>
                <div>{name}</div>
                { row?.cpfOrCnpj && <div>{row?.cpfOrCnpj}</div> }
              </>
            }>
              <NameDropdownController
                showUserDetails={UserTableModel.showUsersDetails(user.level)}
                dropDownLabel={`${type} ${name}`}
                userName={`${type} ${name}`}
                userId={row._id}
                tableLevel='Parceiro'
                extraAction={{
                  name: 'Time',
                  url: `/auth/mlm/promoters/${row._id}/${name}`,
                  hasExtraAction: team
                }}
              />
              <div>
                {row?.cpfOrCnpj && row?.cpfOrCnpj}
              </div>
            </OverflowTooltip>

          </>
        )
      }
    },
    {
      title: 'Canal',
      dataIndex: 'channel',
      ellipsis: true,
      width: 100,
      sorter: true,
      render: (channel: string, row: { _id: string }) => {
        if (!channel) return 'N/A'
        const promoterChannel = channelOptions.find((item) => item.value === channel)
        if (!promoterChannel) return 'N/A'

        if (PromoterListModel.canChangeChanel(user.level)) {
          return (
            <p
              className="column-link"
              onClick={() => action({
                type: 'change-channel',
                info: {
                  id: row._id,
                  currentChannel: promoterChannel.label
                }
              })}
            >
              {promoterChannel?.label}
            </p>
          )
        }

        return promoterChannel?.label
      }
    },
    commonColumns.fantasyName,
    commonColumns.foundationDate,
    commonColumns.updatedAt,

    {
      title: 'Gerente de negócios',
      dataIndex: 'inviterFantasyName',
      ellipsis: true,
      width: 175,
      sorter: true,
      render: (inviter: string, row: { _id: string }) => {
        const PlusIcon = () => (
          <div className='w-100 text-center font-size-15'>
            <PlusCircleOutlined />
          </div>
        )

        if (PromoterListModel.canChangeManager(user.level)) {
          return (
            <OverflowTooltip title={inviter} width={150}>
              <p
                className="column-link"
                title=''
                onClick={() => action({
                  type: 'change-manager',
                  info: {
                    id: row._id,
                    currentBusinessManager: inviter
                  }
                })}
              >
                {inviter ?? <PlusIcon/>}
              </p>
            </OverflowTooltip>
          )
        }

        return (
          <OverflowTooltip title={inviter} width={150}>
            <span title=''>{inviter}</span>
          </OverflowTooltip>
        )
      }
    },
    {
      title: 'Ação atual',
      dataIndex: 'currentAction',
      ellipsis: true,
      width: 150,
      render: (currentAction: string) => {
        if (!currentAction) return 'N/A'
        return format.capitalize(currentAction)
      }
    },
    commonColumns.email,
    commonColumns.cellphone,
    commonColumns.createdAt,
    ...(PromoterListModel.showCommissionColumn(viewerLevel) ? [
      {
        title: '% Comissão',
        dataIndex: 'commissionPercentage',
        ellipsis: true,
        width: 150,
        sorter: true,
        render: (commissionPercentage: number, row: { _id: string, type: string }) => {
          const commission = commissionPercentage * 100
          const promoter = { id: row._id, commissionPercentage, promoterType: row.type }

          if (PromoterListModel.canChangeCommission(user)) {
            return (
              <Tooltip title="Editar porcentagem da comissão" destroyTooltipOnHide={{ keepParent: false }}>
                <div
                  className="flex flex-justify-center flex-align-center link-style color-tertiary font-500"
                  onClick={() => action && action({ info: promoter, type: 'commission' })}
                >
                  <p className="mb-0">{format.toPercentage(commission) + '%'}</p>
                  <EditOutlined />
                </div>
              </Tooltip>
            )
          }

          return format.toPercentage(commission) + '%'
        }
      }] : []),
    ...(blockUser ? [blockColumn({ blockUser, userLevel: user.level })] : [])
  ]

  return columns
}

export default PromoterColumns

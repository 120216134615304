import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import React from 'react'

interface IProps extends ButtonProps {}

function ButtonGreenGhost ({ className = '', ...rest }: IProps) {
  return (
    <Button
      className={`${className} button-green-ghost`}
      type='ghost'
      {...rest}
    />
  )
}

export default ButtonGreenGhost

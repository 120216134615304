import React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { Tag } from 'antd'
import translate from 'utils/translate'
import { varColors } from 'styles/colors'
import commonColumns from 'components/UserTable/utils/commonColumns'
import blockColumn from 'components/UserTable/utils/blockColumn'
import format from 'utils/format'
import { useAuth } from 'hooks'
import { IBaseProps } from '../userTableInterfaces'

function PromoterCollaboratorsColumns ({ blockUser }: IBaseProps): ColumnsType<any> {
  const user = useAuth()

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      sorter: true,
      width: 150,
      render: (name: string, row: { type: string, _id: string }) => {
        const type = row?.type ? `${row?.type} -` : ''
        return `${type} ${name}`
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      width: 125,
      render: (value: string) => {
        if (!value) return 'N/A'
        return <Tag color={varColors[value]}>{translate.userStatus(value)}</Tag>
      }
    },
    commonColumns.fantasyName,
    {
      title: 'CPF/CNPJ',
      dataIndex: 'cpfOrCnpj',
      sorter: true,
      width: 125,
      render: (cpfOrCnpj: string) => {
        if (!cpfOrCnpj) return 'N/A'
        return cpfOrCnpj
      }
    },
    commonColumns.foundationDate,
    {
      title: 'Ação atual',
      dataIndex: 'currentAction',
      width: 125,
      render: (currentAction: string) => {
        if (!currentAction) return 'N/A'
        return format.capitalize(currentAction)
      }
    },
    commonColumns.email,
    commonColumns.cellphone,
    commonColumns.createdAt,
    {
      title: '% Comissão',
      dataIndex: 'commissionPercentage',
      sorter: true,
      width: 125,
      render: (commissionPercentage: number) => {
        const commission = commissionPercentage * 100

        return format.toPercentage(commission) + '%'
      }
    },
    ...(blockUser ? [blockColumn({ blockUser, userLevel: user.level })] : [])
  ]

  return columns
}

export default PromoterCollaboratorsColumns

import React from 'react'
import { Col, Collapse, Row } from 'antd'
import DetailsItem from 'ecp/components/DetailsItem/DetailsItem'
import { formatValue } from 'egi/app/AccessManager/accessManagerFunctions'
import { AccessManagerDetailsGroupEnum, IDetailSchema } from 'egi/app/AccessManager/accessManagerInterfaces'

type Props = {
  groups: Array<{key: string, title: string }>
  schema: Array<IDetailSchema>
  data: Object
}

function AccessManagerDetails ({ groups = [{ title: 'Outros', key: AccessManagerDetailsGroupEnum.others }], schema, data: detailsData }: Props) {
  return (
    <Collapse
      bordered={false}
      defaultActiveKey={groups?.[0]?.key}
      className='access-manager-details__collapse'
    >

      {groups.map(group => {
        const data = schema.filter((item) => item.group === group.key)

        if (!Array.isArray(data) || !data.length) return null

        return (
          <Collapse.Panel
            className='access-manager-details__collapse-panel'
            header={group.title}
            key={group.key}
          >
            <Row key={group.key} gutter={[15, 15]}>
              {data.map((item) => {
                const value = formatValue(item, detailsData)

                if (item.Component) return item.Component(item, detailsData)

                return (
                  <Col key={JSON.stringify(item.key)} lg={6} md={6} xs={24}>
                    <DetailsItem detailsItemClassName="access-manager-details__detail-wrapper" label={item.label} value={value}/>
                  </Col>
                )
              })}
            </Row>
          </Collapse.Panel>
        )
      })}
    </Collapse>
  )
}

export default AccessManagerDetails

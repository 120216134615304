import React from 'react'
import { Form, Select } from 'antd'
import { serverTableDefaultFilters, serverTableSelectProps } from 'utils/globals'

function FilterLeadsStatus ({ onChange }: { onChange: (value: string | string[] | undefined) => void }) {
  return (
    <Form.Item
      name="leadStatus"
      label={<label>Status do lead</label>}
    >
      <Select
        {...serverTableSelectProps}
        allowClear
        placeholder="Escolha o status"
        defaultValue={serverTableDefaultFilters.leads}
        onChange={onChange}
      >
        <Select.Option value="awaiting">Aguardando</Select.Option>
        <Select.Option value="ongoing">Em andamento</Select.Option>
        <Select.Option value="approved">Seguiu</Select.Option>
        <Select.Option value="refused">Declinou</Select.Option>
      </Select>
    </Form.Item>
  )
}

export default FilterLeadsStatus

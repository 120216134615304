import React, { Fragment } from 'react'
import { Divider, Typography } from 'antd'
import { useStep } from 'hooks'
import { ReactSVG } from 'react-svg'
import { ColumnsType } from 'antd/lib/table'
import { Table } from 'components'
import FilePdfOutlined from '@ant-design/icons/lib/icons/FilePdfOutlined'

const { Paragraph } = Typography
const statusCrivo: { [key: string]: string } = {
  ok: 'green',
  nok: 'red',
  'Resposta padrão': 'transparent',
  erro: 'black',
  a: 'green',
  b: 'green',
  c: 'yellow',
  d: 'red',
  e: '#6F1E51',
  apr: 'green',
  hum: 'yellow',
  rec: 'red'
}

const columns: ColumnsType<Object> = [
  {
    title: 'Critério',
    dataIndex: 'criterio'
  },
  {
    title: 'Resposta',
    dataIndex: 'resposta'
  },
  {
    title: 'Cor',
    dataIndex: 'resposta',
    render: (item: string) => <div className="crivo-status-circle" style={{ backgroundColor: statusCrivo[item.toLowerCase()] }} />
  }
]

function CrivoPdf () {
  const step = useStep()
  let crivoMessage
  let crivoColors

  switch (step.crivoStatus) {
    case 'APR':
      crivoMessage = 'O crivo foi aprovado'
      crivoColors = '#2CD701'
      break
    case 'HUM':
      crivoMessage = 'Mesa (humano)'
      crivoColors = '#FFC107'
      break
    case 'REC':
      crivoMessage = 'O crivo foi recusado'
      crivoColors = '#D70101'
      break
    default:
      crivoMessage = ''
      crivoColors = 'white'
  }

  return (
    <Fragment>
      {!step.documents ? (
        <div className="flex flex-justify-center flex-align-center flex-column">
          <ReactSVG
            src="https://agxbucket.s3-sa-east-1.amazonaws.com/tesouraria.svg"
            beforeInjection={(svg) => {
              svg.classList.add('department-image-documents')
            }}
          />
          <Paragraph className="mt-2">Os {"PDF's"} do Crivo ainda não se encontram disponíveis.</Paragraph>
        </div>
      ) : (
        <Fragment>
          <Paragraph className="bold">{"PDF's"}.</Paragraph>
          <div className='mb-3'>
            {step.crivoPdf?.map((url: string) => (
              <a
                key={url}
                className="flex flex-column text-center card-style p-3 display-inline-block mr-3"
                href={url}
                target="_blank"
                rel="noreferrer"
                download
              >
                <FilePdfOutlined style={{ fontSize: 35 }} className="pb-3" />
                <p className="mb-0">Abrir Crivo</p>
              </a>
            ))}
          </div>

          <div className="crivo-subtitle">
            <div className="crivo-status-circle" style={{ backgroundColor: crivoColors }} />

            <p className="ml-2 mb-0">
              {crivoMessage}.
            </p>

            <p className="ml-2 mb-0">
              Política usada: <b>{step.politic}</b>
            </p>
          </div>

          <Divider className="my-3" />

          <Table
            pageSize={100}
            loading={false}
            data={step.criterios || []}
            columns={columns}
          />
        </Fragment>
      )}
    </Fragment>
  )
}

export default CrivoPdf

import React, { useMemo, useState } from 'react'
import { Col, Form, Row, Spin } from 'antd'
import { Drawer, Table } from 'components'
import { useForm } from 'antd/lib/form/Form'
import useAccessManager from 'egi/app/AccessManager/hooks/useAccessManager'
import useProposalClientColumns from 'egi/app/AccessManager/hooks/useProposalClientColumns'
import AccessManagerDetails from 'egi/app/AccessManager/components/AccessManagerDetails/AccessManagerDetails'
import { auditoriaEGIGroups, auditoriaEGISchema, auditoriaECPSchema, auditoriaECPGroup } from 'egi/app/AccessManager/accessManagerConstants'
import { useAuth } from 'hooks'
import SearchSVG from 'assets/img/SearchSVG/SearchSVG'
import FilterSearchIdentifierInput from 'components/SearchIdentifierInput/FilterSearchIdentifierInput'
import { accessManagerBuildSchema } from '../../accessManagerFunctions'
import { IDetailSchema } from '../../accessManagerInterfaces'

const identifiers = [
  { label: 'CPF', value: 'cpf' }
]

export const ACCESS_MANAGER_CLIENT_FILTERS = {
  identifier: 'cpf',
  search: ''
}

function AccessManagerClient () {
  const { errorMessage, accessDetails, params, setParams, isLoading: loading, getAccessDetails } = useAccessManager()
  const [formRef] = useForm()
  const auth = useAuth()
  const { columns: buildProposalClientColumns } = useProposalClientColumns()
  const [toogleDrawer, setToogleDrawer] = useState(false)
  const [proposalSelected, setProposalSelected] = useState<any>(null)

  const { groups, schema } = useMemo(() => {
    if (auth.selectedProduct?.slug === 'home-equity') {
      return {
        groups: auditoriaEGIGroups,
        schema: auditoriaEGISchema
      }
    }
    if (auth.selectedProduct?.slug === 'ecp') {
      return {
        groups: auditoriaECPGroup,
        schema: auditoriaECPSchema
      }
    }
    return { groups: [], schema: [] }
  }, [])

  const description = useMemo(() => {
    if (errorMessage) return errorMessage
    if (!params.search) return 'Pesquise um usuário para obter suas propostas.'
    return ''
  }, [JSON.stringify(params), accessDetails])

  const handleSelectProposal = (proposal: any) => {
    setProposalSelected(proposal)
    setToogleDrawer(prev => !prev)
  }

  const handleOnReset = () => {
    formRef.setFieldsValue({ search: '' })
    setParams(prev => ({
      ...prev,
      search: ''
    }))
  }

  const handleOnClickSearch = (search?: string, identifier?: string) => {
    getAccessDetails({
      search: search?.trim(),
      identifier: identifier || 'cpf'
    })

    setParams({
      search: search?.trim(),
      identifier: identifier || 'cpf'
    })
  }

  const columns = buildProposalClientColumns(handleSelectProposal)

  const detailsSchema = useMemo<Array<IDetailSchema>>(() => {
    return accessManagerBuildSchema(schema, proposalSelected || {})
  }, [schema, proposalSelected])

  return (
    <div className="w-100">
      <Drawer
        visible={toogleDrawer}
        onClose={() => {
          setToogleDrawer(prev => !prev)
          setProposalSelected(null)
        }}
        title="Auditoria"
        footer={null}
      >
        <AccessManagerDetails
          groups={groups}
          schema={detailsSchema}
          data={proposalSelected}
        />
      </Drawer>

      <p className='mb-4 font-size-15 font-500'>Pesquise abaixo o usuário que deseja consultar suas propostas.</p>

      <Form
        layout="vertical"
        form={formRef}
      >
        <Row justify='space-between' wrap>
          <Col lg={6} md={12} sm={24} xs={24}>
            <FilterSearchIdentifierInput
              placeholder='Escolha o que deseja pesquisar'
              key='identifier'
              label='Pesquisar'
              identifiers={identifiers}
              onReset={handleOnReset}
              onChange={() => {}}
              onClickSearch={handleOnClickSearch}
            />
          </Col>
        </Row>
      </Form>

      {description && (
        <Spin spinning={loading} >
          <div className="mt-5 w-100 access-manager-client__svg-wrapper">
            <div className='access-manager-client__search-svg-container'>
              <SearchSVG/>
            </div>
            <p>{description}</p>
          </div>
        </Spin>
      )}

      {(!!params.search && !description) && (
        <Table
          id="server-table"
          canSearch={false}
          showPagination={true}
          data={accessDetails}
          columns={columns}
          loading={loading}
        />
      ) }
    </div>
  )
}

export default AccessManagerClient

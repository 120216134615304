import React from 'react'
import DynamicColorButton from 'ecp/components/DynamicColorButton/DynamicColorButton'
import { useAccessManagerCancelBackoffice } from '../../hooks/useAccessManager'

type Props = {
  disabled?: boolean
  userId?: string
  onFinish?: () => void
  className?: string
}

export default function BackofficeCancelButton ({ disabled, userId, onFinish, className }: Props) {
  const { loading, onCancel } = useAccessManagerCancelBackoffice()
  return (
    <DynamicColorButton
      colorClass="button-red"
      className={className}
      type='primary'
      onClick={async () => {
        onCancel(userId, onFinish)
      }}
      disabled={disabled}
      loading={loading}
    >
      Cancelar usuário
    </DynamicColorButton>
  )
}

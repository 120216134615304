import { Divider, Form } from 'antd'
import { Icons } from 'components'
import { colors } from 'styles/colors'
import ReviewModal from './ReviewModal'
import { useDispatch } from 'react-redux'
import { _userLevel } from 'ecp/models/UsersModel'
import BackofficeModel from 'egi/models/BackofficeModel'
import { ReviewForm } from './reviewBackofficeInterfaces'
import { ReviewBackofficeForm } from './ReviewBackofficeForm/ReviewBackofficeForm'
import WarningText from 'components/WarningText/WarningText'
import { useAuth, useProposal, useResources, useStep } from 'hooks'
import React, { useState, useLayoutEffect, useEffect, Fragment } from 'react'
import ReviewBackofficeHistory from 'components/ReviewBackofficeHistory/ReviewBackofficeHistory'
import { resourcesGetAreaNames, resourcesGetAreas, resourcesGetDeclines, resourcesGetProviders } from 'store/modules/resources/actions'

function ReviewFormWrapper ({ children, conditional }: { children: React.ReactNode, conditional?: boolean }) {
  const user = useAuth()

  function levelIcons ({ level }: { level: _userLevel }) {
    const size = 30
    const labels = [<label key={1} className="mx-1"><Icons name={'analysis'} size={size} color={colors.analysis} /></label>]

    if (level === 'master') {
      labels.push(<label key={2} className="mx-1"><Icons name={'approved'} size={size} color={colors.approved} /></label>)
      labels.push(<label key={3} className="mx-1"><Icons name={'reproved'} size={size} color={colors.reproved} /></label>)
    }

    return <>{labels.map(item => item)}</>
  }

  if (!conditional) {
    return (
      <label className="flex flex-align-center">
        Sua área deve ter a permissão e o passo precisa estar em {user.level ? levelIcons({ level: user.level }) : ''} para ser revisado.
      </label>
    )
  }

  return <>{children}</>
}

function ReviewBackoffice ({ finishReview, hide, canFetchProviders = false }: { finishReview: Function, hide: boolean, canFetchProviders?: boolean }) {
  const [updateHistory, setUpdateHistory] = useState<boolean>(false)
  const [modalShare, setModalShare] = useState({ show: false, link: '' })
  const [form, setForm] = useState<ReviewForm>({ area: '', status: '' })
  const [formRef] = Form.useForm()

  const step = useStep()
  const user = useAuth()
  const proposal = useProposal()
  const resources = useResources()

  const dispatch = useDispatch()
  const reviewRules = step.review

  function resetFields () {
    if (formRef) formRef.resetFields()
    setForm({ area: '', status: '' })
  }

  useLayoutEffect(() => {
    if (canFetchProviders && step.hasProvider) {
      dispatch(resourcesGetProviders({ areaName: step.hasProvider }))
    }
  }, [])

  useEffect(() => {
    if (!user.level) return
    const canFetchReproval = BackofficeModel.canFetchReproval(user.level)
    const canFetchAreaNames = BackofficeModel.canFetchAreaNames(user.level)

    if (resources.declines.length < 1 && canFetchReproval) dispatch(resourcesGetDeclines({ productId: resources.products[0]._id }))
    if (resources.areaNames.length < 1 && canFetchAreaNames) dispatch(resourcesGetAreaNames())
    if (resources.areas.length < 1) dispatch(resourcesGetAreas())
  }, [
    resources.declines,
    resources.areaNames,
    resources.areas
  ])

  function resetShareModal () {
    setModalShare({ show: false, link: '' })
  }

  if (hide) return (<></>)

  if (proposal?.proposalExpiration?.isDateExpired) {
    return (
      <div className='mt-3 review-backoffice-expired-proposal'>
        <WarningText title={<span className="review-backoffice-expired-proposal__title">Proposta expirada!</span>} type="warning" iconColor="var(--warning-status)" >
          <p>Sua proposta está expirada, solicite uma nova validade para o Suporte comercial.</p>
        </WarningText>
      </div>
    )
  }

  return (
    <Fragment>
      <ReviewFormWrapper conditional={reviewRules}>
        <ReviewBackofficeForm
          finishReview={finishReview}
          form={form}
          setForm={setForm}
          resetFields={resetFields}
          setModalShare={setModalShare}
          setUpdateHistory={setUpdateHistory}
        />
      </ReviewFormWrapper>

      <ReviewModal
        show={modalShare.show}
        link={modalShare.link}
        resetShareModal={resetShareModal}
        finishReview={finishReview}
      />

      <section className='w-50 m-auto m-3'>
        <Divider className='mb-5'/>
      </section>

      <ReviewBackofficeHistory
        updateHistory={updateHistory}
        resetFields={resetFields}
      />
    </Fragment>
  )
}

export default ReviewBackoffice
